import React from 'react';

function SvgNoAtlasAppointments() {
  /* eslint-disable */
  return (
    <svg
      width="61px"
      height="53px"
      viewBox="0 0 61 53"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>EBC69659-3219-4159-BE13-A81ECB09114D</title>
      <defs>
        <linearGradient x1="50%" y1="1.22994088%" x2="50%" y2="100%" id="linearGradient-1">
          <stop stopColor="#0AB6C9" offset="0%"></stop>
          <stop stopColor="#0AABE4" offset="100%"></stop>
        </linearGradient>
        <path
          d="M50.7934004,2.39616613 C52.5760039,2.39616613 54.0210896,3.76188733 54.0210896,5.4465898 L58.658147,34.4256147 C58.658147,36.1103171 57.2130613,37.4760383 55.4304577,37.4760383 L23.6347006,37.4760383 C21.852097,37.4760383 20.4070113,36.1103171 20.4070113,34.4256147 L10.4472843,5.4465898 C10.4472843,3.76188733 11.8923701,2.39616613 13.6749736,2.39616613 L50.7934004,2.39616613 Z"
          id="path-2"
        ></path>
        <path
          d="M40.3798367,0 C42.1639301,0 43.6102236,1.45900816 43.6102236,3.25878594 L43.6102236,34.2172524 C43.6102236,36.0170302 42.1639301,37.4760383 40.3798367,37.4760383 L3.23038694,37.4760383 C1.4462935,37.4760383 0,36.0170302 0,34.2172524 L0,3.25878594 C0,1.45900816 1.4462935,0 3.23038694,0 L40.3798367,0 Z"
          id="path-3"
        ></path>
        <linearGradient x1="50%" y1="1.22994088%" x2="50%" y2="100%" id="linearGradient-5">
          <stop stopColor="#0AB6C9" offset="0%"></stop>
          <stop stopColor="#0AABE4" offset="100%"></stop>
        </linearGradient>
        <rect
          id="path-6"
          x="33.7380192"
          y="9.87220447"
          width="6.23003195"
          height="6.32587859"
          rx="0.368051118"
        ></rect>
        <rect
          id="path-7"
          x="23.8658147"
          y="9.87220447"
          width="6.23003195"
          height="6.32587859"
          rx="0.368051118"
        ></rect>
        <rect
          id="path-8"
          x="23.6741214"
          y="18.6900958"
          width="6.23003195"
          height="6.32587859"
          rx="0.368051118"
        ></rect>
        <rect
          id="path-9"
          x="13.7060703"
          y="9.87220447"
          width="6.23003195"
          height="6.32587859"
          rx="0.368051118"
        ></rect>
        <rect
          id="path-10"
          x="33.7380192"
          y="18.5942492"
          width="6.23003195"
          height="6.32587859"
          rx="0.368051118"
        ></rect>
        <rect
          id="path-11"
          x="13.7060703"
          y="18.5942492"
          width="6.23003195"
          height="6.32587859"
          rx="0.368051118"
        ></rect>
        <rect
          id="path-12"
          x="3.83386581"
          y="18.5942492"
          width="6.23003195"
          height="6.32587859"
          rx="0.368051118"
        ></rect>
        <rect
          id="path-13"
          x="3.83386581"
          y="9.87220447"
          width="6.23003195"
          height="6.32587859"
          rx="0.368051118"
        ></rect>
        <rect
          id="path-14"
          x="23.8658147"
          y="27.5079872"
          width="6.23003195"
          height="6.32587859"
          rx="0.368051118"
        ></rect>
        <rect
          id="path-15"
          x="13.7060703"
          y="27.5079872"
          width="6.23003195"
          height="6.32587859"
          rx="0.368051118"
        ></rect>
        <rect
          id="path-16"
          x="3.83386581"
          y="27.5079872"
          width="6.23003195"
          height="6.32587859"
          rx="0.368051118"
        ></rect>
        <linearGradient x1="50%" y1="1.22994088%" x2="50%" y2="100%" id="linearGradient-17">
          <stop stopColor="#0AB6C9" offset="0%"></stop>
          <stop stopColor="#0AABE4" offset="100%"></stop>
        </linearGradient>
        <path
          d="M50.8271211,2.39616613 C52.6112145,2.39616613 54.057508,3.81938684 54.057508,5.57501855 L54.057508,8.65132734 C54.057508,10.406959 10.4472843,10.406959 10.4472843,8.65132734 L10.4472843,5.57501855 C10.4472843,3.81938684 11.8935778,2.39616613 13.6776713,2.39616613 L50.8271211,2.39616613 Z"
          id="path-18"
        ></path>
        <linearGradient x1="50%" y1="1.22994088%" x2="50%" y2="100%" id="linearGradient-19">
          <stop stopColor="#0AB6C9" offset="0%"></stop>
          <stop stopColor="#0AABE4" offset="100%"></stop>
        </linearGradient>
        <path
          d="M22.6563509,0 C14.770582,0 8.34907428,6.42150776 8.34907428,14.3072766 C8.34907428,17.7331983 9.55066232,20.8740933 11.5682115,23.3387449 C6.60626618,27.1391153 2.80589584,30.9394856 0.167100486,34.7398559 C-0.546926492,35.7681914 1.19543599,37.5105539 2.22377149,36.7965269 C6.02414183,34.1577316 9.82451217,30.3573612 13.6248825,25.3954159 C16.0895341,27.4129651 19.2304291,28.6145531 22.6563509,28.6145531 C30.5421197,28.6145531 36.9636274,22.1930454 36.9636274,14.3072766 C36.9636274,6.42150776 30.5421197,0 22.6563509,0 Z M22.6563509,2.86145531 C28.994028,2.86145531 34.1021721,7.96960087 34.1021721,14.3072766 C34.1021721,20.6449537 28.994028,25.7530978 22.6563509,25.7530978 C16.3186751,25.7530978 11.2105296,20.6449537 11.2105296,14.3072766 C11.2105296,7.96960087 16.3186751,2.86145531 22.6563509,2.86145531 Z"
          id="path-20"
        ></path>
      </defs>
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Appointments" transform="translate(-238.000000, -193.000000)">
          <g id="Group-15" transform="translate(239.000000, 194.000000)">
            <g id="Group-13" transform="translate(0.000000, 0.805112)">
              <g id="Illustration/DigitalScheduling" transform="translate(1.000000, 0.000000)">
                <g id="Group-3">
                  <g id="Rectangle-Copy-13">
                    <use fill="#FFFFFF" xlinkHref="#path-2"></use>
                    <use
                      stroke="#32333C"
                      strokeWidth="1"
                      fillOpacity="0.45"
                      fill="url(#linearGradient-1)"
                      xlinkHref="#path-2"
                    ></use>
                  </g>
                  <line
                    x1="42.3642173"
                    y1="38.2907348"
                    x2="60"
                    y2="38.2907348"
                    id="Line-2-Copy-4"
                    stroke="#32333C"
                    strokeLinecap="round"
                  ></line>
                  <line
                    x1="-1.9027482e-17"
                    y1="38.3865815"
                    x2="17.6357827"
                    y2="38.3865815"
                    id="Line-2-Copy-3"
                    stroke="#32333C"
                    strokeLinecap="round"
                  ></line>
                  <g id="Path-Copy" transform="translate(10.447284, 2.587859)">
                    <mask id="mask-4" fill="white">
                      <use xlinkHref="#path-3"></use>
                    </mask>
                    <use id="Mask" stroke="#32333C" fill="#FFFFFF" xlinkHref="#path-3"></use>
                    <g id="Rectangle" mask="url(#mask-4)">
                      <use fill="#FFFFFF" xlinkHref="#path-6"></use>
                      <use
                        stroke="#32333C"
                        strokeWidth="1"
                        fillOpacity="0.15"
                        fill="url(#linearGradient-5)"
                        xlinkHref="#path-6"
                      ></use>
                    </g>
                    <g id="Rectangle-Copy-2" mask="url(#mask-4)">
                      <use fill="#FFFFFF" xlinkHref="#path-7"></use>
                      <use
                        stroke="#32333C"
                        strokeWidth="1"
                        fillOpacity="0.15"
                        fill="url(#linearGradient-5)"
                        xlinkHref="#path-7"
                      ></use>
                    </g>
                    <g id="Rectangle-Copy-14" mask="url(#mask-4)">
                      <use fill="#FFFFFF" xlinkHref="#path-8"></use>
                      <use
                        stroke="#32333C"
                        strokeWidth="1"
                        fillOpacity="0.15"
                        fill="url(#linearGradient-5)"
                        xlinkHref="#path-8"
                      ></use>
                    </g>
                    <g id="Rectangle-Copy-4" mask="url(#mask-4)">
                      <use fill="#FFFFFF" xlinkHref="#path-9"></use>
                      <use
                        stroke="#32333C"
                        strokeWidth="1"
                        fillOpacity="0.15"
                        fill="url(#linearGradient-5)"
                        xlinkHref="#path-9"
                      ></use>
                    </g>
                    <g id="Rectangle-Copy-8" mask="url(#mask-4)">
                      <use fill="#FFFFFF" xlinkHref="#path-10"></use>
                      <use
                        stroke="#32333C"
                        strokeWidth="1"
                        fillOpacity="0.15"
                        fill="url(#linearGradient-5)"
                        xlinkHref="#path-10"
                      ></use>
                    </g>
                    <g id="Rectangle-Copy-6" mask="url(#mask-4)">
                      <use fill="#FFFFFF" xlinkHref="#path-11"></use>
                      <use
                        stroke="#32333C"
                        strokeWidth="1"
                        fillOpacity="0.15"
                        fill="url(#linearGradient-5)"
                        xlinkHref="#path-11"
                      ></use>
                    </g>
                    <g id="Rectangle-Copy-5" mask="url(#mask-4)">
                      <use fill="#FFFFFF" xlinkHref="#path-12"></use>
                      <use
                        stroke="#32333C"
                        strokeWidth="1"
                        fillOpacity="0.15"
                        fill="url(#linearGradient-5)"
                        xlinkHref="#path-12"
                      ></use>
                    </g>
                    <g id="Rectangle-Copy-7" mask="url(#mask-4)">
                      <use fill="#FFFFFF" xlinkHref="#path-13"></use>
                      <use
                        stroke="#32333C"
                        strokeWidth="1"
                        fillOpacity="0.15"
                        fill="url(#linearGradient-5)"
                        xlinkHref="#path-13"
                      ></use>
                    </g>
                    <line
                      x1="5.84664537"
                      y1="13.2268371"
                      x2="8.05111821"
                      y2="13.2268371"
                      id="Line-3-Copy"
                      stroke="#002E5B"
                      strokeLinecap="round"
                      mask="url(#mask-4)"
                    ></line>
                    <g id="Rectangle-Copy-11" mask="url(#mask-4)">
                      <use fill="#FFFFFF" xlinkHref="#path-14"></use>
                      <use
                        stroke="#32333C"
                        strokeWidth="1"
                        fillOpacity="0.15"
                        fill="url(#linearGradient-5)"
                        xlinkHref="#path-14"
                      ></use>
                    </g>
                    <g id="Rectangle-Copy-10" mask="url(#mask-4)">
                      <use fill="#FFFFFF" xlinkHref="#path-15"></use>
                      <use
                        stroke="#32333C"
                        strokeWidth="1"
                        fillOpacity="0.15"
                        fill="url(#linearGradient-5)"
                        xlinkHref="#path-15"
                      ></use>
                    </g>
                    <line
                      x1="15.7188498"
                      y1="30.4792332"
                      x2="17.9233227"
                      y2="30.4792332"
                      id="Line-3-Copy-9"
                      stroke="#002E5B"
                      strokeLinecap="round"
                      mask="url(#mask-4)"
                    ></line>
                    <line
                      x1="25.686901"
                      y1="21.6613419"
                      x2="27.8913738"
                      y2="21.6613419"
                      id="Line-3-Copy-5"
                      stroke="#002E5B"
                      strokeLinecap="round"
                      mask="url(#mask-4)"
                    ></line>
                    <g id="Rectangle-Copy-9" mask="url(#mask-4)">
                      <use fill="#FFFFFF" xlinkHref="#path-16"></use>
                      <use
                        stroke="#32333C"
                        strokeWidth="1"
                        fillOpacity="0.15"
                        fill="url(#linearGradient-5)"
                        xlinkHref="#path-16"
                      ></use>
                    </g>
                  </g>
                  <g id="Path">
                    <use fill="#FFFFFF" xlinkHref="#path-18"></use>
                    <use
                      stroke="#32333C"
                      strokeWidth="1"
                      fillOpacity="0.15"
                      fill="url(#linearGradient-17)"
                      xlinkHref="#path-18"
                    ></use>
                  </g>
                  <line
                    x1="46.1980831"
                    y1="24.2492013"
                    x2="48.4025559"
                    y2="24.2492013"
                    id="Line-3-Copy-7"
                    stroke="#002E5B"
                    strokeLinecap="round"
                  ></line>
                  <line
                    x1="21.6421725"
                    y1="4.98402556"
                    x2="21.6421725"
                    y2="-7.53142667e-15"
                    id="Line"
                    stroke="#32333C"
                    strokeLinecap="round"
                  ></line>
                  <line
                    x1="42.9201278"
                    y1="4.98402556"
                    x2="42.9201278"
                    y2="-7.53142667e-15"
                    id="Line-Copy"
                    stroke="#32333C"
                    strokeLinecap="round"
                  ></line>
                  <line
                    x1="14.1017558"
                    y1="48.7124601"
                    x2="38.3263592"
                    y2="48.7124601"
                    id="Line-2"
                    stroke="#32333C"
                    strokeLinecap="round"
                  ></line>
                  <line
                    x1="36.7092652"
                    y1="44.5079872"
                    x2="54.3450479"
                    y2="44.5079872"
                    id="Line-2-Copy"
                    stroke="#32333C"
                    strokeLinecap="round"
                  ></line>
                </g>
              </g>
              <g id="Group" transform="translate(0.191693, 13.565495)">
                <g id="search-solid" transform="translate(0.808307, 0.629393)">
                  <g id="Shape">
                    <use fill="#FFFFFF" xlinkHref="#path-20"></use>
                    <path
                      stroke="#32333C"
                      strokeWidth="1"
                      d="M22.6563509,-0.5 C26.7370282,-0.5 30.4390026,1.16121832 33.1207058,3.84292159 C35.8024091,6.52462486 37.4636274,10.2265992 37.4636274,14.3072766 C37.4636274,18.387954 35.8024091,22.0899283 33.1207058,24.7716316 C30.4390026,27.4533348 26.7370282,29.1145531 22.6563509,29.1145531 C19.2946433,29.1145531 16.1981634,27.9966271 13.7112939,26.102321 C9.97775195,30.9151419 6.24210683,34.6150999 2.50894383,37.2072297 C2.14936279,37.4569056 1.73176102,37.515518 1.31318053,37.422193 C0.912198249,37.3327917 0.509845808,37.0964799 0.188496669,36.7751308 C-0.132852471,36.4537816 -0.369164284,36.0514292 -0.458565615,35.6504469 C-0.551890569,35.2318664 -0.493278126,34.8142646 -0.243602253,34.4546836 C2.34869014,30.7212864 6.04894975,26.9854068 10.862064,23.2513742 C8.96685635,20.7650653 7.84907428,17.6687677 7.84907428,14.3072766 C7.84907428,10.2265992 9.5102926,6.52462486 12.1919959,3.84292159 C14.8736991,1.16121832 18.5756735,-0.5 22.6563509,-0.5 L22.6563509,-0.5 Z M22.6563509,3.36145531 C19.6259407,3.36145531 16.8892863,4.58236985 14.9103652,6.56129095 C12.9314441,8.54021204 11.7105296,11.2768664 11.7105296,14.3072766 C11.7105296,17.3376872 12.931444,20.0743415 14.9103649,22.0532624 C16.889286,24.0321835 19.6259405,25.2530978 22.6563509,25.2530978 C25.6867617,25.2530978 28.4234161,24.0321837 30.402337,22.0532627 C32.3812579,20.0743418 33.6021721,17.3376874 33.6021721,14.3072766 C33.6021721,11.2768663 32.3812578,8.54021172 30.4023367,6.5612906 C28.4234158,4.5823697 25.6867615,3.36145531 22.6563509,3.36145531 Z"
                      fillOpacity="0.55"
                      fill="url(#linearGradient-19)"
                    ></path>
                  </g>
                </g>
                <path
                  d="M23.3083067,4.12939297 C20.2628969,4.12939297 17.5126978,5.35635235 15.523982,7.34506821 C13.5352661,9.33378407 12.3083067,12.0839832 12.3083067,15.129393 C12.3083067,18.1748033 13.5352659,20.9250023 15.5239816,22.9137179 C17.5126975,24.9024338 20.2628967,26.129393 23.3083067,26.129393 C26.3537172,26.129393 29.1039163,24.902434 31.092632,22.9137183 C33.0813477,20.9250026 34.3083067,18.1748035 34.3083067,15.129393 C34.3083067,12.083983 33.0813475,9.33378375 31.0926317,7.34506786 C29.103916,5.3563522 26.353717,4.12939297 23.3083067,4.12939297 Z"
                  id="Path"
                  stroke="#32333C"
                  fillOpacity="0.25"
                  fill="url(#linearGradient-19)"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
  /* eslint-enable */
}

export default SvgNoAtlasAppointments;
