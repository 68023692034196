/**
 * Created by moran on 11/2/16.
 */
import React from 'react';

export default function SvgLocation() {
  /* eslint-disable */
  return (
    <svg aria-hidden="true" focusable="false" className="svg-icon svg-icon-location-pin" width="22px" height="22px" viewBox="0 0 22 22" version="1.1">
      <defs></defs>
      <g id="Latest" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="03-Calendar---Pending-Details" transform="translate(-290.000000, -365.000000)">
          <g id="Modal" transform="translate(260.000000, 91.000000)">
            <g id="ico-location-22x22" transform="translate(30.000000, 274.000000)" >
              <path d="M11,1.5 C5.762,1.5 1.5,5.762 1.5,11 C1.5,16.238 5.762,20.5 11,20.5 C16.238,20.5 20.5,16.238 20.5,11 C20.5,5.762 16.238,1.5 11,1.5" id="Fill-1" fill="#FFFFFF"></path>
              <path d="M11,0 C4.925,0 0,4.925 0,11 C0,17.075 4.925,22 11,22 C17.075,22 22,17.075 22,11 C22,4.925 17.075,0 11,0 L11,0 Z M11,20.5 C5.762,20.5 1.5,16.238 1.5,11 C1.5,5.762 5.762,1.5 11,1.5 C16.238,1.5 20.5,5.762 20.5,11 C20.5,16.238 16.238,20.5 11,20.5 L11,20.5 Z" id="Fill-2" fill="#898989"></path>
              <path d="M11,4.25 C8.238,4.25 6,6.488 6,9.25 C6,13 11,19 11,19 C11,19 16,13 16,9.25 C16,6.488 13.763,4.25 11,4.25 L11,4.25 Z M11,11.75 C9.62,11.75 8.5,10.63 8.5,9.25 C8.5,7.87 9.62,6.75 11,6.75 C12.38,6.75 13.5,7.87 13.5,9.25 C13.5,10.63 12.38,11.75 11,11.75 L11,11.75 Z" id="Fill-3" fill="#08ACE4"></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
  /* eslint-enable */
}
