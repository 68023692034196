import React from 'react';
import PropTypes from 'prop-types';
import { isUndefined } from 'lodash';
import { withRouter } from 'react-router';
import { FormattedMessage, defineMessages } from 'react-intl';

import {
  compose,
  setPropTypes,
  withStateHandlers,
  withPropsOnChange,
  withHandlers,
} from 'recompose';
import SvgHelpStars from '../../assets/svg/helpStars';
import { BkmdModal, ModalHeader } from '../../components/bkmdModal/index';
import { withStateFetchersOnMount } from '../../api/injectApi/withStateFetchersOnMount';
import Api from '../../api/index';

const messages = defineMessages({
  title: {
    defaultMessage: 'Your Appointment',
    id: 'members.alreadyBookedModal.title',
  },
  subtitle: {
    defaultMessage: "Good news, you're already booked",
    id: 'members.alreadyBookedModal.subtitle',
  },
  text: {
    defaultMessage: `You have an upcoming appointment booked at Sanitas.
      To cancel or reschedule please check out your appointment details.`,
    id: 'members.alreadyBookedModal.text',
  },
  buttonText: {
    defaultMessage: 'View appointment details',
    id: 'members.alreadyBookedModal.buttonText',
  },
});

function AlreadyBookedModal({ onClick, handleClose, ...rest }) {
  return (
    <BkmdModal {...rest} className="fixed-header">
      <ModalHeader onClose={handleClose} title={messages.title} />
      <div className="dialog-body">
        <div className="row">
          <div className="col-xs-12 text-center">
            <SvgHelpStars />
          </div>
        </div>
        <div>
          <div className="row">
            <div className="col-xs-12 activation-modal-subtitle">
              <span className="text-18 text-semibold text-dark">
                <FormattedMessage {...messages.subtitle} />
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 text-16">
              <FormattedMessage {...messages.text} />
            </div>
          </div>
        </div>
        <button onClick={onClick} className="btn btn-big btn-block btn-outline margin-top-30">
          <FormattedMessage {...messages.buttonText} />
        </button>
      </div>
    </BkmdModal>
  );
}

AlreadyBookedModal.propTypes = {
  onClick: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
};

const withAlreadyBookedModal = Component =>
  class WrappedComponent extends React.PureComponent {
    static propTypes = {
      alreadyBooked: PropTypes.bool.isRequired,
      redirectToAppointment: PropTypes.func.isRequired,
      handleClose: PropTypes.func.isRequired,
    };

    render() {
      const { alreadyBooked, redirectToAppointment, handleClose, ...rest } = this.props;
      return (
        <div>
          <Component {...rest} />
          <AlreadyBookedModal
            isOpen={alreadyBooked}
            handleClose={handleClose}
            onClick={redirectToAppointment}
          />
        </div>
      );
    }
  };

const enhanced = Component =>
  compose(
    withRouter,
    setPropTypes({
      token: PropTypes.string.isRequired,
    }),
    withStateFetchersOnMount({
      getMemberLatestBookedAppointment: {
        handler: () => () => Api.appointmentApi.getMemberLatestBookedAppointment(),
        resultPropName: 'previouslyBookedAppointment',
        isReady: ({ previouslyBookedAppointment }) => !isUndefined(previouslyBookedAppointment),
      },
    }),
    withStateHandlers(
      { bookedAppointmentId: undefined, bookingDisabled: false, alreadyBooked: false },
      {
        onBookingSuccess: () => ({ id: bookedAppointmentId }) => ({
          bookedAppointmentId,
          bookingDisabled: true,
        }),
        onBookDisabledClick: () => () => ({
          alreadyBooked: true, // todo: rename
        }),
        handleClose: () => () => ({
          alreadyBooked: false,
        }),
      },
    ),
    withPropsOnChange(
      ['previouslyBookedAppointment', 'bookingDisabled'],
      ({ previouslyBookedAppointment, bookingDisabled }) => ({
        bookingDisabled: bookingDisabled || !!previouslyBookedAppointment.id,
      }),
    ),
    withHandlers({
      redirectToAppointment: ({
        previouslyBookedAppointment,
        bookedAppointmentId,
        router,
      }) => () => {
        router.push(`/appointment/${previouslyBookedAppointment.id || bookedAppointmentId}`);
      },
    }),
    withAlreadyBookedModal,
  )(Component);

export default enhanced;
