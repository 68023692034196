import Introduction from '../steps/introduction';
import FamilyCancerHistory from '../steps/familyCancerHistory';
import ScreenedWhitin10Years from '../steps/screenedWithin10Years';
import OrderAKit from '../steps/orderAKit';
import OrderAKitAddressForm from '../steps/orderAKitAddressForm';
import OrderAKitEmailForm from '../steps/orderAKitEmailForm';
import OrderAKitReview from '../steps/orderAKitReview';
import ScreenType from '../steps/screenType';
import SetAReminder from '../steps/setARemindar';
import SetAReminderConfirmation from '../steps/setARemindarConfirmation';
import YoullNeedToSeeADoctor from '../steps/youllNeedToSeeADoctor';

export const name = 'colorectalFlow';

export const steps = {
  INTRODUCTION: 'introduction',
  FAMILY_CANCER_HISTORY: 'familyCancerHistory',
  SCREENED_WITHIN_10_YEARS: 'screenedWithin10Years',
  SCREEN_TYPE: 'screenType',
  ORDER_A_KIT: 'orderAKit',
  ORDER_A_KIT_ADDRESS_FORM: 'orderAKitAddressForm',
  ORDER_A_KIT_EMAIL_FORM: 'orderAKitEmailForm',
  ORDER_A_KIT_REVIEW: 'orderAKitReview',
  SET_A_REMINDER: 'setAReminder',
  SET_A_REMINDER_CONFIRMATION: 'setAReminderConfirmation',
  YOULL_NEED_TO_SEE_A_DOCTOR: 'haveFamilyCancerHistory',
};

export const flow = {
  [steps.INTRODUCTION]: {
    component: Introduction,
    transitions: {
      NEXT: steps.FAMILY_CANCER_HISTORY,
    },
  },
  [steps.FAMILY_CANCER_HISTORY]: {
    component: FamilyCancerHistory,
    mapPayloadToFlowData: answer => ({ haveFamilyCancerHistory: answer }),
    transitions: {
      PREV: steps.INTRODUCTION,
      NEXT: ({ data: { haveFamilyCancerHistory } }) =>
        haveFamilyCancerHistory === 'YES'
          ? steps.YOULL_NEED_TO_SEE_A_DOCTOR
          : steps.SCREENED_WITHIN_10_YEARS,
    },
  },
  [steps.SCREENED_WITHIN_10_YEARS]: {
    component: ScreenedWhitin10Years,
    mapPayloadToFlowData: answer => ({ screenedWithin10Years: answer }),
    transitions: {
      PREV: steps.FAMILY_CANCER_HISTORY,
      NEXT: ({ data: { screenedWithin10Years } }) =>
        screenedWithin10Years === 'YES' ? steps.SCREEN_TYPE : steps.ORDER_A_KIT,
    },
  },
  [steps.ORDER_A_KIT]: {
    component: OrderAKit,
    transitions: {
      NEXT: steps.ORDER_A_KIT_ADDRESS_FORM,
      PREV: steps.SCREENED_WITHIN_10_YEARS,
    },
  },
  [steps.ORDER_A_KIT_ADDRESS_FORM]: {
    component: OrderAKitAddressForm,
    mapPayloadToFlowData: payload => ({ address: payload }),
    mapFlowDataToProps: ({ address }) => ({ initialValues: address }),
    transitions: {
      NEXT: steps.ORDER_A_KIT_EMAIL_FORM,
      PREV: steps.ORDER_A_KIT,
    },
  },
  [steps.ORDER_A_KIT_EMAIL_FORM]: {
    component: OrderAKitEmailForm,
    mapPayloadToFlowData: payload => ({ email: payload }),
    mapFlowDataToProps: ({ email }) => ({ initialValues: { email } }),
    transitions: {
      NEXT: steps.ORDER_A_KIT_REVIEW,
      PREV: steps.ORDER_A_KIT_ADDRESS_FORM,
    },
  },
  [steps.ORDER_A_KIT_REVIEW]: {
    component: OrderAKitReview,
    mapFlowDataToProps: ({ address }) => ({ address }),
    transitions: {
      PREV: steps.ORDER_A_KIT_EMAIL_FORM,
    },
  },
  [steps.SCREEN_TYPE]: {
    component: ScreenType,
    mapPayloadToFlowData: payload => ({ screenType: payload }),
    transitions: {
      PREV: steps.SCREENED_WITHIN_10_YEARS,
      NEXT: steps.SET_A_REMINDER,
    },
  },
  [steps.SET_A_REMINDER]: {
    component: SetAReminder,
    mapPayloadToFlowData: payload => ({ reminderData: payload }),
    mapFlowDataToProps: ({ reminderData }) => ({ initialValues: reminderData }),
    transitions: {
      NEXT: steps.SET_A_REMINDER_CONFIRMATION,
      PREV: steps.SCREEN_TYPE,
    },
  },
  [steps.SET_A_REMINDER_CONFIRMATION]: {
    component: SetAReminderConfirmation,
    transitions: {
      PREV: steps.SET_A_REMINDER,
    },
  },
  [steps.YOULL_NEED_TO_SEE_A_DOCTOR]: {
    component: YoullNeedToSeeADoctor,
    transitions: {
      PREV: steps.FAMILY_CANCER_HISTORY,
    },
  },
};

export const initialStepName = steps.INTRODUCTION;
