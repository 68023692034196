import { autobind } from 'core-decorators';
import BkmdApi from './bkmdApi';
import ServicesApi from './services';

const SERVICE_NAME = 'vimGuideProfile';

@autobind
export default class VimGuideProfileApi extends BkmdApi {
  constructor(fetch) {
    super(fetch, '/vimGuideProfile');
    this.servicesApi = new ServicesApi(fetch);
  }

  getByUserId() {
    return this.get('');
  }

  createProfile(profile, domains) {
    return this.servicesApi
      .callSecure(SERVICE_NAME, 'createProfile', { profile, domains })
      .then(res => res.data);
  }

  updateProfile(profile) {
    return this.servicesApi
      .callSecure(SERVICE_NAME, 'updateProfile', { profile })
      .then(res => res.data);
  }

  getAllVimGuideProfiles(skip, limit, search, sort, sortAscending) {
    return this.servicesApi
      .callSecure(SERVICE_NAME, 'getAll', {
        limit,
        skip,
        search,
        sort,
        sortAscending,
      })
      .then(res => res.data);
  }

  getById(id) {
    return this.servicesApi.callSecure(SERVICE_NAME, 'getById', { id }).then(res => res.data);
  }

  getByUserIdPublic(id) {
    return this.servicesApi.call(SERVICE_NAME, 'getByUserIdPublic', { id }).then(res => res.data);
  }
}
