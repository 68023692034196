import { defineMessages } from 'react-intl';

const attemptsMessages = defineMessages({
  maxAttemptsErrorMessage: {
    defaultMessage:
      "You've reached the maximum attempts for resetting your password, " +
      'please contact our customer service for unlocking your account.',
    id: 'members.auth.resetPassword.error.maxAttemptsErrorMessage',
  },
  userLockedErrorMessage: {
    defaultMessage:
      'Your account is locked, ' +
      'please contact our customer service for unlocking your account.',
    id: 'members.auth.resetPassword.error.userLockedErrorMessage',
  },
});

export default attemptsMessages;
