/**
 * Created by chenrozenes on 29/03/2017.
 */
import { withProps } from 'recompose';
import FieldWrapper from '../fieldWrapper';
import CleanCheckbox from '../../cleanCheckbox';
import CleanRadioGroup from '../../cleanRadioGroup';
import _ReduxFormCleanSelect from '../reduxFormCleanSelect';
import _ReduxFormCleanSwitch from '../reduxFormCleanSwitch';
import CleanTimeSelect from '../../cleanTimeSelect';
import DateTimeComponent from '../../dateTimeComponent';
import CleanPhoneNumber from '../../cleanPhoneNumber';
import CleanRestrictedInput from '../../cleanRestrictedInput';
import CleanGeoPoint from '../../cleanGeoPoint';
import CleanZipInput from '../../cleanZipInput';
import Address from '../../address';
import StateSelect from '../../stateSelect/stateSelect';
import LanguagesTags from '../../../languages/languagesTags';
import WeekdaysOpeningHours from '../../../openHours/weekdaysOpeningHours';
import CleanJSONListDisplay from '../../cleanJSONListDisplay';
import CleanTextArea from '../../cleanTextArea';
import GenderSelect from '../../genderSelect';
import ReduxEnumSelect from '../../reduxEnumSelect';
import IntlSelect from '../../intlSelect';
import ItemList from '../../itemList/itemList';
import _ReduxFormBkmdGrid from '../reduxFormBkmdGrid';
import LocationSelectByNpi from '../../../npi/stringifiedLocationSelectByNpi';
import CleanTagList from '../../cleanTagList';
import CleanJSONInput from '../../cleanJSONInput';
import SelectInsurer from '../../../selectInsurer/selectInsurer';
import CleanTimezoneSelect from '../../cleanTimezoneSelect';
import CleanColorInput from '../../cleanColorInput';
import CleanInputWithPicture from '../../cleanInputWithPicture';
import NpiTypeahead from '../../../npi/npiTypeaheadWrapper';
import CptCodeSelect from '../../../molecules/codeInput/CptCodeSelect';
import IcdCodeSelect from '../../../molecules/codeInput/IcdCodeSelect';
import CleanChipInput from '../../cleanChipInput';

export { ReduxFormCleanInput } from './reduxFormCleanInput';
export { ReduxFormCleanAlphabet } from './reduxFormCleanAlphabet';
export { ReduxFormCleanAlphanum } from './reduxFormCleanAlphanum';
export { ReduxFormCleanDateInput } from './reduxFormCleanDateInput';
export { ReduxFormCleanPasswordInput } from './reduxFormCleanPasswordInput';
export { ReduxFormCleanRepeatPasswordInput } from './ReduxFormCleanRepeatPasswordInput';
export const ReduxFormCleanChipInput = FieldWrapper(CleanChipInput);
export const ReduxFormCleanColorInput = FieldWrapper(CleanColorInput);
export const ReduxFormCleanInputWithPicture = FieldWrapper(CleanInputWithPicture);
export const ReduxFormCleanCheckbox = FieldWrapper(CleanCheckbox);
export const ReduxFormCleanRadioGroup = FieldWrapper(CleanRadioGroup);
export const ReduxFormCleanSelect = _ReduxFormCleanSelect;
export const ReduxFormCleanSwitch = _ReduxFormCleanSwitch;
export const ReduxFormCleanTimeSelect = FieldWrapper(CleanTimeSelect);
export const ReduxFormDateTime = FieldWrapper(DateTimeComponent);
export const ReduxFormCleanPhoneNumber = FieldWrapper(CleanPhoneNumber);
export const ReduxFormCleanPositiveInteger = FieldWrapper(CleanRestrictedInput('tel', /\D/g));
export const ReduxFormCleanGeoPoint = FieldWrapper(CleanGeoPoint);
export const ReduxFormAddress = FieldWrapper(Address);
export const ReduxFormStateSelect = FieldWrapper(StateSelect);
export const ReduxFormCleanZipInput = FieldWrapper(CleanZipInput);
export const ReduxFormLanguagesTags = FieldWrapper(LanguagesTags);
export const ReduxFormOpenHours = FieldWrapper(WeekdaysOpeningHours);
export const ReduxFormListDisplay = FieldWrapper(CleanJSONListDisplay);
export const ReduxFormCleanTextArea = FieldWrapper(CleanTextArea);
export const ReduxFormGenderSelect = FieldWrapper(GenderSelect);
export const ReduxFormReduxEnumSelect = FieldWrapper(ReduxEnumSelect);
export const ReduxFormIntlSelect = FieldWrapper(IntlSelect);
export const ReduxFormItemList = FieldWrapper(ItemList);
export const ReduxFormLocationSelectByNpi = FieldWrapper(LocationSelectByNpi);
export const ReduxFormTagList = FieldWrapper(CleanTagList);
export const ReduxFormBkmdGrid = _ReduxFormBkmdGrid;
export const ReduxFormJSONInput = FieldWrapper(CleanJSONInput);
export const ReduxCleanSelectInsurer = FieldWrapper(SelectInsurer);
export const ReduxFormCleanTimezoneSelect = FieldWrapper(CleanTimezoneSelect);
export const ReduxFormNpiTypeahead = FieldWrapper(NpiTypeahead);

const reduxFormCodeSelect = withProps(props => ({
  initialSelectedItems: props.input.value,
  onSelectionChange: props.input.onChange,
}));
export const ReduxFormCptCodeSelect = reduxFormCodeSelect(FieldWrapper(CptCodeSelect));
export const ReduxFormIcdCodeSelect = reduxFormCodeSelect(FieldWrapper(IcdCodeSelect));
