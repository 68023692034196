/**
 * Created by guyavarham on 03/04/2017.
 */
import { defineMessages } from 'react-intl';

const messages = defineMessages({
  clinicDistance: {
    defaultMessage: '{clinicDistance} mi',
    id: 'common.providers.clinicDistance'
  },
  getDirections: {
    defaultMessage: 'Get Directions',
    id: 'providers.common.watchVideo'
  },
  watchVideo: {
    defaultMessage: 'Watch Video',
    id: 'common.providers.watchVideo'
  },
});

export default messages;
