import SecureRoutesCreator from '../secure/secureRoutes';
import CollectReviewRoutes from '../../collectReview/routes/collectReviewRoutes';

class SecuredDirectoryRouteStrategy {
  static predicate({ features }) {
    return features.securedDirectory;
  }

  static getRoutes({ features }) {
    return [...SecureRoutesCreator(features), ...CollectReviewRoutes];
  }
}

export default SecuredDirectoryRouteStrategy;
