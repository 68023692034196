import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '../../storybook/src/utils/theme';

function SvgUserCircle({ theme }) {
  /* eslint-disable */
  return (
    <svg aria-hidden="true" focusable="false" width="72px" height="72px" viewBox="0 0 72 72" version="1.1" className="svg-icon svg-user-circle">
      <defs>
        <polygon points="0 53.7469354 0 0 35.9997465 0 71.999493 0 71.999493 53.7469354"></polygon>
      </defs>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-152.000000, -141.000000)">
          <g transform="translate(152.000000, 141.000000)">
            <path d="M10.849893,57.2641352 C17.1412732,64.7095437 26.2507944,68.9575437 36.0002028,68.9575437 C45.7496113,68.9575437 54.8581183,64.7095437 61.1505127,57.2641352 C57.6397521,51.0974873 51.8473014,46.3221634 45.1188507,44.0830648 C44.5925408,43.9086423 44.2051606,43.4624451 44.1037521,42.9178817 C44.0023437,42.3723042 44.2061746,41.8165859 44.6331042,41.4646986 C47.846738,38.8270648 49.6903437,34.9867268 49.6903437,30.9293746 C49.6903437,23.3805296 43.5490479,17.2392338 36.0002028,17.2392338 C28.4503437,17.2392338 22.310062,23.3805296 22.310062,30.9293746 C22.310062,34.9867268 24.1526535,38.8270648 27.3662873,41.4646986 C27.794231,41.8165859 27.9970479,42.3723042 27.8966535,42.9178817 C27.7952451,43.4624451 27.4068507,43.9086423 26.8815549,44.0830648 C20.1531042,46.3221634 14.3606535,51.0974873 10.849893,57.2641352 M36.0002028,71.9997972 C24.9842028,71.9997972 14.7176113,67.0368676 7.83299155,58.3826704 C7.45879437,57.9121352 7.39693521,57.2631211 7.67580845,56.7307268 C11.1561465,50.0783324 16.753893,44.9450366 23.5989634,42.069093 C20.8213859,39.0075718 19.2678085,35.0627831 19.2678085,30.9293746 C19.2678085,21.7042479 26.774062,14.1969803 36.0002028,14.1969803 C45.2253296,14.1969803 52.7325972,21.7042479 52.7325972,30.9293746 C52.7325972,35.0627831 51.1790197,39.0075718 48.4004282,42.069093 C55.2454986,44.9450366 60.8442592,50.0783324 64.3235831,56.7307268 C64.6034704,57.2631211 64.5405972,57.9121352 64.1664,58.3826704 C57.2817803,67.0368676 47.0151887,71.9997972 36.0002028,71.9997972" fill={theme.mainColor}></path>
            <g>
              <g></g>
              <path d="M66.3997183,53.7464789 C66.156338,53.7464789 65.9109296,53.687662 65.6817465,53.5649577 C64.9414648,53.1684507 64.6625915,52.2466479 65.0611268,51.5063662 C67.6095211,46.7543662 68.9572394,41.3929014 68.9572394,36 C68.9572394,17.8265915 54.1729014,3.04225352 35.999493,3.04225352 C17.8270986,3.04225352 3.04174648,17.8265915 3.04174648,36 C3.04174648,41.3929014 4.39047887,46.7543662 6.93887324,51.5063662 C7.33740845,52.2466479 7.05853521,53.1684507 6.31825352,53.5649577 C5.58,53.963493 4.65616901,53.6856338 4.25966197,52.944338 C1.47295775,47.7532394 -0.000507042254,41.8938592 -0.000507042254,36 C-0.000507042254,16.1492958 16.1487887,0 35.999493,0 C55.8512113,0 71.999493,16.1492958 71.999493,36 C71.999493,41.8938592 70.5270423,47.7532394 67.740338,52.944338 C67.4665352,53.4564507 66.9412394,53.7464789 66.3997183,53.7464789" fill={theme.secondaryColor}></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
  /* eslint-enable */
}

SvgUserCircle.propTypes = {
  theme: PropTypes.object.isRequired,
};

export default withTheme(SvgUserCircle);
