/**
 * Created by guyavarham on 20/03/2017.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { autobind } from 'core-decorators';
import { compose } from 'redux';

import { messages } from '../../../../../../messages/searchDoctorResults/messages';
import PrimaryCareModal from './primaryCareModal';
import {
  createToggleState,
  createToggleStatePropTypes,
} from '../../../../../../components/recompose/toggleStateComponent';

const primaryCareBarImg = require("../images/primary-care-bar.png");

@autobind
class PcpBanner extends React.PureComponent {
  static propTypes = {
    showPCPBanner: PropTypes.bool.isRequired,
    onChoosePcpLabel: PropTypes.func.isRequired,
    ...createToggleStatePropTypes('primaryCareModal'),
  };

  render() {
    const {
      primaryCareModalShow,
      primaryCareModalHide,
      primaryCareModalOn,
      showPCPBanner,
      onChoosePcpLabel,
    } = this.props;

    if (!showPCPBanner) return null;

    return (
      <div className="primary-care-bar">
        <img src={primaryCareBarImg} alt="" />
        <span className="care-bar-text text-16 text-dark text-semibold">
          <FormattedMessage {...messages.pcpBannerTitle} />
          &nbsp;
          <button className="btn btn-inline-link blue-link" onClick={primaryCareModalShow}>
            <FormattedMessage {...messages.learnMore} />
          </button>
        </span>

        <PrimaryCareModal
          onChoosePcpLabel={onChoosePcpLabel}
          isOpen={primaryCareModalOn}
          handleClose={primaryCareModalHide}
        />
      </div>
    );
  }
}

export default compose(createToggleState('primaryCareModal'))(PcpBanner);
