import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { compose, withHandlers, withPropsOnChange, withStateHandlers } from 'recompose';
import displayProvider from '../../utils/displayProvider';
import Formatter from '../../utils/formatter/index';
import PointDistance from '../ui/map/pointDistance';
import BookingHorizonChip from '../../storybook/src/components/atoms/BookingHorizonChip/BookingHorizonChip';
import NetworkStatus from '../doctorCard/sections/network/networkStatus';
import ProviderLocationSelector from '../doctorDetails/sections/providerLocationSelector';
import DoctorLogo from '../doctorImage/doctorLogo';
import messages from './messages';
import ClickableDiv from '../clickableElement/clickableDiv';
import { getLocationFromProvider, getProviderLocationId } from '../../utils/providerLocationAccess';
import BookingButton from '../../components/bookingButton/bookingButton';
import './verticlProviderCard.less';
import getProviderPhoneNumber from '../../utils/getProviderPhoneNumber';
import * as BookingType from '../../model/enum/bookingType';

const Distance = ({ distance }) => {
  if (distance >= 0) {
    return (
      <div className="distance">
        <i className="icon-map-pin-e text-16 i-va-fix-2 hidden-xs" />
        <PointDistance distance={distance} message={messages.clinicDistance} />
      </div>
    );
  }
  return null;
};

Distance.propTypes = {
  distance: PropTypes.number,
};
Distance.defaultProps = {
  distance: -1,
};

const stopPropagation = e => e.stopPropagation();

const GetDirectionsLink = ({ directionsUrl, location = {} }) => {
  const { addressLine1, city, state, zip } = location;
  return (
    <a
      target="_blank" // eslint-disable-line react/jsx-no-target-blank
      onClick={stopPropagation}
      href={`${directionsUrl}=${addressLine1} ${city} ${state} ${zip}`}
      aria-labelledby="new-window-0"
      className="font-color-brand-link"
    >
      <FormattedMessage {...messages.getDirections} />
      &nbsp;
      <i className="icon-ext-link text-12" id="new-window-0" aria-label="external-link" />
    </a>
  );
};
GetDirectionsLink.propTypes = {
  location: PropTypes.object.isRequired,
  directionsUrl: PropTypes.string,
};

GetDirectionsLink.defaultProps = {
  directionsUrl: 'http://www.bing.com/maps/?v=2&where1',
};

const GetDirections = connect(state => {
  const mapStrategy = _.get(state, 'config.mapStrategy', 'bingMaps');
  return { directionsUrl: _.get(state, ['config', mapStrategy, 'directionsUrl']) };
})(GetDirectionsLink);

// TODO: Add geo(!) + client location
const VerticalProviderCard = props => {
  if (!props.provider) return <div />;
  const {
    provider,
    onProviderClick,
    chosenTaxonomyCodes,
    currentLocation,
    setCurrentProviderLocationId,
    distances,
    plan,
    hideNetworkStatus,
    onlyShowCurrentLocation,
    renderActions,
    staticLocation,
    onClick,
    showDirection,
    showNextAvailableSlot,
    distanceCustomComponent: DistanceCustomComponent,
    isInNetworkCustomComponent: IsInNetworkCustomComponent,
    bookingSection,
  } = props;
  const {
    npi,
    firstName,
    lastName,
    sources,
    taxonomiesCodes,
    taxonomies,
    suffix,
    locations,
  } = provider;
  const providerDisplayName = displayProvider(firstName, lastName, suffix);
  const taxonomyName = Formatter.truncate(
    // support taxonomiesCodes from elastic and taxonomies from postgres
    Formatter.taxonomy(sources, taxonomiesCodes || taxonomies, chosenTaxonomyCodes),
  );
  // TODO refactor the response of public referral and public share
  const distance = distances
    ? distances[npi][currentLocation.id]
    : _.get(provider, `distances[${currentLocation.id}]`);
  const otherLocations = onlyShowCurrentLocation
    ? []
    : _.reject(locations, location => currentLocation.id === location.id);
  return (
    <ClickableDiv disabled={!onProviderClick} clickHandler={onClick}>
      <div className="card vertical-provider-card">
        <div className="card-body text-center">
          <div className="card-main-content">
            <DoctorLogo provider={provider} />
            <div
              data-id="provider-display-name"
              className="margin-top text-16 text-semibold text-dark"
            >
              {providerDisplayName}
            </div>
            <div className="text-14">{taxonomyName}</div>
            <div className="info-row">
              <div className="detail">
                {DistanceCustomComponent ? (
                  <DistanceCustomComponent {...props} />
                ) : (
                  <Distance distance={distance} />
                )}
              </div>
              {!hideNetworkStatus ? (
                <span className="detail">
                  {IsInNetworkCustomComponent ? (
                    <IsInNetworkCustomComponent {...props} />
                  ) : (
                    <NetworkStatus location={currentLocation} plan={plan} />
                  )}
                </span>
              ) : (
                <span />
              )}
              {showNextAvailableSlot &&
                currentLocation.nextAvailableSlot && (
                  <span data-id="next-available-slot" className="detail">
                    <div className="font-color-brand-secondary text-12">
                      <span>
                        <FormattedMessage {...messages.nextAvailable} />
                      </span>
                      <span className="text-semibold">
                        {Formatter.date(currentLocation.nextAvailableSlot.startTime)}
                      </span>
                    </div>
                  </span>
                )}
              <span className="detail">
                <BookingHorizonChip bookingHorizon={currentLocation.bookingHorizon} shortText />
              </span>
            </div>
            <div className="margin-top text-dark text-semibold text-16">
              <ProviderLocationSelector
                staticLocation={staticLocation}
                pickedLocation={currentLocation}
                otherLocations={otherLocations}
                onLocationChanged={setCurrentProviderLocationId}
              />
            </div>
            <div className="margin-top">
              {currentLocation && showDirection && <GetDirections location={currentLocation} />}
            </div>
            {bookingSection}
          </div>

          {renderActions && renderActions(provider, currentLocation, setCurrentProviderLocationId)}
        </div>
      </div>
    </ClickableDiv>
  );
};

VerticalProviderCard.propTypes = {
  provider: PropTypes.object.isRequired,
  plan: PropTypes.string.isRequired,
  distances: PropTypes.object,
  onProviderClick: PropTypes.func,
  onClick: PropTypes.func.isRequired,
  chosenTaxonomyCodes: PropTypes.array,
  currentLocation: PropTypes.object.isRequired,
  setCurrentProviderLocationId: PropTypes.func.isRequired,
  onlyShowCurrentLocation: PropTypes.bool,
  hideNetworkStatus: PropTypes.bool.isRequired,
  renderActions: PropTypes.func,
  distanceCustomComponent: PropTypes.func,
  isInNetworkCustomComponent: PropTypes.func,
  staticLocation: PropTypes.bool.isRequired,
  showDirection: PropTypes.bool,
  showNextAvailableSlot: PropTypes.bool,
  bookingSection: PropTypes.element.isRequired,
};

VerticalProviderCard.defaultProps = {
  onlyShowCurrentLocation: false,
  renderActions: null,
  onProviderClick: undefined,
  distanceCustomComponent: undefined,
  isInNetworkCustomComponent: undefined,
  distances: undefined,
  chosenTaxonomyCodes: undefined,
  showDirection: true,
  showNextAvailableSlot: false,
};

const getClosestLocationId = function getClosestLocationId(provider, closestLocations) {
  // TODO refactor the response of public referral and share from atlas
  const closestLocation = closestLocations
    ? closestLocations[provider.npi]
    : provider.closestLocation;
  return parseInt(closestLocation, 10);
};
export default compose(
  withStateHandlers(
    ({ closestLocations, provider }) => ({
      currentProviderLocationId:
        _.get(provider, 'selectedLocationId') || getClosestLocationId(provider, closestLocations),
    }),
    {
      setCurrentProviderLocationId: () => value => ({ currentProviderLocationId: value }),
    },
  ),
  withPropsOnChange(
    ['currentProviderLocationId', 'bookingType', 'provider'],
    ({ currentProviderLocationId, provider }) => {
      const currentLocation =
        provider.selectedLocation ||
        _.chain(getProviderLocationId(currentProviderLocationId, provider))
          .thru(selectedLocationId => getLocationFromProvider(selectedLocationId, provider))
          .defaultTo(_.get(provider, 'locations[0]'))
          .value();

      return {
        currentLocation,
        bookingType: currentLocation.bookingType,
      };
    },
  ),
  withHandlers({
    onClick: ({
      onProviderClick,
      provider: { npi },
      currentProviderLocationId,
      chosenTaxonomyCodes,
    }) => () => {
      onProviderClick(npi, currentProviderLocationId, chosenTaxonomyCodes);
    },
    onBookClick: ({ provider, onBookClick, bookingType, currentProviderLocationId }) => e => {
      e.stopPropagation();
      onBookClick(provider, currentProviderLocationId, bookingType);
    },
  }),
  withPropsOnChange(['bookButtonCustomComponent', 'currentLocation'], props => {
    const {
      bookButtonCustomComponent: CustomBookComp,
      showBook,
      currentLocation,
      onBookClick,
      member,
      plan,
      provider,
      bookingType,
      memberContext,
    } = props;

    const phoneNumber = getProviderPhoneNumber(currentLocation, memberContext);
    const BookButtonComponent = CustomBookComp || BookingButton;
    if (!CustomBookComp && (bookingType === BookingType.NONE || !showBook)) {
      return {
        bookingSection: (
          <div className="margin-top-20 margin-bottom-20 text-16 text-dark text-semibold">
            {phoneNumber && (
              <span className="text-15 text-dark">{Formatter.formatLocal(phoneNumber)}</span>
            )}
          </div>
        ),
      };
    }

    return {
      bookingSection: (
        <div className="card-bottom margin-top-20">
          <div className="text-16 text-dark text-semibold margin-bottom-20">
            {phoneNumber && Formatter.formatLocal(phoneNumber)}
          </div>

          <div className="col-xs-6 booking-btn-wrap">
            <BookButtonComponent
              {...props}
              provider={provider}
              enableBooking={showBook}
              pickedLocation={currentLocation}
              onBookAppointmentClicked={onBookClick}
              member={member}
              targetLocationGeo={_.get(currentLocation, 'geo')}
              plan={plan}
            />
          </div>
        </div>
      ),
    };
  }),
)(VerticalProviderCard);
