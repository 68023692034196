/**
 * Created by galgoltzman on 05/09/2017.
 */
import React from 'react';

class SettingsLayout extends React.PureComponent {
  render() {
    const { children } = this.props;
    return (
      <div className="settings-layout">
        <div className="main-content padding-h-30 has-footer">
          <div className="row">
            <div className="col-sm-8 col-sm-offset-2 col-md-6 col-md-offset-3">{children}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default SettingsLayout;
