/**
 * Created by matan on 3/12/17.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import _ from 'lodash';

import MessageShape from './messageShape';

export function formatIfIntl(msg, intl) {
  return _.isString(msg) ? msg : intl.formatMessage(msg);
}

/**
 * Smart wrapper for intl FormattedMessage.
 * Can render either an intl message or a simple string
 */
class IntlString extends React.Component {
  static propTypes = {
    /**
     * CSS class to apply to message
     */
    className: PropTypes.string,
    /**
     * The message to render.
     * Can be either an intl message object or a string
     */
    message: MessageShape,
    html: PropTypes.bool,
  };

  static defaultProps = {
    message: undefined,
    className: '',
    html: false,
  };


  render() {
    const { html, message, className, ...others } = this.props;
    if (_.isNil(message)) return null;

    const renderMsg = () => {
      if (_.isString(this.props.message)) return message;
      if (html) return <FormattedHTMLMessage {...message} {...others} />;
      return <FormattedMessage {...message} {...others} />;
    };

    return (
      <span className={className}>
        {renderMsg()}
      </span>
    );
  }
}

export default IntlString;
