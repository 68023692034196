/* eslint-disable react/no-unused-prop-types */
/**
 * Created by asafdavid on 04/05/2016.
 */
import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import { withHandlers } from 'recompose';

import { loadFromStorage, refreshIfActive } from './actions';
import { routeToWithNext } from '../../utils/routeToWithNext';
import { REACT_ROUTER_2 } from '../../model/enum/routers';

const RENEW_PASSWORD_ROUTE = '/renewExpiredPassword';

/**
 * Implement react router 2 methods
 */
const withRouterMethods = withHandlers({
  redirectToRenewPassword(props) {
    return location => routeToWithNext(props.router, location, RENEW_PASSWORD_ROUTE);
  },
  redirectToLogin(props) {
    return location => routeToWithNext(props.router, location, '/signIn');
  },
  redirectToHome(props) {
    return () => props.router.push('/secure');
  },
});

/**
 * Default HOC that handles check auth and inject router to props
 * Relevant only to react router 2
 */
const router2HOC = compose(
  withRouter,
  withRouterMethods,
);

/**
 * Wraps a component and make sure it accessible only if there's an authenticated user
 * @returns HOC that wraps a component with authentication
 * @param router
 * @param newReactRouterHOC
 */
export default (router = REACT_ROUTER_2, newReactRouterHOC) =>
  function requireAuthentication(Component) {
    class AuthenticatedComponent extends React.Component {
      static propTypes = {
        dispatch: PropTypes.func.isRequired,
        location: PropTypes.object.isRequired,
        isAuthenticated: PropTypes.bool.isRequired,
        expiredToken: PropTypes.string,
        loading: PropTypes.bool,
        user: PropTypes.object,
        router: PropTypes.object,
        redirectToRenewPassword: PropTypes.func.isRequired,
        redirectToHome: PropTypes.func.isRequired,
        redirectToLogin: PropTypes.func.isRequired,
      };

      static defaultProps = {
        user: undefined,
        router: undefined,
        expiredToken: null,
        loading: undefined,
      };

      /**
       * Verifies if the user is really authenticated
       */
      componentWillMount() {
        this.props.dispatch(loadFromStorage());
      }

      /**
       * Refreshes the accessToken every 5 minutes unless there was no activity
       */
      componentDidMount() {
        // wait for next tick, make sure loadFromStorage is updated
        setTimeout(() => {
          this.checkAuth(this.props);
        });

        // handle session refreshes
        this.refreshInterval = setInterval(() => {
          this.props.dispatch(refreshIfActive());
        }, 5 * 60 * 1000); // Every 5 minutes
      }

      /**
       * Validates the new token
       * @param nextProps
       */
      componentWillReceiveProps(nextProps) {
        // If user id changed
        this.checkAuth(nextProps);
      }

      /**
       * Clear the refresh interval
       */
      componentWillUnmount() {
        clearInterval(this.refreshInterval);
      }

      checkAuth(props) {
        const {
          location,
          expiredToken,
          loading,
          isAuthenticated,
          redirectToRenewPassword,
          redirectToHome,
          redirectToLogin,
        } = props;

        if (expiredToken && !loading) {
          if (_.get(location, 'pathname') !== RENEW_PASSWORD_ROUTE) {
            redirectToRenewPassword(location);
          }
          return;
        } else if (!expiredToken && isAuthenticated && !loading) {
          if (!location || _.get(location, 'pathname') === RENEW_PASSWORD_ROUTE) {
            redirectToHome();
          }
        }

        if (!isAuthenticated && !loading) {
          redirectToLogin(location);
        }
      }

      render() {
        return (
          <div>
            {(this.props.isAuthenticated === true && this.props.user) || this.props.expiredToken ? (
              <Component {...this.props} />
            ) : (
              <div />
            )}
          </div>
        );
      }
    }

    const mapStateToProps = state => ({
      accessToken: state.auth.accessToken,
      refreshToken: state.auth.refreshToken,
      expiredToken: state.auth.expiredToken,
      user: state.auth.user,
      reason: state.auth.reason,
      isAuthenticated: state.auth.isAuthenticated,
      loading: state.auth.loading,
    });

    return compose(
      router === REACT_ROUTER_2 ? router2HOC : newReactRouterHOC,
      connect(mapStateToProps)
    )(AuthenticatedComponent);
  };

/* eslint-disable react/no-unused-prop-types */
