import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '../../storybook/src/utils/theme';

function SvgHospital({ theme }) {
  /* eslint-disable */
  return (
    <svg aria-hidden="true" focusable="false" className="svg-icon svg-hospital" width="26px" height="32px" viewBox="0 0 26 32" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-669.000000, -295.000000)">
          <g transform="translate(669.000000, 295.000000)">
            <path d="M25,32 C24.447,32 24,31.552 24,31 L24,5 C24,3.346 22.654,2 21,2 L5,2 C3.346,2 2,3.346 2,5 L2,31 C2,31.552 1.553,32 1,32 C0.447,32 0,31.552 0,31 L0,5 C0,2.243 2.243,0 5,0 L21,0 C23.757,0 26,2.243 26,5 L26,31 C26,31.552 25.553,32 25,32" fill={theme.mainColor}></path>
            <path d="M16,8 L14,8 L14,6 C14,5.448 13.553,5 13,5 C12.447,5 12,5.448 12,6 L12,8 L10,8 C9.447,8 9,8.448 9,9 C9,9.552 9.447,10 10,10 L12,10 L12,12 C12,12.552 12.447,13 13,13 C13.553,13 14,12.552 14,12 L14,10 L16,10 C16.553,10 17,9.552 17,9 C17,8.448 16.553,8 16,8" fill={theme.secondaryColor}></path>
            <path d="M19,26 L7,26 C6.447,26 6,26.448 6,27 L6,31 C6,31.552 6.447,32 7,32 C7.553,32 8,31.552 8,31 L8,28 L12,28 L12,31 C12,31.552 12.447,32 13,32 C13.553,32 14,31.552 14,31 L14,28 L18,28 L18,31 C18,31.552 18.447,32 19,32 C19.553,32 20,31.552 20,31 L20,27 C20,26.448 19.553,26 19,26" fill={theme.secondaryColor}></path>
            <path d="M7,24 C6.447,24 6,23.552 6,23 L6,21 C6,20.448 6.447,20 7,20 C7.553,20 8,20.448 8,21 L8,23 C8,23.552 7.553,24 7,24" fill={theme.mainColor}></path>
            <path d="M13,24 C12.447,24 12,23.552 12,23 L12,21 C12,20.448 12.447,20 13,20 C13.553,20 14,20.448 14,21 L14,23 C14,23.552 13.553,24 13,24" fill={theme.mainColor}></path>
            <path d="M19,24 C18.447,24 18,23.552 18,23 L18,21 C18,20.448 18.447,20 19,20 C19.553,20 20,20.448 20,21 L20,23 C20,23.552 19.553,24 19,24" fill={theme.mainColor}></path>
            <path d="M7,18 C6.447,18 6,17.552 6,17 L6,15 C6,14.448 6.447,14 7,14 C7.553,14 8,14.448 8,15 L8,17 C8,17.552 7.553,18 7,18" fill={theme.mainColor}></path>
            <path d="M13,18 C12.447,18 12,17.552 12,17 L12,15 C12,14.448 12.447,14 13,14 C13.553,14 14,14.448 14,15 L14,17 C14,17.552 13.553,18 13,18" fill={theme.mainColor}></path>
            <path d="M19,18 C18.447,18 18,17.552 18,17 L18,15 C18,14.448 18.447,14 19,14 C19.553,14 20,14.448 20,15 L20,17 C20,17.552 19.553,18 19,18" fill={theme.mainColor}></path>
          </g>
        </g>
      </g>
    </svg>
  );
  /* eslint-enable */
}

SvgHospital.propTypes = {
  theme: PropTypes.object.isRequired,
};

export default withTheme(SvgHospital);
