import PropTypes from 'prop-types';
import _ from 'lodash';
import { compose, withProps, withHandlers, setPropTypes, setDisplayName } from 'recompose';

import { defineMessages } from 'react-intl';
import { FlowShape } from '../../components/wizard/flow/shapes';

import CustomInsurerForm from '../../components/organisms/CustomInsurerForm/CustomInsurerForm';
import SideDrawerContent from '../../components/templates/SideDrawerContent/SideDrawerContent';
import { withTranslatedMessages } from '../../utils/withTranslatedMessages';

const messages = defineMessages({
  title: {
    defaultMessage: 'Type in your insurance',
    id: 'members.directory.booking.selectCustomInsurer.title',
  },
});

const ComposedTemplate = withProps({
  formComponent: CustomInsurerForm,
})(SideDrawerContent);

/**
 * Input: non
 * Output:
 * * Result: NEXT
 * * Returned value: answer
 */
const SelectCustomInsurer = compose(
  setDisplayName('SelectCustomPlan'),
  setPropTypes({
    control: FlowShape.isRequired,
    selectedInsurer: PropTypes.string,
  }),
  withTranslatedMessages(messages),
  withProps(({ selectedInsurer }) => ({
    name: 'selectCustomInsurer',
    initialValues: { id: _.get(selectedInsurer, 'id') },
  })),
  withHandlers({
    onSubmit: ({ control }) => insurer =>
      control.next({ payload: { id: insurer.id, value: insurer.id, label: insurer.id } }),
  }),
)(ComposedTemplate);

export default SelectCustomInsurer;
