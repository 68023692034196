/**
 * Created by anna on 12/04/2017.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import _ from 'lodash';
import classNames from 'classnames';
import './starRating.less';

const STARS_NUM = 5;
const DEFAULT_RATE_VALUE = 0;

@autobind
export default class ReviewStarRate extends React.Component {

  static propTypes = {
    rateValue: PropTypes.number.isRequired,
    onChange: PropTypes.func,
    className: PropTypes.string
  };

  static defaultProps = {
    className: undefined,
    onChange: _.noop,
  };

  renderStarsRate(rating) {
    const stars = [];
    _.times(STARS_NUM, index => {
      let starClassName = 'fa fa-star';
      if (!rating) {
        starClassName = 'fa fa-star empty';
      } else {
        rating--;
      }
      stars.push(
        <button
          className="btn btn-inline-link"
          key={index}
          onClick={() => this.props.onChange(index + 1)}
        >
          <i
            className={starClassName}
          />
        </button>
      );
    });
    return stars;
  }

  render() {
    let { className, rateValue } = this.props;
    if (!rateValue) rateValue = DEFAULT_RATE_VALUE;
    return (
      <div className={classNames('rating', className)}>
        {this.renderStarsRate(rateValue)}
      </div>
    );
  }
}
