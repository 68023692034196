import React from 'react';
import { autobind } from 'core-decorators';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './clickableElement.less';

const ENTER_KEY = 13;
const SPACE_KEY = 32;

// Intended for accessibility purposes
@autobind
class ClickableDiv extends React.PureComponent {
  static propTypes = {
    clickHandler: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    tabIndex: PropTypes.string,
  };

  static defaultProps = {
    disabled: false,
    tabIndex: 0,
  };

  onKeyDown(e) {
    if (e.keyCode === ENTER_KEY || e.keyCode === SPACE_KEY) {
      this.props.clickHandler(e);
    }
  }

  render() {
    const { clickHandler, children, disabled, className, tabIndex, ...otherProps } = this.props;

    return (
      // eslint-disable-next-line jsx-a11y/no-static-element-interactions
      <div
        className={classNames('clickable-element', className)}
        onClick={!disabled && clickHandler}
        onKeyDown={!disabled && this.onKeyDown}
        role={!disabled ? 'button' : undefined}
        tabIndex={tabIndex}
        style={{ cursor: disabled ? 'auto' : 'pointer' }}
        {...otherProps}
      >
        {children}
      </div>
    );
  }
}

export default ClickableDiv;
