import _ from 'lodash';
import { NEXT } from './consts';

function getStep(steps, name) {
  return steps[name] || {};
}

function safeGetFunc(steps, stepName, funcName, fallback = _.noop) {
  const step = getStep(steps, stepName);
  return _.get(step, funcName, fallback);
}

/**
 * Help cheking if we have a transition, could be used to check if we have 'PREV'.
 */
export function hasTransitionHandler(steps, stepName, key) {
  const curr = getStep(steps, stepName);
  return !_.isNil(curr.transitions[key]);
}

/**
 * Evaluate step before the actual render. by default every step returns true,
 * which means , that this step should render. otherwise, we would 'skip' its transition.
 */
export function evaluateStep(steps, stepName, flowState, flowProps) {
  // @mrsufgi :not that stubTrue is here to allow no definitions,
  // we should consider ׳what is the default behaviour here.
  const evaluate = safeGetFunc(steps, stepName, 'evaluate', _.stubTrue);
  return evaluate(flowState, flowProps);
}
/**
 * Apply transition on the current step to reduce the next step
 */
export function reduceStep(
  steps,
  currentStepName,
  transitionType,
  state,
  history,
  popHistory,
  flowProps,
) {
  if (!hasTransitionHandler(steps, currentStepName, transitionType)) {
    return undefined;
  }
  const currentStep = getStep(steps, currentStepName);
  const transition = currentStep.transitions[transitionType];
  let res = transition;
  if (_.isFunction(transition)) {
    // TODO: OH LORD FIX THIS HISTORY THINGY!!
    const tmpHistory = state.history;
    state.history = history;
    res = transition(state);
    state.history = tmpHistory;
  }
  if (popHistory) {
    history.splice(history.length - 1);
  } else {
    history.push(currentStepName);
  }
  // evaluate step render condition and see if you should how you should traverse.
  // TODO: @mrsufgiL: add support for new transition type instead of only applying
  // the same action
  // const newAction = flowTraverse.reduceStepAction(state, action);
  const shouldTraverse = evaluateStep(steps, res, state, flowProps);
  if (!shouldTraverse) {
    res = reduceStep(steps, res, transitionType, state, history, popHistory, flowProps);
  }
  return res;
}

export function reduceInitialStep(steps, flowState, flowProps) {
  const { stepName, history, data } = flowState;
  const shouldTraverse = evaluateStep(steps, stepName, flowState, flowProps);
  let res = flowState;
  if (!shouldTraverse) {
    const newInitialStep = reduceStep(steps, stepName, NEXT, flowState, history, false);
    res = { stepName: newInitialStep, history, data };
  }
  return res;
}

/**
 * Help cheking if we have a transition, could be used to check if we have 'PREV'.
 */
export function hasTransition(
  definitions,
  stepName,
  transitionType,
  flowState,
  flowProps,
  history,
  popHistory,
) {
  const historyClone = [...history];
  const nextStep = reduceStep(
    definitions,
    stepName,
    transitionType,
    flowState,
    historyClone,
    popHistory,
    flowProps,
  );
  return !_.isEmpty(nextStep);
}

/**
 * Map step output into new flow data.
 */
export function mapPayloadToFlowData(steps, stepName, value, currentFlowData) {
  if (value === undefined) return {};

  const mapper = safeGetFunc(steps, stepName, 'mapPayloadToFlowData');
  try {
    return mapper(value, currentFlowData);
  } catch (e) {
    console.error(`The flow step ${stepName} threw an error while running it's mapPayloadToFlowData
handler. This is a critical error, check your flow definition.`);
    return {};
  }
}

/**
 * Map flow data into props used by the step.
 */
export function mapFlowDataToProps(steps, stepName, context, flowProps) {
  const mapper = safeGetFunc(steps, stepName, 'mapFlowDataToProps');
  return mapper(context, flowProps);
}
