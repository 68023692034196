import { autobind } from 'core-decorators';
import BkmdApi from './bkmdApi';
import ServicesApi from './services';
import { GetDomainResourceTypeByName } from '../model/enum/domainResourceType';

const SERVICE_NAME = 'bookmdProvider';

/**
 * Represents the search doctors API
 */
@autobind
export default class ProviderApi extends BkmdApi {
  /**
   * A fetch object that points to the server
   * @param fetch
   */
  constructor(fetch) {
    super(fetch, '/provider');
    this.servicesApi = new ServicesApi(fetch);
  }

  byNpi(npi, domain) {
    const getByNpiPromise = domain
      ? this.get(`/${npi}`, undefined, undefined, domain)
      : this.get(`/${npi}`);
    return getByNpiPromise.then((result) => ({ ...result, taxonomiesCodes: result.taxonomies }));
  }

  byNpiPublicToken(npi) {
    return this.get(`/byPublicToken/${npi}`, {}).then((result) => ({
      ...result,
      taxonomiesCodes: result.taxonomies,
    }));
  }

  byNpiPublic(npi) {
    return this.servicesApi.call(SERVICE_NAME, 'getByNpiPublic', { npi });
  }

  getPdStatus(npi, domain) {
    return this.get(
      `/${npi}/pdStatus`,
      { resourceId: GetDomainResourceTypeByName(domain) },
      undefined,
      domain,
    );
  }

  getAllExistedSpokenLanguages(codes) {
    return this.get('/getAllExistedSpokenLanguages', { codes });
  }

  byNpiForBackoffice(npi) {
    return this.servicesApi.callSecure(SERVICE_NAME, 'getByNpiForBackoffice', { npi });
  }

  getAllProviders(skip, limit, search, sort, sortAscending) {
    return this.servicesApi.callSecure(SERVICE_NAME, 'getAllProviders', {
      skip,
      limit,
      search,
      sort,
      sortAscending,
    });
  }

  getAllProvidersByNpiOrName(skip, limit, search, sort, sortAscending) {
    return this.servicesApi.callSecure(SERVICE_NAME, 'getAllProvidersByNpiOrName', {
      skip,
      limit,
      search,
      sort,
      sortAscending,
    });
  }

  getProviderLocations(npi, skip, limit, search, sort, sortAscending) {
    return this.servicesApi.callSecure(SERVICE_NAME, 'getProviderLocations', {
      npi,
      skip,
      limit,
      search,
      sort,
      sortAscending,
    });
  }

  updateVideo(npi, videoObj) {
    return this.servicesApi.callSecure(SERVICE_NAME, 'updateVideo', { npi, videoObj });
  }

  shareViaEmail(email, npi, providerLocationId, geo) {
    return this.post('/share/email', { email, npi, providerLocationId, geo });
  }

  shareViaSms(phoneNumber, npi, providerLocationId, geo) {
    return this.post('/share/sms', { phoneNumber, npi, providerLocationId, geo });
  }

  getAcceptNewPatientsStatus(npi) {
    return this.servicesApi
      .callSecure(SERVICE_NAME, 'getAcceptNewPatientsStatus', { npi })
      .then(({ data }) => data);
  }

  saveProfilePictures(npiList) {
    return this.servicesApi
      .callSecure(SERVICE_NAME, 'saveProfilePictures', { npiList })
      .then(({ data }) => data);
  }

  getProviderAvailability({ providers, memberToken, plan, npiType }) {
    return this.post('/availability', {
      providers,
      memberToken,
      plan,
      npiType,
    }).then(({ data }) => data);
  }
}
