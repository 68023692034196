/**
 * Created by chenrozenes on 04/05/2016.
 */
import _ from 'lodash';

export const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION';
export const REMOVE_ALL_NOTIFICATIONS = 'REMOVE_ALL_NOTIFICATIONS';
export const DEFAULT_ERROR_TITLE = 'Error';

export function showNotification(params) {
  return {
    type: SHOW_NOTIFICATION,
    payload: params
  };
}

export function removeAllNotifications() {
  return {
    type: REMOVE_ALL_NOTIFICATIONS
  };
}

export function intlNotification(intl, title, message, options) {
  const { titleParams, messageParams, ...other } = options;

  const intlTitle = _.isString(title) ? title : intl.formatMessage(title, titleParams);
  const intlMessage = _.isString(message) ? message : intl.formatMessage(message, messageParams);

  // if the titles value is "error", it will not be displayed
  return showNotification({
    title: intlTitle === DEFAULT_ERROR_TITLE ? '' : intlTitle,
    message: intlMessage,
    ...other
  });
}

export function intlErrorNotification(intl, title, message, params) {
  return intlNotification(intl, title, message, _.extend(params, { level: 'error' }));
}

export function intlInfoNotification(intl, title, message, params) {
  return intlNotification(intl, title, message, _.extend(params, { level: 'success' }));
}
