import { pathsWithQuery } from '../../../../utils/pathsWithQuery';

export default {
  // redirect when share details displayed
  shareDoctorsDetails: (shareToken = ':shareToken', qsParams) =>
    pathsWithQuery(`/doctorsShare/${shareToken}/list`, qsParams),
  // landing page for pages: shareDoctorsDetails
  landing: (shareToken = ':shareToken', qsParams) =>
    pathsWithQuery(`/doctorsShare/${shareToken}/landing`, qsParams),
};
