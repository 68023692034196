import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '../../storybook/src/utils/theme';

function SvgEnvelopeStars2({ theme }) {
  /* eslint-disable */
  return (

    <svg aria-hidden="true" focusable="false" width="72px" height="72px" viewBox="0 0 72 72" version="1.1" className="svg-icon svg-envelope-stars-2">
      <defs>
        <polygon id="path-1" points="0 0 9.6816 0 9.6816 9.6817 0 9.6817"></polygon>
        <polygon id="path-3" points="0.0001 0.5453 4.4541 0.5453 4.4541 5 0.0001 5"></polygon>
      </defs>
      <g id="All-Screens" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="SCR012---Email-form" transform="translate(-152.000000, -191.000000)">
          <g id="Group-3">
            <g id="Page-1" transform="translate(152.000000, 191.000000)">
              <path d="M66.5,30.7655 C65.121,30.7655 64,31.8865 64,33.2655 C64,34.6445 65.121,35.7655 66.5,35.7655 C67.879,35.7655 69,34.6445 69,33.2655 C69,31.8865 67.879,30.7655 66.5,30.7655 M66.5,38.7655 C63.468,38.7655 61,36.2975 61,33.2655 C61,30.2335 63.468,27.7655 66.5,27.7655 C69.532,27.7655 72,30.2335 72,33.2655 C72,36.2975 69.532,38.7655 66.5,38.7655" id="Fill-1" fill={theme.secondaryColor} ></path>
              <g id="Group-5">
                <mask id="mask-2" fill="white">
                  <use xlinkHref="#path-1"></use>
                </mask>
                <g id="Clip-4"></g>
                <path d="M8.1816,3.3407 L6.3406,3.3407 L6.3406,1.4997 C6.3406,0.6717 5.6686,-0.0003 4.8406,-0.0003 C4.0126,-0.0003 3.3406,0.6717 3.3406,1.4997 L3.3406,3.3407 L1.4996,3.3407 C0.6716,3.3407 -0.0004,4.0127 -0.0004,4.8407 C-0.0004,5.6687 0.6716,6.3407 1.4996,6.3407 L3.3406,6.3407 L3.3406,8.1817 C3.3406,9.0097 4.0126,9.6817 4.8406,9.6817 C5.6686,9.6817 6.3406,9.0097 6.3406,8.1817 L6.3406,6.3407 L8.1816,6.3407 C9.0096,6.3407 9.6816,5.6687 9.6816,4.8407 C9.6816,4.0127 9.0096,3.3407 8.1816,3.3407" id="Fill-3" fill={theme.secondaryColor} mask="url(#mask-2)"></path>
              </g>
              <g id="Group-8" transform="translate(20.000000, 67.000000)">
                <mask id="mask-4" fill="white">
                  <use xlinkHref="#path-3"></use>
                </mask>
                <g id="Clip-7"></g>
                <path d="M4.4541,2.7723 C4.4541,4.0023 3.4571,5.0003 2.2271,5.0003 C0.9971,5.0003 0.0001,4.0023 0.0001,2.7723 C0.0001,1.5433 0.9971,0.5453 2.2271,0.5453 C3.4571,0.5453 4.4541,1.5433 4.4541,2.7723" id="Fill-6" fill={theme.secondaryColor} mask="url(#mask-4)"></path>
              </g>
              <path d="M34.5,37.0936 L5.815,16.6046 L63.185,16.6046 L34.5,37.0936 Z M67,25.9376 C67.828,25.9376 68.5,25.2656 68.5,24.4376 L68.5,17.7706 C68.5,15.4736 66.631,13.6046 64.333,13.6046 L4.667,13.6046 C2.369,13.6046 0.5,15.4736 0.5,17.7706 L0.5,55.7706 C0.5,58.0686 2.369,59.9376 4.667,59.9376 L64.333,59.9376 C66.631,59.9376 68.5,58.0686 68.5,55.7706 L68.5,42.5726 C68.5,41.7436 67.828,41.0726 67,41.0726 C66.172,41.0726 65.5,41.7436 65.5,42.5726 L65.5,55.7706 C65.5,56.4136 64.977,56.9376 64.333,56.9376 L4.667,56.9376 C4.023,56.9376 3.5,56.4136 3.5,55.7706 L3.5,18.6376 L33.628,40.1576 C33.889,40.3446 34.194,40.4376 34.5,40.4376 C34.806,40.4376 35.111,40.3446 35.372,40.1576 L65.5,18.6376 L65.5,24.4376 C65.5,25.2656 66.172,25.9376 67,25.9376 Z" id="Fill-9" fill={theme.mainColor} ></path>
              <path d="M10.667,51.2704 C10.283,51.2704 9.899,51.1244 9.606,50.8314 C9.021,50.2454 9.021,49.2954 9.606,48.7094 L18.272,40.0434 C18.858,39.4574 19.808,39.4574 20.394,40.0434 C20.98,40.6294 20.98,41.5794 20.394,42.1654 L11.728,50.8314 C11.435,51.1244 11.051,51.2704 10.667,51.2704" id="Fill-11" fill={theme.secondaryColor} ></path>
              <path d="M58.333,51.2704 C57.949,51.2704 57.565,51.1244 57.272,50.8314 L48.606,42.1654 C48.02,41.5794 48.02,40.6294 48.606,40.0434 C49.192,39.4574 50.142,39.4574 50.728,40.0434 L59.394,48.7094 C59.979,49.2954 59.979,50.2454 59.394,50.8314 C59.101,51.1244 58.717,51.2704 58.333,51.2704" id="Fill-13" fill={theme.secondaryColor} ></path>
              <path d="M16.7803,7.494 C16.7803,8.262 16.1583,8.885 15.3893,8.885 C14.6223,8.885 14.0003,8.262 14.0003,7.494 C14.0003,6.726 14.6223,6.104 15.3893,6.104 C16.1583,6.104 16.7803,6.726 16.7803,7.494" id="Fill-15" fill={theme.mainColor} ></path>
              <path d="M55.7803,64.7137 C55.7803,65.4817 55.1583,66.1047 54.3893,66.1047 C53.6223,66.1047 53.0003,65.4817 53.0003,64.7137 C53.0003,63.9457 53.6223,63.3237 54.3893,63.3237 C55.1583,63.3237 55.7803,63.9457 55.7803,64.7137" id="Fill-17" fill={theme.mainColor} ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
  /* eslint-enable */
}

SvgEnvelopeStars2.propTypes = {
  theme: PropTypes.object.isRequired,
};

export default withTheme(SvgEnvelopeStars2);
