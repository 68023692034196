/**
 * Created by galgoltzman on 07/02/2017.
 */
import React from 'react';
import PropTypes from 'prop-types';

import './roundedImage.less';

class RoundedImage extends React.Component {

  static propTypes = {
    /**
     * The css class name of the root element.
     */
    className: PropTypes.string,

    /**
     * The css class name of the img tag
     */
    imgClassName: PropTypes.string,

    /**
     * The image source.
     */
    src: PropTypes.string.isRequired,
    /**
     * The alternative content for the img tag.
     */
    alt: PropTypes.string,
  };

  static defaultProps = {
    className: 'rounded-img',
    imgClassName: '',
    alt: '',
  };

  render() {
    const { className, imgClassName, src, alt } = this.props;

    return (
      <div className={className}>
        <img className={imgClassName} src={src} alt={alt} />
      </div>
    );
  }
}

export default RoundedImage;
