/**
 * Created by matan on 10/19/17.
 */
import Immutable from 'seamless-immutable';

import { LOAD_RECAPTCHA_API, SET_RECAPTCHA_TOKEN, } from './actions';

const initialState = {
  ready: false,
  token: null,
};

export default function recaptchaReducer(state = initialState, action) {
  state = Immutable(state);

  switch (action.type) {
    case LOAD_RECAPTCHA_API.SUCCESS: {
      return state.set('ready', action.payload);
    }
    case SET_RECAPTCHA_TOKEN: {
      return state.set('token', action.payload);
    }
    default:
      return state;
  }
}
