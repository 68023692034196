/**
 * Created by anna on 07/08/2017.
 */
import { defineMessages } from 'react-intl';
import IntlEnum from '../../../../utils/intlEnum';
import * as PositiveDocotorEnum from '../../../doctorCard/sections/reviewPositiveOptions/positiveDoctorOptions';
import * as PositiveOfficeEnum from '../../../doctorCard/sections/reviewPositiveOptions/positiveOfficeOptions';

const messages = defineMessages({
  enoughTimeSpent: {
    defaultMessage: 'spends enough time',
    id: 'members.doctorReview.enoughTimeSpent'
  },
  goodListener: {
    defaultMessage: 'a good listener',
    id: 'members.doctorReview.goodListener'
  },
  explainsClearly: {
    defaultMessage: 'explains clearly',
    id: 'members.doctorReview.explainsClearly'
  },
  iTrustHerDecisions: {
    defaultMessage: 'decisions are trusted',
    id: 'members.doctorReview.iTrustHerDecisions'
  },
  easyScheduling: {
    defaultMessage: 'scheduling is easy',
    id: 'members.doctorReview.easyScheduling'
  },
  cleanComfy: {
    defaultMessage: 'office is clean and comfy',
    id: 'members.doctorReview.cleanComfy'
  },
  friendlyStaff: {
    defaultMessage: 'staff is friendly',
    id: 'members.doctorReview.friendlyStaff'
  },
  shortWaitTimes: {
    defaultMessage: 'has short wait times',
    id: 'members.doctorReview.shortWaitTimes'
  },
});

const PositiveEnum = new IntlEnum(
  { value: PositiveDocotorEnum.ENOUGH_TIME_SPENT, message: messages.enoughTimeSpent },
  { value: PositiveDocotorEnum.GOOD_LISTENER, message: messages.goodListener },
  { value: PositiveDocotorEnum.EXPLAINS_CLEARLY, message: messages.explainsClearly },
  { value: PositiveDocotorEnum.TRUST_DECISIONS, message: messages.iTrustHerDecisions },
  { value: PositiveOfficeEnum.EASY_SCHEDULING, message: messages.easyScheduling },
  { value: PositiveOfficeEnum.CLEAN_AND_COMFY, message: messages.cleanComfy },
  { value: PositiveOfficeEnum.FRIENDLY_STAFF, message: messages.friendlyStaff },
  { value: PositiveOfficeEnum.SHORT_WAIT_TIME, message: messages.shortWaitTimes },
);

export default PositiveEnum;
