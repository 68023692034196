import PropTypes from 'prop-types';

/**
 * PropType object shape for the flow object
 */
export const FlowShape = PropTypes.shape({
  next: PropTypes.func.isRequired,
  prev: PropTypes.func.isRequired,
  hasNext: PropTypes.func.isRequired,
  hasPrev: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  flowId: PropTypes.string,
  currentStep: PropTypes.string, // step name
  stepProps: PropTypes.object,
});

/**
 * PropType object for single step meta description
 */
export const FlowStepMetaShape = PropTypes.shape({
  /** A component type */
  component: PropTypes.any.isRequired,
  transitions: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string, PropTypes.func
  ])).isRequired,
  mapFlowDataToProps: PropTypes.func.isRequired,
  mapPayloadToFlowData: PropTypes.func,
  evaluate: PropTypes.func,
});
