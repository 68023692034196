import PropTypes from 'prop-types';

export const clientLocationShape = {
  name: PropTypes.string,
  address: PropTypes.string.isRequired,
  shortAddress: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  geo: PropTypes.shape({
    longitude: PropTypes.number.isRequired,
    latitude: PropTypes.number.isRequired,
    zip: PropTypes.string,
    state: PropTypes.string,
  }),
};
