/**
 * Created by chenrozenes on 09/08/2017.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '../../storybook/src/utils/theme';

function SvgDoctorsCheckC({ theme }) {
  /* eslint-disable */
  return (
    <svg className="svg-icon svg-doctors-check-c" width="71px" height="72px" viewBox="0 0 71 72" version="1.1">
      <defs>
        <polygon id="path-1" points="0.45385 0.973904303 37.9967539 0.973904303 37.9967539 32 0.45385 32"></polygon>
        <polygon id="path-3" points="0.0005 0.7314 71 0.7314 71 53.7314 0.0005 53.7314"></polygon>
      </defs>
      <g id="All-screens" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="2-SCR067---Landing-page-with-CTA-to-view-more-Copy" transform="translate(-151.000000, -107.000000)">
          <g id="Group-11" transform="translate(151.000000, 106.000000)">
            <g id="Group-3" transform="translate(30.000000, 40.268500)">
              <g id="Clip-2"></g>
              <path d="M7.3896,12.6934 C6.3806,12.6934 5.3706,13.0774 4.6036,13.8444 C3.0686,15.3804 3.0686,17.8804 4.6036,19.4164 L13.0346,27.8464 C13.7786,28.5894 14.7686,29.0004 15.8206,29.0004 L15.9256,28.9984 C17.0166,28.9654 18.0466,28.4844 18.7646,27.6774 L34.0046,10.5324 C35.4486,8.9084 35.3016,6.4144 33.6776,4.9704 C32.8916,4.2714 31.8736,3.9214 30.8286,3.9824 C29.7796,4.0454 28.8166,4.5114 28.1176,5.2974 L15.6516,19.3204 L10.1756,13.8434 C9.4076,13.0764 8.3986,12.6934 7.3896,12.6934 Z M15.8206,32.0004 C13.9676,32.0004 12.2246,31.2784 10.9126,29.9684 L2.4826,21.5374 C-0.2224,18.8314 -0.2224,14.4284 2.4826,11.7224 C5.1886,9.0194 9.5906,9.0204 12.2966,11.7214 L15.5226,14.9494 L25.8746,3.3044 C27.1056,1.9204 28.8016,1.0974 30.6526,0.9884 C32.4956,0.8684 34.2856,1.4974 35.6706,2.7284 C38.5316,5.2704 38.7886,9.6664 36.2466,12.5254 L21.0066,29.6704 C19.7436,31.0924 17.9266,31.9404 16.0246,31.9964 L15.8206,32.0004 Z" id="Fill-1" fill={theme.mainColor} ></path>
            </g>
            <path d="M32.416,71.8613 C32.371,71.8613 32.325,71.8593 32.279,71.8553 C22.704,70.9913 13.754,66.1493 7.725,58.5713 C7.355,58.1073 7.294,57.4683 7.569,56.9423 C11.001,50.3823 16.521,45.3203 23.271,42.4843 C20.532,39.4653 19,35.5763 19,31.5003 C19,22.4023 26.402,15.0003 35.5,15.0003 C44.598,15.0003 52,22.4023 52,31.5003 C52,36.4033 49.783,41.0343 45.918,44.2073 C45.277,44.7343 44.331,44.6403 43.807,44.0003 C43.281,43.3593 43.374,42.4143 44.014,41.8883 C47.183,39.2883 49,35.5013 49,31.5003 C49,24.0563 42.944,18.0003 35.5,18.0003 C28.056,18.0003 22,24.0563 22,31.5003 C22,35.5013 23.817,39.2883 26.986,41.8883 C27.408,42.2353 27.608,42.7843 27.509,43.3213 C27.409,43.8583 27.026,44.2993 26.508,44.4703 C19.873,46.6783 14.161,51.3863 10.7,57.4673 C16.204,63.9683 24.112,68.1063 32.549,68.8673 C33.374,68.9413 33.982,69.6713 33.908,70.4963 C33.838,71.2753 33.184,71.8613 32.416,71.8613" id="Fill-4" fill={theme.mainColor} ></path>
            <path d="M62.7988,35.3876 C62.3588,35.3876 61.9218,35.1946 61.6258,34.8236 C59.8338,32.5796 57.4538,30.9176 54.7438,30.0166 C54.2268,29.8446 53.8428,29.4046 53.7428,28.8666 C53.6438,28.3296 53.8438,27.7806 54.2658,27.4346 C55.8228,26.1566 56.7158,24.2986 56.7158,22.3386 C56.7158,18.6856 53.7438,15.7136 50.0908,15.7136 C49.2358,15.7136 48.4048,15.8736 47.6208,16.1886 C46.8528,16.4966 45.9788,16.1256 45.6698,15.3566 C45.3608,14.5886 45.7338,13.7146 46.5018,13.4056 C47.6438,12.9466 48.8518,12.7136 50.0908,12.7136 C55.3988,12.7136 59.7158,17.0316 59.7158,22.3386 C59.7158,24.4066 59.0408,26.3916 57.8158,28.0326 C60.1968,29.1836 62.2968,30.8566 63.9698,32.9516 C64.4868,33.5986 64.3808,34.5426 63.7348,35.0596 C63.4578,35.2806 63.1268,35.3876 62.7988,35.3876" id="Fill-6" fill={theme.mainColor} ></path>
            <g id="Group-10" transform="translate(0.000000, 0.268500)">
              <g id="Clip-9"></g>
              <path d="M5.5225,53.7314 C4.9885,53.7314 4.4705,53.4454 4.2005,52.9404 C1.4525,47.8214 0.0005,42.0434 0.0005,36.2314 C0.0005,16.6564 15.9245,0.7314 35.5005,0.7314 C55.0755,0.7314 71.0005,16.6564 71.0005,36.2314 C71.0005,37.1944 70.9605,38.1614 70.8835,39.1044 C70.8635,39.3614 70.8385,39.6244 70.8125,39.8844 C70.7285,40.7094 69.9905,41.3024 69.1685,41.2244 C68.3435,41.1404 67.7445,40.4044 67.8285,39.5804 C67.8525,39.3424 67.8755,39.1054 67.8935,38.8664 C67.9635,37.9984 68.0005,37.1134 68.0005,36.2314 C68.0005,18.3114 53.4205,3.7314 35.5005,3.7314 C17.5795,3.7314 3.0005,18.3114 3.0005,36.2314 C3.0005,41.5494 4.3295,46.8364 6.8425,51.5224 C7.2355,52.2524 6.9605,53.1614 6.2305,53.5524 C6.0045,53.6734 5.7615,53.7314 5.5225,53.7314" id="Fill-8" fill={theme.secondaryColor} ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
  /* eslint-enable */
}

SvgDoctorsCheckC.propTypes = {
  theme: PropTypes.object.isRequired,
};

export default withTheme(SvgDoctorsCheckC);
