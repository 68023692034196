/**
 * Created by galgoltzman on 01/02/2017.
 */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './header.less';

class Header extends React.Component {
  static propTypes = {
    /**
     * Sets component css class
     */
    className: PropTypes.string,
  };

  static defaultProps = {
    className: undefined
  };

  render() {
    const { className } = this.props;
    return (
      <header className={classNames('header', className)}>
        {this.props.children}
      </header>
    );
  }
}

export default Header;
