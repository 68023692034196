import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '../../storybook/src/utils/theme';

function SvgUserCircleQ({ theme }) {
  /* eslint-disable */
  return (
    <svg className="svg-icon svg-user-circle-q" width="70px" height="72px" viewBox="0 0 70 72" version="1.1">
      <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Failed-to-activate" transform="translate(-153.000000, -96.000000)">
          <g id="Group" transform="translate(153.000000, 96.000000)">
            <path d="M31.6084,69.0752 C31.5634,69.0752 31.5174,69.0732 31.4714,69.0692 C22.1354,68.2262 13.4104,63.5072 7.5314,56.1182 C7.1624,55.6542 7.1004,55.0142 7.3764,54.4892 C10.7144,48.1092 16.0784,43.1812 22.6364,40.4132 C19.9864,37.4712 18.5044,33.6902 18.5044,29.7292 C18.5044,20.8472 25.7294,13.6222 34.6114,13.6222 C43.4934,13.6222 50.7194,20.8472 50.7194,29.7292 C50.7194,34.5152 48.5554,39.0372 44.7824,42.1362 C44.1404,42.6622 43.1974,42.5692 42.6714,41.9292 C42.1454,41.2882 42.2384,40.3432 42.8784,39.8172 C45.9554,37.2912 47.7194,33.6142 47.7194,29.7292 C47.7194,22.5022 41.8384,16.6222 34.6114,16.6222 C27.3834,16.6222 21.5044,22.5022 21.5044,29.7292 C21.5044,33.6142 23.2684,37.2912 26.3454,39.8172 C26.7674,40.1642 26.9674,40.7132 26.8684,41.2502 C26.7684,41.7872 26.3854,42.2272 25.8674,42.4002 C19.4234,44.5432 13.8764,49.1122 10.5064,55.0142 C15.8604,61.3252 23.5444,65.3422 31.7414,66.0812 C32.5664,66.1552 33.1744,66.8852 33.1004,67.7102 C33.0304,68.4892 32.3764,69.0752 31.6084,69.0752" id="Fill-1" fill={theme.mainColor} ></path>
            <path d="M5.418,51.6924 C4.884,51.6924 4.366,51.4064 4.095,50.9014 C1.416,45.9084 0,40.2744 0,34.6104 C0,15.5264 15.526,0.0004 34.611,0.0004 C53.696,0.0004 69.223,15.5264 69.223,34.6104 C69.223,40.2764 67.807,45.9104 65.127,50.9014 C64.735,51.6334 63.825,51.9054 63.097,51.5134 C62.366,51.1224 62.092,50.2134 62.484,49.4834 C64.93,44.9254 66.223,39.7834 66.223,34.6104 C66.223,17.1804 52.042,3.0004 34.611,3.0004 C17.181,3.0004 3,17.1804 3,34.6104 C3,39.7814 4.293,44.9234 6.739,49.4834 C7.131,50.2134 6.856,51.1224 6.126,51.5144 C5.9,51.6344 5.657,51.6924 5.418,51.6924" id="Fill-3" fill={theme.secondaryColor} ></path>
            <path d="M47.2607,46.9229 C41.1747,46.9229 36.2227,51.8749 36.2227,57.9609 C36.2227,64.0479 41.1747,68.9999 47.2607,68.9999 C53.3477,68.9999 58.2997,64.0479 58.2997,57.9609 C58.2997,51.8749 53.3477,46.9229 47.2607,46.9229 M47.2607,71.9999 C39.5207,71.9999 33.2227,65.7019 33.2227,57.9609 C33.2227,50.2209 39.5207,43.9229 47.2607,43.9229 C55.0017,43.9229 61.2997,50.2209 61.2997,57.9609 C61.2997,65.7019 55.0017,71.9999 47.2607,71.9999" id="Fill-5" fill={theme.secondaryColor} ></path>
            <path d="M47.8115,61.6631 C48.6905,61.6631 49.4295,62.4011 49.4295,63.2801 C49.4295,64.1611 48.6905,64.8991 47.8115,64.8991 C46.9315,64.8991 46.1925,64.1611 46.1925,63.2801 C46.1925,62.4011 46.9315,61.6631 47.8115,61.6631 M47.9515,56.2261 C48.5705,55.7861 49.1105,55.4071 49.1105,54.8071 C49.1105,54.1861 48.6495,53.6681 47.5505,53.6681 C46.5515,53.6681 45.7115,54.1671 45.0915,54.9061 L43.4745,53.0891 C44.5325,51.8491 46.1315,51.1491 47.8505,51.1491 C50.4285,51.1491 52.0085,52.4491 52.0085,54.3081 C52.0085,55.9661 50.9495,56.7671 49.9895,57.4261 C49.2895,57.9441 48.6705,58.3841 48.6705,59.1241 C48.6705,59.4431 48.8305,59.7831 49.0305,59.9641 L46.8515,60.6031 C46.4315,60.1421 46.1925,59.5441 46.1925,58.8451 C46.1925,57.4651 47.1515,56.8061 47.9515,56.2261" id="Fill-7" fill={theme.mainColor} ></path>
          </g>
        </g>
      </g>
    </svg>
  );
  /* eslint-enable */
}

SvgUserCircleQ.propTypes = {
  theme: PropTypes.object.isRequired,
};

export default withTheme(SvgUserCircleQ);
