/**
 * Created by guyavarham on 09/03/2017.
 */

import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { DropdownButton, MenuItem } from 'react-bootstrap';

export default class IntlDropdown extends React.PureComponent {
  static defaultProps = {
    bsStyle: 'link',
    className: '',
  };

  static propTypes = {
    /**
     * Unique id not give to other checkboxes in the form
     */
    id: PropTypes.string.isRequired,
    /**
     * The options for the drop down
     */
    options: PropTypes.arrayOf(PropTypes.object).isRequired,
    /**
     * Sets component value
     */
    value: PropTypes.object.isRequired,
    /**
     * Hook method, called on component value change
     */
    onChange: PropTypes.func.isRequired,
    /**
     * bootstrap style
     */
    bsStyle: PropTypes.string,
    className: PropTypes.string,
  };

  render() {
    const { id, options, value, onChange, className, bsStyle } = this.props;
    return (
      <DropdownButton
        className={className}
        onSelect={onChange}
        title={value.defaultMessage}
        id={id}
        bsStyle={bsStyle}
      >
        {_.map(options, option => (
          <MenuItem
            eventKey={option.id}
            key={option.id}
            id={option.id}
          >
            <FormattedMessage {...option} />
          </MenuItem>
        ))}
      </DropdownButton>
    );
  }
}
