/**
 * Created by meirshalev on 04/06/2017.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import { defineMessages } from 'react-intl';
import { destroy } from 'redux-form';
import { withRouter } from 'react-router';

import injectNotification from '../../../../../../store/notification/injectNotification';
import actionTracker from '../../../../../../store/tools/actionTracker/actionTrackerComponent';

import Layout from '../../../layout/layout';
import UpdatePasswordForm from './updatePasswordForm';
import { name as formName } from './updatePasswordDef';
import * as Errors from '../../../../../../api/errors';
import errorMapping from './errorMapping';
import { paths } from '../../paths';
import SettingsLayout from '../../settingsLayout';
import SvgKey from '../../../../../../assets/svg/key';

import { UPDATE_PASSWORD, updatePassword } from '../../../../../../store/user/actions';

const messages = defineMessages({
  successMessage: {
    defaultMessage: 'Your password has been updated.',
    id: 'members.settings.updatePassword.successMessage',
  },
});
const successTitle = ' ';

@autobind
class UpdatePasswordPage extends React.Component {
  static propTypes = {
    /**
     * Injected prop for notifying on errors
     */
    notification: PropTypes.object.isRequired,
    /**
     * Server request action tracking.
     */
    updatePasswordTracking: PropTypes.object.isRequired,
    /**
     * Redux action creator
     */
    updatePassword: PropTypes.func.isRequired,
    /**
     * Action creator for redux-form destroy action.
     */
    destroy: PropTypes.func.isRequired,
    /**
     * react-router object, injected by withRouter
     */
    router: PropTypes.object.isRequired,
  };

  componentWillReceiveProps(nextProps) {
    const { updatePasswordTracking } = nextProps;

    if (updatePasswordTracking.finished) this.handleResponse(updatePasswordTracking);
  }

  onSubmit({ currentPassword, newPassword }) {
    this.props.updatePassword(currentPassword, newPassword);
  }

  handleResponse(tracking) {
    if (tracking.hasError) {
      const { responseMessage } = tracking.error;
      switch (responseMessage) {
        case Errors.PASSWORD_WAS_IN_USE:
        case Errors.WEAK_PASSWORD: {
          this.props.notification.error(
            errorMapping[responseMessage].title,
            errorMapping[responseMessage].message,
            { autoDismiss: 10 },
          );
          break;
        }
        default: {
          this.props.notification.error(
            errorMapping.defaultMessage.title,
            errorMapping.defaultMessage.message,
            { autoDismiss: 10 },
          );
        }
      }
    } else {
      this.props.destroy(formName);
      this.props.notification.success(successTitle, messages.successMessage, { autoDismiss: 10 });
      this.props.router.replace(paths.profile());
    }
  }

  render() {
    return (
      <Layout
        hideLogo
        centeredBackBtn
        previousPageName="your profile"
        previousPageRoute={paths.profile()}
      >
        <SettingsLayout>
          <div className="text-center margin-top-20 margin-bottom-clean-form">
            <SvgKey />
          </div>
          <UpdatePasswordForm onSubmit={this.onSubmit} />
        </SettingsLayout>
      </Layout>
    );
  }
}

export default compose(
  withRouter,
  connect(
    null,
    {
      updatePassword,
      destroy,
    },
  ),
  injectNotification,
  actionTracker({
    updatePasswordTracking: UPDATE_PASSWORD.SOURCE,
  }),
)(UpdatePasswordPage);
