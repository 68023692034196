/**
 * Created by chenrozenes on 01/05/2017.
 */
import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import FieldWrapper from './fieldWrapper';
import CleanSelect from '../cleanSelect';

const Component = FieldWrapper(CleanSelect);

export const ReduxFormCleanSelectWrapper = Component => {
  @autobind
  class ReduxFormCleanSelect extends React.Component {
    static propTypes = {
      input: PropTypes.object.isRequired,
    };

    onChange(option) {
      if (!option) {
        this.props.input.onChange(null);
      } else if (option.value) {
        this.props.input.onChange(option.value);
      } else if (_.isArray(option)) {
        this.props.input.onChange(_.map(option, 'value'));
      } else {
        this.props.input.onChange(option);
      }
    }

    render() {
      return (
        <Component onChange={this.onChange} {...this.props} />
      );
    }
  }

  return ReduxFormCleanSelect;
};

export default ReduxFormCleanSelectWrapper(Component);
