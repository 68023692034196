import { pathsWithQuery } from '../../../../../utils/pathsWithQuery';

export default {
  home: (qsParams) => pathsWithQuery('/secure', qsParams),
  searchDoctors: (qsParams) => pathsWithQuery('/secure/directory/searchDoctors', qsParams),
  doctorDetails: (npi, qsParams) =>
    pathsWithQuery(`/secure/directory/doctorDetails/${npi}`, qsParams),
  searchDoctorsResults: (qsParams) =>
    pathsWithQuery('/secure/directory/searchDoctorsResults', qsParams),
  yourPlan: (qsParams) => pathsWithQuery('/secure/yourPlan', qsParams),
  careCues: (qsParams) => pathsWithQuery('/secure/careCues', qsParams),
  medicalTeam: (qsParams) => pathsWithQuery('/secure/medicalTeam', qsParams),
  settings: (qsParams) => pathsWithQuery('/secure/settings', qsParams),
  medicationHistory: (qsParams) => pathsWithQuery('/secure/medications', qsParams),
  facilitySearchResults: (qsParams) => pathsWithQuery('/secure/facilitySearchResults', qsParams),
  help: (qsParams) => pathsWithQuery('/secure/help', qsParams),
  premeraHelp: (qsParams) => pathsWithQuery('/secure/premeraHelp', qsParams),
  uhcAllsaversHelp: (qsParams) => pathsWithQuery('/secure/uhcAllsaversHelp', qsParams),
  anthemHelp: (qsParams) => pathsWithQuery('/secure/anthemHelp', qsParams),
  floridaHelp: (qsParams) => pathsWithQuery('/secure/floridaHelp', qsParams),
  resources: (qsParams) => pathsWithQuery('/secure/resources', qsParams),
  bookAppointment: (npi, locationId, qsParams) =>
    pathsWithQuery(`/secure/directory/${npi}/${locationId}/appointments`, qsParams),
  appointments: (qsParams) => pathsWithQuery('/secure/appointments', qsParams),
};
