/**
 * Created by matan on 3/15/17.
 */
import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { autobind } from 'core-decorators';

import PhoneLink from '../../../../components/ui/links/phoneLink';
import Layout from '../layout/layout';

import './help.less';

const messages = defineMessages({
  title: {
    defaultMessage: 'Help',
    id: 'help.uhcAllSavers.title',
  },
  subtitle1: {
    defaultMessage: 'Contact UnitedHealthcare All Savers',
    id: 'help.uhcAllSavers.subtitle1',
  },
  healthPlanSupport: {
    defaultMessage: 'Health plan support',
    id: 'help.uhcAllSavers.healthPlanSupport',
  },
  forQuestions: {
    defaultMessage: 'For questions about your plan and coverage please call:',
    id: 'help.uhcAllSavers.forQuestions',
  },
  monFriFrom: {
    defaultMessage: 'Mon-Fri from 7:30 - 6PM CST',
    id: 'help.uhcAllSavers.monFriFrom',
  },
  learnMoreAbout: {
    defaultMessage: 'Learn more about your All Savers plan',
    id: 'help.uhcAllSavers.lookingForSomethingElse',
  },
  visitTheAllSaversMembers: {
    defaultMessage: 'Visit the All Savers members website:',
    id: 'help.uhcAllSavers.visitThePremeraBlueCross',
  },
  goToTheWebsite: {
    defaultMessage: 'Go to the website',
    id: 'help.uhcAllSavers.goToTheWebsite',
  },
  insurerWebsite: {
    defaultMessage: 'https://www.myallsaversmember.com',
    id: 'help.uhcAllSavers.insurerWebsite',
  },
  contactInformation: {
    defaultMessage: 'All Savers contact information',
    id: 'help.uhcAllSavers.contactInformation',
  },
  contactWebsite: {
    defaultMessage: 'https://www.myallsavers.com/MyAllSavers/Home/Contact',
    id: 'help.uhcAllSavers.contactWebsite',
  },
  healthPlanSupportNumber: {
    defaultMessage: '1-844-719-4871',
    id: 'help.uhcAllSavers.healthPlanSupportNumber',
  },
});

@autobind
class UhcAllsaversHelpContent extends React.Component {
  render() {
    return (
      <Layout footerClassName="xs-not-fixed white">
        <div className="help-container">
          <div className="help-content">
            <div className="text-center">
              <img
                src={require('../../../../assets/images/logos/vimUhcLogo.png')}
                className="help-modal-top-img logo"
                alt=""
              />
            </div>
            <div>
              <div className="help-top">
                <div className="row margin-top-20">
                  <div className="col-xs-12 text-center">
                    <span className="text-18 text-semibold text-dark">
                      <FormattedMessage {...messages.subtitle1} />
                    </span>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-sm-4">
                  <div className="contact-section">
                    <i className="icon-iphone contact-icon" />
                    <div className="text-semibold text-dark text-16">
                      <FormattedMessage {...messages.healthPlanSupport} />
                    </div>
                    <div className="margin-top-5 text-16">
                      <FormattedMessage {...messages.forQuestions} />
                    </div>
                    <div className="margin-top-5 text-16 blue-link">
                      <PhoneLink phoneNumber={messages.healthPlanSupportNumber} />
                    </div>
                    <div className="margin-top-5 text-14">
                      <FormattedMessage {...messages.monFriFrom} />
                    </div>
                  </div>
                </div>
                <div className="col-sm-4" />
                <div className="col-sm-4">
                  <div className="contact-section">
                    <i className="icon-browser contact-icon" />
                    <div className="text-semibold text-dark text-16">
                      <FormattedMessage {...messages.learnMoreAbout} />
                    </div>
                    <div className="margin-top-5 text-16">
                      <FormattedMessage {...messages.visitTheAllSaversMembers} />
                    </div>
                    <div className="margin-top-5 text-16">
                      <a
                        href={messages.insurerWebsite.defaultMessage}
                        rel="noopener noreferrer"
                        className="blue-link"
                        target="_blank"
                        aria-labelledby="go-to-allsavers-website-0"
                      >
                        <FormattedMessage {...messages.goToTheWebsite} />
                        &nbsp;
                        <i
                          className="icon-ext-link text-12"
                          id="go-to-allsavers-website-0"
                          aria-label="external-link"
                        />
                      </a>
                    </div>
                    <br />
                    <div className="margin-top-5 text-16">
                      <FormattedMessage {...messages.contactInformation} />
                    </div>
                    <div className="margin-top-5 text-16">
                      <a
                        href={messages.contactWebsite.defaultMessage}
                        rel="noopener noreferrer"
                        className="blue-link"
                        target="_blank"
                        aria-labelledby="go-to-allsavers-website-1"
                      >
                        <FormattedMessage {...messages.goToTheWebsite} />
                        &nbsp;
                        <i
                          className="icon-ext-link text-12"
                          id="go-to-allsavers-website-1"
                          aria-label="external-link"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default UhcAllsaversHelpContent;
