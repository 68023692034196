import uuid from 'uuid/v4';
import { getSessionStorage } from '../storage/sessionStorage';

let memberSessionId = null;

export function getMemberSessionId() {
  if (!memberSessionId) memberSessionId = getSessionStorage().getItem('memberSessionId');
  return memberSessionId;
}

export function startMemberSession() {
  const newMemberSessionId = uuid();
  getSessionStorage().setItem('memberSessionId', newMemberSessionId);
  memberSessionId = newMemberSessionId;
  return newMemberSessionId;
}

export function clearMemberSession() {
  getSessionStorage().removeItem('memberSessionId');
  memberSessionId = null;
}
