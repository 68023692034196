import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { autobind } from 'core-decorators';
import { destroy } from 'redux-form';

import ActivationLayout from '../components/activationLayout';
import Signup from '../components/signup';
import * as ActivationForm from '../activationForm';
import injectNotification from '../../../../../store/notification/injectNotification';
import { memberSignup, SIGNUP } from '../../../../../store/activation/actions';
import * as Errors from '../../../../../api/errors';
import actionTracker from '../../../../../store/tools/actionTracker/actionTrackerComponent';
import errorMapping from '../activationErrorMapping';
import { ActivationCompleted } from '../../../../../store/tools/analytics/events/memberEvents';
import { resetRecaptch } from '../../../../../store/recaptcha/actions';
import { goToNext } from '../../../../../utils/reRouting';
import {
  createToggleState,
  createToggleStatePropTypes,
} from '../../../../../components/recompose/toggleStateComponent';
import { sourceSelector } from '../../../../../store/activation/selectors';

@autobind
export class ShortActivationFlow extends React.Component {
  static propTypes = {
    /**
     * Router object.
     * Provided by the withRouter HoC
     */
    router: PropTypes.object.isRequired,
    /**
     * react router params
     */
    params: PropTypes.object.isRequired,
    /**
     * Server request action tracking.
     * For creating a new user.
     */
    signupTracking: PropTypes.object.isRequired,
    /**
     * Redux action creator
     */
    memberSignup: PropTypes.func.isRequired,
    /**
     * Injected prop for notifying on errors
     */
    notification: PropTypes.object.isRequired,
    /**
     * redux-form action.
     */
    destroy: PropTypes.func.isRequired,
    /**
     * source from url param
     */
    source: PropTypes.string.isRequired,
    /**
     * The id of the domain-member to register
     */
    activationToken: PropTypes.string.isRequired,
    /**
     * Action function to reset system recaptch component.
     * Provided by the connect HoC
     */
    resetRecaptch: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    ...createToggleStatePropTypes('alreadyActivatedModal'),
  };

  static contextTypes = {
    analytics: PropTypes.object.isRequired,
  };

  componentWillReceiveProps(nextProps) {
    const { signupTracking } = nextProps;

    if (signupTracking.finished) {
      this.handleResponse(signupTracking, () => {
        this.context.analytics.track(ActivationCompleted);
        this.redirectToNextPage();
      });
    }
  }

  handleError(error) {
    const { responseMessage } = error;
    switch (responseMessage) {
      case Errors.RECAPTCHA_FAILED:
        break; // Handled by recaptchaMiddleware
      case Errors.USER_ALREADY_EXIST: {
        this.props.destroy(ActivationForm.name); // clean the from
        this.props.alreadyActivatedModalShow();
        break;
      }
      default: {
        this.props.notification.error(
          errorMapping.defaultMessage.title,
          errorMapping.defaultMessage.message,
          { autoDismiss: 10 },
        );
      }
    }
    // In case of an error, reset the Recaptch in the last page
    this.props.resetRecaptch();
  }

  handleResponse(tracking, onSuccess, nextProps) {
    if (tracking.hasError) {
      this.handleError(tracking.error);
    } else {
      onSuccess(nextProps);
    }
  }

  signup({ email, password }) {
    this.props.memberSignup(this.props.activationToken, email, password, this.props.source);
  }

  exit() {
    // clean the form in the state
    this.props.destroy(ActivationForm.name);
  }

  redirectToLandingPage() {
    this.exit();
    this.props.router.replace('/secure');
  }

  redirectToNextPage() {
    const {
      router,
      location: { query },
    } = this.props;
    this.exit();
    goToNext(router, query, '/secure?first=true');
  }

  render() {
    const { signupTracking } = this.props;
    return (
      <ActivationLayout
        onSigninClick={this.redirectToLandingPage}
        alreadyActivatedModalOn={this.props.alreadyActivatedModalOn}
        alreadyActivatedModalHide={this.props.alreadyActivatedModalHide}
      >
        <Signup onSubmit={this.signup} loading={signupTracking.inProgress} />
      </ActivationLayout>
    );
  }
}

export const flowComposition = [
  createToggleState('alreadyActivatedModal'),
  actionTracker({
    signupTracking: SIGNUP.SOURCE,
  }),
  connect(
    (state, ownProps) => ({
      source: sourceSelector(state, ownProps),
      activationToken: state.activation.activationToken,
    }),
    {
      memberSignup,
      destroy,
      resetRecaptch,
    },
  ),
  injectNotification,
  withRouter,
];

export default compose(...flowComposition)(ShortActivationFlow);
