import { autobind } from 'core-decorators';
import ServicesApi from './services';
import BkmdApi from './bkmdApi';

const SERVICE_NAME = 'medicalCode';

@autobind
export default class MedicalCodesApi extends BkmdApi {
  constructor(fetch) {
    super(fetch, '/medicalCode');
    this.servicesApi = new ServicesApi(fetch);
  }

  createMedicalCode(service) {
    return this.servicesApi.callSecure(SERVICE_NAME, 'createMedicalCode', { service });
  }

  updateMedicalCode(service) {
    return this.servicesApi.callSecure(SERVICE_NAME, 'updateMedicalCode', { service });
  }

  deleteMedicalCode(key, type) {
    return this.servicesApi.callSecure(SERVICE_NAME, 'deleteMedicalCode', { key, type });
  }

  listMedicalCodes(skip, limit, search, sort, sortAscending) {
    return this.servicesApi.callSecure(SERVICE_NAME, 'listMedicalCodes', {
      skip,
      limit,
      search,
      sort,
      sortAscending,
    });
  }

  getMedicalCodeById(key, type) {
    return this.servicesApi.callSecure(SERVICE_NAME, 'getMedicalById', { key, type });
  }

  getAllMedicalCodes() {
    return this.servicesApi.callSecure(SERVICE_NAME, 'getAllMedicalCodes', {});
  }
}
