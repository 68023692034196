import Joi from 'joi-browser';
import React from 'react';
import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form';
import { withProps } from 'recompose';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import ClickableDiv from '../../../../components/clickableElement/clickableDiv';
import messages from './messages';
import FindIdNumModal from '../../../../components/organisms/FindIdNumModal/FindIdNumModal';
import actionTracker, {
  actionTrackerShape,
} from '../../../../store/tools/actionTracker/actionTrackerComponent';
import { DateOfBirthSchema } from '../../../../utils/commonSchema';
import { createValidator } from '../../../../utils/joiValidator';
import {
  createToggleState,
  createToggleStatePropTypes,
} from '../../../../components/recompose/toggleStateComponent';
import injectNotification from '../../../../store/notification/injectNotification';
import SpinnerButton from '../../../../components/ui/spinner/spinnerButton';
import {
  ReduxFormCleanDateInput,
  ReduxFormCleanInput,
} from '../../../../components/form/reduxForm/components';
import { hasInsuranceIdSelector } from '../../../../store/member/selectors';
import {
  ADD_INSURANCE_INFO,
  addInsuranceInfo,
  getMemberWithPlan,
  getSettings,
} from '../../../../store/member/actions';
import FeatureFlagged from '../../../../components/features/featureFlagged';

import './InsuranceId.less';

export const insuranceIdMaxLength = 40;
const insuranceId = 'insuranceId';
const dateOfBirth = 'dateOfBirth';

class EnterInsuranceID extends React.Component {
  static propTypes = {
    addInsuranceInfoTracker: PropTypes.shape(actionTrackerShape),
    ...createToggleStatePropTypes('infoModal'),
    ...createToggleStatePropTypes('findIdModal'),

    /* eslint-disable react/no-unused-prop-types */
    hasInsuranceId: PropTypes.bool,
    /* eslint-enable react/no-unused-prop-types */

    handleSubmit: PropTypes.func.isRequired, // Hook method. supplied by redux-forms
    notification: PropTypes.object.isRequired, // Injected prop for notifying on errors
    getSettings: PropTypes.func.isRequired,
    getMemberWithPlan: PropTypes.func.isRequired,

    onNext: PropTypes.func.isRequired,
    onLaterClick: PropTypes.func,
    notificationTimeout: PropTypes.number,
  };

  static defaultProps = {
    addInsuranceInfoTracker: {},
    hasInsuranceId: false,
    notificationTimeout: 10,
    onLaterClick: undefined,
  };

  /**
   * C'tor implemented for binding class methods because of an issue when binding with autobind.
   * @autobind of core-decorator does not work well with react context.
   * The issue is when using context in class methods its properties are empty (paths on our case).
   * Need to remove the use of react context and put the paths on redux store.
   */
  constructor() {
    super();
    this.reRouteIfNeeded = this.reRouteIfNeeded.bind(this);
    this.renderOnLaterClick = this.renderOnLaterClick.bind(this);
  }

  componentWillMount() {
    this.reRouteIfNeeded(this.props);
  }

  componentWillReceiveProps(nextProps) {
    this.reRouteIfNeeded(nextProps);

    const {
      addInsuranceInfoTracker: { finished, hasError },
      notificationTimeout,
    } = nextProps;
    if (finished && hasError) {
      this.props.notification.error(messages.errorTitle, messages.errorMessage, {
        autoDismiss: notificationTimeout,
      });
    }
  }

  reRouteIfNeeded(props) {
    // Go to the next page only in case the user has insuranceId
    if (props.addInsuranceInfoTracker.finished && !props.addInsuranceInfoTracker.hasError) {
      const { getSettings, getMemberWithPlan, onNext, notificationTimeout } = props;
      getSettings();
      getMemberWithPlan();
      this.props.notification.success(messages.successTitle, messages.successMessage, {
        autoDismiss: notificationTimeout,
      });

      onNext();
    }
  }

  renderOnLaterClick() {
    const { onLaterClick } = this.props;
    if (!onLaterClick) return null;
    return (
      <div className="text-center padding-v-20">
        <ClickableDiv clickHandler={onLaterClick} className="btn btn-inline-link text-14 text-dark">
          <FormattedMessage {...messages.enterMyInsuranceIdLater} />
          &nbsp;
          <i className="icon-chevron-right i-va-fix-2" />
        </ClickableDiv>
      </div>
    );
  }

  render() {
    const {
      findIdModalShow,
      findIdModalHide,
      findIdModalOn,
      handleSubmit,
      addInsuranceInfoTracker,
    } = this.props;

    return (
      <div className="insurance-id-form-wrap padding-box-30 has-footer">
        <div className="row">
          <div className="col-sm-8 col-md-6 col-sm-offset-2 col-md-offset-3">
            <div className="top-section">
              <div className="row">
                <div className="col-xs-12 text-center">
                  <h1 className="title-brand-1 title-24 margin-10">
                    <FormattedMessage {...messages.enterYourInsuranceInfo} />
                  </h1>
                </div>
              </div>

              <div className="row  margin-bottom-clean-form">
                <div className="col-xs-12 text-center">
                  <span className="text-18 text-light">
                    <FormattedMessage {...messages.confirmYourIdToGetSearchResults} />
                  </span>
                </div>
              </div>
            </div>

            <form className="form-section" onSubmit={handleSubmit} method="post">
              <div className="row margin-bottom-clean-form">
                <div className="col-xs-12 enter-id-input-row">
                  <Field
                    label={messages.myInsuranceId}
                    name={insuranceId}
                    id={insuranceId}
                    maxLength={insuranceIdMaxLength}
                    component={ReduxFormCleanInput}
                    inputClassName="no-margin"
                  />
                </div>
              </div>

              <FeatureFlagged uri="showInsuranceInfoHints">
                <div className="row margin-top-5 margin-bottom-clean-form">
                  <div className="col-xs-12">
                    <button
                      className="btn btn-inline-link blue-link text-14"
                      onClick={findIdModalShow}
                      type="button"
                    >
                      <FormattedMessage {...messages.whereCanIFindMyIdNumber} />
                    </button>
                  </div>
                </div>
              </FeatureFlagged>

              <div className="row">
                <div className="col-xs-12 margin-top">
                  <Field
                    label={messages.myDateOfBirth}
                    placeholder="MM/DD/YYYY"
                    name={dateOfBirth}
                    id={dateOfBirth}
                    component={ReduxFormCleanDateInput}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-xs-12">
                  <SpinnerButton
                    type="submit"
                    className="btn btn-big bg-color-brand-button"
                    isLoading={addInsuranceInfoTracker.inProgress}
                  >
                    <FormattedMessage {...messages.submit} />
                  </SpinnerButton>
                </div>
              </div>
              {this.renderOnLaterClick()}
            </form>
          </div>
        </div>

        <FindIdNumModal isOpen={findIdModalOn} handleClose={findIdModalHide} />
      </div>
    );
  }
}

export default compose(
  injectNotification,
  createToggleState('infoModal'),
  createToggleState('findIdModal'),
  connect(
    state => ({
      hasInsuranceId: hasInsuranceIdSelector(state),
    }),
    { addInsuranceInfo, getSettings, getMemberWithPlan },
  ),
  withProps(ownProps => ({
    // reduxForm needs an onSubmit prop...
    onSubmit: ownProps.addInsuranceInfo,
  })),
  reduxForm({
    form: 'addInsuranceInfo',
    destroyOnUnmount: true,
    validate: createValidator(
      Joi.object().keys({
        [insuranceId]: Joi.string()
          .regex(/^[\w\d_]+$/)
          .max(insuranceIdMaxLength)
          .required()
          .options({
            language: {
              any: {
                required: '!!Please enter your insurance id',
              },
              string: {
                length: `up to ${insuranceIdMaxLength} characters`,
                regex: { base: 'can have only numbers, letters and underline' },
              },
            },
          }),
        [dateOfBirth]: DateOfBirthSchema.required().options({
          language: {
            any: {
              required: '!!Please enter your date of birth MM/DD/YYYY',
            },
            date: {
              base: '!!Please enter your date of birth MM/DD/YYYY',
              max: '!!Please enter your date of birth MM/DD/YYYY',
            },
          },
        }),
      }),
    ),
  }),
  actionTracker({ addInsuranceInfoTracker: ADD_INSURANCE_INFO.SOURCE }),
)(EnterInsuranceID);
