import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { compose, pure } from 'recompose';
import queryConnect from '../../../../store/tools/queryString/queryConnect';
import SavingAlaskaFlow from '../../../../flows/savingAlaskaFlow';
import BlueFooter from '../../../../components/molecules/BlueFooter/BlueFooter';
import Header from '../../../../components/ui/header/header';
import CoOpLogo from '../../../../components/branding/co_opLogo';
import { withPublicToken } from '../../../../components/recompose/withPublicToken';

function SavingAlaskaPage({ token, npiList }) {
  return (
    <div>
      <Header>
        <CoOpLogo />
      </Header>
      <main className="has-footer footer-not-fixed">
        <SavingAlaskaFlow token={token} npiList={_.castArray(npiList)} />
      </main>
      <BlueFooter footerClassName="not-fixed-vh white" />
    </div>
  );
}

SavingAlaskaPage.propTypes = {
  token: PropTypes.string.isRequired,
  npiList: PropTypes.string.isRequired,
};

export default compose(
  pure,
  withPublicToken(),
  queryConnect(({ npiList }) => ({ npiList })),
)(SavingAlaskaPage);
