import { compose, withProps, withStateHandlers, withPropsOnChange } from 'recompose';
import Api from '../../../api';
import { withStateFetchers } from '../../../api/injectApi/withStateFetchers';

export default codeType =>
  compose(
    withStateFetchers({
      typeaheadCode: {
        handler: () => Api.clinicsApi.typeaheadCode,
        resultPropName: 'items',
        defaultValue: [],
      }
    }),
    withStateHandlers(
      {},
      {
        onInputChange: (state, { typeaheadCode }) => input => {
          if (!input || input.trim() === '') {
            return { itemsOverride: [] };
          }

          typeaheadCode(codeType, input);
          return { itemsOverride: undefined };
        },
      },
    ),
    withProps(({ items, itemsOverride, typeaheadCodeTracker }) => ({
      items: itemsOverride || items,
      isLoading: typeaheadCodeTracker.inProgress,
      labelKey: 'typeaheadLabel',
    })),
    withPropsOnChange(['items'], ({ items }) => ({
      items: items.map(item => ({
        code: item.code,
        label: item.label,
        typeaheadLabel: `${item.code} - ${item.label}`,
      })),
    })),
  );
