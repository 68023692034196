import React from 'react';

const SvgCareTeamBadge = () => (
  <svg
    width="22px"
    height="19px"
    viewBox="0 0 22 19"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>Group 7</title>
    <g id="web" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="SearchMain-1280-Copy-11" transform="translate(-872.000000, -500.000000)">
        <g id="Group-17" transform="translate(833.000000, 430.000000)">
          <g id="Group-4">
            <g id="Group-7" transform="translate(41.000000, 72.000000)">
              <path
                d="M16.7491047,-0.0262950386 C15.7103026,-0.812760747 14.4443733,-1.11359976 13.2369872,-0.962125493 C12.0269382,-0.810317156 10.8773418,-0.204586601 10.072792,0.815050091 L8.76084227,2.47671803 L7.38504038,0.73931342 C6.55978337,-0.205240716 5.42292913,-0.754037077 4.23863101,-0.869240526 C3.07224616,-0.982701449 1.86145303,-0.675681914 0.861058044,0.0817061302 C-0.186688994,0.874621283 -0.804930473,2.00942624 -0.960814283,3.20110796 C-1.11705033,4.39548238 -0.809313964,5.64881278 0.00222857415,6.67720266 L6.38075457,14.7586399 C6.95761499,15.4387036 7.82261114,15.8756506 8.79597943,15.8756506 C9.84394556,15.8756506 10.7664241,15.3690656 11.3388154,14.5977088 L14.3186281,10.7370032 L17.6080439,6.56937833 C18.4196328,5.54092975 18.7273692,4.28759934 18.5711331,3.09322492 C18.4152493,1.9015432 17.7970078,0.766738247 16.7491047,-0.0262950386 Z"
                id="Combined-Shape"
                stroke="#FFFFFF"
                strokeWidth="2"
                fill="#0AB6C9"
              ></path>
              <path
                d="M10.8025386,1.43449203 C9.52948853,-0.178893039 7.16199344,-0.476028051 5.51472337,0.771101999 C3.86731831,2.01782705 3.56410829,4.33645214 4.83729334,5.9498372 L11.1010236,13.8870275 C11.4922536,14.4186575 12.1259436,14.7677676 12.8460337,14.7677676 C13.5659887,14.7677676 14.1996787,14.4186575 14.5909087,13.8870275 L17.6103189,10.0609924 L10.8025386,1.43449203"
                id="Fill-57-Copy-3"
                stroke="#002E58"
                fill="#FFFFFF"
                transform="translate(10.830187, 7.383884) scale(-1, 1) translate(-10.830187, -7.383884) "
              ></path>
              <path
                d="M6.75248434,1.54237506 C5.47943429,-0.0710100028 3.1119392,-0.368145015 1.46466913,0.878985035 C-0.182735931,2.12571008 -0.485945943,4.44433518 0.787239108,6.05772024 L7.05096936,13.9949106 C7.44219937,14.5265406 8.0758894,14.8756506 8.79597943,14.8756506 C9.51593445,14.8756506 10.1496245,14.5265406 10.5408545,13.9949106 L13.5602646,10.1688754 L6.75248434,1.54237506"
                id="Fill-57-Copy-2"
                stroke="#002E58"
                fill="#FFFFFF"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SvgCareTeamBadge;
