import React from 'react';
import { withRouter } from 'react-router';
import { compose, pure, withHandlers, withProps } from 'recompose';
import { defineMessages, FormattedHTMLMessage } from 'react-intl';
import SCR007Info from '../../screenTemplates/SCR007Info';
import { withTranslatedMessages } from '../../utils/withTranslatedMessages';
import SvgDoctorCircle from '../../assets/svg/doctorCircle';

const messages = defineMessages({
  title: {
    defaultMessage: "You'll need to see a doctor",
    id: 'colorectalFlow.introduction.title',
  },
  buttonText: {
    defaultMessage: 'Find a doctor',
    id: 'colorectalFlow.introduction.buttonText',
  },
  mainText: {
    defaultMessage: `Because you or a family member has a history of cancer,
    you will need to go to a doctor to do your colon cancer screening.
    Find a doctor you'll love and book an appointment.`,
    id: 'colorectalFlow.introduction.mainText',
  },
});

const YoullNeedToSeeADoctor = compose(
  pure,
  withRouter,
  withTranslatedMessages(messages),
  withProps({
    mainText: <FormattedHTMLMessage {...messages.mainText} />,
    svgIcon: <SvgDoctorCircle />,
    buttonType: 'bg-color-brand-button',
  }),
  withHandlers({
    onButtonClick: ({ router }) => () => router.push('/public'),
  }),
)(SCR007Info);

export default YoullNeedToSeeADoctor;
