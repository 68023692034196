/**
 * Created by chenrozenes on 09/03/2017.
 */
import _ from 'lodash';
import Immutable from 'seamless-immutable';

import { SEARCH_FREE_TEXT } from './actions';

const initialState = {
  physicians: [],
  taxonomies: [],
};

export default function autoCompleteProviderReducer(state = initialState, action) {
  state = Immutable(state);

  switch (action.type) {
    case SEARCH_FREE_TEXT.SUCCESS: {
      const { physicians, taxonomies, freeText } = action.payload;

      return state.merge({
        physicians: _.get(physicians, 'data', []),
        taxonomies: _.get(taxonomies, 'data', []),
        freeText,
      });
    }
    default:
      return state;
  }
}
