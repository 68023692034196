/**
 * Created by galgoltzman on 28/05/2017.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, intlShape } from 'react-intl';
import { autobind } from 'core-decorators';
import doctorCompose from '../doctorReviewHOC';
import PillsReviewPage from './pillsReviewPage';
import paths from '../routes/paths';
import IntlEnum from '../../../../../utils/intlEnum';
import * as PositiveDocotorEnum from '../../../../../components/doctorCard/sections/reviewPositiveOptions/positiveDoctorOptions';
import * as Gender from '../../../../../model/enum/gender';
import { genderPicker } from './utils';
import displayProvider from '../../../../../utils/displayProvider';

import './doctorReview.less';

const messages = defineMessages({
  whatDidYouLikeMostAboutDr: {
    defaultMessage: 'What did you like most about {providerDisplayName}?',
    id: 'members.doctorReview.whatDidYouLikeMostAboutDr',
  },
  enoughTimeSpent: {
    defaultMessage: 'Enough time spent',
    id: 'members.doctorReview.enoughTimeSpent',
  },
  goodListener: {
    defaultMessage: 'Good listener',
    id: 'members.doctorReview.goodListener',
  },
  explainsClearly: {
    defaultMessage: 'Explains clearly',
    id: 'members.doctorReview.explainsClearly',
  },
  trustDecisionsFemale: {
    defaultMessage: 'I trust her decisions',
    id: 'members.doctorReview.trustDecisionsFemale',
  },
  trustDecisionsMale: {
    defaultMessage: 'I trust his decisions',
    id: 'members.doctorReview.trustDecisionsMale',
  },
  next: {
    defaultMessage: 'Next',
    id: 'members.doctorReview.next',
  },
});

const PositiveGenderText = {
  [Gender.MALE]: new IntlEnum(
    { value: PositiveDocotorEnum.ENOUGH_TIME_SPENT, message: messages.enoughTimeSpent },
    { value: PositiveDocotorEnum.GOOD_LISTENER, message: messages.goodListener },
    { value: PositiveDocotorEnum.EXPLAINS_CLEARLY, message: messages.explainsClearly },
    { value: PositiveDocotorEnum.TRUST_DECISIONS, message: messages.trustDecisionsMale },
  ),
  [Gender.FEMALE]: new IntlEnum(
    { value: PositiveDocotorEnum.ENOUGH_TIME_SPENT, message: messages.enoughTimeSpent },
    { value: PositiveDocotorEnum.GOOD_LISTENER, message: messages.goodListener },
    { value: PositiveDocotorEnum.EXPLAINS_CLEARLY, message: messages.explainsClearly },
    { value: PositiveDocotorEnum.TRUST_DECISIONS, message: messages.trustDecisionsFemale },
  ),
};

@autobind
class PositiveRatingDr extends React.Component {
  static propTypes = {
    router: PropTypes.object.isRequired,
    params: PropTypes.object.isRequired,
    intl: intlShape.isRequired,
    reviewProcess: PropTypes.object.isRequired,
  };

  onNext() {
    const {
      router,
      reviewProcess: { locationId },
      params: { token },
    } = this.props;
    router.push(!locationId ? paths.doctorTextReview(token) : paths.positiveOffice(token));
  }

  onBack() {
    const {
      router,
      params: { token },
    } = this.props;
    router.push(paths.doctorStarRating(token));
  }

  render() {
    const {
      intl,
      reviewProcess: {
        provider: { suffix, firstName, lastName, gender },
      },
    } = this.props;
    const providerDisplayName = displayProvider(firstName, lastName, suffix);

    const message = intl.formatMessage(messages.whatDidYouLikeMostAboutDr, { providerDisplayName });
    return (
      <PillsReviewPage
        token={this.props.params.token}
        onNext={this.onNext}
        onBack={this.onBack}
        intlEnum={genderPicker(PositiveGenderText, gender)}
        name="positiveDoctor"
        icons="heart-icons"
        headerMessage={message}
      />
    );
  }
}

export default doctorCompose(PositiveRatingDr);
