import _ from 'lodash';
import Promise from 'bluebird';

import PromiseActionType from '../promiseActionType';
import handleLoginResponse from '../auth/handleLoginResponse';
import { VIM, BkmdDomains } from '../../model/enum/bkmdDomain';
import { getUserDomainAccess } from '../auth/selectors';

export const SEARCH_MEMBER = new PromiseActionType('SEARCH_MEMBER');
export const ACTIVATE_WITH_MEMBER_ID = new PromiseActionType('ACTIVATE_WITH_MEMBER_ID');
export const INVITE_MEMBER = new PromiseActionType('ACTIVATE_MEMBER');
export const SET_ACTIVATION_STEP = 'SET_ACTIVATION_STEP';
export const VALIDATE = new PromiseActionType('VALIDATE');
export const ACTIVATE = new PromiseActionType('ACTIVATE');
export const SIGNUP = new PromiseActionType('SIGNUP');
export const VALIDATE_PHONE_NUMBER = new PromiseActionType('VALIDATE_PHONE_NUMBER');

export function setActivationStep(step) {
  return {
    type: SET_ACTIVATION_STEP,
    payload: step,
  };
}

export function validatePhoneNumber(phoneNumber, source) {
  return ({ bkmdApi: { activationApi } }) => ({
    type: VALIDATE_PHONE_NUMBER.SOURCE,
    meta: {
      tracker: VALIDATE_PHONE_NUMBER.SOURCE,
    },
    payload: {
      promise: activationApi.validatePhoneNumber(phoneNumber, source),
    },
  });
}

export function validate(
  phoneNumber,
  firstName,
  middleName,
  lastName,
  dateOfBirth,
  step,
  source,
  validationToken,
  shouldActivate = true,
) {
  return ({ bkmdApi: { activationApi } }) => ({
    type: VALIDATE.SOURCE,
    meta: {
      tracker: `${step}.${VALIDATE.SOURCE}`,
    },
    payload: {
      promise: activationApi.validate(
        phoneNumber,
        firstName,
        middleName,
        lastName,
        dateOfBirth,
        source,
        validationToken,
        shouldActivate,
      ),
    },
  });
}

export function activateWithMemberId(payload) {
  return ({ bkmdApi: { activationApi } }) => ({
    type: ACTIVATE_WITH_MEMBER_ID.SOURCE,
    meta: {
      tracker: ACTIVATE_WITH_MEMBER_ID.SOURCE,
    },
    payload: {
      promise: activationApi.activateWithMemberId(payload),
    },
  });
}

export function activate(shortToken, dateOfBirth, nextPage) {
  return ({ bkmdApi: { activationApi } }) => ({
    type: ACTIVATE.SOURCE,
    meta: {
      tracker: ACTIVATE.SOURCE,
      nextPage,
    },
    payload: {
      promise: activationApi.activateByShortToken(shortToken, dateOfBirth),
    },
  });
}

export function memberSignup(activationToken, email, password, source, phoneNumber) {
  return ({ bkmdApi: { activationApi } }) => ({
    type: SIGNUP.SOURCE,
    meta: {
      tracker: SIGNUP.SOURCE,
    },
    payload: {
      promise: activationApi
        .createVerifiedUser(activationToken, email, password, source, phoneNumber)
        .then(handleLoginResponse),
    },
  });
}

/**
 * Try to find in all domains. fallback to VIM domain.
 */
export function _findMembersByDomains(domains, searchFunc, payload) {
  return Promise.map(domains, domain =>
    Promise.props({
      domain,
      search: searchFunc(payload, domain).reflect(),
    }),
  ).then(results => {
    let found;
    _.each(results, ({ search, domain }) => {
      if (search.isFulfilled()) {
        if (found) {
          // make sure there is only one found
          throw new Error(
            '[Activation/Actions]: search patient in multiple domains found multiple results',
            results,
          );
        }
        found = { ...search.value(), domain };
      }
    });

    if (found) return found;
    return searchFunc(payload, VIM).then(vimResult => ({ ...vimResult, domain: VIM }));
  });
}

export function searchPublicMember(payload) {
  return ({ bkmdApi: { activationApi } }) => {
    const filterDomains = _.filter(BkmdDomains, d => d !== VIM);
    return {
      type: SEARCH_MEMBER.SOURCE,
      meta: {
        tracker: SEARCH_MEMBER.SOURCE,
      },
      payload: {
        promise: activationApi.searchMemberByDomains(filterDomains, payload),
      },
    };
  };
}

export function searchMember(payload) {
  return ({ bkmdApi: { activationApi }, getState }) => {
    const state = getState();
    const domains = getUserDomainAccess(state);
    const filterDomains = _.filter(domains, d => d !== VIM);
    return {
      type: SEARCH_MEMBER.SOURCE,
      meta: {
        tracker: SEARCH_MEMBER.SOURCE,
      },
      payload: {
        promise: activationApi.searchMemberByDomains(filterDomains, payload),
      },
    };
  };
}

export function inviteMemberByProvider({ domainMemberId, phoneNumber, npi, insurer }, domain) {
  return ({ bkmdApi: { activationApi } }) => ({
    type: INVITE_MEMBER.SOURCE,
    meta: {
      tracker: INVITE_MEMBER.SOURCE,
    },
    payload: {
      promise: activationApi.inviteMemberByProvider(
        { domainMemberId, phoneNumber, npi, insurer, notify: true },
        domain,
      ),
    },
  });
}

export function inviteMemberNoNotifications({ domainMemberId, phoneNumber, npi, insurer }, domain) {
  return ({ bkmdApi: { activationApi } }) => ({
    type: INVITE_MEMBER.SOURCE,
    meta: {
      tracker: INVITE_MEMBER.SOURCE,
    },
    payload: {
      promise: activationApi.inviteMemberByProvider(
        { domainMemberId, phoneNumber, npi, insurer, notify: false, validateUserPhone: true },
        domain,
      ),
    },
  });
}
