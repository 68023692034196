export const MemberLogin = 'Member logged in';
export const MemberLogout = 'Member logged out';
export const TaxonomySelected = 'Taxonomy selected';
export const ActivationCompleted = 'Member activation completed';
export const CareCueActionClicked = 'Care cue action clicked';
export const PcpSearchSteering = 'Member Steered to search for PCP';

export const ViewedInfoBanner = 'View Informational Banner';
export const InfoBannerClickedSeeMore = 'Informational Banner See more clicked';
export const ClosedInfoBanner = 'Closed Informational Banner';
export const InfoBannerNextMessages = 'Informational Banner Next Messages';
