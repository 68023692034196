import _ from 'lodash';

export const getLocationFromProvider = (locationId, provider) => _.chain(provider)
    .get('locations')
    .find({ id: locationId })
    .value();

/**
 * Get locationId from provider details, according to locationId parameter.
 * If location is hidden, provide the location that it points to, otherwise return same locationId.
 */
export const getProviderLocationId = (locationId, provider) => _.chain(provider)
    .get('_hiddenLocations')
    .find(({ id: locationId }))
    .get('mergedOffice')
    .defaultTo(locationId)
    .value();
