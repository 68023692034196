import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { defineMessages, FormattedMessage } from 'react-intl';
import { reduxForm, Field } from 'redux-form';
import Joi from 'joi-browser';

import { ReduxFormCleanInput } from '../../../../components/form/reduxForm/components';
import commonMessages from '../../../../store/intl/commonMessages';
import { createValidator } from '../../../../utils/joiValidator';
import { schema } from './identificationSchema';
import Recaptcha from '../../../../components/verification/bkmdRecaptcha';
import SpinnerButton from '../../../../components/ui/spinner/spinnerButton';
import FeatureFlagged from '../../../../components/features/featureFlagged';

const messages = defineMessages({
  signIn: {
    defaultMessage: 'Sign in',
    id: 'members.auth.signIn.signInForm.signIn',
  },
  createYourAccount: {
    defaultMessage: 'Create your account',
    id: 'members.auth.signIn.signInForm.createYourAccount',
  },
  yourMobileOrEmail: {
    defaultMessage: 'Your mobile number or email address',
    id: 'members.auth.signIn.signInForm.yourMobileOrEmail',
  },
  or: {
    defaultMessage: 'or',
    id: 'members.auth.signIn.signInForm.or',
  },
});

const FORM_NAME = 'signIn';

function SignInForm(props) {
  const captchaRow = props.requireCaptcha ? (
    <div className="row captcha">
      <div className="col-md-12">
        <Recaptcha />
      </div>
    </div>
  ) : null;

  return (
    <form className="signin-form" onSubmit={props.handleSubmit} method="post">
      <div className="row">
        <div className="col-xs-12">
          <Field
            label={messages.yourMobileOrEmail}
            name="identifier"
            id="identifier"
            component={ReduxFormCleanInput}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">
          <Field
            label={commonMessages.password}
            name="password"
            type="password"
            component={ReduxFormCleanInput}
            id="passwordInput"
          />
        </div>
      </div>
      {captchaRow}
      <div className="row">
        <div className="col-xs-12">
          <SpinnerButton
            className="btn btn-big btn-sm-block bg-color-brand-button"
            isLoading={props.auth.loading}
          >
            <FormattedMessage {...messages.signIn} />
          </SpinnerButton>
        </div>
      </div>

      <FeatureFlagged uri="loginScreen.signUpButton">
        <div className="row no-margin">
          <div className="col-xs-12 text-center text-16 or-hr">
            <span className="or-text">
              <FormattedMessage {...messages.or} />
            </span>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <button
              className="btn btn-small btn-block btn-outline btn-sm-block text-semibold"
              type="button"
              onClick={props.onCreateAccount}
            >
              <FormattedMessage {...messages.createYourAccount} />
            </button>
          </div>
        </div>
      </FeatureFlagged>
    </form>
  );
}

SignInForm.propTypes = {
  /**
   * Hook method. supplied by redux-forms
   */
  handleSubmit: PropTypes.func.isRequired,

  /**
   * Hook method for account creation
   */
  onCreateAccount: PropTypes.func.isRequired,

  /**
   * Auth status, used to check if the page is loading
   */
  auth: PropTypes.object.isRequired,
  requireCaptcha: PropTypes.bool.isRequired,
};

export default compose(
  reduxForm({
    form: FORM_NAME,
    validate: createValidator(Joi.object().keys(schema)),
  }),
)(SignInForm);
