import * as storageHelper from '../../../utils/storage/storageHelper';

export const updateFlowStateInStorage = (storageKey, flowState) => {
  storageHelper.addOrUpdateStorageItem(storageKey, JSON.stringify(flowState));
};

export const getFlowStateFromStorage = storageKey => {
  const values = storageHelper.getStorageItemValue(storageKey);
  let res;
  if (values && values !== 'undefined') {
    res = JSON.parse(values);
  }
  return res;
};

export const clearFlowStateFromStorage = storageKey => {
  storageHelper.removeStorageItem(storageKey);
};

export const createStorageKey = (name, flowId) => `${name}_${flowId}`;
