import _ from 'lodash';
import { connect } from 'react-redux';

import { applyMiddleware, applyHOCs as oldApplyHOCs } from '../../../../api/injectApi';
import { auth, logger as oldLogger } from '../../../../api/injectApi/middlewares';

import { applyHOCs, subscribe } from '../../../../api/injectApi/withFetchers';
import { authTouch, loginExpired } from '../../../../store/auth/actions';
import { clearGetMemberTimeout } from '../../../../store/member/actions';
import * as subscribers from '../../../../api/injectApi/subscribers';
import VimSdkClient from '../../../../utils/sdk/vimSdkClient';
import { clearMemberSession } from '../../../../utils/storage/memberSession';

const loginExpiredByDirectory = () => {
  VimSdkClient.closeBookingDialog();
  clearGetMemberTimeout();
  clearMemberSession();
  return loginExpired();
};

// old implementation - TODO remove this
// Add the authTouch and loginExpired action creators to the props injected to the fetch HOCs
oldApplyHOCs(
  connect(
    null,
    { authTouch, loginExpired: loginExpiredByDirectory },
  ),
);

applyMiddleware(auth, oldLogger);
// old implementation - TODO remove this

// new implementation
// Add the authTouch and loginExpired action creators to the props injected to the fetch HOCs
applyHOCs(
  connect(
    null,
    { authTouch, loginExpired: loginExpiredByDirectory },
  ),
);

_.forEach(subscribers, subscriber => subscribe(subscriber));
// new implementation
