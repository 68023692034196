/**
 * Created by chenrozenes on 16/10/2017.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  findPCP: {
    defaultMessage: 'Find a primary care doctor',
    id: 'careCues.actionText.findPCP',
  },
  findWomenHealthProvider: {
    defaultMessage: 'Find a women\'s health provider',
    id: 'careCues.actionText.findWomenHealthProvider',
  },
  findEyeDoctor: {
    defaultMessage: 'Find an eye doctor',
    id: 'careCues.actionText.findEyeDoctor',
  },
});
