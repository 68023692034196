import { autobind } from 'core-decorators';
import BkmdApi from './bkmdApi';

/**
 * Represents the API for microservices
 */
@autobind
class ServicesApi extends BkmdApi {
  constructor(fetch) {
    super(fetch, '/services/');
  }

  call(service, action, data = {}, domain) {
    return this.post(`open/${service}/${action}`, data, domain);
  }

  callSecure(service, action, data = {}, domain) {
    return this.post(`secure/${service}/${action}`, data, domain);
  }
}

export default ServicesApi;
