import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { autobind } from 'core-decorators';
import { FlowShape } from './shapes';
import Utils from '../../../utils/util';

/**
 * Component which managers flow between multiple steps
 * The FlowManager saves the steps results and current step on the query string, and will know to
 * navigate between steps including browser back button cases
 *
 * The FlowManager will send a special prop to every child (step) called 'flow' which contains
 * methods and props to control the flow (next, back, finish, flowState, etc.) aka flow control obj
 *
 * To define steps:
 * 1. Add the step component as a child + "stepName" prop
 * 2. Add the step to the stepsMeta prop of the FlowManager component
 */
@autobind
export default class FlowManager extends React.PureComponent {
  static propTypes = {
    /**
     * Hook function when flow starts
     */
    onStart: PropTypes.func,
    /**
     * Hook function when flow finishes
     */
    onFinish: PropTypes.func,
    /**
     * Hook function when step changes
     */
    onStepChange: PropTypes.func,
    /**
     * When true, flow will finish when the FlowManager component will unmount
     */
    finishOnUnmount: PropTypes.bool,
    /**
     * When true, flow will start when the FlowManager component will mount
     */
    startOnMount: PropTypes.bool,
    /**
     * Flow control object
     */
    flow: FlowShape.isRequired,
    /**
     * Steps can be provided as children or as a prop (recommended)
     */
    renderStep: PropTypes.func,
  };

  static defaultProps = {
    finishOnUnmount: true,
    startOnMount: true,
    onStart: _.noop,
    onFinish: _.noop,
    onStepChange: _.noop,
    renderStep: undefined,
  };

  componentWillMount() {
    if (this.props.startOnMount) {
      this.props.flow.start();
      this.props.onStart();
    }
  }

  componentWillReceiveProps(nextProps) {
    Utils.inspectChange(this.props.flow, nextProps.flow, [{
      uri: 'currentStep',
      action: () => this.props.onStepChange(
        this.props.flow.currentStep, nextProps.flow.currentStep),
    }]);

    const hasStarted = !this.props.flow.isActive && nextProps.flow.isActive;
    const hasFinished = this.props.flow.isActive && !nextProps.flow.isActive;

    if (hasStarted) {
      this.props.onStart();
    }
    if (hasFinished) {
      this.props.onFinish();
    }
  }

  componentWillUnmount() {
    if (this.props.finishOnUnmount) {
      this.props.flow.finish();
      this.props.onFinish();
    }
  }

  render() {
    const { children, flow, renderStep } = this.props;
    if (!renderStep) {
      // Transforms children to actual array
      const stepsElements = React.Children.toArray(children);

      // Find child component by the step name
      const element = stepsElements.find(({ props }) => flow.currentStep === props.stepName);
      if (!element) return null;

      const mergedProps = _.assign({}, element.props, { flow }, flow.stepProps);
      return React.cloneElement(element, mergedProps);
    }

    const step = renderStep(flow.currentStep);
    if (!step) return null;
    const { component: Component, props } = step;
    const mergedProps = _.assign({}, props, { flow }, flow.stepProps);
    return <Component {...mergedProps} />;
  }
}

export const externalParams = (state, props) => ({
  name: props.name,
  flowId: props.flowId,
  stepsMeta: props.stepsMeta,
  initialStepName: props.initialStepName,
});
