import React from 'react';
import { noop } from 'lodash';
import PropTypes from 'prop-types';
import { withState, withHandlers, compose, pure } from 'recompose';
import moment from 'moment';

import CleanInput from './cleanInput';
import { MessageShape } from '../ui/intlString';

export const defaultDateFormat = 'MM/DD/YYYY';

const DateInput = props => {
  const {
    value,
    onRef,
    onChange,
    onBlur,
    label,
    placeholder,
    forceRender,
    fixedLabel,
    showPlaceholderOnlyOnFocus,
    ...rest
  } = props;
  return (
    <CleanInput
      inputRef={onRef}
      label={label}
      placeholder={placeholder || defaultDateFormat}
      forceRender={forceRender}
      value={value}
      fixedLabel={fixedLabel}
      onChange={onChange}
      onBlur={onBlur}
      showPlaceholderOnlyOnFocus={showPlaceholderOnlyOnFocus}
      {...rest}
    />
  );
};

DateInput.propTypes = {
  value: PropTypes.string.isRequired,
  onRef: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  label: MessageShape,
  placeholder: MessageShape,
  forceRender: PropTypes.number.isRequired,
  fixedLabel: PropTypes.bool.isRequired,
  showPlaceholderOnlyOnFocus: PropTypes.bool.isRequired,
};

DateInput.defaultProps = {
  value: '',
  label: undefined,
  onChange: noop,
  onBlur: noop,
  placeholder: undefined,
  forceRender: 0,
  fixedLabel: true,
  showPlaceholderOnlyOnFocus: false,
};

const getFormattedDate = value => {
  const date = moment(value, defaultDateFormat);

  return date.isValid() ? date.format(defaultDateFormat) : null;
};

export default compose(
  pure,
  withState('element', 'setElement', null),
  withHandlers({
    onRef: ({ setElement }) => element => setElement(element),
    onBlur: ({ value, onBlur, onChange }) => event => {
      onBlur(event);
      const formattedDate = getFormattedDate(value);
      if (formattedDate) {
        onChange(formattedDate);
      }
    },
    onChange: ({ onChange }) => event => {
      const { value } = event.target;
      const sanitizedValue = value.replace(/[^\d/\\.]/g, '');
      onChange(sanitizedValue);
    },
  }),
)(DateInput);
