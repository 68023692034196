/**
 * Created by guyavarham on 13/12/2017.
 */

import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import { connect } from 'react-redux';
import CleanTypeahead from '../form/cleanTypeahead';

@autobind
class NpiTypeahead extends React.Component {

  static propTypes = {
    onChange: PropTypes.func.isRequired,
    providers: PropTypes.array.isRequired,
  };

  onChange(newValue) {
    this.props.onChange(_.get(newValue, 'value'));
  }

  getOptions() {
    const { providers } = this.props;
    return _.map(providers, provider => {
      const { firstName, lastName, npi } = provider;
      return ({
        name: `${npi} : ${firstName} ${lastName}`,
        value: npi,
      });
    });
  }

  render() {
    return (
      <div className="margin-bottom-clean-form">
        <CleanTypeahead
          options={this.getOptions()}
          onOptionSelection={this.onChange}
          placeholder="Search by name or NPI"
          {...this.props}
        />
      </div>
    );
  }
}

export default connect(
  (state, props) => ({ providers: props.providers || props.providerSelector(state) }),
)(NpiTypeahead);
