/**
 * Created by chenrozenes on 23/04/2017.
 */
import React from 'react';
import { ColumnDefinition } from 'griddle-react';

/**
 * Wrapper to griddle column component for defining columns in BkmdGrid
 * @param props
 * @returns {XML}
 * @constructor
 */
export default function BkmdGridColumn(props) {
  return (<ColumnDefinition {...props} />);
}
