/**
 * Created by galgoltzman on 26/03/2017.
 */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { IntlString, MessageShape } from '../ui/intlString';

import './cleanRadioButton.less';

class CleanRadioButton extends React.Component {
  static propTypes = {
    /**
     * Unique id not give to other checkboxes in the form
     */
    id: PropTypes.string.isRequired,
    /**
     * Sets the value returned in onChange function when clicking a radio button
     */
    radioValue: PropTypes.string,
    /**
     * Sets the value of the radio (checked or not)
     */
    value: PropTypes.bool,
    /**
     * Sets component labels text
     */
    label: MessageShape,
    /**
     * Sets component css class
     */
    className: PropTypes.string,
    /**
     * Sets radio button group name
     */
    radioGroup: PropTypes.string,
  };

  static defaultProps = {
    radioValue: undefined,
    label: undefined,
    className: undefined,
    radioGroup: undefined,
    value: false,
  };

  render() {
    const { radioValue, value, label, id, className, radioGroup, ...other } = this.props;
    return (
      <div className={classNames('clean-radio-button', className)}>
        <input
          type="radio"
          id={id}
          checked={value}
          value={radioValue}
          name={radioGroup}
          {...other}
        />
        <label htmlFor={id} />
        <label htmlFor={id}>
          <IntlString message={label} />
        </label>
      </div>
    );
  }
}

export default CleanRadioButton;
