import { safeLocalStorage } from './storage/localStorage';

export const isLogEnabled = () =>
  process.env.NODE_ENV !== 'production' || safeLocalStorage.getItem('VIM_CLIENT_LOGGER') === 'true';

export const log = (message, ...args) => {
  if (isLogEnabled()) {
    console.log(message, ...args); // eslint-disable-line
  }
};

export const error = (message, ...args) => {
  if (isLogEnabled()) {
    console.error(message, ...args); // eslint-disable-line
  }
};

export const debug = (message, ...args) => {
  if (isLogEnabled()) {
    console.debug(message, ...args); // eslint-disable-line
  }
};
