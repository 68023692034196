/**
 * Created by moran on 11/2/16.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '../../storybook/src/utils/theme';

function SvgNoResults({ theme }) {
  /* eslint-disable */
  return (
    <svg aria-hidden="true" focusable="false" className="svg-icon svg-no-results" width="32px" height="32px" viewBox="0 0 32 32" version="1.1">
      <defs>
        <polygon id="path-1" points="11.5 0.49975 11.5 11.5 5.999875 11.5 0.49975 11.5 0.49975 0.49975 11.5 0.49975"></polygon>
      </defs>
      <g id="Style-guide" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Two-color-icons" transform="translate(-69.000000, -556.000000)">
          <g id="Page-1" transform="translate(69.000000, 556.000000)">
            <path d="M13,26 C5.832,26 0,20.168 0,13 C0,5.832 5.832,0 13,0 C15.285,0 17.534,0.602 19.503,1.741 C19.98,2.018 20.145,2.629 19.867,3.107 C19.592,3.585 18.98,3.749 18.501,3.472 C16.837,2.509 14.935,2 13,2 C6.935,2 2,6.935 2,13 C2,19.065 6.935,24 13,24 C19.065,24 24,19.065 24,13 C24,11.399 23.664,9.856 23.001,8.412 C22.771,7.91 22.991,7.316 23.493,7.086 C23.993,6.856 24.588,7.076 24.819,7.578 C25.603,9.285 26,11.109 26,13 C26,20.168 20.168,26 13,26" id="Fill-1" fill={theme.mainColor}></path>
            <g id="Group-5" transform="translate(20.500000, 20.500000)">
              <mask id="mask-2" fill="white">
                <use xlinkHref="#path-1"></use>
              </mask>
              <g id="Clip-4"></g>
              <path d="M10.5,11.5 C10.244,11.5 9.988,11.402 9.793,11.207 L0.793,2.207 C0.402,1.816 0.402,1.184 0.793,0.793 C1.184,0.402 1.816,0.402 2.207,0.793 L11.207,9.793 C11.598,10.184 11.598,10.816 11.207,11.207 C11.012,11.402 10.756,11.5 10.5,11.5" id="Fill-3" fill={theme.mainColor} mask="url(#mask-2)"></path>
            </g>
            <path d="M21.5,2.835 C20.683,4.167 20,5.519 20,6 C20,6.827 20.673,7.5 21.5,7.5 C22.327,7.5 23,6.827 23,6 C23,5.519 22.317,4.167 21.5,2.835 M21.5,9.5 C19.57,9.5 18,7.93 18,6 C18,4.429 20.049,1.341 20.677,0.432 C21.052,-0.109 21.948,-0.109 22.323,0.432 C22.951,1.341 25,4.429 25,6 C25,7.93 23.43,9.5 21.5,9.5" id="Fill-6" fill={theme.secondaryColor}></path>
            <path d="M11,11.5 C11,12.329 10.328,13 9.5,13 C8.672,13 8,12.329 8,11.5 C8,10.671 8.672,10 9.5,10 C10.328,10 11,10.671 11,11.5" id="Fill-8" fill={theme.mainColor}></path>
            <path d="M18,11.5 C18,12.329 17.328,13 16.5,13 C15.672,13 15,12.329 15,11.5 C15,10.671 15.672,10 16.5,10 C17.328,10 18,10.671 18,11.5" id="Fill-10" fill={theme.mainColor}></path>
          </g>
        </g>
      </g>
    </svg>
  );
  /* eslint-enable */
}

SvgNoResults.propTypes = {
  theme: PropTypes.object.isRequired,
};

export default withTheme(SvgNoResults);
