import { autobind } from 'core-decorators';
import BkmdApi from './bkmdApi';

// eslint-disable-next-line
const SERVICE_NAME = 'interventionFlowData';

@autobind
class InterventionFlowDataApi extends BkmdApi {
  constructor(fetch) {
    super(fetch, '/interventionFlowData');
  }

  insertInterventionFlowData({ flowId, type, data }) {
    return this.post('/insertInterventionFlowData', {
      flowId,
      type,
      data,
    }).then(({ data }) => data);
  }
}

export default InterventionFlowDataApi;
