/**
 * Created by galgoltzman on 14/05/2017.
 */
import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import PropTypes from 'prop-types';
import { defineMessages, FormattedMessage } from 'react-intl';
import _ from 'lodash';
import SettingsLayout from '../settingsLayout';
import * as selectors from '../../../../../store/member/selectors';
import Layout from '../../layout/layout';
import { paths } from '../paths';
import SvgLocationsStars from '../../../../../assets/svg/locationStars';
import { getFeature } from '../../../../../store/feature/selectors';

const messages = defineMessages({
  home: {
    defaultMessage: 'Home',
    id: 'members.settings.locations.home',
  },
  work: {
    defaultMessage: 'Work',
    id: 'members.settings.locations.work',
  },
  enterHomeAddress: {
    defaultMessage: 'Set your home address',
    id: 'members.members.settings.locations.enterHomeAddress',
  },
  enterWorkAddress: {
    defaultMessage: 'Set your work address',
    id: 'members.members.settings.locations.enterWorkAddress',
  },
  footerNote: {
    defaultMessage: 'FYI, these changes are not reflected on your {domainName} account.',
    id: 'members.settings.footerNote',
  },
  locations: {
    defaultMessage: 'Your locations',
    id: 'members.settings.locations',
  },
});

class LocationSettings extends React.Component {
  static propTypes = {
    /**
     * member settings object.
     */
    /* eslint-disable react/no-unused-prop-types */
    home: PropTypes.object.isRequired,
    work: PropTypes.object.isRequired,
    /* eslint-enable react/no-unused-prop-types */
    /**
     * redux state object.
     */
    // state: PropTypes.object.isRequired,
    domainName: PropTypes.string.isRequired,
  };

  getHomeAddressMessage() {
    return _.get(this, 'props.home.address', <FormattedMessage {...messages.enterHomeAddress} />);
  }

  getWorkAddressMessage() {
    return _.get(this, 'props.work.address', <FormattedMessage {...messages.enterWorkAddress} />);
  }

  render() {
    return (
      <Layout
        hideLogo
        centeredBackBtn
        previousPageName="settings"
        previousPageRoute={paths.settingsHome()}
      >
        <SettingsLayout>
          <div className="left-img-title">
            <SvgLocationsStars />
            <h1 className="left-img-title-text text-16 text-dark text-semibold">
              <FormattedMessage {...messages.locations} />
            </h1>
          </div>

          <ul className="settings-menu-list">
            <li>
              <Link to={paths.homeAddress()}>
                <i className="icon-home-2 item-icon" />
                <div className="item-content">
                  <span className="text-dark text-16 text-semibold">
                    <FormattedMessage {...messages.home} />
                  </span>
                  <div className="text-12">{this.getHomeAddressMessage()}</div>
                </div>
                <i className="icon-chevron-right item-arrow" />
              </Link>
            </li>
            <li>
              <Link to={paths.workAddress()}>
                <i className="icon-suitcase item-icon" />
                <div className="item-content">
                  <span className="text-dark text-16 text-semibold">
                    <FormattedMessage {...messages.work} />
                  </span>
                  <div className="text-12">{this.getWorkAddressMessage()}</div>
                </div>
                <i className="icon-chevron-right item-arrow" />
              </Link>
            </li>
          </ul>
          <div className="text-14">
            <FormattedMessage
              {...messages.footerNote}
              values={{ domainName: this.props.domainName }}
            />
          </div>
        </SettingsLayout>
      </Layout>
    );
  }
}

export default compose(
  connect(state => ({
    memberSettings: state.member.settings,
    home: selectors.homeSetting(state),
    work: selectors.workSetting(state),
    domainName: getFeature(state, 'site.clientName'),
  })),
)(LocationSettings);
