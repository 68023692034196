import { compose, withHandlers } from 'recompose';
import { withFetchOnMount } from '../../../../api/injectApi';
import Api from '../../../../api';
import ThreeBounceSpinner from '../../../../components/ui/spinner/threeBounceSpinner';
import withActivationFlow from '../../../../components/withActivationFlow/withActivationFlow';
import { pathsWithQuery } from '../../../../utils/pathsWithQuery';
import queryConnect from '../../../../store/tools/queryString/queryConnect';

export default compose(
  withActivationFlow({
    activationCampaignSource: 'pcpSelection',
    activationNextPage: '/publicPcpSelection',
  }),
  queryConnect(({ next }) => ({ next })),
  withHandlers({
    redirectToPcpSelection: ({ router, next }) => token => {
      const path = pathsWithQuery(`/publicPCPSelection/${token}`, { next });
      router.push(path);
    },
  }),
  withFetchOnMount(
    ({ activationToken }) => Api.pcpSelectionApi.generateToken(activationToken),
    'getPCPSelectionToken',
    {
      onSuccess: (props, result) => props.redirectToPcpSelection(result),
      onError: props => props.redirectToActivationFlow(),
    },
  ),
)(ThreeBounceSpinner);
