import _ from 'lodash';
import React from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';

import analytics from '../../store/tools/analytics/analyticsHoc';
import '../ui/pillsRadioGroup/pillsRadioGroup.less';
import './cleanRadioGroup.less';

class CleanRadioGroup extends React.Component {
  static defaultProps = {
    displayedValues: [],
    label: '',
    labelClass: '',
    errorText: '',
    wrapClass: '',
    optionsWrapClass: '',
    horizontal: false,
    equalBtnSize: false,
    marginBetweenOptions: null,
    btnStyle: '',
    value: '',
    simpleSelect: false,
    options: null,
    intlEnum: null,
    btnStyleGenerator: null,
  };

  static propTypes = {
    /**
     * Intl Enum to show
     */
    intlEnum: PropTypes.object,
    /**
     * function to be called on click
     */
    onChange: PropTypes.any.isRequired,
    /**
     * value for the component
     */
    value: PropTypes.any,
    /**
     * An array of the intlEnum values to display as buttons.
     * Default is display all.
     */
    displayedValues: PropTypes.array,
    /**
     * Label for the group
     * Either a string or an intl object for adding question text on top of the buttons
     */
    label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    /**
     * Class name to apply on the component label
     */
    labelClass: PropTypes.string,
    /**
     * error text
     */
    errorText: PropTypes.string,
    /**
     * when set, btns will have the same width
     */
    equalBtnSize: PropTypes.bool,
    /**
     * Boolean value indicating whether to display the component in a horizontal way
     * (i.e question to the left, answers to the right).
     * Default is false, display vertical (i.e question on top, answers below it)
     */
    horizontal: PropTypes.bool,
    /**
     * wrapping class
     */
    wrapClass: PropTypes.any,
    /**
     * wrapping class for the options part
     */
    optionsWrapClass: PropTypes.any,
    /**
     * intl supplied via intlInject
     */
    intl: intlShape.isRequired,
    /**
     * if set, defines a margin between the options. The value is by percentage
     */
    marginBetweenOptions: PropTypes.number,
    /**
     * class for btn.
     */
    btnStyle: PropTypes.string,
    btnStyleGenerator: PropTypes.func,
    id: PropTypes.string.isRequired,

    simpleSelect: PropTypes.bool,
    options: PropTypes.array,
  };

  selectionChanged = value => {
    const { onChange } = this.props;
    if (onChange) onChange(value);
  };

  renderOption = option => {
    const {
      value,
      intlEnum,
      equalBtnSize,
      marginBetweenOptions,
      btnStyle,
      btnStyleGenerator,
    } = this.props;
    const click = e => {
      e.preventDefault();
      this.selectionChanged(option.value);
    };

    const numOfOptions = _.keys(intlEnum).length;
    const btnWidthPercentage = 100 / numOfOptions;

    const btnWidthWithoutMargin = !marginBetweenOptions
      ? btnWidthPercentage
      : btnWidthPercentage - marginBetweenOptions;

    const widthStyle = !equalBtnSize ? {} : { width: `${btnWidthWithoutMargin}%` };

    const marginStyle = !marginBetweenOptions
      ? {}
      : {
        marginLeft: `${marginBetweenOptions / 2}%`,
        marginRight: `${marginBetweenOptions / 2}%`,
      };

    const widthAndMarginStyle = _.extend(widthStyle, marginStyle);

    const buttonStyle = _.isFunction(btnStyleGenerator)
      ? btnStyleGenerator(option, this.props)
      : btnStyle;
    return (
      <button
        key={option.value}
        className={classNames(
          'text-semibold btn btn-outline btn-small',
          { selected: value === option.value },
          buttonStyle,
        )}
        style={widthAndMarginStyle}
        onClick={click}
      >
        {option.label}
      </button>
    );
  };

  renderOptions = () => {
    const { intlEnum, intl, displayedValues, simpleSelect, options } = this.props;
    const selectOptions = simpleSelect
      ? options
      : intlEnum.toSelectOptions(intl, { allowedValues: displayedValues });
    return _.map(selectOptions, option => this.renderOption(option));
  };

  renderQuestion = () => {
    const { label, labelClass, errorText, horizontal } = this.props;
    if (!label) return null;

    return (
      <div className={classNames({ 'col-xs-12 text-center': !horizontal })}>
        <div className={classNames('question', { row: !horizontal, 'has-error': !!errorText })}>
          <span className="validation-message top">{errorText}</span>
          <span className={classNames('question-text', labelClass)}>
            {_.isString(label) ? label : <FormattedMessage {...label} />}
          </span>
        </div>
      </div>
    );
  };

  render = () => {
    const { horizontal, wrapClass, optionsWrapClass, id } = this.props;
    return (
      <div
        id={id}
        className={classNames('q-and-a-item', { 'horizontal-q-and-a': horizontal }, wrapClass)}
      >
        {this.renderQuestion()}
        <div className={classNames('answer', optionsWrapClass)}>{this.renderOptions()}</div>
      </div>
    );
  };
}

export default compose(
  injectIntl,
  component =>
    analytics(component, false, [{ event: 'onChange', eventData: value => ({ value }) }]),
)(CleanRadioGroup);
