/* eslint-disable no-unused-vars */
/* eslint-disable spaced-comment */
import React from 'react';
import PropTypes from 'prop-types';
import { compose, pure } from 'recompose';
import SearchPreview from '../../../components/searchDoctors/searchPreview';
import {
  createToggleState,
  createToggleStatePropTypes,
} from '../../../components/recompose/toggleStateComponent';

import './locationRow.less';
import ProviderFilterModal from '../../../components/organisms/providerFilterModal/providerFilterModal';

function LocationRow({
  inputFilters,
  searchOptionsModalShow,
  searchOptionsModalOn,
  searchOptionsModalHide,
  onApply,
  defaultLocationOptions,
}) {
  return (
    <div className="search-location-row">
      <SearchPreview onClick={searchOptionsModalShow} chosenLocation={inputFilters.location} />
      <ProviderFilterModal
        isOpen={searchOptionsModalOn}
        onClose={searchOptionsModalHide}
        filters={inputFilters}
        onApply={onApply}
        defaultLocationOptions={defaultLocationOptions}
      />
    </div>
  );
}

LocationRow.propTypes = {
  ...createToggleStatePropTypes('searchOptionsModal'),
  inputFilters: PropTypes.object.isRequired,
  onApply: PropTypes.func.isRequired,
};

LocationRow.defaultProps = {
  inputFilters: {},
};

export default compose(
  pure,
  createToggleState('searchOptionsModal'),
)(LocationRow);
