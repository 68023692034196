/**
 * Created by galgoltzman on 11/06/2017.
 */
import React from 'react';
import PropTypes from 'prop-types';

import CoOpLogo from '../../../../../components/branding/co_opLogo';
import Header from '../../../../../components/ui/header/header';
import FeatureFlagged from '../../../../../components/features/featureFlagged';
import LanguageMenu from '../../../../../components/molecules/LanguageMenu/LanguageMenu';

function ActivationHeader(props) {
  return (
    <Header className="no-padding">
      {props.goBack ? (
        <button onClick={props.goBack} className="header-back-btn" type="button">
          <i className="icon-back-arrow" />
        </button>
      ) : null}
      <CoOpLogo className="logos" externalPageLogo />
      <ul className="header-right-nav show-xs">
        <FeatureFlagged renderLi uri="layout.changeLanguage">
          <LanguageMenu id="languageMenuActivation" />
        </FeatureFlagged>
      </ul>
    </Header>
  );
}

ActivationHeader.propTypes = {
  /**
   * function to route back to the previous step in the flow
   */
  goBack: PropTypes.func,
};

ActivationHeader.defaultProps = {
  goBack: undefined,
};

export default ActivationHeader;
