import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, FormattedMessage } from 'react-intl';
import { autobind } from 'core-decorators';
import { connect } from 'react-redux';
import { compose, pure, withProps } from 'recompose';

import PhoneLink from '../../../../components/ui/links/phoneLink';
import commonMessages from '../../../../store/intl/commonMessages';
import Layout from '../layout/layout';
import { member as getMemberInfo } from '../../../../store/member/selectors';
import PremeraLogos from '../../../../components/branding/premeraLogos/premeraLogos';
import { loadingUntil } from '../../../../components/recompose/loadingUntil';

import './help.less';
import Formatter from '../../../../utils/formatter';

const messages = defineMessages({
  title: {
    defaultMessage: 'Help',
    id: 'activation.helpModal.title',
  },
  healthPlanSupport: {
    defaultMessage: 'Health plan support',
    id: 'activation.helpModal.healthPlanSupport',
  },
  forQuestions: {
    defaultMessage: 'For questions about your plan and coverage please call:',
    id: 'activation.helpModal.forQuestions',
  },
  TTY: {
    defaultMessage: 'TTY: 711',
    id: 'activation.helpModal.TTY',
  },
  outsideTheUs: {
    defaultMessage: 'Outside the US:',
    id: 'activation.helpModal.outsideTheUs',
  },
  monFriFrom: {
    defaultMessage: 'Mon-Fri from 8AM - 5PM PT',
    id: 'activation.helpModal.monFriFrom',
  },
  lookingForSomethingElse: {
    defaultMessage: 'Looking for something else?',
    id: 'activation.helpModal.lookingForSomethingElse',
  },
  goToTheWebsite: {
    defaultMessage: 'Go to the website',
    id: 'activation.helpModal.goToTheWebsite',
  },
  sendUsAnEmail: {
    defaultMessage: 'Send us an email',
    id: 'activation.helpModal.sendUsAnEmail',
  },
  wellGetBackToYou: {
    defaultMessage: 'We’ll get back to you within 24 hours.',
    id: 'activation.helpModal.wellGetBackToYou',
  },
  bookmdSupportEmail: {
    defaultMessage: 'support@getvim.com',
    id: 'activation.helpModal.bookmdSupportEmail',
  },
  openASupportTicket: {
    defaultMessage: 'Open a support ticket',
    id: 'activation.helpModal.openASupportTicket',
  },
  openATicket: {
    defaultMessage: 'Open a ticket',
    id: 'activation.helpModal.openATicket',
  },
});

const messagesByContext = {
  AK: defineMessages({
    subtitle1: {
      defaultMessage: 'Contact Premera Blue Cross Blue Shield of Alaska',
      id: 'messagesByContext.AK.subtitle1',
    },
    visitThePremeraBlueCross: {
      defaultMessage: 'Visit the Premera Blue Cross Blue Shield of Alaska website:',
      id: 'messagesByContext.AK.visitThePremeraBlueCross',
    },
    insurerWebsite: {
      defaultMessage: 'https://www.premera.com/ak/visitor/contact-us/',
      id: 'messagesByContext.AK.insurerWebsite',
    },
    healthPlanSupportNumber: {
      defaultMessage: '(800) 508 4722',
      id: 'messagesByContext.AK.healthPlanSupportNumber',
    },
  }),
  WA: defineMessages({
    subtitle1: {
      defaultMessage: 'Contact Premera Blue Cross',
      id: 'messagesByContext.WA.subtitle1',
    },
    visitThePremeraBlueCross: {
      defaultMessage: 'Visit the Premera Blue Cross website:',
      id: 'messagesByContext.WA.visitThePremeraBlueCross',
    },
    insurerWebsite: {
      defaultMessage: 'https://www.premera.com/wa/visitor/',
      id: 'messagesByContext.WA.insurerWebsite',
    },
    healthPlanSupportNumber: {
      defaultMessage: '(800) 722 1471',
      id: 'messagesByContext.WA.healthPlanSupportNumber',
    },
  }),
  PK: defineMessages({
    subtitle1: {
      defaultMessage: 'Contact Premera Blue Cross',
      id: 'messagesByContext.PK.subtitle1',
    },
    visitThePremeraBlueCross: {
      defaultMessage: 'Visit the Premera Blue Cross website:',
      id: 'messagesByContext.PK.visitThePremeraBlueCross',
    },
    insurerWebsite: {
      defaultMessage: 'https://www.premera.com/wa/visitor/',
      id: 'messagesByContext.PK.insurerWebsite',
    },
    healthPlanSupportNumber: {
      defaultMessage: '(855) 250 7325',
      id: 'messagesByContext.PK.healthPlanSupportNumber',
    },
  }),
};

@autobind
class PremeraHelpContent extends React.Component {
  static propTypes = {
    contextMessages: PropTypes.object.isRequired,
    healthPlanSupportNumber: PropTypes.string,
    insurerWebsite: PropTypes.string,
    openHours: PropTypes.string,
    outOfCountryPhone: PropTypes.string,
  };

  static defaultProps = {
    healthPlanSupportNumber: undefined,
    insurerWebsite: undefined,
    openHours: undefined,
    outOfCountryPhone: undefined,
  };

  render() {
    const {
      contextMessages,
      healthPlanSupportNumber,
      insurerWebsite,
      openHours,
      outOfCountryPhone,
    } = this.props;
    return (
      <Layout footerClassName="xs-not-fixed white">
        <div className="help-container">
          <div className="help-content">
            <div className="text-center">
              <PremeraLogos contextOnly />
            </div>
            <div>
              <div className="help-top">
                <div className="row margin-top-20">
                  <div className="col-xs-12 text-center">
                    <span className="title-brand-1 text-18 text-dark">
                      <FormattedMessage {...contextMessages.subtitle1} />
                    </span>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-sm-4 col-sm-offset-2">
                  <div className="contact-section">
                    <i className="icon-iphone contact-icon" />
                    <div className="text-semibold text-dark text-16">
                      <FormattedMessage {...messages.healthPlanSupport} />
                    </div>
                    <div className="margin-top-5 text-16">
                      <FormattedMessage {...messages.forQuestions} />
                    </div>
                    <div className="margin-top-5 text-16 font-color-brand-link">
                      <PhoneLink
                        phoneNumber={
                          healthPlanSupportNumber || contextMessages.healthPlanSupportNumber
                        }
                      />
                    </div>
                    <div className="text-16">
                      <FormattedMessage {...messages.TTY} />
                    </div>
                    <div className="margin-top-5 text-16">
                      <FormattedMessage {...messages.outsideTheUs} />
                    </div>
                    <div className="text-16 font-color-brand-link">
                      <PhoneLink
                        phoneNumber={
                          outOfCountryPhone || commonMessages.healthPlanSupportOutsideUSNumber
                        }
                      />
                    </div>
                    <div className="margin-top-5 text-14">
                      {openHours || <FormattedMessage {...messages.monFriFrom} />}
                    </div>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="contact-section">
                    <i className="icon-browser contact-icon" />
                    <div className="text-semibold text-dark text-16">
                      <FormattedMessage {...messages.lookingForSomethingElse} />
                    </div>
                    <div className="margin-top-5 text-16">
                      <FormattedMessage {...contextMessages.visitThePremeraBlueCross} />
                    </div>
                    <div className="margin-top-5 text-16">
                      <a
                        href={insurerWebsite || contextMessages.insurerWebsite.defaultMessage}
                        className="font-color-brand-link"
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-labelledby="go-to-premera-website"
                      >
                        <FormattedMessage {...messages.goToTheWebsite} />
                        &nbsp;
                        <i
                          className="icon-ext-link text-12"
                          id="go-to-premera-website"
                          aria-label="external-link"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default compose(
  pure,
  connect(state => {
    let healthPlanSupportNumber;
    const memberInfo = getMemberInfo(state);
    const phoneNumber = _.get(memberInfo, 'asoMetadata.phoneNumber');
    let outOfCountryPhone = _.get(memberInfo, 'asoMetadata.outOfCountryPhone');
    if (phoneNumber) healthPlanSupportNumber = Formatter.phoneNumber(phoneNumber);
    if (outOfCountryPhone) outOfCountryPhone = Formatter.phoneNumber(outOfCountryPhone);

    return {
      memberContext: _.get(memberInfo, 'memberContext'),
      healthPlanSupportNumber,
      outOfCountryPhone,
      insurerWebsite: _.get(memberInfo, 'asoMetadata.websiteLink'),
      openHours: _.get(memberInfo, 'asoMetadata.openHours'),
    };
  }),
  loadingUntil(({ memberContext }) => !_.isEmpty(memberContext)),
  withProps(({ memberContext }) => ({
    contextMessages: messagesByContext[memberContext],
  })),
)(PremeraHelpContent);
