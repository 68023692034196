import _ from 'lodash';

/**
 * Created by ofirhar on 02/03/17.
 */
const languages = [
  { id: 'aa', iso3: ['aar'], name: 'Afar', nativeName: 'Qafaraf' },
  { id: 'ab', iso3: ['abk'], name: 'Abkhazian', nativeName: 'Аҧсуа бызшәа' },
  { id: 'af', iso3: ['afr'], name: 'Afrikaans', nativeName: 'Afrikaans' },
  { id: 'ak', iso3: ['aka'], name: 'Akan', nativeName: 'Akan' },
  { id: 'am', iso3: ['amh'], name: 'Amharic', nativeName: 'አማርኛ' },
  { id: 'ar', iso3: ['ara'], name: 'Arabic', nativeName: 'العَرَبِيَّة' },
  { id: 'an', iso3: ['arg'], name: 'Aragonese', nativeName: 'aragonés' },
  { id: 'hy', iso3: ['arm', 'hye'], name: 'Armenian', nativeName: 'Հայերէն' },
  { id: 'is', iso3: ['isl', 'ice'], name: 'Icelandic', nativeName: 'íslenska' },
  { id: 'my', iso3: ['bur', 'mya'], name: 'Burmese', nativeName: 'မြန်မာစာ' },
  { id: 'bo', iso3: ['bod', 'tib'], name: 'Tibetan', nativeName: 'བོད་སྐད་' },
  { id: 'cy', iso3: ['cym', 'wel'], name: 'Welsh', nativeName: 'Cymraeg' },
  { id: 'cs', iso3: ['cze', 'ces'], name: 'Czech', nativeName: 'čeština' },
  { id: 'de', iso3: ['deu', 'ger'], name: 'German', nativeName: 'Deutsch' },
  { id: 'nl', iso3: ['dut', 'nld'], name: 'Dutch / Flemish', nativeName: 'Nederlands' },
  { id: 'el', iso3: ['ell', 'gre'], name: 'Modern Greek (1453–)', nativeName: 'Νέα Ελληνικά' },
  { id: 'eu', iso3: ['eus', 'baq'], name: 'Basque', nativeName: 'euskara' },
  { id: 'fr', iso3: ['fra', 'fre'], name: 'French', nativeName: 'français' },
  { id: 'ka', iso3: ['geo', 'kat'], name: 'Georgian', nativeName: 'ქართული' },
  { id: 'mk', iso3: ['mkd', 'mac'], name: 'Macedonian', nativeName: 'македонски јазик' },
  { id: 'mi', iso3: ['mri', 'mao'], name: 'Maori', nativeName: 'Te Reo Māori' },
  { id: 'ms', iso3: ['msa', 'may'], name: 'Malay', nativeName: 'Bahasa Melayu' },
  { id: 'fa', iso3: ['per', 'fas'], name: 'Persian', nativeName: 'فارسی' },
  { id: 'ro', iso3: ['ron', 'rum'], name: 'Romanian / Moldavian / Moldovan', nativeName: 'limba română' },
  { id: 'sk', iso3: ['slo', 'slk'], name: 'Slovak', nativeName: 'slovenčina' },
  { id: 'sq', iso3: ['sqi', 'alb'], name: 'Albanian', nativeName: 'Shqip' },
  { id: 'zh', iso3: ['zho', 'chi'], name: 'Chinese', nativeName: '中文' },
  { id: 'as', iso3: ['asm'], name: 'Assamese', nativeName: 'অসমীয়া' },
  { id: 'av', iso3: ['ava'], name: 'Avaric', nativeName: 'Магӏарул мацӏ' },
  { id: 'ay', iso3: ['aym'], name: 'Aymara', nativeName: 'Aymar aru' },
  { id: 'az', iso3: ['aze'], name: 'Azerbaijani', nativeName: 'Azərbaycan dili' },
  { id: 'ba', iso3: ['bak'], name: 'Bashkir', nativeName: 'Башҡорт теле' },
  { id: 'bm', iso3: ['bam'], name: 'Bambara', nativeName: 'ߓߊߡߊߣߊߣߞߊߣ‎' },
  { id: 'be', iso3: ['bel'], name: 'Belarusian', nativeName: 'Беларуская мова' },
  { id: 'bn', iso3: ['ben'], name: 'Bengali', nativeName: 'বাংলা' },
  { id: 'bi', iso3: ['bis'], name: 'Bislama', nativeName: '' },
  { id: 'bs', iso3: ['bos'], name: 'Bosnian', nativeName: 'bosanski' },
  { id: 'br', iso3: ['bre'], name: 'Breton', nativeName: 'Brezhoneg' },
  { id: 'bg', iso3: ['bul'], name: 'Bulgarian', nativeName: 'български език' },
  { id: 'ca', iso3: ['cat'], name: 'Catalan / Valencian', nativeName: 'català' },
  { id: 'ch', iso3: ['cha'], name: 'Chamorro', nativeName: 'Finu Chamoru' },
  { id: 'ce', iso3: ['che'], name: 'Chechen', nativeName: 'Нохчийн мотт' },
  { id: 'cv', iso3: ['chv'], name: 'Chuvash', nativeName: 'Чӑвашла' },
  { id: 'kw', iso3: ['cor'], name: 'Cornish', nativeName: 'Kernowek' },
  { id: 'co', iso3: ['cos'], name: 'Corsican', nativeName: 'Corsu' },
  { id: 'cr', iso3: ['cre'], name: 'Cree', nativeName: '' },
  { id: 'da', iso3: ['dan'], name: 'Danish', nativeName: 'dansk' },
  { id: 'dv', iso3: ['div'], name: 'Dhivehi / Dhivehi / Maldivian', nativeName: 'ދިވެހި' },
  { id: 'dz', iso3: ['dzo'], name: 'Dzongkha', nativeName: 'རྫོང་ཁ་' },
  { id: 'en', iso3: ['eng'], name: 'English', nativeName: 'English' },
  { id: 'et', iso3: ['est'], name: 'Estonian', nativeName: 'eesti keel' },
  { id: 'ee', iso3: ['ewe'], name: 'Ewe', nativeName: 'Èʋegbe' },
  { id: 'fo', iso3: ['fao'], name: 'Faroese', nativeName: 'føroyskt' },
  { id: 'fj', iso3: ['fij'], name: 'Fijian', nativeName: 'Na Vosa Vakaviti' },
  { id: 'fi', iso3: ['fin'], name: 'Finnish', nativeName: 'suomen kieli' },
  { id: 'fy', iso3: ['fry'], name: 'Western Frisian', nativeName: 'Frysk' },
  { id: 'ff', iso3: ['ful'], name: 'Fulah', nativeName: 'Fulfulde' },
  { id: 'gd', iso3: ['gla'], name: 'Gaelic / Scottish Gaelic', nativeName: 'Gàidhlig' },
  { id: 'ga', iso3: ['gle'], name: 'Irish', nativeName: 'Gaeilge' },
  { id: 'gl', iso3: ['glg'], name: 'Galician', nativeName: 'galego' },
  { id: 'gv', iso3: ['glv'], name: 'Manx', nativeName: 'Gaelg' },
  { id: 'gn', iso3: ['grn'], name: 'Guarani', nativeName: 'Avañeẽ' },
  { id: 'gu', iso3: ['guj'], name: 'Gujarati', nativeName: 'ગુજરાતી' },
  { id: 'ht', iso3: ['hat'], name: 'Haitian / Haitian Creole', nativeName: 'kreyòl ayisyen' },
  { id: 'ha', iso3: ['hau'], name: 'Hausa', nativeName: 'Harshen Hausa' },
  { id: 'he', iso3: ['heb'], name: 'Hebrew', nativeName: 'עברית‎' },
  { id: 'hz', iso3: ['her'], name: 'Herero', nativeName: 'Otjiherero' },
  { id: 'hi', iso3: ['hin'], name: 'Hindi', nativeName: 'हिन्दी' },
  { id: 'ho', iso3: ['hmo'], name: 'Hiri Motu', nativeName: '' },
  { id: 'hr', iso3: ['hrv'], name: 'Croatian', nativeName: 'hrvatski' },
  { id: 'hu', iso3: ['hun'], name: 'Hungarian', nativeName: 'magyar nyelv' },
  { id: 'ig', iso3: ['ibo'], name: 'Igbo', nativeName: 'Asụsụ Igbo' },
  { id: 'ii', iso3: ['iii'], name: 'Sichuan Yi / Nuosu', nativeName: 'ꆈꌠꉙ' },
  { id: 'iu', iso3: ['iku'], name: 'Inuktitut', nativeName: 'ᐃᓄᒃᑎᑐᑦ' },
  { id: 'id', iso3: ['ind'], name: 'Indonesian', nativeName: 'bahasa Indonesia' },
  { id: 'ik', iso3: ['ipk'], name: 'Inupiaq', nativeName: 'Iñupiaq' },
  { id: 'it', iso3: ['ita'], name: 'Italian', nativeName: 'italiano' },
  { id: 'jv', iso3: ['jav'], name: 'Javanese', nativeName: 'ꦧꦱꦗꦮ' },
  { id: 'ja', iso3: ['jpn'], name: 'Japanese', nativeName: '日本語' },
  { id: 'kl', iso3: ['kal'], name: 'Kalaallisut / Greenlandic', nativeName: '' },
  { id: 'kn', iso3: ['kan'], name: 'Kannada', nativeName: 'ಕನ್ನಡ' },
  { id: 'ks', iso3: ['kas'], name: 'Kashmiri', nativeName: 'कॉशुर / كأشُر' },
  { id: 'kr', iso3: ['kau'], name: 'Kanuri', nativeName: '' },
  { id: 'kk', iso3: ['kaz'], name: 'Kazakh', nativeName: 'қазақ тілі / qazaq tili' },
  { id: 'km', iso3: ['khm'], name: 'Central Khmer', nativeName: 'ភាសាខ្មែរ' },
  { id: 'ki', iso3: ['kik'], name: 'Kikuyu / Gikuyu', nativeName: 'Gĩkũyũ' },
  { id: 'rw', iso3: ['kin'], name: 'Kinyarwanda', nativeName: '' },
  { id: 'ky', iso3: ['kir'], name: 'Kirghiz / Kyrgyz', nativeName: 'кыргызча' },
  { id: 'kv', iso3: ['kom'], name: 'Komi', nativeName: 'Коми кыв' },
  { id: 'kg', iso3: ['kon'], name: 'Kongo', nativeName: '' },
  { id: 'ko', iso3: ['kor'], name: 'Korean', nativeName: '한국어' },
  { id: 'kj', iso3: ['kua'], name: 'Kuanyama / Kwanyama', nativeName: '' },
  { id: 'ku', iso3: ['kur'], name: 'Kurdish', nativeName: 'Kurdî / کوردی‎' },
  { id: 'lo', iso3: ['lao'], name: 'Lao', nativeName: 'ພາສາລາວ' },
  { id: 'lv', iso3: ['lav'], name: 'Latvian', nativeName: 'Latviešu valoda' },
  { id: 'li', iso3: ['lim'], name: 'Limburgan / Limburger / Limburgish', nativeName: 'Lèmburgs' },
  { id: 'ln', iso3: ['lin'], name: 'Lingala', nativeName: '' },
  { id: 'lt', iso3: ['lit'], name: 'Lithuanian', nativeName: 'lietuvių kalba' },
  { id: 'lb', iso3: ['ltz'], name: 'Luxembourgish / Letzeburgesch', nativeName: 'Lëtzebuergesch' },
  { id: 'lu', iso3: ['lub'], name: 'Luba-Katanga', nativeName: 'Kiluba' },
  { id: 'lg', iso3: ['lug'], name: 'Ganda', nativeName: 'Luganda' },
  { id: 'mh', iso3: ['mah'], name: 'Marshallese', nativeName: 'Kajin M̧ajeļ' },
  { id: 'ml', iso3: ['mal'], name: 'Malayalam', nativeName: 'മലയാളം' },
  { id: 'mr', iso3: ['mar'], name: 'Marathi', nativeName: 'मराठी' },
  { id: 'mg', iso3: ['mlg'], name: 'Malagasy', nativeName: '' },
  { id: 'mt', iso3: ['mlt'], name: 'Maltese', nativeName: 'Malti' },
  { id: 'mn', iso3: ['mon'], name: 'Mongolian', nativeName: 'монгол хэл' },
  { id: 'na', iso3: ['nau'], name: 'Nauru', nativeName: 'dorerin Naoero' },
  { id: 'nv', iso3: ['nav'], name: 'Navajo / Navaho', nativeName: 'Diné bizaad' },
  { id: 'nr', iso3: ['nbl'], name: 'South Ndebele', nativeName: 'isiNdebele seSewula' },
  { id: 'nd', iso3: ['nde'], name: 'North Ndebele', nativeName: 'siNdebele saseNyakatho' },
  { id: 'ng', iso3: ['ndo'], name: 'Ndonga', nativeName: 'ndonga' },
  { id: 'ne', iso3: ['nep'], name: 'Nepali', nativeName: 'नेपाली भाषा' },
  { id: 'nn', iso3: ['nno'], name: 'Norwegian Nynorsk', nativeName: 'nynorsk' },
  { id: 'nb', iso3: ['nob'], name: 'Norwegian Bokmål', nativeName: 'bokmål' },
  { id: 'no', iso3: ['nor'], name: 'Norwegian', nativeName: 'norsk' },
  { id: 'ny', iso3: ['nya'], name: 'Chichewa / Chewa / Nyanja', nativeName: 'Chichewa' },
  { id: 'oc', iso3: ['oci'], name: 'Occitan (post 1500)', nativeName: 'occitan' },
  { id: 'oj', iso3: ['oji'], name: 'Ojibwa', nativeName: '' },
  { id: 'or', iso3: ['ori'], name: 'Oriya', nativeName: 'ଓଡ଼ିଆ' },
  { id: 'om', iso3: ['orm'], name: 'Oromo', nativeName: 'Afaan Oromoo' },
  { id: 'os', iso3: ['oss'], name: 'Ossetian / Ossetic', nativeName: 'Ирон æвзаг' },
  { id: 'pa', iso3: ['pan'], name: 'Panjabi / Punjabi', nativeName: 'ਪੰਜਾਬੀ / پنجابی‎' },
  { id: 'pl', iso3: ['pol'], name: 'Polish', nativeName: 'Język polski' },
  { id: 'pt', iso3: ['por'], name: 'Portuguese', nativeName: 'português' },
  { id: 'ps', iso3: ['pus'], name: 'Pushto / Pashto', nativeName: 'پښتو' },
  { id: 'qu', iso3: ['que'], name: 'Quechua', nativeName: 'Runa simi' },
  { id: 'rm', iso3: ['roh'], name: 'Romansh', nativeName: 'Rumantsch' },
  { id: 'rn', iso3: ['run'], name: 'Rundi', nativeName: 'Ikirundi' },
  { id: 'ru', iso3: ['rus'], name: 'Russian', nativeName: 'русский язык' },
  { id: 'sg', iso3: ['sag'], name: 'Sango', nativeName: 'yângâ tî sängö' },
  { id: 'si', iso3: ['sin'], name: 'Sinhala / Sinhalese', nativeName: 'සිංහල' },
  { id: 'sl', iso3: ['slv'], name: 'Slovenian', nativeName: 'slovenski jezik' },
  { id: 'se', iso3: ['sme'], name: 'Northern Sami', nativeName: 'davvisámegiella' },
  { id: 'sm', iso3: ['smo'], name: 'Samoan', nativeName: 'Gagana faʻa Sāmoa' },
  { id: 'sn', iso3: ['sna'], name: 'Shona', nativeName: 'chiShona' },
  { id: 'sd', iso3: ['snd'], name: 'Sindhi', nativeName: 'سنڌي / सिन्धी / ਸਿੰਧੀ' },
  { id: 'so', iso3: ['som'], name: 'Somali', nativeName: 'af Soomaali' },
  { id: 'st', iso3: ['sot'], name: 'Southern Sotho', nativeName: 'Sesotho [southern]' },
  { id: 'es', iso3: ['spa'], name: 'Spanish', nativeName: 'español' },
  { id: 'sc', iso3: ['srd'], name: 'Sardinian', nativeName: 'sardu' },
  { id: 'sr', iso3: ['srp'], name: 'Serbian', nativeName: 'српски / srpski' },
  { id: 'ss', iso3: ['ssw'], name: 'Swati', nativeName: 'siSwati' },
  { id: 'su', iso3: ['sun'], name: 'Sundanese', nativeName: 'ᮘᮞ ᮞᮥᮔ᮪ᮓ / Basa Sunda' },
  { id: 'sw', iso3: ['swa'], name: 'Swahili', nativeName: 'Kiswahili' },
  { id: 'sv', iso3: ['swe'], name: 'Swedish', nativeName: 'svenska' },
  { id: 'ty', iso3: ['tah'], name: 'Tahitian', nativeName: 'Reo Tahiti' },
  { id: 'ta', iso3: ['tam'], name: 'Tamil', nativeName: 'தமிழ்' },
  { id: 'tt', iso3: ['tat'], name: 'Tatar', nativeName: 'татар теле / tatar tele / تاتار' },
  { id: 'te', iso3: ['tel'], name: 'Telugu', nativeName: 'తెలుగు' },
  { id: 'tg', iso3: ['tgk'], name: 'Tajik', nativeName: 'тоҷикӣ / tojikī' },
  { id: 'tl', iso3: ['tgl'], name: 'Tagalog', nativeName: 'Wikang Tagalog' },
  { id: 'th', iso3: ['tha'], name: 'Thai', nativeName: 'ภาษาไทย' },
  { id: 'ti', iso3: ['tir'], name: 'Tigrinya', nativeName: 'ትግርኛ' },
  { id: 'to', iso3: ['ton'], name: 'Tonga (Tonga Islands)', nativeName: 'lea faka-Tonga' },
  { id: 'tn', iso3: ['tsn'], name: 'Tswana', nativeName: 'Setswana' },
  { id: 'ts', iso3: ['tso'], name: 'Tsonga', nativeName: 'Xitsonga' },
  { id: 'tk', iso3: ['tuk'], name: 'Turkmen', nativeName: '"Türkmençe / Түркменче / تورکمن تیلی ' },
  { id: 'tr', iso3: ['tur'], name: 'Turkish', nativeName: 'Türkçe' },
  { id: 'tw', iso3: ['twi'], name: 'Twi', nativeName: '' },
  { id: 'ug', iso3: ['uig'], name: 'Uighur / Uyghur', nativeName: 'ئۇيغۇرچە ' },
  { id: 'uk', iso3: ['ukr'], name: 'Ukrainian', nativeName: 'українська мова' },
  { id: 'ur', iso3: ['urd'], name: 'Urdu', nativeName: 'اُردُو‎' },
  { id: 'uz', iso3: ['uzb'], name: 'Uzbek', nativeName: 'Oʻzbekcha / ўзбекча / ئوزبېچه' },
  { id: 've', iso3: ['ven'], name: 'Venda', nativeName: 'Tshivenḓa' },
  { id: 'vi', iso3: ['vie'], name: 'Vietnamese', nativeName: 'Tiếng Việt' },
  { id: 'wa', iso3: ['wln'], name: 'Walloon', nativeName: 'Walon' },
  { id: 'wo', iso3: ['wol'], name: 'Wolof', nativeName: '' },
  { id: 'xh', iso3: ['xho'], name: 'Xhosa', nativeName: 'isiXhosa' },
  { id: 'yi', iso3: ['yid'], name: 'Yiddish', nativeName: '"ייִדיש' },
  { id: 'yo', iso3: ['yor'], name: 'Yoruba', nativeName: 'èdè Yorùbá' },
  { id: 'za', iso3: ['zha'], name: 'Zhuang / Chuang', nativeName: 'Vahcuengh / 話僮' },
  { id: 'zu', iso3: ['zul'], name: 'Zulu', nativeName: 'isiZulu' },
];

// Used the id field for indication and value + label fields to support react select components
// (thats also why u can search by native name language and by English name language in same time)
export const languagesNames =
  _.map(languages, lang => (
    {
      id: lang.id,
      value: lang.name,
      label: lang.name
    }));
export const languagesNativeNames =
  _.map(languages, lang => (
    {
      id: lang.id,
      value: lang.name,
      label: lang.nativeName || lang.name
    }));

export const languagesIds = _.map(languages, lang => lang.id);

export const englishLanguageItem = { id: 'en', iso3: ['eng'], name: 'English', nativeName: 'English' };
export const englishLanguageSelect = { id: 'en', value: 'English', label: 'English' };

export default languages;
