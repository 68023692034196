/**
 * Created by moran on 8/2/17.
 */
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { autobind } from 'core-decorators';
import CleanInput from './cleanInput';

@autobind
class CleanYearSelector extends React.Component {
  static propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
  };

  static defaultProps = {
    value: ''
  };

  onChange(e) {
    e.preventDefault();
    this.props.onChange(_.truncate(e.target.value.replace(/\D/g, ''), { length: 4, omission: '' }));
  }

  render() {
    return (<CleanInput
      {...this.props}
      type="tel"
      onChange={this.onChange}
      value={this.props.value}
    />);
  }
}

export default CleanYearSelector;
