/**
 * Created by moran on 8/13/17.
 */
import _ from 'lodash';
import { getLocale } from '../intl/selectors';

export const domain = state => _.get(state, 'config.domain.domain');
export const domainShortName = state => _.get(state, 'config.domain.shortName');
export const getMembersPrivacyPolicyUrl = state => {
  const locale = getLocale(state);
  return _.get(state, 'config.membersPrivacyPolicyUrl')[locale];
};
export const getAccessibilityLink = state => _.get(state, 'config.features.accessibilityLink');
export const isPublicDirectory = state => {
  const currentRoute = _.get(state, 'routing.locationBeforeTransitions.pathname');
  return !!_.includes(currentRoute, '/public/');
};
export const getProvidersPrivacyPolicyUrl = state =>
  _.get(state, 'config.providersPrivacyPolicyUrl');
export const getMembersTermsConditionsUrl = state => {
  const locale = getLocale(state);
  return _.get(state, 'config.memberTermsConditionsUrl', {})[locale];
};
export const getProvidersTermsConditionsUrl = state =>
  _.get(state, 'config.providerTermsConditions');
export const getDefaultTheme = state => _.get(state, 'config.defaultTheme');
export const getContactNumber = state => _.get(state, 'config.contact.phone');
export const getResetPasswordConfig = state => _.get(state, 'config.resetPassword');
export const getSupportPhoneNumber = state => _.get(state, 'config.features.phoneNumbers.support');
export const getProvidersContact = state => _.get(state, 'config.contact');
export const getAppName = state => _.get(state, 'config.appName');
export const getParticipatingPayers = state => _.get(state, 'config.participatingPayers');
export const getSearchURL = state => _.get(state, 'config.searchUrl');
