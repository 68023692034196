/* eslint-disable react/no-unused-prop-types */
/**
 * Created by galgoltzman on 14/05/2017.
 */
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import BlockUI from 'react-block-ui';
import 'react-block-ui/style.css';

import injectNotification from '../../../../../store/notification/injectNotification';
import Layout from '../../layout/layout';
import messages from './messages';
import actionTracker from '../../../../../store/tools/actionTracker/actionTrackerComponent';
import {
  getSettings,
  GET_MEMBER_SETTINGS,
  CHANGE_NOTIFICATION_PREFERENCES,
  changeNotificationPreferences,
} from '../../../../../store/member/actions';
import NotificationsForm from './notificationsForm';
import * as NotificationType from '../../../../../model/enum/notificationType';
import * as FormDefs from './notificationFormDefs';
import { paths } from '../paths';
import SettingsLayout from '../settingsLayout';
import SvgNotificationsStars from '../../../../../assets/svg/notificationsStars';

const changeNotificationSuccessTitle = ' ';

@autobind
class NotificationsPage extends React.Component {
  static propTypes = {
    /**
     * Server request action tracking.
     * For changing notification preferences
     */
    changeNotificationPreferencesTracking: PropTypes.object.isRequired,
    /**
     * Server request action tracking.
     * For fetching member settings.
     */
    getSettingsTracking: PropTypes.object.isRequired,
    /**
     * Redux function for getting member settings
     */
    getSettings: PropTypes.func.isRequired,
    /**
     * Redux function for changing notification preferences
     */
    changeNotificationPreferences: PropTypes.func.isRequired,
    /**
     * Injected prop for notifying on errors
     */
    notification: PropTypes.object.isRequired,
  };

  constructor() {
    super();
    this.state = {};
  }

  componentWillReceiveProps(nextProps) {
    const { changeNotificationPreferencesTracking, getSettingsTracking } = nextProps;

    if (changeNotificationPreferencesTracking.finished) {
      this.handleChangeNotificationTracking(changeNotificationPreferencesTracking);
    }

    if (getSettingsTracking.finished) {
      this.handleGetSettingsTracking(getSettingsTracking, nextProps);
    }
  }

  handleChangeNotificationTracking(tracking) {
    // If we got an error, we should re-fetch the settings from the server
    // and block the UI in the meanwhile.
    if (tracking.hasError) {
      this.props.getSettings();
      this.setState({ blocking: true });
      this.props.notification.error(
        messages.changeNotificationErrorTitle,
        messages.changeNotificationErrorMessage,
        { autoDismiss: 10 },
      );
    } else {
      this.props.notification.success(
        changeNotificationSuccessTitle,
        messages.changeNotificationSuccessMessage,
        { autoDismiss: 10 },
      );
    }
  }

  handleGetSettingsTracking(tracking) {
    this.setState({ blocking: false });

    // re-fetching the settings could fail as well...
    if (tracking.hasError) {
      this.props.notification.error(messages.serverErrorTitle, messages.serverErrorMessage, {
        autoDismiss: 10,
      });
    }
  }

  handleSubmit(form) {
    const preferences = {};
    preferences[NotificationType.FINANCIAL_AND_HEALTH_UPDATES] = {
      EMAIL: form[FormDefs.fields.financialAndHealthUpdatesEmails.name],
      SMS: form[FormDefs.fields.financialAndHealthUpdatesMessages.name],
    };
    preferences[NotificationType.ACCOUNT_REMINDERS] = {
      EMAIL: form[FormDefs.fields.accountRemindersEmails.name],
      SMS: form[FormDefs.fields.accountRemindersMessages.name],
    };
    preferences[NotificationType.APPOINTMENT_INFO] = {
      EMAIL: form[FormDefs.fields.appointmentInfoEmails.name],
      SMS: form[FormDefs.fields.appointmentInfoMessages.name],
    };
    preferences[NotificationType.NEWS] = {
      EMAIL: form[FormDefs.fields.newsTipsPromotionsEmails.name],
    };

    this.props.changeNotificationPreferences(preferences);
  }

  render() {
    return (
      <Layout
        hideLogo
        centeredBackBtn
        previousPageName="settings"
        previousPageRoute={paths.settingsHome()}
      >
        <SettingsLayout>
          <BlockUI blocking={this.state.blocking}>
            <div>
              <div className="left-img-title">
                <SvgNotificationsStars />
                <span className="left-img-title-text text-16 text-dark text-semibold">
                  <FormattedMessage {...messages.yourNotifications} />
                </span>
              </div>
              <div className="margin-top-30">
                <NotificationsForm
                  onSubmit={this.handleSubmit}
                  loading={this.props.changeNotificationPreferencesTracking.inProgress}
                />
              </div>
            </div>
          </BlockUI>
        </SettingsLayout>
      </Layout>
    );
  }
}

export default compose(
  actionTracker({
    changeNotificationPreferencesTracking: CHANGE_NOTIFICATION_PREFERENCES.SOURCE,
    getSettingsTracking: GET_MEMBER_SETTINGS.SOURCE,
  }),
  connect(
    null,
    {
      getSettings,
      changeNotificationPreferences,
    },
  ),
  injectNotification,
)(NotificationsPage);
/* eslint-enable react/no-unused-prop-types */
