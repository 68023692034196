import React from 'react';
import PropTypes from 'prop-types';
import { object } from 'yup';
import { Field, Form } from 'formik';
import { WrappedFormik } from '../../form/formik/wrappedFormik';
import withFormikWrapper from '../../form/formik/withFormikWrapper';
import FormActionButtons from '../../molecules/FormSubmissionButtons/FormActionButtons';
import selectInsurer from '../../selectInsurer/selectInsurer';
import ClickableDiv from '../../clickableElement/clickableDiv';
import './SelectInsurerForm.less';

const CleanFormikInsurerSelect = withFormikWrapper()(selectInsurer);

const scheme = object().shape({
  insurer: object()
    .required()
    .label('Insurer'),
});

class SelectInsurerForm extends React.Component {
  static defaultProps = {
    initialValues: null,
  };

  render() {
    const {
      initialValues,
      onSecondaryClick,
      secondaryButtonText,
      label,
      onSubmit,
      ...rest
    } = this.props;
    return (
      <WrappedFormik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={scheme}
        render={({ isValid }) => (
          <Form className="select-insurer-form">
            <label className="margin-bottom-30 text-18 text-semibold">{label}</label>
            <Field
              {...rest}
              name="insurer"
              component={CleanFormikInsurerSelect}
              selectClassName="margin-45"
              isLoading={false}
            />
            <FormActionButtons {...rest} primaryDisabled={!isValid} />
            <div className="col-xs-12">
              <ClickableDiv
                clickHandler={onSecondaryClick}
                className="font-color-brand-link text-medium margin-top-20 text-center"
              >
                {secondaryButtonText}
              </ClickableDiv>
            </div>
          </Form>
        )}
      />
    );
  }
}

SelectInsurerForm.propTypes = {
  initialValues: PropTypes.object,
  onSecondaryClick: PropTypes.func.isRequired,
  secondaryButtonText: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
};

export default SelectInsurerForm;
