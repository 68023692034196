/**
 * Created by chenrozenes on 09/08/2017.
 */
import React from 'react';

export default function Folder() {
  /* eslint-disable */
  return (
    <svg aria-hidden="true" focusable="false" width="72px" height="56px" viewBox="0 0 72 56" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <defs></defs>
      <g id="992" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="992-medications-empty" transform="translate(-460.000000, -436.000000)">
          <g id="Page-1" transform="translate(460.000000, 436.000000)">
            <polyline id="Fill-1" fill="#007BBC" points="4 54 62 48 62 10 62 2 50 2 46 10 2 10 2 44"></polyline>
            <polyline id="Fill-2" fill="#09ACE4" points="6 54 70 54 70 16 70 8 58 8 54 16 10 16 10 50"></polyline>
            <g id="Group-11" fill="#083A6B">
              <path d="M68,52 L11.63,52 C11.856,51.372 12,50.706 12,50 L12,18 L54,18 C54.758,18 55.45,17.572 55.79,16.894 L59.236,10 L68,10 L68,52 Z M4,12 L46,12 C46.758,12 47.45,11.572 47.79,10.894 L51.236,4 L60,4 L60,6 L58,6 C57.242,6 56.55,6.428 56.21,7.106 L52.764,14 L10,14 C8.894,14 8,14.894 8,16 L8,50 C8,51.104 7.104,52 6,52 C4.896,52 4,51.104 4,50 L4,12 Z M70,6 L64,6 L64,2 C64,0.896 63.106,0 62,0 L50,0 C49.242,0 48.55,0.428 48.21,1.106 L44.764,8 L2,8 C0.894,8 0,8.894 0,10 L0,50 C0,53.308 2.692,56 6,56 L70,56 C71.106,56 72,55.106 72,54 L72,8 C72,6.894 71.106,6 70,6 L70,6 Z" id="Fill-3"></path>
              <path d="M40,42 C37.882,42 35.86,41.002 34.448,39.26 C33.75,38.402 33.882,37.142 34.74,36.448 C35.6,35.75 36.858,35.88 37.552,36.74 C38.874,38.366 41.126,38.366 42.448,36.74 C43.14,35.88 44.398,35.75 45.26,36.448 C46.118,37.142 46.25,38.402 45.552,39.26 C44.14,41.002 42.118,42 40,42" id="Fill-5"></path>
              <path d="M34,32 C34,33.106 33.106,34 32,34 C30.894,34 30,33.106 30,32 C30,30.894 30.894,30 32,30 C33.106,30 34,30.894 34,32" id="Fill-7"></path>
              <path d="M50,32 C50,33.106 49.106,34 48,34 C46.894,34 46,33.106 46,32 C46,30.894 46.894,30 48,30 C49.106,30 50,30.894 50,32" id="Fill-9"></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
  /* eslint-enable */
}
