import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { pure } from 'recompose';

import Summary from './sections/summary';
import Availability from '../../storybook/src/components/organisms/Availability/Availability';
import FeatureFlagged from '../features/featureFlagged';
import CommunityFeedback from './sections/communityFeedback';
import ReviewSummary from './sections/reviewSummary';
import PhysiciansLocations from './sections/physiciansLocations';
import ProfessionalBackground from './sections/professionalBackground';
import CostEstimation from './sections/costEstimation';

const DoctorDetails = ({
  details,
  clientLocation,
  distance,
  locationId,
  memberContext,
  memberPlan,
  videoModalShow,
  chosenCode,
  onLocationChanged,
  renderActions,
  showNetworkStatus,
  member: { domainMemberId },
  isInNetworkCustomComponent,
  mapSize,
}) => {
  const picked = _.find(details.locations, { id: locationId });
  const otherLocations = _.filter(details.locations, location => location.id !== locationId);
  const hasEnoughRating = details.ratingCount >= 3;
  const clientGeo = _.get(clientLocation, 'geo');
  return (
    <div>
      <div className="card dr-details-top-card">
        <div className="card-body">
          <Summary
            chosenCode={chosenCode}
            details={details}
            clientGeo={clientGeo}
            distance={distance}
            plan={memberPlan}
            pickedLocation={picked}
            otherLocations={otherLocations}
            onLocationChanged={onLocationChanged}
            showNetworkStatus={showNetworkStatus}
            onVideoPreviewClick={videoModalShow}
            isInNetworkCustomComponent={isInNetworkCustomComponent}
          />
          {renderActions(picked)}
        </div>
      </div>

      <FeatureFlagged uri="costEstimation" renderChildren>
        <CostEstimation
          provider={details}
          domainMemberId={domainMemberId}
          location={picked}
          memberPlan={memberPlan}
        />
      </FeatureFlagged>

      <Availability provider={details} location={picked} card size="large" />
      {hasEnoughRating ? (
        <FeatureFlagged uri="doctorDetails.communityFeedback">
          <CommunityFeedback
            providerGender={details.gender}
            bestDrCommFeedback={picked.bestDrCommunityFeedback}
            bestOfficeCommFeedback={picked.bestOfficeCommunityFeedback}
          />
        </FeatureFlagged>
      ) : null}
      <FeatureFlagged uri="doctorDetails.communityFeedback">
        {hasEnoughRating && details.rating >= 3 && _.get(picked.sampleReview, 'review') ? (
          <ReviewSummary
            npi={details.npi}
            locationId={picked.id}
            review={picked.sampleReview.review}
          />
        ) : (
          <div />
        )}
      </FeatureFlagged>
      <PhysiciansLocations
        location={picked}
        clientLocation={clientLocation}
        distance={distance}
        otherLocations={otherLocations}
        onLocationChanged={onLocationChanged}
        memberContext={memberContext}
        mapSize={mapSize}
      />
      <ProfessionalBackground details={details} />
    </div>
  );
};

DoctorDetails.propTypes = {
  details: PropTypes.object.isRequired,
  member: PropTypes.object,
  locationId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  chosenCode: PropTypes.string,
  clientLocation: PropTypes.object,
  distance: PropTypes.number,
  memberPlan: PropTypes.string,
  onLocationChanged: PropTypes.func,
  renderActions: PropTypes.func,
  isInNetworkCustomComponent: PropTypes.func,
  showNetworkStatus: PropTypes.bool,
  videoModalShow: PropTypes.func,
  memberContext: PropTypes.string,
  mapSize: PropTypes.object,
};

DoctorDetails.defaultProps = {
  chosenCode: undefined,
  clientLocation: undefined,
  distance: undefined,
  memberPlan: undefined,
  memberContext: '',
  onLocationChanged: undefined,
  isInNetworkCustomComponent: undefined,
  renderActions: _.noop,
  showNetworkStatus: true,
  videoModalShow: _.noop,
  member: {},
  mapSize: {},
};

export default pure(DoctorDetails);
