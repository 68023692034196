/**
 * Created by moran on 7/18/17.
 */
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { autobind } from 'core-decorators';
import { FormattedMessage } from 'react-intl';

@autobind
class ProfessionalSection extends React.Component {
  static propTypes = {
    data: PropTypes.string,
    message: PropTypes.object,
  };

  static defaultProps = {
    message: '',
    data: null,
  };

  render() {
    const { data, message } = this.props;
    if (_.isNil(data)) return (<div></div>);

    return (<div className="pro-section">
      <div className="text-18 text-light">
        <FormattedMessage {...message} />
      </div>
      <div className="text-18 no-margin text-semibold text-dark">
        {data}
      </div>
      <div className="section-icon">
        {this.props.children}
      </div>
    </div>);
  }
}

export default ProfessionalSection;
