/**
 * Created by chenrozenes on 30/03/2017.
 */

import Joi from 'joi-browser';
import * as Yup from 'yup';
import _ from 'lodash';
import { defineMessages } from 'react-intl';

const messages = defineMessages({
  maxNumOfLines: {
    defaultMessage: 'Comments must be no longer than {maxNumOfCommentLines} lines',
    id: 'commonSchema.maxNumOfLines',
  },
});

const INVALID_PHONE_NUMBER = '!!Oopsy, please enter a valid number here.';

export const DateOfBirthSchema = Joi.date()
  .format('MM/DD/YYYY')
  .max('now')
  .options({
    language: {
      date: {
        base: '!!Valid date of birth is mandatory',
        max: '!!Please enter a past date',
      },
    },
  });

export const PhoneNumberSchema = Joi.object().keys({
  number: Joi.string().length(10).options({
    language: {
      string: { length: INVALID_PHONE_NUMBER },
      any: { required: INVALID_PHONE_NUMBER },
    },
  }).required(),
  countryDialingCode: Joi.string().required(),
}).options({
  language: {
    any: { required: INVALID_PHONE_NUMBER },
  }
});

export const PasswordSchema = Joi.string().options({
  language: {
    any: { required: '!!Password is mandatory' },
  },
});

export const EmailSchema = Joi.string()
  .regex(
    new RegExp(
      '^(([^<>()[\\]\\\\.,;:\\s@"]+(\\.[^<>()[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z0-9-]+\\.)+[a-zA-Z]{2,}))$',
    ),
  )
  .options({
    language: {
      string: { email: '!!Is that an email address? Please try again.' },
      any: { required: '!!Email is mandatory' },
    },
  });

export const VerificationCodeSchema = Joi.string()
  .length(6)
  .options({
    language: {
      any: { required: '!!Please enter the 6 digit verification code you\'ve received' },
      string: { length: '!!Please enter the 6 digit verification code you\'ve received' },
    }
  });

export const enumSchema = Enum => Joi.string().valid(_.values(Enum))
  .options({
    language: {
      any: { required: `please enter valid values (${_.join(_.values(Enum), '/')})` },
    },
  });

export const linesCountSchema = (maxNumOfCommentLines, intl) => Yup.string().test({
  name: 'numOfLines',
  exclusive: true,
  message: intl.formatMessage(messages.maxNumOfLines, {
    maxNumOfCommentLines,
  }),
  test: value => (value ? value.split('\n').length <= maxNumOfCommentLines : true),
});

const csvRegxp = /[0-9a-zA-Z]+(,[0-9a-zA-Z]+)*/;
export const CommaSeparatedValues = Joi.string().regex(csvRegxp, 'Comma separated values');
