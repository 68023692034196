/**
 * Created by moran on 6/7/17.
 */
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import { injectIntl } from 'react-intl';
import { selectors, actions } from '../../../../store/collectReview/actions';

export default compose(
  connect(
    state => ({
      reviewProcess: selectors.get(state),
    }),
    { updateReview: actions.saveChanges },
  ),
  injectIntl,
  withRouter,
);
