import React from 'react';
import { defineMessages } from 'react-intl';
import UpdateAddressPage from './updateAddressPage';
import SvgWorkStars from '../../../../../assets/svg/workStars';

const messages = defineMessages({
  enterYourAddress: {
    defaultMessage: 'Your work address',
    id: 'members.settings.address.enterAddress',
  },
  hiddenTitle: {
    defaultMessage: 'Update work address',
    id: 'members.settings.workAddress.hiddenTitle',
  },
});

export default UpdateAddressPage(
  'WORK',
  messages.enterYourAddress,
  'member.settings.search.workAddress.address',
  <SvgWorkStars />,
  messages.hiddenTitle,
);
