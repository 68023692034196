import React from 'react';
import _ from 'lodash';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, defineMessages } from 'react-intl';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { autobind } from 'core-decorators';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import Joi from 'joi-browser';

import { TosLink, PrivacyPolicyLink } from '../../../../../../components/ui/links/index';
import { createValidator } from '../../../../../../utils/joiValidator';
import commonMessages from '../../../../../../store/intl/commonMessages';
import injectNotification from '../../../../../../store/notification/injectNotification';
import actionTracker from '../../../../../../store/tools/actionTracker/actionTrackerComponent';
import Layout from '../../../layout/layout';
import { SpinnerButton } from '../../../../../../components/ui/spinner/index';
import Recaptcha from '../../../../../../components/verification/bkmdRecaptcha';
import FeatureFlagged from '../../../../../../components/features/featureFlagged';

import * as Errors from '../../../../../../api/errors';
import * as FormDefs from './updatePhoneNumberDef';
import { paths } from '../../paths';
import SettingsLayout from '../../settingsLayout';
import SvgCellphoneStars from '../../../../../../assets/svg/cellphoneStars';

import {
  CHANGE_PHONE_NUMBER,
  RESEND_PHONE_NUMBER_VERIFICATION,
  changePhoneNumber,
  resendPhoneNumberVerification,
} from '../../../../../../store/user/actions';

const messages = defineMessages({
  serverErrorTitle: {
    defaultMessage: 'Hmm. Something went wrong',
    id: 'members.settings.updatePhoneNumber.serverErrorTitle',
  },
  serverErrorMessage: {
    defaultMessage: 'Please refresh the page and try again',
    id: 'members.settings.updatePhoneNumber.serverErrorMessage',
  },
  invalidPasswordTitle: {
    defaultMessage: 'Wrong password',
    id: 'members.settings.updatePhoneNumber.invalidPassword',
  },
  phoneNumber: {
    defaultMessage: 'Phone number',
    id: 'members.settings.profile',
  },
  password: {
    defaultMessage: 'Your password',
    id: 'members.settings.updatePhoneNumber.password',
  },
  byClickingUpdateMyNumber: {
    defaultMessage: 'By clicking "Update my number", you agree to Vim\'s ',
    id: 'members.settings.byClickingUpdateMyNumber',
  },
  and: {
    defaultMessage: ' and ',
    id: 'members.settings.and',
  },
  yourMobileNumber: {
    defaultMessage: 'Your mobile number',
    id: 'members.settings.updatePhoneNumber.yourMobileNumber',
  },
  verifyPhoneNumber: {
    defaultMessage: 'Verify your phone number',
    id: 'members.settings.updatePhoneNumber.verifyPhoneNumber',
  },
  updateMyNumber: {
    defaultMessage: 'Update your number',
    id: 'members.settings.updatePhoneNumber.updateMyNumber',
  },
  phoneNumberAlreadyTakenTitle: {
    defaultMessage: 'This phone number is already taken',
    id: 'members.settings.updatePhoneNumber.phoneNumberAlreadyTakenTitle',
  },
  invalidPhoneNumberMessage: {
    defaultMessage: 'Please use a mobile number for your account.',
    id: 'activation.flow.error.invalidPhoneNumberMessage',
  },
});

const invalidPhoneNumberTitle = ' ';
const phoneNumberAlreadyTakenMessage = ' ';

@autobind
class UpdatePhoneNumber extends React.Component {
  static propTypes = {
    /**
     * Injected prop for notifying on errors
     */
    notification: PropTypes.object.isRequired,
    /**
     * Server request action tracking.
     */
    changePhoneNumberTracking: PropTypes.object.isRequired,
    /**
     * Injected prop for using the flow manager.
     */
    flow: PropTypes.object.isRequired,
    /**
     * Redux action creator
     */
    changePhoneNumber: PropTypes.func.isRequired,
    /**
     * Redux action creator
     */
    resendPhoneNumberVerification: PropTypes.func.isRequired,
    /**
     * Server request action tracking.
     */
    resendPhoneNumberVerificationTracking: PropTypes.object.isRequired,
    /**
     * The phone number value filled out in this form
     */
    enteredPhoneNumber: PropTypes.string,
    /**
     * The password value filled out in this form
     */
    password: PropTypes.string,
    /**
     * The current phone number of the member.
     */
    phoneNumber: PropTypes.string.isRequired,
    /**
     * Injected by redux-form.
     */
    handleSubmit: PropTypes.func.isRequired,

    phoneNumberVerified: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    enteredPhoneNumber: undefined,
    password: undefined,
  };

  componentWillReceiveProps(nextProps) {
    const { changePhoneNumberTracking, resendPhoneNumberVerificationTracking } = nextProps;

    if (changePhoneNumberTracking.finished) this.handleResponse(changePhoneNumberTracking);
    if (resendPhoneNumberVerificationTracking.finished) {
      this.handleResponse(resendPhoneNumberVerificationTracking);
    }
  }

  handleResponse(tracking) {
    if (tracking.finished) {
      if (tracking.hasError) {
        const { responseMessage } = tracking.error;
        switch (responseMessage) {
          case Errors.RECAPTCHA_FAILED:
            break; // Handled by Recaptcha middleware
          case Errors.USER_ALREADY_EXIST: {
            this.props.notification.error(
              messages.phoneNumberAlreadyTakenTitle,
              phoneNumberAlreadyTakenMessage,
              { autoDismiss: 10 },
            );
            break;
          }
          case Errors.INVALID_PHONE_NUMBER: {
            this.props.notification.error(
              invalidPhoneNumberTitle,
              messages.invalidPhoneNumberMessage,
              { autoDismiss: 10 },
            );
            break;
          }
          case Errors.INVALID_PASSWORD: {
            this.props.notification.error(messages.invalidPasswordTitle, ' ', {
              autoDismiss: 10,
            });
            break;
          }
          default: {
            this.props.notification.error(messages.serverErrorTitle, messages.serverErrorMessage, {
              autoDismiss: 10,
            });
          }
        }
      } else {
        this.props.flow.nextStep();
      }
    }
  }

  changePhoneNumber() {
    this.props.changePhoneNumber(this.props.enteredPhoneNumber, this.props.password);
  }

  resendVerification() {
    this.props.resendPhoneNumberVerification(this.props.phoneNumber);
  }

  isModified() {
    return !_.isEqual(this.props.phoneNumber, this.props.enteredPhoneNumber);
  }

  /**
   * returns true if this form should update the phone number,
   * false if it should verify the phone number
   */
  isUpdate() {
    return this.isModified() || this.props.phoneNumberVerified;
  }

  render() {
    const {
      handleSubmit,
      phoneNumberVerified,
      resendPhoneNumberVerificationTracking,
      changePhoneNumberTracking,
    } = this.props;

    const onSubmit = this.isUpdate()
      ? handleSubmit(this.changePhoneNumber)
      : handleSubmit(this.resendVerification);

    return (
      <Layout
        hideLogo
        centeredBackBtn
        previousPageName="your profile"
        previousPageRoute={paths.profile()}
      >
        <SettingsLayout>
          <div className="text-center margin-top-20 margin-bottom-clean-form">
            <SvgCellphoneStars />
          </div>

          <form onSubmit={onSubmit} method="post">
            <div className="row">
              <div className="col-xs-12">
                <Field
                  inputClassName="no-margin"
                  label={messages.yourMobileNumber}
                  {...FormDefs.fields.phoneNumber}
                  id="mobileNumber"
                />
              </div>
            </div>
            <div className="row margin-top-5">
              {phoneNumberVerified && !this.isModified() ? (
                <div className="col-xs-12 text-16 text-success">
                  <FormattedMessage {...commonMessages.verified} />
                </div>
              ) : (
                <div className="col-xs-12 text-16 text-danger">
                  <FormattedMessage {...commonMessages.unverified} />
                </div>
              )}
            </div>

            {this.isUpdate() ? (
              <div className="row margin-top-30">
                <div className="col-xs-12">
                  <Field label={messages.password} id="password" {...FormDefs.fields.password} />
                  <Field type="hidden" {...FormDefs.fields.existingPhoneNumber} />
                </div>
              </div>
            ) : null}

            <div className="row margin-top-20">
              <div className="col-xs-12">
                <SpinnerButton
                  className="btn btn-big bg-color-brand-button"
                  type="submit"
                  disabled={
                    !this.isModified() && this.isUpdate() // disable if this is an update button
                    // but the phone number hasn't been modified.
                  }
                  isLoading={
                    this.isUpdate()
                      ? changePhoneNumberTracking.inProgress
                      : resendPhoneNumberVerificationTracking.inProgress
                  }
                >
                  {this.isUpdate() ? (
                    <FormattedMessage {...messages.updateMyNumber} />
                  ) : (
                    <FormattedMessage {...messages.verifyPhoneNumber} />
                  )}
                </SpinnerButton>
              </div>
            </div>

            <div className="row margin-top-30">
              <div className="col-xs-12">
                <Recaptcha />
              </div>
            </div>
            {this.isUpdate() ? (
              <FeatureFlagged uri="layout.showTermsOfService">
                <div className="row margin-top">
                  <div className="col-xs-12 text-center text-14">
                    <FormattedMessage {...messages.byClickingUpdateMyNumber} />
                    <br />
                    <TosLink className="underline-link" aria-labelledby="new-window-0" />
                    &nbsp;
                    <i
                      className="icon-ext-link text-12 i-va-fix-2"
                      id="new-window-0"
                      aria-label="external-link"
                    />
                    <FormattedMessage {...messages.and} />
                    <PrivacyPolicyLink className="underline-link" aria-labelledby="new-window-1" />
                    &nbsp;
                    <i
                      className="icon-ext-link text-12 i-va-fix-2"
                      id="new-window-1"
                      aria-label="external-link"
                    />
                  </div>
                </div>
              </FeatureFlagged>
            ) : null}
          </form>
        </SettingsLayout>
      </Layout>
    );
  }
}

const fieldSelector = formValueSelector(FormDefs.name);
export default compose(
  connect(
    state => ({
      enteredPhoneNumber: fieldSelector(state, FormDefs.fields.phoneNumber.name),
      password: fieldSelector(state, FormDefs.fields.password.name),
      phoneNumber: _.get(state, 'auth.user.phoneNumber', false),
      phoneNumberVerified: _.get(state, 'auth.user.phoneNumberVerified', false),
      initialValues: {
        [FormDefs.fields.phoneNumber.name]: state.auth.user.phoneNumber,
      },
    }),
    {
      changePhoneNumber,
      resendPhoneNumberVerification,
    },
  ),
  reduxForm({
    form: FormDefs.name,
    validate: createValidator(Joi.object().keys(FormDefs.schema), FormDefs.customValidator),
    // don't destroy so that we could fetch the phone number from the verification code step
    destroyOnUnmount: false,
  }),
  actionTracker({
    changePhoneNumberTracking: CHANGE_PHONE_NUMBER.SOURCE,
    resendPhoneNumberVerificationTracking: RESEND_PHONE_NUMBER_VERIFICATION,
  }),
  withRouter,
  injectNotification,
)(UpdatePhoneNumber);
