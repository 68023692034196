/**
 * Created by guyavarham on 21/03/2017.
 */

import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import { autobind } from 'core-decorators';
import { FormattedMessage } from 'react-intl';
import messages from '../../../messages/doctorDetails/messages';
import directoryMessages from '../../../messages/providersCommon/messages';
import PointDistance from '../../ui/map/pointDistance';
import Formatter from '../../../utils/formatter';
import getProviderPhoneNumber from '../../../utils/getProviderPhoneNumber';
import ProviderLocationSelector from './providerLocationSelector';
import MapSnapshot from '../../connectedMapSnapshot/connectedMapSnapshot';

const daysOrder = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

@autobind
class PhysiciansLocations extends React.Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
    clientLocation: PropTypes.object,
    distance: PropTypes.number,
    otherLocations: PropTypes.array,
    onLocationChanged: PropTypes.func,
    memberContext: PropTypes.string,
    mapSize: PropTypes.object,
  };

  static defaultProps = {
    clientLocation: undefined,
    distance: undefined,
    otherLocations: [],
    onLocationChanged: undefined,
    memberContext: '',
    mapSize: {},
  };

  /**
   * Renders a specific day's availability
   * @param location
   * @param day
   * @returns {XML}
   */
  renderOperationDay(location, day) {
    const data = location.openHours[day];
    const isClosed = !data || _.isEmpty(data.open) || _.isEmpty(data.close);
    let open;
    let close;

    if (!isClosed) {
      open = moment(data.open, 'HH:mm').format('h:mm A');
      close = moment(data.close, 'HH:mm').format('h:mm A');
    }

    return (
      <div className="row">
        <div className="col-xs-4 text-semibold">
          <FormattedMessage {...messages[_.toLower(day)]} />
        </div>
        <div className="col-xs-8 text-light">
          {isClosed ? <FormattedMessage {...messages.closed} /> : `${open} - ${close}`}
          <span className="open-now-tag font-color-brand-success">
            <FormattedMessage {...messages.openNow} />
          </span>
        </div>
      </div>
    );
  }

  /**
   * Render opening hours
   * @returns {XML}
   */
  renderOpeningHours() {
    const { location } = this.props;
    if (!location.openHours || _.isEmpty(location.openHours)) return <div />;
    return (
      <div className="office-hours text-dark text-16">
        {_.map(daysOrder, day => this.renderOperationDay(location, day))}
      </div>
    );
  }

  renderLocation(clinic) {
    if (!clinic) return null;
    const {
      clientLocation,
      location,
      otherLocations,
      onLocationChanged,
      distance,
      memberContext,
      mapSize: { width: mapWidth, height: mapHeight },
    } = this.props;
    const clientGeo = _.get(clientLocation, 'geo');
    // Title priority:
    // 1. Client location name
    // 2. Client location zip code
    // * Cant use the zip as
    const fromTitle = _.get(clientLocation, 'name') || _.get(clientLocation, 'geo.zip', 'home');
    const phoneNumber = getProviderPhoneNumber(clinic, memberContext);
    return (
      <div className="card">
        <div className="card-body">
          <div className="text-16 text-dark">
            <strong>
              <FormattedMessage {...messages.officeInformation} />
            </strong>
          </div>
          <div>
            {Boolean(otherLocations.length) && onLocationChanged && (
              <ProviderLocationSelector
                pickedLocation={location}
                otherLocations={otherLocations}
                onLocationChanged={onLocationChanged}
              />
            )}
          </div>
          <div className="facility-name margin-top-20">
            <span className="text-16 text-semibold text-dark">
              {location.officeName !== 'None'
                ? Formatter.officeName(location.officeName)
                : location.addressLine1}
            </span>
          </div>
          {clinic.geo && (clientGeo || distance) && (
            <div className="facility-distance text-dark">
              <PointDistance
                message={directoryMessages.clinicDistance}
                p1={clinic.geo}
                p2={clientGeo}
                distance={distance}
                className=""
              />
              <span className="from-address">
                <FormattedMessage {...messages.fromAddress} values={{ address: fromTitle }} />
              </span>
            </div>
          )}
          <div>
            {clinic.addressLine1}
            {clinic.addressLine2 && `, ${clinic.addressLine2}`}
            <br />
            {`${clinic.city}, ${clinic.state} ${clinic.zip}`}
          </div>
          <div className="facility-phone-number">{Formatter.phoneNumber(phoneNumber)}</div>
        </div>
        {clinic.geo && (
          <div className="map-container">
            <MapSnapshot geo={clinic.geo} width={mapWidth} height={mapHeight} />
          </div>
        )}
        {this.renderOpeningHours()}
      </div>
    );
  }

  render() {
    const { location } = this.props;
    if (!location) return null;

    return <div className="address-section">{this.renderLocation(location)}</div>;
  }
}

export default PhysiciansLocations;
