/**
 * Created by moran on 11/2/16.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '../../storybook/src/utils/theme';

function SvgDoctor({ theme }) {
  /* eslint-disable */
  return (
    <svg aria-hidden="true" focusable="false" className="svg-icon svg-icon-doctor" width="32px" height="32px" viewBox="0 0 32 32" version="1.1">
      <g id="Style-guide" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Two-color-icons" transform="translate(-308.000000, -556.000000)">
          <g id="Page-1" transform="translate(308.000000, 556.000000)">
            <path d="M2,30 L30,30 L30,28.5 C30,22.465 26.148,17.129 20.417,15.223 C20.071,15.108 20.473,13.175 20.645,13 C21.831,11.786 22.5,10.182 22.5,8.5 C22.5,4.916 19.584,2 16,2 C12.416,2 9.5,4.916 9.5,8.5 C9.5,10.181 10.168,11.784 11.353,12.997 C11.525,13.174 11.929,15.108 11.583,15.223 C5.852,17.129 2,22.465 2,28.5 L2,30 Z M31,32 L1,32 C0.447,32 0,31.552 0,31 L0,28.5 C0,22.135 3.747,16.451 9.473,13.899 C8.205,12.384 7.5,10.485 7.5,8.5 C7.5,3.813 11.313,0 16,0 C20.687,0 24.5,3.813 24.5,8.5 C24.5,10.485 23.795,12.384 22.527,13.899 C28.253,16.451 32,22.135 32,28.5 L32,31 C32,31.552 31.553,32 31,32 L31,32 Z" id="Fill-1" fill={theme.mainColor}></path>
            <path d="M26.5146,16.1426 L21.5146,13.1426 C21.2616,12.9916 20.9546,12.9586 20.6746,13.0546 C20.3956,13.1506 20.1726,13.3656 20.0666,13.6416 L15.9996,24.2126 L11.9336,13.6416 C11.8266,13.3656 11.6046,13.1506 11.3256,13.0546 C11.0466,12.9586 10.7396,12.9916 10.4856,13.1426 L5.4856,16.1426 C5.1626,16.3356 4.9766,16.6926 5.0016,17.0676 C5.0276,17.4426 5.2606,17.7706 5.6066,17.9186 L11.4366,20.4176 L9.2006,23.3996 C9.0226,23.6376 8.9586,23.9406 9.0266,24.2296 C9.0946,24.5186 9.2866,24.7616 9.5526,24.8946 L15.5526,27.8946 C15.6936,27.9646 15.8466,27.9996 15.9996,27.9996 C16.1536,27.9996 16.3066,27.9646 16.4476,27.8946 L22.4476,24.8946 C22.7126,24.7616 22.9056,24.5186 22.9736,24.2296 C23.0406,23.9406 22.9776,23.6376 22.7996,23.3996 L20.5636,20.4176 L26.3936,17.9186 C26.7396,17.7706 26.9726,17.4426 26.9976,17.0676 C27.0236,16.6926 26.8366,16.3356 26.5146,16.1426" id="Fill-3" fill={theme.secondaryColor}></path>
          </g>
        </g>
      </g>
    </svg>
  );
  /* eslint-enable */
}

SvgDoctor.propTypes = {
  theme: PropTypes.object.isRequired,
};

export default withTheme(SvgDoctor);
