import { defineMessages } from 'react-intl';
import IntlEnum from '../../utils/intlEnum';

export const TimePreference = {
  ASAP: 'Next available appointment',
  CUSTOM: 'CUSTOM',
};

const messages = defineMessages({
  asap: {
    defaultMessage: 'Next available appointment',
    id: 'vim.enum.booking.timePreference.asap',
  },
  custom: {
    defaultMessage: 'Select preferred days and times',
    id: 'vim.enum.booking.timePreference.custom',
  },
});

export default new IntlEnum(
  { value: TimePreference.ASAP, message: messages.asap },
  { value: TimePreference.CUSTOM, message: messages.custom },
);
