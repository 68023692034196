/**
 * Created by matan on 6/1/16.
 */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import analytics from '../../store/tools/analytics/analyticsHoc';
import MessageShape from '../ui/intlString/messageShape';
import IntlString from '../ui/intlString/intlString';
import './cleanCheckbox.less';

class CleanCheckbox extends React.PureComponent {
  static propTypes = {
    /**
     * Unique id not give to other checkboxes in the form
     */
    id: PropTypes.string.isRequired,
    /**
     * Sets component value
     */
    value: PropTypes.bool,
    /**
     * Hook method, called on component value change
     */
    onChange: PropTypes.func,
    /**
     * Sets component labels text
     */
    label: MessageShape,
    /**
     * Error message to show
     */
    errorText: PropTypes.string,
    /**
     * Sets component css class
     */
    className: PropTypes.string,
    /**
     * Add additional css classes to the error message
     */
    errorClassName: PropTypes.string,
    /**
     * When true, component will be readonly
     */
    readOnly: PropTypes.bool,
    /**
     * disable field
     */
    disabled: PropTypes.bool,
  };

  static defaultProps = {
    value: undefined,
    onChange: undefined,
    label: undefined,
    errorText: undefined,
    className: undefined,
    errorClassName: undefined,
    readOnly: false,
    disabled: false,
  };

  constructor(props) {
    super(props);

    this.buildClassName = this.buildClassName.bind(this);
  }

  buildClassName(className) {
    className = `form-group material-form-group ${className}`;
    if (this.props.errorText) {
      className += ' has-error';
    }

    return className;
  }

  render() {
    const {
      value,
      onChange,
      label,
      id,
      errorText,
      errorClassName,
      readOnly,
      disabled,
    } = this.props;
    const className = this.buildClassName(this.props.className);
    return (
      <div className={classNames('checkbox-container', className)}>
        <input
          type="checkbox"
          className="css-checkbox"
          id={id}
          checked={value}
          onChange={readOnly ? null : onChange}
          disabled={disabled}
        />
        <label className="css-label-checkbox web-checkbox" htmlFor={id} />
        <label htmlFor={id}>
          <IntlString message={label} />
        </label>
        {!errorText ? (
          undefined
        ) : (
          <span className={classNames('validation-message', errorClassName)}>{errorText}</span>
        )}
      </div>
    );
  }
}

export default analytics(CleanCheckbox, false);
