/**
 * Created by chenrozenes on 18/01/2017.
 */

import { getFromState } from './util';

export default function buildSelectors(type) {
  const key = type.key;

  return {
    get(state) {
      return getFromState(state, key, 'data', {});
    },
    getChanges(state) {
      return getFromState(state, key, 'pendingChanges', {});
    },
    getWithoutChanges(state) {
      return getFromState(state, key, 'serverData', {});
    },
    isUpdating(state) {
      return getFromState(state, key, 'isUpdating', false);
    },
    isFlushing(state) {
      return getFromState(state, key, 'isFlushing', false);
    },
    hasError(state) {
      return getFromState(state, key, 'hasError', false);
    },
    getError(state) {
      return getFromState(state, key, 'error');
    }
  };
}
