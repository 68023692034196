/**
 * Created by moran on 11/2/16.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '../../storybook/src/utils/theme';

function SvgHourglass({ theme }) {
  /* eslint-disable */
  return (
    <svg aria-hidden="true" focusable="false" className="svg-icon svg-icon-hourglass" width="20px" height="32px" viewBox="0 0 20 32" version="1.1">
      <g id="Style-guide" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Two-color-icons" transform="translate(-438.000000, -556.000000)">
          <g id="Page-1" transform="translate(438.000000, 556.000000)">
            <path d="M5.3223,11 C5.4373,11.157 5.5423,11.323 5.6713,11.468 L10.0513,15.951 L14.4313,11.468 C14.5613,11.323 14.6653,11.157 14.7803,11 L5.3223,11 Z" id="Fill-1" fill={theme.secondaryColor}></path>
            <path d="M19,2 L1,2 C0.447,2 0,1.552 0,1 C0,0.448 0.447,0 1,0 L19,0 C19.553,0 20,0.448 20,1 C20,1.552 19.553,2 19,2" id="Fill-3" fill={theme.mainColor}></path>
            <path d="M10,16.9512 C9.742,16.9512 9.484,16.8522 9.289,16.6542 C8.9,16.2612 8.904,15.6282 9.297,15.2402 L13.678,10.9112 C13.801,10.7762 13.972,10.5992 14.15,10.4152 C14.975,9.5632 16,8.5042 16,7.5852 L16,6.0002 L4,6.0002 L4,7.5852 C4,8.5042 5.025,9.5632 5.85,10.4152 C6.028,10.5992 6.199,10.7762 6.354,10.9452 L8.282,12.8472 C8.675,13.2362 8.679,13.8692 8.29,14.2622 C7.903,14.6552 7.271,14.6592 6.876,14.2702 L4.916,12.3342 C4.739,12.1432 4.579,11.9782 4.412,11.8062 C3.338,10.6952 2,9.3132 2,7.5852 L2,5.0002 C2,4.4482 2.447,4.0002 3,4.0002 L17,4.0002 C17.553,4.0002 18,4.4482 18,5.0002 L18,7.5852 C18,9.3132 16.662,10.6952 15.588,11.8062 C15.421,11.9782 15.261,12.1432 15.116,12.3002 L10.703,16.6622 C10.508,16.8552 10.254,16.9512 10,16.9512" id="Fill-5" fill={theme.mainColor}></path>
            <path d="M19,32 L1,32 C0.447,32 0,31.552 0,31 C0,30.448 0.447,30 1,30 L19,30 C19.553,30 20,30.448 20,31 C20,31.552 19.553,32 19,32" id="Fill-7" fill={theme.mainColor}></path>
            <path d="M10.6641,23.312 C10.2991,22.896 9.7011,22.896 9.3361,23.312 L5.8841,27.243 C5.5181,27.66 5.6701,28 6.2201,28 L13.7801,28 C14.3301,28 14.4811,27.66 14.1161,27.243 L10.6641,23.312 Z" id="Fill-9" fill={theme.secondaryColor}></path>
            <path d="M17,28 L3,28 C2.447,28 2,27.552 2,27 L2,24.317 C2,22.589 3.338,21.208 4.412,20.097 C4.579,19.925 4.739,19.76 4.884,19.603 L9.297,15.24 C9.69,14.852 10.323,14.855 10.711,15.248 C11.1,15.641 11.096,16.274 10.703,16.663 L6.322,20.992 C6.199,21.126 6.028,21.304 5.85,21.488 C5.025,22.339 4,23.398 4,24.317 L4,26 L16,26 L16,24.317 C16,23.398 14.975,22.339 14.15,21.488 C13.972,21.304 13.801,21.126 13.646,20.958 L12.151,19.482 C11.758,19.095 11.754,18.461 12.142,18.068 C12.531,17.675 13.164,17.672 13.556,18.06 L15.084,19.569 C15.261,19.76 15.421,19.925 15.588,20.097 C16.662,21.208 18,22.589 18,24.317 L18,27 C18,27.552 17.553,28 17,28" id="Fill-11" fill={theme.mainColor}></path>
            <path d="M10,22 C9.447,22 9,21.552 9,21 L9,20 C9,19.448 9.447,19 10,19 C10.553,19 11,19.448 11,20 L11,21 C11,21.552 10.553,22 10,22" id="Fill-13" fill={theme.secondaryColor}></path>
          </g>
        </g>
      </g>
    </svg>
  );
  /* eslint-enable */
}

SvgHourglass.propTypes = {
  theme: PropTypes.object.isRequired,
};

export default withTheme(SvgHourglass);
