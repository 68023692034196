import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '../../storybook/src/utils/theme';

function SvgCalendarPlus({ theme }) {
  /* eslint-disable */
  return (
    <svg aria-hidden="true" focusable="false" width="32px" height="32px" viewBox="0 0 32 32" version="1.1" className="svg-icon svg-calendar-plus">
      <g id="Accepting-appointment" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="992-appointments-to-do" transform="translate(-112.000000, -116.000000)">
          <g id="Page-1" transform="translate(112.000000, 116.000000)">
            <path d="M2,8 L2,5 C2,3.346 3.346,2 5,2 L27,2 C28.654,2 30,3.346 30,5 L30,8 L2,8 Z M27,0 L5,0 C2.243,0 0,2.243 0,5 L0,27 C0,29.757 2.243,32 5,32 L13,32 C13.553,32 14,31.552 14,31 C14,30.448 13.553,30 13,30 L5,30 C3.346,30 2,28.654 2,27 L2,10 L30,10 L30,14 C30,14.552 30.447,15 31,15 C31.553,15 32,14.552 32,14 L32,5 C32,2.243 29.757,0 27,0 L27,0 Z" id="Fill-1" fill={theme.mainColor}></path>
            <path d="M23,30 C19.141,30 16,26.86 16,23 C16,19.14 19.141,16 23,16 C26.859,16 30,19.14 30,23 C30,26.86 26.859,30 23,30 M23,14 C18.037,14 14,18.038 14,23 C14,27.962 18.037,32 23,32 C27.963,32 32,27.962 32,23 C32,18.038 27.963,14 23,14" id="Fill-3" fill={theme.secondaryColor}></path>
            <path d="M27,22 L24,22 L24,19.286 C24,18.733 23.553,18.286 23,18.286 C22.447,18.286 22,18.733 22,19.286 L22,22 L19.286,22 C18.733,22 18.286,22.448 18.286,23 C18.286,23.552 18.733,24 19.286,24 L22,24 L22,27 C22,27.552 22.447,28 23,28 C23.553,28 24,27.552 24,27 L24,24 L27,24 C27.553,24 28,23.552 28,23 C28,22.448 27.553,22 27,22" id="Fill-5" fill={theme.secondaryColor}></path>
          </g>
        </g>
      </g>
    </svg>
  );
  /* eslint-enable */
}

SvgCalendarPlus.propTypes = {
  theme: PropTypes.object.isRequired,
};

export default withTheme(SvgCalendarPlus);
