export default {
  onStart: props => {
    const { authTouch } = props;
    authTouch();
  },
  onSuccess: props => {
    const { authTouch } = props;
    authTouch();
  },
  onError: (props, error) => {
    const { loginExpired } = props;
    if (error.status === 401 || error.message === 'Unauthorized') {
      loginExpired();
    }
  },
};
