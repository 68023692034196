/**
 * Created by anna on 03/10/2017.
 */

import Immutable from 'seamless-immutable';
import _ from 'lodash';

import { HAS_FAMILY_MEMBERS, GET_FAMILY_MEMBERS, SET_ACTIVATION_STEP } from './actions';

const initialState = {
  openFamilyActivation: false,
  hasDependents: null,
  dependents: null,
  guardianDependents: null
};

export default function familyActivationReducer(state = initialState, action) {
  state = Immutable(state);
  switch (action.type) {
    case HAS_FAMILY_MEMBERS.SUCCESS: {
      return state.merge(action.payload);
    }
    case GET_FAMILY_MEMBERS.SUCCESS: {
      const { dependents, guardianDependents } = action.payload;
      return state.merge({
        hasDependents: !_.isEmpty(dependents) || !_.isEmpty(guardianDependents),
        ...action.payload,
      });
    }
    case SET_ACTIVATION_STEP: {
      return state.set('step', action.payload);
    }
    default:
      return state;
  }
}
