/**
 * Created by chenrozenes on 09/08/2017.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '../../storybook/src/utils/theme';

function SvgSigninNight({ theme }) {
  /* eslint-disable */
  return (
    <svg aria-hidden="true" focusable="false" width="241px" height="115px" viewBox="0 0 241 115" version="1.1" className="svg-icon svg-si">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-67.000000, -145.000000)">
          <g transform="translate(67.000000, 145.000000)">
            <path d="M38.9121,67.3604 C33.4281,67.3604 28.6911,64.1504 26.4451,59.5174 L51.3781,59.5174 C49.1331,64.1504 44.3961,67.3604 38.9121,67.3604 M25.0521,53.5004 C25.0521,45.8574 31.2691,39.6394 38.9121,39.6394 C46.5551,39.6394 52.7711,45.8574 52.7711,53.5004 C52.7711,54.5374 52.6471,55.5454 52.4311,56.5174 L25.3921,56.5174 C25.1761,55.5454 25.0521,54.5374 25.0521,53.5004 M57.8861,49.2894 C57.0581,49.2894 56.3861,49.9614 56.3861,50.7894 C56.3861,51.6174 57.0581,52.2894 57.8861,52.2894 C59.0521,52.2894 60.0001,53.2374 60.0001,54.4034 C60.0001,55.5694 59.0521,56.5174 57.8861,56.5174 L55.4861,56.5174 C55.6641,55.5374 55.7711,54.5314 55.7711,53.5004 C55.7711,44.2034 48.2081,36.6394 38.9121,36.6394 C29.6151,36.6394 22.0521,44.2034 22.0521,53.5004 C22.0521,54.5314 22.1591,55.5374 22.3381,56.5174 L19.9371,56.5174 C18.7711,56.5174 17.8231,55.5694 17.8231,54.4034 C17.8231,53.2374 18.7711,52.2894 19.9371,52.2894 C20.7661,52.2894 21.4371,51.6174 21.4371,50.7894 C21.4371,49.9614 20.7661,49.2894 19.9371,49.2894 C17.1171,49.2894 14.8231,51.5834 14.8231,54.4034 C14.8231,57.2234 17.1171,59.5174 19.9371,59.5174 L23.1821,59.5174 C25.6111,65.8474 31.7371,70.3604 38.9121,70.3604 C46.0861,70.3604 52.2121,65.8474 54.6421,59.5174 L57.8861,59.5174 C60.7061,59.5174 63.0001,57.2234 63.0001,54.4034 C63.0001,51.5834 60.7061,49.2894 57.8861,49.2894" fill={theme.secondaryColor}></path>
            <path d="M208.5,22 C203.354,22 199.159,17.884 199.014,12.771 C199.033,12.772 199.052,12.777 199.071,12.777 L207.277,12.777 C208.105,12.777 208.777,12.105 208.777,11.277 C208.777,10.449 208.105,9.777 207.277,9.777 L199.402,9.777 C200.576,5.862 204.208,3 208.5,3 C210.518,3 212.387,3.637 213.927,4.714 L206.929,4.714 C206.101,4.714 205.429,5.386 205.429,6.214 C205.429,7.042 206.101,7.714 206.929,7.714 L216.357,7.714 C216.465,7.714 216.57,7.701 216.672,7.68 C217.51,9.095 218,10.739 218,12.5 C218,17.738 213.738,22 208.5,22 M208.5,0 C201.607,0 196,5.607 196,12.5 C196,19.393 201.607,25 208.5,25 C215.393,25 221,19.393 221,12.5 C221,5.607 215.393,0 208.5,0" fill={theme.secondaryColor}></path>
            <path d="M214.7861,9.7774 L212.1671,9.7774 C211.3391,9.7774 210.6671,10.4494 210.6671,11.2774 C210.6671,12.1054 211.3391,12.7774 212.1671,12.7774 L214.7861,12.7774 C215.6141,12.7774 216.2861,12.1054 216.2861,11.2774 C216.2861,10.4494 215.6141,9.7774 214.7861,9.7774" fill={theme.secondaryColor}></path>
            <path d="M209.7227,14.667 L203.4367,14.667 C202.6087,14.667 201.9367,15.339 201.9367,16.167 C201.9367,16.995 202.6087,17.667 203.4367,17.667 L209.7227,17.667 C210.5507,17.667 211.2227,16.995 211.2227,16.167 C211.2227,15.339 210.5507,14.667 209.7227,14.667" fill={theme.secondaryColor}></path>
            <path d="M236.2373,48.2344 C235.5783,48.8744 235.2003,50.0404 235.3563,50.9434 L235.6423,52.6194 L234.1333,51.8264 C233.6283,51.5634 233.0833,51.5074 232.7143,51.5074 C232.3433,51.5074 231.7973,51.5634 231.2913,51.8284 L229.7803,52.6424 L230.0723,50.9414 C230.2263,50.0384 229.8483,48.8754 229.1933,48.2364 L227.9753,47.0474 L229.6553,46.8034 C230.5613,46.6734 231.5513,45.9564 231.9613,45.1304 L232.7153,43.6064 L233.4673,45.1304 C233.8763,45.9554 234.8673,46.6734 235.7703,46.8024 L237.4543,47.0484 L236.2373,48.2344 Z M240.8813,45.9324 C240.5783,45.0024 239.7553,44.3514 238.6783,44.1934 L236.2793,43.8514 C236.2353,43.8354 236.1463,43.7714 236.1573,43.8014 L235.0503,41.5594 C234.5703,40.5834 233.6963,40.0004 232.7143,40.0004 C231.7323,40.0004 230.8593,40.5824 230.3803,41.5564 L229.3133,43.7324 C229.2823,43.7704 229.1953,43.8344 229.2273,43.8344 L229.2283,43.8344 L226.7453,44.1934 C225.6703,44.3524 224.8473,45.0044 224.5483,45.9324 C224.2433,46.8654 224.5273,47.8764 225.3063,48.6364 L227.0453,50.3224 C227.0713,50.3644 227.1093,50.4724 227.1153,50.4344 L226.6913,52.9054 C226.5473,53.7514 226.7413,54.5434 227.2383,55.1354 C227.9873,56.0274 229.3263,56.2544 230.4703,55.6494 L232.6093,54.5174 C232.6203,54.5144 232.6583,54.5074 232.7143,54.5074 C232.7563,54.5084 232.7893,54.5114 232.7733,54.5014 L234.9563,55.6474 C235.3983,55.8814 235.8543,56.0004 236.3123,56.0004 C237.0453,56.0004 237.7293,55.6844 238.1903,55.1354 C238.6863,54.5444 238.8813,53.7524 238.7363,52.9024 L238.3203,50.5144 C238.3233,50.4674 238.3833,50.3484 238.3293,50.3844 L240.1233,48.6354 C240.9013,47.8764 241.1853,46.8654 240.8813,45.9324 L240.8813,45.9324 Z" fill={theme.secondaryColor}></path>
            <path d="M92.5693,30.9659 C93.3973,30.9659 94.0693,30.2939 94.0693,29.4659 C94.0693,28.6379 93.3973,27.9659 92.5693,27.9659 L88.7583,27.9659 L88.7583,24.1549 C88.7583,23.3269 88.0873,22.6549 87.2583,22.6549 C86.4303,22.6549 85.7583,23.3269 85.7583,24.1549 L85.7583,27.9659 L81.9483,27.9659 C81.1203,27.9659 80.4483,28.6379 80.4483,29.4659 C80.4483,30.2939 81.1203,30.9659 81.9483,30.9659 L85.7583,30.9659 L85.7583,34.7769 C85.7583,35.6049 86.4303,36.2769 87.2583,36.2769 C88.0873,36.2769 88.7583,35.6049 88.7583,34.7769 L88.7583,30.9659 L92.5693,30.9659 Z" fill={theme.secondaryColor}></path>
            <path d="M81.4375,25.7657 C81.7305,26.0587 82.1145,26.2047 82.4985,26.2047 C82.8815,26.2047 83.2655,26.0587 83.5585,25.7657 C84.1445,25.1797 84.1445,24.2307 83.5585,23.6447 L80.3535,20.4397 C79.7675,19.8537 78.8185,19.8537 78.2325,20.4397 C77.6465,21.0257 77.6465,21.9747 78.2325,22.5607 L81.4375,25.7657 Z" fill={theme.secondaryColor}></path>
            <path d="M93.3555,33.4415 C92.7695,32.8555 91.8205,32.8555 91.2345,33.4415 C90.6485,34.0275 90.6485,34.9765 91.2345,35.5625 L94.4395,38.7675 C94.7325,39.0605 95.1165,39.2075 95.5005,39.2075 C95.8835,39.2075 96.2675,39.0605 96.5605,38.7675 C97.1465,38.1815 97.1465,37.2325 96.5605,36.6465 L93.3555,33.4415 Z" fill={theme.secondaryColor}></path>
            <path d="M94.4395,20.4395 L91.2345,23.6445 C90.6485,24.2305 90.6485,25.1795 91.2345,25.7655 C91.5275,26.0585 91.9115,26.2055 92.2945,26.2055 C92.6785,26.2055 93.0625,26.0585 93.3555,25.7655 L96.5605,22.5605 C97.1465,21.9745 97.1465,21.0255 96.5605,20.4395 C95.9745,19.8535 95.0255,19.8535 94.4395,20.4395" fill={theme.secondaryColor}></path>
            <path d="M81.4375,33.4415 L78.2325,36.6465 C77.6465,37.2325 77.6465,38.1815 78.2325,38.7675 C78.5255,39.0605 78.9095,39.2075 79.2925,39.2075 C79.6765,39.2075 80.0605,39.0605 80.3535,38.7675 L83.5585,35.5625 C84.1445,34.9765 84.1445,34.0275 83.5585,33.4415 C82.9725,32.8555 82.0235,32.8555 81.4375,33.4415" fill={theme.secondaryColor}></path>
            <path d="M7.5,41 L6,41 L6,39.5 C6,38.672 5.328,38 4.5,38 C3.672,38 3,38.672 3,39.5 L3,41 L1.5,41 C0.672,41 0,41.672 0,42.5 C0,43.328 0.672,44 1.5,44 L3,44 L3,45.5 C3,46.328 3.672,47 4.5,47 C5.328,47 6,46.328 6,45.5 L6,44 L7.5,44 C8.328,44 9,43.328 9,42.5 C9,41.672 8.328,41 7.5,41" fill={theme.secondaryColor}></path>
            <path d="M7.8604,27 L47.3734,27 C51.7844,27 55.3734,23.412 55.3734,19.001 C55.3734,14.614 51.8234,11.04 47.4454,11.001 L47.4144,11.001 C45.2244,11.001 43.4434,9.22 43.4434,7.031 C43.4434,4.841 45.2244,3.06 47.4144,3.06 L75.5004,3.06 C76.3284,3.06 77.0004,2.388 77.0004,1.56 C77.0004,0.731 76.3284,0.06 75.5004,0.06 L47.4144,0.06 C43.5704,0.06 40.4434,3.188 40.4434,7.031 C40.4434,10.849 43.5294,13.959 47.3384,14.001 L47.3734,14.001 C50.1304,14.001 52.3734,16.244 52.3734,19.001 C52.3734,21.758 50.1304,24 47.3734,24 L7.8604,24 C7.0324,24 6.3604,24.672 6.3604,25.5 C6.3604,26.328 7.0324,27 7.8604,27" fill={theme.secondaryColor}></path>
            <path d="M215.1396,42.627 L175.6266,42.627 C172.8696,42.627 170.6266,40.385 170.6266,37.628 C170.6266,34.871 172.8696,32.628 175.6266,32.628 L175.6616,32.628 C179.4706,32.586 182.5566,29.476 182.5566,25.658 C182.5566,21.815 179.4296,18.687 175.5856,18.687 L158.1896,18.687 C157.3616,18.687 156.6896,19.358 156.6896,20.187 C156.6896,21.015 157.3616,21.687 158.1896,21.687 L175.5856,21.687 C177.7756,21.687 179.5566,23.468 179.5566,25.658 C179.5566,27.847 177.7756,29.628 175.5856,29.628 L175.5836,29.628 L175.5546,29.628 C171.1766,29.667 167.6266,33.241 167.6266,37.628 C167.6266,42.039 171.2156,45.627 175.6266,45.627 L215.1396,45.627 C215.9676,45.627 216.6396,44.955 216.6396,44.127 C216.6396,43.299 215.9676,42.627 215.1396,42.627" fill={theme.secondaryColor}></path>
            <path d="M152.626,18.6866 L139.5,18.6866 C138.672,18.6866 138,19.3586 138,20.1866 C138,21.0146 138.672,21.6866 139.5,21.6866 L152.626,21.6866 C153.454,21.6866 154.126,21.0146 154.126,20.1866 C154.126,19.3586 153.454,18.6866 152.626,18.6866" fill={theme.secondaryColor}></path>
            <path d="M95.5,0.0596 L81.5,0.0596 C80.672,0.0596 80,0.7316 80,1.5596 C80,2.3876 80.672,3.0596 81.5,3.0596 L95.5,3.0596 C96.328,3.0596 97,2.3876 97,1.5596 C97,0.7316 96.328,0.0596 95.5,0.0596" fill={theme.secondaryColor}></path>
            <path d="M105.5,0.0596 L101.5,0.0596 C100.672,0.0596 100,0.7316 100,1.5596 C100,2.3876 100.672,3.0596 101.5,3.0596 L105.5,3.0596 C106.328,3.0596 107,2.3876 107,1.5596 C107,0.7316 106.328,0.0596 105.5,0.0596" fill={theme.secondaryColor}></path>
            <path d="M64.5,33 C64.225,33 64,32.775 64,32.5 C64,32.225 64.225,32 64.5,32 C64.775,32 65,32.225 65,32.5 C65,32.775 64.775,33 64.5,33 M64.5,30 C63.121,30 62,31.121 62,32.5 C62,33.879 63.121,35 64.5,35 C65.879,35 67,33.879 67,32.5 C67,31.121 65.879,30 64.5,30" fill={theme.secondaryColor}></path>
            <path d="M76.5,51 C76.225,51 76,50.775 76,50.5 C76,50.225 76.225,50 76.5,50 C76.775,50 77,50.225 77,50.5 C77,50.775 76.775,51 76.5,51 M76.5,48 C75.121,48 74,49.121 74,50.5 C74,51.879 75.121,53 76.5,53 C77.879,53 79,51.879 79,50.5 C79,49.121 77.879,48 76.5,48" fill={theme.secondaryColor}></path>
            <path d="M156.5,39 C156.225,39 156,38.775 156,38.5 C156,38.225 156.225,38 156.5,38 C156.775,38 157,38.225 157,38.5 C157,38.775 156.775,39 156.5,39 M156.5,36 C155.121,36 154,37.121 154,38.5 C154,39.879 155.121,41 156.5,41 C157.879,41 159,39.879 159,38.5 C159,37.121 157.879,36 156.5,36" fill={theme.secondaryColor}></path>
            <path d="M112.5,17 C112.225,17 112,16.775 112,16.5 C112,16.225 112.225,16 112.5,16 C112.775,16 113,16.225 113,16.5 C113,16.775 112.775,17 112.5,17 M112.5,14 C111.121,14 110,15.121 110,16.5 C110,17.879 111.121,19 112.5,19 C113.879,19 115,17.879 115,16.5 C115,15.121 113.879,14 112.5,14" fill={theme.secondaryColor}></path>
            <path d="M206.5,57 C206.225,57 206,56.775 206,56.5 C206,56.225 206.225,56 206.5,56 C206.775,56 207,56.225 207,56.5 C207,56.775 206.775,57 206.5,57 M206.5,54 C205.121,54 204,55.121 204,56.5 C204,57.879 205.121,59 206.5,59 C207.879,59 209,57.879 209,56.5 C209,55.121 207.879,54 206.5,54" fill={theme.secondaryColor}></path>
            <path d="M192.5,34 C192.775,34 193,34.225 193,34.5 C193,34.775 192.775,35 192.5,35 C192.225,35 192,34.775 192,34.5 C192,34.225 192.225,34 192.5,34 M192.5,37 C193.879,37 195,35.879 195,34.5 C195,33.121 193.879,32 192.5,32 C191.121,32 190,33.121 190,34.5 C190,35.879 191.121,37 192.5,37" fill={theme.secondaryColor}></path>
            <path d="M131.567482,46.0422535 C113.081735,46.0422535 98.0418479,60.8265915 98.0418479,79 C98.0418479,97.1734085 113.081735,111.957746 131.567482,111.957746 C134.877454,111.957746 138.114411,111.491268 141.231707,110.568451 C129.594073,104.291268 122.258186,92.2682817 122.258186,79 C122.258186,65.7317183 129.594073,53.7087324 141.231707,47.4315493 C138.114411,46.5087324 134.877454,46.0422535 131.567482,46.0422535 M131.567482,115 C111.403425,115 94.9995944,98.8507042 94.9995944,79 C94.9995944,59.1492958 111.403425,43 131.567482,43 C136.493904,43 141.276327,43.9501972 145.781904,45.8221972 C146.348777,46.0584789 146.717904,46.612169 146.717904,47.2267042 C146.717904,47.8412394 146.348777,48.3949296 145.781904,48.6312113 C133.340101,53.8020282 125.300439,65.7225915 125.300439,79 C125.300439,92.2774085 133.340101,104.197972 145.781904,109.368789 C146.348777,109.60507 146.717904,110.158761 146.717904,110.773296 C146.717904,111.387831 146.348777,111.941521 145.781904,112.177803 C141.276327,114.049803 136.493904,115 131.567482,115" fill={theme.mainColor}></path>
          </g>
        </g>
      </g>
    </svg>
  );
  /* eslint-enable */
}

SvgSigninNight.propTypes = {
  theme: PropTypes.object.isRequired,
};

export default withTheme(SvgSigninNight);
