import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { autobind } from 'core-decorators';
import { formValueSelector } from 'redux-form';
import { withRouter } from 'react-router';

import { FlowManager } from '../../../../../components/wizard/flowManager';
import {
  createToggleState,
  createToggleStatePropTypes,
} from '../../../../../components/recompose/toggleStateComponent';

import actionTracker from '../../../../../store/tools/actionTracker/actionTrackerComponent';
import * as FlowDefs from './fullActivationFlowDefs';
import * as ActivationForm from '../activationForm';
import ActivationLayout from '../components/activationLayout';
import MobileNumberFlowStep from '../components/mobileNumberFlowStep';
import FindMemberFlowStep from './findMember/findMemberFlowStep';
import SignupFlowStep from '../components/signupFlowStep';
import NotFound from '../components/memberNotFound';
import { UPDATE_MEMBER } from '../../../../../store/member/actions';
import { activationToken, sourceSelector } from '../../../../../store/activation/selectors';
import queryConnect from '../../../../../store/tools/queryString/queryConnect';

@autobind
class FullActivationFlow extends React.PureComponent {
  static propTypes = {
    /**
     * source from url param
     */
    next: PropTypes.string.isRequired,
    source: PropTypes.string.isRequired,
    ...createToggleStatePropTypes('alreadyActivatedModal'),
  };

  static defaultProps = {};

  redirectToLandingPage() {
    this.props.router.replace(this.props.next);
  }

  render() {
    return (
      <ActivationLayout
        onSigninClick={this.redirectToLandingPage}
        alreadyActivatedModalOn={this.props.alreadyActivatedModalOn}
        alreadyActivatedModalHide={this.props.alreadyActivatedModalHide}
      >
        <FlowManager
          name={FlowDefs.name}
          stepsMeta={FlowDefs.flow}
          onFinish={this.redirectToLandingPage}
          initialStepName={FlowDefs.steps.ENTER_PHONE_NUMBER}
        >
          <MobileNumberFlowStep
            stepName={FlowDefs.steps.ENTER_PHONE_NUMBER}
            alreadyActivatedModalShow={this.props.alreadyActivatedModalShow}
          />
          <FindMemberFlowStep
            stepName={FlowDefs.steps.FIND_MEMBER}
            phoneNumber={this.props.phoneNumber}
          />
          <NotFound stepName={FlowDefs.steps.NOT_FOUND} />
          <SignupFlowStep
            stepName={FlowDefs.steps.SIGNUP}
            alreadyActivatedModalShow={this.props.alreadyActivatedModalShow}
            activationToken={this.props.activationToken}
          />
        </FlowManager>
      </ActivationLayout>
    );
  }
}

const fieldSelector = formValueSelector(ActivationForm.name);

export default compose(
  queryConnect(query => ({
    next: _.get(query, 'next', '/secure?first=true'),
  })),
  withRouter,
  createToggleState('alreadyActivatedModal'),
  connect(
    (state, ownProps) => ({
      source: sourceSelector(state, ownProps),
      phoneNumber: fieldSelector(state, ActivationForm.fields.phoneNumber.name),
      activationToken: activationToken(state),
    }),
    {},
  ),
  actionTracker({
    updateMemberTracking: UPDATE_MEMBER.SOURCE,
  }),
)(FullActivationFlow);
