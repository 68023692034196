import { compose, pure, withHandlers } from 'recompose';
import { defineMessages } from 'react-intl';
import { withTranslatedMessages } from '../../utils/withTranslatedMessages';
import SCR030OrderReview from '../../screenTemplates/SCR030OrderReview';
import withColrectalVideoModal from './colorectalVideoModal';

const messages = defineMessages({
  title: {
    defaultMessage: 'You ordered the at-home screening kit!',
    id: 'colorectalFlow.orderAKitReview.title',
  },
  buttonText: {
    defaultMessage: 'View video',
    id: 'colorectalFlow.orderAKitReview.buttonText',
  },
});

const OrderAKitReview = compose(
  pure,
  withTranslatedMessages(messages),
  withColrectalVideoModal,
  withHandlers({
    onButtonClick: ({ videoModalShow }) => () => {
      videoModalShow();
    }
  }),
)(SCR030OrderReview);

export default OrderAKitReview;
