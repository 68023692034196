import React from 'react';
import PropTypes from 'prop-types';
import * as _ from 'lodash';
import classnames from 'classnames';
import { autobind } from 'core-decorators';
import { FormattedMessage, defineMessages } from 'react-intl';
import Timeslot from './Timeslot';
import ClickableDiv from '../../components/clickableElement/clickableDiv';

const messages = defineMessages({
  hide: {
    defaultMessage: '- Hide',
    id: 'preCert.timeslots.clinicTimeslot.hide',
  },
  show: {
    defaultMessage: '+ Show All Timeslots',
    id: 'preCert.timeslots.clinicTimeslot.show',
  },
  numberOfResults: {
    defaultMessage: '{number} available',
    id: 'preCert.timeslots.clinicTimeslot.results',
  },
  singleResult: {
    defaultMessage: '{number} available',
    id: 'preCert.timeslots.clinicTimeslot.singleResult',
  },
});

const TIMESLOT_WIDTH = 92;

// TODO: generic messages

@autobind
class TimeslotsGroup extends React.Component {
  static propTypes = {
    /**
     * The timeslot list
     */
    timeslots: PropTypes.array.isRequired,

    /**
     * The date in DATE_KEY_FORMAT format
     */
    dateString: PropTypes.string.isRequired,

    /**
     * React-Router's params
     */
    params: PropTypes.any,
    /**
     * on timeslot Time Selection
     */
    onTimeslotSelect: PropTypes.func.isRequired,
  };

  static defaultProps = {
    params: {},
  };

  static contextTypes = {
    /**
     * the router
     */
    router: PropTypes.object.isRequired,
  };

  constructor() {
    super();
    this.state = {
      showAll: false,
    };
  }

  /**
   * called whenever the component is mounted
   */
  componentDidMount() {
    this.calcTimeslotInRow();
  }

  calcTimeslotInRow() {
    const timeslotList = this.results;
    const containerWidth = timeslotList.getBoundingClientRect().width;
    const timeslotsInLine = Math.floor(containerWidth / TIMESLOT_WIDTH);
    this.setState({
      timeslotsInLine,
      timeslotLimit: timeslotsInLine * 3,
    });
  }

  /**
   * Returns the day in LONG_NAMING_DATE format
   * @returns {*}
   */
  day() {
    return this.props.dateString;
  }

  /**
   * Toggles show all state
   */
  toggleShowAll() {
    this.setState({ showAll: !this.state.showAll });
  }

  /**
   * Renders the timeslot list (hours)
   * @returns {Array}
   */
  renderTimeslots() {
    const timeslots = [];

    _.each(this.props.timeslots, (timeslot, i) => {
      if (i < this.state.timeslotLimit || this.state.showAll) {
        timeslots.push(
          <Timeslot
            className={classnames({ showAll: this.state.showAll })}
            key={i}
            router={this.context.router}
            params={this.props.params}
            timeslot={timeslot}
            timeslots={this.props.timeslots}
            onTimeslotSelect={this.props.onTimeslotSelect}
          />,
        );
      }
    });

    return timeslots;
  }

  /**
   * Renders the show all button if needed
   */
  renderToggleButton() {
    // In case there are less timeslots than the limit, hide the button
    if (this.props.timeslots.length <= this.state.timeslotLimit) return false;
    const messageToShow = this.state.showAll ? messages.hide : messages.show;

    return (
      <ClickableDiv className="card-footer-btn text-center" clickHandler={this.toggleShowAll}>
        <span className="font-color-brand-link">
          <FormattedMessage {...messageToShow} />
        </span>
      </ClickableDiv>
    );
  }

  renderNumberOfResults() {
    const numberOfTimeslots = this.props.timeslots.length;
    const resultMessage = numberOfTimeslots > 1 ? messages.numberOfResults : messages.singleResult;
    return (
      <div className="results-count text-14">
        <FormattedMessage
          {...resultMessage}
          values={{ number: numberOfTimeslots }}
        />
      </div>
    );
  }

  render() {
    return (
      <div className="card result-wrap time">
        <div className="result-date">
          <div className="day">
            <i className="icon-calendar-sm" />
            {this.day()}
          </div>
          {this.renderNumberOfResults()}
        </div>
        <div className="text-center">
          <ul
            className="result-time-list font-color-brand-secondary"
            ref={instance => {
              this.results = instance;
            }}
          >
            {this.renderTimeslots()}
            <div className="spacer" />
            {this.renderToggleButton()}
          </ul>
        </div>
      </div>
    );
  }
}

export default TimeslotsGroup;
