/**
 * Created by matan on 9/5/16.
 */
import _ from 'lodash';
import PromiseActionType from '../promiseActionType';

export const GET_LOCATION = new PromiseActionType('GET_LOCATION');

/**
 * Retrieve the device current location.
 * User permission is required!
 * @param {object=} options - PositionOptions
 * @param {bool=} options.enableHighAccuracy - Order the device to attempt an retrieve the most
 * accurate locations possible. Might take longer. default is true.
 * @param {number=} options.timeout - Request timeout in milliseconds. default is infinity.
 * @param {number=} options.maximumAge - maximum age in milliseconds of cached position the device
 * has and allowed to retrieve. default is 0 (i.e don't use cache)
 */
export function getCurrentLocation(options = {}) {
  return ({ getState }) => {
    const state = getState();
    const config = _.get(state, 'config.myLocationOptions', {});
    // WebAPI PositionOptions can be viewed at:
    // https://developer.mozilla.org/en-US/docs/Web/API/PositionOptions
    const _options = _.assign({}, config, options);
    return {
      type: GET_LOCATION.SOURCE,
      payload: {
        promise: new Promise((resolve, reject) => {
          if (typeof navigator === 'undefined') {
            reject(new Error('navigator global object is not defined'));
          }
          navigator.geolocation.getCurrentPosition(resolve, reject, _options);

          // Note: fix issue with ie11 which makes getCurrentPosition to not
          // call reject if user didn't respond to location prompt
          setTimeout(reject, config.timeout);
        }),
      },
      meta: {
        tracker: GET_LOCATION.SOURCE,
      },
    };
  };
}
