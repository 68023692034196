import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withPropsOnChange } from 'recompose';
import { autobind } from 'core-decorators';
import { formValueSelector, destroy } from 'redux-form';
import { defineMessages } from 'react-intl';
import { withRouter } from 'react-router';

import Formatter from 'Utils/formatter';
import SettingsLayout from '../../settingsLayout';
import Layout from '../../../layout/layout';
import injectNotification from '../../../../../../store/notification/injectNotification';
import actionTracker from '../../../../../../store/tools/actionTracker/actionTrackerComponent';
import Verification from '../../../../../../components/verification/verification';
import * as FormDef from './updatePhoneNumberDef';
import { paths } from '../../paths';
import {
  VERIFY_PHONE_NUMBER,
  resendPhoneNumberVerification,
  verifyPhoneNumber,
} from '../../../../../../store/user/actions';
import * as Errors from '../../../../../../api/errors';

const messages = defineMessages({
  wrongCodeMessage: {
    defaultMessage: "Let's try again, that doesn't seem to match the code we sent.",
    id: 'activation.flow.error.wrongCodeMessage',
  },
});
const wrongCodeTitle = ' ';

@autobind
class VerificationStep extends React.Component {
  static propTypes = {
    /**
     * Server request action tracking.
     */
    verifyPhoneNumberTracking: PropTypes.object.isRequired,
    /**
     * Redux action creator.
     */
    verifyPhoneNumber: PropTypes.func.isRequired,
    /**
     * Redux action creator.
     */
    resendPhoneNumberVerification: PropTypes.func.isRequired,
    /**
     * Injected prop for notifying on errors
     */
    notification: PropTypes.object.isRequired,
    /**
     * Router object.
     * Provided by the withRouter HoC
     */
    router: PropTypes.object.isRequired,
    phoneNumber: PropTypes.object.isRequired,
    /**
     * Action creator for redux-form destroy action.
     */
    destroy: PropTypes.func.isRequired,
  };

  componentWillReceiveProps(nextProps) {
    const { verifyPhoneNumberTracking } = nextProps;
    if (verifyPhoneNumberTracking.finished) {
      if (verifyPhoneNumberTracking.hasError) {
        const { responseMessage } = verifyPhoneNumberTracking.error;
        if (responseMessage !== Errors.RECAPTCHA_FAILED) {
          this.props.notification.error(wrongCodeTitle, messages.wrongCodeMessage, {
            autoDismiss: 10,
          });
        }
      } else {
        this.props.destroy(FormDef.name);
        this.props.router.replace(paths.profile());
      }
    }
  }

  onSubmit({ verificationCode }) {
    this.props.verifyPhoneNumber(verificationCode);
  }

  resendCode() {
    this.props.resendPhoneNumberVerification(this.props.phoneNumber);
  }

  render() {
    return (
      <Layout
        hideLogo
        centeredBackBtn
        previousPageName="your profile"
        previousPageRoute={paths.profile()}
      >
        <SettingsLayout>
          <div className="main-content padding-box-30">
            <Verification
              onSubmit={this.onSubmit}
              loading={this.props.verifyPhoneNumberTracking.inProgress}
              identifier={this.props.phoneNumber}
              resendCode={this.resendCode}
              withRecaptcha
            />
          </div>
        </SettingsLayout>
      </Layout>
    );
  }
}

const fieldSelector = formValueSelector(FormDef.name);
export default compose(
  connect(
    state => ({
      phoneNumber: fieldSelector(state, FormDef.fields.phoneNumber.name),
    }),
    {
      resendPhoneNumberVerification,
      verifyPhoneNumber,
      destroy,
    },
  ),
  actionTracker({
    verifyPhoneNumberTracking: VERIFY_PHONE_NUMBER.SOURCE,
  }),
  withPropsOnChange(['phoneNumber'], ({ phoneNumber }) => ({
    phoneNumber: Formatter.phoneNumberDefault(phoneNumber),
  })),
  injectNotification,
  withRouter,
)(VerificationStep);
