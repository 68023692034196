/**
 * Created by meirshalev 18/10/2017.
 */
import PromiseActionType from '../promiseActionType';
import handleLoginResponse from '../auth/handleLoginResponse';

export const SSO_SIGNIN = new PromiseActionType('SSO_SIGNIN');
export const CLEAR = 'CLEAR';

export function ssoSignin(insuranceId) {
  return ({ bkmdApi: { ssoApi } }) => ({
    type: SSO_SIGNIN.SOURCE,
    meta: {
      tracker: SSO_SIGNIN.SOURCE,
    },
    payload: {
      promise: ssoApi.ssoSignin(insuranceId)
      .then(handleLoginResponse)
    }
  });
}

export function clear() {
  return {
    type: CLEAR,
  };
}
