import Rox from 'rox-browser';
import _ from 'lodash';
import * as FlagTypes from './flagTypes';
import { safeLocalStorage } from '../storage/localStorage';

Rox.setDynamicCustomPropertyRule((propName, context) => _.get(context, propName));

const isLoggingEnabled = () => safeLocalStorage.getItem('VIM_FLAG_LOGGER') === 'true';

const variationByFlagType = {
  [FlagTypes.Boolean]: (...args) => Rox.dynamicApi.isEnabled(...args),
  [FlagTypes.String]: (...args) => Rox.dynamicApi.value(...args),
};

const cache = {};

class FeatureFlagClient {
  async init(clientId) {
    await Rox.setup(clientId, { freeze: 'none' });
    this.shouldUseCache = Rox.dynamicApi.isEnabled('featureFlagsInfra.shouldUseCache', false);
  }

  variation(key, defaultValue, context = {}, flagType) {
    const cacheKey = key + JSON.stringify(context);
    if (this.shouldUseCache && cache[cacheKey]) {
      if (isLoggingEnabled()) {
        // eslint-disable-next-line
        console.log(`[Rollout] Checking flag ${key} in cache, value is ${cache[cacheKey]}`);
      }
      return cache[cacheKey];
    }
    const value = variationByFlagType[flagType](key, defaultValue, context);
    if (this.shouldUseCache) cache[cacheKey] = value;
    if (isLoggingEnabled()) {
      console.log(`[Rollout] Checking flag ${key}, value is ${value}`); // eslint-disable-line
    }
    return value;
  }

  showOverrides() {
    this.shouldUseCache = false;
    Rox.showOverrides();
  }
}

export default new FeatureFlagClient();
