/**
 * Created by guyavarham on 11/06/2017.
 */

import { defineMessages } from 'react-intl';

const messages = defineMessages({
  enterYourInsuranceInfo: {
    defaultMessage: 'Enter your insurance info',
    id: 'members.insuranceID.enterYourInsuranceInfo',
  },
  confirmYourIdToGetSearchResults: {
    defaultMessage: 'Confirm your ID to get secure access to your health information.',
    id: 'members.insuranceID.confirmYourIdToGetSearchResults',
  },
  learnMore: {
    defaultMessage: 'Learn more',
    id: 'members.insuranceID.learnMore',
  },
  myInsuranceId: {
    defaultMessage: 'My insurance ID number',
    id: 'members.insuranceID.myInsuranceId',
  },
  myDateOfBirth: {
    defaultMessage: 'My date of birth',
    id: 'members.insuranceID.myDateOfBirth',
  },
  whereCanIFindMyIdNumber: {
    defaultMessage: 'Where can I find my ID number?',
    id: 'members.insuranceID.whereCanIFindMyIdNumber',
  },
  submit: {
    defaultMessage: 'Submit',
    id: 'members.insuranceID.submit',
  },
  enterMyInsuranceIdLater: {
    defaultMessage: 'Enter my insurance ID later',
    id: 'members.insuranceID.enterMyInsuranceIdLater',
  },
  errorTitle: {
    defaultMessage: 'Sorry, adding insurance info failed',
    id: 'members.insuranceID.loginErrorTitle',
  },
  errorMessage: {
    defaultMessage: 'Please check your insurance ID and birthdate',
    id: 'members.insuranceID.loginErrorMessage',
  },
  successTitle: {
    defaultMessage: 'Thank you',
    id: 'members.insuranceID.successTitle',
  },
  successMessage: {
    defaultMessage: 'Your insurance info is confirmed',
    id: 'members.insuranceID.successMessage',
  },
});

export default messages;
