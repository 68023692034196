import { autobind } from 'core-decorators';
import _ from 'lodash';
import BkmdApi from './bkmdApi';
import ServicesApi from './services';
import { VIM } from '../model/enum/bkmdDomain';
import * as NetworkStatusEnum from '../model/enum/networkStatus';

const DOMAIN_MEMBER_SERVICE_NAME = 'domainMember';

@autobind
export default class PayerApi extends BkmdApi {
  constructor(fetch) {
    super(fetch, '/members/');
    this.servicesApi = new ServicesApi(fetch);
  }

  formatGeo(geo) {
    // elastic lat, postgres latitude
    return {
      latitude: geo.latitude || geo.lat,
      longitude: geo.longitude || geo.lon,
    };
  }

  selectDomainMemberId(externalMemberId, vimDomainMemberId, externalMemberDomain) {
    return externalMemberDomain === VIM ? vimDomainMemberId : externalMemberId;
  }

  isInNetwork(
    providerNpi,
    providerLocationGeo,
    externalMemberId,
    externalMemberDomain,
    vimDomainMemberId,
  ) {
    const formattedGeo = this.formatGeo(providerLocationGeo);
    const selectedDomainMemberId = this.selectDomainMemberId(
      externalMemberId,
      vimDomainMemberId,
      externalMemberDomain,
    );
    return this.servicesApi
      .call(
        DOMAIN_MEMBER_SERVICE_NAME,
        'isInNetwork',
        { providerNpi, providerLocationGeo: formattedGeo, domainMemberId: selectedDomainMemberId },
        externalMemberDomain,
      )
      .then(response => _.get(response, 'data'))
      .catch(() => ({
        result: NetworkStatusEnum.UNVERIFIED_NETWORK,
      }));
  }
}
