import classNames from 'classnames';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ClickableDiv from '../../clickableElement/clickableDiv';

const maxPagesToDisplay = 10;
const Noop = () => null;

/**
 * Created by asafdavid on 08/01/2018.
 */
class CustomPager extends PureComponent {
  static propTypes = {
    maxPages: PropTypes.number,
    currentPage: PropTypes.number,
    setPage: PropTypes.func,
  };

  static defaultProps = {
    maxPages: 1,
    currentPage: 1,
    setPage: Noop,
  };

  render() {
    const { maxPages, currentPage, setPage } = this.props;
    const pageLinks = [];
    let minPage = Math.max(currentPage - (maxPagesToDisplay / 2), 1);
    const maxPage = Math.min(minPage + maxPagesToDisplay - 1, maxPages);
    if (maxPage - minPage < maxPages && maxPagesToDisplay < maxPages) {
      minPage = maxPage - maxPagesToDisplay + 1;
    }

    for (let i = minPage; i <= maxPage; i++) {
      pageLinks.push(
        <li key={i} className={classNames({ active: currentPage === i })}>
          <ClickableDiv clickHandler={() => setPage(i)}>{i}</ClickableDiv>
        </li>
      );
    }

    return (
      <ul className="pagination bkmd-pagination pagination-lg pagination-vim">
        {pageLinks}
      </ul>
    );
  }
}

export default CustomPager;
