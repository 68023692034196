/* eslint-disable import/first */
/**
 * Created by meirshalev on 06/06/2017.
 */
if (typeof require.ensure !== 'function') require.ensure = (d, c) => c(require);

import SettingsHome from '../../settings/settingsHome';
import divComponent from '../divComponent';
import fetchProfile from '../../settings/fetchProfile';

import '../../settings/settings.less';

// Routes
const Profile = require('../../settings/profile/profile');
const UpdatePhoneNumber = require('../../settings/profile/updatePhoneNumber/updatePhoneNumberPage');
const UpdateEmail = require('../../settings/profile/updateEmail/changeEmailPage');
const UpdatePassword = require('../../settings/profile/updatePassword/updatePasswordPage');
const Notifications = require('../../settings/notifications');
const Locations = require('../../settings/locations/locations');
const ChangeHomeAddress = require('../../settings/locations/updateHomeAddressPage');
const ChangeWorkAddress = require('../../settings/locations/updateWorkAddressPage');

// Components
const Components = [
  {
    path: 'profile',
    name: 'profile',
    component: fetchProfile(Profile.default || Profile),
  },
  {
    path: 'updatePhoneNumber',
    name: 'updatePhoneNumber',
    component: UpdatePhoneNumber.default || UpdatePhoneNumber,
  },
  {
    path: 'updateEmail',
    name: 'updateEmail',
    component: fetchProfile(UpdateEmail.default || UpdateEmail),
  },
  {
    path: 'updatePassword',
    name: 'updatePassword',
    component: fetchProfile(UpdatePassword.default || UpdatePassword),
  },
  {
    path: 'notifications',
    name: 'notifications',
    component: fetchProfile(Notifications.default || Notifications),
  },
  {
    path: 'locations',
    name: 'locations',
    component: fetchProfile(Locations.default || Locations),
  },
  {
    path: 'homeAddress',
    name: 'homeAddress',
    component: fetchProfile(ChangeHomeAddress.default || ChangeHomeAddress),
  },
  {
    path: 'workAddress',
    name: 'workAddress',
    component: fetchProfile(ChangeWorkAddress.default || ChangeWorkAddress),
  },
];

export default {
  path: 'settings',
  name: 'settings',
  getChildRoutes(location, cb) {
    require.ensure([], () => {
      cb(null, Components);
    });
  },

  // Requiring secured page NOT dynamically so it won't be unmounted every route
  component: divComponent,
  indexRoute: {
    name: 'settingsHome',
    component: fetchProfile(SettingsHome.default || SettingsHome),
  },
};
/* eslint-enable import/first */
