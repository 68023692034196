/**
 * Created by asafdavid on 17/01/2016.
 */
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import CleanInput from './cleanInput';
import Formatter from '../../utils/formatter';

const DEFAULT_DIALING_CODE = '+1';

class CleanPhoneNumber extends React.Component {
  /**
   * Properties
   */
  static propTypes = {
    /**
     * Callback function that will be called when the phone number is changes
     */
    onChange: PropTypes.func,

    /**
     * The value of the component, number and dialing code.
     * Defaults are number = undefined, countryDialingCode = '+1'
     */
    value: PropTypes.shape({
      number: PropTypes.string, // eslint-disable-line react/no-unused-prop-types
      countryDialingCode: PropTypes.string, // eslint-disable-line react/no-unused-prop-types
    }),
  };

  static defaultProps = {
    value: { countryDialingCode: DEFAULT_DIALING_CODE, number: undefined },
    onChange: undefined,
  };

  constructor(props) {
    super(props);

    this.state = {
      internalValue: this.formatNumber(_.get(props, 'value.number')),
    };

    this.formatNumber = this.formatNumber.bind(this);
    this._wrapOutputEvent = this._wrapOutputEvent.bind(this);
    this.handleNumberChange = this.handleNumberChange.bind(this);
    this.updateRef = this.updateRef.bind(this);
    this.mounted = false;
  }

  componentDidMount() {
    this.mounted = true;
  }

  /**
   * Updates the internal state
   * @param nextProps
   */
  componentWillReceiveProps(nextProps) {
    setTimeout(() => {
      if (this.mounted) {
        this.setState({ internalValue: this.formatNumber(_.get(nextProps, 'value.number')) });
      }
    });
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  _getDialingCode() {
    return _.get(this.props, 'value.countryDialingCode', '+1');
  }

  /**
   * Formats a number
   * @param number
   */
  formatNumber(number) {
    if (!number) return '';
    const countryDialingCode = this._getDialingCode();

    return Formatter.phoneNumber({ countryDialingCode, number });
  }

  /**
   * Wraps outputting events such as onChange so the output will be the phone number
   * structure and not a simple string
   */
  _wrapOutputEvent(innerEvent, event) {
    const countryDialingCode = this._getDialingCode();
    if (innerEvent) {
      innerEvent(
        event.target.value ? {
          number: event.target.value.replace(/\D/g, ''),
          countryDialingCode,
        } : null
      );
    }
  }

  handleNumberChange(event) {
    this._wrapOutputEvent(this.props.onChange, event);
  }

  updateRef(clean) {
    this._input = clean ? clean._input : null;
  }

  /**
   * Wraps CleanInput and adds the needed functionality
   * @returns {XML}
   */
  render() {
    // Return the input
    return (
      <CleanInput
        type="tel"
        {...this.props}
        value={this.state.internalValue}
        onChange={this.handleNumberChange}
        ref={this.updateRef}
      />
    );
  }
}

export default CleanPhoneNumber;
