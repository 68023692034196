import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '../../storybook/src/utils/theme';

function SvgMedication({ theme }) {
  /* eslint-disable */
  return (
    <svg aria-hidden="true" focusable="false" className="svg-icon svg-medication" width="26px" height="32px" viewBox="0 0 26 32" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g>
          <g>
            <g fill={theme.secondaryColor}>
              <path d="M22,8 L24,8 L24,2 L22,2 L22,8 Z M18,8 L20,8 L20,2 L18,2 L18,8 Z M14,8 L16,8 L16,2 L14,2 L14,8 Z M10,8 L12,8 L12,2 L10,2 L10,8 Z M6,8 L8,8 L8,2 L6,2 L6,8 Z M25,0 C25.552,0 26,0.448 26,1 L26,9 C26,9.552 25.552,10 25,10 L22,10 L20,10 L6,10 L4,10 L1,10 C0.448,10 0,9.552 0,9 L0,1 C0,0.448 0.448,0 1,0 L25,0 Z M2,8 L4,8 L4,2 L2,2 L2,8 Z" ></path>
            </g>
            <path d="M20,30 L6,30 L6,28 L8.5,28 C9.052,28 9.5,27.552 9.5,27 C9.5,26.448 9.052,26 8.5,26 L6,26 L6,24 L10.5,24 C11.052,24 11.5,23.552 11.5,23 C11.5,22.448 11.052,22 10.5,22 L6,22 L6,20 L8.5,20 C9.052,20 9.5,19.552 9.5,19 C9.5,18.448 9.052,18 8.5,18 L6,18 L6,16 L10.5,16 C11.052,16 11.5,15.552 11.5,15 C11.5,14.448 11.052,14 10.5,14 L6,14 L6,10 L4,10 L4,31 C4,31.552 4.448,32 5,32 L21,32 C21.552,32 22,31.552 22,31 L22,10 L20,10 L20,30 Z" fill={theme.mainColor} ></path>
          </g>
        </g>
      </g>
    </svg>
  );
  /* eslint-enable */
}

SvgMedication.propTypes = {
  theme: PropTypes.object.isRequired,
};

export default withTheme(SvgMedication);
