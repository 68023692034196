import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '../../storybook/src/utils/theme';

function SvgUserQuestionMark({ theme }) {
  /* eslint-disable */
  return (
    <svg aria-hidden="true" focusable="false" width="68px" height="67px" viewBox="0 0 68 67" version="1.1" className="svg-icon svg-question-mark">
      <g id="flow" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="992-new-appointment---can't-find-patient-after-3-times" transform="translate(-799.000000, -78.000000)">
          <g id="Widget">
            <g id="Page-1" transform="translate(799.000000, 78.000000)">
              <path d="M0.0004,23.8067 C0.0004,20.2461759 2.88606981,17.3597 6.4464,17.3597 C10.0076256,17.3597 12.8944,20.2460714 12.8944,23.8067 C12.8944,27.3673286 10.0076256,30.2537 6.4464,30.2537 C2.88606981,30.2537 0.0004,27.3672241 0.0004,23.8067 Z M3.0004,23.8067 C3.0004,25.7105574 4.54311165,27.2537 6.4464,27.2537 C8.35086512,27.2537 9.8944,25.7103806 9.8944,23.8067 C9.8944,21.9030194 8.35086512,20.3597 6.4464,20.3597 C4.54311165,20.3597 3.0004,21.9028426 3.0004,23.8067 Z" id="Stroke-1" fill={theme.secondaryColor} fillRule="nonzero"></path>
              <path d="M26.5755474,5.28980964 C25.8891073,5.7535893 24.95667,5.5730874 24.4928904,4.88664736 C24.0291107,4.20020731 24.2096126,3.26777002 24.8960526,2.80399036 C27.5860267,0.9865625 30.758008,-0.0001 34.0738,-0.0001 C43.1380675,-0.0001 50.4858,7.34831323 50.4858,16.4129 C50.4858,17.468335 50.384431,18.511027 50.1848362,19.5301872 C50.0256196,20.3431703 49.237496,20.8731528 48.4245128,20.7139362 C47.6115297,20.5547196 47.0815472,19.766596 47.2407638,18.9536128 C47.4032026,18.124177 47.4858,17.2745713 47.4858,16.4129 C47.4858,9.00510527 41.481151,2.9999 34.0738,2.9999 C31.3613451,2.9999 28.7733428,3.80491258 26.5755474,5.28980964 Z" id="Stroke-3" fill={theme.mainColor} fillRule="nonzero"></path>
              <path d="M5.7481,59.6504 L28.0971,59.6504 C28.9255271,59.6504 29.5971,60.3219729 29.5971,61.1504 C29.5971,61.9788271 28.9255271,62.6504 28.0971,62.6504 L4.2481,62.6504 C3.41967288,62.6504 2.7481,61.9788271 2.7481,61.1504 L2.7481,56.1794 C2.7481,43.4262978 10.4496759,32.1591437 21.9011518,27.3312147 C19.2070381,24.3597132 17.6601,20.5172354 17.6601,16.4124 C17.6601,15.5839729 18.3316729,14.9124 19.1601,14.9124 C19.9885271,14.9124 20.6601,15.5839729 20.6601,16.4124 C20.6601,20.4473607 22.4952704,24.1734287 25.6139642,26.7341095 C26.5263707,27.4832634 26.255806,28.9440276 25.1356132,29.3167008 C13.6501665,33.1377554 5.7481,43.8970355 5.7481,56.1794 L5.7481,59.6504 Z" id="Stroke-5" fill={theme.mainColor} fillRule="nonzero"></path>
              <path d="M18.5883,6.5694 C18.5883,5.74097288 19.2598729,5.0694 20.0883,5.0694 C20.9167271,5.0694 21.5883,5.74097288 21.5883,6.5694 L21.5883,11.1654 C21.5883,11.9938271 20.9167271,12.6654 20.0883,12.6654 C19.2598729,12.6654 18.5883,11.9938271 18.5883,11.1654 L18.5883,6.5694 Z" id="Stroke-7" fill={theme.secondaryColor} fillRule="nonzero"></path>
              <path d="M22.3862,7.3672 C23.2146271,7.3672 23.8862,8.03877288 23.8862,8.8672 C23.8862,9.69562712 23.2146271,10.3672 22.3862,10.3672 L17.7902,10.3672 C16.9617729,10.3672 16.2902,9.69562712 16.2902,8.8672 C16.2902,8.03877288 16.9617729,7.3672 17.7902,7.3672 L22.3862,7.3672 Z" id="Stroke-9" fill={theme.secondaryColor} fillRule="nonzero"></path>
              <path d="M45.3979,26.5552 C45.3979,27.7692 46.3819,28.7532 47.5959,28.7532 C48.8109,28.7532 49.7949,27.7692 49.7949,26.5552 C49.7949,25.3412 48.8109,24.3562 47.5959,24.3562 C46.3819,24.3562 45.3979,25.3412 45.3979,26.5552" id="Fill-11" fill="#16ABE2"></path>
              <path d="M49.395,55.2305 C50.523,55.2305 51.473,56.1775 51.473,57.3085 C51.473,58.4345 50.523,59.3845 49.395,59.3845 C48.268,59.3845 47.319,58.4345 47.319,57.3085 C47.319,56.1775 48.268,55.2305 49.395,55.2305 M49.96,46.8015 C51.057,46.0285 52.036,45.3465 52.036,44.1895 C52.036,43.0325 51.147,42.0535 49.218,42.0535 C47.406,42.0535 46.101,42.8815 45.09,44.1285 L43.104,41.9035 C44.617,40.0645 46.903,38.9645 49.574,38.9645 C53.312,38.9645 55.598,40.9545 55.598,43.6265 C55.598,46.0885 53.935,47.2755 52.452,48.3145 C51.326,49.1455 50.287,49.8585 50.287,51.0445 C50.287,51.5185 50.552,52.0555 50.939,52.3785 L48.327,53.2425 C47.614,52.5575 47.258,51.6975 47.258,50.7185 C47.258,48.7305 48.684,47.7195 49.96,46.8015" id="Fill-13" fill={theme.secondaryColor} ></path>
              <path d="M64.3354,48.5284 C64.3354,40.4979673 57.825113,33.9874 49.7954,33.9874 C41.7648271,33.9874 35.2544,40.4978271 35.2544,48.5284 C35.2544,56.558113 41.7649673,63.0684 49.7954,63.0684 C57.8249729,63.0684 64.3354,56.5579729 64.3354,48.5284 Z M67.3354,48.5284 C67.3354,58.2148271 59.4818271,66.0684 49.7954,66.0684 C40.108142,66.0684 32.2544,58.2149962 32.2544,48.5284 C32.2544,38.8409729 40.1079729,30.9874 49.7954,30.9874 C59.4819962,30.9874 67.3354,38.841142 67.3354,48.5284 Z" id="Stroke-15" fill={theme.mainColor} fillRule="nonzero"></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
  /* eslint-enable */
}

SvgUserQuestionMark.propTypes = {
  theme: PropTypes.object.isRequired,
};

export default withTheme(SvgUserQuestionMark);
