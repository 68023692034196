import React from 'react';
import PropTypes from 'prop-types';
import { string, object } from 'yup';
import { defineMessages } from 'react-intl';
import { Field, Form } from 'formik';
import { WrappedFormik } from '../../form/formik/wrappedFormik';
import FormActionButtons from '../../molecules/FormSubmissionButtons/FormActionButtons';
import { withTranslatedMessages } from '../../../utils/withTranslatedMessages';
import { CleanFormikInput } from '../../form/formik/cleanFormikInput';
import './ReasonForVisit.less';

const messages = defineMessages({
  primaryButtonText: {
    defaultMessage: 'Add',
    id: 'members.directory.booking.reasonForVisit.primaryButtonText',
  },
  secondaryButtonText: {
    defaultMessage: 'Skip',
    id: 'members.directory.booking.reasonForVisit.secondaryButtonText',
  },
  label: {
    defaultMessage: 'Reason for visit',
    id: 'members.directory.booking.reasonForVisitForm.label',
  },
});

const scheme = object().shape({
  reasonForVisit: string().label('Reason for visit'),
});

class ReasonForVisitForm extends React.PureComponent {
  static propTypes = {
    initialValues: PropTypes.object,
    onSubmit: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
  };

  static defaultProps = {
    initialValues: {
      reasonForVisit: '',
    },
  };

  render() {
    const { initialValues, onSubmit, label, ...props } = this.props;
    return (
      <WrappedFormik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={scheme}
        render={() => (
          <Form>
            <Field
              name="reasonForVisit"
              label={label}
              inputClassName="no-margin"
              component={CleanFormikInput}
              maxLength={255}
              id="reasonForVisitInput"
              showLimitSpan
            />
            <div data-id="form-buttons" className="margin-top-30">
              <FormActionButtons {...props} />
            </div>
          </Form>
        )}
      />
    );
  }
}

export default withTranslatedMessages(messages)(ReasonForVisitForm);
