/**
 * Created by galgoltzman on 11/07/2017.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import _ from 'lodash';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';

import SpecializesInProfessionalSection from './specializesInSection';
import ProfessionalSection from './proffessionalSection';
import ProffessionalSectionForMultipleValues from './proffessionalSectionForMultipleValues';
import messages from '../../../messages/doctorDetails/messages';
import SvgEducation from '../../../assets/svg/education';
import SvgLanguage from '../../../assets/svg/language';
import SvgBoardCertification from '../../../assets/svg/boardCertification';
import './professionalBackground.less';

class ProfessionalBackground extends React.PureComponent {
  static propTypes = {
    details: PropTypes.object.isRequired,
    intl: intlShape.isRequired,
  };

  render() {
    const {
      details,
      intl,
    } = this.props;

    const gender = details.gender;
    const isCertified = (details.boardCertifications == 'true'); // eslint-disable-line eqeqeq
    const certifiedMessage = isCertified ? intl.formatMessage(messages.boardCertified) : null;
    let languages = details.languages ? _.map(_.compact(details.languages), lang => lang.name) : [];
    languages.unshift('English');  // set English as default in case there is'nt
    languages = _.uniq(languages);

    return (<div className="professional-background-section">
      <div className="card">
        <div className="card-body">
          <div className="text-16 text-strong-dark">
            <FormattedMessage {...messages.professionalBackground} />
          </div>

          <ProfessionalSection
            data={certifiedMessage}
            message={{ ...messages.certifications, values: { gender } }}
          >
            <SvgBoardCertification />
          </ProfessionalSection>

          <SpecializesInProfessionalSection details={details} />

          <ProfessionalSection
            data={details.medicalSchool || details.medicalDegree}
            message={{ ...messages.gotMedicalDegreeAt, values: { gender } }}
          >
            <SvgEducation />
          </ProfessionalSection>

          <ProffessionalSectionForMultipleValues
            dataArray={languages}
            message={{ ...messages.speaks, values: { gender } }}
          >
            <SvgLanguage />
          </ProffessionalSectionForMultipleValues>
        </div>
      </div>
    </div>);
  }
}

export default compose(
  injectIntl,
)(ProfessionalBackground);
