/**
 * Created by anna on 06/08/2017.
 */
import Immutable from 'seamless-immutable';
import _ from 'lodash';
import { GET_REVIEWS_BY_NPI, GET_REVIEW_BY_NPI } from './actions';

const initialState = {
  reviews: {},
  random: {},
};

export default function appointmentReview(state = initialState, action) {
  state = Immutable(state);
  switch (action.type) {
    case GET_REVIEWS_BY_NPI.SUCCESS: {
      const reviews = _.get(action, 'payload.data');
      if (!reviews) {
        return initialState;
      }

      const mapReviews = {};
      _.map(reviews, review => {
        mapReviews[review.token] = review;
      });
      return state.merge({ reviews: _.orderBy(mapReviews, 'reviewDate', 'desc') });
    }
    case GET_REVIEW_BY_NPI.SUCCESS: {
      const review = _.get(action, 'payload.data');
      if (!review) {
        return initialState;
      }
      return state.merge({ random: review });
    }
    default:
      return state;
  }
}
