/**
 * Created by galgoltzman on 28/05/2017.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, defineMessages } from 'react-intl';
import Header from '../../../../../components/ui/header/header';
import reviewCompose from '../doctorReviewHOC';
import './doctorReview.less';
import displayProvider from '../../../../../utils/displayProvider';

const messages = defineMessages({
  tellUsMore: {
    defaultMessage: 'Tell us about {providerDisplayName}',
    id: 'members.reviewProcess.tellUsMore',
  },
});

class DoctorReviewLayout extends React.PureComponent {
  static propTypes = {
    headerClassName: PropTypes.string,
    reviewProcess: PropTypes.object.isRequired,
  };

  static defaultProps = {
    headerClassName: undefined,
  };

  render() {
    const {
      headerClassName,
      reviewProcess: {
        provider: { suffix, firstName, lastName },
      },
    } = this.props;
    const providerDisplayName = displayProvider(firstName, lastName, suffix);

    return (
      <div className="doctor-review-layout">
        <Header className={headerClassName}>
          <h2 className="header-title-main font-color-brand-main">
            <FormattedMessage {...messages.tellUsMore} values={{ providerDisplayName }} />
          </h2>
        </Header>
        <div className="doctor-review-content">
          <div className="row">
            <div className="col-sm-6 col-sm-offset-3 col-lg-4 col-lg-offset-4">
              {this.props.children}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default reviewCompose(DoctorReviewLayout);
