import _ from 'lodash';
import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import { autobind } from 'core-decorators';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router';

import ClickableDiv from '../../../../components/clickableElement/clickableDiv';
import UserMenu from './userMenu/userMenu';
import HelpMenu from './helpMenu/helpMenu';
import MobileMenu from '../mobileMenu/mobileMenu';
import ServicesMenu from './servicesMenu/servicesMenu';
import LanguageMenu from '../../../../components/molecules/LanguageMenu/LanguageMenu';
import MyHealthMenu from './myHealthMenu/myHealthMenu';
import DoctorsMenu from './doctorsMenu/doctorsMenu';
import Header from '../../../../components/ui/header/header';
import CoOpLogo from '../../../../components/branding/co_opLogo';
import CleanSelect from '../../../../components/form/cleanSelect';
import SearchModal from '../directory/searchDoctors/searchModal';
import BackButton from '../../../../components/bkmdModal/modalBackButton';
import FeatureFlagged from '../../../../components/features/featureFlagged';
import BlueFooter from '../../../../components/molecules/BlueFooter/BlueFooter';

import { mobileMenuOpen, closeSearchModal, openSearchModal } from '../../../../store/ui/actions';
import { isSearchModalOpenSelector, isDirectoryAllowed } from '../../../../store/ui/selectors';
import { openFindDoctors } from '../directory/routingActions';
import { getPathsFromContext } from '../routes/injectPathsToContext';
import { member } from '../../../../store/member/selectors';
import { getFeature } from '../../../../store/feature/selectors';
import { isAuthenticated } from '../../../../store/auth/selectors';
import { shouldShowPCPModal } from '../medicalTeam/medicalTeam';
import InformationalBanner from '../informational-banner';

const messages = defineMessages({
  doctors: {
    defaultMessage: 'Doctors',
    id: 'members.layout.doctors',
  },
  searchPlaceholder: {
    defaultMessage: 'Specialty, condition, procedure or name',
    id: 'members.layout.searchPlaceholder',
  },
  createAccount: {
    defaultMessage: 'Create account',
    id: 'members.layout.createAccount',
  },
});

@autobind
class Layout extends React.PureComponent {
  static propTypes = {
    memberState: PropTypes.string.isRequired,
    openSearchModal: PropTypes.func.isRequired,
    openFindDoctors: PropTypes.func.isRequired,
    closeSearchModal: PropTypes.func.isRequired,
    mobileMenuOpen: PropTypes.func.isRequired,
    isSearchModalOpen: PropTypes.bool.isRequired,
    servicesMenuCitiesWhiteList: PropTypes.array.isRequired,
    router: PropTypes.object.isRequired,
    paths: PropTypes.object.isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
    pcpSelection: PropTypes.bool.isRequired,
    logoClassName: PropTypes.string,
    previousPageName: PropTypes.string,
    previousPageRoute: PropTypes.string,
    hideLogo: PropTypes.bool,
    externalPageLogo: PropTypes.bool,
    centeredBackBtn: PropTypes.bool,
    hideSearchLink: PropTypes.bool,
    active: PropTypes.string,
    backBtn: PropTypes.bool,
    searchPreview: PropTypes.shape({
      showOnBar: PropTypes.bool,
      searchTerm: PropTypes.string,
    }),
    footerClassName: PropTypes.string,
    allowUserMenu: PropTypes.bool,
    allowDirectory: PropTypes.bool,
    allowMobileMenu: PropTypes.bool,
    showRightNavInMobile: PropTypes.bool,
    isLogoClickable: PropTypes.bool,
  };

  static defaultProps = {
    hideLogo: false,
    hideSearchLink: false,
    searchPreview: null,
    previousPageName: null,
    centeredBackBtn: false,
    externalPageLogo: false,
    backBtn: false,
    logoClassName: 'logos',
    previousPageRoute: undefined,
    active: '',
    footerClassName: 'white',
    allowUserMenu: true,
    allowDirectory: true,
    allowMobileMenu: true,
    showRightNavInMobile: false,
    isLogoClickable: undefined,
  };

  onBack() {
    const { previousPageRoute, router } = this.props;
    if (previousPageRoute) {
      router.push(previousPageRoute);
    } else {
      router.goBack();
    }
  }

  openFindDoctors() {
    this.props.openFindDoctors(this.props.paths);
  }

  showServicesMenu() {
    const { memberState, servicesMenuCitiesWhiteList } = this.props;
    // In case it's not defined, we should not filter
    if (_.isEmpty(servicesMenuCitiesWhiteList)) return true;
    const match = _.intersection(_.words(_.toLower(memberState)), servicesMenuCitiesWhiteList);
    return !_.isEmpty(match);
  }

  renderSearchInput() {
    const { searchPreview, openSearchModal } = this.props;
    if (!searchPreview || searchPreview.showOnBar === false) return null;
    const label = _.get(searchPreview, 'searchTerm', '') || '';
    const optionPreview = { label };

    return (
      <ClickableDiv className="header-search-input visible-xs" clickHandler={openSearchModal}>
        <CleanSelect
          className="big-search-input multiple-inputs left-search-icon no-right-border hide-arrow"
          placeholder={messages.searchPlaceholder}
          clearable
          disabled
          value={optionPreview}
        />
        <i className="search-inner-icon icon-search-2" />
      </ClickableDiv>
    );
  }

  renderLogo() {
    const {
      hideLogo,
      logoClassName,
      externalPageLogo,
      allowDirectory,
      isLogoClickable,
    } = this.props;
    if (hideLogo) return null;

    const Logo = <CoOpLogo externalPageLogo={externalPageLogo} />;
    const renderWithClickableLink = _.isNil(isLogoClickable) ? allowDirectory : isLogoClickable;
    return renderWithClickableLink ? (
      <ClickableDiv clickHandler={this.openFindDoctors} className={logoClassName}>
        {Logo}
      </ClickableDiv>
    ) : (
      <div className={logoClassName}>{Logo}</div>
    );
  }

  renderMobileMenu() {
    const { allowDirectory, allowMobileMenu } = this.props;

    return allowDirectory && allowMobileMenu ? (
      <span>
        <button
          className="mobile-menu-trigger visible-xs"
          onClick={() => this.props.mobileMenuOpen(true)}
          aria-label="Open menu"
        >
          <i className="icon-menu-bars" />
        </button>
        <MobileMenu />
      </span>
    ) : null;
  }

  renderRightNav() {
    const {
      active,
      isAuthenticated,
      pcpSelection,
      allowDirectory,
      allowUserMenu,
      showRightNavInMobile,
    } = this.props;

    let doctorsElement = null;
    if (isAuthenticated) {
      if (pcpSelection) {
        doctorsElement = <DoctorsMenu id="doctorsMenu" />;
      } else {
        doctorsElement = (
          <button
            onClick={this.openFindDoctors}
            className="btn btn-inline-link text-semibold"
            tabIndex="0"
          >
            <FormattedMessage {...messages.doctors} />
          </button>
        );
      }
    }

    const rightNavClassName = showRightNavInMobile
      ? 'header-right-nav show-xs'
      : 'header-right-nav';

    return (
      <nav>
        <ul className={rightNavClassName}>
          <FeatureFlagged
            renderLi
            uri="layout.doctorsLink"
            className={classnames({ active: active === 'HOME' })}
          >
            {doctorsElement}
          </FeatureFlagged>
          <FeatureFlagged
            renderLi
            uri="layout.myHealthMenu.menuItem"
            className={classnames({ active: active === 'MEDICAL' })}
          >
            {isAuthenticated ? <MyHealthMenu id="myHealthMenu" /> : null}
          </FeatureFlagged>
          <FeatureFlagged
            renderLi
            uri="layout.servicesMenu"
            className={classnames({ active: active === 'MEDICAL' })}
          >
            {this.showServicesMenu() && isAuthenticated && <ServicesMenu id="servicesMenu" />}
          </FeatureFlagged>
          <FeatureFlagged renderLi uri="layout.helpMenu">
            {isAuthenticated ? <HelpMenu id="helpMenu" /> : null}
          </FeatureFlagged>
          <FeatureFlagged renderLi uri="layout.changeLanguage">
            <LanguageMenu id="languageMenu" />
          </FeatureFlagged>
          {allowDirectory && allowUserMenu && (
            <li className="hidden-xs">
              <UserMenu />
            </li>
          )}
        </ul>
      </nav>
    );
  }

  render() {
    const {
      centeredBackBtn,
      backBtn,
      previousPageName,
      children,
      isSearchModalOpen,
      closeSearchModal,
      footerClassName,
      allowDirectory,
      allowUserMenu,
    } = this.props;
    return (
      <InformationalBanner>
        <div>
          <Header className="members-main-layout-header">
            {this.renderLogo()}
            {this.renderSearchInput()}
            {centeredBackBtn || backBtn ? (
              <BackButton
                onBack={this.onBack}
                centered={centeredBackBtn}
                previousPageName={previousPageName}
              />
            ) : null}
            {this.renderMobileMenu()}
            <FeatureFlagged uri="layout.createAccount">
              <button className="create-account-btn pull-right btn btn-inline-link text-semibold">
                <FormattedMessage {...messages.createAccount} />
              </button>
            </FeatureFlagged>
            {allowDirectory && allowUserMenu && <UserMenu className="visible-xs" />}
            {this.renderRightNav()}
          </Header>

          <main className="content has-footer">{children}</main>

          <BlueFooter footerClassName={footerClassName} />

          <SearchModal isOpen={isSearchModalOpen} handleClose={closeSearchModal} />
        </div>
      </InformationalBanner>
    );
  }
}

export default compose(
  withRouter,
  injectIntl,
  getPathsFromContext(),
  connect(
    (state) => {
      const shouldShow = shouldShowPCPModal(state);
      return {
        isAuthenticated: isAuthenticated(state),
        servicesMenuCitiesWhiteList: getFeature(state, 'layout.servicesMenuCitiesWhiteList'),
        pcpSelection: getFeature(state, 'pcpSelection') && shouldShow,
        isSearchModalOpen: isSearchModalOpenSelector(state),
        memberState: member(state).state,
        allowUserMenu: getFeature(state, 'layout.userMenu'),
        allowDirectory: isDirectoryAllowed(state),
        allowMobileMenu: getFeature(state, 'layout.mobileMenu'),
      };
    },
    { openSearchModal, closeSearchModal, mobileMenuOpen, openFindDoctors },
  ),
)(Layout);
