/**
 * Created by anna on 25/05/2017.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import { defineMessages, FormattedMessage } from 'react-intl';
import CleanTextArea from '../../../components/form/cleanTextArea';
import { MessageShape } from '../intlString/';

const messages = defineMessages({
  charactersCount: {
    defaultMessage: '{ charNum } of { maxLength } characters',
    id: 'members.callReview.freeTextComponent.charactersCount'
  },
  tellUsMore: {
    defaultMessage: 'Tell us more',
    id: 'members.callReview.freeTextComponent.tellUsMore'
  },
});

const defaultMaxLength = '250';

@autobind
class FreeTextCharCountComponent extends React.PureComponent {

  static defaultProps = {
    maxLength: defaultMaxLength,
    label: messages.tellUsMore,
  };

  static propTypes = {
    maxLength: PropTypes.string,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    label: MessageShape,
  };

  constructor(props) {
    super(props);

    this.state = {
      charNum: '0',
    };
  }

  onReasonFreeTextKeyUp(event) {
    let charNum = event.target.value.length;
    if (!charNum) {
      charNum = '0';
    }
    this.setState({ charNum });
  }

  render() {
    const { onChange, value, maxLength, label } = this.props;
    const charNum = this.state.charNum;
    return (
      <div>
        <div className="row">
          <div className="col-xs-12">
            <CleanTextArea
              label={label}
              value={value}
              onChange={onChange}
              onKeyUp={this.onReasonFreeTextKeyUp}
              inputClassName="no-margin"
              maxLength={maxLength}
            />
          </div>
        </div>
        <div className="row margin-top-5">
          <div className="col-xs-12 text-14">
            <div className="characters-count">
              <FormattedMessage
                {...messages.charactersCount}
                values={{ charNum, maxLength }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FreeTextCharCountComponent;
