/**
 * Created by guyavarham on 25/07/2017.
 */
import _ from 'lodash';

export default function getBrowserLocation(reduxState) {
  return (_.get(reduxState, 'location.longitude') && _.get(reduxState, 'location.latitude')) ?
    reduxState.location : null;
}

export function isBrowserLocationAllowed(reduxState) {
  return _.get(reduxState, 'location.error', true) !== true;
}

export function isLocationExecuted(state) {
  return _.get(state, 'location.getLocationExecuted', false);
}
export function isMemberSettingsExecuted(state) {
  return _.get(state, 'location.getMemberSettingsExecuted', false);
}

export function isGetMemberExecuted(state) {
  return _.get(state, 'location.getMemberExecuted', false);
}

export function getLocationShort(state) {
  const locationState = state.location;
  if (!_.isNil(locationState.latitude) && !_.isNil(locationState.longitude)) {
    return { lat: locationState.latitude, lon: locationState.longitude };
  }

  return undefined;
}
