/**
 * Created by matan on 9/5/16.
 */
import Immutable from 'seamless-immutable';

import { GET_MEMBER_SETTINGS, GET_MEMBER } from '../member/actions';
import { GET_LOCATION } from './actions';

const initialState = {
  error: false,
  latitude: null,
  longitude: null,
  timestamp: null,
  getLocationExecuted: false,
  getMemberSettingsExecuted: false,
  getMemberExecuted: false,
};

export default function locationReducer(state = initialState, action) {
  state = Immutable(state);

  switch (action.type) {
    case `${GET_LOCATION}_SUCCESS`: {
      const { timestamp, coords: { latitude, longitude } } = action.payload;
      return state.merge({
        error: false,
        latitude,
        longitude,
        timestamp: new Date(timestamp),
        getLocationExecuted: true,
      });
    }
    case `${GET_LOCATION}_ERROR`: {
      return state.merge({
        error: true,
        latitude: null,
        longitude: null,
        timestamp: null,
        getLocationExecuted: true,
      });
    }
    case `${GET_MEMBER}_ERROR`:
    case `${GET_MEMBER}_SUCCESS`: {
      return state.merge({
        getMemberExecuted: true,
      });
    }
    case `${GET_MEMBER_SETTINGS}_ERROR`:
    case `${GET_MEMBER_SETTINGS}_SUCCESS`: {
      return state.merge({
        getMemberSettingsExecuted: true,
      });
    }
  }

  return state;
}
