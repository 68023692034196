/**
 * Created by moran on 6/5/17.
 */
/* eslint-disable react/no-unused-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { autobind } from 'core-decorators';
import OpeningHoursSelectors from './openingHoursSelector';
import IntlString from '../ui/intlString/intlString';
import messageShape from '../ui/intlString/messageShape';
import * as WeekDays from '../../model/enum/weekDays';

@autobind
class WeekdaysOpeningHours extends React.Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    days: PropTypes.array,
    label: messageShape.isRequired,
    value: PropTypes.object.isRequired,
  };

  static defaultProps = {
    days: _.values(WeekDays)
  };

  onChange({ day, open, close, isOpen }) {
    const openHours = _.extend({}, this.props.value, {
      [day]: {
        open,
        close,
        isOpen
      }
    });
    this.props.onChange(openHours);
  }

  renderDays() {
    return _.map(this.props.days, day => (
      <OpeningHoursSelectors
        onChangeDefault
        key={day}
        day={day}
        onChange={this.onChange}
        open={_.get(this, `props.value[${day}].open`)}
        close={_.get(this, `props.value[${day}].close`)}
        isOpen={_.get(this, `props.value[${day}].isOpen`)}
        label={day}
      />));
  }

  render() {
    return (
      <div className="hours-selector-wrap">
        <div className="weekday-hours">
          <div className="row">
            <div className="col-xs-12">
              <h4 className="text-14 text-medium">
                <IntlString message={this.props.label} />
              </h4>
            </div>
          </div>
          <div className="row days-row two-lines-btn margin-top">
            {this.renderDays()}
          </div>
        </div>
      </div>
    );
  }
}
export default WeekdaysOpeningHours;
/* eslint-enable react/no-unused-prop-types */
