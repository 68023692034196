import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  compose,
  branch,
  withHandlers,
  defaultProps,
  lifecycle,
  withPropsOnChange,
  setPropTypes,
  getContext,
  pure,
} from 'recompose';
import { connect } from 'react-redux';
import _ from 'lodash';
import { autobind } from 'core-decorators';
import createUuid from 'uuid';

import { initFlow, resetFlow } from './actionCreators';
import {
  createStorageKey,
  getFlowStateFromStorage,
  updateFlowStateInStorage,
  clearFlowStateFromStorage,
} from './storage';
import withControl from './withControl';
import * as flowTraverse from './flowTraverse';
import * as AnalyticConsts from './analyticConsts';
import { FlowStepMetaShape } from './shapes';
import { NEXT, PREV } from '../../../components/wizard/flow/consts';
import FlaggedRender from '../../../utils/FlaggedRender';
import {
  fetchFromQuery,
  withQueryString,
  withHistoryActions,
} from '../../../utils/queryString/historyConnector';
import './Flow.less';

const loadFromStorage = (name, flowId) => {
  const key = createStorageKey(name, flowId);
  return getFlowStateFromStorage(key);
};

const writeToStorage = (name, flowId, data) => {
  const key = createStorageKey(name, flowId);
  updateFlowStateInStorage(key, data);
};

const clearFromStorage = (name, flowId) => {
  const key = createStorageKey(name, flowId);
  clearFlowStateFromStorage(key);
};

const getInitialFlowState = function getInitialFlowState(
  persist,
  name,
  flowId,
  initialStepName,
  initialValues,
  flowProps,
) {
  const history = [];
  let res;
  if (persist) {
    res = loadFromStorage(name, flowId);
  }
  return (
    res || {
      stepName: initialStepName,
      history,
      data: initialValues,
      flowProps,
    }
  );
};

/**
 * Component which managers flow between multiple steps
 * The FlowManager saves the steps results and current step on the query string, and will know to
 * navigate between steps including browser back button cases
 *
 * The FlowManager will send a special prop to every child (step) called 'flow' which contains
 * methods and props to control the flow (next, back, finish, flowState, etc.) aka flow control obj
 *
 * To define steps:
 * 1. Add the step component as a child + "stepName" prop
 * 2. Add the step to the stepsMeta prop of the FlowManager component
 */
@autobind
class Flow extends React.PureComponent {
  static propTypes = {
    /**
     * Hook function when flow starts
     */
    onStart: PropTypes.func,
    /**
     * Hook function when flow finishes
     */
    onFinish: PropTypes.func.isRequired,
    /**
     * name of the current step
     */
    stepName: PropTypes.string,
    /**
     * initizlize a flow instance.
     */
    initFlow: PropTypes.func.isRequired,
    /**
     * reset  flow instance.
     */
    resetFlow: PropTypes.func.isRequired,

    /**
     * reset  flow instance.
     */
    StepComponent: PropTypes.func,
    /**
     * current step's props
     */
    stepProps: PropTypes.object,
    /**
     * should the back button be included in the flow
     */
    shouldRenderBackButton: PropTypes.bool,
    /**
     * back button handler
     */
    onBack: PropTypes.func,

    hidden: PropTypes.object,

    className: PropTypes.string,
  };

  static defaultProps = {
    onStart: _.noop,
    onStepChange: _.noop,
    onBack: undefined,
    renderStep: undefined,
    flowState: undefined,
    stepName: undefined,
    StepComponent: undefined,
    stepProps: {},
    shouldRenderBackButton: false,
    hidden: undefined,
    className: '',
  };

  // TODO: change to didMount
  componentWillMount() {
    this.props.initFlow();
    this.props.onStart();
  }

  componentWillUnmount() {
    this.props.resetFlow();
    this.props.onFinish();
  }

  render() {
    const {
      StepComponent,
      stepName,
      stepProps,
      onBack,
      shouldRenderBackButton,
      hidden,
      className,
    } = this.props;
    if (!StepComponent) return null;

    return (
      <div className={classNames('flow-component', className)} style={hidden}>
        <FlaggedRender shouldRender={shouldRenderBackButton && onBack}>
          <button
            aria-label="Back"
            className="btn btn-modal-header-back pull-left"
            onClick={onBack}
          >
            <i className="icon-back-arrow" />
          </button>
        </FlaggedRender>
        <StepComponent key={stepName} {...stepProps} />
      </div>
    );
  }
}

const syncFlowId = lifecycle({
  componentWillMount() {
    const { flowId: flowIdProp, getFlowId, setFlowId } = this.props;
    let flowId = flowIdProp || getFlowId();
    if (!flowId) {
      flowId = createUuid();
      setFlowId(flowId);
    }

    this.setState({ flowId });
  },
});

const withExperimentalQSBehavior = compose(
  fetchFromQuery(({ history }) => ({
    queryHistory: history ? _.castArray(history) : [],
  })),
  withControl(({ name }) => name),
  withPropsOnChange(['queryHistory'], ({ queryHistory, control }) => {
    const { flowState, progress } = control;
    if (!flowState) return;

    const { history } = flowState;
    if (queryHistory && history && _.last(queryHistory) !== _.last(history)) {
      if (queryHistory.length < history.length) {
        progress({ type: PREV, meta: { popHistory: true } });
      } else {
        progress({ type: NEXT });
      }
    }
  }),
);

const enhance = compose(
  setPropTypes({
    /**
     * The name of the flow, used as key.
     */
    name: PropTypes.string.isRequired,
    initialStepName: PropTypes.string.isRequired,
    definitions: PropTypes.objectOf(FlowStepMetaShape.isRequired).isRequired,
    onFinish: PropTypes.func,
    onStart: PropTypes.func,
    flowProps: PropTypes.object,
    initialValues: PropTypes.object,
    persist: PropTypes.bool,
    flowId: PropTypes.string,
    experimentalQSSupport: PropTypes.bool,

    /**
     * Query string synchronization methods, will be deprecated very soon
     */
    setFlowId: PropTypes.func,
    getFlowId: PropTypes.func,
    clearFlowId: PropTypes.func,
    onFlowDataUpdate: PropTypes.func,
    mapFlowDataToAnalytics: PropTypes.func,
  }),
  defaultProps({
    onFinish: _.noop,
    onStart: _.noop,
    flowProps: {},
    initialValues: {},
    persist: false,
    flowId: undefined,
    experimentalQSSupport: false,

    setFlowId: _.noop,
    getFlowId: _.noop,
    clearFlowId: _.noop,
    onFlowDataUpdate: _.noop,
    mapFlowDataToAnalytics: _.noop,
  }),
  withHistoryActions,
  withHandlers({
    getFlowId: ({ flowId }) => () => flowId,
    setFlowId: ({ replace }) => flowId => replace({ flowId }),
    clearFlowId: ({ replace }) => () => replace({ flowId: null }),
  }),
  getContext({ analytics: PropTypes.object }),
  syncFlowId,
  connect(
    (state, props) => {
      const flowState = _.get(state, `flows[${props.name}].state`);
      return { flowState, stepName: _.get(flowState, 'stepName') };
    },
    { initFlow, resetFlow },
  ),
  withPropsOnChange(['flowState'], ({ name, flowId, flowState, persist, onFlowDataUpdate }) => {
    if (persist && !_.isEmpty(flowState)) {
      writeToStorage(name, flowId, flowState);
    }

    onFlowDataUpdate({ name, flowId, flowState });
  }),
  withPropsOnChange(['stepName'], ({ name, stepName, definitions, flowState, flowProps }) => {
    if (!stepName) return null;

    const StepComponent = withControl(name)(definitions[stepName].component);

    const stepProps = flowTraverse.mapFlowDataToProps(
      definitions,
      stepName,
      flowState.data,
      flowProps,
    );

    return { StepComponent, stepProps };
  }),
  withHandlers({
    initFlow: ({
      initFlow,
      name,
      definitions,
      initialStepName,
      initialValues,
      flowId,
      persist,
      analytics,
      experimentalQSSupport,
      flowProps,
      mapFlowDataToAnalytics,
      push,
    }) => () => {
      const flowState = getInitialFlowState(
        persist,
        name,
        flowId,
        initialStepName,
        initialValues,
        flowProps,
      );

      const newFlowState = flowTraverse.reduceInitialStep(definitions, flowState, flowProps);

      analytics.track(`${AnalyticConsts.SCOPE}_${AnalyticConsts.INIT}`, {
        name,
        persist,
        flowId,
        stepName: newFlowState.stepName,
        ...mapFlowDataToAnalytics(newFlowState.data),
      });

      if (experimentalQSSupport) {
        push({ history: newFlowState.history });
      }

      initFlow(
        name,
        definitions,
        initialStepName,
        flowId,
        newFlowState,
        experimentalQSSupport,
        mapFlowDataToAnalytics,
      );
    },
    resetFlow: ({ resetFlow, clearFlowId, name, flowId, persist }) => () => {
      resetFlow(name); // TODO: rename to clearFlow
      if (persist) {
        clearFromStorage(name, flowId);
        clearFlowId();
      }
    },
    onFinish: ({ onFinish, name, flowId, analytics }) => () => {
      analytics.track(`${AnalyticConsts.SCOPE}_${AnalyticConsts.FINISH}`, {
        name,
        flowId,
      });

      onFinish();
    },
  }),
  pure,
);

export default compose(
  withQueryString,
  fetchFromQuery(({ flowId }, props) => ({ flowId: props.flowId || flowId })),
  // Once experimentalQSSupport is not experimental anymore, we should merge the fetchFromQuery
  branch(({ experimentalQSSupport }) => experimentalQSSupport, withExperimentalQSBehavior),
  enhance,
)(Flow);
