/**
 * Created by moran on 7/31/17.
 */
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { autobind } from 'core-decorators';

import analytics from '../../store/tools/analytics/analyticsHoc';
import CleanInput from './cleanInput';

@autobind
class CleanJSONListDisplay extends React.Component {
  static propTypes = {
    value: PropTypes.array.isRequired,
  };

  render() {
    let list = '';
    _.map(this.props.value, val => {
      list = `${JSON.stringify(val)},${list}`;
    });

    return (<CleanInput {...this.props} value={list} />);
  }
}

export default analytics(CleanJSONListDisplay, false);
