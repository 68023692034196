/**
 * Created by anna on 10/10/2017.
 */

import _ from 'lodash';
import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Joi from 'joi-browser';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import { defineMessages, FormattedMessage } from 'react-intl';
import { createValidator } from '../../../../../../utils/joiValidator';
import { ReduxFormCleanInput } from '../../../../../../components/form/reduxForm/components';
import SpinnerButton from '../../../../../../components/ui/spinner/spinnerButton';
import actionTracker from '../../../../../../store/tools/actionTracker/actionTrackerComponent';
import { INVITE_FAMILY_MEMBER } from '../../../../../../store/familyActivation/actions';
import SvgAdultMaleAvatar from '../../../../../../assets/svg/avatars/adultMaleAvatar';
import SvgAdultFemaleAvatar from '../../../../../../assets/svg/avatars/adultFemaleAvatar';

const boyImage = <SvgAdultMaleAvatar />;
const girlImage = <SvgAdultFemaleAvatar />;

const messages = defineMessages({
  send: {
    defaultMessage: 'Send',
    id: 'inviteRest.send',
  },
  invite: {
    defaultMessage: 'Invite {name}',
    id: 'inviteRest.invite',
  },
  yourInviteWentTo: {
    defaultMessage: 'Your invite went to',
    id: 'inviteRest.yourInviteWentTo',
  },
  sendAgain: {
    defaultMessage: 'Invite again',
    id: 'inviteRest.sendAgain',
  },
});

export const fields = {
  contactInfo: {
    name: 'contactInfo',
    component: ReduxFormCleanInput,
  },
};

const renderNotSent = function renderNotSent(tracker, firstname) {
  return (
    <div>
      <div className="row margin-top-30">
        <div className="col-xs-12">
          <Field label={`${firstname}'s Email address`} {...fields.contactInfo} />
        </div>
      </div>
      <div>
        <SpinnerButton
          type="submit"
          className="btn btn-big bg-color-brand-button"
          aria-label={`submit ${firstname}'s Email address`}
          isLoading={_.get(tracker, 'inProgress', false)}
        >
          <FormattedMessage {...messages.send} />
        </SpinnerButton>
      </div>
    </div>
  );
};
class InviteMemberForm extends React.PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired, // from redux-form
    onFinish: PropTypes.func.isRequired,
    dependent: PropTypes.object.isRequired,
    inviteMemberTracker: PropTypes.object.isRequired,
    contactInfo: PropTypes.string.isRequired,
  };

  renderSent(dependent) {
    return (
      <div>
        <div className="invite-success">
          <i className="fa fa-check font-color-brand-success invite-success-icon" />
          <div className="text-16 text-medium">
            <FormattedMessage {...messages.yourInviteWentTo} />
          </div>
          <div className="text-16 text-semibold">{this.props.contactInfo}</div>
        </div>
        <div>
          <button
            className="btn btn-big bg-color-brand-button"
            onClick={() => this.props.onFinish(dependent.id)}
          >
            <FormattedMessage {...messages.sendAgain} />
          </button>
        </div>
      </div>
    );
  }

  render() {
    const { dependent, inviteMemberTracker, handleSubmit } = this.props;
    const tracker = _.isEmpty(inviteMemberTracker)
      ? inviteMemberTracker
      : _.find(_.castArray(inviteMemberTracker), [
          'key',
          `${INVITE_FAMILY_MEMBER}-${dependent.id}`,
        ]);

    return (
      <div className="card invite-card">
        <div className="card-body">
          <form onSubmit={handleSubmit} method="post">
            <div className="text-center">{dependent.gender === 'MALE' ? boyImage : girlImage}</div>
            <div className="invite-item-name text-18 text-semibold text-dark text-center">
              <FormattedMessage {...messages.invite} values={{ name: dependent.firstName }} />
            </div>
            {_.isEmpty(tracker) || tracker.inProgress
              ? renderNotSent(tracker, dependent.firstName)
              : this.renderSent(dependent)}
          </form>
        </div>
      </div>
    );
  }
}

const schema = {
  contactInfo: Joi.string()
    .email()
    .required()
    .options({
      language: {
        string: { email: '!!Oopsy, please enter a valid email' },
        any: { required: '!!Please enter a valid email' },
      },
    }),
};

export default compose(
  reduxForm({
    validate: createValidator(Joi.object().keys(schema)),
  }),
  connect((state, props) => ({
    contactInfo: formValueSelector(props.form)(state, fields.contactInfo.name) || '',
  })),
  actionTracker({
    inviteMemberTracker: new RegExp(`${INVITE_FAMILY_MEMBER}-.*`),
  }),
)(InviteMemberForm);
