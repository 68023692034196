import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { defineMessages, FormattedMessage } from 'react-intl';

import vimChoiceLogo from 'Assets/images/logos/vimChoiceLogo.png';
import highValueLogo from 'Assets/images/logos/highValueLogo.png';
import CareTeamBadge from 'Assets/svg/careTeam';
import { getFeature } from 'Store/feature/selectors';

import './ProviderLogo.less';
import RoundedImage from '../../atoms/RoundedImage/RoundedImage';

const messages = defineMessages({
  careTeam: {
    defaultMessage: 'Care Team',
    id: 'scheduling.booking.alternativeProviders.careTeamBadge',
  },
});

const ProviderShape = PropTypes.shape({
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  npi: PropTypes.string,
  profilePicture: PropTypes.string,
});

export class PureProviderLogo extends React.PureComponent {
  static propTypes = {
    provider: ProviderShape.isRequired,
    /**
     * The css class name based on doctor img css class.
     */
    containerClass: PropTypes.string,
    /**
     * An alternative custom css class instead of @containerClass.
     */
    className: PropTypes.string,
    hidePicture: PropTypes.bool,

    isVimChoiceEnable: PropTypes.bool.isRequired,
    isHighValueBadgeEnable: PropTypes.bool,

    showOfficeName: PropTypes.bool,
  };

  static defaultProps = {
    className: undefined,
    containerClass: 'big',
    hidePicture: false,
    showOfficeName: false,
    isHighValueBadgeEnable: false,
  };

  renderVimChoice() {
    const { provider, isVimChoiceEnable } = this.props;

    if (isVimChoiceEnable && provider.vimChoice) {
      return <img src={vimChoiceLogo} className="vim-heart" alt="vim choice physician" />;
    }
    return null;
  }

  renderHighValue() {
    const { provider, isHighValueBadgeEnable } = this.props;
    if (isHighValueBadgeEnable && (!_.isEmpty(provider.hvns) || provider.isHighValue)) {
      return <img src={highValueLogo} className="high-value-logo" alt="high value physician" />;
    }
    return null;
  }

  renderCareTeam() {
    const { provider } = this.props;
    if (provider.careTeam) {
      return (
        <div className="care-team" alt="care team provider">
          <CareTeamBadge />
          <span className="text-14 text-semibold text-dark">
            <FormattedMessage {...messages.careTeam} />
          </span>
        </div>
      );
    }
    return null;
  }

  render() {
    const {
      provider,
      containerClass,
      hidePicture,
      className,
      isVimChoiceEnable,
      showOfficeName,
    } = this.props;
    const { firstName, lastName, profilePicture, officeName } = provider;
    if ((!firstName || !lastName) && !showOfficeName) return null;
    const initials = showOfficeName
      ? `${_.first(officeName)}`
      : `${_.first(firstName)}${_.first(lastName)}`;

    if (!profilePicture || hidePicture) {
      return (
        <span className="pic-wrap">
          <div
            className={classnames('doctor-img ', containerClass, {
              'vim-choice': isVimChoiceEnable && provider.vimChoice,
            })}
          >
            <span className="content" aria-hidden="true">
              {initials}
            </span>
          </div>
          {this.renderVimChoice()}
          {this.renderHighValue()}
        </span>
      );
    }

    return (
      <span className="pic-wrap">
        <RoundedImage
          className={classnames(className || 'doctor-img', containerClass, {
            'vim-choice': isVimChoiceEnable && provider.vimChoice,
          })}
          src={profilePicture}
        />
        {this.renderVimChoice()}
        {this.renderHighValue()}
        {this.renderCareTeam()}
      </span>
    );
  }
}

const ProviderLogo = compose(
  connect(state => ({
    // vim choice is a default feature - we only disabled it when required
    isVimChoiceEnable: getFeature(state, 'results.vimChoice', true),
  })),
)(PureProviderLogo);

export default ProviderLogo;
