/**
 * Created by meirshalev 04/01/2018.
 */
import React from 'react';
import _ from 'lodash';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { defineMessages, FormattedMessage } from 'react-intl';
import { autobind } from 'core-decorators';

import TermsOfServices from '../termsOfServices/termsOfServices';
import SpinnerButton from '../ui/spinner/spinnerButton';
import actionTracker from '../../store/tools/actionTracker/actionTrackerComponent';
import { ACTIVATE } from '../../store/activation/actions';
import { IntlString, MessageShape } from '../ui/intlString/index';
import FeatureFlagged from '../features/featureFlagged';

const messages = defineMessages({
  letsGetYouSetUp: {
    defaultMessage: "Let's get you set up",
    id: 'activation.mobileNum.letsGetYouSetUp',
  },
  activateMyAccount: {
    defaultMessage: 'Activate my account',
    id: 'activation.mobileNum.activateMyAccount',
  },
  youAgree: {
    defaultMessage: "you agree to Vim's",
    id: 'activation.mobileNum.youAgree',
  },
  byClicking: {
    defaultMessage: 'By clicking',
    id: 'activation.mobileNum.byClicking',
  },

  termsOfService: {
    defaultMessage: 'Terms of Service',
    id: 'activation.mobileNum.termsOfService',
  },
  and: {
    defaultMessage: 'and',
    id: 'activation.mobileNum.and',
  },
  privacyPolicy: {
    defaultMessage: 'Privacy Policy',
    id: 'activation.mobileNum.privacyPolicy',
  },
  or: {
    defaultMessage: 'or',
    id: 'members.referrals.or',
  },
  signIn: {
    defaultMessage: 'Sign in',
    id: 'members.referrals.signIn',
  },
});

@autobind
class ActivationButton extends React.Component {
  static propTypes = {
    activateTracking: PropTypes.object.isRequired,
    onSigninClicked: PropTypes.func,
    onActivateClicked: PropTypes.func.isRequired,
    className: PropTypes.string,
    showSignIn: PropTypes.bool,
    mainButtonText: MessageShape,
    activateButtonText: MessageShape,
  };

  static defaultProps = {
    className: undefined,
    mainButtonText: messages.activateMyAccount,
    showSignIn: false,
    onSigninClicked: _.noop,
    activateButtonText: messages.activateMyAccount,
  };

  render() {
    const {
      activateTracking,
      mainButtonText,
      activateButtonText,
      onActivateClicked,
      showSignIn,
    } = this.props;
    return (
      <div className={this.props.className}>
        <div className="row margin-top-30">
          <div className="col-xs-12">
            <SpinnerButton
              isLoading={activateTracking.inProgress}
              className="btn btn-big bg-color-brand-button two-lines-btn"
              type="button"
              onClick={onActivateClicked}
            >
              <IntlString message={activateButtonText || mainButtonText} />
            </SpinnerButton>
          </div>
        </div>
        {!showSignIn && (
          <div>
            <div className="row no-margin">
              <div className="col-xs-12 text-center text-16 or-hr">
                <span className="or-text">
                  <FormattedMessage {...messages.or} />
                </span>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12 text-center">
                <button className="btn btn-big  btn-outline" onClick={this.props.onSigninClicked}>
                  <FormattedMessage {...messages.signIn} />
                </button>
              </div>
            </div>
          </div>
        )}

        <FeatureFlagged uri="layout.showTermsOfService">
          <div className="row margin-top-30">
            <TermsOfServices
              isMember
              activateText={activateButtonText || mainButtonText}
            />
          </div>
        </FeatureFlagged>
      </div>
    );
  }
}

export default compose(
  actionTracker({
    activateTracking: ACTIVATE.SOURCE,
  }),
)(ActivationButton);
