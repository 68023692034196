/**
 * Created by galgoltzman on 08/08/2017.
 */
import _ from 'lodash';
import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import { FormattedMessage } from 'react-intl';

import Layout from '../layout/layout';
import Header from '../../../../components/ui/header/header';
import Folder from '../../../../assets/svg/folder';
import ThreeBounceSpinner from '../../../../components/ui/spinner/threeBounceSpinner';
import MedicationCard from './medicationCard';
import InformationByPremera from '../help/informationByPremera';

import fetch from '../../../../store/tools/fetch';
import actionTracker from '../../../../store/tools/actionTracker/actionTrackerComponent';
import * as selectors from '../../../../store/member/selectors';
import { GET_MEDICATIONS_SUMMARY, getMedicationsSummary } from '../../../../store/member/actions';
import messages from './messages';

import './medications.less';

@autobind
class Medications extends React.PureComponent {
  static propTypes = {
    medicationsAction: PropTypes.object.isRequired,
    medications: PropTypes.array,
  };

  static defaultProps = {
    medications: null,
  };

  renderContent() {
    const { medications, medicationsAction } = this.props;
    if (medicationsAction.inProgress) {
      return <ThreeBounceSpinner />;
    }

    if (_.isEmpty(medications)) {
      return (
        <div className="text-center margin-top-30 padding-v-30">
          <Folder />
          <br />
          <div className="text-18">
            <FormattedMessage {...messages.nothingToSee} />
          </div>
        </div>
      );
    }
    return _.map(medications, medication => (
      <MedicationCard medication={medication} key={medication.drugName} />
    ));
  }

  render() {
    return (
      <Layout>
        <Header className="medications-list-header">
          <div className="pull-left text-18 font-color-brand-main title-brand-3">
            <FormattedMessage {...messages.yourMedications} />
          </div>
        </Header>
        <div>
          <div className="container container-1040">
            <div className="row no-margin">
              <div className="col-sm-10 col-sm-offset-1">
                <div className="cards-list medications-list">
                  <div className="recent-refills text-16 text-semibold text-dark padding-v-20">
                    <FormattedMessage {...messages.recentRefillsMayNotBeListedHere} />
                  </div>
                  {this.renderContent()}
                  <InformationByPremera />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="content-grey-bg" />
      </Layout>
    );
  }
}

export default compose(
  fetch(getMedicationsSummary),
  actionTracker({
    medicationsAction: GET_MEDICATIONS_SUMMARY,
  }),
  connect(state => ({
    medications: selectors.medicationsSummaryByDate(state),
  })),
)(Medications);
