/**
 * created by moran on 11/26/17
 */
import PromiseActionType from '../promiseActionType';

export const GET_VIM_GUIDE_BY_USER_ID = new PromiseActionType('GET_VIM_GUIDE_BY_USER_ID');

export function getVimGuideByUserId(userId) {
  return ({ bkmdApi: { vimGuideProfileApi } }) => ({
    type: GET_VIM_GUIDE_BY_USER_ID.SOURCE,
    payload: {
      promise: vimGuideProfileApi.getByUserIdPublic(userId)
    },
    meta: {
      tracker: GET_VIM_GUIDE_BY_USER_ID.SOURCE,
    },
  });
}
