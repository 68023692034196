import { autobind } from 'core-decorators';
import BkmdApi from './bkmdApi';
import ServicesApi from './services';

const SERVICE_NAME = 'appointmentsBackoffice';

@autobind
class ProviderAppointmentsApi extends BkmdApi {
  /**
   * A fetch object that points to the server
   * @param fetch
   */
  constructor(fetch) {
    super(fetch, '/appointmentsBackoffice/');
    this.servicesApi = new ServicesApi(fetch);
  }

  getAllAppointmentsForCalendar(skip, limit, search, sort, sortAscending, domain) {
    return this.servicesApi.callSecure(
      SERVICE_NAME,
      'getAllAppointmentsForCalendar',
      {
        limit,
        skip,
        search,
        sort,
        sortAscending,
        domain,
      },
      domain,
    );
  }

  getAppointmentById(id, domain) {
    return this.servicesApi.callSecure(SERVICE_NAME, 'getAppointmentById', { id }, domain);
  }

  updateAppointment(data, domain) {
    return this.servicesApi.callSecure(SERVICE_NAME, 'updateAppointment', { data }, domain);
  }

  cancelAppointment(data, domain) {
    return this.servicesApi.callSecure(SERVICE_NAME, 'cancelAppointment', { data }, domain);
  }

  deleteAppointment(id) {
    return this.servicesApi.callSecure(SERVICE_NAME, 'deleteAppointment', { id });
  }

  createAppointment(data) {
    return this.servicesApi.callSecure(SERVICE_NAME, 'createAppointment', { data });
  }
}

export default ProviderAppointmentsApi;
