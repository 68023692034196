import * as Errors from '../../../../api/errors';
import messages from '../../../../messages/errors/activation';

const mapping = {
  defaultMessage: {
    title: messages.serverErrorTitle,
    message: messages.serverErrorMessage,
  },
  nameNotFound: {
    title: messages.nameNotFoundTitle,
    message: messages.nameNotFoundMessage,
  },
  dobDoesNotMatch: {
    title: messages.dobDoesNotMatchTitle,
    message: messages.dobDoesNotMatchMessage,
  },
  [Errors.INVALID_CODE]: {
    title: ' ',
    message: messages.wrongCodeMessage,
  },
  [Errors.VERIFICATION_EXPIRED]: {
    title: messages.codeExpiredTitle,
    message: messages.codeExpiredMessage,
  },
  [Errors.WEAK_PASSWORD]: {
    title: messages.weakPasswordErrorTitle,
    message: messages.weakPasswordErrorMessage,
  },
  [Errors.TOO_YOUNG]: {
    title: messages.dobTooYoungTitle,
    message: messages.dobTooYoungMessage,
  },
  [Errors.INVALID_PHONE_NUMBER]: {
    title: ' ',
    message: messages.invalidPhoneNumberMessage,
  },
  [Errors.ACTIVATION_TOKEN_EXPIRED]: {
    title: messages.activationTokenExpiredTitle,
    message: messages.activationTokenExpiredMessage,
  },
  [Errors.VALIDATION_SESSION_EXPIRED]: {
    title: messages.validationTokenExpiredTitle,
    message: messages.validationTokenExpiredMessage,
  },
};

export default mapping;
