import { autobind } from 'core-decorators';
import BkmdApi from './bkmdApi';
import ServicesApi from './services';

const FILES_SERVICE_NAME = 'filesManager';
const SERVICE_NAME = 'metadata';

@autobind
export default class MetaApi extends BkmdApi {
  constructor(fetch) {
    super(fetch, '/meta/');
    this.serviceApi = new ServicesApi(fetch);
  }

  getTaxonomyList() {
    return this.get('taxonomiesList');
  }

  getFileMetadata(fileId) {
    return this.serviceApi.call(FILES_SERVICE_NAME, 'getFileMetadata', { fileId });
  }

  getAllFeatures() {
    return this.serviceApi.callSecure(SERVICE_NAME, 'getAllFeatures');
  }

  getAllFeatureFlags() {
    return this.serviceApi.call(SERVICE_NAME, 'getAllFeatureFlags');
  }

  updateFeatureFlag(domain, feature, value) {
    return this.serviceApi.callSecure(SERVICE_NAME, 'updateFeatureFlag', {
      domain,
      feature,
      value,
    });
  }
}
