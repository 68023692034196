/**
 * Created by chenrozenes on 29/11/2015.
 *
 * Class for handling common string formatting.
 * Use for formatting currency, time, date and phone number values.
 *
 * Formats can be used from "CommonFormats"
 */

import _ from 'lodash';
import moment from 'moment';
// import 'moment/locale/es';
import numeral from 'numeral';
import Phoneformat from 'phoneformat.js';
import { lookup } from 'country-data';
import { defineMessages } from 'react-intl';
import { parseFullName } from 'parse-full-name';
import parser from 'parse-address';

import BookMdUtil from '../util';
import { getLocaleLanguage } from '../intl/intlSelector';
import CommonFormats, { getLocaledFormats } from './commonFormats';

const messages = defineMessages({
  male: {
    defaultMessage: 'Male',
    id: 'store.formatter.male',
  },
  female: {
    defaultMessage: 'Female',
    id: 'store.formatter.female',
  },
});

export function dialCodeToCountryCode(dialCode) {
  // Add + if not exists
  dialCode = dialCode[0] !== '+' ? `+${dialCode}` : dialCode;
  if (dialCode === '+1') return 'US';
  const country = lookup.countries({ countryCallingCodes: dialCode });

  return country && country[0] && country[0].alpha2;
}

function _parseSuffix(rawSuffix) {
  const rawSuffixInUppercase = rawSuffix.toUpperCase();
  for (const { original, inUppercase } of suffixesThatShouldntBeUppercase) {
    if (rawSuffixInUppercase.match(inUppercase)) return original;
  }

  return rawSuffixInUppercase;
}

const suffixesThatShouldntBeUppercase = [
  { original: 'MeD', inUppercase: 'MED' },
  { original: 'PharmD', inUppercase: 'PHARMD' },
  { original: 'EdD', inUppercase: 'EDD' },
  { original: 'AuD', inUppercase: 'AUD' },
  { original: 'PsyD', inUppercase: 'PSYD' },
  { original: 'PhD', inUppercase: 'PHD' },
];

class Formatter {
  /**
   * Formats currency values
   *
   * @param value
   * @param format
   * @returns {*}
   */
  static currency(value, format) {
    if (isNaN(value)) return '';

    const formatToUse = format || CommonFormats.DOLLAR_CURRENCY;

    return numeral(value).format(formatToUse);
  }

  /**
   *
   * return taxonomy to display.
   *
   * @param sources
   * @param taxonomiesCodes
   * @param chosenCode
   * @param showSpecialtyDescription
   */
  static taxonomy(sources = [], taxonomiesCodes, chosenCode, showSpecialtyDescription = false) {
    const taxonomyItems = _.isEmpty(chosenCode)
      ? taxonomiesCodes
      : _.isArray(chosenCode) &&
        _.filter(taxonomiesCodes, taxonomy =>
          _.includes(chosenCode, taxonomy.code || taxonomy.taxonomyCode),
        );
    return _.chain(taxonomyItems)
      .map(taxonomyItem =>
        showSpecialtyDescription
          ? taxonomyItem.subSpecialtyDescription
          : taxonomyItem.sourceDescription || taxonomyItem.desc || taxonomyItem.taxonomyDisplayName,
      )
      .uniq()
      .join(', ')
      .trim()
      .value();
  }

  /**
   * formats a boolean.
   * @param value
   * @param trueValue
   * @param falseValue
   * @returns {string}
   */
  static boolean(value, trueValue = 'True', falseValue = 'False') {
    return value ? trueValue : falseValue;
  }

  /**
   * Formats a phone number object into a string
   * @param phoneNumberObj
   * @returns {*}
   */
  static phoneNumber(phoneNumberObj) {
    if (_.isEmpty(phoneNumberObj)) return '';

    const countryCode = dialCodeToCountryCode(phoneNumberObj.countryDialingCode);

    return Phoneformat.formatInternational(countryCode, phoneNumberObj.number);
  }

  static phoneNumberSimple(phoneNumberObj) {
    return _.isEmpty(phoneNumberObj)
      ? ''
      : phoneNumberObj.countryDialingCode + phoneNumberObj.number;
  }

  static phoneNumberDefault(phoneNumber) {
    if (phoneNumber instanceof Object) return this.phoneNumberSimple(phoneNumber);

    if (phoneNumber) {
      return phoneNumber.startsWith('+1') ? phoneNumber : `+1${phoneNumber}`;
    }

    return '';
  }

  /**
   * Limit text and add '...'
   * Always prefer css truncate class.
   * @param value
   * @param limit
   */
  static truncate(value, limit = 65) {
    return !value ? '' : (value.length < limit && value) || `${value.substring(0, limit)}...`;
  }

  /**
   * Formats time zones.
   * If doesn't have a timezone data on it the object, will return an empty string
   * @param value
   * @param format
   * @returns {*}
   */
  static timezone(value, format = CommonFormats.SHORT_TIMEZONE) {
    return Formatter.date(value, format);
  }

  /**
   * Formats date values.
   * Accepts date and moment objects
   *
   * @param value
   * @param formatStyle
   * @param utc
   * @returns {*}
   */
  static date(value, formatStyle = CommonFormats.SHORT_NAMING_DATE, utc = false) {
    if (!value) return '';
    const locale = getLocaleLanguage() || 'en';

    if (locale === 'es') require('moment/locale/es');
    const localedFormat = getLocaledFormats(formatStyle, locale);
    const formatted = (utc ? moment.utc(value) : moment(value))
      .locale(locale)
      .format(localedFormat);

    return _.upperFirst(formatted);
  }

  static minutesToMs(minutes) {
    return minutes * 60 * 1000;
  }

  static msToMinutes(time) {
    return Math.floor(time / 1000 / 60);
  }

  /**
   * Formats time values.
   * Accepts date and moment objects
   *
   * @param value
   * @param format
   * @returns {*}
   */
  static time(value, format = CommonFormats.AM_PM_TIME) {
    return Formatter.date(value, format);
  }

  static calendarDate(value, formatStyle) {
    if (!value) return '';
    const formatToUse = formatStyle || CommonFormats.SHORT_NAMING_DATE;
    return moment(moment().calendar(value)).format(formatToUse);
  }

  /**
   * Formats a utc date
   * @param value
   * @param format
   * @returns {*}
   */
  static dateUtc(value, format = CommonFormats.SHORT_NAMING_DATE) {
    return Formatter.date(value, format, true);
  }

  /**
   * Formats an address object to a string
   * @param address
   * @returns {*}
   */
  static address(address) {
    if (!address) return '';
    if (_.isString(address)) return address;

    // e.g. 1250 16th Street, Santa Monica, CA 90404
    const addressFormatStr = address.addressLine2
      ? ':addressLine1, :addressLine2, :city, :state :zip'
      : ':addressLine1, :city, :state :zip';

    return BookMdUtil.format(addressFormatStr, address, false);
  }

  /**
   * this formatter better handles fallback for missing data including grammatical elements
   * @param address
   * @param isFiveDigitZip
   * @returns {string}
   */
  static addressFormat(address, isFiveDigitZip = true) {
    let stringAddress = '';
    if (!address) {
      return stringAddress;
    }
    if (address.addressLine1) {
      stringAddress = address.addressLine1;
    }
    if (address.addressLine2) {
      stringAddress += `${stringAddress ? ', ' : ''}${address.addressLine2}`;
    }
    if (address.city) {
      stringAddress += `${stringAddress ? ', ' : ''}${address.city}`;
    }
    if (address.state) {
      stringAddress += `${stringAddress ? ', ' : ''}${address.state}`;
    }
    if (address.zip) {
      stringAddress += `${stringAddress ? ' ' : ''}${
        isFiveDigitZip ? address.zip.substring(0, 5) : address.zip
      }`;
    }

    return stringAddress;
  }

  static formatAddress(address, isFiveDigitZip = true) {
    let stringAddress = '';
    if (!address) {
      return stringAddress;
    }
    if (address.addressLineOne) {
      stringAddress = address.addressLineOne;
    }
    if (address.addressLineTwo) {
      stringAddress += `${stringAddress ? ', ' : ''}${address.addressLineTwo}`;
    }
    if (address.city) {
      stringAddress += `${stringAddress ? ', ' : ''}${address.city}`;
    }
    if (address.state) {
      stringAddress += `${stringAddress ? ', ' : ''}${address.state}`;
    }
    if (address.zip) {
      stringAddress += `${stringAddress ? ', ' : ''}${
        isFiveDigitZip ? address.zip.substring(0, 5) : address.zip
      }`;
    }
    return stringAddress;
  }

  static addressStringToObject(addressString) {
    if (_.isEmpty(addressString)) {
      return null;
    }

    const addressObject = parser.parseLocation(addressString);
    const { city, state, zip = '', number, street, type } = addressObject;

    return {
      city,
      state,
      zip: zip || '',
      addressLine1: _.chain([number, street, type])
        .compact()
        .join(' ')
        .value(),
      addressLine2: '',
    };
  }

  static parseAddress(addressString) {
    if (_.isEmpty(addressString)) return null;

    const addressObject = parser.parseLocation(addressString) || {};
    const {
      number,
      prefix,
      street,
      type,
      suffix,
      city,
      state,
      zip,
      sec_unit_type: secUnitType,
      sec_unit_num: secUnitNum,
    } = addressObject;

    const addressLine1 = _.join(_.compact([number, prefix, street, type, suffix]), ' ') || '';
    const addressLine2 = _.join(_.compact([secUnitType, secUnitNum]), ' ') || '';

    return {
      city,
      state,
      zip: zip || '',
      addressLine1,
      addressLine2,
    };
  }

  /**
   * gets a list of cpt objects and returns their code separated with
   * @param cpts
   * @returns {*}
   */
  static cptCodes(cpts) {
    return _.chain(cpts)
      .map(val => val.cpt.code)
      .join(', ')
      .value();
  }

  /**
   * gets a list of icd objects and returns their code separated with
   * @param icds
   * @returns {*}
   */
  static icdCodes(icds) {
    return _.chain(icds)
      .map(val => val.icd.code)
      .join(', ')
      .value();
  }

  /**
   * Formats gender
   * @param intl
   * @param gender
   * @returns {*}
   */
  static gender(intl, gender) {
    return gender === 'MALE'
      ? intl.formatMessage(messages.male)
      : intl.formatMessage(messages.female);
  }

  static duration(duration) {
    duration /= 1000 * 60;
    return `${duration}m`;
  }

  /**
   * Formats an address object that has 2 address lines
   * @param address
   * @returns {*}
   */
  static inline2LineAddress(address) {
    const addressFormatStr = ':addressLine1 :addressLine2, :city, :state :zip';
    return BookMdUtil.format(addressFormatStr, address);
  }

  /**
   * Formats a full name string to first, middle and last name object (and more).
   * Check out parse-full-name package for more information.
   * @param name
   * @returns {{firstName, middleName, lastName}}
   */
  static fullName(name) {
    const parsedName = parseFullName(name);
    return {
      firstName: parsedName.first,
      middleName: parsedName.middle,
      lastName: _.trim(`${parsedName.last} ${parsedName.suffix}`),
    };
  }

  static toFullName(firstName, middleName, lastName) {
    return middleName ? `${firstName} ${middleName} ${lastName}` : `${firstName} ${lastName}`;
  }

  static destructFullName(person) {
    if (_.isEmpty(person)) return '';
    const firstName = _.get(person, 'firstName');
    const middleName = _.get(person, 'middleName');
    const lastName = _.get(person, 'lastName');
    return Formatter.toFullName(firstName, middleName, lastName);
  }

  static intlToString(intl, msg) {
    return _.isString(msg) ? msg : intl.formatMessage(msg);
  }

  /**
   * Converts a users birthday into age (years)
   * @param dateOfBirth birthday as string 'YYYY-MM-DD'
   */
  static age(dateOfBirth) {
    return moment().diff(dateOfBirth, 'years');
  }

  /**
   * Formatting phone number.
   * @param phoneNumberObj: object representing a phone number (with country and number properies).
   * @returns String: formatted representation of the number (xxx)xxx-xxxx
   */
  static formatLocal(phoneNumberObj) {
    const countryCode = dialCodeToCountryCode(phoneNumberObj.countryDialingCode);
    return Phoneformat.formatLocal(countryCode, phoneNumberObj.number);
  }

  /**
   * Formatting phone numbers from a string with no country code.
   */
  static formatLocalString(countryCode, phoneNumberString) {
    return Phoneformat.formatLocal(countryCode, phoneNumberString);
  }

  static appointmentStatus(status) {
    return _.chain(status)
      .capitalize()
      .replace('_', ' ')
      .value();
  }

  static enumValueToString(str) {
    return _.chain(str)
      .split('_')
      .map(_.capitalize)
      .join(' ')
      .value();
  }

  /**
   * formats the office name to start each word with capital letter
   * without dropping special characters (/*&...) in the office name that startCase does
   * @param officeName
   * @returns {*}
   */
  static officeName(officeName) {
    return _.replace(_.toLower(officeName), /\w+/g, _.capitalize);
  }

  /**
   * Most of the time, physician titles are in uppercase (like MD). Sometimes, they're not (like
   * PhD). This function takes the title/titles from the full name and parses them in the correct
   * capitalization. Also, this function takes into account that some providers have multiple titles
   * separated by comma.
   */
  static formatProviderTitle({ title, firstName, middleName, lastName, suffix }) {
    // get suffixes in array instead of comma limited string
    const suffixes = _.isEmpty(suffix)
      ? []
      : suffix
          .split(',')
          .map(_.trim)
          .filter(s => !_.isEmpty(s));
    const parsedSuffix = suffixes.map(_parseSuffix).join(', ');
    const fullProviderTitleWithoutSuffix = _.compact([title, firstName, middleName, lastName]).join(
      ' ',
    );

    return suffix
      ? `${fullProviderTitleWithoutSuffix}, ${parsedSuffix}`
      : fullProviderTitleWithoutSuffix;
  }
}

export default Formatter;
