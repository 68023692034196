import qs from 'qs';
import Promise from 'bluebird';

import queryStringParseOptions from '../utils/queryStringParseOptions';
import { getJwt, getRecaptcha } from '../utils/auth/storage';
import { getSessionId } from '../utils/fraud/session';
import { getDomain } from '../utils/domain/storage';
import { getInternalSessionId } from '../utils/internalSession';
import headerConsts from '../utils/headers';
import { getPublicToken } from '../utils/publicToken';
import { getLocaleLanguage } from '../utils/intl/intlSelector';

const checkStatus = response => {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }
  return response.text().then(responseText => {
    let message = response.statusText;
    try {
      const json = JSON.parse(responseText);
      message = json.responseMessage || message;
    } catch (e) {
      // I want application to not crush, but don't want eslint to yell at me
    }

    const error = new Error(message);
    error.responseMessage = message;
    error.responseText = responseText;
    error.status = response.status;
    throw error;
  });
};

/**
 * Created by asafdavid on 14/03/2016.
 */
export default class BkmdApi {
  /**
   * A fetch object that points to the server
   * @param fetch
   * @param prefix
   */
  constructor(fetch, prefix) {
    this.fetch = fetch;
    this.prefix = `/api/v1${prefix}`;
  }

  /**
   * builds a request headers
   * @private
   */
  static _headers(_domain) {
    const { accessToken } = getJwt();
    const internalSessionId = getInternalSessionId();
    const sessionId = getSessionId();
    const recaptchaToken = getRecaptcha();
    const domain = _domain || getDomain();
    const publicToken = getPublicToken();
    const locale = getLocaleLanguage();
    const headers = { Accept: 'application/json', 'Content-Type': 'application/json' };

    if (sessionId) headers[headerConsts.sessionId] = sessionId;
    if (internalSessionId) headers[headerConsts.internalSessionId] = internalSessionId;
    if (accessToken) headers[headerConsts.authorization] = `Bearer ${accessToken}`;
    if (recaptchaToken) headers[headerConsts.recaptcha] = recaptchaToken;
    if (domain) headers[headerConsts.domain] = domain;
    if (publicToken) headers[headerConsts.publicToken] = publicToken;
    if (locale) headers[headerConsts.locale] = locale;

    return headers;
  }

  /**
   * Invokes a request for update
   * @param uri
   * @param data
   * @param method
   * @param domain
   * @param customHeaders
   * @returns {*}
   * @private
   */
  _update(uri, data, method, domain, customHeaders = {}) {
    const headers = { ...BkmdApi._headers(domain), ...customHeaders };
    return Promise.resolve()
      .then(() =>
        this.fetch(this.prefix + uri, {
          method,
          headers,
          body: JSON.stringify(data),
        }),
      )
      .then(checkStatus);
  }

  _updateJsonResponse(uri, data, method, domain, customHeaders) {
    return this._update(uri, data, method, domain, customHeaders).then(response => response.json());
  }

  _updateHTMLResponse(uri, data, method) {
    return this._update(uri, data, method).then(response => response.text());
  }

  /**
   * posts a new request
   * @param uri
   * @param data
   * @param domain
   * @param customHeaders
   * @returns {*}
   */
  post(uri, data, domain, customHeaders) {
    return this._updateJsonResponse(uri, data, 'post', domain, customHeaders);
  }

  /**
   * deletes a resource
   * @param uri
   * @param data
   * @returns {*}
   */
  httpDelete(uri, data) {
    return this._updateJsonResponse(uri, data, 'delete');
  }

  /**
   *
   * Invokes a new put request
   * @param uri
   * @param data
   * @returns {*}
   */
  put(uri, data) {
    return this._updateJsonResponse(uri, data, 'put');
  }

  /**
   *
   * Invokes a new patch request
   * @param uri
   * @param data
   * @returns {*}
   */
  patch(uri, data) {
    return this._updateJsonResponse(uri, data, 'PATCH');
  }

  /**
   * invoke a get request
   * @param uri
   * @param query - object to by stringify as query string.
   * property 'DCTS' is a saved key and will be overridden
   * @param queryOptions
   * @param domain
   * @param customHeaders
   * @returns {Promise}
   */
  get(uri, query = {}, queryOptions, domain, customHeaders = {}) {
    // Add a timestamp to GET requests to prevent browsers (f**king IE) from caching the responses
    // Hence the name, Disable Cache Time Stamp (DCTS)
    query.DCTS = Date.now();

    const headers = { ...BkmdApi._headers(domain), ...customHeaders };
    const stringifiedQS = qs.stringify(query, queryOptions || queryStringParseOptions);
    const fullUrl = `${this.prefix}${uri}?${stringifiedQS}`;
    return Promise.resolve()
      .then(() =>
        this.fetch(fullUrl, {
          method: 'get',
          headers,
        }),
      )
      .then(checkStatus)
      .then(response => response.json());
  }
}
