import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '../../storybook/src/utils/theme';

function SvgDosage({ theme }) {
  /* eslint-disable */
  return (
    <svg aria-hidden="true" focusable="false" className="svg-icon svg-dosage" width="32px" height="32px" viewBox="0 0 32 32" version="1.1">
      <g id="Style-guide" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Two-color-icons" transform="translate(-523.000000, -559.000000)">
          <g id="Page-1" transform="translate(523.000000, 559.000000)">
            <path d="M31,32 L1,32 C0.447,32 0,31.552 0,31 L0,1 C0,0.448 0.447,0 1,0 C1.553,0 2,0.448 2,1 L2,30 L31,30 C31.553,30 32,30.448 32,31 C32,31.552 31.553,32 31,32" id="Fill-1" fill={theme.secondaryColor}></path>
            <path d="M11,24 C11,25.657 9.657,27 8,27 C6.343,27 5,25.657 5,24 C5,22.343 6.343,21 8,21 C9.657,21 11,22.343 11,24" id="Fill-3" fill={theme.mainColor}></path>
            <path d="M11,15 C11,16.657 9.657,18 8,18 C6.343,18 5,16.657 5,15 C5,13.343 6.343,12 8,12 C9.657,12 11,13.343 11,15" id="Fill-5" fill={theme.mainColor}></path>
            <path d="M20,24 C20,25.657 18.657,27 17,27 C15.343,27 14,25.657 14,24 C14,22.343 15.343,21 17,21 C18.657,21 20,22.343 20,24" id="Fill-7" fill={theme.mainColor}></path>
            <path d="M20,15 C20,16.657 18.657,18 17,18 C15.343,18 14,16.657 14,15 C14,13.343 15.343,12 17,12 C18.657,12 20,13.343 20,15" id="Fill-9" fill={theme.mainColor}></path>
            <path d="M29,24 C29,25.657 27.657,27 26,27 C24.343,27 23,25.657 23,24 C23,22.343 24.343,21 26,21 C27.657,21 29,22.343 29,24" id="Fill-11" fill={theme.mainColor}></path>
            <path d="M11,6 C11,7.657 9.657,9 8,9 C6.343,9 5,7.657 5,6 C5,4.343 6.343,3 8,3 C9.657,3 11,4.343 11,6" id="Fill-13" fill={theme.mainColor}></path>
          </g>
        </g>
      </g>
    </svg>
  );
  /* eslint-enable */
}

SvgDosage.propTypes = {
  theme: PropTypes.object.isRequired,
};

export default withTheme(SvgDosage);
