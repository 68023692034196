/**
 * Created by galgoltzman on 19/06/2017.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'react-bootstrap';
import { defineMessages, FormattedMessage } from 'react-intl';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import classNames from 'classnames';
import { compose } from 'recompose';

import LoggedIn from '../../../../../components/loggedIn/loggedIn';
import FeatureFlagged from '../../../../../components/features/featureFlagged';
import { MemberLogout } from '../../../../../store/tools/analytics/events/memberEvents';
import { firstNameSelector } from '../../../../../store/member/selectors';
import withFeatureFlag from '../../../../../utils/featureFlags/withFeatureFlag';

import './userMenu.less';
import withMembersLogout from '../../utils/withMembersLogout';

const messages = defineMessages({
  yourPlan: {
    defaultMessage: 'Your plan',
    id: 'members.userMenu.yourPlan',
  },
  careCues: {
    defaultMessage: 'Care cues',
    id: 'members.userMenu.careCues',
  },
  medications: {
    defaultMessage: 'Medications',
    id: 'members.userMenu.medications',
  },
  visits: {
    defaultMessage: 'Visits',
    id: 'members.userMenu.visits',
  },
  settings: {
    defaultMessage: 'Settings',
    id: 'members.userMenu.settings',
  },
  signOut: {
    defaultMessage: 'Sign Out',
    id: 'members.userMenu.SignOut',
  },
  signIn: {
    defaultMessage: 'Sign In',
    id: 'members.userMenu.SignIn',
  },
  createAccount: {
    defaultMessage: 'Create account',
    id: 'members.userMenu.createAccount',
  },
  hi: {
    defaultMessage: 'Hi {name}',
    id: 'members.userMenu.hi',
  },
  resources: {
    defaultMessage: 'Resources',
    id: 'members.userMenu.resources',
  },
  appointments: {
    defaultMessage: 'Appointments',
    id: 'members.userMenu.appointments',
  },
});

@autobind
class UserMenu extends React.PureComponent {
  static propTypes = {
    firstName: PropTypes.string.isRequired,
    logout: PropTypes.func.isRequired,
    className: PropTypes.string,
  };

  static contextTypes = {
    paths: PropTypes.object.isRequired,
    analytics: PropTypes.object.isRequired,
  };

  static defaultProps = {
    className: null,
  };

  onLogoutClick() {
    this.context.analytics.track(MemberLogout);
    this.props.logout();
  }

  render() {
    const { firstName, className, showMemberAppointments } = this.props;
    const { paths } = this.context;
    return (
      <Dropdown id="userMenu" className={classNames('user-menu xs-fullscreen-dropdown', className)}>
        <Dropdown.Toggle bsStyle="inline-link user-menu-toggle" aria-label="user menu">
          <i className="icon-user-2" />
          <span className="text-semibold member-name">{firstName}</span>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <LoggedIn renderLi className="dropdown-header" role="menuitem">
            <span className="text-18 text-semibold font-color-brand-main">
              <FormattedMessage {...messages.hi} values={{ name: firstName }} />
            </span>
            <i className="icon-x-2 dropdown-back-btn left visible-xs" />
          </LoggedIn>

          <FeatureFlagged renderLi uri="layout.userMenu.settings" role="menuitem">
            <LoggedIn>
              <Link to={paths.settings && paths.settings()}>
                <i className="icon-cog-s-2 item-icon" />
                <FormattedMessage {...messages.settings} />
              </Link>
            </LoggedIn>
          </FeatureFlagged>

          {showMemberAppointments && (
            <li role="menuitem">
              <LoggedIn>
                <Link to={paths.settings && paths.appointments()}>
                  <i className="icon-calendar-check-2 item-icon" />
                  <FormattedMessage {...messages.appointments} />
                </Link>
              </LoggedIn>
            </li>
          )}

          <FeatureFlagged renderLi uri="layout.userMenu.resources" role="menuitem">
            <LoggedIn>
              <Link to={paths.resources && paths.resources()}>
                <i className="icon-browser item-icon" />
                <FormattedMessage {...messages.resources} />
              </Link>
            </LoggedIn>
          </FeatureFlagged>

          <LoggedIn renderLi onClick={this.onLogoutClick} role="menuitem">
            <Link to="/">
              <i className="icon-heart-key item-icon" />
              <FormattedMessage {...messages.signOut} />
            </Link>
          </LoggedIn>

          <LoggedIn guest renderLi role="menuitem">
            <Link to="/">
              <i className="icon-heart-key item-icon" />
              <FormattedMessage {...messages.signIn} />
            </Link>
          </LoggedIn>

          <LoggedIn guest renderLi role="menuitem">
            <Link to="/start/activation">
              <i className="icon-add-member item-icon" />
              <FormattedMessage {...messages.createAccount} />
            </Link>
          </LoggedIn>
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}

export default compose(
  connect((state) => ({
    firstName: firstNameSelector(state),
  })),
  withMembersLogout,
  withFeatureFlag({
    key: 'members.showMemberAppointments',
    prop: 'showMemberAppointments',
    defaultValue: false,
  }),
)(UserMenu);
