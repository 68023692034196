/**
 * Created by chenrozenes on 19/02/2017.
 */
import { defineMessages } from 'react-intl';
import IntlEnum from '../../utils/intlEnum';

export const UNITED_HEALTH_CARE_ALLSAVERS = 'UNITED_HEALTH_CARE_ALLSAVERS';
export const PREMERA_BLUE_CROSS_ALASKA = 'PREMERA_BLUE_CROSS_ALASKA';
export const ANTHEM = 'ANTHEM';
export const VIM = 'VIM';
export const FLORIDA_BLUE = 'FLORIDA_BLUE';

const messages = defineMessages({
  premeraBlueCrossAlaska: {
    defaultMessage: 'Premera Blue Cross Alaska',
    id: 'bkmdTpa.premeraBlueCrossAlaska',
  },
  unitedHealthCareAllsavers: {
    defaultMessage: 'UnitedHealthcare All Savers',
    id: 'bkmdTpa.unitedHealthCareAllsavers',
  },
  anthem: {
    defaultMessage: 'Anthem',
    id: 'bkmdTpa.anthem',
  },
  floridaBlue: {
    defaultMessage: 'Florida Blue',
    id: 'bkmdTpa.floridaBlue',
  },
  vim: {
    defaultMessage: 'Vim',
    id: 'bkmdTpa.vim',
  },
});

export default new IntlEnum(
  { value: PREMERA_BLUE_CROSS_ALASKA, message: messages.premeraBlueCrossAlaska },
  { value: UNITED_HEALTH_CARE_ALLSAVERS, message: messages.unitedHealthCareAllsavers },
  { value: ANTHEM, message: messages.anthem },
  { value: FLORIDA_BLUE, message: messages.floridaBlue },
  { value: VIM, message: messages.vim },
);
