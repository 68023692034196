/**
 * Created by rom on 09/10/2016.
 */

import { getSessionStorage } from '../storage/sessionStorage';
import { generateUUID } from '../../utils/uuid';

export function getSessionId() {
  if (!process.env.IS_BROWSER) {
    return null;
  }

  let sessionId;

  // Try to get it from session storage
  sessionId = getSessionStorage().getItem('id');

  // Generate if not exists
  if (!sessionId) {
    sessionId = generateUUID();
    getSessionStorage().setItem('id', sessionId);
  }

  return sessionId;
}

