/**
 * Created by guyavarham on 06/03/2017.
 */

import PromiseActionType from '../../promiseActionType';

export const GET_PROVIDER = new PromiseActionType('GET_PROVIDER');
export const GET_PUBLIC_PROVIDER = new PromiseActionType('GET_PUBLIC_PROVIDER');
export const SHARE_VIA_EMAIL = new PromiseActionType('SHARE_VIA_EMAIL');
export const SHARE_VIA_SMS = new PromiseActionType('SHARE_VIA_SMS');
export const GET_PROVIDER_BY_ID = new PromiseActionType('GET_PROVIDER_BY_ID');
export const GET_ALL_EXISTED_SPOKEN_LANGUAGES = new PromiseActionType(
  'GET_ALL_EXISTED_SPOKEN_LANGUAGES',
);

export function getDoctorDetails(npi, memberToken) {
  return ({ bkmdApi: { searchApi } }) => ({
    type: GET_PROVIDER.SOURCE,
    payload: {
      promise: searchApi.getProviderByNpi({ npi, memberToken }),
    },
    meta: {
      tracker: GET_PROVIDER.SOURCE,
    },
  });
}

export function getPublicProviderDetails(npi) {
  return ({ bkmdApi: { providerApi } }) => ({
    type: GET_PUBLIC_PROVIDER.SOURCE,
    payload: {
      promise: providerApi.byNpiPublic(npi)
        .then(res => res.data)
    },
    meta: {
      tracker: GET_PUBLIC_PROVIDER.SOURCE,
    }
  });
}

export function shareViaEmail(email, npi, providerLocationId, geo) {
  return ({ bkmdApi: { providerApi } }) => ({
    type: SHARE_VIA_EMAIL.SOURCE,
    payload: {
      promise: providerApi.shareViaEmail(email, npi, providerLocationId, geo)
    },
    meta: {
      tracker: SHARE_VIA_EMAIL.SOURCE,
    }
  });
}

export function shareViaSms(phoneNumber, npi, providerLocationId, geo) {
  return ({ bkmdApi: { providerApi } }) => ({
    type: SHARE_VIA_SMS.SOURCE,
    payload: {
      promise: providerApi.shareViaSms(phoneNumber, npi, providerLocationId, geo)
    },
    meta: {
      tracker: SHARE_VIA_SMS.SOURCE,
    }
  });
}

export function getAllExistedSpokenLanguages(codes = []) {
  return ({ bkmdApi: { providerApi } }) => ({
    type: GET_ALL_EXISTED_SPOKEN_LANGUAGES.SOURCE,
    payload: {
      promise: providerApi.getAllExistedSpokenLanguages(codes)
        .then(res => res.data)
    },
  });
}
