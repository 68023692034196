import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { autobind } from 'core-decorators';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { compose } from 'redux';
import _ from 'lodash';

import messages from './messages';
import Layout from '../layout/layout';
import SearchOptionsModal from '../../../../components/searchDoctors/searchOptionsModal';
import * as platformSelectors from '../../../../store/platform/selectors';
import FeatureFlagged from '../../../../components/features/featureFlagged';
import setInQueryString from '../../../../store/tools/queryString/setInQueryString';
import SearchPreview from '../../../../components/searchDoctors/searchPreview';
import SearchTypeahead from '../directory/searchDoctors/directorySearch/directorySearchTypeahead';
import { chooseClientLocation, extractSearchTerm } from '../directory/directorySelectors';
import { memberPlan } from '../../../../store/member/selectors';
import queryConnect from '../../../../store/tools/queryString/queryConnect';
import { getCurrentLocation } from '../../../../store/location/actions';
import { openDoctorsResults, openDoctorDetails } from '../directory/routingActions';
import { domain } from '../../../../store/config/selectors';
import SvgHeartBubble from '../../../../assets/svg/heart-bubble';
import SvgMedication from '../../../../assets/svg/medication';
import SvgSearchUser from '../../../../assets/svg/search-user';
import buildPlaceholder from '../../../../utils/placeholder/buildPlaceholder';
import emailVerification from './emailVerification';
import * as Domains from '../../../../model/enum/bkmdDomain';
import { fetchTaxonomiesNotEmpty } from '../../../../store/meta/selectors';
import { autoCompleteProvider } from '../../../../store/directory/selectors';
import { getFeature } from '../../../../store/feature/selectors';
import {
  createToggleState,
  createToggleStatePropTypes,
} from '../../../../components/recompose/toggleStateComponent';
import { mobileMenuOpen, openSearchModal } from '../../../../store/ui/actions';
import { getPathsFromContext } from '../routes/injectPathsToContext';
import { isAuthenticated } from '../../../../store/auth/selectors';
import RecommendedProviders from './components/recommendedProviders';
import HomePopupModals from './components/homePopupModals';
import InsuranceIdBanner from './components/insuranceIdBanner';
import { clientLocationShape } from '../../../../components/searchDoctors/clientLocationShape';
import { generateUUID } from '../../../../utils/uuid';

import './home.less';

const headerText = {
  [Domains.PREMERA_BLUE_CROSS_ALASKA]: messages.premeraSearchText,
  [Domains.VIM]: messages.premeraSearchText,
  [Domains.UNITED_HEALTH_CARE_ALLSAVERS]: messages.uhcAllsaversSearchText,
  [Domains.ANTHEM]: messages.anthemSearchText,
  [Domains.FLORIDA_BLUE]: messages.floridaSearchText,
};

@autobind
class Home extends React.PureComponent {
  static propTypes = {
    ...createToggleStatePropTypes('searchOptionsModal'),
    paths: PropTypes.object.isRequired,
    clientLocation: PropTypes.shape(clientLocationShape).isRequired,
    setInQueryString: PropTypes.func.isRequired,
    openDoctorsResults: PropTypes.func.isRequired,
    isMobileScreen: PropTypes.bool.isRequired,
    mobileMenuOpen: PropTypes.func.isRequired,
    openSearchModal: PropTypes.func.isRequired,
    getCurrentLocation: PropTypes.func.isRequired,
    getRecommendedPcpDoctors: PropTypes.func.isRequired,
    getRecommendDoctorsTracking: PropTypes.object.isRequired,
    hasInsuranceId: PropTypes.bool,
    memberPlan: PropTypes.string,
    intl: intlShape.isRequired,
    recommendedPcp: PropTypes.object,
    searchTerm: PropTypes.string,
    placeholder: PropTypes.string.isRequired,
    isAuthenticatedMember: PropTypes.bool,
    colors: PropTypes.object,
  };

  static defaultProps = {
    isAuthenticatedMember: false,
    hasInsuranceId: null,
    searchTerm: null,
    memberPlan: undefined,
    recommendedPcp: undefined,
    colors: { brandColorSecondary: '#09ace4' },
  };

  onTypeaheadClick() {
    this.openSearch();
  }

  onDummyInputFocus() {
    this.mobileDummyInput.blur();
  }

  onApply(data) {
    this.props.setInQueryString({ ...data, queryId: generateUUID() });
  }

  openSearch() {
    this.props.mobileMenuOpen(false);
    this.props.openSearchModal();
  }

  renderSeeMoreInNetwork() {
    const { openDoctorsResults, paths } = this.props;
    return (
      <div className="text-center margin-top-30 margin-bottom-clean-form">
        <div
          className="btn btn-small btn-outline text-semibold"
          onClick={() => openDoctorsResults(paths)}
        >
          <FormattedMessage {...messages.seeMoreInYourNetwork} />
        </div>
      </div>
    );
  }

  renderLocationRow() {
    const {
      searchOptionsModalShow,
      searchOptionsModalHide,
      searchOptionsModalOn,
      clientLocation,
      memberPlan,
    } = this.props;

    return (
      <div className="search-location-row">
        <SearchPreview onClick={searchOptionsModalShow} chosenLocation={clientLocation} />
        <SearchOptionsModal
          plan={memberPlan}
          isOpen={searchOptionsModalOn}
          onClose={searchOptionsModalHide}
          onApply={this.onApply}
        />
      </div>
    );
  }

  renderMyHealthRow() {
    const { openDoctorsResults, paths } = this.props;
    // support cases when this paths are'nt defined in the domain
    const careCues = _.isFunction(paths.careCues) ? paths.careCues() : '';
    const medicationHistory = _.isFunction(paths.medicationHistory)
      ? paths.medicationHistory()
      : '';

    return (
      <FeatureFlagged uri="home.myHealthRow">
        <div className="my-health-row">
          <div className="row text-center">
            <div className="col-sm-4 my-health-item">
              <Link to={careCues} className="btn-block">
                <SvgHeartBubble />
                <div className="text-18 text-semibold font-color-brand-secondary">
                  <FormattedMessage {...messages.careCues} />
                </div>
                <div className="text-16 margin-top-5">
                  <FormattedMessage {...messages.takeAdvantageOfYourHealthPlanBenefits} />
                </div>
              </Link>
            </div>
            <div className="col-sm-4 my-health-item">
              <Link to={medicationHistory} className="btn-block">
                <SvgMedication />
                <div className="text-18 text-semibold font-color-brand-secondary">
                  <FormattedMessage {...messages.medications} />
                </div>
                <div className="text-16 margin-top-5">
                  <FormattedMessage {...messages.yourMedicationsAreAllHere} />
                </div>
              </Link>
            </div>
            <div className="col-sm-4 my-health-item">
              <a
                href=""
                className="btn-block"
                onClick={e => {
                  e.preventDefault();
                  return openDoctorsResults(paths);
                }}
              >
                <SvgSearchUser />
                <div className="text-18 text-semibold font-color-brand-secondary">
                  <FormattedMessage {...messages.primaryCare} />
                </div>
                <div className="text-16 margin-top-5">
                  <FormattedMessage {...messages.seeThePrimaryCareDoctors} />
                </div>
              </a>
            </div>
          </div>
        </div>
      </FeatureFlagged>
    );
  }

  render() {
    const { domain, searchTerm, placeholder, isAuthenticatedMember } = this.props;
    return (
      <div>
        <Layout active="HOME" footerClassName="white not-fixed">
          <div className="members-home-wrap">
            <div className="row">
              <div className="col-sm-10 col-sm-offset-1 col-md-10 col-md-offset-1">
                <div className="home-main-title">
                  <h1 className="title-brand-1 no-margin" role="alert">
                    <FormattedMessage {...headerText[domain]} />
                    <span className="off-screen-text">
                      <FormattedMessage {...messages.love} />
                    </span>
                  </h1>
                  <i className="icon-heart-vim font-color-brand-accent" />
                </div>

                {/* /// Desktop search input /// */}
                <div className="hidden-xs">
                  <SearchTypeahead
                    containerClasses="left-search-icon no-right-border hide-arrow"
                    location={_.get(this.props, 'clientLocation.geo', null)}
                  />
                </div>

                {/* /// Mobile search input /// */}
                <div className="home-search-input-mobile visible-xs">
                  <div
                    className="big-search-input multiple-inputs
                    left-search-icon right-clear-icon no-margin"
                  >
                    <input
                      type="text"
                      className="clean-input bootstrap-typeahead-input-main"
                      aria-label={placeholder}
                      placeholder={placeholder}
                      readOnly="true"
                      onClick={this.onTypeaheadClick}
                      onFocus={this.onDummyInputFocus}
                      ref={input => {
                        this.mobileDummyInput = input;
                      }}
                      value={searchTerm}
                    />
                  </div>
                  <i className="icon-search-2 search-inner-icon" />
                  <i className="icon-x-2 clear-input-btn" />
                </div>

                {this.renderLocationRow()}
                <InsuranceIdBanner />
                <FeatureFlagged uri="home.recommendedDoctors">
                  <RecommendedProviders />
                </FeatureFlagged>
                {this.renderSeeMoreInNetwork()}
                {this.renderMyHealthRow()}
              </div>
            </div>
          </div>
        </Layout>
        {isAuthenticatedMember && <HomePopupModals />}
      </div>
    );
  }
}

export default compose(
  queryConnect(query => ({
    searchTerm: extractSearchTerm(query),
  })),
  createToggleState('searchOptionsModal'),
  emailVerification(),
  fetchTaxonomiesNotEmpty(),
  injectIntl,
  getPathsFromContext(),
  connect(
    (state, props) => ({
      domain: domain(state),
      isMobileScreen: platformSelectors.isMobileOrTablet(state),
      clientLocation: chooseClientLocation(state),
      memberPlan: memberPlan(state),
      placeholder: buildPlaceholder(getFeature(state, 'search.types'), props.intl),
      colors: getFeature(state, 'site.colors'),
      freeText: autoCompleteProvider(state),
      isAuthenticatedMember: isAuthenticated(state),
    }),
    {
      openDoctorsResults,
      openDoctorDetails,
      setInQueryString,
      getCurrentLocation,
      openSearchModal,
      mobileMenuOpen,
    },
  ),
)(Home);
