/**
 * Created by galgoltzman on 04/06/2017.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import PostReviewActionsList from './postReviewActionsList';
import * as FacilityTypes from '../../directory/facilitySearchResults/facilityTypes';

import SvgFlowersStars from '../../../../../assets/svg/flowersStars';
import SvgSearchUser from '../../../../../assets/svg/search-user';
import SvgLabs from '../../../../../assets/svg/labs';
import SvgImaging from '../../../../../assets/svg/imaging';
import SvgPharmacies from '../../../../../assets/svg/pharmacies';
import SvgHome from '../../../../../assets/svg/home';
import { getFeature } from '../../../../../store/feature/selectors';

const messages = defineMessages({
  seeASpecialist: {
    defaultMessage: 'Find a doctor or specialist',
    id: 'members.doctorReview.seeASpecialist',
  },
  scheduleALab: {
    defaultMessage: 'Find a lab',
    id: 'members.doctorReview.scheduleALab',
  },
  setUpAnImagingVisit: {
    defaultMessage: 'Find an imaging facility',
    id: 'members.doctorReview.setUpAnImagingVisit',
  },
  fulfillYourPrescription: {
    defaultMessage: 'Find a pharmacy',
    id: 'members.doctorReview.fulfillYourPrescription',
  },
  goToYourPlan: {
    defaultMessage: 'Go to Your Plan',
    id: 'members.doctorReview.goToYourPlan',
  },
  goToVimHome: {
    defaultMessage: 'Home',
    id: 'members.doctorReview.goToVimHome',
  },
  thanksOnBehalf: {
    defaultMessage: 'Thanks on behalf of the community',
    id: 'members.doctorReview.thanksOnBehalf',
  },
  wouldYouLikeToDoSomethingElse: {
    defaultMessage: 'Would you like to do something else?',
    id: 'members.doctorReview.wouldYouLikeToDoSomethingElse',
  },
});

const facePic = <SvgSearchUser />;
const tubePic = <SvgLabs />;
const pillPic = <SvgPharmacies />;
const thingPic = <SvgImaging />;
const homePic = <SvgHome />;

function getOptions(paths, hasOpenDirectory, hasServicesMenu) {
  const facilitySearchResultsRoute = hasOpenDirectory
    ? paths.facilitySearchResults
    : paths.securedFacilitySearchResults;

  const facilitiesItems = hasServicesMenu
    ? [
        {
          pic: tubePic,
          message: messages.scheduleALab,
          path: facilitySearchResultsRoute({ facilityType: FacilityTypes.LABORATORY }),
        },
        {
          pic: thingPic,
          message: messages.setUpAnImagingVisit,
          path: facilitySearchResultsRoute({ facilityType: FacilityTypes.RADIOLOGY }),
        },
        {
          pic: pillPic,
          message: messages.fulfillYourPrescription,
          path: facilitySearchResultsRoute({ facilityType: FacilityTypes.PHARMACY }),
        },
      ]
    : [];

  return [
    {
      pic: facePic,
      message: messages.seeASpecialist,
      path: '/',
    },
    ...facilitiesItems,
    {
      pic: homePic,
      message: messages.goToVimHome,
      path: '/',
    },
  ];
}

class ReviewThanks extends React.PureComponent {
  static propTypes = {
    hasOpenDirectory: PropTypes.bool.isRequired,
    hasServicesMenu: PropTypes.bool.isRequired,
  };

  static contextTypes = {
    paths: PropTypes.object.isRequired,
  };

  render() {
    const { hasOpenDirectory, hasServicesMenu } = this.props;
    return (
      <div className="doctor-review-thanks-wrap">
        <div className="row">
          <div className="col-sm-6 col-sm-offset-3 col-lg-4 col-lg-offset-4">
            <div className="text-center">
              <SvgFlowersStars />
            </div>
            <div className="title-24 text-center no-margin padding-h-30 title-brand-1">
              <strong>
                <FormattedMessage {...messages.thanksOnBehalf} />
              </strong>
            </div>
            <div className="text-center text-18 text-dark text-light margin-top-20 padding-h-30">
              <FormattedMessage {...messages.wouldYouLikeToDoSomethingElse} />
            </div>
            <PostReviewActionsList
              options={getOptions(this.context.paths, hasOpenDirectory, hasServicesMenu)}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default connect(state => ({
  hasOpenDirectory: getFeature(state, 'openDirectory'),
  hasServicesMenu: getFeature(state, 'layout.servicesMenu'),
}))(ReviewThanks);
