/**
 * Created by moran on 8/24/17.
 */
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { autobind } from 'core-decorators';
import { connect } from 'react-redux';
import ProfessionalMultiSection from './proffessionalSectionForMultipleValues';
import { taxonomies } from '../../../store/meta/selectors';
import messages from '../../../messages/doctorDetails/messages';
import SvgStethoscope from '../../../assets/svg/stethoscope';

@autobind
class SpecializesInProfessionalSection extends React.Component {
  static propTypes = {
    details: PropTypes.object.isRequired,
    staticTaxonomies: PropTypes.object,
  };

  defaultProps = {
    staticTaxonomies: null,
  };

  render() {
    const { details, staticTaxonomies } = this.props;
    if (!staticTaxonomies) return null;
    const codes = _.compact(
      _.map(details.taxonomies, ({ code, taxonomyCode }) => {
        const taxonomy = code || taxonomyCode;
        return _.get(staticTaxonomies, `${taxonomy}.name`, null);
      }),
    );
    if (_.isEmpty(codes)) return null;
    return (
      <ProfessionalMultiSection
        dataArray={codes}
        message={{ ...messages.specializesIn, values: { gender: details.gender } }}
      >
        <SvgStethoscope />
      </ProfessionalMultiSection>
    );
  }
}

export default connect(state => ({
  staticTaxonomies: taxonomies(state),
}))(SpecializesInProfessionalSection);
