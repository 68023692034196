import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '../../storybook/src/utils/theme';

function SvgSearchUser({ theme }) {
  /* eslint-disable */
  return (
    <svg aria-hidden="true" focusable="false" width="32px" height="32px" viewBox="0 0 32 32" version="1.1" className="svg-icon svg-icon-search-user">
      <defs>
        <polygon id="path-1" points="0.5003 0 25 0 25 31.9999 0.5003 31.9999"></polygon>
      </defs>
      <g id="Home-versions" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Home-Premera-768" transform="translate(-589.000000, -943.000000)">
          <g id="Group-13" transform="translate(65.000000, 942.000000)">
            <g id="2-color-icon---search-docs-Premera" transform="translate(524.000000, 1.000000)">
              <g>
                <g id="Group-3" transform="translate(7.000000, 0.000000)">
                  <g id="Clip-2"></g>
                  <path d="M15.5273,13.8989 C16.7953,12.3839 17.5003,10.4849 17.5003,8.4999 C17.5003,3.8129 13.6863,-0.0001 9.0003,-0.0001 C4.3133,-0.0001 0.5003,3.8129 0.5003,8.4999 C0.5003,11.0259 1.6423,13.4129 3.6323,15.0469 C4.0613,15.3989 4.6903,15.3359 5.0413,14.9079 C5.3903,14.4819 5.3293,13.8519 4.9023,13.5009 C3.3763,12.2479 2.5003,10.4249 2.5003,8.4999 C2.5003,4.9159 5.4163,1.9999 9.0003,1.9999 C12.5843,1.9999 15.5003,4.9159 15.5003,8.4999 C15.5003,10.4249 14.6243,12.2479 13.0973,13.5009 C12.8163,13.7319 12.6823,14.0979 12.7493,14.4559 C12.8153,14.8139 13.0713,15.1079 13.4173,15.2229 C19.1483,17.1289 23.0003,22.4649 23.0003,28.4999 L23.0003,29.9999 L13.0003,29.9999 C12.4473,29.9999 12.0003,30.4479 12.0003,30.9999 C12.0003,31.5519 12.4473,31.9999 13.0003,31.9999 L24.0003,31.9999 C24.5523,31.9999 25.0003,31.5519 25.0003,30.9999 L25.0003,28.4999 C25.0003,22.1349 21.2533,16.4509 15.5273,13.8989" id="Fill-1" fill={theme.mainColor} ></path>
                </g>
                <path d="M5.99990625,26 C3.79394072,26 1.99996875,24.206 1.99996875,22 C1.99996875,19.794 3.79394072,18 5.99990625,18 C8.20587178,18 9.99984375,19.794 9.99984375,22 C9.99984375,24.206 8.20587178,26 5.99990625,26 L5.99990625,26 Z M10.8848299,25.471 C11.583819,24.49 11.9998125,23.294 11.9998125,22 C11.9998125,18.691 9.30885455,16 5.99990625,16 C2.69095795,16 0,18.691 0,22 C0,25.309 2.69095795,28 5.99990625,28 C7.29388603,28 8.48986735,27.584 9.47085202,26.885 L14.2927767,31.707 C14.4877736,31.902 14.7437696,32 14.9997656,32 C15.2557616,32 15.5117576,31.902 15.7067546,31.707 C16.0977485,31.316 16.0977485,30.684 15.7067546,30.293 L10.8848299,25.471 Z" id="Fill-4" fill={theme.secondaryColor} ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
  /* eslint-enable */
}

SvgSearchUser.propTypes = {
  theme: PropTypes.object.isRequired,
};

export default withTheme(SvgSearchUser);
