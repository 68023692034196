import React from 'react';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import moment from 'moment-timezone';

import Formatter from '../../utils/formatter';
import CommonFormats from '../../utils/formatter/commonFormats';
import * as constants from '../../model/enum/appointments';
import SpinnerButton from '../ui/spinner/spinnerButton';
import SvgNoAtlasAppointments from '../../assets/svg/noAtlasAppointments';
import DoctorLogo from '../doctorImage/doctorLogo';
import CancelAppointmentsModal from '../cancelAppointmentsModal';

import './styles.less';

const messages = defineMessages({
  canceled: {
    defaultMessage: 'Canceled',
    id: 'atlas.appointmentsPage.canceled',
  },
  tookPlace: {
    defaultMessage: 'Took place',
    id: 'atlas.appointmentsPage.tookPlace',
  },
  cancelBtn: {
    defaultMessage: 'Cancel',
    id: 'atlas.appointmentsPage.cancelBtn',
  },
  rescheduleBtn: {
    defaultMessage: 'Reschedule',
    id: 'atlas.appointmentsPage.rescheduleBtn',
  },
  notingToShow: {
    defaultMessage: 'Nothing to show here',
    id: 'atlas.appointmentsPage.notingToShow',
  },
});

class AppointmentCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      appointment: { name: '', phoneNumber: '', startTime: null },
      isSelected: false,
    };
  }
  render() {
    const {
      type,
      appointment,
      availabilityByNpi,
      isLoadingAvailability,
      isLoadingCancel,
      isLoadingReschedule,
      onConfirmation,
    } = this.props;

    const getApptDate = () => {
      if (!appointment.startTime || !appointment.timeZone) {
        return appointment.timePreferences;
      }
      return Formatter.date(
        moment(appointment.startTime).tz(appointment.timeZone),
        CommonFormats.APPOINTMENTS_DEFAULT_DATE_AND_DEFAULT_TIME,
      ).replace(':00', '');
    };

    const cancelAppointmentModalToggle = (
      item = { name: '', phoneNumber: '', startTime: null },
    ) => {
      this.setState({ appointment: item, isSelected: !this.state.isSelected });
    };

    return (
      <div className="appointment-card">
        <div className="appointment-card__wrapper">
          <div className="appointment-card__logo">
            {constants.CARD_TYPE_EMPTY === type ? (
              <SvgNoAtlasAppointments />
            ) : (
              <DoctorLogo provider={appointment.provider} />
            )}
          </div>
          <div className="appointment-card__body-wrapper">
            {constants.CARD_TYPE_EMPTY === type ? (
              <div className="appointment-card__body-wrapper__empty-section">
                <FormattedMessage {...messages.notingToShow} />
              </div>
            ) : (
              <div className="appointment-card__body">
                <div className="appointment-card__body-top-section">
                  <span className="appointment-card__body-top-section__name">
                    {appointment.provider.firstName} {appointment.provider.lastName},{' '}
                    {appointment.provider.suffix}
                  </span>
                  {appointment.provider.taxonomies.length && (
                    <span className="appointment-card__body-top-section__suffix">
                      &nbsp;/&nbsp;{appointment.provider.taxonomies[0].taxonomyDisplayName}
                    </span>
                  )}
                </div>
                <div className="appointment-card__body-middle-section">
                  {appointment.provider.address}
                </div>
                <div className="appointment-card__body-bottom-section">{getApptDate()}</div>
              </div>
            )}
          </div>
        </div>

        <div className={`appointment-card__actions appointment-card__actions--${type}`}>
          {constants.CARD_TYPE_REQUESTED === type ? (
            <SpinnerButton
              className="appointment-card__actions__cancel-btn"
              onClick={() => {
                if (appointment.cancellation.cancellationType !== constants.API_CANCELLATION_TYPE) {
                  cancelAppointmentModalToggle({
                    name: `${appointment.provider.firstName} ${appointment.provider.lastName},
                ${appointment.provider.suffix}`,
                    phoneNumber: Formatter.formatLocal(appointment.provider.clinicPhoneNumber),
                    startTime: appointment.startTime,
                  });
                } else {
                  onConfirmation({
                    appointment,
                    action: constants.CANCEL_APPOINTMENT_ACTION,
                    type,
                  });
                }
              }}
              isLoading={isLoadingAvailability || isLoadingCancel[appointment.id]}
            >
              <FormattedMessage {...messages.cancelBtn} />
            </SpinnerButton>
          ) : constants.CARD_TYPE_UPCOMING === type ? (
            <div>
              {isLoadingAvailability ||
              constants.BOOKING_TYPES.some(
                (bookingType) => bookingType === availabilityByNpi[appointment.provider.npi],
              ) ? (
                <div className="appointment-card__actions__btns-wrapper">
                  <SpinnerButton
                    className="appointment-card__actions__cancel-btn"
                    onClick={() => {
                      if (
                        appointment.cancellation.cancellationType !==
                        constants.API_CANCELLATION_TYPE
                      ) {
                        cancelAppointmentModalToggle({
                          name: `${appointment.provider.firstName} ${appointment.provider.lastName},
                ${appointment.provider.suffix}`,
                          phoneNumber: Formatter.formatLocal(
                            appointment.provider.clinicPhoneNumber,
                          ),
                          startTime: appointment.startTime,
                        });
                      } else {
                        onConfirmation({
                          appointment,
                          action: constants.CANCEL_APPOINTMENT_ACTION,
                          type,
                        });
                      }
                    }}
                    isLoading={isLoadingAvailability || isLoadingCancel[appointment.id]}
                  >
                    <FormattedMessage {...messages.cancelBtn} />
                  </SpinnerButton>
                  {appointment.cancellation.cancellationType ===
                    constants.API_CANCELLATION_TYPE && (
                    <SpinnerButton
                      className="appointment-card__actions__reschedule-btn"
                      isLoading={isLoadingAvailability || isLoadingReschedule[appointment.id]}
                      onClick={() =>
                        onConfirmation({
                          appointment,
                          action: constants.RESCHEDULE_APPOINTMENT_ACTION,
                          type,
                        })
                      }
                    >
                      <FormattedMessage {...messages.rescheduleBtn} />
                    </SpinnerButton>
                  )}
                </div>
              ) : null}
            </div>
          ) : constants.CARD_TYPE_PAST === type ? (
            <div className="appointment-card__actions__past-btns-wrapper">
              {!constants.ACCEPTED_APPOINTMENTS_STATUS.includes(appointment.status) ? (
                <div className="appointment-card__actions__canceled-btn">
                  <FormattedMessage {...messages.canceled} />
                  <span className="icon-x icon-cancel" />
                </div>
              ) : (
                <div className="appointment-card__actions__took-btn">
                  <FormattedMessage {...messages.tookPlace} />
                  <span className="icon-check-circle-e icon-accepted" />
                </div>
              )}
            </div>
          ) : (
            <div />
          )}
        </div>
        <CancelAppointmentsModal
          isOpen={this.state.isSelected}
          onClose={cancelAppointmentModalToggle}
          name={this.state.appointment.name}
          startTime={this.state.appointment.startTime}
          phoneNumber={this.state.appointment.phoneNumber}
        />
      </div>
    );
  }
}

export default injectIntl(AppointmentCard);
