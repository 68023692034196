/**
 * Created by chenrozenes on 09/08/2017.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '../../storybook/src/utils/theme';

function SvgSigninEvening({ theme }) {
  /* eslint-disable */
  return (
    <svg aria-hidden="true" focusable="false" width="259px" height="115px" viewBox="0 0 259 115" version="1.1" className="svg-icon svg-signin-evening">
      <defs>
        <polygon points="0.7008 0.8 33.7008 0.8 33.7008 46.9998 0.7008 46.9998"></polygon>
      </defs>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-58.000000, -145.000000)">
          <g transform="translate(58.000000, 144.000000)">
            <g transform="translate(0.000000, 0.200200)">
              <g></g>
              <path d="M23.8008,3.7998 C12.7178,3.7998 3.7008,12.8168 3.7008,23.9008 C3.7008,34.9838 12.7178,43.9998 23.8008,43.9998 C25.3458,43.9998 26.8638,43.8278 28.3418,43.4868 C21.6568,39.3098 17.5008,31.9618 17.5008,23.9008 C17.5008,15.8378 21.6568,8.4898 28.3428,4.3138 C26.8638,3.9718 25.3458,3.7998 23.8008,3.7998 M23.8008,46.9998 C11.0638,46.9998 0.7008,36.6378 0.7008,23.9008 C0.7008,11.1628 11.0638,0.7998 23.8008,0.7998 C26.9158,0.7998 29.9398,1.4098 32.7848,2.6128 C33.3408,2.8488 33.7008,3.3928 33.7008,3.9948 C33.7008,4.5978 33.3398,5.1428 32.7848,5.3768 C25.3218,8.5288 20.5008,15.8008 20.5008,23.9008 C20.5008,31.9998 25.3218,39.2718 32.7848,42.4238 C33.3398,42.6578 33.7008,43.2028 33.7008,43.8058 C33.7008,44.4078 33.3398,44.9528 32.7848,45.1878 C29.9378,46.3908 26.9148,46.9998 23.8008,46.9998" fill={theme.secondaryColor}></path>
            </g>
            <path d="M54.0742,81.2002 L14.5612,81.2002 C10.1522,81.2002 6.5652,77.6122 6.5652,73.2012 C6.5652,68.7902 10.1522,65.2012 14.5612,65.2012 L16.8132,65.2012 C19.0042,65.2012 20.7852,63.4202 20.7852,61.2312 C20.7852,59.0412 19.0042,57.2602 16.8132,57.2602 L10.1862,57.2602 C4.5692,57.2602 0.0002,52.6902 0.0002,47.0732 C0.0002,44.7632 0.7542,42.5842 2.1802,40.7722 C2.6922,40.1212 3.6362,40.0082 4.2872,40.5212 C4.9382,41.0342 5.0512,41.9772 4.5382,42.6282 C3.5322,43.9062 3.0002,45.4432 3.0002,47.0732 C3.0002,51.0362 6.2232,54.2602 10.1862,54.2602 L16.8132,54.2602 C20.6572,54.2602 23.7852,57.3882 23.7852,61.2312 C23.7852,65.0742 20.6572,68.2012 16.8132,68.2012 L14.5612,68.2012 C11.8062,68.2012 9.5652,70.4442 9.5652,73.2012 C9.5652,75.9582 11.8062,78.2002 14.5612,78.2002 L54.0742,78.2002 C56.8312,78.2002 59.0732,75.9582 59.0732,73.2012 C59.0732,70.4442 56.8312,68.2012 54.0742,68.2012 L54.0392,68.2012 C50.2292,68.1612 47.1432,65.0502 47.1432,61.2312 C47.1432,57.3882 50.2702,54.2602 54.1142,54.2602 L71.5112,54.2602 C75.4752,54.2602 78.7012,51.0362 78.7012,47.0732 C78.7012,43.1102 75.4752,39.8872 71.5112,39.8872 L32.2012,39.8872 C31.3732,39.8872 30.7012,39.2152 30.7012,38.3872 C30.7012,37.5582 31.3732,36.8872 32.2012,36.8872 L71.5112,36.8872 C77.1302,36.8872 81.7012,41.4562 81.7012,47.0732 C81.7012,52.6902 77.1302,57.2602 71.5112,57.2602 L54.1142,57.2602 C51.9252,57.2602 50.1432,59.0412 50.1432,61.2312 C50.1432,63.4202 51.9252,65.2012 54.1142,65.2012 L54.1442,65.2012 C58.5232,65.2392 62.0732,68.8132 62.0732,73.2012 C62.0732,77.6122 58.4852,81.2002 54.0742,81.2002" fill={theme.secondaryColor}></path>
            <path d="M161.8984,30.2002 L185.8454,30.2002 C187.4464,30.2002 188.7494,28.8962 188.7494,27.2952 C188.7494,25.6932 187.4464,24.3892 185.8454,24.3892 L176.8134,24.3892 C173.9414,24.3892 171.6044,22.0542 171.6044,19.1822 C171.6044,16.3102 173.9414,13.9742 176.8134,13.9742 L179.1624,13.9742 C181.5814,13.9742 183.5484,12.0072 183.5484,9.5882 C183.5484,7.1692 181.5814,5.2002 179.1624,5.2002 L155.4294,5.2002 C148.5764,5.2002 143.0004,10.7762 143.0004,17.6302 C143.0004,24.4832 148.5764,30.0582 155.4294,30.0582 L161.8984,30.2002 Z M185.8454,33.2002 L161.8814,33.2002 L161.8484,33.2002 L155.3964,33.0582 C146.9214,33.0582 140.0004,26.1382 140.0004,17.6302 C140.0004,9.1222 146.9214,2.2002 155.4294,2.2002 L179.1624,2.2002 C183.2354,2.2002 186.5484,5.5142 186.5484,9.5882 C186.5484,13.6612 183.2354,16.9742 179.1624,16.9742 L176.8134,16.9742 C175.5954,16.9742 174.6044,17.9652 174.6044,19.1822 C174.6044,20.3992 175.5954,21.3892 176.8134,21.3892 L185.8454,21.3892 C189.1004,21.3892 191.7494,24.0392 191.7494,27.2952 C191.7494,30.5512 189.1004,33.2002 185.8454,33.2002 L185.8454,33.2002 Z" fill={theme.secondaryColor}></path>
            <path d="M202.0625,49.2002 C195.9625,49.2002 190.9995,54.1632 190.9995,60.2632 C190.9995,66.3632 195.9625,71.3262 202.0625,71.3262 L240.9335,71.3262 C247.0355,71.3262 252.0005,66.3632 252.0005,60.2632 C252.0005,54.1632 247.0355,49.2002 240.9335,49.2002 L202.0625,49.2002 Z M240.9335,74.3262 L202.0625,74.3262 C194.3085,74.3262 187.9995,68.0172 187.9995,60.2632 C187.9995,52.5092 194.3085,46.2002 202.0625,46.2002 L240.9335,46.2002 C248.6895,46.2002 255.0005,52.5092 255.0005,60.2632 C255.0005,68.0172 248.6895,74.3262 240.9335,74.3262 L240.9335,74.3262 Z" fill={theme.secondaryColor}></path>
            <path d="M99.418,10.0273 L101.226,11.7893 C101.939,12.4853 102.351,13.7523 102.183,14.7343 L101.756,17.2223 L103.99,16.0483 C104.859,15.5983 106.228,15.6023 107.08,16.0443 L109.321,17.2213 L108.895,14.7363 C108.727,13.7513 109.137,12.4853 109.85,11.7903 L111.659,10.0273 L109.161,9.6653 C108.178,9.5243 107.099,8.7413 106.655,7.8443 L105.538,5.5823 L104.421,7.8443 C103.979,8.7413 102.901,9.5243 101.914,9.6653 L99.418,10.0273 Z M109.782,17.4543 L109.792,17.4543 L109.782,17.4543 Z M109.761,20.4533 C109.265,20.4533 108.769,20.3233 108.286,20.0663 L105.692,18.7043 C105.716,18.7183 105.644,18.7013 105.538,18.7013 C105.432,18.7013 105.361,18.7173 105.343,18.7233 L102.784,20.0703 C101.56,20.7203 100.119,20.4883 99.314,19.5353 C98.779,18.8923 98.572,18.0393 98.729,17.1243 L99.226,14.2273 C99.222,14.1803 99.155,13.9773 99.102,13.9043 L97.028,11.8873 C96.187,11.0653 95.879,9.9793 96.203,8.9823 C96.528,7.9833 97.415,7.2873 98.576,7.1183 L101.486,6.6963 C101.529,6.6773 101.699,6.5523 101.752,6.4793 L103.032,3.8813 C103.551,2.8293 104.487,2.2003 105.538,2.2003 C106.588,2.2003 107.525,2.8293 108.045,3.8823 L109.345,6.5153 C109.377,6.5513 109.548,6.6763 109.634,6.7053 L112.499,7.1183 C113.66,7.2863 114.549,7.9833 114.874,8.9823 C115.198,9.9793 114.891,11.0653 114.051,11.8863 L111.944,13.9373 C111.921,13.9773 111.856,14.1803 111.856,14.2713 L112.349,17.1223 C112.504,18.0413 112.297,18.8943 111.767,19.5293 C111.271,20.1183 110.541,20.4533 109.761,20.4533 L109.761,20.4533 Z" fill={theme.secondaryColor}></path>
            <g transform="translate(244.000000, 12.200200)" fill={theme.secondaryColor}>
              <path d="M10.623,3.3691 L10.629,3.3701 C10.627,3.3701 10.625,3.3691 10.623,3.3691 L10.623,3.3691 Z M4.449,3.3701 C4.448,3.3701 4.446,3.3701 4.444,3.3711 L4.449,3.3701 Z M10.727,3.4401 L10.727,3.4411 L10.727,3.4401 Z M2.439,9.3191 C2.439,9.3201 2.439,9.3221 2.438,9.3231 L2.439,9.3191 Z M3.766,6.5011 L4.572,7.2871 C5.2,7.9011 5.546,8.9701 5.395,9.8301 L5.206,10.9361 L6.203,10.4121 C6.685,10.1611 7.194,10.1091 7.538,10.1091 C7.882,10.1091 8.393,10.1611 8.869,10.4101 L9.87,10.9361 L9.68,9.8271 C9.532,8.9651 9.879,7.8961 10.505,7.2871 L11.311,6.5011 L10.197,6.3391 C9.336,6.2171 8.425,5.5571 8.036,4.7681 L7.539,3.7611 L7.041,4.7701 C6.651,5.5571 5.741,6.2171 4.876,6.3401 L3.766,6.5011 Z M10.7,14.4211 C10.269,14.4211 9.84,14.3091 9.424,14.0911 L7.562,13.1121 C7.534,13.1121 7.525,13.1091 7.53,13.1041 L5.652,14.0921 C4.566,14.6621 3.298,14.4441 2.588,13.5961 C2.115,13.0361 1.931,12.2851 2.068,11.4861 L2.424,9.4111 C2.415,9.3831 2.416,9.3801 2.422,9.3801 L0.903,7.9001 C0.168,7.1831 -0.099,6.2261 0.189,5.3401 C0.477,4.4531 1.255,3.8341 2.271,3.6861 L4.357,3.3841 C4.38,3.3671 4.385,3.3681 4.385,3.3731 L5.324,1.4691 C5.777,0.5501 6.604,0.0001 7.538,0.0001 C8.473,0.0001 9.302,0.5511 9.754,1.4721 L10.685,3.3581 C10.705,3.3731 10.71,3.3801 10.703,3.3811 L12.805,3.6861 C13.818,3.8341 14.598,4.4501 14.887,5.3371 C15.176,6.2261 14.909,7.1831 14.175,7.8991 L12.667,9.3691 C12.657,9.3961 12.652,9.4021 12.649,9.3951 L13.009,11.4861 C13.145,12.2881 12.96,13.0371 12.487,13.5981 C12.051,14.1201 11.398,14.4211 10.7,14.4211 L10.7,14.4211 Z"></path>
            </g>
            <g transform="translate(94.000000, 44.000000)">
              <g transform="translate(0.000000, 0.000986)" fill={theme.mainColor}>
                <path d="M67.0685918,42.8337863 C67.0685918,53.757074 59.3133041,62.7107178 48.6287014,64.1221151 C47.8929205,64.2197589 47.3425644,64.8460603 47.3425644,65.5887452 L47.3425644,68.0544986 L22.6850301,68.0544986 L22.6850301,65.5887452 C22.6850301,64.8460603 22.134674,64.2197589 21.3988932,64.1221151 C10.7142904,62.7107178 2.95900274,53.757074 2.95900274,42.8337863 L2.95900274,27.6161425 L67.0685918,27.6161425 L67.0685918,42.8337863 Z M68.5480438,24.6572384 L61.1912219,24.6572384 L69.8834959,6.37121096 C70.2346192,5.63345753 69.9219616,4.75170411 69.1832219,4.40058082 C68.4474411,4.05143014 67.5627288,4.36211507 67.2125918,5.1018411 L57.9157151,24.6572384 L55.716263,24.6572384 L64.398674,3.0168 C64.7024548,2.25833425 64.3345644,1.39630685 63.5760986,1.09252603 C62.8176329,0.787758904 61.9556055,1.15466301 61.6518247,1.91510137 L52.5275507,24.6572384 L1.47955068,24.6572384 C0.662893151,24.6572384 9.8630137e-05,25.3200329 9.8630137e-05,26.1366904 L9.8630137e-05,42.8337863 C9.8630137e-05,54.8183342 8.21993425,64.7198137 19.726126,66.8521973 L19.726126,69.5339507 C19.726126,70.3506082 20.3889205,71.0134027 21.2055781,71.0134027 L48.8220164,71.0134027 C49.638674,71.0134027 50.3014685,70.3506082 50.3014685,69.5339507 L50.3014685,66.8521973 C61.8076603,64.7198137 70.0274959,54.8183342 70.0274959,42.8337863 L70.0274959,26.1366904 C70.0274959,25.3200329 69.3647014,24.6572384 68.5480438,24.6572384 L68.5480438,24.6572384 Z"></path>
              </g>
              <path d="M23.1781808,21.6996164 C22.3615233,21.6996164 21.6987288,21.0368219 21.6987288,20.2201644 C21.6987288,18.0009863 23.1811397,16.8894247 24.2640986,16.0786849 C25.2651945,15.3271233 25.6439342,14.9809315 25.6439342,14.3053151 C25.6439342,13.6287123 25.2651945,13.2805479 24.2631123,12.5289863 C23.1811397,11.7172603 21.6987288,10.6047123 21.6987288,8.38553425 C21.6987288,6.16635616 23.1811397,5.05479452 24.2631123,4.24306849 C25.2651945,3.49150685 25.6439342,3.14334247 25.6439342,2.46673973 C25.6439342,1.65008219 26.3067288,0.987287671 27.1233863,0.987287671 C27.9400438,0.987287671 28.6028384,1.65008219 28.6028384,2.46673973 C28.6028384,4.68690411 27.1204274,5.79846575 26.0374685,6.61019178 C25.0363726,7.36175342 24.6576329,7.70893151 24.6576329,8.38553425 C24.6576329,9.06213699 25.0363726,9.41030137 26.0384548,10.161863 C27.1204274,10.973589 28.6028384,12.086137 28.6028384,14.3053151 C28.6028384,16.5235068 27.1204274,17.6350685 26.0384548,18.4458082 C25.0363726,19.1973699 24.6576329,19.5445479 24.6576329,20.2201644 C24.6576329,21.0368219 23.9948384,21.6996164 23.1781808,21.6996164" fill={theme.secondaryColor}></path>
              <path d="M33.0411945,21.6996164 C32.224537,21.6996164 31.5617425,21.0368219 31.5617425,20.2201644 C31.5617425,18.0009863 33.0441534,16.8894247 34.1271123,16.0786849 C35.1282082,15.3271233 35.5069479,14.9809315 35.5069479,14.3053151 C35.5069479,13.6287123 35.1282082,13.2805479 34.126126,12.5289863 C33.0441534,11.7172603 31.5617425,10.6047123 31.5617425,8.38553425 C31.5617425,6.16635616 33.0441534,5.05479452 34.126126,4.24306849 C35.1282082,3.49150685 35.5069479,3.14334247 35.5069479,2.46673973 C35.5069479,1.65008219 36.1697425,0.987287671 36.9864,0.987287671 C37.8030575,0.987287671 38.4658521,1.65008219 38.4658521,2.46673973 C38.4658521,4.68690411 36.9834411,5.79846575 35.9004822,6.61019178 C34.8993863,7.36175342 34.5206466,7.70893151 34.5206466,8.38553425 C34.5206466,9.06213699 34.8993863,9.41030137 35.9014685,10.161863 C36.9834411,10.973589 38.4658521,12.086137 38.4658521,14.3053151 C38.4658521,16.5235068 36.9834411,17.6350685 35.9014685,18.4458082 C34.8993863,19.1973699 34.5206466,19.5445479 34.5206466,20.2201644 C34.5206466,21.0368219 33.8578521,21.6996164 33.0411945,21.6996164" fill={theme.secondaryColor}></path>
              <path d="M42.9042082,21.6996164 C42.0875507,21.6996164 41.4247562,21.0368219 41.4247562,20.2201644 C41.4247562,18.0009863 42.9071671,16.8894247 43.990126,16.0786849 C44.9912219,15.3271233 45.3699616,14.9809315 45.3699616,14.3053151 C45.3699616,13.6287123 44.9912219,13.2805479 43.9891397,12.5289863 C42.9071671,11.7172603 41.4247562,10.6047123 41.4247562,8.38553425 C41.4247562,6.16635616 42.9071671,5.05479452 43.9891397,4.24306849 C44.9912219,3.49150685 45.3699616,3.14334247 45.3699616,2.46673973 C45.3699616,1.65008219 46.0327562,0.987287671 46.8494137,0.987287671 C47.6660712,0.987287671 48.3288658,1.65008219 48.3288658,2.46673973 C48.3288658,4.68690411 46.8464548,5.79846575 45.7634959,6.61019178 C44.7624,7.36175342 44.3836603,7.70893151 44.3836603,8.38553425 C44.3836603,9.06213699 44.7624,9.41030137 45.7644822,10.161863 C46.8464548,10.973589 48.3288658,12.086137 48.3288658,14.3053151 C48.3288658,16.5235068 46.8464548,17.6350685 45.7644822,18.4458082 C44.7624,19.1973699 44.3836603,19.5445479 44.3836603,20.2201644 C44.3836603,21.0368219 43.7208658,21.6996164 42.9042082,21.6996164" fill={theme.secondaryColor}></path>
              <path d="M43.2345205,38.8491288 C41.3871781,37.4328 38.7330411,37.7701151 37.3048767,39.602663 L35.0136986,42.5418411 L35.0363836,42.5714301 L32.7225205,39.602663 C31.2953425,37.7701151 28.6402192,37.4328 26.7928767,38.8491288 C24.9455342,40.2654575 24.6052603,42.8969096 26.0334247,44.7294575 L29.6709041,49.3966356 L33.057863,53.7412932 C33.4957808,54.3439233 34.2069041,54.7404164 35.0136986,54.7404164 C35.8204932,54.7404164 36.5316164,54.3439233 36.9695342,53.7412932 L40.3555068,49.3966356 L40.3564932,49.3966356 L43.9939726,44.7294575 C45.422137,42.8969096 45.081863,40.2654575 43.2345205,38.8491288" fill={theme.secondaryColor}></path>
            </g>
            <path d="M131.0293,30.6572 L131.0303,30.6612 C131.0303,30.6602 131.0303,30.6592 131.0293,30.6572 L131.0293,30.6572 Z M122.0473,30.6592 C122.0473,30.6612 122.0473,30.6622 122.0463,30.6632 L122.0473,30.6592 Z M126.5383,31.1082 C126.8543,31.1082 127.3243,31.1572 127.7663,31.3902 L128.1453,31.5892 L128.0733,31.1672 C127.9363,30.3792 128.2543,29.3952 128.8293,28.8332 L129.1363,28.5332 L128.7163,28.4712 C127.9213,28.3562 127.0853,27.7502 126.7273,27.0282 L126.5383,26.6442 L126.3483,27.0282 C125.9933,27.7492 125.1583,28.3562 124.3633,28.4712 L123.9393,28.5332 L124.2453,28.8312 C124.8223,29.3932 125.1413,30.3772 125.0033,31.1702 L124.9313,31.5892 L125.3093,31.3892 C125.7573,31.1572 126.2233,31.1082 126.5383,31.1082 L126.5383,31.1082 Z M129.3863,32.2412 L129.3973,32.2412 L129.3863,32.2412 Z M129.1703,35.2002 C128.7693,35.2002 128.3733,35.0972 127.9903,34.8962 L126.5383,34.1342 L125.0863,34.8972 C124.0803,35.4242 122.8933,35.2182 122.2273,34.4272 C121.7863,33.9022 121.6123,33.2072 121.7373,32.4692 L122.0143,30.8462 L120.8413,29.7022 C120.1593,29.0352 119.9143,28.1432 120.1823,27.3142 C120.4543,26.4802 121.1773,25.9022 122.1183,25.7652 L123.7433,25.5302 L124.4693,24.0572 C124.8893,23.2052 125.6633,22.6952 126.5383,22.6952 C127.4153,22.6952 128.1883,23.2062 128.6093,24.0612 L129.3343,25.5302 L130.9573,25.7652 C131.9013,25.9032 132.6253,26.4822 132.8943,27.3152 C133.1643,28.1392 132.9203,29.0342 132.2383,29.7002 L131.0613,30.8452 L131.3393,32.4652 C131.4653,33.2002 131.2933,33.8952 130.8543,34.4212 C130.4353,34.9182 129.8243,35.2002 129.1703,35.2002 L129.1703,35.2002 Z" fill={theme.secondaryColor}></path>
            <path d="M58.5,21.2002 C57.672,21.2002 57,20.5282 57,19.7002 L57,13.7002 C57,12.8722 57.672,12.2002 58.5,12.2002 C59.328,12.2002 60,12.8722 60,13.7002 L60,19.7002 C60,20.5282 59.328,21.2002 58.5,21.2002" fill={theme.secondaryColor}></path>
            <path d="M61.5,18.2002 L55.5,18.2002 C54.672,18.2002 54,17.5282 54,16.7002 C54,15.8722 54.672,15.2002 55.5,15.2002 L61.5,15.2002 C62.328,15.2002 63,15.8722 63,16.7002 C63,17.5282 62.328,18.2002 61.5,18.2002" fill={theme.secondaryColor}></path>
            <path d="M222.5,35.2002 C221.672,35.2002 221,34.5282 221,33.7002 L221,27.7002 C221,26.8722 221.672,26.2002 222.5,26.2002 C223.328,26.2002 224,26.8722 224,27.7002 L224,33.7002 C224,34.5282 223.328,35.2002 222.5,35.2002" fill={theme.secondaryColor}></path>
            <path d="M225.5,32.2002 L219.5,32.2002 C218.672,32.2002 218,31.5282 218,30.7002 C218,29.8722 218.672,29.2002 219.5,29.2002 L225.5,29.2002 C226.328,29.2002 227,29.8722 227,30.7002 C227,31.5282 226.328,32.2002 225.5,32.2002" fill={theme.secondaryColor}></path>
            <path d="M79.5,25.7002 C79.5,26.8042 78.604,27.7002 77.5,27.7002 C76.396,27.7002 75.5,26.8042 75.5,25.7002 C75.5,24.5962 76.396,23.7002 77.5,23.7002 C78.604,23.7002 79.5,24.5962 79.5,25.7002" fill={theme.secondaryColor}></path>
            <path d="M205.5,11.2002 C204.121,11.2002 203,12.3212 203,13.7002 C203,15.0792 204.121,16.2002 205.5,16.2002 C206.879,16.2002 208,15.0792 208,13.7002 C208,12.3212 206.879,11.2002 205.5,11.2002 M205.5,19.2002 C202.468,19.2002 200,16.7322 200,13.7002 C200,10.6682 202.468,8.2002 205.5,8.2002 C208.532,8.2002 211,10.6682 211,13.7002 C211,16.7322 208.532,19.2002 205.5,19.2002" fill={theme.secondaryColor}></path>
          </g>
        </g>
      </g>
    </svg>
  );
  /* eslint-enable */
}

SvgSigninEvening.propTypes = {
  theme: PropTypes.object.isRequired,
};

export default withTheme(SvgSigninEvening);
