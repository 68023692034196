import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '../../storybook/src/utils/theme';

function SvgFlag({ theme }) {
  /* eslint-disable */
  return (
    <svg aria-hidden="true" focusable="false" width="63px" height="72px" viewBox="0 0 63 72" version="1.1" className="svg-icon svg-flag">
      <defs>
        <polygon id="path-1" points="0 72 62.8 72 62.8 0 0 0"></polygon>
      </defs>
      <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Tiemout-1" transform="translate(-465.000000, -146.000000)">
          <g id="Group" transform="translate(465.000000, 146.000000)">
            <path d="M1.5,72 C0.672,72 0,71.328 0,70.5 L0,1.5 C0,0.672 0.672,0 1.5,0 C2.328,0 3,0.672 3,1.5 L3,70.5 C3,71.328 2.328,72 1.5,72" id="Fill-1" fill={theme.mainColor} ></path>
            <g id="Clip-4"></g>
            <polygon id="Fill-3" fill={theme.secondaryColor} points="20.688 27.778 30.244 27.778 30.244 18.222 20.688 18.222"></polygon>
            <path d="M22.188,26.278 L28.744,26.278 L28.744,19.722 L22.188,19.722 L22.188,26.278 Z M30.244,29.278 L20.688,29.278 C19.86,29.278 19.188,28.606 19.188,27.778 L19.188,18.223 C19.188,17.395 19.86,16.723 20.688,16.723 L30.244,16.723 C31.072,16.723 31.744,17.395 31.744,18.223 L31.744,27.778 C31.744,28.606 31.072,29.278 30.244,29.278 Z" id="Fill-5" fill={theme.secondaryColor} ></path>
            <path d="M61.2998,0 L15.9108,0 L6.3558,0 C5.5278,0 4.8558,0.672 4.8558,1.5 L4.8558,13.445 C4.8558,14.273 5.5278,14.945 6.3558,14.945 L15.9108,14.945 C16.7388,14.945 17.4108,14.273 17.4108,13.445 L17.4108,3 L33.8208,3 C33.6358,3.249 33.5228,3.556 33.5228,3.89 L33.5228,13.445 C33.5228,14.273 34.1948,14.945 35.0228,14.945 L44.5768,14.945 C45.4048,14.945 46.0768,14.273 46.0768,13.445 L46.0768,3.89 C46.0768,3.556 45.9638,3.249 45.7788,3 L59.7998,3 L59.7998,17.021 C59.5498,16.836 59.2448,16.723 58.9108,16.723 L49.3558,16.723 C48.5278,16.723 47.8558,17.395 47.8558,18.223 L47.8558,27.778 C47.8558,28.606 48.5278,29.278 49.3558,29.278 L58.9108,29.278 C59.2448,29.278 59.5498,29.165 59.7998,28.98 L59.7998,43 L45.7788,43 C45.9638,42.751 46.0768,42.445 46.0768,42.111 L46.0768,32.556 C46.0768,31.728 45.4048,31.056 44.5768,31.056 L35.0228,31.056 C34.1948,31.056 33.5228,31.728 33.5228,32.556 L33.5228,42.111 C33.5228,42.445 33.6358,42.751 33.8208,43 L17.1118,43 C17.2978,42.749 17.4108,42.441 17.4108,42.111 L17.4108,32.556 C17.4108,31.728 16.7388,31.056 15.9108,31.056 L6.3558,31.056 C5.5278,31.056 4.8558,31.728 4.8558,32.556 L4.8558,44.5 C4.8558,44.611 4.8698,44.719 4.8928,44.823 C4.8998,44.854 4.9108,44.882 4.9198,44.911 C4.9408,44.984 4.9638,45.057 4.9948,45.125 C5.0118,45.159 5.0318,45.191 5.0498,45.225 C5.0828,45.284 5.1178,45.341 5.1578,45.395 C5.1828,45.428 5.2088,45.459 5.2368,45.49 C5.2808,45.54 5.3288,45.586 5.3788,45.63 C5.3978,45.646 5.4128,45.667 5.4328,45.683 C5.4428,45.689 5.4528,45.692 5.4618,45.699 C5.5768,45.784 5.7038,45.854 5.8408,45.903 C5.8658,45.913 5.8918,45.919 5.9168,45.927 C6.0568,45.97 6.2018,46 6.3558,46 L61.2998,46 C62.1278,46 62.7998,45.328 62.7998,44.5 L62.7998,1.5 C62.7998,0.672 62.1278,0 61.2998,0" id="Fill-6" fill={theme.secondaryColor} ></path>
          </g>
        </g>
      </g>
    </svg>
  );
  /* eslint-enable */
}

SvgFlag.propTypes = {
  theme: PropTypes.object.isRequired,
};

export default withTheme(SvgFlag);
