/**
 * Created by moran on 6/27/17.
 */

import _ from 'lodash';
import { combineReducers } from 'redux';

import genericReducer from '../genericReducer';
import { GET_TAXONOMIES_LIST } from './actions';

const taxonomies = genericReducer(GET_TAXONOMIES_LIST, {}, payload => ({
  ...payload,
  grouped: _.groupBy(payload.data)
}), { keepStateOnStart: true });

export default combineReducers({ taxonomies });
