/**
 * Created by matan on 7/11/16.
 */
import Promise from 'bluebird';
import _ from 'lodash';

function snippetChecks(apiKey, systemName) {
  if (_.isEmpty(apiKey)) {
    return Promise.reject(new Error(`Can not init ${systemName} without an api key!`));
  } else if (typeof document === 'undefined') {
    return Promise.reject(new Error(`document was not found, can only init ${systemName} on the` +
      'client side!'));
  }

  return Promise.resolve();
}

function loadScript(src) {
  return new Promise(resolve => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = src;
    script.onload = script.onreadystatechange = () => { // eslint-disable-line no-multi-assign
      resolve();
    };

    const s = document.getElementsByTagName('script')[0];
    s.parentNode.insertBefore(script, s);
  });
}

/**
 * Init snap engage.
 * returns a promise with the snap engage api as the result
 * NOTE: This method uses document & window, and can only be called on the client side
 * @param apiKey - snap engage api key
 * @returns {Promise}
 */
export function snapEngage(apiKey, onMessageCounterChange) {
  return snippetChecks(apiKey, 'Snap Engage')
    .then(() => loadScript(`//storage.googleapis.com/code.snapengage.com/js/${apiKey}.js`))
    .then(() => {
      const { SnapEngage } = window;
      if (typeof SnapEngage === 'undefined') {
        throw new Error('SnapEngage had failed to initialize for an unknown reason');
      }

      // TODO: Replace this code with something less hacky once SnapEngage stop sucking so much
      const mobileView = _.get(SnapEngage.getMobileUI(), 'buttonView.main.buttonView');
      if (!_.isNil(mobileView)) {
        const wrap = functionName => {
          const original = mobileView[functionName];

          mobileView[functionName] = function wrapped() {
            original.call(this);
            onMessageCounterChange(this.notifyCount);
          };
        };

        wrap('incrementNotify');
        wrap('hideNotify');
      }

      return SnapEngage; // eslint-disable-line no-undef
    });
}

/**
 * Init Help scout beacon using the api key and base url provided
 * return a promise with the beacon control object
 * NOTE: This method uses document & window, and can only be called on the client side
 * @param apiKey - Help Scout API key
 * @param src - HelpScout script url
 * @param baseUrl - Docs base URL
 * @returns {Promise}
 */
export function initHelpScout(apiKey, src, baseUrl) {
  // If already initialized, return existing control object.
  if (_.get(window, 'HS.beacon')) {
    return Promise.resolve(window.HS.beacon);
  }

  return snippetChecks(apiKey, 'Help Scout')
    .then(() => {
      window.HSCW = {
        config: {
          docs: { enabled: true, baseUrl: `//${baseUrl}/` },
          contact: { enabled: true, formId: apiKey },
          modal: true
        }
      };
      window.HS = {
        beacon: {
          userConfig: {},
          readyQueue: [],
          config: function helpScoutConfig(e) {
            this.userConfig = e;
          },
          ready: function helpScoutOnReady(e) {
            this.readyQueue.push(e);
          }
        }
      };
    })
    .then(() => loadScript(src))
    .then(() => new Promise(resolve => window.HS.beacon.ready(function resolveBeacon() {
      resolve(this);
    })));
}

export function siftScience(apiKey) {
  return loadScript('//cdn.siftscience.com/s.js')
    .then(() => {
      const _sift = window._sift = window._sift || []; // eslint-disable-line no-multi-assign
      _sift.push(['_setAccount', apiKey]);
    });
}

export function loadGooglePlacesAutocomplete(apiKey) {
  if (!_.get(window, 'google.maps')) {
    return snippetChecks(apiKey, 'Google places auto complete api')
      .then(() => loadScript(`https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`))
      .then(() => {
        const { google: { maps } } = window;
        if (typeof maps === 'undefined') {
          throw new Error('Google api for places auto complete failed to initialize');
        }
        return true;
      });
  }
  return Promise.resolve(true);
}

export function initRecaptchaApi() {
  return !_.isNil(window.grecaptcha) ? Promise.resolve(true) :
    loadScript('https://www.google.com/recaptcha/api.js')
      .delay(3000) // ReCaptcha takes some time to load (and it's async...)
      .return(true);
}

export function loadSdkScript() {
  return loadScript('https://js.getvim.com/web/v1.6/sdk.js').return(true);
}
