import { compose, setDisplayName, setPropTypes, withHandlers } from 'recompose';
import { get, toNumber, find } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { defineMessages, FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import injectNotification from '../../store/notification/injectNotification';

import { FlowShape } from '../../components/wizard/flow/shapes';
import ProviderSearch from '../../components/providerSelection/providerSearch';
import Api from '../../api';
import { withFetchers } from '../../api/injectApi/withFetchers';
import PcpSelectionSearch from '../../components/pcpSelection/components/pcpSelectionSearch';
import {
  createToggleState,
  createToggleStatePropTypes,
} from '../../components/recompose/toggleStateComponent';
import PCPSelectionActions from '../../components/pcpSelection/components/pcpSelectionActions';
import ClickableDiv from '../../components/clickableElement/clickableDiv';
import { chooseClientLocation } from '../../apps/members/src/directory/directorySelectors';

const messages = defineMessages({
  chooseADoctorYou: {
    defaultMessage: 'Choose a doctor you’ll ',
    id: 'members.selectPcp.chooseADoctorYou',
  },
  love: {
    defaultMessage: 'love',
    id: 'members.selectPcp.love',
  },
  wantToSelectSomeoneElse: {
    defaultMessage: 'Want to select someone else?',
    id: 'members.selectPcp.wantToSelectSomeoneElse',
  },
});

const getOnSelectPCP = (updatePcpSelection, member) => (
  provider,
  selectedLocation,
) => {
  updatePcpSelection(
    provider,
    toNumber(selectedLocation),
    member,
  );
};

function selectPCP({
  updatePcpSelection,
  pcpSearchModalShow,
  pcpSearchModalHide,
  pcpSearchModalOn,
  control,
  limitConfig,
  getPcpSelections,
  clientLocation,
}) {
  return (
    <ProviderSearch
      limitConfig={limitConfig}
      control={control}
      getProvidersApi={getPcpSelections}
      genderFilterOn
      distanceFilterOn
      renderTitle={() => (
        <h1 className="title-24 title-brand-1 margin-30 padding-h-30">
          <FormattedMessage {...messages.chooseADoctorYou} />
          <span className="font-color-brand-accent">
            <i className="icon-heart-vim i-va-fix-2" />
            <span className="off-screen-text">
              <FormattedMessage {...messages.love} />
            </span>
          </span>
        </h1>
      )}
      renderSearchArea={member => (
        <div className="row no-margin">
          <div className="col-md-6 col-md-offset-3 col-lg-4 col-lg-offset-4 provider-selection-input">
            <PcpSelectionSearch
              updatePcpSelection={getOnSelectPCP(updatePcpSelection, member)}
              memberPlan={member.plan}
              memberContext={member.memberContext}
              pcpSearchModalHide={pcpSearchModalHide}
              pcpSearchModalOn={pcpSearchModalOn}
              clientLocation={clientLocation}
            />
          </div>
        </div>
        )}
      renderBottomArea={() => (
        <div className="margin-top-20">
          <ClickableDiv
            className="font-color-brand-link underline-link hidden-lg"
            onClick={pcpSearchModalShow}
          >
            <FormattedMessage {...messages.wantToSelectSomeoneElse} />
          </ClickableDiv>
        </div>
      )}
      renderActions={member => (provider, selectedLocation, onLocationChanged) => (
        <PCPSelectionActions
          plan={member.plan}
          provider={provider}
          memberContext={member.memberContext}
          onLocationChanged={onLocationChanged}
          selectedLocation={selectedLocation.id}
          onUpdatePcpSelection={getOnSelectPCP(updatePcpSelection, member)}
          clientLocation={clientLocation}
        />
      )}
    />
  );
}

selectPCP.propTypes = {
  ...createToggleStatePropTypes('pcpSearchModal'),
};
/**
 * Input: non
 * Output:
 * * Result: NEXT
 * * Returned value: answer
 */
export default compose(
  injectNotification,
  setDisplayName('SelectPCP'),
  connect(state => ({
    limitConfig: get(state, 'config.publicPcpSelection.limit'),
    apiKey: get(state, 'config.schedulingSdkKeys.sdkKey'),
    clientLocation: chooseClientLocation(state),
  })),
  withFetchers({
    updatePcpSelection: {
      handler: () => (provider, selectedLocation, member) => {
        const geo = find(provider.locations, 'geo');
        return Api.pcpSelectionApi
          .updatePcpSelection({
            npi: provider.npi,
            locationId: selectedLocation,
            geo,
          })
          .then(() => ({ provider, selectedLocation, member }));
      },
      onSuccess: ({ control }, { provider, selectedLocation, member }) =>
        control.next({
          payload: {
            provider,
            member,
            selectedLocation,
          },
        }),
      onError: ({ notification, member }, error) => {
        notification.error('Failed in choosing a doctor, please retry', error.message, {
          member, // TODO: split member fetching from pcps so we will have it in here
          error,
        });
      },
    },
  }),
  withHandlers({
    getPcpSelections: ({ apiKey }) => query => Api.pcpSelectionApi.searchPCPs(query, apiKey),
  }),
  setPropTypes({
    control: FlowShape.isRequired,
    updatePcpSelection: PropTypes.func.isRequired,
  }),
  createToggleState('pcpSearchModal'),
)(selectPCP);
