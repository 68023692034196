import '@babel/polyfill';

import {
  PREMERA_BLUE_CROSS_ALASKA,
  ANTHEM,
  UNITED_HEALTH_CARE_ALLSAVERS,
  FLORIDA_BLUE,
  VIM,
} from 'Model/enum/bkmdDomain';

// THIS SHOULD BE BEFORE OTHER IMPORTS!
// We are adding default HOCs to all the withFetchers,
// and if the component will be imported before this it will not work
import './api/configureApi';

import start from './start';

const { DOMAIN, NODE_ENV } = process.env;
const getTheme = function getTheme(domain) {
  if (NODE_ENV !== 'production') {
    switch (domain) {
      case PREMERA_BLUE_CROSS_ALASKA:
        return import(/* webpackChunkName: "premera" */ `../../../theme/premera.less`);
      case UNITED_HEALTH_CARE_ALLSAVERS:
        return import(/* webpackChunkName: "united" */ `../../../theme/united.less`);
      case ANTHEM:
        return import(/* webpackChunkName: "anthem" */ `../../../theme/anthem.less`);
      case FLORIDA_BLUE:
        return import(/* webpackChunkName: "sanitas" */ `../../../theme/sanitas.less`);
      case VIM:
      default:
        return import(/* webpackChunkName: "vim" */ `../../../theme/vim.less`);
    }
  } else {
    return Promise.resolve();
  }
};

getTheme(DOMAIN).then(() => {
  if (!global.Intl && typeof require.ensure === 'function') {
    require.ensure(['intl', 'intl/locale-data/jsonp/en.js'], require => {
      require('intl');
      require('intl/locale-data/jsonp/en.js');
      start();
    });
  } else {
    start();
  }
});
