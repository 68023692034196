import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'formik';
import { WrappedFormik } from '../../form/formik/wrappedFormik';
import SpinnerButton from '../../../components/ui/spinner/spinnerButton';
import { CleanFormikPhoneInput } from '../../form/formik/cleanFormikPhoneInput';

const MemberPhoneView = ({ memberPhone, hintComponent, onSubmit, onSubmitTracker, messages }) => (
  <div>
    <WrappedFormik
      initialValues={{ memberPhone: _.defaultTo(memberPhone, undefined) }}
      onSubmit={onSubmit}
      render={data => (
        <Form>
          <div className="row">
            <div className="col-xs-12 text-16 text-dark margin-bottom-clean-form text-center">
              {messages.details}
            </div>
          </div>

          <div className="row">
            <div className="col-xs-12">
              <Field
                name="memberPhone"
                component={CleanFormikPhoneInput}
                label={messages.mobileNum}
                autoComplete="tel"
              />
              {_.isFunction(hintComponent) && hintComponent()}
            </div>
          </div>

          <div className="row">
            <div className="col-xs-12">
              <SpinnerButton
                isLoading={onSubmitTracker.inProgress}
                className="btn btn-big btn-block"
                type="submit"
                disabled={!data.isValid}
              >
                {messages.next}
              </SpinnerButton>
            </div>
          </div>
        </Form>
      )}
    />
  </div>
);
MemberPhoneView.propTypes = {
  memberPhone: PropTypes.object,
  /**
   * hintComponent is a general component that should be used to hint about data of the phone,
   * like what date that phone is from...
   */
  hintComponent: PropTypes.func,
  messages: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onSubmitTracker: PropTypes.object.isRequired,
};
MemberPhoneView.defaultProps = {
  memberPhone: undefined,
  hintComponent: undefined,
};

export default MemberPhoneView;
