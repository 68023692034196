import { compose } from 'recompose';
import { defineMessages } from 'react-intl';
import _ from 'lodash';
import { withFetchers } from '../../../../api/injectApi/withFetchers';
import Api from '../../../../api';
import * as Errors from '../../../../api/errors';
import injectNotification from '../../../../store/notification/injectNotification';
import queryConnect from '../../../../store/tools/queryString/queryConnect';
import { withTranslatedMessages } from '../../../../utils/withTranslatedMessages';
import MemberValidation from '../../../../storybook/src/components/pages/MemberValidation/MemberValidation';
import withAppAnalyticsHandlers from '../../../../store/tools/analytics/withAppAnalyticsHandlers';
import SearchActionEvents from '../../../../store/tools/analytics/events/searchActionEvents';

const messages = defineMessages({
  errorMessage: {
    defaultMessage: 'Error occurred while submitting details',
    id: 'scheduling.memberValidation.error',
  },
  recaptchErrorMessage: {
    defaultMessage: "Please confirm you're not a bot",
    id: 'scheduling.memberValidation.recaptchError',
  },
});

const DirectoryMemberValidation = compose(
  withAppAnalyticsHandlers(),
  queryConnect(({ token, correlationId }) => ({ token, correlationId })),
  injectNotification,
  withTranslatedMessages(messages),
  withFetchers({
    onSubmit: {
      handler: ({ token }) => ({ firstName, lastName, memberId, dateOfBirth }) =>
        Api.membersApi.urlAuthentication({ firstName, lastName, memberId, dateOfBirth, token }),
      onSuccess: (props, { url }) => {
        const targetUrl =
          _.includes(url, 'https://') || _.includes(url, 'http://') ? url : `https://${url}`;
        window.location.replace(targetUrl);
      },
      onError: (
        { notification, errorMessage, recaptchErrorMessage, analytics, correlationId },
        error,
      ) => {
        analytics.track(...SearchActionEvents.SearchShareAuthFailed({ correlationId, error }));
        if (error.responseMessage === Errors.RECAPTCHA_FAILED)
          return notification.error('', recaptchErrorMessage);
        return notification.error('', errorMessage);
      },
    },
  }),
)(MemberValidation);

export default DirectoryMemberValidation;
