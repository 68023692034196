/**
 * Created by chenrozenes on 30/03/2017.
 */

import React from 'react';
import PropTypes from 'prop-types';
import ProgressLayout from './progressLayout';
import IntlString from '../intlString/intlString';

import './simpleProgressPage.less';

export default function SimpleProgressPage({
  title,
  progress,
  img,
  caption,
  children,
  showLogos,
  externalPageLogo,
  showRightNavInMobile,
}) {
  return (
    <ProgressLayout
      className="progress-layout"
      contentClassName="content"
      title={title}
      progress={progress}
      showLogos={showLogos}
      externalPageLogo={externalPageLogo}
      showRightNavInMobile={showRightNavInMobile}
    >
      <div className="simple-layout-fixed-wrapper">
        <div className="simple-layout-content">
          <div className="top-section">
            <div className="row">
              <div className="col-xs-12 text-center">{img}</div>
            </div>
            <div className="row">
              <div className="col-xs-12 text-center">
                <h1 className="title-24 md-margin title-brand-1">
                  <IntlString message={caption} />
                </h1>
              </div>
            </div>
          </div>
          {children}
        </div>
      </div>
    </ProgressLayout>
  );
}

SimpleProgressPage.propTypes = {
  title: PropTypes.string.isRequired,
  progress: PropTypes.number.isRequired,
  caption: PropTypes.string.isRequired,
  img: PropTypes.string.isRequired,
  children: PropTypes.object.isRequired,
  showLogos: PropTypes.bool,
  externalPageLogo: PropTypes.bool,
  showRightNavInMobile: PropTypes.bool,
};

SimpleProgressPage.defaultProps = {
  showLogos: true,
  externalPageLogo: false,
  showRightNavInMobile: false,
};
