import React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { defineMessages, FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { autobind } from 'core-decorators';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { member, memberContext } from 'Store/member/selectors';
import { getDomain } from 'Store/feature/selectors';

import Utils from '../../../../utils/util';
import SvgDoctorCircle from '../../../../assets/svg/doctorCircle';
import DoctorLogo from '../../../../components/doctorImage/doctorLogo';
import { displayProviderObj } from '../../../../utils/displayProvider';
import ProviderLocationSelector from '../../../../components/doctorDetails/sections/providerLocationSelector';
import Formatter from '../../../../utils/formatter';
import { messages } from './medicalTeam';
import commonMessages from '../../../../store/intl/commonMessages';
import { chooseClientLocation } from '../directory/directorySelectors';
import { clientLocationShape } from '../../../../components/searchDoctors/clientLocationShape';
import { MALE } from '../../../../model/enum/gender';
import withBookingModal from '../../../../components/bookingModal/withBookingModal';
import getProviderPhoneNumber from '../../../../utils/getProviderPhoneNumber';
import * as NpiType from '../../../../model/enum/npiType';
import BookingButton from '../directory/components/bookButton/bookButton';

const messageValues = defineMessages({
  noName: {
    defaultMessage: 'your kid',
    id: 'members.pcpSelection.pcpCard.noName',
  },
});

const FAMILY_PHYSICAIN_CODE = '207Q00000X';

@autobind
class PcpCard extends React.PureComponent {
  static propTypes = {
    intl: intlShape.isRequired,
    clientLocation: PropTypes.shape(clientLocationShape).isRequired,
    onPcpSelectionFlow: PropTypes.func.isRequired,
    onViewPcpDetails: PropTypes.func.isRequired,
    onAssignAllChildren: PropTypes.func,
    hasMinorDependents: PropTypes.bool,
    pcp: PropTypes.object,
    pcpLocationId: PropTypes.number,
    dependent: PropTypes.object,
  };

  static defaultProps = {
    pcp: {},
    pcpLocationId: null,
    dependent: {},
    hasMinorDependents: false,
    onAssignAllChildren: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      currentLocation: this._findLocationById(props.pcpLocationId),
    };
  }

  onBookClick() {
    const { onBookClick, pcp } = this.props;
    const { currentLocation } = this.state;
    onBookClick(pcp, currentLocation.id, currentLocation.bookingType);
  }

  onLocationChanged(locationId) {
    this.setState({ currentLocation: this._findLocationById(locationId) });
  }

  isDependent() {
    const { dependent } = this.props;
    return !_.isEmpty(dependent);
  }

  viewPcpDetails() {
    const { onViewPcpDetails, pcp } = this.props;
    const { currentLocation } = this.state;
    onViewPcpDetails(pcp.npi, currentLocation.id);
  }

  pcpSelectionFlow() {
    const { onPcpSelectionFlow, dependent } = this.props;
    const id = this.isDependent() ? dependent.id : '';

    onPcpSelectionFlow(id);
  }

  _findLocationById(locationId = null) {
    const { pcp } = this.props;
    // No pcp:
    if (_.isEmpty(pcp)) return {};
    const location = _.find(pcp.locations, loc => loc.id === locationId);
    // locationId is valid:
    if (!_.isEmpty(location)) return location;
    // Fallback:
    return pcp.locations[0];
  }

  renderPcp() {
    const { pcp, hasMinorDependents, onAssignAllChildren, member, memberContext } = this.props;
    const { sources, taxonomies, npi } = pcp;
    const { currentLocation } = this.state;
    const otherLocations = _.filter(pcp.locations, loc => loc.id !== currentLocation.id);
    const isDependent = this.isDependent();
    const pcpDetailsButtonClass = 'btn btn-big btn-outline margin-top-30';
    const isPrimaryCareDoctor = _.some(pcp.taxonomies, { code: FAMILY_PHYSICAIN_CODE });
    const phoneNumber = getProviderPhoneNumber(currentLocation, memberContext);
    const taxonomyName = Formatter.truncate(Formatter.taxonomy(sources, taxonomies));
    return (
      <div className="row pcp-details">
        <div className="col-sm-6 right-border text-center">
          <DoctorLogo provider={pcp} />
          <div className="text-18 text-dark text-semibold margin-top-20">
            {displayProviderObj(pcp)}
          </div>
          <div className="text-14">{taxonomyName}</div>
          <div>
            <ProviderLocationSelector
              staticLocation
              pickedLocation={currentLocation}
              otherLocations={otherLocations}
              onLocationChanged={this.onLocationChanged}
            />
          </div>
          <div className="text-14 text-dark margin-top-20">
            {Formatter.phoneNumber(phoneNumber)}
          </div>
        </div>

        <div className="col-sm-6 text-center">
          <div className="pcp-buttons">
            <div className="col-xs-6 booking-btn-wrap">
              <BookingButton
                onBookAppointmentClicked={this.onBookClick}
                pickedLocation={currentLocation}
                npiType={NpiType.INDIVIDUAL}
                targetNpi={npi}
                targetLocationAddress={Formatter.addressFormat(currentLocation)}
                targetLocationGeo={currentLocation.geo}
                targetLocationPhone={currentLocation.phoneNumber}
                bookingType={currentLocation.bookingType}
                member={member}
                btnClassName="booking-btn"
              />
            </div>
            <button className={pcpDetailsButtonClass} onClick={this.viewPcpDetails}>
              <FormattedMessage {...messages.viewDetails} />
            </button>
            <button
              className="btn btn-big btn-outline margin-top-30"
              onClick={this.pcpSelectionFlow}
            >
              {isDependent ? (
                <FormattedMessage {...messages.changeMyChildDoctor} />
              ) : (
                <FormattedMessage {...messages.changeMyDoctor} />
              )}
            </button>
            {!isDependent && hasMinorDependents && isPrimaryCareDoctor ? (
              <button
                className="btn btn-big btn-outline margin-top-30"
                onClick={onAssignAllChildren}
              >
                <FormattedMessage {...messages.assignForAllDependents} />
              </button>
            ) : null}
          </div>
        </div>
      </div>
    );
  }

  renderNoPcp() {
    const { dependent, intl } = this.props;
    const defaultName = intl.formatMessage(messageValues.noName);
    const firstName = _.get(dependent, 'firstName', defaultName);
    const gender = _.get(dependent, 'gender', MALE);
    const genderPrep = intl.formatMessage(
      Utils.isMale(gender) ? commonMessages.him : commonMessages.her,
    );

    return (
      <div className="row pcp-details">
        <div className="col-sm-6 right-border">
          <SvgDoctorCircle />
          <div className="text-semibold">
            <FormattedMessage
              {...messages.beReady}
              values={{
                firstName,
                genderPrep,
              }}
            />
          </div>
        </div>
        <div className="col-sm-6 text-center">
          <div className="pcp-buttons">
            <button className="btn btn-big bg-color-brand-button" onClick={this.pcpSelectionFlow}>
              <FormattedMessage {...messages.chooseADoctor} />
            </button>
          </div>
        </div>
      </div>
    );
  }

  renderCard() {
    const { pcp } = this.props;
    const isPcpSelected = !_.isEmpty(pcp);

    return isPcpSelected ? this.renderPcp() : this.renderNoPcp();
  }

  render() {
    const { dependent, pcp } = this.props;
    const cardClassName = !_.isEmpty(pcp) ? 'card pcp-card' : 'card unset-pcp-card';

    return (
      <div className={cardClassName}>
        {!this.isDependent() ? (
          <div className="card-title">
            <FormattedMessage {...messages.yourPrimaryCareDoctor} />
          </div>
        ) : (
          <div className="card-title">
            <FormattedMessage {...messages.primaryCareDoctorFor} />
            {dependent.firstName}
          </div>
        )}
        <div className="card-body">{this.renderCard()}</div>
      </div>
    );
  }
}

export default compose(
  injectIntl,
  connect((state, ownProps) => ({
    domain: getDomain(state),
    member: ownProps.member || member(state),
    memberContext: memberContext(state),
    clientLocation: chooseClientLocation(state),
  })),
  withBookingModal(),
)(PcpCard);
