/**
 * Created by galgoltzman on 25/06/2017.
 */
import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { autobind } from 'core-decorators';
import { defineMessages, FormattedMessage } from 'react-intl';
import { sortByDistance } from './facilitySearchResultsSelectors';
import { chooseClientLocation, isLocationReady } from '../directorySelectors';
import { getCurrentLocation } from '../../../../../store/location/actions';
import { getFacilities } from '../../../../../store/facilitySearch/actions';
import { getFeature } from '../../../../../store/feature/selectors';
import * as FacilityTypes from './facilityTypes';
import CardListLayout from '../../layout/cardListLayout/cardListLayout';
import BkmdUtils from '../../../../../utils/formatter';
import SvgImaging from '../../../../../assets/svg/imaging';
import SvgLabs from '../../../../../assets/svg/labs';
import SvgPharmacies from '../../../../../assets/svg/pharmacies';
import SvgHospital from '../../../../../assets/svg/hospital';
import SvgUrgentCare from '../../../../../assets/svg/urgentCare';
import SvgOtherFacilities from '../../../../../assets/svg/otherFacilities';
import fetchNotEmpty from '../../../../../store/tools/fetchNotEmpty';
import './facilitySearchResults.less';

const messages = defineMessages({
  website: {
    defaultMessage: 'Website',
    id: 'members.facilityResults.website',
  },
  Urgent: {
    defaultMessage: 'Urgent care + ERs',
    id: 'members.facilityResults.Urgent',
  },
  Hospitals: {
    defaultMessage: 'Hospitals',
    id: 'members.facilityResults.Hospitals',
  },
  Pharmacies: {
    defaultMessage: 'Pharmacies',
    id: 'members.facilityResults.Pharmacies',
  },
  Imaging: {
    defaultMessage: 'MRIs, CTs + PET scans',
    id: 'members.facilityResults.Imaging',
  },
  Lab: {
    defaultMessage: 'Labs',
    id: 'members.facilityResults.Labs',
  },
  Others: {
    defaultMessage: 'Other providers',
    id: 'members.facilityResults.Others',
  },
  cantFind: {
    defaultMessage: "Can't find what you're looking for?",
    id: 'members.facilityResults.cantFind',
  },
  forMore: {
    defaultMessage: 'For more locations, please visit:',
    id: 'members.facilityResults.forMore',
  },
});

// facility mapping of enum value to image and message
const facilityTypeMap = {};
facilityTypeMap[FacilityTypes.RADIOLOGY] = {
  mainSvg: <SvgImaging />,
  message: messages.Imaging,
};
facilityTypeMap[FacilityTypes.LABORATORY] = {
  mainSvg: <SvgLabs />,
  message: messages.Lab,
};
facilityTypeMap[FacilityTypes.PHARMACY] = {
  mainSvg: <SvgPharmacies />,
  message: messages.Pharmacies,
};
facilityTypeMap[FacilityTypes.HOSPITAL] = {
  mainSvg: <SvgHospital />,
  message: messages.Hospitals,
};
facilityTypeMap[FacilityTypes.URGENT_CARE] = {
  mainSvg: <SvgUrgentCare />,
  message: messages.Urgent,
};
facilityTypeMap[FacilityTypes.OTHERS] = {
  mainSvg: <SvgOtherFacilities />,
  message: messages.Others,
};

const GOOGLE_MAPS_URL = 'http://maps.google.com/maps';

const openMap = function openMap(e, facility) {
  e.preventDefault();
  const address = BkmdUtils.formatAddress(facility);
  if (!address) return;
  const win = window.open('', '_blank');
  win.location.assign(`${GOOGLE_MAPS_URL}?q=${address}&z=17`);
};

const renderFacility = function renderFacility(facility) {
  const key = `${BkmdUtils.formatAddress(facility)}${facility.phone}${facility.name}`;
  return (
    <div className="card facility-result" key={key}>
      <div className="card-body">
        <div className="result-distance text-14 text-dark">
          {facility.distance ? `${facility.distance} mi` : ''}
        </div>
        <div className="row">
          <div className="col-xs-12 col-md-5">
            <div className="result-top">
              <div className="result-title text-16 text-semibold text-dark">{facility.name}</div>
              <div className="result-address">
                <i className="icon-map-pin-e" />
                <a
                  href=""
                  className="address-line font-color-brand-link"
                  onClick={e => openMap(e, facility)}
                >
                  {facility.addressLineOne}
                  {facility.addressLineTwo ? <br /> : null}
                  {facility.addressLineTwo}
                  <br />
                  {`${facility.city}, ${facility.state}, ${facility.zip}`}
                </a>
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-sm-5 col-md-4">
            <div className="card-footer">
              <div className="row footer-content">
                <div className="col-xs-6">
                  <i className="icon-iphone" />
                  <a href={`tel:+1${facility.phone}`} className="font-color-brand-link">
                    {BkmdUtils.formatLocalString('US', facility.phone)}
                  </a>
                </div>
                {facility.website ? (
                  <div className="col-xs-6">
                    <i className="icon-browser" />
                    <a
                      rel="noopender noreferrer"
                      href={facility.website}
                      className="font-color-brand-link"
                    >
                      <FormattedMessage {...messages.website} />
                    </a>
                  </div>
                ) : (
                  <div className="col-xs-6" />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
@autobind
class FacilitySearchResults extends React.PureComponent {
  static propTypes = {
    location: PropTypes.object.isRequired,
    facilities: PropTypes.array,
    getFacilities: PropTypes.func.isRequired,
    moreFacilitiesLink: PropTypes.string.isRequired,
    moreFacilitiesLinkText: PropTypes.string.isRequired,
  };

  static defaultProps = {
    facilities: [],
  };

  componentWillMount() {
    const { location } = this.props;
    this.props.getFacilities(location.query.facilityType);
    this.facilityTypeInfo = facilityTypeMap[location.query.facilityType];
  }

  componentWillReceiveProps(nextProps) {
    const { location } = this.props;
    const { facilityType } = nextProps.location.query;

    if (!_.isEqual(nextProps.location.query.facilityType, location.query.facilityType)) {
      this.facilityTypeInfo = facilityTypeMap[facilityType];
      this.props.getFacilities([facilityType]);
    }
  }

  render() {
    const { facilities, moreFacilitiesLink, moreFacilitiesLinkText } = this.props;
    const displayableFacilities = _.isEmpty(facilities) ? [] : facilities.map(renderFacility);
    const formattedMessage = {
      ...this.facilityTypeInfo.message,
      values: {
        count: displayableFacilities.length > 0 ? displayableFacilities.length : 'no',
      },
    };

    return (
      <div>
        <CardListLayout
          mainIconSrc={this.facilityTypeInfo.mainImg}
          mainSvgComp={this.facilityTypeInfo.mainSvg}
          title={formattedMessage}
          listClassName="facility-results"
          blueHeaderClass="facility-list-header white"
          active="MEDICAL"
        >
          {displayableFacilities}
          <div className="text-center margin-top-30">
            <span className="text-dark">
              <FormattedMessage {...messages.cantFind} />
              <br />
              <FormattedMessage {...messages.forMore} />
              <br />
            </span>
            <a
              href={moreFacilitiesLink}
              rel="noopener noreferrer"
              target="_blank"
              className="font-color-brand-link"
            >
              {moreFacilitiesLinkText}
            </a>
          </div>
        </CardListLayout>
      </div>
    );
  }
}

export default compose(
  withRouter,
  fetchNotEmpty(getCurrentLocation, isLocationReady, { renderAlways: false, loading: true }),
  connect(
    state => ({
      facilities: sortByDistance(state, chooseClientLocation(state).geo),
      moreFacilitiesLink: getFeature(state, 'facilitySearch.link'),
      moreFacilitiesLinkText: getFeature(state, 'facilitySearch.linkText'),
    }),
    { getCurrentLocation, getFacilities },
  ),
)(FacilitySearchResults);
