import _ from 'lodash';
import qs from 'qs';
import queryStringParseOptions from './queryStringParseOptions';

/**
 * Will take you to the newRoute and add a next query string param with the curr location
 * @param router
 * @param location from router
 * @param newRoute
 */
export function routeToWithNext(router, location, newRoute) {
  const redirectAfterLogin = _.get(location, 'pathname');
  const redirectionQS = _.get(location, 'query') ?
    `&qs=${encodeURIComponent(qs.stringify(location.query, queryStringParseOptions))}` : '';

  router.push(`${newRoute}?next=${redirectAfterLogin}${redirectionQS}`);
}
