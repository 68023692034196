import { compose, pure, withHandlers } from 'recompose';
import { defineMessages } from 'react-intl';
import { withTranslatedMessages } from '../../utils/withTranslatedMessages';
import SCR011AddressForm from '../../screenTemplates/SCR011AddressForm';

const messages = defineMessages({
  title: {
    defaultMessage: 'Let us know where we should ship your kit.',
    id: 'colorectalFlow.orderAKitReview.title',
  },
  buttonText: {
    defaultMessage: 'Order',
    id: 'colorectalFlow.orderAKitReview.buttonText',
  },
});

const OrderAKitAddressForm = compose(
  pure,
  withTranslatedMessages(messages),
  withHandlers({
    onButtonClick: ({ control }) => formResult => control.next({ payload: formResult }),
  }),
)(SCR011AddressForm);

export default OrderAKitAddressForm;
