import React from 'react';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { destroy } from 'redux-form';
import * as ActivationForm from '../activationForm';

import actionTracker, {
  actionTrackerShape,
} from '../../../../../store/tools/actionTracker/actionTrackerComponent';
import { memberSignup, SIGNUP } from '../../../../../store/activation/actions';

import Signup from './signup';
import { FlowShape } from '../../../../../components/wizard/flowManager';
import { resetRecaptch } from '../../../../../store/recaptcha/actions';
import injectNotification from '../../../../../store/notification/injectNotification';
import * as Errors from '../../../../../api/errors';
import errorMapping from '../activationErrorMapping';

@autobind
class SignupFlowStep extends React.PureComponent {
  static propTypes = {
    flow: FlowShape,
    memberSignup: PropTypes.func.isRequired,
    destroy: PropTypes.func.isRequired,
    alreadyActivatedModalShow: PropTypes.func.isRequired,
    tracker: actionTrackerShape.isRequired,
    notification: PropTypes.object.isRequired,
    source: PropTypes.string.isRequired,
    activationToken: PropTypes.string.isRequired,
  };

  static defaultProps = {
    flow: undefined,
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.tracker.finished) {
      if (nextProps.tracker.hasError) {
        this.handleError(nextProps.tracker.error);
      } else {
        this.props.flow.next();
      }
    }
  }

  handleError(error) {
    const { responseMessage } = error;
    switch (responseMessage) {
      case Errors.INVALID_PHONE_NUMBER: {
        this.props.notification.error(
          errorMapping[responseMessage].title,
          errorMapping[responseMessage].message,
          { autoDismiss: 10 },
        );
        break;
      }
      case Errors.USER_ALREADY_EXIST: {
        this.props.destroy(ActivationForm.name); // clean the from
        this.props.alreadyActivatedModalShow();
        break;
      }
      default:
        break;
    }
  }

  signup({ email, password }) {
    this.props.memberSignup(this.props.activationToken, email, password, this.props.source);
  }

  render() {
    return <Signup onSubmit={this.signup} loading={this.props.tracker.inProgress} />;
  }
}

export default compose(
  // todo: createToggleState('alreadyActivatedModal'),
  injectNotification,
  connect(
    null,
    { memberSignup, resetRecaptch, destroy },
  ),
  actionTracker({
    tracker: SIGNUP.SOURCE,
  }),
)(SignupFlowStep);
