import _ from 'lodash';
import React from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import BookingButton from '../../directory/components/bookButton/bookButton';
import * as NpiType from '../../../../../model/enum/npiType';
import * as SessionActionTypes from '../../../../../model/enum/sessionActionTypes';
import BookTypeToSessionEntity from '../../../../../model/enum/bookingTypeToSessionEntityType';
import Formatter from '../../../../../utils/formatter';
import Api from '../../../../../api';
import { withFetchers } from '../../../../../api/injectApi/withFetchers';
import queryConnect from '../../../../../store/tools/queryString/queryConnect';

// this component was created to support SDK scheduling in the share-doctors page
// it was created so we don't have to pass implementation-specific props through several components
const ShareButtonWrapper = ({
  member,
  plan,
  pickedLocation,
  onBookAppointmentClicked,
  provider,
  onShowBookingDialogSuccess,
}) => (
  <BookingButton
    enableBooking
    onBookAppointmentClicked={onBookAppointmentClicked}
    pickedLocation={pickedLocation}
    npiType={NpiType.INDIVIDUAL}
    targetNpi={_.get(provider, 'npi')}
    targetLocationAddress={Formatter.addressFormat(pickedLocation)}
    member={member}
    targetLocationGeo={pickedLocation.geo}
    targetLocationPhone={pickedLocation.phoneNumber}
    plan={plan}
    onShowBookingDialogSuccess={onShowBookingDialogSuccess}
  />
);

ShareButtonWrapper.propTypes = {
  member: PropTypes.object,
  plan: PropTypes.object,
  pickedLocation: PropTypes.object.isRequired,
  onBookAppointmentClicked: PropTypes.func.isRequired,
  onShowBookingDialogSuccess: PropTypes.func.isRequired,
  provider: PropTypes.object.isRequired,
};

ShareButtonWrapper.defaultProps = {
  member: undefined,
  plan: undefined,
};

export default compose(
  queryConnect(query => ({
    sessionToken: query.sessionToken,
  })),
  withFetchers({
    onShowBookingDialogSuccess: {
      handler: ({ sessionToken }) => async (entityId, bookingType) =>
        Api.sessionApi.createAction(sessionToken, SessionActionTypes.BOOK_FROM_LANDING, {
          entityType: BookTypeToSessionEntity[bookingType],
          entityId: _.toString(entityId),
        }),
    },
  }),
)(ShareButtonWrapper);
