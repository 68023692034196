/**
 * Created by chenrozenes on 04/04/2017.
 */
import _ from 'lodash';
import PromiseActionType from '../promiseActionType';
import setInQueryString from '../tools/queryString/setInQueryString';
import { convertLocationData } from './converters';

export const UPDATE_MEMBER = new PromiseActionType('UPDATE_MEMBER');
export const GET_MEMBER = new PromiseActionType('GET_MEMBER');
export const GET_FULL_MEMBER_INFO = new PromiseActionType('GET_FULL_MEMBER_INFO');
export const GET_MEMBER_WITH_PLAN = new PromiseActionType('GET_MEMBER_WITH_PLAN');
export const GET_MEMBER_SETTINGS = new PromiseActionType('GET_MEMBER_SETTINGS');
export const CHANGE_NOTIFICATION_PREFERENCES = new PromiseActionType(
  'CHANGE_NOTIFICATION_PREFERENCES',
);
export const CHANGE_LOCATION_PERMISSIONS = new PromiseActionType('CHANGE_LOCATION_PERMISSIONS');
export const CHANGE_ADDRESS = new PromiseActionType('CHANGE_ADDRESS');
export const ADD_INSURANCE_INFO = new PromiseActionType('ADD_INSURANCE_INFO');
export const GET_DRUG_HISTORY = new PromiseActionType('GET_DRUG_HISTORY');
export const GET_MEDICATIONS_SUMMARY = new PromiseActionType('GET_MEDICATION_SUMMARY');
export const GET_CARE_CUES = new PromiseActionType('GET_CARE_CUES');
export const FETCH_ACTIVATION_BY_TOKEN = new PromiseActionType('FETCH_ACTIVATION_BY_TOKEN');
export const UPDATE_MEMBER_STATE = new PromiseActionType('UPDATE_MEMBER_STATE');
export const SET_MEMBER = 'SET_MEMBER';
export const SET_BOOKABLE_PROVIDERS = 'SET_BOOKABLE_PROVIDERS';

let timeoutId;

export const clearGetMemberTimeout = () => timeoutId;

export function setMember(member) {
  return {
    type: SET_MEMBER,
    payload: member,
  };
}

export function updateMember(id, data) {
  return ({ bkmdApi: { membersApi } }) => ({
    type: UPDATE_MEMBER.SOURCE,
    meta: {
      tracker: UPDATE_MEMBER.SOURCE,
    },
    payload: {
      promise: membersApi.update(id, data),
    },
  });
}

export function getMember(shouldReload = false) {
  return ({ dispatch, bkmdApi: { membersApi }, getState }) => {
    if (shouldReload) {
      const pollingTimeout = _.get(getState(), 'config.memberBookingSdkPolling');
      timeoutId = setTimeout(() => dispatch(getMember(shouldReload)), pollingTimeout);
    }

    return {
      type: GET_MEMBER.SOURCE,
      meta: {
        tracker: GET_MEMBER.SOURCE,
      },
      payload: {
        promise: membersApi.getByUserId().then(result => _.get(result, 'data')),
      },
    };
  };
}

export function getFullMemberForProvider(domain, domainMemberId, ehrPatientId) {
  return ({ bkmdApi: { membersApi } }) => ({
    type: GET_FULL_MEMBER_INFO.SOURCE,
    meta: {
      tracker: GET_FULL_MEMBER_INFO.SOURCE,
    },
    payload: {
      promise: membersApi.getFullMemberForProvider(domainMemberId, domain, ehrPatientId),
    },
  });
}

export function getMemberWithPlan() {
  return ({ dispatch }) => ({
    type: GET_MEMBER_WITH_PLAN.SOURCE,
    meta: {
      tracker: GET_MEMBER_WITH_PLAN.SOURCE,
    },
    payload: {
      promise: dispatch(getMember()).tap(member => {
        if (member.plan) {
          dispatch(setInQueryString({ plan: member.plan }));
        }
      }),
    },
  });
}

export function addInsuranceInfo(data) {
  return ({ bkmdApi: { membersApi } }) => ({
    type: ADD_INSURANCE_INFO.SOURCE,
    meta: {
      tracker: ADD_INSURANCE_INFO.SOURCE,
    },
    payload: {
      promise: membersApi.addInsuranceInfo(data),
    },
  });
}

export function getSettings() {
  return ({ bkmdApi: { membersApi } }) => ({
    type: GET_MEMBER_SETTINGS.SOURCE,
    meta: {
      tracker: GET_MEMBER_SETTINGS.SOURCE,
    },
    payload: {
      promise: membersApi.getSettings()
        .then(data => convertLocationData(data))
        .catch(() => ({ data: {} })),
    },
  });
}

export function changeNotificationPreferences(preferences) {
  return ({ bkmdApi: { membersApi } }) => ({
    type: CHANGE_NOTIFICATION_PREFERENCES.SOURCE,
    meta: {
      tracker: CHANGE_NOTIFICATION_PREFERENCES.SOURCE,
    },
    payload: {
      promise: membersApi.changeNotificationPreferences(preferences),
    },
  });
}

export function changeLocationPermissions(allow) {
  return ({ bkmdApi: { membersApi } }) => ({
    type: CHANGE_LOCATION_PERMISSIONS.SOURCE,
    meta: {
      tracker: CHANGE_LOCATION_PERMISSIONS.SOURCE,
    },
    payload: {
      promise: membersApi.changeLocationPermissions(allow),
    },
  });
}

export function changeAddress(addressType, address) {
  return ({ bkmdApi: { membersApi } }) => ({
    type: CHANGE_ADDRESS.SOURCE,
    meta: {
      tracker: CHANGE_ADDRESS.SOURCE,
    },
    payload: {
      promise: membersApi.changeAddress(addressType, address),
    },
  });
}

export function getCareCues() {
  return ({ bkmdApi: { membersApi } }) => ({
    type: GET_CARE_CUES.SOURCE,
    meta: {
      tracker: GET_CARE_CUES.SOURCE,
    },
    payload: {
      promise: membersApi.getCareCues(),
    },
  });
}

export function getMedicationsSummary() {
  return ({ bkmdApi: { membersApi } }) => ({
    type: GET_MEDICATIONS_SUMMARY,
    meta: {
      tracker: GET_MEDICATIONS_SUMMARY,
    },
    payload: {
      promise: membersApi.getMedicationSummary(),
    },
  });
}

export function getDrugHistory(drugName) {
  return ({ bkmdApi: { membersApi } }) => ({
    type: GET_DRUG_HISTORY,
    meta: {
      tracker: `${GET_DRUG_HISTORY}-${drugName}`,
    },
    payload: {
      promise: membersApi.getDrugHistory(drugName),
    },
  });
}

export function fetchActivationByToken(token) {
  return ({ bkmdApi: { membersApi } }) => ({
    type: FETCH_ACTIVATION_BY_TOKEN.SOURCE,
    meta: {
      tracker: FETCH_ACTIVATION_BY_TOKEN.SOURCE,
    },
    payload: {
      promise: membersApi.fetchActivationByToken(token),
    },
  });
}

export function updateMemberState(stateDescription, value) {
  return ({ bkmdApi: { membersApi }, dispatch }) => ({
    type: UPDATE_MEMBER_STATE.SOURCE,
    meta: {
      tracker: UPDATE_MEMBER_STATE.SOURCE,
    },
    payload: {
      promise: membersApi.updateMemberState(stateDescription.key, value).then(() => {
        dispatch(getSettings());
      }),
    },
  });
}
