/**
 * Created by galgoltzman on 07/06/2017.
 */

import React from 'react';
import { autobind } from 'core-decorators';
import { defineMessages, FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import BkmdModal from '../../bkmdModal/bkmdModal';
import ModalHeader from '../../bkmdModal/modalHeader';
import SvgHelpStars from '../../../assets/svg/helpStars';
import commonMessages from '../../../store/intl/commonMessages';
import './FindIdNumModal.less';

const messages = defineMessages({
  modalText: {
    defaultMessage: 'Here’s where you can find your ID and what it could look like.',
    id: 'activation.insuranceID.modalText',
  },
  modalTitle: {
    defaultMessage: 'Useful hints',
    id: 'activation.insuranceID.modalHeader',
  },
  whereCanIFind: {
    defaultMessage: 'Where can I find my ID number?',
    id: 'activation.insuranceID.whereCanIFind',
  },
  howToFindYourMemberId: {
    defaultMessage: 'How to find your member ID.',
    id: 'activation.insuranceID.howToFindYourMemberId',
  },
  ifYouNeedHelpPleaseCallUsAt: {
    defaultMessage: 'If you need help, please call us at {phoneNumber}.',
    id: 'activation.insuranceID.ifYouNeedHelpPleaseCallUsAt',
  },
  insuranceIdNumber: {
    defaultMessage: 'Insurance ID number',
    id: 'activation.insuranceID.insuranceIdNumber',
  },
});

@autobind
class FindIdNumModalAllsavers extends React.PureComponent {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    /**
     * Name for identifying via analytics
     */
    name: PropTypes.string, // eslint-disable-line react/no-unused-prop-types
  };

  static defaultProps = {
    name: 'find_id_number_modal',
  };

  render() {
    const { isOpen, handleClose, name } = this.props;
    return (
      <div>
        <BkmdModal
          isOpen={isOpen}
          handleClose={handleClose}
          name={name}
          className="id-num-help-modal fixed-header"
        >
          <ModalHeader onClose={handleClose} title={messages.modalTitle} />
          <div className="dialog-body">
            <div className="row">
              <div className="col-xs-12 text-center">
                <SvgHelpStars />
              </div>
            </div>
            <div className="row margin-top-30">
              <div className="col-xs-12 text-center">
                <span className="text-18 text-semibold text-dark">
                  <FormattedMessage {...messages.howToFindYourMemberId} />
                </span>
              </div>
            </div>
            <div className="row margin-top-30">
              <div className="col-xs-12 text-16 text-center">
                <FormattedMessage {...messages.modalText} />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6 text-center">
                <img
                  src={require('./images/idCardHelpAS1.png')}
                  className="insurance-id-modal-main-img"
                  alt=""
                />
              </div>
              <div className="col-sm-6 text-center">
                <img
                  src={require('./images/idCardHelpAS2.png')}
                  className="insurance-id-modal-main-img"
                  alt=""
                />
              </div>
            </div>
            <div className="row margin-top-30">
              <div className="col-xs-12 text-16 text-center">
                <FormattedMessage
                  {...messages.ifYouNeedHelpPleaseCallUsAt}
                  values={{
                    phoneNumber: (
                      <span className="text-semibold text-no-line-breaks">
                        <FormattedMessage {...commonMessages.bookmdPhoneNumber} />
                      </span>
                    ),
                  }}
                />
              </div>
            </div>
          </div>
        </BkmdModal>
      </div>
    );
  }
}

export default FindIdNumModalAllsavers;
