/**
 * Created by guyavraham on 01/08/2017.
 */

import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { getFeature } from '../../store/feature/selectors';

@autobind
class FeatureFlagged extends React.PureComponent {
  static defaultProps = {
    hide: false,
    renderLi: false,
    renderChildren: false,
    feature: undefined,
  };

  static propTypes = {
    feature: PropTypes.any,
    hide: PropTypes.bool,
    renderLi: PropTypes.bool,
    /**
     *  Renders the children if feature flag is on without div/li wrap
     */
    renderChildren: PropTypes.bool,
  };

  renderDiv() {
    const { children, ...other } = this.props;
    return (
      <div
        className="flagged"
        {..._.omit(other, 'uri', 'feature', 'dispatch', 'hide', 'renderLi', 'renderChildren')}
      >
        {children}
      </div>
    );
  }

  renderLi() {
    const { children, ...other } = this.props;
    return (
      <li {..._.omit(other, 'uri', 'feature', 'dispatch', 'hide', 'renderLi', 'renderChildren')}>
        {children}
      </li>
    );
  }

  renderChildren() {
    return this.props.children;
  }

  render() {
    const { feature, hide, renderLi, renderChildren } = this.props;
    // Check if the feature is defined and turned off, or if it is hidden by choice
    if (!feature || hide) return null;

    if (renderLi) return this.renderLi();
    if (renderChildren) return this.renderChildren();

    return this.renderDiv();
  }
}

export default compose(
  connect((state, ownProps) => ({
    feature: getFeature(state, ownProps.uri),
  })),
)(FeatureFlagged);
