/**
 * Created by chenrozenes on 26/04/2017.
 */
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

export default function ArrayColumn({ value }) {
  return <span>{value.size === 0 ? '[]' : _.toString(_.get(value, '_tail.array'))}</span>;
}

ArrayColumn.propTypes = {
  value: PropTypes.object
};

ArrayColumn.defaultProps = {
  value: []
};
