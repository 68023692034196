/**
 * Created by rom on 20/12/2015.
 */
import React from 'react';

export default function SvgSex() {
  /* eslint-disable */
  return (
    <svg aria-hidden="true" focusable="false" className="svg-icon svg-icon-sex" width="22px" height="22px" viewBox="0 0 22 22" version="1.1">
     <desc></desc>
      <defs>
        <polygon id="path-1" points="0.0002 22 14.4812 22 14.4812 0.0004 0.0002 0.0004 0.0002 22"></polygon>
        <polygon id="path-3" points="0.5176 22 14.9996 22 14.9996 0.0002 0.5176 0.0002 0.5176 22"></polygon>
      </defs>
      <g id="Basic-flow---no-TIN" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Patient-details" transform="translate(-508.000000, -229.000000)">
      <g id="Page-1" transform="translate(508.000000, 229.000000)">
      <g id="Group-3">
      <g id="Clip-2"></g>
      <path d="M7.2402,12.4824 C4.3512,12.4824 2.0002,10.1304 2.0002,7.2404 C2.0002,4.3504 4.3512,2.0004 7.2402,2.0004 C10.1312,2.0004 12.4812,4.3504 12.4812,7.2404 C12.4812,10.1304 10.1312,12.4824 7.2402,12.4824 M14.4812,7.2404 C14.4812,3.2424 11.2402,0.0004 7.2402,0.0004 C3.2422,0.0004 0.0002,3.2424 0.0002,7.2404 C0.0002,10.9054 2.7242,13.9254 6.2552,14.4074 C6.2532,14.4334 6.2402,14.4564 6.2402,14.4824 L6.2402,18.5864 L5.2402,17.5864 L3.8262,19.0004 L6.5332,21.7074 C6.7282,21.9024 6.9842,22.0004 7.2402,22.0004 C7.4962,22.0004 7.7522,21.9024 7.9472,21.7074 L10.6542,19.0004 L9.2402,17.5864 L8.2402,18.5864 L8.2402,14.4824 C8.2402,14.4564 8.2272,14.4334 8.2252,14.4084 C11.7582,13.9254 14.4812,10.9054 14.4812,7.2404" id="Fill-1" fill="#898989"></path>
      </g>
      <g id="Group-6" transform="translate(7.000000, 0.000000)">
      <g id="Clip-5"></g>
      <path d="M2.5176,7.2402 C2.5176,4.3502 4.8696,2.0002 7.7596,2.0002 C10.6496,2.0002 12.9996,4.3502 12.9996,7.2402 C12.9996,10.1312 10.6496,12.4822 7.7596,12.4822 C4.8696,12.4822 2.5176,10.1312 2.5176,7.2402 M14.9996,7.2402 C14.9996,3.2422 11.7576,0.0002 7.7596,0.0002 C3.7596,0.0002 0.5176,3.2422 0.5176,7.2402 C0.5176,10.9002 3.2346,13.9172 6.7596,14.4052 L6.7596,18.0112 L4.7596,18.0112 L4.7596,20.0112 L6.7596,20.0112 L6.7596,22.0002 L8.7596,22.0002 L8.7596,20.0112 L10.7596,20.0112 L10.7596,18.0112 L8.7596,18.0112 L8.7596,14.4052 C12.2836,13.9172 14.9996,10.8992 14.9996,7.2402" id="Fill-4" fill="#08ACE4"></path>
      </g>
      </g>
      </g>
      </g>
    </svg>
  );
  /* eslint-enable */
}
