import Immutable from 'seamless-immutable';

import { GET_APPOINTMENT_BY_ID, SET_APPOINTMENTS } from './actions';

const initialState = {};

export default function appointmentReducer(state = initialState, action) {
  state = Immutable(state);
  switch (action.type) {
    case GET_APPOINTMENT_BY_ID.START: {
      return Immutable(initialState);
    }
    case GET_APPOINTMENT_BY_ID.SUCCESS: {
      return state.set(action.payload.id, action.payload);
    }
    case SET_APPOINTMENTS:
      return action.payload;
    default:
      return state;
  }
}
