/**
 * Created by galgoltzman on 14/06/2017.
 */

import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { autobind } from 'core-decorators';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import { connect } from 'react-redux';

import messages from './messages';
import SearchTypeahead from './directorySearch/directorySearchTypeahead';
import BkmdModal from '../../../../../components/bkmdModal/bkmdModal';
import ModalHeader from '../../../../../components/bkmdModal/modalHeader';
import { getBrowserHeight } from '../../../../../store/browser/selectors';
import { getCurrentLocation } from '../../../../../store/location/actions';
import { chooseClientLocation, isLocationReady } from '../directorySelectors';
import ThreeBounceSpinner from '../../../../../components/ui/spinner/threeBounceSpinner';

import '../../../../../components/searchDoctors/searchModal.less';

@autobind
class SearchModal extends React.PureComponent {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    browserHeight: PropTypes.number.isRequired,
    clientLocation: PropTypes.object.isRequired,
    /**
     * Name for identifying via analytics
     */
    name: PropTypes.string, // eslint-disable-line react/no-unused-prop-types
    validLocation: PropTypes.object.isRequired,
  };

  static defaultProps = {
    name: 'search_modal',
  };

  render() {
    const { isOpen, handleClose, name, browserHeight, clientLocation, validLocation } = this.props;

    const maxHeight = `${browserHeight - 106}`;
    return (
      <div>
        <BkmdModal
          isOpen={isOpen}
          handleClose={handleClose}
          name={name}
          className="fixed-header search-modal"
        >
          <ModalHeader onClose={handleClose} title="">
            <h4 className="modal-title text-left">
              <FormattedMessage {...messages.findADoctor} />
            </h4>
          </ModalHeader>
          <div className="dialog-body">
            <div className="header-search-input">
              {validLocation ? (
                <SearchTypeahead
                  containerClasses="left-search-icon no-right-border hide-arrow"
                  onChange={handleClose}
                  maxHeight={maxHeight}
                  location={_.get(clientLocation, 'geo', null)}
                  autoFocus
                />
              ) : (
                <ThreeBounceSpinner />
              )}
            </div>
          </div>
        </BkmdModal>
      </div>
    );
  }
}

export default compose(
  withRouter,
  connect(
    state => ({
      browserHeight: getBrowserHeight(state),
      clientLocation: chooseClientLocation(state),
      validLocation: isLocationReady(state),
    }),
    { getCurrentLocation },
  ),
)(SearchModal);
