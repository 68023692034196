/**
 * Created by matan on 9/4/16.
 */
import Immutable from 'seamless-immutable';
import _ from 'lodash';

import { SET_CACHE } from './actions';

const initialState = {};

export default function cacheSearchReducer(state = initialState, action) {
  state = Immutable(state);

  switch (action.type) {
    case SET_CACHE: {
      return state.set(action.payload.searchKey, _.omit(action.payload, 'searchKey'));
    }
  }

  return state;
}
