/**
 * Created by anna on 03/08/2017.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import { defineMessages, FormattedMessage, injectIntl, intlShape } from 'react-intl';
import Utils from '../../../utils/util';
import PositiveEnum from './communityFeedbackMsg/positiveFeedbackMsg';
import PositiveEnumGender from './communityFeedbackMsg/genderFeedbackMsg';

const genderMsg = defineMessages({
  relativeMale: {
    defaultMessage: 'His',
    id: 'members.relativeGender.male',
  },
  relativeFemale: {
    defaultMessage: 'Her',
    id: 'members.relativeGender.female',
  },
  female: {
    defaultMessage: 'She',
    id: 'members.gender.female',
  },
  male: {
    defaultMessage: 'He',
    id: 'members.gender.male',
  },
});

const messages = defineMessages({
  communityFeedback: {
    defaultMessage: 'Community feedback',
    id: 'members.communityFeedback',
  },
});

@autobind
class CommunityFeedback extends React.Component {
  static propTypes = {
    intl: intlShape.isRequired,
    providerGender: PropTypes.string.isRequired,
    bestDrCommFeedback: PropTypes.string,
    bestOfficeCommFeedback: PropTypes.string,
  };

  static defaultProps = {
    bestDrCommFeedback: undefined,
    bestOfficeCommFeedback: undefined,
  };

  renderFeedback(gender, relativeGender, feedback) {
    if (!feedback) return <div></div>;
    return (
      <div className="feedback-item text-22">
        <i className="icon-heart-f font-color-brand-accent" />
        <span className="text-light">
          <FormattedMessage
            {...PositiveEnumGender.getMessageByValue(feedback)}
            values={{ gender, relativeGender }}
          />
        </span>
        <span className="text-semibold font-color-brand-accent">
          <FormattedMessage
            {...PositiveEnum.getMessageByValue(feedback)}
          />
        </span>
      </div>
    );
  }

  render() {
    const { bestDrCommFeedback, bestOfficeCommFeedback, providerGender, intl } = this.props;
    if (!bestDrCommFeedback && !bestOfficeCommFeedback) return (<div></div>);

    const isMale = Utils.isMale(providerGender);
    const relativeGender = isMale ? genderMsg.relativeMale : genderMsg.relativeFemale;
    const gender = isMale ? genderMsg.male : genderMsg.female;

    const genderStr = intl.formatMessage(gender);
    const relativeGenderStr = intl.formatMessage(relativeGender);

    return (
      <div>
        <div className="card feedback-section">
          <div className="card-body">
            <div className="text-16 text-dark">
              <strong>
                <FormattedMessage {...messages.communityFeedback} />
              </strong>
            </div>
            {this.renderFeedback(genderStr, relativeGenderStr, bestDrCommFeedback)}
            {this.renderFeedback(genderStr, relativeGenderStr, bestOfficeCommFeedback)}
          </div>
        </div>
      </div>
    );
  }
}

export default injectIntl(CommunityFeedback);
