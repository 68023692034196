import _ from 'lodash';
import { back, mapPayloadTo } from '../../components/wizard/flow/results';
import SelectCustomInsurer from '../steps/selectCustomInsurer';
import Confirmation from '../steps/confirmation';
import FindMemberPublic from '../steps/findMemberPublic';
import ReasonForVisit from '../steps/reasonForVisit';
import GetBookingData from '../steps/getBookingData';
import SelectAppointmentType from '../steps/selectAppointmentType';
import SelectInsurerPatient from '../steps/selectInsurerPatient';
import ReviewAppointmentDetails from '../steps/reviewAppointmentDetails';
import SelectAppointmentTimeslot from '../steps/selectAppointmentTimeslot';
import SelectTimePreference from '../steps/selectTimePreference';
import * as BookingType from '../../model/enum/bookingType';
import PatientMemberPhone from '../steps/patientMemberPhone';
import Api from '../../api';

export const name = 'patientBookingFlow';

export const steps = {
  GET_BOOKING_DATA: 'getBookingData',
  SELECT_INSURER: 'selectInsurer',
  SELECT_CUSTOM_INSURER: 'selectCustomInsurer',
  SELECT_APPOINTMENT_TYPE: 'selectAppointmentType',
  SELECT_TIME_PREFERENCE: 'selectTimePreference',
  SELECT_APPOINTMENT_TIME: 'selectAppointmentTime',
  MEMBER_PHONE: 'memberPhone',
  FIND_MEMBER: 'findMember',
  REASON_FOR_VISIT: 'reasonForVisit',
  REVIEW_BOOKING: 'reviewBooking',
  CONFIRMATION: 'confirmation',
};

const hasMemberIdentifier = data => _.isEmpty(data.domainMemberId) && _.isEmpty(data.token);

const mapFlowDataToAppointmentProps = flowData => ({
  appointment: _.assign({}, flowData.selectedTimeslot, {
    reasonForVisit: flowData.reasonForVisit,
    appointmentTypeId: flowData.selectedAppointmentType.id,
    timePreference: flowData.timePreference,
    timePreferenceCustomText: flowData.timePreferenceCustomText,
    npi: flowData.npi,
    locationId: flowData.location.id,
  }),
  autoSelectedAppointmentType: flowData.autoSelectedAppointmentType,

  location: flowData.location,
  domainMemberId: flowData.domainMemberId || _.get(flowData, ['member', 'domainMemberId']),
  service: flowData.selectedAppointmentType,
});

const mapFlowDataToBookingData = flowData => {
  const {
    bookingData: { appointmentTypes, strategy, appointments },
  } = flowData;
  return { appointmentTypes, bookingStrategy: strategy, appointments };
};

const mapFlowDataToMember = flowData => {
  const { findMemberInput, selectedInsurer, memberPhone } = flowData;
  return {
    userInput: findMemberInput,
    insurer: selectedInsurer.id,
    memberPhone,
  };
};

const getBookingDataStep = ({ transitions }) => ({
  [steps.GET_BOOKING_DATA]: {
    component: GetBookingData,
    transitions,
    mapFlowDataToProps: ({ location, npi }) => ({ location, npi }),
    mapPayloadToFlowData: ({ bookingData, bookingType }) => {
      const { appointmentTypes } = bookingData;
      let selectedAppointmentType;
      let autoSelectedAppointmentType;
      if (_.size(appointmentTypes) === 1) {
        selectedAppointmentType = appointmentTypes[0];
        autoSelectedAppointmentType = true;
      }
      return {
        bookingData,
        bookingType,
        selectedAppointmentType,
        autoSelectedAppointmentType,
      };
    },
  },
});

const selectAppointmentTimeStep = ({ transitions }) => ({
  [steps.SELECT_APPOINTMENT_TIME]: {
    component: SelectAppointmentTimeslot,
    transitions,
    mapFlowDataToProps: flowData => ({
      appointmentType: flowData.selectedAppointmentType,
      npi: flowData.npi,
      locationId: flowData.location.id,
      timezone: flowData.location.timezone,
      ...mapFlowDataToBookingData(flowData),
    }),
    mapPayloadToFlowData: ({ timeslot }) => ({ selectedTimeslot: timeslot }),
    evaluate: ({ data }) => data.bookingType === BookingType.INSTANT,
  },
});

const selectAppointmentTypeStep = ({ transitions }) => ({
  [steps.SELECT_APPOINTMENT_TYPE]: {
    component: SelectAppointmentType,
    transitions,
    mapFlowDataToProps: flowData => ({
      bookingType: flowData.bookingType,
      ...mapFlowDataToBookingData(flowData),
    }),
    mapPayloadToFlowData: returnedValue => ({ selectedAppointmentType: returnedValue }),
    evaluate: ({ data }) =>
      !data.autoSelectedAppointmentType || _.isEmpty(data.selectedAppointmentType),
  },
});

export const flow = {
  ...getBookingDataStep({
    transitions: {
      NEXT: steps.SELECT_INSURER,
    },
  }),

  [steps.SELECT_INSURER]: {
    component: SelectInsurerPatient,
    transitions: {
      NEXT: ({ data: { selectedInsurer } }) =>
        selectedInsurer ? steps.MEMBER_PHONE : steps.SELECT_CUSTOM_INSURER,
    },
    mapFlowDataToProps: flowData => ({ selectedInsurer: flowData.selectedInsurer }),
    mapPayloadToFlowData: payload => ({ selectedInsurer: payload }),
    evaluate: ({ data }) => _.isEmpty(data.selectedInsurer),
  },

  [steps.SELECT_CUSTOM_INSURER]: {
    component: SelectCustomInsurer,
    transitions: {
      PREV: steps.SELECT_INSURER,
      NEXT: steps.MEMBER_PHONE,
    },
    mapFlowDataToProps: flowData => ({ selectedInsurer: flowData.selectedInsurer }),
    mapPayloadToFlowData: payload => ({ selectedInsurer: payload }),
  },

  [steps.MEMBER_PHONE]: {
    component: PatientMemberPhone,
    transitions: {
      PREV: back,
      NEXT: steps.FIND_MEMBER,
    },
    mapFlowDataToProps: ({ memberPhone, findMemberInput }) => ({
      memberPhone,
      fullName: findMemberInput && findMemberInput.fullName,
      validatePhoneNumberAPI: Api.activationApi.validatePhoneNumber,
    }),
    mapPayloadToFlowData: mapPayloadTo('memberPhone'),
    evaluate: ({ data }) => hasMemberIdentifier(data),
  },

  [steps.FIND_MEMBER]: {
    component: FindMemberPublic,
    transitions: {
      PREV: back,
      NEXT: steps.SELECT_APPOINTMENT_TYPE,
    },
    mapFlowDataToProps: mapFlowDataToMember,
    evaluate: ({ data }) => hasMemberIdentifier(data),
  },

  ...selectAppointmentTypeStep({
    transitions: {
      NEXT: steps.SELECT_APPOINTMENT_TIME,
      PREV: steps.FIND_MEMBER,
    },
  }),

  ...selectAppointmentTimeStep({
    transitions: {
      NEXT: steps.SELECT_TIME_PREFERENCE,
      PREV: steps.SELECT_APPOINTMENT_TYPE,
    },
  }),

  [steps.SELECT_TIME_PREFERENCE]: {
    component: SelectTimePreference,
    transitions: {
      NEXT: steps.REASON_FOR_VISIT,
      PREV: steps.SELECT_APPOINTMENT_TIME,
    },
    mapFlowDataToProps: flowData =>
      _.pick(flowData, ['timePreference', 'timePreferenceCustomText']),
    mapPayloadToFlowData: returnedValue => ({ ...returnedValue }),
    evaluate: ({ data }) => data.bookingType === BookingType.REQUEST,
  },

  [steps.REASON_FOR_VISIT]: {
    component: ReasonForVisit,
    transitions: {
      NEXT: steps.REVIEW_BOOKING,
      PREV: steps.SELECT_TIME_PREFERENCE,
    },
    mapPayloadToFlowData: ({ reasonForVisit }) => ({ reasonForVisit }),
    mapFlowDataToProps: ({ reasonForVisit }) => ({ reasonForVisit }),
  },

  [steps.REVIEW_BOOKING]: {
    component: ReviewAppointmentDetails,
    transitions: {
      NEXT: steps.CONFIRMATION,
      PREV: steps.REASON_FOR_VISIT,
    },
    mapFlowDataToProps: (flowData, flowProps) => ({
      memberDomain: flowData.memberDomain,
      bookApiOverride: flowProps.reviewBookingApiOverride,
      appointmentRequestApi: flowProps.createAppointmentRequestApi,
      onBookingSuccess: flowProps.onBookingSuccess,
      bookingType: flowData.bookingType,
      bookingStrategy: flowData.bookingData.strategy,
      ...mapFlowDataToAppointmentProps(flowData),
    }),
    mapPayloadToFlowData: payload => {
      if (payload && payload.bookingData) {
        return payload;
      }
      return null;
    },
  },

  [steps.CONFIRMATION]: {
    component: Confirmation,
    transitions: {},
    mapFlowDataToProps: (flowData, flowProps) => ({
      onConfirmationClick: flowProps.onConfirmationClick,
      messageBannerText: flowProps.messageBannerText,
      showMessageBanner: flowProps.showMessageBanner,
      bookingType: flowData.bookingType,
      ...mapFlowDataToAppointmentProps(flowData),
    }),
  },
};

export const initialStepName = steps.GET_BOOKING_DATA;
