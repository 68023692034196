/**
 * Created by chenrozenes on 20/10/2016.
 */
import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import _ from 'lodash';
import CleanInput from '../form/cleanInput';

/**
 * Debounced search input
 * @type {*|Function}
 */
@autobind
class SearchBanner extends React.Component {
  static propTypes = {
    onSearch: PropTypes.func.isRequired,
    waitTime: PropTypes.number, // Time to debounce
    label: PropTypes.string,
    text: PropTypes.string,
    className: PropTypes.string
  };

  static defaultProps = {
    waitTime: 1000, // 1 seconds
    label: 'Search',
    text: '',
    className: undefined
  };

  constructor(props) {
    super(props);
    this.state = {
      text: props.text,
    };
  }

  componentWillMount() {
    const { onSearch, waitTime } = this.props;
    if (onSearch) {
      this.search = _.debounce(onSearch, waitTime);
    }
  }

  onChange(event) {
    const text = event.target.value;

    this.setState({ text });
    if (this.search) this.search(text);
  }

  render() {
    return (
      <div
        className={classNames(
          'big-search-input-options',
          'margin-bottom-clean-form',
          this.props.className,
        )}
      >
        <div
          className="big-search-input multiple-inputs no-margin no-right-border
          hide-arrow left-search-icon"
        >
          <CleanInput
            {..._.omit(this.props, ['label', 'waitTime', 'text', 'onSearch'])}
            placeholder={this.props.label}
            value={this.state.text}
            onChange={this.onChange}
            inputClassName="bootstrap-typeahead-input-main"
          />
        </div>
        <i className="icon-search-2 search-inner-icon" />
      </div>
    );
  }
}

export default SearchBanner;
