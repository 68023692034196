/**
 * Created by matan on 3/12/17.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import BaseLink from './baseLink';
import commonMessages from '../../../store/intl/commonMessages';
import { getMembersTermsConditionsUrl, getProvidersTermsConditionsUrl }
from '../../../store/config/selectors';

class TermsOfServiceLink extends React.PureComponent {

  static propTypes = {
    /**
     * Should url lead the members "Terms of Service" or tne providers one
     * Default is false (i.e provider)
     */
    isMember: PropTypes.bool,
    overrideUrl: PropTypes.string,
    membersUrl: PropTypes.string,
    providersUrl: PropTypes.string,
  };

  static defaultProps = {
    isMember: false,
    providersUrl: null,
    overrideUrl: null,
    membersUrl: null,
  };

  render() {
    // eslint-disable-next-line no-unused-vars
    const { isMember, providersUrl, membersUrl, overrideUrl, dispatch, ...other } = this.props;
    const url = isMember ? membersUrl : providersUrl;
    return (
      <BaseLink
        url={overrideUrl || url}
        label={commonMessages.termsOfUse}
        {...other}
      />
    );
  }
}

export default connect(
  state => ({
    membersUrl: getMembersTermsConditionsUrl(state),
    providersUrl: getProvidersTermsConditionsUrl(state)
  })
)(TermsOfServiceLink);

