const shortActivationEvents = {
  flow: {
    startFlow: 'Short activation flow start',
    dobEntered: 'Short activation dob entered',
    dobMissMatch: 'Short activation dob miss match',
    complete: 'Short activation complete',
    failed: 'Short activation failed',
  },
};

export default shortActivationEvents;
