import React from 'react';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import Formatter from '../../utils/formatter';
import { toMoment } from '../../store/tools/seamlessImmutableHelper';
import ClickableLi from '../../components/clickableElement/clickableLi';

@autobind
class Timeslot extends React.Component {
  static propTypes = {
    timeslot: PropTypes.object.isRequired,
    onTimeslotSelect: PropTypes.func.isRequired,
  };

  /**
   * Select the wanted timeslot
   */
  select() {
    const { timeslot } = this.props;
    this.props.onTimeslotSelect(timeslot);
  }

  /**
   * Returns the timeslot time
   */
  timeslotTime() {
    const {
      timeslot: { startTime },
    } = this.props;
    return Formatter.time(toMoment(startTime));
  }

  render() {
    const { className } = this.props;
    return (
      <ClickableLi clickHandler={this.select} className={className}>
        {this.timeslotTime()}
      </ClickableLi>
    );
  }
}

export default Timeslot;
