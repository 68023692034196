import _ from 'lodash';
import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { defineMessages, FormattedMessage } from 'react-intl';
import { withHandlers } from 'recompose';
import classNames from 'classnames';
import { autobind } from 'core-decorators';
import VerticalProviderCard from './verticalProviderCard';
import ProviderModal from './providerModal';
import InsuranceIdBanner from '../insuranceIdBanner/insuranceIdBanner';
import { createToggleState, createToggleStatePropTypes } from '../recompose/toggleStateComponent';
import SvgShieldCheck from '../../assets/svg/shieldCheck';
import messageShape from '../ui/intlString/messageShape';
import MessageBanner from '../molecules/messageBanner/messageBanner';
import * as BookingType from '../../model/enum/bookingType';
import './providerList.less';

const messages = defineMessages({
  disclaimer: {
    defaultMessage:
      "Check with your health insurance or call the specialist to make sure they're in-network.",
    id: 'common.providerList.disclaimer',
  },
  seeMore: {
    defaultMessage: 'See More Doctors',
    id: 'members.providerList.other',
  },
});
const NetworkDisclaimer = () => (
  <div className="network-disclaimer">
    <SvgShieldCheck />
    <FormattedMessage {...messages.disclaimer} />
  </div>
);

const SecondaryText = ({ text }) => (
  <div className="secondary-text">
    <i className="font-color-brand-success icon-check svg-icon text-22 text-strong" />
    <FormattedMessage {...text} />
  </div>
);

SecondaryText.propTypes = {
  text: messageShape.isRequired,
};

// TODO: sign in modal.
const SeeMore = compose(
  withHandlers({
    onClick: props => () => {
      props.onClick();
    },
  }),
)(({ onClick }) => (
  <div>
    <button className="btn btn-big btn-outline text-semibold" onClick={onClick}>
      <FormattedMessage {...messages.seeMore} />
    </button>
  </div>
));

SeeMore.propTypes = {
  onClick: PropTypes.func.isRequired,
};

const ModalWrapper = compose(createToggleState('providerModal'))(
  ({ providerModalHide, providerModalShow, providerModalOn, buttonText, modalTexts }) => (
    <div className="text-center margin-bottom-clean-form">
      <button
        onClick={providerModalShow}
        className="btn btn-inline-link font-color-brand-link text-16"
      >
        {buttonText}
      </button>
      <ProviderModal {...modalTexts} onClose={providerModalHide} isOpen={providerModalOn} />
    </div>
  ),
);

ModalWrapper.propTypes = {
  ...createToggleStatePropTypes('createToggleStatePropTypes'),
  buttonText: PropTypes.string.isRequired,
};

@autobind
class ProviderList extends React.PureComponent {
  static propTypes = {
    title: messageShape.isRequired,
    providers: PropTypes.array.isRequired,
    distances: PropTypes.object,
    closestLocations: PropTypes.object.isRequired,
    onProviderClick: PropTypes.func,
    plan: PropTypes.string,
    chosenTaxonomyCodes: PropTypes.array,
    showDetails: PropTypes.bool,
    showDisclaimer: PropTypes.bool,
    showInsuranceIdBanner: PropTypes.bool,
    showSeeMore: PropTypes.bool,
    showModal: PropTypes.bool,
    showMessageBanner: PropTypes.bool,
    seeMoreClick: PropTypes.func,
    insuranceIdBannerClick: PropTypes.func,
    distanceCustomComponent: PropTypes.func,
    isInNetworkCustomComponent: PropTypes.func,
    bookButtonCustomComponent: PropTypes.element,
    messageBannerText: PropTypes.string,
    modalButtonText: PropTypes.string,
    modalTexts: PropTypes.object,
    showBook: PropTypes.bool,
    onBookClick: PropTypes.func.isRequired,
    hideNetworkStatus: PropTypes.bool.isRequired,
    renderActions: PropTypes.func,
    secondaryText: PropTypes.object,
    onlyShowCurrentLocation: PropTypes.bool,
    bookingType: PropTypes.string,
    className: PropTypes.string,
    staticLocation: PropTypes.bool,
    memberContext: PropTypes.string,
    showDirection: PropTypes.bool,
    showNextAvailableSlot: PropTypes.bool,
    member: PropTypes.object,
    // showShare: PropTypes.array.isRequired,
  };

  static defaultProps = {
    distanceCustomComponent: undefined,
    isInNetworkCustomComponent: undefined,
    bookButtonCustomComponent: undefined,
    renderActions: undefined,
    plan: undefined,
    chosenTaxonomyCodes: undefined,
    showDetails: false,
    showDisclaimer: false,
    showInsuranceIdBanner: false,
    showSeeMore: false,
    showModal: false,
    seeMoreClick: false,
    showMessageBanner: false,
    insuranceIdBannerClick: _.noop,
    modalButtonText: undefined,
    modalTexts: undefined,
    showBook: true,
    onProviderClick: undefined,
    messageBannerText: undefined,
    hideNetworkStatus: false,
    onlyShowCurrentLocation: false,
    bookingType: BookingType.INSTANT,
    renderTitle: null,
    className: null,
    staticLocation: false,
    distances: undefined,
    memberContext: '',
    showDirection: true,
    showNextAvailableSlot: false,
    member: undefined,
  };

  renderProviders() {
    const {
      providers,
      // TODO: showShare
    } = this.props;
    const providersChunks = _.chunk(providers, 3);
    return _.map(providersChunks, this.renderProvidersChunk);
  }

  renderProvidersChunk(providers) {
    const {
      plan,
      distances,
      closestLocations,
      onProviderClick,
      chosenTaxonomyCodes,
      showDetails,
      showBook,
      onBookClick,
      hideNetworkStatus,
      onlyShowCurrentLocation,
      bookingType,
      renderActions,
      staticLocation,
      memberContext,
      showDirection,
      showNextAvailableSlot,
      distanceCustomComponent,
      isInNetworkCustomComponent,
      bookButtonCustomComponent,
      member,
    } = this.props;
    return (
      <div className="equal-flex-cards md-blocks">
        {_.map(providers, provider => (
          <VerticalProviderCard
            provider={provider}
            key={provider.npi}
            distances={distances}
            closestLocations={closestLocations}
            chosenTaxonomyCodes={chosenTaxonomyCodes}
            staticLocation={staticLocation}
            onProviderClick={onProviderClick}
            showBook={showBook}
            showShare={false}
            showDetails={showDetails}
            plan={plan}
            onBookClick={onBookClick}
            bookingType={bookingType}
            hideNetworkStatus={hideNetworkStatus}
            onlyShowCurrentLocation={onlyShowCurrentLocation}
            renderActions={renderActions}
            memberContext={memberContext}
            showDirection={showDirection}
            showNextAvailableSlot={showNextAvailableSlot}
            distanceCustomComponent={distanceCustomComponent}
            isInNetworkCustomComponent={isInNetworkCustomComponent}
            bookButtonCustomComponent={bookButtonCustomComponent}
            member={member}
          />
        ))}
      </div>
    );
  }

  render() {
    const {
      title,
      showDisclaimer,
      showInsuranceIdBanner,
      showSeeMore,
      showModal,
      seeMoreClick,
      showMessageBanner,
      insuranceIdBannerClick,
      modalButtonText,
      modalTexts,
      secondaryText,
      hideNetworkStatus,
      messageBannerText,
      className,
    } = this.props;
    return (
      <div>
        {showInsuranceIdBanner && <InsuranceIdBanner onClick={insuranceIdBannerClick} />}
        <div className="row no-margin">
          <div className="col-xs-12 no-padding">
            {title && (
              <div className="page-title padding-h-30">
                <h1 className="title-24 title-brand-1 margin-30 text-center">{title}</h1>
              </div>
            )}
            {showMessageBanner && (
              <div className="margin-top-30 padding-h-10">
                <MessageBanner text={messageBannerText} icon={'icon-calendar-sm'} />
              </div>
            )}
            <div className="margin-top-30">
              {showModal && <ModalWrapper buttonText={modalButtonText} modalTexts={modalTexts} />}
              {showDisclaimer && !hideNetworkStatus && <NetworkDisclaimer />}
              {secondaryText && <SecondaryText text={secondaryText} />}
              <div className={classNames('provider-list-wrap', className)}>
                {this.renderProviders()}
              </div>
              {showSeeMore && <SeeMore onClick={seeMoreClick} />}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ProviderList;
