import { autobind } from 'core-decorators';
import BkmdApi from './bkmdApi';

// eslint-disable-next-line
const SERVICE_NAME = 'facilitySearch';

@autobind
export default class FacilitySearchApi extends BkmdApi {
  constructor(fetch) {
    super(fetch, '/facilitySearch/');
  }

  getFacilities(facilityTypes) {
    return this.get('facilities', { facilityTypes });
  }
}
