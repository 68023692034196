/**
 * Created by matan on 7/6/16.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';

import formatter from '../../../utils/formatter';
import { initAnalyticsReporter } from './analyticsHelper';

/**
 * Injector component class that places the initialized on the wrapped component context
 * @param WrappedComponent - React component
 */
export default function injectAnalytics(appName, getDomain = _.noop) {
  return function injectAnalytics(WrappedComponent) {
    class InjectAnalytics extends React.PureComponent {
      static propTypes = {
        config: PropTypes.object.isRequired,
        routes: PropTypes.array,
        user: PropTypes.object, // eslint-disable-line react/no-unused-prop-types
        domain: PropTypes.object,
        member: PropTypes.object // eslint-disable-line react/no-unused-prop-types
      };

      static defaultProps = {
        user: undefined,
        member: undefined,
        domain: undefined,
        routes: undefined
      };

      // Used implicitly by React
      // noinspection JSUnusedGlobalSymbols
      static childContextTypes = {
        analytics: PropTypes.object.isRequired,
        routes: PropTypes.array
      };

      constructor(props) {
        super(props);
        this.state = { analytics: {} };
      }

      // Used implicitly by React
      // noinspection JSUnusedGlobalSymbols
      getChildContext() {
        return {
          analytics: this.state.analytics,
          routes: this.props.routes
        };
      }

      componentWillMount() {
        /**
         * Analytics reporter can only be initialized on pure client side (not SSR) since segment
         * analytics uses client only objects like window & document.
         */
        if (process.env.IS_BROWSER) {
          const analytics = _.isEmpty(this.state.analytics)
            ? initAnalyticsReporter(this.props.config.segmentio.apiKey)
            : this.state.analytics;
          this.setState({ analytics });
        }
      }

      componentWillReceiveProps(nextProps) {
        // If user id changed, report logged on user to analytics
        if (
          _.get(nextProps, 'user.id') !== _.get(this.props, 'user.id') ||
          _.get(nextProps, 'member.info.fullName') !== _.get(this.props, 'member.info.fullName')
        ) {
          this.state.analytics.identify(_.get(nextProps, 'user.id'), {
            ..._.pick(nextProps.user, ['invisible']),
            age: formatter.age(_.get(nextProps, 'member.info.dateOfBirth')),
            gender: _.get(nextProps, 'member.info.gender'),
            appName,
            domain: this.props.domain
          });
        }
      }

      render() {
        return <WrappedComponent {...this.props} />;
      }
    }

    return connect(state => ({
      config: state.config,
      user: _.get(state, 'auth.user'),
      member: state.member,
      domain: getDomain(state)
    }))(InjectAnalytics);
  };
}
