/**
 * Created by guyavarham on 20/07/2017.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { autobind } from 'core-decorators';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { hasInsuranceIdSelector } from '../../../../../../store/member/selectors';
import { paths as addInsurancePaths } from '../../../addInsuarnceInfo/routes';
import { routeToWithNext } from '../../../../../../utils/routeToWithNext';
import { messages } from '../../../../../../messages/searchDoctorResults/messages';
import {
  createToggleState,
  createToggleStatePropTypes,
} from '../../../../../../components/recompose/toggleStateComponent';

@autobind
class InsuranceIdBanner extends React.PureComponent {
  static propTypes = {
    hasInsuranceId: PropTypes.bool.isRequired,
    routerLocation: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
    ...createToggleStatePropTypes('insuranceIdBanner'),
  };

  onClickAddInsuranceId() {
    const { router, routerLocation } = this.props;
    routeToWithNext(router, routerLocation, addInsurancePaths.enterInsuranceID());
  }

  render() {
    const { hasInsuranceId, insuranceIdBannerHide, insuranceIdBannerOn } = this.props;

    if (hasInsuranceId || !insuranceIdBannerOn) return null;

    return (
      <div className="member-id-banner bg-color-brand-main banner top">
        <button
          className="close-banner-btn btn btn-inline-link"
          onClick={insuranceIdBannerHide}
          aria-label="Close"
        >
          <i className="icon-x-2" />
        </button>
        <div className="row">
          <div className="col-sm-8">
            <div className="text-18 text-semibold">
              <i className="banner-icon icon-shield-check i-va-fix-2" />
              <FormattedMessage {...messages.alwaysStayInNetwork} />
            </div>
            <div className="text-14 margin-top-5">
              <FormattedMessage {...messages.enterYourMemberIdToMakeSure} />
            </div>
          </div>
          <div className="col-sm-4">
            <button
              className="btn btn-small bg-color-brand-success text-semibold banner-right-btn"
              onClick={this.onClickAddInsuranceId}
            >
              <FormattedMessage {...messages.enterYourMemberId} />
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default compose(
  withRouter,
  createToggleState('insuranceIdBanner', true),
  connect(state => ({
    hasInsuranceId: hasInsuranceIdSelector(state),
  })),
)(InsuranceIdBanner);
