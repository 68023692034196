import React from 'react';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';
import { BkmdModal } from '../../../../components/bkmdModal';
import './providerVideoModal.less';

const ProviderVideoModal = props => {
  const { provider, handleClose, autoplay, ...other } = props;
  const { videoUrl } = provider;
  return (
    <BkmdModal
      className="provider-video-modal"
      handleClose={handleClose}
      {...other}
    >
      <button
        onClick={handleClose}
        className="btn btn-inline-link close-btn"
      >
        <i className="icon-x-2" />
      </button>
      <ReactPlayer
        url={videoUrl}
        playing={!autoplay}
        width="100%"
        height="100%"
        className="video-player"
      />
    </BkmdModal>
  );
};

ProviderVideoModal.propTypes = {
  provider: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
  autoplay: PropTypes.bool.isRequired,
};

export default ProviderVideoModal;
