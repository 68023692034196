/**
 * Created by guyavarham on 25/12/2017.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import { compose } from 'redux';
import { defineMessages, FormattedMessage } from 'react-intl';
import { reduxForm, Field } from 'redux-form';

import * as formDefs from './findMemberFormDefs';
import { createValidator } from '../../../../../../utils/joiValidator';
import SpinnerButton from '../../../../../../components/ui/spinner/spinnerButton';

const messages = defineMessages({
  name: {
    defaultMessage: 'First and last name',
    id: 'providers.scheduling.appointment.flow.findPatientForm.name',
  },
  dateOfBirth: {
    defaultMessage: 'Date of birth',
    id: 'providers.scheduling.appointment.flow.findPatientForm.dob',
  },
  memberId: {
    defaultMessage: 'Member ID',
    id: 'providers.scheduling.appointment.flow.findPatientForm.memberId',
  },
  searchButton: {
    defaultMessage: 'Search',
    id: 'providers.scheduling.appointment.flow.findPatientForm.search',
  },
});

@autobind
class FindMemberForm extends React.PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
  };

  render() {
    const { handleSubmit, isLoading } = this.props;

    return (
      <form onSubmit={handleSubmit} method="post">
        <div className="row">
          <div className="col-xs-12">
            <Field label={messages.name} {...formDefs.fields.fullName} />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <Field
              label={messages.dateOfBirth}
              placeholder="MM/DD/YYYY"
              {...formDefs.fields.dateOfBirth}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <Field label={messages.memberId} {...formDefs.fields.insuranceId} />
          </div>
        </div>

        <div className="row margin-bottom-clean-form">
          <div className="col-xs-12">
            <SpinnerButton isLoading={isLoading} className="btn btn-big btn-block" type="submit">
              <FormattedMessage {...messages.searchButton} />
            </SpinnerButton>
          </div>
        </div>
      </form>
    );
  }
}

export default compose(
  reduxForm({
    form: formDefs.name,
    validate: createValidator(formDefs.schema),
  }),
)(FindMemberForm);
