/**
 * Created by guyavarham on 19/12/2017.
 */

import { compose } from 'redux';
import { connect } from 'react-redux';

import NpiTypeahead from './npiTypeahead';
import * as selectors from '../../store/backoffice/providers/selectors';
import { searchProviderByNameOrNpi } from '../../store/backoffice/providers/actions';
import { getDomain } from '../../utils/domain/storage';

export default compose(
  connect(
    () => ({
      providerSelector: selectors.getProvidersSearch,
      onBlur: () => {
      },
    }),
    dispatch => ({
      onInputChange: input => dispatch(searchProviderByNameOrNpi(input, getDomain())),
    }),
  ),
)(NpiTypeahead);
