import { reduxForm } from 'redux-form';
import Joi from 'joi-browser';
import _ from 'lodash';
import { compose } from 'redux';
import { injectIntl } from 'react-intl';

import { createValidator } from '../../../../../utils/joiValidator';
import * as ActivationForm from '../activationForm';

export const withActivationForm = (field, customValidator) =>
  compose(
    injectIntl,
    reduxForm({
      form: ActivationForm.name,
      validate: createValidator(
        Joi.object().keys(_.pick(ActivationForm.schema, field)),
        customValidator,
      ),
      destroyOnUnmount: false,
    }),
  );
