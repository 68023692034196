import PropTypes from 'prop-types';
import {
  withProps,
  withHandlers,
  setPropTypes,
  compose,
  setDisplayName,
  defaultProps,
} from 'recompose';
import { FlowShape } from '../../components/wizard/flow/shapes';
import SelectInsurerForm from '../../components/organisms/SelectInsurerForm/SelectInsurerForm';
import SideDrawerContent from '../../components/templates/SideDrawerContent/SideDrawerContent';

const ComposedTemplate = withProps({
  formComponent: SelectInsurerForm,
})(SideDrawerContent);

const SelectInsurer = compose(
  setDisplayName('SelectInsurer'),
  setPropTypes({
    control: FlowShape.isRequired,
    options: PropTypes.array,
    title: PropTypes.string.isRequired,
    primaryButtonText: PropTypes.string.isRequired,
    secondaryButtonText: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
  }),
  defaultProps({
    options: [],
  }),
  withProps(({ selectedInsurer }) => ({
    initialValues: { insurer: selectedInsurer },
  })),
  withHandlers({
    onSubmit: ({ control }) => values => control.next({ payload: values.insurer }),
    onSecondaryClick: ({ control }) => () => {
      control.next({ payload: null });
    },
  }),
)(ComposedTemplate);

export default SelectInsurer;
