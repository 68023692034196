import { autobind } from 'core-decorators';
import BkmdApi from './bkmdApi';
import ServicesApi from './services';
import AclApi from './acl';

const SERVICE_NAME = 'users';

@autobind
class UsersApi extends BkmdApi {
  /**
   * A fetch object that points to the server
   * @param fetch
   */
  constructor(fetch) {
    super(fetch, '/users/');
    this.servicesApi = new ServicesApi(fetch);
    this.aclApi = new AclApi(fetch);
  }

  /**
   * Verifies the user by the token received from the email sent to him
   * @param userId
   * @param token
   */
  verifyUser(userId, token) {
    return this.post(`${userId}/verify`, { token });
  }

  /**
   * Creates a new user
   * @param user
   */
  createUser(user) {
    return this.post('create', { user });
  }

  /**
   * Get all the users
   * @param skip
   * @param limit
   * @param search
   * @param sort - column to sort
   * @param sortAscending - true if ascending
   * @returns {*}
   */
  getAllUsers(skip, limit, search, sort, sortAscending) {
    return this.servicesApi.callSecure(SERVICE_NAME, 'getAll', {
      limit,
      skip,
      search,
      sort,
      sortAscending,
    });
  }

  /**
   * Get the user object
   * @param id
   * @returns {*}
   */
  getUser(id) {
    return this.servicesApi.callSecure(SERVICE_NAME, 'getUserFromBackoffice', { id });
  }

  /**
   * Delete user
   * @param id: user id
   * @returns {*}
   */
  deleteUser(userId) {
    return this.servicesApi.callSecure(SERVICE_NAME, 'deleteUser', { userId });
  }

  /**
   * Change password for a user
   * (Backoffice method)
   * @param userId
   * @param password
   * @returns {*}
   */
  changePassword(userId, password, isTemporaryPassword) {
    return this.servicesApi.callSecure(SERVICE_NAME, 'changePassword', {
      userId,
      password,
      isTemporaryPassword,
    });
  }

  /**
   * Update tags for a user
   * (Backoffice method)
   * @param userId
   * @param tags
   * @returns {*}
   */
  updateTags(userId, tags) {
    return this.servicesApi.callSecure(SERVICE_NAME, 'updateTags', { userId, tags });
  }

  /**
   * Expire the user's password
   * (Backoffice method)
   * @param userId
   * @returns {*}
   */
  expireUserPassword(userId) {
    return this.servicesApi.callSecure(SERVICE_NAME, 'expireUserPassword', { userId });
  }

  /**
   * Create a user from the backoffice
   * @param email
   * @param password
   * @param phoneNumber
   * @returns {*}
   */
  createUserFromBackoffice(email, password, phoneNumber) {
    return this.servicesApi.callSecure(SERVICE_NAME, 'createUserFromBackoffice', {
      data: {
        email,
        password,
        phoneNumber,
        isTemporaryPassword: true,
      },
    });
  }

  /**
   * Lock a user
   * (Backoffice method)
   * @param userId
   * @returns {*}
   */
  lock(userId) {
    return this.servicesApi.callSecure(SERVICE_NAME, 'lock', { userId });
  }

  /**
   * Unlock a user
   * (Backoffice method)
   * @param userId
   * @returns {*}
   */
  unlock(userId) {
    return this.servicesApi.callSecure(SERVICE_NAME, 'unlock', { userId });
  }

  markVerified(userId) {
    return this.servicesApi.callSecure(SERVICE_NAME, 'markVerified', { userId });
  }

  /**
   * Gets permissions for the a user
   * (Backoffice method)
   * @param userId
   * @param options
   * @returns {*}
   */
  getUserPermissions(userId, options) {
    return this.aclApi.getPermissionsByUserId(userId, options);
  }

  getGroupPermissions(groupId, options) {
    return this.aclApi.getPermissionsByGroupId(groupId, options);
  }

  /**
   * Add permission to a user or group
   * (Backoffice method)
   * @param promoteOnly
   * @param userId
   * @param permission
   * @param resourceType
   * @param resourceId
   * @param isGroup
   * @returns {*}
   */
  addPermission(promoteOnly, userId, permission, resourceType, resourceId, isGroup) {
    return this.aclApi.addPermission(
      promoteOnly,
      userId,
      permission,
      resourceType,
      resourceId,
      isGroup,
    );
  }

  /**
   * Remove permission from a user
   * (Backoffice method)
   * @returns {*}
   */
  removePermission(userId, resourceType, resourceId, isGroup) {
    return this.aclApi.removePermission(userId, resourceType, resourceId, isGroup);
  }

  /**
   * Get a list of ACL user group the user has read permissions to
   * @param skip
   * @param limit
   * @param search
   * @param sort
   * @param sortAscending
   * @return {*}
   */
  getAllowedGroups(skip, limit, search, sort, sortAscending) {
    return this.servicesApi.callSecure(SERVICE_NAME, 'getAllowedGroups', {
      limit,
      skip,
      search,
      sort,
      sortAscending,
    });
  }

  /**
   * Creates an ACL user group
   * @param name - group name
   * @param owningResourceType - optional, resource type the group belongs to
   * @param owningResourceId - optional, resource id the group belongs to
   * @return {*}
   */
  createUserGroup(name, owningResourceType, owningResourceId) {
    return this.servicesApi.callSecure(SERVICE_NAME, 'createGroup', {
      name,
      owningResourceType,
      owningResourceId,
    });
  }

  getGroup(id) {
    return this.servicesApi.callSecure(SERVICE_NAME, 'getGroup', { id });
  }

  addUserToGroup(groupId, userId) {
    return this.servicesApi.callSecure(SERVICE_NAME, 'addUserToGroup', {
      groupId,
      userId,
      resourceId: groupId,
    });
  }

  addUserToGroupByEmail(groupId, email) {
    return this.servicesApi.callSecure(SERVICE_NAME, 'addUserToGroupByEmail', { groupId, email });
  }

  removeUserFromGroup(groupId, userId) {
    return this.servicesApi.callSecure(SERVICE_NAME, 'removeUserFromGroup', { groupId, userId });
  }

  deleteGroup(id) {
    return this.servicesApi.callSecure(SERVICE_NAME, 'deleteGroup', { id });
  }

  renameGroup(groupId, groupName) {
    return this.servicesApi.callSecure(SERVICE_NAME, 'renameGroup', { id: groupId, groupName });
  }

  resendEmailVerification(email) {
    return this.post('/resendEmailVerification', { email });
  }

  resendPhoneNumberVerification(phoneNumber) {
    return this.post('/resendPhoneNumberVerification', { phoneNumber });
  }

  changePhoneNumber(phoneNumber, password) {
    return this.post('/changePhoneNumber', { phoneNumber, password }).then(payload => payload.data);
  }

  changeEmail(email, password) {
    return this.post('/changeEmail', { email, password }).then(payload => payload.data);
  }

  updatePassword(currentPassword, newPassword) {
    return this.post('/updatePassword', { currentPassword, newPassword });
  }

  verifyEmail(emailToken) {
    return this.post('/verifyEmail', { emailToken }).then(payload => payload.data);
  }

  verifyPhoneNumber(phoneNumberToken) {
    return this.post('/verifyPhoneNumber', { phoneNumberToken }).then(payload => payload.data);
  }

  validatePhoneNumber(phoneNumber) {
    return this.post('/validatePhoneNumber', { phoneNumber }).then(payload => payload.data);
  }
}

export default UsersApi;
