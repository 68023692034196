import * as ActionTypes from './actionTypes';

// Allowing flow updates for things like current step, results etc...
export const updateFlow = (name, flowUpdates) => ({
  type: ActionTypes.UPDATE_FLOW,
  payload: { name, ...flowUpdates },
});

export const updateFlowData = (name, dataUpdates) => ({
  type: ActionTypes.UPDATE_FLOW_DATA,
  payload: { name, dataUpdates },
});

export const initFlow = (
  name,
  definitions,
  initialStep,
  flowId,
  flowState,
  experimentalQSSupport,
  mapFlowDataToAnalytics,
) => ({
  type: ActionTypes.INIT_FLOW,
  payload: {
    name,
    config: {
      name,
      definitions,
      initialStep,
      flowId,
      experimentalQSSupport,
      mapFlowDataToAnalytics,
    },
    flowState,
  },
});

export const resetFlow = name => ({
  type: ActionTypes.RESET_FLOW,
  payload: { name },
});
