/**
 * Created by chenrozenes on 27/03/2017.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { intlErrorNotification, intlInfoNotification } from './actions';

/**
 * Injects a "notification" prop to the component that allows to notify for errors and success
 * The object will have 2 methods: error and success
 * The notifications are a banner that pop out in the top-right corner of the screen
 * Must include the NotificationContainer component in the page
 * @param Component
 * @returns {*}
 */
export default function injectNotification(Component) {
  class Wrap extends React.PureComponent {
    static propTypes = {
      showError: PropTypes.func.isRequired,
      showSuccess: PropTypes.func.isRequired
    };

    render() {
      const { showError, showSuccess, ...other } = this.props;

      const notification = {
        error: showError,
        success: showSuccess
      };

      return (<Component {...other} notification={notification} />);
    }
  }

  return compose(
    injectIntl,
    connect(null, (dispatch, props) => ({
      showError(title, message, options) {
        dispatch(intlErrorNotification(props.intl, title, message, options));
      },
      showSuccess(title, message, options) {
        dispatch(intlInfoNotification(props.intl, title, message, options));
      }
    }))
  )(Wrap);
}
