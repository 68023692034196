/**
 * Created by guyavarham on 20/07/2017.
 */

import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, } from 'react-intl';
import { autobind } from 'core-decorators';

import IntlString from '../ui/intlString/intlString';
import { messages } from '../../messages/searchDoctorResults/messages';

@autobind
class SearchPreview extends React.PureComponent {

  static defaultProps = {
    searchTerm: '',
  };

  static propTypes = {
    searchTerm: PropTypes.string,
    onClick: PropTypes.func.isRequired,
    chosenLocation: PropTypes.object.isRequired,
  };

  render() {
    const {
      searchTerm, onClick, chosenLocation
    } = this.props;
    if (_.isEmpty(chosenLocation)) return null;

    const { displayName, address, icon = 'icon-map-pin-e' } = chosenLocation;
    const shortAddress = address && address.split(',')[0];
    return (
      <div className="selected-location">
        {searchTerm}
        &nbsp;
        <i className={icon} />
        <span className="text-dark text-semibold">
          <FormattedMessage {...messages.near} />
          &nbsp;
          <button className="btn btn-inline-link font-color-brand-link" onClick={onClick}>
            <IntlString message={displayName || shortAddress} />
          </button>
        </span>
      </div>
    );
  }
}

export default SearchPreview;
