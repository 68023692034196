import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '../../storybook/src/utils/theme';

function SvgImaging({ theme }) {
  /* eslint-disable */
  return (
<svg aria-hidden="true" focusable="false" className="svg-icon svg-imaging" width="32px" height="32px" viewBox="0 0 32 32" version="1.1">
  <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
    <g transform="translate(-69.000000, -295.000000)">
      <g transform="translate(69.000000, 295.000000)">
        <path d="M22.5,17 L20.5,17 L17,17 L17,14 L22.5,14 C23.327,14 24,14.673 24,15.5 C24,16.327 23.327,17 22.5,17 L22.5,17 Z M20.5,22 L17,22 L17,19 L20.5,19 C21.327,19 22,19.673 22,20.5 C22,21.327 21.327,22 20.5,22 L20.5,22 Z M11.5,22 C10.673,22 10,21.327 10,20.5 C10,19.673 10.673,19 11.5,19 L15,19 L15,22 L11.5,22 Z M8,15.5 C8,14.673 8.673,14 9.5,14 L15,14 L15,17 L11.5,17 L9.5,17 C8.673,17 8,16.327 8,15.5 L8,15.5 Z M28,10.5 C28,8.57 26.43,7 24.5,7 C23.947,7 23.5,7.448 23.5,8 C23.5,8.552 23.947,9 24.5,9 C25.327,9 26,9.673 26,10.5 C26,11.327 25.327,12 24.5,12 L22.5,12 L17,12 L17,6 C17,5.448 16.553,5 16,5 C15.447,5 15,5.448 15,6 L15,12 L9.5,12 L7.5,12 C6.673,12 6,11.327 6,10.5 C6,9.673 6.673,9 7.5,9 C8.053,9 8.5,8.552 8.5,8 C8.5,7.448 8.053,7 7.5,7 C5.57,7 4,8.57 4,10.5 C4,12.061 5.034,13.371 6.448,13.821 C6.172,14.322 6,14.889 6,15.5 C6,17.061 7.034,18.371 8.448,18.821 C8.172,19.322 8,19.889 8,20.5 C8,22.43 9.57,24 11.5,24 L15,24 L15,26 C15,26.552 15.447,27 16,27 C16.553,27 17,26.552 17,26 L17,24 L20.5,24 C22.43,24 24,22.43 24,20.5 C24,19.889 23.828,19.322 23.552,18.821 C24.966,18.371 26,17.061 26,15.5 C26,14.889 25.828,14.322 25.552,13.821 C26.966,13.371 28,12.061 28,10.5 L28,10.5 Z" fill={theme.secondaryColor}></path>
        <path d="M5,2 C3.346,2 2,3.346 2,5 L2,27 C2,28.654 3.346,30 5,30 L27,30 C28.654,30 30,28.654 30,27 L30,5 C30,3.346 28.654,2 27,2 L5,2 Z M27,32 L5,32 C2.243,32 0,29.757 0,27 L0,5 C0,2.243 2.243,0 5,0 L27,0 C29.757,0 32,2.243 32,5 L32,27 C32,29.757 29.757,32 27,32 L27,32 Z" fill={theme.mainColor}></path>
      </g>
    </g>
  </g>
</svg>
  );
  /* eslint-enable */
}

SvgImaging.propTypes = {
  theme: PropTypes.object.isRequired,
};

export default withTheme(SvgImaging);
