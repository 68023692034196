/**
 * Created by chenrozenes on 31/08/2016.
 */

export const ACTION_TRACKER_CLEAR_DATA = 'action-tracker/CLEAR_DATA';
export const ACTION_TRACKER_START_MARK = 'action-tracker/START_MARK';
export const ACTION_TRACKER_SUCCESS_MARK = 'action-tracker/SUCCESS_MARK';
export const ACTION_TRACKER_ERROR_MARK = 'action-tracker/ERROR_MARK';


/**
 * Deletes from the state the saved data for the provided actions' keys
 * @param keys
 * @returns {{type: string, payload: *}}
 */
export function clearTrackingData(keys) {
  return {
    type: ACTION_TRACKER_CLEAR_DATA,
    payload: keys
  };
}

/**
 * Marks the action as started and now its on progress
 * @param actionKey
 * @param meta
 * @returns {{type: string, payload: *}}
 */
export function startMark(actionKey, meta) {
  return {
    type: ACTION_TRACKER_START_MARK,
    payload: { actionKey, meta }
  };
}

/**
 * Marks the action as succeeded
 * @param actionKey
 * @param meta
 * @returns {{type: string, payload: *}}
 */
export function successMark(actionKey, meta) {
  return {
    type: ACTION_TRACKER_SUCCESS_MARK,
    payload: { actionKey, meta }
  };
}

/**
 * Marks the action as failed, and stores its error
 * @param actionKey
 * @param errorPayload
 * @param meta
 * @returns {{type: string, payload: {actionKey: *, errorPayload: *}}}
 */
export function errorMark(actionKey, errorPayload, meta) {
  return {
    type: ACTION_TRACKER_ERROR_MARK,
    payload: { actionKey, errorPayload, meta }
  };
}
