/**
 * Created by meirshalev 19/06/2018.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { isAuthenticated } from '../../store/auth/selectors';

@autobind
class LoggedIn extends React.PureComponent {

  static defaultProps = {
    guest: false,
    hide: false,
    renderLi: false,
  };

  static propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
    guest: PropTypes.bool,
    hide: PropTypes.bool,
    renderLi: PropTypes.bool,
  };

  renderChildren() {
    return this.props.children;
  }

  renderLi() {
    const { children, ...other } = this.props;
    return (
      <li {...other}>
        {children}
      </li>
    );
  }

  render() {
    const { isAuthenticated, guest, hide, renderLi } = this.props;
    if (!isAuthenticated && !guest) return null;
    if (guest && isAuthenticated) return null;
    // Check if the feature is defined and turned off, or if it is hidden by choice
    if (hide) return null;
    return renderLi ? this.renderLi() : this.renderChildren();
  }
}

export default compose(
  connect(state => ({
    isAuthenticated: isAuthenticated(state),
  }))
)(LoggedIn);
