import { compose, lifecycle, withHandlers, withPropsOnChange } from 'recompose';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import _ from 'lodash';
import { pathsWithQuery } from '../../utils/pathsWithQuery';
import { activationToken } from '../../store/activation/selectors';
import { isAuthenticated, isAuthLoading } from '../../store/auth/selectors';
import { loadFromStorage } from '../../store/auth/actions';

/**
 * Will redirect the user to activation flow and then redirect to activationNextPage
 * Very useful in landing pages when we don't have the context of the member
 * @param activationCampaignSource - The activation campaign source
 * @param activationNextPage - Page to redirect after activation success
 * @param disableSignup - Determines if the activation flow will require the user to signup or not
 * @return {*}
 */
const withActivationFlow = ({
  activationCampaignSource,
  activationNextPage,
  disableSignup = true,
}) =>
  compose(
    withRouter,
    withHandlers({
      redirectToActivationFlow: props => () =>
        props.router.push(
          pathsWithQuery(`/start/${activationCampaignSource}`, {
            next: _.isFunction(activationNextPage) ? activationNextPage(props) : activationNextPage,
            disableSignup,
          }),
        ),
    }),
    connect(
      state => ({
        activationToken: activationToken(state),
        isAuthLoading: isAuthLoading(state),
        isAuthenticated: isAuthenticated(state),
      }),
      { loadFromStorage },
    ),
    lifecycle({
      componentDidMount() {
        this.props.loadFromStorage();
      },
    }),
    withPropsOnChange(
      ['activationToken', 'isAuthenticated', 'isAuthLoading'],
      ({ activationToken, isAuthenticated, redirectToActivationFlow, isAuthLoading }) => {
        const finishLoadingAuthStatus = isAuthLoading === false;
        if (_.isEmpty(activationToken) && finishLoadingAuthStatus && !isAuthenticated) {
          // @TODO This is a side effect inside the withPropsOnChange callback,
          // it considered as bad practice but currently we don't have a better solution
          // @mrsufgi @nico
          redirectToActivationFlow();
          return { hasToken: false };
        }
        // hasToken should be true only when finished loading the user
        return { hasToken: finishLoadingAuthStatus };
      },
    ),
  );

export default withActivationFlow;
