import Immutable from 'seamless-immutable';

import {
  OPEN_SEARCH_MODAL,
  SET_DROP_DOWN_OPEN,
  OPEN_MOBILE_MENU,
  FOCUS_SEARCH_TYPEAHEAD,
  TOGGLE_SIDEBAR_MENU,
} from './actions';

const initialState = {
  isDropDownOpen: false,
  isSearchModalOpen: false,
  isMobileMenuOpen: false,
  focusSearchTypeaheadNow: false,
  isSidebarMenuOpened: true,
};

export default function ui(state = initialState, action) {
  state = Immutable(state);

  switch (action.type) {
    case SET_DROP_DOWN_OPEN:
      return state.merge({ isDropDownOpen: action.payload });
    case OPEN_SEARCH_MODAL:
      return state.merge({ isSearchModalOpen: action.payload });
    case OPEN_MOBILE_MENU:
      return state.merge({ isMobileMenuOpen: action.payload });
    case FOCUS_SEARCH_TYPEAHEAD:
      return state.merge({ focusSearchTypeaheadNow: action.payload });
    case TOGGLE_SIDEBAR_MENU:
      return state.merge({ isSidebarMenuOpened: !state.isSidebarMenuOpened });
  }

  return state;
}
