import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '../../storybook/src/utils/theme';

function SvgHome({ theme }) {
  /* eslint-disable */
  return (

    <svg aria-hidden="true" focusable="false" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="svg-icon svg-home">
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Post-Visit-=-Get-Started-08-Copy" transform="translate(-49.000000, -633.000000)">
          <g id="Group-4" transform="translate(49.000000, 633.000000)">
            <g id="Group-15" transform="translate(1.000000, 0.000000)">
              <path d="M20.2943333,24 C19.9256667,24 19.6276667,23.702 19.6276667,23.3333333 L19.6276667,8.324 L10.961,1.514 L2.29433333,8.324 L2.29433333,23.3333333 C2.29433333,23.702 1.99633333,24 1.62766667,24 C1.259,24 0.961,23.702 0.961,23.3333333 L0.961,8 C0.961,7.79533333 1.055,7.602 1.21566667,7.476 L10.549,0.142666667 C10.791,-0.0473333333 11.131,-0.0473333333 11.373,0.142666667 L20.7063333,7.476 C20.867,7.602 20.961,7.79533333 20.961,8 L20.961,23.3333333 C20.961,23.702 20.663,24 20.2943333,24" id="Fill-2" fill={theme.mainColor} ></path>
              <path d="M14.2943333,24 C13.9256667,24 13.6276667,23.702 13.6276667,23.3333333 L13.6276667,16 L8.29433333,16 L8.29433333,23.3333333 C8.29433333,23.702 7.99633333,24 7.62766667,24 C7.259,24 6.961,23.702 6.961,23.3333333 L6.961,15.3333333 C6.961,14.9646667 7.259,14.6666667 7.62766667,14.6666667 L14.2943333,14.6666667 C14.663,14.6666667 14.961,14.9646667 14.961,15.3333333 L14.961,23.3333333 C14.961,23.702 14.663,24 14.2943333,24" id="Fill-9" fill={theme.secondaryColor} ></path>
              <path d="M8.60813333,10.6666667 L13.6274667,10.6666667 L13.6274667,7.62933333 L11.1181333,5.65666667 L8.60813333,7.62933333 L8.60813333,10.6666667 Z M14.2941333,12 L7.94146667,12 C7.5728,12 7.2748,11.702 7.2748,11.3333333 L7.2748,7.30533333 C7.2748,7.10066667 7.3688,6.90733333 7.52946667,6.78133333 L10.7054667,4.28533333 C10.9481333,4.09533333 11.2881333,4.09533333 11.5301333,4.28533333 L14.7061333,6.78133333 C14.8674667,6.90733333 14.9608,7.10066667 14.9608,7.30533333 L14.9608,11.3333333 C14.9608,11.702 14.6628,12 14.2941333,12 Z" id="Fill-13" fill={theme.secondaryColor} ></path>
            </g>
            <g id="Page-1" fill={theme.mainColor} >
              <path d="M23.3327333,10.2382667 C23.1887333,10.2382667 23.0434,10.1922667 22.9214,10.0956 L12.0000667,1.51426667 L1.07873333,10.0956 C0.788066667,10.3249333 0.370066667,10.2729333 0.142733333,9.9836 C-0.0852666667,9.69426667 -0.0346,9.27493333 0.254733333,9.0476 L11.5880667,0.142266667 C11.8300667,-0.0477333333 12.1700667,-0.0477333333 12.4120667,0.142266667 L23.7454,9.0476 C24.0347333,9.27493333 24.0854,9.69426667 23.8574,9.9836 C23.7260667,10.1509333 23.5307333,10.2382667 23.3327333,10.2382667" id="Fill-1"></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
  /* eslint-enable */
}

SvgHome.propTypes = {
  theme: PropTypes.object.isRequired,
};

export default withTheme(SvgHome);
