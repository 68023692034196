/**
 * Created by matan on 3/14/17.
 */
const PROMISE_START = 'START';
const PROMISE_SUCCESS = 'SUCCESS';
const PROMISE_ERROR = 'ERROR';

export default class PromiseActionType {
  /**
   * Simple wrap for redux action types that are used with the promise middleware.
   * Simplify the usage with the middleware suffixes
   * @param {string} actionType - action type name, stored under 'SOURCE'
   * @constructor
   */
  constructor(actionType) {
    this.SOURCE = actionType;
    this.START = `${actionType}_${PROMISE_START}`;
    this.SUCCESS = `${actionType}_${PROMISE_SUCCESS}`;
    this.ERROR = `${actionType}_${PROMISE_ERROR}`;
  }

  toString() {
    return this.SOURCE;
  }
}
