import { autobind } from 'core-decorators';
import _ from 'lodash';
import BkmdApi from './bkmdApi';
import { BkmdDomains } from '../model/enum/bkmdDomain';
import ServicesApi from './services';
import * as NetworkStatusEnum from '../model/enum/networkStatus';

const SERVICE_NAME = 'member';
const DOMAIN_MEMBER_SERVICE_NAME = 'domainMember';

@autobind
export default class MembersApi extends BkmdApi {
  constructor(fetch) {
    super(fetch, '/members/');
    this.servicesApi = new ServicesApi(fetch);
  }

  getByUserId() {
    return this.get('/');
  }

  updateMemberState(stateDescription, value) {
    return this.post('/updateMemberState', { stateDescription, value }).then(res => res.data);
  }

  getMemberPcpSelectionsBackoffice(domainMemberId, domain) {
    return this.servicesApi.callSecure(
      DOMAIN_MEMBER_SERVICE_NAME,
      'getMemberPcpSelectionsBackoffice',
      {
        domainMemberId,
      },
      domain,
    );
  }

  updatePcpSelectionBackoffice(domainMemberId, npi, locationId, domain) {
    return this.servicesApi.callSecure(
      DOMAIN_MEMBER_SERVICE_NAME,
      'updatePcpSelectionBackoffice',
      { domainMemberId, npi, locationId },
      domain,
    );
  }

  getFullMemberForProvider(domainMemberId, domain, ehrPatientId) {
    return this.get(
      '/getFullMemberForProvider',
      { domainMemberId, ehrPatientId },
      undefined,
      domain,
    );
  }

  getFullMemberForVimGuide(domainMemberId, domain) {
    return this.get('/getFullMemberForVimGuide', { domainMemberId }, undefined, domain);
  }

  getFullMemberForBackpack(domainMemberId, memberContext, domain) {
    return this.servicesApi
      .callSecure(
        SERVICE_NAME,
        'getFullMemberForBackpack',
        {
          domainMemberId,
          memberContext,
        },
        domain,
      )
      .then(res => res.data);
  }

  isInNetwork(providerNpi, providerLocationGeo, domainMemberId, memberDomain) {
    // elastic lat, postgres latitude
    const formattedGeo = {
      latitude: providerLocationGeo.latitude || providerLocationGeo.lat,
      longitude: providerLocationGeo.longitude || providerLocationGeo.lon,
    };
    return this.servicesApi
      .callSecure(
        DOMAIN_MEMBER_SERVICE_NAME,
        'isInNetwork',
        { providerNpi, providerLocationGeo: formattedGeo, domainMemberId },
        memberDomain,
      )
      .then(response => _.get(response, 'data.result'))
      .catch(() => NetworkStatusEnum.UNVERIFIED_NETWORK);
  }

  update(id, data) {
    return this.put(`update/${id}`, data);
  }

  getSettings() {
    return this.get('/getMemberSettings');
  }

  isOptOut() {
    return this.servicesApi.callSecure(SERVICE_NAME, 'isOptOut').then(({ data }) => data);
  }

  updateSmsOptStatus(shouldOptIn) {
    return this.servicesApi.callSecure(SERVICE_NAME, 'updateSmsOptStatus', { shouldOptIn });
  }

  getMatchingMembersByName(name) {
    return this.servicesApi.callSecure(SERVICE_NAME, 'getMatchingMembersByName', { name });
  }

  changeNotificationPreferences(preferences) {
    return this.post('/changeNotificationPreferences', {
      preferences,
    }).then(settings => settings.data);
  }

  changeLocationPermissions(allow) {
    return this.post('/changeLocationPermissions', { allow }).then(settings => settings.data);
  }

  addInsuranceInfo(data) {
    return this.post('addInsuranceInfo', data).then(res => res.data);
  }

  changeAddress(addressType, address) {
    return this.post('/changeAddress', { addressType, address }).then(settings => settings.data);
  }

  getCareCues() {
    return this.get('/careCues');
  }

  getMedicationSummary() {
    return this.get('/getMedicationsSummary');
  }

  getDrugHistory(drugName) {
    return this.get('/getDrugHistory', { drugName });
  }

  getPcpSelection() {
    return this.get('/getPcpSelection').then(res => _.get(res, 'data', res)); // supports null/undefined returns
  }

  updatePcpSelection(domainMemberId, npi, locationId) {
    return this.post('/updatePcpSelection', { domainMemberId, npi, locationId }).then(
      res => res.data,
    );
  }

  fetchActivationByToken(token) {
    return this.servicesApi
      .call(SERVICE_NAME, 'fetchActivationByToken', { token })
      .then(activation => activation.data);
  }

  /**
   * Get all the users
   * @param skip
   * @param limit
   * @param search
   * @param sort - column to sort
   * @param sortAscending - true if ascending
   * @returns {*}
   */
  getAllUsers(skip, limit, search, sort, sortAscending) {
    return this.servicesApi.callSecure(SERVICE_NAME, 'getAll', {
      limit,
      skip,
      search,
      sort,
      sortAscending,
    });
  }

  /**
   * Get the user object
   * @param user
   * @returns {*}
   */
  getUser(id) {
    return this.servicesApi.callSecure(SERVICE_NAME, 'getMemberFromBackoffice', { id });
  }

  hasFamilyMembers() {
    return this.get('/hasDependents');
  }

  getFamilyMembers(withPcp = false) {
    return this.get('/getMemberDependents', { withPcp });
  }

  importMemberToDomain(fromDomain, fromDomainMemberId, toDomain, toDomainMemberId) {
    return this.post('/importMemberToDomain', {
      fromDomain,
      fromDomainMemberId,
      toDomain,
      toDomainMemberId,
    });
  }

  getAllDomainMembers(skip, limit, search, sort, sortAscending) {
    return this.servicesApi.callSecure(DOMAIN_MEMBER_SERVICE_NAME, 'getAllMembers', {
      limit,
      offset: skip,
      search,
      sort,
      sortAscending,
    });
  }

  deactivateMember(domainMemberId, domain) {
    return this.servicesApi
      .callSecure(SERVICE_NAME, 'deactivateMember', { domainMemberId }, domain)
      .then(res => res.data);
  }

  loadDummyMembers() {
    return this.servicesApi.callSecure(
      DOMAIN_MEMBER_SERVICE_NAME,
      'loadDummyMembers',
      {},
      BkmdDomains.PREMERA_BLUE_CROSS_ALASKA,
    );
  }

  attachPastActivity(currentDomainMemberId, newDomainMemberId, validateMembers) {
    return this.servicesApi.callSecure(DOMAIN_MEMBER_SERVICE_NAME, 'attachPastActivity', {
      currentDomainMemberId,
      newDomainMemberId,
      validateMembers,
    });
  }

  urlAuthentication(payload) {
    return this.post('/urlAuthentication', payload);
  }
}
