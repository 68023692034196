import React from 'react';
import { defineMessages } from 'react-intl';
import LoadingComponent from '../../../../components/pages/loading/loadingComponent';

const messages = defineMessages({
  loadingScreenTitle: {
    defaultMessage: 'Loading appointment',
    id: 'members.appointmentPage.loadingScreenTitle',
  },
  loadingScreenMessage: {
    defaultMessage: "Finding your appointment's info",
    id: 'members.appointmentPage.loadingScreenMessage',
  },
});

export default function loadingPage() {
  return (
    <LoadingComponent
      headerMessage={messages.loadingScreenTitle}
      contentMessage={messages.loadingScreenMessage}
    />
  );
}
