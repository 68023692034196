/**
 * Created by moran on 8/10/17.
 */
import * as Gender from '../../../../../model/enum/gender';

function isPositive(rating) {
  return rating > 3;
}

function genderPicker(data, gender) {
  if (!data[gender]) return data[Gender.MALE];
  return data[gender];
}

export { isPositive, genderPicker };
