import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import _ from 'lodash';
import { defineMessages, FormattedMessage, injectIntl, intlShape } from 'react-intl';
import SvgCancelAppointment from '../../assets/svg/svgCancelAppointment';
import BkmdModal from '../bkmdModal/bkmdModal';
import ModalHeader from '../bkmdModal/modalHeader';
import './styles.less';

const messages = defineMessages({
  cancelAppointment: {
    defaultMessage: 'Cancel Appointment',
    id: 'appointments.cancelModal.cancelAppointment',
  },
  confirm: {
    defaultMessage: 'Confirm',
    id: 'appointments.cancelModal.confirm',
  },
  subTitle: {
    defaultMessage:
      'Note that even after cancelling, the appointment will still appear\n' +
      'until the appointment date has passed',
    id: 'appointments.cancelModal.subTitle',
  },
  title: {
    defaultMessage: `To cancel the appointment, please call {name} at {phoneNumber}.`,
    id: 'appointments.cancelModal.title',
  },
  conciergeTitle: {
    defaultMessage: `Please call our concierge desk at 855-784-4563 (TRS: 711), weekdays, 7 a.m. to 5 p.m. Pacific Time to cancel.`,
    id: 'appointments.cancelModal.conciergeTitle',
  },
});

class CancelAppointmentModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  static propTypes = {
    intl: intlShape.isRequired,
  };

  render() {
    const { isOpen, onClose, name, phoneNumber, intl, startTime } = this.props;

    return (
      <BkmdModal
        id="cancel-appointment"
        disableKeyDown
        handleClose={_.noop}
        isOpen={isOpen}
        name="cancel-appointment"
        className="cancel-appointment-dialog fixed-header overflow-visible"
      >
        <ModalHeader title={intl.formatMessage(messages.cancelAppointment)} onClose={onClose} />
        <div className="dialog-body">
          <Row>
            <Col xs={12}>
              <div className="cancel-appointment">
                <div className="cancel-appointment__icon">
                  <SvgCancelAppointment />
                </div>
                {_.isNil(startTime) ? (
                  <div className="cancel-appointment__title cancel-appointment__concierge-title">
                    <FormattedMessage {...messages.conciergeTitle} />
                  </div>
                ) : (
                  <div className="cancel-appointment__title">
                    <FormattedMessage {...messages.title} values={{ name, phoneNumber }} />
                  </div>
                )}
                <div className="cancel-appointment__sub-title">
                  <FormattedMessage {...messages.subTitle} />
                </div>
              </div>
            </Col>
          </Row>

          <div className="dialog-footer">
            <Row className="dialog-footer__btn-wrapper">
              <Button
                bsPrefix="btn btn-primary"
                className="bg-color-brand-button pull-right"
                onClick={onClose}
              >
                <FormattedMessage {...messages.confirm} />
              </Button>
            </Row>
          </div>
        </div>
      </BkmdModal>
    );
  }
}

export default injectIntl(CancelAppointmentModal);
