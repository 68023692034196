/**
 * Created by chenrozenes on 08/06/2016.
 */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { IntlString, MessageShape } from '../ui/intlString';
import ModalBackButton from './modalBackButton';
import ModalCloseButton from './modalCloseButton';
import './dialog.less';

/**
 * Simple header for BkmdModal
 */
export default class ModalHeaderComponent extends React.Component {
  static propTypes = {
    /**
     * Header top level class name
     */
    className: PropTypes.string,
    /**
     * Header title.
     * Can be either string or an intl message obj
     */
    title: MessageShape,
    /**
     * Method hook.
     * Called when the user requests to close the modal via the header.
     */
    onClose: PropTypes.func,
    /**
     * Method hook.
     * If provided a back button will be added to the modal.
     * Method will be called for every user click.
     */
    onBack: PropTypes.func,
  };

  static defaultProps = {
    title: '',
    onBack: null,
    className: null,
    onClose: null,
  };

  render() {
    const { className, onBack, onClose, title } = this.props;
    return (
      <div className={classNames('dialog-header', className)}>
        {!onBack ? null : <ModalBackButton onBack={onBack} />}
        {title &&
        (
          <h1 className="modal-title font-color-brand-main">
            <IntlString message={title} />
          </h1>
        )}
        {!onClose ? null :
        <ModalCloseButton
          className="close-icon pull-right"
          handleClose={onClose}
        />
        }
        {this.props.children}
      </div>
    );
  }
}

