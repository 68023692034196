/**
 * Created by noahguld on 25/06/2017.
 */
import moment from 'moment';
import commonFormats from '../../utils/formatter/commonFormats';

/**
 * convert a date to a moment object // https://github.com/rtfeldman/seamless-immutable/issues/218
 * @param dateString - in format 'DEFAULT_DATE_AND_TIME' - 'YYYY-MM-DD HH:mm'
 * @returns a moment object
 */
export function toMoment(dateString) {
  return moment(dateString, commonFormats.DEFAULT_DATE_AND_TIME);
}

/**
 * convert a momentObj to dateString // https://github.com/rtfeldman/seamless-immutable/issues/218
 * @param momentObj
 * @returns dateString in format 'DEFAULT_DATE_AND_TIME' - 'YYYY-MM-DD HH:mm'
 */
export function toDateString(momentObj) {
  return momentObj.format(commonFormats.DEFAULT_DATE_AND_TIME);
}

