/**
 * Created by chenrozenes on 26/04/2017.
 */
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

export default function ToStringColumn({ value }) {
  return <span>{_.toString(value)}</span>;
}

ToStringColumn.propTypes = {
  value: PropTypes.any
};

ToStringColumn.defaultProps = {
  value: ''
};
