/**
 * Created by meirshalev on 01/06/2017.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import FlowManager from '../../../../../../components/wizard/oldFlowManager';
import PhoneNumberStep from './phoneNumberStep';
import VerificationStep from './verificationStep';
import * as FormDefs from './updatePhoneNumberDef';

import { getUser } from '../../../../../../store/auth/actions';

const getSteps = function getSteps() {
  return [
    {
      component: PhoneNumberStep,
    },
    {
      component: VerificationStep,
    },
    {
      component: () => <div />,
    },
  ];
};
@autobind
class UpdatePhoneNumberPage extends React.Component {
  static propTypes = {
    getUser: PropTypes.func.isRequired,
  };

  componentWillUnmount() {
    this.props.getUser();
  }

  render() {
    return (
      <FlowManager
        formName={FormDefs.name}
        steps={getSteps()}
        maxSteps={3}
        enforceFirstStep={false}
      />
    );
  }
}

export default connect(
  null,
  { getUser },
)(UpdatePhoneNumberPage);
