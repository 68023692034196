/**
 * Created by anna on 03/10/2017.
 */
import PromiseActionType from '../promiseActionType';

export const HAS_FAMILY_MEMBERS = new PromiseActionType('HAS_FAMILY_MEMBERS');
export const GET_FAMILY_MEMBERS = new PromiseActionType('GET_FAMILY_MEMBERS');
export const ACTIVATE_KINDS = new PromiseActionType('ACTIVATE_KINDS');
export const INVITE_FAMILY_MEMBER = new PromiseActionType('ACTIVATE_FAMILY');
export const SET_ACTIVATION_STEP = 'SET_ACTIVATION_STEP';

export function activateFamily(source) {
  return ({ bkmdApi: { activationApi } }) => ({
    type: ACTIVATE_KINDS.SOURCE,
    payload: {
      promise: activationApi.activateFamily(source)
    },
    meta: {
      tracker: ACTIVATE_KINDS.SOURCE,
    }
  });
}

export function hasFamilyMembers() {
  return ({ bkmdApi: { membersApi } }) => ({
    type: HAS_FAMILY_MEMBERS,
    payload: {
      promise: membersApi.hasFamilyMembers()
    }
  });
}

export function getFamilyMembers() {
  return ({ bkmdApi: { membersApi } }) => ({
    type: GET_FAMILY_MEMBERS.SOURCE,
    payload: {
      promise: membersApi.getFamilyMembers()
    },
    meta: {
      tracker: GET_FAMILY_MEMBERS.SOURCE,
    }
  });
}

export function inviteDependent(dependentWithContactInfo) {
  return ({ bkmdApi: { communicationApi } }) => {
    const { domainMemberId } = dependentWithContactInfo;
    const action = {
      type: INVITE_FAMILY_MEMBER.SOURCE,
      payload: {
        promise: communicationApi.inviteDependent(dependentWithContactInfo)
      },
      meta: {
        tracker: `${INVITE_FAMILY_MEMBER}-${domainMemberId}`,
      }
    };
    return action;
  };
}

export function setActivationStep(step) {
  return () => ({
    type: SET_ACTIVATION_STEP,
    payload: step,
  });
}
