import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { FormattedMessage, defineMessages } from 'react-intl';
import withFeatureFlag from 'Utils/featureFlags/withFeatureFlag';
import { branch, compose, renderNothing } from 'recompose';
import Button from '../Button/Button';
import * as BookingType from '../../../../../model/enum/bookingType';

const messages = defineMessages({
  schedule: {
    defaultMessage: 'Schedule',
    id: 'common.searchDoctorsResults.schedule',
  },
  book: {
    defaultMessage: 'Book',
    id: 'common.searchDoctorsResults.book',
  },
  request: {
    defaultMessage: 'Request appointment',
    id: 'common.searchDoctorsResults.request',
  },
});

function getButtonText(bookingType, scheduleButton, showNewBookButton) {
  let btnText;
  if (showNewBookButton) {
    switch (bookingType) {
      case BookingType.CONCIERGE:
        btnText = scheduleButton ? messages.schedule : messages.request;
        break;
      case BookingType.HYBRID:
      case BookingType.REQUEST:
        btnText = messages.request;
        break;
      case BookingType.INSTANT:
      default:
        btnText = messages.book;
        break;
    }
  } else {
    btnText =
      scheduleButton && bookingType === BookingType.CONCIERGE ? messages.schedule : messages.book;
  }

  return btnText;
}

function BookButton({
  btnClassName,
  scheduleButton,
  bookingType,
  inProgress,
  onClick,
  showNewBookButton,
}) {
  return (
    <Button className={btnClassName} onClick={onClick} isLoading={inProgress}>
      <span>
        {scheduleButton && bookingType === BookingType.CONCIERGE && (
          <i className="icon-datepicker-s i-va-fix-2" />
        )}
        {bookingType === BookingType.INSTANT && <i className="icon-lightning i-va-fix-3" />}
        &nbsp;
        <FormattedMessage {...getButtonText(bookingType, scheduleButton, showNewBookButton)} />
      </span>
    </Button>
  );
}

BookButton.propTypes = {
  bookingType: PropTypes.string.isRequired,
  btnClassName: PropTypes.string,
  scheduleButton: PropTypes.bool,
  inProgress: PropTypes.bool,
  onClick: PropTypes.func,
  showNewBookButton: PropTypes.bool,
};

BookButton.defaultProps = {
  btnClassName: undefined,
  scheduleButton: false,
  inProgress: false,
  onClick: _.noop,
  showNewBookButton: false,
};

export default compose(
  branch(
    ({ bookingType }) => _.isNil(bookingType) || bookingType === BookingType.NONE,
    renderNothing,
  ),
  withFeatureFlag({
    key: 'storybook.newBookButton',
    prop: 'showNewBookButton',
    defaultValue: false,
  }),
)(BookButton);
