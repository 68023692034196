/**
 * Created by guyavarham on 12/06/2017.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import { connect } from 'react-redux';

import EnterInsuranceId from './enterInsuranceID.presentational';
import Layout from '../layout/layout';
import { goToNext } from '../../../../utils/reRouting';
import { getPathsFromContext } from '../routes/injectPathsToContext';
import { hasInsuranceIdSelector } from '../../../../store/member/selectors';

class EnterInsuranceIdWrapper extends React.Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
    paths: PropTypes.object.isRequired,
    hasInsuranceId: PropTypes.bool,
  };

  static defaultProps = {
    hasInsuranceId: false,
  };

  /**
   * C'tor implemented for binding class methods because of an issue when binding with autobind.
   * @autobind of core-decorator does not work well with react context.
   * The issue is when using context in class methods its properties are empty (paths on our case).
   * Need to remove the use of react context and put the paths on redux store.
   */
  constructor() {
    super();
    this.onLaterClick = this.onLaterClick.bind(this);
    this.onNext = this.onNext.bind(this);
  }

  componentDidMount() {
    if (this.props.hasInsuranceId) {
      this.onNext();
    }
  }

  onLaterClick() {
    const { router, paths } = this.props;
    router.push(paths.home());
  }

  onNext() {
    const {
      paths,
      router,
      location: { query },
    } = this.props;
    goToNext(router, query, paths.home());
  }

  render() {
    return (
      <Layout>
        <EnterInsuranceId onLaterClick={this.onLaterClick} onNext={this.onNext} />
      </Layout>
    );
  }
}

export default compose(
  getPathsFromContext(),
  withRouter,
  connect(state => ({
    hasInsuranceId: hasInsuranceIdSelector(state),
  })),
)(EnterInsuranceIdWrapper);
