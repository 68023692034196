/**
 * Created by chenrozenes on 09/08/2017.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '../../storybook/src/utils/theme';


function SvgSigninMorning({ theme }) {
  /* eslint-disable */
  return (

    <svg aria-hidden="true" focusable="false" width="265px" height="123px" viewBox="0 0 265 123" version="1.1" className="svg-icon svg-signin-afternoon">
      <defs>
        <polygon points="108.162 0.7368 108.162 57.0498 0.6016 57.0498 0.6016 0.7368"></polygon>
        <polygon points="0.0003 25 69.9883 25 69.9883 0.0225 0.0003 0.0225"></polygon>
        <polygon points="0.6018 25 29.5858 25 29.5858 0.0225 0.6018 0.0225 0.6018 25"></polygon>
      </defs>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-55.000000, -137.000000)">
          <g transform="translate(-1.000000, 120.000000)">
            <g transform="translate(56.000000, 17.000000)">
              <g transform="translate(156.000000, 20.710900)">
                <g></g>
                <path d="M64.1016,57.0498 L18.9566,57.0498 C13.5066,57.0498 9.0736,52.6138 9.0736,47.1618 C9.0736,41.7098 13.5066,37.2748 18.9566,37.2748 L21.8626,37.2748 C24.9286,37.2748 27.4236,34.7808 27.4236,31.7158 C27.4236,28.6498 24.9286,26.1548 21.8626,26.1548 L13.3106,26.1548 C6.3026,26.1548 0.6016,20.4528 0.6016,13.4458 C0.6016,6.4378 6.3026,0.7368 13.3106,0.7368 L95.4496,0.7368 C102.4586,0.7368 108.1626,6.4378 108.1626,13.4458 C108.1626,20.4528 102.4586,26.1548 95.4496,26.1548 L69.9976,26.1548 C66.9326,26.1548 64.4386,28.6498 64.4386,31.7158 C64.4386,34.2058 66.1126,36.4088 68.5076,37.0728 C68.9896,37.2068 69.4906,37.2748 69.9976,37.2748 C70.8266,37.2748 71.4976,37.9458 71.4976,38.7748 C71.4976,39.6028 70.8266,40.2748 69.9976,40.2748 C69.2196,40.2748 68.4486,40.1698 67.7056,39.9638 C64.0156,38.9418 61.4386,35.5488 61.4386,31.7158 C61.4386,26.9948 65.2786,23.1548 69.9976,23.1548 L95.4496,23.1548 C100.8046,23.1548 105.1626,18.7998 105.1626,13.4458 C105.1626,8.0918 100.8046,3.7368 95.4496,3.7368 L13.3106,3.7368 C7.9566,3.7368 3.6016,8.0918 3.6016,13.4458 C3.6016,18.7998 7.9566,23.1548 13.3106,23.1548 L21.8626,23.1548 C26.5826,23.1548 30.4236,26.9948 30.4236,31.7158 C30.4236,36.4348 26.5826,40.2748 21.8626,40.2748 L18.9566,40.2748 C15.1616,40.2748 12.0736,43.3638 12.0736,47.1618 C12.0736,50.9598 15.1616,54.0498 18.9566,54.0498 L64.1016,54.0498 C64.9296,54.0498 65.6016,54.7218 65.6016,55.5498 C65.6016,56.3778 64.9296,57.0498 64.1016,57.0498" fill={theme.secondaryColor}></path>
              </g>
              <g transform="translate(32.000000, 61.710900)">
                <g></g>
                <path d="M12.4883,3.0225 C7.2573,3.0225 3.0003,7.2795 3.0003,12.5115 C3.0003,17.7435 7.2573,22.0005 12.4883,22.0005 L57.4963,22.0005 C62.7303,22.0005 66.9883,17.7435 66.9883,12.5115 C66.9883,7.2795 62.7303,3.0225 57.4963,3.0225 L12.4883,3.0225 Z M57.4963,25.0005 L12.4883,25.0005 C5.6023,25.0005 0.0003,19.3975 0.0003,12.5115 C0.0003,5.6255 5.6023,0.0225 12.4883,0.0225 L57.4963,0.0225 C64.3843,0.0225 69.9883,5.6255 69.9883,12.5115 C69.9883,19.3975 64.3843,25.0005 57.4963,25.0005 L57.4963,25.0005 Z" fill={theme.secondaryColor}></path>
              </g>
              <path d="M12.4883,64.7109 C7.2573,64.7109 3.0003,68.9679 3.0003,74.1989 C3.0003,79.4309 7.2573,83.6879 12.4883,83.6879 L16.4923,83.6879 C21.7263,83.6879 25.9843,79.4309 25.9843,74.1989 C25.9843,68.9679 21.7263,64.7109 16.4923,64.7109 L12.4883,64.7109 Z M16.4923,86.6879 L12.4883,86.6879 C5.6023,86.6879 0.0003,81.0849 0.0003,74.1989 C0.0003,67.3139 5.6023,61.7109 12.4883,61.7109 L16.4923,61.7109 C23.3813,61.7109 28.9843,67.3139 28.9843,74.1989 C28.9843,81.0849 23.3813,86.6879 16.4923,86.6879 L16.4923,86.6879 Z" fill={theme.secondaryColor}></path>
              <path d="M152.3818,29.4941 C152.0278,29.4941 151.6698,29.3691 151.3848,29.1131 C145.7528,24.0911 138.4908,21.3241 130.9378,21.3241 C124.8518,21.3241 118.9688,23.0991 113.9248,26.4581 C113.2378,26.9181 112.3038,26.7311 111.8458,26.0411 C111.3858,25.3511 111.5728,24.4211 112.2628,23.9621 C117.8008,20.2731 124.2588,18.3241 130.9378,18.3241 C139.2298,18.3241 147.1998,21.3611 153.3808,26.8751 C153.9998,27.4261 154.0538,28.3741 153.5018,28.9921 C153.2058,29.3251 152.7948,29.4941 152.3818,29.4941" fill={theme.secondaryColor}></path>
              <path d="M91.2314,54.5537 L80.4884,54.5537 C79.6604,54.5537 78.9884,53.8817 78.9884,53.0537 C78.9884,52.2257 79.6604,51.5537 80.4884,51.5537 L91.2314,51.5537 C92.0594,51.5537 92.7314,52.2257 92.7314,53.0537 C92.7314,53.8817 92.0594,54.5537 91.2314,54.5537" fill={theme.secondaryColor}></path>
              <path d="M72.4883,54.5537 L36.4883,54.5537 C35.6603,54.5537 34.9883,53.8817 34.9883,53.0537 C34.9883,52.2257 35.6603,51.5537 36.4883,51.5537 L72.4883,51.5537 C73.3163,51.5537 73.9883,52.2257 73.9883,53.0537 C73.9883,53.8817 73.3163,54.5537 72.4883,54.5537" fill={theme.secondaryColor}></path>
              <path d="M30.4883,54.5537 L24.4883,54.5537 C23.6603,54.5537 22.9883,53.8817 22.9883,53.0537 C22.9883,52.2257 23.6603,51.5537 24.4883,51.5537 L30.4883,51.5537 C31.3163,51.5537 31.9883,52.2257 31.9883,53.0537 C31.9883,53.8817 31.3163,54.5537 30.4883,54.5537" fill={theme.secondaryColor}></path>
              <path d="M149.2891,18.5703 C149.0341,18.5703 148.7761,18.5063 148.5401,18.3693 C147.8221,17.9553 147.5771,17.0383 147.9911,16.3203 L153.3631,7.0163 C153.7771,6.2983 154.6951,6.0523 155.4121,6.4673 C156.1301,6.8823 156.3751,7.7993 155.9611,8.5163 L150.5891,17.8203 C150.3111,18.3013 149.8071,18.5703 149.2891,18.5703" fill={theme.secondaryColor}></path>
              <path d="M112.5859,18.5703 C112.0679,18.5703 111.5639,18.3013 111.2859,17.8203 L105.9139,8.5163 C105.4999,7.7993 105.7449,6.8823 106.4629,6.4673 C107.1789,6.0523 108.0989,6.2983 108.5119,7.0163 L113.8839,16.3203 C114.2979,17.0383 114.0529,17.9553 113.3349,18.3693 C113.0989,18.5063 112.8409,18.5703 112.5859,18.5703" fill={theme.secondaryColor}></path>
              <path d="M130.9375,13.7431 C130.1095,13.7431 129.4375,13.0711 129.4375,12.2431 L129.4375,1.5001 C129.4375,0.6721 130.1095,0.0001 130.9375,0.0001 C131.7655,0.0001 132.4375,0.6721 132.4375,1.5001 L132.4375,12.2431 C132.4375,13.0711 131.7655,13.7431 130.9375,13.7431" fill={theme.secondaryColor}></path>
              <path d="M60.8916,37.9004 C59.2906,37.9004 57.9886,39.2044 57.9886,40.8054 C57.9886,42.4074 59.2906,43.7114 60.8916,43.7114 L84.8386,43.7114 L91.2746,43.5694 C98.1616,43.5694 103.7376,37.9934 103.7376,31.1394 C103.7376,24.2864 98.1616,18.7114 91.3076,18.7114 L67.5756,18.7114 C65.1566,18.7114 63.1886,20.6784 63.1886,23.0974 C63.1886,25.5164 65.1566,27.4854 67.5756,27.4854 L69.9236,27.4854 C72.7956,27.4854 75.1326,29.8214 75.1326,32.6934 C75.1326,35.5644 72.7956,37.9004 69.9236,37.9004 L60.8916,37.9004 Z M84.8556,46.7114 L60.8916,46.7114 C57.6366,46.7114 54.9886,44.0614 54.9886,40.8054 C54.9886,37.5494 57.6366,34.9004 60.8916,34.9004 L69.9236,34.9004 C71.1416,34.9004 72.1326,33.9104 72.1326,32.6934 C72.1326,31.4754 71.1416,30.4854 69.9236,30.4854 L67.5756,30.4854 C63.5016,30.4854 60.1886,27.1714 60.1886,23.0974 C60.1886,19.0244 63.5016,15.7114 67.5756,15.7114 L91.3076,15.7114 C99.8156,15.7114 106.7376,22.6314 106.7376,31.1394 C106.7376,39.6474 99.8156,46.5694 91.3076,46.5694 L84.8886,46.7114 L84.8556,46.7114 Z" fill={theme.secondaryColor}></path>
              <g transform="translate(224.000000, 61.710900)">
                <g></g>
                <path d="M13.0898,3.0225 C7.8588,3.0225 3.6018,7.2795 3.6018,12.5115 C3.6018,17.7435 7.8588,22.0005 13.0898,22.0005 L17.0938,22.0005 C22.3278,22.0005 26.5858,17.7435 26.5858,12.5115 C26.5858,7.2795 22.3278,3.0225 17.0938,3.0225 L13.0898,3.0225 Z M17.0938,25.0005 L13.0898,25.0005 C6.2038,25.0005 0.6018,19.3975 0.6018,12.5115 C0.6018,5.6255 6.2038,0.0225 13.0898,0.0225 L17.0938,0.0225 C23.9828,0.0225 29.5858,5.6255 29.5858,12.5115 C29.5858,19.3975 23.9828,25.0005 17.0938,25.0005 L17.0938,25.0005 Z" fill={theme.secondaryColor}></path>
              </g>
              <path d="M224.1016,86.7109 L188.1016,86.7109 C187.2736,86.7109 186.6016,86.0389 186.6016,85.2109 C186.6016,84.3829 187.2736,83.7109 188.1016,83.7109 L224.1016,83.7109 C224.9296,83.7109 225.6016,84.3829 225.6016,85.2109 C225.6016,86.0389 224.9296,86.7109 224.1016,86.7109" fill={theme.secondaryColor}></path>
              <path d="M180.1016,86.7109 L170.1016,86.7109 C169.2736,86.7109 168.6016,86.0389 168.6016,85.2109 C168.6016,84.3829 169.2736,83.7109 170.1016,83.7109 L180.1016,83.7109 C180.9296,83.7109 181.6016,84.3829 181.6016,85.2109 C181.6016,86.0389 180.9296,86.7109 180.1016,86.7109" fill={theme.secondaryColor}></path>
              <path d="M206.1016,15.7109 L170.1016,15.7109 C169.2736,15.7109 168.6016,15.0389 168.6016,14.2109 C168.6016,13.3829 169.2736,12.7109 170.1016,12.7109 L206.1016,12.7109 C206.9296,12.7109 207.6016,13.3829 207.6016,14.2109 C207.6016,15.0389 206.9296,15.7109 206.1016,15.7109" fill={theme.secondaryColor}></path>
              <path d="M215.1016,15.7109 L213.1016,15.7109 C212.2736,15.7109 211.6016,15.0389 211.6016,14.2109 C211.6016,13.3829 212.2736,12.7109 213.1016,12.7109 L215.1016,12.7109 C215.9296,12.7109 216.6016,13.3829 216.6016,14.2109 C216.6016,15.0389 215.9296,15.7109 215.1016,15.7109" fill={theme.secondaryColor}></path>
              <path d="M148.3311,76.6914 L145.8181,76.6914 L145.8181,68.5004 C145.8181,64.3254 143.9131,60.5884 140.9281,58.1114 L141.7191,57.1714 C142.2521,56.5384 142.1711,55.5914 141.5371,55.0574 C140.9041,54.5234 139.9581,54.6074 139.4241,55.2384 L138.3941,56.4614 C136.9891,55.7494 135.4501,55.2694 133.8181,55.0884 L133.8181,52.5004 C133.8181,51.6714 133.1461,51.0004 132.3181,51.0004 C131.4901,51.0004 130.8181,51.6714 130.8181,52.5004 L130.8181,55.0884 C124.0781,55.8374 118.8181,61.5634 118.8181,68.5004 L118.8181,70.6154 L116.6551,68.8654 C116.0111,68.3424 115.0651,68.4444 114.5461,69.0874 C114.0241,69.7324 114.1241,70.6764 114.7681,71.1974 L118.8181,74.4734 L118.8181,82.9194 L116.6411,82.9194 C115.8121,82.9194 115.1411,83.5904 115.1411,84.4194 C115.1411,85.2474 115.8121,85.9194 116.6411,85.9194 L118.8181,85.9194 L118.8181,91.5004 C118.8181,92.3284 119.4901,93.0004 120.3181,93.0004 C121.1461,93.0004 121.8181,92.3284 121.8181,91.5004 L121.8181,85.9194 L123.0001,85.9194 C123.8281,85.9194 124.5001,85.2474 124.5001,84.4194 C124.5001,83.5904 123.8281,82.9194 123.0001,82.9194 L121.8181,82.9194 L121.8181,68.5004 C121.8181,62.7104 126.5281,58.0004 132.3181,58.0004 C133.7661,58.0004 135.1451,58.2944 136.4011,58.8264 L135.4611,59.9434 C134.9281,60.5774 135.0091,61.5234 135.6431,62.0574 C135.9241,62.2954 136.2671,62.4104 136.6071,62.4104 C137.0351,62.4104 137.4591,62.2284 137.7561,61.8774 L138.9971,60.4034 C141.3291,62.3314 142.8181,65.2454 142.8181,68.5004 L142.8181,76.7104 C142.0771,76.8004 141.5001,77.4254 141.5001,78.1914 C141.5001,78.9574 142.0771,79.5824 142.8181,79.6724 L142.8181,91.5004 C142.8181,92.3284 143.4901,93.0004 144.3181,93.0004 C145.1461,93.0004 145.8181,92.3284 145.8181,91.5004 L145.8181,79.6914 L148.3311,79.6914 C149.1591,79.6914 149.8311,79.0194 149.8311,78.1914 C149.8311,77.3634 149.1591,76.6914 148.3311,76.6914" fill={theme.mainColor}></path>
              <path d="M125.4942,111.5 C125.4942,111.367 125.4822,111.232 125.4452,111.098 L123.7642,105.058 C123.5412,104.259 122.7102,103.794 121.9162,104.015 C121.1182,104.237 120.6512,105.064 120.8732,105.862 L122.4422,111.5 L120.0002,111.5 L114.0002,93.5 L151.0002,93.5 L145.0002,111.5 L125.4942,111.5 Z M149.0002,119.5 L116.0002,119.5 L116.0002,114.5 L120.0002,114.5 L145.0002,114.5 L149.0002,114.5 L149.0002,119.5 Z M153.4332,91.746 C152.8702,90.964 151.9642,90.5 151.0002,90.5 L114.0002,90.5 C113.0362,90.5 112.1302,90.964 111.5662,91.746 C111.0022,92.528 110.8482,93.534 111.1542,94.448 L116.8382,111.5 L116.0002,111.5 C114.3432,111.5 113.0002,112.843 113.0002,114.5 L113.0002,119.5 C113.0002,121.157 114.3432,122.5 116.0002,122.5 L149.0002,122.5 C150.6572,122.5 152.0002,121.157 152.0002,119.5 L152.0002,114.5 C152.0002,112.843 150.6572,111.5 149.0002,111.5 L148.1622,111.5 L153.8462,94.448 C154.1512,93.534 153.9982,92.528 153.4332,91.746 L153.4332,91.746 Z" fill={theme.secondaryColor}></path>
              <path d="M121.3018,96.7412 C121.0668,95.9462 120.2278,95.4952 119.4378,95.7282 C118.6428,95.9642 118.1898,96.7992 118.4248,97.5932 L119.0628,99.7452 C119.2558,100.3972 119.8538,100.8192 120.4998,100.8192 C120.6418,100.8192 120.7848,100.8002 120.9268,100.7582 C121.7218,100.5222 122.1748,99.6872 121.9398,98.8932 L121.3018,96.7412 Z" fill={theme.secondaryColor}></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
  /* eslint-enable */
}

SvgSigninMorning.propTypes = {
  theme: PropTypes.object.isRequired,
};

export default withTheme(SvgSigninMorning);
