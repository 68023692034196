/**
 * Created by chenrozenes on 22/11/2016.
 */

import React from 'react';
import { autobind } from 'core-decorators';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';

import ThreeBounceSpinner from '../../components/ui/spinner/threeBounceSpinner';

const defaultOptions = {
  /**
   * Should the component show loading component when data doesn't exists.
   * loading: true - default loading component will be used
   * loading: CustomLoadingComponent - CustomLoadingComponent will be used.
   *
   * In order to use loading - renderAlways should be false
   */
  loading: false,
  /**
   * When true, the wrapped component will always be rendered, regardless to the state values.
   * When false, only if all data exists component will be rendered
   */
  renderAlways: true,
  /**
   * Should return true when the state value is valid.
   * Default is when the state is not empty - then ready to be mounted and rendered
   * @param stateVal - the state value that was provided in the "paths" argument
   *                   (as a path or a function)
   * @param props -    the component's own props
   */
  predicate: stateVal => !_.isEmpty(stateVal),
};

/**
 * High order component for fetching data on component mount if not exists on the state
 * The actions provided will be called only if the state values actually contain values.
 * Similar to 'fetch' but not uses SSR (Server Side Rendering).
 *
 * @param actions - array of actions (or single) to perform when data is missing.
 * same syntax as 'fetch'
 * @param paths - list of paths to state or functions to evaluate the value
 * @param options - see defaultOptions
 * @returns {function()}
 */
export default function fetchNotEmpty(actions, paths, options = {}) {
  options = _.extend({}, defaultOptions, options);
  actions = _.castArray(actions);
  paths = _.castArray(paths);

  return Component => {
    const mapStateToProps = (state, props) => ({
      // Getting all values to inspect from state
      inspectionValues: _.map(paths, path => {
        // Allow path to be a function evaluated by state and props
        if (_.isFunction(path)) return path(state, props);
        if (_.isString(path)) return _.get(state, path);
        throw new Error('paths values must be a string or a function');
      })
    });

    @autobind
    class Wrapped extends React.Component {
      static defaultProps = {
        location: {},
        params: {},
      };

      static propTypes = {
        // From provided 'paths' param
        inspectionValues: PropTypes.array.isRequired,
        // We get from react-router
        location: PropTypes.object,
        params: PropTypes.object,
      };

      constructor(props) {
        super(props);
        this.state = {
          shouldRender: options.renderAlways
        };
      }

      componentWillMount() {
        // If we got an empty val call all actions
        if (!this.hasData(this.props.inspectionValues)) {
          const { location, params, dispatch, ...other } = this.props;
          actions.forEach(action => dispatch(action({ props: other, location, params })));
        } else {
          this.setState({ shouldRender: true });
        }
      }

      componentWillReceiveProps(nextProps) {
        const shouldRender = options.renderAlways || this.hasData(nextProps.inspectionValues);

        if (shouldRender) {
          this.setState({ shouldRender: true });
        }
      }

      hasData(values) {
        return _.every(values, currVal => options.predicate(currVal, this.props));
      }

      render() {
        const otherProps = _.omit(this.props, 'inspectionValues');
        if (this.state.shouldRender) {
          return <Component {...otherProps} />;
        } else if (options.loading) {
          // if loading is a boolean, show default loading component. otherwise use the provided one
          return options.loading === true ? <ThreeBounceSpinner /> :
            options.loading;
        }

        return null;
      }
    }

    return connect(mapStateToProps)(Wrapped);
  };
}

export const paramIdPredicate = (val, props) =>
  !_.isEmpty(val) && val.id === parseInt(props.params.id, 10);
