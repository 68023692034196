import { autobind } from 'core-decorators';
import BkmdApi from './bkmdApi';

@autobind
export default class BackofficeApi extends BkmdApi {
  constructor(fetch) {
    super(fetch, '/backoffice/');
  }

  executeAction(module, action, data) {
    return this.post(`${module}/${action}`, data);
  }
}
