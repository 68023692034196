export default {
  onStart: () => next => props => {
    const { authTouch } = props;
    authTouch();
    next(props);
  },
  onSuccess: () => next => (props, response) => {
    const { authTouch } = props;
    authTouch();
    next(props, response);
  },
  onError: () => next => (props, error) => {
    const { loginExpired } = props;
    if (error.status === 401 || error.message === 'Unauthorized') {
      loginExpired();
    } else {
      next(props, error);
    }
  },
};
