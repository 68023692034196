/**
 * Created by guyavarham on 06/03/2017.
 */

import _ from 'lodash';
import Immutable from 'seamless-immutable';
import Formatter from '../../../utils/formatter';
import { GET_PROVIDER, GET_PROVIDER_BY_ID, GET_PUBLIC_PROVIDER } from './actions';

const initialState = {};

export default function providerReducer(state = initialState, action) {
  state = Immutable(state);
  switch (action.type) {
    case GET_PROVIDER.START: {
      return state;
    }
    case GET_PROVIDER.ERROR: {
      return state;
    }
    case GET_PROVIDER_BY_ID.SUCCESS:
    case GET_PROVIDER.SUCCESS:
    case GET_PUBLIC_PROVIDER.SUCCESS: {
      const payload = action.payload;
      const { npi, locations } = payload;

      if (_.find(state, npi)) {
        return state;
      }

      // Add locationTitle for each location in the provider.
      // Which is formatted string of the location, e.g. 105 Main ST. Santa Monica, CA 1234
      _.map(locations, location => {
        location.locationTitle = Formatter.addressFormat(location);
        return location;
      });

      return Immutable.set(state, npi, Immutable(payload));
    }
  }
  return state;
}
