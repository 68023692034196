import { autobind } from 'core-decorators';
import BkmdApi from './bkmdApi';

// eslint-disable-next-line
const SERVICE_NAME = 'share';

@autobind
export default class ShareApi extends BkmdApi {
  constructor(fetch) {
    super(fetch, '/share');
  }

  getShareByToken(token) {
    return this.get('/shareDoctors/getShareByToken', { token });
  }

  shareProvidersWithMember(domainMemberId, providers, phoneNumber) {
    return this.post('/shareDoctors/shareProvidersWithMember', {
      domainMemberId,
      providers,
      phoneNumber,
    }).then(res => res.data);
  }
}
