import React from 'react';
import { FormattedMessage } from 'react-intl';
import { autobind } from 'core-decorators';
import PropTypes from 'prop-types';
import ThreeBounceSpinner from '../../ui/spinner/threeBounceSpinner';

@autobind
class LoadingComponent extends React.PureComponent {
  static defaultProps = {
    isLoading: false,
    svgComponent: undefined,
    spinnerClassName: undefined,
  };

  static propTypes = {
    headerMessage: PropTypes.object.isRequired,
    contentMessage: PropTypes.object.isRequired,
    svgComponent: PropTypes.element,
    spinnerClassName: PropTypes.string,
  };

  render() {
    const {
      headerMessage,
      contentMessage,
      spinnerClassName,
      svgComponent: SvgComponent
    } = this.props;

    return (
      <div className="row text-center margin-top-30">
        <div className="col-sm-8 col-sm-offset-2">
          {SvgComponent && <SvgComponent />}
          <div className="title-36 text-strong-dark margin-30">
            <FormattedMessage {...headerMessage} />
          </div>
          <div className="text-16 text-medium">
            <FormattedMessage {...contentMessage} />
          </div>
          <ThreeBounceSpinner spinnerClass={spinnerClassName} />
        </div>
      </div>
    );
  }
}

export default LoadingComponent;
