/**
 * Created by chenrozenes on 21/08/2017.
 */
import _ from 'lodash';

export function getFeature(state, uri, defaultValue, memberContext) {
  const domainFF = _.get(state, `config.features.${uri}`, defaultValue);
  let memberContextFF;
  if (memberContext) {
    memberContextFF = _.get(state, `config.features.${memberContext}.${uri}`, defaultValue);
  }

  return _.isUndefined(memberContextFF) ? domainFF : memberContextFF;
}

export function getDomain(state) {
  return _.get(state, 'config.domain.domain');
}

export function getDomainShortName(state) {
  return _.get(state, 'config.domain.shortName');
}
