import { autobind } from 'core-decorators';
import BkmdApi from './bkmdApi';

// eslint-disable-next-line
const SERVICE_NAME = 'sso';

@autobind
export default class SsoApi extends BkmdApi {
  constructor(fetch) {
    super(fetch, '/sso/');
  }

  ssoSignin(assertion) {
    return this.post('/', assertion);
  }
}
