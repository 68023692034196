/**
 * Created by chenrozenes on 22/08/2017.
 */
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { defineMessages } from 'react-intl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import queryConnect from '../../../../store/tools/queryString/queryConnect';
import actionTracker from '../../../../store/tools/actionTracker/actionTrackerComponent';
import injectNotification from '../../../../store/notification/injectNotification';
import { verifyEmail, VERIFY_EMAIL } from '../../../../store/user/actions';
import commonMessages from '../../../../store/intl/commonMessages';

const messages = defineMessages({
  emailVerifiedText: {
    defaultMessage: 'Email verified successfully',
    id: 'bkmd.emailVerification.emailVerifiedTitle',
  },
  emailVerifiedErrorText: {
    defaultMessage: 'Error occurred while verifying your email - try again',
    id: 'bkmd.emailVerification.emailVerifiedErrorTitle',
  },
});

/**
 * HOC that will inspect the query string for "emailToken" param, and will verify the email
 * with the server - a success/error notification will be displayed
 * @param action
 * @param trackerId
 * @returns {function()}
 */
export default function emailVerification({
  action = verifyEmail,
  trackerId = VERIFY_EMAIL.SOURCE,
} = {}) {
  return Component => {
    class EmailVerification extends React.Component {
      static propTypes = {
        action: PropTypes.func.isRequired,
        tracker: PropTypes.object.isRequired,
        emailToken: PropTypes.string,
        notification: PropTypes.object.isRequired,
      };

      static defaultProps = {
        emailToken: undefined,
      };

      componentWillMount() {
        const { action, emailToken } = this.props;
        if (emailToken) {
          action(emailToken);
        }
      }

      componentWillReceiveProps(nextProps) {
        const { tracker, notification } = nextProps;

        if (tracker.finished) {
          if (tracker.hasError) {
            notification.error(commonMessages.failure, messages.emailVerifiedErrorText, {
              autoDismiss: 10,
            });
          } else {
            notification.success(commonMessages.success, messages.emailVerifiedText, {
              autoDismiss: 10,
            });
          }
        }
      }

      render() {
        const props = _.omit(this.props, ['action', 'emailToken', 'tracker', 'notification']);
        return <Component {...props} />;
      }
    }

    return compose(
      queryConnect(['emailToken']),
      injectNotification,
      actionTracker({
        tracker: trackerId,
      }),
      connect(
        null,
        { action },
      ),
    )(EmailVerification);
  };
}
