import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import PlacesAutocomplete from 'react-places-autocomplete';
import Highlighter from 'react-highlight-words';
import { connect } from 'react-redux';
import { compose, withState, withHandlers, withPropsOnChange } from 'recompose';
import classNames from 'classnames';

import './address.less';
import { isGooglePlacesApiLoaded } from '../../store/help/selectors';
import { loadingUntil } from '../recompose/loadingUntil';
import IntlString from '../ui/intlString/intlString';

const DefaultOption = ({ option, getSuggestionItemProps, onSelect }) => (
  <div
    {...getSuggestionItemProps(option, {
      onClick: () => onSelect(option.address),
      className: 'suggestion-item custom-option',
    })}
  >
    <IntlString message={option.label} values={option.intlValues} html />
  </div>
);

DefaultOption.propTypes = {
  option: PropTypes.object.isRequired,
  getSuggestionItemProps: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
};

const Option = ({ addressInput, option, getSuggestionItemProps }) => {
  const {
    formattedSuggestion: { mainText, secondaryText },
  } = option;
  const searchWords = addressInput.split(/[\s,]/).filter(word => word);
  return (
    <div {...getSuggestionItemProps(option)}>
      <strong>
        <Highlighter searchWords={searchWords} textToHighlight={mainText} />
      </strong>
      <br />
      {secondaryText}
    </div>
  );
};

Option.propTypes = {
  getSuggestionItemProps: PropTypes.func.isRequired,
  addressInput: PropTypes.string.isRequired,
  option: PropTypes.object.isRequired,
};

const Address = props => {
  const {
    name,
    className,
    options,
    addressInput,
    handleChange,
    onSelect,
    onFocus,
    onBlur,
    focused,
    defaultOptions,
    placeholder,
  } = props;

  const defaultAddress = _.find(defaultOptions, ({ address }) => address === addressInput);
  return (
    <div className="places-autocomplete">
      <PlacesAutocomplete
        typeAhead={false}
        onSelect={onSelect}
        inputName={name}
        onChange={handleChange}
        value={addressInput}
        googleLogo={false}
        searchOptions={options}
      >
        {({ getInputProps, getSuggestionItemProps, suggestions }) => (
          <div
            className={classNames('clean-input location-autocomplete-wrap', {
              'in-focus': focused,
              className,
            })}
            onFocus={onFocus}
            onBlur={onBlur}
          >
            {defaultAddress ? (
              <span
                className="clean-input location-autocomplete-select"
                onClick={onFocus}
                role="button"
                tabIndex="0"
              >
                <IntlString
                  message={defaultAddress.label}
                  values={defaultAddress.intlValues}
                  html
                />
              </span>
            ) : (
              <input
                {...getInputProps({
                  placeholder,
                  className: 'clean-input location-autocomplete-select',
                })}
              />
            )}

            {focused && (
              <div className="autocomplete-dropdown-container location-autocomplete-options">
                {suggestions && suggestions.length
                  ? suggestions.map(option => (
                    <Option
                      key={option.id}
                      option={option}
                      addressInput={addressInput}
                      getSuggestionItemProps={getSuggestionItemProps}
                    />
                    ))
                  : _.map(defaultOptions, option => (
                    <DefaultOption
                      key={option.id}
                      option={option}
                      getSuggestionItemProps={getSuggestionItemProps}
                      onSelect={onSelect}
                    />
                    ))}
              </div>
            )}
          </div>
        )}
      </PlacesAutocomplete>
      <button
        className="btn btn-inline-link clear-input-icon"
        onClick={() => handleChange('')}
        aria-label="Clear"
        type="button"
      >
        <i className="icon-x-2" />
      </button>
    </div>
  );
};

Address.propTypes = {
  name: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  addressInput: PropTypes.string.isRequired,
  focused: PropTypes.bool.isRequired,
  className: PropTypes.string,
  onSelect: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  options: PropTypes.object,
  placeholder: PropTypes.string,
  defaultOptions: PropTypes.array,
};

Address.defaultProps = {
  className: undefined,
  onSelect: undefined,
  onFocus: undefined,
  onBlur: undefined,
  options: {},
  placeholder: undefined,
  defaultOptions: undefined,
};

export default compose(
  withState('focused', 'setFocus', false),
  withState('addressInput', 'setAddress', ''),
  connect(state => ({
    isReady: isGooglePlacesApiLoaded(state),
  })),
  loadingUntil(({ isReady }) => isReady === true),
  withHandlers({
    onFocus: ({ onFocus, setFocus }) => () => {
      setFocus(true);
      if (onFocus) onFocus();
    },
    onBlur: ({ onBlur, setFocus }) => () => {
      setFocus(false);
      if (onBlur) onBlur();
    },
    handleChange: ({ setAddress, onChange, name }) => address => {
      setAddress(address);
      onChange('', name);
    },
    onSelect: ({ onSelect, onChange, setFocus, name, setAddress }) => address => {
      if (!_.isNil(address)) {
        onChange(address, name);
        setFocus(false);
        setAddress(address);
        if (onSelect) onSelect(address);
      }
    },
  }),
  withPropsOnChange(['address'], ({ address, setAddress }) => setAddress(address || '')),
)(Address);
