/**
 * Created by galgoltzman on 19/06/2017.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Dropdown, MenuItem } from 'react-bootstrap';
import { autobind } from 'core-decorators';
import { defineMessages, FormattedMessage } from 'react-intl';

import analytics from '../../../../../store/tools/analytics/analyticsHoc';
import * as FacilityTypes from '../../directory/facilitySearchResults/facilityTypes';
import { mobileMenuOpen } from '../../../../../store/ui/actions';
import { isMobile } from '../../../../../store/platform/selectors';
import { getPathsFromContext } from '../../routes/injectPathsToContext';

import SvgImaging from '../../../../../assets/svg/imaging';
import SvgPharmacies from '../../../../../assets/svg/pharmacies';
import SvgUrgentCare from '../../../../../assets/svg/urgentCare';
import SvgHospital from '../../../../../assets/svg/hospital';
import SvgLabs from '../../../../../assets/svg/labs';
import SvgOtherFacilities from '../../../../../assets/svg/otherFacilities';

import './servicesMenu.less';

const messages = defineMessages({
  places: {
    defaultMessage: 'Places',
    id: 'members.servicesMenu.places',
  },
  findARadiologyFacility: {
    defaultMessage: 'MRIs, CTs + PET scans',
    id: 'members.servicesMenu.findARadiologyFacility',
  },
  findALab: {
    defaultMessage: 'Labs',
    id: 'members.servicesMenu.findALab',
  },
  findAPharmacy: {
    defaultMessage: 'Pharmacies',
    id: 'members.servicesMenu.findAPharmacy',
  },
  findAHospital: {
    defaultMessage: 'Hospitals',
    id: 'members.servicesMenu.findAHospital',
  },
  findAnUrgentCare: {
    defaultMessage: 'Urgent care + ERs',
    id: 'members.servicesMenu.findAnUrgentCare',
  },
  otherProviders: {
    defaultMessage: 'Other providers',
    id: 'members.servicesMenu.otherProviders',
  },
});

@autobind
class ServicesMenu extends React.PureComponent {
  static propTypes = {
    mobileMenuOpen: PropTypes.func.isRequired,
    isMobileScreen: PropTypes.bool.isRequired,
    router: PropTypes.object.isRequired,
    paths: PropTypes.object.isRequired,
    closeMainMenu: PropTypes.func,
    id: PropTypes.string.isRequired,
  };

  static defaultProps = {
    closeMainMenu: () => true,
  };

  loadPage(facilityType) {
    const { router, paths, isMobileScreen, closeMainMenu } = this.props;

    router.push(paths.facilitySearchResults({ facilityType }));
    if (isMobileScreen) closeMainMenu();
  }

  backToMenu(notRelevant, e) {
    if (this.props.isMobileScreen) {
      e.stopPropagation();
      this.props.mobileMenuOpen(true);
    }
  }

  render() {
    const { id } = this.props;
    const AnalyticsMenu = analytics(MenuItem, true, [
      {
        event: 'onSelect',
        eventData: () => ({}),
      },
    ]);
    return (
      <Dropdown id={id} className="services-menu xs-fullscreen-dropdown">
        <Dropdown.Toggle bsStyle="inline-link text-semibold services-menu-toggle">
          <FormattedMessage {...messages.places} />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <MenuItem className="dropdown-header visible-xs" onSelect={this.backToMenu}>
            <span className="text-18 text-semibold text-main">
              <FormattedMessage {...messages.places} />
              <i className="icon-back-arrow dropdown-back-btn left" />
            </span>
          </MenuItem>
          <AnalyticsMenu
            onSelect={() => this.loadPage(FacilityTypes.RADIOLOGY)}
            name="facility_imaging_menu_item"
          >
            <SvgImaging />
            &nbsp;
            <span>
              <FormattedMessage {...messages.findARadiologyFacility} />
            </span>
          </AnalyticsMenu>
          <AnalyticsMenu
            onSelect={() => this.loadPage(FacilityTypes.PHARMACY)}
            name="facility_pharmacy_menu_item"
          >
            <SvgPharmacies />
            <span>
              <FormattedMessage {...messages.findAPharmacy} />
            </span>
          </AnalyticsMenu>
          <AnalyticsMenu
            onSelect={() => this.loadPage(FacilityTypes.HOSPITAL)}
            name="facility_hospital_menu_item"
          >
            <SvgHospital />
            <span>
              <FormattedMessage {...messages.findAHospital} />
            </span>
          </AnalyticsMenu>
          <AnalyticsMenu
            onSelect={() => this.loadPage(FacilityTypes.URGENT_CARE)}
            name="facility_urgent_care_menu_item"
          >
            <SvgUrgentCare />
            <span>
              <FormattedMessage {...messages.findAnUrgentCare} />
            </span>
          </AnalyticsMenu>
          <AnalyticsMenu onSelect={() => this.loadPage(FacilityTypes.LABORATORY)}>
            <SvgLabs />
            <span>
              <FormattedMessage {...messages.findALab} />
            </span>
          </AnalyticsMenu>
          <AnalyticsMenu
            onSelect={() => this.loadPage(FacilityTypes.OTHERS)}
            name="facility_other_menu_item"
          >
            <SvgOtherFacilities />
            <span>
              <FormattedMessage {...messages.otherProviders} />
            </span>
          </AnalyticsMenu>
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}

export default compose(
  getPathsFromContext(),
  withRouter,
  connect(
    state => ({
      isMobileScreen: isMobile(state),
    }),
    { mobileMenuOpen },
  ),
)(ServicesMenu);
