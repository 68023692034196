import { PASSWORD_ALLOWED_CHARACTERS } from 'Utils/regex/password';
import {
  ReduxFormCleanPasswordInput,
  ReduxFormCleanRepeatPasswordInput,
} from '../../form/reduxForm/components';

import { PasswordSchema } from '../../../utils/commonSchema';

const MINIMAL_PASSWORD_STRENGTH = 2;

export const name = 'renewExpiredPassword';

export const fields = {
  currentPassword: {
    name: 'currentPassword',
    component: ReduxFormCleanPasswordInput,
  },
  newPassword: {
    name: 'newPassword',
    component: ReduxFormCleanPasswordInput,
  },
  verifyNewPassword: {
    name: 'verifyNewPassword',
    component: ReduxFormCleanPasswordInput,
  },
  repeatPassword: {
    name: 'repeatPassword',
    component: ReduxFormCleanRepeatPasswordInput,
  },
  passwordStrength: {
    name: 'passwordStrength',
    component: 'input',
  },
};

export const renewExpiredPasswordScheme = {
  currentPassword: PasswordSchema.required(),
  newPassword: PasswordSchema.required(),
  verifyNewPassword: PasswordSchema.required(),
};

export const customValidator = {
  [fields.verifyNewPassword.name]: (value, values) => {
    if (values[fields.verifyNewPassword.name] !== values[fields.newPassword.name]) {
      return 'Passwords are not identical';
    }
    return undefined;
  },
  [fields.newPassword.name]: (value, values) => {
    if (!values.hasOwnProperty(fields.passwordStrength.name)) return undefined;

    const { deprecatedCharacters } = values[fields.passwordStrength.name];
    const passwordStrength = values[fields.passwordStrength.name].score;

    if (deprecatedCharacters) {
      return `Password should contain only allowed special characters: ${PASSWORD_ALLOWED_CHARACTERS}`;
    }
    if (!passwordStrength || passwordStrength < MINIMAL_PASSWORD_STRENGTH) {
      return 'Password is too weak';
    }
    return undefined;
  },
  [fields.repeatPassword.name]: (value, values) => {
    if (values[fields.repeatPassword.name] !== values[fields.newPassword.name]) {
      return 'Passwords are not identical';
    }
    return undefined;
  },
};
