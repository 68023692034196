/**
 * Created by idanziv on 15/10/2018.
 */
import _ from 'lodash';
import { defineMessages } from 'react-intl';
import IntlEnum from '../../utils/intlEnum';

export const PREMERA_BLUE_CROSS_ALASKA_WA = 'PREMERA_BLUE_CROSS';
export const PREMERA_BLUE_CROSS_ALASKA_AK = 'PREMERA_BLUE_CROSS_ALASKA';
export const PREMERA_BLUE_CROSS_WA_PEAK_CARE = 'PREMERA_BLUE_CROSS_WA_PEAK_CARE';
export const UNITED_HEALTH_CARE_ALLSAVERS = 'UNITED_HEALTH_CARE_ALLSAVERS';
export const ANTHEM = 'ANTHEM';
export const FLORIDA_BLUE = 'FLORIDA_BLUE';
export const VIM = 'VIM';

export const BkmdInsurers = {
  PREMERA_BLUE_CROSS_ALASKA_AK,
  PREMERA_BLUE_CROSS_ALASKA_WA,
  PREMERA_BLUE_CROSS_WA_PEAK_CARE,
  UNITED_HEALTH_CARE_ALLSAVERS,
  ANTHEM,
  FLORIDA_BLUE,
  VIM
};

export const messages = defineMessages({
  premeraBlueCross: {
    defaultMessage: 'Premera Blue Cross',
    id: 'bkmdInsurer.premeraBlueCross',
    value: PREMERA_BLUE_CROSS_ALASKA_WA,
  },
  premeraBlueCrossAlaska: {
    defaultMessage: 'Premera Blue Cross Alaska',
    id: 'bkmdInsurer.premeraBlueCrossAlaska',
    value: PREMERA_BLUE_CROSS_ALASKA_AK,
  },
  unitedHealthCareAllsavers: {
    defaultMessage: 'UnitedHealthcare All Savers',
    id: 'bkmdInsurer.unitedHealthCareAllsavers',
    value: UNITED_HEALTH_CARE_ALLSAVERS,
  },
  anthem: {
    defaultMessage: 'Anthem',
    id: 'bkmdInsurer.anthem',
    value: ANTHEM,
  },
  floridaBlue: {
    defaultMessage: 'Florida Blue',
    id: 'bkmdInsurer.floridaBlue',
    value: FLORIDA_BLUE,
  },
  vim: {
    defaultMessage: 'Vim',
    id: 'bkmdInsurer.vim',
    value: VIM,
  },
});

export default new IntlEnum(
  { value: PREMERA_BLUE_CROSS_ALASKA_WA, message: messages.premeraBlueCross },
  { value: PREMERA_BLUE_CROSS_ALASKA_AK, message: messages.premeraBlueCrossAlaska },
  { value: PREMERA_BLUE_CROSS_WA_PEAK_CARE, message: messages.premeraBlueCross },
  { value: UNITED_HEALTH_CARE_ALLSAVERS, message: messages.unitedHealthCareAllsavers },
  { value: ANTHEM, message: messages.anthem },
  { value: FLORIDA_BLUE, message: messages.floridaBlue },
  { value: VIM, message: messages.vim },
);

export function getKeyForMsgId(msgId) {
  return _.findKey(messages, function compareMsgId(msg) {
    return _.isEqual(msg.id, msgId);
  });
}

export function getValueForMsgId(msgId) {
  return messages[getKeyForMsgId(msgId)].value;
}

