import _ from 'lodash';

export const addDefaultGeoToDoctors = () => doctors => {
  _.each(doctors.data, doctor => {
    doctor = doctor.data;
    doctor.closestLocation = doctor.locations[0];
  });
};

export const locationFields = ['latitude', 'longitude', 'state', 'zip'];
