/**
 * Created by chenrozenes on 30/03/2017.
 */
import React from 'react';
import { autobind } from 'core-decorators';
import FlowManager from '../../../../../components/wizard/oldFlowManager';
import IdentificationStep from './IdentificationStep';
import VerificationCodeStep from './verificationCodeStep';
import PasswordStep from './passwordStep';
import * as ResetPasswordFormDef from './forms/resetPasswordFormDef';

const getSteps = function getSteps() {
  return [
    {
      component: IdentificationStep,
    },
    {
      component: VerificationCodeStep,
    },
    {
      component: PasswordStep,
    },
  ];
};

@autobind
class ResetPasswordPage extends React.Component {
  render() {
    return <FlowManager formName={ResetPasswordFormDef.name} steps={getSteps()} maxSteps={3} />;
  }
}

export default ResetPasswordPage;
