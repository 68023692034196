import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, defineMessages } from 'react-intl';
import { pure } from 'recompose';

const messages = defineMessages({
  chooseAsMyPrimaryCareDoctor: {
    defaultMessage: 'Choose as my doctor',
    id: 'pcpSelection.chooseAsMyPrimaryCareDoctor',
  },
});

const ChooseAsMyPCP = ({ onClick }) =>
  (
    <button
      className="btn btn-big bg-color-brand-button choose-pcp-btn"
      onClick={onClick}
    >
      <FormattedMessage {...messages.chooseAsMyPrimaryCareDoctor} />
    </button>
  );

ChooseAsMyPCP.propTypes = {
  onClick: PropTypes.func.isRequired
};

export default pure(ChooseAsMyPCP);
