/**
 * Created by guyavarham on 10/04/2017.
 */

import React from 'react';
import PropTypes from 'prop-types';
import GeoLib from 'geolib';
import { FormattedHTMLMessage, defineMessages } from 'react-intl';
import _ from 'lodash';

import BookMdUtil from '../../../utils/util';
import { ThreeBounceSpinner } from '../spinner/index';

const messages = defineMessages({
  defaultMessage: {
    defaultMessage: '{clinicDistance} mi',
    id: 'components.pointDistance.defaultMessage',
  },
});

export function calcPointDistance({ p1, p2 }) {
  if (!p1 || !p2) return null;
  const point1 = BookMdUtil.toGeoPoint(p1);
  const point2 = BookMdUtil.toGeoPoint(p2);

  if (!point2 || !point1) return null;
  if (
    _.isNil(point1.longitude) ||
    _.isNil(point1.latitude) ||
    _.isNil(point2.longitude) ||
    _.isNil(point2.latitude)
  ) {
    return null;
  }
  let dist = GeoLib.getDistance(point1, point2);
  // distance in meters
  dist = GeoLib.convertUnit('mi', dist, 1);
  return dist < 1 ? dist : Math.round(dist);
}

function PointDistance({ className, message, distance, p1, p2, distanceLimit }) {
  let clinicDistance = !_.isNil(distance) ? distance : (p1 && p2 && calcPointDistance({ p1, p2 }));
  if (_.isNil(clinicDistance)) {
    return <div />;
  }

  clinicDistance = clinicDistance <= distanceLimit ? clinicDistance : `${distanceLimit}+`;
  if (clinicDistance === -1) return <ThreeBounceSpinner />;

  // Convert to a string so that react-intl will treat 0 as a valid value
  clinicDistance = clinicDistance.toString();
  return (
    <span className={className}>
      <FormattedHTMLMessage {...message} values={{ clinicDistance }} />
    </span>
  );
}

PointDistance.defaultProps = {
  distanceLimit: Number.POSITIVE_INFINITY,
  distance: undefined,
  className: '',
  message: messages.defaultMessage,
  p1: undefined,
  p2: undefined,
};

PointDistance.propTypes = {
  /**
   * The the message to be displayed
   */
  message: PropTypes.any,
  /**
   * The the message to be displayed
   */
  distance: PropTypes.number,
  /**
   * The clinic object
   */
  p1: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  /**
   * The customer
   */
  p2: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  /**
   * A number for which any distance above will presented as x+
   * For example, if 20 is passed and the distance is 35, the value presented 20+
   */
  distanceLimit: PropTypes.number,
  /**
   * Class name for the span that is displayed
   */
  className: PropTypes.string
};

export default PointDistance;
