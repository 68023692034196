import React from 'react';
import { withRouter } from 'react-router';
import { compose, withHandlers } from 'recompose';
import RenewExpiredPassword from '../../../../components/pages/auth/renewExpiredPassword';
import requireAuthentication from '../../../../store/auth/authenticatedComponent';
import Header from '../../../../components/ui/header/header';
import CoOpLogo from '../../../../components/branding/co_opLogo';

const MemberRenewPasswordPage = props => (
  <div className="expired-password-page">
    <Header>
      <button className="btn-inline-link header-back-btn" aria-label="Back">
        <i className="icon-back-arrow" />
      </button>
      <div className="logos">
        <CoOpLogo />
      </div>
    </Header>
    <RenewExpiredPassword {...props} />
  </div>
);

export default compose(
  withRouter,
  requireAuthentication(),
  withHandlers({
    onSuccess: props => props.router.push('/secure'),
  }),
)(MemberRenewPasswordPage);
