/**
 * Created by matan on 11/12/17.
 */

const getFormatted = parameters => {
  const {
    address, zip, state, geocode: { latitude, longitude }
  } = parameters;
  const formatted = {
    address,
    geo: {
      state,
      zip,
      latitude,
      longitude
    },
  };
  return formatted;
};

export function convertDrugHistory(data) {
  return {
    ...data,
    prescribedAt: new Date(data.prescribedAt),
  };
}

export function convertLocationData(params) {
  let { search } = params;

  if (search && search.homeAddress && search.homeAddress.geocode) {
    const homeAddress = getFormatted(params.search.homeAddress);
    search = { ...search, homeAddress };
  }
  if (search && search.workAddress && search.workAddress.geocode) {
    const workAddress = getFormatted(params.search.workAddress);
    search = { ...search, workAddress };
  }

  return { ...params, search };
}
