import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { getAccessibilityLink } from '../../../store/config/selectors';
import BaseLink from './baseLink';
import commonMessages from '../../../store/intl/commonMessages';

const AccessibilityLink = ({ url, ...other }) => (
  <BaseLink
    url={url}
    label={commonMessages.accessibilityLink}
    {...other}
  />
);


AccessibilityLink.propTypes = {
  url: PropTypes.string
};

AccessibilityLink.defaultProps = {
  url: null,
};

export default connect(
  state => ({
    url: getAccessibilityLink(state),
  })
)(AccessibilityLink);
