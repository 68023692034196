/**
 * Created by matan on 9/4/16.
 */

export const SET_CACHE = 'SET_CACHE';

export function setCache(searchKey, query, metadata, cache) {
  return {
    type: SET_CACHE,
    payload: {
      searchKey,
      cache,
      metadata,
      query,
    }
  };
}
