import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { compose, pure, setDisplayName } from 'recompose';
import { defineMessages } from 'react-intl';
import _ from 'lodash';
import { Field, Form } from 'formik';
import { object, string } from 'yup';
import { WrappedFormik } from '../components/form/formik/wrappedFormik';
import SvgEnvelopeStars2 from '../assets/svg/envelopeStars2';
import FormActionButtons from '../components/molecules/FormSubmissionButtons/FormActionButtons';
import { CleanFormikInput } from '../components/form/formik/cleanFormikInput';

const messages = defineMessages({
  yourEmail: {
    defaultMessage: 'Your Email',
    id: 'screenTemplates.emailForm.yourEmail',
  },
});

const scheme = object().shape({
  email: string()
    .email()
    .required()
    .label('Your Email'),
});

function EmailForm({ className, title, buttonText, svgIcon, onButtonClick, initialValues }) {
  return (
    <WrappedFormik
      initialValues={initialValues}
      onSubmit={onButtonClick}
      validationSchema={scheme}
      render={() => (
        <Form>
          <div className={classNames('email-form-page padding-h-30', className)}>
            <div className="row margin-top">
              <div className="col-xs-12 text-center svg-72">{svgIcon}</div>
            </div>

            <div className="row margin-top-20">
              <div className="col-xs-12">
                <div className="title-24 text-strong-dark text-center no-margin">{title}</div>
              </div>
            </div>

            <div className="row margin-top-30">
              <div className="col-xs-12">
                <Field name="email" label={messages.yourEmail} component={CleanFormikInput} />
              </div>
            </div>

            <div className="row">
              <div className="col-xs-12">
                <FormActionButtons
                  primaryButtonText={buttonText}
                />
              </div>
            </div>
          </div>
        </Form>
      )}
    />
  );
}
EmailForm.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  buttonText: PropTypes.string,
  svgIcon: PropTypes.object,
  onButtonClick: PropTypes.func,
  initialValues: PropTypes.object,
};
EmailForm.defaultProps = {
  className: undefined,
  title: undefined,
  svgIcon: <SvgEnvelopeStars2 />,
  buttonText: 'Confirm',
  onButtonClick: _.noop,
  initialValues: null,
};

const enhance = compose(
  setDisplayName('SCR012EmailForm'),
  pure,
);

export default enhance(EmailForm);
