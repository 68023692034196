/**
 * Created by chenrozenes on 09/08/2017.
 */
import { defineMessages } from 'react-intl';
import * as Id from './id';

export default defineMessages({
  [Id.BKMD01]: {
    defaultMessage: 'Most doctors recommend all women ages 50 to 74 get screened with a ' +
    'mammogram every two years. Every woman is different, so we advise you talk to your ' +
    'primary care provider about how often to be screened.',
    id: 'careCues.texts.bkmd01',
  },
  [Id.BKMD02]: {
    defaultMessage: 'Cervical cancer is highly preventable with regular checks. Most doctors ' +
    'recommend all women have a Pap test every three or five years starting at age 21. If you ' +
    'have certain risk factors, your doctor may recommend more frequent tests. Ask your women\'s ' +
    'health provider to find out what plan is right for you.',
    id: 'careCues.texts.bkmd02',
  },
  [Id.BKMD03]: {
    defaultMessage: 'Did you know that Chlamydia is the most common bacterial sexually ' +
    'transmitted infection in the United States? The good news is that it\'s easy to get a ' +
    'private check-up to keep you healthy and safe. Get tested for Chlamydia once a year at ' +
    'your doctor\'s office. If you don\'t have a doctor, we\'ll help you find one you can trust.',
    id: 'careCues.texts.bkmd03',
  },
  [Id.BKMD04]: {
    defaultMessage: 'When discovered and treated early, the survival rate of colon cancer can be ' +
    '90 percent or greater.  You can get a colonoscopy or your doctor can order you an easy and ' +
    'convenient at-home screening kit called FIT (fecal immunochemical test). The FIT screening ' +
    'should be done once a year, while the colonoscopy should be done once every 10 years.',
    id: 'careCues.texts.bkmd04',
  },
  [Id.BKMD05]: {
    defaultMessage: 'Did you know diabetes can slowly damage your vision over time? Try to keep ' +
    'your eye doctor appointment every year to keep your vision as healthy and clear as possible.',
    id: 'careCues.texts.bkmd05',
  },
  [Id.BKMD06]: {
    defaultMessage: 'Taking control of your diabetes starts with knowing your blood sugars. ' +
    'A Hemoglobin A1c tests your blood sugar control for the previous three months. This will ' +
    'help you and your doctor design a treatment plan to improve your blood sugar control and ' +
    'overall health if your A1c is higher than you’d like.',
    id: 'careCues.texts.bkmd06',
  },
  [Id.BKMD07]: {
    defaultMessage: 'Diabetes can be hard on your kidneys. Over time, diabetes and high blood ' +
    'pressure can cause your kidneys to fail. Take the first step to better kidney health by ' +
    'getting a yearly blood test at your doctor\'s office to monitor your kidney function.',
    id: 'careCues.texts.bkmd07',
  },
  [Id.BKMD08]: {
    defaultMessage: 'We hope you are feeling better after your fracture a few months ago. ' +
    'Have you had a bone test or started treatment since then? Bone Mineral Density tests could ' +
    'help your doctor decide the best treatment to help you to prevent future fractures. Let ' +
    'us help you find a doctor who could order the test or talk to you about treatment options.',
    id: 'careCues.texts.bkmd08',
  },
  [Id.BKMD09]: {
    defaultMessage: 'Be strong and protect your joints. It looks like you have not started ' +
    'medications to slow the joint damage caused by rheumatoid arthritis. Ask your doctor or ' +
    'let us help you find the right specialist.',
    id: 'careCues.texts.bkmd09',
  },
  [Id.BKMD10]: {
    defaultMessage: 'Take the first step to preventing heart disease. Cholesterol and fats in ' +
    'your bloodstream lead to hardening of the blood vessels but can be treated with several ' +
    'types of medication. The first step is to know when and how to get tested. Let us help you ' +
    'find a good doctor to help you!',
    id: 'careCues.texts.bkmd10',
  },
  [Id.BKMD11]: {
    defaultMessage: 'Its flu season again! This year\'s vaccine will help you stay healthy this ' +
    'flu season and also will help protect your very young and your old family members from ' +
    'getting sick. Let us help you find a doctor in your area who can help you get protected.',
    id: 'careCues.texts.bkmd11',
  },
  [Id.BKMD12]: {
    defaultMessage: 'A healthy life journey starts with a visit to your personal doctor. Your ' +
    'doctor will review your history, do a physical exam and speak with you about your health ' +
    'risks. This will help us keep you as healthy as possible this year. If you don\'t have a ' +
    'doctor, let\'s help you find one. Here\'s to a healthy and happy 2017',
    id: 'careCues.texts.bkmd12',
  },
});
