/**
 * Created by chenrozenes on 30/08/2016.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import { memoizedSelectorCreator } from '../../../utils/reselectUtils';
import injectNotification from '../../notification/injectNotification';
import { clearTrackingData } from './actions';

const defaultTracker = { hasError: false, inProgress: false };

/**
 * Creates a memoized selector for actions from the state
 * @returns {*}
 */
function createActionsSelector() {
  // Filter actions to prevent re-render if unrelated tracking objects changed
  const filterActions = (state, regexp) => {
    const actions = state.actionTracker;
    const stateActions = _.filter(actions, (obj, actionKey) => actionKey.match(regexp));
    if (_.isEmpty(stateActions)) return defaultTracker;
    return stateActions.length === 1 ? stateActions[0] : stateActions;
  };

  return memoizedSelectorCreator(filterActions, _.identity);
}

export const actionTrackerShape = PropTypes.shape({
  finished: PropTypes.bool,
  hasError: PropTypes.bool.isRequired,
  inProgress: PropTypes.bool.isRequired,
});

/**
 * Wraps a component for tracking actions that we need to know when they finished,
 * if they succeeded or failed and what is the error
 *
 * Should get a map between the component's props and the tracking key of the action
 * (which should be defined in the action creator)
 * @param mapKeysToProps
 * @returns {*}
 */
export default function actionTracker(mapKeysToProps, mapKeysToNotifications = {}) {
  const actionsSelector = createActionsSelector();
  const mapStateToProps = (state, props) =>
    _.mapValues(mapKeysToProps, regexpKey => {
      // Enable each action to be evaluated with state and props
      if (_.isFunction(regexpKey)) regexpKey = regexpKey(state, props);
      // Selecting from state with memoize, to prevent unnecessary renders
      return actionsSelector(state, regexpKey);
    });

  const mapDispatchToProps = dispatch => ({
    clearActionData(keys) {
      dispatch(clearTrackingData(keys));
    }
  });

  // Creating a wrapped react component to pass action props and clean actions data if necessary
  return Component => {
    class Wrap extends React.Component {
      static propTypes = {
        clearActionData: PropTypes.func.isRequired,
        notification: PropTypes.object.isRequired,
      };

      constructor(props) {
        super(props);
        this.state = {
          // Initializing our action props with what we got from redux
          actionProps: _.pick(props, _.keys(mapKeysToProps))
        };
      }

      /**
       * Gets the props from redux and inspects to see if action have finished
       * @param nextProps
       */
      componentWillReceiveProps(nextProps) {
        // Checking if we need to add the "finished" flag to the action props we will pass to the
        // wrapped component
        const actionProps = _.mapValues(mapKeysToProps, (val, prop) =>
          this.decorateWithFinished(
            this.props[prop], nextProps[prop], mapKeysToNotifications[prop]
          ));

        // Saving those on the state
        this.setState({ actionProps });
      }

      /**
       * Clearing all used actions on unmount
       */
      componentWillUnmount() {
        // Getting action keys
        const keysToClear = _.chain(mapKeysToProps)
          .keys()
          .map(currProp => {
            const action = this.props[currProp];
            return _.isArray(action) ? _.map(action, curr => curr.key) : action.key;
          })
          .flatten()
          .compact()
          .value();

        // Clear data only if we have data
        if (keysToClear.length > 0) {
          this.props.clearActionData(keysToClear);
        }
      }

      /**
       * Checks if change has been made to the action (not executed -> executed)
       * if yes, adds the "finished" flag
       * @param prop
       * @param nextProp
       * @returns {Array|*}
       */
      decorateWithFinished(prop, nextProp, messages) {
        const { notification } = this.props;

        prop = _.castArray(prop);
        nextProp = _.castArray(nextProp);
        _.each(prop, (currProp, index) => {
          if (!currProp.executed && nextProp[index].executed) {
            nextProp[index] = nextProp[index].set('finished', true);

            if (!_.isEmpty(messages)) {
              if (nextProp[index].hasError && !_.isEmpty(messages.error)) {
                notification.error(messages.error.title, messages.error.text);
              } else if (!_.isEmpty(messages.success)) {
                notification.success(messages.success.title, messages.success.text);
              }
            }
          }
        });

        return nextProp.length === 1 ? nextProp[0] : nextProp;
      }

      render() {
        const otherProps = _.omit(this.props, _.keys(mapKeysToProps));
        return <Component {...otherProps} {...this.state.actionProps} />;
      }
    }

    const IntlWrap = injectNotification(Wrap);

    return connect(mapStateToProps, mapDispatchToProps)(IntlWrap);
  };
}
