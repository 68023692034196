export default {
  user: 'x-bkmd-user',
  txId: 'x-bkmd-tx-id',
  sessionId: 'x-bkmd-session-id',
  internalSessionId: 'x-bkmd-internal-session-id',
  app: 'x-bkmd-app',
  domain: 'x-bkmd-domain',
  recaptcha: 'x-recaptcah',
  auth: 'x-bkmd-auth',
  apiKey: 'x-api-key',
  apiSecret: 'x-api-secret',
  publicToken: 'x-public-token',
  authorization: 'authorization',
  locale: 'locale',
  insurer: 'x-insurer',
};
