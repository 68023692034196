/**
 * Created by chenrozenes on 30/03/2017.
 */
import _ from 'lodash';
import { PASSWORD_ALLOWED_CHARACTERS } from 'Utils/regex/password';

import {
  ReduxFormCleanInput,
  ReduxFormCleanPasswordInput,
  ReduxFormCleanRepeatPasswordInput,
} from '../../../../../../components/form/reduxForm/components';

import { PasswordSchema, VerificationCodeSchema } from '../../../../../../utils/commonSchema';
import { schema } from '../../identificationSchema';

const MINIMAL_PASSWORD_STRENGTH = 2;

export const name = 'resetPassword';

export const fields = {
  identifier: {
    name: 'identifier',
    component: ReduxFormCleanInput,
  },
  verificationCode: {
    name: 'verificationCode',
    component: ReduxFormCleanInput,
  },
  password: {
    name: 'password',
    component: ReduxFormCleanPasswordInput,
  },
  repeatPassword: {
    name: 'repeatPassword',
    component: ReduxFormCleanRepeatPasswordInput,
  },
  passwordStrength: {
    name: 'passwordStrength',
    component: 'input',
  },
};

export const resetPasswordSchema = {
  identifier: schema.identifier.required(),
  verificationCode: VerificationCodeSchema.required(),
  password: PasswordSchema.required(),
  repeatPassword: PasswordSchema,
};

function pickFields(...fieldNames) {
  return _.pick(resetPasswordSchema, fieldNames);
}

export const identifierFields = pickFields(fields.identifier.name);

export const passwordFields = pickFields(fields.password.name);

export const verificationCodeFields = pickFields(fields.verificationCode.name);

export const customValidator = {
  [fields.password.name]: (value, values) => {
    // eslint-disable-next-line no-prototype-builtins
    if (!values.hasOwnProperty(fields.passwordStrength.name)) return undefined;

    const { deprecatedCharacters } = values[fields.passwordStrength.name];
    const passwordStrength = values[fields.passwordStrength.name].score;

    if (deprecatedCharacters) {
      return `Password should contain only allowed special characters: ${PASSWORD_ALLOWED_CHARACTERS}`;
    }
    if (!passwordStrength || passwordStrength < MINIMAL_PASSWORD_STRENGTH) {
      return 'Password is too weak';
    }
    return undefined;
  },
  [fields.repeatPassword.name]: (value, values) => {
    if (values[fields.password.name] !== values[fields.repeatPassword.name]) {
      return 'Passwords are not identical';
    }
    return undefined;
  },
};
