/**
 * Created by matan on 11/9/17.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  supplyFor: {
    defaultMessage: 'Supply for ',
    id: 'members.medications.supplyFor',
  },
  prescribedBy: {
    defaultMessage: 'Prescribed by ',
    id: 'members.medications.prescribedBy',
  },
  lastFilled: {
    defaultMessage: 'Last filled',
    id: 'members.medications.lastFilled',
  },
  yourMedications: {
    defaultMessage: 'Your medications',
    id: 'members.medications.yourMedications',
  },
  drTitle: {
    defaultMessage: 'Dr. {prescribedBy}',
    id: 'members.medications.drTitle',
  },
  periodSupplyFor: {
    defaultMessage: '{supplyFor} Days',
    id: 'members.medications.periodSupplyFor',
  },
  nothingToSee: {
    defaultMessage: 'Nothing to see here',
    id: 'members.medications.nothingToSee',
  },
  informationIsProvidedByPremera: {
    defaultMessage: 'Information is provided by Premera. For questions, please contact ',
    id: 'members.medications.informationIsProvidedByPremera',
  },
  history: {
    defaultMessage: 'History',
    id: 'members.medications.history',
  },
  refills: {
    defaultMessage: 'Refills',
    id: 'members.medications.refills',
  },
  dosage: {
    defaultMessage: 'dosage',
    id: 'members.medications.dosage',
  },
  days: {
    defaultMessage: ' days',
    id: 'members.medications.days',
  },
  multipleDosages: {
    defaultMessage: 'Multiple dosages',
    id: 'members.medications.multipleDosages',
  },
  recentRefillsMayNotBeListedHere: {
    defaultMessage: 'Recent refills may not be listed here.',
    id: 'members.medications.recentRefillsMayNotBeListedHere',
  },
  premeraCustomerCare: {
    defaultMessage: 'Premera Customer Care',
    id: 'members.medications.premeraCustomerCare',
  },
});
