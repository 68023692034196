import _ from 'lodash';
import { autobind } from 'core-decorators';
import * as ClinicPermissionsEntities from '../model/enum/clinicPermissionsEntities';
import BkmdApi from './bkmdApi';
import ServicesApi from './services';

const SERVICE_NAME = 'sdk';

const mapPermissions = ({
                          data: { allowedResources, webhookUrl, verificationToken, isWebHookUrlVerify, ...permissions },
                        }) => {
  const allowedHealthSystemIds = _.chain(allowedResources)
    .filter(({ ownerType }) => ownerType === ClinicPermissionsEntities.HEALTH_SYSTEM)
    .map('ownerId')
    .value();

  return {
    ...permissions,
    allowedHealthSystemIds,
    webhookUrl,
    verificationToken,
    isWebHookUrlVerify,
  };
};

@autobind
class SdkApi extends BkmdApi {
  /**
   * A fetch object that points to the server
   * @param fetch
   */
  constructor(fetch) {
    super(fetch, '/sdk');
    this.servicesApi = new ServicesApi(fetch);
  }

  getAllSdkPermissions(skip, limit, search, sort, sortAscending) {
    return this.servicesApi.callSecure(SERVICE_NAME, 'getAllSdkPermissions', {
      skip,
      limit,
      search,
      sort,
      sortAscending,
    });
  }

  verifyWebhookUrl({ webhookUrl, verificationToken }) {
    return this.servicesApi.callSecure(SERVICE_NAME, 'verifyWebhookUrl', {
      webhookUrl,
      verificationToken,
    });
  }

  createSdkPermission(sdkPermission) {
    return this.servicesApi.callSecure(SERVICE_NAME, 'createSdkPermission', sdkPermission);
  }

  generateApiKeyInAWS() {
    return this.servicesApi.callSecure(SERVICE_NAME, 'generateApiKeyInAWS');
  }

  updateSdkPermission(sdkPermission) {
    return this.servicesApi.callSecure(SERVICE_NAME, 'updateSdkPermission', sdkPermission);
  }

  backofficeGetSdkPermissions(id) {
    return this.servicesApi
      .callSecure(SERVICE_NAME, 'backofficeGetSdkPermissions', { id })
      .then(mapPermissions);
  }

  getAllSdkThemes() {
    return this.servicesApi.callSecure(SERVICE_NAME, 'getAllSdkThemes', {});
  }

  getAllSdkThemesWithPagination(skip, limit, search, sort, sortAscending) {
    return this.servicesApi.callSecure(SERVICE_NAME, 'getAllSdkThemesWithPagination', {
      skip,
      limit,
      search,
      sort,
      sortAscending,
    });
  }

  createSdkTheme({ name, ...theme }) {
    return this.servicesApi.callSecure(SERVICE_NAME, 'createSdkTheme', { name, theme });
  }

  updateSdkTheme({ name, ...theme }) {
    return this.servicesApi.callSecure(SERVICE_NAME, 'updateSdkTheme', { name, theme });
  }

  getSdkTheme(name) {
    return this.servicesApi.callSecure(SERVICE_NAME, 'getSdkTheme', { name });
  }

  getReferralGuidanceConfig({ apiKey, id }) {
    return this.servicesApi.call(SERVICE_NAME, 'getReferralGuidanceConfig', { apiKey, id })
      .then(({ data }) => data);
  }

  updateReferralGuidanceConfig({ apiKey, id, newConfig }) {
    return this.servicesApi.callSecure(SERVICE_NAME, 'updateReferralGuidanceConfig', { apiKey, id, newConfig });
  }
}

export default SdkApi;
