/**
 * Created by chenrozenes on 04/07/2017.
 */

import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import CopyToClipboard from 'react-copy-to-clipboard';
import { defineMessages, FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { Popover, OverlayTrigger } from 'react-bootstrap';
import { autobind } from 'core-decorators';
import { getContext } from 'recompose';
import { connect } from 'react-redux';
import { compose } from 'redux';

import shareMessages from './messages';
import BkmdUtils from '../../../../../../utils/util';
import ShareDoctorHeader from './shareDoctorHeader';
import displayProvider from '../../../../../../utils/displayProvider';
import * as platformSelectors from '../../../../../../store/platform/selectors';
import ModalHeader from '../../../../../../components/bkmdModal/modalHeader';
import { getFeature } from '../../../../../../store/feature/selectors';

const messages = defineMessages({
  quickShareWith: {
    defaultMessage: 'Quick share with',
    id: 'members.shareModal.quickShareWith',
  },
  share: {
    defaultMessage: 'Share',
    id: 'members.shareModal.share',
  },
  message: {
    defaultMessage: 'Text message',
    id: 'members.shareModal.message',
  },
  email: {
    defaultMessage: 'Email',
    id: 'members.shareModal.email',
  },
  copyLink: {
    defaultMessage: 'Copy link',
    id: 'members.shareModal.copyLink',
  },
  copiedToYourClipboard: {
    defaultMessage: 'Copied to your clipboard',
    id: 'members.shareModal.copiedToYourClipboard',
  },
  mobileSmsContent: {
    defaultMessage: 'I found this doctor for you in the {domain} network. {name}, {url}',
    id: 'members.shareModal.mobileSmsContent',
  },
  mobileEmailContentSubject: {
    defaultMessage: 'Check out {name} on {domainTitle}',
    id: 'members.shareModal.mobileEmailContentSubject',
  },
  mobileEmailContentBody: {
    defaultMessage: 'I found this doctor for you in the {domain} network. {name}\n{url}',
    id: 'members.shareModal.mobileEmailContentBody',
  },
});

const copyLinkPopover = (
  <Popover id="link-popover" className="copy-link-popover">
    <i className="fa fa-check text-success" />
    <span className="text-semibold text-dark">
      <FormattedMessage {...messages.copiedToYourClipboard} />
    </span>
    <br />
  </Popover>
);

@autobind
class ShareOptions extends React.Component {
  static propTypes = {
    intl: intlShape.isRequired,
    provider: PropTypes.object.isRequired,
    isMobile: PropTypes.bool.isRequired,
    wizard: PropTypes.object.isRequired,
    handleClose: PropTypes.func.isRequired,
    paths: PropTypes.object.isRequired,
    providerLocationId: PropTypes.number,
    phoneType: PropTypes.string.isRequired,
    domainClientName: PropTypes.string.isRequired,
    domainTitle: PropTypes.string.isRequired,
  };

  static defaultProps = {
    providerLocationId: null,
  };

  getMobileSmsSeparator() {
    const { phoneType } = this.props;
    return BkmdUtils.isIphoneOrIpad(phoneType) ? '&' : '?';
  }

  buildUrl() {
    const baseUrl = _.get(window, 'location.origin');
    const { provider, providerLocationId, paths } = this.props;
    return `${baseUrl}${paths.doctorDetails(provider.npi, { locationId: providerLocationId })}`;
  }

  composeEmail() {
    const {
      intl,
      isMobile,
      domainClientName,
      domainTitle,
      provider: { firstName, lastName, suffix },
    } = this.props;
    const providerDisplayName = displayProvider(firstName, lastName, suffix);

    if (!isMobile) {
      return '';
    }

    const url = this.buildUrl();
    const body = intl.formatMessage(messages.mobileEmailContentBody, {
      domain: domainClientName,
      name: providerDisplayName,
      url,
    });
    const subject = intl.formatMessage(messages.mobileEmailContentSubject, {
      name: providerDisplayName,
      domainTitle,
    });

    return `mailto:?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
  }

  composeSms() {
    const {
      intl,
      isMobile,
      domainClientName,
      provider: { firstName, lastName, suffix },
    } = this.props;
    const providerDisplayName = displayProvider(firstName, lastName, suffix);

    if (!isMobile) {
      return '';
    }

    const url = this.buildUrl();
    const body = intl.formatMessage(messages.mobileSmsContent, {
      domain: domainClientName,
      name: providerDisplayName,
      url,
    });
    const separator = this.getMobileSmsSeparator();

    return `sms:${separator}body=${encodeURIComponent(body)}`;
  }

  gotoEmail(e) {
    if (!this.props.isMobile) {
      e.preventDefault();
      this.props.wizard.advance();
    }
  }

  gotoSms(e) {
    if (!this.props.isMobile) {
      e.preventDefault();
      this.props.wizard.advance(2);
    }
  }

  render() {
    const { provider } = this.props;
    return (
      <div>
        <ModalHeader onClose={this.props.handleClose} title={shareMessages.shareThisDoctor} />
        <div className="dialog-body">
          <ShareDoctorHeader provider={provider} />
          <div className="share-options-section">
            <div className="row text-center">
              <div className="col-xs-12">
                <a
                  href={this.composeSms()}
                  onClick={this.gotoSms}
                  className="btn btn-big btn-outline inline-block"
                >
                  <i className="icon-speak-b" />
                  &nbsp;
                  <FormattedMessage {...messages.message} />
                </a>
              </div>
            </div>
            <div className="row text-center">
              <div className="col-xs-12">
                <a
                  href={this.composeEmail()}
                  onClick={this.gotoEmail}
                  className="btn btn-big btn-outline inline-block"
                >
                  <i className="icon-envelope-b" />
                  &nbsp;
                  <FormattedMessage {...messages.email} />
                </a>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12">
                <CopyToClipboard text={this.buildUrl()}>
                  <OverlayTrigger
                    trigger="click"
                    rootClose
                    placement="top"
                    overlay={copyLinkPopover}
                  >
                    <button className="btn btn-big btn-outline">
                      <i className="icon-link" />
                      &nbsp;
                      <FormattedMessage {...messages.copyLink} />
                    </button>
                  </OverlayTrigger>
                </CopyToClipboard>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default compose(
  injectIntl,
  getContext({ paths: PropTypes.object.isRequired }),
  connect(state => ({
    isMobile: platformSelectors.isMobile(state),
    phoneType: state.platform.phoneType,
    domainClientName: getFeature(state, 'site.clientName'),
    domainTitle: getFeature(state, 'site.title'),
  })),
)(ShareOptions);
