/**
 * Created by chenrozenes on 31/01/2017.
 */
import fetch from 'isomorphic-fetch';

import AuthApi from './auth';
import UsersApi from './users';
import SearchApi from './search';
import MembersApi from './members';
import ProviderApi from './provider';
import ActivationApi from './activation';
import BackofficeApi from './backoffice';
import EligibilityApi from './eligibility';
import FeedbackSurveyApi from './feedbackSurvey';
import OnBoardingApi from './providerOnBoarding';
import CommunicationApi from './communication';
import MetaApi from './meta';
import FacilitySearchApi from './facilitySearch';
import AnalyticsApi from './analytics';
import AppointmentsApi from './appointments';
import AppointmentRequestsApi from './appointmentRequest';
import ExternalLinksApi from './externalLinks';
import ClinicsApi from './clinics';
import ResourceApi from './resource';
import CalendarApi from './calendar';
import ProviderAppointmentsApi from './providerAppointmentsApi';
import SsoApi from './sso';
import AclApi from './acl';
import ProviderProfileApi from './providerProfile';
import ScheduleApi from './clinicManagement/schedule';
import ClinicResourceApi from './clinicManagement/resource';
import ClinicPermissionsApi from './clinicPermissions';
import SchedulingApi from './scheduling';
import OutreachApi from './outreach';
import MedicalCodesApi from './medicalCodes';
import VimGuideProfileApi from './vimGuideProfile';
import ShareApi from './share';
import SdkApi from './sdk';
import PCPSelectionApi from './pcpSelection';
import ColorectalFlowApi from './colorectalFlow';
import TokenManagerApi from './tokenManager';
import CostEstimationApi from './costEstimation';
import InterventionFlowDataApi from './interventionFlowData';
import PeriscopeApi from './periscope';
import PayerApi from './payer';
import FacilityApi from './facilityApi';
import Session from './session';
import NamespaceManager from './namespaceManager';
import AsoGroup from './asoGroup';

export const createApi = fetch => ({
  aclApi: new AclApi(fetch),
  activationApi: new ActivationApi(fetch),
  analyticsApi: new AnalyticsApi(fetch),
  appointmentApi: new AppointmentsApi(fetch),
  appointmentRequestsApi: new AppointmentRequestsApi(fetch),
  asoGroup: new AsoGroup(fetch),
  authApi: new AuthApi(fetch),
  backofficeApi: new BackofficeApi(fetch),
  calendarsApi: new CalendarApi(fetch),
  clinicPermissionsApi: new ClinicPermissionsApi(fetch),
  clinicResourceApi: new ClinicResourceApi(fetch),
  clinicsApi: new ClinicsApi(fetch),
  colorectalFlowApi: new ColorectalFlowApi(fetch),
  communicationApi: new CommunicationApi(fetch),
  costEstimationApi: new CostEstimationApi(fetch),
  eligibilityApi: new EligibilityApi(fetch),
  externalLinksApi: new ExternalLinksApi(fetch),
  facilityApi: new FacilityApi(fetch),
  facilitySearchApi: new FacilitySearchApi(fetch),
  feedbackSurveyApi: new FeedbackSurveyApi(fetch),
  interventionFlowDataApi: new InterventionFlowDataApi(fetch),
  medicalCodesApi: new MedicalCodesApi(fetch),
  membersApi: new MembersApi(fetch),
  metaApi: new MetaApi(fetch),
  namespaceManagerApi: new NamespaceManager(fetch),
  onBoardingApi: new OnBoardingApi(fetch),
  outreachApi: new OutreachApi(fetch),
  payerApi: new PayerApi(fetch),
  pcpSelectionApi: new PCPSelectionApi(fetch),
  periscopeApi: new PeriscopeApi(fetch),
  providerApi: new ProviderApi(fetch),
  providerAppointmentsApi: new ProviderAppointmentsApi(fetch),
  providerProfileApi: new ProviderProfileApi(fetch),
  resourceApi: new ResourceApi(fetch),
  scheduleApi: new ScheduleApi(fetch),
  schedulingApi: new SchedulingApi(fetch),
  sdkApi: new SdkApi(fetch),
  searchApi: new SearchApi(fetch),
  sessionApi: new Session(fetch),
  shareApi: new ShareApi(fetch),
  ssoApi: new SsoApi(fetch),
  tokenManagerApi: new TokenManagerApi(fetch),
  usersApi: new UsersApi(fetch),
  vimGuideProfileApi: new VimGuideProfileApi(fetch),
});

const Api = createApi(fetch);

export default Api;
