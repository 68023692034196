/**
 * Created by chenrozenes on 30/03/2017.
 */
import { compose, branch } from 'recompose';
import _ from 'lodash';
import flowControl from './flowControl';
import FlowManager, { externalParams } from './baseFlowManager';

const enhanced = compose(
  branch(props => !props.flow, flowControl(externalParams)),
)(FlowManager);

enhanced.defaultProps = {
  finishOnUnmount: true,
  startOnMount: true,
  onStart: _.noop,
  onFinish: _.noop,
  onStepChange: _.noop,
};

export default enhanced;
