import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withTheme } from '../../../utils/theme';

const h1Size = {
  default: 'title-24',
  large: 'title-36',
};

const h1Margin = {
  default: 'margin-30',
  medium: 'md-margin',
  small: 'margin-10',
  none: 'no-margin',
};

const h1Align = {
  default: 'text-center',
  left: 'text-left',
  right: 'text-right',
};

function BrandedH1({ text, size, margin, theme, align, children }) {
  const dynamicStyle = {
    color: theme.mainColor,
    fontFamily: theme.font,
    fontWeight: theme.fontWeight,
  };
  return (
    <h1
      className={classNames('title-brand-1', h1Size[size], h1Margin[margin], h1Align[align])}
      style={dynamicStyle}
    >
      {children}
      {text}
    </h1>
  );
}

BrandedH1.propTypes = {
  text: PropTypes.string.isRequired,
  size: PropTypes.string,
  margin: PropTypes.string,
  align: PropTypes.string,
  theme: PropTypes.object.isRequired,
};

BrandedH1.defaultProps = {
  size: 'default',
  margin: 'default',
  align: 'default',
};

export default withTheme(BrandedH1);
