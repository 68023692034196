/**
 * Created by chenrozenes on 26/12/2017.
 */
import _ from 'lodash';
import { compose, withHandlers } from 'recompose';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

import queryConnect from '../../../store/tools/queryString/queryConnect';
import routingSelectors from '../../../store/routing/selectors';
import * as selectors from './selectors';
import FlowControl, { evaluateExternalParams, mapQueryToProps } from './baseFlowControl';

/**
 * HOC for controlling a flow.
 * The HOC will inject a flow control object which contains methods and props for controlling
 * an existing flow
 * This HOC also wraps and manages the FlowManager but can also be used externally in order to
 * control the flow outside the steps
 * This is the React Router 2 version of the HOC
 *
 */
export default function flowControl(externalParams = {}) {
  return compose(
    withRouter,
    withHandlers({
      browserBack: ({ router }) => () => router.goBack(),
    }),
    queryConnect(mapQueryToProps),
    connect((state, props) => {
      const params = evaluateExternalParams(externalParams, state, props);

      return {
        name: params.name,
        flowId: params.flowId,
        stepsMeta: params.stepsMeta,
        initialStepName: params.initialStepName,
        fromStep: _.get(routingSelectors.currRouteState(state), 'fromStep'),
        isActive: selectors.isFlowActive(state, params.name),
      };
    }),
    FlowControl,
  );
}
