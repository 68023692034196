import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { defineMessages, FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { compose } from 'recompose';

import isInNetwork from '../../../../../utils/networkStatus';
import * as NetworkStatusEnum from '../../../../../model/enum/networkStatus';
import SvgShieldCheck from '../../../../../assets/svg/shieldCheck';

import './NetworkStatusChip.less';
import NetworkTierDescription from '../NetworkTierDescription/NetworkTierDescription';

const messages = defineMessages({
  inNetwork: {
    defaultMessage: 'In-Network',
    id: 'members.doctorDetails.inNetwork',
  },
  ofOutNetwork: {
    defaultMessage: 'Out-of-Network',
    id: 'members.doctorDetails.ofOutNetwork',
  },
  unverifiedNetwork: {
    defaultMessage: 'Network<br/>unverified',
    id: 'members.doctorDetails.unverifiedNetwork',
  },
});

function InNetwork({
  tierData: { tier, description, color, shouldShowStatus },
  block,
  popoverPlacement,
}) {
  const paddingLeft = tier && shouldShowStatus && !block;
  return (
    <div>
      {shouldShowStatus ? (
        <div className="in-network inline-block">
          <SvgShieldCheck />
          <FormattedMessage {...messages.inNetwork} />
        </div>
      ) : null}
      {tier ? (
        <NetworkTierDescription
          color={color}
          description={description}
          title={tier}
          block={block}
          paddingLeft={paddingLeft}
          placement={popoverPlacement}
        />
      ) : null}
    </div>
  );
}

InNetwork.propTypes = {
  tierData: PropTypes.object,
  block: PropTypes.bool,
  popoverPlacement: PropTypes.string,
};

InNetwork.defaultProps = {
  tierData: { shouldShowStatus: true },
  block: false,
  popoverPlacement: undefined,
};

// this hack will insure we get the member in directory or atlas
// we should fix it and bring the tiers data from the parent components
const tryGetAsoInfo = state =>
  _.get(state, 'memberSession.patient.member.asoMetadata') ||
  _.get(state, 'member.info.asoMetadata') ||
  _.get(state, 'share.details.member.asoMetadata');

const ComposedInNetwork = compose(
  connect((state, { tier, asoMetadata: asoMetadataProp }) => {
    const asoMetadata = asoMetadataProp || tryGetAsoInfo(state);
    const providerTier = tier || _.get(asoMetadata, 'defaultTier.name');
    if (!providerTier || !asoMetadata) return {};
    const tiers = _.get(asoMetadata, 'tiers', []);
    const foundTier = _.find(tiers, tier => _.toUpper(tier.name) === _.toUpper(providerTier));
    if (!foundTier) return {};
    const { title, description, showInNetwork, overrideColor } = foundTier;
    return {
      tierData: {
        tier: title,
        description,
        shouldShowStatus: showInNetwork,
        color: overrideColor,
      },
    };
  }),
)(InNetwork);

export function Unverified() {
  return (
    <div className="network-unverified">
      <FormattedHTMLMessage {...messages.unverifiedNetwork} />
    </div>
  );
}

function OutOfNetwork() {
  return (
    <div className="out-of-network">
      <FormattedMessage {...messages.ofOutNetwork} />
    </div>
  );
}

const NetworkStatusToComponent = {
  [NetworkStatusEnum.UNVERIFIED_NETWORK]: Unverified,
  [NetworkStatusEnum.IN_NETWORK]: ComposedInNetwork,
  [NetworkStatusEnum.OUT_OF_NEWTORK]: OutOfNetwork,
};

export function PureNetworkStatus({
  location,
  plan,
  networkStatus,
  block,
  asoMetadata,
  popoverPlacement,
  hideUnverified,
}) {
  const status = _.get(location, 'network.status', networkStatus.result);
  const tier = _.get(location, 'network.tier', networkStatus.tier);
  const networkStatusEnum = status || isInNetwork(_.get(location, 'networks'), plan);
  if (networkStatusEnum === NetworkStatusEnum.UNVERIFIED_NETWORK && hideUnverified) return <div />;
  const Status = NetworkStatusToComponent[networkStatusEnum];

  return (
    <Status
      tier={tier}
      block={block}
      asoMetadata={asoMetadata}
      popoverPlacement={popoverPlacement}
    />
  );
}

PureNetworkStatus.propTypes = {
  networkStatus: PropTypes.object,
  location: PropTypes.object.isRequired,
  plan: PropTypes.string,
  block: PropTypes.bool,
  asoMetadata: PropTypes.object,
  popoverPlacement: PropTypes.string,
  hideUnverified: PropTypes.bool,
};

PureNetworkStatus.defaultProps = {
  plan: '',
  networkStatus: {},
  block: false,
  asoMetadata: undefined,
  popoverPlacement: undefined,
  hideUnverified: false,
};

export default PureNetworkStatus;
