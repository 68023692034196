import React from 'react';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import AppointmentDetailsWidget from '../../components/appointmentDetails/appointmentDetailsWidget';
import MessageBanner from '../../components/molecules/messageBanner/messageBanner';

@autobind
export class SuccessfulConfirmation extends React.PureComponent {
  static propTypes = {
    appointment: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    service: PropTypes.object.isRequired,
    onClick: PropTypes.func.isRequired,
    showWeSent: PropTypes.bool.isRequired,
    showMessageBanner: PropTypes.bool,
    titleText: PropTypes.string,
    buttonText: PropTypes.string,
    confirmationText: PropTypes.string,
    secondaryText: PropTypes.string,
    messageBannerText: PropTypes.string,
    phoneNumber: PropTypes.string,
    showSelectedAppointmentType: PropTypes.bool,
  };

  static defaultProps = {
    phoneNumber: undefined,
    confirmationText: '',
    titleText: '',
    buttonText: '',
    secondaryText: undefined,
    messageBannerText: undefined,
    showMessageBanner: false,
    showSelectedAppointmentType: true,
  };

  _renderWeSent() {
    const { phoneNumber, confirmationText } = this.props;
    return (
      <div>
        <div className="text-16">{confirmationText}</div>
        {phoneNumber && <div className="text-16 text-semibold text-dark">{phoneNumber}</div>}
      </div>
    );
  }

  render() {
    const {
      appointment,
      location,
      service,
      titleText,
      buttonText,
      showWeSent,
      showMessageBanner,
      secondaryText,
      messageBannerText,
      showSelectedAppointmentType,
    } = this.props;
    return (
      <div className="confirmation">
        <div className="title-24 margin-30 text-strong text-center">
          <i className="fa fa-check text-success" />
          &nbsp;
          <span className="font-color-brand-main">{titleText}</span>
        </div>

        {secondaryText && (
          <div className="text-16 margin-bottom-clean-form text-center text-dark">
            {secondaryText}
          </div>
        )}

        <AppointmentDetailsWidget
          showSelectedAppointmentType={showSelectedAppointmentType}
          appointment={appointment}
          location={location}
          service={service}
        />
        {showWeSent && this._renderWeSent()}

        <button
          className="btn btn-small btn-outline btn-block margin-top-30 text-semibold"
          onClick={this.props.onClick}
        >
          {buttonText}
        </button>
        {showMessageBanner && <MessageBanner text={messageBannerText} />}
      </div>
    );
  }
}
