import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { autobind } from 'core-decorators';

import BkmdModal from '../../../../../../components/bkmdModal/bkmdModal';
import ModalHeader from '../../../../../../components/bkmdModal/modalHeader';
import { PcpSearchSteering } from '../../../../../../store/tools/analytics/events/memberEvents';

const mainImg = require("../images/primary-care-modal-img.png");

const messages = defineMessages({
  whyStartWithAPrimaryCareDoctor: {
    defaultMessage: 'Why start with a primary care doctor?',
    id: 'members.primaryCareModal.whyStartWithAPrimaryCareDoctor',
  },
  findAPrimaryCareDoctor: {
    defaultMessage: 'Find a primary care doctor',
    id: 'members.primaryCareModal.findAPrimaryCareDoctor',
  },
  modalText: {
    defaultMessage:
      'In most cases, before you go to a specialist you should consult with ' +
      'a primary care doctor first. A primary care doctor is responsible for seeing the entire ' +
      'picture of your health. They will make connections based on your health history, symptoms, ' +
      'lifestyle and more that are important to getting your diagnosis and treatment right.' +
      '<br/><br/>If your doctor decides that you should see a specialist, they will make sure that the ' +
      'specialist receives your medical records and the relevant information that will help them ' +
      'make better choices about your treatment plan.<br/><br/>Going to a primary care doctor also ' +
      'costs you less than going to a specialist. They can help assess the situation first ' +
      'before you start a more costly treatment plan.<br/><br/>They’re your own personal hero and' +
      ' they have your back.',
    id: 'members.primaryCareModal.modalText',
  },
});

@autobind
class PrimaryCareModal extends React.PureComponent {
  static propTypes = {
    onChoosePcpLabel: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    /**
     * Name for identifying via analytics
     */
    name: PropTypes.string,
  };

  static contextTypes = {
    analytics: PropTypes.object.isRequired,
  };

  static defaultProps = {
    name: 'primary_care_modal',
  };

  onClickFindPcp() {
    const { handleClose, onChoosePcpLabel } = this.props;

    this.context.analytics.track(PcpSearchSteering);

    onChoosePcpLabel();
    handleClose();
  }

  render() {
    const { isOpen, handleClose, name } = this.props;

    return (
      <div>
        <BkmdModal
          isOpen={isOpen}
          handleClose={handleClose}
          name={name}
          className="primary-care-modal fixed-header"
        >
          <ModalHeader onClose={handleClose} title="Primary care doctor" />
          <div className="dialog-body">
            <div className="text-center">
              <img src={mainImg} className="main-img" alt="" />
            </div>
            <div className="title-24 no-margin text-strong-main text-center">
              <FormattedMessage {...messages.whyStartWithAPrimaryCareDoctor} />
            </div>
            <div className="text-16 margin-top-20">
              <FormattedHTMLMessage {...messages.modalText} />
            </div>
            <div className="margin-top-30">
              <button className="btn btn-big btn-outline" onClick={this.onClickFindPcp}>
                <FormattedMessage {...messages.findAPrimaryCareDoctor} />
              </button>
            </div>
          </div>
        </BkmdModal>
      </div>
    );
  }
}

export default PrimaryCareModal;
