import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '../../storybook/src/utils/theme';

function SvgWorkStars({ theme }) {
  /* eslint-disable */
  return (
    <svg aria-hidden="true" focusable="false" width="62px" height="60px" viewBox="0 0 62 60" version="1.1" className="svg-icon svg-work-stars">
      <defs>
        <polygon id="path-1" points="0 60 62 60 62 0 0 0"></polygon>
      </defs>
      <g id="All-settings-screens" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Work-address" transform="translate(-157.000000, -146.000000)">
          <g id="Page-1" transform="translate(157.000000, 146.000000)">
            <path d="M45.5,17 C44.672,17 44,16.328 44,15.5 L44,4.611 C44,3.723 43.327,3 42.5,3 L18.5,3 C17.673,3 17,3.723 17,4.611 L17,15.5 C17,16.328 16.328,17 15.5,17 C14.672,17 14,16.328 14,15.5 L14,4.611 C14,2.068 16.019,0 18.5,0 L42.5,0 C44.981,0 47,2.068 47,4.611 L47,15.5 C47,16.328 46.328,17 45.5,17 Z" id="Fill-1" fill={theme.secondaryColor} ></path>
            <g id="Clip-4"></g>
            <path d="M22,43 L39,43 L39,36 L22,36 L22,43 Z M39.5,46 L21.5,46 C20.121,46 19,44.879 19,43.5 L19,35.5 C19,34.121 20.121,33 21.5,33 L39.5,33 C40.879,33 42,34.121 42,35.5 L42,43.5 C42,44.879 40.879,46 39.5,46 Z" id="Fill-3" fill={theme.secondaryColor} ></path>
            <path d="M54.5,19 L6.5,19 C4.57,19 3,20.57 3,22.5 L3,52.5 C3,54.43 4.57,56 6.5,56 L46.5,56 C47.328,56 48,55.328 48,54.5 C48,53.672 47.328,53 46.5,53 L6.5,53 C6.229,53 6,52.771 6,52.5 L6,42 L15.5,42 C16.328,42 17,41.328 17,40.5 C17,39.672 16.328,39 15.5,39 L6,39 L6,22.5 C6,22.229 6.229,22 6.5,22 L54.5,22 C54.771,22 55,22.229 55,22.5 L55,39 L45.5,39 C44.672,39 44,39.672 44,40.5 C44,41.328 44.672,42 45.5,42 L55,42 L55,44.5 C55,45.328 55.672,46 56.5,46 C57.328,46 58,45.328 58,44.5 L58,22.5 C58,20.57 56.43,19 54.5,19" id="Fill-5" fill={theme.mainColor} ></path>
            <path d="M8.45073332,7.45073332 L6.54926668,7.45073332 L6.54926668,5.54926668 C6.54926668,4.69407147 5.85519521,4 5,4 C4.14480479,4 3.45073332,4.69407147 3.45073332,5.54926668 L3.45073332,7.45073332 L1.54926668,7.45073332 C0.694071473,7.45073332 0,8.14480479 0,9 C0,9.85519521 0.694071473,10.5492667 1.54926668,10.5492667 L3.45073332,10.5492667 L3.45073332,12.4507333 C3.45073332,13.3059285 4.14480479,14 5,14 C5.85519521,14 6.54926668,13.3059285 6.54926668,12.4507333 L6.54926668,10.5492667 L8.45073332,10.5492667 C9.30592853,10.5492667 10,9.85519521 10,9 C10,8.14480479 9.30592853,7.45073332 8.45073332,7.45073332" id="Fill-6" fill={theme.secondaryColor} ></path>
            <path d="M56.5,52 C55.121,52 54,53.121 54,54.5 C54,55.879 55.121,57 56.5,57 C57.879,57 59,55.879 59,54.5 C59,53.121 57.879,52 56.5,52 M56.5,60 C53.468,60 51,57.532 51,54.5 C51,51.468 53.468,49 56.5,49 C59.532,49 62,51.468 62,54.5 C62,57.532 59.532,60 56.5,60" id="Fill-7" fill={theme.secondaryColor} ></path>
            <path d="M57,13.4994606 C57,14.3290183 56.328777,15 55.5,15 C54.671223,15 54,14.3290183 54,13.4994606 C54,12.6709817 54.671223,12 55.5,12 C56.328777,12 57,12.6709817 57,13.4994606" id="Fill-8" fill={theme.secondaryColor} ></path>
          </g>
        </g>
      </g>
    </svg>
  );
  /* eslint-enable */
}

SvgWorkStars.propTypes = {
  theme: PropTypes.object.isRequired,
};

export default withTheme(SvgWorkStars);
