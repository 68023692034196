import _ from 'lodash';
import PropTypes from 'prop-types';
import { compose, withHandlers } from 'recompose';
import { getGeoAndZip } from '../../../utils/geocodeByLocation';
import { withFetchers } from '../../../api/injectApi/withFetchers';
import LocationSelect from '../locationSelect/locationSelect';

const LocationSelectWithGeoAndZip = compose(
  withFetchers({
    getGeoAndZip: {
      handler: () => address => getGeoAndZip(address),
      onStart: ({ onStartGeocode }) => onStartGeocode(),
      onSuccess: ({ onChange, onFinishGeocode }, { address, ...geo }) => {
        onChange({ geo, address, locationType: '' });
        onFinishGeocode();
      },
      onError: (props, error) => {
        throw new Error(`Google service places auto complete failed with status = ${error}`);
      },
    },
  }),
  withHandlers({
    addressChange: ({ getGeoAndZip, onChange }) => address => {
      if (!_.isEmpty(address)) {
        getGeoAndZip(address);
      } else {
        onChange(null);
      }
    },
  }),
)(LocationSelect);

LocationSelectWithGeoAndZip.propTypes = {
  onStartGeocode: PropTypes.func.isRequired,
  onFinishGeocode: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  location: PropTypes.object,
};

export default LocationSelectWithGeoAndZip;
