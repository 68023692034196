/**
 * Created by guyavarham on 29/06/2017.
 */

import _ from 'lodash';
import React from 'react';
import { autobind } from 'core-decorators';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import ScrollSelect from '../form/horizontalScrollSelect';
import CleanRadioGroup from '../form/cleanRadioGroup';
import IntlEnum from '../../utils/intlEnum';

import './taxonomySelector.less';

@autobind
class TaxonomySelector extends React.Component {
  static propTypes = {
    bucketLabelsToCodes: PropTypes.object,
  };

  static defaultProps = {
    bucketLabelsToCodes: {},
  };

  render() {
    const { bucketLabelsToCodes } = this.props;
    if (_.keys(bucketLabelsToCodes).length < 2) return null;
    return (
      <ScrollSelect
        className="taxonomy-selector-scroller bg-color-brand-secondary-dark"
        style={{ height: 56 }}
      >
        <CleanRadioGroup {...this.props} btnStyle="white-focus" />
      </ScrollSelect>
    );
  }
}

export default compose(
  connect((state, ownProps) => {
    const bucketLabels = _.chain(ownProps.bucketLabelsToCodes)
      .keys()
      .map(curr => ({ value: curr, message: curr }))
      .value();

    const intlEnum = new IntlEnum(...bucketLabels);
    return {
      intlEnum,
      optionsWrapClass: 'btn-group',
      singleOptionClass: 'btn btn-small btn-pill btn-outline text-semibold',
      wrapClass: 'inline-block taxonomy-selector',
    };
  }),
)(TaxonomySelector);
