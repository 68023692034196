/**
 * Created by moran on 7/27/17.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { autobind } from 'core-decorators';

import DoctorLogo from '../../../../../../components/doctorImage/doctorLogo';
import shareMessages from './messages';
import displayProvider from '../../../../../../utils/displayProvider';

@autobind
class ShareDoctorHeader extends React.Component {
  static propTypes = {
    provider: PropTypes.object.isRequired,
  };

  render() {
    const { provider } = this.props;
    const { firstName, lastName, suffix } = provider;
    const providerDisplayName = displayProvider(firstName, lastName, suffix);

    return (
      <div className="top-section">
        <div className="doctor-img-wrap">
          <DoctorLogo provider={provider} />
        </div>
        <div className="title-24 text-semibold no-margin">
          <FormattedMessage {...shareMessages.shareDrInfo} values={{ providerDisplayName }} />
        </div>
      </div>
    );
  }
}

export default ShareDoctorHeader;
