import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { defineMessages, FormattedMessage } from 'react-intl';
import { Link } from 'react-router';
import ClickableDiv from '../../../../components/clickableElement/clickableDiv';

import Layout from '../layout/layout';
import Formatter from '../../../../utils/formatter';
import SettingsLayout from './settingsLayout';
import InviteFamilyFlow from '../activation/familyActivation/inviteFamilyFlow';
import { hasInsuranceIdSelector } from '../../../../store/member/selectors';
import FeatureFlagged from '../../../../components/features/featureFlagged';
import SvgUserCircle from '../../../../assets/svg/userCircle';
import {
  fetchHasFamilyMembersNotEmpty,
  getHasDependents,
} from '../../../../store/familyActivation/selectors';
import {
  createToggleState,
  createToggleStatePropTypes,
} from '../../../../components/recompose/toggleStateComponent';

import { paths } from './paths';

const messages = defineMessages({
  settings: {
    defaultMessage: 'Settings',
    id: 'members.settings.settings',
  },
  profileSettings: {
    defaultMessage: 'Profile settings',
    id: 'members.settings.profileSettings',
  },
  yourLocations: {
    defaultMessage: 'Your locations',
    id: 'members.settings.yourLocations',
  },
  notifications: {
    defaultMessage: 'Notifications',
    id: 'members.settings.notifications',
  },
  inviteYourFamily: {
    defaultMessage: 'Invite your family',
    id: 'members.settings.inviteYourFamily',
  },
});

function SettingsHome(props) {
  const {
    fullName,
    phoneNumber,
    email,
    hasInsuranceId,
    inviteFamilyModalOn,
    inviteFamilyModalHide,
    inviteFamilyModalShow,
    hasDependents,
  } = props;

  return (
    <Layout>
      <SettingsLayout>
        <div className="profile-basic-info home margin-top-30">
          <SvgUserCircle />
          <div className="basic-info-text">
            <span className="text-16 text-dark text-semibold">{fullName}</span>
            <div className="text-16 text-dark">{Formatter.phoneNumber(phoneNumber)}</div>
            <div className="text-16 text-dark">{email}</div>
          </div>
        </div>

        <ul className="settings-menu-list">
          <li>
            <Link to={paths.profile()}>
              <i className="icon-user-2 item-icon" />
              <div className="item-content">
                <span className="text-dark text-16 text-semibold">
                  <FormattedMessage {...messages.profileSettings} />
                </span>
              </div>
              <i className="icon-chevron-right item-arrow" />
            </Link>
          </li>
          <li>
            <Link to={paths.locations()}>
              <i className="icon-home-2 item-icon" />
              <div className="item-content">
                <span className="text-dark text-16 text-semibold">
                  <FormattedMessage {...messages.yourLocations} />
                </span>
              </div>
              <i className="icon-chevron-right item-arrow" />
            </Link>
          </li>
          <li>
            <Link to={paths.notifications()}>
              <i className="icon-notification-s item-icon" />
              <div className="item-content">
                <span className="text-dark text-16 text-semibold">
                  <FormattedMessage {...messages.notifications} />
                </span>
              </div>
              <i className="icon-chevron-right item-arrow" />
            </Link>
          </li>
          <FeatureFlagged renderLi uri="familyActivation" hide={!hasDependents}>
            <ClickableDiv onClick={inviteFamilyModalShow}>
              <a href="">
                <i className="icon-add-member item-icon" />
                <div className="item-content">
                  <span className="text-dark text-16 text-semibold">
                    <FormattedMessage {...messages.inviteYourFamily} />
                  </span>
                </div>
              </a>
            </ClickableDiv>
          </FeatureFlagged>
        </ul>
      </SettingsLayout>

      <InviteFamilyFlow
        hasInsuranceId={hasInsuranceId}
        isOpen={inviteFamilyModalOn}
        onClose={inviteFamilyModalHide}
        skipIntro
      />
      <h1 className="off-screen-text">
        <FormattedMessage {...messages.settings} />
      </h1>
    </Layout>
  );
}

SettingsHome.propTypes = {
  phoneNumber: PropTypes.object.isRequired,
  email: PropTypes.string.isRequired,
  fullName: PropTypes.string.isRequired,
  member: PropTypes.object.isRequired,
  hasInsuranceId: PropTypes.bool,
  hasDependents: PropTypes.bool,
  ...createToggleStatePropTypes('inviteFamilyModal'),
};

SettingsHome.defaultProps = {
  hasInsuranceId: null,
  hasDependents: null,
};

export default compose(
  createToggleState('inviteFamilyModal'),
  fetchHasFamilyMembersNotEmpty(),
  connect(state => ({
    hasInsuranceId: hasInsuranceIdSelector(state),
    hasDependents: getHasDependents(state),
  })),
)(SettingsHome);
