/**
 * Created by galgoltzman on 19/06/2017.
 */
import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Dropdown, MenuItem } from 'react-bootstrap';
import { autobind } from 'core-decorators';
import _ from 'lodash';
import { defineMessages, FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { mobileMenuOpen } from '../../../store/ui/actions';
import { isMobile } from '../../../store/platform/selectors';
import { getCurrentLocale } from '../../../store/intl/selectors';
import { updateIntl } from '../../../store/app/actions';
import Languages from '../../../store/intl/languages';

import './LanguageMenu.less';
import { getFeature } from '../../../store/feature/selectors';

const messages = defineMessages({
  language: {
    defaultMessage: 'Language',
    id: 'members.languageMenu.language',
  },
});

@autobind
class LanguageMenu extends React.PureComponent {
  static propTypes = {
    mobileMenuOpen: PropTypes.func.isRequired,
    updateIntl: PropTypes.func.isRequired,
    isMobileScreen: PropTypes.bool.isRequired,
    locale: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    languages: PropTypes.object,
  };

  static defaultProps = {
    languages: Languages,
  };

  onChange(newLocale) {
    // Check that we got an eventKey - the language title has no key
    if (newLocale) {
      this.props.updateIntl({ locale: newLocale });
    }
  }

  getShortName() {
    const lang = _.get(
      Languages,
      [this.props.locale, 'short'],
      Languages.getMessageByValue(this.props.locale),
    );
    return lang;
  }

  backToMenu(notRelevant, e) {
    if (this.props.isMobileScreen) {
      e.stopPropagation();
      this.props.mobileMenuOpen(true);
    }
  }

  renderLanguages() {
    const { languages } = this.props;
    const values = _.keys(languages);
    return _.map(values, lang => (
      <MenuItem className={this.props.locale === lang ? 'active' : ''} eventKey={lang} key={lang}>
        <FormattedMessage {...Languages.getMessageByValue(lang)} />
      </MenuItem>
    ));
  }

  render() {
    const { id } = this.props;
    return (
      <Dropdown
        id={id}
        className="language-menu left-border-menu xs-fullscreen-dropdown"
        onSelect={this.onChange}
      >
        <Dropdown.Toggle bsStyle="inline-link text-semibold language-menu-toggle">
          <FormattedMessage {...this.getShortName()} />
          {/* <img src={imgMap[this.props.currentLocale]} /> */}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <MenuItem className="dropdown-header visible-xs" onSelect={this.backToMenu}>
            <span className="text-18 text-semibold text-main">
              <FormattedMessage {...messages.language} />
            </span>
            <i className="icon-back-arrow dropdown-back-btn left" />
          </MenuItem>
          {this.renderLanguages()}
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}

const getSupportedLanguages = state => {
  const supportedLanguages = getFeature(state, 'supportedLanguages');
  if (!_.isEmpty(supportedLanguages)) return _.pick(Languages, supportedLanguages);
  return Languages;
};

export default compose(
  withRouter,
  connect(
    state => ({
      isMobileScreen: isMobile(state),
      locale: getCurrentLocale(state),
      languages: getSupportedLanguages(state),
    }),
    { mobileMenuOpen, updateIntl },
  ),
)(LanguageMenu);
