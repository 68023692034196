/**
 * Created by chenrozenes on 21/12/2017.
 */
import { DEFAULT_FLOW_NAME } from './consts';
import routingSelectors from '../../../store/routing/selectors';

/**
 * Selector to check if flow is currently active
 * Useful to determine state after refresh
 */
export const isFlowActive = (state, name = DEFAULT_FLOW_NAME) =>
    routingSelectors.currRouteQueryKey(state, 'flow') === name;

/**
 * Selector to get the flow name is currently active.
 * When not active, returns undefined
 */
export const getFlowName = state => routingSelectors.currRouteQueryKey(state, 'flow');

/**
 * Selector to get the flow id
 * @param state
 */
export const getFlowId = state => routingSelectors.currRouteQueryKey(state, 'flowId');
