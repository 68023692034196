import _ from 'lodash';
import { compose, renderNothing } from 'recompose';
import Api from '../../api';
import { withFetchersOnMount } from '../../api/injectApi/withFetchersOnMount';

export default compose(
  withFetchersOnMount({
    getBookingData: {
      handler: ({ npi, location }) => async () => {
        const bookingData = await Api.schedulingApi.getBookingData(npi, location.id);
        return { bookingData, bookingType: bookingData.bookingType };
      },
      isReady: ({ bookingData }) => !_.isEmpty(bookingData),
      onSuccess: ({ control }, bookingData) => control.next({ payload: bookingData }),
    },
  }),
)(renderNothing);
