/**
 * created by moran on 6/24/18
 */
import _ from 'lodash';
import {
  PREMERA_BLUE_CROSS_ALASKA,
  VIM,
  UNITED_HEALTH_CARE_ALLSAVERS,
  ANTHEM,
  FLORIDA_BLUE,
} from './bkmdDomain';

const DomainMap = {
  1: PREMERA_BLUE_CROSS_ALASKA,
  3: VIM,
  4: UNITED_HEALTH_CARE_ALLSAVERS,
  5: ANTHEM,
  6: FLORIDA_BLUE,
};

const GetDomainResourceTypeByName = function GetDomainResourceTypeByName(domain) {
  return _.invert(DomainMap)[domain];
};

export default DomainMap;
export { GetDomainResourceTypeByName };
