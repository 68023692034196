import PropTypes from 'prop-types';
import { compose, withProps, withHandlers, setPropTypes, setDisplayName } from 'recompose';

import { defineMessages } from 'react-intl';
import { FlowShape } from '../../components/wizard/flow/shapes';

import SideDrawerContent from '../../components/templates/SideDrawerContent/SideDrawerContent';
import { withTranslatedMessages } from '../../utils/withTranslatedMessages';
import ReasonForVisitForm from '../../components/organisms/ReasonForVisitForm/ReasonForVisitForm';

const messages = defineMessages({
  title: {
    defaultMessage: "What's the reason for the visit?",
    id: 'members.directory.booking.reasonForVisit.title',
  },
  description: {
    defaultMessage: 'Help the doctor prepare, a few words will do.',
    id: 'members.directory.booking.reasonForVisit.helpYourDoctor',
  },
});

const ComposedTemplate = withProps(() => ({
  formComponent: ReasonForVisitForm,
}))(SideDrawerContent);

/**
 * Input: non
 * Output:
 * * Result: NEXT
 * * Returned value: answer
 */
const ReasonForVisit = compose(
  setDisplayName('ReasonForVisit'),
  setPropTypes({
    control: FlowShape.isRequired,
    reasonForVisit: PropTypes.string,
  }),
  withTranslatedMessages(messages),
  withProps(({ reasonForVisit }) => ({
    name: 'reasonForVisit',
    initialValues: { reasonForVisit },
  })),
  withHandlers({
    onSubmit: ({ control }) => payload => control.next({ payload }),
    // @mrsufgi: note that empty string is a valid RFV, skip just submit an empty reason.
    onSecondaryClick: ({ control }) => () => control.next({ payload: { reasonForVisit: '' } }),
  }),
)(ComposedTemplate);

export default ReasonForVisit;
