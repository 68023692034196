import { Record } from 'immutable';

const InitialState = Record({
  host: '',
  internal: '',
  platform: '' // iOS or Android in React Native.
});
const initialState = new InitialState();

export default function deviceReducer(state = initialState) {
  if (!(state instanceof InitialState)) return initialState.merge(state);
  return state;
}
