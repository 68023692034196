import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { autobind } from 'core-decorators';
import { defineMessages, FormattedMessage } from 'react-intl';

import MiniDoctorCard from '../miniDoctorCard/miniDoctorCard';
import BkmdModal from '../bkmdModal/bkmdModal';
import ModalHeader from '../bkmdModal/modalHeader';
import { getFeature } from '../../store/feature/selectors';
import CancelBooking from './cancelBooking';

import PatientBookingFlow from '../../flows/patientBookingFlow';
import * as FlowDef from '../../flows/definitions/patientBookingFlowDef';

import './booking.less';
import withControl from '../wizard/flow/withControl';
import { FlowShape } from '../wizard/flow/shapes';
import * as BookingType from '../../model/enum/bookingType';

const vimLogoImg = require('../../assets/images/logos/vimLogoSm.png');

const messages = defineMessages({
  instantBooking: {
    defaultMessage: 'Instant book by',
    id: 'members.directory.booking.instantBooking',
  },
  bookAppointment: {
    defaultMessage: 'Book appointment',
    id: 'members.directory.booking.bookAppointment',
  },
  requestAppointment: {
    defaultMessage: 'Request appointment',
    id: 'members.directory.booking.requestAppointment',
  },
});

const requestCancelMessages = defineMessages({
  cancel: {
    defaultMessage: 'Cancel this request?',
    id: 'members.directory.booking.cancel',
  },
  areYouSure: {
    defaultMessage: 'Are you sure you want to close this window before submiting ' +
      'your request?',
    id: 'members.directory.booking.areYouSure',
  },
});

@autobind
class BookingModal extends React.Component {
  static propTypes = {
    /**
     * the provider we are currently booking to
     */
    provider: PropTypes.object.isRequired,
    /**
     * the location of the provider we are booking to
     */
    location: PropTypes.object.isRequired,
    /**
     * onClose callback function
     */
    onClose: PropTypes.func.isRequired,
    /**
     * bool that determines if the booking modal is open.
     */
    isOpen: PropTypes.bool.isRequired,
    /**
     * Feature flag that indicates if this is a Vim branded product or not.
     */
    vimBranded: PropTypes.bool.isRequired,
    insurer: PropTypes.string,
    control: FlowShape.isRequired,
    domainMemberId: PropTypes.oneOf([PropTypes.string, undefined]),
    flowProps: PropTypes.object,
    bookingType: PropTypes.string.isRequired,
    domain: PropTypes.string.isRequired,
    token: PropTypes.string,
  };

  static defaultProps = {
    insurer: undefined,
    flowProps: {},
    token: null,
    domainMemberId: undefined,
  };

  constructor(props) {
    super(props);
    this.state = {
      isCloseModalOpen: false,
    };
  }

  componentWillMount() {
    this.setState({
      isCloseModalOpen: false,
    });
  }

  /**
   * the function is invoked whenever we somebody leaves the modal, it either pops the cancel
   * booking modal or closes the booking modal all together (depending on whether we already
   *  booked an appointment or not)
   * @returns {*}
   */
  onCloseModalClicked() {
    if (this.props.control.currentStep === FlowDef.steps.CONFIRMATION) {
      return this.closeBookingModal();
    }
    return this.openCancelBookingModal();
  }

  onBack() {
    const { control } = this.props;
    if (control.hasPrev()) {
      control.prev();
    } else {
      this.closeBookingModal();
    }
  }

  closeCancelBookingModal() {
    this.setState({ isCloseModalOpen: false });
  }

  closeBookingModal() {
    this.setState({ isCloseModalOpen: false });
    this.props.onClose();
  }

  openCancelBookingModal() {
    this.setState({ isCloseModalOpen: true });
  }

  /**
   * render the right step according to the state of the booking flow.
   * @returns {*}
   */
  renderBookingFlowStep() {
    const {
      provider,
      location,
      domainMemberId,
      insurer,
      flowProps,
      bookingType,
      token,
      domain,
    } = this.props;
    return (
      <PatientBookingFlow
        bookingType={bookingType}
        npi={provider.npi}
        location={location}
        insurer={insurer}
        domain={domain}
        token={token}
        domainMemberId={domainMemberId}
        onClose={this.onCloseModalClicked}
        flowProps={flowProps}
      />
    );
  }

  /**
   * render the body. it can be one of the following:
   * 1) the booking flow
   * 2) cancel booking modal if user tries to leave in the middle of the flow.
   * @returns {XML}
   */
  renderBody() {
    const { provider } = this.props;
    const overlayed = this.state.isCloseModalOpen;
    return (
      <div>
        <div style={{ display: this.state.isCloseModalOpen ? 'block' : 'none' }}>
          {this.renderCancelButton()}
        </div>
        {
          !overlayed && (
            <div>
              <div className="text-center margin-bottom-20 margin-top-10">
                <MiniDoctorCard
                  containerClassName="no-shadow no-pointer inline-block"
                  provider={provider}
                  docImgSize="doc-img-72"
                  showSuffix
                />
              </div>
              {this.renderBookingFlowStep()}
            </div>
          )
        }
      </div>
    );
  }

  renderCancelButton() {
    const { bookingType } = this.props;
    const messages = bookingType === BookingType.REQUEST ? requestCancelMessages : {};
    return (<CancelBooking
      onCloseBooking={this.closeBookingModal}
      onKeepBooking={this.closeCancelBookingModal}
      messages={messages}
    />);
  }

  renderTitle() {
    const { vimBranded, bookingType } = this.props;

    if (bookingType === BookingType.REQUEST) {
      return (
        <span className="text-18 text-semibold font-color-brand-main">
          <FormattedMessage {...messages.requestAppointment} />
        </span>
      );
    }

    if (vimBranded) {
      return [
        <i className="icon-lightning booking-icon font-color-brand-button" />,
        <span className="text-18 text-semibold font-color-brand-main">
          <FormattedMessage {...messages.instantBooking} />
        </span>,
        <img className="vim-logo" src={vimLogoImg} alt="" />,
      ];
    }

    return (
      <span className="text-18 text-semibold font-color-brand-main">
        <FormattedMessage {...messages.bookAppointment} />
      </span>
    );
  }

  render() {
    const { control } = this.props;
    return (
      <BkmdModal
        handleClose={_.noop}
        isOpen={this.props.isOpen}
        className="instant-booking-modal sidebar-modal fixed-header"
      >
        <ModalHeader
          onClose={this.onCloseModalClicked}
          onBack={control.hasPrev() ? control.prev : undefined}
        >
          <div className="text-center">{this.renderTitle()}</div>
        </ModalHeader>
        <div className="dialog-body gpu-render">{this.renderBody()}</div>
      </BkmdModal>
    );
  }
}

export default compose(
  withControl(FlowDef.name),
  connect(state => ({
    vimBranded: getFeature(state, 'bookmdBranded'),
  })),
)(BookingModal);
