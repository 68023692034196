import React from 'react';
import { defineMessages, FormattedHTMLMessage } from 'react-intl';
import { compose } from 'redux';
import { connect } from 'react-redux';

import InviteFamilyModal from './inviteFamilyModal';
import {
  createToggleState,
  createToggleStatePropTypes,
} from '../../../../../components/recompose/toggleStateComponent';

const messages = defineMessages({
  start: {
    defaultMessage: 'START',
    id: 'familyActivation.start',
  },
});

class FamilyActivation extends React.PureComponent {
  static propTypes = {
    ...createToggleStatePropTypes('InviteFamilyModal'),
  };

  render() {
    const { InviteFamilyModalShow, InviteFamilyModalHide, InviteFamilyModalOn } = this.props;
    return (
      <div>
        <div className="margin-top-30 text-center">
          <button className="btn blue-big" onClick={InviteFamilyModalShow}>
            <FormattedHTMLMessage {...messages.start} />
          </button>
        </div>

        <InviteFamilyModal isOpen={InviteFamilyModalOn} handleClose={InviteFamilyModalHide} />
      </div>
    );
  }
}

export default compose(
  createToggleState('InviteFamilyModal'),
  connect(state => ({
    auth: state.auth,
  })),
)(FamilyActivation);
