/**
 * Created by chenrozenes on 09/11/2016.
 */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _ from 'lodash';
import { intlShape, injectIntl, defineMessages, FormattedMessage } from 'react-intl';
import moment from 'moment-timezone';
import DateInput from './cleanDateInput';
import CleanTimeInputComponent from './cleanTimeInputComponent/cleanTimeInputComponent';
import CommonFormats from '../../utils/formatter/commonFormats';
import Formatter from '../../utils/formatter';
import commonMessages from '../../store/intl/commonMessages';
import { IntlString, MessageShape } from '../ui/intlString';

import './dateTimeComponent.less';

const DATE_FORMAT = CommonFormats.MONTH_DAY_YEAR;
const TIME_FORMAT = CommonFormats.HOURS_24_TIME;

const messages = defineMessages({
  pastDate: {
    defaultMessage: 'This date is in the past.',
    id: 'providers.scheduling.appointment.flow.appointmentDetails.pastDate',
  },
});

/**
 * Date and time component which the date and time are separate.
 * @type {*|Function}
 */
class DateTimeComponent extends React.Component {
  static propTypes = {
    intl: intlShape.isRequired,
    intlDate: PropTypes.object,
    intlTime: PropTypes.object,
    onDateChange: PropTypes.func,
    onChange: PropTypes.func.isRequired,
    className: PropTypes.string,
    value: PropTypes.object,
    errorText: PropTypes.string,
    labelClassName: PropTypes.string,
    timeZone: PropTypes.string,
    errorsOnlyAfterTouch: PropTypes.bool,
    label: MessageShape,
    interval: PropTypes.number,
    showPastDateMessage: PropTypes.bool,
    readonly: PropTypes.bool,
    disabled: PropTypes.bool,
    defaultDateTime: PropTypes.object,
  };

  static defaultProps = {
    intlDate: commonMessages.date,
    intlTime: commonMessages.time,
    onDateChange: undefined,
    className: undefined,
    value: undefined,
    errorText: undefined,
    labelClassName: undefined,
    timeZone: undefined,
    errorsOnlyAfterTouch: true,
    label: undefined,
    interval: 30,
    showPastDateMessage: false,
    defaultDateTime: moment(),
    readonly: false,
    disabled: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      date: null,
      time: null,
      dateTouched: false,
      pastDateTime: false,
    };

    this.onDateChange = this.onDateChange.bind(this);
    this.onTimeChange = this.onTimeChange.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.hasTouched = this.hasTouched.bind(this);
    this.acceptValue = this.acceptValue.bind(this);
    this.notifyOnChange = this.notifyOnChange.bind(this);
  }

  componentWillMount() {
    const value = this.props.value || this.props.defaultDateTime;
    this.acceptValue(value);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.value !== nextProps.value || this.props.timeZone !== nextProps.timeZone) {
      this.acceptValue(nextProps.value);
    }
  }

  onDateChange(date) {
    this.setState({ date });
    this.notifyOnChange(date, this.state.time);
  }

  onTimeChange(time) {
    this.setState({ time });
    this.notifyOnChange(this.state.date, time);
  }

  onBlur() {
    this.setState({ dateTouched: true });
  }

  hasTouched() {
    if (!this.props.errorsOnlyAfterTouch) return true;
    return this.state.dateTouched && !!this.state.time;
  }

  acceptValue(value) {
    const { timeZone } = this.props;
    if (value) {
      if (timeZone) {
        value = moment.tz(value, timeZone);
      }
      const date = Formatter.date(value, DATE_FORMAT);
      const time = Formatter.time(value, TIME_FORMAT);
      this.setState({ date, time });
    }
  }

  calcDateTime(date, time) {
    const { timeZone } = this.props;
    let momentObj;
    if (timeZone) {
      momentObj = moment.tz(`${date} ${time}`, `${DATE_FORMAT} ${TIME_FORMAT}`, true, timeZone);
    } else {
      momentObj = moment(`${date} ${time}`, `${DATE_FORMAT} ${TIME_FORMAT}`, true);
    }

    return momentObj;
  }

  notifyOnChange(date, time) {
    const { onChange } = this.props;
    const momentObj = this.calcDateTime(date, time);
    if (onChange) onChange(momentObj.isValid() ? momentObj.toDate() : null);
  }

  renderPastDate() {
    if (!this.props.showPastDateMessage) return null;
    if (this.props.disabled) return null;
    const dateTimeMoment = this.calcDateTime(this.state.date, this.state.time);
    const pastDateTime = dateTimeMoment.isValid() && dateTimeMoment.isBefore(moment());
    if (!pastDateTime) return null;
    return (
      <span className="past-date-message validation-message">
        <FormattedMessage {...messages.pastDate} />
      </span>
    );
  }

  render() {
    const {
      intl,
      intlDate,
      className,
      errorText,
      labelClassName,
      label,
      timeZone,
      readonly,
      disabled,
      ...other
    } = this.props;
    const datePlaceholder = intl.formatMessage(intlDate);

    const otherWithoutChange = _.omit(other, ['onChange']);

    return (
      <div
        className={classNames(
          className,
          'date-component',
          errorText && this.hasTouched() ? 'has-error' : '',
        )}
      >
        {label && (
          <div className="date-time-label">
            <IntlString className={labelClassName} message={label} />
          </div>
        )}

        {!readonly ? (
          <div className="row">
            <div className="col-sm-4 text-left">
              <div className="error-wrap">
                <DateInput
                  placeholder={'MM/DD/YYYY'}
                  label={datePlaceholder}
                  labelClassName={labelClassName}
                  inputClassName="text-left"
                  value={this.state.date}
                  onChange={this.onDateChange}
                  onBlur={this.onBlur}
                  disabled={disabled}
                />
                <span className="validation-message">{errorText}</span>
              </div>
            </div>
            <div className="col-sm-8 text-left">
              <CleanTimeInputComponent
                {...otherWithoutChange}
                value={this.state.time}
                onChange={this.onTimeChange}
                timeZone={timeZone}
                disabled={disabled}
              />
            </div>
          </div>
        ) : (
          `${this.calcDateTime(this.state.date, this.state.time).format(
            CommonFormats.DEFAULT_DATE_AND_TIME,
          )}`
        )}
        {this.renderPastDate()}
      </div>
    );
  }
}

export default injectIntl(DateTimeComponent);
