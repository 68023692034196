/**
 * Created by guyavarham on 06/03/2017.
 */
import _ from 'lodash';
import Promise from 'bluebird';
import PromiseActionType from '../../promiseActionType';
import { enrichGeoWithZipAndState } from '../../../utils/geocodeByLocation';
import { addDefaultGeoToDoctors, locationFields } from '../../utils/geoCommon';
import Api from '../../../api';
import { findResponseMapping } from '../../../utils/mapping/search';
import * as BookingType from '../../../model/enum/bookingType';

export const GET_BY_NPI = new PromiseActionType('GET_BY_NPI');
export const SEARCH_DOCTORS = new PromiseActionType('SEARCH_DOCTORS');
export const GET_RECOMMENDED = new PromiseActionType('GET_RECOMMENDED');
export const GET_RECOMMENDED_PCP_DOCTORS = new PromiseActionType('GET_RECOMMENDED_PCP_DOCTORS');

async function find({
  geo = {},
  memberToken,
  limit,
  codes,
  plan,
  prefix,
  distance,
  gender,
  skip,
  sort,
  onlyInNetwork,
  queryId,
  memberSessionId,
}) {
  const response = await Api.searchApi.find({
    geo: { geocode: { latitude: geo.latitude, longitude: geo.longitude }, zip: geo.zip },
    plan,
    prefix,
    bcbsMemberPrefix: prefix,
    memberToken,
    limit,
    skip,
    sort,
    filters: {
      taxonomy: _.first(codes),
      distance,
      gender,
      state: geo.state,
      onlyInNetwork,
    },
    withBookingAvailability: false,
    queryId,
    memberSessionId,
  });
  return findResponseMapping(response);
}

export function searchDoctors(
  clientGeo,
  codes,
  filter,
  plan,
  memberIdPrefix,
  memberToken,
  onlyInNetwork,
  queryId,
  memberSessionId,
) {
  return ({ getState }) => {
    const state = getState();
    const limit = _.get(state, 'config.directory.searchResults.pageSize');
    const queryString = _.get(state, 'routing.locationBeforeTransitions.query');
    const sorting = queryString.sorting;
    const skip = !_.isNil(queryString.skip) ? parseInt(queryString.skip, 10) : undefined;
    const currentQuery = {
      ...filter,
      geo: clientGeo,
      codes,
      plan,
      prefix: memberIdPrefix,
    };

    return {
      type: SEARCH_DOCTORS.SOURCE,
      meta: {
        tracker: SEARCH_DOCTORS.SOURCE,
        cacheSearch: {
          searchFunc: async (searchQuery, limit, skip, sort) => {
            const { geo, codes, plan, prefix, gender, distance } = searchQuery;
            return find({
              geo,
              memberToken,
              limit,
              codes,
              plan,
              prefix,
              skip,
              gender,
              distance,
              sort,
              onlyInNetwork,
              queryId,
              memberSessionId,
            }).tap(addDefaultGeoToDoctors(clientGeo));
          },
          searchKey: SEARCH_DOCTORS.SOURCE,
          currentQuery,
          sorting,
          skip,
          limit,
        },
      },
    };
  };
}

export function getByNpi(npiList, clientGeo) {
  npiList = _.castArray(npiList);
  return ({ bkmdApi: { searchApi } }) => ({
    type: SEARCH_DOCTORS.SOURCE,
    payload: {
      promise: searchApi
        .getByNpi(npiList)
        .then(data => data.physician)
        .tap(addDefaultGeoToDoctors(clientGeo)),
    },
    meta: {
      tracker: SEARCH_DOCTORS.SOURCE,
    },
  });
}

export function clearSearchResults() {
  return {
    type: SEARCH_DOCTORS.SUCCESS,
    payload: [],
  };
}

function partitionByProfilePicture(providers) {
  return _.partition(providers, provider => provider.data.profilePicture);
}

function isBookable(provider) {
  return _.get(provider.locations[0], 'bookingType') !== BookingType.NONE;
}

function _randomResults(randomResults, limit, results) {
  if (randomResults) {
    // Split by bookable and not bookable providers
    const [bookable, notBookable] = _.partition(results.data, searchResult =>
      isBookable(searchResult.data),
    );
    const bookableRandomized = _.sampleSize(bookable, limit);
    // Randomize not bookable results - with a limit minus the count of bookable providers
    const notBookableRandomized = _.sampleSize(notBookable, limit - bookable.length);

    // Preference for providers with a profile picture
    const [bookableWithPicture, bookableWithoutPicture] = partitionByProfilePicture(
      bookableRandomized,
    );
    const [notBookableWithPicture, notBookableWithoutPicture] = partitionByProfilePicture(
      notBookableRandomized,
    );

    // Return always the bookable providers before the not bookable,
    return {
      data: _.take(
        _.concat(
          bookableWithPicture,
          bookableWithoutPicture,
          notBookableWithPicture,
          notBookableWithoutPicture,
        ),
        limit,
      ),
    };
  }

  return { data: _.take(results.data, limit) };
}

export function getRecommendedPcpDoctors(
  clientLocation,
  plan,
  memberToken,
  onlyInNetwork,
  codes = ['207Q00000X'], // Family Phy
  distance = '20plusmi',
  limit = 3,
) {
  return ({ getState }) => {
    const state = getState();
    const doctorsBucketCountForRandomization = _.get(
      state,
      'config.homePage.doctorsBucketCountForRandomization',
      20,
    );
    const randomResults = _.get(state, 'config.homePage.randomResults', false);
    const prefix = _.get(state, 'member.info.memberIdPrefix');

    const geo = clientLocation.geo;

    return {
      type: GET_RECOMMENDED_PCP_DOCTORS.SOURCE,
      payload: {
        promise: Promise.resolve().then(async () => {
          if (_.isEmpty(codes)) return {};
          const enrichedGeo = await enrichGeoWithZipAndState(geo);
          const geoLocation = _.pick(enrichedGeo, locationFields);
          const providersResult = await find({
            geo: geoLocation,
            memberToken,
            limit: doctorsBucketCountForRandomization,
            codes,
            plan,
            prefix,
            distance,
            onlyInNetwork,
          });
          addDefaultGeoToDoctors()(providersResult);
          return _randomResults(randomResults, limit, providersResult);
        }),
        meta: { tracker: GET_RECOMMENDED_PCP_DOCTORS.SOURCE },
      },
    };
  };
}
