/**
 * Created by galgoltzman on 28/02/2017.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, FormattedMessage } from 'react-intl';
import { Field } from 'redux-form';
import { autobind } from 'core-decorators';
import { compose } from 'redux';

import { SpinnerButton } from '../../../../../components/ui/spinner/index';
import * as ActivationForm from '../activationForm';
import SvgKey from '../../../../../assets/svg/key';
import { withActivationForm } from './withActivationForm';
import withFeatureFlag from '../../../../../utils/featureFlags/withFeatureFlag';

const messages = defineMessages({
  oneLastStep: {
    defaultMessage: 'Choose your login',
    id: 'activation.welcome.oneLastStep',
  },
  password: {
    defaultMessage: 'Create password',
    id: 'activation.welcome.password',
  },
  repeatPassword: {
    defaultMessage: 'Re-enter password',
    id: 'activation.welcome.repeatPassword',
  },
  email: {
    defaultMessage: 'Your email',
    id: 'activation.welcome.yourEmail',
  },
  completeYourAccount: {
    defaultMessage: 'Complete your account',
    id: 'activation.welcome.completeYourAccount',
  },
  personalEmailAddress: {
    defaultMessage: 'A personal email is recommended.',
    id: 'activation.welcome.personalEmailAddress',
  },
});

@autobind
class Signup extends React.PureComponent {
  static propTypes = {
    /**
     * Supplied by redux-form
     */
    change: PropTypes.func.isRequired,
    /**
     * Hook method. supplied by redux-forms
     */
    handleSubmit: PropTypes.func.isRequired,
    /**
     * Should form submit button should show loading.
     * Default is false.
     */
    loading: PropTypes.bool,
  };

  static contextTypes = {
    analytics: PropTypes.object.isRequired,
  };

  static defaultProps = { loading: false };

  componentDidMount() {
    this.context.analytics.manualPageReport('activation.signup');
  }

  /**
   * Updates the passwordStrength field whenever the strength changes.
   * Uses the "change" action of redux-form.
   * @param strength
   */
  onPasswordStrengthChanged(strength) {
    this.props.change(ActivationForm.fields.passwordStrength.name, strength);
  }

  render() {
    const { handleSubmit, loading, newPasswordValidationFeature } = this.props;
    return (
      <form className="activation-welcome-wrap" onSubmit={handleSubmit} method="post">
        <div className="top-section">
          <div className="row">
            <div className="col-xs-12 text-center">
              <SvgKey />
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 text-center">
              <h1 className="title-24 md-margin title-brand-1">
                <FormattedMessage {...messages.oneLastStep} />
              </h1>
            </div>
            <div className="col-xs-12 text-center">
              <span className="text-15 text-light">
                <FormattedMessage {...messages.personalEmailAddress} />
              </span>
            </div>
          </div>
        </div>
        <div className="form-section">
          <div className="row">
            <div className="col-xs-12">
              <Field type="email" label={messages.email} {...ActivationForm.fields.email} />
            </div>
          </div>

          <div className="row">
            <div className="col-xs-12">
              <Field
                label={messages.password}
                className="password-input"
                showSuggestions
                onStrengthChanged={this.onPasswordStrengthChanged}
                withFeatureFlag={newPasswordValidationFeature}
                verifyPasswordField={
                  <div>
                    <Field
                      label={messages.repeatPassword}
                      className="password-input"
                      {...ActivationForm.fields.repeatPassword}
                    />
                  </div>
                }
                {...ActivationForm.fields.password}
              />
              <Field type="hidden" {...ActivationForm.fields.passwordStrength} />
            </div>
          </div>

          <div className="row margin-top-5">
            <div className="col-xs-12">
              <SpinnerButton
                className="btn btn-big complete-btn bg-color-brand-button"
                type="submit"
                isLoading={loading}
              >
                <FormattedMessage {...messages.completeYourAccount} />
              </SpinnerButton>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

const fieldNames = [
  ActivationForm.fields.email.name,
  ActivationForm.fields.password.name,
  ActivationForm.fields.passwordStrength.name,
  ActivationForm.fields.phoneNumber.name,
  ActivationForm.fields.repeatPassword.name,
];

export default compose(
  withActivationForm(fieldNames, ActivationForm.customValidator),
  withFeatureFlag({
    key: 'vim.members.password_validation_feature',
    prop: 'newPasswordValidationFeature',
    defaultValue: false,
  }),
)(Signup);
