import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { change } from 'redux-form';
import moment from 'moment';

import ThreeBounceSpinner from '../../../../components/ui/spinner/threeBounceSpinner';
import actionTracker from '../../../../store/tools/actionTracker/actionTrackerComponent';
import Formatter from '../../../../utils/formatter';

import * as steps from './steps';
import * as ActivationForm from './activationForm';
import {
  fetchActivationByToken,
  FETCH_ACTIVATION_BY_TOKEN,
} from '../../../../store/member/actions';

export default Component => {
  class ActivationByToken extends React.Component {
    static propTypes = {
      /**
       * react router params
       */
      params: PropTypes.object.isRequired,
      /**
       * redux-form action
       */
      change: PropTypes.func.isRequired,
      /**
       * Redux action creator
       */
      fetchActivationByToken: PropTypes.func.isRequired,
      /**
       * Action tracker
       */
      fetchActivationByTokenTracking: PropTypes.object.isRequired,
      /**
       * form fields
       */
      name: PropTypes.string,
      dob: PropTypes.instanceOf(Date),
      phoneNumber: PropTypes.string,
    };

    static defaultProps = {
      name: undefined,
      dob: undefined,
      phoneNumber: undefined,
    };

    constructor(props) {
      super(props);
      this.state = {
        shouldRender: !props.params.token,
        step: steps.ENTER_PHONE_NUMBER,
        formSet: false,
      };
    }

    componentWillMount() {
      const { token } = this.props.params;
      if (token) {
        this.props.fetchActivationByToken(token);
      }
    }

    componentWillReceiveProps(nextProps) {
      const { fetchActivationByTokenTracking, name, dob, phoneNumber } = nextProps;
      if (fetchActivationByTokenTracking.finished) {
        if (fetchActivationByTokenTracking.hasError) {
          this.setState({ step: steps.ENTER_PHONE_NUMBER });
          this.setState({ shouldRender: true });
        }
      }
      if (!this.state.formSet && name) {
        this.props.change(ActivationForm.name, ActivationForm.fields.name.name, name);
        this.props.change(ActivationForm.name, ActivationForm.fields.phoneNumber.name, phoneNumber);
        if (dob) {
          const dobString = moment(dob).format('MM/DD/YYYY');
          this.props.change(ActivationForm.name, ActivationForm.fields.dob.name, dobString);
        }
        this.setState({
          shouldRender: true,
          step: steps.SIGN,
          formSet: true,
        });
      }
    }

    render() {
      return (
        <div>
          {this.state.shouldRender ? (
            <Component initialStep={this.state.step} {...this.props} />
          ) : (
            <ThreeBounceSpinner />
          )}
        </div>
      );
    }
  }

  return compose(
    connect(
      state => {
        const { activation } = state.member;
        const { firstName, middleName, lastName } = activation;
        const name = firstName ? Formatter.toFullName(firstName, middleName, lastName) : undefined;
        return {
          name,
          dob: activation.dateOfBirth,
          phoneNumber: activation.phoneNumber,
        };
      },
      {
        change,
        fetchActivationByToken,
      },
    ),
    actionTracker({
      fetchActivationByTokenTracking: FETCH_ACTIVATION_BY_TOKEN.SOURCE,
    }),
  )(ActivationByToken);
};
