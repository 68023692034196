/**
 * Created by rom on 20/12/2015.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '../../storybook/src/utils/theme';

function SvgNewPatientsPlus({ theme }) {
  /* eslint-disable */
  return (
    <svg aria-hidden="true" focusable="false" className="svg-icon svg-new-patients-plus" width="28px" height="32px" viewBox="0 0 28 32" version="1.1">
      <defs>
        <polygon points="0 32 28 32 28 0 0 0"></polygon>
      </defs>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <path d="M2,22.9224 L14,29.8454 L26,22.9224 L26,9.0774 L14,2.1544 L2,9.0774 L2,22.9224 Z M14,32.0004 C13.827,32.0004 13.655,31.9554 13.5,31.8664 L0.5,24.3664 C0.19,24.1874 0,23.8574 0,23.5004 L0,8.5004 C0,8.1424 0.19,7.8124 0.5,7.6334 L13.5,0.1334 C13.811,-0.0446 14.189,-0.0446 14.5,0.1334 L27.5,7.6334 C27.81,7.8124 28,8.1424 28,8.5004 L28,23.5004 C28,23.8574 27.81,24.1874 27.5,24.3664 L14.5,31.8664 C14.345,31.9554 14.173,32.0004 14,32.0004 L14,32.0004 Z" fill={theme.mainColor}></path>
        <path d="M20,15.0002 L15,15.0002 L15,10.0002 C15,9.4482 14.553,9.0002 14,9.0002 C13.447,9.0002 13,9.4482 13,10.0002 L13,15.0002 L8,15.0002 C7.447,15.0002 7,15.4482 7,16.0002 C7,16.5522 7.447,17.0002 8,17.0002 L13,17.0002 L13,22.0002 C13,22.5522 13.447,23.0002 14,23.0002 C14.553,23.0002 15,22.5522 15,22.0002 L15,17.0002 L20,17.0002 C20.553,17.0002 21,16.5522 21,16.0002 C21,15.4482 20.553,15.0002 20,15.0002" fill={theme.secondaryColor}></path>
      </g>
    </svg>
  );
  /* eslint-enable */
}

SvgNewPatientsPlus.propTypes = {
  theme: PropTypes.object.isRequired,
};

export default withTheme(SvgNewPatientsPlus);
