/**
 * Created by noahguld on 28/06/2017.
 */
import Immutable from 'seamless-immutable';
import { GET_FACILITIES } from './actions';

export default function facilitySearchReducer(state = {}, action) {
  state = Immutable(state);
  switch (action.type) {
    case `${GET_FACILITIES}_START`: {
      return state.merge({ fetching: true });
    }
    case `${GET_FACILITIES}_ERROR`: {
      return state.merge({ fetching: false });
    }
    case `${GET_FACILITIES}_SUCCESS`: {
      return state.merge(
        {
          fetching: false,
          facilities: action.payload
        });
    }
    default:
      return state;
  }
}
