import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import './styles.less';

const messages = defineMessages({
  viewAll: {
    defaultMessage: 'View all',
    id: 'atlas.appointmentsPage.viewAll',
  },
  viewLess: {
    defaultMessage: 'View less',
    id: 'atlas.appointmentsPage.viewLess',
  },
});

class ShowMoreItems extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      unMutableItems: 3,
      itemsToShow: 3,
      expanded: false,
    };
  }

  viewAll = () => {
    const { itemsToShow } = this.state;
    const { children } = this.props;

    itemsToShow === 3
      ? this.setState({ itemsToShow: children.length, expanded: true })
      : this.setState({ itemsToShow: 3, expanded: false });
  };

  render() {
    const { children } = this.props;

    const { itemsToShow, unMutableItems, expanded } = this.state;
    return (
      <div className="show-more-items">
        {children.slice(0, itemsToShow)}
        {children.length > unMutableItems && (
          <div className="show-more-items__btn" onClick={this.viewAll}>
            {!expanded ? (
              <FormattedMessage {...messages.viewAll} />
            ) : (
              <FormattedMessage {...messages.viewLess} />
            )}
          </div>
        )}
      </div>
    );
  }
}

export default ShowMoreItems;
