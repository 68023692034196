import { compose, withHandlers, withProps, lifecycle } from 'recompose';
import withFormikWrapper from '../formik/withFormikWrapper';
import CleanSelect from '../cleanSelect';

export const CleanFormikSelect = compose(
  withFormikWrapper({ onChangeProp: 'onChanged' }),
  withProps({ ignoreOnBlur: false }),
  withHandlers({
    onChanged: ({
      form: { setFieldValue },
      field: { name },
      onSelect,
      valueConverter,
    }) => eventOrValue => {
      let val = eventOrValue || '';
      if (valueConverter && val) val = valueConverter(val);
      setFieldValue(name, val);
      if (onSelect) onSelect(val);
    },
  }),
  lifecycle({
    componentDidMount() {
      const { initialValue, onChanged } = this.props;
      if (initialValue) onChanged(initialValue);
    },
  }),
)(CleanSelect);
