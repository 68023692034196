import React from 'react';
import { autobind } from 'core-decorators';
import PropTypes from 'prop-types';
import { compose, withHandlers, withState } from 'recompose';
import FilterModal from '../../../../../../../components/searchDoctors/filter/filterModal';
import LocationSelect from '../location/locationSelect';

@autobind
class DirectoryFilterModal extends React.PureComponent {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    filters: PropTypes.object,
    isGeocoding: PropTypes.bool.isRequired,
    toggleGeocoding: PropTypes.func.isRequired,
    onApply: PropTypes.func.isRequired,
    setLocation: PropTypes.func.isRequired,
  };

  static defaultProps = {
    filters: null,
  };

  renderLocation() {
    const { setLocation, toggleGeocoding } = this.props;
    return (
      <LocationSelect
        onChange={setLocation}
        onStartGeocode={toggleGeocoding}
        onFinishGeocode={toggleGeocoding}
      />
    );
  }

  render() {
    const { isOpen, onClose, filters, isGeocoding, onApply } = this.props;
    return (
      <FilterModal
        isOpen={isOpen}
        onClose={onClose}
        filter={filters}
        onApply={onApply}
        isGeocoding={isGeocoding}
        locationComponent={this.renderLocation}
        genderFilterOn
        distanceFilterOn
      />
    );
  }
}

export default compose(
  withState('location', 'setLocation', null),
  withState('isGeocoding', 'setIsGeocoding', false),
  withHandlers({
    toggleGeocoding: ({ isGeocoding, setIsGeocoding }) => () => setIsGeocoding(!isGeocoding),
    onApply: ({ location, onApply }) => filters => onApply({ ...location, ...filters }),
  }),
)(DirectoryFilterModal);
