/**
 * Created by asafdavid on 20/01/2016.
 */
import React from 'react';
import PropTypes from 'prop-types';
import Ladda from 'ladda';
import classNames from 'classnames';
import _ from 'lodash';
import './spinnerButton.less';

class SpinnerButton extends React.PureComponent {
  static propTypes = {
    dataStyle: PropTypes.string,
    dataSize: PropTypes.string,
    isLoading: PropTypes.bool,
    onClick: PropTypes.func,
    dataSpinnerColor: PropTypes.string,
    disabled: PropTypes.bool,
  };

  static defaultProps = {
    dataStyle: 'zoom-in',
    dataSize: 'l',
    isLoading: false,
    onClick: undefined,
    dataSpinnerColor: undefined,
    disabled: false,
  };

  constructor(props) {
    super(props);
    this.updateSpinner = this.updateSpinner.bind(this);
    this.setButtonRef = this.setButtonRef.bind(this);
  }

  /**
   * Initalizes Ladda
   */
  componentDidMount() {
    this.ladda = Ladda.create(this.buttonRef);
    this.updateSpinner(this.props.isLoading);
  }

  /**
   * Update ladda state in case it's needed
   * @param prevProps
   */
  componentDidUpdate(prevProps) {
    if (!this.ladda) return;

    // Check if there was an update
    if (prevProps.isLoading === this.props.isLoading) return;

    this.updateSpinner(this.props.isLoading);
  }

  /**
   * Cleanup
   */
  componentWillUnmount() {
    if (this.ladda) this.ladda.remove();
  }

  setButtonRef(element) {
    this.buttonRef = element;
  }

  updateSpinner(spinnerOn) {
    if (this.ladda) {
      if (spinnerOn) this.ladda.start();
      else this.ladda.stop();
    }
  }

  /**
   * Renders the component
   * @returns {XML}
   */
  render() {
    const {
      className,
      dataStyle,
      dataSize,
      onClick,
      dataSpinnerColor,
      disabled,
      isLoading,
      ...other
    } = this.props;
    const disabledClass = disabled ? 'disabled' : '';
    return (
      <button
        onClick={onClick}
        className={classNames('ladda-button', className, disabledClass)}
        data-style={dataStyle}
        data-size={dataSize}
        data-spinner-color={dataSpinnerColor}
        ref={this.setButtonRef}
        disabled={disabled || isLoading}
        {..._.omit(other, 'isLoading')}
      >
        <span className="ladda-label">{this.props.children}</span>
      </button>
    );
  }
}

export default SpinnerButton;
