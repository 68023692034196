import React from 'react';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';

import * as FlowDefs from './definitions/patientBookingFlowDef';
import Flow from '../components/wizard/flow/Flow';

const mapFlowDataToAnalytics = ({ npi, location: { officeName }, bookingType }) => ({
  npi,
  officeName,
  bookingType,
});

@autobind
export default class PatientBookingFlow extends React.PureComponent {
  static propTypes = {
    npi: PropTypes.string.isRequired,
    location: PropTypes.object.isRequired,
    domainMemberId: PropTypes.oneOf([PropTypes.string, undefined]),
    insurer: PropTypes.string,
    domain: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    persist: PropTypes.bool,
    bookingType: PropTypes.string.isRequired,
    token: PropTypes.string,
    flowProps: PropTypes.object,
  };

  static defaultProps = {
    persist: false,
    insurer: '',
    token: undefined,
    domainMemberId: undefined,
    flowProps: {},
  };

  render() {
    const {
      location,
      npi,
      domainMemberId,
      onClose,
      persist,
      insurer,
      flowProps,
      bookingType,
      domain,
      token,
    } = this.props;
    return (
      <Flow
        name={FlowDefs.name}
        definitions={FlowDefs.flow}
        initialStepName={FlowDefs.initialStepName}
        persist={persist}
        initialValues={{
          token,
          location,
          npi,
          domainMemberId,
          selectedInsurer: insurer,
          memberDomain: domain,
          bookingType,
        }}
        flowProps={{ onConfirmationClick: onClose, ...flowProps }}
        mapFlowDataToAnalytics={mapFlowDataToAnalytics}
      />
    );
  }
}
