import _ from 'lodash';
import { compose, lifecycle } from 'recompose';
import { loadingUntil } from '../../components/recompose/loadingUntil';
import { withFetchers } from './withFetchers';
import { withStateFetchers } from './withStateFetchers';
import { ThreeBounceSpinner } from '../../components/ui/spinner/index';

/**
 * withFetchersOnMount wraps withFetchers/withStateFetchers under the hood (see withState param),
 * in addition to provide all the functionality of withFetchers -
 * it will call to the fetchers in componentDidMount.
 * you can provide a loading predicate to prevent rendering your component until the data is ready.
 * if a predicate is not passed it will just dispatch the request.
 * if the initial value of the predicate is true - it will not make the request.
 * Wraps a component with fetchers on mount - fetchers are something like "async" handlers.
 * the idea is to provide a unified way to fetch async data to the component.
 * it will inject a fetch tracker that can help to track the request @see withTracker
 * The usage is very similar to withHandlers from recompose, despite of few changes:
 * instead of providing for each key an handler - you need to pass fetcher config:
 * {
 *   doSomething: {
 *     handler: props => () => promise OR props => promise
 *     @optional onStart: (props, result) => {}
 *     @optional onSuccess: (props, result) => {}
 *     @optional onError: (props, error) => {}
 *     @optional track: boolean (true by default)
 *     @optional isReady - (props) => boolean
 *     @optional resultPropName - string (${key}Result by default)
 *     it will work only if withState=true
 *   }
 *     @optional customLoadingComponent - (Component) (ThreeBounceSpinner by default)

 * }
 * @param handlersConfig
 * @param withState - determines if we want the result of the fetchers to be stored in the state
 * @return {*}
 */
export const withFetchersOnMount = (
  handlersConfig,
  withState = false,
  customLoadingComponent = ThreeBounceSpinner,
) => {
  const predicates = _.chain(handlersConfig)
    .values()
    .map('isReady')
    .compact()
    .value();

  const withFetchersHOC = withState ? withStateFetchers : withFetchers;
  return compose(
    withFetchersHOC(handlersConfig),
    lifecycle({
      componentDidMount() {
        const props = this.props;

        _(handlersConfig)
          .keys()
          .forEach(key => {
            const loadingPredicate = handlersConfig[key].isReady;
            if (!loadingPredicate || !loadingPredicate(props)) {
              props[key]();
            }
          });
      },
    }),
    loadingUntil(
      props => _.every(predicates, predicate => predicate(props)),
      customLoadingComponent,
    ),
  );
};
