import { compose, pure, withHandlers, withProps } from 'recompose';
import { withTranslatedMessages } from '../../utils/withTranslatedMessages';
import SCR016YesNo from '../../screenTemplates/SCR016YesNo';

const withYesNoHandlers = withHandlers({
  onYes: ({ control }) => () => control.next({ payload: 'YES' }),
  onNo: ({ control }) => () => control.next({ payload: 'NO' }),
});

export const YesNoStep = (svgIcon, messages) =>
  compose(
    pure,
    withTranslatedMessages(messages),
    withProps({
      svgIcon,
      buttonType: 'bg-color-brand-button',
    }),
    withYesNoHandlers,
  )(SCR016YesNo);
