import { defineMessages } from 'react-intl';

const messages = defineMessages({
  doctors: {
    defaultMessage: 'Doctors',
    id: 'members.authCompleteSearch.doctors',
  },
  noResults: {
    defaultMessage:
      "Hmmmm, we're not sure what you're looking for with " +
      '<span class="text-dark text-semibold">"{term}"</span>. ' +
      'Can you try something else?',
    id: 'members.authCompleteSearch.noResults',
  },
  specialty: {
    defaultMessage: 'specialty',
    id: 'members.authCompleteSearch.specialty',
  },
  physicians: {
    defaultMessage: 'name',
    id: 'members.authCompleteSearch.physicians',
  },
  condition: {
    defaultMessage: 'condition',
    id: 'members.authCompleteSearch.condition',
  },
  procedure: {
    defaultMessage: 'procedure',
    id: 'members.authCompleteSearch.procedure',
  },
  name: {
    defaultMessage: 'name',
    id: 'members.authCompleteSearch.name',
  },
  searchBy: {
    defaultMessage: 'Search by',
    id: 'members.authCompleteSearch.searchBy',
  },
});

export default messages;
