import { compose, pure, withHandlers, withProps } from 'recompose';
import { defineMessages } from 'react-intl';
import { withTranslatedMessages } from '../../utils/withTranslatedMessages';
import SCR017RadioButtonChoice from '../../screenTemplates/SCR017RadioButtonChoice';

const messages = defineMessages({
  question: {
    defaultMessage: 'What type of screening did you have?',
    id: 'colorectalFlow.screenType.title',
  },
  buttonText: {
    defaultMessage: 'Confirm',
    id: 'colorectalFlow.screenType.buttonText',
  },
});

export const ScreenTypes = defineMessages({
  colnoscopyInLast10Years: {
    defaultMessage: 'Colonoscopy in last 10 years',
    id: 'colorectalFlow.screenType.colnoscopyInLast10Years',
  },
  facelOccultBloodTestInLastYear: {
    defaultMessage: 'Fecal occult blood test in last year',
    id: 'colorectalFlow.screenType.facelOccultBloodTestInLastYear',
  },
  fitDnaTestInLast3Year: {
    defaultMessage: 'FIT-DNA test in last 3 years',
    id: 'colorectalFlow.screenType.fitDnaTestInLast3Year',
  },
  flexibleSigmoidscopyInLast5Years: {
    defaultMessage: 'Flexible sigmoidscopy in last 5 years',
    id: 'colorectalFlow.screenType.flexibleSigmoidscopyInLast5Years',
  },
  ctColonographyInLast5Years: {
    defaultMessage: 'CT colonography in last 5 years',
    id: 'colorectalFlow.screenType.ctColonographyInLast5Years',
  },
  imNotSure: {
    defaultMessage: 'I\'m not sure',
    id: 'colorectalFlow.screenType.imNotSure',
  },
  noneOfTheAbove: {
    defaultMessage: 'None of the above',
    id: 'colorectalFlow.screenType.noneOfTheAbove',
  },
});

const ScreenType = compose(
  pure,
  withTranslatedMessages(messages),
  withProps({ options: ScreenTypes }),
  withHandlers({
    onButtonClick: ({ control }) => chosen => control.next({ payload: chosen }),
  }),
)(SCR017RadioButtonChoice);

export default ScreenType;
