/**
 * Created by guyavarham on 02/04/2017.
 */

import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { autobind } from 'core-decorators';
import { connect } from 'react-redux';
import { compose } from 'redux';

import RoundedImage from '../ui/roundedImage/roundedImage';
import { getFeature } from '../../store/feature/selectors';

import './doctorLogo.less';

const vimChoiceLogo = require('./vimChoiceLogo.png');
const highValueLogo = require('./highValueLogo.png');

const ProviderShape = PropTypes.shape({
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  npi: PropTypes.string,
  profilePicture: PropTypes.string,
});

@autobind
class DoctorLogo extends React.Component {
  static propTypes = {
    provider: ProviderShape.isRequired,
    /**
     * The css class name based on doctor img css class.
     */
    containerClass: PropTypes.string,
    /**
     * An alternative custom css class instead of @containerClass.
     */
    className: PropTypes.string,
    hidePicture: PropTypes.bool,

    isVimChoiceEnable: PropTypes.bool.isRequired,
    isHighValueBadgeEnable: PropTypes.bool
  };

  static defaultProps = {
    className: undefined,
    containerClass: 'big',
    hidePicture: false,
    isHighValueBadgeEnable: false
  };

  renderVimChoice() {
    const { provider, isVimChoiceEnable } = this.props;

    if (isVimChoiceEnable && provider.vimChoice) {
      return (<img src={vimChoiceLogo} className="vim-heart" alt="vim choice physician" />);
    }
    return null;
  }

  renderHighValue() {
    const { provider, isHighValueBadgeEnable } = this.props;
    if (isHighValueBadgeEnable && !_.isEmpty(provider.hvns)) {
      return (<img src={highValueLogo} className="high-value-logo" alt="high value physician" />);
    }
    return null;
  }

  render() {
    const { provider, containerClass, hidePicture, className, isVimChoiceEnable } = this.props;
    const { profilePicture } = provider;

    if (!profilePicture || hidePicture) {
      const { firstName, lastName } = provider;
      if (!firstName || !lastName) return null;
      const initials = `${_.first(firstName)}${_.first(lastName)}`;
      return (
        <span className="pic-wrap">
          <div
            className={classnames(
              'doctor-img ',
              containerClass,
              { 'vim-choice': isVimChoiceEnable && provider.vimChoice }
            )}
          >
            <span className="content" aria-hidden="true">
              {initials}
            </span>
          </div>
          {this.renderVimChoice()}
          {this.renderHighValue()}
        </span>
      );
    }

    return (
      <span className="pic-wrap">
        <RoundedImage
          className={classnames(className || 'doctor-img', containerClass,
            { 'vim-choice': isVimChoiceEnable && provider.vimChoice })}
          src={profilePicture}
        />
        {this.renderVimChoice()}
        {this.renderHighValue()}
      </span>
    );
  }
}

export default compose(
  connect(state => ({
    // vim choice is a default feature - we only disabled it when required
    isVimChoiceEnable: getFeature(state, 'results.vimChoice', true),
  })),
)(DoctorLogo);
