import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '../../storybook/src/utils/theme';

function SvgSmartphone({ theme }) {
  /* eslint-disable */
  return (
    <svg aria-label="Phone number" width="11px" height="16px" viewBox="0 0 11 16" version="1.1" className="svg-icon svg-smartphone">
      <defs>
        <polygon id="path-1" points="0 16 11 16 11 0 0 0"></polygon>
      </defs>
      <g id="Share-doctors" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-425.000000, -187.000000)">
          <g transform="translate(422.000000, 175.000000)">
            <g transform="translate(3.000000, 12.000000)">
            <path d="M2.5,1 C1.673,1 1,1.673 1,2.5 L1,13.5 C1,14.327 1.673,15 2.5,15 L8.5,15 C9.327,15 10,14.327 10,13.5 L10,2.5 C10,1.673 9.327,1 8.5,1 L2.5,1 Z M8.5,16 L2.5,16 C1.121,16 0,14.878 0,13.5 L0,2.5 C0,1.122 1.121,0 2.5,0 L8.5,0 C9.879,0 11,1.122 11,2.5 L11,13.5 C11,14.878 9.879,16 8.5,16 Z" fill={theme.mainColor} ></path>
              <g></g>
              <polygon fill={theme.secondaryColor} points="1 4 10 4 10 3 1 3"></polygon>
              <polygon fill={theme.secondaryColor} points="1 13 10 13 10 12 1 12"></polygon>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
  /* eslint-enable */
}

SvgSmartphone.propTypes = {
  theme: PropTypes.object.isRequired,
};

export default withTheme(SvgSmartphone);
