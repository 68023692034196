import _ from 'lodash';
import { compose, withState } from 'recompose';
import { withFetchers } from './withFetchers';
import { removeProps } from './utils/removeProps';
import { setter } from './utils/setter';

/**
 * Hooks the onSuccess of withFetchers fetcher and set the value to the given props
 * If onSuccess method is defined - it will be called after the prop will be assigned
 * @param onSuccess - (optional) a method that will be called after the prop will be assigned
 * @param setterProp - the name of the setter prop
 * @returns {function(*=, *=)}
 * @private
 */
const onSuccessToState = (setterProp, onSuccess) => (props, result) => {
  props[setterProp](result);
  if (onSuccess) onSuccess(props, result);
};

/**
 * withStateFetchers wraps withFetchers under the hood,
 * and adds an ability to store the result in the component state
 * Wraps a component with state fetchers - fetchers are something like "async" handlers.
 * the idea is to provide a unified way to fetch async data to the component.
 * it will inject a fetch tracker that can help to track the request @see withTracker
 * in addition, it will inject to the given resultPropName the result of the fetch
 * The usage is very similar to withHandlers from recompose, despite of few changes:
 * instead of providing for each key an handler - you need to pass fetcher config:
 * {
 *   doSomething: {
 *     handler: props => () => promise OR props => promise
 *     @optional onStart: (props, result) => {}
 *     @optional onSuccess: (props, result) => {}
 *     @optional onError: (props, error) => {}
 *     @optional track: boolean (true by default)
 *     @optional resultPropName - string (${key}Result by default)
 *   }
 * }
 * @param handlersConfig
 * @return {*}
 */
export const withStateFetchers = handlersConfig => {
  const { handlersWithStateConfig, stateHOCs } = _.reduce(
    handlersConfig,
    (result, handlerConfig, key) => {
      const { handlersWithStateConfig, stateHOCs } = result;
      const { onSuccess, resultPropName = `${key}Result`, defaultValue, ...other } = handlerConfig;
      const setResultPropName = setter(resultPropName);

      stateHOCs[setResultPropName] = withState(resultPropName, setResultPropName, defaultValue);

      handlersWithStateConfig[key] = {
        ...other,
        onSuccess: onSuccessToState(setResultPropName, onSuccess),
      };

      return result;
    },
    { handlersWithStateConfig: {}, stateHOCs: {} },
  );

  return compose(
    ..._.values(stateHOCs),
    withFetchers(handlersWithStateConfig),
    removeProps(_.keys(stateHOCs)),
  );
};
