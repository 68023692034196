import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { branch } from 'recompose';
import { compose } from 'redux';
import { autobind } from 'core-decorators';
import FeatureFlagged from '../../../../../components/features/featureFlagged';
import InviteFamilyFlow from '../../activation/familyActivation/inviteFamilyFlow';
import StartPcpSelectionModal from '../../pcpSelection/components/startPcpSelectionModal';
import {
  getMemberSettingsState,
  hasInsuranceIdSelector,
} from '../../../../../store/member/selectors';
import {
  getPcpSelection,
  GET_PCP_SELECTION,
} from '../../../../../store/member/medicalTeam/actions';
import { getSelectedPcp } from '../../../../../store/member/medicalTeam/selectors';
import {
  createToggleState,
  createToggleStatePropTypes,
} from '../../../../../components/recompose/toggleStateComponent';
import actionTracker from '../../../../../store/tools/actionTracker/actionTrackerComponent';
import fetchNotEmpty from '../../../../../store/tools/fetchNotEmpty';
import { updateMemberState } from '../../../../../store/member/actions';
import {
  PCP_SELECTION_INTRO_MODAL,
  FAMILY_ACTIVATION_INTRO_MODAL,
} from '../../../../../model/enum/clientMemberSettingsStates';
import { getFeature } from '../../../../../store/feature/selectors';
import { shouldShowPCPModal } from '../../medicalTeam/medicalTeam';

@autobind
class HomePopupModals extends React.PureComponent {
  static propTypes = {
    hasInsuranceId: PropTypes.bool.isRequired,
    hasPcpSelectedAlready: PropTypes.bool.isRequired,
    tryLaterPcpState: PropTypes.bool.isRequired,
    pcpSelectionOn: PropTypes.bool.isRequired,
    tryLaterActivationState: PropTypes.bool.isRequired,
    updateMemberState: PropTypes.func.isRequired,
    getPcpSelection: PropTypes.func.isRequired,
    getPcpSelectionTracker: PropTypes.object.isRequired,
    ...createToggleStatePropTypes('homeModals'),
  };

  handlePcpModalClose() {
    const { homeModalsHide, updateMemberState } = this.props;
    updateMemberState(PCP_SELECTION_INTRO_MODAL, true);
    homeModalsHide();
  }

  handleFamilyActivationModalClose() {
    const { homeModalsHide, updateMemberState } = this.props;
    updateMemberState(FAMILY_ACTIVATION_INTRO_MODAL, true);
    homeModalsHide();
  }

  renderPcp() {
    const {
      homeModalsShow,
      homeModalsOn,
      hasPcpSelectedAlready,
      tryLaterPcpState,
      shouldShowPCPModal,
    } = this.props;
    if (tryLaterPcpState === true || hasPcpSelectedAlready || !shouldShowPCPModal) return null;

    if (!homeModalsOn) {
      homeModalsShow();
    }

    return (
      <FeatureFlagged uri="pcpSelection">
        <StartPcpSelectionModal isOpen={homeModalsOn} handleClose={this.handlePcpModalClose} />
      </FeatureFlagged>
    );
  }

  renderFamilyActivation() {
    const { hasInsuranceId, homeModalsOn } = this.props;
    return (
      <FeatureFlagged uri="familyActivation">
        {() => (
          <InviteFamilyFlow
            hasInsuranceId={hasInsuranceId}
            isOpen={homeModalsOn}
            onClose={this.handleFamilyActivationModalClose}
          />
        )}
      </FeatureFlagged>
    );
  }

  render() {
    const { pcpSelectionOn, familyActivationOn } = this.props;
    if (pcpSelectionOn && familyActivationOn) {
      return this.renderPcp() || this.renderFamilyActivation();
    }

    if (pcpSelectionOn) {
      return this.renderPcp();
    }

    if (familyActivationOn) {
      return this.renderFamilyActivation();
    }

    return null;
  }
}

export default compose(
  createToggleState('homeModals'),
  connect(state => ({
    pcpSelectionOn: getFeature(state, 'pcpSelection'),
  })),
  branch(
    props => props.pcpSelectionOn,
    fetchNotEmpty(getPcpSelection, getSelectedPcp, {
      predicate: val => !_.isNil(val),
      renderAlways: false,
      loading: false,
    }),
  ),
  connect(
    state => ({
      familyActivationOn: getFeature(state, 'familyActivation'),
      tryLaterPcpState: getMemberSettingsState(state, PCP_SELECTION_INTRO_MODAL),
      tryLaterActivationState: getMemberSettingsState(state, FAMILY_ACTIVATION_INTRO_MODAL),
      hasPcpSelectedAlready: !_.isEmpty(getSelectedPcp(state)),
      hasInsuranceId: hasInsuranceIdSelector(state),
      shouldShowPCPModal: shouldShowPCPModal(state),
    }),
    { updateMemberState },
  ),
  actionTracker({
    getPcpSelectionTracker: GET_PCP_SELECTION.SOURCE,
  }),
)(HomePopupModals);
