import React from 'react';
import PropTypes from 'prop-types';
import { compose, pure, withHandlers, defaultProps } from 'recompose';
import { withRouter } from 'react-router';

import PCPSelectionFlow from '../../../../flows/pcpSelectionFlow';
import BlueFooter from '../../../../components/molecules/BlueFooter/BlueFooter';
import { withPublicToken } from '../../../../components/recompose/withPublicToken';
import queryConnect from '../../../../store/tools/queryString/queryConnect';
import Header from '../../../../components/ui/header/header';
import CoOpLogo from '../../../../components/branding/co_opLogo';
import * as Subdomain from '../../../../model/enum/subdomain';

function PublicPCPSelection({ token, goBack, memberContext }) {
  return (
    <div>
      <Header>
        {goBack && (
          <button className="btn header-back-btn" onClick={goBack} aria-label="Back">
            <i className="icon-x-2" />
          </button>
        )}
        <CoOpLogo memberContext={memberContext} />
      </Header>
      <div className="has-footer footer-not-fixed">
        <PCPSelectionFlow token={token} />
      </div>
      <BlueFooter footerClassName="not-fixed-vh white" />
    </div>
  );
}

PublicPCPSelection.propTypes = {
  token: PropTypes.string.isRequired,
  goBack: PropTypes.func.isRequired,
  memberContext: PropTypes.string,
};

PublicPCPSelection.defaultProps = {
  memberContext: Subdomain.PREMERA_PEAK_CARE,
};

export default compose(
  pure,
  withRouter,
  queryConnect(({ next }) => ({
    next,
  })),
  defaultProps({
    next: '/secure',
  }),
  withHandlers({
    goBack: ({ next, router }) => () => {
      router.push(next);
    },
  }),
  withPublicToken(),
)(PublicPCPSelection);
