import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '../../../storybook/src/utils/theme';

function SvgGirlAvatar({ theme }) {
  /* eslint-disable */
  return (
    <svg aria-hidden="true" focusable="false" width="71px" height="71px" viewBox="0 0 71 71" version="1.1" className="svg-icon svg-girl-avatar">
      <defs>
        <polygon points="0 53.0004502 0 0 35.49975 0 70.9995 0 70.9995 53.0004502"></polygon>
      </defs>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-1.000000, -1.000000)">
          <g transform="translate(1.000000, 1.000000)">
            <g>
              <g></g>
              <path d="M65.4775,53 C65.2385,53 64.9955,52.943 64.7695,52.822 C64.0395,52.431 63.7645,51.521 64.1565,50.791 C66.6705,46.104 67.9995,40.816 67.9995,35.5 C67.9995,17.579 53.4205,3 35.4995,3 C17.5795,3 2.9995,17.579 2.9995,35.5 C2.9995,40.818 4.3295,46.105 6.8425,50.791 C7.2355,51.521 6.9605,52.43 6.2305,52.821 C5.5005,53.214 4.5915,52.94 4.2005,52.209 C1.4525,47.09 -0.0005,41.312 -0.0005,35.5 C-0.0005,15.925 15.9245,0 35.4995,0 C55.0755,0 70.9995,15.925 70.9995,35.5 C70.9995,41.31 69.5475,47.088 66.8005,52.209 C66.5295,52.714 66.0115,53 65.4775,53" fill={theme.secondaryColor} ></path>
            </g>
            <path d="M35.5,68 C25.886,68 16.903,63.811 10.699,56.469 C14.161,50.388 19.873,45.679 26.508,43.471 C27.026,43.299 27.409,42.858 27.509,42.321 C27.608,41.784 27.408,41.235 26.986,40.889 C23.817,38.288 22,34.501 22,30.5 C22,23.056 28.056,17 35.5,17 C42.942,17 48.996,23.053 49,30.493 C49,30.496 48.999,30.498 48.999,30.5 L48.999,30.518 C48.994,34.512 47.178,38.292 44.014,40.889 C43.592,41.235 43.392,41.784 43.491,42.321 C43.591,42.858 43.974,43.299 44.492,43.471 C51.127,45.679 56.839,50.388 60.301,56.469 C54.097,63.811 45.114,68 35.5,68 M63.431,55.942 C59.999,49.383 54.479,44.32 47.729,41.484 C48.715,40.397 49.537,39.195 50.195,37.915 C51.204,39.183 52.757,40 54.499,40 C56.076,40 57.58,39.321 58.625,38.137 C59.173,37.516 59.113,36.568 58.492,36.02 C57.869,35.473 56.923,35.531 56.375,36.152 C55.899,36.691 55.216,37 54.499,37 C53.12,37 51.999,35.879 51.999,34.5 L51.999,30.521 C51.999,30.514 52,30.507 52,30.5 C52,21.402 44.598,14 35.5,14 C26.402,14 19,21.402 19,30.5 L19,34.5 C19,35.879 17.879,37 16.5,37 C15.783,37 15.1,36.691 14.624,36.152 C14.075,35.532 13.129,35.473 12.507,36.02 C11.886,36.568 11.826,37.516 12.374,38.137 C13.419,39.321 14.923,40 16.5,40 C18.242,40 19.795,39.183 20.804,37.914 C21.462,39.194 22.285,40.397 23.271,41.484 C16.521,44.32 11.001,49.383 7.569,55.942 C7.294,56.468 7.355,57.107 7.725,57.571 C14.514,66.105 24.638,71 35.5,71 C46.362,71 56.486,66.105 63.275,57.571 C63.645,57.107 63.706,56.468 63.431,55.942" fill={theme.mainColor} ></path>
            <path d="M44.5,27 L41.5,27 C41.103,27 40.721,26.842 40.439,26.561 L38.5,24.621 L36.561,26.561 C36.279,26.842 35.897,27 35.5,27 L26.5,27 C25.672,27 25,26.328 25,25.5 C25,24.672 25.672,24 26.5,24 L34.879,24 L37.439,21.439 C38.025,20.854 38.975,20.854 39.561,21.439 L42.121,24 L44.5,24 C45.328,24 46,24.672 46,25.5 C46,26.328 45.328,27 44.5,27" fill={theme.mainColor} ></path>
            <path d="M35.5003,53.5275 C34.4433,53.5275 33.7433,52.8165 33.2803,52.3465 C33.1963,52.2605 33.0803,52.1435 32.9973,52.0695 C32.8823,52.0755 32.7093,52.0955 32.5843,52.1115 C31.9383,52.1915 30.9683,52.3075 30.1543,51.6745 C29.3553,51.0515 29.2233,50.0925 29.1363,49.4585 C29.1203,49.3345 29.0963,49.1635 29.0733,49.0485 C28.9773,48.9905 28.8353,48.9155 28.7303,48.8595 C28.1613,48.5595 27.3023,48.1055 26.9813,47.1345 C26.7223,46.3475 27.1493,45.5005 27.9363,45.2405 C28.6553,45.0045 29.4203,45.3375 29.7473,45.9935 C29.8443,46.0555 30.0113,46.1435 30.1313,46.2075 C30.5913,46.4495 31.1633,46.7515 31.5543,47.3175 C31.9503,47.8865 32.0393,48.5335 32.1093,49.0515 C32.1133,49.0815 32.1183,49.1125 32.1223,49.1455 C32.1573,49.1405 32.1913,49.1365 32.2213,49.1325 C32.7283,49.0715 33.3623,48.9945 34.0003,49.2175 C34.6263,49.4375 35.0653,49.8825 35.4183,50.2415 C35.4433,50.2665 35.4703,50.2945 35.5003,50.3245 C35.5293,50.2945 35.5563,50.2665 35.5823,50.2415 C35.9343,49.8825 36.3743,49.4375 36.9993,49.2175 C37.6363,48.9945 38.2693,49.0705 38.7783,49.1325 C38.8083,49.1365 38.8433,49.1405 38.8783,49.1455 C38.8823,49.1125 38.8863,49.0815 38.8903,49.0515 C38.9613,48.5335 39.0503,47.8865 39.4453,47.3165 C39.8373,46.7515 40.4093,46.4495 40.8693,46.2075 C40.9883,46.1435 41.1553,46.0555 41.2533,45.9935 C41.5803,45.3375 42.3433,45.0045 43.0633,45.2405 C43.8503,45.5005 44.2773,46.3475 44.0183,47.1345 C43.6983,48.1055 42.8393,48.5595 42.2693,48.8595 C42.1593,48.9185 42.0073,48.9995 41.9113,49.0585 C41.9073,49.1325 41.8813,49.3235 41.8633,49.4585 C41.7763,50.0925 41.6443,51.0515 40.8453,51.6735 C40.0333,52.3085 39.0593,52.1905 38.4163,52.1115 C38.2913,52.0955 38.1183,52.0755 38.0033,52.0695 C37.9203,52.1435 37.8033,52.2605 37.7193,52.3465 C37.2573,52.8165 36.5563,53.5275 35.5003,53.5275 Z" fill={theme.mainColor} ></path>
          </g>
        </g>
      </g>
    </svg>
  );
  /* eslint-enable */
}

SvgGirlAvatar.propTypes = {
  theme: PropTypes.object.isRequired,
};

export default withTheme(SvgGirlAvatar);
