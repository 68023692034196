/**
 * Created by galgoltzman on 02/08/2017.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { autobind } from 'core-decorators';

import messages from '../../apps/members/src/home/messages';
import BkmdModal from '../bkmdModal/bkmdModal';
import ModalHeader from '../bkmdModal/modalHeader';
import SpinnerButton from '../ui/spinner/spinnerButton';
import LocationSelect from '../../apps/members/src/directory/searchDoctorsResults/components/location/locationSelect';
import {
  createToggleState, createToggleStatePropTypes
} from '../recompose/toggleStateComponent';

const isGeocoding = 'isGeocoding';

@autobind
class SearchOptionsModal extends React.Component {

  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onApply: PropTypes.func.isRequired,
    plan: PropTypes.string,
    /**
     * Name for identifying via analytics
     */
    name: PropTypes.string, // eslint-disable-line react/no-unused-prop-types
    ...createToggleStatePropTypes(isGeocoding),
  };

  static defaultProps = {
    name: 'search_options_modal',
    plan: undefined,
  };

  constructor(props) {
    super(props);

    this.state = {
      wasApplied: false,
      plan: props.plan,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (!this.props.plan && !!nextProps.plan) {
      this.setState({ plan: nextProps.plan });
    }
  }

  componentWillUpdate(nextProps, nextState) {
    const wasClickedOrGeocoded =
      nextState.wasApplied !== this.state.wasApplied ||
      nextProps.isGeocodingOn !== this.props.isGeocodingOn;

    if (wasClickedOrGeocoded && nextState.wasApplied && !nextProps.isGeocodingOn) {
      this.doApply();
    }
  }

  onApply() {
    this.setState({ wasApplied: true });
  }

  onLocationChange(data) {
    this.setState({ ...data });
  }

  onPlanChange(plan) {
    this.setState({ plan });
  }

  doApply() {
    // Remove wasApplied from the state
    const { wasApplied, ...other } = this.state; // eslint-disable-line no-unused-vars
    this.props.onApply(other);
    this.props.onClose();
    this.setState({ wasApplied: false });
  }

  render() {
    const { onClose, isOpen, name, isGeocodingShow, isGeocodingHide, isGeocodingOn } = this.props;
    const { wasApplied } = this.state;

    return (
      <BkmdModal
        className="search-options-modal fixed-header overflow-visible"
        isOpen={isOpen}
        handleClose={onClose}
        handleSubmit={this.onApply} // bind 'Enter' key
        name={name}
      >
        <ModalHeader
          onClose={onClose}
          title={messages.personalizeYourSearch}
        />
        <div className="dialog-body">
          <div className="row">
            <div className="col-xs-12">
              <label className="static-label-header">
                <FormattedMessage {...messages.location} />
              </label>
            </div>
          </div>
          <div className="row margin-bottom-clean-form">
            <div className="col-xs-12">
              <LocationSelect
                onChange={this.onLocationChange}
                onStartGeocode={isGeocodingShow}
                onFinishGeocode={isGeocodingHide}
              />
            </div>
          </div>
          <div className="dialog-footer no-padding">
            <div className="row tiny-gutter">
              <div className="col-xs-6">
                <button className="btn btn-big btn-light-grey pull-left" onClick={onClose}>
                  <FormattedMessage {...messages.cancel} />
                </button>
              </div>
              <div className="col-xs-6">
                <SpinnerButton
                  isLoading={isGeocodingOn && wasApplied}
                  className="btn btn-big pull-right bg-color-brand-button"
                  onClick={this.onApply}
                >
                  <FormattedMessage {...messages.apply} />
                </SpinnerButton>
              </div>
            </div>
          </div>
        </div>
      </BkmdModal>
    );
  }
}

export default createToggleState(isGeocoding, false)(SearchOptionsModal);
