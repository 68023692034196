/**
 * Created by asafdavid on 13/01/2016.
 */
import React from 'react';
import { omit } from 'lodash';
import PropTypes from 'prop-types';

import cleanInputWrapper from './cleanInputWrapper';

const inputComponent = ({ inputRef, ...rest }) => (
  <input
    ref={inputRef} {...omit(rest, [
      'forceRender',
      'focused',
      'field',
      'element',
      'caretPosition',
      'preValue',
      'showErrorOnTop',
      'rightIconDisabled',
      'showLimitSpan',
      'controlled',
      'rightIconLoading',
      'rightIconType',
    ])}
  />
);

inputComponent.propTypes = {
  inputRef: PropTypes.func,
};

inputComponent.defaultProps = {
  inputRef: undefined,
};

export default cleanInputWrapper(inputComponent);
