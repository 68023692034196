/**
 * Created by anna on 07/08/2017.
 */
import { defineMessages } from 'react-intl';
import IntlEnum from '../../../../utils/intlEnum';
import * as PositiveDoctorEnum from '../../../../components/doctorCard/sections/reviewPositiveOptions/positiveDoctorOptions';
import * as PositiveOfficeEnum from '../../../../components/doctorCard/sections/reviewPositiveOptions/positiveOfficeOptions';

const messagesGender = defineMessages({
  enoughTimeSpentGender: {
    defaultMessage: '{gender} ',
    id: 'members.doctorReview.enoughTimeSpent.gender'
  },
  goodListenerGender: {
    defaultMessage: '{gender}\'s ',
    id: 'members.doctorReview.goodListener.gender'
  },
  explainsClearlyGender: {
    defaultMessage: '{gender} ',
    id: 'members.doctorReview.explainsClearly.gender'
  },
  iTrustHerDecisionsGender: {
    defaultMessage: '{relativeGender} ',
    id: 'members.doctorReview.iTrustHerDecisions.gender'
  },
  easySchedulingGender: {
    defaultMessage: '{relativeGender} ',
    id: 'members.doctorReview.easyScheduling.gender'
  },
  cleanComfyGender: {
    defaultMessage: '{relativeGender} ',
    id: 'members.doctorReview.cleanComfy.gender'
  },
  friendlyStaffGender: {
    defaultMessage: '{relativeGender} ',
    id: 'members.doctorReview.friendlyStaff.gender'
  },
  shortWaitTimesGender: {
    defaultMessage: '{gender} ',
    id: 'members.doctorReview.shortWaitTimes.gender'
  },
});

const PositiveEnumGender = new IntlEnum(
  { value: PositiveDoctorEnum.ENOUGH_TIME_SPENT, message: messagesGender.enoughTimeSpentGender },
  { value: PositiveDoctorEnum.GOOD_LISTENER, message: messagesGender.goodListenerGender },
  { value: PositiveDoctorEnum.EXPLAINS_CLEARLY, message: messagesGender.explainsClearlyGender },
  { value: PositiveDoctorEnum.TRUST_DECISIONS, message: messagesGender.iTrustHerDecisionsGender },
  { value: PositiveOfficeEnum.EASY_SCHEDULING, message: messagesGender.easySchedulingGender },
  { value: PositiveOfficeEnum.CLEAN_AND_COMFY, message: messagesGender.cleanComfyGender },
  { value: PositiveOfficeEnum.FRIENDLY_STAFF, message: messagesGender.friendlyStaffGender },
  { value: PositiveOfficeEnum.SHORT_WAIT_TIME, message: messagesGender.shortWaitTimesGender },
);

export default PositiveEnumGender;
