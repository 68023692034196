/**
 * Created by orizis on 17/04/2018.
 */

import _ from 'lodash';
import PromiseActionType from '../../promiseActionType';

export const HAS_DEPENDENTS = new PromiseActionType('pcpSelection/HAS_DEPENDENTS');
export const GET_DEPENDENTS = new PromiseActionType('pcpSelection/GET_DEPENDENTS');
export const GET_PCP_SELECTION = new PromiseActionType('pcpSelection/GET_PCP_SELECTION');
export const UPDATE_PCP_SELECTION = new PromiseActionType('pcpSelection/UPDATE_PCP_SELECTION');

export function hasDependents() {
  return ({ bkmdApi: { membersApi } }) => ({
    type: HAS_DEPENDENTS.SOURCE,
    payload: {
      promise: membersApi.hasFamilyMembers()
    }
  });
}

export function getDependents(withPcp = false) {
  return ({ bkmdApi: { membersApi } }) => ({
    type: GET_DEPENDENTS.SOURCE,
    payload: {
      promise: membersApi.getFamilyMembers(withPcp)
    },
    meta: {
      tracker: GET_DEPENDENTS.SOURCE,
    }
  });
}

export function getPcpSelection() {
  return ({ bkmdApi: { membersApi } }) => ({
    type: GET_PCP_SELECTION.SOURCE,
    meta: {
      tracker: GET_PCP_SELECTION.SOURCE,
    },
    payload: {
      promise: membersApi.getPcpSelection()
    }
  });
}

export function updatePcpSelection(domainMemberId, npi, locationId, isDependent = false) {
  return ({ bkmdApi: { membersApi }, dispatch }) => ({
    type: UPDATE_PCP_SELECTION.SOURCE,
    meta: {
      tracker: `${npi}-${UPDATE_PCP_SELECTION.SOURCE}`,
    },
    payload: {
      promise: membersApi.updatePcpSelection(domainMemberId, npi, _.toNumber(locationId))
        .then(() => {
          if (isDependent) {
            dispatch(getDependents(true));
          } else {
            dispatch(getPcpSelection());
          }
        })
    }
  });
}
