import _ from 'lodash';
import { compose } from 'recompose';
import { autobind } from 'core-decorators';

@autobind
export default class MiddlewareManager {
  static callbackMethods = ['onStart', 'onSuccess', 'onError'];

  _appliedMiddlewares = [];

  /**
   * middleware is an object of the form: {
   *  onStart: name => next => props => {}
   *  onSuccess: name => next => (props, response) => {}
   *  onError: name => next => error => {}
   * }
   */
  applyMiddleware(...middleware) {
    this._appliedMiddlewares.push(...middleware);
  }

  /**
   * Composes the middlewares that were applied with the callback object into one callback object,
   * @param name
   * @param callback
   * @param middlewares
   * @return {{}}
   * @private
   */
  _composeMiddlewares(name, callback, middlewares) {
    const middlewaresWithName = {};
    // initiate an object with the middleware functions, after initiating with name
    _.each(MiddlewareManager.callbackMethods, method => {
      middlewaresWithName[method] = [];
    });

    /**
     * Run the middleware functions with the prop/action name
     * @param middlewares
     */
    const invokeMiddlewaresWithName = (...middlewares) => {
      _.each(middlewares, middleware => {
        _.each(MiddlewareManager.callbackMethods, method => {
          const middlewareMethod = _.get(middleware, method);
          if (middlewareMethod) middlewaresWithName[method].push(middlewareMethod(name));
        });
      });
    };

    invokeMiddlewaresWithName(...this._appliedMiddlewares, ...middlewares);

    const returned = {};
    _.each(middlewaresWithName, (middleware, method) => {
      const enhance = compose(...middleware);
      const callbackMethod = _.get(callback, method, _.noop);
      returned[method] = enhance(callbackMethod);
    });
    return returned;
  }

  /**
   * Creates the fetch function using the callback and the middlewares
   * @param fetchFunction
   * @param propName
   * @param onStart
   * @param onSuccess
   * @param onError
   * @param middlewares
   * @return {function(*=): function(...[*])}
   */
  createFetchFunction(
    fetchFunction,
    propName,
    { onStart, onSuccess, onError, _asHandler } = {},
    middlewares = [],
  ) {
    const {
      onStart: _onStart,
      onSuccess: _onSuccess,
      onError: _onError,
    } = this._composeMiddlewares(propName, { onStart, onSuccess, onError }, middlewares);

    return props => (...args) => {
      let func = fetchFunction;
      if (_onStart) {
        _onStart(props);
      }
      if (_asHandler) {
        func = fetchFunction(props);
      }

      return func(...args)
        .then(response => {
          if (_onSuccess) _onSuccess(props, response);
          return response;
        })
        .catch(err => {
          if (!_onError) throw err;
          _onError(props, err);
        });
    };
  }

  /**
   * @param fetchFunction
   * @param propName
   * @param onSuccess
   * @param onError
   * @param middlewares
   * @return {function(*=)}
   */
  createFetchOnMountFunction(
    fetchFunction,
    propName,
    { onSuccess, onError } = {},
    middlewares = [],
  ) {
    const { onSuccess: _onSuccess, onError: _onError } = this._composeMiddlewares(
      propName,
      { onSuccess, onError },
      middlewares,
    );

    return props =>
      fetchFunction(props)
        .then(response => {
          if (_onSuccess) _onSuccess(props, response);
          return response;
        })
        .catch(err => {
          if (_onError) _onError(props, err);
          throw err;
        });
  }
}
