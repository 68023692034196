/**
 * Created by chenrozenes on 19/06/2016.
 */
import Promise from 'bluebird';
import _ from 'lodash';

const funcs = {};

/**
 * Debounces a promise function by key and time
 * @param key - unique function key for tracking function calls
 * @param promiseFunc - a function that returns a promise
 * @param time - time for debouncing
 * @param flush - true if you don't wish to debounce
 * @returns {*}
 */
export function debounce(key, promiseFunc, time, flush = false) {
  // If we don't have a this function key yet, create its object
  if (!funcs[key]) {
    funcs[key] = {};
  }

  // Clear timeout if we have one
  if (funcs[key].timer) {
    clearTimeout(funcs[key].timer);
  }

  // If we need to send it now - do it
  if (flush) {
    return promiseFunc();
  }

  // Creating the promise to return, and resolving it later when done
  const pending = new Promise((resolve, reject) => {
    funcs[key].resolve = resolve;
    funcs[key].reject = reject;
  });

  // Setting timeout for execution and resolving promise when done
  funcs[key].timer = setTimeout(() => {
    promiseFunc().then(funcs[key].resolve, funcs[key].reject);
  }, time);

  return pending;
}

/**
 * Middleware for redux that can will debounce promise functions within actions
 * @param config
 * @returns {function(): function(): function()}
 */
export const debouncePromiseMiddleware = (config = {}) => {
  const promisePath = config.promisePath || 'payload.promise';

  return () => next => action => {
    // If we don't have a debounce property
    if (!action.debounce) {
      return next(action);
    }

    const { time, flush, execute, key } = action.debounce;
    // Starting debounce and preparing action for the next middleware
    _.set(action, promisePath, debounce(key || action.type, execute, time, flush));
    return next(action);
  };
};
