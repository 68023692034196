import React from 'react';
import PropTypes from 'prop-types';
import { withPropsOnChange, compose } from 'recompose';

import './averageSlider.less';

const DEFAULT_AVERAGE_POSITION = 50;

// assuming min is less than max :D
// Note that postion is max 90 to fix some design issues.
const calculate = function calculateAvaragePosition(min, max, avarage) {
  if (min === max) return DEFAULT_AVERAGE_POSITION;

  const range = max - min;
  const scaledAvarage = avarage - min;
  const position = Math.min((scaledAvarage / range) * 100, 90);
  return position;
};

function AverageSlider({ minLabel, maxLabel, averageLabel, min, max, average, avarageStyle }) {
  return (
    <div className="average-slider">
      <div className="slider-line">
        <div className="start-label">
          <div className="text-success">{minLabel}</div>
          <div className="amount">{`$${min}`}</div>
        </div>
        <div className="average-label" style={avarageStyle}>
          <div className="font-color-brand-secondary">{averageLabel}</div>
          <div className="amount">{`$${average}`}</div>
        </div>
        <div className="end-label">
          <div className="text-danger">{maxLabel}</div>
          <div className="amount">{`$${max}`}</div>
        </div>
      </div>
    </div>
  );
}

AverageSlider.propTypes = {
  minLabel: PropTypes.string,
  maxLabel: PropTypes.string,
  averageLabel: PropTypes.string,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  average: PropTypes.number.isRequired,
  avarageStyle: PropTypes.object.isRequired,
};

AverageSlider.defaultProps = {
  minLabel: 'Low',
  maxLabel: 'High',
  averageLabel: 'Average',
};

export default compose(
  withPropsOnChange(['min', 'max', 'avarage'], ({ min, max, average }) => ({
    avarageStyle: { left: `${calculate(min, max, average)}%` },
  })),
)(AverageSlider);
