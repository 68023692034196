/**
 * Created by noahguld on 13/08/2017.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Scrollbars } from 'react-custom-scrollbars';
import classNames from 'classnames';
import { autobind } from 'core-decorators';
import { SpringSystem } from 'rebound';
import ClickableDiv from '../clickableElement/clickableDiv';

@autobind
export default class extends React.Component {
  static propTypes = {
    style: PropTypes.object,
    className: PropTypes.string,
  };

  static defaultProps = {
    style: undefined,
    className: undefined,
  };

  componentDidMount() {
    this.springSystem = new SpringSystem();
    this.spring = this.springSystem.createSpring();
    this.spring.addListener({ onSpringUpdate: this.handleSpringUpdate });
  }

  componentWillUnmount() {
    this.spring.destroy();
    this.springSystem.removeAllListeners();
  }

  right() {
    // scroll half the width of view right
    const location = this.scrollBars.getScrollLeft() + (this.scrollBars.getClientWidth() / 2);
    this.scrollPositionChange(location);
  }

  left() {
    // scroll half the width of view left
    const location = this.scrollBars.getScrollLeft() - (this.scrollBars.getClientWidth() / 2);
    this.scrollPositionChange(location);
  }

  handleSpringUpdate(spring) {
    const val = spring.getCurrentValue();
    this.scrollBars.scrollLeft(val);
    this.forceUpdate();
  }

  handleOnScroll() {
    // force a re-render to change the hide/show arrows
    this.forceUpdate();
  }

  handleUpdate() {
    // force a re-render to change the hide/show arrows
    this.forceUpdate();
  }

  scrollPositionChange(position) {
    const scrollLeft = this.scrollBars.getScrollLeft();
    this.spring.setCurrentValue(scrollLeft).setAtRest();
    this.spring.setEndValue(position);
  }

  renderLeftButton() {
    return this.scrollBars && this.scrollBars.getScrollLeft() !== 0;
  }

  renderRightButton() {
    if (!this.scrollBars) return false;

    const values = this.scrollBars.getValues();
    return (values.left <= 0.98) && (values.clientWidth !== values.scrollWidth);
  }

  render() {
    const { style, className } = this.props;
    return (
      <div className={className}>
        <ClickableDiv
          clickHandler={this.left}
          aria-label="scroll left"
          className={classNames(
            'arrow',
            // on first render, this will always be false, which is ok because we can't scroll
            // left anyways
            this.renderLeftButton() ? null : 'hide-arrow'
          )}
        >
          <i className="icon-chevron-left left-arrow" />
        </ClickableDiv>
        <Scrollbars
          ref={instance => {
            this.scrollBars = instance;
          }}
          style={style}
          onUpdate={this.handleUpdate}
          onScroll={this.handleOnScroll}
          renderTrackHorizontal={props => <div {...props} visibility={'hidden'} />}
          renderTrackVertical={props => <div {...props} visibility={'hidden'} />}
          renderThumbHorizontal={props => <div {...props} visibility={'hidden'} />}
          renderThumbVertical={props => <div {...props} visibility={'hidden'} />}
          autoHide
        >
          {this.props.children}
        </Scrollbars>
        <ClickableDiv
          clickHandler={this.right}
          aria-label="scroll right"
          className={classNames(
            'arrow',
            this.renderRightButton() ? null : 'hide-arrow'
          )}
        >
          <i className="icon-chevron-right right-arrow" />
        </ClickableDiv>
      </div>
    );
  }
}
