import PropTypes from 'prop-types';
import React from 'react';
import { compose, pure, withHandlers } from 'recompose';
import ColorectalFlow from '../../../../flows/colorectalFlow';
import CoOpLogo from '../../../../components/branding/co_opLogo';
import Header from '../../../../components/ui/header/header';
import withControl from '../../../../components/wizard/flow/withControl';
import * as FlowDefs from '../../../../flows/definitions/colorectalFlowDef';
import BlueFooter from '../../../../components/molecules/BlueFooter/BlueFooter';
import { withPublicToken } from '../../../../components/recompose/withPublicToken';

function ColorectalFlowPage({ onPrev, hasPrev, token }) {
  return (
    <div>
      <Header>
        {hasPrev() && (
          <button className="btn header-back-btn" onClick={onPrev} aria-label="Back">
            <i className="icon-back-arrow" />
          </button>
        )}
        <CoOpLogo externalPageLogo />
      </Header>
      <div className="container container-1040 has-footer footer-not-fixed">
        <div className="row margin-top-30 no-v-margin">
          <div className="col-sm-6 col-sm-offset-3 no-padding">
            <ColorectalFlow token={token} persist />
          </div>
        </div>
      </div>
      <BlueFooter footerClassName="white not-fixed-vh" />
    </div>
  );
}

ColorectalFlowPage.propTypes = {
  hasPrev: PropTypes.func.isRequired,
  onPrev: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired,
};

export default compose(
  pure,
  withControl(FlowDefs.name),
  withHandlers({
    onPrev: ({ control }) => () => {
      control.prev({});
    },
    hasPrev: ({ control }) => () => control.hasPrev(),
  }),
  withPublicToken(),
)(ColorectalFlowPage);
