import _ from 'lodash';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { branch } from 'recompose';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Typekit from 'react-typekit';
import { IntlProvider } from 'react-intl';

import { getLocale, getVimApiUrl } from '../../../../store/intl/selectors';
import { updateLocaleFromStorage } from '../../../../store/app/actions';
import {
  loadGooglePlacesAutoCompleteApi,
  initHelpScoutBeacon,
  initSdkApi,
  initVimApi,
} from '../../../../store/help/actions';
import { loadRecaptchaApi } from '../../../../store/recaptcha/actions';
import { domainShortName, domain as domainSelector } from '../../../../store/config/selectors';
import { getFeature } from '../../../../store/feature/selectors';
import injectAnalytics from '../../../../store/tools/analytics/injectAnalytics';
import { getCurrentPlatform } from '../../../../store/platform/actions';
import { getTaxonomyList } from '../../../../store/meta/actions';
import { getCurrentLocation } from '../../../../store/location/actions';
import queryConnect from '../../../../store/tools/queryString/queryConnect';
import { loadSdkScript } from '../../../../store/help/initializationSnippets';
import NotificationContainer from '../../../../components/notification/notificationsContainer';
import { withRolloutFlagsView } from '../../../../components/withRolloutFlagsView/withRolloutFlagsView';
import { ThemeProvider, themes } from '../../../../storybook/src/utils/theme';
import { withStateFetchersOnMount } from '../../../../api/injectApi/withStateFetchersOnMount';
import Util from '../../../../utils/util';
import withFeatureFlag from '../../../../utils/featureFlags/withFeatureFlag';
import messages from '../../../../store/intl/translations';
import '../../../../assets/less/bookmd-app.less';

export const Basename = '';

class App extends PureComponent {
  static propTypes = {
    children: PropTypes.object.isRequired,
    loadGooglePlacesAutoCompleteApi: PropTypes.func.isRequired,
    getTaxonomyList: PropTypes.func.isRequired,
    getCurrentPlatform: PropTypes.func.isRequired,
    getCurrentLocation: PropTypes.func.isRequired,
    updateLocaleFromStorage: PropTypes.func.isRequired,
    initHelpScoutBeacon: PropTypes.func.isRequired,
    loadRecaptchaApi: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    params: PropTypes.object.isRequired,
    routes: PropTypes.array.isRequired,
    auth: PropTypes.object.isRequired,
    domain: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    favicon: PropTypes.string.isRequired,
    linkImage: PropTypes.string.isRequired,
    description: PropTypes.string,
    defaultLocale: PropTypes.string,
    userScalable: PropTypes.bool,
    locale: PropTypes.string.isRequired,
  };

  static contextTypes = {
    analytics: PropTypes.object.isRequired,
    paths: PropTypes.object.isRequired,
  };

  static childContextTypes = {
    location: PropTypes.object,
  };

  static defaultProps = {
    description: undefined,
    userScalable: false,
    defaultLocale: undefined,
  };

  componentDidMount() {
    this.props.updateLocaleFromStorage(this.props.defaultLocale);
    this.props.loadGooglePlacesAutoCompleteApi();
    this.props.getCurrentPlatform();
    this.props.getTaxonomyList();
    this.props.initHelpScoutBeacon();
    this.props.loadRecaptchaApi();
    this.reportPageView();
    this.props.getCurrentLocation();
  }

  componentWillReceiveProps(nextProps) {
    Util.inspectChange(this.props, nextProps, [
      {
        uri: 'location.pathname',
        action: () => this.reportPageView(nextProps.routes, nextProps.location, nextProps.auth),
      },
    ]);
  }

  reportPageView() {
    const { routes, location, auth, params } = this.props;
    const userId = _.get(auth, 'user.id');
    this.context.analytics.reportViewedPage(routes, location.pathname, userId, {
      ...params,
      ...location.query,
    });
  }

  render() {
    const {
      children,
      domain,
      title,
      favicon,
      linkImage,
      description,
      userScalable,
      locale,
    } = this.props;
    const { wait } = this.state || {};
    const className = `page ${domain}`;
    const links = [
      {
        rel: 'icon',
        href: favicon || require('../../../../assets/images/favicon.png'),
      },
    ];

    const scalingOptions = userScalable
      ? 'maximum-scale=6.0'
      : 'maximum-scale=1.0, user-scalable=0';
    return (
      <IntlProvider
        key={locale} // https://github.com/yahoo/react-intl/issues/234
        locale={locale}
        messages={messages[locale]}
      >
        <ThemeProvider theme={themes[domain]}>
          <div className={className}>
            <Typekit kitId="yhc6nhi" />
            <Helmet
              link={links}
              meta={[
                {
                  name: 'description',
                  content: description || 'BookMD - Healthcare simplified',
                },
                {
                  name: 'viewport',
                  content: `width=device-width, ${scalingOptions}`,
                },
                {
                  name: 'robots',
                  content: 'noindex',
                },
                {
                  property: 'og:title',
                  content: title,
                },
                {
                  property: 'og:image',
                  content: linkImage,
                },
              ]}
              title={title || 'Vim'}
            />
            <NotificationContainer />
            {wait ? null : children}
          </div>
        </ThemeProvider>
      </IntlProvider>
    );
  }
}

const withSdk = compose(
  connect(state => ({
    domainName: domainSelector(state),
  })),
  withFeatureFlag({
    key: 'members.useSchedulingSdk',
    prop: 'useSchedulingSdk',
    defaultValue: false,
    context: ({ domainName }) => ({ domain: domainName }),
  }),
  branch(
    ({ useSchedulingSdk }) => useSchedulingSdk === true,
    withStateFetchersOnMount({
      initSdkApiFetch: {
        handler: () => () => loadSdkScript(),
        resultPropName: 'initSdk', // we want to wait until the sdk script downloaded
        isReady: ({ initSdk }) => initSdk === true,
        onSuccess: ({ initSdkApi, sdkKeysConfig }) => initSdkApi(sdkKeysConfig),
      },
    }),
  ),
);

const withVimApi = compose(
  connect(state => ({
    vimApiUrl: getVimApiUrl(state),
  })),
  withStateFetchersOnMount({
    initVimApi: {
      handler: ({ initVimApi, vimApiUrl, sdkKeysConfig }) => () => {
        initVimApi(vimApiUrl, _.get(sdkKeysConfig, 'sdkKey'));
      },
    },
  }),
);
export default compose(
  injectAnalytics('Members', domainSelector),
  withRolloutFlagsView,
  queryConnect(({ language }) => ({ defaultLocale: language })),
  connect(
    state => ({
      domain: domainShortName(state),
      auth: state.auth,
      title: getFeature(state, 'site.title'),
      favicon: getFeature(state, 'site.favicon'),
      description: getFeature(state, 'site.description'),
      theme: getFeature(state, 'site.theme'),
      linkImage: getFeature(state, 'site.linkImage'),
      userScalable: getFeature(state, 'site.userScalable'),
      locale: getLocale(state),
      sdkKeysConfig: _.get(state, 'config.schedulingSdkKeys'),
    }),
    {
      getTaxonomyList,
      loadGooglePlacesAutoCompleteApi,
      getCurrentPlatform,
      initHelpScoutBeacon,
      loadRecaptchaApi,
      getCurrentLocation,
      updateLocaleFromStorage,
      initSdkApi,
      initVimApi,
    },
  ),
  withSdk,
  withVimApi,
)(App);
