import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './container.less';

const containerWidth = {
  default: 'col-md-6 col-md-offset-3 col-sm-8 col-sm-offset-2 no-padding',
  small: 'col-sm-6 col-sm-offset-3 col-lg-4 col-lg-offset-4 no-padding',
  large: 'col-sm-10 col-sm-offset-1 col-md-10 col-md-offset-1 no-padding',
};

function Container({ width, children, withHeader, withFooter }) {
  const containerWithHeader = withHeader ? 'with-header' : undefined;
  const containerWithFooter = withFooter ? 'has-footer footer-not-fixed' : undefined;
  return (
    <div
      className={classNames('container container-1040', containerWithHeader, containerWithFooter)}
    >
      <div className="row no-margin padding-h-30">
        <div className={containerWidth[width]}>{children}</div>
      </div>
    </div>
  );
}

Container.propTypes = {
  width: PropTypes.string,
  withHeader: PropTypes.bool,
  withFooter: PropTypes.bool,
};

Container.defaultProps = {
  width: 'default',
  withHeader: false,
  withFooter: false,
};

export default Container;
