import React from 'react';
import { defineMessages } from 'react-intl';
import LoadingComponent from '../../../../components/pages/loading/loadingComponent';

const messages = defineMessages({
  loadingScreenTitle: {
    defaultMessage: 'Loading Providers',
    id: 'members.shareDoctors.loadingScreenTitle',
  },
  loadingScreenMessage: {
    defaultMessage: 'Finding your shared providers list info',
    id: 'members.shareDoctors.loadingScreenMessage',
  },
});

export default function loadingPage() {
  return (
    <LoadingComponent
      headerMessage={messages.loadingScreenTitle}
      contentMessage={messages.loadingScreenMessage}
    />
  );
}
