/**
 * Created by guyavarham on 05/04/2017.
 */

import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import { FormattedMessage } from 'react-intl';
import FilterGroup from './filterGroup';
import messages from './filterModalMessages';
import BkmdModal from '../../bkmdModal/bkmdModal';
import ModalHeader from '../../bkmdModal/modalHeader';
import SpinnerButton from '../../ui/spinner/spinnerButton';
import { definitionsByKey } from './filteringDefenitions';

import './filterModal.less';

const distanceFilters = definitionsByKey('distance');
const genderFilters = definitionsByKey('gender');
const languageFilters = definitionsByKey('language');

@autobind
class FilterModal extends React.PureComponent {
  static propTypes = {
    /*  eslint-disable react/no-unused-prop-types */
    filters: PropTypes.object.isRequired,
    /*  eslint-enable react/no-unused-prop-types */
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onApply: PropTypes.func.isRequired,
    locationComponent: PropTypes.func.isRequired,
    isGeocoding: PropTypes.bool.isRequired,
    /**
     * Name for identifying via analytics
     */
    name: PropTypes.string, // eslint-disable-line react/no-unused-prop-types
    distanceFilterOn: PropTypes.bool,
    genderFilterOn: PropTypes.bool,
    languageFilterOn: PropTypes.bool,
  };

  static defaultProps = {
    name: '',
    distanceFilterOn: false,
    genderFilterOn: false,
    languageFilterOn: false,
  };

  constructor(props) {
    super(props);

    this.state = {};
  }

  componentWillReceiveProps(nextProps) {
    if ((_.isEmpty(this.state) && nextProps.filters) || this.props.filters !== nextProps.filters) {
      this.setState({ ...nextProps.filters });
    }
  }

  componentWillUpdate(nextProps, nextState) {
    const wasClickedOrGeocoded =
      nextState.wasApplied !== this.state.wasApplied ||
      nextProps.isGeocoding !== this.props.isGeocoding;

    if (wasClickedOrGeocoded && nextState.wasApplied && !nextProps.isGeocoding) {
      this.doApply();
    }
  }

  /**
   * filter groups raises an object with the relevant filter key and the new values
   * @param payload
   */
  onFilterGroupChange(payload) {
    this.setState(payload);
  }

  onApply() {
    this.setState({ wasApplied: true });
  }

  onLocationChange(data) {
    this.setState({ ...data });
  }

  doApply() {
    // Remove wasApplied from the state
    const { wasApplied, ...other } = this.state; // eslint-disable-line no-unused-vars
    this.props.onApply(other);
    this.props.onClose();
    this.setState({ wasApplied: false });
  }

  render() {
    const {
      onClose,
      isOpen,
      name,
      locationComponent,
      isGeocoding,
      distanceFilterOn,
      genderFilterOn,
      languageFilterOn,
    } = this.props;
    const { wasApplied } = this.state;

    return (
      <BkmdModal
        id="filter_doctors"
        disableKeyDown
        handleClose={onClose}
        handleSubmit={this.onApply} // bind 'Enter' key
        isOpen={isOpen}
        name={name}
        className="results-filter-dialog fixed-header overflow-visible"
      >
        <ModalHeader onClose={onClose} title={messages.filterDoctors} />
        <div className="dialog-body">
          <div className="row">
            <div className="col-xs-12">
              <div className="row">
                {/*  location */}
                <div className="col-xs-12">
                  <div className="row">
                    <div className="col-xs-12">
                      <label className="static-label-header" htmlFor="location">
                        <FormattedMessage {...messages.location} />
                      </label>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-12" id="location">
                      {locationComponent()}
                    </div>
                  </div>
                </div>
              </div>

              {/*  distance */}
              {distanceFilterOn && (
                <div role="group" aria-labelledby="within_a">
                  <div className="row" id="within_a">
                    <div className="col-xs-12">
                      <label className="static-label-header" htmlFor="within">
                        <FormattedMessage {...messages.within} />
                      </label>
                    </div>
                  </div>

                  {distanceFilters && (
                    <FilterGroup
                      id="within"
                      value={this.state[distanceFilters.key]}
                      intlEnum={distanceFilters.options}
                      qsKey={distanceFilters.key}
                      onChange={this.onFilterGroupChange}
                      defaultValue={distanceFilters.defaultValue}
                      wrapClass="row two-lines-btn tiny-gutter distance-options"
                      singleOptionClass="btn btn-small btn-outline text-semibold"
                      marginBetweenOptions={2}
                      equalBtnSize
                    />
                  )}
                </div>
              )}

              {/*  gender */}
              {genderFilterOn && (
                <div role="group" aria-labelledby="doctor's gender">
                  <div className="row" id="doctor's gender">
                    <div className="col-xs-12">
                      <label className="static-label-header" htmlFor="doctorsGender">
                        <FormattedMessage {...messages.doctorsGender} />
                      </label>
                    </div>
                  </div>

                  {genderFilters && (
                    <FilterGroup
                      id="doctorsGender"
                      value={this.state[genderFilters.key]}
                      intlEnum={genderFilters.options}
                      qsKey={genderFilters.key}
                      onChange={this.onFilterGroupChange}
                      defaultValue={genderFilters.defaultValue}
                      wrapClass="row tiny-gutter gender-options"
                      singleOptionClass="btn btn-small btn-outline text-semibold"
                      marginBetweenOptions={2}
                      equalBtnSize
                    />
                  )}
                </div>
              )}

              {/*  language */}
              {languageFilterOn && (
                <div role="group" aria-labelledby="language">
                  <div className="row" id="language">
                    <div className="col-xs-12">
                      <label className="static-label-header" htmlFor="doctorsLanguage">
                        <FormattedMessage {...messages.doctorsLanguage} />
                      </label>
                    </div>
                  </div>

                  {languageFilters && (
                    <FilterGroup
                      id="doctorsLanguage"
                      value={this.state[languageFilters.key]}
                      intlEnum={languageFilters.options}
                      qsKey={languageFilters.key}
                      onChange={this.onFilterGroupChange}
                      defaultValue={languageFilters.defaultValue}
                      wrapClass="row tiny-gutter language-options"
                      singleOptionClass="btn btn-small btn-outline text-semibold"
                      marginBetweenOptions={2}
                      equalBtnSize
                    />
                  )}
                </div>
              )}
            </div>
          </div>

          {/* <div className="row"> */}
          {/* <div className="col-xs-12"> */}
          {/* <label className="static-label-header"> */}
          {/* <FormattedMessage {...messages.showMe} /> */}
          {/* </label> */}
          {/* </div> */}
          {/* </div> */}
          {/* <div className="vim-choice-section margin-bottom-clean-form"> */}
          {/* <div className="row"> */}
          {/* <div className="col-sm-6 col-xs-9"> */}
          {/* <div className="text-18 text-dark"> */}
          {/* <FormattedMessage {...messages.vimChoiceDoctorsOnly} /> */}
          {/* </div> */}
          {/* <div className="text-14"> */}
          {/* <FormattedMessage {...messages.vimChoiceDoctorsAreHighlyRated} /> */}
          {/* </div> */}
          {/* </div> */}
          {/* <div className="col-sm-6 col-xs-3"> */}
          {/* <CleanSwitch className="pull-right" value={this.state.vimChoice} */}
          {/* onCahnge={this.toggleVim} /> */}
          {/* </div> */}
          {/* </div> */}
          {/* </div> */}

          <div className="dialog-footer no-padding">
            <div className="row tiny-gutter filter-footer-btns">
              <div className="col-xs-6">
                <button className="btn btn-big btn-light-grey pull-left" onClick={onClose}>
                  <FormattedMessage {...messages.cancel} />
                </button>
              </div>
              <div className="col-xs-6">
                <SpinnerButton
                  isLoading={isGeocoding && wasApplied}
                  className="btn btn-big bg-color-brand-button pull-right"
                  onClick={this.onApply}
                >
                  <FormattedMessage {...messages.apply} />
                </SpinnerButton>
              </div>
            </div>
          </div>
        </div>
      </BkmdModal>
    );
  }
}

export default FilterModal;
