import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import {
  compose,
  withHandlers,
  withPropsOnChange,
  withStateHandlers,
  branch,
  renderNothing,
} from 'recompose';
import classNames from 'classnames';

import displayProvider from 'Utils/displayProvider';
import Formatter from 'Utils/formatter/index';
import getProviderPhoneNumber from 'Utils/getProviderPhoneNumber';
import { getLocationFromProvider, getProviderLocationId } from 'Utils/providerLocationAccess';

import * as BookingType from 'Model/enum/bookingType';

import ClickableDiv from 'Components/clickableElement/clickableDiv';
import BookingButton from 'Components/bookingButton/bookingButton';

import './FullProviderCard.less';
import './MiniCard.less';

import GetDirectionsLink from '../../atoms/GetDirectionsLink/GetDirectionsLink';
import DistanceChip from '../../atoms/DistanceChip/DistanceChip';
import AcceptNewPatients from '../../molecules/AcceptNewPatients/AcceptNewPatients';
import BookingHorizonChip from '../../atoms/BookingHorizonChip/BookingHorizonChip';
import NetworkStatusChip from '../../atoms/NetworkStatusChip/NetworkStatusChip';
import ProviderLocationSelect from '../../molecules/ProviderLocationSelect/ProviderLocationSelect';
import ProviderLogo from '../../molecules/ProviderLogo/ProviderLogo';
import NextAvailable from '../../molecules/NextAvailable/NextAvailable';
import Text from '../../atoms/Text/Text';

const imgSize = {
  medium: 'doc-img-72',
  big: 'doc-img-80',
  default: '',
};

const getDistance = (distances, provider, currentLocation) =>
  distances
    ? distances[provider.npi][currentLocation.id]
    : _.get(provider, `distances[${currentLocation.id}]`, currentLocation.distance);

// TODO: Add geo(!) + client location
function FullProviderCard(props) {
  const {
    provider,
    onProviderClick,
    chosenTaxonomyCodes,
    currentLocation,
    setCurrentLocation,
    distances,
    clientGeo,
    plan,
    bookingType,
    hideNetworkStatus,
    onlyShowCurrentLocation,
    renderActions,
    showBook,
    staticLocation,
    onClick,
    memberContext,
    miniCard,
    docImgSize,
    noShadow,
    className,
    showDirection,
    showNextAvailableSlot,
    showOfficeName,
    isHighValueBadgeEnable,
    showBookingHorizonChip,
    hideAcceptNewPatients,
    hideUnverified,
    showSpecialtyDescription,
    tabIndex,
  } = props;

  const {
    firstName = 'Unknown',
    lastName = 'Provider',
    sources,
    taxonomies,
    specialties,
    suffix,
    locations,
    officeName,
  } = provider;

  const providerLogoData = {
    ...provider,
    firstName: _.get(provider, 'firstName', 'Unknown'),
    lastName: _.get(provider, 'lastName', 'Provider'),
  };

  const providerDisplayName = displayProvider(firstName, lastName, suffix);
  const title = showOfficeName ? officeName : providerDisplayName;
  const taxonomyName = Formatter.truncate(
    Formatter.taxonomy(
      sources,
      taxonomies || specialties,
      chosenTaxonomyCodes,
      showSpecialtyDescription,
    ),
  );
  // TODO refactor the response of public referral and public share
  const distance = getDistance(distances, provider, currentLocation);
  const otherLocations = onlyShowCurrentLocation
    ? []
    : _.reject(locations, location => currentLocation.id === location.id);
  const phoneNumber = getProviderPhoneNumber(currentLocation, memberContext);
  const hideShadow = noShadow ? 'no-shadow' : undefined;
  const nextAvailableSlot = _.get(currentLocation, 'nextAvailableSlot');

  return (
    <ClickableDiv disabled={!onProviderClick} clickHandler={onClick} tabIndex={tabIndex}>
      {miniCard ? (
        <div
          className={classNames(
            'card mini-doctor-card inline-block',
            hideShadow,
            imgSize[docImgSize],
            className,
          )}
        >
          <div className="card-body">
            <div className="doctor-pic">
              <ProviderLogo
                provider={providerLogoData}
                showOfficeName={showOfficeName}
                isHighValueBadgeEnable={isHighValueBadgeEnable}
              />
            </div>
            <div className="doctor-info">
              <div className="dr-name text-16 text-semibold text-dark">{title}</div>
              <div className="text-14">{taxonomyName}</div>
              <div className="address">
                <Text className="facility-name" weight="bold" size="12px" align="left" inline>
                  {provider.officeName}
                </Text>
                <Text size="12px" align="left" inline>
                  {provider.address}
                </Text>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          className={classNames(
            'card vertical-provider-card',
            hideShadow,
            imgSize[docImgSize],
            className,
          )}
        >
          <div className="card-body text-center">
            <div
              className={classNames('card-main-content', {
                'card-main-with-badge': props.provider.careTeam,
              })}
            >
              <ProviderLogo
                provider={props.provider}
                showOfficeName={showOfficeName}
                isHighValueBadgeEnable={isHighValueBadgeEnable}
              />
              <div className="margin-top text-16 text-semibold text-dark">
                {providerDisplayName}
              </div>
              <div className="text-14">{taxonomyName}</div>
              <div className="info-row">
                <div className="detail">
                  <DistanceChip distance={distance} p1={currentLocation.geo} p2={clientGeo} />
                </div>
                {!hideNetworkStatus ? (
                  <span className="detail">
                    <NetworkStatusChip
                      location={currentLocation}
                      plan={plan}
                      hideUnverified={hideUnverified}
                    />
                  </span>
                ) : (
                  <span />
                )}
                {showNextAvailableSlot && nextAvailableSlot && (
                  <NextAvailable nextAvailable={nextAvailableSlot} />
                )}
                {!hideAcceptNewPatients && (
                  <div className="detail">
                    <div className="availability-section tiny">
                      <AcceptNewPatients
                        acceptNewPatients={currentLocation.acceptNewPatients}
                        textClass="item-text"
                        itemClass="availability-item new-patients"
                      />
                    </div>
                  </div>
                )}
                {showBookingHorizonChip && (
                  <span className="detail">
                    <BookingHorizonChip bookingHorizon={currentLocation.bookingHorizon} shortText />
                  </span>
                )}
              </div>
              <div className="margin-top text-dark text-semibold text-16">
                <ProviderLocationSelect
                  staticLocation={staticLocation}
                  pickedLocation={currentLocation}
                  otherLocations={otherLocations}
                  onLocationChanged={setCurrentLocation}
                />
              </div>
              <div className="font-color-brand-link">
                {currentLocation && showDirection && (
                  <div className="margin-top">
                    <GetDirectionsLink location={currentLocation} />
                  </div>
                )}
              </div>
              {bookingType === BookingType.NONE || !showBook ? (
                <div className="margin-top-20 margin-bottom-20 text-16 text-dark text-semibold">
                  {phoneNumber && (
                    <span className="text-15 text-dark">{Formatter.formatLocal(phoneNumber)}</span>
                  )}
                </div>
              ) : (
                <div className="margin-top-20">
                  <div className="text-16 text-dark text-semibold margin-bottom-20">
                    {phoneNumber && Formatter.formatLocal(phoneNumber)}
                  </div>

                  <div className="col-xs-6 booking-btn-wrap">
                    <BookingButton
                      enableBooking={showBook}
                      pickedLocation={currentLocation}
                      onBookAppointmentClicked={props.onBookClick}
                    />
                  </div>
                </div>
              )}
            </div>
            {renderActions && renderActions(props.provider, currentLocation, setCurrentLocation)}
          </div>
        </div>
      )}
    </ClickableDiv>
  );
}

FullProviderCard.propTypes = {
  provider: PropTypes.object.isRequired,
  clientGeo: PropTypes.object,
  onClick: PropTypes.func.isRequired,
  currentLocation: PropTypes.object.isRequired,
  setCurrentLocation: PropTypes.func.isRequired,
  onBookClick: PropTypes.func.isRequired,
  plan: PropTypes.string,
  distances: PropTypes.object,
  onProviderClick: PropTypes.func,
  chosenTaxonomyCodes: PropTypes.array,
  onlyShowCurrentLocation: PropTypes.bool,
  bookingType: PropTypes.string,
  hideNetworkStatus: PropTypes.bool,
  renderActions: PropTypes.func,
  showBook: PropTypes.bool,
  staticLocation: PropTypes.bool,
  memberContext: PropTypes.string,
  miniCard: PropTypes.bool,
  docImgSize: PropTypes.string,
  noShadow: PropTypes.bool,
  className: PropTypes.string,
  showDirection: PropTypes.bool,
  showNextAvailableSlot: PropTypes.bool,
  showOfficeName: PropTypes.bool,
  isHighValueBadgeEnable: PropTypes.bool,
  showBookingHorizonChip: PropTypes.bool,
  hideAcceptNewPatients: PropTypes.bool,
  hideUnverified: PropTypes.bool,
  showSpecialtyDescription: PropTypes.bool,
  tabIndex: PropTypes.string,
};

FullProviderCard.defaultProps = {
  onlyShowCurrentLocation: false,
  renderActions: null,
  onProviderClick: undefined,
  distances: undefined,
  chosenTaxonomyCodes: undefined,
  miniCard: false,
  docImgSize: 'doc-img-72',
  noShadow: '',
  plan: '',
  bookingType: BookingType.NONE,
  hideNetworkStatus: false,
  showBook: false,
  staticLocation: false,
  memberContext: undefined,
  className: undefined,
  showDirection: true,
  showNextAvailableSlot: false,
  showOfficeName: false,
  isHighValueBadgeEnable: false,
  clientGeo: undefined,
  showBookingHorizonChip: true,
  hideAcceptNewPatients: false,
  hideUnverified: false,
  showSpecialtyDescription: false,
  tabIndex: 0,
};

const getClosestLocationId = function getClosestLocationId(provider, closestLocations) {
  // TODO refactor the response of public referral and share from atlas
  const closestLocation = closestLocations
    ? closestLocations[provider.npi]
    : provider.closestLocation;
  return closestLocation && parseInt(closestLocation, 10);
};

const withLocationSelection = compose(
  withStateHandlers(
    ({ closestLocations, provider }) => ({
      currentProviderLocationId:
        _.get(provider, 'selectedLocationId') ||
        getClosestLocationId(provider, closestLocations) ||
        _.get(provider, 'locations[0].id'),
    }),
    {
      setCurrentLocation: (state, { onLocationChanged }) => value => {
        if (onLocationChanged) {
          onLocationChanged(value);
          return {};
        }
        return { currentProviderLocationId: value };
      },
    },
  ),
  withPropsOnChange(
    ['provider', 'currentProviderLocationId', 'bookingType'],
    ({ currentProviderLocationId, provider }) => {
      const currentLocation =
        provider.selectedLocation ||
        _.chain(getProviderLocationId(currentProviderLocationId, provider))
          .thru(selectedLocationId => getLocationFromProvider(selectedLocationId, provider))
          .defaultTo(_.get(provider, 'locations[0]', {}))
          .value();

      return {
        currentLocation,
        bookingType: currentLocation.bookingType || provider.bookingType,
      };
    },
  ),
);

const enhance = compose(
  branch(({ provider }) => _.isNil(provider), renderNothing),
  withLocationSelection,
  withHandlers({
    onClick: ({
      onProviderClick,
      provider: { npi },
      currentProviderLocationId,
      chosenTaxonomyCodes,
    }) => () => {
      onProviderClick(npi, currentProviderLocationId, chosenTaxonomyCodes);
    },
    onBookClick: ({ provider, onBookClick, bookingType, currentProviderLocationId }) => e => {
      e.stopPropagation();
      onBookClick(provider, currentProviderLocationId, bookingType);
    },
  }),
)(FullProviderCard);

enhance.propTypes = {
  provider: PropTypes.object.isRequired,
  closestLocations: PropTypes.array,
  plan: PropTypes.string,
  distances: PropTypes.object,
  clientGeo: PropTypes.object,
  onProviderClick: PropTypes.func,
  chosenTaxonomyCodes: PropTypes.array,
  onBookClick: PropTypes.func,
  onlyShowCurrentLocation: PropTypes.bool,
  bookingType: PropTypes.string,
  hideNetworkStatus: PropTypes.bool,
  renderActions: PropTypes.func,
  showBook: PropTypes.bool,
  staticLocation: PropTypes.bool,
  memberContext: PropTypes.string,
  miniCard: PropTypes.bool,
  docImgSize: PropTypes.string,
  noShadow: PropTypes.bool,
  className: PropTypes.string,
  horizontal: PropTypes.bool,
};

enhance.defaultProps = {
  closestLocations: undefined,
  plan: '',
  distances: undefined,
  onProviderClick: undefined,
  onLocationChanged: undefined,
  chosenTaxonomyCodes: undefined,
  onBookClick: _.noop,
  onlyShowCurrentLocation: false,
  bookingType: BookingType.NONE,
  hideNetworkStatus: false,
  renderActions: null,
  showBook: false,
  staticLocation: false,
  memberContext: undefined,
  miniCard: false,
  docImgSize: 'doc-img-72',
  noShadow: false,
  className: undefined,
  horizontal: false,
  clientGeo: undefined,
};

export default enhance;
