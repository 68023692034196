/**
 * Created by asafdavid on 08/01/2018.
 */
import classNames from 'classnames';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ClickableDiv from '../../clickableElement/clickableDiv';

const Noop = () => null;


class PreviousButton extends PureComponent {
  static propTypes = {
    style: PropTypes.object,
    className: PropTypes.string,
    hasPrevious: PropTypes.bool,
    onClick: PropTypes.func,
  };

  static defaultProps = {
    style: {},
    className: '',
    hasPrevious: false,
    onClick: Noop,
  };

  render() {
    const { onClick, hasPrevious, className, style } = this.props;
    if (!hasPrevious) return null;

    return (
      <ul className="pagination bkmd-pagination previous">
        <li>
          <ClickableDiv clickHandler={onClick} style={style} className={classNames(className, 'pagination-arrow-vim')}>
            <i className="icon-chevron-left item-arrow" />
          </ClickableDiv>
        </li>
      </ul>
    );
  }
}

export default PreviousButton;
