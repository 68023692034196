import pcpSelectionRoutes from '../pcpSelectionRoutes';

class PublicPCPSelectionRoutesStrategy {
  static predicate() {
    return true;
  }

  static getRoutes() {
    return [...pcpSelectionRoutes];
  }
}

export default PublicPCPSelectionRoutesStrategy;
