import _ from 'lodash';
import * as SearchTypes from '../../../../model/enum/searchTypes';

export const TypeaheadBeingInitiated = 'Typeahead being initiated';
export const TypeaheadContentEntered = 'Typeahead content entered';
export const TypeaheadClick = 'Typeahead click';
export const TypeaheadNoResults = 'Typeahead no results';
export const TypeaheadFailed = 'Typeahead failed';

export default {
  contentEntered: ({ results, type, term, memberSessionId, message } = {}) => {
    if (message) {
      return [TypeaheadContentEntered,
        {
          memberSessionId,
          term,
          message,
        },
      ];
    }
    if (_.isEmpty(results)) {
      return [TypeaheadNoResults, {
        memberSessionId,
        type,
        term,
        [type === SearchTypes.PROVIDER ? 'physicians' : 'taxonomies']: results,
      }];
    }
    return [TypeaheadContentEntered, {
      memberSessionId,
      type,
      term,
      taxonomies: type !== SearchTypes.PROVIDER ?
        _.map(results, currRes => ({
          searchTerm: _.get(currRes, 'searchTerm'),
          codes: _.chain(currRes)
          .get('filters')
          .map('value.taxonomyCode')
          .value(),
        })) : undefined,
      physicians: type === SearchTypes.PROVIDER ?
        _.map(results, currProvider => _.pick(currProvider, ['npi', 'firstName', 'lastName'])) :
        undefined,
    }];
  },
  clicked: ({ searchTerm, type, data = {}, memberSessionId } = {}) => [
    TypeaheadClick,
    {
      searchTerm,
      type,
      npi: type === SearchTypes.PROVIDER ? data.value : undefined,
      codes: type !== SearchTypes.PROVIDER ? data.value : undefined,
      memberSessionId,
    },
  ],
  failed: ({ memberSessionId, error, type, insurer, term } = {}) => [
    TypeaheadFailed,
    {
      memberSessionId,
      errorMessage: _.get(error, 'responseMessage'),
      errorStatus: _.get(error, 'status'),
      type,
      insurer,
      term,
    }
  ],
};
