/**
 * Created by galgoltzman on 19/06/2017.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown, MenuItem } from 'react-bootstrap';
import { defineMessages, FormattedMessage } from 'react-intl';
import { Link } from 'react-router';
import { autobind } from 'core-decorators';

import FeatureFlagged from '../../../../../components/features/featureFlagged';

import './myHealthMenu.less';

const messages = defineMessages({
  careCues: {
    defaultMessage: 'Care cues',
    id: 'members.userMenu.careCues',
  },
  medications: {
    defaultMessage: 'Medications',
    id: 'members.userMenu.medications',
  },
  myHealth: {
    defaultMessage: 'My health',
    id: 'members.userMenu.myHealth',
  },
});

@autobind
class myHealthMenu extends React.PureComponent {
  static propTypes = {
    mobileMenuOpen: PropTypes.func.isRequired,
    isMobileScreen: PropTypes.bool.isRequired,
    onSelected: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired,
  };

  static contextTypes = {
    paths: PropTypes.object.isRequired,
  };

  backToMenu(notRelevant, e) {
    if (this.props.isMobileScreen) {
      e.stopPropagation();
      this.props.mobileMenuOpen(true);
    }
  }

  render() {
    const { paths } = this.context;
    const { id } = this.props;
    return (
      <Dropdown id={id} className="my-health-menu xs-fullscreen-dropdown">
        <Dropdown.Toggle bsStyle="inline-link text-semibold my-health-menu-toggle">
          <FeatureFlagged uri="layout.myHealthMenu.notificationIcon">
            <div className="notification" />
          </FeatureFlagged>
          <i className="icon-heart-vim-e hidden-xs" />
          <FormattedMessage {...messages.myHealth} />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <MenuItem className="dropdown-header visible-xs" onSelect={this.backToMenu}>
            <span className="text-18 text-semibold text-main">
              <FormattedMessage {...messages.myHealth} />
              <i className="icon-back-arrow dropdown-back-btn left" />
            </span>
          </MenuItem>
          {paths.careCues && (
            <MenuItem onClick={this.props.onSelected}>
              <Link to={paths.careCues()}>
                <i className="icon-speak-heart item-icon" />
                <FormattedMessage {...messages.careCues} />
              </Link>
            </MenuItem>
          )}
          {paths.medicationHistory && (
            <MenuItem onClick={this.props.onSelected}>
              <Link to={paths.medicationHistory()}>
                <i className="icon-medication item-icon" />
                <FormattedMessage {...messages.medications} />
              </Link>
            </MenuItem>
          )}
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}

export default myHealthMenu;
