import _ from 'lodash';
import * as NetworkStatus from '../../../../model/enum/networkStatus';

export function isInNetwork(networks, insurancePlanDescription) {
  if (_.isEmpty(insurancePlanDescription) || _.isEmpty(networks)) {
    return NetworkStatus.UNVERIFIED_NETWORK;
  }

  const lowerCaseNetworks = _.map(networks, plan =>
    ({ insurancePlanId: _.toLower(plan.insurancePlanId) }));

  return _.find(lowerCaseNetworks, { insurancePlanId: _.toLower(insurancePlanDescription) })
    ? NetworkStatus.IN_NETWORK
    : NetworkStatus.OUT_OF_NEWTORK;
}
