import { autobind } from 'core-decorators';
import ServicesApi from './services';
import BkmdApi from './bkmdApi';

const SERVICE_NAME = 'appointmentManager';

@autobind
export default class AppointmentRequestApi extends BkmdApi {
  constructor(fetch) {
    super(fetch, '/appointmentRequest/');
    this.servicesApi = new ServicesApi(fetch);
  }

  updateAppointmentRequest(data) {
    return this.servicesApi.callSecure(SERVICE_NAME, 'updateAppointmentRequest', { data });
  }

  deleteAppointmentRequest(id) {
    return this.servicesApi.callSecure(SERVICE_NAME, 'deleteAppointmentRequest', { id });
  }

  getAllAppointmentRequests(domain, skip, limit, search, sort, sortAscending) {
    return this.servicesApi.callSecure(SERVICE_NAME, 'getAllAppointmentRequests', {
      domain,
      skip,
      limit,
      search,
      sort,
      sortAscending,
    });
  }

  getAppointmentRequestById(id) {
    return this.servicesApi.callSecure(SERVICE_NAME, 'getAppointmentRequestById', { id });
  }

  checkAndUpdateAppointmentRequestStatus(id) {
    return this.servicesApi.callSecure(SERVICE_NAME, 'checkAndUpdateAppointmentRequestStatus', {
      id,
    });
  }

  resendEmailRequest(id, domain) {
    return this.servicesApi.callSecure(
      SERVICE_NAME,
      'resendEmailRequest',
      {
        id,
      },
      domain,
    );
  }

  attachAppointmentToRequest(params) {
    const { appointmentRequestId } = params;
    return this.post(`/${appointmentRequestId}/attachAppointmentToRequest`, params).then(
      payload => payload.data,
    );
  }

  // Allowed Providers Appointment Request
  createAllowedProvider(data) {
    return this.servicesApi.callSecure(SERVICE_NAME, 'createAllowedProvider', data);
  }

  updateAllowedProvider(data) {
    return this.servicesApi.callSecure(SERVICE_NAME, 'updateAllowedProvider', data);
  }

  turnAllowedProviders(data) {
    return this.servicesApi.callSecure(SERVICE_NAME, 'turnAllowedProviders', data);
  }

  deleteAllowedProvider(id) {
    return this.servicesApi.callSecure(SERVICE_NAME, 'deleteAllowedProvider', { id });
  }

  getAllAllowedProviders(domain, skip, limit, search, sort, sortAscending) {
    return this.servicesApi.callSecure(SERVICE_NAME, 'getAllAllowedProviders', {
      domain,
      skip,
      limit,
      search,
      sort,
      sortAscending,
    });
  }

  isRequestConfirmed({ requestToken }) {
    return this.servicesApi.call(SERVICE_NAME, 'isRequestConfirmed', {
      requestToken,
    });
  }

  requestConfirmation({ requestToken, selectedTime }) {
    return this.servicesApi.call(SERVICE_NAME, 'requestConfirmation', {
      requestToken,
      selectedTime,
    });
  }

  requestNoApptScheduled({ requestToken, noAppointmentReason }) {
    return this.servicesApi.call(SERVICE_NAME, 'requestNoApptScheduled', {
      requestToken,
      noAppointmentReason,
    });
  }

  requestBookOtherAppointment({ requestToken, selectedNpi, selectedTime }) {
    return this.servicesApi.call(SERVICE_NAME, 'requestBookOtherAppointment', {
      requestToken,
      selectedNpi,
      selectedTime,
    });
  }

  getRequestProvidersData({ requestToken }) {
    return this.servicesApi.call(SERVICE_NAME, 'getRequestProvidersData', {
      requestToken,
    });
  }
}
