import { autobind } from 'core-decorators';
import BkmdApi from './bkmdApi';
import ServicesApi from './services';

const SERVICE_NAME = 'eligibility';

@autobind
export default class EligibilityApi extends BkmdApi {
  constructor(fetch) {
    super(fetch, '/eligibility');
    this.servicesApi = new ServicesApi(fetch);
  }

  getYourPlan(payload) {
    return this.get('/plan', payload);
  }

  getInsurers() {
    return this.servicesApi.call(SERVICE_NAME, 'getAllInsurers', {});
  }

  getInsurerByPrefix(prefix) {
    return this.servicesApi.callSecure(SERVICE_NAME, 'getInsurerByPrefix', { prefix });
  }
}
