import React from 'react';
import { pure } from 'recompose';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { messages } from '../../../messages/searchDoctorResults/messages';

const FilterButton = ({ onClick }) => (
  <span>
    <button className="filter-btn btn btn-transparent text-dark" onClick={onClick}>
      <i className="icon-filter-sliders text-18 i-va-fix-2" />
      <span className="text-16">
        <FormattedMessage {...messages.filter} />
      </span>
    </button>
  </span>
);

FilterButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default pure(FilterButton);
