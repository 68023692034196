/**
 * Created by guyavarham on 25/12/2017.
 */

import Joi from 'joi-browser';
import {
  ReduxFormCleanInput,
  ReduxFormCleanDateInput,
  ReduxFormCleanAlphabet,
} from '../../../../../../components/form/reduxForm/components';

import { DateOfBirthSchema } from '../../../../../../utils/commonSchema';
import { fullName } from '../../../../../../utils/joiSchemes';

export const name = 'provider-addAppointment-findPatient';

export const fields = {
  fullName: {
    name: 'fullName',
    component: ReduxFormCleanAlphabet,
  },
  dateOfBirth: {
    name: 'dateOfBirth',
    component: ReduxFormCleanDateInput,
  },
  insuranceId: {
    name: 'insuranceId',
    component: ReduxFormCleanInput,
  },
};

export const schema = Joi.object().keys({
  fullName,
  dateOfBirth: DateOfBirthSchema.required().options({
    language: {
      any: {
        required: '!!Please enter your date of birth MM/DD/YYYY',
      },
      date: {
        base: '!!Please enter your date of birth MM/DD/YYYY',
        max: '!!Please enter your date of birth MM/DD/YYYY',
      },
    },
  }),
  insuranceId: Joi.string()
    .required()
    .options({
      language: {
        any: { required: '!!Insurance Id is required' },
      },
    }),
});
