import { combineReducers } from 'redux';
import { intlReducer } from 'react-intl-redux';
import { responsiveStateReducer } from 'redux-responsive';

import * as commonReducers from '../../../../store/app/commonReducers';

import activation from '../../../../store/activation/reducer';
import files from '../../../../store/file/reducer';
import directory from '../../../../store/directory/reducer';
import location from '../../../../store/location/reducer';
import member from '../../../../store/member/reducer';
import reviewProcess from '../../../../store/collectReview/reducer';
import platform from '../../../../store/platform/reducer';
import help from '../../../../store/help/reducer';
import { reducer as podrick } from '../../../../store/app/podrick';
import facilitySearch from '../../../../store/facilitySearch/reducer';
import reviews from '../../../../store/reviews/reducer';
import familyActivation from '../../../../store/familyActivation/reducer';
import appointments from '../../../../store/appointments/reducer';
import vimGuide from '../../../../store/vimGuide/reducer';
import recaptcha from '../../../../store/recaptcha/reducer';
import booking from '../../../../store/booking/reducer';
import flows from '../../../../components/wizard/flow/reducer';
import share from '../../../../store/share/reducer';
import insurers from '../../../../store/insurers/reducer';

export default combineReducers({
  ...commonReducers,
  intl: intlReducer,
  browser: responsiveStateReducer,
  activation,
  files,
  directory,
  location,
  member,
  reviewProcess,
  platform,
  help,
  podrick,
  facilitySearch,
  reviews,
  familyActivation,
  recaptcha,
  appointments,
  booking,
  vimGuide,
  share,
  flows,
  insurers,
});
