import React from 'react';
import { autobind } from 'core-decorators';
import _ from 'lodash';
import { compose } from 'redux';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { branch } from 'recompose';
import PropTypes from 'prop-types';
import momentz from 'moment-timezone';
import moment from 'moment';
import { defineMessages, FormattedMessage, FormattedHTMLMessage } from 'react-intl';

import { getSupportPhoneNumber } from '../../../../../store/config/selectors';
import Layout from '../../layout/layout';
import MiniDoctorCard from '../../../../../components/miniDoctorCard/miniDoctorCard';
import Formatter from '../../../../../utils/formatter';
import CommonFormats from '../../../../../utils/formatter/commonFormats';
import paths from '../memberAppointmentsPaths';
import * as AppointmentStatus from '../../../../../model/enum/appointmentStatus';
import requireActivation from '../../../../../components/activationLanding/requireActivation';
import LoadingPage from '../loadingPage';
import { loadingUntil } from '../../../../../components/recompose/loadingUntil';
import appointmentsEvents from '../analytics/appointmentsEvents';
import { getFeature } from '../../../../../store/feature/selectors';
import { PREMERA_PEAK_CARE } from '../../../../../model/enum/subdomain';

const messages = defineMessages({
  yourAppointmentDetails: {
    defaultMessage: 'Your appointment details',
    id: 'members.bookedApptDetails.yourAppointmentDetails',
  },
  appointmentCancelled: {
    defaultMessage: 'Appointment cancelled',
    id: 'members.bookedApptDetails.appointmentCancelled',
  },
  itsMyFirstVisit: {
    defaultMessage: 'It’s my first visit',
    id: 'members.bookedApptDetails.itsMyFirstVisit',
  },
  cancelThisAppointment: {
    defaultMessage: 'Cancel this appointment',
    id: 'members.bookedApptDetails.cancelThisAppointment',
  },
  wantToReschedule: {
    defaultMessage: 'Want to reschedule?',
    id: 'members.bookedApptDetails.wantToReschedule',
  },
  toReschedule: {
    defaultMessage:
      "To reschedule, you'll need to cancel your appointment first, then book " +
      'a new appointment.',
    id: 'members.bookedApptDetails.toReschedule',
  },
  youCanAlsoCallUsAt: {
    defaultMessage: 'You can also call us at<br />{supportPhoneNumber}',
    id: 'members.bookedApptDetails.youCanAlsoCallUsAt',
  },
  appointmentTypeDescription: {
    defaultMessage: '{minutes} min',
    id: 'members.bookedApptDetails.appointmentTypeDescription',
  },
  dateAndTime: {
    defaultMessage: 'Date and time',
    id: 'members.bookedApptDetails.dateAndTime',
  },
  callToCancel: {
    defaultMessage: 'To cancel or reschedule please call: </br> {cancellationPhoneNumber}', // TODO: pass params when needed.
    id: 'members.bookedApptDetails.callSupport',
  },
});

export const isCancelledFunc = function isCancelledFunc(appointment) {
  const { status } = appointment;
  return _.includes([AppointmentStatus.CANCELED, AppointmentStatus.ARCHIVED], status);
};

@autobind
class BookedApptDetails extends React.PureComponent {
  static propTypes = {
    router: PropTypes.object.isRequired,
    appointment: PropTypes.object.isRequired,
    supportPhoneNumber: PropTypes.string.isRequired,
    memberContext: PropTypes.string.isRequired,
  };

  static contextTypes = {
    analytics: PropTypes.object.isRequired,
  };

  componentDidMount() {
    const { appointment } = this.props;
    const { analytics } = this.context;
    analytics.track(appointmentsEvents.details.appointmentDetailsVisit, {
      ...appointment,
    });
  }

  cancelAppointment() {
    const { appointment, router } = this.props;
    router.push(paths.cancel(appointment.id));
  }

  renderCancel() {
    const { cancellationPhoneNumber, cancelByPhone } = this.props;
    return cancelByPhone ? (
      <FormattedHTMLMessage {...messages.callToCancel} values={{ cancellationPhoneNumber }} />
    ) : (
      <div>
        <div className="text-center">
          <button
            className="btn btn-inline-link blue-link text-14"
            onClick={this.cancelAppointment}
          >
            <FormattedMessage {...messages.cancelThisAppointment} />
          </button>
        </div>

        <div className="margin-top-30 text-center text-16 text-semibold text-dark">
          <FormattedMessage {...messages.wantToReschedule} />
        </div>
        <div className="text-14 text-center">
          <FormattedHTMLMessage {...messages.toReschedule} />
        </div>
      </div>
    );
  }

  renderAppointmentDetails(appointment, supportPhoneNumber) {
    const { startTime, endTime, provider, timeZone, address, appointmentType } = appointment;
    const isCancelled = isCancelledFunc(appointment);
    const titleMessage = isCancelled
      ? messages.appointmentCancelled
      : messages.yourAppointmentDetails;
    const duration = moment(endTime).diff(startTime, 'm');
    return (
      <Layout externalPageLogo footerClassName="white" showRightNavInMobile>
        <div className="container container-1040">
          <div className="row no-margin has-footer">
            <div className="col-sm-6 col-sm-offset-3">
              <div className="padding-h-20">
                <h1 className="title-24 text-strong-main md-margin text-center">
                  <FormattedMessage {...titleMessage} />
                </h1>
                <div className="text-center">
                  <MiniDoctorCard
                    containerClassName="no-shadow name-break-rows no-pointer"
                    provider={provider}
                    docImgSize="doc-img-72"
                  />
                </div>
                <ul className="right-icons-details-list">
                  <li>
                    <h2 className="off-screen-text">
                      <FormattedMessage {...messages.dateAndTime} />
                    </h2>
                    <i className="detail-icon icon-calendar-sm" />
                    <div className="text-16 text-dark text-semibold">
                      {Formatter.date(
                        momentz.tz(startTime, timeZone),
                        CommonFormats.SHORT_NAMING_WITH_YEAR,
                      )}
                    </div>
                    <div className="text-14">{Formatter.time(momentz.tz(startTime, timeZone))}</div>
                  </li>
                  <li>
                    <i className="detail-icon icon-map-pin-e" aria-label="Address" />
                    <div className="text-16 text-dark text-semibold">{address.addressLine1}</div>
                    <div className="text-14">
                      {_.get(address, 'addressLine2', '')}
                      {`${address.city}, ${address.state} ${address.zip}`}
                    </div>
                  </li>
                  <li>
                    <div className="appointment-details">
                      <div className="text-16 text-dark text-semibold">
                        {appointmentType.commonName}
                      </div>
                      <div className="text-14">
                        <FormattedMessage
                          {...messages.appointmentTypeDescription}
                          values={{
                            minutes: duration,
                          }}
                        />
                      </div>
                      <i className="detail-icon icon-user-2" />
                    </div>
                  </li>
                </ul>

                {isCancelled ? null : this.renderCancel(appointment)}
                <br />
                <br />
                <div className="text-14 text-center">
                  <FormattedHTMLMessage
                    {...messages.youCanAlsoCallUsAt}
                    values={{
                      supportPhoneNumber: Formatter.phoneNumber(supportPhoneNumber),
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }

  render() {
    const { appointment, supportPhoneNumber } = this.props;
    return this.renderAppointmentDetails(appointment, supportPhoneNumber);
  }
}

export default compose(
  withRouter,
  connect((state, props) => {
    const appointment = _.get(state, `appointments.${props.params.appointmentId}`);
    const memberContext = _.get(appointment, 'member.memberContext');
    const peakCarePlan = getFeature(state, `planSettings.planByContext[${PREMERA_PEAK_CARE}]`);
    const isPeakCareProvider = _.chain(appointment)
      .get(`provider.locations`)
      .find({ id: _.get(appointment, 'locationId') })
      .get('networks')
      .map('insurancePlanId')
      .includes(peakCarePlan)
      .value();
    return {
      appointment,
      supportPhoneNumber: getSupportPhoneNumber(state),
      allowActivations: getFeature(state, 'activation.allowActivations'),
      cancelByPhone:
        isPeakCareProvider && getFeature(state, 'cancelByPhone', undefined, memberContext),
      cancellationPhoneNumber: getFeature(state, 'cancellationPhoneNumber', false, memberContext),
    };
  }),
  loadingUntil(({ appointment }) => !_.isEmpty(appointment), LoadingPage),
  branch(
    props => props.allowActivations,
    requireActivation(
      ({ appointment }) => paths.landing(appointment.id),
      (state, props) =>
        !_.isEmpty(_.get(state, 'activation.activationToken')) ||
        _.get(props, 'appointment.member.isActivated', false),
    ),
  ),
)(BookedApptDetails);
