/**
 * Created by guyavarham on 14/03/2017.
 */

import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { autobind } from 'core-decorators';

import CleanRadioGroup from '../../../components/form/cleanRadioGroup';
import IntlEnum from '../../../utils/intlEnum';

/**
 * Represents an options group. adds default and handle single option group.
 */
@autobind
class FilterGroup extends React.PureComponent {

  static defaultProps = {
    value: null,
  };

  static propTypes = {
    /**
     * string, number, or boolean
     */
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    intlEnum: PropTypes.instanceOf(IntlEnum).isRequired,
    qsKey: PropTypes.string.isRequired,
    defaultValue: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired,
  };

  componentWillMount() {
    const { value, intlEnum, defaultValue } = this.props;

    // handle defaults
    if (value && _.find(intlEnum, option => _.isEqual(option.value, value))) return;
    this.onChange(defaultValue);
  }

  onChange(selectedMsg) {
    const { intlEnum, qsKey, value, onChange } = this.props;
    if (!selectedMsg) return;

    // handle single option in group
    if (_.keys(intlEnum).length === 1) selectedMsg = !value;

    onChange({ [qsKey]: selectedMsg });
  }

  render() {
    const { intlEnum, value, ...others } = this.props;
    if (_.isNull(value) || _.isUndefined(value)) return null;
    return (
      <CleanRadioGroup
        {...others}
        intlEnum={intlEnum}
        value={value}
        onChange={this.onChange}
      />
    );
  }
}

export default FilterGroup;
