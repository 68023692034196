/* eslint-disable import/prefer-default-export */
/**
 * Created by matan on 3/24/16.
 */
import analyticsjs from 'analytics.js-loader';
import _ from 'lodash';

/**
 * Get react router routes array and returns BookMD style page name based on
 * the given route and names given to the component in the routing schema
 * @param routes - React router route object array
 */
function pageName(routes) {
  return _.chain(routes).map(route => route.name)
    .filter(undefined)
    .join('.')
    .value();
}

/**
 * Get react router routes array and returns false if the page should be tracked based on
 * the given route and names given to the component in the routing schema
 * @param routes - React router route object array
 * @param path - the path of the page we want to check if we should not track
 */
function shouldNotTrack(routes, path) {
  const shouldNotTrackObj = _.chain(routes)
    .map(route => ({ shouldNotTrack: route.shouldNotTrack, path: route.path }))
    .filter(route => (route.path === path || `/${route.path}` === path) &&
      route.shouldNotTrack !== undefined)
    .first()
    .value();

  return shouldNotTrackObj === undefined ? false : shouldNotTrackObj.shouldNotTrack;
}

/**
 * Reports a viewing of an page to analytics platforms.
 * Infer page name from the route array provided
 * @param routes - Array of redux-router routes, as saved in the props.routes.
 * @param path - String of the URL path to report
 */
function reportViewedPage(routes, path, userId, args) {
  if (shouldNotTrack(routes, path) === true) {
    return;
  }

  const attributes = { page: this._getPage(routes, path), userId, ...args };
  this.track(`Viewed ${this.pageName(routes)} Page`, attributes);

  // Check if Appcues is enabled and report to it that a page was viewed
  if (window.Appcues) {
    window.Appcues.start();
  }

  // UPDATE - Currently do not send to sift
  // Send event to Sift Science
  // siftPageview(userId);
}

function manualPageReport(pageName) {
  this.track(`Viewed ${pageName} Page`);
}

/**
 * Reports generic event to analytics platforms.
 * Infer page from the route array provided
 * @param routes - Array of redux-router routes, as saved in the props.routes.
 * @param path - String of the URL path to report
 * @param event - The event name
 * @param entityName - the name of the entity
 */
function genericEvent(routes, path, event, entityName, eventData = {}) {
  if (shouldNotTrack(routes, path) === true) {
    return;
  }

  const attributes = {
    page: this._getPage(routes, path),
    pageName: this.pageName(routes),
    event,
    ...eventData,
    entityName,
  };
  this.track(
    `${attributes.pageName}${entityName ? `: ${entityName}:` : ''} ${event}`,
    attributes);
}

function _getPage(routes, path) {
  if (_.last(routes).reportPage) {
    return _.first(_.last(routes).reportPage.exec(path));
  }
  return undefined;
}

let analyticsInstance;

/**
 * Init an extend the Segment.io analytics object.
 * Method in the file are mixed in in to the object to be used implicitly from the returned object.
 * @param apiKey - Segment.io api key.
 */
export function initAnalyticsReporter(apiKey) {
  const rawAnalytics = analyticsjs({ writeKey: apiKey });
  analyticsInstance = _.assign(rawAnalytics, {
    pageName,
    reportViewedPage,
    manualPageReport,
    genericEvent,
    _getPage });

  analyticsInstance.isReady = false;

  analyticsInstance.ready(() => {
    analyticsInstance.isReady = true;
  });
  return analyticsInstance;
}

export function getAnalyticsReporter() {
  if (!analyticsInstance) throw new Error('analytics reporter instance isn\'t initialized!');
  return analyticsInstance;
}

/* eslint-enable import/prefer-default-export */
