/**
 * Created by matan on 8/21/17.
 */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getFeature } from '../../store/feature/selectors';
import CarrierLogo from './carrierLogo';

import './branding.less';

class CoOpLogo extends React.Component {
  static propTypes = {
    vimLogoClassName: PropTypes.string,
    bookmdBranded: PropTypes.bool.isRequired,
    externalPageLogo: PropTypes.bool.isRequired,
    memberContext: PropTypes.string,
  };

  static defaultProps = {
    className: '',
    vimLogoClassName: '',
    externalPageLogo: false,
    memberContext: undefined,
  };

  render() {
    const { bookmdBranded, vimLogoClassName, externalPageLogo, memberContext } = this.props;
    return (
      <CarrierLogo
        externalPageLogo={externalPageLogo}
        bookmdBranded={bookmdBranded}
        vimLogoClassName={vimLogoClassName}
        memberContext={memberContext}
      />
    );
  }
}

export default connect(state => ({ bookmdBranded: getFeature(state, 'bookmdBranded') }))(CoOpLogo);
