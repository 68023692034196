import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '../../storybook/src/utils/theme';

function SvgEnvelope({ theme }) {
  /* eslint-disable */
  return (
    <svg aria-hidden="true" focusable="false" width="69px" height="47px" viewBox="0 0 69 47" version="1.1" className="svg-icon svg-envelope">
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Forgot-password-04" transform="translate(-153.000000, -155.000000)">
          <g id="Page-1" transform="translate(153.000000, 155.000000)">
            <path d="M65.5,44 L3.5,44 C3.229,44 3,43.771 3,43.5 L3,5.044 L33.628,26.921 C33.889,27.107 34.194,27.2 34.5,27.2 C34.806,27.2 35.111,27.107 35.372,26.921 L66,5.044 L66,43.5 C66,43.771 65.771,44 65.5,44 L65.5,44 Z M63.699,3 L34.5,23.856 L5.301,3 L63.699,3 Z M65.5,0 L3.5,0 C1.57,0 0,1.57 0,3.5 L0,43.5 C0,45.43 1.57,47 3.5,47 L65.5,47 C67.43,47 69,45.43 69,43.5 L69,3.5 C69,1.57 67.43,0 65.5,0 L65.5,0 Z" id="Fill-1" fill={theme.mainColor}></path>
            <path d="M10.2998,38.2002 C9.9158,38.2002 9.5318,38.0542 9.2388,37.7612 C8.6538,37.1752 8.6538,36.2252 9.2388,35.6392 L18.0388,26.8402 C18.6248,26.2542 19.5738,26.2542 20.1598,26.8402 C20.7458,27.4262 20.7458,28.3752 20.1598,28.9612 L11.3608,37.7612 C11.0678,38.0542 10.6838,38.2002 10.2998,38.2002" id="Fill-3" fill={theme.secondaryColor}></path>
            <path d="M58.7002,38.2002 C58.3162,38.2002 57.9322,38.0542 57.6392,37.7612 L48.8402,28.9612 C48.2542,28.3752 48.2542,27.4262 48.8402,26.8402 C49.4262,26.2542 50.3752,26.2542 50.9612,26.8402 L59.7612,35.6392 C60.3462,36.2252 60.3462,37.1752 59.7612,37.7612 C59.4682,38.0542 59.0842,38.2002 58.7002,38.2002" id="Fill-5" fill={theme.secondaryColor}></path>
          </g>
        </g>
      </g>
    </svg>
  );
  /* eslint-enable */
}

SvgEnvelope.propTypes = {
  theme: PropTypes.object.isRequired,
};

export default withTheme(SvgEnvelope);
