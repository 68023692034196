import { autobind } from 'core-decorators';
import BkmdApi from './bkmdApi';

@autobind
export default class NamespaceManagerApi extends BkmdApi {
  constructor(fetch) {
    super(fetch, '/namespaceManager');
  }

  async getNamespaces() {
    return (await this.get('/ns')).data;
  }

  async getSingleTenantNamespaces() {
    return (await this.get('/ns')).data.filter(namespace => namespace.name !== 'multi-tenant-phi');
  }

  async getVirtualNamespaces() {
    return (await this.get('/virtualns')).data;
  }

  async getNamespace(namespace) {
    return (await this.get(`/ns/${namespace}`)).data;
  }

  async getNamespaceConfigs(namespace) {
    return (await this.get(`/configs/${namespace}`)).data;
  }

  createNamespace(payload) {
    return this.post('/add/ns', payload);
  }

  createVirtualNamespace(payload) {
    return this.post('/add/virtualns', payload);
  }

  updateNamespace(payload) {
    return this.post('/update/ns', payload);
  }
}
