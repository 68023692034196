/* eslint-disable react/prop-types */
import React from 'react';
import _ from 'lodash';
import { compose, withProps, setPropTypes, withHandlers, lifecycle } from 'recompose';
import PropTypes from 'prop-types';
import MultiAutoCompleteSelect from '../MultiAutoCompleteSelect/MultiAutoCompleteSelect';
import codeTypeaheadSearch from '../codeInput/codeTypeaheadSearch';
import './codeTypeaheadSearch.less';

const IcdItem = ({ item, remove }) => (
  <div className="card icd-code-item">
    <div className="card-body">
      <div className="text-16 text-semibold text-dark">{item.code}</div>
      <div className="text-14 text-dark margin-top">{item.label}</div>
      <button className="btn btn-inline-link item-delete-btn" onClick={remove}>
        <i className="icon-trash" />
      </button>
    </div>
  </div>
);

const transformInitialSelectionOnMount = lifecycle({
  componentWillMount() {
    this.setState({
      initialSelectedItems: _.map(this.props.initialSelectedItems, code => ({
        code,
        label: '',
      })),
    });
  }
});

export default compose(
  setPropTypes({
    onSelectionChange: PropTypes.func.isRequired,
    initialSelectedItems: PropTypes.arrayOf(PropTypes.string.isRequired),
  }),
  withProps({
    idKey: 'code',
    placeholderText: 'ICD code or description',
    itemComponent: IcdItem,
  }),
  withHandlers({
    onSelectionChange: ({ onSelectionChange }) => selection => {
      const transformedSelection = selection.map(code => code.code);
      onSelectionChange(transformedSelection);
    }
  }),
  transformInitialSelectionOnMount,
  codeTypeaheadSearch('ICD'),
)(MultiAutoCompleteSelect);
