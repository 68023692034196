import _ from 'lodash';
import ActivationRoutes from '../activationRoutes';

class ActivationRouteStrategy {
  static predicate({ features }) {
    return _.get(features, 'activation.allowActivations');
  }

  static getRoutes() {
    return [...ActivationRoutes];
  }
}

export default ActivationRouteStrategy;
