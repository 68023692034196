/**
 * Created by matan on 5/26/16.
 */
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import _ from 'lodash';

import analytics from '../../store/tools/analytics/analyticsHoc';
import CleanSelect from './cleanSelect';
import CommonFormats from '../../utils/formatter/commonFormats';

const OPTION_LABEL_FORMAT = CommonFormats.HALF_TIME;
const TIME_VALUE_FORMAT = CommonFormats.HOURS_24_TIME;

class CleanTimeSelect extends React.PureComponent {
  static propTypes = {
    /**
     * A string in a HH:mm format
     * Default is 00:00
     */
    startTime: PropTypes.string,
    /**
     * A string in a HH:mm format (must be after start time)
     * * Default is 23:30
     */
    endTime: PropTypes.string,
    /**
     * A string in a HH:mm format that the dropdown will start with
     */
    startOption: PropTypes.string,
    /**
     * The number in minutes between each selectable time
     * default is 30 minutes
     */
    interval: PropTypes.number,
    /**
     * A HH:mm string
     */
    value: PropTypes.string,
    /**
     * Called on each change of selection with the moment object
     */
    onChange: PropTypes.func,

    /**
     * if value is available, it automatically scroll to it.
     * If no value selected, scroll to the option "scrollToOption" represents.
     * ex: We have hours from 00:00 am to 11:30 pm,
     * and no time ("value") selected. and we want to scroll to "08:00 am"
     * if we open the timeSelect scroll,
     * the scroll bar will be set to the option provided to "scrollToOption"
     */
    scrollToOption: PropTypes.string,
  };

  static defaultProps = {
    startTime: '00:00',
    endTime: '23:31',
    interval: 30,
    value: undefined,
    startOption: undefined,
    onChange: () => {
    },
  };

  constructor(props) {
    super(props);

    this.getSelections = this.getSelections.bind(this);
    this.selectionChanged = this.selectionChanged.bind(this);
  }

  getSelections() {
    const { startTime, endTime, interval, value, startOption } = this.props;
    const time = moment(startTime, TIME_VALUE_FORMAT);
    const end = moment(endTime, TIME_VALUE_FORMAT);
    const currentTime = value && moment(value, TIME_VALUE_FORMAT);
    let rounded;
    const options = [];
    while (end.isAfter(time)) {
      // Calculate rounded value according to value input and intervals
      if (currentTime && !rounded && (currentTime.isSame(time) || currentTime.isBefore(time))) {
        rounded = time.format(TIME_VALUE_FORMAT);
      }
      options.push({
        label: time.format(OPTION_LABEL_FORMAT),
        value: time.format(TIME_VALUE_FORMAT),
      });
      time.add(interval, 'minutes');
    }
    let sortedOptions = options;
    if (startOption && value === undefined) {
      const optionIndex = _.findIndex(options, option => startOption === option.value);
      const firstSlice = _.slice(options, optionIndex);
      const secondSlice = _.slice(options, 0, optionIndex);
      sortedOptions = _.concat(firstSlice, secondSlice);
    }
    return { options: sortedOptions, rounded };
  }

  selectionChanged(selection) {
    const { onChange } = this.props;

    if (onChange) {
      onChange(selection && selection.value);
    }
  }

  render() {
    const { value, scrollToOption } = this.props;
    const other = _.omit(this.props, ['startTime', 'endTime', 'interval', 'value', 'onChange', 'scrollToOption']);

    const { options, rounded } = this.getSelections();

    // rounded can be undefined if value is out of range. If it does, search for value instead.
    let selection = rounded && _.find(options, option => rounded === option.value);
    selection = !selection ?
      (value && _.find(options, option => value === option.value)) : selection;

    let formattedScrollToOption;
    if (scrollToOption) {
      const mTimeToScrollTo = moment(scrollToOption, OPTION_LABEL_FORMAT);
      formattedScrollToOption = mTimeToScrollTo.format(OPTION_LABEL_FORMAT);
    }

    return (
      <CleanSelect
        value={selection}
        options={options}
        onChanged={this.selectionChanged}
        placeholder=""
        scrollToOption={formattedScrollToOption}
        {...other}
      />
    );
  }
}

export default analytics(CleanTimeSelect, false, [
  { event: 'onChange', eventData: newTime => ({ newTime }) },
]);
