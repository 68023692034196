/**
 * created by moran on 11/26/17
 */

import Immutable from 'seamless-immutable';

import { GET_VIM_GUIDE_BY_USER_ID } from './actions';

const initialState = {
  details: {}
};

export default function vimGuideReducer(state = initialState, action) {
  state = Immutable(state);
  switch (action.type) {
    case GET_VIM_GUIDE_BY_USER_ID.SUCCESS: {
      return state.merge({ details: action.payload });
    }
    default:
      return state;
  }
}
