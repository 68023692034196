/**
 * Created by galgoltzman on 07/02/2017.
 */
import React from 'react';
import PropTypes from 'prop-types';

import './RoundedImage.less';

function RoundedImage({ className, src, alt }) {
  return (
    <div className={className}>
      <img src={src} alt={alt} />
    </div>
  );
}

RoundedImage.propTypes = {
  /**
   * The css class name of the root element.
   */
  className: PropTypes.string,

  /**
   * The image source.
   */
  src: PropTypes.string.isRequired,
  /**
   * The alternative content for the img tag.
   */
  alt: PropTypes.string,
};

RoundedImage.defaultProps = {
  className: 'rounded-img',
  alt: '',
};

export default RoundedImage;
