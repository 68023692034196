import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import BkmdModal from '../../../../../../components/bkmdModal/bkmdModal';
import Wizard from '../../../../../../components/wizard/wizard';
import ShareOptions from './shareOptions';
import ShareDoctorByEmail from './shareDoctorByEmail';
import ShareDoctorBySms from './shareDoctorBySms';

import './shareDoctorModal.less';

class ShareDoctorModal extends React.PureComponent {
  static propTypes = {
    provider: PropTypes.object.isRequired,
    providerLocationId: PropTypes.number.isRequired,
    handleClose: PropTypes.func.isRequired,
    onFinish: PropTypes.func,
    onShareLink: PropTypes.func,
    /**
     * Name for identifying via analytics
     */
    name: PropTypes.string, // eslint-disable-line react/no-unused-prop-types
  };

  static defaultProps = {
    name: 'share_doctor_modal',
    onFinish: _.noop,
    onShareLink: _.noop,
  };

  render() {
    const { provider, providerLocationId, handleClose, onFinish, ...other } = this.props;
    return (
      <BkmdModal handleClose={handleClose} className="fixed-header share-doctor-modal" {...other}>
        <Wizard
          steps={[ShareOptions, ShareDoctorByEmail, ShareDoctorBySms]}
          handleClose={handleClose}
          provider={provider}
          providerLocationId={providerLocationId}
          onFinish={onFinish}
        />
      </BkmdModal>
    );
  }
}

export default ShareDoctorModal;
