/**
 * Created by guyavarham on 18/04/2017.
 */

import { defineMessages } from 'react-intl';

const messages = defineMessages({
  findADoctor: {
    defaultMessage: 'Find a doctor',
    id: 'members.home.findADoctor',
  },
  closeBy: {
    defaultMessage: 'close by.',
    id: 'members.home.closeBy',
  },
  whoListens: {
    defaultMessage: 'who listens.',
    id: 'members.home.whoListens',
  },
  youllLove: {
    defaultMessage: "you'll love.",
    id: 'members.home.youllLove',
  },
  whoCares: {
    defaultMessage: 'who cares.',
    id: 'members.home.whoCares',
  },
  inNetwork: {
    defaultMessage: 'In-network',
    id: 'members.home.inNetwork',
  },
  getUpToDateInfo: {
    defaultMessage:
      'Get up-to-date info on your insurance plan. Check out your deductible, ' +
      'copayments and more.',
    id: 'members.home.getUpToDateInfo',
  },
  goToYourPlan: {
    defaultMessage: 'Go to your plan',
    id: 'members.home.Go to your plan',
  },
  welcome: {
    defaultMessage: 'Welcome ',
    id: 'members.home.welcome',
  },
  cancel: {
    defaultMessage: 'Cancel',
    id: 'members.searchDoctors.cancel',
  },
  apply: {
    defaultMessage: 'Apply',
    id: 'members.searchDoctors.apply',
  },
  yourPlan: {
    defaultMessage: 'Your plan',
    id: 'members.searchDoctors.yourPlan',
  },
  location: {
    defaultMessage: 'Location',
    id: 'members.searchDoctors.location',
  },
  nearHome: {
    defaultMessage: 'Near home',
    id: 'members.searchDoctors.nearHome',
  },
  myLocation: {
    defaultMessage: 'My Location',
    id: 'members.searchDoctors.myLocation',
  },
  nearWork: {
    defaultMessage: 'Near work',
    id: 'members.searchDoctors.nearWork',
  },
  stayInNetwork: {
    defaultMessage: 'Stay in-network,',
    id: 'members.searchDoctors.stayInNetwork',
  },
  enterMemberId: {
    defaultMessage: 'enter member ID',
    id: 'members.searchDoctors.enterMemberId',
  },
  premeraSearchText: {
    defaultMessage: 'Find a doctor you’ll',
    id: 'members.searchDoctors.premeraSearchText',
  },
  uhcAllsaversSearchText: {
    defaultMessage: 'Find a doctor you’ll',
    id: 'members.searchDoctors.uhcAllsaversSearchText',
  },
  anthemSearchText: {
    defaultMessage: 'Find a doctor you’ll',
    id: 'members.searchDoctors.anthemSearchText',
  },
  floridaSearchText: {
    defaultMessage: 'Choose a doctor you’ll',
    id: 'members.searchDoctors.floridaSearchText',
  },
  personalizeYourSearch: {
    defaultMessage: 'Personalize your search',
    id: 'members.searchDoctors.personalizeYourSearch',
  },
  doctors: {
    defaultMessage: 'Doctors',
    id: 'members.searchDoctors.doctors',
  },
  doctorsInYourArea: {
    defaultMessage: 'in your area',
    id: 'members.searchDoctors.doctorsInYourArea',
  },
  seeMoreInYourNetwork: {
    defaultMessage: 'See more in your network',
    id: 'members.searchDoctors.seeMoreInYourNetwork',
  },
  careCues: {
    defaultMessage: 'Care cues',
    id: 'members.searchDoctors.careCues',
  },
  callToBook: {
    defaultMessage: 'Call to book',
    id: 'members.searchDoctors.callToBook',
  },
  instantBooking: {
    defaultMessage: 'Instant booking',
    id: 'members.searchDoctors.instantBooking',
  },
  takeAdvantageOfYourHealthPlanBenefits: {
    defaultMessage: 'Take advantage of your health plan benefits and stay on top of care.',
    id: 'members.searchDoctors.takeAdvantageOfYourHealthPlanBenefits',
  },
  medications: {
    defaultMessage: 'Medications',
    id: 'members.searchDoctors.medications',
  },
  seeThePrimaryCareDoctors: {
    defaultMessage: 'See the primary care doctors who are in your network and close by.',
    id: 'members.searchDoctors.seeThePrimaryCareDoctors',
  },
  primaryCare: {
    defaultMessage: 'Primary care',
    id: 'members.searchDoctors.Primary care',
  },
  yourMedicationsAreAllHere: {
    defaultMessage: 'Your medications are all here. Show them to your doctors and pharmacists.',
    id: 'members.searchDoctors.yourMedicationsAreAllHere',
  },
  love: {
    defaultMessage: 'love',
    id: 'members.searchDoctors.love',
  },
});

export default messages;
