/**
 * Created by moran on 11/2/16.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '../../storybook/src/utils/theme';

function SvgClock({ theme }) {
  /* eslint-disable */
  return (
    <svg aria-hidden="true" focusable="false" className="svg-icon svg-icon-clock" width="32px" height="32px" viewBox="0 0 32 32" version="1.1">
      <g id="Style-guide" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Two-color-icons" transform="translate(-189.000000, -295.000000)">
          <g id="Page-1" transform="translate(189.000000, 295.000000)">
            <path d="M16,0 C7.163,0 0,7.164 0,16 C0,24.836 7.163,32 16,32 C24.837,32 32,24.836 32,16 C32,7.164 24.837,0 16,0 M16,2 C23.72,2 30,8.28 30,16 C30,23.72 23.72,30 16,30 C8.28,30 2,23.72 2,16 C2,8.28 8.28,2 16,2" id="Fill-1" fill={theme.mainColor}></path>
            <path d="M22.4981062,11.6358974 C22.1911282,11.1651282 21.5711726,11.0358974 21.1112055,11.3517949 L16.6665236,14.3907692 L16.6665236,5.02564103 C16.6665236,4.45846154 16.2195555,4 15.6665951,4 C15.1136347,4 14.6666667,4.45846154 14.6666667,5.02564103 L14.6666667,16.3076923 C14.6666667,16.3394872 14.6816656,16.3661538 14.6846654,16.3979487 C14.689665,16.4666667 14.703664,16.5323077 14.7246625,16.6 C14.7446611,16.6676923 14.7686594,16.7292308 14.8006571,16.7907692 C14.8146561,16.8184615 14.8166559,16.8492308 14.8346546,16.8758974 C14.8636526,16.9210256 14.89965,16.9558974 14.9346475,16.9938462 C14.9486465,17.0102564 14.9606456,17.0287179 14.9776444,17.0441026 C15.0596385,17.1261538 15.151632,17.1887179 15.2526247,17.2348718 C15.2736232,17.2461538 15.2966216,17.2523077 15.31862,17.2605128 C15.430612,17.3035897 15.5456038,17.3323077 15.6645953,17.3323077 C15.6655952,17.3323077 15.6665951,17.3333333 15.6665951,17.3333333 L15.667595,17.3333333 C15.7835867,17.3333333 15.8995784,17.3046154 16.0125704,17.2615385 C16.0485678,17.2482051 16.0795656,17.2307692 16.1135631,17.2123077 C16.1485606,17.1938462 16.1865579,17.1846154 16.2215554,17.1610256 L22.2211261,13.0584615 C22.6810931,12.7446154 22.8050843,12.1066667 22.4981062,11.6358974" id="Fill-3" fill={theme.secondaryColor}></path>
          </g>
        </g>
      </g>
    </svg>
  );
  /* eslint-enable */
}

SvgClock.propTypes = {
  theme: PropTypes.object.isRequired,
};

export default withTheme(SvgClock);
