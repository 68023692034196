import React from 'react';
import { compose, pure, withProps, withHandlers } from 'recompose';
import { defineMessages, FormattedMessage } from 'react-intl';
import SCR007Info from '../../screenTemplates/SCR007Info';
import { withTranslatedMessages } from '../../utils/withTranslatedMessages';
import SvgPoo from '../../assets/svg/poo/poo';
import SvgPooBoxOpen from '../../assets/svg/poo/pooBoxOpen';
import SvgPooBoxArrow from '../../assets/svg/poo/pooBoxArrow';
import withColrectalVideoModal from './colorectalVideoModal';

const messages = defineMessages({
  title: {
    defaultMessage: 'You\'re eligible for a home screening kit.',
    id: 'colorectalFlow.introduction.title',
  },
  buttonText: {
    defaultMessage: 'Order my kit for free',
    id: 'colorectalFlow.introduction.buttonText',
  },
  secondaryButtonText: {
    defaultMessage: 'View video',
    id: 'colorectalFlow.introduction.secondaryButtonText',
  },
  step1Text: {
    defaultMessage: 'Receive your kit',
    id: 'colorectalFlow.introduction.step1Text',
  },
  step2Text: {
    defaultMessage: 'Get sample at home',
    id: 'colorectalFlow.introduction.step2Text',
  },
  step3Text: {
    defaultMessage: 'Ship the kit back',
    id: 'colorectalFlow.introduction.step3Text',
  },
});

const OrderAKit = compose(
  pure,
  withTranslatedMessages(messages),
  withProps({
    mainText: (
      <div className="row text-center margin-top-20">
        <div className="col-xs-4">
          <div className="title-24 text-strong margin-10">1</div>
          <SvgPooBoxOpen />
          <div className="text-16 text-semibold text-dark">
            <FormattedMessage {...messages.step1Text} />
          </div>
        </div>
        <div className="col-xs-4">
          <div className="title-24 text-strong margin-10">2</div>
          <SvgPoo />
          <div className="text-16 text-semibold text-dark">
            <FormattedMessage {...messages.step2Text} />
          </div>
        </div>
        <div className="col-xs-4">
          <div className="title-24 text-strong margin-10">3</div>
          <SvgPooBoxArrow />
          <div className="text-16 text-semibold text-dark">
            <FormattedMessage {...messages.step3Text} />
          </div>
        </div>
      </div>
    ),
    buttonType: 'bg-color-brand-button',
    secondaryButtonType:
      'btn-inline-link font-color-brand-link text-18 text-semibold margin-top-20',
  }),
  withColrectalVideoModal,
  withHandlers({
    onButtonClick: ({ control }) => () => control.next({}),
    onSecondaryButtonClick: ({ videoModalShow }) => () => {
      videoModalShow();
    },
  })
)(SCR007Info);

export default OrderAKit;
