import moment from 'moment';
import { push } from 'react-router-redux';
import _ from 'lodash';
import httpStatus from 'http-status';

import * as storage from '../../utils/auth/storage';
import handleLoginResponse from './handleLoginResponse';
import PromiseActionType from '../promiseActionType';
import { resetRecaptch } from '../recaptcha/actions';
import vimSdkClient from '../../utils/sdk/vimSdkClient';
import { clearGetMemberTimeout } from '../member/actions';

export const LOGIN_ACTION_TYPE = new PromiseActionType('LOGIN');
export const AUTH0_LOGIN_ACTION_TYPE = new PromiseActionType('AUTH0_LOGIN');
export const GET_USER_ACTION_TYPE = new PromiseActionType('GET_USER');
export const LOGOUT_ACTION_TYPE = 'LOGOUT';
export const LOAD_STORAGE_ACTION_TYPE = 'LOAD_STORAGE';
export const AUTH_TOUCH_ACTION_TYPE = 'AUTH_TOUCH';
export const REQUIRE_CAPTCHA_ACTION_TYPE = 'REQUIRE_CAPTCHA';
export const REFRESH_IF_ACTIVE_ACTION_TYPE = 'REFRESH_IF_ACTIVE';
export const REFRESH_ACTION_TYPE = new PromiseActionType('REFRESH');
export const RENEW_EXPIRED_PASSWORD = new PromiseActionType('RENEW_EXPIRED_PASSWORD');
export const RESET_PASSWORD_ACTION_TYPE = new PromiseActionType('RESET_PASSWORD');
export const RESET_PASSWORD_REQUEST_ACTION_TYPE = new PromiseActionType('RESET_PASSWORD_REQUEST');
export const VERIFY_RESET_TOKEN_ACTION_TYPE = new PromiseActionType('VERIFY_RESET_TOKEN');
export const CREATE_USER_ACTION_TYPE = new PromiseActionType('CREATE_USER');
export const CHECK_USERNAME_ACTION_TYPE = new PromiseActionType('CHECK_USERNAME');
export const CHANGE_PASSWORD_ACTION_TYPE = new PromiseActionType('CHANGE_PASSWORD');
export const GET_PERMISSIONS_ACTION_TYPE = new PromiseActionType('GET_PERMISSIONS');
export const REMEMBER_LOGIN_IDENTIFIER = new PromiseActionType('REMEMBER_LOGIN_IDENTIFIER');

/*
  We don't require captcha on the first couple of attempts. After a few failures
  (configured in server) the server requires a captcha through loginCaptcha
  endpoint. This action uses the correct login function and updates the captcha state.
 */
export function login(identifier, password) {
  return ({ bkmdApi: { authApi }, dispatch, getState }) => {
    const requireCaptcha = getState().auth.requireCaptcha;
    const login = requireCaptcha ? authApi.loginCaptcha.bind(authApi) : authApi.login.bind(authApi);

    const action = {
      type: LOGIN_ACTION_TYPE.SOURCE,
      meta: {
        tracker: LOGIN_ACTION_TYPE.SOURCE,
      },
      payload: {
        promise: login(identifier, password)
          .then(auth => handleLoginResponse(auth))
          .catch(err => {
            dispatch(resetRecaptch());
            if (_.get(err, 'status') === httpStatus.EXPECTATION_FAILED) {
              dispatch({
                type: REQUIRE_CAPTCHA_ACTION_TYPE,
                payload: {},
              });
            }

            throw err;
          }),
      },
    };
    return action;
  };
}

export function auth0Login(token, redirectUri) {
  return ({ bkmdApi: { authApi }, dispatch }) => {
    const login = authApi.auth0Login.bind(authApi);
    const action = {
      type: AUTH0_LOGIN_ACTION_TYPE.SOURCE,
      meta: {
        tracker: AUTH0_LOGIN_ACTION_TYPE.SOURCE,
      },
      payload: {
        promise: login(token, redirectUri)
          .then(auth => handleLoginResponse(auth))
          .catch(err => {
            dispatch({
              type: AUTH0_LOGIN_ACTION_TYPE.ERROR,
              payload: {},
            });
            throw err;
          }),
      },
    };
    return action;
  };
}

function _logout() {
  storage.removeJwt();
  vimSdkClient.closeBookingDialog();
  clearGetMemberTimeout();
  return {
    type: LOGOUT_ACTION_TYPE,
  };
}

export function logout() {
  return ({ dispatch }) => {
    dispatch(push('/'));
    return _logout();
  };
}

export function loginExpired() {
  return _logout();
}

/**
 * Verifies the the current session is valid,
 * in case it is, nothing would happen.
 * in case it's not, the server will return a normal 401 response that will lead to session
 * invalidation on the client.
 */
export function getUser() {
  return ({ bkmdApi: { authApi } }) => {
    const action = {
      type: GET_USER_ACTION_TYPE.SOURCE,
      payload: {
        promise: authApi.getUser(),
      },
    };
    return action;
  };
}

/**
 * Retrieves the user's Acl permissions
 * @returns {function(): {type: string, payload: {promise: Promise.<*>}}}
 */
export function getPermissions() {
  return ({ bkmdApi: { authApi } }) => ({
    type: GET_PERMISSIONS_ACTION_TYPE.SOURCE,
    payload: {
      promise: authApi.getPermissions(),
    },
  });
}

/**
 * Loads access tokens from local storage and verifies them
 * @returns {function()}
 */
export function loadFromStorage() {
  return ({ dispatch }) => {
    const { accessToken, refreshToken } = storage.getJwt();
    const isAuthenticated = !!accessToken;
    if (isAuthenticated) {
      dispatch(getUser());
      dispatch(getPermissions());
    }
    return {
      type: LOAD_STORAGE_ACTION_TYPE,
      payload: {
        accessToken,
        refreshToken,
        isAuthenticated,
        loading: isAuthenticated,
      },
    };
  };
}

/**
 * Updates the last activity of the current session,
 * can be used to invalidate the session after 30 minutes
 *
 * @returns {{type: string, payload: {lastUpdate: Date}}}
 */
export function authTouch() {
  return {
    type: AUTH_TOUCH_ACTION_TYPE,
    payload: {
      lastActivity: new Date(),
    },
  };
}

/**
 * Refreshes the session
 * @returns {function()}
 */
export function renewExpiredPassword({ currentPassword, newPassword }) {
  return ({ bkmdApi: { authApi }, getState, dispatch }) => {
    const state = getState();
    const action = {
      type: RENEW_EXPIRED_PASSWORD.SOURCE,
      meta: {
        tracker: RENEW_EXPIRED_PASSWORD.SOURCE,
      },
      payload: {
        promise: authApi
          .renewExpiredPassword(currentPassword, newPassword, state.auth.expiredToken)
          .then(handleLoginResponse)
          .tap(() => {
            dispatch(getUser());
            dispatch(getPermissions());
          })
          .then(tokens => tokens),
      },
    };
    return action;
  };
}

/**
 * Refreshes the session
 * @returns {function()}
 */
function refresh() {
  return ({ bkmdApi: { authApi }, getState }) => {
    const state = getState();
    const action = {
      type: REFRESH_ACTION_TYPE.SOURCE,
      payload: {
        promise: authApi.refresh(state.auth.refreshToken).then(response => {
          const { access_token, refresh_token } = response.data;
          storage.storeJwt(access_token, refresh_token);
          return { accessToken: access_token, refreshToken: refresh_token };
        }),
      },
    };
    return action;
  };
}

/**
 * Refreshes the session or logout based on last activity
 */
export function refreshIfActive() {
  return ({ dispatch, getState }) => {
    const state = getState();
    const diff = moment().diff(state.auth.lastActivity, 'minutes');
    if (diff < state.config.minutesToSessionExpiration) {
      dispatch(refresh());
    } else {
      dispatch(loginExpired);
    }

    const action = {
      type: REFRESH_IF_ACTIVE_ACTION_TYPE,
    };
    return action;
  };
}

/**
 * Sends an email to the user to reset his password
 * The server will generate a token which will be mandatory for password change
 * @param identifier (email or phone number)
 */
export function resetPasswordRequest(identifier, resend = false) {
  return ({ bkmdApi: { authApi } }) => ({
    type: RESET_PASSWORD_REQUEST_ACTION_TYPE.SOURCE,
    meta: {
      tracker: RESET_PASSWORD_REQUEST_ACTION_TYPE.SOURCE,
    },
    payload: {
      promise: !resend
        ? authApi.resetPasswordRequest(identifier)
        : authApi.resendResetPasswordRequest(identifier),
    },
  });
}

/**
 * Changes the user's password if the token is matched the one in the db (generated before)
 * @param identifier
 * @param password
 * @param token
 */
export function resetPassword(identifier, password, token) {
  return ({ bkmdApi: { authApi } }) => ({
    type: RESET_PASSWORD_ACTION_TYPE.SOURCE,
    meta: {
      tracker: RESET_PASSWORD_ACTION_TYPE.SOURCE,
    },
    payload: {
      promise: authApi.resetPassword(identifier, password, token),
    },
  });
}

/**
 * Verifies that the reset token is valid, and the user can proceed to password change
 * @param username
 * @param token
 */
export function verifyResetToken(username, token) {
  return ({ bkmdApi: { authApi } }) => ({
    type: VERIFY_RESET_TOKEN_ACTION_TYPE.SOURCE,
    meta: {
      tracker: VERIFY_RESET_TOKEN_ACTION_TYPE.SOURCE,
    },
    payload: {
      promise: authApi.verifyResetToken(username, token).then(res => res.data),
    },
  });
}

/**
 * Verifies that the reset token is valid, and the user can proceed to password change
 * @param username
 * @param token
 */
export function verifyResetTokenForEmail(token) {
  return ({ bkmdApi: { authApi } }) => ({
    type: VERIFY_RESET_TOKEN_ACTION_TYPE.SOURCE,
    meta: {
      tracker: VERIFY_RESET_TOKEN_ACTION_TYPE.SOURCE,
    },
    payload: {
      promise: authApi.verifyResetTokenForEmail(token).then(res => res.data),
    },
  });
}

/**
 * Creates a new user
 * @param user
 */
export function createUser(user) {
  return ({ bkmdApi: { authApi } }) => ({
    type: CREATE_USER_ACTION_TYPE.SOURCE,
    payload: {
      promise: authApi.createUser(user).then(res => res.data),
    },
  });
}

/**
 * Changes the password for a user
 * @param username
 * @param oldPassword
 * @param newPassword
 * @returns {function(): {type: string, payload: {promise: *}}}
 */
export function changePassword(username, oldPassword, newPassword) {
  return ({ bkmdApi: { authApi } }) => ({
    type: CHANGE_PASSWORD_ACTION_TYPE.SOURCE,
    payload: {
      promise: authApi.usersApi
        .changePassword(username, oldPassword, newPassword)
        .then(res => res.data),
    },
  });
}

export function rememberLoginIdentifier(loginIdentifier) {
  storage.storeLoginIdentifier(loginIdentifier);
  return { type: REMEMBER_LOGIN_IDENTIFIER.SOURCE };
}
