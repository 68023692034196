import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _ from 'lodash';
import { autobind } from 'core-decorators';
import { defineMessages, FormattedMessage } from 'react-intl';

import analytics from '../../store/tools/analytics/analyticsHoc';
import { IntlString, MessageShape } from '../ui/intlString';
import Loader from '../../storybook/src/components/atoms/Loader/Loader';

const messages = defineMessages({
  limit: {
    defaultMessage: '{chars} of {max} characters',
    id: 'cleanTextArea.limit',
  },
});

/**
 * This function adds clean-input behavior to components that contains an input element.
 * Useful when dealing with third-party components that let's you pass props to the inner
 * input element.
 * @param Component
 * @returns {CleanInputWrapper}
 */
export default Component => {
  @autobind
  class CleanInputWrapper extends React.PureComponent {
    static propTypes = {
      /**
       * The css class name of the root element.
       */
      className: PropTypes.string,

      /**
       * The id of the root element.
       */
      id: PropTypes.string,

      /**
       * Set the 'type' html attribute for the inner input field.
       * Default is text.
       */
      type: PropTypes.string,

      /**
       * The css class name of the error
       */
      errorClassName: PropTypes.string,

      /**
       * The css class name of the help text
       */
      helpClassName: PropTypes.string,

      /**
       * The css class name of the input
       */
      inputClassName: PropTypes.string,

      /**
       * The css class name of the label
       */
      labelClassName: PropTypes.string,

      /**
       * The error content to display.
       */
      errorText: MessageShape,

      /**
       * The help text content to display.
       */
      helpText: MessageShape,

      /**
       * The class of the right icon.
       */
      rightIcon: PropTypes.string,

      /**
       * Optional method hook
       * Called whenever the user clicks the right icon
       */
      rightIconClick: PropTypes.func,

      /**
       * Set aria-label to the right icon
       */
      rightIconLabel: PropTypes.string,

      /**
       * rightIconType
       */
      rightIconType: PropTypes.string,

      /**
       * Set aria-pressed to the right icon
       */
      rightIconState: PropTypes.bool,

      /**
       * Set right icon disabled prop
       */
      rightIconDisabled: PropTypes.bool,

      /**
       * Set right icon loading
       */
      rightIconLoading: PropTypes.bool,
      /**
       * The content to use for the floating label element.
       */
      label: MessageShape,

      /**
       * The placeholder to use for the element.
       */
      placeholder: MessageShape,

      /**
       * Callback function that is fired when the field receives focus.
       */
      onFocus: PropTypes.func,

      /**
       * The value of the text field.
       */
      value: PropTypes.any,

      /**
       * Indicator whether to automatically select all the text-box content on focus.
       * Default value is false
       */
      autoSelect: PropTypes.bool,

      /**
       * fix the label to top (placeholder fix)
       * Default value is false
       */
      fixedLabel: PropTypes.bool,
      /**
       * the input component
       */
      inputRef: PropTypes.func,

      /**
       * show placeholder only when field focused
       */
      showPlaceholderOnlyOnFocus: PropTypes.bool,
      focused: PropTypes.bool,

      /**
       * maximum allowed length for the input
       */
      maxLength: PropTypes.number,
      showLimitSpan: PropTypes.bool,
      /**
       * If true - shows the error message above the field
       */
      showErrorOnTop: PropTypes.bool,

      /**
       * If false - the old "clean-input" class will be removed
       */
      isCleanInputOldStyle: PropTypes.bool,
    };

    static defaultProps = {
      className: undefined,
      type: 'text',
      errorClassName: undefined,
      helpClassName: undefined,
      helpText: undefined,
      rightIcon: undefined,
      rightIconClick: undefined,
      rightIconLabel: undefined,
      rightIconState: undefined,
      rightIconDisabled: false,
      rightIconType: undefined,
      inputClassName: undefined,
      labelClassName: undefined,
      errorText: undefined,
      maxLength: undefined,
      showLimitSpan: false,
      label: undefined,
      placeholder: undefined,
      onFocus: () => {},
      value: undefined,
      id: undefined,
      autoSelect: false,
      fixedLabel: false,
      inputRef: _.noop,
      focused: false,
      showPlaceholderOnlyOnFocus: false,
      showErrorOnTop: false,
      isCleanInputOldStyle: true,
    };

    onFocus(e, ...other) {
      const { autoSelect, onFocus } = this.props;
      if (autoSelect) {
        _.invoke(e, 'target.select');
      }

      if (onFocus) {
        onFocus(e, ...other);
      }
    }

    /**
     * Checks if the current input has a value
     * @returns {*}
     */
    hasValue() {
      return !!this.props.value;
    }

    /**
     * Builds input's group class
     * @param className
     * @returns {string|*}
     */
    buildClassName(className) {
      className = `form-group material-form-group ${className}`;
      if (this.props.errorText) {
        className += this.props.showErrorOnTop ? ' has-error top' : ' has-error';
      }

      return className;
    }

    updateRef(input) {
      this._input = input;
      this.props.inputRef(input);
    }

    renderErrorSpan() {
      const { errorText, errorClassName } = this.props;
      if (_.isEmpty(errorText)) return null;

      return _.isString(errorText) ? (
        <span className={classNames('validation-message', errorClassName)} aria-live="polite">
          {errorText}
        </span>
      ) : (
        <IntlString
          className={classNames('validation-message', errorClassName)}
          message={errorText}
        />
      );
    }

    renderHelpSpan() {
      const { helpText, helpClassName } = this.props;
      return _.isEmpty(helpText) ? null : (
        <IntlString className={classNames('help-message', helpClassName)} message={helpText} />
      );
    }

    renderRightIcon() {
      const {
        rightIcon,
        rightIconClick,
        rightIconLabel,
        rightIconState,
        rightIconDisabled,
        rightIconLoading,
        rightIconType,
      } = this.props;

      if (!rightIcon) return <div />;
      return (
        <button
          className={classNames('input-right-icon', !rightIconLoading && rightIcon)}
          type={rightIconType}
          onClick={rightIconClick}
          aria-label={rightIconLabel}
          aria-pressed={rightIconState}
          disabled={rightIconDisabled}
        >
          {rightIconLoading && <Loader size="small" padding="no-padding" />}
        </button>
      );
    }

    renderLimitSpan() {
      const { value, maxLength, showLimitSpan } = this.props;
      const chars = _.isString(value) ? value.length : 0;
      if (!showLimitSpan) return null;

      return (
        <span
          className={classNames('textarea-limit', {
            'text-danger': chars === maxLength,
          })}
        >
          <FormattedMessage {...messages.limit} values={{ chars, max: maxLength }} />
        </span>
      );
    }

    /**
     * Renders the component
     * @returns {XML}
     */
    render() {
      // Extract properties
      const {
        className,
        type,
        label,
        labelClassName,
        inputClassName,
        id,
        fixedLabel,
        placeholder,
        showPlaceholderOnlyOnFocus,
        inputRef, // eslint-disable-line no-unused-vars
        isCleanInputOldStyle,
        ...other
      } = this.props;

      const { focused } = this.props;
      const _other = _.omit(other, [
        'autoSelect',
        'helpText',
        'helpClassName',
        'errorText',
        'errorClassName',
        'rightIcon',
        'rightIconClick',
        'rightIconLabel',
        'rightIconState',
        'hideErrorWhileFocused',
      ]);
      const _className = this.buildClassName(className);
      const placeholderText = showPlaceholderOnlyOnFocus
        ? focused
          ? placeholder
          : undefined
        : placeholder;
      const cleanInput = isCleanInputOldStyle ? 'clean-input' : undefined;
      return (
        <div className={_className}>
          <Component
            placeholder={placeholderText}
            type={type}
            className={classNames(cleanInput, inputClassName)}
            {..._other}
            onFocus={this.onFocus}
            inputRef={this.updateRef}
            id={id}
          />
          {label && (
            <label
              className={classNames('static-label-header', {
                'input-active': fixedLabel || this.hasValue(),
              })}
              htmlFor={id}
            >
              <IntlString className={labelClassName} message={label} />
            </label>
          )}
          {this.renderRightIcon()}
          {this.renderErrorSpan()}
          {this.renderHelpSpan()}
          {this.renderLimitSpan()}
        </div>
      );
    }
  }

  return analytics(CleanInputWrapper, false, [{ event: 'onFocus' }, { event: 'onChange' }]);
};
