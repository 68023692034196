/**
 * Created by guyavraham on 17/8/17.
 */
import PropTypes from 'prop-types';
import { withContext, getContext } from 'recompose';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { isAuthenticated } from '../../../../store/auth/selectors';

/**
 * Uses recompose to get HOC with "childrenContextTypes" and "getChildContext"
 * @param paths
 */
export default (secureRoutes, publicRoutes) =>
  compose(
    connect(state => ({
      isAuthenticated: isAuthenticated(state),
    })),
    withContext({ paths: PropTypes.object.isRequired }, props => ({
      paths: props.isAuthenticated ? secureRoutes : publicRoutes,
    })),
  );

export const getPathsFromContext = () =>
  getContext({
    paths: PropTypes.object.isRequired,
  });
