/**
 * Created by guyavarham on 19/04/2017.
 */

import _ from 'lodash';

import routerSelectors from '../../../../store/routing/selectors';
import * as memberSelectors from '../../../../store/member/selectors';
import getBrowserLocation, {
  isBrowserLocationAllowed,
  isLocationExecuted,
  isMemberSettingsExecuted,
  isGetMemberExecuted,
} from '../../../../store/location/selectors';
import locationMessages from './searchDoctorsResults/components/location/messages';
import { isAuthenticated } from '../../../../store/auth/selectors';
import { getAllFilterKeys } from '../../../../components/searchDoctors/filter/filteringDefenitions';
import { generateUUID } from '../../../../utils/uuid';

export const extractSearchTerm = query => query.searchTerm;
export const extractAddress = query => query.address;
export const extractLocationType = query => query.locationType;
export const extractSorting = query => query.sorting;
export const extractLocationId = query => parseInt(query.locationId, 10);
export const extractChosenLabel = query => query.chosenLabel;

export const extractFirstLogin = query => query.first;

export function extractCodesArray(query) {
  return _.isString(query.codes) ? [query.codes] : query.codes;
}

export function extractGeo(query) {
  const longitude = _.get(query, 'geo.longitude');
  const latitude = _.get(query, 'geo.latitude');
  const zip = _.get(query, 'geo.zip');
  const state = _.get(query, 'geo.state');

  return !(longitude && latitude)
    ? undefined
    : {
        longitude: parseFloat(longitude),
        latitude: parseFloat(latitude),
        zip,
        state,
      };
}

export function extractFilters(query) {
  const filters = _.pick(query, _.concat(getAllFilterKeys(), ['address', 'distance']));

  return _.mapValues(filters, value => {
    if (value === 'true') return true;
    if (value === 'false') return false;
    return value;
  });
}

export function extractOpenBookingModal(query) {
  return query.openBookingModal === 'true';
}

export const extractBookingNpi = query => query.bookingNpi;
export const extractRanking = query => query.ranking && parseInt(query.ranking, 10);
export const extractQueryId = query => query.queryId || generateUUID();
export const extractBookingLocationId = query => _.toNumber(query.bookingLocationId);

export function extractChosenCode(query) {
  return query.chosenCode;
}

/**
 * Get an object with the current query string formatted parameters
 * @param query
 * @param blackList
 */
export function getFilteredQS(query, blackList) {
  const all = {
    chosenLabel: extractChosenLabel(query),
    ...extractFilters(query),
    searchTerm: extractSearchTerm(query),
    locationType: extractLocationType(query),
    sorting: extractSorting(query),
    locationId: extractLocationId(query),
    address: extractAddress(query),
    geo: extractGeo(query),
    queryId: extractQueryId(query),
    codes: extractCodesArray(query), // please leave codes last in the array for easy qs debug
  };

  return _.chain(all)
    .pickBy(_.identity)
    .omit(blackList)
    .value();
}

const MY_LOCATION = 'my-location';

function selectMyLocation(state) {
  const browserLocation = getBrowserLocation(state);
  if (!browserLocation) return null;
  return {
    address: MY_LOCATION,
    shortAddress: MY_LOCATION.split(',')[0],
    geo: browserLocation,
  };
}

function selectCustomLocation(queryString, meta) {
  const address = extractAddress(queryString);
  const geo = extractGeo(queryString);
  if (!address || !geo) return null;
  const shortAddress = address.split(',')[0];
  return { address, shortAddress, geo, ...meta };
}

export function getDefaultLocations() {
  return {
    MY_LOCATION: {
      name: MY_LOCATION,
      displayName: locationMessages.myLocation,
      icon: 'icon-location-cross my-location-icon',
      selector: selectMyLocation,
    },
    HOME: {
      name: 'home',
      displayName: locationMessages.home,
      icon: 'icon-home-2 home-icon font-color-brand-link',
      selector: memberSelectors.homeSetting,
    },
    WORK: {
      name: 'work',
      displayName: locationMessages.work,
      icon: 'icon-suitcase work-icon',
      selector: memberSelectors.workSetting,
    },
    DEFAULT: { name: '', icon: 'icon-map-pin-e' },
  };
}

function isMyLocationReady(state) {
  const isBrowserLocationAllowedFlag = isBrowserLocationAllowed(state);
  const browserLocationExecuted = isLocationExecuted(state);
  const hasBrowserLocation = !_.isEmpty(getBrowserLocation(state));

  // my location service is valid, whether its blocked or exists or its allowed and executed.
  const isMyLocationValid =
    hasBrowserLocation ||
    !isBrowserLocationAllowedFlag ||
    (isBrowserLocationAllowedFlag && browserLocationExecuted);

  return isMyLocationValid;
}

export function waitForMyLocation(state) {
  return _.get(state, 'config.waitForMyLocation', false);
}

// options { waitForMyLocation: false }
export function isLocationReady(state, options) {
  const isAuthenticatedFlag = isAuthenticated(state);
  const memberSettingsExecuted = isMemberSettingsExecuted(state);
  const getMemberExecuted = isGetMemberExecuted(state);
  const hasWorkOrHomeLocation = !_.isEmpty(
    _.get(memberSelectors.homeSetting(state), 'geo', null) ||
      _.get(memberSelectors.workSetting(state), 'geo', null),
  );

  let myLocationValid = true;
  if (_.get(options, 'waitForMyLocation', false)) {
    myLocationValid = isMyLocationReady(state);
  }

  // member locations is valid, when the member is unauthenticated(getMemberSettings will
  // not execute.
  // OR (getMemberExecuted AND (getMemberSettings action executed OR data existed already))
  const memberLocationsValid =
    !isAuthenticatedFlag ||
    ((hasWorkOrHomeLocation || memberSettingsExecuted) && getMemberExecuted);
  return myLocationValid && memberLocationsValid ? { result: true } : null;
}

/**
 * This selector will choose a location by the following priorities:
 * 1. The client went to the filter and chose a location (there is a query string for geo)
 *    If the locationType (from qs) is defined we will add it to the response
 * 2. Take the member home address from settings if it is defined
 * 3. Take the browser location if it exists
 * 4. As last result
 * @param state
 * @returns {*}
 */
export function chooseClientLocation(state) {
  // if (!isLocationReady(state)) return null;
  const defaultLocations = getDefaultLocations();
  const queryString = routerSelectors.currRouteQuery(state);

  // 1. the client chose a location
  if (queryString) {
    const locationType = extractLocationType(queryString);
    const meta = _.find(defaultLocations, o => o.name === locationType);
    // if the qs has one of the default locations
    if (meta && _.isFunction(meta.selector)) {
      const selected = meta.selector(state);
      if (selected) return { ...selected, ...meta };
    }

    // for custom address
    const custom = selectCustomLocation(queryString, defaultLocations.DEFAULT);
    if (custom) return custom;
  }

  // 2. try member's home
  const home = memberSelectors.homeSetting(state);
  if (home) return { ...home, ...defaultLocations.HOME };

  // work
  const work = memberSelectors.workSetting(state);
  if (work) return { ...work, ...defaultLocations.WORK };

  // 3. browser location if exists
  const myLocation = selectMyLocation(state);
  if (myLocation) return { ...myLocation, ...defaultLocations.MY_LOCATION };

  // 4. defaults from configuration
  return {
    ...memberSelectors.getDefaultLocationSelector(state),
    ...defaultLocations.DEFAULT,
  };
}
