import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '../../storybook/src/utils/theme';

function SvgOtherFacilities({ theme }) {
  /* eslint-disable */
  return (
    <svg aria-hidden="true" focusable="false" className="svg-icon svg-other-facilities" width="32px" height="32px" viewBox="0 0 32 32" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-666.000000, -426.000000)">
          <g transform="translate(666.000000, 426.000000)">
            <path d="M30.9997,0 C31.5527,0 31.9997,0.448 31.9997,1 C31.9997,1.552 31.5527,2 30.9997,2 L29.9997,2 L29.9997,31 C29.9997,31.552 29.5527,32 28.9997,32 C28.4467,32 27.9997,31.552 27.9997,31 L27.9997,2 L11.9997,2 L11.9997,31 C11.9997,31.552 11.5527,32 10.9997,32 C10.4467,32 9.9997,31.552 9.9997,31 L9.9997,10.245 L3.9387,15.7 C3.9707,15.796 3.9997,15.894 3.9997,16 L3.9997,31 C3.9997,31.552 3.5527,32 2.9997,32 C2.4467,32 1.9997,31.552 1.9997,31 L1.9997,17.445 L1.6687,17.743 C1.4777,17.915 1.2377,18 0.9997,18 C0.7267,18 0.4537,17.888 0.2567,17.669 C-0.1123,17.258 -0.0793,16.626 0.3307,16.257 L9.9997,7.555 L9.9997,2 L8.9997,2 C8.4467,2 7.9997,1.552 7.9997,1 C7.9997,0.448 8.4467,0 8.9997,0 L30.9997,0 Z" fill={theme.mainColor}></path>
            <path d="M22.9997,22 L16.9997,22 C16.4467,22 15.9997,22.448 15.9997,23 L15.9997,31 C15.9997,31.552 16.4467,32 16.9997,32 C17.5527,32 17.9997,31.552 17.9997,31 L17.9997,24 L21.9997,24 L21.9997,31 C21.9997,31.552 22.4467,32 22.9997,32 C23.5527,32 23.9997,31.552 23.9997,31 L23.9997,23 C23.9997,22.448 23.5527,22 22.9997,22" fill={theme.mainColor}></path>
            <path d="M23,14 L17,14 C16.447,14 16,14.448 16,15 L16,19 C16,19.552 16.447,20 17,20 L23,20 C23.552,20 24,19.552 24,19 L24,15 C24,14.448 23.552,14 23,14 L23,14 Z" fill={theme.secondaryColor}></path>
            <path d="M23,6 L17,6 C16.447,6 16,6.448 16,7 L16,11 C16,11.552 16.447,12 17,12 L23,12 C23.552,12 24,11.552 24,11 L24,7 C24,6.448 23.552,6 23,6 L23,6 Z" fill={theme.secondaryColor}></path>
            <polygon fill="#FFFFFF" points="18 10 22 10 22 8 18 8"></polygon>
            <polygon fill="#FFFFFF" points="18 18 22 18 22 16 18 16"></polygon>
          </g>
        </g>
      </g>
    </svg>
  );
  /* eslint-enable */
}

SvgOtherFacilities.propTypes = {
  theme: PropTypes.object.isRequired,
};

export default withTheme(SvgOtherFacilities);
