/**
 * Created by galgoltzman on 01/02/2017.
 */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './footer.less';

class Footer extends React.PureComponent {
  static propTypes = {
    /**
     * Sets component css class
     */
    footerClassName: PropTypes.string,
  };

  static defaultProps = {
    footerClassName: undefined
  };

  render() {
    const { footerClassName } = this.props;
    return (
      <footer className={classNames('footer', footerClassName)}>
        {this.props.children}
      </footer>
    );
  }
}

export default Footer;
