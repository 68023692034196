/**
 * Created by matan on 1/11/18.
 */
import BkmdApi from '../bkmdApi';
import ServicesApi from '../services';

const SERVICE_NAME = 'clinicResource';

export default class ClinicResourceApi extends BkmdApi {
  constructor(fetch) {
    super(fetch, '/clinicManagement/resource/');
    this.servicesApi = new ServicesApi(fetch);
  }

  getAllAutoTimeSlotsIdentifiers() {
    return this.servicesApi
      .callSecure(SERVICE_NAME, 'getAllAutoTimeSlotsIdentifiers', {})
      .then(result => result.data);
  }

  addResourceToAutoTimeSlotsList(identifier) {
    return this.servicesApi.callSecure(SERVICE_NAME, 'addResourceToAutoTimeSlotsList', {
      identifier,
    });
  }

  deleteResourceFromAutoTimeSlotsList(identifier) {
    return this.servicesApi.callSecure(SERVICE_NAME, 'deleteResourceFromAutoTimeSlotsList', {
      identifier,
    });
  }
}
