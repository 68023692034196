import { defineMessages } from 'react-intl';

const messages = defineMessages({
  successTitle: {
    defaultMessage: 'Thank you',
    id: 'members.auth.resetPassword.successTitle',
  },
  successMessage: {
    defaultMessage:
      'If you don’t receive a code within a few seconds, please make sure that the details you entered are correct',
    id: 'members.auth.resetPassword.successMessage',
  },
});

export default messages;
