/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { defineMessages, FormattedMessage } from 'react-intl';
import {
  compose,
  setPropTypes,
  withHandlers,
  withPropsOnChange,
  defaultProps,
  lifecycle,
} from 'recompose';
import { object, string } from 'yup';
import { Field, Form } from 'formik';
import FormActionButtons from '../../components/molecules/FormSubmissionButtons/FormActionButtons';
import { WrappedFormik } from '../../components/form/formik/wrappedFormik';
import { withTranslatedMessages } from '../../utils/withTranslatedMessages';
import { FlowShape } from '../../components/wizard/flow/shapes';
import TimePreferenceIntlEnum, { TimePreference } from '../../model/enum/timePreference';
import { CleanFormikInput } from '../../components/form/formik/cleanFormikInput';
import { CleanFormikRadioGroup } from '../../components/form/formik/cleanFormikRadioGroup';

const messages = defineMessages({
  title: {
    defaultMessage: 'When do you want to see the doctor?',
    id: 'members.directory.booking.timePreference.title',
  },
  preferredLabel: {
    defaultMessage: 'Your preferred days and times',
    id: 'members.directory.booking.timePreference.preferredLabel',
  },
  primaryButtonText: {
    defaultMessage: 'Next',
    id: 'members.directory.booking.timePreference.primaryButtonText',
  },
});

const schema = object().shape({
  timePreference: string().required(),
  customText: string()
    .nullable(true)
    .label('Your preferred days and times')
    .when('timePreference', (timePreference, schema) =>
      timePreference === TimePreference.CUSTOM
        ? schema
            .min(3)
            .nullable(false)
            .required('You must provide days and times or choose the ASAP options')
        : schema.nullable(true).notRequired(),
    ),
});

const SelectTimePreference = ({ initialValues, primaryButtonText, onSubmit }) => (
  <div>
    <div className="title-24 margin-top text-strong font-color-brand-main text-center">
      <FormattedMessage {...messages.title} />
    </div>

    <WrappedFormik
      initialValues={initialValues}
      isInitialValid
      onSubmit={onSubmit}
      validationSchema={schema}
      render={data => (
        <Form>
          <Field
            name="timePreference"
            intlEnum={TimePreferenceIntlEnum}
            component={CleanFormikRadioGroup}
            marginBetweenOptions={0}
            wrapClass="two-lines-btn no-padding margin-bottom-30"
            btnStyle="btn btn-outline btn-block btn-big"
            id="timePreferenceInput"
          />
          {data.values.timePreference === TimePreference.CUSTOM ? (
            <div className="margin-top-30 margin-bottom-30">
              <Field
                name="customText"
                label={messages.preferredLabel}
                component={CleanFormikInput}
                inputClassName="no-margin"
                id="customTextInput"
              />
            </div>
          ) : null}
          <div>
            <FormActionButtons
              primaryButtonText={primaryButtonText}
              primaryDisabled={!data.isValid}
            />
          </div>
        </Form>
      )}
    />
  </div>
);

export default compose(
  setPropTypes({
    control: FlowShape.isRequired,
    timePreference: PropTypes.string,
    timePreferenceCustomText: PropTypes.string,
  }),
  defaultProps({
    timePreference: TimePreference.ASAP,
    timePreferenceCustomText: undefined,
  }),

  lifecycle({
    componentWillMount() {
      this.setState({
        initialValues: {
          timePreference: this.props.timePreference,
          customText: this.props.timePreferenceCustomText,
        },
      });
    },
  }),
  withHandlers({
    onSubmit: ({ control }) => ({ timePreference, customText }) => {
      control.next({
        payload: { timePreference, timePreferenceCustomText: customText },
      });
    },
  }),
  withPropsOnChange(['timePreference'], ({ timePreference }) => ({
    showCustomText: timePreference === TimePreference.CUSTOM,
  })),
  withTranslatedMessages(_.pick(messages, ['primaryButtonText'])),
)(SelectTimePreference);
