import React from 'react';
import PropTypes from 'prop-types';
import { object, string, date } from 'yup';
import { defineMessages } from 'react-intl';
import { Field, Form } from 'formik';
import { WrappedFormik } from '../../form/formik/wrappedFormik';
import FormActionButtons from '../../molecules/FormSubmissionButtons/FormActionButtons';
import { withTranslatedMessages } from '../../../utils/withTranslatedMessages';
import { CleanFormikInput } from '../../form/formik/cleanFormikInput';
import { CleanFormikDateInput } from '../../form/formik/cleanFormikDateInput';

const messages = defineMessages({
  fullNameLabel: {
    defaultMessage: "Patient's first and last name",
    id: 'members.directory.booking.findMemberForm.fullNamePlaceholder',
  },
  insuranceIdLabel: {
    defaultMessage: 'Insurance ID number',
    id: 'members.directory.booking.findMemberForm.insuranceIdPlaceholder',
  },
  dateOfBirthLabel: {
    defaultMessage: 'Date of birth',
    id: 'members.directory.booking.findMemberForm.dateOfBirthPlaceholder',
  },
});

const scheme = object().shape({
  fullName: string()
    .matches(/^[\s]*[^\s\\]+[\s]*[^\\]*[\s]+[^\s\\]+[\s]*$/, 'Full name is required, letters only')
    .required()
    .label('Full name'),
  insuranceId: string()
    .matches(/^[a-z0-9]+$/i, 'Insurance id is required, letters and numbers only')
    .required()
    .label('Insurance ID'),
  dateOfBirth: date()
    .required()
    .label('Date of birth'),
});

class FindMemberForm extends React.Component {
  render() {
    const {
      initialValues,
      onSubmit,
      fullNameLabel,
      insuranceIdLabel,
      dateOfBirthLabel,
      ...props
    } = this.props;
    return (
      <WrappedFormik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={scheme}
        render={() => (
          <Form>
            <div className="margin-top-20">
              <Field name="fullName" label={fullNameLabel} component={CleanFormikInput} />
            </div>
            <Field name="dateOfBirth" label={dateOfBirthLabel} component={CleanFormikDateInput} />
            <Field name="insuranceId" label={insuranceIdLabel} component={CleanFormikInput} />
            <FormActionButtons {...props} />
          </Form>
        )}
      />
    );
  }
}

FindMemberForm.propTypes = {
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  fullNameLabel: PropTypes.string.isRequired,
  insuranceIdLabel: PropTypes.string.isRequired,
  dateOfBirthLabel: PropTypes.string.isRequired,
};
FindMemberForm.defaultProps = {
  initialValues: {},
};

export default withTranslatedMessages(messages)(FindMemberForm);
