import { autobind } from 'core-decorators';
import BkmdApi from './bkmdApi';
import ServicesApi from './services';

// eslint-disable-next-line
const SERVICE_NAME = 'appointments';

@autobind
export default class AppointmentsApi extends BkmdApi {
  constructor(fetch) {
    super(fetch, '/appointments/');
    this.servicesApi = new ServicesApi(fetch);
  }

  updateAppointment(appointmentId, data) {
    return this.post('/updateAppointment', { id: appointmentId, ...data });
  }

  markAppointmetAsReviewed(appointmentId, domain) {
    return this.post('/markAppointmetAsReviewed', { id: appointmentId }, domain);
  }

  updateAppointmentStatus(appointmentId, domain, newStatus) {
    return this.post('/updateAppointmentStatus', { id: appointmentId, newStatus }, domain);
  }

  setAsArchiveStatus(appointmentId, domain, reason) {
    return this.post('/setAsArchiveStatus', { id: appointmentId, reason }, domain);
  }

  publicGetAppointment(appointmentId) {
    return this.get('/publicGetAppointment', { id: appointmentId });
  }

  getMemberLatestBookedAppointment() {
    return this.post('/getMemberLatestBookedAppointment').then((res) => res.data);
  }

  getPatientAppointments(memberToken) {
    return this.post('/getPatientAppointments', { memberToken }).then((res) => res.data);
  }

  cancelAppointment(memberToken, appointmentId) {
    return this.post('/cancel', { memberToken, appointmentId });
  }
}
