/* eslint-disable react/prop-types */
import React from 'react';
import {
  compose,
  withProps,
  setPropTypes,
  withStateHandlers,
  lifecycle,
  withHandlers,
} from 'recompose';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { defineMessages, FormattedMessage } from 'react-intl';
import MultiAutoCompleteSelect from '../MultiAutoCompleteSelect/MultiAutoCompleteSelect';
import CleanSelect from '../../form/cleanSelect';
import codeTypeaheadSearch from '../codeInput/codeTypeaheadSearch';
import './codeTypeaheadSearch.less';

const messages = defineMessages({
  qty: {
    defaultMessage: 'Qty',
    id: 'cptItem.qty',
  },
});

const quantities = _.range(1, 30).map(value => ({
  label: value,
  value,
}));

const CptItem = ({ item, remove, onQuantityChange, quantity }) => (
  <div className="card cpt-code-item">
    <div className="card-body padding-box-20">
      <div className="text-16 text-semibold text-dark">{item.code}</div>
      <div className="text-14 text-dark margin-top">{item.label}</div>
      <button className="btn btn-inline-link item-delete-btn" onClick={remove}>
        <i className="icon-trash" />
      </button>
      <div className="row margin-top-20">
        <div className="col-xs-2">
          <label htmlFor="qty" className="qty-label text-16 text-semibold text-dark">
            <FormattedMessage {...messages.qty} />
          </label>
        </div>
        <div className="col-xs-5">
          <CleanSelect
            options={quantities}
            onChanged={onQuantityChange}
            value={quantity}
            id="qty"
            clearable={false}
          />
        </div>
      </div>
    </div>
  </div>
);
const enhanceItem = compose(
  withStateHandlers(({ item }) => ({ quantity: item.quantity || 12 }), {
    onQuantityChange: (state, { update }) => e => {
      const newState = { quantity: e.value };
      update(newState);
      return newState;
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.onQuantityChange({ value: this.props.quantity });
    },
  }),
);

const transformInitialSelectionOnMount = lifecycle({
  componentWillMount() {
    this.setState({
      initialSelectedItems: _.map(this.props.initialSelectedItems, item =>
        _.pick(item, ['code', 'quantity', 'label'])),
    });
  }
});

export default compose(
  setPropTypes({
    onSelectionChange: PropTypes.func.isRequired,
    initialSelectedItems: PropTypes.arrayOf(
      PropTypes.shape({
        code: PropTypes.string.isRequired,
        label: PropTypes.string,
      }).isRequired,
    ),
  }),
  withProps({
    idKey: 'code',
    placeholderText: 'CPT code or description',
    itemComponent: enhanceItem(CptItem),
  }),
  withHandlers({
    onSelectionChange: ({ onSelectionChange }) => selection => {
      const transformedSelection = selection.map(({ code, quantity }) => ({ code, quantity }));
      onSelectionChange(transformedSelection);
    },
  }),
  transformInitialSelectionOnMount,
  codeTypeaheadSearch('CPT'),
)(MultiAutoCompleteSelect);
