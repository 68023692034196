export const SET_DROP_DOWN_OPEN = 'SET_DROPDOWN_OPEN';
export const OPEN_SEARCH_MODAL = 'OPEN_SEARCH_MODAL';
export const OPEN_MOBILE_MENU = 'OPEN_MOBILE_MENU';
export const FOCUS_SEARCH_TYPEAHEAD = 'FOCUS_SEARCH_TYPEAHEAD';
export const TOGGLE_SIDEBAR_MENU = 'TOGGLE_SIDEBAR_MENU';

export const dropDownOpen = value => ({ type: SET_DROP_DOWN_OPEN, payload: !!value });
export const openSearchModal = () => ({ type: OPEN_SEARCH_MODAL, payload: true });
export const closeSearchModal = () => ({ type: OPEN_SEARCH_MODAL, payload: false });
export const mobileMenuOpen = value => ({ type: OPEN_MOBILE_MENU, payload: value });
export const focusSearchTypeahead = value => ({ type: FOCUS_SEARCH_TYPEAHEAD, payload: value });
export const toggleSidebarMenu = () => ({ type: TOGGLE_SIDEBAR_MENU, payload: true });
