import React from 'react';

function SvgCancelAppointment() {
  /* eslint-disable */
  return (
    <svg
      width="116px"
      height="72px"
      viewBox="0 0 116 72"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>E2AB3C5E-B389-4B1D-B9E1-975180FA614A</title>
      <defs>
        <path
          d="M62.0268767,3.9705449 C64.7528704,3.9705449 66.9627249,6.0572677 66.9627249,8.63137074 L82.1933425,52.9092162 C82.1933425,55.4833193 79.9834879,57.5700421 77.2574942,57.5700421 L20.4952391,57.5700421 C17.7692454,57.5700421 15.5593909,55.4833193 15.5593909,52.9092162 L0.32877337,8.63137074 C0.32877337,6.0572677 2.53862791,3.9705449 5.26462163,3.9705449 L62.0268767,3.9705449 Z"
          id="cancel-appt-path-1"
        ></path>
        <rect
          id="cancel-appt-path-2"
          x="51.7210541"
          y="19.1901552"
          width="9.53442772"
          height="9.59497172"
          rx="3.36"
        ></rect>
        <rect
          id="cancel-appt-path-3"
          x="36.5974791"
          y="19.1901552"
          width="9.53442772"
          height="9.59497172"
          rx="3.36"
        ></rect>
        <rect
          id="cancel-appt-path-4"
          x="36.3565891"
          y="32.5581395"
          width="9.53442772"
          height="9.59497172"
          rx="3.36"
        ></rect>
        <rect
          id="cancel-appt-path-5"
          x="21.1451308"
          y="19.1901552"
          width="9.53442772"
          height="9.59497172"
          rx="3.36"
        ></rect>
        <rect
          id="cancel-appt-path-6"
          x="6.02155574"
          y="19.1901552"
          width="9.53442772"
          height="9.59497172"
          rx="3.36"
        ></rect>
        <rect
          id="cancel-appt-path-7"
          x="51.7210541"
          y="32.424599"
          width="9.53442772"
          height="9.59497172"
          rx="3.36"
        ></rect>
        <rect
          id="cancel-appt-path-8"
          x="21.1451308"
          y="32.424599"
          width="9.53442772"
          height="9.59497172"
          rx="3.36"
        ></rect>
        <rect
          id="cancel-appt-path-9"
          x="6.02155574"
          y="32.424599"
          width="9.53442772"
          height="9.59497172"
          rx="3.36"
        ></rect>
        <rect
          id="cancel-appt-path-10"
          x="51.7210541"
          y="45.9899038"
          width="9.53442772"
          height="9.59497172"
          rx="3.36"
        ></rect>
        <rect
          id="cancel-appt-path-11"
          x="36.5974791"
          y="45.9899038"
          width="9.53442772"
          height="9.59497172"
          rx="3.36"
        ></rect>
        <rect
          id="cancel-appt-path-12"
          x="21.1451308"
          y="45.9899038"
          width="9.53442772"
          height="9.59497172"
          rx="3.36"
        ></rect>
        <rect
          id="cancel-appt-path-13"
          x="6.02155574"
          y="45.9899038"
          width="9.53442772"
          height="9.59497172"
          rx="3.36"
        ></rect>
        <path
          d="M61.9008518,3.6396838 C64.6312246,3.6396838 66.8446292,5.81630067 66.8446292,8.50129364 L66.8446292,13.2060774 C66.8446292,15.8910703 0.103635084,15.8910703 0.103635084,13.2060774 L0.103635084,8.50129364 C0.103635084,5.81630067 2.31703959,3.6396838 5.04741242,3.6396838 L61.9008518,3.6396838 Z"
          id="cancel-appt-path-14"
        ></path>
        <path
          d="M37.7437615,1.51423637 C39.4867845,-0.228786618 42.3127831,-0.228786618 44.0558061,1.51423637 C45.7988291,3.25725936 45.7988291,6.08325799 44.0558061,7.82628097 L44.0558061,7.82628097 L29.242294,22.6399691 L44.0558061,37.4534367 C45.7988291,39.1964597 45.7988291,42.0224583 44.0558061,43.7654813 C42.3127831,45.5085043 39.4867845,45.5085043 37.7437615,43.7654813 L22.930294,28.9519691 L8.1166058,43.7654813 C6.42805228,45.4540348 3.7231515,45.5068021 1.97107628,43.9237832 L1.80456119,43.7654813 C0.0615382043,42.0224583 0.0615382043,39.1964597 1.80456119,37.4534367 L1.80456119,37.4534367 L16.618294,22.6399691 L1.80456119,7.82628097 C0.0615382043,6.08325799 0.0615382043,3.25725936 1.80456119,1.51423637 C3.54758418,-0.228786618 6.37358281,-0.228786618 8.1166058,1.51423637 L22.930294,16.3279691 Z"
          id="cancel-appt-path-15"
        ></path>
      </defs>
      <g id="cancel-appt-Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="cancel-appt-Appointments-Copy-3" transform="translate(-662.000000, -237.000000)">
          <g id="cancel-appt-Group-7" transform="translate(663.000000, 238.000000)">
            <g id="cancel-appt-Group-3" transform="translate(24.744186, 0.000278)">
              <g id="cancel-appt-Rectangle-Copy-13">
                <use fill="#FFFFFF" xlinkHref="#cancel-appt-path-1"></use>
                <use
                  stroke="#32333C"
                  strokeWidth="1"
                  fillOpacity="0.35"
                  fill="#E74476"
                  xlinkHref="#cancel-appt-path-1"
                ></use>
              </g>
              <line
                x1="62.3025536"
                y1="58.7280559"
                x2="89.2619699"
                y2="58.7280559"
                id="cancel-appt-Line-2-Copy-4"
                stroke="#32333C"
                strokeLinecap="round"
              ></line>
              <line
                x1="45.8638851"
                y1="60.0515003"
                x2="72.8233014"
                y2="60.0515003"
                id="cancel-appt-Line-2-Copy-3"
                stroke="#32333C"
                strokeLinecap="round"
              ></line>
              <path
                d="M61.9008518,3.9705449 C64.6312246,3.9705449 66.8446292,6.19895876 66.8446292,8.94784657 L66.8446292,56.2322124 C66.8446292,58.9811002 64.6312246,61.2095141 61.9008518,61.2095141 L5.04741242,61.2095141 C2.31703959,61.2095141 0.103635084,58.9811002 0.103635084,56.2322124 L0.103635084,8.94784657 C0.103635084,6.19895876 2.31703959,3.9705449 5.04741242,3.9705449 L61.9008518,3.9705449 Z"
                id="cancel-appt-Rectangle-Copy"
                stroke="#32333C"
                fill="#FFFFFF"
              ></path>
              <g id="cancel-appt-Rectangle">
                <use fill="#FFFFFF" xlinkHref="#cancel-appt-path-2"></use>
                <use
                  stroke="#32333C"
                  strokeWidth="1"
                  fillOpacity="0.15"
                  fill="#E74476"
                  xlinkHref="#cancel-appt-path-2"
                ></use>
              </g>
              <g id="cancel-appt-Rectangle-Copy-2">
                <use fill="#FFFFFF" xlinkHref="#cancel-appt-path-3"></use>
                <use
                  stroke="#32333C"
                  strokeWidth="1"
                  fillOpacity="0.15"
                  fill="#E74476"
                  xlinkHref="#cancel-appt-path-3"
                ></use>
              </g>
              <g id="cancel-appt-Rectangle-Copy-14">
                <use fill="#FFFFFF" xlinkHref="#cancel-appt-path-4"></use>
                <use
                  stroke="#32333C"
                  strokeWidth="1"
                  fillOpacity="0.15"
                  fill="#E74476"
                  xlinkHref="#cancel-appt-path-4"
                ></use>
              </g>
              <g id="cancel-appt-Rectangle-Copy-4">
                <use fill="#FFFFFF" xlinkHref="#cancel-appt-path-5"></use>
                <use
                  stroke="#32333C"
                  strokeWidth="1"
                  fillOpacity="0.15"
                  fill="#E74476"
                  xlinkHref="#cancel-appt-path-5"
                ></use>
              </g>
              <g id="cancel-appt-Rectangle-Copy-3">
                <use fill="#FFFFFF" xlinkHref="#cancel-appt-path-6"></use>
                <use
                  stroke="#32333C"
                  strokeWidth="1"
                  fillOpacity="0.15"
                  fill="#E74476"
                  xlinkHref="#cancel-appt-path-6"
                ></use>
              </g>
              <g id="cancel-appt-Rectangle-Copy-8">
                <use fill="#FFFFFF" xlinkHref="#cancel-appt-path-7"></use>
                <use
                  stroke="#32333C"
                  strokeWidth="1"
                  fillOpacity="0.15"
                  fill="#E74476"
                  xlinkHref="#cancel-appt-path-7"
                ></use>
              </g>
              <g id="cancel-appt-Rectangle-Copy-6">
                <use fill="#FFFFFF" xlinkHref="#cancel-appt-path-8"></use>
                <use
                  stroke="#32333C"
                  strokeWidth="1"
                  fillOpacity="0.15"
                  fill="#E74476"
                  xlinkHref="#cancel-appt-path-8"
                ></use>
              </g>
              <g id="cancel-appt-Rectangle-Copy-5">
                <use fill="#FFFFFF" xlinkHref="#cancel-appt-path-9"></use>
                <use
                  stroke="#32333C"
                  strokeWidth="1"
                  fillOpacity="0.15"
                  fill="#E74476"
                  xlinkHref="#cancel-appt-path-9"
                ></use>
              </g>
              <g id="cancel-appt-Rectangle-Copy-12">
                <use fill="#FFFFFF" xlinkHref="#cancel-appt-path-10"></use>
                <use
                  stroke="#32333C"
                  strokeWidth="1"
                  fillOpacity="0.15"
                  fill="#E74476"
                  xlinkHref="#cancel-appt-path-10"
                ></use>
              </g>
              <g id="cancel-appt-Rectangle-Copy-11">
                <use fill="#FFFFFF" xlinkHref="#cancel-appt-path-11"></use>
                <use
                  stroke="#32333C"
                  strokeWidth="1"
                  fillOpacity="0.15"
                  fill="#E74476"
                  xlinkHref="#cancel-appt-path-11"
                ></use>
              </g>
              <g id="cancel-appt-Rectangle-Copy-10">
                <use fill="#FFFFFF" xlinkHref="#cancel-appt-path-12"></use>
                <use
                  stroke="#32333C"
                  strokeWidth="1"
                  fillOpacity="0.15"
                  fill="#E74476"
                  xlinkHref="#cancel-appt-path-12"
                ></use>
              </g>
              <g id="cancel-appt-Rectangle-Copy-9">
                <use fill="#FFFFFF" xlinkHref="#cancel-appt-path-13"></use>
                <use
                  stroke="#32333C"
                  strokeWidth="1"
                  fillOpacity="0.15"
                  fill="#E74476"
                  xlinkHref="#cancel-appt-path-13"
                ></use>
              </g>
              <g id="cancel-appt-Path">
                <use fill="#FFFFFF" xlinkHref="#cancel-appt-path-14"></use>
                <use
                  stroke="#32333C"
                  strokeWidth="1"
                  fillOpacity="0.15"
                  fill="#E74476"
                  xlinkHref="#cancel-appt-path-14"
                ></use>
              </g>
              <line
                x1="17.364237"
                y1="7.61001693"
                x2="17.364237"
                y2="0.0002117735"
                id="cancel-appt-Line"
                stroke="#32333C"
                strokeLinecap="round"
              ></line>
              <line
                x1="49.9128006"
                y1="7.61001693"
                x2="49.9128006"
                y2="0.0002117735"
                id="cancel-appt-Line-Copy"
                stroke="#32333C"
                strokeLinecap="round"
              ></line>
            </g>
            <line
              x1="13.6225561"
              y1="68.3723709"
              x2="50.6255059"
              y2="68.3723709"
              id="cancel-appt-Line-2"
              stroke="#32333C"
              strokeLinecap="round"
            ></line>
            <line
              x1="35.5968992"
              y1="64.8995027"
              x2="62.5116279"
              y2="64.8995027"
              id="cancel-appt-Line-2-Copy"
              stroke="#32333C"
              strokeLinecap="round"
            ></line>
            <g id="cancel-appt-Group-4" transform="translate(18.775194, 24.093301)">
              <g id="cancel-appt-Combined-Shape">
                <use fill="#FFFFFF" xlinkHref="#cancel-appt-path-15"></use>
                <use
                  stroke="#32333C"
                  strokeWidth="0.7"
                  fillOpacity="0.65"
                  fill="#E74476"
                  xlinkHref="#cancel-appt-path-15"
                ></use>
              </g>
            </g>
            <line
              x1="-2.92616921e-17"
              y1="64.8995027"
              x2="13.8914729"
              y2="64.8995027"
              id="cancel-appt-Line-2-Copy-2"
              stroke="#32333C"
              strokeLinecap="round"
            ></line>
          </g>
        </g>
      </g>
    </svg>
  );
  /* eslint-enable */
}

export default SvgCancelAppointment;
