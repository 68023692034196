import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '../../storybook/src/utils/theme';

function SvgCalendarCheck({ theme }) {
  /* eslint-disable */
  return (
    <svg aria-hidden="true" focusable="false" width="32px" height="32px" viewBox="0 0 32 32" version="1.1" className="svg-icon svg-calendar-check">
      <defs>
        <path d="M6.454,1.229 L2.996,4.688 L1.832,3.523 C1.429,3.121 0.775,3.121 0.372,3.523 C-0.03,3.926 -0.03,4.58 0.372,4.983 L1.536,6.147 L2.267,6.876 C2.669,7.279 3.322,7.279 3.726,6.876 L4.454,6.147 L7.913,2.69 C8.316,2.285 8.316,1.633 7.913,1.23 L7.913,1.229 C7.712,1.028 7.448,0.927 7.184,0.927 C6.919,0.927 6.656,1.028 6.454,1.229 Z" id="path-1"></path>
        <path d="M6.454,1.229 L2.996,4.688 L1.832,3.523 C1.429,3.121 0.775,3.121 0.372,3.523 C-0.03,3.926 -0.03,4.58 0.372,4.983 L1.536,6.147 L2.267,6.876 C2.669,7.279 3.322,7.279 3.726,6.876 L4.454,6.147 L7.913,2.69 C8.316,2.285 8.316,1.633 7.913,1.23 L7.913,1.229 C7.712,1.028 7.447,0.927 7.183,0.927 C6.92,0.927 6.655,1.028 6.454,1.229 Z" id="path-3"></path>
        <path d="M5,0 L27,0 C29.756,0 32,2.243 32,5 L32,14 C32,14.552 31.553,15 31,15 C30.448,15 30,14.552 30,14 L30,10 L2,10 L2,27 C2,28.654 3.346,30 5,30 L13,30 C13.553,30 14,30.448 14,31 C14,31.552 13.553,32 13,32 L5,32 C2.243,32 0,29.756 0,27 L0,5 C0,2.243 2.243,0 5,0 Z M2,5 L2,8 L30,8 L30,5 C30,3.347 28.654,1.999 27,1.999 L5,1.999 C3.346,1.999 2,3.347 2,5 Z" id="path-5"></path>
      </defs>
      <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="checkin-patient" transform="translate(-184.000000, -168.000000)">
          <g id="icon">
            <g transform="translate(184.000000, 168.000000)">
              <path d="M25.4541,20.2292 L21.9961,23.6872 L20.8321,22.5232 C20.4291,22.1212 19.7751,22.1212 19.3721,22.5232 C18.9701,22.9262 18.9701,23.5802 19.3721,23.9832 L20.5361,25.1472 L21.2671,25.8762 C21.6691,26.2792 22.3221,26.2792 22.7251,25.8762 L23.4541,25.1472 L26.9131,21.6892 C27.3161,21.2862 27.3161,20.6322 26.9131,20.2302 L26.9131,20.2292 C26.5101,19.8272 25.8561,19.8272 25.4541,20.2292" id="Fill-1" fill={theme.secondaryColor} ></path>
              <g id="Group-5" transform="translate(19.000000, 19.000000)">
                <mask id="mask-2" fill="white">
                  <use xlinkHref="#path-1"></use>
                </mask>
                <g id="Clip-4"></g>
                <polygon id="Fill-3" fill={theme.secondaryColor} mask="url(#mask-2)" points="-243.857 271 252.143 271 252.143 -113 -243.857 -113"></polygon>
              </g>
              <g id="Group-8" transform="translate(19.000000, 19.000000)">
                <mask id="mask-4" fill="white">
                  <use xlinkHref="#path-3"></use>
                </mask>
                <g id="Clip-7"></g>
                <polygon id="Fill-6" fill={theme.secondaryColor} mask="url(#mask-4)" points="-2.857 10.106 11.143 10.106 11.143 -2 -2.857 -2"></polygon>
              </g>
              <path d="M22.9424,13.8845 C17.9394,13.8845 13.8834,17.9395 13.8834,22.9425 C13.8834,27.9455 17.9394,31.9995 22.9424,31.9995 C27.9444,31.9995 32.0004,27.9455 32.0004,22.9425 C32.0004,17.9395 27.9444,13.8845 22.9424,13.8845 M22.9424,15.8845 C26.8344,15.8845 30.0004,19.0505 30.0004,22.9425 C30.0004,26.8335 26.8344,29.9995 22.9424,29.9995 C19.0504,29.9995 15.8834,26.8335 15.8834,22.9425 C15.8834,19.0505 19.0504,15.8845 22.9424,15.8845" id="Fill-9" fill={theme.secondaryColor} ></path>
              <g id="Group-13">
                <mask id="mask-6" fill="white">
                  <use xlinkHref="#path-5"></use>
                </mask>
                <g id="Clip-12"></g>
                <polygon id="Fill-11" fill={theme.mainColor} mask="url(#mask-6)" points="-5 37 37 37 37 -5 -5 -5"></polygon>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
  /* eslint-enable */
}

SvgCalendarCheck.propTypes = {
  theme: PropTypes.object.isRequired,
};

export default withTheme(SvgCalendarCheck);
