/**
 * Created by chenrozenes on 04/07/2017.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, defineMessages } from 'react-intl';
import { autobind } from 'core-decorators';
import { compose } from 'redux';
import { connect } from 'react-redux';
import Joi from 'joi-browser';
import _ from 'lodash';
import CleanPhoneNumber from '../../../../../../components/form/cleanPhoneNumber';
import ShareDoctorHeader from './shareDoctorHeader';
import ModalHeader from '../../../../../../components/bkmdModal/modalHeader';
import shareMessages from './messages';
import { shareViaSms, SHARE_VIA_SMS } from '../../../../../../store/directory/provider/actions';
import * as Errors from '../../../../../../api/errors';
import { PhoneNumberSchema } from '../../../../../../utils/commonSchema';
import actionTracker from '../../../../../../store/tools/actionTracker/actionTrackerComponent';
import injectNotification from '../../../../../../store/notification/injectNotification';
import SpinnerButton from '../../../../../../components/ui/spinner/spinnerButton';
import { domain } from '../../../../../../store/config/selectors';

const messages = defineMessages({
  yourFriendPhoneNumber: {
    defaultMessage: "Your friend's phone number",
    id: 'members.shareModal.sms.yourFriendPhoneNumber',
  },
  smsSuccessText: {
    defaultMessage: 'Text message sent successfully',
    id: 'members.shareModal.sms.smsSuccessText',
  },
  invalidPhoneNumber: {
    defaultMessage: 'Please provide a valid phone number',
    id: 'members.shareModal.sms.invalidPhoneNumber',
  },
});

/**
 * Share via SMS step in the share provider modal
 */
@autobind
class ShareDoctorBySms extends React.Component {
  static propTypes = {
    shareAction: PropTypes.object.isRequired,
    shareViaSms: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired,
    notification: PropTypes.object.isRequired,
    providerLocationId: PropTypes.number,
    wizard: PropTypes.object.isRequired,
    provider: PropTypes.object.isRequired,
    domain: PropTypes.string.isRequired,
  };

  static defaultProps = {
    providerLocationId: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      phoneNumber: {},
      error: null,
      blurred: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.shareAction.finished) this.handleActionResult(nextProps.shareAction);
  }

  onShareClick() {
    const { provider, providerLocationId } = this.props;
    const location = _.find(provider.locations, { id: providerLocationId });
    this.props.shareViaSms(this.state.phoneNumber, provider.npi, providerLocationId, location.geo);
  }

  onBlur() {
    this.setState({ blurred: true });
  }

  onBackClick() {
    this.props.wizard.back(0);
  }

  onInputChange(val) {
    const hasError = Joi.validate(val, PhoneNumberSchema).error;
    this.setState({ phoneNumber: val, error: hasError ? messages.invalidPhoneNumber : null });
  }

  handleActionResult(tracking) {
    const { notification, handleClose, wizard } = this.props;
    if (tracking.hasError) {
      switch (tracking.error.responseMessage) {
        case Errors.USER_SHARE_BLOCKED: {
          notification.error(shareMessages.shareFailedTitle, shareMessages.shareFailedUserBlocked);
          break;
        }
        case Errors.ADDRESS_SHARE_DENIED: {
          notification.error(
            shareMessages.shareFailedTitle,
            shareMessages.shareFailedAddressBlocked,
          );
          break;
        }
        default: {
          notification.error(
            shareMessages.shareFailedTitle,
            shareMessages.shareFailedGeneralMessage,
          );
          break;
        }
      }
    } else {
      notification.success(shareMessages.success, messages.smsSuccessText);
      wizard.advance();
      handleClose();
    }
  }

  render() {
    const { handleClose, shareAction, provider } = this.props;
    return (
      <div>
        <ModalHeader
          onClose={handleClose}
          title={shareMessages.shareThisDoctor}
          onBack={this.onBackClick}
        />
        <div className="dialog-body">
          <ShareDoctorHeader provider={provider} />
          <div>
            <div className="row margin-bottom-clean-form">
              <div className="col-xs-12 col-sm-10 col-sm-offset-1">
                <CleanPhoneNumber
                  label={messages.yourFriendPhoneNumber}
                  className="no-margin"
                  inputClassName="no-margin"
                  value={this.state.phoneNumber}
                  onChange={this.onInputChange}
                  onBlur={this.onBlur}
                  errorText={this.state.blurred ? this.state.error : null}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12 col-sm-10 col-sm-offset-1">
                <SpinnerButton
                  className="btn btn-big bg-color-brand-button"
                  onClick={this.onShareClick}
                  isLoading={shareAction.inProgress}
                  disabled={!!this.state.error || _.isEmpty(this.state.phoneNumber)}
                >
                  <FormattedMessage {...shareMessages.send} />
                </SpinnerButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default compose(
  injectNotification,
  actionTracker({
    shareAction: SHARE_VIA_SMS.SOURCE,
  }),
  connect(
    state => ({ domain: domain(state) }),
    { shareViaSms },
  ),
)(ShareDoctorBySms);
