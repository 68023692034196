import AppointmentRoutes from '../../appointments/memberAppointmentsRoutes';

class BookingRouteStrategy {
  static predicate({ features }) {
    return features.booking;
  }

  static getRoutes() {
    return [...AppointmentRoutes];
  }
}

export default BookingRouteStrategy;
