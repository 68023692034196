/* eslint-disable import/first */
if (typeof require.ensure !== 'function') require.ensure = (d, c) => c(require);

import Home from '../home/home';
import divComponent from './divComponent';
import uhcAllSaversHelp from '../help/uhcAllsaversHelpContent';
import help from '../help/helpPage';
import { pathsWithQuery } from '../../../../utils/pathsWithQuery';
import DirectoryRoutes from './directoryRoutes';

export const paths = {
  home: qsParams => pathsWithQuery('/public', qsParams),
  searchDoctors: qsParams => pathsWithQuery('/public/directory/searchDoctors', qsParams),
  doctorDetails: (npi, qsParams) =>
    pathsWithQuery(`/public/directory/doctorDetails/${npi}`, qsParams),
  searchDoctorsResults: qsParams =>
    pathsWithQuery('/public/directory/searchDoctorsResults', qsParams),
  facilitySearchResults: qsParams => pathsWithQuery('/public/facilitySearchResults', qsParams),
  help: qsParams => pathsWithQuery('/public/contactVim', qsParams),
  premeraHelp: qsParams => pathsWithQuery('/public/premeraHelp', qsParams),
  uhcAllsaversHelp: qsParams => pathsWithQuery('/public/uhcAllSaversHelp', qsParams),
  anthemHelp: qsParams => pathsWithQuery('/public/anthemHelp', qsParams),
  floridaHelp: qsParams => pathsWithQuery('/public/floridaHelp', qsParams),
};

// Routes
const facilitySearchResults = require('../directory/facilitySearchResults/facilitySearchResults');

// Components
const Components = [
  ...DirectoryRoutes,
  {
    path: 'facilitySearchResults',
    name: 'facilitySearchResults',
    component: facilitySearchResults.default || facilitySearchResults,
  },
  {
    path: 'uhcAllSaversHelp',
    name: 'uhcAllSaversHelp',
    component: uhcAllSaversHelp.default || uhcAllSaversHelp,
  },
  {
    path: 'contactVim',
    name: 'contactVim',
    component: help.default || help,
  },
];

export default [
  {
    path: 'public',
    name: 'public',
    getChildRoutes(location, cb) {
      require.ensure([], () => {
        cb(null, Components);
      });
    },
    component: divComponent,
    indexRoute: {
      name: 'Home',
      component: Home.default || Home,
    },
  },
];
/* eslint-enable import/first */
