/**
 * Created by chenrozenes on 19/10/2017.
 */

/**
 * Haitian Creole is missing in react-intl and in the package it uses to get its locale data.
 * This is used for faking the haitian locale data so react-intl will work
 *
 * https://github.com/yahoo/react-intl/issues/1050
 */
export default [{
  locale: 'ht',
  pluralRuleFunction(e, t) {
    return t && e === 1 ? 'one' : 'other';
  },
  fields: {},
}];
