import PropTypes from 'prop-types';
import _ from 'lodash';
import React from 'react';
import { compose, setDisplayName, setPropTypes, withProps, withStateHandlers } from 'recompose';

import { defineMessages } from 'react-intl';
import ProviderList from '../../components/providerList/providerList';

import { FlowShape } from '../../components/wizard/flow/shapes';

import { withTranslatedMessages } from '../../utils/withTranslatedMessages';
import withBookingModal from '../../components/bookingModal/withBookingModal';
import * as BookingType from '../../model/enum/bookingType';
import PCPSelectionActions from '../../components/pcpSelection/components/pcpSelectionActions';
import './reviewSelectedPCP.less';

const messages = defineMessages({
  title: {
    defaultMessage: '{ name } is your primary care doctor',
    id: 'members.public.pcpSelection.reviewSelectedPCP.title',
  },
  promotionPreBooking: {
    defaultMessage: 'Book now for a $25 gift card to thank you for taking care of your health.',
    id: 'members.public.pcpSelection.reviewSelectedPCP.promotionPreBooking',
  },
  promotionPostBooking: {
    defaultMessage: 'Thank you for booking! Your gift card will arrive after your visit.',
    id: 'members.public.pcpSelection.reviewSelectedPCP.promotionPostBooking',
  },
});

function ReviewSelectedPCP({
  provider,
  selectedLocation,
  member: { plan, memberContext },
  title,
  onBookClick,
  messageBannerText,
  showMessageBanner,
}) {
  return (
    <div>
      <div>
        <h1 className="title-24 margin-top-30 padding-h-30 text-center">
          <i className="fa fa-check font-color-brand-secondary" />
          &nbsp;
          <span className="title-brand-1">{title}</span>
        </h1>
        <ProviderList
          className="selected-pcp"
          plan={plan}
          memberContext={memberContext}
          providers={_.castArray({
            ...provider,
            selectedLocationId: selectedLocation,
          })}
          onBookClick={onBookClick}
          bookingType={BookingType.REQUEST}
          messageBannerText={messageBannerText}
          showMessageBanner={showMessageBanner}
          staticLocation
          renderActions={(provider, location) => (
            <PCPSelectionActions
              memberContext={memberContext}
              provider={provider}
              plan={plan}
              showUpdate={false}
              selectedLocation={location.id}
            />
          )}
          showBook
        />
      </div>
    </div>
  );
}

ReviewSelectedPCP.propTypes = {
  messageBannerText: PropTypes.string.isRequired,
  onBookClick: PropTypes.func.isRequired,
  provider: PropTypes.object.isRequired,
  selectedLocation: PropTypes.object.isRequired,
  member: PropTypes.object.isRequired,
  showMessageBanner: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
};

export default compose(
  setDisplayName('ReviewSelectedPCP'),
  setPropTypes({
    control: FlowShape.isRequired,
    provider: PropTypes.object.isRequired,
    selectedLocation: PropTypes.number.isRequired,
    member: PropTypes.object.isRequired,
  }),
  withProps({
    showMessageBanner: false,
  }),
  withTranslatedMessages(messages, ({ provider }) => ({
    name: provider.firstName,
  })),
  withStateHandlers(
    ({ promotionPreBooking }) => ({
      messageBannerText: promotionPreBooking,
    }),
    {
      onBookingSuccess: (state, { promotionPostBooking }) => () => ({
        messageBannerText: promotionPostBooking,
      }),
    },
  ),
  withBookingModal(({ onBookingSuccess, showMessageBanner, messageBannerText }) => ({
    onBookingSuccess,
    showMessageBanner,
    messageBannerText,
  })),
)(ReviewSelectedPCP);
