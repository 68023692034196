import { compose, withPropsOnChange } from 'recompose';
import _ from 'lodash';
import { connect } from 'react-redux';
import uuid from 'uuid';
import * as FlagTypes from './flagTypes';
import { getUser } from '../../store/auth/selectors';
import FeatureFlagClient from './featureFlagClient';

const noContext = () => ({});

const anonymousUserId = `ANONYMOUS_${uuid.v4()}`;

const convertReduxUser = reduxUser => {
  if (!reduxUser || _.isEmpty(reduxUser)) {
    return { key: anonymousUserId, anonymous: true };
  }

  const tags = _.reduce(
    reduxUser.tags,
    (result, tag) => {
      result[`tag_${tag}`] = true;
      return result;
    },
    {},
  );
  return { ...tags, key: `REGISTERED_${reduxUser.id}` };
};

const getAppName = state => _.get(state, 'config.appName');
const getEnvironment = state => _.get(state, 'config.APP_ENV');

export default ({ key, prop, defaultValue, context = noContext, type = FlagTypes.Boolean }) =>
  compose(
    connect((state, props) => {
      const reduxUser = getUser(state);
      const appName = getAppName(state);
      const environment = getEnvironment(state);
      const user = convertReduxUser(reduxUser);
      const globalContext = { ...user, appName, environment };
      const localContext = context(props);

      return { flagContext: { ...globalContext, ...localContext } };
    }),
    withPropsOnChange(
      (oldProps, newProps) => {
        const { flagContext: oldFlagContext } = oldProps;
        const { flagContext } = newProps;
        return !_.isEqual(oldFlagContext, flagContext);
      },
      ({ flagContext }) => {
        const value = FeatureFlagClient.variation(key, defaultValue, flagContext, type);
        return {
          [prop]: value,
        };
      },
    ),
  );
