import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { getDomain } from '../../../../store/feature/selectors';
import PremeraResources from './premera/premeraResources';
import * as domains from '../../../../model/enum/bkmdDomain';

const domainResources = {
  [domains.PREMERA_BLUE_CROSS_ALASKA]: PremeraResources,
};

class DomainResource extends React.PureComponent {
  static propTypes = {
    domain: PropTypes.string.isRequired,
    className: PropTypes.string,
  };

  static defaultProps = {
    className: '',
  };

  render() {
    const { className, domain } = this.props;
    if (!domain) return null;

    const Component = domainResources[domain];
    return <Component className={className} />;
  }
}

export default compose(
  connect(state => ({
    domain: getDomain(state),
  })),
)(DomainResource);
