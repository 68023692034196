/**
 * Created by matan on 3/15/17.
 */
import React from 'react';
import PropTypes from 'prop-types';

import analytics from '../../store/tools/analytics/analyticsHoc';
import CleanInput from './cleanInput';
import { PasswordMeter } from '../ui/passwordMeter';
import { PasswordFeatureMeter } from '../ui/passwordFeatureMeter';
export const MINIMUM_PASSWORD_STRENGTH = 2;

class CleanPasswordInput extends React.Component {
  static propTypes = {
    /**
     * Component optional outer class name
     */
    className: PropTypes.string,

    /**
     * Currently inputed password
     */
    value: PropTypes.string,

    // PasswordMeter props
    hideHelpers: PropTypes.bool,

    /**
     * An array of string of other user-inputted data.
     * Helps zxcvbn defend against easy to guess passwords
     */
    userInputs: PropTypes.arrayOf(PropTypes.string),
    /**
     * Boolean flag, indicating if zxcvbn password improvement suggestions are displayed or not
     * Default is false
     */
    showSuggestions: PropTypes.bool,
    /**
     * Hook method, called each time the password strength has changed.
     * (Note that method is called each time the STRENGTH is changed not the password itself)
     */
    onStrengthChanged: PropTypes.func,

    /**
     * NOTE: you can also pass in all other props excepted by CleanInput
     */
  };

  static defaultProps = {
    className: '',
    userInputs: [],
    showSuggestions: false,
    hideHelpers: false,
    onStrengthChanged: undefined,
    value: '',
  };

  constructor(props) {
    super(props);
    this.state = { showPassword: false, showRepeatPassword: false, repeatPasswordValue: null };

    this.inputIconClick = this.inputIconClick.bind(this);
  }

  inputIconClick(eyeType) {
    this.setState({ [eyeType]: !this.state[eyeType] });
  }

  changePasswordVisibility(eyeType) {
    return this.state[eyeType] ? 'text' : 'password';
  }

  render() {
    const {
      className,
      value,
      userInputs,
      showSuggestions,
      onStrengthChanged,
      onRepeatPasswordChanged,
      hideHelpers,
      withFeatureFlag,
      verifyPasswordField,
      ...other
    } = this.props;
    const { showPassword } = this.state;
    return (
      <div className={className}>
        <CleanInput
          {...other}
          type={this.changePasswordVisibility('showPassword')}
          value={value}
          rightIcon={!hideHelpers ? 'icon-eye' : ''}
          rightIconClick={() => this.inputIconClick('showPassword')}
          rightIconType="button"
          name="cleanPassword"
          rightIconLabel="Show password"
          rightIconState={showPassword}
        />
        {withFeatureFlag && verifyPasswordField && verifyPasswordField.props && (
          <div className={`${verifyPasswordField.props.className}`}>
            {verifyPasswordField.props.children}
          </div>
        )}
        {!hideHelpers &&
          (withFeatureFlag ? (
            <PasswordFeatureMeter
              password={value}
              userInputs={userInputs}
              showSuggestions={showSuggestions}
              onStrengthChanged={onStrengthChanged}
            />
          ) : (
            <PasswordMeter
              password={value}
              userInputs={userInputs}
              showSuggestions={showSuggestions}
              onStrengthChanged={onStrengthChanged}
            />
          ))}
      </div>
    );
  }
}

export default analytics(CleanPasswordInput, false);
