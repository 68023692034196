/**
 * Created by moran on 6/5/17.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import CleanCheckbox from '../form/cleanCheckbox';
import CleanTimeSelect from '../form/cleanTimeSelect';
import MessageShape from '../ui/intlString/messageShape';

import './openingHoursSelector.less';

@autobind
class HoursSelector extends React.Component {
  static propTypes = {
    /**
     * the our it opens. defaults to 09:00
     */
    open: PropTypes.string,
    /**
     * the our it closes. defaults to 17:00
     */
    close: PropTypes.string,
    /**
     * the earliest possible hour to select from. default to 06:00
     */
    startTime: PropTypes.string,
    /**
     * the latest possible hour to select from. defaults to 23:00
     */
    endTime: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    day: PropTypes.string.isRequired,
    isOpen: PropTypes.bool,
    label: MessageShape.isRequired,
  };

  static defaultProps = {
    open: '09:00',
    close: '17:00',
    isOpen: false,
    startTime: '06:00',
    endTime: '23:01',
  };

  onStartChange(open) {
    const { close, day } = this.props;
    this.props.onChange({ open, close, day, isOpen: true });
  }

  onEndChange(close) {
    const { open, day } = this.props;
    this.props.onChange({ open, close, day, isOpen: true });
  }

  onIsOpenChange() {
    const { day, isOpen, open, close } = this.props;
    this.props.onChange({ day, close, open, isOpen: !isOpen });
  }


  render() {
    const { isOpen, open, close, label, day, startTime, endTime } = this.props;
    return (<div>
      <div className="select-time">
        <div className="row">
          <div className="col-xs-3">
            <CleanCheckbox
              className="hours-line-height"
              name="openingHoursSelector"
              id={day}
              value={isOpen}
              label={label}
              onChange={this.onIsOpenChange}
            />
          </div>

          <div className="col-xs-4">
            <CleanTimeSelect
              onChange={this.onStartChange}
              value={open}
              startTime={startTime}
              endTime={endTime}
              disabled={!isOpen}
              name="OPEN_HOURS"
            />
          </div>
          <div className="col-xs-1 hours-line-height">
            To
          </div>
          <div className="col-xs-4">
            <CleanTimeSelect
              onChange={this.onEndChange}
              value={close}
              startTime={startTime}
              endTime={endTime}
              disabled={!isOpen}
              name="OPEN_HOURS"
            />
          </div>
        </div>
      </div>
    </div>);
  }
}

export default HoursSelector;

