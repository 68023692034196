import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import { connect } from 'react-redux';
import { compose, withHandlers, withPropsOnChange } from 'recompose';

import PageNotFound from '../../../../../components/pages/notfound/pageNotFound';
import setInQueryString from '../../../../../store/tools/queryString/setInQueryString';
import { GET_PROVIDER, getDoctorDetails } from '../../../../../store/directory/provider/actions';
import { ThreeBounceSpinner } from '../../../../../components/ui/spinner/index';
import ProviderVideoModal from '../../../../../components/doctorDetails/sections/modals/providerVideoModal';
import { getCurrentLocation } from '../../../../../store/location/actions';
import { getProviders } from '../../../../../store/directory/provider/selectors';
import { getDomain, getFeature } from '../../../../../store/feature/selectors';
import { member, memberContext, memberPlan } from '../../../../../store/member/selectors';
import { isMobileOrTablet } from '../../../../../store/platform/selectors';
import { isPublicDirectory } from '../../../../../store/config/selectors';
import withBookingModal from '../../../../../components/bookingModal/withBookingModal';
import * as BookingType from '../../../../../model/enum/bookingType';

import actionTracker, {
  actionTrackerShape,
} from '../../../../../store/tools/actionTracker/actionTrackerComponent';
import { chooseClientLocation, isLocationReady } from '../directorySelectors';

import { clientLocationShape } from '../../../../../components/searchDoctors/clientLocationShape';
import { isAuthenticated } from '../../../../../store/auth/selectors';
import '../../../../../components/doctorDetails/doctorDetails.less';
import {
  createToggleState,
  createToggleStatePropTypes,
} from '../../../../../components/recompose/toggleStateComponent';
import DoctorDetails from '../../../../../components/doctorDetails/doctorDetails';
import ActionButtons from './sections/actionButtons';
import NetworkStatusWithFetch from '../../../../../components/doctorCard/sections/network/networkStatusWithFetch';
import withFeatureFlag from '../../../../../utils/featureFlags/withFeatureFlag';
import * as NetworkStatus from '../../../../../model/enum/networkStatus';
import { loadingUntil } from '../../../../../components/recompose/loadingUntil';

const getSelectedBookingType = (provider, locationId) => {
  if (_.isEmpty(provider) || !locationId) return null;
  const location = _.find(provider.locations, { id: locationId });
  return _.get(location, 'bookingType', BookingType.INSTANT);
};

@autobind
class DirectoryDoctorDetails extends React.Component {
  static propTypes = {
    details: PropTypes.object,
    isMobile: PropTypes.bool.isRequired,
    clientLocation: PropTypes.shape(clientLocationShape).isRequired,
    getDoctorDetailsTracker: PropTypes.shape(actionTrackerShape),
    setInQueryString: PropTypes.func.isRequired,
    getDoctorDetails: PropTypes.func.isRequired,
    memberPlan: PropTypes.string.isRequired,
    address: PropTypes.string,
    filters: PropTypes.object,
    validLocation: PropTypes.object.isRequired,
    locationId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    clearAppointmentType: PropTypes.func.isRequired,
    clearAppointmentTime: PropTypes.func.isRequired,
    clearBookedAppointment: PropTypes.func.isRequired,
    bookingFlag: PropTypes.bool.isRequired,
    npi: PropTypes.string.isRequired,
    enableBooking: PropTypes.bool,
    isAuthenticated: PropTypes.bool.isRequired,
    onlyAuthenticatedBooking: PropTypes.bool.isRequired,
    openBookingModal: PropTypes.bool.isRequired,
    isPublicDirectory: PropTypes.bool,
    onShareSuccess: PropTypes.func,
    onBookSuccess: PropTypes.func,
    ...createToggleStatePropTypes('bookingModal'),
    ...createToggleStatePropTypes('videoModal'),
  };

  static defaultProps = {
    address: '',
    locationId: null,
    getDoctorDetailsTracker: {},
    details: {},
    filters: { distance: null },
    enableBooking: true,
    isPublicDirectory: false,
    onShareSuccess: _.noop,
    onBookSuccess: _.noop,
  };

  onBookClicked() {
    const {
      isAuthenticated,
      onlyAuthenticatedBooking,
      goToSecureDoctorDetails,
      bookingModalShow,
    } = this.props;

    if (onlyAuthenticatedBooking && !isAuthenticated) {
      goToSecureDoctorDetails();
    } else {
      bookingModalShow();
    }
  }

  onBookingModalClose() {
    this.props.bookingModalClose();
  }

  onLocationChanged(locationId) {
    this.props.setInQueryString({ locationId });
  }

  renderActions() {
    const { details, enableBooking, locationId, onShareSuccess, onBookSuccess } = this.props;
    const pickedLocation = _.find(details.locations, { id: locationId });
    return (
      <ActionButtons
        provider={details}
        pickedLocation={pickedLocation}
        onBookAppointmentClicked={this.onBookClicked}
        enableBooking={enableBooking}
        onShareSuccess={onShareSuccess}
        onBookSuccess={onBookSuccess}
      />
    );
  }

  renderBody() {
    const { isPublicDirectory, isInNetworkCustomComponent } = this.props;
    return (
      <DoctorDetails
        {...this.props}
        onLocationChanged={this.onLocationChanged}
        renderActions={this.renderActions}
        showNetworkStatus={!isPublicDirectory}
        isInNetworkCustomComponent={isInNetworkCustomComponent}
      />
    );
  }

  render() {
    const {
      details,
      getDoctorDetailsTracker,
      locationId,
      validLocation,
      videoModalOn,
      videoModalHide,
    } = this.props;

    if (
      _.isEmpty(getDoctorDetailsTracker) ||
      getDoctorDetailsTracker.inProgress ||
      _.isEmpty(validLocation)
    ) {
      return <ThreeBounceSpinner />;
    }
    if (getDoctorDetailsTracker.finished && !_.get(details, 'npi')) return <PageNotFound />;
    const picked = _.find(details.locations, { id: locationId });
    if (!picked) return <div />;
    return (
      <div>
        {this.renderBody()}
        <ProviderVideoModal
          isOpen={videoModalOn}
          handleClose={videoModalHide}
          autoplay={this.props.isMobile}
          provider={details}
        />
      </div>
    );
  }
}

export default compose(
  connect(
    (state, ownProps) => ({
      details: _.get(getProviders(state), ownProps.npi),
      clientLocation: ownProps.clientLocation || chooseClientLocation(state),
      bookingFlag: getFeature(state, 'booking'),
      memberPlan: memberPlan(state),
      member: member(state),
      memberDomain: getDomain(state),
      memberContext: memberContext(state),
      isMobile: isMobileOrTablet(state),
      validLocation: isLocationReady(state),
      isAuthenticated: isAuthenticated(state),
      onlyAuthenticatedBooking: getFeature(state, 'doctorDetails.onlyAuthenticatedBooking'),
      isPublicDirectory: isPublicDirectory(state),
      onlyInNetworkDomainConfig: getFeature(state, 'search.onlyInNetwork', false),
    }),
    {
      getDoctorDetails,
      getCurrentLocation,
      setInQueryString,
    },
  ),
  withFeatureFlag({
    key: 'premera.providers.onlyInNetwork',
    prop: 'onlyInNetworkFeatureFlag',
    defaultValue: false,
  }),
  createToggleState('videoModal'),
  actionTracker({
    getDoctorDetailsTracker: GET_PROVIDER.SOURCE,
  }),
  withBookingModal(),
  withHandlers({
    bookingModalShow: ({ onBookClick, details, locationId }) => () =>
      onBookClick(details, locationId, getSelectedBookingType(details, locationId)),
    isInNetworkCustomComponent: ({ member, memberDomain }) => outerProps => {
      const { domainMemberId } = member;
      const { location, npi } = outerProps;
      return (
        <NetworkStatusWithFetch
          externalMemberId={domainMemberId}
          externalMemberDomain={memberDomain}
          location={location}
          npi={npi}
        />
      );
    },
  }),
  withPropsOnChange(['npi'], ({ npi, getDoctorDetails, member }) => {
    getDoctorDetails(npi, member.memberToken);
  }),
  loadingUntil(({ details }) => !_.isEmpty(details)),
  withPropsOnChange(
    ['details', 'locationId'],
    ({ details, onlyInNetworkFeatureFlag, onlyInNetworkDomainConfig, locationId }) => {
      const onlyInNetWork = onlyInNetworkFeatureFlag && onlyInNetworkDomainConfig;
      const filteredDetails = {
        ...details,
        locations: onlyInNetWork
          ? _.filter(
              details.locations,
              location => location.network.status === NetworkStatus.IN_NETWORK,
            )
          : details.locations,
      };
      return {
        details: filteredDetails,
        locationId: locationId || _.get(filteredDetails, 'locations[0].id'),
      };
    },
  ),
  withPropsOnChange(
    ['openBookingModal'],
    ({ openBookingModal, isAuthenticated, bookingModalShow }) => {
      if (isAuthenticated && openBookingModal) {
        bookingModalShow();
      }
    },
  ),
)(DirectoryDoctorDetails);
