import Immutable from 'seamless-immutable';
import _ from 'lodash';

import {
  UPLOAD_FILE,
  UPLOAD_PROGRESS,
  ABORT_UPLOAD,
  FORCE_ERROR,
  DROP_UPLOAD_BY_ID,
  CLEAR,
  GET_FILE_METADATA,
} from './actions';

const initialState = {};

export const OPEN_FILE_BY_URL = 'OPEN_FILE_BY_URL';

export default function files(state = initialState, action) {
  state = Immutable(state);
  const managerName = _.get(action, 'payload.managerName');
  switch (action.type) {
    case `${UPLOAD_FILE}_START`: {
      return state.setIn([managerName, 'uploading', action.payload.id], {
        _id: action.payload.id,
        name: action.payload.file.name,
        _abort: action.payload.upload.abortUpload.bind(action.payload.upload),
        progress: 0,
        error: false,
      });
    }
    case UPLOAD_PROGRESS: {
      let upload = state[managerName].uploading[action.payload.id];
      if (upload) {
        upload = upload
          .set('progress', action.payload.percent)
          .set('status', action.payload.status);
        state = state.setIn([managerName, 'uploading', upload._id], upload);
      }
      return state;
    }
    case `${UPLOAD_FILE}_ERROR`: {
      let upload = state[managerName].uploading[action.payload.id];
      if (upload) {
        upload = upload
          .set('status', action.payload.status)
          .set('error', true)
          .set('progress', -1);
        state = state.setIn([managerName, 'uploading', upload._id], upload);
      }

      return state;
    }
    case ABORT_UPLOAD: {
      const upload = state[managerName].uploading[action.payload.id];
      if (upload) {
        upload._abort();
        state = state.setIn(
          [managerName, 'uploading'],
          state[managerName].uploading.without(action.payload.id),
        );
      }
      return state;
    }
    case `${UPLOAD_FILE}_SUCCESS`: {
      let upload = state[managerName].uploading[action.payload.id];
      if (upload) {
        upload = upload.set('progress', 100).set('status', 'completed');
        state = state.setIn(
          [managerName, 'uploading'],
          state[managerName].uploading.without(upload._id),
        );
        state = state.setIn([managerName, 'completed', upload._id], upload);
      }

      return state;
    }
    case FORCE_ERROR: {
      let upload = state[managerName].completed[action.payload.id];
      state = state.setIn(
        [managerName, 'completed'],
        state[managerName].completed.without(action.payload.id),
      );
      if (state[managerName].uploading[action.payload.id]) {
        upload = state[managerName].uploading[action.payload.id];
        state[managerName].uploading[action.payload.id]._abort();
      }
      if (upload) {
        upload = upload
          .set('state', 'Forced Error')
          .set('error', true)
          .set('progress', -1);
        state = state.setIn([managerName, 'uploading', upload._id], upload);
      }
      return state;
    }
    case DROP_UPLOAD_BY_ID: {
      const managerState = state[managerName];
      const uploadingIds = _.filter(action.payload.ids, id => !!managerState.uploading[id]);
      _.each(uploadingIds, uploadId => {
        managerState.uploading[uploadId]._abort();
      });

      if (managerState.uploading) {
        state = state.setIn(
          [managerName, 'uploading'],
          managerState.uploading.without(action.payload.ids),
        );
      }

      if (managerState.completed) {
        state = state.setIn(
          [managerName, 'completed'],
          managerState.completed.without(action.payload.ids),
        );
      }

      return state;
    }
    case CLEAR: {
      // Abort ongoing upload before clearing
      _.forEach(_.get(state, `${managerName}.uploading`), upload => {
        if (!upload.error) {
          upload._abort();
        }
      });
      return Immutable(initialState);
    }
    case `${OPEN_FILE_BY_URL}_SUCCESS`: {
      action.payload.window.location.assign(action.payload.url);
      return state;
    }
    case GET_FILE_METADATA.SUCCESS: {
      return state.setIn([managerName, 'metadata', action.payload.id], action.payload);
    }
    default:
      return state;
  }
}
