/* eslint-disable import/prefer-default-export */
/**
 * Created by chenrozenes on 25/04/2017.
 */
import _ from 'lodash';

import * as PermissionLevel from '../../model/enum/permissionLevel';
import * as AclResourceType from '../../model/enum/aclResourceType';

/**
 * Returns true if acl object (permissions set) represent a super admin permissions
 * @param permission
 * @param resourceType
 * @returns {boolean}
 */
export function isAdmin({ permission, resourceType }) {
  return permission === PermissionLevel.SUPER_ADMIN && resourceType === AclResourceType.APPLICATION;
}

/**
 * Returns true if user is super admin or have permission for phi access
 * @param permissions
 * @returns {boolean}
 */
export function hasPhiAccess(permissions) {
  return _.some(permissions, permissionObj => {
    return (
      isAdmin(permissionObj) ||
      AclResourceType.BACK_OFFICE_PHI_ACCESS === permissionObj.resourceType
    );
  });
}

/**
 * Checks if a user has permission to the required "permission set" provided.
 *
 * @param userAcls - an array of the user's permissions
 * @param permission - permission level required
 * @param resourceType - resource type required
 * @param resourceId - resource id (optional)
 * @param mustHaveResourceId - is resource id required (default false)
 * @returns {boolean}
 */
export function validatePermissions(
  userAcls,
  permission,
  resourceType,
  resourceId,
  mustHaveResourceId = false,
) {
  return _.some(userAcls, aclObject => {
    if (isAdmin(aclObject)) {
      return true;
    }

    if (mustHaveResourceId && !resourceId) {
      return false;
    }

    return (
      resourceType === aclObject.resourceType &&
      permission <= aclObject.permission &&
      (!resourceId ? true : resourceId.toString() === aclObject.resourceId)
    );
  });
}

/* eslint-enable import/prefer-default-export */
