import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Linkify from 'react-linkify';
import { compose, withHandlers } from 'recompose';
import { defineMessages, FormattedMessage } from 'react-intl';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import Button from '../Button/Button';
import Text from '../Text/Text';

import './NetworkTierDescription.less';

const messages = defineMessages({
  providers: {
    defaultMessage: 'providers',
    id: 'tierDescription.providers',
  },
});

const componentDecorator = ({ href, children }) => (
  <a href={href} target="_blank" rel="noopener noreferrer">
    <Button type="link">{children}</Button>
  </a>
);

componentDecorator.propTypes = {
  href: PropTypes.string.isRequired,
};

function NetworkTierDescription({
  description,
  placement,
  color,
  title,
  className,
  onClick,
  block,
  paddingLeft,
}) {
  const isBlock = block ? 'block text-center' : undefined;
  const isPaddingLeft = paddingLeft ? 'padding-left' : undefined;
  return (
    <OverlayTrigger
      onClick={onClick}
      trigger="click"
      rootClose
      overlay={
        <Popover className="network-tier-description-popover">
          <Text size="12px" align="left" color="dark" weight="semibold">
            {title}
            &nbsp;
            <FormattedMessage {...messages.providers} />
          </Text>
          <Linkify component={componentDecorator}>
            <Text size="12px" align="left" className="margin-top-10">
              {description}
            </Text>
          </Linkify>
        </Popover>
      }
      placement={placement}
    >
      <Button color={color} type="link" className={isBlock}>
        <Text
          size="12px"
          align="left"
          className={classNames('network-tier-description', className, isPaddingLeft)}
          inline
        >
          {title}
        </Text>
      </Button>
    </OverlayTrigger>
  );
}

NetworkTierDescription.propTypes = {
  description: PropTypes.string,
  placement: PropTypes.string,
  color: PropTypes.string,
  title: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  block: PropTypes.bool,
  paddingLeft: PropTypes.bool,
};

NetworkTierDescription.defaultProps = {
  placement: 'bottom',
  description: '',
  color: 'default',
  title: '',
  className: '',
  block: false,
  paddingLeft: false,
};

export default compose(
  withHandlers({
    onClick: () => e => e.stopPropagation(),
  }),
)(NetworkTierDescription);
