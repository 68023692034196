/**
 * Created by meirshalev on 04/06/2017.
 */
import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { defineMessages, FormattedMessage } from 'react-intl';
import { compose } from 'redux';
import { reduxForm, Field } from 'redux-form';
import Joi from 'joi-browser';
import { autobind } from 'core-decorators';

import MessageShape from '../../../../../components/ui/intlString/messageShape';
import { createValidator } from '../../../../../utils/joiValidator';
import { SpinnerButton } from '../../../../../components/ui/spinner/index';
import * as FormDef from './updateAddressDef';

const messages = defineMessages({
  address: {
    defaultMessage: 'Address',
    id: 'members.settings.address',
  },
  submitMessage: {
    defaultMessage: 'Update your address',
    id: 'members.settings.address.submitMessage',
  },
});

const required = value => (value ? undefined : 'Required');

@autobind
class UpdateHomeAddressForm extends React.Component {
  static propTypes = {
    /**
     * redux-forms form submission method.
     * Provided by redux-forms HoC
     */
    handleSubmit: PropTypes.func.isRequired,
    /**
     * True if form is currently submitting
     */
    loading: PropTypes.bool,
    label: MessageShape.isRequired,
    hiddenTitle: MessageShape.isRequired,
    svgIcon: PropTypes.object.isRequired,
    address: PropTypes.string,
  };

  static defaultProps = {
    address: undefined,
    loading: false,
  };

  render() {
    const { valid } = this.props;
    const addressString = _.split(this.props.address, ',')[0];

    return (
      <div className="">
        <form onSubmit={this.props.handleSubmit} method="post">
          <h1 className="hidden">
            <FormattedMessage {...this.props.hiddenTitle} />
          </h1>
          <div className="row margin-top-20">
            <div className="col-xs-12 text-center">{this.props.svgIcon}</div>
          </div>
          <div className="row margin-top-30">
            <div className="col-xs-12">
              <Field
                {...FormDef.fields.address}
                label={addressString || this.props.label}
                validate={[required]}
                id="address"
              />
            </div>
          </div>

          <div className="row margin-top-20">
            <div className="col-xs-12">
              <SpinnerButton
                className="btn btn-big bg-color-brand-button"
                type="submit"
                isLoading={this.props.loading}
                disabled={!valid}
              >
                <FormattedMessage {...messages.submitMessage} />
              </SpinnerButton>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default compose(
  reduxForm({
    form: FormDef.name,
    validate: createValidator(Joi.object().keys(FormDef.schema)),
  }),
)(UpdateHomeAddressForm);
