import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import SpinnerButton from '../../ui/spinner/spinnerButton';

// @mrsufgi note that primary button is also a form submission button.
// We would add a configuration in the future (form types)
const FormActionButtons = ({
  primaryButtonText,
  secondaryButtonText,
  onSecondaryClick,
  onPrimaryClick,
  isPrimaryLoading,
  primaryDisabled,
}) => (
  <div className="row tiny-gutter">
    <div className={classnames('col-xs-6', { hidden: !secondaryButtonText })}>
      <button
        data-id="secondaryButton"
        className="btn btn-big btn-light-grey btn-min-100 btn-block"
        type="button"
        onClick={onSecondaryClick}
      >
        {secondaryButtonText}
      </button>
    </div>
    <div
      className={classnames(
        { 'col-xs-6': secondaryButtonText },
        { 'col-xs-12': !secondaryButtonText },
      )}
    >
      <SpinnerButton
        data-id="primaryButton"
        onClick={onPrimaryClick}
        className="btn btn-big btn-min-100 btn-block bg-color-brand-button"
        type="submit"
        disabled={primaryDisabled}
        isLoading={isPrimaryLoading}
      >
        {primaryButtonText}
      </SpinnerButton>
    </div>
  </div>
);

FormActionButtons.propTypes = {
  primaryButtonText: PropTypes.string,
  secondaryButtonText: PropTypes.string,
  onSecondaryClick: PropTypes.func,
  onPrimaryClick: PropTypes.func,
  primaryDisabled: PropTypes.bool,
  isPrimaryLoading: PropTypes.bool,
};

FormActionButtons.defaultProps = {
  primaryButtonText: '',
  secondaryButtonText: '',
  onPrimaryClick: _.noop,
  onSecondaryClick: _.noop,
  primaryDisabled: false,
  isPrimaryLoading: false,
};

export default FormActionButtons;
