/**
 * Created by meirshalev on 28/05/2017.
 */
/**
 * This is not a regular enum (where key === value).
 * Here we need the value to equal the key in the settings object.
 */
export const FINANCIAL_AND_HEALTH_UPDATES = 'financialAndHealthUpdates';
export const APPOINTMENT_INFO = 'appointmentInfo';
export const ACCOUNT_REMINDERS = 'accountReminders';
export const NEWS = 'newsTipsPromotions';
export const ACCOUNT_SUPPORT = 'accountSupport';
export const NONE = 'none';
