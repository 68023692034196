/**
 * Created by meirshalev 14/02/2018.
 */
import _ from 'lodash';
import BkmdInsurer from '../../model/enum/bkmdInsurer';

export const getInsurersList = state => _.get(state, 'insurers.insurers', []);
export const getInsurerFromPrefix = state => {
  const insurer = _.get(state, 'insurers.insurerFromPrefix');
  return _.isEmpty(insurer) ? null : insurer;
};
export const getInsurer = (insurers, insurerId, intl) => {
  if (_.isString(insurerId)) {
    try {
      return intl.formatMessage(BkmdInsurer.getMessageByValue(insurerId));
    } catch (e) {
      return _.get(_.find(insurers, { pokitdokTradingPartnerId: insurerId }),
        'displayName', insurerId);
    }
  }

  return insurerId;
};
