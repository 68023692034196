/**
 * Created by guyavarham on 05/02/2018.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getFeature } from '../../../../store/feature/selectors';

import WithMemberId from './activateWithMemberId/fullActivationFlow';
import NoMemberId from './activationFlow';

function ActivationFlowByDomain({ withMemberId, ...others }) {
  return withMemberId ? <WithMemberId {...others} /> : <NoMemberId {...others} />;
}

ActivationFlowByDomain.propTypes = {
  withMemberId: PropTypes.bool.isRequired,
};

export default connect(state => ({
  withMemberId: getFeature(state, 'activation.withMemberId'),
}))(ActivationFlowByDomain);
