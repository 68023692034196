import { autobind } from 'core-decorators';
import BkmdApi from './bkmdApi';

@autobind
export default class AnalyticsApi extends BkmdApi {
  constructor(fetch) {
    super(fetch, '/analytics/');
  }

  getCallData(callSid) {
    return this.post('getCallData', { callSid });
  }
}
