/**
 * Created by chenrozenes on 25/04/2017.
 */

export const APPLICATION = 'APPLICATION';

export const APPLICATION_TYPE_ACCESS = 'APPLICATION_TYPE_ACCESS';
export const DOMAIN_ACCESS = 'DOMAIN_ACCESS';
export const NAMESPACES_ACCESS = 'NAMESPACES_ACCESS';

export const BACK_OFFICE_PROVIDERS = 'BACK_OFFICE_PROVIDERS';
export const BACK_OFFICE_APPOINTMENTS = 'BACK_OFFICE_APPOINTMENTS';
export const BACK_OFFICE_USERS = 'BACK_OFFICE_USERS';
export const BACK_OFFICE_ACL = 'BACK_OFFICE_ACL';
export const BACK_OFFICE_COMMUNICATION = 'BACK_OFFICE_COMMUNICATION';
export const BACK_OFFICE_SDK = 'BACK_OFFICE_SDK';
export const BACK_OFFICE_PREDRAFT = 'BACK_OFFICE_PREDRAFT';
export const BACK_OFFICE_REVIEWS = 'BACK_OFFICE_REVIEWS';
export const BACK_OFFICE_MEMBERS = 'BACK_OFFICE_MEMBERS';
export const BACK_OFFICE_DOMAIN_MEMBERS = 'BACK_OFFICE_DOMAIN_MEMBERS';
export const BACK_OFFICE_CLINICS = 'BACK_OFFICE_CLINICS';
export const BACK_OFFICE_HEALTH_SYSTEM = 'BACK_OFFICE_HEALTH_SYSTEM';
export const BACK_OFFICE_PROVIDER_PROFILE = 'BACK_OFFICE_PROVIDER_PROFILE';
export const BACK_OFFICE_VIM_GUIDE_PROFILE = 'BACK_OFFICE_VIM_GUIDE_PROFILE';
export const BACK_OFFICE_REFERRALS = 'BACK_OFFICE_REFERRALS';
export const BACK_OFFICE_MEDICAL_CODES = 'BACK_OFFICE_MEDICAL_CODES';
export const BACK_OFFICE_FEATURES = 'BACK_OFFICE_FEATURES';
export const BACK_OFFICE_ASO_GROUPS = 'BACK_OFFICE_ASO_GROUPS';
export const BACK_OFFICE_ELIGIBILITY = 'BACK_OFFICE_ELIGIBILITY';
export const BACK_OFFICE_SIU_MESSAGES = 'BACK_OFFICE_SIU_MESSAGES';
export const BACK_OFFICE_NAMESPACES = 'BACK_OFFICE_NAMESPACES';
export const BACK_OFFICE_PHI_ACCESS = 'BACK_OFFICE_PHI_ACCESS';

export const HEALTH_SYSTEM = 'HEALTH_SYSTEM';
export const HEALTH_SYSTEM_BILLING = 'HEALTH_SYSTEM_BILLING';

export const CLINIC = 'CLINIC';
export const RESOURCE = 'RESOURCE';
export const RESOURCE_CALENDAR = 'RESOURCE_CALENDAR';
export const RESOURCE_BILLING = 'RESOURCE_BILLING';
export const REFERRAL = 'REFERRAL';
// Allow to search domain members by their memberIds and decode member token
export const DOMAIN_MEMBERS = 'DOMAIN_MEMBERS';
export const MICROSERVICE_ACCESS = 'MICROSERVICE_ACCESS';

export const BACK_OFFICE_RESOURCES = [
  BACK_OFFICE_PROVIDERS,
  BACK_OFFICE_USERS,
  BACK_OFFICE_ACL,
  BACK_OFFICE_COMMUNICATION,
  BACK_OFFICE_REVIEWS,
  BACK_OFFICE_CLINICS,
  BACK_OFFICE_PROVIDER_PROFILE,
  BACK_OFFICE_VIM_GUIDE_PROFILE,
  BACK_OFFICE_REFERRALS,
  BACK_OFFICE_MEDICAL_CODES,
  BACK_OFFICE_APPOINTMENTS,
  BACK_OFFICE_ELIGIBILITY,
  BACK_OFFICE_SIU_MESSAGES,
  BACK_OFFICE_NAMESPACES,
  BACK_OFFICE_PHI_ACCESS,
];

// Scheduling
export const SCHEDULE_HEALTH_SYSTEM = 'SCHEDULE_HEALTH_SYSTEM';
export const SCHEDULE_CLINIC = 'SCHEDULE_CLINIC';
export const SCHEDULE_CALENDAR = 'SCHEDULE_CALENDAR';
export const SCHEDULE_PROVIDER_RESOURCE = 'SCHEDULE_PROVIDER_RESOURCE';

export const SCHEDULING_RESOURCES = [
  SCHEDULE_CLINIC,
  SCHEDULE_CALENDAR,
  SCHEDULE_PROVIDER_RESOURCE,
  SCHEDULE_HEALTH_SYSTEM,
];

export const GENERAL_RESOURCES = [APPLICATION, ...BACK_OFFICE_RESOURCES];

export const STAND_ALONE_SEARCH_CLINIC = 'STAND_ALONE_SEARCH_CLINIC';
export const STAND_ALONE_SEARCH_HEALTH_SYSTEM = 'STAND_ALONE_SEARCH_HEALTH_SYSTEM';

export const ACL_GROUP = 'ACL_GROUP';
