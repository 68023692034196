import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { compose, withProps } from 'recompose';
import PropTypes from 'prop-types';
import { domainShortName } from '../../../store/config/selectors';

export const memberInfo = state => _.get(state, 'member.info');

const urlMapper = {
  contextOnly: {
    AK: require('../../../assets/images/logos/premeraLogo.png'),
    WA: require('../../../assets/images/logos/premeraLogo-no alaska.png'),
    PK: require('../../../assets/images/logos/premeraWithPeakCare.png'),
  },
  AK: require('../../../assets/images/logos/premeraLogo2.png'),
  WA: require('../../../assets/images/logos/premeraLogo2 - no alaska.png'),
  PK: require('../../../assets/images/logos/premeraWithPeakCare.png'),
};

class PremeraLogos extends React.Component {
  static propTypes = {
    shortName: PropTypes.string.isRequired,
    logoUrl: PropTypes.string.isRequired,
  };

  render() {
    const {
      shortName,
      logoUrl,
    } = this.props;
    return !logoUrl ? null : (
      <div>
        <img
          src={logoUrl}
          className={`${shortName} help-modal-top-img logo`}
          alt="Logo"
        />
      </div>
    );
  }
}

export default compose(
  connect((state, ownProps) => ({
    memberContext: _.get(memberInfo(state), 'memberContext', ownProps.memberContext),
    shortName: domainShortName(state)
  })),
  withProps(({ memberContext, contextOnly }) => ({
    logoUrl: contextOnly ?
      _.get(urlMapper, `contextOnly.${memberContext}`, urlMapper.contextOnly.WA) :
      _.get(urlMapper, `${memberContext}`, urlMapper.WA),
  })),
)(PremeraLogos);
