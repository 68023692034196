/**
 * Created by moran on 6/27/17.
 */

import _ from 'lodash';
import fetchNotEmpty from '../tools/fetchNotEmpty';
import { getTaxonomyList } from './actions';

export const taxonomies = state => state.meta.taxonomies.data;

export const bucketLabelsToCodesSelector = (state, codes) => {
  const mapping = taxonomies(state);
  const bucketLabelsToCodes = {};

  _.each(codes, code => {
    const label = _.get(mapping, [code, 'description']);
    if (_.isNil(label)) return;

    if (bucketLabelsToCodes[label]) {
      bucketLabelsToCodes[label].push(code);
    } else {
      bucketLabelsToCodes[label] = [code];
    }
  });

  return bucketLabelsToCodes;
};

export const fetchTaxonomiesNotEmpty = () => fetchNotEmpty(
  getTaxonomyList,
  taxonomies,
  { renderAlways: false, loading: false }
);


export const getDescByCode = (state, code) => _.get(taxonomies(state), `${code}.description`, '');
