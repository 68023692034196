import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '../../storybook/src/utils/theme';

function SvgWarningCheck({ theme }) {
  /* eslint-disable */
  return (
    <svg width="71px" height="60px" viewBox="0 0 71 60" version="1.1" className="svg-icon svg-warning-check">
      <defs>
        <polygon id="path-1" points="0 0.04 53.0002244 0.04 53.0002244 46.6475 0 46.6475"></polygon>
        <polygon id="path-3" points="0.00045 0.727860793 36 0.727860793 36 30.9995 0.00045 30.9995"></polygon>
      </defs>
      <g id="V0" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Creating-and-replying" transform="translate(-1499.000000, -317.000000)">
          <g id="Group-9" transform="translate(1499.000000, 316.000000)">
            <g id="Group-3" transform="translate(0.000000, 0.960400)">
              <g id="Clip-2"></g>
              <path d="M28.5,46.6475 L1.5,46.6475 C0.943,46.6475 0.432,46.3385 0.173,45.8455 C-0.087,45.3525 -0.052,44.7565 0.264,44.2975 L30.264,0.6505 C30.824,-0.1645 32.176,-0.1645 32.736,0.6505 L49.041,24.3725 L52.729,29.6395 C53.204,30.3185 53.039,31.2535 52.36,31.7285 C51.684,32.2035 50.748,32.0405 50.271,31.3605 L46.576,26.0825 L31.5,4.1485 L4.352,43.6475 L28.5,43.6475 C29.328,43.6475 30,44.3195 30,45.1475 C30,45.9755 29.328,46.6475 28.5,46.6475" id="Fill-1" fill={theme.secondaryColor} ></path>
            </g>
            <path d="M29.2774,17.1958 L34.4934,17.1958 L33.8134,32.5398 L29.9934,32.5398 L29.2774,17.1958 Z M31.9034,34.7908 C33.4024,34.7908 34.6664,36.0508 34.6664,37.5498 C34.6664,39.0158 33.4024,40.2788 31.9034,40.2788 C30.4044,40.2788 29.1774,39.0158 29.1774,37.5498 C29.1774,36.0508 30.4044,34.7908 31.9034,34.7908 Z" id="Fill-4" fill={theme.secondaryColor} ></path>
            <g id="Group-8" transform="translate(35.000000, 29.960400)">
              <g id="Clip-7"></g>
              <path d="M7.5977,11.3945 C6.4187,11.3945 5.2407,11.8415 4.3427,12.7385 C2.5507,14.5305 2.5507,17.4495 4.3437,19.2435 L11.7537,26.6545 C12.6197,27.5175 13.7697,27.9965 14.9917,27.9995 L15.1547,27.9965 C16.4037,27.9595 17.6077,27.3985 18.4457,26.4555 L31.8407,11.3855 C32.6557,10.4685 33.0647,9.2885 32.9927,8.0595 C32.9197,6.8315 32.3747,5.7065 31.4587,4.8915 C30.5327,4.0685 29.3547,3.6545 28.1417,3.7385 C26.9067,3.8105 25.7827,4.3565 24.9657,5.2745 L14.8087,16.6985 L10.8497,12.7365 C9.9527,11.8415 8.7757,11.3945 7.5977,11.3945 Z M15.0237,30.9995 L15.0067,30.9995 C12.9797,30.9995 11.0717,30.2105 9.6337,28.7765 L2.2227,21.3645 C-0.7403,18.4005 -0.7403,13.5795 2.2227,10.6165 C5.1857,7.6575 10.0067,7.6575 12.9697,10.6145 L14.6817,12.3275 L22.7237,3.2805 C24.0727,1.7635 25.9307,0.8635 27.9557,0.7445 C29.9567,0.6105 31.9207,1.2895 33.4517,2.6495 C34.9667,3.9965 35.8677,5.8545 35.9867,7.8835 C36.1067,9.9125 35.4297,11.8635 34.0827,13.3785 L20.6877,28.4485 C19.3027,30.0065 17.3137,30.9345 15.2307,30.9955 L15.0237,30.9995 Z" id="Fill-6" fill={theme.mainColor} ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
  /* eslint-enable */
}

SvgWarningCheck.propTypes = {
  theme: PropTypes.object.isRequired,
};

export default withTheme(SvgWarningCheck);
