import { Formik } from 'formik';
import { withProps } from 'recompose';

/**
 * WrappedFormik defaults the isInitialValid behavior to sync yup validation
 * Any async yup validation will fail here
 */
const withYupInitialValidation = withProps({
  isInitialValid: props => {
    const { validationSchema, initialValues } = props;
    if (!validationSchema) return true;
    return validationSchema.isValidSync(initialValues);
  },
});

export const WrappedFormik = withYupInitialValidation(Formik);
