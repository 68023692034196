/**
 * Created by asafdavid on 09/05/2016.
 */
import _ from 'lodash';
import { authTouch, loginExpired } from './actions';

const ACTION_BLACKLIST = [
  /LOCATION_CHANGE/,
  /LOGIN/,
  /AUTH_TOUCH/,
  /REFRESH_IF_ACTIVE/,
  /REFRESH/,
  /LOAD_STORAGE/,
  /GET_MEMBER/,
  /SET_PATIENT_DETAILS/,
  /action-tracker/,
];

function ignoreAction(actionType) {
  if (!actionType) return false;
  return _.some(ACTION_BLACKLIST, function actionBlacklistMap(currRegexp) {
    return actionType.match(currRegexp);
  });
}

/**
 *
 * @param preLoginExpiredHook {function(state) : void}
 * @returns {function({dispatch?: *, getState?: *}): function(*): function(*=): *}
 */
const getAuthMiddleware = ({ preLoginExpiredHook } = {}) =>
  ({ dispatch, getState }) => next => action => {
    if (!ignoreAction(action.type)) {
      if (!!action.error &&
      (_.get(action, 'payload.status') === 401 ||
        _.get(action, 'payload.message') === 'Unauthorized')) {
        if (_.isFunction(preLoginExpiredHook)) {
          const state = getState();
          preLoginExpiredHook(state);
        }
        dispatch(loginExpired);
      } else {
        dispatch(authTouch);
      }
    }
    return next(action);
  };

export default getAuthMiddleware;
