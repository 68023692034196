/**
 * Created by guyavarham on 21/03/2017.
 */

import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Dropdown, MenuItem } from 'react-bootstrap';
import { autobind } from 'core-decorators';
import Formatter from '../../../utils/formatter';
import './providerLocationSelector.less';
import ClickableDiv from '../../clickableElement/clickableDiv';

@autobind
class ProviderLocationSelector extends React.Component {
  static propTypes = {
    pickedLocation: PropTypes.object,
    otherLocations: PropTypes.array,
    onLocationChanged: PropTypes.func.isRequired,
    staticLocation: PropTypes.bool,
  };

  static defaultProps = {
    otherLocations: [],
    pickedLocation: {},
    staticLocation: false,
  };

  renderLocation(location) {
    return (
      <MenuItem eventKey={location.id} key={location.id} id={location.id}>
        <div className="item-title text-16 text-semibold">
          {location.officeName !== 'None'
            ? Formatter.officeName(location.officeName)
            : location.addressLine1}
        </div>
        <div className="text-14">
          {location.addressLine1}, {location.city} {location.state} {location.zip}
        </div>
      </MenuItem>
    );
  }

  render() {
    const { otherLocations, pickedLocation, staticLocation } = this.props;
    if (_.isEmpty(pickedLocation)) return null;

    const locationTitleToShow =
      pickedLocation.officeName && pickedLocation.officeName !== 'None'
        ? Formatter.officeName(pickedLocation.officeName)
        : pickedLocation.addressLine1;
    if (otherLocations.length === 0) {
      return <div className="single-location">{locationTitleToShow}</div>;
    }
    if (staticLocation) {
      return <div>{locationTitleToShow}</div>;
    }
    return (
      <ClickableDiv clickHandler={e => e.stopPropagation()}>
        <Dropdown
          id="summery"
          className="locations-dropdown pill-btn-dropdown margin-top-5"
          onSelect={this.props.onLocationChanged}
        >
          <Dropdown.Toggle bsStyle="transparent" noCaret>
            {locationTitleToShow}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {_.map(otherLocations, location => this.renderLocation(location))}
          </Dropdown.Menu>
        </Dropdown>
      </ClickableDiv>
    );
  }
}

export default ProviderLocationSelector;
