import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '../../storybook/src/utils/theme';

function SvgHeartLock({ theme }) {
  /* eslint-disable */
  return (
    <svg aria-hidden="true" focusable="false" className="svg-icon svg-heart-lock" width="70px" height="70px" viewBox="0 0 70 70" version="1.1">
      <defs>
        <polygon id="path-1" points="0.0001 0.6527 69.348 0.6527 69.348 69.9997 0.0001 69.9997"></polygon>
      </defs>
      <g id="Forgot-password" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="2-SCR041-Change-your-password" transform="translate(-461.000000, -117.000000)">
          <g id="Group" transform="translate(461.000000, 116.000000)">
            <path d="M46.626,27.5 L43.626,27.5 L43.626,21.5 C43.626,16.812 39.813,13 35.126,13 C30.439,13 26.626,16.812 26.626,21.5 L26.626,27.5 L23.626,27.5 L23.626,21.5 C23.626,15.158 28.785,10 35.126,10 C41.467,10 46.626,15.158 46.626,21.5 L46.626,27.5 Z" id="Fill-1" fill={theme.secondaryColor} ></path>
            <path d="M37.8965,43.1006 C37.5705,43.3766 37.3825,43.7826 37.3825,44.2096 L37.3825,49.0346 C37.3825,49.9486 36.6385,50.6926 35.7235,50.6926 C34.8095,50.6926 34.0655,49.9486 34.0655,49.0346 L34.0655,44.2096 C34.0655,43.7826 33.8775,43.3766 33.5515,43.1006 C32.7795,42.4486 32.3365,41.5116 32.3365,40.5316 C32.3365,38.6636 33.8565,37.1446 35.7235,37.1446 C37.5915,37.1446 39.1115,38.6636 39.1115,40.5316 C39.1115,41.5116 38.6685,42.4486 37.8965,43.1006" id="Fill-3" fill={theme.mainColor} ></path>
            <g id="Group-7" transform="translate(0.000000, 0.347700)">
              <g id="Clip-6"></g>
              <path d="M67.1191,55.8037 L62.2841,56.2927 C66.8571,50.2847 69.3481,42.9707 69.3481,35.3257 C69.3481,29.4047 67.8281,23.5587 64.9531,18.4197 C63.6971,16.1757 62.1871,14.0657 60.4641,12.1487 C58.1781,9.6077 55.5411,7.4227 52.6251,5.6557 C47.2261,2.3827 41.0181,0.6527 34.6741,0.6527 C15.5551,0.6527 0.0001,16.2067 0.0001,35.3257 C0.0001,54.4457 15.5551,69.9997 34.6741,69.9997 C40.1831,69.9997 45.6691,68.6747 50.5351,66.1667 C51.2711,65.7877 51.5601,64.8837 51.1811,64.1467 C50.8021,63.4097 49.8971,63.1227 49.1601,63.5007 C44.6541,65.8227 39.7801,66.9997 34.6741,66.9997 C17.2091,66.9997 3.0001,52.7907 3.0001,35.3257 C3.0001,17.8617 17.2091,3.6527 34.6741,3.6527 C40.4711,3.6527 46.1401,5.2327 51.0701,8.2207 C53.7341,9.8357 56.1441,11.8317 58.2331,14.1547 C59.8071,15.9067 61.1881,17.8337 62.3361,19.8847 C64.9601,24.5757 66.3481,29.9157 66.3481,35.3257 C66.3481,42.3377 64.0601,49.0487 59.8501,54.5497 L59.3381,49.9857 C59.2461,49.1617 58.4961,48.5627 57.6811,48.6617 C56.8571,48.7537 56.2651,49.4957 56.3571,50.3197 L57.2561,58.3197 C57.3201,58.8997 57.7161,59.3887 58.2691,59.5747 C58.4311,59.6287 58.5961,59.6457 58.7601,59.6447 C58.7751,59.6447 58.7881,59.6527 58.8031,59.6527 C58.8531,59.6527 58.9041,59.6507 58.9561,59.6447 L67.4221,58.7877 C68.2461,58.7047 68.8471,57.9687 68.7631,57.1447 C68.6801,56.3207 67.9471,55.7077 67.1191,55.8037" id="Fill-5" fill={theme.secondaryColor} ></path>
            </g>
            <path d="M35.6318,29.8106 C35.6348,29.8136 35.6368,29.8166 35.6388,29.8196 C35.6368,29.8166 35.6348,29.8136 35.6318,29.8106 Z M34.8038,29.8686 C34.7958,29.8776 34.7878,29.8886 34.7808,29.9006 C34.7888,29.8896 34.7958,29.8786 34.8038,29.8686 Z M18.3508,36.1026 L18.3528,36.1466 L18.3478,36.2916 C18.3478,36.8196 18.4068,37.3916 18.5208,37.9296 C20.2088,47.3336 33.0588,56.4136 35.2358,57.8916 C37.3458,56.4436 50.2818,47.2336 51.9398,38.0026 C52.0668,37.3976 52.1258,36.8296 52.1258,36.2726 L52.1108,36.1226 L52.1168,36.0826 L52.0868,35.8396 C51.8568,31.4436 48.2798,27.9996 43.9458,27.9996 C41.3528,27.9996 38.8538,29.3406 37.2618,31.5856 C36.8378,32.2396 36.0708,32.6596 35.2438,32.6596 L35.2418,32.6596 C34.4088,32.6596 33.6308,32.2296 33.1588,31.5076 C31.6118,29.3296 29.1208,27.9996 26.5278,27.9996 C22.1938,27.9996 18.6168,31.4436 18.3868,35.8396 L18.3508,36.1026 Z M35.2368,60.9996 C34.7538,60.9996 34.2838,60.8596 33.8808,60.5946 C32.2158,59.4996 17.5658,49.5836 15.5758,38.5026 C15.4278,37.7986 15.3478,37.0346 15.3478,36.2726 L15.3498,36.1736 C15.3488,36.1366 15.3478,36.0996 15.3478,36.0636 C15.3478,35.8916 15.3638,35.7186 15.3988,35.5486 C15.7778,29.6236 20.6418,24.9996 26.5278,24.9996 C29.8778,24.9996 33.0898,26.5926 35.2408,29.2916 C37.4298,26.5776 40.6228,24.9996 43.9458,24.9996 C49.8318,24.9996 54.6938,29.6216 55.0748,35.5466 C55.1088,35.7166 55.1258,35.8896 55.1258,36.0636 C55.1258,36.0916 55.1248,36.1276 55.1228,36.1686 C55.1248,36.2086 55.1258,36.2446 55.1258,36.2726 C55.1258,37.0366 55.0448,37.8116 54.8838,38.5766 C52.8708,49.7926 37.2568,60.1596 36.5928,60.5946 C36.1898,60.8596 35.7208,60.9996 35.2368,60.9996 Z" id="Fill-8" fill={theme.mainColor} ></path>
          </g>
        </g>
      </g>
    </svg>
  );
  /* eslint-enable */
}

SvgHeartLock.propTypes = {
  theme: PropTypes.object.isRequired,
};

export default withTheme(SvgHeartLock);
