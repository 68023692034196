/**
 * Created by rom on 20/12/2015.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '../../storybook/src/utils/theme';

function SvgBirthdayCake({ theme }) {
  /* eslint-disable */
  return (
    <svg aria-hidden="true" focusable="false" className="svg-icon svg-icon-birthday-cake" width="16px" height="16px" viewBox="0 0 16 16" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-420.000000, -183.000000)">
          <g transform="translate(420.000000, 171.000000)">
            <g transform="translate(0.000000, 12.000000)">
              <path d="M15,15 L1,15 L1,11 L2.5,11 L13.5,11 L15,11 L15,15 Z M3,8 L4.5,8 L4.5,8.5 C4.5,8.776 4.7235,9 5,9 C5.2765,9 5.5,8.776 5.5,8.5 L5.5,8 L7.5,8 L7.5,8.5 C7.5,8.776 7.7235,9 8,9 C8.2765,9 8.5,8.776 8.5,8.5 L8.5,8 L10.5,8 L10.5,8.5 C10.5,8.776 10.7235,9 11,9 C11.2765,9 11.5,8.776 11.5,8.5 L11.5,8 L13,8 L13,10 L3,10 L3,8 Z M15.5,10 L14,10 L14,7.5 C14,7.224 13.7765,7 13.5,7 L11.5,7 L11.5,5 C11.5,4.724 11.2765,4.5 11,4.5 C10.7235,4.5 10.5,4.724 10.5,5 L10.5,7 L8.5,7 L8.5,5 C8.5,4.724 8.2765,4.5 8,4.5 C7.7235,4.5 7.5,4.724 7.5,5 L7.5,7 L5.5,7 L5.5,5 C5.5,4.724 5.2765,4.5 5,4.5 C4.7235,4.5 4.5,4.724 4.5,5 L4.5,7 L2.5,7 C2.2235,7 2,7.224 2,7.5 L2,10 L0.5,10 C0.2235,10 0,10.224 0,10.5 L0,15.5 C0,15.776 0.2235,16 0.5,16 L15.5,16 C15.7765,16 16,15.776 16,15.5 L16,10.5 C16,10.224 15.7765,10 15.5,10 L15.5,10 Z" fill={theme.mainColor}></path>
              <path d="M12.25,2.33325 C12.25,2.89075 11.6905,3.49975 11,3.49975 C10.3095,3.49975 9.75,2.89075 9.75,2.33325 C9.75,1.77575 11,-0.00025 11,-0.00025 C11,-0.00025 12.25,1.77575 12.25,2.33325" fill={theme.secondaryColor}></path>
              <path d="M9.25,2.33325 C9.25,2.89075 8.6905,3.49975 8,3.49975 C7.3095,3.49975 6.75,2.89075 6.75,2.33325 C6.75,1.77575 8,-0.00025 8,-0.00025 C8,-0.00025 9.25,1.77575 9.25,2.33325" fill={theme.secondaryColor}></path>
              <path d="M6.25,2.33325 C6.25,2.89075 5.6905,3.49975 5,3.49975 C4.3095,3.49975 3.75,2.89075 3.75,2.33325 C3.75,1.77575 5,-0.00025 5,-0.00025 C5,-0.00025 6.25,1.77575 6.25,2.33325" fill={theme.secondaryColor}></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
  /* eslint-enable */
}

SvgBirthdayCake.propTypes = {
  theme: PropTypes.object.isRequired,
};

export default withTheme(SvgBirthdayCake);
