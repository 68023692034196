import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { defineMessages, FormattedMessage } from 'react-intl';

const stopPropagation = e => e.stopPropagation();

// TODO: intl
const messages = defineMessages({
  getDirections: {
    defaultMessage: 'Get Directions',
    id: 'members.components.getDirections',
  },
});

function GetDirectionsLink({ directionsUrl, location = {} }) {
  const { addressLine1, city, state, zip } = location;
  return (
    <a
      target="_blank"
      rel="noopener noreferrer"
      onClick={stopPropagation}
      href={`${directionsUrl}=${addressLine1} ${city} ${state} ${zip}`}
      aria-labelledby="new-window-0"
      className="font-color-brand-link"
    >
      <FormattedMessage key="message" {...messages.getDirections} />
      &nbsp;
      <i className="icon-ext-link text-12" id="new-window-0" aria-label="external-link" />
    </a>
  );
}

GetDirectionsLink.propTypes = {
  location: PropTypes.object.isRequired,
  directionsUrl: PropTypes.string,
};

GetDirectionsLink.defaultProps = {
  directionsUrl: 'http://www.bing.com/maps/?v=2&where1',
};

export default connect(state => {
  const mapStrategy = _.get(state, 'config.mapStrategy', 'bingMaps');
  return { directionsUrl: _.get(state, ['config', mapStrategy, 'directionsUrl']) };
})(GetDirectionsLink);
