import PropTypes from 'prop-types';
import { defineMessages, injectIntl } from 'react-intl';
import { withProps, withHandlers, setPropTypes, compose } from 'recompose';
import { FlowShape } from '../../components/wizard/flow/shapes';
import SelectOne from '../../screenTemplates/SCR001/SelectOne';
import * as BookingType from '../../model/enum/bookingType';
import { appointmentTypeOptions } from '../../model/enum/appointmentType';

const messages = defineMessages({
  bookAVisitFor: {
    defaultMessage: 'Book a visit for',
    id: 'members.directory.booking.bookAVisitFor',
  },
});

export default compose(
  injectIntl,
  setPropTypes({
    control: FlowShape.isRequired,
    appointmentTypes: PropTypes.array.isRequired,
  }),
  withProps(({ intl, appointmentTypes, bookingType }) => ({
    headerText: intl.formatMessage(messages.bookAVisitFor),
    displayField: 'commonName',
    // Selecting between server appointment types to patient type enum
    options: bookingType === BookingType.INSTANT ? appointmentTypes : appointmentTypeOptions,
  })),
  withHandlers({
    onClick: ({ control }) => payload => control.next({ payload }),
  }),
)(SelectOne);
