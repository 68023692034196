import { defineMessages } from 'react-intl';
import IntlEnum from '../../utils/intlEnum';

export const MALE = 'MALE';
export const FEMALE = 'FEMALE';
export const UNSPECIFIED = 'UNSPECIFIED';

const messages = defineMessages({
  male: {
    defaultMessage: 'Male',
    id: 'gender.male'
  },
  female: {
    defaultMessage: 'Female',
    id: 'gender.female'
  },
  unspecified: {
    defaultMessage: 'Unspecified',
    id: 'gender.unspecified'
  },
});

const anyMessage = defineMessages({
  any: {
    defaultMessage: 'Any',
    id: 'gender.any'
  },
});

// usefull for selecting with default option (any)
export const genderIntlWithAny = new IntlEnum(
  { value: MALE, message: messages.male },
  { value: FEMALE, message: messages.female },
  { value: UNSPECIFIED, message: anyMessage.any },
);

export default new IntlEnum(
  { value: MALE, message: messages.male },
  { value: FEMALE, message: messages.female },
  { value: UNSPECIFIED, message: messages.unspecified },
);

