import ShareDoctorsRoutes from '../../shareDoctors/shareDoctorsRoutes';

class DoctorsShareRouteStrategy {
  static predicate({ features }) {
    return features.doctorShare;
  }

  static getRoutes() {
    return [...ShareDoctorsRoutes];
  }
}

export default DoctorsShareRouteStrategy;
