import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import { compose } from 'recompose';
import { defineMessages, FormattedMessage } from 'react-intl';

import ClickableDiv from '../../../../components/clickableElement/clickableDiv';
import FeatureFlagged from '../../../../components/features/featureFlagged';
import ServicesMenu from '../layout/servicesMenu/servicesMenu';
import HelpMenu from '../layout/helpMenu/helpMenu';
import LanguageMenu from '../../../../components/molecules/LanguageMenu/LanguageMenu';
import MyHealthMenu from '../layout/myHealthMenu/myHealthMenu';
import DoctorsMenu from '../layout/doctorsMenu/doctorsMenu';
import { mobileMenuOpen, openSearchModal } from '../../../../store/ui/actions';
import { fullNameSelector } from '../../../../store/member/selectors';
import { isMobileOrTablet } from '../../../../store/platform/selectors';
import { MemberLogout } from '../../../../store/tools/analytics/events/memberEvents';
import { isAuthenticated } from '../../../../store/auth/selectors';
import { getFeature } from '../../../../store/feature/selectors';
import ClickableLi from '../../../../components/clickableElement/clickableLi';

import './mobileMenu.less';
import { shouldShowPCPModal } from '../medicalTeam/medicalTeam';
import withMembersLogout from '../utils/withMembersLogout';

const messages = defineMessages({
  home: {
    defaultMessage: 'Home',
    id: 'members.mobileMenu.home',
  },
  doctors: {
    defaultMessage: 'Doctors',
    id: 'members.mobileMenu.doctors',
  },
  careAlerts: {
    defaultMessage: 'Care alerts',
    id: 'members.mobileMenu.careAlerts',
  },
  settings: {
    defaultMessage: 'Settings',
    id: 'members.mobileMenu.settings',
  },
  help: {
    defaultMessage: 'Help',
    id: 'members.mobileMenu.help',
  },
  legal: {
    defaultMessage: 'Legal',
    id: 'members.mobileMenu.legal',
  },
  signOut: {
    defaultMessage: 'Sign out',
    id: 'members.mobileMenu.signOut',
  },
  menu: {
    defaultMessage: 'Menu',
    id: 'members.mobileMenu.menu',
  },
  closeMenu: {
    defaultMessage: 'Close menu',
    id: 'members.mobileMenu.closeMenu',
  },
});

@autobind
class MobileMenu extends React.PureComponent {
  static propTypes = {
    mobileMenuOpen: PropTypes.func.isRequired,
    logout: PropTypes.func.isRequired,
    openSearchModal: PropTypes.func.isRequired,
    isMobileScreen: PropTypes.bool.isRequired,
    isMenuOpen: PropTypes.bool.isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
    pcpSelection: PropTypes.bool.isRequired,
  };

  static contextTypes = {
    paths: PropTypes.object.isRequired,
    analytics: PropTypes.object.isRequired,
  };

  onLogoutClick() {
    this.context.analytics.track(MemberLogout);
    this.props.logout();
  }

  openSearch() {
    this.props.openSearchModal();
  }

  closeMenu() {
    if (this.props.isMobileScreen) {
      this.props.mobileMenuOpen(false);
    }
  }

  render() {
    const { isAuthenticated, pcpSelection } = this.props;
    return (
      <div className="mobile-menu-wrap">
        <input
          type="checkbox"
          id="menu-trigger-checkbox"
          tabIndex="-1"
          checked={this.props.isMenuOpen}
        />
        <div className="mobile-menu white-menu">
          <div className="menu-header">
            <button
              className="menu-close-btn btn btn-inline-link gfgf"
              onClick={this.closeMenu}
              aria-label="Close menu"
            >
              <i className="icon-x-2" />
            </button>
            <div className="text-18 text-semibold font-color-brand-main">
              <FormattedMessage {...messages.menu} />
            </div>
          </div>
          <ul className="menu-list" role="menu">
            <ClickableLi clickHandler={this.closeMenu} role="menuitem">
              <Link to={this.context.paths.home()} className="btn btn-transparent">
                <i className="icon-home-3" />
                <FormattedMessage {...messages.home} />
              </Link>
            </ClickableLi>
            <FeatureFlagged renderLi uri="layout.doctorsLink">
              {isAuthenticated && pcpSelection ? (
                <div>
                  <i className="icon-search-2" />
                  <DoctorsMenu id="doctorsMenuMobile" isMobileScreen />
                  <i className="icon-chevron-right item-arrow" />
                </div>
              ) : (
                <ClickableDiv clickHandler={this.closeMenu} role="menuitem">
                  <ClickableDiv clickHandler={this.openSearch} className="btn btn-transparent">
                    <i className="icon-search-2" />
                    <FormattedMessage {...messages.doctors} />
                  </ClickableDiv>
                </ClickableDiv>
              )}
            </FeatureFlagged>
            <FeatureFlagged renderLi uri="layout.myHealthMenu.menuItem" role="menuitem">
              <i className="icon-heart-vim-e" />
              <MyHealthMenu onSelected={this.closeMenu} id="myHealthMenuMobile" />
              <i className="icon-chevron-right item-arrow" />
            </FeatureFlagged>
            <li role="menuitem">
              <FeatureFlagged uri="layout.servicesMenu">
                <i className="icon-places" />
                <ServicesMenu closeMainMenu={this.closeMenu} id="servicesMenuMobile" />
                <i className="icon-chevron-right item-arrow" />
              </FeatureFlagged>
            </li>
            <li role="menuitem">
              <FeatureFlagged uri="layout.helpMenu">
                <i className="icon-help-w" />
                <HelpMenu closeMainMenu={this.closeMenu} id="helpMenuMobile" />
                <i className="icon-chevron-right item-arrow" />
              </FeatureFlagged>
            </li>
            <li role="menuitem">
              <FeatureFlagged uri="layout.changeLanguage">
                <i className="icon-globe" />
                <LanguageMenu closeMainMenu={this.closeMenu} id="languageMenuMobile" />
                <i className="icon-chevron-right item-arrow" />
              </FeatureFlagged>
            </li>
          </ul>
          <div className="menu-footer">
            {/*
            <button className="btn btn-transparent">
              <FormattedMessage {...messages.legal} />
            </button>
            <button className="btn btn-transparent" onClick={this.onLogoutClick}>
              <FormattedMessage {...messages.signOut} />
            </button>
            */}
          </div>
        </div>
        <ClickableDiv
          htmlFor="menu-trigger-checkbox"
          className="menu-backdrop-trigger"
          tabIndex="-1"
          clickHandler={this.closeMenu}
        >
          <FormattedMessage {...messages.closeMenu} />
        </ClickableDiv>
      </div>
    );
  }
}

export default compose(
  connect(
    state => {
      const shouldShow = shouldShowPCPModal(state);
      return {
        fullName: fullNameSelector(state),
        isMenuOpen: state.ui.isMobileMenuOpen,
        isMobileScreen: isMobileOrTablet(state),
        isAuthenticated: isAuthenticated(state),
        pcpSelection: getFeature(state, 'pcpSelection') && shouldShow,
      };
    },
    { openSearchModal, mobileMenuOpen },
  ),
  withMembersLogout,
)(MobileMenu);
