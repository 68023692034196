/**
 * Created by chenrozenes on 12/04/2017.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';

const rowDataSelector = (state, { griddleKey }) =>
  state
    .get('data')
    .find(r => r.get('griddleKey') === griddleKey)
    .toJSON();

/**
 * Hack to Griddle in order to enable onRowClick hook
 * @param onClick
 * @returns {*}
 */
export default function createClickableRow(onClick) {
  @autobind
  class ClickableRow extends React.PureComponent {
    static propTypes = {
      Cell: PropTypes.any.isRequired,
      griddleKey: PropTypes.number.isRequired,
      columnIds: PropTypes.array.isRequired,
      style: PropTypes.object,
      className: PropTypes.string,
      rowData: PropTypes.object,
    };

    static defaultProps = {
      style: undefined,
      className: undefined,
      rowData: {},
    };

    onClick() {
      onClick(this.props.rowData);
    }

    render() {
      const { Cell, griddleKey, columnIds, style, className } = this.props;

      return (
        <tr // eslint-disable-line jsx-a11y/no-static-element-interactions
          key={griddleKey}
          style={style}
          className={className}
          onClick={this.onClick}
        >
          { columnIds && columnIds.map(c => (
            <Cell
              key={`${c}-${griddleKey}`}
              griddleKey={griddleKey}
              columnId={c}
              style={style}
              className={className}
            />
          ))}
        </tr>
      );
    }
  }

  return connect((state, props) => ({
    rowData: rowDataSelector(state, props)
  }))(ClickableRow);
}
