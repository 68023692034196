/* eslint-disable react/no-unused-prop-types */
import React from 'react';
import _ from 'lodash';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import Joi from 'joi-browser';

import CleanSwitch from '../../../../../components/form/cleanSwitch';
import { SpinnerButton } from '../../../../../components/ui/spinner';
import { createValidator } from '../../../../../utils/joiValidator';
import * as FormDefs from './notificationFormDefs';
import messages from './messages';

class NotificationsForm extends React.PureComponent {
  static propTypes = {
    /**
     * Hook method. supplied by redux-forms
     */
    handleSubmit: PropTypes.func.isRequired,
    /**
     * Should form submit button should show loading.
     * Default is false.
     */
    loading: PropTypes.bool,
    /**
     * initial values for the redux form, fetched from redux state.
     */
    initialValues: PropTypes.object.isRequired,
  };

  static defaultProps = {
    loading: false,
  };

  render() {
    const { handleSubmit, loading } = this.props;

    return (
      <form onSubmit={handleSubmit} method="post">
        <div className="careAlerts">
          <div className="row">
            <div className="col-xs-12">
              <span className="text-16 text-dark text-semibold">
                <FormattedMessage {...messages.financialAndHealthUpdatesTitle} />
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12">
              <span className="">
                <FormattedMessage {...messages.financialAndHealthUpdatesBody} />
              </span>
            </div>
          </div>
          <div className="row margin-top-20">
            <div className="col-xs-12">
              <Field
                {...FormDefs.fields.financialAndHealthUpdatesEmails}
                className="left-label"
                label={messages.emailLabel}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12">
              <Field
                {...FormDefs.fields.financialAndHealthUpdatesMessages}
                className="left-label"
                label={messages.smsLabel}
              />
            </div>
          </div>
        </div>

        <hr />

        <div className="appointmentInfo">
          <div className="row">
            <div className="col-xs-12">
              <span className="text-16 text-dark text-semibold">
                <FormattedMessage {...messages.appointmentInfoTitle} />
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12">
              <span className="">
                <FormattedMessage {...messages.appointmentInfoBody} />
              </span>
            </div>
          </div>
          <div className="row margin-top-20">
            <div className="col-xs-12">
              <Field
                {...FormDefs.fields.appointmentInfoEmails}
                className="left-label"
                label={messages.emailLabel}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12">
              <Field
                {...FormDefs.fields.appointmentInfoMessages}
                className="left-label"
                label={messages.smsLabel}
              />
            </div>
          </div>
        </div>

        <hr />

        <div className="accountReminders">
          <div className="row">
            <div className="col-xs-12">
              <span className="text-16 text-dark text-semibold">
                <FormattedMessage {...messages.accountRemindersTitle} />
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12">
              <span className="">
                <FormattedMessage {...messages.accountRemindersBody} />
              </span>
            </div>
          </div>
          <div className="row margin-top-20">
            <div className="col-xs-12">
              <Field
                {...FormDefs.fields.accountRemindersEmails}
                className="left-label"
                label={messages.emailLabel}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12">
              <Field
                {...FormDefs.fields.accountRemindersMessages}
                className="left-label"
                label={messages.smsLabel}
              />
            </div>
          </div>
        </div>

        <hr />

        <div className="news">
          <div className="row  margin-top-30">
            <div className="col-xs-12">
              <span className="text-16 text-dark text-semibold">
                <FormattedMessage {...messages.newsTitle} />
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12">
              <span className="">
                <FormattedMessage {...messages.premeraBookmdUpdatesBody} />
              </span>
            </div>
          </div>
          <div className="row margin-top-20">
            <div className="col-xs-12">
              <Field
                {...FormDefs.fields.newsTipsPromotionsEmails}
                className="left-label"
                label={messages.emailLabel}
              />
            </div>
          </div>
        </div>

        <hr />

        <div className="accountSupport">
          <div className="row">
            <div className="col-xs-12">
              <span className="text-16 text-dark text-semibold">
                <FormattedMessage {...messages.accountSupportTitle} />
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12">
              <span className="">
                <FormattedMessage {...messages.accountSupportBody} />
              </span>
            </div>
          </div>
          <div className="row margin-top-20">
            <div className="col-xs-12">
              <CleanSwitch
                id="care-alerts-emails-switch"
                value
                label={messages.emailLabel}
                className="left-label"
                onChange={() => {}}
                readOnly
              />
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12">
              <CleanSwitch
                id="care-alerts-messages-switch"
                value
                label={messages.smsLabel}
                className="left-label"
                onChange={() => {}}
                readOnly
              />
            </div>
          </div>
        </div>

        <hr />

        <div className="row">
          <div className="col-xs-12">
            <SpinnerButton
              className="btn btn-big submit-btn bg-color-brand-button"
              type="submit"
              isLoading={loading}
            >
              <FormattedMessage {...messages.saveYourSettings} />
            </SpinnerButton>
          </div>
        </div>
      </form>
    );
  }
}

export default compose(
  connect(state => {
    const memberNotifications = state.member.settings.notifications;
    return {
      initialValues: {
        [FormDefs.fields.newsTipsPromotionsEmails.name]: _.get(
          memberNotifications,
          'newsTipsPromotions.EMAIL',
          false,
        ),
        [FormDefs.fields.appointmentInfoEmails.name]: _.get(
          memberNotifications,
          'appointmentInfo.EMAIL',
          false,
        ),
        [FormDefs.fields.appointmentInfoMessages.name]: _.get(
          memberNotifications,
          'appointmentInfo.SMS',
          false,
        ),
        [FormDefs.fields.accountRemindersEmails.name]: _.get(
          memberNotifications,
          'accountReminders.EMAIL',
          false,
        ),
        [FormDefs.fields.accountRemindersMessages.name]: _.get(
          memberNotifications,
          'accountReminders.SMS',
          false,
        ),
        [FormDefs.fields.financialAndHealthUpdatesEmails.name]: _.get(
          memberNotifications,
          'financialAndHealthUpdates.EMAIL',
          false,
        ),
        [FormDefs.fields.financialAndHealthUpdatesMessages.name]: _.get(
          memberNotifications,
          'financialAndHealthUpdates.SMS',
          false,
        ),
      },
    };
  }),
  reduxForm({
    form: FormDefs.name,
    validate: createValidator(Joi.object().keys(FormDefs.schema)),
  }),
)(NotificationsForm);
/* eslint-enable react/no-unused-prop-types */
