import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import queryString from 'qs';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import { defineMessages, FormattedMessage } from 'react-intl';
import {
  branch,
  compose,
  defaultProps,
  setPropTypes,
  withHandlers,
  withStateHandlers,
} from 'recompose';

// TODO: aliases fail on "client common eslint"
import ProviderList from '../../storybook/src/components/organisms/ProviderList/ProviderList';

import LocationRow from '../../components/molecules/locationRow/locationRow';
import providerSearchEvents from '../../store/tools/analytics/interventionFlows/interventionFlowsEvents';
import SpinnerButton from '../ui/spinner/spinnerButton';
import ThreeBounceSpinner from '../ui/spinner/threeBounceSpinner';

import './providerSearch.less';
import { createToggleState, createToggleStatePropTypes } from '../recompose/toggleStateComponent';
import ProviderFilterModal from '../../components/organisms/providerFilterModal/providerFilterModal';
import DoctorsResultsFilter from '../../components/molecules/doctorsResultsFilter/doctorsResultsFilter';
import injectNotification from '../../store/notification/injectNotification';
import { withFetchers } from '../../api/injectApi/withFetchers';
import withEffects from '../recompose/withEffects';
import { withTranslatedMessages } from '../../utils/withTranslatedMessages';
import withAlreadyBookedModal from '../withAlreadyBookModal/withAlreadyBookedModal';
import withBookingModal from '../bookingModal/withBookingModal';
import queryConnect from '../../store/tools/queryString/queryConnect';
import queryStringParseOptions from '../../utils/queryStringParseOptions';
import { member } from '../../store/member/selectors';

const HOME = 'HOME';

const messages = defineMessages({
  seeMoreInYourNetwork: {
    defaultMessage: 'See more doctors',
    id: 'members.selectProvider.seeMoreDoctors',
  },
  showDoctorDetails: {
    defaultMessage: 'Details',
    id: 'members.selectProvider.showDoctorDetails',
  },
  home: {
    defaultMessage: 'Home',
    id: 'members.directory.booking.home',
  },
});

@autobind
class ProviderSearch extends React.PureComponent {
  static propTypes = {
    providers: PropTypes.array.isRequired,
    getProviders: PropTypes.func.isRequired,
    getProvidersTracker: PropTypes.object.isRequired,
    renderTitle: PropTypes.func.isRequired,
    renderSearchArea: PropTypes.func,
    renderBottomArea: PropTypes.func,
    renderActions: PropTypes.func,
    member: PropTypes.object.isRequired,
    numOfChunks: PropTypes.number.isRequired,
    genderFilterOn: PropTypes.bool,
    distanceFilterOn: PropTypes.bool,
    languageFilterOn: PropTypes.bool,
    ...createToggleStatePropTypes('filterModal'),
  };

  static defaultProps = {
    renderSearchArea: undefined,
    renderBottomArea: undefined,
    renderActions: undefined,
    genderFilterOn: false,
    distanceFilterOn: false,
    languageFilterOn: false,
  };

  static contextTypes = {
    analytics: PropTypes.object.isRequired,
  };

  increaseNumOfChunks() {
    const { getProviders, numOfChunks: currentNumOfChunks, control } = this.props;
    const numOfChunks = currentNumOfChunks + 1;
    this.context.analytics.track(
      providerSearchEvents.providerSearchEvents.resultsPage.showMoreClick,
      {
        clickCount: numOfChunks,
        flowSource: control.name,
      },
    );

    getProviders();
  }

  render() {
    const {
      providers,
      total,
      member,
      getProvidersTracker,
      filterModalOn,
      filterModalHide,
      filterModalShow,
      filters = { location: {} },
      setFilters,
      renderTitle,
      renderSearchArea,
      renderBottomArea,
      onBookClick,
      enableBooking,
      renderActions,
      genderFilterOn,
      distanceFilterOn,
      languageFilterOn,
    } = this.props;
    return (
      <div>
        <main>
          <div className="provider-selection-page text-center">
            <div className="top-section clearfix">
              {renderTitle && renderTitle()}
              {renderSearchArea && renderSearchArea(member)}
            </div>
            <div className="provider-selection-page-content clearfix">
              <div className="provider-filter-row">
                <DoctorsResultsFilter onClick={filterModalShow} />
                <LocationRow inputFilters={filters} onApply={setFilters} />
              </div>

              <ProviderList
                plan={member.plan}
                providers={providers}
                showBook={enableBooking}
                onBookClick={onBookClick}
                memberContext={member.memberContext}
                renderActions={renderActions && renderActions(member)}
              />
              {getProvidersTracker.inProgress && <ThreeBounceSpinner />}
              {!_.isEmpty(providers) && !getProvidersTracker.inProgress && (
                <div>
                  <div className="text-center margin-bottom-20">
                    {providers.length < total && (
                      <SpinnerButton
                        onClick={this.increaseNumOfChunks}
                        className="btn btn-inline-link font-color-brand-link"
                        isLoading={getProvidersTracker.inProgress}
                      >
                        <span className="text-underline">
                          <FormattedMessage {...messages.seeMoreInYourNetwork} />
                        </span>
                      </SpinnerButton>
                    )}

                    {renderBottomArea && renderBottomArea()}
                  </div>
                </div>
              )}
            </div>
          </div>
        </main>
        <ProviderFilterModal
          isOpen={filterModalOn}
          onClose={filterModalHide}
          filters={filters}
          onApply={setFilters}
          genderFilterOn={genderFilterOn}
          distanceFilterOn={distanceFilterOn}
          languageFilterOn={languageFilterOn}
        />
      </div>
    );
  }
}

export default compose(
  setPropTypes({
    enableBooking: PropTypes.bool,
    allowMultipleBooking: PropTypes.bool,
  }),
  defaultProps({
    enableBooking: false,
    allowMultipleBooking: true,
  }),
  createToggleState('filterModal'),
  injectNotification,
  withTranslatedMessages(messages),
  connect(state => ({
    member: member(state),
  })),
  withStateHandlers(
    ({ member = {} }) => ({
      providers: [],
      total: null,
      member,
    }),
    {
      onPCPChunkReceived: props => ({ member, pcps: providers, total }) => ({
        member: member || props.member,
        providers: [...props.providers, ...providers],
        total,
      }),
      clearPCPs: () => () => ({ providers: [], total: null }),
    },
  ),
  queryConnect((query, { home }) => {
    const defaultFilters = { location: { displayName: home, locationType: HOME } };
    const queryObject = queryString.parse(query, queryStringParseOptions);

    return {
      filters: _.assign({}, defaultFilters, _.omitBy(queryObject.filters, _.isEmpty)),
    };
  }),
  withHandlers({
    setFilters: ({ setInQueryString }) => filters => {
      const removeDefaultLocation = filters => {
        const { location, ...restFilters } = filters;
        return _.get(location, 'locationType') === HOME || !location
          ? { ...restFilters, location: undefined }
          : filters;
      };
      const clearEmptyFilters = filters =>
        _.isEmpty(_.omitBy(filters, _.isEmpty)) ? undefined : filters;

      const filtersForQuery = _.chain(filters)
        .thru(removeDefaultLocation)
        .thru(clearEmptyFilters)
        .value();

      setInQueryString({ filters: filtersForQuery });
    },
  }),
  withFetchers({
    getProviders: {
      handler: ({ getProvidersApi, filters: { location, distance, gender, language } }) => (
        limit,
        page,
      ) =>
        getProvidersApi({
          limit,
          skip: (page - 1) * limit,
          geo: _.get(location, 'geo'),
          distance,
          gender,
          spokenLanguage: language,
        }),
      onSuccess: ({ onPCPChunkReceived }, result) => onPCPChunkReceived(result),
    },
  }),
  withStateHandlers(
    { numOfChunks: 0 },
    {
      getProviders: ({ numOfChunks }, props) => clear => {
        const { clearPCPs, getProviders, limitConfig: limit } = props;
        let nextPage = numOfChunks + 1;
        if (clear) {
          nextPage = 1;
          clearPCPs();
        }
        getProviders(limit, nextPage);
        return {
          numOfChunks: nextPage,
        };
      },
    },
  ),
  withEffects({
    callProviders: {
      action: ({ getProviders }, didUpdate) => getProviders(didUpdate),
      didMountPredicate: () => true,
      didUpdatePredicate: (props, prevProps) => !_.isEqual(prevProps.filters, props.filters),
    },
  }),
  branch(
    ({ enableBooking, allowMultipleBooking }) => enableBooking && !allowMultipleBooking,
    withAlreadyBookedModal,
  ),
  branch(
    ({ enableBooking }) => enableBooking,
    withBookingModal(({ onBookingSuccess }) => ({
      onBookingSuccess,
    })),
  ),
)(ProviderSearch);
