import _ from 'lodash';
import Bluebird from 'bluebird';
import React from 'react';
import en from 'react-intl/locale-data/en';
import es from 'react-intl/locale-data/es';
import vi from 'react-intl/locale-data/vi';
import { render } from 'react-dom';
import moment from 'moment';
import { Provider } from 'react-redux';
import { applyRouterMiddleware, match, Router, useRouterHistory } from 'react-router';
import useScroll from 'react-router-scroll/lib/useScroll';
import { createHistory } from 'history';
import { addLocaleData } from 'react-intl';
import { routerMiddleware, syncHistoryWithStore } from 'react-router-redux';
import ht from '../../../utils/intl/haitianCreole';
import getAppRoutes from './routes/rootRoute';
import { Basename } from './app/App';
import configureStore from './store/configureStore';
import '../../../utils/accessibility';

// http://bluebirdjs.com/docs/why-bluebird.html
window.Promise = Bluebird;
window.moment = moment;

// github.com/yahoo/react-intl/wiki/Upgrade-Guide#add-call-to-addlocaledata-in-members
addLocaleData([...en, ...es, ...vi, ...ht]);

const target = document.querySelector('#root');
export default async function start() {
  // Create an enhanced history that syncs navigation events with the store
  let history = useRouterHistory(createHistory)({
    basename: Basename,
  });

  // TODO handle failure of configureStore and show error page to the user
  const store = await configureStore({
    platformMiddleware: [routerMiddleware(history)],
  });

  const state = store.getState();
  const isTestMode = _.get(state, 'config.testMode', false);
  const features = _.get(state, 'config.features');
  const routes = getAppRoutes(isTestMode, features);
  history = syncHistoryWithStore(history, store);
  match({ history, routes }, (error, redirectLocation, renderProps) => {
    render(
      <Provider store={store}>
        <Router {...renderProps} render={applyRouterMiddleware(useScroll())} />
      </Provider>,
      target,
    );
  });
}
