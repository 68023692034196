import _ from 'lodash';

class IntlEnum {
  /**
   *
   * @param {object} enumValues - objects making up the values of this enum
   * each value need to contain a string value (which will also act a key) and message (inlt obj)
   */
  constructor(...enumValues) {
    _.each(enumValues, enumVal => {
      this[enumVal.value] = enumVal;
    });
  }

  /**
   * returns the appropriate intl message for the given value base on the pre-defined enum.
   * If the value does not exist in the enum returns the default value from `defaultKey` message
   * @param enumValue
   * @returns {*}
   */
  getMessageByValue(enumValue) {
    if (this[enumValue]) {
      return this[enumValue].message;
    }

    const defaultMessage = _.get(this, [IntlEnum.defaultKey, 'message']);
    if (!defaultMessage) {
      throw new Error(
        `Failed to map intl enum value. value=${enumValue} enumValues=${JSON.stringify(this)}`,
      );
    }

    return defaultMessage;
  }

  /**
   * Translates the enum into a array of {value, label} ready to use in a select component
   * @param {object} intl - i18n control object, used to format the messages
   * @param options {allowedValues?, forbiddenValues?} - Optional list for include an exclude values
   * if non given, all values are considered valid
   * @returns {[{value: string, label: string}]}
   */
  toSelectOptions(intl, options = {}) {
    let { allowedValues, forbiddenValues = [] } = options;
    allowedValues = _.isEmpty(allowedValues) ? _.keys(this) : allowedValues;
    return _.chain(this)
      .pick(allowedValues)
      .omit(forbiddenValues)
      .sortBy('_order')
      .map(enumValue => ({
        value: enumValue.value,
        label: _.isString(enumValue.message)
          ? enumValue.message
          : intl
            ? intl.formatMessage(enumValue.message)
            : enumValue.message.defaultMessage
      }))
      .value();
  }
}

// Default key for default message if none found in getMessageByValue
IntlEnum.defaultKey = 'DEFAULT';

export default IntlEnum;
