import * as BookingType from './bookingType';
import * as SessionEntityTypes from './sessionEntityTypes';

const map = {
  [BookingType.INSTANT]: SessionEntityTypes.INSTANT_BOOK,
  [BookingType.REQUEST]: SessionEntityTypes.REQUEST_TO_BOOK,
  [BookingType.CONCIERGE]: SessionEntityTypes.CONCIERGE_BOOK,
};

export default map;
