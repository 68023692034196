import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '../../storybook/src/utils/theme';

function SvgHomeStars({ theme }) {
  /* eslint-disable */
  return (
    <svg aria-hidden="true" focusable="false" width="59px" height="61px" viewBox="0 0 59 61" version="1.1" className="svg-icon svg-home-stars">
      <defs>
        <polygon id="path-1" points="0 60.6816 59 60.6816 59 -0.0004 0 -0.0004"></polygon>
      </defs>
      <g id="All-settings-screens" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Home-address" transform="translate(-158.000000, -146.000000)">
          <g id="Page-1" transform="translate(158.000000, 146.000000)">
            <path d="M49.5001,24.6816 L9.5001,24.6816 L5.6891,24.6816 L29.5001,5.1226 L53.3111,24.6816 L49.5001,24.6816 Z M58.4521,25.0226 L30.4521,2.0226 C29.8991,1.5676 29.1011,1.5676 28.5481,2.0226 L0.5481,25.0226 C0.0591,25.4246 -0.1259,26.0906 0.0881,26.6876 C0.3021,27.2836 0.8661,27.6816 1.5001,27.6816 L8.0001,27.6816 L8.0001,37.1816 C8.0001,38.0096 8.6721,38.6816 9.5001,38.6816 C10.3281,38.6816 11.0001,38.0096 11.0001,37.1816 L11.0001,27.6816 L48.0001,27.6816 L48.0001,57.6816 L44.5001,57.6816 C43.6721,57.6816 43.0001,58.3536 43.0001,59.1816 C43.0001,60.0096 43.6721,60.6816 44.5001,60.6816 L49.5001,60.6816 C50.3281,60.6816 51.0001,60.0096 51.0001,59.1816 L51.0001,27.6816 L57.5001,27.6816 C58.1341,27.6816 58.6981,27.2836 58.9121,26.6876 C59.1261,26.0906 58.9411,25.4246 58.4521,25.0226 Z" id="Fill-1" fill={theme.mainColor} ></path>
            <path d="M14.5001,57.6816 L11.0001,57.6816 L11.0001,55.1816 C11.0001,54.3536 10.3281,53.6816 9.5001,53.6816 C8.6721,53.6816 8.0001,54.3536 8.0001,55.1816 L8.0001,59.1816 C8.0001,60.0096 8.6721,60.6816 9.5001,60.6816 L14.5001,60.6816 C15.3281,60.6816 16.0001,60.0096 16.0001,59.1816 C16.0001,58.3536 15.3281,57.6816 14.5001,57.6816" id="Fill-4" fill={theme.mainColor} ></path>
            <g id="Clip-7"></g>
            <path d="M21.667,57.6816 L37.333,57.6816 L37.333,42.0146 L21.667,42.0146 L21.667,57.6816 Z M38.833,60.6816 L20.167,60.6816 C19.339,60.6816 18.667,60.0096 18.667,59.1816 L18.667,40.5146 C18.667,39.6866 19.339,39.0146 20.167,39.0146 L38.833,39.0146 C39.661,39.0146 40.333,39.6866 40.333,40.5146 L40.333,59.1816 C40.333,60.0096 39.661,60.6816 38.833,60.6816 Z" id="Fill-6" fill={theme.secondaryColor} ></path>
            <path d="M53.1817,3.3408 L51.3407,3.3408 L51.3407,1.4998 C51.3407,0.6718 50.6687,-0.0002 49.8407,-0.0002 C49.0127,-0.0002 48.3407,0.6718 48.3407,1.4998 L48.3407,3.3408 L46.4997,3.3408 C45.6717,3.3408 44.9997,4.0128 44.9997,4.8408 C44.9997,5.6688 45.6717,6.3408 46.4997,6.3408 L48.3407,6.3408 L48.3407,8.1818 C48.3407,9.0098 49.0127,9.6818 49.8407,9.6818 C50.6687,9.6818 51.3407,9.0098 51.3407,8.1818 L51.3407,6.3408 L53.1817,6.3408 C54.0097,6.3408 54.6817,5.6688 54.6817,4.8408 C54.6817,4.0128 54.0097,3.3408 53.1817,3.3408" id="Fill-8" fill={theme.secondaryColor} ></path>
            <path d="M5.5001,43.6816 C4.1211,43.6816 3.0001,44.8026 3.0001,46.1816 C3.0001,47.5606 4.1211,48.6816 5.5001,48.6816 C6.8791,48.6816 8.0001,47.5606 8.0001,46.1816 C8.0001,44.8026 6.8791,43.6816 5.5001,43.6816 M5.5001,51.6816 C2.4681,51.6816 0.0001,49.2136 0.0001,46.1816 C0.0001,43.1496 2.4681,40.6816 5.5001,40.6816 C8.5321,40.6816 11.0001,43.1496 11.0001,46.1816 C11.0001,49.2136 8.5321,51.6816 5.5001,51.6816" id="Fill-9" fill={theme.secondaryColor} ></path>
            <path d="M57.5001,42.792 C57.5001,43.56 56.8781,44.182 56.1101,44.182 C55.3421,44.182 54.7201,43.56 54.7201,42.792 C54.7201,42.023 55.3421,41.401 56.1101,41.401 C56.8781,41.401 57.5001,42.023 57.5001,42.792" id="Fill-10" fill={theme.secondaryColor} ></path>
          </g>
        </g>
      </g>
    </svg>
  );
  /* eslint-enable */
}

SvgHomeStars.propTypes = {
  theme: PropTypes.object.isRequired,
};

export default withTheme(SvgHomeStars);
