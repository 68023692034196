import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { defineMessages, FormattedMessage } from 'react-intl';
import { Field } from 'redux-form';
import { autobind } from 'core-decorators';

import * as ActivationForm from '../activationForm';
import SpinnerButton from '../../../../../components/ui/spinner/spinnerButton';
import SvgUserCircle from '../../../../../assets/svg/userCircle';
import { getFeature } from '../../../../../store/feature/selectors';
import { withActivationForm } from './withActivationForm';

const messages = defineMessages({
  whatsYourName: {
    defaultMessage: "What's your name?",
    id: 'activation.mobileNum.whatsYourName',
  },
  yourFirstName: {
    defaultMessage: 'Your First Name',
    id: 'activation.mobileNum.yourFirstName',
  },
  yourLastName: {
    defaultMessage: 'Your Last Name',
    id: 'activation.mobileNum.yourLastName',
  },
});

@autobind
class EnterName extends React.Component {
  static propTypes = {
    /**
     * Hook method. supplied by redux-forms
     */
    handleSubmit: PropTypes.func.isRequired,
    /**
     * True if form is currently submitting
     */
    loading: PropTypes.bool,
    submitText: PropTypes.object.isRequired,
  };

  static contextTypes = {
    analytics: PropTypes.object.isRequired,
  };

  static defaultProps = {
    loading: false,
  };

  componentDidMount() {
    this.context.analytics.manualPageReport('activation.name');
  }

  render() {
    const { handleSubmit, loading, submitText } = this.props;

    return (
      <form onSubmit={handleSubmit} className="activation-mobile-num-wrap" method="post">
        <div className="top-section">
          <div className="row">
            <div className="col-xs-12 text-center">
              <SvgUserCircle />
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 text-center">
              <h1 className="title-24 md-margin title-brand-1">
                <FormattedMessage {...messages.whatsYourName} />
              </h1>
            </div>
          </div>
        </div>

        <div className="form-section">
          <div className="row">
            <div className="col-xs-12">
              <Field label={messages.yourFirstName} {...ActivationForm.fields.firstName} />
            </div>
          </div>
        </div>

        <div className="form-section">
          <div className="row">
            <div className="col-xs-12">
              <Field
                inputClassName="no-margin"
                label={messages.yourLastName}
                {...ActivationForm.fields.lastName}
              />
            </div>
          </div>
        </div>

        <div className="row padding-v-30">
          <div className="col-xs-12">
            <SpinnerButton
              isLoading={loading}
              className="btn btn-big bg-color-brand-button"
              type="submit"
            >
              <FormattedMessage {...submitText} />
            </SpinnerButton>
          </div>
        </div>
      </form>
    );
  }
}

const fieldName = ['firstName', 'lastName'];
export default compose(
  connect(state => ({
    submitText: getFeature(state, 'activationFlow.fullNameStep.submit'),
  })),
  withActivationForm(fieldName),
)(EnterName);
