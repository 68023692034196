/**
 * Created by matan on 2/16/16.
 */

import React from 'react';

const SvgCalendarComplete = React.createClass({
  /* eslint-disable */
  render() {
    return (
      <svg aria-hidden="true" focusable="false" className="svg-icon svg-icon-calendar-complete" width="22px" height="22px" viewBox="0 0 22 22" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns-xlink="http://www.w3.org/1999/xlink" xmlns-sketch="http://www.bohemiancoding.com/sketch/ns">
        <defs></defs>
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" sketch-type="MSPage">
          <g id="calendar-complete" sketch-type="MSLayerGroup">
            <g id="Page-1" sketch-type="MSShapeGroup">
              <path d="M21,20 C21,20.552 20.552,21 20,21 L2,21 C1.449,21 1,20.552 1,20 L1,7 L21,7 L21,20 L21,20 Z" id="Shape-path-Copy" fill="#FFFFFF"></path>
              <g id="complete-apt-icon-22x22_Grey" fill="#37AB2D" className="frame">
                <g id="Group">
                  <path d="M20,3 L18,3 L18,0 L16,0 L16,3 L6,3 L6,0 L4,0 L4,3 L2,3 C0.896,3 0,3.896 0,5 L0,20 C0,21.104 0.896,22 2,22 L20,22 C21.104,22 22,21.104 22,20 L22,5 C22,3.896 21.104,3 20,3 L20,3 L20,3 Z M21,20 C21,20.552 20.552,21 20,21 L2,21 C1.449,21 1,20.552 1,20 L1,7 L21,7 L21,20 L21,20 Z" id="Shape"></path>
                  <path d="M9.878,17.999 L9.879,18 L16.412,11.465 L14.645,9.697 L9.878,14.464 L7.587,12.174 L5.819,13.941 L9.877,18 L9.878,17.999 L9.878,17.999 Z" id="Shape" className="check"></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    );
    /* eslint-enable */
  }
});


export default SvgCalendarComplete;
