/**
 * Created by matan on 10/9/17.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { compose, branch, renderNothing } from 'recompose';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import Recaptcha from 'react-recaptcha';
import _ from 'lodash';

import './bkmdRecaptcha.less';
import ThreeBounceSpinner from '../ui/spinner/threeBounceSpinner';
import actionTracker, {
  actionTrackerShape
} from '../../store/tools/actionTracker/actionTrackerComponent';
import {
  RESET_RECAPTCHA,
  saveRecaptchaToken,
  clearRecaptchaToken
} from '../../store/recaptcha/actions';

export const RecaptchaSize = {
  NORMAL: 'normal',
  COMPACT: 'compact'
};

@autobind
class BkmdRecaptcha extends React.Component {
  static propTypes = {
    /**
     * Boolean value indicating if the ReCaptcha code has been loaded to the client.
     * Provided by the connect HoC
     */
    recaptchaReady: PropTypes.bool.isRequired,
    /**
     * Api Key for google recaptcha.
     * Provided by the connect HoC
     */
    recaptchaApiKey: PropTypes.string.isRequired,
    /**
     * Display language for recaptcha component.
     * Provided by the connect HoC
     */
    language: PropTypes.string.isRequired,
    /**
     * Sets the recaptcha render size. Options are normal and compact. (import enum from this file)
     * default is normal
     */
    size: PropTypes.string,
    /**
     * Redux store actions
     * Provided by connect HoC
     */
    saveRecaptchaToken: PropTypes.func.isRequired,
    /**
     * Redux store actions
     * Provided by connect HoC
     */
    clearRecaptchaToken: PropTypes.func.isRequired,
    /**
     * Action tracking for reset recaptch actions.
     * Component trackes all recaptch reset requests dispatched in the system and reacts.
     * Provided by the action tracker HoC
     */
    resetRecaptchTracking: actionTrackerShape, // eslint-disable-line react/no-unused-prop-types
  };

  static defaultProps = {
    size: RecaptchaSize.NORMAL,
    resetRecaptchTracking: {}
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.resetRecaptchTracking.finished) {
      this.onExpired();
      _.invoke(this.recaptcha, 'reset'); // safe invoke JIC Recaptcha not yet loaded
    }
  }

  componentWillUnmount() {
    this.onExpired();
  }

  onChange(token) {
    this.props.saveRecaptchaToken(token);
  }

  onExpired() {
    this.props.clearRecaptchaToken();
  }

  render() {
    const { recaptchaReady, recaptchaApiKey, size, language } = this.props;
    return !recaptchaReady ? (
      <ThreeBounceSpinner spinnerClass={'va-mid-spinner'} />
    ) : (
      <Recaptcha
        ref={e => {
          this.recaptcha = e;
        }}
        sitekey={recaptchaApiKey}
        size={size}
        verifyCallback={this.onChange}
        expiredCallback={this.onExpired}
        hl={language}
        render="explicit"
      />
    );
  }
}

export default compose(
  // https://github.com/google/recaptcha/issues/269
  // this bug makes some Testim.io tests in the CI not work
  // so we don't render the component if we're in the CI
  branch(() => process.env.IS_CI === true, renderNothing),
  actionTracker({
    resetRecaptchTracking: RESET_RECAPTCHA
  }),
  connect(
    state => ({
      recaptchaReady: _.get(state.recaptcha, 'ready'),
      recaptchaApiKey: _.get(state.config, 'recaptcha.key', ''),
      language: _.get(state, 'intl.locale') || _.get(state, 'intl.locale', 'en')
    }),
    {
      saveRecaptchaToken,
      clearRecaptchaToken
    }
  )
)(BkmdRecaptcha);
