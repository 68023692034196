/**
 * Created by matan on 3/12/17.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, FormattedMessage, FormattedHTMLMessage } from 'react-intl';

import BkmdModal from '../../../../../components/bkmdModal/bkmdModal';
import ModalHeader from '../../../../../components/bkmdModal/modalHeader';

const messages = defineMessages({
  subTitle: {
    defaultMessage: 'Why do I need to get verified?',
    id: 'activation.mobileNumModal.subTitle',
  },
  mainText: {
    defaultMessage:
      'We use your phone number to confirm your identity and to protect your ' +
      "privacy.<br/><br/>We'll never share your information with anyone else.<br/><br/>We'll " +
      'periodically reach out to you with helpful info about your plan and benefits. For ' +
      "example, we'll send you notifications about your plan deductibles and tips for upcoming " +
      'doctor appointments. This way, you can stay informed and manage decisions for getting care. ' +
      'You can turn off notifications any time.<br/><br/>If you need help, please call customer ' +
      'support at (877) 233-3943.',
    id: 'activation.mobileNumModal.mainText',
  },
});

export default function PhoneNumberModal(props) {
  return (
    <BkmdModal className="fixed-header" {...props}>
      <ModalHeader onClose={props.handleClose} title="Your contact info" />
      <div className="dialog-body">
        <div className="row">
          <div className="col-xs-12 activation-modal-subtitle text-center">
            <span className="text-18 text-semibold text-dark">
              <FormattedMessage {...messages.subTitle} />
            </span>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 text-16">
            <FormattedHTMLMessage {...messages.mainText} />
          </div>
        </div>
      </div>
    </BkmdModal>
  );
}

PhoneNumberModal.propTypes = {
  /**
   * Hook method.
   * Called whenever the modal should be closed
   */
  handleClose: PropTypes.func.isRequired,
  /**
   * Name for identifying via analytics
   */
  name: PropTypes.string, // eslint-disable-line react/no-unused-prop-types
};

PhoneNumberModal.defaultProps = {
  name: 'phone_number_modal',
};
