import React from 'react';
import { branch, renderComponent } from 'recompose';
import PropTypes from 'prop-types';
import _ from 'lodash';
import directoryMessages from '../../messages/providersCommon/messages';
import PointDistance from '../ui/map/pointDistance';

const DoctorLocation = ({ doctorLocation, clientLocation }) => (
  <div className="distance">
    <i className="icon-map-pin-e text-16 i-va-fix-2 hidden-xs" />
    &nbsp;
    <PointDistance
      message={directoryMessages.clinicDistance}
      p1={doctorLocation.geo}
      p2={clientLocation.geo}
    />
  </div>
);

DoctorLocation.propTypes = {
  doctorLocation: PropTypes.object.isRequired,
  clientLocation: PropTypes.object.isRequired,
};

export default branch(
  ({ doctorLocation, clientLocation }) =>
    _.isEmpty(doctorLocation) || _.isEmpty(clientLocation),
  renderComponent(() => null),
)(DoctorLocation);

