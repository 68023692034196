import { compose, withPropsOnChange } from 'recompose';
import { get, isString } from 'lodash';
import withFormikWrapper from '../formik/withFormikWrapper';
import CleanPhoneInput from '../cleanPhoneNumber';

export const CleanFormikPhoneInput = compose(
  withFormikWrapper(),
  withPropsOnChange(['errorText'], ({ errorText }) => {
    if (!errorText || isString(errorText) || get(errorText, 'id')) return errorText;
    if (errorText.countryDialingCode) return { errorText: errorText.countryDialingCode };
    if (errorText.number) return { errorText: errorText.number };
    return errorText;
  }),
)(CleanPhoneInput);
