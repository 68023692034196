/**
 * Created by galgoltzman on 01/10/2017.
 */

import _ from 'lodash';
import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import * as selectors from '../../../../../../store/familyActivation/selectors';
import {
  inviteDependent,
  INVITE_FAMILY_MEMBER,
} from '../../../../../../store/familyActivation/actions';
import InviteMemberForm from './inviteMemberForm';
import { clearTrackingData } from '../../../../../../store/tools/actionTracker/actions';

@autobind
class RestOfFamilyStep extends React.PureComponent {
  static propTypes = {
    inviteDependent: PropTypes.func.isRequired,
    clearTrackingData: PropTypes.func.isRequired,
    dependentsList: PropTypes.arrayOf(PropTypes.object).isRequired,
  };

  sendInvite({ contactInfo }, arg, { dependent }) {
    this.props.inviteDependent({
      domainMemberId: dependent.id, // here, id is the domainMemberId
      contactInfo,
    });
  }

  clearTracker(key) {
    this.props.clearTrackingData(`${INVITE_FAMILY_MEMBER}-${key}`);
  }

  renderInviteMemberForm(dependent) {
    return (
      <InviteMemberForm
        key={dependent.id}
        onSubmit={this.sendInvite}
        dependent={dependent}
        onFinish={this.clearTracker}
        form={dependent.id}
      />
    );
  }

  render() {
    const { dependentsList } = this.props;
    return (
      <div>
        <div className="invite-card-list">
          {_.map(dependentsList, dependent => this.renderInviteMemberForm(dependent))}
        </div>
      </div>
    );
  }
}

export default compose(
  connect(
    state => ({
      dependentsList: selectors.getDependents(state),
    }),
    { inviteDependent, clearTrackingData },
  ),
)(RestOfFamilyStep);
