import { compose, lifecycle, mapProps, withHandlers, withState } from 'recompose';
import _ from 'lodash';
import { loadingUntil } from '../../components/recompose/loadingUntil';
import MiddlewareManager from './middlewareManager';

const middlewareManager = new MiddlewareManager();
const _hocs = [];

const _setter = propName => `set${propName}`;

/**
 * Hooks the onSuccess of withFetch method and set the value to a given props
 * If onSuccess method is defined - it will be called after the prop will be assigned
 * @param onSuccess - (optional) a method that will be called after the prop will be assigned
 * @param setterProp - the name of the setter prop
 * @returns {function(*=, *=)}
 * @private
 */
const _onSuccessToState = (setterProp, onSuccess) => (props, result) => {
  props[setterProp](result);
  if (onSuccess) onSuccess(props, result);
};

/**
 * Simple util that receive array of props and remove them from the component
 * It's just an alias to mapProps with _.omit to improve the readability of the code :)
 * @param propsToRemove
 * @returns {*}
 * @private
 */
const _removeProps = propsToRemove => mapProps(props => _.omit(props, propsToRemove));

/**
 * Used to wrap all the fetch HOCs with the HOCs supplied
 * @param hoc
 */
const applyHOCs = (...hoc) => {
  _hocs.push(...hoc);
};

/**
 * Adds a prop that will make some async call when called
 * It can be hooked by pass onStart, onSuccess and onError params
 * For example - if we need to know when the action starts -
 * We just need to pass our own implementation using this options:
 * { onStart: props => { console.log('Promise has been started!'); } }
 * Every step of the hook will receive as first param the props of the wrapped component
 * The second param will be the data that returned from the promise (response or error)
 * @param propName - the name of the added prop
 * @param promise - Promise to some async action
 * @param onStart: (props) => {}
 * @param onSuccess: (props, response) => {}
 * @param onError: (props, err) => {}
 * @param _asHandler: boolean indicating whether to treat the 'promise' param as a handler
 * (a function that will return another function from props)
 * @param middlewares - additional middlewares specific for this call
 * @returns {*}
 * @private
 */
const _withFetch = (
  promise,
  propName,
  { onStart, onSuccess, onError, _asHandler } = {},
  middlewares,
) =>
  compose(
    ..._hocs,
    withHandlers({
      [propName]: middlewareManager.createFetchFunction(
        promise,
        propName,
        { onStart, onSuccess, onError, _asHandler },
        middlewares,
      ),
    }),
  );

/**
 * Add tracker to async call, the name will be the prop that passed, suffixed by Tracker
 * For example: given prop searchMember - the tracker name will be searchMemberTracker
 * The tracker will be update when the request finished
 * During execution the value of tracker.inProgress will be true,
 * and when it finished it will be false
 *
 * During execution the value will be:
 * { inProgress: true, executed: false, error: null, hasError: false }
 *
 * On case of success the value will be:
 * { inProgress: false, executed: true, error: null, hasError: false }
 *
 * On case of error the value will be:
 * { inProgress: false, executed: true, error: err, hasError: true }
 *
 * The usage in the wrapped component is simply bind to tracker properties,
 * for example, assume that we need to show some loading indication:
 * <Loader isLoading={tracker.inProgress} />
 * will pass true to Loader while the action is not finished
 * @param promiseProp - the prop of the async operation that will be tracked
 * @private
 * @param promise - A reference to the api action that we should run
 * @param propName
 */
const _withTracker = (promise, propName, { onSuccess, onError, _asHandler } = {}) => {
  const tracker = `${propName}Tracker`;
  const setter = _setter(tracker);

  const trackingMiddleware = {
    onStart: () => next => props => {
      props[setter]({ inProgress: true, executed: false, error: null, hasError: false });
      next(props);
    },
    onSuccess: () => next => (props, result) => {
      props[setter]({ inProgress: false, executed: true, error: null, hasError: false });
      next(props, result);
    },
    onError: () => next => (props, err) => {
      props[setter]({ inProgress: false, executed: true, error: err, hasError: true });
      next(props, err);
    },
  };

  return compose(
    withState(tracker, setter, {}),
    _withFetch(promise, propName, { onSuccess, onError, _asHandler }, [trackingMiddleware]),
    _removeProps([setter]),
  );
};

/**
 * Wraps a component with _withTracker or _withFetch
 * This allow us to decide if you need a tracker or not, by pass a value to options.track
 * See docs of _withTracker and _withFetch for more information
 * @param promise
 * @param propName
 * @param onSuccess
 * @param onError
 * @param track - a boolean that
 * @returns {*}
 */
const withFetch = (
  promise,
  propName,
  { onSuccess, onError, track = true, _asHandler = false } = {},
) => {
  const HOC = track ? _withTracker : _withFetch;
  return HOC(promise, propName, { onSuccess, onError, _asHandler });
};

const withFetchHandler = (handler, propName, options) =>
  withFetch(handler, propName, { ...options, _asHandler: true });

/**
 * Wraps a component with withFetch and assign the return value of the async call to the given prop.
 * You can decide if you need a track or not
 * see docs of withFetch for more information
 * @param promise
 * @param propName
 * @param onSuccess
 * @param onError
 * @param track
 * @param resultPropName - prop the will be receive the result of the async call
 * @param defaultValue - the initial value of the prop
 * @param asHandler - will expect to an handler instead of a promise
 * @returns {*}
 */
const withStateFetch = (
  promise,
  propName,
  { onSuccess, onError, track = true, resultPropName, defaultValue = null, asHandler = false } = {},
) => {
  const setterProp = `set${resultPropName}`;
  return compose(
    withState(resultPropName, setterProp, defaultValue),
    withFetch(promise, propName, {
      onSuccess: _onSuccessToState(setterProp, onSuccess),
      onError,
      track,
      _asHandler: asHandler,
    }),
    _removeProps([setterProp]),
  );
};

const trueFunc = () => true;
const withFetchOnMount = (apiAction, actionName, { onSuccess, onError, loadingPredicate } = {}) =>
  compose(
    ..._hocs,
    lifecycle({
      componentDidMount() {
        if (!loadingPredicate || !loadingPredicate(this.props)) {
          const wrappedApiAction = middlewareManager.createFetchOnMountFunction(
            apiAction,
            actionName,
            { onSuccess, onError },
          );
          wrappedApiAction(this.props);
        }
      },
    }),
    loadingUntil(loadingPredicate || trueFunc),
  );

const { applyMiddleware } = middlewareManager;
export {
  applyHOCs,
  applyMiddleware,
  withFetchOnMount,
  withStateFetch,
  withFetch,
  withFetchHandler,
};
