/**
 * Created by matan on 3/14/17.
 */
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { injectIntl, intlShape } from 'react-intl';

import { MessageShape } from '../intlString';

const NON_NUMBERS_REGEX = /[\D]/g; // Finds all non-number characters in a string

class PhoneLink extends React.PureComponent {
  static propTypes = {
    /**
     * i18n controller.
     * Supplied by the injectIntl HoC
     */
    intl: intlShape.isRequired,
    /**
     * The phone number to display and call.
     * Can be either a simple string or an intl message object.
     * string can contain any character, but only numbers are used for the dialing.
     */
    phoneNumber: MessageShape.isRequired,
    /**
     * Optional component css class name
     */
    className: PropTypes.string
  };

  static defaultProps = {
    className: '',
  };

  render() {
    const { intl, phoneNumber, className } = this.props;
    const number = _.isString(phoneNumber) ? phoneNumber : intl.formatMessage(phoneNumber);

    return (
      <a className={className} href={`tel:${number.replace(NON_NUMBERS_REGEX, '')}`}>
        {number}
      </a>
    );
  }
}

export default injectIntl(PhoneLink);
