import _ from 'lodash';
import { combineReducers } from 'redux';
import reduceReducers from 'reduce-reducers';
import * as ActionTypes from './actionTypes';

const reduceFlowState = (state = {}, action) => {
  switch (action.type) {
    case ActionTypes.UPDATE_FLOW:
      return { ...state, ...action.payload };
    case ActionTypes.UPDATE_FLOW_DATA:
      return { ...state, data: Object.assign({}, state.data, action.payload.dataUpdates) };
    case ActionTypes.INIT_FLOW:
      return { ...state, ...action.payload.flowState };
    default:
      return state;
  }
};

const reduceConfig = (state = {}, action) => {
  switch (action.type) {
    case ActionTypes.INIT_FLOW:
      return { ...state, ...action.payload.config };
    default:
      return state;
  }
};

const flowReducer = (state = {}, action) => {
  switch (action.type) {
    case ActionTypes.RESET_FLOW:
      return undefined;
    default:
      return state;
  }
};

const combined = combineReducers({
  config: reduceConfig,
  state: reduceFlowState,
});

const reducer = reduceReducers(combined, flowReducer);

export default (state = {}, action) => {
  if (_.includes(ActionTypes, action.type)) {
    const flow = state[action.payload.name];
    const reducedFlow = reducer(flow, action);

    return { ...state, [action.payload.name]: reducedFlow };
  }

  return state;
};
