/**
 * Created by matan on 7/18/16.
 */
import _ from 'lodash';
/**
 * High order reducer.
 * Wraps an existing reducer and clean the state for a given list of events.
 * @param {function} reducer - The reducer function to wrap
 * @param {[string]} actions - A list of action when passed through the reducer a clean state will
 * passed in
 * @param {object} initialState - Initial state to pass in in case of a action for the action
 * array is passed in. Default is {}
 * @param {[string]} exceptions - An array of top level properties names in the system state.
 * In case of a recycle these properties will not be cleared and will be transferred from the
 * existing state to the initial state passed in.
 * @returns {function(state, action)}
 */
export default function recycleState(reducer, actions = [], initialState = {}, exceptions = []) {
  return (state, action) => {
    if (_.indexOf(actions, action.type) !== -1) {
      let compositeState = initialState;
      if (!_.isEmpty(exceptions)) {
        compositeState = {
          ..._.omit(initialState, exceptions),
          ..._.pick(state, exceptions)
        };
      }

      return reducer(compositeState, { type: '@@redux-recycle/INIT' });
    }

    return reducer(state, action);
  };
}
