/**
 * Created by noahguld on 02/08/2017.
 */
import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { flatten } from 'flat';
import { log } from '../../../utils/logger';

/**
 * HOC that provides analytics on a component. MUST be applied DIRECTLY to component to track.
 * Takes a boolean prop 'track' to flag whether or not this instance should track. *NOTE* track
 * does not get passed down. We do not want to recursively enable or disable tracking.
 * @param Component, the component to track analytics
 * @param defaultEnabled, whether or not to track be default. Defaults to true.
 * @param defaultEventsToTrack, events to be tracked prescribed by component. Takes array of objects
 * of form { event: 'NAME_OF_PROP_FUNC', eventData: function_returning_object }
 */
export default function analyticsHoc(Component,
  defaultEnabled = true,
  defaultEventsToTrack = [{ event: 'onChange' }]) {
  return class AnalyticsHoc extends React.PureComponent {
    static defaultProps = {
      name: '',
      track: undefined,
      additionalEventsToTrack: [],
      disableTrackedEvents: [],
    };

    static propTypes = {
      additionalEventsToTrack: PropTypes.array,
      disableTrackedEvents: PropTypes.array,
      track: PropTypes.bool,
      name: PropTypes.string,
    };

    static removeProps = [
      'additionalEventsToTrack',
      'disableTrackedEvents',
      'track'
    ];

    static contextTypes = {
      analytics: PropTypes.object.isRequired,
      location: PropTypes.object,
      routes: PropTypes.array,
    };

    constructor(props) {
      super(props);

      // protect from double wrapping of analytics wrapper.
      this._track = !Component.hasAnalytics;
      this.hasAnalytics = true;
    }

    _getPropsToPass() {
      return _.omit(this.props, AnalyticsHoc.removeProps);
    }

    _getHookedProps() {
      // don't track if tracking is explicitly disabled or double wrapped.
      if (this.props.track === false || !this._track) return { ...this._getPropsToPass() };

      // dont't track if we not enabled by default and has not explicitly enabled.
      if (this.props.track !== true && !defaultEnabled) return { ...this._getPropsToPass() };

      // hook into the event cycle of the components. Hook into call specifed components, minus
      // those to ignore.
      const newProps = _.chain(defaultEventsToTrack.concat(this.props.additionalEventsToTrack))
        .filter(event => this.props.disableTrackedEvents.indexOf(event.event) === -1)
        .map(({ event, eventData }) => {
          // only wrap if a value exists for the prop and it's not been explicitly removed.
          // We don't want to start changing behaviour by introducing ineffective/unexpected props.
          const propFunc = _.has(this.props, event) ? this.props[event]
            : (Component.defaultProps && Component.defaultProps[event]);
          const baseEventData = {
            componentName: Component.name,
            name: this.props.name || (Component.defaultProps && Component.defaultProps.name)
          };
          const wrappedEventProp = !propFunc ? this.props[event] :
            (...args) => {
              if (!this.context.analytics) {
                log('no analytics found on context object');
                return propFunc(...args);
              }
              this.context.analytics.genericEvent(
                // TODO (Nico): This is workaround because we use react-router v4 in Atlas
                // until we fix it the analytics will be sent
                // from Atlas to MixPanel without route params
                _.get(this.context, 'routes', [{
                  name: 'ATLAS_MOCK_NAME',
                  reportPage: { exec: () => ['ATLAS_MOCK_PATH'] }
                }]),
                _.get(this.context, 'location.path', 'ATLAS_MOCK_PATH'),
                event,
                baseEventData.name ? ` ${baseEventData.name}` : null,
                eventData ? { ...baseEventData, ...flatten(eventData(...args), { safe: true }) }
                  : baseEventData);

              return propFunc(...args);
            };
          return { [event]: wrappedEventProp };
        }).value();

      return _.assign({}, this._getPropsToPass(), ...newProps);
    }

    render() {
      const innerProps = this._getHookedProps();
      return <Component {...innerProps} />;
    }
  };
}
