import esJson from '../../translations/es';

/**
 * Make Json array into object ready for IntlProvider component
 * @param descriptors
 * @returns {*}
 */
const descriptorsToMessages = descriptors =>
  descriptors.reduce(
    (previous, { defaultMessage, id }) => ({
      ...previous,
      [id]: defaultMessage,
    }),
    {},
  );

const es = descriptorsToMessages(esJson);

export default {
  es,
};
