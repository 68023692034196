/**
 * Created by guyavarham on 10/07/2017.
 */

export const searchResults = state => state.directory.searchDoctorsResults.data;
export const searchResultsMeta = state => state.directory.searchDoctorsResults.metadata;
export const autoCompleteProvider = state => state.directory.autoCompleteProvider;
export const recommendedPcp = state => state.directory.recommendedPcp;
export const suggestionsSelector = state => state.directory.suggestions;

