import React from 'react';
import { noop } from 'lodash';
import PropTypes from 'prop-types';
import { withStateHandlers, compose, pure, withPropsOnChange, defaultProps } from 'recompose';
import CleanInput from './cleanInput';
import { MessageShape } from '../ui/intlString';

// TODO: currently supports only American date format
export const defaultDateFormat = 'MM/DD/YYYY';
const DateInput = props => {
  const {
    value,
    onRef,
    onChange,
    label,
    forceRender,
    placeholder,
    fixedLabel,
    showPlaceholderOnlyOnFocus,
    ...rest
  } = props;
  return (
    <CleanInput
      inputRef={onRef}
      label={label}
      placeholder={placeholder || defaultDateFormat}
      forceRender={forceRender}
      value={value}
      fixedLabel={fixedLabel}
      onChange={onChange}
      showPlaceholderOnlyOnFocus={showPlaceholderOnlyOnFocus}
      {...rest}
    />
  );
};

DateInput.propTypes = {
  value: PropTypes.string.isRequired,
  onRef: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  forceRender: PropTypes.number.isRequired,
  label: MessageShape,
  placeholder: MessageShape,
  fixedLabel: PropTypes.bool.isRequired,
  showPlaceholderOnlyOnFocus: PropTypes.bool.isRequired,
};

DateInput.defaultProps = {
  label: undefined,
  onChange: noop,
  placeholder: undefined,
  fixedLabel: true,
  showPlaceholderOnlyOnFocus: false,
};

// TODO: Optimize and memoize format and blocks
const getFormattedDate = function getFormattedDate(
  value,
  format = defaultDateFormat,
  delimiters = ['/'],
) {
  if (!value) return value;
  let res = '';
  const delimiter = delimiters[0];
  const sanitizedValue = value.replace(/[^\d]/g, '').substring(0, 8);
  const blocks = format.split('/');
  let tmpVal = sanitizedValue;
  blocks.forEach(block => {
    const { length } = block;
    const sub = tmpVal.substring(0, length);
    const msb = sub.substring(0, 1);
    const next = tmpVal.substring(length);
    const blockType = block[0].toLowerCase();
    let toAdd = sub;
    switch (blockType) {
      case 'd':
        if (sub === '00') {
          toAdd = '01';
        } else if (parseInt(msb, 10) > 3) {
          toAdd = `0${msb}`;
        } else if (parseInt(sub, 10) > 31) {
          toAdd = '31';
        }
        break;
      case 'm':
        if (sub === '00') {
          toAdd = '01';
        } else if (parseInt(msb, 10) > 1) {
          toAdd = `0${msb}`;
        } else if (parseInt(sub, 10) > 12) {
          toAdd = '12';
        }
        break;
    }

    res += next ? `${toAdd}${delimiter}` : toAdd;

    // for next iteration
    tmpVal = next;
  });
  return res;
};

const stripDelimiters = function stripDelimiters(value, delimiters) {
  let res = value;
  delimiters.forEach(delimiter => {
    const reg = new RegExp(delimiter, 'g');
    res = res.replace(reg, '');
  });
  return res;
};
/*
 *  https://github.com/facebook/react/issues/955
*/
const updateCaretPosition = function updateCaretPosition(
  element,
  postValue,
  caretPosition,
  preValue,
  delimiters,
) {
  if (!element || !postValue || !preValue) {
    return;
  }

  // DO NOT REMOVE THIS setTimeout NEVER
  // THIS IS A FIX FOR ANDROID BROWSER ISSUES (HAPPEN ONLY ON ANDROID DEVICE!)
  setTimeout(() => {
    // finding the diff size between pre formatting value
    // and post formatting value to find the offset
    const oldRawValue = stripDelimiters(preValue.slice(0, caretPosition), delimiters);
    const newRawValue = stripDelimiters(postValue.slice(0, caretPosition), delimiters);
    const offset = oldRawValue.length - newRawValue.length;

    const index = preValue.length === caretPosition ? postValue.length : caretPosition + offset;
    if (index !== -1) {
      element.selectionStart = index;
      element.selectionEnd = index;
    }
  }, 0);
};

const fixOnChange = (formatFunc, delimiters) =>
  compose(
    pure,
    defaultProps({
      controlled: false
    }),
    withStateHandlers(
      props => ({
        ...(props.controlled ? {} : { value: props.initialValue || props.value || '' }),
        element: null,
        forceRender: 0,
      }),
      {
        onRef: () => element => ({ element }),
        onChange: (state, props) => event => {
          const caretPosition = event.target.selectionEnd;
          const preValue = event.target.value;
          const value = formatFunc(preValue, props.format, delimiters);
          props.onChange(value);
          return {
            ...(props.controlled ? {} : { value }),
            caretPosition,
            preValue,

            // @mrsufgi: HACK - should always rerender on change
            // withStateHandlers always compare previous state to block rendering,
            // so we need to override it.
            forceRender: state.forceRender + 1,
          };
        },
      },
    ),
    withPropsOnChange(
      ['element', 'value', 'forceRender', 'caretPosition', 'preValue'],
      ({ element, value, caretPosition, preValue }) => {
        updateCaretPosition(element, value, caretPosition, preValue, delimiters);
      },
    ),
  );

export default compose(fixOnChange(getFormattedDate, ['/']))(DateInput);
