/**
 * Created by meirshalev on 04/06/2017.
 */
import PromiseActionType from '../promiseActionType';
import { getMember } from '../member/actions';

export const RESEND_EMAIL_VERIFICATION =
  new PromiseActionType('RESEND_EMAIL_VERIFICATION');
export const RESEND_PHONE_NUMBER_VERIFICATION =
  new PromiseActionType('RESEND_PHONE_NUMBER_VERIFICATION');

export const CHANGE_PHONE_NUMBER = new PromiseActionType('CHANGE_PHONE_NUMBER');
export const CHANGE_EMAIL = new PromiseActionType('CHANGE_EMAIL');
export const UPDATE_PASSWORD = new PromiseActionType('UPDATE_PASSWORD');
export const VERIFY_EMAIL = new PromiseActionType('VERIFY_EMAIL');
export const VERIFY_PHONE_NUMBER = new PromiseActionType('VERIFY_PHONE_NUMBER');

export function resendEmailVerification(email) {
  return ({ bkmdApi: { usersApi } }) => ({
    type: RESEND_EMAIL_VERIFICATION.SOURCE,
    meta: {
      tracker: RESEND_EMAIL_VERIFICATION.SOURCE,
    },
    payload: {
      promise: usersApi.resendEmailVerification(email)
    }
  });
}

export function resendPhoneNumberVerification(phoneNumber) {
  return ({ bkmdApi: { usersApi } }) => ({
    type: RESEND_PHONE_NUMBER_VERIFICATION.SOURCE,
    meta: {
      tracker: RESEND_PHONE_NUMBER_VERIFICATION.SOURCE,
    },
    payload: {
      promise: usersApi.resendPhoneNumberVerification(phoneNumber)
    }
  });
}

export function changePhoneNumber(phoneNumber, password) {
  return ({ dispatch, bkmdApi: { usersApi } }) => ({
    type: CHANGE_PHONE_NUMBER.SOURCE,
    meta: {
      tracker: CHANGE_PHONE_NUMBER.SOURCE,
    },
    payload: {
      promise: usersApi.changePhoneNumber(phoneNumber, password)
        .then(() => dispatch(getMember())),
    }
  });
}

export function changeEmail(email, password) {
  return ({ bkmdApi: { usersApi } }) => ({
    type: CHANGE_EMAIL.SOURCE,
    meta: {
      tracker: CHANGE_EMAIL.SOURCE,
    },
    payload: {
      promise: usersApi.changeEmail(email, password)
    }
  });
}

export function updatePassword(currentPassword, newPassword) {
  return ({ bkmdApi: { usersApi } }) => ({
    type: UPDATE_PASSWORD.SOURCE,
    meta: {
      tracker: UPDATE_PASSWORD.SOURCE,
    },
    payload: {
      promise: usersApi.updatePassword(currentPassword, newPassword)
    }
  });
}

export function verifyEmail(emailToken) {
  return ({ bkmdApi: { usersApi } }) => ({
    type: VERIFY_EMAIL.SOURCE,
    meta: {
      tracker: VERIFY_EMAIL.SOURCE,
    },
    payload: {
      promise: usersApi.verifyEmail(emailToken)
    }
  });
}

export function verifyPhoneNumber(phoneNumberToken) {
  return ({ bkmdApi: { usersApi } }) => ({
    type: VERIFY_PHONE_NUMBER.SOURCE,
    meta: {
      tracker: VERIFY_PHONE_NUMBER.SOURCE,
    },
    payload: {
      promise: usersApi.verifyPhoneNumber(phoneNumberToken)
    }
  });
}
