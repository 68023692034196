/**
 * Created by matan on 3/12/17.
 */
import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { defineMessages, FormattedMessage } from 'react-intl';
import { IntlString, MessageShape } from '../intlString';

const messages = defineMessages({
  newWindow: {
    defaultMessage: 'Opens in new window',
    id: 'baseLink.a11y.newWindow',
  },
});

export default function BaseLink(props) {
  const { url, label, externalLink, ...other } = props;
  if (_.isNil(url) || _.isNil(label)) return null;

  return (
    <a {...other} href={url} rel="noreferrer noopener" target="_blank">
      <IntlString message={label} />
      {externalLink && (
        <div className="off-screen-text">
          <FormattedMessage {...messages.newWindow} />
        </div>
      )}
    </a>
  );
}

BaseLink.propTypes = {
  /**
   * Link url href
   */
  url: PropTypes.string.isRequired,
  /**
   * Link display text
   * Can be either a string or an intel message obj
   */
  label: MessageShape.isRequired,
  externalLink: PropTypes.bool,
};

BaseLink.defaultProps = {
  externalLink: false,
};
