import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { withProps } from 'recompose';

const geoForMaps = geo => {
  if (_.isArray(geo)) return `${geo.coordinates[1]},${geo.coordinates[0]}`;
  if (geo.lat && geo.lon) return `${geo.lat},${geo.lon}`;
  if (geo.latitude && geo.longitude) return `${geo.latitude},${geo.longitude}`;
  throw new Error(`[mapSnapshot.js] geo prop bad format: ${geo}`);
};

const MapSnapshotView = ({ mapSrc }) => (
  <img className="img-cover" src={mapSrc} alt="Bing map location" />
);

MapSnapshotView.propTypes = {
  mapSrc: PropTypes.string.isRequired,
};

const VIM_TEMPLATE_DATA_MARKER = /\*\|([a-zA-Z0-9_]+[a-zA-Z0-9])\|\*/;

const MapSnapshot = withProps(({ geo, width, height, mapConfig: { templateUrl, key } }) => {
  const point = geoForMaps(geo);
  return {
    mapSrc: _.template(templateUrl, {
      interpolate: VIM_TEMPLATE_DATA_MARKER,
    })({ point, key, width, height }),
  };
})(MapSnapshotView);

MapSnapshot.propTypes = {
  geo: PropTypes.object.isRequired,
  mapConfig: PropTypes.object.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
};

MapSnapshot.defaultProps = {
  width: 555,
  height: 160,
};

export default MapSnapshot;
