import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '../../storybook/src/utils/theme';

function SvgEnvelopeStars({ theme }) {
  /* eslint-disable */
  return (
    <svg aria-hidden="true" focusable="false" width="72px" height="35px" viewBox="0 0 72 35" version="1.1" className="svg-icon svg-envelope-stars">
      <defs>
        <polygon id="path-1" points="50.4729 0.376 0.0359 0.376 0.0359 34.9998 50.4729 34.9998 50.4729 0.376"></polygon>
      </defs>
      <g id="All-settings-screens" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Update-email" transform="translate(-152.000000, -158.000000)">
          <g id="Page-1" transform="translate(152.000000, 157.000000)">
            <g id="Group-3" transform="translate(15.000000, 0.624000)">
              <g id="Clip-2"></g>
              <path d="M25.2539,17.4248 L5.5849,3.3758 L44.9229,3.3758 L25.2539,17.4248 Z M48.9729,10.1878 C49.8009,10.1878 50.4729,9.5158 50.4729,8.6878 L50.4729,3.8208 C50.4729,1.9218 48.9269,0.3758 47.0259,0.3758 L3.4819,0.3758 C1.5819,0.3758 0.0359,1.9218 0.0359,3.8208 L0.0359,31.5538 C0.0359,33.4538 1.5819,34.9998 3.4819,34.9998 L47.0259,34.9998 C48.9269,34.9998 50.4729,33.4538 50.4729,31.5538 L50.4729,19.3908 C50.4729,18.5628 49.8009,17.8908 48.9729,17.8908 C48.1449,17.8908 47.4729,18.5628 47.4729,19.3908 L47.4729,31.5538 C47.4729,31.7958 47.2689,31.9998 47.0259,31.9998 L3.4819,31.9998 C3.2399,31.9998 3.0359,31.7958 3.0359,31.5538 L3.0359,5.2428 L24.3819,20.4888 C24.6429,20.6758 24.9479,20.7688 25.2539,20.7688 C25.5599,20.7688 25.8649,20.6758 26.1259,20.4888 L47.4729,5.2418 L47.4729,8.6878 C47.4729,9.5158 48.1449,10.1878 48.9729,10.1878 L48.9729,10.1878 Z" id="Fill-1" fill={theme.mainColor} ></path>
            </g>
            <path d="M22.8604,29.2988 C22.4764,29.2988 22.0924,29.1528 21.7994,28.8598 C21.2144,28.2738 21.2144,27.3238 21.7994,26.7378 L28.1254,20.4138 C28.7114,19.8278 29.6604,19.8278 30.2464,20.4138 C30.8324,20.9998 30.8324,21.9488 30.2464,22.5348 L23.9214,28.8598 C23.6284,29.1528 23.2444,29.2988 22.8604,29.2988" id="Fill-4" fill={theme.secondaryColor} ></path>
            <path d="M57.6475,29.2988 C57.2635,29.2988 56.8795,29.1528 56.5865,28.8598 L50.2615,22.5348 C49.6755,21.9488 49.6755,20.9998 50.2615,20.4138 C50.8475,19.8278 51.7965,19.8278 52.3825,20.4138 L58.7085,26.7378 C59.2935,27.3238 59.2935,28.2738 58.7085,28.8598 C58.4155,29.1528 58.0315,29.2988 57.6475,29.2988" id="Fill-6" fill={theme.secondaryColor} ></path>
            <path d="M67.6211,19.042 C66.8151,19.042 66.1621,18.388 66.1621,17.582 L66.1621,11.744 C66.1621,10.938 66.8151,10.284 67.6211,10.284 C68.4271,10.284 69.0811,10.938 69.0811,11.744 L69.0811,17.582 C69.0811,18.388 68.4271,19.042 67.6211,19.042" id="Fill-8" fill={theme.secondaryColor} ></path>
            <path d="M70.54,16.122 L64.702,16.122 C63.896,16.122 63.242,15.469 63.242,14.663 C63.242,13.857 63.896,13.203 64.702,13.203 L70.54,13.203 C71.347,13.203 72,13.857 72,14.663 C72,15.469 71.347,16.122 70.54,16.122" id="Fill-10" fill={theme.secondaryColor} ></path>
            <path d="M6.1104,24.7412 C4.9604,24.7412 4.0254,25.6772 4.0254,26.8262 C4.0254,27.9752 4.9604,28.9112 6.1104,28.9112 C7.2594,28.9112 8.1954,27.9752 8.1954,26.8262 C8.1954,25.6772 7.2594,24.7412 6.1104,24.7412 M6.1104,30.9962 C3.8104,30.9962 1.9394,29.1262 1.9394,26.8262 C1.9394,24.5262 3.8104,22.6562 6.1104,22.6562 C8.4104,22.6562 10.2804,24.5262 10.2804,26.8262 C10.2804,29.1262 8.4104,30.9962 6.1104,30.9962" id="Fill-12" fill={theme.secondaryColor} ></path>
            <path d="M2.7803,10.8398 C2.7803,11.6078 2.1583,12.2308 1.3893,12.2308 C0.6223,12.2308 0.0003,11.6078 0.0003,10.8398 C0.0003,10.0718 0.6223,9.4498 1.3893,9.4498 C2.1583,9.4498 2.7803,10.0718 2.7803,10.8398" id="Fill-14" fill={theme.mainColor} ></path>
          </g>
        </g>
      </g>
    </svg>
  );
  /* eslint-enable */
}

SvgEnvelopeStars.propTypes = {
  theme: PropTypes.object.isRequired,
};

export default withTheme(SvgEnvelopeStars);
