const appointmentsEvents = {
  cancel: {
    cancelAppointmentVisit: 'Cancel appointment visit',
    cancelAppointment: 'Appointment Canceled',
  },
  details: {
    appointmentDetailsVisit: 'Appointment details visit',
  },
  activation: {
    appointmentLandingVisit: 'Appointment lending page visit',
  },
  viewAppointmentsPage: `view appointments page`,
  rescheduleAppointments: `reschedule appointments`,
  cancelAppointment: `cancel appointment`,
};

export default appointmentsEvents;
