import React from 'react';
import CleanInput from './cleanInput';

function CleanColorInput(props) {
  return (
    <span>
      <CleanInput {...props} />
      <CleanInput {...props} type="color" />
    </span>
  );
}

export default CleanColorInput;
