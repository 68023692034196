import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import Highlight from 'react-highlighter';
import { FormattedHTMLMessage } from 'react-intl';
import { Menu, MenuItem } from 'react-bootstrap-typeahead';
import classnames from 'classnames';

import DoctorLogo from '../doctorImage/doctorLogo';
import SvgNoResults from '../../assets/svg/noResults';
import messages from '../../utils/placeholder/placeholderMessages';
import ThreeBounceSpinner from '../ui/spinner/threeBounceSpinner';

const DropMenu = Menu;
const DropMenuItem = MenuItem;

const noResultsArray = [{ name: 'empty' }];

export const OptionItem = ({ option, inputText }) => (
  <div className={option.className}>
    <DoctorLogo provider={option.data} containerClass="select-option-rounded-img" />

    <span className={classnames('option-name', { 'has-input-text': inputText })}>
      <Highlight search={inputText}>{option.name || option.label}</Highlight>
    </span>
    <div className="option-info">{option.info}</div>
  </div>
);

OptionItem.propTypes = {
  option: PropTypes.object.isRequired,
  inputText: PropTypes.string.isRequired,
};

const OptionsMenu = ({ options, inputText = '', overrideRenderItem: OverrideRenderItem }) => {
  const ItemComponent = OverrideRenderItem || OptionItem;
  return _.map(options, option => (
    <DropMenuItem option={option} key={option.key || option.name}>
      <ItemComponent option={option} inputText={inputText} />
    </DropMenuItem>
  ));
};

const NoResults = ({ term = '' }) => (
  <DropMenuItem option="NO_RESULTS" className="no-results-select-option">
    <SvgNoResults />
    <br />
    <FormattedHTMLMessage {...messages.noResults} values={{ term }} />
  </DropMenuItem>
);

NoResults.propTypes = {
  term: PropTypes.string,
};

NoResults.defaultProps = {
  term: '',
};

/**
 * Renders the main status of the referral,
 * @param options -
 *  @param {array}   suggestions - a static default options to select
 *  @param {boolean} isLoading - a flag represent if the search is loading
 *  @param {func}    overrideRenderItem - an override func item render
 *
 * @param {array} results - received from typeahead
 * @param {Object} menuProps - received from typeahead
 * @returns {*} DropMenu component
 */
const RenderMenu = options => (results, menuProps) => {
  const { isLoading, suggestions, overrideRenderItem } = options;
  if (isLoading === true) return <ThreeBounceSpinner />;

  let content = null;
  if (_.isEmpty(menuProps.text) && !_.isEmpty(suggestions)) {
    content = OptionsMenu({ options: suggestions, overrideRenderItem, inputText: menuProps.text });
  } else if (_.isEqual(results, noResultsArray)) content = <NoResults term={menuProps.text} />;
  else content = OptionsMenu({ options: results, inputText: menuProps.text, overrideRenderItem });

  return <DropMenu {...menuProps}>{content}</DropMenu>;
};

export default RenderMenu;
