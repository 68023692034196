import { pathsWithQuery } from '../../../../utils/pathsWithQuery';

export default {
  // redirect when appointment is yet to be cancel
  cancel: (appointmentId = ':appointmentId') => `/appointment/${appointmentId}/cancel`,
  // redirect when appointment is already canceled
  cancelled: (appointmentId = ':appointmentId') => `/appointment/${appointmentId}/cancelled`,
  // redirect when the user decided not to cancel the appointment
  declineCancellation: (appointmentId = ':appointmentId') =>
    `/appointment/${appointmentId}/declineCancellation`,
  // redirect when appointment details displayed
  appointmentDetails: (appointmentId = ':appointmentId', qsParams) =>
    pathsWithQuery(`/appointment/${appointmentId}`, qsParams),
  // landing page for pages: appointmentDetails
  landing: (appointmentId = ':appointmentId', qsParams) =>
    pathsWithQuery(`/appointment/${appointmentId}/landing`, qsParams),
};
