import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { compose } from 'redux';
import _ from 'lodash';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';

import { generateUUID } from '../../../../../../utils/uuid';
import SearchDoctorsTypeahed from '../../../../../../components/freeText/searchDoctorsTypeahead';
import buildPlaceholder from '../../../../../../utils/placeholder/buildPlaceholder';
import actionTracker, {
  actionTrackerShape,
} from '../../../../../../store/tools/actionTracker/actionTrackerComponent';
import {
  suggestionsSelector,
  autoCompleteProvider,
} from '../../../../../../store/directory/selectors';
import {
  searchFreeText,
  SEARCH_FREE_TEXT,
  suggestions,
} from '../../../../../../store/directory/autoCompleteProvider/actions';
import queryConnect from '../../../../../../store/tools/queryString/queryConnect';
import { openDoctorsResults, openDoctorDetails } from '../../routingActions';
import { getFeature } from '../../../../../../store/feature/selectors';
import * as filtersUtils from '../../../../../../components/searchDoctors/filter/filteringDefenitions';
import { TypeaheadClick } from '../../../../../../store/tools/analytics/events/typeaheadEvents';
import { PHYSICIAN_TYPE, TAXONOMY } from '../../../../../../components/freeText/resultType';
import fetchNotEmpty from '../../../../../../store/tools/fetchNotEmpty';
import { member } from '../../../../../../store/member/selectors';

@autobind
class DirectorySearchTypeahead extends React.Component {
  static propTypes = {
    setInQueryString: PropTypes.func.isRequired,
    onChange: PropTypes.func,
    freeTextSearchTracker: actionTrackerShape.isRequired,
    openDoctorDetails: PropTypes.func.isRequired,
    openDoctorsResults: PropTypes.func.isRequired,
    /* eslint-disable react/no-unused-prop-types */
    location: PropTypes.object.isRequired,
    /* eslint-enable react/no-unused-prop-types */
  };

  static contextTypes = {
    paths: PropTypes.object.isRequired,
    analytics: PropTypes.object.isRequired,
  };

  static defaultProps = {
    onChange: () => {},
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  onChange(option) {
    const { onChange } = this.props;
    switch (option.meta.type) {
      case PHYSICIAN_TYPE: {
        this.gotoDoctorDetails(option);
        break;
      }
      case TAXONOMY: {
        this.gotoResultsPage(option);
        break;
      }
      default:
        break;
    }

    onChange(option);
  }

  onSearchTermChanged(value) {
    this.props.setInQueryString({ searchTerm: value, queryId: generateUUID() });
  }

  gotoResultsPage(data) {
    const {
      data: { name, codes },
    } = data;
    const queryId = generateUUID();
    this.props.setInQueryString({ ...filtersUtils.getDefaultsObject(), queryId }); // new search, no filters
    const params = {
      codes: _.castArray(codes),
      searchTerm: name,
      queryId,
    };
    this.props.openDoctorsResults(this.context.paths, params);
    this.context.analytics.track(TypeaheadClick, { ...params });
  }

  gotoDoctorDetails({ data: { npi, locations, closestLocation } }) {
    const locationId = closestLocation ? closestLocation.id : null;
    this.props.openDoctorDetails(npi, locationId, this.context.paths);
    this.context.analytics.track(TypeaheadClick, {
      npi,
      locations,
    });
  }

  render() {
    return (
      <SearchDoctorsTypeahed
        {...this.props}
        onChange={this.onChange}
        showSpinner={this.props.freeTextSearchTracker.inProgress}
        onSearchTermChanged={this.onSearchTermChanged}
      />
    );
  }
}

export default compose(
  injectIntl,
  queryConnect(['searchTerm']),
  connect(
    (state, props) => ({
      autoComplete: autoCompleteProvider(state),
      suggestions: suggestionsSelector(state),
      placeholder: buildPlaceholder(getFeature(state, 'search.types'), props.intl),
      configuredSuggestions: state.config.suggestions,
      member: member(state),
    }),
    {
      searchFreeText,
      openDoctorsResults,
      openDoctorDetails,
    },
  ),
  fetchNotEmpty(
    ({ props: { configuredSuggestions } }) => suggestions(configuredSuggestions),
    suggestionsSelector,
    { renderAlways: true, loading: false },
  ),
  actionTracker({
    freeTextSearchTracker: SEARCH_FREE_TEXT.SOURCE,
  }),
)(DirectorySearchTypeahead);
