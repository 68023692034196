import { log } from '../../../utils/logger';

export default {
  onStart: (props, args, name) => {
    log('FETCH_START', name, args);
  },
  onSuccess: (props, response, name) => {
    log('FETCH_SUCCESS', name, response);
  },
  onError: (props, error, name) => {
    log('FETCH_ERROR', name, error);
  },
};
