import PropTypes from 'prop-types';
import { defineMessages } from 'react-intl';
import {
  setPropTypes,
  defaultProps,
  compose,
  setDisplayName,
} from 'recompose';
import { FlowShape } from '../../components/wizard/flow/shapes';
import SelectInsurer from './selectInsurer';
import { withTranslatedMessages } from '../../utils/withTranslatedMessages';

const messages = defineMessages({
  title: {
    defaultMessage: 'What Insurance do you have?',
    id: 'patient.selectInsurer.title',
  },
  primaryButtonText: {
    defaultMessage: 'Next',
    id: 'patient.selectInsurer.primaryButtonText',
  },
  secondaryButtonText: {
    defaultMessage: 'I can\'t find my insurance provider',
    id: 'patient.selectInsurer.secondaryButtonText',
  },
  label: {
    defaultMessage: 'Your Insurance',
    id: 'patient.selectInsurer.label',
  },
  placeholder: {
    defaultMessage: 'Search insurance providers',
    id: 'patient.selectInsurer.placeholder',
  },
});

const NonPatientSelectInsurer = compose(
  setPropTypes({
    control: FlowShape.isRequired,
    options: PropTypes.array,
  }),
  defaultProps({
    options: [],
  }),
  setDisplayName('NonPatientSelectInsurer'),
  withTranslatedMessages(messages),
)(SelectInsurer);

export default NonPatientSelectInsurer;
