import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { defineMessages, FormattedMessage } from 'react-intl';

import SvgMug from '../../../../assets/svg/mug';
import ContactInfo from './helpContactInfo';
import { getFeature } from '../../../../store/feature/selectors';

import './help.less';

const messages = defineMessages({
  title: {
    defaultMessage: 'Help',
    id: 'activation.helpModal.title',
  },
  subtitle1: {
    defaultMessage: 'Contact {customerSupportName} customer support',
    id: 'activation.helpModal.subtitle1',
  },
  mainText1: {
    defaultMessage:
      "We're always happy to hear from you. " +
      "Please keep in mind, Vim doesn't handle medical advice " +
      'or answer questions about your health plan. ' +
      'We can help with your account ' +
      'and answer any questions you may have about our services and our company.',
    id: 'activation.helpModal.mainText1',
  },
  forQuestions: {
    defaultMessage: 'For questions about your Vim account call:',
    id: 'activation.helpModal.forQuestions',
  },
  openATicket: {
    defaultMessage: 'Open a ticket',
    id: 'activation.helpModal.openATicket',
  },
});

function renderSection(title, text, customerSupportName) {
  return (
    <div>
      <div className="help-top">
        <div className="row margin-top-20">
          <div className="col-xs-12 text-center">
            <h1 className="text-18 text-dark title-brand-3">
              <FormattedMessage {...title} values={{ customerSupportName }} />
            </h1>
          </div>
        </div>
        <div className="row margin-top-20">
          <div className="col-xs-12 text-16">
            <FormattedMessage {...text} />
          </div>
        </div>
      </div>
      <ContactInfo />
    </div>
  );
}

const HelpContent = function HelpContent({ customerSupportName }) {
  return (
    <div className="help-content">
      <div className="text-center">
        <SvgMug />
      </div>
      {renderSection(messages.subtitle1, messages.mainText1, customerSupportName)}
    </div>
  );
};

HelpContent.propTypes = {
  customerSupportName: PropTypes.string.isRequired,
};

export default connect(state => ({
  customerSupportName: getFeature(state, 'help.customerSupportName', 'Vim'),
}))(HelpContent);
