/**
 * Created by chenrozenes on 05/07/2017.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  shareDrInfo: {
    defaultMessage: "Share {providerDisplayName}'s info",
    id: 'members.shareModal.shareDrInfo',
  },
  send: {
    defaultMessage: 'Send',
    id: 'members.shareModal.send',
  },
  shareThisDoctor: {
    defaultMessage: 'Share this doctor',
    id: 'members.shareModal.shareThisDoctor',
  },
  shareFailedTitle: {
    defaultMessage: 'Share action failed',
    id: 'members.shareModal.shareFailedTitle',
  },
  shareFailedUserBlocked: {
    defaultMessage: "You've reached your share limit for today. Please try again later",
    id: 'members.shareModal.shareFailedUserBlocked',
  },
  shareFailedAddressBlocked: {
    defaultMessage:
      "The address entered has reached it's share limit for today. Please try again later",
    id: 'members.shareModal.shareFailedAddressBlocked',
  },
  shareFailedGeneralMessage: {
    defaultMessage: 'Please refresh and try again',
    id: 'members.shareModal.shareFailedGeneralMessage',
  },
  success: {
    defaultMessage: 'Success!',
    id: 'members.shareModal.success',
  },
});
