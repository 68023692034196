import React from 'react';
import PropTypes from 'prop-types';
import { compose, withHandlers } from 'recompose';
import { defineMessages, FormattedMessage } from 'react-intl';
import ChooseAsMyPCP from './chooseAsMyPCP';
import PcpDetailsModal from './pcpDetailsModal';
import { createToggleState } from '../../recompose/toggleStateComponent';
import './pcpSelectionAction.less';

const messages = defineMessages({
  showDoctorDetails: {
    defaultMessage: 'Details',
    id: 'members.selectPcp.showDoctorDetails',
  },
});

function PCPSelectionActions({
  onUpdatePcpSelection,
  showUpdate,
  showDetails,
  provider,
  selectedLocation,
  plan,
  memberContext,
  detailsModalOn,
  detailsModalHide,
  detailsModalShow,
  onLocationChanged,
  clientLocation
}) {
  return (
    <div className="pcp-action-buttons">
      {showUpdate && <ChooseAsMyPCP onClick={onUpdatePcpSelection} />}
      {showDetails && (
        <div>
          <button className="btn btn-big btn-outline pcp-details-btn" onClick={detailsModalShow}>
            <FormattedMessage {...messages.showDoctorDetails} />
          </button>
          <PcpDetailsModal
            shouldFetchProviderDetails={false}
            isOpen={detailsModalOn}
            handleClose={detailsModalHide}
            memberPlan={plan}
            memberContext={memberContext}
            details={provider}
            updatePcpSelection={onUpdatePcpSelection}
            selectedLocation={selectedLocation}
            onLocationChanged={onLocationChanged}
            showUpdate={showUpdate}
            clientLocation={clientLocation}
          />
        </div>
      )}
    </div>
  );
}

PCPSelectionActions.propTypes = {
  detailsModalHide: PropTypes.func.isRequired,
  detailsModalOn: PropTypes.bool.isRequired,
  detailsModalShow: PropTypes.func.isRequired,
  onUpdatePcpSelection: PropTypes.func,
  plan: PropTypes.string.isRequired,
  memberContext: PropTypes.string.isRequired,
  provider: PropTypes.object.isRequired,
  selectedLocation: PropTypes.object.isRequired,
  showUpdate: PropTypes.bool,
  showDetails: PropTypes.bool,
  onLocationChanged: PropTypes.func,
  clientLocation: PropTypes.object,
};

PCPSelectionActions.defaultProps = {
  onUpdatePcpSelection: null,
  onLocationChanged: undefined,
  showUpdate: true,
  showDetails: true,
};

export default compose(
  createToggleState('detailsModal'),
  withHandlers({
    onUpdatePcpSelection: ({ onUpdatePcpSelection, provider, selectedLocation }) => () =>
      onUpdatePcpSelection(provider, selectedLocation),
  }),
)(PCPSelectionActions);
