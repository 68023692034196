import fetchNotEmpty from '../tools/fetchNotEmpty';
import { hasFamilyMembers } from './actions';

export const getHasDependents = state => state.familyActivation.hasDependents;
export const getGuardianDependents = state => state.familyActivation.guardianDependents;
export const getDependents = state => state.familyActivation.dependents;
export const openFamilyActivation = state => state.familyActivation.openFamilyActivation;
export const getStep = (state, defaultStep) => state.familyActivation.step || defaultStep;
export const fetchHasFamilyMembersNotEmpty = () => fetchNotEmpty(
  hasFamilyMembers,
  getHasDependents,
);
