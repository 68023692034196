/**
 * Created by asafdavid on 08/01/2018.
 */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ClickableDiv from '../../clickableElement/clickableDiv';

const Noop = () => null;

class NextButton extends PureComponent {
  static propTypes = {
    style: PropTypes.object,
    className: PropTypes.string,
    hasNext: PropTypes.bool,
    onClick: PropTypes.func,
  };

  static defaultProps = {
    style: {},
    className: '',
    hasNext: false,
    onClick: Noop,
  };

  render() {
    const { onClick, hasNext, className, style } = this.props;
    if (!hasNext) return null;

    return (
      <ul className="pagination bkmd-pagination next">
        <li>
          <ClickableDiv clickHandler={onClick} style={style} className={classNames(className, 'pagination-arrow-vim')}>
            <i className="icon-chevron-right item-arrow" />
          </ClickableDiv>
        </li>
      </ul>
    );
  }
}

export default NextButton;
