import { autobind } from 'core-decorators';
import BkmdApi from './bkmdApi';
import ServicesApi from './services';

const SERVICE_NAME = 'asoGroup';

@autobind
class AsoGroupApi extends BkmdApi {
  constructor(fetch) {
    super(fetch, '/asoGroup/');
    this.servicesApi = new ServicesApi(fetch);
  }

  getAllAsoGroups(skip, limit, search, sort, sortAscending) {
    return this.servicesApi
      .callSecure(SERVICE_NAME, 'getAllAsoGroups', {
        skip,
        limit,
        search,
        sort,
        sortAscending,
      })
      .then(res => res.data);
  }

  createAsoGroup(data) {
    return this.servicesApi.callSecure(SERVICE_NAME, 'createAsoGroup', { ...data });
  }

  updateAsoGroup(data) {
    return this.servicesApi.callSecure(SERVICE_NAME, 'updateAsoGroup', { ...data });
  }

  getById(id) {
    return this.servicesApi.callSecure(SERVICE_NAME, 'getById', { id }).then(res => res.data);
  }

  deleteAsoGroup(id) {
    return this.servicesApi.callSecure(SERVICE_NAME, 'deleteAsoGroup', { id });
  }

  createTier(data) {
    return this.servicesApi.callSecure(SERVICE_NAME, 'createTier', { ...data });
  }

  updateTier(data) {
    return this.servicesApi.callSecure(SERVICE_NAME, 'updateTier', { ...data });
  }

  deleteTier(tierId) {
    return this.servicesApi.callSecure(SERVICE_NAME, 'deleteTier', { tierId });
  }
}

export default AsoGroupApi;
