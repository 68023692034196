import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { autobind } from 'core-decorators';
import { destroy, formValueSelector } from 'redux-form';
import _ from 'lodash';

import {
  createToggleState,
  createToggleStatePropTypes,
} from '../../../../components/recompose/toggleStateComponent';
import ActivationLayout from './components/activationLayout';
import Signup from './components/signup';
import MobileNumber from './components/mobileNumber';
import SeparatedName from './components/separatedName';
import Name from './components/name';
import DateOfBirth from './components/dateOfBirth';
import MemberNotFound from './components/memberNotFound';
import MemberId from './components/memberId';
import * as ActivationForm from './activationForm';
import injectNotification from '../../../../store/notification/injectNotification';
import {
  activateWithMemberId,
  memberSignup,
  setActivationStep,
  SIGNUP,
  validate,
  VALIDATE,
  VALIDATE_PHONE_NUMBER,
  ACTIVATE_WITH_MEMBER_ID,
  validatePhoneNumber,
} from '../../../../store/activation/actions';
import * as Errors from '../../../../api/errors';
import { UPDATE_MEMBER } from '../../../../store/member/actions';
import { resetRecaptch } from '../../../../store/recaptcha/actions';
import actionTracker from '../../../../store/tools/actionTracker/actionTrackerComponent';
import Formatter from '../../../../utils/formatter';
import { getFeature } from '../../../../store/feature/selectors';
import errorMapping from './activationErrorMapping';
import { ActivationCompleted } from '../../../../store/tools/analytics/events/memberEvents';
import {
  COULD_NOT_FIND,
  ENTER_DOB,
  ENTER_MEMBER_ID,
  ENTER_NAME,
  ENTER_PHONE_NUMBER,
  SIGN,
} from './steps';
import queryConnect from '../../../../store/tools/queryString/queryConnect';
import { goToNext } from '../../../../utils/reRouting';
import { validationToken, sourceSelector } from '../../../../store/activation/selectors';

const NAME_RETRIES = 3;

const parseName = function parseName(name) {
  const trimmedName = name.replace(/\s+/g, ' ').trim();
  return Formatter.fullName(trimmedName);
};

@autobind
export class ActivationFlow extends React.Component {
  static propTypes = {
    /**
     * Router object.
     * Provided by the withRouter HoC
     */
    router: PropTypes.object.isRequired,
    /**
     * react router params
     */
    params: PropTypes.object.isRequired,
    /**
     * Initial step in the flow.
     */
    initialStep: PropTypes.string,
    /**
     * Server request action tracking.
     */
    validatePhoneNumberTracking: PropTypes.object.isRequired,
    /**
     * Server request action tracking.
     * For creating a new user.
     */
    signupTracking: PropTypes.object.isRequired,
    /**
     * Server request action tracking.
     */
    validate: PropTypes.object.isRequired,
    activateWithMemberId: PropTypes.func.isRequired,
    activateMemberIdTracking: PropTypes.object.isRequired,
    /**
     * Action tracker object for the validate action.
     */
    validateNameTracking: PropTypes.object.isRequired,
    /**
     * Action tracker object for the validate action.
     */
    validateDoBTracking: PropTypes.object.isRequired,
    /**
     * Redux action creator
     */
    validatePhoneNumber: PropTypes.func.isRequired,
    /**
     * Redux function to create a new user
     */
    memberSignup: PropTypes.func.isRequired,
    /**
     * Injected prop for notifying on errors
     */
    notification: PropTypes.object.isRequired,
    /**
     * Action function to reset system recaptch component.
     * Provided by the connect HoC
     */
    resetRecaptch: PropTypes.func.isRequired,
    /**
     * redux-form action.
     */
    destroy: PropTypes.func.isRequired,
    /**
     * source from url param
     */
    source: PropTypes.string.isRequired,
    /**
     * The jwt token generated for the activation using the validate action.
     */
    activationToken: PropTypes.string,
    /**
     * Feature flag for whether or not to check if the user is from a state that is active.
     */
    matchNotUniqueWithMemberId: PropTypes.bool.isRequired,
    step: PropTypes.any.isRequired,
    setStep: PropTypes.func.isRequired,
    ...createToggleStatePropTypes('alreadyActivatedModal'),
  };

  static contextTypes = {
    analytics: PropTypes.object.isRequired,
  };

  static defaultProps = {
    activationToken: undefined,
    initialStep: ENTER_PHONE_NUMBER,
  };

  componentWillMount() {
    this.couldNotFindMemberCount = 0;
    this.couldNotValidateDoBCount = 0;
  }

  componentWillReceiveProps(nextProps) {
    const {
      step,
      setStep,
      signupTracking,
      validatePhoneNumberTracking,
      validateNameTracking,
      validateDoBTracking,
      activateMemberIdTracking,
      disableSignup,
    } = nextProps;

    if (validatePhoneNumberTracking.finished) {
      this.handleResponse(validatePhoneNumberTracking, () => setStep(ENTER_NAME));
    }

    if (signupTracking.finished) {
      this.handleResponse(
        signupTracking,
        () => {
          this.context.analytics.track(ActivationCompleted);
          this.redirectToLandingPage();
        },
        {},
        step,
      );
    }

    // The user is in the right (main) flow, check if an action has been completed
    if (validateNameTracking.finished) {
      this.handleResponse(validateNameTracking, () => setStep(ENTER_DOB), null, ENTER_NAME, [
        Errors.NOT_UNIQUE,
      ]);
    }

    const afterActivationSuccess = () => {
      if (disableSignup) {
        this.redirectToLandingPage();
      } else {
        setStep(SIGN);
      }
    };

    if (validateDoBTracking.finished) {
      this.handleResponse(validateDoBTracking, afterActivationSuccess, null, ENTER_DOB);
    }

    if (activateMemberIdTracking.finished) {
      this.handleResponse(activateMemberIdTracking, afterActivationSuccess, null, ENTER_MEMBER_ID);
    }
  }

  handleError(error, step) {
    const { responseMessage } = error;

    switch (responseMessage) {
      case Errors.WEAK_PASSWORD:
      case Errors.INVALID_CODE:
      case Errors.INVALID_PHONE_NUMBER:
      case Errors.TOO_YOUNG:
      case Errors.ACTIVATION_TOKEN_EXPIRED: {
        this.notifyError(responseMessage);
        break;
      }
      case Errors.VALIDATION_SESSION_EXPIRED: {
        this.notifyError(responseMessage);
        this.props.setStep(ENTER_PHONE_NUMBER);
        break;
      }
      case Errors.USER_ALREADY_EXIST: {
        this.props.destroy(ActivationForm.name); // clean the from
        this.props.alreadyActivatedModalShow();
        this.props.setStep(ENTER_PHONE_NUMBER); // reset the step state
        break;
      }
      case Errors.MEMBER_NOT_FOUND: {
        this.handleNotFoundError(step);
        break;
      }
      case Errors.NOT_UNIQUE: {
        this.props.setStep(
          this.props.matchNotUniqueWithMemberId ? ENTER_MEMBER_ID : COULD_NOT_FIND,
        );
        break;
      }
      case Errors.RECAPTCHA_FAILED:
        break; // Handled by recaptchaMiddleware
      default: {
        this.notifyError();
      }
    }

    // In case of an error, reset the Recaptch in the last page
    this.props.resetRecaptch();
  }

  notifyError(responseMessage) {
    const { activationMinimalAge } = this.props;
    const { title, message } = responseMessage
      ? errorMapping[responseMessage]
      : errorMapping.defaultMessage;
    this.props.notification.error(title, message, {
      autoDismiss: 10,
      titleParams: { minimalAge: activationMinimalAge },
    });
  }

  handleNotFoundError(step) {
    let counter;
    let message;
    if (step === ENTER_NAME) {
      counter = ++this.couldNotFindMemberCount;
      message = errorMapping.nameNotFound;
    } else {
      counter = ++this.couldNotValidateDoBCount;
      message = errorMapping.dobDoesNotMatch;
    }
    if (counter >= NAME_RETRIES) {
      this.props.setStep(COULD_NOT_FIND);
    } else {
      this.props.notification.error(message.title, message.message, {
        autoDismiss: 10,
      });
    }
  }

  handleResponse(tracking, onSuccess, nextProps, step, ignoredErrors) {
    if (tracking.hasError && !_.includes(ignoredErrors, tracking.error.responseMessage)) {
      this.handleError(tracking.error, step);
    } else {
      onSuccess(nextProps);
    }
  }

  validatePhoneNumber({ phoneNumber }) {
    this.props.validatePhoneNumber(phoneNumber, this.props.source);
  }

  validate(step, shouldActivate = true) {
    const _validate = ({ phoneNumber, name, firstName, lastName, dob }) => {
      const nameObj = this.props.useSeparatedNameFields
        ? { firstName, middleName: '', lastName }
        : parseName(name);

      return this.props.validate(
        phoneNumber,
        nameObj.firstName,
        nameObj.middleName,
        nameObj.lastName,
        dob,
        step,
        this.props.source,
        this.props.validationToken,
        shouldActivate,
      );
    };

    return _validate;
  }

  activateWithMemberId({ phoneNumber, name, dob, memberId, firstName, lastName }) {
    const fullName = this.props.useSeparatedNameFields ? `${firstName} ${lastName}` : name;
    return this.props.activateWithMemberId({
      fullName,
      memberId,
      dateOfBirth: dob,
      source: this.props.source,
      phoneNumber,
    });
  }

  signup({ email, password }) {
    this.props.memberSignup(this.props.activationToken, email, password, this.props.source);
  }

  redirectToLandingPage() {
    const {
      destroy,
      setStep,
      router,
      location: { query },
    } = this.props;
    // clean the form in the state
    destroy(ActivationForm.name);
    // Clear current step in store
    setStep(null);
    goToNext(router, query, '/secure', 'replace');
  }

  render() {
    const {
      signupTracking,
      validateNameTracking,
      validateDoBTracking,
      validatePhoneNumberTracking,
      step,
    } = this.props;

    switch (step) {
      case ENTER_PHONE_NUMBER:
        return (
          <ActivationLayout
            onSigninClick={this.redirectToLandingPage}
            alreadyActivatedModalOn={this.props.alreadyActivatedModalOn}
            alreadyActivatedModalHide={this.props.alreadyActivatedModalHide}
          >
            <MobileNumber
              onSubmit={this.validatePhoneNumber}
              loading={validatePhoneNumberTracking.inProgress}
            />
          </ActivationLayout>
        );
      case ENTER_NAME:
        return (
          <ActivationLayout
            goBack={() => this.props.setStep(ENTER_PHONE_NUMBER)}
            onSigninClick={this.redirectToLandingPage}
            alreadyActivatedModalOn={this.props.alreadyActivatedModalOn}
            alreadyActivatedModalHide={this.props.alreadyActivatedModalHide}
          >
            {this.props.useSeparatedNameFields ? (
              <SeparatedName
                onSubmit={this.validate(ENTER_NAME, false)}
                loading={validateNameTracking.inProgress}
                formName={ActivationForm.name}
              />
            ) : (
              <Name
                onSubmit={this.validate(ENTER_NAME, false)}
                loading={validateNameTracking.inProgress}
                formName={ActivationForm.name}
              />
            )}
          </ActivationLayout>
        );
      case ENTER_DOB:
        return (
          <ActivationLayout
            goBack={() => this.props.setStep(ENTER_NAME)}
            onSigninClick={this.redirectToLandingPage}
            alreadyActivatedModalOn={this.props.alreadyActivatedModalOn}
            alreadyActivatedModalHide={this.props.alreadyActivatedModalHide}
          >
            <DateOfBirth
              onSubmit={this.validate(ENTER_DOB)}
              loading={validateDoBTracking.inProgress}
              formName={ActivationForm.name}
            />
          </ActivationLayout>
        );
      case ENTER_MEMBER_ID:
        return (
          <ActivationLayout
            goBack={() => this.props.setStep(ENTER_DOB)}
            onSigninClick={this.redirectToLandingPage}
            alreadyActivatedModalOn={this.props.alreadyActivatedModalOn}
            alreadyActivatedModalHide={this.props.alreadyActivatedModalHide}
          >
            <MemberId
              onSubmit={this.activateWithMemberId}
              loading={this.props.activateMemberIdTracking.inProgress}
              formName={ActivationForm.name}
            />
          </ActivationLayout>
        );
      case COULD_NOT_FIND:
        return (
          <ActivationLayout
            goBack={this.redirectToLandingPage}
            onSigninClick={this.redirectToLandingPage}
            alreadyActivatedModalOn={this.props.alreadyActivatedModalOn}
            alreadyActivatedModalHide={this.props.alreadyActivatedModalHide}
          >
            <MemberNotFound />
          </ActivationLayout>
        );
      case SIGN:
        return (
          <ActivationLayout
            goBack={() => this.props.setStep(ENTER_NAME)}
            onSigninClick={this.redirectToLandingPage}
            alreadyActivatedModalOn={this.props.alreadyActivatedModalOn}
            alreadyActivatedModalHide={this.props.alreadyActivatedModalHide}
          >
            <Signup onSubmit={this.signup} loading={signupTracking.inProgress} />
          </ActivationLayout>
        );
      default:
        return null;
    }
  }
}

const fieldSelector = formValueSelector(ActivationForm.name);

export const flowComposition = [
  createToggleState('alreadyActivatedModal'),
  connect(
    (state, ownProps) => ({
      step: state.activation.step || ENTER_PHONE_NUMBER,
      phoneNumber: fieldSelector(state, ActivationForm.fields.phoneNumber.name),
      isInActiveState: _.get(state.activation, 'isInActiveState'),
      source: sourceSelector(state, ownProps),
      activationToken: _.get(state.activation, 'activationToken'),
      matchNotUniqueWithMemberId: getFeature(state, 'activation.matchNotUniqueWithMemberId', false),
      useSeparatedNameFields: getFeature(state, 'activation.useSeparatedNameFields', false),
      validationToken: validationToken(state),
      activationMinimalAge: getFeature(state, 'minimalAge', 18),
    }),
    {
      setStep: setActivationStep,
      validate,
      activateWithMemberId,
      memberSignup,
      validatePhoneNumber,
      resetRecaptch,
      destroy,
    },
  ),
  injectNotification,
  withRouter,
  queryConnect(query => ({
    next: _.get(query, 'next', '/secure?first=true'),
    disableSignup: query.disableSignup,
  })),
  actionTracker({
    validateNameTracking: `${ENTER_NAME}.${VALIDATE.SOURCE}`,
    validateDoBTracking: `${ENTER_DOB}.${VALIDATE.SOURCE}`,
    activateMemberIdTracking: ACTIVATE_WITH_MEMBER_ID.SOURCE,
    updateMemberTracking: UPDATE_MEMBER.SOURCE,
    signupTracking: SIGNUP.SOURCE,
    validatePhoneNumberTracking: VALIDATE_PHONE_NUMBER.SOURCE,
  }),
];

export default compose(...flowComposition)(ActivationFlow);
