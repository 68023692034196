import PublicPCPSelection from '../publicPCPSelection/publicPCPSelection';
import PCPSelectionActivation from '../publicPCPSelection/pcpSelectionActivation';
import requireInsuranceId from '../addInsuarnceInfo/requireInsuranceIdComponent';

export default [
  {
    path: 'publicPCPSelection/:token',
    name: 'publicPCPSelectionToken',
    component: requireInsuranceId(PublicPCPSelection),
  },
  {
    path: 'publicPCPSelection',
    name: 'publicPCPSelection',
    component: requireInsuranceId(PCPSelectionActivation),
  },
];
