/**
 * Created by matan on 3/15/17.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, FormattedMessage } from 'react-intl';

import BkmdModal from '../../../../components/bkmdModal/bkmdModal';
import ModalHeader from '../../../../components/bkmdModal/modalHeader';
import HelpContent from './helpContent';

import './help.less';

const messages = defineMessages({
  title: {
    defaultMessage: 'Help',
    id: 'activation.helpModal.title',
  },
});

class NeedHelpModal extends React.PureComponent {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
  };

  render() {
    const { isOpen, handleClose } = this.props;
    return (
      <BkmdModal isOpen={isOpen} handleClose={handleClose} className="need-help-modal fixed-header">
        <ModalHeader onClose={handleClose}>
          <div className="font-color-brand-main text-center text-semibold margin-top-20 text-18">
            <FormattedMessage {...messages.title} />
          </div>
        </ModalHeader>
        <div className="dialog-body">
          <HelpContent />
        </div>
      </BkmdModal>
    );
  }
}

export default NeedHelpModal;
