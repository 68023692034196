/**
 * Created by meirshalev on 04/06/2017.
 */
import Joi from 'joi-browser';
import { ReduxFormCleanInput } from '../../../../../../components/form/reduxForm/components';
import { EmailSchema, PasswordSchema } from '../../../../../../utils/commonSchema';

export const name = 'changeEmail';

export const fields = {
  email: {
    name: 'email',
    component: ReduxFormCleanInput,
  },
  // hidden field that is only used in the custom validator
  existingEmail: {
    name: 'existingEmail',
    component: 'input',
  },
  password: {
    name: 'password',
    type: 'password',
    component: ReduxFormCleanInput,
  },
};

export const schema = {
  email: EmailSchema.required(),
  password: PasswordSchema,
  existingEmail: Joi.string(),
};

export const customValidator = {
  [fields.password.name]: (value, values) => {
    const email = values[fields.email.name];
    const existingEmail = values[fields.existingEmail.name];
    const password = value;
    if (email !== existingEmail && !password) {
      return 'For your security, please re-enter your password';
    }
    return undefined;
  },
};
