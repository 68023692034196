import Immutable from 'seamless-immutable';

import {
  PREMERA_BLUE_CROSS_ALASKA,
  UNITED_HEALTH_CARE_ALLSAVERS,
  ANTHEM,
  FLORIDA_BLUE,
} from '../../model/enum/bkmdDomain';
import { LOAD_DOMAIN } from './actions';

const prefixToDomain = {
  premera: PREMERA_BLUE_CROSS_ALASKA,
  allsavers: UNITED_HEALTH_CARE_ALLSAVERS,
  anthem: ANTHEM,
  sanitas: FLORIDA_BLUE,
  florida: FLORIDA_BLUE,
};

export default function configReducer(state = {}, action) {
  state = Immutable(state);
  switch (action.type) {
    case LOAD_DOMAIN:
      return state.set('domain', {
        domain: prefixToDomain[action.payload.domain],
        shortName: action.payload.domain,
      });
    default:
      return state;
  }
}
