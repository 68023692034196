import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '../../storybook/src/utils/theme';

function SvgKey({ theme }) {
  /* eslint-disable */
  return (
    <svg aria-hidden="true" focusable="false" width="71px" height="57px" viewBox="0 0 71 57" version="1.1" className="svg-icon svg-key">
      <defs>
        <polygon id="path-1" points="0 41.937 0 0.934437038 70.991 0.934437038 70.991 41.937"></polygon>
      </defs>
      <g id="From-Fidelity" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Create-account" transform="translate(-152.000000, -140.000000)">
          <g id="Page-1" transform="translate(152.000000, 140.000000)">
            <g id="Group-3" transform="translate(0.000000, 8.000000)">
              <g id="Clip-2"></g>
              <path d="M29.697,24.041 L13.035,37.571 C11.733,38.629 10.123,39.097 8.495,38.888 C6.833,38.675 5.349,37.785 4.317,36.383 C2.172,33.465 2.678,29.263 5.447,27.014 L10.883,22.6 C11.233,22.315 11.437,21.888 11.437,21.436 C11.437,20.983 11.233,20.556 10.883,20.271 L5.447,15.857 C2.678,13.608 2.172,9.405 4.317,6.487 C5.348,5.085 6.833,4.196 8.495,3.983 C8.748,3.951 9,3.935 9.251,3.935 C10.614,3.935 11.935,4.407 13.035,5.3 L29.736,18.861 C30.522,19.457 30.991,20.42 30.991,21.436 C30.991,22.451 30.522,23.414 29.697,24.041 M69.491,20 L33.825,20 C33.498,18.604 32.705,17.349 31.588,16.502 L14.926,2.972 C12.98,1.392 10.561,0.694 8.114,1.008 C5.63,1.325 3.424,2.641 1.901,4.71 C-1.179,8.897 -0.437,14.943 3.556,18.186 L7.557,21.436 L3.556,24.686 C-0.437,27.928 -1.18,33.973 1.901,38.16 C3.424,40.23 5.63,41.545 8.114,41.863 C8.497,41.912 8.88,41.937 9.26,41.937 C11.307,41.937 13.285,41.232 14.926,39.899 L31.549,26.4 C32.66,25.558 33.439,24.346 33.786,23 L57.991,23 L57.991,30.5 C57.991,31.328 58.663,32 59.491,32 C60.319,32 60.991,31.328 60.991,30.5 L60.991,23 L67.991,23 L67.991,32.5 C67.991,33.328 68.663,34 69.491,34 C70.319,34 70.991,33.328 70.991,32.5 L70.991,21.5 C70.991,20.672 70.319,20 69.491,20" id="Fill-1" fill={theme.mainColor}></path>
            </g>
            <path d="M36.4909,3 L34.9909,3 L34.9909,1.5 C34.9909,0.672 34.3189,0 33.4909,0 C32.6629,0 31.9909,0.672 31.9909,1.5 L31.9909,3 L30.4909,3 C29.6629,3 28.9909,3.672 28.9909,4.5 C28.9909,5.328 29.6629,6 30.4909,6 L31.9909,6 L31.9909,7.5 C31.9909,8.328 32.6629,9 33.4909,9 C34.3189,9 34.9909,8.328 34.9909,7.5 L34.9909,6 L36.4909,6 C37.3189,6 37.9909,5.328 37.9909,4.5 C37.9909,3.672 37.3189,3 36.4909,3" id="Fill-4" fill={theme.secondaryColor}></path>
            <path d="M44.9909,48 C43.3369,48 41.9909,49.346 41.9909,51 C41.9909,52.654 43.3369,54 44.9909,54 C46.6449,54 47.9909,52.654 47.9909,51 C47.9909,49.346 46.6449,48 44.9909,48 M44.9909,57 C41.6819,57 38.9909,54.309 38.9909,51 C38.9909,47.691 41.6819,45 44.9909,45 C48.2999,45 50.9909,47.691 50.9909,51 C50.9909,54.309 48.2999,57 44.9909,57" id="Fill-6" fill={theme.secondaryColor}></path>
            <path d="M59.4909,12.5 C59.4909,13.604 58.5949,14.5 57.4909,14.5 C56.3869,14.5 55.4909,13.604 55.4909,12.5 C55.4909,11.396 56.3869,10.5 57.4909,10.5 C58.5949,10.5 59.4909,11.396 59.4909,12.5" id="Fill-8" fill={theme.secondaryColor}></path>
          </g>
        </g>
      </g>
    </svg>
  );
  /* eslint-enable */
}

SvgKey.propTypes = {
  theme: PropTypes.object.isRequired,
};

export default withTheme(SvgKey);
