/**
 * Created by galgoltzman on 19/06/2017.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown, MenuItem } from 'react-bootstrap';
import { defineMessages, FormattedMessage } from 'react-intl';
import { autobind } from 'core-decorators';
import { Link } from 'react-router';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { openFindDoctors } from '../../directory/routingActions';
import { getPathsFromContext } from '../../routes/injectPathsToContext';
import { mobileMenuOpen } from '../../../../../store/ui/actions';
import medicalTeamEvents from '../../../../../store/tools/analytics/pcpSelection/medicalTeamEvents';

import './doctorsMenu.less';
import { getFeature } from '../../../../../store/feature/selectors';
import { shouldShowPCPModal } from '../../medicalTeam/medicalTeam';

const messages = defineMessages({
  findADoctor: {
    defaultMessage: 'Find a doctor',
    id: 'members.doctorsMenu.findADoctor',
  },
  myMedicalTeam: {
    defaultMessage: 'Your medical team',
    id: 'members.doctorsMenu.myMedicalTeam',
  },
  doctors: {
    defaultMessage: 'Doctors',
    id: 'members.doctorsMenu.doctors',
  },
});

@autobind
class DoctorsMenu extends React.PureComponent {
  static propTypes = {
    mobileMenuOpen: PropTypes.func.isRequired,
    isMobileScreen: PropTypes.bool.isRequired,
    paths: PropTypes.object.isRequired,
    openFindDoctors: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired,
  };

  static contextTypes = {
    analytics: PropTypes.object.isRequired,
  };

  onSelectMedicalTeam() {
    this.context.analytics.track(medicalTeamEvents.medicalTeamTabClick);
    this.closeMobileMenu();
  }

  closeMobileMenu() {
    if (this.props.isMobileScreen) {
      this.props.mobileMenuOpen(false);
    }
  }

  openFindDoctors() {
    this.closeMobileMenu();
    this.props.openFindDoctors(this.props.paths);
  }

  backToMenu(notRelevant, e) {
    if (this.props.isMobileScreen) {
      e.stopPropagation();
      this.props.mobileMenuOpen(true);
    }
  }

  render() {
    const { id, pcpSelectionFeature } = this.props;
    return (
      <Dropdown id={id} className="doctors-menu xs-fullscreen-dropdown">
        <Dropdown.Toggle bsStyle="inline-link text-semibold doctors-menu-toggle">
          <FormattedMessage {...messages.doctors} />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <MenuItem className="dropdown-header visible-xs" onSelect={this.backToMenu}>
            <span className="text-18 text-semibold text-main">
              <FormattedMessage {...messages.doctors} />
              <i className="icon-back-arrow dropdown-back-btn left" />
            </span>
          </MenuItem>
          <MenuItem onSelect={this.openFindDoctors}>
            <i className="icon-search-2 item-icon" />
            <FormattedMessage {...messages.findADoctor} />
          </MenuItem>
          {/* Public directory doesn't have this route */}
          {pcpSelectionFeature && this.props.paths.medicalTeam ? (
            <MenuItem onSelect={this.onSelectMedicalTeam}>
              <Link to={this.props.paths.medicalTeam()}>
                <i className="icon-medical-team item-icon" />
                <FormattedMessage {...messages.myMedicalTeam} />
              </Link>
            </MenuItem>
          ) : null}
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}

export default compose(
  getPathsFromContext(),
  connect(
    state => {
      const shouldShow = shouldShowPCPModal(state);
      return {
        pcpSelectionFeature: getFeature(state, 'pcpSelection') && shouldShow,
      };
    },
    { openFindDoctors, mobileMenuOpen },
  ),
)(DoctorsMenu);
