/**
 * Created by guyavarham on 23/04/2017.
 */
import _ from 'lodash';
import Immutable from 'seamless-immutable';

export default function genericReducer(
  genericActionType,
  initialState = {},
  transform,
  options = {}
) {
  return (state = initialState, action) => {
    state = Immutable(state);
    // support regular actions type
    if (_.isString(genericActionType) && action.type === genericActionType) {
      const response = transform ? transform(action.payload) : action.payload;
      return Immutable(response);
    }
    // support promise actions type
    switch (action.type) {
      case genericActionType.START:
        return options.keepStateOnStart ? state : Immutable(initialState);
      case genericActionType.ERROR:
        return options.keepStateOnError ? state : Immutable(initialState);
      case genericActionType.SUCCESS: {
        const response = transform ? transform(action.payload) : action.payload;
        return Immutable(response);
      }
    }

    return state;
  };
}
