/**
 * Created by anna on 13/04/2017.
 */
import Immutable from 'seamless-immutable';
import { GET_PLATFORM, TEST_LOCAL_STORAGE } from './actions';

const initialState = {
  platform: null,
  isMobilePlatform: false,
  isLocalStorageAccessible: true
};

export default function platformReducer(state = initialState, action) {
  state = Immutable(state);
  switch (action.type) {
    case GET_PLATFORM: {
      return state.merge(action.payload);
    }
    case TEST_LOCAL_STORAGE: {
      return state.merge({ isLocalStorageAccessible: action.payload });
    }
  }
  return state;
}
