/**
 * Created by guyavarham on 11/06/2017.
 */

import _ from 'lodash';
import { createSelector } from 'reselect';
import { getFeature } from '../feature/selectors';
import { isAuthenticated } from '../auth/selectors';

export const hasInsuranceIdSelector = state => _.get(state, 'member.info.hasInsuranceId');
export const fullNameSelector = state => state.member.info.fullName;
export const firstNameSelector = state =>
  _.chain(state.member.info)
    .get('fullName')
    .split(' ')
    .first()
    .value();
export const homeSetting = state => {
  const home = _.get(state, 'member.settings.search.homeAddress');
  if (!home || _.isEmpty(home) || (!home.address && _.isEmpty(home.geo))) return null;
  return {
    address: home.address,
    geo: home.geo,
    shortAddress: home.address && home.address.split(',')[0],
  };
};

export const workSetting = state => {
  const work = _.get(state, 'member.settings.search.workAddress');
  if (!work || _.isEmpty(work) || (!work.address && _.isEmpty(work.geo))) return null;
  return {
    address: work.address,
    geo: work.geo,
    shortAddress: work.address && work.address.split(',')[0],
  };
};

export const memberPlan = state => state.member.info.plan;

export const memberContext = state => _.get(state, 'member.info.memberContext');

export const getCareCues = state => state.member.careCues;

export const getMedicationsSummary = state => state.member.medicationsSummary;

export const medicationsSummaryByDate = createSelector(getMedicationsSummary, medications =>
  _.orderBy(medications, 'lastPrescribed', 'desc'),
);

export const getDrugHistory = (state, drugName) => _.get(state.member.drugHistory, drugName, null);

function structureDrugPrescriptions(prescriptions) {
  if (_.isNil(prescriptions)) return null;

  const result = {};
  _(prescriptions)
    .sortBy('prescribedAt')
    .each(script => {
      const year = script.prescribedAt.getFullYear();
      const dosageGroup = _.get(result, [year, script.dosage], []);
      dosageGroup.unshift(script);
      result[year] = _.assign(result[year], { [script.dosage]: dosageGroup });
    });
  return result;
}

/**
 * For a given drug name, map prescriptions by, then by dosage sorted by date
 * @param state
 * @param drugName
 * @constructor
 */
export const DrugHistoryByYearAndDosage = createSelector(
  getDrugHistory,
  structureDrugPrescriptions,
);

export const member = state => _.get(state, 'member.info');
export const domainMemberIdSelector = state => member(state).domainMemberId;
export const getMembers = state => state.members.members.arr;

/**
 * @param state - redux state
 * @param {clientMemberSettingsStates} memberSettingState - the member-state from the enum
 * @returns 3 options to return:
 * 1. in case of certain state exists - return the state value.
 * 2. in case of certain state is not exists - return the given default value of the state.
 * 3. in case of *member states obj* is not exists - return null.
 */
export const getMemberSettingsState = (state, memberSettingState) => {
  // support the case of member states obj is not accessible/didn't arrived yet to redux
  if (!_.get(state, 'member.settings.memberStates', null)) return null;

  const { key, defaultValue } = memberSettingState;
  const memberStateItem = _.get(state, `member.settings.memberStates.${key}`, null);
  return _.get(memberStateItem, 'value', defaultValue);
};

/**
 * @param state - redux state
 * @returns 3 options to return:
 * 1. in case member isAuthenticated and has state
 * and it exists in config - return the address of the member's state.
 * 2. in case member isAuthenticated with no state -  return null.
 * 3. all other cases returns the fallBackLocation;
 */
export const getDefaultLocationSelector = (state, memberSelector = member) => {
  const memberInfo = memberSelector(state);
  // Default location is affected by member's context (when there's no context, state is taken)
  // For instance, Premera's PK member has context='PK' buy state='WA'
  const context = _.get(memberInfo, 'memberContext', _.get(memberInfo, 'state'));
  const memberGeo = _.get(memberInfo, 'memberCityAddress.geo');
  const memberCityAddress = !_.isEmpty(memberGeo) && memberInfo.memberCityAddress;
  const fallBackLocation =
    memberCityAddress || getFeature(state, 'search.defaultLocation.fallBackLocation');

  if (context) {
    const memberStateLocation =
      memberCityAddress || getFeature(state, `search.defaultLocation.${context}`);
    return _.isEmpty(memberStateLocation) ? fallBackLocation : memberStateLocation;
  }

  // for non authorizedMembers return the default, for authorized - wait
  return isAuthenticated(state) ? null : fallBackLocation;
};

export const getHomeLocationSelector = (state, memberSelector = member) => {
  const memberInfo = memberSelector(state);

  return _.get(memberInfo, 'address');
};
