/**
 * Created by matan on 11/9/17.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { autobind } from 'core-decorators';
import _ from 'lodash';
import moment from 'moment';

import SvgMedication from '../../../../assets/svg/medication';
import SvgDoctor from '../../../../assets/svg/doctor';
import SvgHourglass from '../../../../assets/svg/hourglass';
import SvgDosage from '../../../../assets/svg/dosage';
import CollapsibleCard from '../../../../components/ui/collapsibleCard/collapsibleCard';
import ThreeBounceSpinner from '../../../../components/ui/spinner/threeBounceSpinner';
import { getDrugHistory, GET_DRUG_HISTORY } from '../../../../store/member/actions';
import actionTracker from '../../../../store/tools/actionTracker/actionTrackerComponent';
import * as selectors from '../../../../store/member/selectors';
import messages from './messages';

import './medications.less';

const renderPrescriptionRow = function renderPrescriptionRow(prescription) {
  const time = moment(prescription.prescribedAt);
  return (
    <div className="history-row" key={time.unix()}>
      <span className="pull-left text-14 text-dark text-semibold">{time.format('MMM D')}</span>
      <span className="pull-right text-14 text-dark">
        {prescription.supplyFor}
        <FormattedMessage {...messages.days} />
      </span>
    </div>
  );
};

const renderDosageGroup = function renderDosageGroup(prescriptions, dosage) {
  return (
    <div className="dosage-group" key={dosage}>
      <div className="dosage-row text-dark text-14">
        <SvgDosage />
        <span className="text-semibold">{dosage}</span>
        &nbsp;
        <span>
          <FormattedMessage {...messages.dosage} />
        </span>
      </div>

      {_.map(prescriptions, renderPrescriptionRow)}
    </div>
  );
};

const renderYearHistory = function renderYearHistory(year, yearlyHistory) {
  return (
    <div className="history-year" key={year}>
      <div className="year-tag bg-color-brand-secondary">{year}</div>

      <div className="text-13">
        <FormattedMessage {...messages.refills} />
      </div>

      {_.map(yearlyHistory, renderDosageGroup)}
    </div>
  );
};

const renderMultiDosageBadge = function renderMultiDosageBadge(show) {
  return !show ? null : (
    <div className="col-xs-12">
      <div className="details-item">
        <SvgDosage />
        <span className="text-14 text-dark text-semibold">
          <FormattedMessage {...messages.multipleDosages} />
        </span>
      </div>
    </div>
  );
};

@autobind
class MedicationCard extends React.Component {
  static propTypes = {
    medication: PropTypes.object,
    getDrugHistory: PropTypes.func.isRequired,
    drugHistoryTracking: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
    drugHistory: PropTypes.object,
  };

  static defaultProps = {
    medication: null,
    drugHistory: null,
  };

  onCardExtend() {
    const { drugHistory, getDrugHistory, medication } = this.props;
    const tracker = this._getTracker();

    if (_.get(tracker, 'inProgress') || !_.isNil(drugHistory)) return;
    getDrugHistory(medication.drugName);
  }

  _getTracker() {
    return _.chain(this.props.drugHistoryTracking)
      .castArray()
      .find(['key', `${GET_DRUG_HISTORY}-${this.props.medication.drugName}`])
      .value();
  }

  renderCardContent() {
    const { drugHistory } = this.props;
    if (_.isNil(drugHistory)) return <ThreeBounceSpinner />;

    return (
      <div className="history-content">
        {_.chain(drugHistory)
          .toPairs()
          .orderBy(_.first, 'desc')
          .map(pair => renderYearHistory(...pair))
          .value()}
      </div>
    );
  }

  renderDrugHistory() {
    return (
      <CollapsibleCard
        title="Show history"
        className="medication-history clean-card open-up-card"
        onClick={this.onCardExtend}
      >
        <div className="history-header">
          <div className="text-14 text-dark">
            <FormattedMessage {...messages.history} />
          </div>
        </div>
        {this.renderCardContent()}
      </CollapsibleCard>
    );
  }

  render() {
    const {
      drugName,
      lastDosage,
      lastPrescribedString,
      lastSupplyFor,
      pastMultipleDosage,
      prescribedBy,
    } = this.props.medication;

    return (
      <div className="row tiny-gutter" key={_.replace(drugName, / /g, '')}>
        <div className="col-xs-3 bg-color-brand-secondary">
          <div className="date">
            <div className="last-filled text-13 text-white">
              <FormattedMessage {...messages.lastFilled} />
            </div>
            <span className="text-18 text-white">
              <strong>{lastPrescribedString}</strong>
            </span>
          </div>
        </div>

        <div className="col-xs-9">
          <div className="card arthritis">
            <div className="card-body">
              <div className="row">
                <div className="col-sm-6 col-md-7">
                  <div className="medication-top">
                    <span className="hidden-xs">
                      <SvgMedication />
                    </span>
                    <div className="text-18 text-semibold font-color-brand-main">{drugName}</div>
                    <div className="text-14">{lastDosage}</div>
                  </div>
                </div>
              </div>

              <div className="row medication-details">
                <div className="col-md-4">
                  <div className="details-item">
                    <SvgHourglass />
                    <span className="text-13">
                      <FormattedMessage {...messages.supplyFor} />
                    </span>
                    <br className="visible-xs" />
                    <span className="text-14 text-dark text-semibold">
                      <FormattedMessage
                        {...messages.periodSupplyFor}
                        values={{ supplyFor: lastSupplyFor }}
                      />
                    </span>
                  </div>
                </div>
                <div className="col-md-8">
                  <div className="details-item">
                    <SvgDoctor />
                    <span className="text-13">
                      <FormattedMessage {...messages.prescribedBy} />
                    </span>
                    <br className="visible-xs" />
                    <span className="text-14 text-dark text-semibold">
                      <FormattedMessage {...messages.drTitle} values={{ prescribedBy }} />
                    </span>
                  </div>
                </div>
                {renderMultiDosageBadge(pastMultipleDosage)}
              </div>
            </div>
            {this.renderDrugHistory()}
          </div>
        </div>
      </div>
    );
  }
}

export default compose(
  actionTracker({
    drugHistoryTracking: new RegExp(`${GET_DRUG_HISTORY}-*`),
  }),
  connect(
    (state, props) => ({
      drugHistory: selectors.DrugHistoryByYearAndDosage(state, _.get(props, 'medication.drugName')),
    }),
    { getDrugHistory },
  ),
)(MedicationCard);
