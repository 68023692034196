import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { autobind } from 'core-decorators';
import { geocodeByAddress } from 'react-places-autocomplete';

import MessageShape from '../../../../../components/ui/intlString/messageShape';
import actionTracker from '../../../../../store/tools/actionTracker/actionTrackerComponent';
import Layout from '../../layout/layout';
import SettingsLayout from '../settingsLayout';

import AddressForm from './updateAddressForm';
import { paths } from '../paths';

import { CHANGE_ADDRESS, changeAddress } from '../../../../../store/member/actions';

const findStateComponent = addressComponents =>
  _.find(addressComponents, ({ types }) =>
    _.some(types, type => type === 'administrative_area_level_1'),
  );

const replaceShortStateNameWithLong = (res, address) => {
  if (_.isEmpty(res)) return address;

  const { address_components: addressComponents } = res[0];
  const stateComponent = findStateComponent(addressComponents);
  if (!stateComponent) return address;

  const { short_name: shortName, long_name: longName } = stateComponent;
  return _.replace(address, shortName, longName);
};

@autobind
class UpdateAddressPage extends React.Component {
  static propTypes = {
    /**
     * Action tracking object.
     */
    changeAddressTracking: PropTypes.object.isRequired,
    /**
     * Redux action creator.
     */
    changeAddress: PropTypes.func.isRequired,
    /**
     * The name of the location that this page updates (WORK/HOME).
     * Will also be the name of the redux-form created by this code.
     */
    locationName: PropTypes.string.isRequired,
    label: MessageShape.isRequired,
    hiddenTitle: MessageShape.isRequired,
    address: PropTypes.string,
    svgIcon: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
  };

  static defaultProps = {
    address: undefined,
  };

  componentWillReceiveProps(nextProps) {
    const { changeAddressTracking } = nextProps;

    if (changeAddressTracking.finished) {
      this.props.router.replace(paths.locations());
    }
  }

  onSubmit({ address }) {
    const { changeAddress, locationName } = this.props;
    geocodeByAddress(address)
      .then(res => changeAddress(locationName, replaceShortStateNameWithLong(res, address)))
      .catch(() => changeAddress(locationName, address));
  }

  render() {
    return (
      <Layout
        hideLogo
        centeredBackBtn
        previousPageName="locations"
        previousPageRoute={paths.locations()}
      >
        <SettingsLayout>
          <AddressForm
            onSubmit={this.onSubmit}
            loading={this.props.changeAddressTracking.inProgress}
            label={this.props.label}
            address={this.props.address}
            svgIcon={this.props.svgIcon}
            hiddenTitle={this.props.hiddenTitle}
          />
        </SettingsLayout>
      </Layout>
    );
  }
}

export default function(locationName, label, addressPath, svgIcon, hiddenTitle) {
  return compose(
    actionTracker({
      changeAddressTracking: CHANGE_ADDRESS.SOURCE,
    }),
    connect(
      state => ({
        locationName,
        label,
        address: _.get(state, addressPath),
        svgIcon,
        hiddenTitle,
      }),
      {
        changeAddress,
      },
    ),
    withRouter,
  )(UpdateAddressPage);
}
