/**
 * Created by galgoltzman on 14/08/2018.
 */
import React from 'react';
import { autobind } from 'core-decorators';
import PropTypes from 'prop-types';
import './clickableElement.less';

const ENTER_KEY = 13;
const SPACE_KEY = 32;

@autobind
class ClickableLi extends React.PureComponent {

  static propTypes = {
    clickHandler: PropTypes.func.isRequired,
  };

  onKeyDown(e) {
    if (e.keyCode === ENTER_KEY || e.keyCode === SPACE_KEY) {
      this.props.clickHandler();
    }
  }

  render() {
    const { clickHandler, children, ...otherProps } = this.props;
    return (
// eslint-disable-next-line jsx-a11y/no-static-element-interactions
      <li
        className="clickable-element"
        onClick={clickHandler}
        onKeyDown={this.onKeyDown}
        role="button"
        tabIndex="0"
        {...otherProps}
      >
        {children}
      </li>
    );
  }
}

export default ClickableLi;
