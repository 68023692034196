/**
 * Created by chenrozenes on 09/08/2017.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import { compose } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import SubCategory from '../../../../model/enum/careCues/subCategory';
import * as Category from '../../../../model/enum/careCues/category';
import CareCueDef from '../../../../model/enum/careCues/careCueDef';
import CareCuesText from '../../../../model/enum/careCues/text';
import CareCuesTitle from '../../../../model/enum/careCues/title';
import { searchAndOpenResults } from '../directory/routingActions';
import { CareCueActionClicked } from '../../../../store/tools/analytics/events/memberEvents';
import ClickableDiv from '../../../../components/clickableElement/clickableDiv';

import { chooseClientLocation, isLocationReady } from '../directory/directorySelectors';

function categoryToClass(category) {
  const map = {
    [Category.CANCER_SCREENING]: 'cancer-screening',
    [Category.CARE_OF]: 'care-of-something',
  };

  // Default to care of
  return map[category] || map[Category.CARE_OF];
}

@autobind
class CareCueItem extends React.Component {
  static propTypes = {
    searchAndOpenResults: PropTypes.func.isRequired,
    validLocation: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    careCue: PropTypes.shape({
      gapId: PropTypes.string.isRequired,
    }).isRequired,
  };

  static contextTypes = {
    paths: PropTypes.object.isRequired,
    analytics: PropTypes.object.isRequired,
  };

  gotoSearchResults(e) {
    e.preventDefault();
    const {
      careCue: { gapId },
      location,
    } = this.props;
    const term = _.get(CareCueDef, [gapId, 'action', 'term']);
    this.props.searchAndOpenResults(this.context.paths, term, location);
    this.context.analytics.track(CareCueActionClicked, { gapId, actionTerm: term });
  }

  render() {
    const {
      careCue: { gapId },
      validLocation,
    } = this.props;
    const subCategory = _.get(CareCueDef, [gapId, 'subCategory']);
    const category = _.get(CareCueDef, [gapId, 'category']);
    const actionMessage = _.get(CareCueDef, [gapId, 'action', 'message']);

    if (!subCategory || !category || _.isEmpty(validLocation)) return null;

    return (
      <div className={classNames('card', categoryToClass(category))}>
        <ClickableDiv
          className="card-body"
          clickHandler={actionMessage ? this.gotoSearchResults : () => null}
        >
          <i className="icon-speak-heart card-icon bg-color-brand-secondary" />
          <div className="row">
            <div className="col-sm-8 col-md-7">
              <span className="text-14 text-dark">
                <FormattedMessage {...CareCuesTitle.considerGetting} />
              </span>
              <div className="text-18 text-semibold text-dark">
                <FormattedMessage {...CareCuesTitle[gapId]} />
              </div>
              <div className="text-16 margin-top">
                <FormattedMessage {...CareCuesText[gapId]} />
              </div>
              {!actionMessage ? null : (
                <div className="margin-top-30">
                  <div
                    role="button"
                    className="care-cue-action no-padding text-16 text-semibold text-dark"
                  >
                    <FormattedMessage {...actionMessage} />
                    <i className="icon-chevron-right item-arrow" />
                  </div>
                </div>
              )}
            </div>
            <div className="col-sm-4 col-md-5">
              <div className="card-type hidden-xs text-13 font-color-brand-secondary">
                <FormattedMessage {...SubCategory.getMessageByValue(subCategory)} />
              </div>
            </div>
          </div>
        </ClickableDiv>
      </div>
    );
  }
}

export default compose(
  connect(
    state => ({
      location: _.get(chooseClientLocation(state), 'geo', null),
      validLocation: isLocationReady(state),
    }),
    { searchAndOpenResults },
  ),
)(CareCueItem);
