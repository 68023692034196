import React from 'react';
import PropTypes from 'prop-types';
import { FormattedHTMLMessage, defineMessages } from 'react-intl';

import SvgNewPatientsPlus from '../../../../../assets/svg/newPatientsPlus';

const messages = defineMessages({
  acceptingNewPatients: {
    defaultMessage: 'Accepting new patients',
    id: 'common.doctorDetails.acceptingNewPatients:',
  },
});

const AcceptNewPatients = ({ acceptNewPatients, message, textClass, itemClass }) => {
  if (!acceptNewPatients) return null;
  return (
    <div className={itemClass}>
      <SvgNewPatientsPlus />
      <div className={textClass}>
        <FormattedHTMLMessage {...message} />
      </div>
    </div>
  );
};

AcceptNewPatients.propTypes = {
  acceptNewPatients: PropTypes.bool.isRequired,
  message: PropTypes.object,
  textClass: PropTypes.string,
  itemClass: PropTypes.string,
};

AcceptNewPatients.defaultProps = {
  message: messages.acceptingNewPatients,
  textClass: '',
  itemClass: '',
};

export default AcceptNewPatients;
