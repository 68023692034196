import React from 'react';
import { defineMessages, FormattedHTMLMessage } from 'react-intl';
import { compose } from 'redux';
import { connect } from 'react-redux';

import ResetEmailHelpModal from './resetEmailHelpModal';
import { SimpleProgressPage } from '../../../../../components/ui/layouts';
import {
  createToggleState,
  createToggleStatePropTypes,
} from '../../../../../components/recompose/toggleStateComponent';
import SvgEnvelope from '../../../../../assets/svg/envelope';

const messages = defineMessages({
  weSentAPasswordResetEmail: {
    defaultMessage: 'We sent a password reset email to',
    id: 'auth.resetPassword.weSentAPasswordResetEmail',
  },
  notifiedCustomerSupport: {
    defaultMessage:
      "We've notified our customer support team and " +
      "we'll be in touch to see if we can help you.",
    id: 'activation.mobileNum.notifiedCustomerSupport',
  },
  dontSeeIt: {
    defaultMessage: 'Don’t see it? Check other places it might be like promotions or junk folders.',
    id: 'activation.mobileNum.dontSeeIt',
  },
  iDidntGetAnEmail: {
    defaultMessage: 'I didn’t get an email',
    id: 'activation.mobileNum.iDidntGetAnEmail',
  },
  checkYourEmail: {
    defaultMessage: 'Check your email',
    id: 'members.auth.resetPassword.error.checkYourEmail',
  },
});

class WeSentYouAnEmailPage extends React.PureComponent {
  static propTypes = {
    ...createToggleStatePropTypes('ResetEmailHelpModal'),
  };

  render() {
    const {
      ResetEmailHelpModalShow,
      ResetEmailHelpModalHide,
      ResetEmailHelpModalOn,
      auth,
    } = this.props;

    return (
      <SimpleProgressPage
        caption={messages.checkYourEmail.defaultMessage}
        progress={null}
        img={<SvgEnvelope />}
        externalPageLogo
      >
        <div>
          <div>
            <span className="text-18 text-light">
              <FormattedHTMLMessage {...messages.weSentAPasswordResetEmail} />
            </span>
            &nbsp;
            <span className="text-18 text-semibold text-dark">{auth.identifier}</span>
            <div className="margin-top-20 text-18 text-light">
              <FormattedHTMLMessage {...messages.dontSeeIt} />
            </div>
            <div className="margin-top-30">
              <button
                className="btn btn-inline-link text-14 font-color-brand-link"
                onClick={ResetEmailHelpModalShow}
              >
                <FormattedHTMLMessage {...messages.iDidntGetAnEmail} />
              </button>
            </div>
          </div>
        </div>

        <ResetEmailHelpModal isOpen={ResetEmailHelpModalOn} handleClose={ResetEmailHelpModalHide} />
      </SimpleProgressPage>
    );
  }
}

export default compose(
  createToggleState('ResetEmailHelpModal'),
  connect(state => ({
    auth: state.auth,
  })),
)(WeSentYouAnEmailPage);
