import React from 'react';

export default function SvgUserId() {
  /* eslint-disable */
  return (
    <svg aria-hidden="true" focusable="false" className="svg-icon svg-icon-user-id" width="22px" height="14px" viewBox="0 0 22 14" version="1.1">
      <g id="Basic-flow---no-TIN" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Physician-search-02" transform="translate(-189.000000, -374.000000)">
          <g id="Group-7" transform="translate(49.000000, 132.000000)">
            <g id="Group-4" transform="translate(140.000000, 197.000000)">
              <g id="TIN" transform="translate(0.000000, 45.000000)">
                <rect id="Rectangle-path" fill="#898989" x="12" y="3" width="10" height="2" />
                <rect id="Rectangle-path" fill="#898989" x="12" y="7" width="7" height="2" />
                <path d="M7.471,6.185 C8.246,5.543 8.75,4.585 8.75,3.5 C8.75,1.566 7.184,0 5.25,0 C3.317,0 1.75,1.567 1.75,3.5 C1.75,4.585 2.254,5.542 3.029,6.184 C1.241,7.022 0,8.832 0,10.938 C0,13.836 2.35,14 5.249,14 C8.149,14 10.5,13.836 10.5,10.938 C10.5,8.832 9.259,7.022 7.471,6.185 L7.471,6.185 Z" id="Shape" fill="#09ACE4" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
  /* eslint-enable */
}
