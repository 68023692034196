import React from 'react';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import { compose, withPropsOnChange, setPropTypes } from 'recompose';
import { FormattedMessage, defineMessages } from 'react-intl';
import classNames from 'classnames';
import FeatureFlagged from '../features/featureFlagged';
import * as BookingType from '../../model/enum/bookingType';
import Button from '../../storybook/src/components/atoms/Button/Button';

const actionButtonsMessages = defineMessages({
  book: {
    defaultMessage: 'Book',
    id: 'members.directory.booking.book',
  },
  requestToBook: {
    defaultMessage: 'Book',
    id: 'members.directory.booking.requestToBook',
  },
});

@autobind
class BookingButton extends React.PureComponent {
  static propTypes = {
    bookingType: PropTypes.string.isRequired,
    isBookingAvailable: PropTypes.bool.isRequired,
    onBookAppointmentClicked: PropTypes.func.isRequired,
    enableBooking: PropTypes.bool,
    btnClassName: PropTypes.string,
  };

  static defaultProps = {
    enableBooking: true,
    btnClassName: undefined,
  };

  renderButton(bookingType) {
    const { onBookAppointmentClicked, btnClassName } = this.props;
    if (bookingType === BookingType.REQUEST) {
      // BookingType.REQUEST
      return (
        <Button
          className={classNames(
            'booking-btn',
            btnClassName,
          )}
          onClick={onBookAppointmentClicked}
          type="large"
        >
          <FormattedMessage {...actionButtonsMessages.requestToBook} />
        </Button>
      );
    }

    // BookingType.INSTANT
    return (
      <Button
        className={classNames(
          'booking-btn',
          btnClassName,
        )}
        onClick={onBookAppointmentClicked}
        type="large"
      >
        <i className="icon-lightning text-18 i-va-fix-3" />
        &nbsp;
        <FormattedMessage {...actionButtonsMessages.book} />
      </Button>
    );
  }

  render() {
    const { enableBooking, isBookingAvailable, bookingType } = this.props;

    return (
      <FeatureFlagged uri="booking" renderChildren>
        {isBookingAvailable && enableBooking
          ? this.renderButton(bookingType || BookingType.INSTANT)
          : null}
      </FeatureFlagged>
    );
  }
}

export default compose(
  setPropTypes({
    pickedLocation: PropTypes.object.isRequired,
    onBookAppointmentClicked: PropTypes.func.isRequired,
    enableBooking: PropTypes.bool,
    btnClassName: PropTypes.string,
  }),
  withPropsOnChange(
    ['pickedLocation'],
    ({ pickedLocation: { isBookingAvailable, bookingType } }) => ({
      isBookingAvailable,
      bookingType,
    }),
  ),
)(BookingButton);
