import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '../../storybook/src/utils/theme';

function SvgSpeechBubbleDots({ theme }) {
  /* eslint-disable */
  return (
    <svg width="68px" height="53px" viewBox="0 0 68 53" version="1.1" className="svg-icon svg-speech-bubble-dots">
      <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Asset-42" fill-rule="nonzero">
          <path  d="M66.5,53 L10.5,53 C4.70329514,52.9944872 0.00551279961,48.2967049 0,42.5 L0,10.5 C0.00551279961,4.70329514 4.70329514,0.00551279961 10.5,0 L48.5,0 C54.2967049,0.00551279961 58.9944872,4.70329514 59,10.5 L59,40.92 L67.62,50.5 C68.0145896,50.9411636 68.1128615,51.5731157 67.8708721,52.1132707 C67.6288826,52.6534257 67.0918832,53.0007762 66.5,53 Z M10.5,3 C6.35786438,3 3,6.35786438 3,10.5 L3,42.5 C3,46.6421356 6.35786438,50 10.5,50 L63.13,50 L56.38,42.5 C56.1363439,42.2237252 56.0013078,41.8683671 56,41.5 L56,10.5 C56,8.51087633 55.2098237,6.60322198 53.8033009,5.19669914 C52.396778,3.7901763 50.4891237,3 48.5,3 L10.5,3 Z" id="Shape" fill={theme.mainColor}></path>
          <circle id="Oval" fill={theme.secondaryColor} cx="39" cy="25.75" r="2.5"></circle>
          <circle id="Oval" fill={theme.secondaryColor} cx="21" cy="25.75" r="2.5"></circle>
          <circle id="Oval" fill={theme.secondaryColor} cx="30" cy="25.75" r="2.5"></circle>
        </g>
      </g>
    </svg>
  );
  /* eslint-enable */
}

SvgSpeechBubbleDots.propTypes = {
  theme: PropTypes.object.isRequired,
};

export default withTheme(SvgSpeechBubbleDots);
