import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { autobind } from 'core-decorators';

import Formatter from '../../utils/formatter';
import commonFormats from '../../utils/formatter/commonFormats';
import TimeslotsGroup from './TimeslotsGroup';
import { toMoment } from '../../store/tools/seamlessImmutableHelper';
import ClickableDiv from '../../components/clickableElement/clickableDiv';
import SpinnerButton from '../../components/ui/spinner/spinnerButton';

// @mrsufgi: Note that timeslots are actually time blocks. @moranshemesh
@autobind
export default class SelectTimeSlot extends React.Component {
  static propTypes = {
    onTimeslotClick: PropTypes.func.isRequired,
    timeslots: PropTypes.array.isRequired,
    notAvailableText: PropTypes.string,
    nextAvailableTitle: PropTypes.string,
    nextAvailableText: PropTypes.string,
    nextAvailableButtonText: PropTypes.string,
    fetchMoreTimeslotsText: PropTypes.string,
    noMoreTimeslotsText: PropTypes.string,
    fetchMoreTimeslots: PropTypes.func.isRequired,
    fetchMoreTimeslotsTracker: PropTypes.object.isRequired,
    noMoreTimeslots: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    notAvailableText: '',
    nextAvailableTitle: '',
    nextAvailableText: '',
    fetchMoreTimeslotsText: '',
    noMoreTimeslotsText: '',
    nextAvailableButtonText: '',
  };

  onTimeslotSelect(timeslot) {
    const { onTimeslotClick } = this.props;
    onTimeslotClick({ payload: { timeslot } });
  }

  /**
   * Aggregates all of the timeslots that are available by day. returns an object containing
   * timeslots keyed by day.
   */
  getAppointmentByDay(timeslots) {
    const dayTimeslots = {};
    _.each(timeslots, appointment => {
      const key = Formatter.date(toMoment(appointment.startTime));
      if (!dayTimeslots[key]) dayTimeslots[key] = [];
      dayTimeslots[key].push(appointment);
    });

    return dayTimeslots;
  }

  renderDay(timeslots, dateString) {
    return (
      <div className="day-results">
        <TimeslotsGroup
          timeslots={timeslots}
          onTimeslotSelect={this.onTimeslotSelect}
          dateString={dateString}
        />
      </div>
    );
  }

  renderNextAvailableBtn() {
    const {
      timeslots,
      nextAvailableText,
      nextAvailableButtonText,
    } = this.props;
    const nextAvailable = _.first(timeslots);

    // TODO: fix creating functions on every render()
    return (
      <ClickableDiv
        className="next-available text-white bg-color-brand-button"
        role="button"
        clickHandler={() => this.onTimeslotSelect(nextAvailable)}
      >
        <div className="text-15 text-medium">{nextAvailableText}</div>
        <div data-id="next-available" className="text-18 text-strong">
          {Formatter.date(toMoment(nextAvailable.startTime), commonFormats.SHORT_DAY_TIME)}
          {nextAvailableButtonText}
        </div>
      </ClickableDiv>
    );
  }

  renderTimeslotsList(timeslots) {
    const dayTimeslots = this.getAppointmentByDay(timeslots);
    const dayList = [];
    _.each(dayTimeslots, (timeslots, dateString) => {
      dayList.push(
        <div data-id="day-group-wrap" className="day-group-wrap" key={dateString}>
          {this.renderDay(timeslots, dateString)}
        </div>,
      );
    });

    return dayList;
  }

  render() {
    const {
      nextAvailableTitle,
      notAvailableText,
      timeslots,
      fetchMoreTimeslots,
      fetchMoreTimeslotsTracker,
      noMoreTimeslots,
      noMoreTimeslotsText,
      fetchMoreTimeslotsText,
    } = this.props;
    return (
      <div className="search-results-content">
        <div className="results-title title-24 text-strong font-color-brand-main text-center">
          {nextAvailableTitle}
        </div>
        {_.isEmpty(timeslots) ? (
          <span data-id="not-available" className="text-15 text-medium">{notAvailableText}</span>
        ) : (
          this.renderNextAvailableBtn()
        )}
        {this.renderTimeslotsList(timeslots)}
        {noMoreTimeslots ? (
          <div className="text-center padding-h-20 margin-top-30">{noMoreTimeslotsText}</div>
        ) : (
          <SpinnerButton
            data-id="fetch-more-timeslots"
            onClick={fetchMoreTimeslots}
            className="btn btn-small btn-outline btn-block"
            isLoading={fetchMoreTimeslotsTracker.inProgress}
          >
            {fetchMoreTimeslotsText}
          </SpinnerButton>
        )}
      </div>
    );
  }
}
