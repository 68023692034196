import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { autobind } from 'core-decorators';
import { formValueSelector, destroy } from 'redux-form';
import { FormattedMessage, defineMessages } from 'react-intl';
import classNames from 'classnames';
import _ from 'lodash';

import {
  createToggleState,
  createToggleStatePropTypes,
} from '../../../../../components/recompose/toggleStateComponent';
import NeedHelpModal from '../../help/helpModal';
import PhoneNumberTakenModal from '../modals/phoneNumberTakenModal';
import Signup from '../components/signup';
import MobileNumber from '../components/mobileNumber';
import Welcome from './fidelityWelcome';
import * as ActivationForm from '../activationForm';
import injectNotification from '../../../../../store/notification/injectNotification';
import {
  setActivationStep,
  validatePhoneNumber,
  VALIDATE_PHONE_NUMBER,
  memberSignup,
  SIGNUP,
} from '../../../../../store/activation/actions';
import * as Errors from '../../../../../api/errors';
import { UPDATE_MEMBER } from '../../../../../store/member/actions';
import actionTracker from '../../../../../store/tools/actionTracker/actionTrackerComponent';
import BlueFooter from '../../../../../components/molecules/BlueFooter/BlueFooter';
import ActivationHeader from '../components/activationHeader';
import errorMapping from '../activationErrorMapping';
import { ActivationCompleted } from '../../../../../store/tools/analytics/events/memberEvents';
import { WELCOME, ENTER_PHONE_NUMBER, SIGN } from '../steps';
import { resetRecaptch } from '../../../../../store/recaptcha/actions';

import '../activation.less';
import { sourceSelector } from '../../../../../store/activation/selectors';

const messages = defineMessages({
  help: {
    defaultMessage: 'I need help',
    id: 'activation.mobileNum.iNeedHelp',
  },
  signIn: {
    defaultMessage: 'Sign in to your account now',
    id: 'activation.mobileNum.signIn',
  },
});

@autobind
export class SSOActivationFlow extends React.Component {
  static propTypes = {
    /**
     * Router object.
     * Provided by the withRouter HoC
     */
    router: PropTypes.object.isRequired,
    /**
     * react router params
     */
    params: PropTypes.object.isRequired,
    /**
     * Initial step in the flow.
     */
    initialStep: PropTypes.string,
    /**
     * Server request action tracking.
     */
    validatePhoneNumberTracking: PropTypes.object.isRequired,
    /**
     * Server request action tracking.
     * For creating a new user.
     */
    signupTracking: PropTypes.object.isRequired,
    /**
     * Redux action creator
     */
    validatePhoneNumber: PropTypes.func.isRequired,
    /**
     * Redux function to create a new user
     */
    memberSignup: PropTypes.func.isRequired,
    /**
     * Injected prop for notifying on errors
     */
    notification: PropTypes.object.isRequired,
    /**
     * redux-form action.
     */
    destroy: PropTypes.func.isRequired,
    /**
     * source from url param
     */
    source: PropTypes.string.isRequired,
    /**
     * The id of the domain-member to register
     */
    activationToken: PropTypes.string.isRequired,
    /**
     * The first name of the domain-member
     */
    firstName: PropTypes.string.isRequired,
    /**
     * Action function to reset system recaptch component.
     * Provided by the connect HoC
     */
    resetRecaptch: PropTypes.func.isRequired,
    ...createToggleStatePropTypes('helpModal'),
    ...createToggleStatePropTypes('phoneNumberTakenModal'),
  };

  static contextTypes = {
    analytics: PropTypes.object.isRequired,
  };

  static defaultProps = {
    initialStep: ENTER_PHONE_NUMBER,
  };

  componentWillReceiveProps(nextProps) {
    const { setStep, signupTracking, validatePhoneNumberTracking } = nextProps;

    if (validatePhoneNumberTracking.finished) {
      this.handleResponse(validatePhoneNumberTracking, () => setStep(SIGN));
    }

    if (signupTracking.finished) {
      this.handleResponse(signupTracking, () => {
        this.context.analytics.track(ActivationCompleted);
        this.redirectToLandingPage();
      });
    }
  }

  handleError(error) {
    const { responseMessage } = error;
    switch (responseMessage) {
      case Errors.RECAPTCHA_FAILED:
        break; // Handled by recaptchaMiddleware
      case Errors.USER_ALREADY_EXIST: {
        this.props.destroy(ActivationForm.name); // clean the from
        this.props.phoneNumberTakenModalShow();
        this.props.setStep(ENTER_PHONE_NUMBER); // reset the step state
        break;
      }
      default: {
        this.props.notification.error(
          errorMapping.defaultMessage.title,
          errorMapping.defaultMessage.message,
          { autoDismiss: 10 },
        );
      }
    }
  }

  handleResponse(tracking, onSuccess, nextProps) {
    if (tracking.hasError) {
      this.handleError(tracking.error);
    } else {
      onSuccess(nextProps);
    }
  }

  validatePhoneNumber({ phoneNumber }) {
    this.props.validatePhoneNumber(phoneNumber, this.props.source);
  }

  signup({ phoneNumber, email, password }) {
    this.props.memberSignup(
      this.props.activationToken,
      email,
      password,
      this.props.source,
      phoneNumber,
    );
  }

  redirectToLandingPage() {
    // clean the form in the state
    this.props.destroy(ActivationForm.name);
    // Clear current step in store
    this.props.setStep(null);
    this.props.router.replace('/secure?first=true');
  }

  withLayout(PageComponent, rawOptions) {
    const options = _.assign({ bottomLinks: true, loading: false }, rawOptions);

    return (
      <div className="activation-layout">
        <ActivationHeader goBack={options.goBack} />
        <div className="content">
          <div className="row">
            <div className="col-sm-6 col-sm-offset-3 col-lg-4 col-lg-offset-4">
              {this.props.children}
              <PageComponent
                onSubmit={options.onSubmit}
                loading={options.loading}
                formName={options.formName}
                {...options}
              />

              <div
                className={classNames('col-xs-12 text-center', {
                  hidden: !options.bottomLinks,
                })}
              >
                <div className="margin-top">
                  <button
                    className="btn btn-inline-link font-color-brand-link"
                    onClick={this.props.helpModalShow}
                    type="button"
                  >
                    <FormattedMessage {...messages.help} />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <BlueFooter footerClassName="xs-not-fixed white" />

        <NeedHelpModal handleClose={this.props.helpModalHide} isOpen={this.props.helpModalOn} />
        <PhoneNumberTakenModal
          handleClose={this.props.phoneNumberTakenModalHide}
          isOpen={this.props.phoneNumberTakenModalOn}
        />
      </div>
    );
  }

  render() {
    const { signupTracking, validatePhoneNumberTracking, step } = this.props;

    switch (step) {
      case WELCOME:
        return this.withLayout(Welcome, {
          onSubmit: () => this.props.setStep(ENTER_PHONE_NUMBER),
          firstName: _.startCase(this.props.firstName),
        });
      case ENTER_PHONE_NUMBER:
        return this.withLayout(MobileNumber, {
          onSubmit: this.validatePhoneNumber,
          loading: validatePhoneNumberTracking.inProgress,
        });
      case SIGN:
        return this.withLayout(Signup, {
          onSubmit: this.signup,
          loading: signupTracking.inProgress,
          goBack: () => this.props.setStep(ENTER_PHONE_NUMBER),
        });
      default:
        return null;
    }
  }
}

const fieldSelector = formValueSelector(ActivationForm.name);

export const flowComposition = [
  actionTracker({
    updateMemberTracking: UPDATE_MEMBER.SOURCE,
    signupTracking: SIGNUP.SOURCE,
    validatePhoneNumberTracking: VALIDATE_PHONE_NUMBER.SOURCE,
  }),
  createToggleState('helpModal'),
  createToggleState('phoneNumberTakenModal'),
  connect(
    (state, ownProps) => ({
      step: state.activation.step || WELCOME,
      phoneNumber: fieldSelector(state, ActivationForm.fields.phoneNumber.name),
      isInActiveState: _.get(state.activation, 'isInActiveState'),
      source: sourceSelector(state, ownProps),
      activationToken: state.sso.activationToken,
      firstName: state.sso.firstName,
    }),
    {
      setStep: setActivationStep,
      memberSignup,
      validatePhoneNumber,
      destroy,
      resetRecaptch,
    },
  ),
  injectNotification,
  withRouter,
];

export default compose(...flowComposition)(SSOActivationFlow);
