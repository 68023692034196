import React from 'react';
import { compose, defaultProps } from 'recompose';
import { connect } from 'react-redux';
import _ from 'lodash';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import BaseModal from 'react-overlays/lib/Modal';

import analytics from '../../store/tools/analytics/analyticsHoc';
import { getDomainShortName } from '../../store/feature/selectors';

import './dialog.less';

class BkmdModalComponent extends React.Component {
  /**
   * Default prop values
   * @type {{isOpen: boolean, shouldAnimate: boolean, closeOnBackdrop: boolean}}
   */
  static defaultProps = {
    isOpen: false,
    shouldAnimate: false,
    closeOnBackdrop: true,
    className: '',
    onOpen: () => {
    },
    handleClose: () => {
    },
    handleSubmit: () => {
    },
    baseClassName: null,
    name: null,
    domainContext: null,
    disableKeyDown: false,
  };

  static propTypes = {
    baseClassName: PropTypes.string,
    /**
     * Is the modal opened
     */
    isOpen: PropTypes.bool.isRequired,

    /**
     * The css class name of the modal
     */
    className: PropTypes.string,

    /**
     * Should animate when the modal is opened/closed
     */
    shouldAnimate: PropTypes.bool,

    /**
     * Invoked when the modal is opened
     */
    onOpen: PropTypes.func,

    /**
     * Invoked when the modal is closed
     */
    handleClose: PropTypes.func,

    /**
     * Invoked when Enter key pressed
     */
    handleSubmit: PropTypes.func,

    /**
     * should the dialog be closed when the backdrop is pressed
     */
    closeOnBackdrop: PropTypes.bool,
    /**
     * name of modal to specify popup.
     */
    name: PropTypes.string, // eslint-disable-line react/no-unused-prop-types

    /**
     * name of the domain in case theres a domain oriented components.
     */
    domainContext: PropTypes.string,
    /**
     * Indicates whether a key-down listener will be disabled
     */
    disableKeyDown: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    // Bind methods
    this.onBackdrop = this.onBackdrop.bind(this);
    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.handleDocumentKeyUp = this.handleDocumentKeyUp.bind(this);
  }

  /**
   * Checks if the modal visibility was changed an trigger the right handler if needed
   * @param nextProps
   */
  componentWillReceiveProps(nextProps) {
    if (this.props.isOpen !== nextProps.isOpen) {
      if (nextProps.isOpen) {
        this.showModal();
      } else {
        this.hideModal();
      }
    }
  }

  /**
   * Make sure the modal is closed properly
   */
  componentWillUnmount() {
    this.hideModal();
  }

  /**
   * called when backdrop is clicked.
   */
  onBackdrop() {
    if (this.props.closeOnBackdrop) {
      this.props.handleClose();
    }
  }

  /**
   * Hide modal handler
   */
  hideModal() {
    const { disableKeyDown, isOpen, handleClose } = this.props;

    if (!disableKeyDown) {
      window.removeEventListener('keydown', this.handleDocumentKeyUp);
    }

    if (isOpen && handleClose) {
      handleClose();
    }
  }

  /**
   * Show modal handler
   */
  showModal() {
    const { disableKeyDown, onOpen } = this.props;

    if (!disableKeyDown) {
      window.addEventListener('keydown', this.handleDocumentKeyUp);
    }

    if (onOpen) {
      onOpen();
    }
  }

  /**
   * Unmount the key event
   * @param e
   */
  handleDocumentKeyUp(e) {
    if (e.keyCode === 13) {
      if (this.props.handleSubmit) this.props.handleSubmit();
    }
    if (e.keyCode === 27) {
      if (this.props.handleClose) this.props.handleClose();
    }
  }

  /**
   * Renders the component
   * @returns {XML}
   */
  render() {
    const { isOpen, className, baseClassName, shouldAnimate, domainContext } = this.props;
    return (
      <BaseModal
        show={isOpen}
        className={classNames('bkmd-modal', baseClassName, domainContext)}
        backdropClassName="dialog-backdrop"
        onBackdropClick={this.onBackdrop}
      >
        <div className={classNames('dialog', className, { fade: shouldAnimate })}>
          {this.props.children}
        </div>
      </BaseModal>
    );
  }
}

export default compose(
  defaultProps({
    onOpen: _.noop,
    handleClose: _.noop,
  }),
  component => analytics(component, true, [
    { event: 'onOpen' }, { event: 'handleClose' },
  ]),
  connect(state => ({
    domainContext: getDomainShortName(state),
  }))
)(BkmdModalComponent);
