import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { autobind } from 'core-decorators';
import reviewCompose from '../doctorReviewHOC';
import paths from '../routes/paths';
import DoctorReviewLayout from './doctorReviewLayout';
import ReviewStarRate from '../../../../../components/ui/starRating/reviewStarRate';
import TermsOfServices from '../../../../../components/termsOfServices/termsOfServices';
import DoctorLogo from '../../../../../components/doctorImage/doctorLogo';
import FeatureFlagged from '../../../../../components/features/featureFlagged';
import displayProvider from '../../../../../utils/displayProvider';
import { isPositive } from './utils';
import './doctorReview.less';

const messages = defineMessages({
  howWouldYouRateDr: {
    defaultMessage: 'How would you rate<br/>{providerDisplayName}?',
    id: 'members.doctorReview.howWouldYouRateDr',
  },
  next: {
    defaultMessage: 'Next',
    id: 'members.doctorReview.next',
  },
  byRatingThisDoctorYouAgreeToVims: {
    defaultMessage: "By rating this doctor you agree to Vim's",
    id: 'members.doctorReview.byRatingThisDoctorYouAgreeToVims',
  },
  and: {
    defaultMessage: 'and',
    id: 'members.doctorReview.and',
  },
});

@autobind
class DoctorStarRate extends React.PureComponent {
  static propTypes = {
    updateReview: PropTypes.func.isRequired,
    reviewProcess: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
    params: PropTypes.object.isRequired,
  };

  componentDidMount() {
    // Saving the time in which the user got to the page review
    this.props.updateReview({
      token: this.props.params.token,
      reviewDate: new Date(),
    });
  }

  onChange(rating) {
    const { reviewProcess } = this.props;
    const review = {
      token: this.props.params.token,
      rating,
    };

    // if the chosen rating is positive and the old one is negative we want to
    // clear the data from the negative fields.
    if (isPositive(rating) && !isPositive(reviewProcess.rating)) {
      review.negativeDoctor = null;
      review.negativeOffice = null;
    }

    // if the chosen rating is negative and the old one is positive we want to
    // clear the data from the positive fields.
    if (!isPositive(rating) && isPositive(reviewProcess.rating)) {
      review.positiveDoctor = null;
      review.positiveOffice = null;
    }

    this.props.updateReview(review);
  }

  onNext() {
    const {
      reviewProcess,
      router,
      params: { token },
    } = this.props;
    if (isPositive(reviewProcess.rating)) {
      router.push(paths.positiveDoctor(token));
    } else {
      router.push(paths.negativeDoctor(token));
    }
  }

  render() {
    const { reviewProcess } = this.props;
    const { provider } = reviewProcess;
    if (!provider) return <div />;
    const { suffix, firstName, lastName } = provider;
    const providerDisplayName = displayProvider(firstName, lastName, suffix);

    return (
      <DoctorReviewLayout headerClassName="no-padding">
        <div className="text-center padding-v-20">
          <DoctorLogo provider={provider} />
        </div>
        <div className="title-brand-1 title-24 text-center no-margin padding-h-30">
          <FormattedHTMLMessage {...messages.howWouldYouRateDr} values={{ providerDisplayName }} />
        </div>
        <div>
          <div className="row review-star-rate-wrap margin-top-30">
            <div className="col-xs-10 col-xs-offset-1 text-center">
              <ReviewStarRate
                className="wide orange"
                rateValue={reviewProcess.rating}
                onChange={this.onChange}
              />
            </div>
          </div>
        </div>
        <div className="row margin-top-30 padding-h-30">
          <div className="col-xs-12">
            <button
              className="btn btn-big bg-color-brand-button"
              onClick={this.onNext}
              disabled={!this.props.reviewProcess.rating}
            >
              <FormattedMessage {...messages.next} />
            </button>
          </div>
        </div>

        <FeatureFlagged uri="layout.showTermsOfService">
          <div className="row margin-top-20 padding-h-30">
            <div className="col-xs-12 text-center">
              <span className="text-12">
                <FormattedMessage {...messages.byRatingThisDoctorYouAgreeToVims} />
                <TermsOfServices isMember hideAgreeMessage />
              </span>
            </div>
          </div>
        </FeatureFlagged>
      </DoctorReviewLayout>
    );
  }
}

export default reviewCompose(DoctorStarRate);
