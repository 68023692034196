import _ from 'lodash';

const partialMatch = MIN_FILTER_LENGTH => (options, filter) => {
  // if filter is empty or is less then MIN_FILTER_LENGTH characters we don't want to search
  if (_.isEmpty(filter) || filter.length < MIN_FILTER_LENGTH) return options;

  // if the filter has more the one word, split it.
  const filterWords = _.split(filter, ' ');

  // we try to match each option to all of the words in the filter
  return _.chain(options)
    .map(option => {
      const label = _.toLower(option.label);
      let didntMatch = false;
      _.forEach(filterWords, word => {
        // if one of the words in the filter doesn't match any of the words in the options
        // we should consider it as failed match.
        if (!_.includes(label, _.toLower(word))) {
          didntMatch = true;
        }
      });
      return didntMatch ? null : option;
    })
    .compact()
    .value();
};

export default partialMatch;
