/**
 * Created by noahguld on 28/06/2017.
 */
import _ from 'lodash';

export const GET_FACILITIES = 'GET_FACILITIES';

export function getFacilities(facilityTypes) {
  return ({ bkmdApi: { facilitySearchApi } }) => ({
    type: GET_FACILITIES,
    payload: {
      promise: _.isEmpty(facilityTypes) ? Promise.resolve({}) :
        facilitySearchApi.getFacilities(facilityTypes)
    },
  });
}
