/**
 * created by moran on 11/26/17
 */
import PromiseActionType from '../promiseActionType';

export const GET_SHARE_BY_TOKEN = new PromiseActionType('GET_SHARE_BY_TOKEN');

export function getShareByToken(token) {
  return ({ bkmdApi: { shareApi } }) => ({
    type: GET_SHARE_BY_TOKEN.SOURCE,
    payload: {
      promise: shareApi.getShareByToken(token)
    },
    meta: {
      tracker: GET_SHARE_BY_TOKEN.SOURCE,
    }
  });
}

