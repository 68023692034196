const pcpSelectionFlowEvents = {
  modals: {
    closePCPModal: 'Close PCP modal',
    choosePCPFromPCPModal: 'Choose PCP from PCP Modal',
    laterFromPCPModal: 'Later from PCP modal',
  },
  flow: {
    chooseADoctorLater: 'Choose PCP later from specific step in the flow',
  },
  stepsEvents: {
    noResultsPage: {
      continueWithoutGenderClick: 'Click on continue without gender',
    },
    resultsPage: {
      choosePcpClick: 'Choose pcp Click (card or modal)',
      providerDetailsClick: 'Click on Provider Details',
      showMoreClick: 'Click on Show More',
    },
    loadingPage: {
      pcpSelectionPreferenceDetails: 'PCP selection preference request',
    },
    inviteYourFamilyPage: {
      applyForAllMyChildrenClick: 'Click on Apply for all my children',
    },
    successPage: {
      viewMedicalTeamClick: 'Click on View medical team from success page',
    },
  },
};

export default pcpSelectionFlowEvents;
