import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '../../storybook/src/utils/theme';

function SvgLocation2({ theme }) {
  /* eslint-disable */
  return (
    <svg aria-hidden="true" focusable="false" width="24px" height="32px" viewBox="0 0 24 32" version="1.1" className="svg-icon svg-location-2">
      <g id="Refer-by-specialty" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Send-list?" transform="translate(-738.000000, -361.000000)">
          <g id="Page-1" transform="translate(738.000000, 361.000000)">
            <path d="M12,2 C6.486,2 2,6.598 2,12.25 C2,18.969 9.642,27.212 12,29.597 C14.358,27.212 22,18.969 22,12.25 C22,6.598 17.514,2 12,2 M12,32 C11.754,32 11.509,31.91 11.316,31.729 C10.854,31.296 0,21.021 0,12.25 C0,5.495 5.383,0 12,0 C18.617,0 24,5.495 24,12.25 C24,21.021 13.146,31.296 12.684,31.729 C12.491,31.91 12.246,32 12,32" id="Fill-1" fill={theme.mainColor} ></path>
            <path d="M11.999,8 C9.794,8 8,9.795 8,12.001 C8,14.206 9.794,16 11.999,16 C14.205,16 16,14.206 16,12.001 C16,9.795 14.205,8 11.999,8 M11.999,18 C8.691,18 6,15.309 6,12.001 C6,8.692 8.691,6 11.999,6 C15.308,6 18,8.692 18,12.001 C18,15.309 15.308,18 11.999,18" id="Fill-3" fill={theme.secondaryColor} ></path>
          </g>
        </g>
      </g>
    </svg>
  );
  /* eslint-enable */
}

SvgLocation2.propTypes = {
  theme: PropTypes.object.isRequired,
};

export default withTheme(SvgLocation2);
