/**
 * Created by matan on 3/12/17.
 */
import PropTypes from 'prop-types';

export default PropTypes.oneOfType([
  PropTypes.shape({
    defaultMessage: PropTypes.string,
    id: PropTypes.string
  }),
  PropTypes.string,
]);
