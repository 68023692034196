/**
 * Created by chenrozenes on 24/09/2017.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import CleanSelect from './cleanSelect';
import BookMdUtil from '../../utils/util';

/**
 * CleanSelect wrap that gets its options from an enum placed in redux state.
 * Use the enumSelector property to get enum
 */
@autobind
class ReduxEnumSelect extends React.Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    enumSelector: PropTypes.func.isRequired, // eslint-disable-line react/no-unused-prop-types
  };

  onChange({ value }) {
    this.props.onChange(value);
  }

  render() {
    return (
      <CleanSelect {...this.props} onChange={this.onChange} />
    );
  }
}

export default connect((state, props) => ({
  options: BookMdUtil.toSelectOptions(props.enumSelector(state, props))
}))(ReduxEnumSelect);
