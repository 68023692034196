/**
 * created by moran on 11/26/17
 */
import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import { defineMessages } from 'react-intl';

import './cancelAppointment.less';
import ErrorPage from '../../../../components/pages/error/errorPage';
import { withFetchersOnMount } from '../../../../api/injectApi/withFetchersOnMount';
import Api from '../../../../api';
import { setAppointment } from '../../../../store/appointments/actions';
import { getAppointmentSelector } from '../../../../store/appointments/selectors';

const messages = defineMessages({
  errorScreenTitle: {
    defaultMessage: "We're having trouble",
    id: 'members.appointmentPage.errorTitleMessage',
  },
  errorScreenMessage: {
    defaultMessage: "We're sorry, we couldn't find your appointment.",
    id: 'members.appointmentPage.errorDescriptionMessage',
  },
});

const renderErrorPage = function renderErrorPage() {
  return (
    <ErrorPage
      titleMessage={messages.errorScreenTitle}
      descriptionMessage={messages.errorScreenMessage}
      displayError={false}
      displayButton={false}
    />
  );
};

@autobind
class AppointmentPage extends React.Component {
  static propTypes = {
    /* eslint-disable react/no-unused-prop-types */
    params: PropTypes.object.isRequired,
    appointment: PropTypes.object.isRequired,
    getAppointmentTracking: PropTypes.object.isRequired,
    /* eslint-enable react/no-unused-prop-types */
  };

  renderChildren() {
    return <div className="cancellation-page">{this.props.children}</div>;
  }

  render() {
    const { getAppointmentTracking } = this.props;
    if (_.get(getAppointmentTracking, 'hasError')) return renderErrorPage();

    return this.renderChildren();
  }
}

export default compose(
  connect(
    (state, { params }) => ({
      appointment: getAppointmentSelector(state, params.appointmentId),
    }),
    { setAppointment },
  ),
  withFetchersOnMount({
    getAppointment: {
      handler: ({ params }) => () => Api.appointmentApi.publicGetAppointment(params.appointmentId),
      onSuccess: ({ setAppointment }, result) => setAppointment(result),
      isReady: ({ appointment }) => !_.isEmpty(appointment),
    },
  }),
)(AppointmentPage);
