import fetchNotEmpty from '../tools/fetchNotEmpty';
import { getMember, getSettings } from './actions';

export const fetchMemberNotEmpty = (shouldReload = false) =>
  fetchNotEmpty(() => getMember(shouldReload), 'member.info.fullName', {
    renderAlways: false,
    loading: true,
  });

export const fetchSettingsNotEmpty = () =>
  fetchNotEmpty(getSettings, 'member.settings', { renderAlways: false, loading: true });
