/**
 * Created by galgoltzman on 21/11/2017.
 */
import React from 'react';

import Layout from '../layout/layout';
import DomainResources from './domainResources';
import './resources.less';

function Resources() {
  return (
    <Layout>
      <div className="container container-1040">
        <div className="row">
          <DomainResources className="col-sm-6 col-sm-offset-3" />
        </div>
      </div>
    </Layout>
  );
}

export default Resources;
