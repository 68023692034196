import React from 'react';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, defineMessages } from 'react-intl';
import { withRouter } from 'react-router';

import BkmdModal from '../../../../../components/bkmdModal/bkmdModal';
import ModalHeader from '../../../../../components/bkmdModal/modalHeader';
import SvgDoctorStars from '../../../../../assets/svg/doctorStars';
import { getPathsFromContext } from '../../routes/injectPathsToContext';
import { domainMemberIdSelector } from '../../../../../store/member/selectors';
import pcpSelectionEvents from '../../../../../store/tools/analytics/pcpSelection/pcpSelectionEvents';

import './pcpHowToModal.less';
import { getFeature } from '../../../../../store/feature/selectors';
import routingSelectors from '../../../../../store/routing/selectors';

const modalMessages = defineMessages({
  chooseYourPrimaryCareDoctorToday: {
    defaultMessage: 'Choose your Primary Care Doctor today',
    id: 'members.startSelectPcpModal.chooseYourPrimaryCareDoctorToday',
  },
  yourPrimaryCareDoctorKnowsYou: {
    defaultMessage:
      'Your Primary Care Doctor knows you and is the first place to start when you ' +
      'need care. Make sure you’re always ready by choosing a doctor you’ll love.',
    id: 'members.startSelectPcpModal.yourPrimaryCareDoctorKnowsYou',
  },
  chooseMyDoctor: {
    defaultMessage: 'Choose my doctor',
    id: 'members.startSelectPcpModal.chooseMyDoctor',
  },
  chooseYourDoctor: {
    defaultMessage: 'Choose your doctor',
    id: 'members.startSelectPcpModal.chooseYourDoctor',
  },
  doThisLater: {
    defaultMessage: 'Do this later',
    id: 'members.startSelectPcpModal.doThisLater',
  },
  youCanAlwaysSelect: {
    defaultMessage:
      'You can always select your Primary Care Doctor in Your medical team page. ' +
      'You can find it in the main menu under Doctors. ',
    id: 'pcp.PcpFamilyModal.youCanAlwaysSelect',
  },
  gotIt: {
    defaultMessage: 'Got it',
    id: 'pcp.PcpFamilyModal.gotIt',
  },
});

const topImg = require('./pcpHowTo.png');

@autobind
class StartPcpSelectionModal extends React.PureComponent {
  static propTypes = {
    /**
     * Injected prop for notifying on errors / success
     */
    isOpen: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    paths: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
    domainMemberId: PropTypes.string.isRequired,
  };

  static contextTypes = {
    analytics: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = { interrupted: false };
  }

  onChooseMyDoctor() {
    const { router, domainMemberId, pathName, publicPcpSelectionFeature } = this.props;
    if (publicPcpSelectionFeature) {
      router.push(`/publicPcpSelection?next=${pathName}`);
    }

    this.context.analytics.track(pcpSelectionEvents.modals.choosePCPFromPCPModal, {
      domainMemberId,
    });
    this.props.handleClose();
  }

  onDoItLater() {
    const { domainMemberId } = this.props;
    this.context.analytics.track(pcpSelectionEvents.modals.laterFromPCPModal, { domainMemberId });
    this.setState({ interrupted: true });
  }

  onModalClose() {
    const { domainMemberId, handleClose } = this.props;
    const { interrupted } = this.state;
    // This function is called when 'ESC' is pressed (among other use cases).
    // If 'interrupted' is true, it means we are on the how-to modal. We can exit.
    if (interrupted) {
      handleClose();
      return;
    }
    this.context.analytics.track(pcpSelectionEvents.modals.closePCPModal, { domainMemberId });
    this.setState({ interrupted: true });
  }

  renderPcpSelectionContent() {
    return (
      <div>
        <ModalHeader title={modalMessages.chooseYourDoctor} onClose={this.onModalClose} />
        <div className="dialog-body">
          <div className="text-center">
            <SvgDoctorStars />
          </div>
          <div className="text-center title-24 md-margin text-strong-dark">
            <FormattedMessage {...modalMessages.chooseYourPrimaryCareDoctorToday} />
          </div>
          <div className="text-center">
            <FormattedMessage {...modalMessages.yourPrimaryCareDoctorKnowsYou} />
          </div>
          <div className="margin-top-20 text-center">
            <button onClick={this.onChooseMyDoctor} className="btn btn-big bg-color-brand-button">
              <FormattedMessage {...modalMessages.chooseMyDoctor} />
            </button>
          </div>
          <div className="margin-top-30 text-center">
            <button
              onClick={this.onDoItLater}
              className="btn btn-inline-link text-14 text-dark text-semibold"
            >
              <FormattedMessage {...modalMessages.doThisLater} />
            </button>
          </div>
        </div>
      </div>
    );
  }

  renderHowToSelectPcpContent() {
    const { handleClose } = this.props;
    return (
      <div className="dialog-body">
        <div className="row">
          <div className="col-xs-12 text-center">
            <img src={topImg} className="how-to-img" alt="" />
          </div>
        </div>
        <div className="row margin-top-20">
          <div className="col-xs-12">
            <div className="text-16 text-center">
              <FormattedMessage {...modalMessages.youCanAlwaysSelect} />
            </div>
          </div>
        </div>
        <div className="row margin-top-20">
          <div className="col-xs-12">
            <button className="btn btn-big bg-color-brand-button" onClick={handleClose}>
              <FormattedMessage {...modalMessages.gotIt} />
            </button>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { isOpen } = this.props;
    const { interrupted } = this.state;
    const className = interrupted
      ? 'pcp-how-to-modal md-modal'
      : 'pcp-reminder-modal md-modal fixed-header';

    return (
      <BkmdModal
        closeOnBackdrop={interrupted}
        handleClose={this.onModalClose}
        isOpen={isOpen}
        className={className}
      >
        {interrupted ? this.renderHowToSelectPcpContent() : this.renderPcpSelectionContent()}
      </BkmdModal>
    );
  }
}

export default compose(
  withRouter,
  getPathsFromContext(),
  connect(state => ({
    domainMemberId: domainMemberIdSelector(state),
    publicPcpSelectionFeature: getFeature(state, 'publicPcpSelection'),
    pathName: routingSelectors.getCurrentUrl(state),
  })),
)(StartPcpSelectionModal);
