/**
 * Created by galgoltzman on 06/06/2017.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import { defineMessages, FormattedMessage } from 'react-intl';
import { Carousel } from 'react-bootstrap';

import Layout from '../layout/layout';
import { getPathsFromContext } from '../routes/injectPathsToContext';

import './InsuranceId.less';

const messages = defineMessages({
  stayInNetwork: {
    defaultMessage: 'Stay in network',
    id: 'members.addInsuranceId.stayInNetwork',
  },
  enterYourInfoToEnsureYouOnlySeeDoctors: {
    defaultMessage: 'Enter your info to ensure you only see doctors who accept your insurance.',
    id: 'members.addInsuranceId.enterYourInfoToEnsureYouOnlySeeDoctors',
  },
  knowYourDeductible: {
    defaultMessage: 'Know your deductible',
    id: 'members.addInsuranceId.knowYourDeductible',
  },
  alwaysKnowWhatYouOweTowardYourDeductible: {
    defaultMessage: 'Always know what you owe toward your deductible + max-out-of-pocket.',
    id: 'members.addInsuranceId.alwaysKnowWhatYouOweTowardYourDeductible',
  },
  keepUpWithCare: {
    defaultMessage: 'Keep up with care',
    id: 'members.addInsuranceId.keepUpWithCare',
  },
  findOutWhatsCoveredByYourPlan: {
    defaultMessage: 'Find out what’s covered by your plan and stay on top of your care.',
    id: 'members.addInsuranceId.findOutWhatsCoveredByYourPlan',
  },
  enterYourInsuranceInfo: {
    defaultMessage: 'Enter your insurance info',
    id: 'members.addInsuranceId.enterYourInsuranceInfo',
  },
});

class InsuranceIdSlider extends React.PureComponent {
  static propTypes = {
    location: PropTypes.object.isRequired,
    paths: PropTypes.object.isRequired,
  };

  render() {
    return (
      <Layout hideSearchLink>
        <div className="insurance-id-info-wrap padding-box-30">
          <Carousel className="insurance-id-info-slider" controls={false} interval={3000}>
            <Carousel.Item>
              <img src={require('./images/slide-1.png')} alt="" />
              <div className="title-24 text-strong-main margin-10">
                <FormattedMessage {...messages.stayInNetwork} />
              </div>
              <div className="text-18 text-light">
                <FormattedMessage {...messages.enterYourInfoToEnsureYouOnlySeeDoctors} />
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <img src={require('./images/slide-2.png')} alt="" />
              <div className="title-24 text-strong-main margin-10">
                <FormattedMessage {...messages.knowYourDeductible} />
              </div>
              <div className="text-18 text-light">
                <FormattedMessage {...messages.alwaysKnowWhatYouOweTowardYourDeductible} />
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <img src={require('./images/slide-3.png')} alt="" />
              <div className="title-24 text-strong-main margin-10">
                <FormattedMessage {...messages.keepUpWithCare} />
              </div>
              <div className="text-18 text-light">
                <FormattedMessage {...messages.findOutWhatsCoveredByYourPlan} />
              </div>
            </Carousel.Item>
          </Carousel>

          <div className="margin-top-20">
            <Link
              to={this.props.paths.enterInsuranceID(this.props.location.query)}
              className="btn btn-big"
            >
              <FormattedMessage {...messages.enterYourInsuranceInfo} />
            </Link>
          </div>
        </div>
      </Layout>
    );
  }
}

export default getPathsFromContext(InsuranceIdSlider);
