import OpenDirectoryRoutes from '../openDirectoryRoutes';

class OpenDirectoryRouteStrategy {
  static predicate({ features }) {
    return features.openDirectory;
  }

  static getRoutes() {
    return [...OpenDirectoryRoutes];
  }
}

export default OpenDirectoryRouteStrategy;
