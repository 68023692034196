import { mapProps } from 'recompose';
import _ from 'lodash';

/**
 * Simple util that receive array of props and remove them from the component
 * It's just an alias to mapProps with _.omit to improve the readability of the code :)
 * @param propsToRemove
 * @returns {*}
 * @private
 */
export const removeProps = propsToRemove => mapProps(props => _.omit(props, propsToRemove));
