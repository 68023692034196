/**
 * Created by matan on 3/15/17.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, FormattedMessage } from 'react-intl';
import { Link } from 'react-router';
import { compose, pure } from 'recompose';
import _ from 'lodash';

import BkmdModal from '../../../../../components/bkmdModal/bkmdModal';
import ModalHeader from '../../../../../components/bkmdModal/modalHeader';
import SvgHelpStars from '../../../../../assets/svg/helpStars';
import queryConnect from '../../../../../store/tools/queryString/queryConnect';

const messages = defineMessages({
  title: {
    defaultMessage: 'Your account',
    id: 'activation.alreadyActivated.title',
  },
  subtitle1: {
    defaultMessage: "Good news, you're already activated",
    id: 'activation.alreadyActivated.subtitle1',
  },
  mainText1: {
    defaultMessage:
      "It appears you've done this before. You already have an account. " +
      'Please go ahead and sign in.',
    id: 'activation.alreadyActivated.mainText1',
  },
  signIn: {
    defaultMessage: 'Sign in',
    id: 'activation.alreadyActivated.signIn',
  },
  iNeedHelpSigningIn: {
    defaultMessage: 'I need help signing in',
    id: 'activation.alreadyActivated.iNeedHelpSigningIn',
  },
});

function renderSection(title, text) {
  return (
    <div>
      <div className="row">
        <div className="col-xs-12 activation-modal-subtitle">
          <span className="text-18 text-semibold text-dark">
            <FormattedMessage {...title} />
          </span>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 text-16">
          <FormattedMessage {...text} />
        </div>
      </div>
    </div>
  );
}

function AlreadyActivatedModal(props) {
  let linkTo = '/';
  if (props.next) {
    linkTo = `${linkTo}?next=${props.next}`;
  }

  return (
    <BkmdModal {...props} className="fixed-header">
      <ModalHeader onClose={props.handleClose} title={messages.title} />

      <div className="dialog-body">
        <div className="row">
          <div className="col-xs-12 text-center">
            <SvgHelpStars />
          </div>
        </div>
        {renderSection(messages.subtitle1, messages.mainText1)}
        <div className="row padding-v-30">
          <div className="col-xs-12">
            <Link to={linkTo} className="btn btn-big bg-color-brand-button">
              <FormattedMessage {...messages.signIn} />
            </Link>
          </div>
        </div>
      </div>
    </BkmdModal>
  );
}

AlreadyActivatedModal.propTypes = {
  /**
   * Hook method.
   * Called whenever the modal should be closed
   */
  handleClose: PropTypes.func.isRequired,
  /**
   * Name for identifying via analytics
   */
  name: PropTypes.string, // eslint-disable-line react/no-unused-prop-types
  /**
   * the next url we should fo to after activating
   */
  next: PropTypes.string,
};

AlreadyActivatedModal.defaultProps = {
  name: 'already_activated_modal',
  next: '',
};

export default compose(
  pure,
  queryConnect(query => ({
    next: _.get(query, 'next', '/secure?first=true'),
  })),
)(AlreadyActivatedModal);
