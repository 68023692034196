import BkmdApi from './bkmdApi';
import ServicesApi from './services';

const SERVICE_NAME = 'tokenManager';

export default class TokenManagerApi extends BkmdApi {
  constructor(fetch) {
    super(fetch, '/tokens');
    this.servicesApi = new ServicesApi(fetch);
  }

  generateToken({ value, source, tokenExpiresAfter }) {
    return this.servicesApi.callSecure(SERVICE_NAME, 'generateToken', {
      value,
      source,
      tokenExpiresAfter,
    });
  }
}
