/**
 * Created by galgoltzman on 26/11/2017.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router';
import { autobind } from 'core-decorators';

import SvgWavingHand from '../../../../../assets/svg/wavingHand';
import SpinnerButton from '../../../../../components/ui/spinner/spinnerButton';

import '../activation.less';

const messages = defineMessages({
  hello: {
    defaultMessage: 'Hello {name}',
    id: 'members.activation.hello',
  },
  welcomeToTheMemberPortal: {
    defaultMessage:
      'Welcome to the member portal where you ' +
      'can explore the doctors in our network. Create an account for easy scheduling.',
    id: 'members.activation.welcomeToTheMemberPortal',
  },
  letsGetYouSetUp: {
    defaultMessage: 'Let’s get you set up',
    id: 'members.activation.letsGetYouSetUp',
  },
  continueAsAGuest: {
    defaultMessage: 'Continue as a guest',
    id: 'members.activation.continueAsAGuest',
  },
});

@autobind
class FidelityWelcome extends React.Component {
  static propTypes = {
    firstName: PropTypes.string.isRequired,
    onSubmit: PropTypes.func.isRequired,
    /**
     * Router object.
     * Provided by the withRouter HoC
     */
    router: PropTypes.object.isRequired,
  };

  routeToOpenDirectory() {
    this.props.router.replace('/public');
  }

  render() {
    return (
      <form className="activation-welcome-wrap" onSubmit={this.props.onSubmit} method="post">
        <div className="top-section">
          <div className="row">
            <div className="col-xs-12 text-center">
              <SvgWavingHand />
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 text-center">
              <h1 className="title-24 md-margin title-brand-1">
                <FormattedMessage {...messages.hello} values={{ name: this.props.firstName }} />
              </h1>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12">
              <div className="text-16 text-center">
                <FormattedMessage {...messages.welcomeToTheMemberPortal} />
              </div>
            </div>
          </div>
        </div>
        <div className="row margin-top-30">
          <div className="col-xs-12">
            <SpinnerButton className="btn btn-big bg-color-brand-button" type="submit">
              <FormattedMessage {...messages.letsGetYouSetUp} />
            </SpinnerButton>
          </div>
        </div>
        <div className="row margin-top-30">
          <div className="col-xs-12 text-center">
            <button
              className="btn btn-inline-link font-color-brand-link"
              onClick={this.routeToOpenDirectory}
            >
              <FormattedMessage {...messages.continueAsAGuest} />
            </button>
          </div>
        </div>
      </form>
    );
  }
}

export default withRouter(FidelityWelcome);
