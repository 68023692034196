import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const ModalCloseButton = props => (
  <button
    className={classNames('btn btn-transparent help-btn-x icon-x-2', props.className)}
    onClick={props.handleClose}
    aria-label="Close"
  />
);

ModalCloseButton.propTypes = {
  handleClose: PropTypes.func.isRequired
};

export default ModalCloseButton;
