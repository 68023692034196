/**
 * Read the next from url query string and route there
 * @param router
 * @param query from router location
 * @param defaultLocation route to go to if there is no next param
 * @param action router action, can be push or replace
 */
export function goToNext(router, query, defaultLocation, action = 'push') {
  const qs = query.qs ? `?${decodeURIComponent(query.qs)}` : '';
  const next = query.next ? `${query.next}${qs}` : defaultLocation;
  router[action](next);
}
