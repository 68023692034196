import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import ReactPlayer from 'react-player';
import { BkmdModal, ModalHeader } from '../../components/bkmdModal/index';
import './colorectalVideoModal.less';
import { createToggleState } from '../../components/recompose/toggleStateComponent';

const videoUrl =
  'https://embedwistia-a.akamaihd.net/deliveries/767a9b7c5b322338a913993b4827c0df2736802e/file.mp4';

const ColorectalVideoModal = props => {
  const { handleClose, autoplay, ...other } = props;
  return (
    <BkmdModal
      className="colorectal-video-modal fullscreen-modal fixed-header"
      handleClose={handleClose}
      {...other}
    >
      <ModalHeader onClose={handleClose} />
      <div className="dialog-body no-padding">
        <ReactPlayer
          url={videoUrl}
          playing={autoplay}
          width="100%"
          height="auto"
          className="video-player"
          controls
        />
      </div>
    </BkmdModal>
  );
};

ColorectalVideoModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  autoplay: PropTypes.bool.isRequired,
};

const withColrectalVideoModal = compose(
  createToggleState('videoModal'),
  WrappedComponent =>
    class Wrapper extends React.PureComponent {

      static propTypes = {
        videoModalHide: PropTypes.func.isRequired,
        videoModalOn: PropTypes.bool.isRequired,
      };

      render() {
        const { videoModalHide, videoModalOn, ...other } = this.props;
        return (
          <div>
            <WrappedComponent {...other} />
            <ColorectalVideoModal isOpen={videoModalOn} handleClose={videoModalHide} autoplay />
          </div>
        );
      }
    },
);

export default withColrectalVideoModal;
