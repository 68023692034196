import { pathsWithQuery } from '../../../../utils/pathsWithQuery';

export const createPathFunction = function createPathFunction(path) {
  return qsParams => pathsWithQuery(path, qsParams);
};
export const paths = {
  settingsHome: createPathFunction('/secure/settings'),
  profile: createPathFunction('/secure/settings/profile'),
  locations: createPathFunction('/secure/settings/locations'),
  notifications: createPathFunction('/secure/settings/notifications'),
  homeAddress: createPathFunction('/secure/settings/homeAddress'),
  workAddress: createPathFunction('/secure/settings/workAddress'),
  updatePhoneNumber: createPathFunction('/secure/settings/updatePhoneNumber'),
  updateEmail: createPathFunction('/secure/settings/updateEmail'),
  updatePassword: createPathFunction('/secure/settings/updatePassword'),
};
