/**
 * Created by kobimizrahi on 04/05/2019.
 */
import React from 'react';
import {
  compose,
  setPropTypes,
  defaultProps,
  withHandlers,
  pure,
  withPropsOnChange,
  setDisplayName,
} from 'recompose';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { intlShape, injectIntl } from 'react-intl';
import moment from 'moment-timezone';
import TimePicker from 'rc-time-picker';
import './cleanTimeInputComponent.less';
import CleanInput from '../cleanInput';
import CommonFormats from '../../../utils/formatter/commonFormats';
import Formatter from '../../../utils/formatter';
import commonMessages from '../../../store/intl/commonMessages';

const TIME_FORMAT = CommonFormats.HOURS_24_TIME;
const TIME_DISPLAY_FORMAT = CommonFormats.HOURS_12_TIME_FULL;

const formatTimeToSend = time => moment(time).format(TIME_FORMAT);

function CleanTimeInputComponent(props) {
  const {
    intl,
    intlTime,
    errorText,
    time,
    timeZone,
    timeZoneShort,
    timeZoneDisplayText,
    onTimeChange,
    allowEmpty,
    disabled,
  } = props;
  const timePlaceholder = intl.formatMessage(intlTime);

  return (
    <div className="clean-time-input-component">
      <div>
        <div className="error-wrap">
          <TimePicker
            showSecond={false}
            value={time}
            className="vim-time-picker"
            onChange={onTimeChange}
            format={TIME_DISPLAY_FORMAT}
            placeholder={timePlaceholder}
            allowEmpty={allowEmpty}
            use12Hours
            disabled={disabled}
          />
          <span className="validation-message">{errorText}</span>
        </div>
      </div>
      {timeZone && (
        <div className="text-center">
          <OverlayTrigger
            overlay={<Tooltip id="timezone.tooltip">{timeZoneDisplayText}</Tooltip>}
            placement="top"
          >
            <CleanInput
              className="center-text readonly"
              label={'timezone'}
              value={timeZoneShort}
              disabled
            />
          </OverlayTrigger>
        </div>
      )}
    </div>
  );
}

CleanTimeInputComponent.propTypes = {
  intl: intlShape.isRequired,
  intlTime: PropTypes.object,
  time: PropTypes.string,
  timeZone: PropTypes.string,
  timeZoneShort: PropTypes.string,
  timeZoneDisplayText: PropTypes.string,
  onTimeChange: PropTypes.func,
  errorText: PropTypes.string,
  allowEmpty: PropTypes.bool,
  disabled: PropTypes.bool,
};

CleanTimeInputComponent.defaultProps = {
  intlTime: commonMessages.time,
  timeZone: undefined,
  time: '',
  timeZoneShort: '',
  timeZoneDisplayText: '',
  onTimeChange: _.noop(),
  errorText: undefined,
  allowEmpty: false,
  disabled: false,
};

const parseTime = props => {
  const { value, timeZone } = props;

  if (value) {
    const newValue = moment(value, TIME_DISPLAY_FORMAT);
    const timeZonedValue = moment.tz(value, TIME_FORMAT, true, timeZone);
    const timeZoneShort = Formatter.timezone(timeZonedValue);
    const timeZoneDisplayText =
      timeZone && timeZoneShort ? timeZone.replace('_', ' ').replace('/', ' / ') : timeZoneShort;

    return { time: newValue, timeZoneDisplayText, timeZoneShort };
  }

  return {};
};

const enhance = compose(
  setDisplayName('CleanTimeInputComponent'),
  setPropTypes({
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string,
    timeZone: PropTypes.string,
  }),
  defaultProps({
    value: '',
    timeZone: undefined,
  }),
  injectIntl,
  pure,
  withPropsOnChange(['value'], ownerProps => ({
    ...parseTime(ownerProps),
  })),
  withHandlers({
    onTimeChange: props => time => {
      const { onChange } = props;
      const timeToSend = formatTimeToSend(time);
      onChange(timeToSend);
    },
  }),
);

export default enhance(CleanTimeInputComponent);
