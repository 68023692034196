import { autobind } from 'core-decorators';
import BkmdApi from './bkmdApi';
import ServicesApi from './services';

const SERVICE_NAME = 'clinicResource';

@autobind
class ResourceApi extends BkmdApi {
  /**
   * A fetch object that points to the server
   * @param fetch
   */
  constructor(fetch) {
    super(fetch, '/clinicResource/');
    this.servicesApi = new ServicesApi(fetch);
  }

  /**
   * Common section
   */
  getResourceById(id) {
    return this.servicesApi.callSecure(SERVICE_NAME, 'getResourceById', { id });
  }

  updateResource(data) {
    return this.servicesApi.callSecure(SERVICE_NAME, 'updateResource', { data });
  }

  /**
   * Provider resource section
   */
  getAllProviderResourcesByClinic(skip, limit, search, sort, sortAscending) {
    return this.servicesApi.callSecure(SERVICE_NAME, 'getAllClinicProviders', {
      limit,
      skip,
      search,
      sort,
      sortAscending,
    });
  }

  getProviderResourceByNpi(npi) {
    return this.servicesApi.callSecure(SERVICE_NAME, 'getProviderResourceByNpi', { npi });
  }

  deleteProviderResource(id) {
    return this.servicesApi.callSecure(SERVICE_NAME, 'deleteProviderResource', { id });
  }

  createProviderResource(data) {
    return this.servicesApi.callSecure(SERVICE_NAME, 'createProviderResource', { data });
  }

  /**
   * Machine Resource section
   */
  createMachineResource(data) {
    return this.servicesApi.callSecure(SERVICE_NAME, 'createMachineResource', { data });
  }

  deleteMachineResource(machineResourceId) {
    return this.servicesApi.callSecure(SERVICE_NAME, 'deleteMachineResource', {
      id: machineResourceId,
    });
  }

  getAllMachinesResourcesByClinic(skip, limit, search, sort, sortAscending) {
    return this.servicesApi.callSecure(SERVICE_NAME, 'getAllClinicMachines', {
      limit,
      skip,
      search,
      sort,
      sortAscending,
    });
  }

  /**
   * Appointment types section
   */
  getAppointmentType(id) {
    return this.servicesApi.callSecure(SERVICE_NAME, 'getAppointmentType', { id });
  }

  getAppointmentTypesList(aResourceId, includeSoftDeleted) {
    return this.servicesApi.callSecure(SERVICE_NAME, 'getAppointmentTypeList', {
      aResourceId,
      includeSoftDeleted,
    });
  }

  createAppointmentType(data) {
    return this.servicesApi.callSecure(SERVICE_NAME, 'createAppointmentType', { data });
  }

  createProviderResourceBulkAppointmentType(data) {
    return this.servicesApi.callSecure(SERVICE_NAME, 'createBulkAppointmentType', { data });
  }

  updateProviderResourceAppointmentType(data) {
    return this.servicesApi.callSecure(SERVICE_NAME, 'updateAppointmentType', { data });
  }

  deleteAppointmentType(id) {
    return this.servicesApi.callSecure(SERVICE_NAME, 'deleteAppointmentType', { id });
  }
}

export default ResourceApi;
