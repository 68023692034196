/**
 * Created by ofirhartuv on 10/26/17.
 */
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { autobind } from 'core-decorators';
import { FormattedMessage } from 'react-intl';

@autobind
class ProffessionalSectionForMultipleValues extends React.Component {
  static propTypes = {
    dataArray: PropTypes.array,
    message: PropTypes.object,
  };

  static defaultProps = {
    message: '',
    dataArray: [],
  };

  renderValues(dataArray) {
    return (
      <div className="text-18 no-margin text-semibold text-dark">
        <ul>
          {_.map(dataArray, value => (<li className="no-margin" key={value}>{value}</li>))}
        </ul>
      </div>);
  }

  render() {
    const { dataArray, message } = this.props;
    if (_.isNil(dataArray) || _.isEmpty(dataArray)) return (<div></div>);

    return (<div className="pro-section">
      <div className="text-18 text-light">
        <FormattedMessage {...message} />
      </div>
      <div className="title-24 no-margin text-semibold text-dark">
        {this.renderValues(dataArray)}
      </div>
      <div className="section-icon">
        {this.props.children}
      </div>
    </div>);
  }
}

export default ProffessionalSectionForMultipleValues;
