/**
 * Created by moran on 6/8/17.
 */
import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import classNames from 'classnames';

import { domain, domainShortName } from '../../store/config/selectors';
import { getLogoByDomain } from './logos';
import * as BkmdDomain from '../../model/enum/bkmdDomain';

@autobind
class CarrierLogo extends React.Component {
  static propTypes = {
    domain: PropTypes.string,
    shortName: PropTypes.string,
    externalPageLogo: PropTypes.bool,
    bookmdBranded: PropTypes.bool,
    vimLogoClassName: PropTypes.string,
    memberContext: PropTypes.string,
  };

  static defaultProps = {
    domain: BkmdDomain.PREMERA_BLUE_CROSS_ALASKA,
    shortName: 'premera',
    externalPageLogo: false,
    bookmdBranded: false,
    vimLogoClassName: '',
    memberContext: undefined,
  };

  renderVim(bookmdBranded, vimLogoClassName) {
    if (!bookmdBranded) return null;
    return (
      <span>
        <span className="plus">+</span>
        <img
          src={require('../../assets/images/logos/vimLogoMdTM.png')}
          className={vimLogoClassName}
          alt="vim logo"
        />
      </span>
    );
  }

  renderWithUrl(logo) {
    const { shortName, externalPageLogo, bookmdBranded, vimLogoClassName } = this.props;
    return (
      <div className={classNames('logos', shortName, { externalPageLogo })}>
        <img src={logo} className={shortName} alt={`${shortName} logo`} />
        {this.renderVim(bookmdBranded, vimLogoClassName)}
      </div>
    );
  }

  renderWithComponent(Logo) {
    const {
      shortName,
      externalPageLogo,
      bookmdBranded,
      vimLogoClassName,
      memberContext,
    } = this.props;
    return (
      <div className={classNames('logos', shortName, { externalPageLogo })}>
        {/* If we wants vim logo to be rendered as well - we have render it in the same cycle */}
        <Logo
          renderAfter={() => this.renderVim(bookmdBranded, vimLogoClassName)}
          memberContext={memberContext}
        />
      </div>
    );
  }

  render() {
    const { domain, externalPageLogo } = this.props;
    const logo = getLogoByDomain(domain, externalPageLogo);
    if (!logo) return null;
    if (_.isString(logo)) return this.renderWithUrl(logo);
    return this.renderWithComponent(logo);
  }
}

export default connect(state => ({
  domain: domain(state),
  shortName: domainShortName(state),
}))(CarrierLogo);
