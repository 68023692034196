import React from 'react';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import { FormattedMessage, defineMessages } from 'react-intl';
import { Dropdown, MenuItem } from 'react-bootstrap';
import _ from 'lodash';
import IntlString from '../ui/intlString/intlString';
import messageShape from '../ui/intlString/messageShape';
import { generateUUID } from '../../utils/uuid';

import './countHeader.less';

const DropdownMenu = Dropdown.Menu;
const messages = defineMessages({
  of: {
    defaultMessage: 'of',
    id: 'providers.components.grid.countHeader.of'
  },
  view: {
    defaultMessage: 'View:',
    id: 'providers.components.grid.countHeader.view'
  },
  rowsPerPage: {
    defaultMessage: 'Rows per page:',
    id: 'providers.components.grid.countHeader.rowsPerPage'
  }
});

// This is to avoid react warning about adding unknown props to a div
// But we need to have it there for the Dropdown component above it
// Dropdown component needs 'bsRole' on the component and adds 'bsClass' on the component
// eslint-disable-next-line no-unused-vars, react/prop-types
const NoBsDiv = ({ bsRole, bsClass, ...props }) => <div {...props} />;

/**
 * Header for BkmdGrid component that displays "1 of ${data.length}"
 */
@autobind
class CountHeader extends React.PureComponent {
  static propTypes = {
    totalCount: PropTypes.number.isRequired,
    showRowsPerPage: PropTypes.bool,
    onLimitChange: PropTypes.func,
    onViewChange: PropTypes.func,
    availableRowsPerPage: PropTypes.arrayOf(PropTypes.number),
    title: messageShape, // Optional title for the grid
    limit: PropTypes.number,
    skip: PropTypes.number,
    dropdownId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    viewOptions: PropTypes.arrayOf(
      PropTypes.shape({ // eslint-disable-line react/no-unused-prop-types
        id: PropTypes.string.isRequired, // eslint-disable-line react/no-unused-prop-types
        label: messageShape.isRequired, // eslint-disable-line react/no-unused-prop-types
      }).isRequired // eslint-disable react/no-unused-prop-types
    ),
    view: PropTypes.string,
    headerCustomComponent: PropTypes.node,
  };

  static defaultProps = {
    title: '',
    showRowsPerPage: true,
    availableRowsPerPage: [5, 10, 15, 20],
    limit: 10,
    skip: 1,
    onLimitChange: _.noop,
    onViewChange: _.noop,
    dropdownId: generateUUID(),
    viewOptions: [],
    view: '',
    headerCustomComponent: null,
  };

  onChange(value) {
    this.props.onLimitChange(value);
  }

  onViewChange(value) {
    this.props.onViewChange(value);
  }

  renderMenuItems() {
    const items = [];
    _.each(this.props.availableRowsPerPage, val => {
      items.push(<MenuItem key={val} eventKey={val}>{val}</MenuItem>);
    });
    return items;
  }

  renderViewDropdown() {
    const { viewOptions, view } = this.props;
    const selectedMessage = _.get(_.find(viewOptions, vo => vo.id === view), 'label');

    return viewOptions.length === 0 ? null : (
      <Dropdown onSelect={this.onViewChange} id="grid-view-mode-dropdown" bsStyle="">
        <NoBsDiv className="grid-count-dropdown" bsRole="toggle">
          <FormattedMessage {...messages.view} />&nbsp;
          <FormattedMessage {...selectedMessage} />
          &nbsp;
          <i className="icon-chevron-down" />
        </NoBsDiv>

        <DropdownMenu>
          {_.map(viewOptions, ({ id, label }) => (
            <MenuItem key={id} eventKey={id}><FormattedMessage {...label} /></MenuItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    );
  }

  render() {
    const {
      totalCount,
      title,
      showRowsPerPage,
      skip,
      limit,
      dropdownId,
      headerCustomComponent,
    } = this.props;

    const startingIndex = skip + 1;

    let endingIndex = startingIndex + limit;
    if (endingIndex > totalCount) {
      endingIndex = totalCount;
    }

    return (
      <div className="bkmd-table-count-header">
        <div className="row">
          <div className="col-xs-4">
            {totalCount > 0 ?
              (<span className="pull-left">
                <IntlString message={title} />
                &nbsp;
                {startingIndex}-{endingIndex}
                &nbsp;
                <FormattedMessage {...messages.of} />
                &nbsp;
                {totalCount}
              </span>) : (<span className="pull-left">
                <IntlString message={title} />
              </span>)}
          </div>
          {!showRowsPerPage ? null :
          <div className="col-xs-8 pull-right">
            <span className="pull-right">
              <Dropdown
                onSelect={this.onChange}
                id={dropdownId}
                bsStyle=""
              >
                <NoBsDiv className="grid-count-dropdown" bsRole="toggle">
                  <FormattedMessage {...messages.rowsPerPage} />&nbsp;
                  {limit}
                  &nbsp;
                  <i className="icon-chevron-down" />
                </NoBsDiv>

                <DropdownMenu>
                  {this.renderMenuItems()}
                </DropdownMenu>
              </Dropdown>
            </span>

            <span className="pull-right view-container">
              {this.renderViewDropdown()}
            </span>
            <span className="pull-right">
              { headerCustomComponent }
            </span>
          </div>
          }
        </div>
      </div>
    );
  }
}

export default CountHeader;
