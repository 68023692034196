import React from 'react';
import classNames from 'classnames';
import './baseLayout.less';

function BaseLayout({ children, className }) {
  return (
    <div className={classNames('base-layout', className)}>
      {children[0]}
      <div className="base-layout-content">{children[1]}</div>
      {children[2]}
    </div>
  );
}

export default BaseLayout;
