import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import CleanSelect from '../form/cleanSelect';
import Utils from '../../utils/util';

@autobind
class LocationSelectByNpi extends React.Component {
  static defaultProps = {
    npi: '',
    findLocations: _.noop,
    value: null,
  };

  static propTypes = {
    value: PropTypes.number,
    locations: PropTypes.array.isRequired,
    findLocations: PropTypes.func,
    npi: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    getLocationOptions: PropTypes.func.isRequired,
  };

  componentWillMount() {
    this.props.findLocations(this.props.npi);
  }

  componentWillReceiveProps(nextProps) {
    Utils.inspectChange(this.props, nextProps, [
      { uri: 'npi', action: nextProps => this.props.findLocations(nextProps.npi) },
    ]);
  }

  onChange(option) {
    this.props.onChange(option ? { id: option.value, timezone: option.timezone } : null);
  }

  render() {
    return (
      <CleanSelect
        {...this.props}
        value={this.props.value}
        options={this.props.getLocationOptions(this.props.locations)}
        onChange={this.onChange}
      />
    );
  }
}

export default LocationSelectByNpi;
