/* eslint-disable import/prefer-default-export */
/**
 * Created by chenrozenes on 18/01/2017.
 */

import _ from 'lodash';

/**
 * Gets a property from Podrick state
 * @param state
 * @param key
 * @param prop
 * @param defaultVal
 * @returns {*}
 */
export function getFromState(state, key, prop, defaultVal) {
  return _.get(state, ['podrick', key, prop], defaultVal);
}
/* eslint-enable import/prefer-default-export */
