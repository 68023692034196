import React from 'react';
import PropTypes from 'prop-types';
import BkmdDomainsEnum, { VIM } from '../../../model/enum/bkmdDomain';

/**
 * Transforms from bkmdDomainKey to domain message
 */
export default function DomainColumn({ value }) {
  value = value || VIM;
  return <span>{BkmdDomainsEnum.getMessageByValue(value).defaultMessage}</span>;
}

DomainColumn.propTypes = {
  value: PropTypes.string,
};

DomainColumn.defaultProps = {
  value: VIM,
};
