import SelectPCP from '../steps/selectPCP';
import ReviewSelectedPCP from '../steps/reviewSelectedPCP';

export const name = 'pcpSelectionFlow';

export const steps = {
  SELECT_PCP: 'selectPCP',
  REVIEW_SELECTION: 'reviewSelection',
};

export const flow = {
  [steps.SELECT_PCP]: {
    component: SelectPCP,
    transitions: {
      NEXT: steps.REVIEW_SELECTION,
    },
    mapPayloadToFlowData: ({ provider, member, selectedLocation }) => ({
      provider,
      member,
      selectedLocation,
    }),
  },
  [steps.REVIEW_SELECTION]: {
    component: ReviewSelectedPCP,
    transitions: {
      PREV: steps.SELECT_PCP,
    },
    mapFlowDataToProps: ({ provider, selectedLocation, member, token }) => ({
      provider,
      selectedLocation,
      member,
      token,
    }),
  },
};

export const initialStepName = steps.SELECT_PCP;
