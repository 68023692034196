import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '../../../storybook/src/utils/theme';

function SvgAdultFemaleAvatar({ theme }) {
  /* eslint-disable */
  return (
    <svg aria-hidden="true" focusable="false" width="71px" height="71px" viewBox="0 0 71 71" version="1.1" className="svg-icon svg-adult-female-avatar">
      <defs>
        <polygon points="0 53.0000074 0 0 35.49975 0 70.9995 0 70.9995 53.0000074"></polygon>
      </defs>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-1.000000, -1.000000)">
          <g transform="translate(1.000000, 1.000000)">
            <g>
              <g></g>
              <path d="M65.4775,52.9999926 C65.2375,52.9999926 64.9955,52.9419926 64.7695,52.8209926 C64.0395,52.4299926 63.7645,51.5209928 64.1575,50.7909929 C66.6705,46.1049935 67.9995,40.8179943 67.9995,35.499995 C67.9995,17.5789975 53.4205,2.99999958 35.4995,2.99999958 C17.5795,2.99999958 2.9995,17.5789975 2.9995,35.499995 C2.9995,40.8179943 4.3295,46.1049935 6.8425,50.7909929 C7.2355,51.5209928 6.9605,52.4299926 6.2305,52.8209926 C5.5005,53.2129925 4.5915,52.9399926 4.2005,52.2089927 C1.4525,47.0899934 -0.0005,41.3119942 -0.0005,35.499995 C-0.0005,15.9249978 15.9245,0 35.4995,0 C55.0755,0 70.9995,15.9249978 70.9995,35.499995 C70.9995,41.3119942 69.5475,47.0899934 66.7995,52.2089927 C66.5295,52.7139926 66.0115,52.9999926 65.4775,52.9999926" fill={theme.secondaryColor} ></path>
            </g>
            <path d="M35.5,28 C32.46,28 29.448,27.247 26.791,25.822 C26.062,25.431 25.786,24.521 26.178,23.791 C26.57,23.062 27.481,22.787 28.209,23.178 C32.654,25.562 38.346,25.562 42.791,23.178 C43.521,22.785 44.431,23.06 44.822,23.791 C45.214,24.521 44.938,25.431 44.209,25.822 C41.552,27.247 38.54,28 35.5,28" fill={theme.mainColor} ></path>
            <path d="M35.5,68 C25.886,68 16.903,63.811 10.699,56.469 C14.161,50.388 19.873,45.679 26.508,43.471 C27.026,43.299 27.409,42.858 27.509,42.321 C27.608,41.784 27.408,41.235 26.986,40.889 C23.817,38.288 22,34.501 22,30.5 C22,23.056 28.056,17 35.5,17 C42.944,17 49,23.056 49,30.5 C49,34.501 47.183,38.288 44.014,40.889 C43.592,41.235 43.392,41.784 43.491,42.321 C43.591,42.858 43.974,43.299 44.492,43.471 C51.127,45.679 56.839,50.388 60.301,56.469 C54.097,63.811 45.114,68 35.5,68 M47,12 C49.757,12 52,14.243 52,17 C52,18.893 50.938,20.588 49.274,21.439 C47.558,18.838 45.124,16.758 42.259,15.464 C42.919,13.41 44.805,12 47,12 M63.431,55.942 C59.999,49.383 54.479,44.32 47.729,41.484 C50.468,38.466 52,34.576 52,30.5 C52,28.222 51.535,26.049 50.696,24.073 C53.324,22.701 55,20.006 55,17 C55,12.589 51.411,9 47,9 C43.506,9 40.474,11.224 39.402,14.485 C38.148,14.18 36.847,14 35.5,14 C26.402,14 19,21.402 19,30.5 C19,34.576 20.532,38.466 23.271,41.484 C16.521,44.32 11.001,49.383 7.569,55.942 C7.294,56.468 7.355,57.107 7.725,57.571 C14.514,66.105 24.638,71 35.5,71 C46.362,71 56.486,66.105 63.275,57.571 C63.645,57.107 63.706,56.468 63.431,55.942" fill={theme.mainColor} ></path>
            <path d="M35.5,52 C35.271,52 35.04,51.947 34.829,51.842 L26.829,47.842 C26.088,47.471 25.788,46.57 26.158,45.829 C26.529,45.089 27.43,44.791 28.171,45.158 L35.5,48.823 L42.829,45.158 C43.571,44.791 44.472,45.089 44.842,45.829 C45.212,46.57 44.912,47.471 44.171,47.842 L36.171,51.842 C35.96,51.947 35.729,52 35.5,52" fill={theme.mainColor} ></path>
            <path d="M45,56 L45,58 C45,59.103 45.897,60 47,60 C48.103,60 49,59.103 49,58 L49,56 L45,56 Z M47,63 C44.243,63 42,60.757 42,58 L42,54.5 C42,53.672 42.672,53 43.5,53 L50.5,53 C51.328,53 52,53.672 52,54.5 L52,58 C52,60.757 49.757,63 47,63 Z" fill={theme.mainColor} ></path>
          </g>
        </g>
      </g>
    </svg>
  );
  /* eslint-enable */
}

SvgAdultFemaleAvatar.propTypes = {
  theme: PropTypes.object.isRequired,
};

export default withTheme(SvgAdultFemaleAvatar);
