/**
 * Created by moran on 7/31/17.
 */
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import _ from 'lodash';
import { autobind } from 'core-decorators';

@autobind
class ProviderScore extends React.Component {
  static propTypes = {
    ratingClassName: PropTypes.string,
    rating: PropTypes.number.isRequired,
    minScore: PropTypes.number
  };

  static defaultProps = {
    ratingClassName: '',
    minScore: 0
  };

  render() {
    let { rating, minScore } = this.props;
    if (!rating || rating < minScore) return null;
    rating = _.ceil(rating, 1);
    return (
      <div className="rating-average-wrap">
        <div className={classnames('rating-average', this.props.ratingClassName)}>
          <i className="fa fa-star" />
          {rating}
        </div>
      </div>
    );
  }
}

export default connect(state => ({
  minScore: state.config.directory.providerScore.minScore,
}))(ProviderScore);
