import { autobind } from 'core-decorators';
import ServicesApi from './services';
import BackofficeApi from './backoffice';

const SERVICE_NAME = 'acl';

@autobind
class AclApi extends ServicesApi {
  /**
   * A fetch object that points to the server
   * @param fetch
   */
  constructor(fetch) {
    super(fetch);
    this.backofficeApi = new BackofficeApi(fetch);
  }

  getUserPermissions(userId) {
    return this.callSecure(SERVICE_NAME, 'getPermissions', { userId });
  }

  getPermissionsByUserId(userId, options) {
    return this.callSecure(SERVICE_NAME, 'getPermissionsByUserId', { userId, options });
  }

  getPermissionsByGroupId(groupId, options) {
    return this.callSecure(SERVICE_NAME, 'getPermissionsByGroupId', { groupId, options });
  }

  getPermissionsByResourceId(aclResourceId, resourceTypes, queryPayload) {
    return this.callSecure(SERVICE_NAME, 'getResourcePermissionsDetails', {
      aclResourceId,
      resourceTypes,
      queryPayload,
    });
  }

  addPermission(promoteOnly, userId, permission, resourceType, resourceId, isGroup = false) {
    return this.backofficeApi.executeAction(SERVICE_NAME, 'addPermission', {
      promoteOnly,
      userId,
      isGroup,
      permission,
      resourceType,
      resourceId,
    });
  }

  removePermission(userId, resourceType, resourceId, isGroup = false) {
    return this.backofficeApi.executeAction(SERVICE_NAME, 'removePermission', {
      userId,
      isGroup,
      resourceType,
      resourceId,
    });
  }
}

export default AclApi;
