/**
 * Created by galgoltzman on 06/06/2017.
 */
/* eslint-disable import/first */
if (typeof require.ensure !== 'function') require.ensure = (d, c) => c(require);

import divComponent from '../routes/divComponent';
import { pathsWithQuery } from '../../../../utils/pathsWithQuery';

export const paths = {
  infoSlider: qs => pathsWithQuery('/secure/insuranceId/infoSlider', qs),
  enterInsuranceID: qs => pathsWithQuery('/secure/insuranceId/enterInsuranceID', qs),
};

// Routes
const InsuranceIdSlider = require('./insuranceIdSlider');
const EnterInsuranceID = require('./enterInsuranceId.container.jsx');

// Components
const Components = [
  {
    path: 'infoSlider',
    name: 'infoSlider',
    component: InsuranceIdSlider.default || InsuranceIdSlider,
  },
  {
    path: 'enterInsuranceID',
    name: 'enterInsuranceID',
    component: EnterInsuranceID.default || EnterInsuranceID,
  },
];

export default {
  path: 'insuranceId',
  name: 'insuranceId',
  getChildRoutes(location, cb) {
    require.ensure([], () => {
      cb(null, Components);
    });
  },

  // Requiring secured page NOT dynamically so it won't be unmounted every route
  component: divComponent,
};
/* eslint-enable import/first */
