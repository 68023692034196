import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import uuid from 'uuid';
import { log } from '../../../utils/logger';
import FlowTraverse from './flowTraverse';
import { DEFAULT_FLOW_NAME, TRUE_RESULT } from './consts';
import { FlowStepMetaShape } from './shapes';

export default Component => {
  @autobind
  class FlowControl extends React.Component {
    static propTypes = {
      /**
       * The name of the flow
       */
      name: PropTypes.string,
      /**
       * An object represents steps and links between them
       */
      stepsMeta: PropTypes.objectOf(FlowStepMetaShape),
      /**
       * True when flow is active
       */
      isActive: PropTypes.bool,
      /**
       * An id attached to the flow - can be external entity
       */
      flowId: PropTypes.string,
      /**
       * The name of the initial step
       */
      initialStepName: PropTypes.string,
      /**
       * Step name that we came from (not always defined)
       */
      fromStep: PropTypes.string,
      /**
       * Current state of the flow. represents the path that have made through the flow
       */
      flowState: PropTypes.object,
      /**
       * React router
       */
      browserBack: PropTypes.func.isRequired,
      /**
       * Current step name
       */
      stepName: PropTypes.string,
      /**
       * an indication if to use router push(default) or replace
       */
      replaceUrl: PropTypes.bool,
      /**
       * Manipulate query string function - from queryConnect
       */
      setInQueryString: PropTypes.func.isRequired,
      /**
       * A function to update the context in some sort of persistent storage
       */
      updateContext: PropTypes.func,
      /**
       * A flag that indicates whether or not to use the function above
       */
      shouldContextPersist: PropTypes.bool,
      /**
       * Initial state for the flow, including flow uuid and context
       */
      initialState: PropTypes.shape({
        uuid: PropTypes.string,
        context: PropTypes.object,
      }),
    };

    static defaultProps = {
      name: DEFAULT_FLOW_NAME,
      stepsMeta: {},
      flowState: {},
      isActive: false,
      replaceUrl: false,
      flowId: undefined,
      initialStepName: undefined,
      fromStep: undefined,
      stepName: undefined,
      initialState: undefined,
      updateContext: _.noop,
      shouldContextPersist: false,
    };

    constructor(props) {
      super(props);

      this.state = {
        context: null,
        uuid: 0,
      };

      this.flowTraverse = new FlowTraverse(props.stepsMeta, props.initialStepName);
    }

    componentWillMount() {
      const { initialState } = this.props;

      if (initialState) {
        const { uuid, context } = initialState;
        this.setState({ uuid, context });
      } else {
        // Generate flow id
        const id = uuid();
        this.setState({ uuid: id });
      }
    }

    getStepProps() {
      const props = this.flowTraverse.mapContextToProps(this.props.stepName, this.state.context);
      return props;
    }

    /**
     *
     * Goes to the step provided in the name param
     * Changes the qs which affects on the render function
     * @param stepName - the step we want to navigate to
     * @param previousStep
     * @param pushRoute
     * @param value
     */
    navToStep(stepName, previousStep, pushRoute = true, value = true) {
      log(`[BaseFlowControl] navigating to step: ${stepName}. previous step: ${previousStep}`);
      const { name, flowId, setInQueryString } = this.props;
      setInQueryString(
        _.extend(
          { step: stepName, flow: name, uuid: this.state.uuid },
          previousStep ? { [previousStep]: value } : {},
          flowId ? { flowId } : {},
        ),
        pushRoute, // true for push, false for replace
        previousStep ? { fromStep: previousStep } : {},
      );
    }

    /**
     * Finishes the flow - removes all related values from the qs
     */
    finish() {
      const emptyState = _.mapValues(this.props.flowState, () => undefined);
      const steps = _.mapValues(this.props.stepsMeta, () => undefined);
      this.props.setInQueryString({
        ...emptyState,
        ...steps,
        step: undefined,
        flow: undefined,
        flowId: undefined,
        uuid: undefined,
        isActive: undefined,
      });
      this.setState({ uuid: undefined, context: null });
    }

    /**
     * Starts the flow.
     * Navigate to the initial step if step not provided in the qs
     */
    start() {
      const { stepName, initialStepName, replaceUrl } = this.props;
      if (!stepName && initialStepName) {
        this.navToStep(initialStepName, null, replaceUrl);
      }
    }

    goNext(stepValue = TRUE_RESULT, returnValue) {
      const {
        name: flowName,
        stepName,
        flowState,
        updateContext,
        shouldContextPersist,
        replaceUrl,
      } = this.props;

      const { context, uuid: propUuid } = this.state;
      const toStep = this.flowTraverse.findNext(stepName, flowState, stepValue);
      if (toStep) {
        // if the curr step is transient remove it from the stack with 'replace'
        const pushRoute = !this.flowTraverse.isTransient(stepName) && !replaceUrl;
        // Update the context
        const partialContext = this.flowTraverse.mapReturnValueToContext(stepName, returnValue);
        const newContext = _.assign({}, context, partialContext);
        log('[BaseFlowControl] updated context:', newContext);
        this.setState({ context: newContext });
        if (shouldContextPersist) updateContext(flowName, propUuid, newContext);

        this.navToStep(toStep, stepName, pushRoute, stepValue);
      } else {
        // Finish flow if we don't have anywhere to go
        this.finish();
      }
    }

    goBack() {
      const { stepName, flowState, fromStep, browserBack, replaceUrl } = this.props;
      if (!this.flowTraverse.hasBack(stepName)) return;

      // fromStep is retrieved from router state. When we will come from our parent,
      // the fromStep will have value and flowTraverse.isParent will be true,
      // we want to use the browser back, otherwise use the flow graph to determine parent.
      // why not always use the graph? because we want to be correlated to the browser's back.
      const isBrowserBack = this.flowTraverse.isParent(stepName, fromStep);
      if (isBrowserBack) {
        browserBack();
      } else {
        const parent = this.flowTraverse.findParent(stepName, flowState);
        if (parent) {
          this.navToStep(parent, null, replaceUrl);
        }
      }
    }

    hasNext() {
      const { stepName } = this.props;
      return this.flowTraverse.hasNext(stepName);
    }

    hasBack() {
      const { stepName } = this.props;
      return this.flowTraverse.hasBack(stepName);
    }

    render() {
      const { stepName, flowState: state, isActive, flowId, name, ...rest } = this.props;

      return (
        <Component
          flow={{
            next: this.goNext,
            back: this.goBack,
            hasNext: this.hasNext,
            hasBack: this.hasBack,
            start: this.start,
            finish: this.finish,
            state,
            isActive,
            flowId,
            flowName: name,
            currentStep: stepName,
            stepProps: this.getStepProps(),
          }}
          {...rest}
        />
      );
    }
  }

  return FlowControl;
};

// Evaluate with state and props when needed
export const evaluateExternalParams = (externalParams, state, props) => {
  if (_.isFunction(externalParams)) {
    return externalParams(state, props);
  }
  return _.cloneDeep(externalParams);
};

export const mapQueryToProps = (query, props) => {
  // Populate flow state from query string
  const { name } = props;
  const { step, flow, uuid } = query;
  const queryValues = _.keys(props.stepsMeta);
  const map = _.zipObject(queryValues, queryValues);
  const flowState = _.mapValues(map, queryVal => _.get(query, queryVal));
  return {
    flowState,
    stepName: step,
    isActive: name === flow,
    uuid,
  };
};
