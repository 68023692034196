import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '../../storybook/src/utils/theme';

function SvgMedicalTeam({ theme }) {
  /* eslint-disable */
  return (

    <svg aria-hidden="true" focusable="false" width="72px" height="72px" viewBox="0 0 72 72" className="svg-icon svg-medical-team">
      <defs>
        <polygon id="path-1" points="0.506824492 0.2063 57.4923034 0.2063 57.4923034 58 0.506824492 58"></polygon>
      </defs>
      <g id="Subscriber-don't-choose-PCP---empty-my-medical-team-page" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="empty-my-medical-team-page" transform="translate(-460.000000, -80.000000)">
          <g id="Page-1" transform="translate(460.000000, 80.000000)">
            <path d="M6.5723,45.0488 C6.3383,45.0488 6.0993,44.9938 5.8783,44.8778 C5.1433,44.4938 4.8603,43.5878 5.2443,42.8538 C6.6693,40.1298 8.8693,37.9598 11.5583,36.5868 C10.6563,35.2658 10.1623,33.7048 10.1623,32.0828 C10.1623,27.6278 13.7873,24.0038 18.2423,24.0038 C19.3223,24.0038 20.3713,24.2138 21.3583,24.6268 C22.1233,24.9468 22.4823,25.8258 22.1633,26.5898 C21.8433,27.3538 20.9683,27.7158 20.2003,27.3948 C19.5813,27.1358 18.9233,27.0038 18.2423,27.0038 C15.4413,27.0038 13.1623,29.2818 13.1623,32.0828 C13.1623,33.5848 13.8473,35.0088 15.0423,35.9898 C15.4643,36.3368 15.6643,36.8858 15.5643,37.4228 C15.4653,37.9598 15.0823,38.4008 14.5633,38.5718 C11.7203,39.5188 9.2933,41.5848 7.9023,44.2438 C7.6343,44.7558 7.1123,45.0488 6.5723,45.0488" id="Fill-4" fill={theme.mainColor} ></path>
            <path d="M65.4268,45.0488 C64.8868,45.0488 64.3648,44.7558 64.0968,44.2438 C62.7058,41.5848 60.2788,39.5188 57.4358,38.5718 C56.9168,38.4008 56.5338,37.9598 56.4348,37.4228 C56.3348,36.8858 56.5348,36.3368 56.9568,35.9898 C58.1518,35.0088 58.8368,33.5848 58.8368,32.0828 C58.8368,29.2818 56.5578,27.0038 53.7568,27.0038 C53.0758,27.0038 52.4178,27.1358 51.7988,27.3948 C51.0328,27.7158 50.1558,27.3538 49.8358,26.5898 C49.5168,25.8258 49.8758,24.9468 50.6408,24.6268 C51.6278,24.2138 52.6768,24.0038 53.7568,24.0038 C58.2118,24.0038 61.8368,27.6278 61.8368,32.0828 C61.8368,33.7048 61.3428,35.2658 60.4408,36.5868 C63.1298,37.9598 65.3298,40.1298 66.7548,42.8538 C67.1388,43.5878 66.8558,44.4938 66.1208,44.8778 C65.8998,44.9938 65.6608,45.0488 65.4268,45.0488" id="Fill-6" fill={theme.mainColor} ></path>
            <g id="Group-3" transform="translate(7.000000, 14.000000)">
              <g id="Clip-2"></g>
              <path d="M3.8076,43.2803 C10.1076,50.7413 19.2336,55.0003 28.9996,55.0003 C38.7656,55.0003 47.8916,50.7413 54.1916,43.2803 C50.6766,37.0973 44.8726,32.3103 38.1306,30.0673 C37.6126,29.8953 37.2296,29.4553 37.1296,28.9183 C37.0306,28.3813 37.2306,27.8323 37.6526,27.4853 C40.8726,24.8423 42.7196,20.9943 42.7196,16.9263 C42.7196,9.3613 36.5656,3.2063 28.9996,3.2063 C21.4346,3.2063 15.2796,9.3613 15.2796,16.9263 C15.2796,20.9943 17.1256,24.8423 20.3466,27.4853 C20.7686,27.8323 20.9686,28.3813 20.8696,28.9183 C20.7706,29.4553 20.3866,29.8953 19.8686,30.0673 C13.1246,32.3103 7.3226,37.0983 3.8076,43.2803 M28.9996,58.0003 C17.9856,58.0003 7.7186,53.0373 0.8326,44.3833 C0.4636,43.9193 0.4026,43.2803 0.6776,42.7543 C4.1616,36.0933 9.7706,30.9543 16.6286,28.0813 C13.8396,25.0183 12.2796,21.0683 12.2796,16.9263 C12.2796,7.7073 19.7806,0.2063 28.9996,0.2063 C38.2196,0.2063 45.7196,7.7073 45.7196,16.9263 C45.7196,21.0683 44.1596,25.0183 41.3706,28.0813 C48.2286,30.9543 53.8366,36.0933 57.3216,42.7543 C57.5966,43.2803 57.5356,43.9193 57.1656,44.3833 C50.2806,53.0373 40.0146,58.0003 28.9996,58.0003" id="Fill-1" fill={theme.secondaryColor} ></path>
            </g>
            <path d="M66.418,53.7354 C66.179,53.7354 65.936,53.6784 65.71,53.5564 C64.98,53.1654 64.705,52.2564 65.098,51.5264 C67.65,46.7674 69,41.3984 69,36.0004 C69,17.8034 54.196,3.0004 36,3.0004 C17.804,3.0004 3,17.8034 3,36.0004 C3,41.4004 4.35,46.7694 6.902,51.5264 C7.295,52.2564 7.021,53.1654 6.29,53.5564 C5.562,53.9484 4.652,53.6754 4.26,52.9444 C1.473,47.7534 0,41.8934 0,36.0004 C0,16.1494 16.149,0.0004 36,0.0004 C55.851,0.0004 72,16.1494 72,36.0004 C72,41.8914 70.527,47.7514 67.74,52.9444 C67.47,53.4494 66.952,53.7354 66.418,53.7354" id="Fill-8" fill={theme.mainColor} ></path>
            <path d="M22.0381,54.9023 C21.2101,54.9023 20.5381,54.2303 20.5381,53.4023 L20.5381,43.5883 C20.5381,42.7593 21.2101,42.0883 22.0381,42.0883 C22.8661,42.0883 23.5381,42.7593 23.5381,43.5883 L23.5381,53.4023 C23.5381,54.2303 22.8661,54.9023 22.0381,54.9023" id="Fill-10" fill={theme.secondaryColor} ></path>
            <path d="M49.7832,52.7812 C48.9552,52.7812 48.2832,52.1092 48.2832,51.2812 L48.2832,43.1022 C48.2832,42.2742 48.9552,41.6022 49.7832,41.6022 C50.6112,41.6022 51.2832,42.2742 51.2832,43.1022 L51.2832,51.2812 C51.2832,52.1092 50.6112,52.7812 49.7832,52.7812" id="Fill-12" fill={theme.secondaryColor} ></path>
            <path d="M22.2188,55.333 C20.9688,55.333 19.9518,56.351 19.9518,57.601 C19.9518,58.851 20.9688,59.867 22.2188,59.867 C23.4688,59.867 24.4868,58.851 24.4868,57.601 C24.4868,56.351 23.4688,55.333 22.2188,55.333 M22.2188,62.867 C19.3148,62.867 16.9518,60.505 16.9518,57.601 C16.9518,54.696 19.3148,52.333 22.2188,52.333 C25.1228,52.333 27.4868,54.696 27.4868,57.601 C27.4868,60.505 25.1228,62.867 22.2188,62.867" id="Fill-14" fill={theme.secondaryColor} ></path>
            <path d="M52.209,61.9512 C51.381,61.9512 50.709,61.2792 50.709,60.4512 C50.709,59.7692 51.164,59.1942 51.786,59.0122 L51.786,54.7842 C51.786,53.6802 50.888,52.7812 49.783,52.7812 C48.679,52.7812 47.78,53.6802 47.78,54.7842 L47.78,59.0122 C48.403,59.1932 48.858,59.7692 48.858,60.4512 C48.858,61.2792 48.187,61.9512 47.358,61.9512 C45.937,61.9512 44.78,60.7952 44.78,59.3742 L44.78,54.7842 C44.78,52.0252 47.024,49.7812 49.783,49.7812 C52.542,49.7812 54.786,52.0252 54.786,54.7842 L54.786,59.3742 C54.786,60.7952 53.63,61.9512 52.209,61.9512" id="Fill-16" fill={theme.secondaryColor} ></path>
          </g>
        </g>
      </g>
    </svg>
  );
  /* eslint-enable */
}

SvgMedicalTeam.propTypes = {
  theme: PropTypes.object.isRequired,
};

export default withTheme(SvgMedicalTeam);

