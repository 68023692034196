import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { defineMessages, FormattedMessage } from 'react-intl';
import { Field } from 'redux-form';
import { autobind } from 'core-decorators';

import * as ActivationForm from '../activationForm';
import SpinnerButton from '../../../../../components/ui/spinner/spinnerButton';
import SvgBirthdayCake from '../../../../../assets/svg/birthdayCake';
import { getFeature } from '../../../../../store/feature/selectors';
import { withActivationForm } from './withActivationForm';

const messages = defineMessages({
  weNeedYourDateOfBirth: {
    defaultMessage: 'We need your date of birth to confirm your identity',
    id: 'activation.mobileNum.weNeedYourDateOfBirth',
  },
  yourDateOfBirth: {
    defaultMessage: 'Your date of birth',
    id: 'activation.mobileNum.yourDateOfBirth',
  },
});

@autobind
class DateOfBirth extends React.Component {
  static propTypes = {
    /**
     * Hook method. supplied by redux-forms
     */
    handleSubmit: PropTypes.func.isRequired,
    /**
     * True if form is currently submitting
     */
    loading: PropTypes.bool,
    confirmMessage: PropTypes.object.isRequired,
  };

  static contextTypes = {
    analytics: PropTypes.object.isRequired,
  };

  static defaultProps = {
    loading: false,
  };

  componentDidMount() {
    this.context.analytics.manualPageReport('activation.dateOfBirth');
  }

  render() {
    const { handleSubmit, loading, confirmMessage } = this.props;

    return (
      <form onSubmit={handleSubmit} className="activation-mobile-num-wrap" method="post">
        <div className="top-section">
          <div className="row">
            <div className="col-xs-12 text-center">
              <SvgBirthdayCake />
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 text-center">
              <h1 className="title-24 md-margin font-color-brand-main title-brand-1">
                <FormattedMessage {...messages.weNeedYourDateOfBirth} />
              </h1>
            </div>
          </div>
        </div>

        <div className="form-section">
          <div className="row">
            <div className="col-xs-12">
              <Field
                placeholder="MM/DD/YYYY"
                inputClassName="no-margin"
                label={messages.yourDateOfBirth}
                {...ActivationForm.fields.dob}
              />
            </div>
          </div>
        </div>

        <div className="row margin-top-30">
          <div className="col-xs-12">
            <SpinnerButton
              isLoading={loading}
              className="btn btn-big complete-btn bg-color-brand-button"
              type="submit"
            >
              <FormattedMessage {...confirmMessage} />
            </SpinnerButton>
          </div>
        </div>
      </form>
    );
  }
}

const fieldName = 'dob';
export default compose(
  connect(state => ({
    confirmMessage: getFeature(state, 'activationFlow.dobStep.confirm'),
  })),
  withActivationForm(fieldName),
)(DateOfBirth);
