import { autobind } from 'core-decorators';
import BkmdApi from './bkmdApi';

// eslint-disable-next-line
const SERVICE_NAME = 'colorectalFlow';

/**
 * Represents the colorectal flow API
 */
@autobind
class ColorectalFlowApi extends BkmdApi {
  constructor(fetch) {
    super(fetch, '/colorectalFlow');
  }

  generateToken(activationToken) {
    return this.post('/generateToken', { activationToken }).then(response => response.data.token);
  }
}

export default ColorectalFlowApi;
