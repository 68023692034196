import BookingRouteStrategy from './bookingRouteStrategy';
import DoctorsShareRouteStrategy from './doctorsShareRouteStrategy';
import OpenDirectoryRouteStrategy from './openDirectoryRouteStrategy';
import SecuredDirectoryRouteStrategy from './securedDirectoryRouteStrategy';
import GeneralRouteStrategy from './generalRouteStrategy';
import NotFoundRouteStrategy from './notFoundRouteStrategy';
import PublicPCPSelectionRouteStrategy from './publicPCPSelectionRouteStrategy';
import ColorectalFlowRoutesStrategy from './colorectalFlowRouteStrategy';
import SavingAlaskaRoutesStrategy from './savingAlaskaRouteStrategy';
import ActivationRouteStrategy from './activationRouteStrategy';

export default [
  BookingRouteStrategy,
  DoctorsShareRouteStrategy,
  OpenDirectoryRouteStrategy,
  PublicPCPSelectionRouteStrategy,
  ColorectalFlowRoutesStrategy,
  SavingAlaskaRoutesStrategy,
  SecuredDirectoryRouteStrategy,
  ActivationRouteStrategy,
  GeneralRouteStrategy,
  NotFoundRouteStrategy, // Must be the last one
];
