/**
 * Created by matan on 3/13/17.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { defineMessages, FormattedMessage, FormattedHTMLMessage } from 'react-intl';

import BkmdModal from '../../../../../components/bkmdModal/bkmdModal';
import ModalHeader from '../../../../../components/bkmdModal/modalHeader';
import { getSupportPhoneNumber } from '../../../../../store/config/selectors';

const messages = defineMessages({
  subTitle: {
    defaultMessage: 'What if I didn’t get a verification code?',
    id: 'activation.verificationCodeModal.subTitle',
  },
  mainText: {
    defaultMessage:
      'If you didn’t receive a code, please check your phone number and then send ' +
      'it again. You can use the back button on the previous screen to go back and resend the code.' +
      '<br/><br/>If you’re still having problems, please call customer support at {phoneNumber}.',
    id: 'activation.verificationCodeModal.mainText',
  },
  yourVerificationCode: {
    defaultMessage: 'Your verification code',
    id: 'activation.verificationCodeModal.yourVerificationCode',
  },
});

class VerificationCodeModal extends React.PureComponent {
  static propTypes = {
    /**
     * Hook method.
     * Called whenever the modal should be closed
     */
    handleClose: PropTypes.func.isRequired,
    /**
     * Phone number to display in the text - from config
     */
    phoneNumber: PropTypes.string.isRequired,
    /**
     * Name for identifying via analytics
     */
    name: PropTypes.string, // eslint-disable-line react/no-unused-prop-types
  };

  static defaultProps = {
    name: 'verification_code_modal',
  };

  render() {
    const { handleClose, phoneNumber } = this.props;
    return (
      <BkmdModal className="fixed-header" {...this.props}>
        <ModalHeader onClose={handleClose} title={messages.yourVerificationCode} />
        <div className="dialog-body">
          <div className="row">
            <div className="col-xs-12 text-center">
              <img
                src={require('../images/help-modal-top.png')}
                className="activation-help-modal-img"
                alt=""
              />
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 activation-modal-subtitle">
              <span className="text-18 text-semibold text-dark">
                <FormattedMessage {...messages.subTitle} />
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 text-16">
              <FormattedHTMLMessage {...messages.mainText} values={{ phoneNumber }} />
            </div>
          </div>
        </div>
      </BkmdModal>
    );
  }
}

export default connect(state => ({
  phoneNumber: getSupportPhoneNumber(state),
}))(VerificationCodeModal);
