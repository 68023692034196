import { compose, pure, withHandlers, withProps } from 'recompose';
import { defineMessages } from 'react-intl';
import { withTranslatedMessages } from '../../utils/withTranslatedMessages';
import SCR014ReminderInput from '../../screenTemplates/SCR014ReminderInput';

const messages = defineMessages({
  title: {
    defaultMessage: 'Great job taking care of yourself.',
    id: 'colorectalFlow.setAReminder.title',
  },
  mainText: {
    defaultMessage: `Tell us when and where you did your colon cancer screening
    and we'll remind you when you are due for the next one`,
    id: 'colorectoralFlow.setAReminder.title',
  },
  buttonText: {
    defaultMessage: 'Set a reminder',
    id: 'colorectalFlow.setAReminder.buttonText',
  },
});

const ResultsType = [
  { value: 'normal', label: 'Normal' },
  { value: 'abnormal', label: 'Abnormal' },
  { value: 'unknown', label: 'Unknown' },
];

const SetAReminder = compose(
  pure,
  withTranslatedMessages(messages),
  withProps({
    options: ResultsType,
  }),
  withHandlers({
    onButtonClick: ({ control }) => formResult => control.next({ payload: formResult }),
  }),
)(SCR014ReminderInput);

export default SetAReminder;
