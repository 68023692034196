/**
 * Created by guyavarham on 20/04/2017.
 */

import { defineMessages } from 'react-intl';

const messages = defineMessages({
  nearWork: {
    defaultMessage: '<strong>Near work</strong> {address}',
    id: 'locationSelect.nearWork',
  },
  nearHome: {
    defaultMessage: '<strong>Near home</strong> {address}',
    id: 'locationSelect.nearHome',
  },
  myLocation: {
    defaultMessage: 'My Location',
    id: 'locationSelect.myLocation',
  },
  locationErrorTitle: {
    defaultMessage: 'Error',
    id: 'locationSelect.locationActionErrorTitle',
  },
  locationErrorMessage: {
    defaultMessage: 'Please allow location in your browser and refresh',
    id: 'locationSelect.locationActionErrorMessage',
  },
  placeholder: {
    defaultMessage: 'Enter a location',
    id: 'locationSelect.placeholder',
  },
  home: {
    defaultMessage: 'home',
    id: 'members.location.home',
  },
  work: {
    defaultMessage: 'work',
    id: 'members.location.work',
  },
});

export default messages;
