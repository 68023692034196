import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { Pagination } from 'react-bootstrap';
import { autobind } from 'core-decorators';
import { connect } from 'react-redux';
import { compose, withHandlers, withPropsOnChange } from 'recompose';
import classNames from 'classnames';

import { isAuthenticated } from '../../../../../../store/auth/selectors';
import NewDoctorCard from './doctorCard/newDoctorCard';
import PcpBanner from './pcpBanner';
import NoResults from '../../../../../../components/searchDoctors/noResultsPage';
import SearchPreview from '../../../../../../components/searchDoctors/searchPreview';
import Utils from '../../../../../../utils/util';
import InsuranceIdBanner from './insuranceIdBanner';
import FeatureFlagged from '../../../../../../components/features/featureFlagged';
import LoggedIn from '../../../../../../components/loggedIn/loggedIn';
import ThreeBounceSpinner from '../../../../../../components/ui/spinner/threeBounceSpinner';
import actionTracker from '../../../../../../store/tools/actionTracker/actionTrackerComponent';
import { SEARCH_DOCTORS, searchDoctors } from '../../../../../../store/directory/search/actions';
import { searchResults, searchResultsMeta } from '../../../../../../store/directory/selectors';
import {
  hasInsuranceIdSelector,
  member,
  memberPlan,
} from '../../../../../../store/member/selectors';
import { getPathsFromContext } from '../../../routes/injectPathsToContext';
import { openFindDoctors } from '../../routingActions';
import { isLocationReady } from '../../directorySelectors';
import { getDomain, getFeature } from '../../../../../../store/feature/selectors';
import {
  createToggleState,
  createToggleStatePropTypes,
} from '../../../../../../components/recompose/toggleStateComponent';
import { clientLocationShape } from '../../../../../../components/searchDoctors/clientLocationShape';
import { isPublicDirectory } from '../../../../../../store/config/selectors';

import withBookingModal from '../../../../../../components/bookingModal/withBookingModal';

import './resultsList.less';
import withFeatureFlag from '../../../../../../utils/featureFlags/withFeatureFlag';
import { getMemberSessionId } from '../../../../../../utils/storage/memberSession';
import SearchAction from '../../../../../../model/enum/searchAction';
import Api from '../../../../../../api';

/**
 * This component renders lists of cards organized by pages.
 * Each card reflects a single doctor from the results of the latest doctors search
 */
@autobind
class ResultsScrolable extends React.PureComponent {
  static propTypes = {
    paginationMaxButtons: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
    searchDoctors: PropTypes.func.isRequired,
    openFindDoctors: PropTypes.func.isRequired,
    setInQueryString: PropTypes.func.isRequired,
    filterModalShow: PropTypes.func.isRequired,
    onChoosePcpLabel: PropTypes.func.isRequired,
    validLocation: PropTypes.object.isRequired, // router location
    routerLocation: PropTypes.object.isRequired, // router location
    chosenLocation: PropTypes.shape(clientLocationShape).isRequired,
    searchTracker: PropTypes.object.isRequired,
    paths: PropTypes.object.isRequired,
    skip: PropTypes.number.isRequired,
    yourPlanBannerPositionInList: PropTypes.number.isRequired,
    codes: PropTypes.arrayOf(PropTypes.string).isRequired,
    hiddenTaxonomiesSelector: PropTypes.bool.isRequired,
    hasInsuranceId: PropTypes.bool,
    searchResults: PropTypes.arrayOf(PropTypes.object),
    searchResultsMeta: PropTypes.object,
    searchTerm: PropTypes.string,
    filter: PropTypes.object,
    showDetails: PropTypes.bool.isRequired,
    showShare: PropTypes.bool.isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
    onlyAuthenticatedBooking: PropTypes.bool.isRequired,
    goToSecureSearchResults: PropTypes.func.isRequired,
    queryId: PropTypes.string.isRequired,
    ...createToggleStatePropTypes('primaryCareModal'),
  };

  static defaultProps = {
    searchResults: [],
    searchResultsMeta: {},
    filter: {},
    hasInsuranceId: false,
    searchTerm: '',
  };

  componentWillMount() {
    this.callSearch(this.props);
  }

  componentWillReceiveProps(nextProps) {
    Utils.inspectChange(this.props, nextProps, [
      { uri: 'routerLocation.search', action: this.callSearch }, // on query string change
      { uri: 'validLocation', action: this.callSearch },
    ]);
  }

  onBookClicked(provider, location) {
    const {
      isAuthenticated,
      onlyAuthenticatedBooking,
      goToSecureSearchResults,
      bookingModalShow,
    } = this.props;

    if (onlyAuthenticatedBooking && !isAuthenticated) {
      goToSecureSearchResults(provider.npi, location.id);
    } else {
      bookingModalShow(provider, location);
    }
  }

  callSearch({
    chosenLocation: { geo },
    codes,
    filter,
    validLocation,
    memberPlan,
    member,
    onlyInNetworkFeatureFlag,
    onlyInNetworkDomainConfig,
    queryId,
  }) {
    if (!_.isEmpty(codes) && !_.isEmpty(validLocation)) {
      this.props.searchDoctors(
        geo,
        codes,
        filter,
        memberPlan,
        member.memberIdPrefix,
        member.memberToken,
        (onlyInNetworkFeatureFlag && onlyInNetworkDomainConfig) || undefined,
        queryId,
        getMemberSessionId(),
      );
    }
  }

  changePage(activePage) {
    const { setInQueryString, pageSize } = this.props;
    const skip = (activePage - 1) * pageSize;
    setInQueryString({ skip });
  }

  openFindDoctors() {
    this.props.openFindDoctors(this.props.paths);
  }

  renderCard(doctorData = {}) {
    if (!doctorData) return <div />;
    const {
      chosenLocation: { geo },
      chosenCode,
      showShare,
      onShareSuccess,
      onBookSuccess,
      onProviderDetailsClick,
    } = this.props;

    return (
      <NewDoctorCard
        provider={doctorData.data}
        clientLocation={geo}
        chosenCode={chosenCode}
        showShare={showShare}
        onBookAppointmentClicked={this.onBookClicked}
        onShareSuccess={onShareSuccess}
        onBookSuccess={onBookSuccess}
        onProviderDetailsClick={onProviderDetailsClick}
      />
    );
  }

  render() {
    const {
      searchResults,
      searchResultsMeta,
      paginationMaxButtons,
      searchTracker,
      chosenLocation,
      pageSize,
      skip,
      filter,
      setInQueryString,
      yourPlanBannerPositionInList,
      filterModalShow,
      searchTerm,
      routerLocation,
      showPCPBanner,
      onChoosePcpLabel,
      hiddenTaxonomiesSelector,
    } = this.props;

    const numOfPages = searchResultsMeta ? _.round(searchResultsMeta.count / pageSize) : 1;
    const activePage = _.round(skip / pageSize) + 1;

    if (searchTracker.inProgress) return <ThreeBounceSpinner spinnerClass="va-mid-spinner" />;
    if (searchResultsMeta && searchResultsMeta.count === 0) {
      return (
        <NoResults
          filter={filter}
          onSearchAgain={this.openFindDoctors}
          setInQueryString={setInQueryString}
          className={hiddenTaxonomiesSelector ? 'hidden-taxonomies-selector' : null}
          searchTerm={searchTerm}
        />
      );
    }

    return (
      <div
        className={classNames(
          'search-results-content',
          hiddenTaxonomiesSelector ? 'hidden-taxonomies-selector' : null,
        )}
      >
        <FeatureFlagged uri="results.insuranceIdBanner">
          <LoggedIn>
            <InsuranceIdBanner routerLocation={routerLocation} />
          </LoggedIn>
        </FeatureFlagged>
        <div className="results-cards has-footer">
          <FeatureFlagged uri="results.pcpBanner">
            <PcpBanner showPCPBanner={showPCPBanner} onChoosePcpLabel={onChoosePcpLabel} />
          </FeatureFlagged>
          <SearchPreview
            onClick={filterModalShow}
            chosenLocation={chosenLocation}
            searchTerm={searchTerm}
          />
          <ul className="cards-list">
            {_.map(_.take(searchResults, yourPlanBannerPositionInList), this.renderCard)}
          </ul>
          <ul className="cards-list">
            {_.map(_.drop(searchResults, yourPlanBannerPositionInList), this.renderCard)}
          </ul>

          <Pagination
            bsSize="large"
            items={numOfPages}
            activePage={activePage}
            onSelect={this.changePage}
            maxButtons={paginationMaxButtons}
            prev
            next
            ellipsis={false}
          />
        </div>
      </div>
    );
  }
}

export default compose(
  actionTracker({
    searchTracker: SEARCH_DOCTORS.SOURCE,
  }),
  withFeatureFlag({
    key: 'premera.providers.onlyInNetwork',
    prop: 'onlyInNetworkFeatureFlag',
    defaultValue: false,
  }),
  connect(
    (state, ownProps) => ({
      domain: getDomain(state),
      member: member(state),
      memberPlan: memberPlan(state),
      searchResults: searchResults(state),
      validLocation: isLocationReady(state),
      searchResultsMeta: searchResultsMeta(state),
      hasInsuranceId: hasInsuranceIdSelector(state),
      skip: _.toNumber(_.get(ownProps, 'routerLocation.query.skip', 0)),
      pageSize: _.get(state, 'config.directory.searchResults.pageSize', 7),
      paginationMaxButtons: _.get(state, 'config.directory.searchResults.paginationMaxButtons', 3),
      yourPlanBannerPositionInList: _.get(state, 'config.directory.yourPlanBanner.positionInList'),
      showDetails: getFeature(state, 'results.doctorCard.detailsBtn'),
      // Share is relevant only if a user is involved
      showShare: !isPublicDirectory(state) && getFeature(state, 'results.doctorCard.share'),
      isAuthenticated: isAuthenticated(state),
      onlyAuthenticatedBooking: getFeature(state, 'doctorDetails.onlyAuthenticatedBooking'),
      onlyInNetworkDomainConfig: getFeature(state, 'search.onlyInNetwork', false),
    }),
    { searchDoctors, openFindDoctors },
  ),
  getPathsFromContext(),
  createToggleState('primaryCareModal'),
  withBookingModal(),
  withHandlers({
    bookingModalShow: ({ onBookClick }) => (provider, location) =>
      onBookClick(provider, location.id, location.bookingType),
    onShareSuccess: ({ queryId }) => provider => {
      Api.searchApi
        .searchActionAnalytics({
          queryId,
          memberSessionId: getMemberSessionId(),
          npi: provider.npi,
          locationId: _.get(provider, 'locations[0].id'),
          ranking: provider.ranking,
          actionType: SearchAction.SHARE,
        })
        .catch();
    },
    onBookSuccess: ({ queryId }) => (provider, bookingType, entityId) => {
      Api.searchApi
        .searchActionAnalytics({
          queryId,
          memberSessionId: getMemberSessionId(),
          npi: provider.npi,
          locationId: _.get(provider, 'locations[0].id'),
          ranking: provider.ranking,
          actionType: bookingType,
          entityId,
        })
        .catch();
    },
    onProviderDetailsClick: ({ queryId }) => ({ npi, ranking }, locationId) => {
      Api.searchApi
        .searchActionAnalytics({
          queryId,
          memberSessionId: getMemberSessionId(),
          npi,
          locationId,
          ranking,
          actionType: SearchAction.DETAILS,
        })
        .catch();
    },
  }),
  withPropsOnChange(
    ['searchResults'],
    ({
      searchResults,
      isAuthenticated,
      bookingModalShow,
      openBookingModal,
      bookingNpi,
      bookingLocationId,
    }) => {
      if (searchResults && isAuthenticated && openBookingModal && bookingNpi) {
        const providersData = _.map(searchResults, 'data');
        const provider = _.find(providersData, { npi: bookingNpi });
        if (provider) {
          const location = _.find(provider.locations, { id: bookingLocationId });
          if (location) {
            bookingModalShow(provider, location);
          }
        }
      }
    },
  ),
)(ResultsScrolable);
