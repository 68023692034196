
const DOMAIN_KEY = 'vim-domain';
let cachedDomain;

/**
 * Stores the provided domain in the session storage
 * @param domain
 */
export function storeDomain(domain) {
  cachedDomain = domain;
  if (typeof sessionStorage !== 'undefined') {
    sessionStorage.setItem(DOMAIN_KEY, domain);
  }
}

/**
 * Removes the stored domain
 */
export function removeDomain() {
  cachedDomain = undefined;
  if (typeof sessionStorage !== 'undefined') {
    sessionStorage.removeItem(DOMAIN_KEY);
  }
}

/**
 * Fetches the stored domain from session storage
 */
export function getDomain(useCached = false) {
  if (useCached && cachedDomain) return cachedDomain;

  if (typeof sessionStorage !== 'undefined') {
    const domain = sessionStorage.getItem(DOMAIN_KEY);
    cachedDomain = domain;
    return domain;
  }

  return null;
}
