import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { defineMessages, FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { compose, withPropsOnChange } from 'recompose';

import { getFeature } from '../../../../store/feature/selectors';
import * as NetworkStatusEnum from '../../../../model/enum/networkStatus';
import { isInNetwork } from './networkStatusSelector';
import SvgShieldCheck from '../../../../assets/svg/shieldCheck';
import './networkStatus.less';
import { calculatePlan } from '../../../../utils/planCalculator';

const messages = defineMessages({
  inNetwork: {
    defaultMessage: 'In-Network',
    id: 'members.doctorDetails.inNetwork',
  },
  ofOutNetwork: {
    defaultMessage: 'Out-of-Network',
    id: 'members.doctorDetails.ofOutNetwork',
  },
  unverifiedNetwork: {
    defaultMessage: 'Network<br/>unverified',
    id: 'members.doctorDetails.unverifiedNetwork',
  },
});

function InNetwork() {
  return (
    <div className="in-network">
      <SvgShieldCheck />
      <FormattedMessage {...messages.inNetwork} />
    </div>
  );
}

function Unverified() {
  return (
    <div className="network-unverified">
      <FormattedHTMLMessage {...messages.unverifiedNetwork} />
    </div>
  );
}

function OutOfNetwork() {
  return (
    <div className="out-of-network">
      <FormattedMessage {...messages.ofOutNetwork} />
    </div>
  );
}

const NetworkStatusToComponent = {
  [NetworkStatusEnum.UNVERIFIED_NETWORK]: Unverified,
  [NetworkStatusEnum.IN_NETWORK]: InNetwork,
  [NetworkStatusEnum.OUT_OF_NEWTORK]: OutOfNetwork,
};

function NetworkStatus({ location, plan, displaNetworkStatusFeatureFlag }) {
  if (!displaNetworkStatusFeatureFlag) return null;

  const NetworkStatusComponent =
    NetworkStatusToComponent[
      _.get(location, 'network.status') || isInNetwork(location.networks, plan)
    ];
  return <NetworkStatusComponent />;
}

NetworkStatus.propTypes = {
  location: PropTypes.object.isRequired,
  plan: PropTypes.string,
  displaNetworkStatusFeatureFlag: PropTypes.bool,
};

NetworkStatus.defaultProps = {
  plan: '',
  displaNetworkStatusFeatureFlag: true,
};

export default compose(
  connect(state => ({
    displaNetworkStatusFeatureFlag: getFeature(state, 'showInNetwork'),
  })),
  withPropsOnChange(['plan', 'location'],
    ({ plan }) => ({
      plan: calculatePlan(plan),
    })),
)(NetworkStatus);
