import { autobind } from 'core-decorators';
import BkmdApi from './bkmdApi';
import ServicesApi from './services';

const SERVICE_NAME = 'clinicManagement';

@autobind
export default class FacilityApi extends BkmdApi {
  /**
   * A fetch object that points to the server
   * @param fetch
   */
  constructor(fetch) {
    super(fetch, '/clinicManagement/');
    this.servicesApi = new ServicesApi(fetch);
  }

  getFacilityByNpi(npi) {
    return this.servicesApi
      .callSecure(SERVICE_NAME, 'getFacilityByNpi', { npi })
      .then(result => result.data);
  }

  getFacilityResourceByClinicId(id) {
    return this.servicesApi
      .callSecure(SERVICE_NAME, 'getFacilityResourceByClinicId', { id })
      .then(result => result.data);
  }

  handleFacilityEntities({ clinic, supportsFacilityResource }) {
    return this.servicesApi
      .callSecure(SERVICE_NAME, 'handleFacilityEntities', {
        clinic,
        supportsFacilityResource,
      })
      .then(result => result.data);
  }

  getFacilitiesByProcedureCode(limit, skip, sortAscending, filters) {
    return this.servicesApi
      .callSecure(SERVICE_NAME, 'getFacilitiesByProcedureCode', {
        limit,
        skip,
        sortAscending,
        filters,
      })
      .then(result => result.data);
  }
}
