/**
 * Created by chenrozenes on 09/08/2017.
 */

import * as Id from './id';
import * as Category from './category';
import * as SubCategory from './subCategory';
import actionText from './actionText';

const PRIMARY_CARE_TERM = 'Primary Care Doctor';
const WOMEN_HEALTH_TERM = 'Women\'s Health';
const EYE_DOCTOR_TERM = 'Diabetic Eye Exam';

export default {
  [Id.BKMD01]: {
    category: Category.CANCER_SCREENING,
    subCategory: SubCategory.CANCER_SCREENING,
    action: {
      term: PRIMARY_CARE_TERM,
      message: actionText.findPCP,
    }
  },
  [Id.BKMD02]: {
    category: Category.CANCER_SCREENING,
    subCategory: SubCategory.CANCER_SCREENING,
    action: {
      term: WOMEN_HEALTH_TERM,
      message: actionText.findWomenHealthProvider,
    }
  },
  [Id.BKMD04]: {
    category: Category.CANCER_SCREENING,
    subCategory: SubCategory.CANCER_SCREENING,
    action: {
      term: PRIMARY_CARE_TERM,
      message: actionText.findPCP,
    }
  },
  [Id.BKMD05]: {
    category: Category.CARE_OF,
    subCategory: SubCategory.CARE_OF_DIABETES,
    action: {
      term: EYE_DOCTOR_TERM,
      message: actionText.findEyeDoctor,
    }
  },
  [Id.BKMD06]: {
    category: Category.CARE_OF,
    subCategory: SubCategory.CARE_OF_DIABETES,
    action: {
      term: PRIMARY_CARE_TERM,
      message: actionText.findPCP,
    }
  },
  [Id.BKMD07]: {
    category: Category.CARE_OF,
    subCategory: SubCategory.CARE_OF_DIABETES,
    action: {
      term: PRIMARY_CARE_TERM,
      message: actionText.findPCP,
    }
  },
  [Id.BKMD08]: {
    category: Category.CARE_OF,
    subCategory: SubCategory.CARE_OF_OSTEOPOROSIS,
  },
  [Id.BKMD09]: {
    category: Category.CARE_OF,
    subCategory: SubCategory.CARE_OF_ARTHRITIS,
  },
};
