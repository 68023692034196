import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { compose, pure, withProps, withStateHandlers } from 'recompose';
import { connect } from 'react-redux';
import { member } from '../../../store/member/selectors';

import Api from '../../../api';
import SearchDoctorsTypeahead from '../../freeText/searchDoctorsTypeahead';
import { withStateFetch } from '../../../api/injectApi';
import PcpDetailsModal from './pcpDetailsModal';
import { createToggleState } from '../../recompose/toggleStateComponent';

const PcpSearchTypeahead = ({
  detailsModalOn,
  detailsModalHide,
  updatePcpSelection,
  doctorDetails,
  memberPlan,
  onLocationChanged,
  selectedLocation,
  memberContext,
  clientLocation,
  ...typeaheadProps
}) => (
  <div>
    <SearchDoctorsTypeahead {...typeaheadProps} />
    {detailsModalOn && doctorDetails && <PcpDetailsModal
      isOpen
      shouldFetchProviderDetails
      handleClose={detailsModalHide}
      updatePcpSelection={updatePcpSelection}
      details={doctorDetails}
      memberPlan={memberPlan}
      onLocationChanged={onLocationChanged}
      selectedLocation={selectedLocation}
      memberContext={memberContext}
      clientLocation={clientLocation}
    />}
  </div>
  );

PcpSearchTypeahead.propTypes = {
  detailsModalHide: PropTypes.func.isRequired,
  detailsModalOn: PropTypes.bool.isRequired,
  distance: PropTypes.number.isRequired,
  doctorDetails: PropTypes.object.isRequired,
  memberPlan: PropTypes.string.isRequired,
  memberContext: PropTypes.string.isRequired,
  updatePcpSelection: PropTypes.func.isRequired,
  onLocationChanged: PropTypes.func.isRequired,
  selectedLocation: PropTypes.func.isRequired,
  clientLocation: PropTypes.object,
};

export default compose(
  pure,
  connect(state => ({
    apiKey: _.get(state, 'config.schedulingSdkKeys.sdkKey'),
    memberToken: _.get(member(state), 'memberToken'),
  })),
  withStateFetch(
    (text, apiKey) =>
      Api.pcpSelectionApi.searchPCPsByName(text, apiKey),
    'searchByFreeText', {
      resultPropName: 'autoComplete',
      defaultValue: {},
    }),
  createToggleState('detailsModal'),
  withStateHandlers(
    { doctorDetails: null, selectedLocation: null },
    {
      onChange: (state, { detailsModalShow }) => change => {
        detailsModalShow();
        return {
          doctorDetails: change.data,
          selectedLocation: null,
        };
      },
      searchFreeText: (state, props) => text => {
        const { searchByFreeText, apiKey } = props;
        if (!_.isEmpty(text)) {
          searchByFreeText(text, apiKey);
        }
      },
      onLocationChanged: () => locationId => ({
        selectedLocation: locationId,
      }),
    },
  ),
  withProps(({ searchByFreeTextTracker }) => ({
    showSpinner: searchByFreeTextTracker.inProgress,
  })),
)(PcpSearchTypeahead);
