/**
 * Created by matan on 3/15/17.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, FormattedMessage } from 'react-intl';

import commonMessages from '../../../../../store/intl/commonMessages';
import BkmdModal from '../../../../../components/bkmdModal/bkmdModal';

const messages = defineMessages({
  areYouSure: {
    defaultMessage: 'Are you sure you want to choose {doctorName} for your children?',
    id: 'pcp.PcpFamilyModal.areYouSure',
  },
});

class PcpFamilyConfirmModal extends React.PureComponent {
  static propTypes = {
    doctorName: PropTypes.string.isRequired,
    isOpen: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    onApprove: PropTypes.func.isRequired,
  };

  render() {
    const { isOpen, handleClose, onApprove, doctorName } = this.props;
    return (
      <BkmdModal
        isOpen={isOpen}
        handleClose={handleClose}
        className="pcp-family-confirm-modal mini-modal"
      >
        <div className="dialog-body">
          <div className="row">
            <div className="col-xs-12">
              <div className="text-16 text-semibold text-center">
                <FormattedMessage {...messages.areYouSure} values={{ doctorName }} />
              </div>
            </div>
          </div>
          <div className="row tiny-gutter margin-top-30">
            <div className="col-xs-6">
              <button className="btn btn-big btn-grey btn-sm-block" onClick={handleClose}>
                <FormattedMessage {...commonMessages.no} />
              </button>
            </div>
            <div className="col-xs-6">
              <button
                className="btn btn-big bg-color-brand-button btn-sm-block"
                onClick={onApprove}
              >
                <FormattedMessage {...commonMessages.yes} />
              </button>
            </div>
          </div>
        </div>
      </BkmdModal>
    );
  }
}

export default PcpFamilyConfirmModal;
