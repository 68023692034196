/**
 * Created by matan on 3/12/17.
 */
import Joi from 'joi-browser';
import { PASSWORD_ALLOWED_CHARACTERS } from 'Utils/regex/password';

import {
  ReduxFormCleanInput,
  ReduxFormCleanDateInput,
  ReduxFormCleanPhoneNumber,
  ReduxFormCleanPasswordInput,
  ReduxFormCleanRepeatPasswordInput,
  ReduxFormCleanAlphabet,
} from '../../../../components/form/reduxForm/components';

import {
  PhoneNumberSchema,
  PasswordSchema,
  VerificationCodeSchema,
  EmailSchema,
  DateOfBirthSchema,
} from '../../../../utils/commonSchema';

const SHORT_NAME_MESSAGE = "!!We're getting to know you. Please enter your full name.";
const SHORT_NAME_PART_MESSAGE = namePart =>
  `!!We're getting to know you. Please enter your ${namePart} name.`;
const DOB_MESSAGE = '!!Please enter your date of birth MM/DD/YYYY';
const MINIMAL_PASSWORD_STRENGTH = 2;

export const name = 'activation';

export const fields = {
  name: {
    name: 'name',
    component: ReduxFormCleanAlphabet,
  },
  firstName: {
    name: 'firstName',
    component: ReduxFormCleanAlphabet,
  },
  lastName: {
    name: 'lastName',
    component: ReduxFormCleanAlphabet,
  },
  dob: {
    name: 'dob',
    component: ReduxFormCleanDateInput,
  },
  phoneNumber: {
    name: 'phoneNumber',
    component: ReduxFormCleanPhoneNumber,
  },
  memberId: {
    name: 'memberId',
    component: ReduxFormCleanInput,
  },
  verificationCode: {
    name: 'verificationCode',
    component: ReduxFormCleanInput,
  },
  password: {
    name: 'password',
    component: ReduxFormCleanPasswordInput,
  },
  repeatPassword: {
    name: 'repeatPassword',
    component: ReduxFormCleanRepeatPasswordInput,
  },
  /**
   * This field is a computed field that we never render (hidden field)
   * We use this field for validating the password field
   * (must be stronger than the minimal strength).
   */
  passwordStrength: {
    name: 'passwordStrength',
    component: 'input',
  },
  insuranceId: {
    name: 'insuranceId',
    component: ReduxFormCleanInput,
  },
  email: {
    name: 'email',
    component: ReduxFormCleanInput,
  },
};

export const getFullNameSchema = (errorMsg = SHORT_NAME_MESSAGE) =>
  Joi.string()
    // first word: [\s]*[^\s\\]+[\s]*
    // last word: [\s]+[^\s\\]+[\s]*
    // any number of words in between: [^\\]
    .regex(/^[\s]*[^\s\\]+[\s]*[^\\]*[\s]+[^\s\\]+[\s]*$/)
    .options({
      language: {
        any: {
          empty: errorMsg,
          required: errorMsg,
        },
        string: { regex: { base: errorMsg } },
      },
    })
    .required();

const getNamePartSchema = errorMsg =>
  Joi.string()
    .options({
      language: {
        any: {
          empty: errorMsg,
          required: errorMsg,
        },
        string: { regex: { base: errorMsg } },
      },
    })
    .required();

export const schema = {
  name: getFullNameSchema(),

  firstName: getNamePartSchema(SHORT_NAME_PART_MESSAGE('first')),

  lastName: getNamePartSchema(SHORT_NAME_PART_MESSAGE('last')),

  phoneNumber: PhoneNumberSchema.required(),

  password: PasswordSchema.required(),

  repeatPassword: PasswordSchema.required(),

  passwordStrength: Joi.object().keys({
    score: Joi.number(),
    deprecatedCharacters: Joi.number(),
  }),

  verificationCode: VerificationCodeSchema.required(),

  email: EmailSchema.required(),

  dob: DateOfBirthSchema.required().options({
    language: {
      any: {
        required: DOB_MESSAGE,
      },
      date: {
        base: DOB_MESSAGE,
        max: DOB_MESSAGE,
      },
    },
  }),

  memberId: Joi.string().options({
    language: {
      any: { required: '!!Member Id is required' },
    },
  }),
};

/**
 * Custom validator for the password strength, uses the hidden field "passwordStrength".
 * @type {String}
 */
export const customValidator = {
  [fields.password.name]: (value, values) => {
    if (!values[fields.passwordStrength.name]) return undefined;

    const { deprecatedCharacters } = values[fields.passwordStrength.name];
    const passwordStrength = values[fields.passwordStrength.name].score;

    if (deprecatedCharacters) {
      return `Password should contain only allowed special characters: ${PASSWORD_ALLOWED_CHARACTERS}`;
    }
    if (!passwordStrength || passwordStrength < MINIMAL_PASSWORD_STRENGTH) {
      return 'Password is too weak';
    }
    return undefined;
  },
  [fields.repeatPassword.name]: (value, values) => {
    if (values[fields.password.name] !== values[fields.repeatPassword.name]) {
      return 'Passwords are not identical';
    }
    return undefined;
  },
};
