import NotFoundRoutes from '../notFoundRoutes';

class NotFoundRouteStrategy {
  static predicate() {
    return true;
  }

  static getRoutes() {
    return [...NotFoundRoutes];
  }
}

export default NotFoundRouteStrategy;
