import { autobind } from 'core-decorators';
import BkmdApi from './bkmdApi';

@autobind
class PeriscopeApi extends BkmdApi {
  constructor(fetch) {
    super(fetch, '/periscope');
  }

  dashboard(id, filters) {
    return this.get(`/dashboard/${id}`, { filters }).then(({ data }) => data);
  }
}

export default PeriscopeApi;
