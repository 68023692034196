/**
 * Created by matan on 3/15/17.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages } from 'react-intl';

import BkmdModal from '../../components/bkmdModal/bkmdModal';
import ModalHeader from '../../components/bkmdModal/modalHeader';
import IconsDemo from '../fonts/iconsDemo/iconsDemo';

const messages = defineMessages({
  title: {
    defaultMessage: 'Icons',
    id: 'activation.helpModal.title'
  },
});

class IconsModal extends React.PureComponent {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
  };

  render() {
    const { isOpen, handleClose } = this.props;
    return (
      <BkmdModal
        isOpen={isOpen}
        handleClose={handleClose}
        className={'icons-modal fixed-header large-modal'}
      >
        <ModalHeader
          onClose={handleClose}
          title={messages.title}
        />

        <div className="dialog-body">
          <IconsDemo />
        </div>
      </BkmdModal>
    );
  }
}

export default IconsModal;
