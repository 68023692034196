/**
 * Created by chenrozenes on 23/04/2017.
 */
import _ from 'lodash';
import { validatePermissions } from './util';
import * as PermissionLevel from '../../model/enum/permissionLevel';
import { DOMAIN_ACCESS } from '../../model/enum/aclResourceType';
import DomainResourceType from '../../model/enum/domainResourceType';

export const isAuthenticated = state => _.get(state, 'auth.isAuthenticated');
export const isAuthLoading = state => state.auth.loading;
export const getUser = state => _.get(state, 'auth.user', {});
export const getPermissions = state => _.get(state, 'auth.permissions', []);
export const getPhiAccess = state => _.get(state, 'auth.hasPhiAccess', []);
export const getIdentifier = state => state.auth.identifier;
export const getExpiredToken = state => state.auth.expiredToken;
export const getReason = state => state.auth.reason;
export const getUserTags = state => _.get(state, 'auth.user.tags', []);

export const hasPermissionsSelector = (state, resourceType, permission) => {
  const permissions = getPermissions(state);
  return validatePermissions(permissions, permission, resourceType);
};

export const getUserDomainAccess = state =>
  _.chain(state.auth.permissions)
    .filter({ resourceType: DOMAIN_ACCESS })
    .filter(permission => !_.isUndefined(permission.resourceId))
    .map(permission => {
      const domain = _.parseInt(permission.resourceId, 10);
      return DomainResourceType[domain];
    })
    .value();
export const hasWritePermissionSelector = (state, resourceType) =>
  hasPermissionsSelector(state, resourceType, PermissionLevel.WRITE);

export const getLastActivity = state => state.auth.lastActivity;
