/* eslint-disable react/prop-types */
import React from 'react';
import {
  compose,
  setPropTypes,
  withStateHandlers,
  withPropsOnChange,
  defaultProps,
  withHandlers,
} from 'recompose';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _ from 'lodash';
import CleanTypeahead from '../../form/cleanTypeahead';
import { IntlString } from '../../ui/intlString';
import ClickableI from '../../clickableElement/clickableI';

function MultiAutoCompleteSelect({
  items,
  placeholderText,
  labelKey,
  addSelectedItem,
  itemComponents,
  onInputChange,
  isLoading,
  typeaheadClassName,
}) {
  return (
    <div className="margin-bottom-20">
      <CleanTypeahead
        placeholder={placeholderText}
        options={items}
        labelKey={labelKey}
        onOptionSelection={addSelectedItem}
        onInputChange={onInputChange}
        isLoading={isLoading}
        className={typeaheadClassName}
        clearOnSelect
      />
      {itemComponents}
    </div>
  );
}

const enhance = compose(
  setPropTypes({
    items: PropTypes.arrayOf(PropTypes.object).isRequired,
    onSelectionChange: PropTypes.func.isRequired,
    idKey: PropTypes.string.isRequired,
    itemComponent: PropTypes.any.isRequired,
    onInputChange: PropTypes.func,
    placeholderText: PropTypes.string,
    typeaheadClassName: PropTypes.string,
    labelKey: PropTypes.string,
    isLoading: PropTypes.bool,
    initialSelectedItems: PropTypes.array,
  }),
  defaultProps({
    items: [],
    onSelectionChange: _.noop,
    placheholderText: '',
    labelKey: undefined,
    isLoading: false,
    typeaheadClassName: '',
  }),

  withStateHandlers(
    ({ initialSelectedItems }) => ({ selectedItems: initialSelectedItems || [], activeText: '' }),
    {
      addSelectedItem: (state, { idKey }) => item => {
        if (_.find(state.selectedItems, selected => selected[idKey] === item[idKey])) {
          return state;
        }

        const selectedItems = [{ ...item }, ...state.selectedItems];
        return { selectedItems };
      },
      removeSelectedItem: (state, { idKey }) => id => {
        const selectedItems = _.filter(state.selectedItems, selected => selected[idKey] !== id);
        return { selectedItems };
      },
      onInputChange: ({ activeText }, { onInputChange }) => event => {
        onInputChange(event);
        return { activeText: event };
      },
      updateSelectedItem: (state, { idKey }) => (id, updates) => {
        const selectedItems = state.selectedItems.map(selected => {
          if (selected[idKey] === id) {
            return { ...selected, ...updates };
          }
          return selected;
        });

        return { selectedItems };
      },
      onAdd: (state, { idKey, addCreator }) => () => {
        const { selectedItems, activeText } = state;
        const newItem = addCreator ? addCreator(activeText) : { [idKey]: activeText };
        const selected = [newItem, ...selectedItems];
        return { activeText: '', selectedItems: selected };
      },
    },
  ),
  withPropsOnChange(
    ['selectedItems'],
    ({
      selectedItems,
      onSelectionChange,
      itemComponent,
      removeSelectedItem,
      idKey,
      labelKey,
      updateSelectedItem,
    }) => {
      onSelectionChange(selectedItems);

      const Component = itemComponent;
      const itemComponents = selectedItems.map(item => (
        <Component
          item={item}
          remove={() => removeSelectedItem(item[idKey])}
          key={item[idKey]}
          labelKey={labelKey}
          update={updates => updateSelectedItem(item[idKey], updates)}
        />
      ));
      return { itemComponents };
    },
  ),
  withHandlers({
    addSelectedItem: ({ addSelectedItem }) => item => {
      if (item) addSelectedItem(item);
    },
  }),
  withPropsOnChange(['errorText', 'errorClassName'], ({ errorText, errorClassName }) => ({
    errorComponent: _.isEmpty(errorText) ? null : (
      <IntlString
        className={classNames('validation-message', errorClassName)}
        message={errorText}
      />
    ),
  })),
);

export default enhance(
  ({ activeText, onAdd, enableAddition = false, containerClassName, ...props }) =>
    (
      <div className={classNames(containerClassName, 'typeahead-container')}>
        <MultiAutoCompleteSelect {...props} />
        {enableAddition &&
        activeText && (
          <ClickableI className="add-icon icon-plus icon-1-5 btn" clickHandler={onAdd} />
        )}
      </div>
    ),
);
