/**
 * Created by matan on 3/19/17.
 */
import React from 'react';

export default class TimeoutComponent extends React.Component {
  constructor(props, timeout) {
    super(props);
    this.timeoutHandler = setTimeout(this.timeout.bind(this), timeout);
  }

  componentWillUnmount() {
    clearInterval(this.timeoutHandler);
  }

  timeout() {
    throw new Error('Unimplemented');
  }
}
