import colorectalFlowRoutes from '../colorectalFlowRoutes';

class ColorectalFlowRoutesStrategy {
  static predicate() {
    return true;
  }

  static getRoutes() {
    return [...colorectalFlowRoutes];
  }
}

export default ColorectalFlowRoutesStrategy;
