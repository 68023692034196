/**
 *
 * This enum holds all errors codes for errors that may be returned from the server.
 */
export const RECAPTCHA_FAILED = 'RECAPTCHA_FAILED';
export const WEAK_PASSWORD = 'WEAK_PASSWORD';
export const PASSWORD_WAS_IN_USE = 'PASSWORD_WAS_IN_USE';
export const USER_ALREADY_EXIST = 'USER_ALREADY_EXIST';
export const VERIFICATION_EXPIRED = 'VERIFICATION_EXPIRED';
export const INVALID_CODE = 'INVALID_CODE';
export const MEMBER_NOT_FOUND = 'MEMBER_NOT_FOUND';
export const NOT_UNIQUE = 'NOT_UNIQUE';
export const USER_NOT_FOUND = 'USER_NOT_FOUND';
export const INVALID_RESET_TOKEN = 'INVALID_RESET_TOKEN';
export const VERIFICATION_NOT_FOUND = 'VERIFICATION_NOT_FOUND';
export const TOO_YOUNG = 'TOO_YOUNG';
export const INVALID_PHONE_NUMBER = 'INVALID_PHONE_NUMBER';
export const MAX_RESET_PASSWORD_ATTEMPTS = 'MAX_RESET_PASSWORD_ATTEMPTS';
export const USER_LOCKED = 'USER_LOCKED';
export const INVALID_PASSWORD = 'INVALID_PASSWORD';
export const ACTIVATION_TOKEN_EXPIRED = 'ACTIVATION_TOKEN_EXPIRED';
export const APPOINTMENT_CONFLICTS = 'APPOINTMENT_CONFLICTS';
export const OVERLAPPING_CONFLICT = 'OVERLAPPING_CONFLICT';
export const APPOINTMENT_NOT_FOUND = 'APPOINTMENT_NOT_FOUND';
export const USER_SHARE_BLOCKED = 'USER_SHARE_BLOCKED';
export const ADDRESS_SHARE_DENIED = 'ADDRESS_SHARE_DENIED';
export const DOMAIN_MEMBER_NOT_REGISTERED = 'DOMAIN_MEMBER_NOT_REGISTERED';
export const RECOMMENDATIONS_NOT_FOUND = 'RECOMMENDATIONS_NOT_FOUND';
export const TARGET_PROVIDER_ALREADY_EXISTS = 'TARGET_PROVIDER_ALREADY_EXISTS';
export const NO_TIMESLOT_FOR_REQ = 'NO_TIMESLOT_FOR_REQ';
export const SHORT_TOKEN_DOB_MISS_MATCH = 'SHORT_TOKEN_DOB_MISS_MATCH';
export const PROVIDERS_LIST_EMPTY = 'PROVIDERS_LIST_EMPTY';
export const SENDING_NOTIFICATION_FAILED = 'SENDING_NOTIFICATION_FAILED';
export const SHARING_SERVICE_ERROR = 'SHARING_SERVICE_ERROR';
export const PHONE_NUMBER_EXISTS_ERROR = 'PHONE_NUMBER_EXISTS_ERROR';
export const FORBIDDEN = 'Forbidden';
export const PASSWORD_EXPIRED = 'PASSWORD_EXPIRED';
export const REFERRAL_ZENDESK_ERROR = 'REFERRAL_ZENDESK_ERROR';
export const VALIDATION_SESSION_EXPIRED = 'VALIDATION_SESSION_EXPIRED';
