import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { autobind } from 'core-decorators';
import { defineMessages, FormattedMessage } from 'react-intl';

import { IntlString } from '../ui/intlString';
import Formatter from '../../utils/formatter';
import commonFormats from '../../utils/formatter/commonFormats';
import { toMoment } from '../../store/tools/seamlessImmutableHelper';
import { TimePreference } from '../../model/enum/timePreference';

const messages = defineMessages({
  appointmentTypeDescription: {
    defaultMessage: '{minutes} min',
    id: 'members.directory.bookingDetails.appointmentTypeDescription',
  },
  reasonForVisit: {
    defaultMessage: 'Reason for visit',
    id: 'members.directory.bookingDetails.reasonForVisit',
  },
  emptyReason: {
    defaultMessage: 'None specified',
    id: 'members.directory.bookingDetails.emptyReason',
  },
  availability: {
    defaultMessage: 'Availability',
    id: 'members.directory.bookingDetails.availability',
  },
  asapPreference: {
    defaultMessage: 'Just get me seen ASAP.',
    id: 'members.directory.bookingDetails.asapPreference',
  },
  calendar: {
    defaultMessage: 'calendar',
    id: 'members.directory.bookingDetails.calendar',
  },
});

// TODO: @mrsufgi make this configurable.
@autobind
class AppointmentDetailsWidget extends React.Component {
  static propTypes = {
    appointment: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    service: PropTypes.object.isRequired,
    showSelectedAppointmentType: PropTypes.bool,
  };

  static defaultProps = {
    showSelectedAppointmentType: true,
  };

  render() {
    const {
      appointment: { startTime, reasonForVisit, timePreference, timePreferenceCustomText },
      location,
      service,
      showSelectedAppointmentType,
    } = this.props;
    return (
      <div>
        {timePreference ? (
          <div className="appointment-details">
            <div className="text-16 text-dark text-semibold">
              <FormattedMessage {...messages.availability} />
            </div>
            <div className="text-14">
              {timePreference === TimePreference.ASAP ? (
                <FormattedMessage {...messages.asapPreference} />
              ) : (
                timePreferenceCustomText
              )}
            </div>
            <i className="detail-icon icon-calendar-sm" />
          </div>
        ) : null}
        {startTime ? (
          <div className="appointment-details">
            <div className="text-16 text-dark text-semibold">
              {Formatter.date(toMoment(startTime), commonFormats.SHORT_NAMING_WITH_YEAR)}
            </div>
            <div className="text-14">{Formatter.time(toMoment(startTime))}</div>
            <i className="detail-icon icon-calendar-sm" />
          </div>
        ) : null}

        <div className="appointment-details">
          <div className="text-16 text-dark text-semibold">{location.addressLine1}</div>
          <div className="text-14">
            {location.city}, {location.state} {location.zip}
          </div>
          <i className="detail-icon icon-map-pin-e" />
        </div>
        {showSelectedAppointmentType && (
          <div className="appointment-details">
            <div className="text-16 text-dark text-semibold">{service.commonName}</div>
            {service.duration ? (
              <div className="text-14">
                <FormattedMessage
                  {...messages.appointmentTypeDescription}
                  values={{
                    minutes: Math.floor(service.duration / 60000),
                  }}
                />
              </div>
            ) : null}
            <i className="detail-icon icon-user-2" />
          </div>
        )}
        <div className="appointment-details reason-for-visit">
          <div className="text-16 text-dark text-semibold">
            <FormattedMessage {...messages.reasonForVisit} />
          </div>
          <div className="text-14">
            <IntlString
              message={_.isEmpty(reasonForVisit) ? messages.emptyReason : reasonForVisit}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default AppointmentDetailsWidget;
