import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '../../storybook/src/utils/theme';

function SvgHeartBubble({ theme }) {
  /* eslint-disable */
  return (
    <svg aria-hidden="true" focusable="false" width="32px" height="30px" viewBox="0 0 32 30" version="1.1" className="svg-icon svg-icon-heart-bubble">
      <defs>
        <polygon points="0 15.00015 0 30 32 30 32 15.00015 32 0.0003 0 0.0003"></polygon>
      </defs>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-147.000000, -944.000000)">
          <g transform="translate(65.000000, 942.000000)">
            <g transform="translate(82.000000, 2.000000)">
              <g>
                <g>
                  <g></g>
                  <path d="M16,26.9063 C14.354,26.9063 12.714,26.6393 11.125,26.1133 C10.859,26.0243 10.571,26.0513 10.327,26.1863 C8.8,27.0283 7.209,27.5803 5.576,27.8353 C6.255,26.6773 6.642,25.5113 6.861,24.6043 C6.951,24.2313 6.819,23.8403 6.523,23.5963 C3.648,21.2373 2,17.9053 2,14.4533 C2,7.5863 8.28,2.0003 16,2.0003 C23.72,2.0003 30,7.5863 30,14.4533 C30,21.3203 23.72,26.9063 16,26.9063 M16,0.0003 C7.178,0.0003 0,6.4833 0,14.4533 C0,18.3013 1.727,22.0073 4.759,24.7183 C4.428,25.8153 3.826,27.1773 2.733,28.3033 C2.453,28.5923 2.373,29.0203 2.53,29.3893 C2.687,29.7603 3.049,30.0003 3.451,30.0003 C6.034,30.0003 8.541,29.3753 10.91,28.1433 C12.573,28.6493 14.283,28.9063 16,28.9063 C24.822,28.9063 32,22.4233 32,14.4533 C32,6.4833 24.822,0.0003 16,0.0003" fill={theme.mainColor} ></path>
                </g>
                <path d="M21.001,10.522 C19.877,9.678 18.262,9.879 17.394,10.971 L16,12.725 L16.008,12.735 L14.606,10.971 C13.738,9.879 12.123,9.678 10.999,10.522 C9.876,11.366 9.668,12.937 10.537,14.029 L12.75,16.813 L14.81,19.403 C15.077,19.764 15.509,20 16,20 C16.491,20 16.923,19.764 17.19,19.403 L19.25,16.813 L19.25,16.813 L21.463,14.029 C22.332,12.937 22.125,11.366 21.001,10.522" fill={theme.secondaryColor} ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
  /* eslint-enable */
}

SvgHeartBubble.propTypes = {
  theme: PropTypes.object.isRequired,
};

export default withTheme(SvgHeartBubble);
