import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '../../storybook/src/utils/theme';

function SvgDoctorStuffStars({ theme }) {
  /* eslint-disable */
  return (

    <svg aria-hidden="true" focusable="false" width="157px" height="140px" viewBox="0 0 157 140" className="svg-icon svg-doctor-stuff-stars">
      <defs>
        <path d="M0.395,4.938 C0.395,7.571 2.53,9.71 5.167,9.71 C7.805,9.71 9.941,7.571 9.941,4.938 C9.941,2.302 7.805,0.162 5.167,0.162 C2.53,0.162 0.395,2.302 0.395,4.938 Z" id="path-1"></path>
      </defs>
      <g id="Subscriber---no-Children-" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="10" transform="translate(-416.000000, -224.000000)">
          <g id="Page-1" transform="translate(416.000000, 224.000000)">
            <g id="Group-29" transform="translate(0.000000, 0.290000)">
              <path d="M10.453,134.0162 C10.453,135.2902 9.419,136.3242 8.144,136.3242 C6.87,136.3242 5.836,135.2902 5.836,134.0162 C5.836,132.7412 6.87,131.7072 8.144,131.7072 C9.419,131.7072 10.453,132.7412 10.453,134.0162" id="Fill-1" fill={theme.mainColor} ></path>
              <path d="M116.453,8.0162 C116.453,9.2902 115.419,10.3242 114.144,10.3242 C112.87,10.3242 111.836,9.2902 111.836,8.0162 C111.836,6.7412 112.87,5.7072 114.144,5.7072 C115.419,5.7072 116.453,6.7412 116.453,8.0162" id="Fill-3" fill={theme.mainColor} ></path>
              <path d="M156.5458,97.8873 C156.5458,98.7733 155.8268,99.4913 154.9418,99.4913 C154.0558,99.4913 153.3368,98.7733 153.3368,97.8873 C153.3368,97.0013 154.0558,96.2833 154.9418,96.2833 C155.8268,96.2833 156.5458,97.0013 156.5458,97.8873" id="Fill-5" fill={theme.mainColor} ></path>
              <path d="M8.5739243,24.2123238 C10.8054849,24.8047965 12.5956883,26.595 13.1862518,28.8255834 C14.5144783,33.8324704 10.0276704,38.3192783 5.02048932,36.9909737 C2.78931507,36.3986035 0.999111664,34.6084 0.408548247,32.3778166 C-0.919731082,27.3707307 3.56629934,22.8842425 8.5739243,24.2123238 Z M3.30843934,31.6092913 C3.62332824,32.7986399 4.60012346,33.7754351 5.79001655,34.0913482 C8.57112959,34.8291217 11.0243217,32.3759296 10.2863607,29.5941087 C9.9714888,28.4048245 8.99478223,27.4280706 7.8046825,27.112025 C5.02289766,26.3744065 2.57041273,28.8272232 3.30843934,31.6092913 Z" id="Stroke-7" fill={theme.mainColor} fillRule="nonzero"></path>
              <path d="M151.9208,113.8033 L154.7011,113.8033 C155.529527,113.8033 156.2011,114.474873 156.2011,115.3033 C156.2011,116.131727 155.529527,116.8033 154.7011,116.8033 L151.9208,116.8033 L151.9208,119.584 C151.9208,120.412427 151.249227,121.084 150.4208,121.084 C149.592373,121.084 148.9208,120.412427 148.9208,119.584 L148.9208,116.8033 L146.1411,116.8033 C145.312673,116.8033 144.6411,116.131727 144.6411,115.3033 C144.6411,114.474873 145.312673,113.8033 146.1411,113.8033 L148.9208,113.8033 L148.9208,111.024 C148.9208,110.195573 149.592373,109.524 150.4208,109.524 C151.249227,109.524 151.9208,110.195573 151.9208,111.024 L151.9208,113.8033 Z" id="Combined-Shape" fill={theme.mainColor} fillRule="nonzero"></path>
              <path d="M127.829,3.4825 C127.829,5.2515 126.395,6.6845 124.627,6.6845 C122.858,6.6845 121.425,5.2515 121.425,3.4825 C121.425,1.7135 122.858,0.2805 124.627,0.2805 C126.395,0.2805 127.829,1.7135 127.829,3.4825" id="Fill-13" fill={theme.mainColor} ></path>
              <path d="M43.3866,3.5318 C43.3866,5.3008 41.9526,6.7338 40.1846,6.7338 C38.4156,6.7338 36.9816,5.3008 36.9816,3.5318 C36.9816,1.7628 38.4156,0.3298 40.1846,0.3298 C41.9526,0.3298 43.3866,1.7628 43.3866,3.5318" id="Fill-15" fill={theme.mainColor} ></path>
              <path d="M109.5701,101.4478 L109.5701,97.4338 C109.5701,83.7394751 100.760328,71.7449161 87.9546352,67.4851169 C86.8343178,67.1124446 86.5637959,65.6514757 87.4763991,64.9023754 C90.9721677,62.0329123 93.0301,57.8536821 93.0301,53.3278 C93.0301,45.0218677 86.2963135,38.2878 77.9901,38.2878 C69.6838865,38.2878 62.9501,45.0218677 62.9501,53.3278 C62.9501,57.8539109 65.0082124,62.0330159 68.504647,64.9022491 C69.4174306,65.6512948 69.1469577,67.1124478 68.0265324,67.4851277 C55.2200454,71.7448672 46.4101,83.7392888 46.4101,97.4338 L46.4101,101.4478 L109.5701,101.4478 Z M112.5701,97.4338 L112.5701,102.9478 C112.5701,103.776227 111.898527,104.4478 111.0701,104.4478 L44.9101,104.4478 C44.0816729,104.4478 43.4101,103.776227 43.4101,102.9478 L43.4101,97.4338 C43.4101,83.2755409 52.0080298,70.7778376 64.7686196,65.4994892 C61.7102044,62.2109 59.9501,57.918619 59.9501,53.3278 C59.9501,43.3650415 68.0270042,35.2878 77.9901,35.2878 C87.9531958,35.2878 96.0301,43.3650415 96.0301,53.3278 C96.0301,57.918392 94.2701531,62.2106834 91.2122413,65.499415 C103.972249,70.7777859 112.5701,83.2756563 112.5701,97.4338 Z" id="Stroke-17" fill={theme.secondaryColor} fillRule="nonzero"></path>
              <path d="M63.2577,77.3278066 L63.2577,67.663 C63.2577,66.8345729 63.9292729,66.163 64.7577,66.163 C65.5861271,66.163 66.2577,66.8345729 66.2577,67.663 L66.2577,77.3277818 C70.0240444,78.0312178 72.8735,81.3346462 72.8735,85.3055 L72.8735,91.9215 C72.8735,92.7499271 72.2019271,93.4215 71.3735,93.4215 C70.5450729,93.4215 69.8735,92.7499271 69.8735,91.9215 L69.8735,85.3055 C69.8735,82.4789271 67.5840729,80.1895 64.7575,80.1895 C61.9324271,80.1895 59.6425,82.4794271 59.6425,85.3055 L59.6425,91.9215 C59.6425,92.7499271 58.9709271,93.4215 58.1425,93.4215 C57.3140729,93.4215 56.6425,92.7499271 56.6425,91.9215 L56.6425,85.3055 C56.6425,81.335103 59.4923278,78.0313423 63.2577,77.3278066 Z" id="Combined-Shape" fill={theme.secondaryColor} fillRule="nonzero"></path>
              <path d="M92.7216,76.484 C92.7216,77.3124271 92.0500271,77.984 91.2216,77.984 C90.3931729,77.984 89.7216,77.3124271 89.7216,76.484 L89.7216,67.663 C89.7216,66.8345729 90.3931729,66.163 91.2216,66.163 C92.0500271,66.163 92.7216,66.8345729 92.7216,67.663 L92.7216,76.484 Z" id="Stroke-23" fill={theme.secondaryColor} fillRule="nonzero"></path>
              <path d="M91.9158073,78.0677995 C90.3538219,77.685057 88.7784613,78.6401487 88.3956995,80.2008927 C88.0128483,81.7633219 88.9674806,83.3382612 90.528935,83.7210354 C92.0901119,84.1037415 93.6661661,83.1483732 94.0489528,81.5876937 C94.4316182,80.0265164 93.4762204,78.4504802 91.9158073,78.0677995 Z M96.9626646,82.302035 C96.1852656,85.4719469 92.9849733,87.4119324 89.814665,86.6347646 C86.643948,85.8574967 84.7047938,82.6583066 85.4819703,79.4866225 C86.2593798,76.3166683 89.4587611,74.3769839 92.6300775,75.1540703 C95.7998469,75.9314344 97.7398324,79.1317267 96.9626646,82.302035 Z" id="Stroke-25" fill={theme.secondaryColor} fillRule="nonzero"></path>
              <path d="M33.1679,14.4346 L33.1679,15.9346 C28.2037428,15.9346 24.1659,20.0638016 24.1659,25.1746 C24.1659,26.1956822 24.3672057,27.3113889 24.7597843,28.5080165 C25.5495488,30.9153155 27.0716536,33.5443323 29.1128638,36.2407434 C30.2270807,37.7126089 31.4240101,39.1101054 32.6206066,40.3848266 C32.8095844,40.5861427 32.9923164,40.7776916 33.1678487,40.9589343 C33.3433489,40.777707 33.5260466,40.5861754 33.7149884,40.3848786 C34.9114699,39.1101585 36.1082844,37.712663 37.2223947,36.2407982 C39.2634121,33.544385 40.7853738,30.9153651 41.5750662,28.5080567 C41.9676111,27.3114179 42.1689,26.1956979 42.1689,25.1746 C42.1689,20.0628138 38.1330851,15.9346 33.1679,15.9346 L33.1679,14.4346 Z M33.1679,14.4346 L33.1679,12.9346 C39.805329,12.9346 45.1689,18.4209686 45.1689,25.1746 C45.1689,26.5358524 44.9117535,27.9611844 44.4256107,29.4431481 C43.5164444,32.2146626 41.8385055,35.1131157 39.6144053,38.0514018 C38.4314218,39.6142558 37.1670645,41.0906196 35.9023741,42.4380089 C35.459144,42.9102221 35.0468562,43.3332495 34.6756824,43.7015753 C34.4499768,43.9255492 34.2859611,44.083513 34.193829,44.1698896 C33.616857,44.710818 32.7190207,44.7108394 32.142023,44.1699383 C32.0498827,44.0835623 31.8858519,43.925599 31.6601253,43.7016256 C31.2889168,43.3333003 30.8765902,42.9102735 30.4333184,42.4380609 C29.1685086,41.0906727 27.9040318,39.6143099 26.7209362,38.0514566 C24.4966226,35.1131684 22.8185221,32.2147122 21.9092661,29.4431883 C21.4230734,27.9612135 21.1659,26.535868 21.1659,25.1746 C21.1659,18.4217841 26.53168,12.9346 33.1679,12.9346 L33.1679,14.4346 Z" id="Stroke-27" fill={theme.secondaryColor}  fillRule="nonzero"></path>
            </g>
            <g id="Group-70" transform="translate(15.000000, 11.290000)" fill={theme.secondaryColor} >
              <path d="M18.1669,9.1627 L18.1669,10.6627 C16.3595846,10.6627 14.8949,12.1288691 14.8949,13.9377 C14.8949,15.743849 16.3599026,17.2097 18.1669,17.2097 C19.9756852,17.2097 21.4409,15.744061 21.4409,13.9377 C21.4409,12.1286568 19.9760033,10.6627 18.1669,10.6627 L18.1669,9.1627 Z M18.1669,9.1627 L18.1669,7.6627 C21.6333436,7.6627 24.4409,10.4722882 24.4409,13.9377 C24.4409,17.4007209 21.6327338,20.2097 18.1669,20.2097 C14.7026594,20.2097 11.8949,17.4003146 11.8949,13.9377 C11.8949,10.4726947 14.7020497,7.6627 18.1669,7.6627 L18.1669,9.1627 Z" id="Stroke-34" fillRule="nonzero"></path>
              <path d="M120.3466,109.9771 C120.3466,104.478527 115.889173,100.0211 110.3906,100.0211 C104.891796,100.0211 100.4336,104.478758 100.4336,109.9771 C100.4336,115.475442 104.891796,119.9331 110.3906,119.9331 C115.889173,119.9331 120.3466,115.475673 120.3466,109.9771 Z M120.551237,118.016576 L128.635022,126.101302 C129.220774,126.687122 129.220719,127.63687 128.634898,128.222622 C128.049078,128.808374 127.09933,128.808319 126.513578,128.222498 L118.429899,120.137878 C116.220884,121.888033 113.427791,122.9331 110.3906,122.9331 C103.235023,122.9331 97.4336,117.132377 97.4336,109.9771 C97.4336,102.821823 103.235023,97.0211 110.3906,97.0211 C117.546027,97.0211 123.3466,102.821673 123.3466,109.9771 C123.3466,113.014373 122.301477,115.807534 120.551237,118.016576 Z" id="Combined-Shape" fillRule="nonzero"></path>
              <path d="M124.9286,16.6095 C124.9286,9.52892712 119.189173,3.7895 112.1086,3.7895 C105.027804,3.7895 99.2876,9.52915025 99.2876,16.6095 C99.2876,23.6898497 105.027804,29.4295 112.1086,29.4295 C119.189173,29.4295 124.9286,23.6900729 124.9286,16.6095 Z M127.9286,16.6095 C127.9286,25.3469271 120.846027,32.4295 112.1086,32.4295 C103.371015,32.4295 96.2876,25.3467688 96.2876,16.6095 C96.2876,7.87223122 103.371015,0.7895 112.1086,0.7895 C120.846027,0.7895 127.9286,7.87207288 127.9286,16.6095 Z" id="Stroke-40" fillRule="nonzero"></path>
              <path d="M110.453565,3.61174691 C109.723088,3.22098637 109.447693,2.31204317 109.838453,1.58156545 C110.229214,0.85108773 111.138157,0.575692544 111.868635,0.966453086 C117.593597,4.02895489 121.2461,9.99724391 121.2461,16.6091 C121.2461,23.2214964 117.59304,29.1900078 111.867635,32.2527469 C111.137157,32.6435075 110.228214,32.3681123 109.837453,31.6376346 C109.446693,30.9071568 109.722088,29.9982136 110.452565,29.6074531 C115.211661,27.0616305 118.2461,22.1038453 118.2461,16.6091 C118.2461,11.114834 115.212167,6.15730526 110.453565,3.61174691 Z" id="Stroke-42" fillRule="nonzero"></path>
              <path d="M112.903523,29.1081428 C113.60595,29.5473321 113.819347,30.4727954 113.380157,31.1752227 C112.940968,31.8776501 112.015505,32.0910466 111.313077,31.6518572 C106.164887,28.4329757 102.9703,22.794555 102.9703,16.609 C102.9703,10.4240757 106.165287,4.78547579 111.312953,1.56622071 C112.015337,1.12696249 112.940821,1.34026833 113.380079,2.04265264 C113.819338,2.74503694 113.606032,3.67052107 112.903647,4.10977929 C108.623704,6.78637701 105.9703,11.4691761 105.9703,16.609 C105.9703,21.7494542 108.623279,26.4319404 112.903523,29.1081428 Z" id="Stroke-44" fillRule="nonzero"></path>
              <path d="M110.6083,3.2437 C110.6083,2.41527288 111.279873,1.7437 112.1083,1.7437 C112.936727,1.7437 113.6083,2.41527288 113.6083,3.2437 L113.6083,29.9747 C113.6083,30.8031271 112.936727,31.4747 112.1083,31.4747 C111.279873,31.4747 110.6083,30.8031271 110.6083,29.9747 L110.6083,3.2437 Z" id="Stroke-46" fillRule="nonzero"></path>
              <path d="M125.4745,15.1095 C126.302927,15.1095 126.9745,15.7810729 126.9745,16.6095 C126.9745,17.4379271 126.302927,18.1095 125.4745,18.1095 L98.7435,18.1095 C97.9150729,18.1095 97.2435,17.4379271 97.2435,16.6095 C97.2435,15.7810729 97.9150729,15.1095 98.7435,15.1095 L125.4745,15.1095 Z" id="Stroke-48" fillRule="nonzero"></path>
              <path d="M9.8368,99.7447 C4.6308,99.7447 0.4108,103.9657 0.4108,109.1717 C0.4108,114.3777 4.6308,118.5987 9.8368,118.5987 C15.0438,118.5987 19.2628,114.3777 19.2628,109.1717 C19.2628,103.9657 15.0438,99.7447 9.8368,99.7447 M9.8368,102.7447 C13.3798,102.7447 16.2628,105.6277 16.2628,109.1717 C16.2628,112.7157 13.3798,115.5987 9.8368,115.5987 C6.2938,115.5987 3.4108,112.7157 3.4108,109.1717 C3.4108,105.6277 6.2938,102.7447 9.8368,102.7447" id="Fill-50"></path>
              <path d="M19.6259,99.7447 C14.4179,99.7447 10.1969,103.9657 10.1969,109.1717 C10.1969,114.3777 14.4179,118.5987 19.6259,118.5987 C24.8299,118.5987 29.0519,114.3777 29.0519,109.1717 C29.0519,103.9657 24.8299,99.7447 19.6259,99.7447 M19.6259,102.7447 C23.1689,102.7447 26.0519,105.6277 26.0519,109.1717 C26.0519,112.7157 23.1689,115.5987 19.6259,115.5987 C16.0809,115.5987 13.1969,112.7157 13.1969,109.1717 C13.1969,105.6277 16.0809,102.7447 19.6259,102.7447" id="Fill-52"></path>
              <path d="M21.1259,122.9947 L23.7294,122.9947 L23.7294,125.9947 L21.1259,125.9947 L21.1259,128.5832 L18.1259,128.5832 L18.1259,125.9947 L15.5214,125.9947 L15.5214,122.9947 L18.1259,122.9947 L18.1259,117.0982 L21.1259,117.0982 L21.1259,122.9947 Z M11.3368,123.462296 L12.4398926,122.35878 L14.5616203,124.479693 L10.8981639,128.144556 C10.3124104,128.730535 9.36250582,128.730626 8.77663983,128.14476 L5.11197966,124.4801 L7.2333,122.35878 L8.3368,123.46228 L8.3368,118.5982 C8.3368,117.769773 9.00837288,117.0982 9.8368,117.0982 C10.6652271,117.0982 11.3368,117.769773 11.3368,118.5982 L11.3368,123.462296 Z" id="Combined-Shape" fillRule="nonzero"></path>
            </g>
            <path d="M109.201554,129.360223 C109.927354,128.960841 110.839494,129.225455 111.238877,129.951254 C111.638259,130.677054 111.373645,131.589194 110.647846,131.988577 C90.5198742,143.064297 65.799548,142.563418 46.1365475,130.631772 C45.4283129,130.202011 45.2025659,129.279482 45.6323277,128.571248 C46.0620894,127.863013 46.9846178,127.637266 47.6928525,128.067028 C66.4375008,139.441411 90.0128625,139.919091 109.201554,129.360223 Z" id="Stroke-62" fill={theme.mainColor} fillRule="nonzero"></path>
            <path d="M134.083086,46.9247924 C133.698866,46.1908535 133.982369,45.2844064 134.716308,44.9001861 C135.450246,44.5159658 136.356694,44.7994687 136.740914,45.5334076 C146.619506,64.4035261 146.513691,87.121339 136.451139,105.899584 C136.059854,106.629781 135.150713,106.904523 134.420516,106.513239 C133.690319,106.121954 133.415577,105.212813 133.806861,104.482616 C143.39987,86.5806104 143.500786,64.914514 134.083086,46.9247924 Z" id="Stroke-64" fill={theme.mainColor} fillRule="nonzero"></path>
            <path d="M50.5248101,21.2015246 C49.7946208,21.5928239 48.8854747,21.3180993 48.4941754,20.5879101 C48.1028761,19.8577208 48.3776007,18.9485747 49.1077899,18.5572754 C67.8860938,8.49421683 90.6028713,8.38840637 109.474906,18.266441 C110.20887,18.6506136 110.492432,19.5570423 110.108259,20.2910061 C109.724086,21.0249699 108.817658,21.3085317 108.083694,20.924359 C90.0920012,11.5071147 68.4268839,11.6080267 50.5248101,21.2015246 Z" id="Stroke-66" fill={theme.mainColor} fillRule="nonzero"></path>
            <path d="M25.6480602,105.078124 C26.0474591,105.803915 25.7828663,106.716061 25.0570757,107.11546 C24.3312851,107.514859 23.4191387,107.250266 23.0197398,106.524476 C12.1166206,86.7112475 12.4237498,62.4242846 23.8532407,42.8907664 C24.2716149,42.1757458 25.190413,41.9352664 25.9054336,42.3536407 C26.6204542,42.7720149 26.8609336,43.690813 26.4425593,44.4058336 C15.5466919,63.0273654 15.2537861,86.1895733 25.6480602,105.078124 Z" id="Stroke-68" fill={theme.mainColor} fillRule="nonzero"></path>
          </g>
        </g>
      </g>
    </svg>
  );
  /* eslint-enable */
}

SvgDoctorStuffStars.propTypes = {
  theme: PropTypes.object.isRequired,
};

export default withTheme(SvgDoctorStuffStars);
