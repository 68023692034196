/**
 * Created by galgoltzman on 26/06/2017.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { autobind } from 'core-decorators';

const messages = defineMessages({
  inviteYourFamilyLater: {
    defaultMessage: 'Invite your family later',
    id: 'inviteLater.inviteYourFamilyLater',
  },
  select: {
    defaultMessage: 'Select “Invite your family” from your settings whenever you’re ready.',
    id: 'inviteLater.select',
  },
  gotIt: {
    defaultMessage: 'Got it',
    id: 'inviteLater.gotIt',
  },
});

@autobind
class InviteLaterModal extends React.PureComponent {
  static propTypes = {
    onNext: PropTypes.func.isRequired,
  };

  render() {
    const { onNext } = this.props;
    return (
      <div className="">
        <div className="text-18 text-semibold text-dark text-center">
          <FormattedHTMLMessage {...messages.inviteYourFamilyLater} />
        </div>
        <div className="margin-top-20 text-16 text-center">
          <FormattedMessage {...messages.select} />
        </div>
        <div className="row margin-top-20">
          <div className="col-xs-12">
            <button className="btn btn-big btn-outline btn-sm-block" onClick={onNext}>
              <FormattedMessage {...messages.gotIt} />
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default InviteLaterModal;
