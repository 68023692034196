/**
 * Created by guyavarham on 03/01/2018.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import { connect } from 'react-redux';
import { compose } from 'redux';

import actionTracker, {
  actionTrackerShape,
} from '../../../../../store/tools/actionTracker/actionTrackerComponent';
import {
  VALIDATE_PHONE_NUMBER,
  validatePhoneNumber,
} from '../../../../../store/activation/actions';

import MobileNumber from './mobileNumber';
import { FlowShape } from '../../../../../components/wizard/flowManager';
import * as FlowDefs from '../activateWithMemberId/fullActivationFlowDefs';
import { resetRecaptch } from '../../../../../store/recaptcha/actions';
import injectNotification from '../../../../../store/notification/injectNotification';
import * as Errors from '../../../../../api/errors';
import errorMapping from '../activationErrorMapping';

@autobind
class MobileNumberFlowStep extends React.PureComponent {
  static propTypes = {
    flow: FlowShape,
    validatePhoneNumber: PropTypes.func.isRequired,
    alreadyActivatedModalShow: PropTypes.func.isRequired,
    resetRecaptch: PropTypes.func.isRequired,
    tracker: actionTrackerShape.isRequired,
    source: PropTypes.string.isRequired,
    notification: PropTypes.object.isRequired,
  };

  static defaultProps = {
    flow: undefined,
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.tracker.finished) {
      if (nextProps.tracker.hasError) {
        this.handleError(nextProps.tracker.error);
      } else {
        this.props.flow.next();
      }
    }
  }

  handleError(error) {
    const { responseMessage } = error;
    switch (responseMessage) {
      case Errors.INVALID_PHONE_NUMBER: {
        this.props.notification.error(
          errorMapping[responseMessage].title,
          errorMapping[responseMessage].message,
          { autoDismiss: 10 },
        );
        break;
      }
      case Errors.USER_ALREADY_EXIST: {
        this.props.alreadyActivatedModalShow();
        break;
      }
      default:
        break;
    }

    // In case of an error, reset the Recaptch in the last page
    this.props.resetRecaptch();
  }

  validatePhoneNumber({ phoneNumber }) {
    this.props.validatePhoneNumber(phoneNumber, this.props.source);
  }

  render() {
    return (
      <MobileNumber
        stepName={FlowDefs.steps.ENTER_PHONE_NUMBER}
        onSubmit={this.validatePhoneNumber}
        loading={this.props.tracker.inProgress}
      />
    );
  }
}

export default compose(
  // todo: createToggleState('alreadyActivatedModal'),
  injectNotification,
  connect(
    null,
    { validatePhoneNumber, resetRecaptch },
  ),
  actionTracker({
    tracker: VALIDATE_PHONE_NUMBER.SOURCE,
  }),
)(MobileNumberFlowStep);
