/**
 * Created by matan on 3/15/17.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, FormattedMessage } from 'react-intl';

import BkmdModal from '../../../../../components/bkmdModal/bkmdModal';
import ModalHeader from '../../../../../components/bkmdModal/modalHeader';
import HelpContactInfo from '../../help/helpContactInfo';

const messages = defineMessages({
  title: {
    defaultMessage: 'Your account',
    id: 'activation.phoneNumberTaken.Modal.title',
  },
  subtitle1: {
    defaultMessage: 'The phone number you entered is already taken',
    id: 'activation.phoneNumberTaken.Modal.subtitle1',
  },
  mainText1: {
    defaultMessage: 'Please contact our support.',
    id: 'activation.phoneNumberTaken.mainText1',
  },
});

function renderSection(title, text) {
  return (
    <div className="help-content">
      <div className="row">
        <div className="col-xs-12 activation-modal-subtitle">
          <span className="text-18 text-semibold text-dark">
            <FormattedMessage {...title} />
          </span>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 text-16">
          <FormattedMessage {...text} />
        </div>
      </div>
      <HelpContactInfo />
    </div>
  );
}

export default function PhoneNumberTaken(props) {
  return (
    <BkmdModal {...props} className="fixed-header">
      <ModalHeader onClose={props.handleClose} title={messages.title} />

      <div className="dialog-body">
        <div className="row">
          <div className="col-xs-12 text-center">
            <img
              src={require('../images/help-modal-top.png')}
              className="activation-help-modal-img"
              alt=""
            />
          </div>
        </div>
        {renderSection(messages.subtitle1, messages.mainText1)}
      </div>
    </BkmdModal>
  );
}

PhoneNumberTaken.propTypes = {
  /**
   * Hook method.
   * Called whenever the modal should be closed
   */
  handleClose: PropTypes.func.isRequired,
  /**
   * Name for identifying via analytics
   */
  name: PropTypes.string, // eslint-disable-line react/no-unused-prop-types
};

PhoneNumberTaken.defaultProps = {
  name: 'phone_number_taken_modal',
};
