import React from 'react';
import PropTypes from 'prop-types';
import { compose, pure, setDisplayName } from 'recompose';
import classNames from 'classnames';
import { defineMessages } from 'react-intl';
import _ from 'lodash';
import { object, string } from 'yup';
import { Field, Form } from 'formik';
import { WrappedFormik } from '../components/form/formik/wrappedFormik';
import FormActionButtons from '../components/molecules/FormSubmissionButtons/FormActionButtons';
import { CleanFormikZipInput } from '../components/form/formik/cleanFormikZipInput';
import { CleanFormikInput } from '../components/form/formik/cleanFormikInput';
import { CleanFormikStateSelect } from '../components/form/formik/cleanFormikStateSelect';

const messages = defineMessages({
  addressLine1: {
    defaultMessage: 'Address Line 1',
    id: 'screenTemplates.addressForm.addressLine1',
  },
  addressLine2: {
    defaultMessage: 'Address Line 2',
    id: 'screenTemplates.addressForm.addressLine2',
  },
  city: {
    defaultMessage: 'City',
    id: 'screenTemplates.addressForm.city',
  },
  state: {
    defaultMessage: 'State',
    id: 'screenTemplates.addressForm.state',
  },
  zip: {
    defaultMessage: 'Zip',
    id: 'screenTemplates.addressForm.zip',
  },
});

const scheme = object().shape({
  addressLine1: string()
    .required()
    .label('Address Line 1'),
  addressLine2: string(),
  city: string()
    .required()
    .label('City'),
  state: string()
    .required()
    .label('State'),
  zip: string()
    .required()
    .label('Zip'),
});

function AddressForm({ className, title, buttonText, onButtonClick, initialValues }) {
  return (
    <WrappedFormik
      initialValues={initialValues}
      onSubmit={onButtonClick}
      validationSchema={scheme}
      render={() => (
        <Form>
          <div className={classNames('address-page padding-h-30', className)}>
            <div className="row">
              <div className="col-xs-12">
                <div className="title-24 text-strong-dark text-center no-margin">{title}</div>
              </div>
            </div>

            <div className="row margin-top-30">
              <div className="col-xs-12">
                <Field
                  name="addressLine1"
                  component={CleanFormikInput}
                  label={messages.addressLine1}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-xs-12">
                <Field
                  name="addressLine2"
                  component={CleanFormikInput}
                  label={messages.addressLine2}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-xs-6">
                <Field name="city" component={CleanFormikInput} label={messages.city} />
              </div>
              <div className="col-xs-6">
                <Field
                  name="state"
                  component={CleanFormikStateSelect}
                  label={messages.state}
                  className="text-left"
                />
              </div>
            </div>

            <div className="row">
              <div className="col-xs-12">
                <Field name="zip" component={CleanFormikZipInput} label={messages.zip} />
              </div>
            </div>

            <div className="row">
              <div className="col-xs-12">
                <FormActionButtons primaryButtonText={buttonText} />
              </div>
            </div>
          </div>
        </Form>
      )}
    />
  );
}

AddressForm.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  buttonText: PropTypes.string,
  initialValues: PropTypes.object,
  onButtonClick: PropTypes.func,
};
AddressForm.defaultProps = {
  className: undefined,
  title: undefined,
  buttonText: undefined,
  initialValues: undefined,
  onButtonClick: _.noop,
};

const enhance = compose(
  setDisplayName('SCR011AddressForm'),
  pure,
);

export default enhance(AddressForm);
