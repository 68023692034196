/**
 * Created by chenrozenes on 01/05/2017.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import FieldWrapper from './fieldWrapper';
import CleanSwitch from '../cleanSwitch';

const Component = FieldWrapper(CleanSwitch);

@autobind
class ReduxFormCleanSwitch extends React.PureComponent {
  static propTypes = {
    input: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      checked: false
    };
  }

  /**
   * Initialize the state with initial values that are passed to the form.
   */
  componentWillMount() {
    this.setState({ checked: this.props.input.value });
  }

  onChange() {
    const checked = !this.state.checked;
    this.setState({ checked });
    this.props.input.onChange(checked);
  }

  render() {
    return (
      <Component onChange={this.onChange} {...this.props} />
    );
  }
}

export default ReduxFormCleanSwitch;
