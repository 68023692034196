import React from 'react';

export default function SvgInsurance() {
  /* eslint-disable */
  return (
    <svg aria-hidden="true" focusable="false" className="svg-icon svg-icon-insurance" width="16px" height="22px" viewBox="0 0 16 22" version="1.1">
      <desc></desc>
      <defs></defs>
      <g id="Basic-flow---no-TIN" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Patient-details" transform="translate(-157.000000, -232.000000)">
          <g id="Page-1" transform="translate(157.000000, 232.000000)">
            <path d="M16,0 L8,0 L0,0 L0,13.334 C0,14.563 0.523,15.655 1.353,16.411 L7.278,21.685 L7.336,21.736 C7.513,21.896 7.743,22 8,22 C8.239,22 8.45,21.904 8.622,21.765 L8.738,21.661 L14.638,16.411 C15.47,15.655 16,14.563 16,13.334 L16,0 Z M14,2 L14,13.334 C14,13.949 13.749,14.517 13.309,14.917 L7.995,19.646 L2.7,14.933 C2.249,14.521 2,13.954 2,13.334 L2,2 L8,2 L14,2 L14,2 Z" id="Fill-1" fill="#898989"></path>
            <polygon id="Fill-3" fill="#08ACE4" points="12 9 9 9 9 6 7 6 7 9 4 9 4 11 7 11 7 14 9 14 9 11 12 11"></polygon>
          </g>
        </g>
      </g>
    </svg>
  );
  /* eslint-enable */
}
