import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages } from 'react-intl';
import { object, string } from 'yup';
import { Field, Form } from 'formik';
import { WrappedFormik } from '../../form/formik/wrappedFormik';
import FormActionButtons from '../../molecules/FormSubmissionButtons/FormActionButtons';
import { withTranslatedMessages } from '../../../utils/withTranslatedMessages';
import { CleanFormikInput } from '../../form/formik/cleanFormikInput';

const messages = defineMessages({
  primaryButtonText: {
    defaultMessage: 'Next',
    id: 'members.directory.booking.customInsurerForm.primaryButtonText',
  },
  label: {
    defaultMessage: 'Your insurance provider',
    id: 'members.directory.booking.customInsurerForm.placeholder',
  },
});

const scheme = object().shape({
  id: string()
    .label('Insurer')
    .required('Please provide an insurance provider'),
});

class CustomInsurerForm extends React.PureComponent {
  static propTypes = {
    initialValues: PropTypes.object,
    onSubmit: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
  };

  static defaultProps = {
    initialValues: null,
  };

  render() {
    const { initialValues, onSubmit, label, ...props } = this.props;
    return (
      <WrappedFormik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={scheme}
        render={({ isValid }) => (
          <Form>
            <div className="margin-top-30">
              <Field name="id" label={label} component={CleanFormikInput} />
            </div>
            <FormActionButtons {...props} primaryDisabled={!isValid} />
          </Form>
        )}
      />
    );
  }
}

export default withTranslatedMessages(messages)(CustomInsurerForm);
