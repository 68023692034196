import _ from 'lodash';
import { autobind } from 'core-decorators';
import BkmdApi from './bkmdApi';

// eslint-disable-next-line
const SERVICE_NAME = 'scheduling';

/**
 * Represents the search doctors API
 */
@autobind
class SchedulingApi extends BkmdApi {
  /**
   * A fetch object that points to the server
   * @param fetch
   */
  constructor(fetch) {
    super(fetch, '/scheduling');
  }

  providerCSI(npi, address) {
    return this.post('/providerCsi', {
      npi,
      address,
    });
  }

  getBookingData(npi, locationId, startTime, domain, domainMemberId = undefined) {
    return this.get('/bookingData', { npi, locationId, startTime, domainMemberId }, {}, domain);
  }

  getAvailableAppointmentTypes(calendarId) {
    return this.get('/appointmentTypes', { calendarId });
  }

  shareBookAppointment(appointment) {
    return this.post('/member/shareBookAppointment', appointment);
  }

  patientBookAppointment(appointment, domain = undefined) {
    return domain
      ? this.post('/member/bookAppointment', appointment, domain)
      : this.post('/member/bookAppointment', appointment);
  }

  publicAppointmentRequest(appointment) {
    return this.post('/member/publicAppointmentRequest', appointment);
  }

  vimGuideBookAppointment(appointment, member, phoneNumber) {
    return this.post('/vimGuide/bookAppointment', { appointment, member, phoneNumber });
  }

  patientCancelAppointment(appointmentId) {
    return this._cancelAppointment(appointmentId, 'member');
  }

  providerCancelAppointment(appointmentId, domain) {
    return this._cancelAppointment(appointmentId, 'provider', domain);
  }

  providerDeclineAppointment(appointmentId, domain) {
    return this.post('/provider/decline', { appointmentId }, domain);
  }

  providerRescheduleAppointment(appointmentId, startTime, endTime, domain) {
    return this.post(
      '/provider/reschedule',
      { appointmentId, data: { startTime, endTime } },
      domain,
    );
  }

  _cancelAppointment(appointmentId, application, domain) {
    return this.post(`/${application}/cancel`, { appointmentId }, domain);
  }
}

export default SchedulingApi;
