/**
 * Created by matan on 3/19/17.
 */
import React from 'react';

export default class IntervalComponent extends React.Component {
  constructor(props, interval) {
    super(props);
    this.milliseconds = interval;
    this.timerTick = this.timerTick.bind(this);
  }

  componentWillMount() {
    this.interval = setInterval(this.timerTick, this.milliseconds);
  }

  componentWillUnmount() {
    this.clearInterval();
  }

  clearInterval() {
    if (this.interval) clearInterval(this.interval);
  }

  timerTick() {
    // nothing
  }
}
