/**
 * Created by anna on 13/04/2017.
 */
import _ from 'lodash';
import MobileDetect from 'mobile-detect';
import { isLocalStorageAccessible } from '../../utils/auth/storage';

export const TEST_LOCAL_STORAGE = 'TEST_LOCAL_STORAGE';
export const GET_PLATFORM = 'GET_PLATFORM';

export function getCurrentPlatform() {
  const md = new MobileDetect(_.get(window, 'navigator.userAgent'));
  return {
    type: GET_PLATFORM,
    payload: {
      isMobilePlatform: md.mobile() != null && !md.mobile().includes('Unknown'),
      phoneType: md.phone() || md.tablet()
    }
  };
}

export function testLocalStorage() {
  return {
    type: TEST_LOCAL_STORAGE,
    payload: isLocalStorageAccessible(),
  };
}
