import React from 'react';
import { pure, compose, setDisplayName } from 'recompose';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _ from 'lodash';
import DoctorLogo from '../components/doctorImage/doctorLogo';

const provider = {
  profilePicture: 'https://www.ushealthworks.com/GetImage.aspx?ImageName=AK-Dr%20Simono',
  firstName: 'Jane',
  lastName: 'Simono',
  vimChoice: false,
};

function Info({
  className,
  title,
  mainText,
  buttonText,
  secondaryButtonText,
  buttonType,
  showButton,
  secondaryButtonType,
  footerNote,
  svgIcon,
  showDoctorLogo,
  onButtonClick,
  onSecondaryButtonClick,
}) {
  const doctorLogo = !showDoctorLogo ? null : (
    <DoctorLogo containerClass="small" provider={provider} />
  );

  return (
    <div className={classNames('info-page padding-h-30', className)}>

      {svgIcon && (
        <div className="row margin-top margin-bottom-20">
          <div className="col-xs-12 text-center svg-72">
            {svgIcon}
          </div>
        </div>
      )}

      {doctorLogo && (
        <div className="row margin-top margin-bottom-20">
          <div className="col-xs-12 text-center svg-72">
            {doctorLogo}
          </div>
        </div>
      )}

      <div className="row">
        <div className="col-xs-12">
          <div className="title-24 text-strong-dark text-center no-margin">{title}</div>
        </div>
      </div>

      <div className="row">
        <div className="col-xs-12">
          <div className="text-18 text-center">{mainText}</div>
        </div>
      </div>

      {showButton && (
        <div className="row tiny-gutter margin-top-30">
          <div className="col-xs-12">
            <button onClick={onButtonClick} className={classNames('btn btn-big', buttonType)}>
              {buttonText}
            </button>
          </div>
          {secondaryButtonType && (
            <div className="col-xs-12 text-center">
              <button
                onClick={onSecondaryButtonClick}
                className={classNames('btn', secondaryButtonType)}
              >
                {secondaryButtonText}
              </button>
            </div>
          )}
        </div>
      )}

      <div className="row margin-top-30 margin-bottom-20">
        <div className="col-xs-12">
          <div className="text-12 text-dark text-center">{footerNote}</div>
        </div>
      </div>
    </div>
  );
}

Info.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  mainText: PropTypes.string,
  buttonText: PropTypes.string,
  secondaryButtonText: PropTypes.string,
  buttonType: PropTypes.oneOf(['bg-color-brand-button', 'btn-outline']),
  showButton: PropTypes.bool,
  secondaryButtonType: PropTypes.oneOf(['bg-color-brand-button', 'btn-outline']),
  footerNote: PropTypes.string,
  svgIcon: PropTypes.object,
  showDoctorLogo: PropTypes.bool,
  onButtonClick: PropTypes.func,
  onSecondaryButtonClick: PropTypes.func,
};
Info.defaultProps = {
  className: undefined,
  title: undefined,
  mainText: undefined,
  buttonText: undefined,
  buttonType: undefined,
  showButton: true,
  secondaryButtonText: undefined,
  secondaryButtonType: undefined,
  footerNote: undefined,
  svgIcon: undefined,
  showDoctorLogo: false,
  onButtonClick: _.noop,
  onSecondaryButtonClick: _.noop,
};

const enhance = compose(
  setDisplayName('SCR007Info'),
  pure,
);

export default enhance(Info);
