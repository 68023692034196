/**
 * Created by moran on 8/31/16.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, FormattedHTMLMessage } from 'react-intl';
import './starsLightBlue.import.less';
import { IntlString, MessageShape } from '../../ui/intlString';

const messages = defineMessages({
  sorry: {
    defaultMessage: 'We\'re Sorry, but this link has expired',
    id: 'expiredLink.expiry.sorry'
  },
  contact: {
    defaultMessage: 'Please contact book-md <br class="visible-xs-block"/> ' +
    'for another link.',
    id: 'expiredLink.expiry.contact'
  },
});

class Expiry extends React.Component {
  static propTypes = {
    sorryMessage: MessageShape,
    actionMessage: PropTypes.object,
  };

  static defaultProps = {
    sorryMessage: messages.sorry,
    actionMessage: messages.contact,
  };

  render() {
    const { sorryMessage, actionMessage } = this.props;
    return (<div className="stars-background">
      <div className="error-page-wrap text-center">
        <img src={require('./Hourglass.png')} className="header-pic" alt="" />
        <h2 className="text-center">
          <IntlString message={sorryMessage} />
        </h2>
        <div className="light-blue-content">
          <div>
            <FormattedHTMLMessage {...actionMessage} />
          </div>
        </div>
      </div>
    </div>);
  }
}

export default Expiry;

