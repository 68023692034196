import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '../../storybook/src/utils/theme';

function SvgLocationStars({ theme }) {
  /* eslint-disable */
  return (
    <svg aria-hidden="true" focusable="false" width="70px" height="71px" viewBox="0 0 70 71" version="1.1" className="svg-icon svg-location-stars">
        <defs>
          <path d="M0.27,10.75 C0.27,4.959 4.981,0.247 10.77,0.247 C16.559,0.247 21.271,4.959 21.271,10.75 C21.271,16.539 16.559,21.247 10.77,21.247 C4.981,21.247 0.27,16.539 0.27,10.75 Z M3.271,10.75 C3.271,14.883 6.635,18.247 10.77,18.247 C14.905,18.247 18.27,14.883 18.27,10.75 C18.27,6.613 14.905,3.247 10.77,3.247 C6.635,3.247 3.271,6.613 3.271,10.75 Z" id="path-1"></path>
        </defs>
        <g id="All-settings-screens" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="Location-settings" transform="translate(-31.000000, -147.000000)">
            <g id="Page-1" transform="translate(31.000000, 147.000000)">
              <g id="Group-5" transform="translate(13.000000, 0.000000)" fill={theme.mainColor} >
                <path d="M20.6875,3 C11.0385,3 3.1875,10.983 3.1875,20.797 C3.1875,32.652 16.7825,47.392 20.6875,51.381 C24.5905,47.391 38.1875,32.642 38.1875,20.797 C38.1875,10.983 30.3365,3 20.6875,3 M20.6875,55 C20.3145,55 19.9415,54.861 19.6525,54.585 C18.8575,53.827 0.1875,35.841 0.1875,20.797 C0.1875,9.329 9.3835,0 20.6875,0 C31.9915,0 41.1875,9.329 41.1875,20.797 C41.1875,35.841 22.5175,53.827 21.7225,54.585 C21.4335,54.861 21.0605,55 20.6875,55" id="Fill-1"></path>
                <path d="M20.7695,13.2471 C16.6345,13.2471 13.2705,16.6131 13.2705,20.7501 C13.2705,24.8841 16.6345,28.2471 20.7695,28.2471 C24.9055,28.2471 28.2705,24.8841 28.2705,20.7501 C28.2705,16.6131 24.9055,13.2471 20.7695,13.2471 M20.7695,31.2471 C14.9805,31.2471 10.2705,26.5381 10.2705,20.7501 C10.2705,14.9591 14.9805,10.2471 20.7695,10.2471 C26.5595,10.2471 31.2705,14.9591 31.2705,20.7501 C31.2705,26.5381 26.5595,31.2471 20.7695,31.2471" id="Fill-3"></path>
              </g>
              <g id="Group-9" transform="translate(23.000000, 10.000000)">
                <g id="Clip-7"></g>
              </g>
              <g id="Group-22" transform="translate(0.000000, 17.000000)" fill={theme.secondaryColor} >
                <path d="M64.6221,15.3887 C63.9351,15.3887 63.3151,14.9137 63.1591,14.2157 C62.1961,9.9087 60.3331,5.9377 57.6211,2.4147 C57.1151,1.7587 57.2381,0.8167 57.8941,0.3117 C58.5511,-0.1943 59.4931,-0.0713 59.9981,0.5847 C62.9791,4.4567 65.0271,8.8237 66.0871,13.5617 C66.2681,14.3697 65.7591,15.1717 64.9501,15.3527 C64.8401,15.3767 64.7301,15.3887 64.6221,15.3887" id="Fill-10"></path>
                <path d="M33.6875,54 C27.2635,54 21.0315,52.162 15.6655,48.686 C14.9695,48.235 14.7715,47.307 15.2215,46.611 C15.6725,45.915 16.6025,45.72 17.2955,46.168 C22.1755,49.329 27.8435,51 33.6875,51 C45.9125,51 56.8555,43.71 61.5655,32.428 C61.8835,31.664 62.7585,31.301 63.5275,31.622 C64.2915,31.94 64.6525,32.819 64.3335,33.584 C59.1565,45.986 47.1265,54 33.6875,54" id="Fill-12"></path>
                <path d="M6.1494,38.0049 C5.6284,38.0049 5.1234,37.7339 4.8454,37.2489 C1.9984,32.2649 0.4924,26.5779 0.4924,20.8049 C0.4924,13.4269 2.8734,6.4359 7.3774,0.5849 C7.8814,-0.0711 8.8254,-0.1941 9.4804,0.3119 C10.1364,0.8169 10.2594,1.7589 9.7534,2.4149 C5.6574,7.7359 3.4924,14.0949 3.4924,20.8049 C3.4924,26.0569 4.8614,31.2289 7.4514,35.7609 C7.8624,36.4799 7.6124,37.3969 6.8924,37.8079 C6.6584,37.9419 6.4024,38.0049 6.1494,38.0049" id="Fill-14"></path>
                <path d="M12.3691,42.6592 L10.5281,42.6592 L10.5281,40.8182 C10.5281,39.9902 9.8561,39.3182 9.0281,39.3182 C8.2001,39.3182 7.5281,39.9902 7.5281,40.8182 L7.5281,42.6592 L5.6871,42.6592 C4.8591,42.6592 4.1871,43.3312 4.1871,44.1592 C4.1871,44.9872 4.8591,45.6592 5.6871,45.6592 L7.5281,45.6592 L7.5281,47.5002 C7.5281,48.3282 8.2001,49.0002 9.0281,49.0002 C9.8561,49.0002 10.5281,48.3282 10.5281,47.5002 L10.5281,45.6592 L12.3691,45.6592 C13.1971,45.6592 13.8691,44.9872 13.8691,44.1592 C13.8691,43.3312 13.1971,42.6592 12.3691,42.6592" id="Fill-16"></path>
                <path d="M63.6875,21 C62.3085,21 61.1875,22.121 61.1875,23.5 C61.1875,24.879 62.3085,26 63.6875,26 C65.0665,26 66.1875,24.879 66.1875,23.5 C66.1875,22.121 65.0665,21 63.6875,21 M63.6875,29 C60.6555,29 58.1875,26.532 58.1875,23.5 C58.1875,20.468 60.6555,18 63.6875,18 C66.7195,18 69.1875,20.468 69.1875,23.5 C69.1875,26.532 66.7195,29 63.6875,29" id="Fill-18"></path>
                <path d="M19.4678,37.8896 C19.4678,38.6586 18.8458,39.2806 18.0768,39.2806 C17.3098,39.2806 16.6878,38.6586 16.6878,37.8896 C16.6878,37.1216 17.3098,36.4996 18.0768,36.4996 C18.8458,36.4996 19.4678,37.1216 19.4678,37.8896" id="Fill-20"></path>
              </g>
            </g>
          </g>
        </g>
      </svg>
  );
  /* eslint-enable */
}

SvgLocationStars.propTypes = {
  theme: PropTypes.object.isRequired,
};

export default withTheme(SvgLocationStars);
