/**
 * Created by asafdavid on 08/01/2018.
 */
import React, { PureComponent } from 'react';
import { components } from 'griddle-react';
import PropTypes from 'prop-types';

import NextButton from './next';
import PreviousButton from './previous';
import CustomPager from './pager';

class CustomPagination extends PureComponent {
  static propTypes = {
    style: PropTypes.object,
  };

  static defaultProps = {
    style: {},
  };

  render() {
    const { style } = this.props;
    const Prev =
      components.PreviousButtonEnhancer(components.PreviousButtonContainer(PreviousButton));
    const Next = components.NextButtonEnhancer(components.NextButtonContainer(NextButton));
    const Pager = components.PageDropdownContainer(CustomPager);

    return (
      <div style={style} className="bkmd-table-pager">
        {Prev && <Prev />}
        {Pager && <Pager />}
        {Next && <Next />}
      </div>
    );
  }
}

export default CustomPagination;
