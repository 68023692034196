/**
 * Created by matan on 7/11/16.
 */
import _ from 'lodash';
import {
  snapEngage,
  initHelpScout,
  siftScience,
  loadGooglePlacesAutocomplete,
} from './initializationSnippets';

import vimSdkClient from '../../utils/sdk/vimSdkClient';
import VimApi from '../../api/vimApi/api';

export const LOAD_SNAP_ENGAGE = 'LOAD_SNAP_ENGAGE';
export const INIT_SDK_API = 'INIT_SDK_API';
export const INIT_VIM_API = 'INIT_VIM_API';
export const SNAP_ENGAGE_MESSAGE_COUNT = 'SNAP_ENGAGE_MESSAGE_COUNT';
export const LOAD_HELP_SCOUT_BEACON = 'LOAD_HELP_SCOUT_BEACON';
export const LOAD_SIFT_SCIENCE = 'LOAD_SIFT_SCIENCE';
export const LOAD_GOOGLE_PLACES_API = 'LOAD_GOOGLE_PLACES_API';

function snapEngageUpdateMessageCount(count) {
  return {
    type: SNAP_ENGAGE_MESSAGE_COUNT,
    payload: count,
  };
}

export function startSnapEngage() {
  return ({ getState, dispatch }) => {
    const state = getState();
    const onMessageCountChanged = newCount => dispatch(snapEngageUpdateMessageCount(newCount));

    return {
      type: LOAD_SNAP_ENGAGE,
      payload: {
        promise: snapEngage(state.config.snapEngageKey, onMessageCountChanged),
      },
    };
  };
}

export function initHelpScoutBeacon() {
  return ({ getState }) => {
    const {
      config: {
        helpScout,
        features: {
          help: {
            helpScout: { apiKey, docsUrl },
          },
        },
      },
    } = getState();
    return {
      type: LOAD_HELP_SCOUT_BEACON,
      payload: {
        promise: initHelpScout(apiKey, helpScout.snippetSrc, docsUrl),
      },
    };
  };
}

export function startSiftScience() {
  return ({ getState }) => {
    const state = getState();
    return {
      type: LOAD_SIFT_SCIENCE,
      payload: {
        promise: siftScience(state.config.sift.key),
      },
    };
  };
}

export function loadGooglePlacesAutoCompleteApi() {
  return ({ getState }) => {
    const state = getState();
    return {
      type: LOAD_GOOGLE_PLACES_API,
      payload: {
        promise: loadGooglePlacesAutocomplete(state.config.googleApi.key),
      },
    };
  };
}

export function initVimApi(apiUrl, apiKey) {
  return () => ({
    type: INIT_VIM_API,
    payload: {
      promise: VimApi.initializeWithApiKey(apiUrl, apiKey),
    },
  });
}

export function initSdkApi(sdkConfig, cb = null) {
  if (_.isNil(_.get(sdkConfig, ['sdkKey'])) || _.isNil(_.get(sdkConfig, ['options', 'env']))) {
    throw new Error(
      'Must provide SDK key and options object (with options.env key) to withSchedulingSdk!',
    );
  }
  return () => ({
    type: INIT_SDK_API,
    payload: {
      promise: vimSdkClient
        .init(sdkConfig.sdkKey, sdkConfig.options)
        .then(res => (cb ? cb(res) : res)),
    },
  });
}
