import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './spinner.less';
import SvgVimLoader from '../../../assets/svg/vimLoader';
import SvgVimHeart from '../../../assets/svg/vimHeart';

class ThreeBounceSpinner extends React.PureComponent {
  static propTypes = {
    spinnerClass: PropTypes.string
  };

  static defaultProps = {
    spinnerClass: undefined
  };

  render() {
    return (
      <div
        className={classNames(
          'spinner',
          this.props.spinnerClass
        )}
        aria-live="polite"
        aria-label="loading"
      >
        <div className="bounce bounce1"></div>
        <div className="bounce bounce2"></div>
        <div className="bounce bounce3"></div>
        <span className="svg-loader">
          <SvgVimLoader />
          <SvgVimHeart />
        </span>
      </div>
    );
  }
}

export default ThreeBounceSpinner;
