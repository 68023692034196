/**
 * Created by galgoltzman on 01/02/2017.
 */
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { withRouter } from 'react-router';
import { ProgressBar } from 'react-bootstrap';
import { autobind } from 'core-decorators';
import Header from '../header/header';
import { IntlString, MessageShape } from '../intlString';
import CoOpLogo from '../../branding/co_opLogo';
import FeatureFlagged from '../../../components/features/featureFlagged';
import LanguageMenu from '../../molecules/LanguageMenu/LanguageMenu';

@autobind
class ActivationLayout extends React.Component {
  static propTypes = {
    /**
     * Router object.
     * Provided by withRouter HoC
     */
    router: PropTypes.object.isRequired,
    /**
     * Layout title. Can be either a string or an intl message
     * Default is no title
     */
    title: MessageShape,
    /**
     * Set progress bar value.
     * Default is 0
     * Null will hide the progress bar
     */
    progress: PropTypes.number,
    /**
     * Hook method. Called when the user press the layout back arrow.
     * Default is router-back
     */
    onBack: PropTypes.func,
    /**
     * Optional top level class name for the layout
     */
    className: PropTypes.string,
    /**
     * Optional class name for the inner elements surrounding container (div)
     */
    contentClassName: PropTypes.string,

    showLogos: PropTypes.bool,

    externalPageLogo: PropTypes.bool,

    showRightNavInMobile: PropTypes.bool,
  };

  static defaultProps = {
    title: '',
    progress: 0,
    onBack: undefined,
    className: '',
    contentClassName: '',
    showLogos: false,
    externalPageLogo: false,
    showRightNavInMobile: false,
  };

  onBack() {
    this.props.router.goBack();
  }

  renderProgressBar() {
    const { progress } = this.props;
    return _.isNil(progress) ? null : (
      <ProgressBar bsStyle={'warning'} now={this.props.progress} className={'small-progress'} />
    );
  }

  render() {
    const {
      onBack = this.onBack,
      title,
      className,
      contentClassName,
      showLogos,
      externalPageLogo,
      showRightNavInMobile,
    } = this.props;
    const rightNavClassName = showRightNavInMobile
      ? 'header-right-nav show-xs'
      : 'header-right-nav';
    return (
      <div className={className}>
        <Header>
          <button className="btn-inline-link header-back-btn" onClick={onBack} aria-label="Back">
            <i className="icon-back-arrow" />
          </button>
          {showLogos ? (
            <CoOpLogo className={'progress-logos'} externalPageLogo={externalPageLogo} />
          ) : null}
          <h2 className="header-title-grey">
            <IntlString message={title} />
          </h2>
          <ul className={rightNavClassName}>
            <FeatureFlagged renderLi uri="layout.changeLanguage">
              <LanguageMenu id="languageMenuProgress" />
            </FeatureFlagged>
          </ul>
          {this.renderProgressBar()}
        </Header>
        <div className="container container-1040">
          <div className="row no-margin">
            <div className="col-lg-6 col-lg-offset-3 col-sm-8 col-sm-offset-2 no-padding">
              <div className={contentClassName}>{this.props.children}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ActivationLayout);
