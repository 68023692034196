/**
 * created by moran on 11/16/17
 */
import _ from 'lodash';
import Immutable from 'seamless-immutable';
import momentTz from 'moment-timezone';
import moment from 'moment';
import { toDateString } from '../tools/seamlessImmutableHelper';
import {
  SCHEDULING_GET_AVAILABLE_SERVICES, // Should be used by the provider product - not booking
  GET_BOOKING_DATA,
  SELECT_APPOINTMENT,
  SELECT_APPOINTMENT_TYPE,
  PROVIDER_BOOK_APPOINTMENT,
  BOOK_APPOINTMENT,
  ADD_REASON_FOR_VISIT,
} from './actions';

const initialState = {
  appointments: {},
  selectedAppointment: {},
  bookedAppointment: {},
  selectedAppointmentType: {},
  services: {},
};

function enrichWithTimezone(appointment) {
  if (!appointment) return appointment;

  const startTime = momentTz.tz(appointment.startTime, appointment.timeZone);
  const endTime = momentTz.tz(appointment.endTime, appointment.timeZone);
  return {
    ...appointment,
    startTimeUTC: moment(startTime).toISOString(),
    endTimeUTC: moment(endTime).toISOString(),
    startTime: toDateString(startTime),
    endTime: toDateString(endTime)
  };
}

export default function bookingReducer(state = initialState, action) {
  state = Immutable(state);

  switch (action.type) {
    case GET_BOOKING_DATA.SUCCESS: {
      const { appointments, strategy, appointmentTypes } = action.payload;
      return state.merge({ appointments, strategy, services: appointmentTypes });
    }
    case BOOK_APPOINTMENT.SUCCESS:
    case PROVIDER_BOOK_APPOINTMENT.SUCCESS: {
      const appointment = _.get(action, 'payload.app', null);
      return state.set('bookedAppointment', enrichWithTimezone(appointment));
    }
    case SCHEDULING_GET_AVAILABLE_SERVICES.SUCCESS: {
      const services = action.payload.data;
      return state.merge({ services });
    }
    case SELECT_APPOINTMENT: {
      return state.set('selectedAppointment', action.payload);
    }
    case SELECT_APPOINTMENT_TYPE: {
      return state.set('selectedAppointmentType', action.payload);
    }
    case ADD_REASON_FOR_VISIT: {
      return state.setIn(['selectedAppointment', 'reasonForVisit'], action.payload);
    }
    default:
      return state;
  }
}
