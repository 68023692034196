const medicalTeamEvents = {
  medicalTeamTabClick: 'Click on My medical team from tab',
  medicalTeamViewDoctorDetails: 'Click on View details from main member card',
  mainMemberCard: {
    mainMemberChangeMyDoctorClick: 'Click on Change my doctor from main member card',
    applyForAllMyChildrenClick: 'Click on Apply for my children from main member card',
  },
  dependentCard: {
    changeMyChildDoctorClick: 'Click on Change my doctor from main member',
  },
};

export default medicalTeamEvents;
