/**
 * Created by chenrozenes on 07/08/2017.
 */

export const BKMD01 = 'BKMD01';
export const BKMD02 = 'BKMD02';
export const BKMD03 = 'BKMD03';
export const BKMD04 = 'BKMD04';
export const BKMD05 = 'BKMD05';
export const BKMD06 = 'BKMD06';
export const BKMD07 = 'BKMD07';
export const BKMD08 = 'BKMD08';
export const BKMD09 = 'BKMD09';
export const BKMD10 = 'BKMD10';
export const BKMD11 = 'BKMD11';
export const BKMD12 = 'BKMD12';
