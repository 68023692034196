/**
 * Created by anna on 20/07/2017.
 */
import _ from 'lodash';

export const getProviders = state => _.get(state, 'directory.providers');

export const getProviderByNpi = (state, npi) => _.get(state, `directory.providers.${npi}`);

export const getProviderByReview = state => {
  const providers = getProviders(state);
  const providerReviewNpi = _.get(state, 'reviewProcess.providerNpi', '');
  return _.get(providers, providerReviewNpi);
};
