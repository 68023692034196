import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { getFeature } from '../../../store/feature/selectors';
import FeatureFlagged from '../../../components/features/featureFlagged';
import './Disclaimer.less';

/**
 * Component for displaying disclaimer if the feature flag is on
 * 2 relevant props from the config (in redux):
 * 1. layout.disclaimer - feature flag - true/false for showing
 * 2. layout.disclaimerText - client configuration (not feature flag) - disclaimer text to display
 */
class Disclaimer extends React.PureComponent {
  static propTypes = {
    /**
     * Disclaimer text for footer if needed
     */
    disclaimerText: PropTypes.string,
  };

  static defaultProps = {
    disclaimerText: '',
  };

  render() {
    const { disclaimerText } = this.props;

    return (
      <FeatureFlagged uri="layout.disclaimer" renderChildren>
        <div className="footer-disclaimer">{disclaimerText}</div>
      </FeatureFlagged>
    );
  }
}

export default compose(
  connect(state => ({
    // Different property for disclaimer text vs disclaimer (feature flag)
    disclaimerText: getFeature(state, 'layout.disclaimerText'),
  })),
)(Disclaimer);
