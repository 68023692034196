/**
 * Created by meirshalev 18/10/2017.
 */
import Immutable from 'seamless-immutable';
import { CLEAR, SSO_SIGNIN } from './actions';

const initialState = {
  samlResponse: undefined,
};

export default function configReducer(state = initialState, action) {
  state = Immutable(state);

  switch (action.type) {
    case CLEAR:
      return { activationToken: state.activationToken };
    case SSO_SIGNIN.ERROR: {
      const actionPayload = JSON.parse(action.payload.responseMessage);
      return state.merge({
        activationToken: actionPayload.activationToken,
        firstName: actionPayload.firstName,
      });
    }
    default:
      return state;
  }
}
