/* eslint-disable import/first */
/**
 * Created by moran on 6/6/17.
 */
if (typeof require.ensure !== 'function') require.ensure = (d, c) => c(require);

import divComponent from '../../routes/divComponent';
import paths from './paths';

// Routes
const Expired = require('../../../../../components/pages/expired/expiry');
const ReviewThanks = require("../doctorReviewPages/reviewThanks");
const FlowRoutes = require('./flowRoutes');

// Components
const Components = [
  {
    path: paths.expiry(),
    name: 'expired',
    component: Expired.default || Expired,
  },
  {
    path: paths.thankYou(),
    name: 'reviewThanks',
    component: ReviewThanks.default || ReviewThanks,
  },
  FlowRoutes.default || FlowRoutes,
];

export default [
  {
    path: 'review',
    name: 'review',
    getChildRoutes(location, cb) {
      require.ensure([], () => {
        cb(null, Components);
      });
    },
    // Requiring secured page NOT dynamically so it won't be unmounted every route
    component: divComponent,
  },
];
/* eslint-enable import/first */
