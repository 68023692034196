import Helmet from 'react-helmet';
import React from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';

import messages from './messages';
import Svg404 from '../../../assets/svg/404';
import CoOpLogo from '../../branding/co_opLogo';
import Header from '../../ui/header/header';
import './notFound.less';

import { isDirectoryAllowed } from '../../../store/ui/selectors';

function GoBack({ shouldRender, goHome }) {
  return shouldRender && (
    <div>
      <button className="btn btn-big bg-color-brand-button" onClick={goHome}>
        <FormattedMessage {...messages.goBack} />
      </button>
    </div>
  );
}

GoBack.propTypes = {
  shouldRender: PropTypes.bool.isRequired,
  goHome: PropTypes.func.isRequired,
};

const EnhancedGoBack = connect(
  state => ({
    shouldRender: isDirectoryAllowed(state),
  }),
  { goHome: () => push('/') },
)(GoBack);

function NotFound(props) {
  const { intl } = props;
  const title = intl.formatMessage(messages.title);
  return (
    <div className="notfound-page">
      <Header>
        <CoOpLogo className="pull-left" externalPageLogo />
      </Header>
      <div className="notfound-content text-center">
        <Svg404 />
        <h1 className="title-36 text-strong md-margin font-color-brand-main title-brand-1">
          <FormattedMessage {...messages.h1} />
        </h1>
        <div className="error-text font-color-brand-main">
          <FormattedMessage {...messages.weCantSeemToFind} />
        </div>
        <EnhancedGoBack />
      </div>
      <div className="notfound-footer">
        <span>
          <FormattedMessage {...messages.gotQuestions} />
        </span>
        <br />
        <span>
          <a href="mailto:support@getvim.com" className="font-color-brand-link">
            <FormattedMessage {...messages.helpMail} />
          </a>
        </span>
        <span className="phone">
          <FormattedMessage {...messages.bookmdPhoneNumber} />
        </span>
      </div>
      <Helmet title={title} />
    </div>
  );
}

NotFound.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(NotFound);
