import _ from 'lodash';
import { autobind } from 'core-decorators';
import BkmdApi from './bkmdApi';
import ServicesApi from './services';

const SERVICE_NAME = 'clinicManagement';

@autobind
class ClinicsApi extends BkmdApi {
  /**
   * A fetch object that points to the server
   * @param fetch
   */
  constructor(fetch) {
    super(fetch, '/clinicManagement/');
    this.servicesApi = new ServicesApi(fetch);
  }

  getClinicsPagination(
    skip = 0,
    limit = 10,
    search = '',
    sort = 'createdAt',
    sortAscending = false,
    showOnlyOnboarded,
    includeProviderResources,
  ) {
    const getClinicsParams = { limit, skip, search, sort, sortAscending };
    getClinicsParams.filter = { showOnlyOnboarded, includeProviderResources };

    return this.servicesApi.callSecure(SERVICE_NAME, 'getClinicsPagination', getClinicsParams);
  }

  getClinics() {
    return this.servicesApi.callSecure(SERVICE_NAME, 'getClinics', {});
  }

  loadProvidersByClinicsArray(providersGroupById, idField, selectedDomains) {
    return this.post('clinicManagement/loadProvidersByClinicsArray', {
      providersGroupById,
      idField,
      selectedDomains,
    });
  }

  getClinicById(id) {
    return this.servicesApi.callSecure(SERVICE_NAME, 'getClinicById', { id, resourceId: id });
  }

  updateClinic(data) {
    return this.servicesApi.callSecure(SERVICE_NAME, 'updateClinic', { data });
  }

  deleteClinic(id) {
    return this.servicesApi.callSecure(SERVICE_NAME, 'deleteClinic', { id });
  }

  createClinic(data) {
    return this.servicesApi.callSecure(SERVICE_NAME, 'createClinic', { data });
  }

  createClinics(data) {
    return this.servicesApi.callSecure(SERVICE_NAME, 'createClinics', { data });
  }

  clinicsOnBoarding(healthSystemId, data) {
    return this.servicesApi.callSecure(SERVICE_NAME, 'clinicsOnBoarding', { healthSystemId, data });
  }

  getAllCalendars(id) {
    return this.servicesApi.callSecure(SERVICE_NAME, 'getAllCalendars', { id });
  }

  getClinicNotifications(id, queryParams) {
    return this.servicesApi
      .callSecure(SERVICE_NAME, 'getClinicNotifications', { id, queryParams })
      .then(res => res.data);
  }

  addClinicSubscriber(clinicId, userId, npi) {
    return this.servicesApi
      .callSecure(SERVICE_NAME, 'addClinicSubscriber', { clinicId, userId, npi })
      .then(res => res.data);
  }

  typeaheadCode(codeType, input) {
    return this.servicesApi
      .callSecure(SERVICE_NAME, 'typeaheadCode', { codeType, input })
      .then(res => res.data);
  }

  removeClinicSubscriber(clinicId, userId) {
    return this.servicesApi
      .callSecure(SERVICE_NAME, 'removeClinicSubscriber', { clinicId, userId })
      .then(res => res.data);
  }

  async postAppointmentChunks(appointments, clinicId, domain, csvChunkSize) {
    const chunks = _.chunk(appointments, csvChunkSize);
    const results = [];
    // NOTE: we intend to do this seriliazed and not concurrently
    // @mrsufgi imho I prefer using for..of instead of Promise.map with concurrenty cap.
    // I see us not using bluebird on client applications. so this helps.
    for (const chunk of chunks) {
      // eslint-disable-next-line no-await-in-loop
      const { data: chunkRes } = await this.post(
        'clinicManagement/createBulkAppointments',
        { clinicId, appointments: chunk },
        domain,
      );
      results.push(chunkRes);
    }
    const mergedResults = _.reduce(
      results,
      (acc, { ok, fail }) => ({
        ok: acc.ok.concat(ok),
        fail: acc.fail.concat(fail),
      }),
      { ok: [], fail: [], domain },
    );
    return mergedResults;
  }

  createBulkAppointments(clinicId, appointmentsGroups, csvChunkSize = 5) {
    return Promise.all(
      _.map(
        appointmentsGroups,
        (appointments, domain) =>
          !_.isEmpty(appointments) &&
          this.postAppointmentChunks(appointments, clinicId, domain, csvChunkSize).catch(error => {
            const message = `${domain} had an error.`;
            console.error(message, error); // eslint-disable-line
            return { domain, message };
          }),
      ),
    ); // @mrsufgi consider error handling
  }
  getClinicMapping(clinicId) {
    return this.servicesApi
      .callSecure(SERVICE_NAME, 'getClinicMapping', { clinicId })
      .then(res => res.data);
  }
  updateClinicMapping(clinicId, mapping) {
    return this.servicesApi
      .callSecure(SERVICE_NAME, 'updateClinicMapping', { clinicId, mapping })
      .then(res => res.data);
  }

  /**
   * Health system section
   */
  getHealthSystems(skip, limit, search, sort, sortAscending) {
    return this.servicesApi.callSecure(SERVICE_NAME, 'getHealthSystems', {
      limit,
      skip,
      search,
      sort,
      sortAscending,
    });
  }

  getHealthSystemByProvider(npi, address) {
    return this.servicesApi.callSecure(SERVICE_NAME, 'getHealthSystemByProvider', {
      npi,
      address,
    });
  }

  getHealthSystemByEhrIntegrationType(type) {
    return this.servicesApi.callSecure(SERVICE_NAME, 'getHealthSystemByEhrIntegrationType', {
      type,
    });
  }

  getHealthSystemByEhrIntegrationId(ehrIntegrationId) {
    return this.servicesApi.callSecure(SERVICE_NAME, 'getHealthSystemByEhrIntegrationId', {
      ehrIntegrationId,
    });
  }

  getAllHealthSystems() {
    return this.servicesApi
      .callSecure(SERVICE_NAME, 'getAllHealthSystems', {})
      .then(response => response.data);
  }

  createHealthSystem(data) {
    return this.servicesApi.callSecure(SERVICE_NAME, 'createHealthSystem', {
      data,
    });
  }

  deleteHealthSystem(id) {
    return this.servicesApi.callSecure(SERVICE_NAME, 'deleteHealthSystem', {
      id,
    });
  }

  getHealthSystem(id) {
    return this.servicesApi.callSecure(SERVICE_NAME, 'getHealthSystem', {
      id,
    });
  }

  updateHealthSystem(data) {
    return this.servicesApi.callSecure(SERVICE_NAME, 'updateHealthSystem', {
      data,
    });
  }

  getClinicsByHealthSystem(healthSystemId) {
    return this.servicesApi.callSecure(SERVICE_NAME, 'getClinicsByHealthSystem', {
      healthSystemId,
    });
  }

  attachClinicsToHealthSystem(healthSystemId, clinicIds) {
    return this.servicesApi.callSecure(SERVICE_NAME, 'attachClinicsToHealthSystem', {
      healthSystemId,
      clinicIds,
    });
  }

  async setSearchPreferencesForHealthSystem(healthSystemId, isWhitelist, data) {
    const response = await this.servicesApi.callSecure(
      SERVICE_NAME,
      'setSearchPreferencesForHealthSystem',
      {
        healthSystemId,
        isWhitelist,
        data,
      },
    );
    return response.data;
  }

  async setSearchPreferencesForClinic(clinicId, isWhitelist, data) {
    const response = await this.servicesApi.callSecure(
      SERVICE_NAME,
      'setSearchPreferencesForClinic',
      {
        clinicId,
        isWhitelist,
        data,
      },
    );
    return response.data;
  }

  async getSearchPreferencesForHealthSystem(healthSystemId) {
    const response = await this.servicesApi.callSecure(
      SERVICE_NAME,
      'getSearchPreferencesForHealthSystem',
      {
        healthSystemId,
      },
    );
    return response.data;
  }

  async getSearchPreferencesForClinic(clinicId) {
    const response = await this.servicesApi.callSecure(
      SERVICE_NAME,
      'getSearchPreferencesForClinic',
      {
        clinicId,
      },
    );
    return response.data;
  }

  async getPlanMappingsByHealthSystem({ healthSystemId, skip, limit, sort, sortAscending }) {
    return this.servicesApi.callSecure(SERVICE_NAME, 'getPlanMappingsByHealthSystem', {
      healthSystemId,
      limit,
      skip,
      sort,
      sortAscending,
    });
  }

  async findPlanMappingByEhrIdAnd({ healthSystemId, ehrId }) {
    return this.servicesApi.callSecure(SERVICE_NAME, 'getPlanMappingByEhrId', {
      healthSystemId,
      ehrId,
    });
  }

  async updatePlanMapping(data) {
    return this.servicesApi.callSecure(SERVICE_NAME, 'updatePlanMapping', { data });
  }

  async createPlanMapping(data) {
    return this.servicesApi.callSecure(SERVICE_NAME, 'createPlanMapping', { data });
  }

  async deletePlanMapping({ ehrId }) {
    return this.servicesApi.callSecure(SERVICE_NAME, 'deletePlanMapping', { ehrId });
  }
}

export default ClinicsApi;
