/**
 * Created by chenrozenes on 19/02/2017.
 */
import _ from 'lodash';
import { defineMessages } from 'react-intl';
import IntlEnum from '../../utils/intlEnum';

export const PREMERA_BLUE_CROSS_ALASKA = 'PREMERA_BLUE_CROSS_ALASKA';
export const UNITED_HEALTH_CARE_ALLSAVERS = 'UNITED_HEALTH_CARE_ALLSAVERS';
export const ANTHEM = 'ANTHEM';
export const FLORIDA_BLUE = 'FLORIDA_BLUE';
export const VIM = 'VIM';

export const BkmdDomains = {
  PREMERA_BLUE_CROSS_ALASKA,
  UNITED_HEALTH_CARE_ALLSAVERS,
  ANTHEM,
  FLORIDA_BLUE,
  VIM,
};

export const messages = defineMessages({
  premeraBlueCrossAlaska: {
    defaultMessage: 'Premera Blue Cross Alaska',
    id: 'bkmdDomain.premeraBlueCrossAlaska',
    value: PREMERA_BLUE_CROSS_ALASKA,
  },
  unitedHealthCareAllsavers: {
    defaultMessage: 'UnitedHealthcare All Savers',
    id: 'bkmdDomain.unitedHealthCareAllsavers',
    value: UNITED_HEALTH_CARE_ALLSAVERS,
  },
  anthem: {
    defaultMessage: 'Anthem',
    id: 'bkmdDomain.anthem',
    value: ANTHEM,
  },
  floridaBlue: {
    defaultMessage: 'Florida Blue',
    id: 'bkmdDomain.floridaBlue',
    value: FLORIDA_BLUE,
  },
  vim: {
    defaultMessage: 'Vim',
    id: 'bkmdDomain.vim',
    value: VIM,
  },
});

export default new IntlEnum(
  { value: PREMERA_BLUE_CROSS_ALASKA, message: messages.premeraBlueCrossAlaska },
  { value: UNITED_HEALTH_CARE_ALLSAVERS, message: messages.unitedHealthCareAllsavers },
  { value: ANTHEM, message: messages.anthem },
  { value: FLORIDA_BLUE, message: messages.floridaBlue },
  { value: VIM, message: messages.vim },
);

export function getKeyForMsgId(msgId) {
  return _.findKey(messages, function compareMsgId(msg) {
    return _.isEqual(msg.id, msgId);
  });
}

export function getValueForMsgId(msgId) {
  return messages[getKeyForMsgId(msgId)].value;
}

