import { defineMessages } from 'react-intl';
import * as Errors from '../../../../../../api/errors';

const messages = defineMessages({
  serverErrorTitle: {
    defaultMessage: 'Hmm. Something went wrong',
    id: 'members.settings.updatePhoneNumber.serverErrorTitle',
  },
  serverErrorMessage: {
    defaultMessage: 'Please refresh the page and try again',
    id: 'members.settings.updatePhoneNumber.serverErrorMessage',
  },
  weakPasswordTitle: {
    defaultMessage: 'Your password is too weak',
    id: 'activation.flow.error.weakPasswordTitle',
  },
  weakPasswordMessage: {
    defaultMessage: 'Try another one',
    id: 'activation.flow.error.weakPasswordMessage',
  },
  passwordWasInUseTitle: {
    defaultMessage: 'This password has been used already',
    id: 'activation.flow.error.passwordWasInUseTitle',
  },
  passwordWasInUseMessage: {
    defaultMessage: 'Try another one',
    id: 'activation.flow.error.passwordWasInUseMessage',
  },
});

const mapping = {
  defaultMessage: {
    title: messages.serverErrorTitle,
    message: messages.serverErrorMessage,
  },
  [Errors.WEAK_PASSWORD]: {
    title: messages.weakPasswordTitle,
    message: messages.weakPasswordMessage,
  },
  [Errors.PASSWORD_WAS_IN_USE]: {
    title: messages.passwordWasInUseTitle,
    message: messages.passwordWasInUseMessage,
  },
};

export default mapping;
