import React from 'react';
import PropTypes from 'prop-types';

import AverageSlider from '../../atoms/averageSlider/averageSlider';
import './costEstimationSlider.less';

class CostEstimationSlider extends React.Component {
  static propTypes = {
    title: PropTypes.string,
  };

  static defaultProps = {
    title: null,
  };

  render() {
    const { title, ...rest } = this.props;
    return (
      <div className="cost-estimation-slider">
        <div className="card no-margin">
          <div className="card-body padding-box-30">
            {title && (
              <div className="text-12 text-semibold text-dark text-center margin-bottom-20">
                {title}
              </div>
            )}

            <div className="cost-estimation-progress">
              <AverageSlider {...rest} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CostEstimationSlider;
