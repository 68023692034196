/**
 * Created by anna on 03/08/2017.
 */
import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import { FormattedMessage } from 'react-intl';
import {
  createToggleState, createToggleStatePropTypes
} from '../../recompose/toggleStateComponent';
import messages from '../../../messages/doctorDetails/messages';
import ReviewsModal from './modals/reviewsModal';

@autobind
class ReviewSummary extends React.Component {
  static propTypes = {
    review: PropTypes.object.isRequired,
    npi: PropTypes.string.isRequired,
    locationId: PropTypes.number.isRequired,
    ...createToggleStatePropTypes('reviewsModal'),
  };

  render() {
    const {
      ReviewsModalShow,
      ReviewsModalHide,
      ReviewsModalOn,
      review,
      npi,
      locationId,
    } = this.props;

    if (!review) return <div></div>;
    const truncatedReview = _.truncate(review, {
      length: 200,
      separator: ' '
    });
    return (
      <div className="card review-summary bg-color-brand-secondary">
        <div className="card-body">
          <div className="review-title">
            <i className="icon-speak text-18 i-va-fix-3" />&nbsp;
            <span className="text-semibold text-16">
              <FormattedMessage {...messages.patientReview} />
            </span>
          </div>

          {!_.isEmpty(truncatedReview) &&
          <div className="text-18 margin-top-20">
            &quot;{truncatedReview}&quot;
          </div>
          }

          <div className="margin-top-20">
            <button
              className="btn btn-big btn-white-border btn-transparent pull-left white-focus"
              onClick={ReviewsModalShow}
            >
              <FormattedMessage {...messages.seeRatingsReviews} />
            </button>
          </div>
        </div>

        <ReviewsModal
          npi={npi}
          locationId={locationId}
          isOpen={ReviewsModalOn}
          onClose={ReviewsModalHide}
        />

      </div>
    );
  }
}

export default createToggleState('ReviewsModal')(ReviewSummary);
