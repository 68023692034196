import PropTypes from 'prop-types';
import { defineMessages } from 'react-intl';
import { compose, setPropTypes } from 'recompose';
import FindMember from './findMember';
import { withTranslatedMessages } from '../../utils/withTranslatedMessages';
import Api from '../../api';
import { withFetchers } from '../../api/injectApi/withFetchers';
import { setPublicToken } from '../../utils/publicToken';

const messages = defineMessages({
  title: {
    defaultMessage: "Please verify the patient's insurance info",
    id: 'public.findMember.title',
  },
  description: {
    defaultMessage: "The doctor's office will check this before the visit",
    id: 'public.findMember.description',
  },
  primaryButtonText: {
    defaultMessage: 'Verify insurance info',
    id: 'public.findMemberForm.primaryButtonText',
  },
});

/** Searches for members on all domains */
const FindMemberPublic = compose(
  setPropTypes({
    member: PropTypes.object,
  }),
  withFetchers({
    searchMember: {
      handler: ({ insurer, memberPhone }) => ({ fullName, dateOfBirth, insuranceId }) =>
        Api.activationApi.activateWithMemberId({
          fullName,
          dateOfBirth,
          memberId: insuranceId,
          phoneNumber: memberPhone,
          insurer,
          generatePublicToken: true,
        }),
      onSuccess: ({ control }, { data: { token } }) => {
        setPublicToken(token);
        return control.next({ payload: { token } });
      },
    },
  }),
  withTranslatedMessages(messages),
)(FindMember);

export default FindMemberPublic;
