import { compose, withHandlers, withState } from 'recompose';
import _ from 'lodash';
import * as Events from './events';
import { removeProps } from './utils/removeProps';
import { setter } from './utils/setter';

const subscribers = [];

const wrapperHOCs = [];

/**
 * Used to wrap all the fetch HOCs with the HOCs supplied
 * @param hoc
 */
export const applyHOCs = (...hoc) => {
  wrapperHOCs.push(...hoc);
};

/**
 * Subscribe to fetch events globally, each of the events are optional!
 * e.g you can just subscribe to onSuccess.
 * @param fetchSubscriber -
 * {
 *    onStart: (props, args, key) => {},
 *    onSuccess: (props, result, key) => {},
 *    onError: (props, error, key) => {}
 * }
 */
export const subscribe = fetchSubscriber => {
  _.forEach(Events, event => {
    fetchSubscriber[event] = fetchSubscriber[event] || _.noop;
  });

  subscribers.push(fetchSubscriber);
};

/**
 * Emit fetch events -
 * it will trigger all the global subscribers and the specific callback if provided
 * @param event - one of Events array
 * @param props - current component props
 * @param payload - payload of the event, can be either result, error or arguments
 * @param key - the key of the current handler
 * @param callback - @optional, specific request event callback
 * @private
 */
const emit = (event, props, payload, key, callback) => {
  if (callback) callback(props, payload);
  _.forEach(subscribers, fetchSubscriber => fetchSubscriber[event](props, payload, key));
};

/**
 * Add tracker to async handler, the name will be the key that passed, suffixed by Tracker
 * For example: given prop searchMember - the tracker name will be searchMemberTracker
 * The tracker will be update when the request finished
 * During execution the value of tracker.inProgress will be true,
 * and when it finished it will be false
 *
 * During execution the value will be:
 * { inProgress: true, executed: false, error: null, hasError: false }
 *
 * On case of success the value will be:
 * { inProgress: false, executed: true, error: null, hasError: false }
 *
 * On case of error the value will be:
 * { inProgress: false, executed: true, error: err, hasError: true }
 *
 * The usage in the wrapped component is simply bind to tracker properties,
 * for example, assume that we need to show some loading indication:
 * <Loader isLoading={tracker.inProgress} />
 * will pass true to Loader while the action is not finished
 * @param promiseProp - the prop of the async operation that will be tracked
 * @private
 * @param key - Name of the action
 * @param trackersHOCs -
 */
const withTracker = (key, { onStart, onError, onSuccess }, trackersHOCs) => {
  const trackerProp = `${key}Tracker`;
  const trackerSetterProp = setter(trackerProp);

  trackersHOCs[trackerSetterProp] = withState(trackerProp, trackerSetterProp, {});

  return {
    onStart: (props, args) => {
      if (onStart) onStart(props, args);
      props[trackerSetterProp]({ inProgress: true, executed: false, error: null, hasError: false });
    },
    onSuccess: (props, result) => {
      if (onSuccess) onSuccess(props, result);
      props[trackerSetterProp]({ inProgress: false, executed: true, error: null, hasError: false });
    },
    onError: (props, err) => {
      if (onError) onError(props, err);
      props[trackerSetterProp]({ inProgress: false, executed: true, error: err, hasError: true });
    },
  };
};

/**
 * Wraps a component with fetchers - fetchers are something like "async" handlers.
 * the idea is to provide a unified way to fetch async data to the component.
 * it will inject a fetch tracker that can help to track the request @see withTracker
 * The usage is very similar to withHandlers from recompose, despite of few changes:
 * instead of providing for each key an handler - you need to pass fetcher config:
 * {
 *   doSomething: {
 *     handler: props => () => promise OR props => promise
 *     @optional onStart: (props, result) => {}
 *     @optional onSuccess: (props, result) => {}
 *     @optional onError: (props, error) => {}
 *     @optional track: boolean (true by default)
 *   }
 * }
 * @param handlersConfig
 * @return {*}
 */
export const withFetchers = handlersConfig => {
  const { handlers, trackersHOCs } = _.reduce(
    handlersConfig,
    (result, handlerConfig, key) => {
      const { handlers, trackersHOCs } = result;
      const { handler, track = true } = handlerConfig;

      const { onSuccess, onStart, onError } = track
        ? withTracker(key, handlerConfig, trackersHOCs)
        : handlerConfig;

      handlers[key] = props => async (...args) => {
        // before calling the handler
        let result;
        emit(Events.ON_START, props, args, key, onStart);
        try {
          const scopedHandler = handler(props);

          // validation check to make sure that the handler passed correctly
          if (!_.isFunction(scopedHandler)) {
            throw new Error(`[withFetchers - ${key}] 
            Your handler is not a handler! the syntax should be props => () => promise`);
          }

          // run the handler
          result = await scopedHandler(...args);
        } catch (error) {
          // on handler failed
          emit(Events.ON_ERROR, props, error, key, onError);
          return;
        }

        // on handler success
        emit(Events.ON_SUCCESS, props, result, key, onSuccess);
      };

      return result;
    },
    { handlers: {}, trackersHOCs: {} },
  );

  return compose(
    ...wrapperHOCs,
    ..._.values(trackersHOCs),
    withHandlers(handlers),
    removeProps(_.keys(trackersHOCs)),
  );
};
