import PropTypes from 'prop-types';
import { compose, defaultProps, setPropTypes, withProps } from 'recompose';

import { FlowShape } from '../../components/wizard/flow/shapes';
import FindMemberForm from '../../components/organisms/FindMemberForm/FindMemberForm';
import SideDrawerContent from '../../components/templates/SideDrawerContent/SideDrawerContent';

const ComposedTemplate = withProps({
  formComponent: FindMemberForm,
})(SideDrawerContent);

/**
 * Input:
 * Output:
 * * Result: NEXT
 * * Returned value:
 */
const FindMember = compose(
  setPropTypes({
    control: FlowShape.isRequired,
    searchMember: PropTypes.func.isRequired,
    insurer: PropTypes.string,
    userInput: PropTypes.object,
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    primaryButtonText: PropTypes.string.isRequired,
  }),
  defaultProps({
    description: '',
  }),
  withProps(({ userInput, searchMember, searchMemberTracker }) => ({
    initialValues: userInput,
    onSubmit: searchMember,
    isPrimaryLoading: searchMemberTracker.inProgress,
  })),
)(ComposedTemplate);

export default FindMember;
