import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '../../../storybook/src/utils/theme';

function SvgBoyAvatar({ theme }) {
  /* eslint-disable */
  return (
    <svg aria-hidden="true" focusable="false" width="71px" height="71px" viewBox="0 0 71 71" version="1.1" className="svg-icon svg-boy-avatar">
      <defs>
        <polygon points="0 53.0004502 0 0 35.4998 0 70.9996 0 70.9996 53.0004502"></polygon>
      </defs>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-1.000000, -1.000000)">
          <g transform="translate(1.000000, 1.000000)">
            <g>
              <g></g>
              <path d="M65.4766,53 C65.2366,53 64.9946,52.942 64.7686,52.821 C64.0386,52.43 63.7636,51.521 64.1566,50.791 C66.6706,46.104 67.9996,40.817 67.9996,35.5 C67.9996,17.579 53.4206,3 35.4996,3 C17.5796,3 2.9996,17.579 2.9996,35.5 C2.9996,40.818 4.3296,46.105 6.8426,50.791 C7.2356,51.521 6.9606,52.43 6.2306,52.821 C5.5026,53.214 4.5916,52.94 4.2006,52.209 C1.4526,47.09 -0.0004,41.312 -0.0004,35.5 C-0.0004,15.925 15.9246,0 35.4996,0 C55.0756,0 70.9996,15.925 70.9996,35.5 C70.9996,41.311 69.5476,47.089 66.7986,52.209 C66.5286,52.714 66.0106,53 65.4766,53" fill={theme.secondaryColor} ></path>
            </g>
            <path d="M35.5,51 C32.46,51 29.448,50.247 26.791,48.822 C26.062,48.431 25.786,47.521 26.178,46.791 C26.569,46.063 27.481,45.788 28.209,46.178 C32.653,48.562 38.343,48.562 42.791,46.178 C43.521,45.786 44.431,46.062 44.822,46.791 C45.214,47.521 44.938,48.431 44.209,48.822 C41.551,50.247 38.539,51 35.5,51" fill={theme.mainColor} ></path>
            <path d="M35.5,68 C25.887,68 16.903,63.811 10.698,56.469 C14.161,50.388 19.873,45.679 26.508,43.471 C27.026,43.299 27.409,42.858 27.509,42.321 C27.608,41.784 27.408,41.235 26.986,40.889 C23.817,38.288 22,34.501 22,30.5 C22,28.92 22.287,27.41 22.787,26 L48.216,26 C48.727,27.441 49,28.948 49,30.5 C49,34.5 47.183,38.287 44.014,40.889 C43.592,41.235 43.392,41.784 43.491,42.321 C43.591,42.858 43.974,43.299 44.492,43.471 C51.126,45.679 56.838,50.388 60.301,56.469 C54.096,63.811 45.113,68 35.5,68 M35.5,17 C38.364,17 41.113,17.909 43.403,19.568 C43.362,19.706 43.333,19.849 43.333,20 C43.333,21.654 41.987,23 40.333,23 L24.282,23 C26.707,19.385 30.829,17 35.5,17 M58.333,22 C58.333,22.552 57.885,23 57.333,23 L49.243,23 L49.224,23 L45.521,23 C45.874,22.393 46.121,21.718 46.243,21 L57.333,21 C57.885,21 58.333,21.448 58.333,22 M63.431,55.942 C59.998,49.383 54.478,44.32 47.729,41.484 C50.468,38.465 52,34.576 52,30.5 C52,28.96 51.772,27.457 51.359,26 L57.333,26 C59.539,26 61.333,24.206 61.333,22 C61.333,19.794 59.539,18 57.333,18 L46.243,18 C43.251,15.425 39.467,14 35.5,14 C26.402,14 19,21.402 19,30.5 C19,34.576 20.532,38.466 23.271,41.484 C16.521,44.32 11.001,49.383 7.568,55.942 C7.293,56.468 7.354,57.107 7.724,57.571 C14.514,66.105 24.638,71 35.5,71 C46.361,71 56.485,66.105 63.275,57.571 C63.645,57.107 63.706,56.468 63.431,55.942" fill={theme.mainColor} ></path>
          </g>
        </g>
      </g>
    </svg>
      
  );
  /* eslint-enable */
}

SvgBoyAvatar.propTypes = {
  theme: PropTypes.object.isRequired,
};

export default withTheme(SvgBoyAvatar);
