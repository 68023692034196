import { applyMiddleware, compose, createStore } from 'redux';
import { createLogger } from 'redux-logger';
import * as storage from 'redux-storage';
import createEngine from 'redux-storage-engine-localstorage';
import storageFilter from 'redux-storage-decorator-filter';
import promiseMiddleware from 'redux-promise-middleware';
import { responsiveStoreEnhancer } from 'redux-responsive';
import fetch from 'isomorphic-fetch';
import rootReducer from './rootReducer';
import getAuthMiddleware from '../../../../store/auth/getAuthMiddleware';
import actionTrackerMiddleware from '../../../../store/tools/actionTracker/actionTrackerMiddleware';
import dependencies from '../dependencyFactory/index';
import { LOGOUT_ACTION_TYPE, AUTH_TOUCH_ACTION_TYPE } from '../../../../store/auth/actions';
import { SET_CURRENT_LOCALE } from '../../../../store/intl/actions';
import recaptchaMiddleware from '../../../../store/recaptcha/recaptchaMiddleware';
import { debouncePromiseMiddleware } from '../../../../store/tools/promiseDebounce';
import cacheSearchMiddleware from '../../../../store/tools/cacheSearch/cacheSearchMiddleware';
import recycle from '../../../../store/recycleState';
import { isLogEnabled } from '../../../../utils/logger';
import setupFeatureFlagClient from '../../../../utils/featureFlags/setupFeatureFlagClient';
import { clearMemberSession } from '../../../../utils/storage/memberSession';

async function fetchConfig() {
  const response = await fetch('/api/v1/clientConfig');
  return response.json();
}

function injectMiddleware(deps) {
  return ({ dispatch, getState }) => next => action =>
    next(typeof action === 'function' ? action({ ...deps, dispatch, getState }) : action);
}

async function configureStore(options) {
  const { platformMiddleware = [] } = options;
  const config = await fetchConfig();
  const initialState = config; // should be an object
  const enhancers = [responsiveStoreEnhancer];
  const engineKey = 'redux-storage';
  const engine = createEngine && createEngine(engineKey); // No server engine.
  const recycleExcept = ['routing', 'help', 'browser', 'recaptcha', 'location'];
  const reducer = recycle(rootReducer, [LOGOUT_ACTION_TYPE], initialState, recycleExcept);
  const middleware = [
    injectMiddleware({ ...dependencies, engine }),
    ...platformMiddleware,
    cacheSearchMiddleware,
    debouncePromiseMiddleware(),
    promiseMiddleware({
      promiseTypeSuffixes: ['START', 'SUCCESS', 'ERROR'],
    }),
    recaptchaMiddleware,
    actionTrackerMiddleware({
      promiseTypeSuffixes: ['START', 'SUCCESS', 'ERROR'],
    }),
    getAuthMiddleware({
      preLoginExpiredHook: () => {
        clearMemberSession();
      },
    }),
  ];

  const {
    config: { rollout: featureFlagsConfig },
  } = initialState;
  await setupFeatureFlagClient(featureFlagsConfig);

  if (engine) {
    const decoratedEngine = storageFilter(engine, ['whitelisted-key', ['intl', 'locale']]);
    middleware.push(
      storage.createMiddleware(decoratedEngine, [], [LOGOUT_ACTION_TYPE, SET_CURRENT_LOCALE]),
    );
  }

  if (isLogEnabled()) {
    const logger = createLogger({
      collapsed: true,
      // Convert immutable to JSON.
      stateTransformer: state => JSON.parse(JSON.stringify(state)),
      colors: {
        title: () => initialState.config.reduxLogger.colors.title,
        prevState: () => initialState.config.reduxLogger.colors.prevState,
        action: () => initialState.config.reduxLogger.colors.action,
        nextState: () => initialState.config.reduxLogger.colors.nextState,
        error: () => initialState.config.reduxLogger.colors.error,
      },
      predicate: (getState, action) => action.type !== AUTH_TOUCH_ACTION_TYPE,
    });

    // Logger must be the last middleware in chain.
    middleware.push(logger);

    const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__; // eslint-disable-line

    if (typeof devToolsExtension === 'function') {
      enhancers.push(devToolsExtension());
    }
  }

  const composedEnhancers = compose(
    applyMiddleware(...middleware),
    ...enhancers,
  );
  return createStore(reducer, initialState, composedEnhancers);
}

export default configureStore;
