import { get, noop, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import {
  compose,
  setPropTypes,
  withProps,
  withHandlers,
  defaultProps,
  branch,
  mapProps,
} from 'recompose';
import { defineMessages } from 'react-intl';

import injectNotification from '../../store/notification/injectNotification';
import { FlowShape } from '../../components/wizard/flow/shapes';
import Api from '../../api';
import { withFetchHandler, withFetch } from '../../api/injectApi';
import { NO_TIMESLOT_FOR_REQ } from '../../api/errors';
import ReviewDetails from '../../screenTemplates/SCR004/ReviewDetails';
import { withTranslatedMessages } from '../../utils/withTranslatedMessages';
import * as BookingType from '../../model/enum/bookingType';
import { TimePreference } from '../../model/enum/timePreference';

const messages = defineMessages({
  titleText: {
    defaultMessage: 'Review booking details',
    id: 'members.directory.booking.ReviewYourDetails',
  },
  requestTitleText: {
    defaultMessage: 'Review your request',
    id: 'members.directory.booking.requestReviewYourDetails',
  },
  buttonText: {
    defaultMessage: 'Complete booking',
    id: 'members.directory.booking.completeBooking',
  },
  requestButtonText: {
    defaultMessage: 'Submit request',
    id: 'members.directory.booking.requestButtonText',
  },
  error: {
    defaultMessage:
      'Seems like this appointment is no longer available. ' +
      'Please try to book another appointment',
    id: 'members.directory.booking.error',
  },
});

const requestMessages = defineMessages({
  titleText: {
    defaultMessage: 'Review your request',
    id: 'members.directory.booking.requestReviewYourDetails',
  },
  buttonText: {
    defaultMessage: 'Submit request',
    id: 'members.directory.booking.requestButtonText',
  },
  error: {
    defaultMessage: 'Seems like your request is not working at the moment. Please try again.',
    id: 'members.directory.booking.requestError',
  },
});

const withBooking = compose(
  withFetch(Api.schedulingApi.getBookingData, 'getBookingData', {
    onSuccess: (props, result) => {
      const { control } = props;
      control.error({ payload: { bookingData: result } });
    },
  }),
  withFetchHandler(
    ({ bookApiOverride }) => ({ appointment }) => {
      if (bookApiOverride) {
        return bookApiOverride(appointment);
      }

      return Api.schedulingApi.patientBookAppointment(appointment);
    },
    'bookAppointment',
    {
      onSuccess: ({ control, onBookingSuccess = noop }, result) => {
        onBookingSuccess(get(result, 'data.app') || get(result, 'id'));
        control.next({ payload: { bookingResult: result } });
      },
      onError: (props, error) => {
        const {
          appointment: { npi, locationId },
          notification,
        } = props;
        if (get(error, 'responseMessage') === NO_TIMESLOT_FOR_REQ) {
          props.getBookingData(npi, locationId);
        }
        notification.error('', messages.error);
      },
    },
  ),
  withFetchHandler(
    ({ appointmentRequestApi, domainMemberId }) => data => {
      if (appointmentRequestApi) {
        return appointmentRequestApi({ ...data, domainMemberId });
      }

      return Api.schedulingApi.publicAppointmentRequest({ ...data, domainMemberId });
    },
    'createAppointmentRequest',
    {
      onSuccess: ({ control, onBookingSuccess = noop }, result) => {
        onBookingSuccess(get(result, 'data.app') || get(result, 'id'));
        control.next({ payload: { bookingResult: result } });
      },
      onError: ({ notification }) => {
        notification.error('', requestMessages.error);
      },
    },
  ),
);

const ReviewAppointmentDetails = compose(
  injectNotification,
  setPropTypes({
    appointment: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    service: PropTypes.object.isRequired,
    notification: PropTypes.object.isRequired,
    bookingType: PropTypes.string,
    domainMemberId: PropTypes.string,
    control: FlowShape.isRequired,
    bookApiOverride: PropTypes.func,
    bookingStrategy: PropTypes.string.isRequired,
    autoSelectedAppointmentType: PropTypes.bool.isRequired,
  }),
  defaultProps({
    bookingType: BookingType.INSTANT,
  }),

  withBooking,
  withHandlers({
    onClick: ({
      bookAppointment,
      createAppointmentRequest,
      domainMemberId,
      bookingType,
      appointment: {
        npi,
        locationId,
        appointmentTypeId,
        startTimeUTC,
        endTimeUTC,
        reasonForVisit,
        timePreference,
        timePreferenceCustomText,
        externalId,
      },
      memberDomain,
      bookingStrategy,
    }) => () => {
      if (bookingType === BookingType.INSTANT) {
        return bookAppointment({
          appointment: {
            npi,
            locationId,
            appointmentTypeId,
            startTime: startTimeUTC,
            endTime: endTimeUTC,
            reasonForVisit,
            memberDomain,
            bookingStrategy,
            externalId,
          },
          domainMemberId,
        });
      }

      const preferences =
        timePreference === TimePreference.ASAP ? 'ASAP' : timePreferenceCustomText;

      return createAppointmentRequest({
        reasonForVisit,
        preferences,
        requestedNpi: npi,
        requestedLocationId: locationId,
        appointmentRequestType: appointmentTypeId,
      });
    },
  }),
  withProps(({ bookAppointmentTracker, createAppointmentRequestTracker }) => ({
    tracker: isEmpty(bookAppointmentTracker)
      ? createAppointmentRequestTracker
      : bookAppointmentTracker,
  })),
  mapProps(({ autoSelectedAppointmentType, ...rest }) => ({
    showSelectedAppointmentType: !autoSelectedAppointmentType,
    ...rest,
  })),
  branch(
    ({ bookingType }) => bookingType === BookingType.REQUEST,
    withTranslatedMessages(requestMessages),
    withTranslatedMessages(messages),
  ),
)(ReviewDetails);

export default ReviewAppointmentDetails;
