import { compose, withHandlers, mapProps } from 'recompose';
import { connect } from 'react-redux';
import injectAnalyticsAsProp from '../../../utils/injectAnalyticsAsProp';
import { getAppName } from '../../config/selectors';

/**
 * If analytics script isn't loaded for an network error, analytics loader acts as a queue for
 * events to be done, event though the events will never be done (because the script isn't loaded).
 * So, if the analytics wasn't loaded, we want to call the callback anyway to continue the
 * code flow
 * @param analytics
 * @param callback
 */
function callCallbackIfNotInitiallized(analytics, callback) {
  if (!analytics.isReady && callback) {
    callback();
  }
}

export default () => compose(
  connect(
    state => ({
      appName: getAppName(state),
    }),
  ),
  injectAnalyticsAsProp,
  withHandlers({
    track: ({ appName, analytics }) => (eventName, params, options = {}, callback) => {
      callCallbackIfNotInitiallized(analytics, callback);

      // track function structure docs - https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#track
      return analytics.track(
        eventName,
        params,
        { context: { app: { name: appName } }, ...options, anonymousId: 'f8699086-e9f1-4008-ae76-e786511410bd' },
        callback,
      );
    },
    identify: ({ appName, analytics }) => (userId, traits = {}, options, callback) => {
      callCallbackIfNotInitiallized(analytics, callback);

      // identify function structure docs - https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#identify
      return analytics.identify(userId, { appName, ...traits }, options, callback);
    },
  }),
  mapProps(({
    track, identify, analytics, ...otherProps
  }) => ({
    analytics: {
      ...analytics,
      track,
      identify,
    },
    ...otherProps,
  })),
);
