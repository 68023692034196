/**
 * Created by chenrozenes on 18/01/2017.
 */

import _ from 'lodash';
import PodrickManager from './manager';
import PodrickFactory from './factory';
import buildReducer from './reducer';
import buildSandbox from './sandbox';

export { ENTER_SANDBOX, EXIT_SANDBOX } from './sandbox';

const defaultConfig = {
  /**
   * Default time to debounce the updateMethod that is provided for each type
   */
  debounceTime: 2000,
  /**
   * Promise suffixes in reducer
   */
  promiseSuffixes: ['START', 'SUCCESS', 'ERROR'],
  /**
   * Should return the merged entity between the data and the changes performed
   * mergeChanges function can be defined every type separately
   * @param data - data from server after "transformFromServer"
   * @param changes - object changes that occurred in the ui
   */
  mergeChanges: (data, changes) => _.extend({}, data, changes),
  /**
   * Transforms data received from the server (result of the updateMethod)
   * @param data
   */
  transform: data => data,
  /**
   * Should be true if after every saveChanges call the server should be updated
   */
  alwaysFlush: true,
  /**
   * Mandatory param (updateAction can be passed instead)
   * If set, should return promise, and will be called to update server with the saved changes.
   * Will be called every "debounceTime" or when calling "flush"
   */
  updateMethod: null,
  /**
   * Mandatory param (updateMethod can be passed instead)
   * An action creator that returns an update action that updates the server with the saved changes.
   * Will be called every "debounceTime" or when calling "flush"
   */
  updateAction: null
};

export default function init(config) {
  config = _.extend({}, defaultConfig, config);
  const manager = new PodrickManager(config);
  const factory = new PodrickFactory(config, manager);

  return {
    reducer: buildReducer(config, manager),
    factory,
    sandbox: buildSandbox(factory)
  };
}
