import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, FormattedMessage } from 'react-intl';
import { autobind } from 'core-decorators';
import paths from '../routes/paths';
import doctorCompose from '../doctorReviewHOC';
import DoctorReviewLayout from './doctorReviewLayout';
import CleanTextArea from '../../../../../components/form/cleanTextArea';
import FreeTextCharCountComponent from '../../../../../components/ui/freeTextCountingChars/freeTextCharCountComponent';
import { isPositive } from './utils';
import displayProvider from '../../../../../utils/displayProvider';
import SvgSpeechBubbleDots from '../../../../../assets/svg/svgSpeechbubbleDots';

import './doctorReview.less';

const messages = defineMessages({
  wantToLeaveAReviewForDrSmith: {
    defaultMessage: 'Want to leave a review for {providerDisplayName}?',
    id: 'members.doctorReview.wantToLeaveAReviewForDrSmith',
  },
  addAPrivateNoteForYourDoctor: {
    defaultMessage: 'Add a private note for your doctor',
    id: 'members.doctorReview.addAPrivateNoteForYourDoctor',
  },
  OnlyYourDoctor: {
    defaultMessage: 'Only your doctor will see this message',
    id: 'members.doctorReview.OnlyYourDoctor',
  },
  skipThis: {
    defaultMessage: 'Skip this',
    id: 'members.doctorReview.skipThis',
  },
  submit: {
    defaultMessage: 'Submit',
    id: 'members.doctorReview.submit',
  },
  tellUsMore: {
    defaultMessage: 'Tell us about your visit',
    id: 'members.doctorReview.tellUsMore',
  },
});
@autobind
class DoctorTextReview extends React.PureComponent {
  static propTypes = {
    updateReview: PropTypes.func.isRequired,
    reviewProcess: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
    params: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = { showPrivateNote: false };
  }

  onShowNoteClick() {
    this.setState({ showPrivateNote: true });
  }

  onPrivateNote(e) {
    const {
      params: { token },
    } = this.props;
    const privateNote = e.target.value;
    this.props.updateReview({ privateNote, token });
  }

  onReviewText(e) {
    const {
      params: { token },
    } = this.props;
    const review = e.target.value;
    this.props.updateReview({ review, token });
  }

  onBack() {
    const {
      router,
      reviewProcess: { locationId },
      params: { token },
      reviewProcess,
    } = this.props;
    if (isPositive(reviewProcess.rating)) {
      return !locationId
        ? router.push(paths.positiveDoctor(token))
        : router.push(paths.positiveOffice(token));
    }
    return !locationId
      ? router.push(paths.negativeDoctor(token))
      : router.push(paths.negativeOffice(token));
  }

  goToNext() {
    const {
      router,
      params: { token },
    } = this.props;
    router.push(paths.thankYou(token));
  }

  renderPrivateNote() {
    const { reviewProcess } = this.props;
    if (this.state.showPrivateNote) {
      return (
        <div className="row padding-box-30">
          <div className="col-xs-12">
            <CleanTextArea
              value={reviewProcess.privateNote}
              onChange={this.onPrivateNote}
              label={messages.addAPrivateNoteForYourDoctor}
              helpText={messages.OnlyYourDoctor}
            />
          </div>
        </div>
      );
    }
    return (
      <div className="row padding-h-30">
        <div className="col-xs-12">
          <button className="btn btn-small btn-block btn-outline" onClick={this.onShowNoteClick}>
            <i className="icon-plus i-va-fix-2" />
            &nbsp;
            <FormattedMessage {...messages.addAPrivateNoteForYourDoctor} />
          </button>
        </div>
      </div>
    );
  }

  render() {
    const {
      reviewProcess: {
        provider: { suffix, firstName, lastName },
        review,
      },
    } = this.props;
    const providerDisplayName = displayProvider(firstName, lastName, suffix);

    return (
      <DoctorReviewLayout onBack={this.onBack}>
        <div className="text-center padding-v-20">
          <SvgSpeechBubbleDots />
        </div>

        <div className="title-24 text-center no-margin padding-h-30 text-strong title-brand-1">
          <FormattedMessage
            {...messages.wantToLeaveAReviewForDrSmith}
            values={{ providerDisplayName }}
          />
        </div>

        <div className="row margin-top-30 padding-h-30">
          <div className="col-xs-12">
            <FreeTextCharCountComponent
              value={review}
              maxLength="500"
              onChange={this.onReviewText}
              label={messages.tellUsMore}
            />
          </div>
        </div>

        <div className="row margin-top padding-h-30">
          <div className="col-xs-12">
            <button className="btn btn-big bg-color-brand-button" onClick={this.goToNext}>
              <FormattedMessage {...messages.submit} />
            </button>
          </div>
        </div>
        <div className="row margin-top-20">
          <div className="col-xs-12 text-center">
            <button className="btn btn-inline-link" onClick={this.goToNext}>
              <FormattedMessage {...messages.skipThis} />
              &nbsp;
              <i className="icon-chevron-right i-va-fix-2 text-dark" />
            </button>
          </div>
        </div>
      </DoctorReviewLayout>
    );
  }
}

export default doctorCompose(DoctorTextReview);
