import { compose, pure, withHandlers } from 'recompose';
import { defineMessages } from 'react-intl';
import { withTranslatedMessages } from '../../utils/withTranslatedMessages';
import SCR012EmailForm from '../../screenTemplates/SCR012EmailForm';

const messages = defineMessages({
  title: {
    defaultMessage: 'Let us know what email to send your details to.',
    id: 'colorectalFlow.orderAKitEmailForm.title',
  },
  buttonText: {
    defaultMessage: 'Confirm',
    id: 'colorectalFlow.orderAKitEmailForm.buttonText',
  },
});

const OrderAKitEmailForm = compose(
  pure,
  withTranslatedMessages(messages),
  withHandlers({
    onButtonClick: ({ control }) => formResult => control.next({ payload: formResult.email }),
  }),
)(SCR012EmailForm);

export default OrderAKitEmailForm;
