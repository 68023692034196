import { autobind } from 'core-decorators';
import BkmdApi from './bkmdApi';

// eslint-disable-next-line
const SERVICE_NAME = 'activation';

@autobind
export default class ActivationApi extends BkmdApi {
  constructor(fetch) {
    super(fetch, '/activation/');
  }

  validate(
    phoneNumber,
    firstName,
    middleName,
    lastName,
    dateOfBirth,
    source,
    validationToken,
    shouldActivate = true,
  ) {
    return this.post('/validate', {
      phoneNumber,
      firstName,
      middleName,
      lastName,
      dateOfBirth,
      source,
      validationToken,
      shouldActivate,
    });
  }

  activateWithMemberId(payload) {
    return this.post('/activateWithMemberId', payload);
  }

  activateByShortToken(shortToken, dateOfBirth) {
    const payload = { token: shortToken };
    if (dateOfBirth) payload.dateOfBirth = dateOfBirth;
    return this.post('/activateByShortToken', payload);
  }

  validatePhoneNumber(phoneNumber, source) {
    return this.get('/validatePhoneNumber', { phoneNumber, source });
  }

  createVerifiedUser(activationToken, email, password, source, phoneNumber = undefined) {
    return this.post('/createVerifiedUser', {
      activationToken,
      email,
      password,
      source,
      phoneNumber,
    });
  }

  activateFamily(source) {
    return this.post('/activateFamily', { source });
  }

  searchAdultMember(payload, domain) {
    return this.get('/searchAdultMember', payload, undefined, domain);
  }

  inviteMemberByProvider(
    { domainMemberId, insurer, phoneNumber, npi, notify, validateUserPhone },
    domain,
  ) {
    return this.post(
      '/inviteMemberByProvider',
      {
        domainMemberId,
        phoneNumber,
        insurer,
        npi,
        notify,
        validateUserPhone,
      },
      domain,
    );
  }

  inviteMemberByVimGuide(domainMemberId, phoneNumber) {
    return this.post('/inviteMemberByVimGuide', {
      domainMemberId,
      phoneNumber,
    });
  }

  inviteMemberByBackpacker(domainMemberId, phoneNumber) {
    return this.post('/inviteMemberByBackpacker', {
      domainMemberId,
      phoneNumber,
    });
  }

  getByMemberId(memberId, options) {
    return this.post('/getByMemberId', { memberId, options }).then(result => result.data);
  }
}
