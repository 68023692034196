import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '../../storybook/src/utils/theme';

function SvgCodeExpStars({ theme }) {
  /* eslint-disable */
  return (
    <svg aria-hidden="true" focusable="false" width="71px" height="64px" viewBox="0 0 71 64" version="1.1" className="svg-icon svg-code-exp-stars">
      <g id="All-settings-screens" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Expired-code" transform="translate(-152.000000, -141.000000)">
          <g id="Page-1" transform="translate(152.000000, 141.000000)">
            <path d="M4.5,48.6592 C3.672,48.6592 3,47.9872 3,47.1592 L3,16.2502 C3,11.7212 6.936,7.7502 11.423,7.7502 L33.695,7.7502 C34.523,7.7502 35.195,8.4222 35.195,9.2502 C35.195,10.0782 34.523,10.7502 33.695,10.7502 L11.423,10.7502 C8.585,10.7502 6,13.3712 6,16.2502 L6,47.1592 C6,47.9872 5.328,48.6592 4.5,48.6592" id="Fill-1" fill={theme.mainColor} ></path>
            <path d="M69.5,60.75 L16.5,60.75 C15.672,60.75 15,60.078 15,59.25 C15,58.422 15.672,57.75 16.5,57.75 L65.879,57.75 L59.439,51.311 C59.158,51.029 59,50.647 59,50.25 L59,16.25 C59,13.32 56.543,10.75 53.741,10.75 L49.286,10.75 C48.458,10.75 47.786,10.078 47.786,9.25 C47.786,8.422 48.458,7.75 49.286,7.75 L53.741,7.75 C58.218,7.75 62,11.643 62,16.25 L62,49.629 L70.561,58.189 C70.989,58.618 71.118,59.264 70.886,59.824 C70.653,60.385 70.106,60.75 69.5,60.75" id="Fill-3" fill={theme.mainColor} ></path>
            <path d="M34.6211,34.25 L40.5601,28.311 C41.1461,27.725 41.1461,26.775 40.5601,26.189 C39.9751,25.604 39.0251,25.604 38.4391,26.189 L32.5001,32.129 L26.5601,26.189 C25.9751,25.604 25.0251,25.604 24.4391,26.189 C23.8531,26.775 23.8531,27.725 24.4391,28.311 L30.3791,34.25 L24.4391,40.189 C23.8531,40.775 23.8531,41.725 24.4391,42.311 C24.7321,42.604 25.1161,42.75 25.5001,42.75 C25.8841,42.75 26.2671,42.604 26.5601,42.311 L32.5001,36.371 L38.4391,42.311 C38.7321,42.604 39.1161,42.75 39.5001,42.75 C39.8841,42.75 40.2671,42.604 40.5601,42.311 C41.1461,41.725 41.1461,40.775 40.5601,40.189 L34.6211,34.25 Z" id="Fill-5" fill={theme.secondaryColor} ></path>
            <path d="M5.5,55.75 C4.121,55.75 3,56.871 3,58.25 C3,59.629 4.121,60.75 5.5,60.75 C6.879,60.75 8,59.629 8,58.25 C8,56.871 6.879,55.75 5.5,55.75 M5.5,63.75 C2.468,63.75 0,61.282 0,58.25 C0,55.218 2.468,52.75 5.5,52.75 C8.532,52.75 11,55.218 11,58.25 C11,61.282 8.532,63.75 5.5,63.75" id="Fill-7" fill={theme.secondaryColor} ></path>
            <path d="M44.832,3.3408 L42.991,3.3408 L42.991,1.4998 C42.991,0.6718 42.319,-0.0002 41.491,-0.0002 C40.663,-0.0002 39.991,0.6718 39.991,1.4998 L39.991,3.3408 L38.15,3.3408 C37.322,3.3408 36.65,4.0128 36.65,4.8408 C36.65,5.6688 37.322,6.3408 38.15,6.3408 L39.991,6.3408 L39.991,8.1818 C39.991,9.0098 40.663,9.6818 41.491,9.6818 C42.319,9.6818 42.991,9.0098 42.991,8.1818 L42.991,6.3408 L44.832,6.3408 C45.66,6.3408 46.332,5.6688 46.332,4.8408 C46.332,4.0128 45.66,3.3408 44.832,3.3408" id="Fill-9" fill={theme.secondaryColor} ></path>
            <path d="M70.4453,38.25 C70.4453,39.48 69.4483,40.478 68.2173,40.478 C66.9883,40.478 65.9913,39.48 65.9913,38.25 C65.9913,37.02 66.9883,36.022 68.2173,36.022 C69.4483,36.022 70.4453,37.02 70.4453,38.25" id="Fill-12" fill={theme.secondaryColor} ></path>
          </g>
        </g>
      </g>
    </svg>
  );
  /* eslint-enable */
}

SvgCodeExpStars.propTypes = {
  theme: PropTypes.object.isRequired,
};

export default withTheme(SvgCodeExpStars);
