import { find, get } from 'lodash';

const findPhoneNumber = function findPhoneNumberByType(phoneNumbers, memberContext) {
  let res;
  if (phoneNumbers) {
    const phoneNumberObject = find(phoneNumbers, ({ context }) => context === memberContext);
    res = get(phoneNumberObject, 'number');
  }
  return res;
};

/**
 * Only for premera peak care. Prefer Secondary number.
 * otherwise prefer primary. fallback to the regular phone number
 * @param {*} location
 * @param {*} memberContext
 */
export default function getProviderPhoneNumber(location, memberContext) {
  return findPhoneNumber(location.phoneNumbers, memberContext) || location.phoneNumber;
}
