/**
 * Created by chenrozenes on 28/03/2017.
 */
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import _ from 'lodash';
import { FormattedMessage } from 'react-intl';
import { autobind } from 'core-decorators';

@autobind
class TimeBasedContent extends React.Component {
  static propTypes = {
    /**
     * moment unit to compare dates - 'hour', 'day' etc.
     */
    unit: PropTypes.string,
    /**
     * Array of rules from the following structure
     * [{ from: '', to: '', img: '' }, ...]
     */
    rules: PropTypes.array,
  };

  static defaultProps = {
    unit: 'hours',
    rules: [],
  };

  componentWillMount() {
    this.content = this.selectContent();
  }

  selectContent() {
    const { rules, unit } = this.props;

    const now = moment();
    const predicate = ({ from, to }) =>
      now.isSameOrAfter(from, unit) && now.isSameOrBefore(to, unit);
    return _.find(rules, predicate);
  }

  render() {
    if (!this.content) return null;
    const { img, caption } = this.content;
    return (
      <div>
        <div className="top-img text-center">{img}</div>
        <div className="row">
          <div className="col-xs-12 text-center">
            <div className="signin-title title-brand-1">
              <FormattedMessage {...caption} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TimeBasedContent;
