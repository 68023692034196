import _ from 'lodash';
import { autobind } from 'core-decorators';

import BkmdApi from './bkmdApi';
import ServicesApi from './services';
import SdkApi from './vimApi/api';
import * as Sort from '../model/enum/searchApi/sort';
import * as GENDER from '../model/enum/gender';

const SERVICE_NAME = 'SearchEngine';

const DEFAULT_DISTANCE = '20plusmi';
const supportedGenders = [GENDER.MALE, GENDER.FEMALE];

/**
 * Represents the search doctors API
 */
@autobind
export default class SearchApi extends BkmdApi {
  /**
   * A fetch object that points to the server
   * @param fetch
   */
  constructor(fetch) {
    super(fetch, '/search');
    this.servicesApi = new ServicesApi(fetch);
  }

  searchFacilities(searchText, geo) {
    return this.get('/facilities', { searchText, geo });
  }

  searchByNameOrNpi(text, domain) {
    return this.servicesApi
      .callSecure(SERVICE_NAME, 'searchByNameOrNpi', { text, domain })
      .then(res => res.data);
  }

  /**
   *
   * @param searchTerm
   * @param filters
   * @param memberToken
   * @param geo
   * @param insurer
   * @param plan
   * @param skip
   * @param limit
   * @param sort
   * @param icd
   * @param cpt
   * @param bcbsMemberPrefix
   * @param withBookingAvailability
   * @param patientAge
   * @param patientGender
   * @param clinicId
   * @param npiList
   * @param queryId
   * @param memberSessionId
   * @param useSdkApiGW
   * @return {Promise<*>}
   */
  find(
    {
      searchTerm,
      filters,
      memberToken,
      geo,
      insurer,
      plan,
      skip,
      limit,
      sort,
      icd,
      cpt,
      bcbsMemberPrefix,
      withBookingAvailability,
      patientAge,
      patientGender,
      clinicId,
      npiList,
      queryId,
      memberSessionId,
    },
    useSdkApiGW = true,
  ) {
    const { distance, gender, spokenLanguage } = filters;
    const distanceFilter =
      distance && distance !== DEFAULT_DISTANCE ? parseInt(distance, 10) : undefined;
    const genderFilter = _.includes(supportedGenders, gender) ? gender : undefined;
    const languageFilter = spokenLanguage === 'any' ? undefined : spokenLanguage;
    const data = {
      searchTerm,
      filters: {
        ...filters,
        distance: distanceFilter,
        gender: genderFilter,
        spokenLanguage: languageFilter,
      },
      memberToken,
      geo,
      insurer,
      plan,
      skip,
      limit,
      icd,
      cpt,
      sort: _.toUpper(sort) === Sort.DISTANCE ? Sort.DISTANCE : undefined,
      bcbsMemberPrefix,
      withBookingAvailability,
      patientAge,
      patientGender,
      clinicId,
      queryId,
      memberSessionId,
    };

    return useSdkApiGW ? SdkApi.post('provider', data) : this.post('/find', data);
  }

  /**
   *
   * @param freeText
   * @param memberToken
   * @param filters
   * @param geo
   * @param types
   * @param insurer
   * @param useSdkApiGW
   * @return {Promise<*>}
   */
  freeText({ freeText, memberToken, filters, geo, types, insurer }, useSdkApiGW = true) {
    if (geo && (_.isString(geo.geocode.latitude) || _.isString(geo.geocode.longitude))) {
      geo.geocode.latitude = _.toNumber(geo.geocode.latitude);
      geo.geocode.longitude = _.toNumber(geo.geocode.longitude);
    }
    const data = {
      freeText,
      memberToken,
      filters,
      geo,
      types,
      insurer,
      separateTaxonomies: false,
    };

    return useSdkApiGW ? SdkApi.post('provider/freeText', data) : this.post('/freeText', data);
  }

  getProviderByNpi({ npi, insurer, memberToken, apiKey }, useSdkApiGW = true) {
    return useSdkApiGW
      ? SdkApi.getProviderByNpi(npi, memberToken, insurer, apiKey)
      : this.get(`/provider/${npi}`, { memberToken, insurer });
  }

  async shareProviderByPhone(
    { npi, locationId },
    { phoneNumber, insurer },
    referringClinicName,
    member = {},
  ) {
    const { id, firstName, lastName, dateOfBirth, memberId } = member;
    return this.post('/shareDoctors/shareProviderByPhone', {
      npi,
      locationId,
      phoneNumber,
      insurer,
      referringClinicName,
      member: {
        domainMemberId: id,
        firstName,
        lastName,
        dateOfBirth,
        memberId,
      },
    }).then(res => res.data);
  }

  async codesByType({ type, limit, skip, insurer }) {
    return this.get('/codesByType', { type, limit, skip, insurer });
  }

  async getAllTaxonomies({ type, insurer, limit, skip }) {
    return this.get('/taxonomies', { type, insurer, limit, skip });
  }

  identifyPatient({ firstName, lastName, dateOfBirth, memberId, phoneNumber, insurer }) {
    return this.post('/identify', {
      firstName,
      lastName,
      dateOfBirth,
      memberId,
      phoneNumber,
      insurer,
    });
  }

  decodeMemberToken({ memberToken, insurer }) {
    return this.post('/decodeMemberToken', {
      memberToken,
      insurer,
    });
  }

  refreshMemberToken({ memberToken }) {
    return this.post('/refreshMemberToken', {
      memberToken,
    });
  }

  searchActionAnalytics({
    queryId,
    memberSessionId,
    actionType,
    npi,
    locationId,
    ranking,
    entityId,
  }) {
    return this.post('/searchActionAnalytics', {
      queryId,
      memberSessionId,
      actionType,
      npi,
      locationId,
      ranking,
      entityId,
    });
  }

  insurerPlansDescription({ insurer }) {
    return this.get('/plansDescription', { insurer });
  }
}
