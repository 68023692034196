/**
 * Created by galgoltzman on 15/08/2017.
 */
import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { autobind } from 'core-decorators';

import Header from '../../components/ui/header/header';
import IconsModal from './iconsModal';

import './styleGuide.less';

@autobind
class StyleGuide extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
    };
  }

  openModal() {
    this.setState({ isModalOpen: true });
  }

  closeModal() {
    this.setState({ isModalOpen: false });
  }

  render() {
    return (
      <div className="style-guide-page">
        <Header>
          <div className="pull-left">
            <span className="title-24 no-margin text-light text-dark">
              Style guide
            </span>
          </div>
          <ul className="header-right-nav">
            <li>
              <a href="#buttons">
                Buttons
              </a>
            </li>
            <li>
              <a href="#type">
                Type
              </a>
            </li>
            <li>
              <a href="#margin-padding">
                Margin & Padding
              </a>
            </li>
            <li>
              <a href="#bg-colors">
                Background colors
              </a>
            </li>
            <li>
              <button className="btn btn-inline-link text-semibold" onClick={this.openModal}>
                Icons
              </button>
            </li>
          </ul>
        </Header>

        <div className="container container-1040">
          <div className="row margin-top-30" name="top" id="top">
            <div className="col-sm-10 col-sm-offset-1 col-md-8 col-md-offset-2">
              {/* /// BUTTONS /// */}
              <div className="buttons" name="buttons" id="buttons">
                <div className="title-36 text-dark text-light">
                  Buttons
                  <OverlayTrigger
                    placement="bottom"
                    overlay={
                      <Tooltip id={'a'}>
                        {'Buttons are awesome'}
                      </Tooltip>
                    }
                  >
                    <i className="icon-info-f tooltip-icon text-18" />
                  </OverlayTrigger>
                </div>
                <div className="row">
                  <div className="col-xs-12">
                    <div className="title-24 text-light margin-10">
                      Sizes
                    </div>
                  </div>
                </div>

                <div className="card padding-box-30">
                  <div className="row">
                    <div className="col-xs-12">
                      <div className="margin-top-5">
                        <button className="btn btn-small bg-color-brand-button">
                          btn-small
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-12">
                      <div className="margin-top-5">
                        <button className="btn btn-big bg-color-brand-button pull-left">
                          btn-big
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-12">
                      <div className="margin-top-5">
                        <button className="btn btn-huge bg-color-brand-button pull-left text-white">
                          btn-huge
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-12">
                      <div className="margin-top-5">
                        <button
                          className="btn btn-small btn-block pull-left margin-top-5
                        bg-color-brand-button"
                        >
                          btn-block
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-xs-12">
                    <div className="title-24 text-light md-margin">
                      Colors
                    </div>
                  </div>
                </div>

                <div className="card padding-box-30">
                  <div className="row colors">
                    <div className="col-sm-4">
                      <button className="btn btn-small btn-block bg-color-brand-button">
                        bg-color-brand-button
                      </button>
                    </div>
                    <div className="col-sm-4">
                      <button className="btn btn-small btn-outline btn-block">
                        btn-outline
                      </button>
                    </div>
                    <div className="col-sm-4">
                      <button className="btn btn-small btn-block btn-grey">
                        btn-grey
                      </button>
                    </div>
                    <div className="col-sm-4">
                      <button className="btn btn-small btn-block btn-light-grey">
                        btn-light-grey
                      </button>
                    </div>
                    <div className="col-sm-4">
                      <button className="btn btn-small btn-block btn-block btn-blue">
                        btn-blue
                      </button>
                    </div>
                    <div className="col-sm-4">
                      <button className="btn btn-small btn-block btn-block btn-red">
                        btn-red
                      </button>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-xs-12">
                    <div className="title-24 text-light margin-10">
                      Types
                    </div>
                  </div>
                </div>

                <div className="card padding-box-30">
                  <div className="row margin-top-20">
                    <div className="col-xs-12">
                      <button className="btn btn-pill">
                        btn-pill
                      </button>
                    </div>
                  </div>
                  <div className="row margin-top">
                    <div className="col-xs-12">
                      <button className="btn btn-round-plus btn-big pull-left">
                        <i className="icon-plus-fat" />
                      </button>
                      <span className="text-16 text-semibold buttons-text">
                        <i className="icon-chevron-left i-va-fix-2" />&nbsp;
                        btn-round-plus btn-big
                      </span>
                    </div>
                  </div>
                  <div className="row margin-top">
                    <div className="col-xs-12">
                      <button className="btn btn-round-icon">
                        <i className="icon-dots" />
                      </button>
                      <span className="text-16 text-semibold buttons-text">
                        <i className="icon-chevron-left i-va-fix-2" />&nbsp;
                        btn-round-icon
                      </span>
                    </div>
                  </div>
                  <div className="row margin-top">
                    <div className="col-xs-12 text-16">
                      <button className="btn btn-inline-link font-color-brand-link">
                        btn-inline-link font-color-brand-link
                      </button>
                    </div>
                  </div>
                </div>

              </div>
              {/* /// TYPE /// */}
              <div className="type" name="type" id="type">
                <hr />
                <div className="title-36 text-dark text-light md-margin">
                  Type
                </div>
                <div className="row">
                  <div className="col-xs-12">
                    <div className="title-24 text-light margin-10">
                      Size
                    </div>
                  </div>
                </div>
                <div className="sizes card">
                  <span className="text-12">
                    text-12
                  </span>
                  <span className="text-13">
                    text-13
                  </span>
                  <span className="text-14">
                    text-14
                  </span>
                  <span className="text-15">
                    text-15
                  </span>
                  <span className="text-16">
                    text-16
                  </span>
                  <span className="text-18">
                    text-18
                  </span>
                  <span className="text-22">
                    text-22
                  </span>
                  <br />
                  <span className="title-24">
                    title-24
                  </span>
                  <span className="title-36">
                    title-36
                  </span>
                  <span className="title-48">
                    title-48
                  </span>
                </div>
                <div className="row">
                  <div className="col-xs-12">
                    <div className="title-24 text-light margin-10">
                      Weight
                    </div>
                  </div>
                </div>
                <div className="weight card margin-top">
                  <span className="title-24 text-light">
                    text-light
                  </span>
                  <span className="title-24 text-normal">
                    text-normal
                  </span>
                  <span className="title-24 text-medium">
                    text-medium
                  </span>
                  <span className="title-24 text-semibold">
                    text-semibold
                  </span>
                  <span className="title-24 text-strong">
                    text-strong
                  </span>
                </div>

                <div className="row">
                  <div className="col-xs-12">
                    <div className="title-24 text-light margin-10">
                      Colors
                    </div>
                  </div>
                </div>

                <div className="colors card">
                  <span className="title-24 text-strong font-color-brand-main">
                    font-color-brand-main
                  </span>
                  <span className="title-24 text-strong font-color-brand-secondary">
                    font-color-brand-secondary
                  </span>
                  <span className="title-24 text-strong font-color-brand-link">
                    font-color-brand-link
                  </span>
                  <span className="title-24 text-strong font-color-brand-accent">
                    font-color-brand-accent
                  </span>
                  <span className="title-24 text-strong font-color-brand-main-dark">
                    font-color-brand-main-dark
                  </span>
                  <span className="title-24 text-strong font-color-brand-secondary-dark">
                    font-color-brand-secondary-dark
                  </span>
                  <span className="title-24 text-strong font-color-brand-success">
                    font-color-brand-success
                  </span>
                  <span className="title-24 text-strong text-danger">
                    text-danger
                  </span>
                  <span className="title-24 text-strong text-dark">
                    text-dark
                  </span>
                  <span className="title-24 text-strong text-faded">
                    text-faded
                  </span>
                  <span className="bg-mild-grey-color padding-h-20 text-strong text-white title-24">
                    text-white
                  </span>
                </div>

                <div className="row">
                  <div className="col-xs-12">
                    <div className="title-24 text-light margin-10">
                      Decoration
                    </div>
                  </div>
                </div>

                <div className="decoration card">
                  <span className="title-24 text-strong text-underline">
                    text-underline
                  </span>
                  <span className="title-24 text-strong text-line-through">
                    text-line-through
                  </span>
                </div>

                <div className="row">
                  <div className="col-xs-12">
                    <div className="title-24 text-light margin-10">
                      Brand titles
                    </div>
                  </div>
                </div>

                <div className="brand-titles card">
                  <span className="title-brand-1 title-24">
                    title-brand-1
                  </span>
                  <span className="title-brand-2 title-24">
                    title-brand-2
                  </span>
                  <span className="title-brand-3 title-24">
                    title-brand-3
                  </span>
                </div>

                <div className="row">
                  <div className="col-xs-12">
                    <div className="title-24 text-light margin-10">
                      Alignment
                    </div>
                  </div>
                </div>

                <div className="Alignment card padding-box-30">

                  <div className="row">
                    <div className="col-xs-12 text-left">
                      <span className="title-24">
                        text-left
                      </span>
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-xs-12 text-right">
                      <span className="title-24">
                        text-right
                      </span>
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-xs-12 text-center">
                      <span className="title-24">
                        text-center
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <hr />
              {/* //// MARGIN & PADDING //// */}
              <div className="margin-padding" name="margin-padding" id="margin-padding">
                <div className="title-36 text-dark text-light md-margin">
                  Margin & padding
                </div>

                <div className="row">
                  <div className="col-xs-12">
                    <div className="title-24 text-light margin-10">
                      Margin
                    </div>
                  </div>
                </div>

                <div className="card padding-box-30">
                  <div className="row margin-bottom-clean-form">
                    <div className="col-xs-4 text-center">
                      <div className="text-18">
                        margin-top-5 (5px)<br />
                        <i className="icon-chevron-down" />
                      </div>
                      <img
                        src={require('./images/margin-top-5.png')}
                        className="margin-top-5"
                        alt=""
                      />
                    </div>
                    <div className="col-xs-4 text-center">
                      <div className="text-18">
                        margin-top (15px)<br />
                        <i className="icon-chevron-down" />
                      </div>
                      <img
                        src={require('./images/margin-top.png')}
                        className="margin-top-5"
                        alt=""
                      />
                    </div>
                    <div className="col-xs-4 text-center">
                      <div className="text-18">
                        margin-top-20 (20px)<br />
                        <i className="icon-chevron-down" />
                      </div>
                      <img
                        src={require('./images/margin-top-20.png')}
                        className="margin-top-5"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="row margin-bottom-clean-form">
                    <div className="col-xs-4 text-center">
                      <div className="text-18">
                        margin-top-30 (30px)<br />
                        <i className="icon-chevron-down" />
                      </div>
                      <img
                        src={require('./images/margin-top-30.png')}
                        className="margin-top-5"
                        alt=""
                      />
                    </div>
                    <div className="col-xs-8 text-center">
                      <div className="text-18">
                        margin-bottom-clean-form (40px)<br />
                        <i className="icon-chevron-down" />
                      </div>
                      <img
                        src={require('./images/margin-bottom-clean-form.png')}
                        className="margin-top-5"
                        alt=""
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-xs-12">
                    <div className="title-24 text-light margin-10">
                      Padding
                    </div>
                  </div>
                </div>

                <div className="card padding-box-30">
                  <div className="row margin-bottom-clean-form">
                    <div className="col-xs-4 text-center">
                      <div className="text-18">
                        padding-box-20 (20px)<br />
                        <i className="icon-chevron-down" />
                      </div>
                      <img
                        src={require('./images/padding-box-20.png')}
                        className="margin-top-5"
                        alt=""
                      />
                    </div>
                    <div className="col-xs-4 text-center">
                      <div className="text-18">
                        padding-box-30 (30px)<br />
                        <i className="icon-chevron-down" />
                      </div>
                      <img
                        src={require('./images/padding-box-30.png')}
                        className="margin-top-5"
                        alt=""
                      />
                    </div>
                    <div className="col-xs-4 text-center">
                      <div className="text-18">
                        padding-h-20 (20px)<br />
                        <i className="icon-chevron-down" />
                      </div>
                      <img
                        src={require('./images/padding-h-20.png')}
                        className="margin-top-5"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="row margin-bottom-clean-form">
                    <div className="col-xs-4 text-center">
                      <div className="text-18">
                        padding-h-30 (30px)<br />
                        <i className="icon-chevron-down" />
                      </div>
                      <img
                        src={require('./images/padding-h-30.png')}
                        className="margin-top-5"
                        alt=""
                      />
                    </div>
                    <div className="col-xs-4 text-center">
                      <div className="text-18">
                        padding-v-20 (20px)<br />
                        <i className="icon-chevron-down" />
                      </div>
                      <img
                        src={require('./images/padding-v-20.png')}
                        className="margin-top-5"
                        alt=""
                      />
                    </div>
                    <div className="col-xs-4 text-center">
                      <div className="text-18">
                        padding-v-30 (30px)<br />
                        <i className="icon-chevron-down" />
                      </div>
                      <img
                        src={require('./images/padding-v-30.png')}
                        className="margin-top-5"
                        alt=""
                      />
                    </div>
                    <div className="col-xs-4 text-center">
                      <div className="text-18">
                        padding-v-50 (50px)<br />
                        <i className="icon-chevron-down" />
                      </div>
                      <img
                        src={require('./images/padding-v-50.png')}
                        className="margin-top-5"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>

              <hr />
              {/* //// BG COLORS //// */}
              <div className="bg-colors" name="bg-colors" id="bg-colors">

                <div className="title-36 text-dark text-light md-margin">
                  Background colors
                </div>

                <div className="row">
                  <div className="col-sm-6 text-center">
                    <div className="card padding-box-20 bg-color-brand-main text-white">
                      bg-color-brand-main
                    </div>
                  </div>
                  <div className="col-sm-6 text-center">
                    <div className="card padding-box-20 bg-color-brand-secondary text-white">
                      bg-color-brand-secondary
                    </div>
                  </div>
                  <div className="col-sm-6 text-center">
                    <div className="card padding-box-20 bg-color-brand-secondary-darken text-white">
                      bg-color-brand-secondary-darken
                    </div>
                  </div>
                  <div className="col-sm-6 text-center">
                    <div className="card padding-box-20 bg-color-brand-success text-white">
                      bg-color-brand-success
                    </div>
                  </div>
                  <div className="col-sm-6 text-center">
                    <div className="card padding-box-20 bg-color-brand-button text-white">
                      bg-color-brand-button
                    </div>
                  </div>
                  <div className="col-sm-6 text-center">
                    <div className="card padding-box-20 bg-color-brand-accent text-white">
                      bg-color-brand-accent
                    </div>
                  </div>
                  <div className="col-sm-6 text-center">
                    <div className="card padding-box-20 bg-orange-color text-white">
                      bg-orange-color
                    </div>
                  </div>
                  <div className="col-sm-6 text-center">
                    <div className="card padding-box-20 bg-mild-grey-color text-white">
                      bg-mild-grey-color
                    </div>
                  </div>
                  <div className="col-sm-6 text-center">
                    <div className="card padding-box-20 bg-providers-error-color text-white">
                      bg-providers-error-color
                    </div>
                  </div>
                  <div className="col-sm-6 text-center">
                    <div className="card padding-box-20 bg-transparent">
                      bg-transparent
                    </div>
                  </div>
                </div>
              </div>

              <div className="top-icon">
                <button className="btn btn-round-plus btn-big">
                  <a href="#buttons">
                    <i className="fa fa-angle-double-up" />
                  </a>
                </button>
              </div>

            </div>
          </div>
        </div>
        <div className="page-bg"></div>

        <IconsModal
          isOpen={this.state.isModalOpen}
          handleClose={this.closeModal}
        />
      </div>
    );
  }
}

export default StyleGuide;
