import React from 'react';
import PropTypes from 'prop-types';
import { compose, withHandlers } from 'recompose';
import {
  createToggleStatePropTypes,
  createToggleState,
} from 'Components/recompose/toggleStateComponent';
import DateOfBirth from '../activation/components/dateOfBirth';
import ActivationLayout from '../activation/components/activationLayout';

// TODO: Decide if we want to remove this code or not. it is stale atm.
const DateOfBirthPage = props => (
  <ActivationLayout
    alreadyActivatedModalShow={props.alreadyActivatedModalShow}
    alreadyActivatedModalHide={props.alreadyActivatedModalHide}
    alreadyActivatedModalOn={props.alreadyActivatedModalOn}
    onSigninClick={props.onSigninClick}
    contentOnly
  >
    <DateOfBirth loading={props.activationTracking.inProgress} onSubmit={props.activateWithDOB} />
  </ActivationLayout>
);

DateOfBirthPage.propTypes = {
  router: PropTypes.object.isRequired,
  activateWithDOB: PropTypes.func.isRequired,
  ...createToggleStatePropTypes('alreadyActivatedModal'),
};

export default compose(
  withHandlers({
    onSigninClick: props => () => {
      props.router.push('/signIn');
    },
  }),
  createToggleState('alreadyActivatedModal'),
)(DateOfBirthPage);
