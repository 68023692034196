/**
 * Created by guyavarham on 05/04/2017.
 */

import _ from 'lodash';

import IntlEnum from '../../../utils/intlEnum';
import { messages } from '../../../messages/searchDoctorResults/messages';
import { ENGLISH, getLanguagesEnum, SPANISH } from '../../../store/intl/languages';

// todo: move this config to be per domain or per flow, depends on our future unknown requirements
const languagesForFilter = [ENGLISH, SPANISH];

const definitions = [
  {
    key: 'acceptsNewPatients',
    options: new IntlEnum({ value: true, message: messages.acceptsNewPatients }),
    defaultValue: 'true', // its a string because its all based on query strings
  },
  {
    key: 'distance',
    options: new IntlEnum(
      { value: '5mi', message: messages.mi5 },
      { value: '10mi', message: messages.mi10 },
      { value: '20mi', message: messages.mi20 },
      { value: '20plusmi', message: messages.mi20plus },
    ),
    defaultValue: '20plusmi',
  },
  {
    key: 'gender',
    options: new IntlEnum(
      { value: 'FEMALE', message: messages.female },
      { value: 'MALE', message: messages.male },
      { value: 'ANY', message: messages.any },
    ),
    defaultValue: 'ANY',
  },
  {
    key: 'language',
    options: getLanguagesEnum(languagesForFilter),
    defaultValue: ENGLISH,
  },
];

export function definitionsByKey(key) {
  return _.find(definitions, def => def.key === key);
}

export const getAllFilterKeys = () => _.map(definitions, def => def.key);

export const getDefaultsObject = () =>
  _.chain(definitions)
    .keyBy('key')
    .mapValues('defaultValue')
    .value();

export function areAllSetToDefault(currentFilters) {
  return _.every(currentFilters, (value, key) => {
    const definition = definitionsByKey(key);
    if (!definition) return true;
    return _.isEqual(value.toString(), definition.defaultValue);
  });
}

export default definitions;
