import _ from 'lodash';
import Promise from 'bluebird';
import { geocodeByAddress } from 'react-places-autocomplete';

/* eslint no-undef: 2 */

export function geocodeByLocation(location, callback) {
  /* eslint-disable no-undef */
  const geocoder = new google.maps.Geocoder();
  const OK = google.maps.GeocoderStatus.OK;
  /* eslint-enable no-undef */

  return new Promise((resolve, reject) => {
    geocoder.geocode({ location }, (results, status) => {
      if (status !== OK) {
        if (callback) {
          callback({ status }, results);
          return;
        }
        reject(status);
      }

      if (callback) {
        callback(null, results);
      }
      resolve(results);
    });
  });
}

function _findType(googleResult, type) {
  return _.find(googleResult.address_components, addComponent =>
    _.includes(addComponent.types, type),
  );
}

export function getZipCodeItem(googleResult) {
  return _findType(googleResult, 'postal_code');
}

export function getState(googleResult) {
  const stateItem = _findType(googleResult, 'administrative_area_level_1');
  return stateItem ? stateItem.short_name : '';
}

export function getCity(googleResult) {
  const cityItem = _findType(googleResult, 'locality');
  return cityItem ? cityItem.long_name : '';
}

export function getStreet(googleResult) {
  let streetNumber = _findType(googleResult, 'street_number');
  streetNumber = streetNumber ? streetNumber.long_name : '';

  let streetName = _findType(googleResult, 'route');
  streetName = streetName ? streetName.short_name : '';

  if (_.isEmpty(streetNumber) || _.isEmpty(streetName)) return '';
  return `${streetNumber} ${streetName}`;
}

export function getClosestZipCode(googleResult) {
  try {
    const zipCodeItem = getZipCodeItem(googleResult);

    if (!zipCodeItem) {
      // if the address dont have a zip code - gets the nearest one
      return geocodeByLocation(googleResult.geometry.location)
        .then(nearestLocations => {
          const nearestZipCodeItem = getZipCodeItem(
            _.find(nearestLocations, nearestLocation => getZipCodeItem(nearestLocation)),
          );
          return nearestZipCodeItem ? nearestZipCodeItem.long_name : null;
        })
        .catch(() => null);
    }
    return Promise.resolve(zipCodeItem.long_name);
  } catch (e) {
    return Promise.resolve('');
  }
}

export const getGeoAndZip = async address => {
  const addressResult = _.first(await geocodeByAddress(address));
  const zip = await getClosestZipCode(addressResult);
  const state = getState(addressResult);
  const {
    geometry: { location },
  } = addressResult;

  return {
    address,
    latitude: location.lat(),
    longitude: location.lng(),
    zip,
    state,
  };
};

export function enrichGeoWithZipAndState(geoObj) {
  if (!geoObj) return Promise.resolve(null);
  if (geoObj.state && geoObj.zip) return Promise.resolve(geoObj);
  return geocodeByLocation({ lat: _.toNumber(geoObj.latitude), lng: _.toNumber(geoObj.longitude) })
    .then(googleResults => {
      const geo = {
        ...geoObj,
        state: getState(googleResults[0]),
        zip: getClosestZipCode(googleResults[0]),
      };
      return Promise.props(geo);
    })
    .catch(() => Promise.resolve(geoObj));
}

export async function getVimFormatAddress(address) {
  const addressResult = _.first(await geocodeByAddress(address));
  const zip = await getClosestZipCode(addressResult);

  return {
    city: getCity(addressResult),
    state: getState(addressResult),
    zip,
    addressLine1: getStreet(addressResult),
    addressLine2: '',
  };
}
