/**
 * Created by guyavarham on 18/07/2017.
 */

import _ from 'lodash';
import { push } from 'react-router-redux';

import * as platformSelectors from '../../../../store/platform/selectors';
import routingSelectors from '../../../../store/routing/selectors';
import * as qsSelectors from './directorySelectors';
import PromiseActionType from '../../../../store/promiseActionType';
import { openSearchModal, focusSearchTypeahead } from '../../../../store/ui/actions';
import { searchFreeText } from '../../../../store/directory/autoCompleteProvider/actions';
import { getTaxonomies } from '../../../../store/directory/autoCompleteProvider/selectors';

const SEARCH_AND_OPEN_RESULTS = new PromiseActionType('SEARCH_AND_OPEN_RESULTS');
const findDoctorQSBlackList = ['locationId'];

export function openFindDoctors(paths) {
  return ({ getState }) => {
    const state = getState();
    const isMobile = platformSelectors.isMobileOrTablet(state);
    if (isMobile) {
      // mobile ux
      return openSearchModal();
    }

    // desktop ux: open find doctors
    const currRoute = routingSelectors.currRoute(state);
    if (_.isEqual(currRoute.pathname, paths.home())) {
      return focusSearchTypeahead(true);
    }

    const newQueryString = qsSelectors.getFilteredQS(currRoute.query, findDoctorQSBlackList);

    // add default codes if needed
    const {
      config: {
        directory: {
          searchResults: { pcpTaxonomies },
        },
      },
    } = state;
    if (!newQueryString.codes) newQueryString.codes = pcpTaxonomies;

    return push(paths.home(newQueryString));
  };
}

export function openDoctorDetails(npi, locationId, paths, chosenCode, ranking) {
  return ({ getState }) => {
    const state = getState();
    const currRoute = routingSelectors.currRoute(state);
    const newQueryString = qsSelectors.getFilteredQS(currRoute.query);
    if (locationId) _.assign(newQueryString, { locationId, chosenCode, ranking });
    return push(paths.doctorDetails(npi, newQueryString));
  };
}

/**
 * @param ignoreCurrentQS - base the search only on the @params parameter
 * and not combine it with the current query
 */
export function openDoctorsResults(paths, params, ignoreCurrentQS = false) {
  return ({ getState }) => {
    const state = getState();
    let newQueryString;

    const currRoute = routingSelectors.currRoute(state);
    const currQS = qsSelectors.getFilteredQS(currRoute.query, findDoctorQSBlackList);

    if (!ignoreCurrentQS) {
      newQueryString = { ...currQS, ...params };
    } else {
      const { geo, address } = currQS; // get basic search variables
      newQueryString = { ...params, geo, address };
    }

    // add default codes if needed
    const {
      config: {
        directory: {
          searchResults: { pcpTaxonomies },
        },
      },
    } = state;
    if (!newQueryString.codes) newQueryString.codes = pcpTaxonomies;

    return push(paths.searchDoctorsResults(newQueryString));
  };
}

/**
 * Searches for a text in the search engine, takes the first result and opens the results page
 * @param paths
 * @param text
 * @returns {function(): {type: (*|string), payload: {promise: Promise.<TResult>}}}
 */
export function searchAndOpenResults(paths, text, location) {
  return ({ getState, dispatch }) => ({
    type: SEARCH_AND_OPEN_RESULTS.SOURCE,
    payload: {
      promise: dispatch(searchFreeText(text, location, true)).then(() => {
        // Assuming taxonomy
        const searchTaxonomies = getTaxonomies(getState());
        // Taking the first result
        const codes = _.get(searchTaxonomies, '[0].data.codes', []);
        dispatch(openDoctorsResults(paths, { codes, searchTerm: text }));
      }),
    },
  });
}
