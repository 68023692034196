import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { compose, pure, setDisplayName, withStateHandlers, withHandlers } from 'recompose';
import _ from 'lodash';
import CleanRadioButton from '../components/form/cleanRadioButton';

function RadioButton({ option, onChange, chosen }) {
  return (
    <CleanRadioButton
      id={option.id}
      className="checklist-style"
      label={option}
      radioGroup="radioGroup"
      checked={chosen === option}
      onChange={onChange}
    />
  );
}

RadioButton.propTypes = {
  chosen: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  option: PropTypes.object.isRequired,
};

RadioButton.defaultProps = {
  chosen: null,
};

const RadioButtonEnhanced = withHandlers({
  onChange: ({ option, onChange }) => () => onChange(option),
})(RadioButton);

function RadioBtnChoice({
                          className,
                          question,
                          buttonText,
                          onButtonClick,
                          onChange,
                          options,
                          chosen,
                        }) {
  return (
    <div className={classNames('radio-btn-list-page', className)}>
      <div className="row no-margin">
        <div className="col-xs-12">
          <div className="card">
            <div className="card-body padding-box-30">
              <div className="row">
                <div className="col-xs-12">
                  <div className="title-24 text-strong-dark text-center no-margin">{question}</div>
                </div>
              </div>

              <div className="row margin-top-30">
                <div className="col-xs-12">
                  {_.map(options, option => (
                    <RadioButtonEnhanced
                      option={option}
                      onChange={onChange}
                      chosen={chosen}
                    />
                  ))}
                </div>
              </div>

              <div className="row tiny-gutter margin-top-30">
                <div className="col-xs-12">
                  <button
                    className="btn btn-big bg-color-brand-button"
                    disabled={_.isEmpty(chosen)}
                    onClick={onButtonClick}
                  >
                    {buttonText}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

RadioBtnChoice.propTypes = {
  buttonText: PropTypes.string,
  chosen: PropTypes.object,
  className: PropTypes.string,
  onButtonClick: PropTypes.func,
  onChange: PropTypes.func,
  options: PropTypes.array,
  question: PropTypes.string,
};
RadioBtnChoice.defaultProps = {
  buttonText: 'Confirm',
  chosen: undefined,
  className: undefined,
  onButtonClick: _.noop,
  onChange: _.noop,
  options: undefined,
  question: undefined,
};

const enhance = compose(
  setDisplayName('SCR017RadioBtnChoice'),
  pure,
  withStateHandlers(
    { chosen: null },
    {
      onChange: () => change => ({ chosen: change }),
      onButtonClick: (state, props) => () => props.onButtonClick(state.chosen),
    },
  ),
);

export default enhance(RadioBtnChoice);
