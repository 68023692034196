/**
 * Created by matan on 1/8/17.
 */
import React from 'react';
import classNames from 'classnames';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { autobind } from 'core-decorators';
import _ from 'lodash';
import analytics from '../../../store/tools/analytics/analyticsHoc';
import { IntlString, MessageShape } from '../../ui/intlString';
import ClickableDiv from '../../clickableElement/clickableDiv';
import ClickableI from '../../clickableElement/clickableI';

import './itemList.less';

@autobind
class ItemList extends React.Component {
  static propTypes = {
    className: React.PropTypes.string,
    /**
     * React class definition (ctor).
     * The row element class definition, set the row presented in the list.
     */
    RowType: React.PropTypes.func.isRequired,
    /**
     * List title. can either be a string or an intl message
     * Default is an empty string.
     */
    title: MessageShape,
    /**
     * Class name to apply to list title
     */
    titleClassName: React.PropTypes.string,
    /**
     * Add button text.
     * Default is Add
     */
    buttonText: MessageShape,
    /**
     * Array of values to display as the list content.
     * Default is an empty array.
     */
    value: React.PropTypes.array,
    /**
     * Error text to display. string or intl message.
     */
    errorText: MessageShape,
    /**
     * Hook method, called on each change to the values array.
     * Method is called with 3 parameters.
     * The new array (original props are not mutated)
     * The type of changed (create, update or delete)
     * The changed item (after change). undefined for delete.
     */
    onChange: React.PropTypes.func.isRequired,
    /**
     * optional function.
     * called to validate row current value to disable/enable confirm buttons
     */
    rowValidator: React.PropTypes.func,
  };

  // noinspection JSUnusedGlobalSymbols (used implicitly by react)
  static defaultProps = {
    title: '',
    value: [],
    buttonText: 'Add',
    className: '',
    titleClassName: '',
    errorText: '',
    rowValidator: () => true,
  };

  constructor(props) {
    super(props);
    this.state = { editIndex: NaN, valueInEdit: {}, newLineValue: undefined };
  }

  onCreate(e) {
    e.preventDefault();
    const { newLineValue } = this.state;
    // NaN since new row has no index. yet.
    this.notifyChanged(NaN, 'create', newLineValue);

    this.setState({ newLineValue: undefined });
  }

  onRowEdit(change) {
    this._onEdit('valueInEdit', change);
  }

  onNewEdit(change) {
    this._onEdit('newLineValue', change);
  }

  getOtherProps() {
    return _.omit(this.props, _.keys(ItemList.propTypes));
  }

  notifyChanged(index, type, replaceWith) {
    const { onChange, value } = this.props;
    if (!onChange) return;
    index = _.defaultTo(index, value.length);

    // Create a new values array without mutating the props array
    const newValues = [...value.slice(0, index)];
    if (!_.isNil(replaceWith)) newValues.push(replaceWith);
    newValues.push(...value.slice(index + 1));

    onChange(newValues, type, replaceWith);
  }

  startEdit(rowIndex) {
    const original = this.props.value[rowIndex];
    const shallowCopy = _.isObject(original) ? _.assign({}, original) : original;
    this.setState({ editIndex: rowIndex, valueInEdit: shallowCopy });
  }

  _onEdit(stateKey, change) {
    this.setState({ [stateKey]: change });
  }

  saveEdit() {
    const { editIndex, valueInEdit } = this.state;
    this.notifyChanged(editIndex, 'update', valueInEdit);

    this.setState({ editIndex: NaN, valueInEdit: {} });
  }

  rollbackEdit() {
    this.setState({ editIndex: NaN, valueInEdit: {} });
  }

  deleteRow(rowIndex) {
    this.notifyChanged(rowIndex, 'deleted');
  }

  renderDisplayButtons(rowIndex) {
    const editRow = () => this.startEdit(rowIndex);
    const deleteRow = () => this.deleteRow(rowIndex);
    return (
      <div className="action-menu">
        <OverlayTrigger overlay={<Tooltip>Edit</Tooltip>} placement="bottom">
          <ClickableI
            clickHandler={editRow}
            className={classNames('icon-pencil tooltip-icon icon-1-5',
              { hidden: !_.isNaN(this.state.editIndex) })}
          />
        </OverlayTrigger>

        <OverlayTrigger overlay={<Tooltip>Delete</Tooltip>} placement="bottom">
          <ClickableI className="icon-trash tooltip-icon icon-1-5" clickHandler={deleteRow} />
        </OverlayTrigger>
      </div>
    );
  }

  renderEditButtons(rowIndex) {
    const { rowValidator } = this.props;
    const { valueInEdit } = this.state;
    return (
      <div className="action-menu round-confirm-icons">
        <ClickableI clickHandler={this.rollbackEdit} className="cancel-icon fa fa-close fucker" />
        <ClickableDiv clickHandler={this.saveEdit} className="confirm-icon">
          <i
            className={classNames('fa fa-check',
            { 'disabled-icon': !rowValidator(valueInEdit, rowIndex) })}
          />
        </ClickableDiv>
      </div>
    );
  }

  renderRows() {
    const { RowType, ListRowType, value } = this.props;
    const { editIndex, valueInEdit } = this.state;
    // if list type was submitted use it otherwise use the RowType
    const LineType = ListRowType || RowType;
    return _.map(value, (rowValue, index) => {
      const rowInEdit = index === editIndex;
      // If row is in edit mode, pass the state sandbox value
      if (rowInEdit) rowValue = valueInEdit;

      return (
        <li key={index}>
          <LineType
            isEditMode={rowInEdit}
            value={rowValue}
            onChange={this.onRowEdit}
            {...this.getOtherProps()}
          />
          {rowInEdit ? this.renderEditButtons(index) : this.renderDisplayButtons(index)}
        </li>
      );
    });
  }

  renderError() {
    const { errorText } = this.props;
    return _.isEmpty(errorText) ? null :
      (<IntlString className="validation-message" message={errorText} />);
  }

  render() {
    const {
      className,
      errorText,
      title,
      titleClassName,
      RowType,
      rowValidator,
      buttonText,
    } = this.props;
    const { newLineValue } = this.state;

    return (
      <div
        className={classNames(
          className,
          'material-form-group',
          { 'has-error': !_.isEmpty(errorText) },
        )}
      >
        <div className="row">
          <div className="col-xs-12">
            <IntlString className={titleClassName} message={title} />
          </div>
        </div>

        <div className="editable-list-wrap">
          <ul className="editable-list">
            {this.renderRows()}
          </ul>

          <div className="big-search-input multiple-inputs editable-list-input">
            <div className="row no-margin">
              <div className="col-xs-12 big-search-btn-padding">
                <RowType
                  {...this.getOtherProps()}
                  isEditMode
                  value={newLineValue}
                  onChange={this.onNewEdit}
                />
                <button
                  className="btn btn-outline inline-right"
                  onClick={this.onCreate}
                  disabled={!rowValidator(newLineValue, NaN)}
                >
                  <IntlString message={buttonText} />
                </button>
              </div>
            </div>
          </div>

          {this.renderError()}
        </div>
      </div>
    );
  }
}

export default analytics(ItemList, false);
