/**
 * Created by galgoltzman on 11/06/2017.
 */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { MessageShape, IntlString } from '../../../../../components/ui/intlString';

import './blueHeader.less';

const leftStars = require('./stars-left.png');
const rightStars = require('./stars-right.png');

class BlueHeader extends React.PureComponent {
  static propTypes = {
    blueHeaderClass: PropTypes.string,
    mainIconSrc: PropTypes.string,
    mainSvgComp: PropTypes.object,
    title: MessageShape,
  };

  static defaultProps = {
    blueHeaderClass: undefined,
    mainIconSrc: undefined,
    mainSvgComp: undefined,
    title: undefined,
  };

  render() {
    const { blueHeaderClass, mainIconSrc, mainSvgComp: MainSvg, title } = this.props;

    return (
      <div className={classNames('blue-header', blueHeaderClass)}>
        {MainSvg ? (
          <div className="main-svg">{MainSvg}</div>
        ) : (
          <div className="main-icon">
            <img src={leftStars} className="stars" alt="" />
            <img src={mainIconSrc} className="main-img" alt="" />
            <img src={rightStars} className="stars" alt="" />
          </div>
        )}
        <div className="title">
          <h1 className="title-brand-3 text-18 font-color-brand-main">
            <IntlString message={title} />
          </h1>
        </div>
      </div>
    );
  }
}

export default BlueHeader;
