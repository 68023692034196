import { autobind } from 'core-decorators';
import BkmdApi from './bkmdApi';
import { freeTextMapping } from '../utils/mapping/search';

// eslint-disable-next-line
const SERVICE_NAME = 'pcpSelection';

/**
 * Represents the pcp selection API
 */
@autobind
class PCPSelectionApi extends BkmdApi {
  constructor(fetch) {
    super(fetch, '/pcpSelection');
  }

  getPcpSelection() {
    return this.get('/', {});
  }

  searchPCPs(query, apiKey) {
    return this.get('/search/code', { ...query, apiKey });
  }

  async searchPCPsByName(freeText, apiKey) {
    const result = await this.get('/search/name', { freeText, apiKey });
    const { physicians } = freeTextMapping(result);
    return { physicians: physicians.data };
  }

  updatePcpSelection({ npi, locationId }) {
    return this.post('/updatePcpSelection', { npi, locationId });
  }

  async generateToken(activationToken) {
    return this.post('/generateToken', { activationToken }).then(response => response.data.token);
  }
}

export default PCPSelectionApi;
