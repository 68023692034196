/**
 * Created by galgoltzman on 26/06/2017.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { defineMessages, FormattedMessage } from 'react-intl';
import _ from 'lodash';

import ThreeBounceSpinner from '../../../../../components/ui/spinner/threeBounceSpinner';
import KidsStep from './steps/kidsStep';
import * as selectors from '../../../../../store/familyActivation/selectors';

import './familyActivation.less';

const messages = defineMessages({
  inviteTheRest: {
    defaultMessage: 'Invite the rest of my family',
    id: 'kids.inviteTheRest',
  },
  allDone: {
    defaultMessage: 'All done',
    id: 'kids.allDone',
  },
});

@autobind
class FamilyActivation extends React.PureComponent {
  static propTypes = {
    onNext: PropTypes.func.isRequired,
    kidsList: PropTypes.arrayOf(PropTypes.object),
    isFinal: PropTypes.bool,
  };

  static defaultProps = {
    kidsList: null,
    isFinal: false,
  };

  renderKids() {
    const { kidsList } = this.props;
    if (_.isNil(kidsList)) return <ThreeBounceSpinner />;
    return <KidsStep kidsList={kidsList} />;
  }

  render() {
    const { onNext, isFinal } = this.props;
    return (
      <div className="">
        {this.renderKids()}

        <div className="margin-top">
          <button className="btn btn-big bg-color-brand-button" onClick={onNext}>
            <FormattedMessage {...(isFinal ? messages.allDone : messages.inviteTheRest)} />
          </button>
        </div>
      </div>
    );
  }
}

export default compose(
  withRouter,
  connect(state => ({ kidsList: selectors.getGuardianDependents(state) })),
)(FamilyActivation);
