import { compose } from 'redux';
import { lifecycle } from 'recompose';
import { fetchMemberNotEmpty, fetchSettingsNotEmpty } from './fetchers';
import { startMemberSession, getMemberSessionId } from '../../utils/storage/memberSession';

/**
 * Wraps a component and injects member details to it's redux state, including the settings entity
 * @param Component
 * @returns {*}
 */
export default function injectMemberDetails(Component) {
  return compose(
    fetchMemberNotEmpty(true),
    fetchSettingsNotEmpty(),
    lifecycle({
      componentDidMount() {
        if (!getMemberSessionId()) startMemberSession();
      },
    }),
  )(Component);
}
