/* eslint-disable import/first */
import SignIn from '../auth/signInPage';

if (typeof require.ensure !== 'function') require.ensure = (d, c) => c(require);

import App from '../app/App';
import Home from '../home/home';
import injectPathsToContext from './injectPathsToContext';
import NotFound from '../../../../components/pages/notfound/pageNotFound';
import securePaths from './secure/securePaths';
import { paths as publicPaths } from './openDirectoryRoutes';
import RoutesBuilder from './routesBuilder';
import routeStrategies from './routeStrategies';

const homePage = {
  name: 'Home',
  component: Home.default || Home,
};
const notFound = {
  path: '*',
  name: 'NotFound',
  component: NotFound,
};
const signIn = {
  path: 'signIn',
  name: 'SignIn',
  component: SignIn.default || SignIn,
};

export default function getAppRoutes(isTestMode, featureFlags) {
  let indexRoute;

  if (featureFlags.securedDirectory) {
    indexRoute = signIn;
  } else if (featureFlags.openDirectory) {
    indexRoute = homePage;
  }
  // any if both of the feature flags is off - not found.
  else {
    indexRoute = notFound;
  }

  const components = RoutesBuilder.getRoutes(
    { isTestMode, features: featureFlags },
    routeStrategies,
  );

  // hacky shit:
  // the way we split the routes now is not good. we split it according to if the flow is
  // authenticated or not.
  // In this case we want the review summery page to use the authenticated paths even though the
  // flow itself is unauthenticated
  if (featureFlags.securedDirectory && !featureFlags.openDirectory) {
    publicPaths.securedFacilitySearchResults = securePaths.facilitySearchResults;
    publicPaths.doctorDetails = securePaths.doctorDetails;
    publicPaths.searchDoctorsResults = securePaths.searchDoctorsResults;
  }

  return {
    path: '/',
    component: injectPathsToContext(securePaths, publicPaths)(App),
    getChildRoutes(location, cb) {
      require.ensure([], () => {
        cb(null, components);
      });
    },
    indexRoute,
  };
}
