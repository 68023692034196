import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '../../storybook/src/utils/theme';

function SvgPencilStars({ theme }) {
  /* eslint-disable */
  return (
    <svg width="70px" height="64px" viewBox="0 0 70 64" className="svg-icon svg-pencil-stars">
      <defs>
        <polygon id="path-1" points="0.519788868 0.940529179 38.94 0.940529179 38.94 64 0.519788868 64"></polygon>
      </defs>
      <g id="V0" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Creating-and-replying" transform="translate(-789.000000, -321.000000)">
          <g id="Group-5-Copy" transform="translate(534.000000, 190.000000)">
            <g id="Group-23-Copy-4">
              <g id="Group-15" transform="translate(90.000000, 50.000000)">
                <g id="Group-14" transform="translate(165.000000, 80.000000)">
                  <path d="M11.5,64.0595 L1.5,64.0595 C0.672,64.0595 0,63.3875 0,62.5595 C0,61.7315 0.672,61.0595 1.5,61.0595 L11.5,61.0595 C12.328,61.0595 13,61.7315 13,62.5595 C13,63.3875 12.328,64.0595 11.5,64.0595 M24.5,64.0595 L17.5,64.0595 C16.672,64.0595 16,63.3875 16,62.5595 C16,61.7315 16.672,61.0595 17.5,61.0595 L24.5,61.0595 C25.328,61.0595 26,61.7315 26,62.5595 C26,63.3875 25.328,64.0595 24.5,64.0595 M33.456,64.0595 L30.5,64.0595 C29.672,64.0595 29,63.3875 29,62.5595 C29,61.7315 29.672,61.0595 30.5,61.0595 L33.456,61.0595 C34.284,61.0595 34.956,61.7315 34.956,62.5595 C34.956,63.3875 34.284,64.0595 33.456,64.0595" id="Fill-1" fill={theme.secondaryColor} ></path>
                  <g id="Group-5" transform="translate(31.000000, 0.059500)">
                    <g id="Clip-4"></g>
                    <path d="M6.4482,58.7842 L4.2802,57.6992 L3.7562,52.0052 L11.3302,55.8232 L6.4482,58.7842 Z M22.6342,11.8772 L23.3182,10.5192 L33.0462,15.4242 L32.3782,16.7492 L22.6342,11.8772 Z M29.3182,4.2092 L34.5662,6.8542 C35.7972,7.4742 36.2942,8.9812 35.6732,10.2132 L34.3962,12.7452 L24.6692,7.8412 L25.9372,5.3242 C26.5612,4.0852 28.0812,3.5842 29.3182,4.2092 Z M35.9172,4.1752 L30.6692,1.5292 C27.9502,0.1622 24.6282,1.2572 23.2582,3.9732 L21.3142,7.8302 L19.5162,11.3962 C19.3852,11.5162 19.2692,11.6602 19.1842,11.8292 C19.1012,11.9952 19.0572,12.1702 19.0392,12.3452 L16.7802,16.8252 C16.4082,17.5642 16.7052,18.4672 17.4452,18.8402 C18.1812,19.2102 19.0872,18.9162 19.4602,18.1752 L21.2842,14.5552 L31.0272,19.4282 L13.7582,53.6872 L4.0292,48.7832 L11.8972,33.1762 C12.2702,32.4352 11.9732,31.5342 11.2342,31.1612 C10.4962,30.7892 9.5932,31.0852 9.2192,31.8242 L0.6752,48.7722 C0.6712,48.7792 0.6702,48.7872 0.6662,48.7952 C0.6552,48.8162 0.6492,48.8402 0.6402,48.8622 C0.6022,48.9522 0.5722,49.0442 0.5522,49.1372 C0.5462,49.1722 0.5402,49.2062 0.5352,49.2422 C0.5222,49.3362 0.5182,49.4302 0.5232,49.5242 C0.5242,49.5452 0.5182,49.5642 0.5202,49.5852 L1.7202,62.6382 C1.7682,63.1522 2.0782,63.6062 2.5402,63.8402 C2.7532,63.9462 2.9832,64.0002 3.2152,64.0002 C3.4852,64.0002 3.7552,63.9272 3.9932,63.7822 L15.2002,56.9842 C15.2502,56.9542 15.2882,56.9122 15.3342,56.8762 C15.3692,56.8492 15.4032,56.8252 15.4342,56.7952 C15.5632,56.6762 15.6732,56.5432 15.7522,56.3892 C15.7552,56.3852 15.7592,56.3822 15.7612,56.3772 L36.4002,15.4342 L38.3522,11.5622 C39.7172,8.8542 38.6252,5.5402 35.9172,4.1752 Z" id="Fill-3" fill={theme.mainColor} ></path>
                  </g>
                  <path d="M18.8184,40.0595 C17.9904,40.0595 17.3184,39.3875 17.3184,38.5595 L17.3184,31.1435 C17.3184,30.3155 17.9904,29.6435 18.8184,29.6435 C19.6464,29.6435 20.3184,30.3155 20.3184,31.1435 L20.3184,38.5595 C20.3184,39.3875 19.6464,40.0595 18.8184,40.0595" id="Fill-6" fill={theme.secondaryColor} ></path>
                  <path d="M22.5264,36.3515 L15.1104,36.3515 C14.2824,36.3515 13.6104,35.6795 13.6104,34.8515 C13.6104,34.0235 14.2824,33.3515 15.1104,33.3515 L22.5264,33.3515 C23.3544,33.3515 24.0264,34.0235 24.0264,34.8515 C24.0264,35.6795 23.3544,36.3515 22.5264,36.3515" id="Fill-8" fill={theme.secondaryColor} ></path>
                  <path d="M41.9746,20.1835 C41.1936,20.1835 40.4496,20.4825 39.8886,21.0425 C39.1356,21.7955 38.8546,22.8795 39.1366,23.9415 C39.3916,24.9065 40.1796,25.6945 41.1436,25.9495 C42.2056,26.2325 43.2896,25.9505 44.0426,25.1975 C44.7956,24.4445 45.0776,23.3605 44.7946,22.2985 C44.5396,21.3335 43.7516,20.5455 42.7876,20.2905 L42.7866,20.2905 C42.5156,20.2185 42.2436,20.1835 41.9746,20.1835 M41.9696,29.0595 C41.4426,29.0595 40.9076,28.9905 40.3746,28.8495 C38.3876,28.3225 36.7636,26.6985 36.2366,24.7095 C35.6776,22.6035 36.2496,20.4395 37.7676,18.9215 C39.2866,17.4045 41.4476,16.8325 43.5566,17.3905 C45.5756,17.9255 47.1606,19.5115 47.6956,21.5305 C48.2536,23.6365 47.6816,25.8005 46.1636,27.3185 C45.0296,28.4535 43.5336,29.0595 41.9696,29.0595" id="Fill-10" fill={theme.secondaryColor} ></path>
                  <path d="M65.874,44.62 C67.374,44.936 68.333,46.408 68.017,47.907 C67.7,49.407 66.228,50.366 64.728,50.05 C63.229,49.733 62.27,48.262 62.587,46.762 C62.903,45.263 64.375,44.304 65.874,44.62" id="Fill-12" fill={theme.secondaryColor} ></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
  /* eslint-enable */
}

SvgPencilStars.propTypes = {
  theme: PropTypes.object.isRequired,
};

export default withTheme(SvgPencilStars);
