/**
 * Created by chenrozenes on 04/05/2016.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import NotificationSystem from 'react-notification-system';
import _ from 'lodash';

import Formatter from '../../utils/formatter';
import { removeAllNotifications } from '../../store/notification/actions';

const style = {
  Containers: {
    DefaultStyle: {
      zIndex: 16000013,
    },
  },
};

/**
 * React component that displays notifications from redux state
 * @type {*|Function}
 */
class NotificationsContainer extends React.PureComponent {
  static propTypes = {
    removeAllNotifications: PropTypes.func.isRequired,
    notifications: PropTypes.array.isRequired,
    intl: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.refNotifications = this.refNotifications.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    const { intl, removeAllNotifications, notifications } = nextProps;

    _.each(notifications, currNotification => {
      this.notificationSystem.addNotification({
        ...currNotification,
        title: Formatter.intlToString(intl, currNotification.title),
        message: Formatter.intlToString(intl, currNotification.message),
      });
    });

    if (notifications.length > 0) {
      removeAllNotifications();
    }
  }

  refNotifications(input) {
    this.notificationSystem = input;
  }

  render() {
    return (
      <div aria-live="polite">
        <NotificationSystem ref={this.refNotifications} style={style} />
      </div>
    );
  }
}

export default compose(
  injectIntl,
  connect(
    state => ({
      notifications: state.notifications,
    }),
    { removeAllNotifications },
  ),
)(NotificationsContainer);
