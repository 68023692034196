import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { autobind } from 'core-decorators';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import _ from 'lodash';

import messages from '../messages';
import {
  chooseClientLocation,
  isLocationReady,
  waitForMyLocation,
} from '../../directory/directorySelectors';
import { member, memberPlan } from '../../../../../store/member/selectors';
import { openDoctorDetails } from '../../directory/routingActions';
import {
  GET_RECOMMENDED_PCP_DOCTORS,
  getRecommendedPcpDoctors,
} from '../../../../../store/directory/search/actions';
import actionTracker from '../../../../../store/tools/actionTracker/actionTrackerComponent';
import ThreeBounceSpinner from '../../../../../components/ui/spinner/threeBounceSpinner';
import { autoCompleteProvider, recommendedPcp } from '../../../../../store/directory/selectors';
import { getPathsFromContext } from '../../routes/injectPathsToContext';
import { clientLocationShape } from '../../../../../components/searchDoctors/clientLocationShape';
import RecommendedDoctorCard from '../../../../../components/doctorCard/recommendedProviderCard';
import withBookingModal from '../../../../../components/bookingModal/withBookingModal';
import * as NpiType from '../../../../../model/enum/npiType';
import Formatter from '../../../../../utils/formatter';
import BookingButton from '../../directory/components/bookButton/bookButton';
import { defaultHomepageSearch } from '../../../../../components/doctorCard/defaultHomepageSearch';
import { getFeature } from '../../../../../store/feature/selectors';
import withFeatureFlag from '../../../../../utils/featureFlags/withFeatureFlag';
import Api from '../../../../../api';
import { getMemberSessionId } from '../../../../../utils/storage/memberSession';
import SearchAction from '../../../../../model/enum/searchAction';

@autobind
class RecommendedProviders extends React.PureComponent {
  static propTypes = {
    validLocation: PropTypes.object.isRequired,
    paths: PropTypes.object.isRequired,
    clientLocation: PropTypes.shape(clientLocationShape).isRequired,
    openDoctorDetails: PropTypes.func.isRequired,
    getRecommendedPcpDoctors: PropTypes.func.isRequired,
    getRecommendDoctorsTracking: PropTypes.object.isRequired,
    memberPlan: PropTypes.string,
    recommendedPcp: PropTypes.object,
  };

  static defaultProps = {
    memberPlan: undefined,
    recommendedPcp: undefined,
  };

  componentDidMount() {
    this.getRecommendedDoctors(this.props);
  }

  componentWillReceiveProps(nextProps) {
    const before = {
      geo: _.get(this.props, 'clientLocation.geo', null),
      validLocation: _.get(this.props, 'validLocation', null),
    };
    const after = {
      geo: _.get(nextProps, 'clientLocation.geo', null),
      validLocation: _.get(nextProps, 'validLocation', null),
    };

    if (!_.isEqual(before, after)) this.getRecommendedDoctors(nextProps);
  }

  getRecommendedDoctors({
    validLocation,
    clientLocation,
    memberPlan,
    member,
    onlyInNetworkFeatureFlag,
    onlyInNetworkDomainConfig,
  }) {
    return (
      !_.isEmpty(validLocation) &&
      clientLocation &&
      this.props.getRecommendedPcpDoctors(
        clientLocation,
        memberPlan,
        member.memberToken,
        (onlyInNetworkFeatureFlag && onlyInNetworkDomainConfig) || undefined,
      )
    );
  }

  openDoctor(provider, locationId) {
    const { openDoctorDetails, paths } = this.props;

    Api.searchApi
      .searchActionAnalytics({
        queryId: defaultHomepageSearch,
        memberSessionId: getMemberSessionId(),
        npi: provider.npi,
        locationId,
        ranking: provider.ranking,
        actionType: SearchAction.DETAILS,
      })
      .catch();

    openDoctorDetails(provider.npi, locationId, paths);
  }

  renderDoctor({ data }) {
    const { clientLocation = {}, onBookClick, member } = this.props;
    const { closestLocation = {} } = data;
    return (
      <RecommendedDoctorCard
        {...data}
        key={data.npi}
        data={data}
        onBookClick={onBookClick}
        openDoctor={(npi, locationId) => this.openDoctor(data, locationId)}
        closestLocation={closestLocation}
        clientLocation={clientLocation.geo}
        detailsBtnClassName="btn-big"
        customBookButtonRenderProp={extraProps => (
          <BookingButton
            enableBooking
            onBookAppointmentClicked={onBookClick}
            pickedLocation={closestLocation}
            npiType={NpiType.INDIVIDUAL}
            targetNpi={_.get(data, 'npi')}
            targetLocationAddress={Formatter.addressFormat(closestLocation)}
            targetLocationGeo={closestLocation.geo}
            targetLocationPhone={closestLocation.phoneNumber}
            member={member}
            onShowBookingDialogSuccess={(entityId, bookingType) =>
              Api.searchApi
                .searchActionAnalytics({
                  queryId: defaultHomepageSearch,
                  memberSessionId: getMemberSessionId(),
                  npi: data.npi,
                  locationId: _.get(data, 'locations[0].id'),
                  ranking: data.ranking,
                  actionType: bookingType,
                  entityId,
                })
                .catch()}
            btnClassName="btn-block"
            {...extraProps}
          />
        )}
      />
    );
  }

  render() {
    const {
      recommendedPcp: { data },
      getRecommendDoctorsTracking,
      clientLocation,
      validLocation,
    } = this.props;
    if (
      _.isEmpty(validLocation) ||
      _.get(getRecommendDoctorsTracking, 'inProgress', false) === true
    ) {
      return <ThreeBounceSpinner />;
    }
    return (
      <div className="recommended-doctors-row">
        <div className="brand-title-2 title-24 no-margin text-medium text-center padding-v-30">
          <span className="font-color-brand-secondary default-cursor-override">
            <FormattedMessage {...messages.doctors} />
          </span>
          &nbsp;
          <span className="text-dark">
            <FormattedMessage {...messages.doctorsInYourArea} />
          </span>
        </div>
        <div className="row equal-cols margin-top-20">
          {/* only if we have valid data and valid location */}
          {!_.isEmpty(data) && !_.isEmpty(clientLocation) && _.map(data, this.renderDoctor)}
        </div>
      </div>
    );
  }
}

export default compose(
  getPathsFromContext(),
  withFeatureFlag({
    key: 'premera.providers.onlyInNetwork',
    prop: 'onlyInNetworkFeatureFlag',
    defaultValue: false,
  }),
  connect(
    state => ({
      validLocation: isLocationReady(state, {
        waitForMyLocation: waitForMyLocation(state),
      }),
      clientLocation: chooseClientLocation(state),
      memberPlan: memberPlan(state),
      recommendedPcp: recommendedPcp(state),
      freeText: autoCompleteProvider(state),
      plan: memberPlan(state),
      member: member(state),
      onlyInNetworkDomainConfig: getFeature(state, 'search.onlyInNetwork', false),
    }),
    {
      openDoctorDetails,
      getRecommendedPcpDoctors,
    },
  ),
  withBookingModal(),
  actionTracker({
    getRecommendDoctorsTracking: GET_RECOMMENDED_PCP_DOCTORS.SOURCE,
  }),
)(RecommendedProviders);
