/**
 * Created by moran on 5/29/17.
 */
import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';

import analytics from '../../store/tools/analytics/analyticsHoc';
import CleanInput from './cleanInput';

@autobind
class CleanZipInput extends React.Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired
  };

  /**
   * this function formats the value in the right way.
   * remove all none numeric characters and truncates to 5 digits.
   * @param e
   */
  onChange(e) {
    this.props.onChange(_.truncate(e.target.value.replace(/\D/g, ''), { length: 5, omission: '' }));
  }

  render() {
    return (
      <CleanInput
        {...this.props}
        type="text"
        onChange={this.onChange}
      />
    );
  }
}

export default analytics(CleanZipInput, false, [
  { event: 'onChange', eventData: newZip => ({ newZip }) },
]);
