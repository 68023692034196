/**
 * Created by noahguld on 04/07/2017.
 */
import _ from 'lodash';
import { createSelector } from 'reselect';

import BookMdUtil from '../../../../../utils/util';

export const getFacilitiesSelector = state => state.facilitySearch.facilities;

export const getLocationSelector = (state, clientLocation) => clientLocation;

export const sortByDistance = createSelector(
  [getFacilitiesSelector, getLocationSelector],
  (facilities, clientLocation) => {
    let flatFacilities = [];
    _.forOwn(facilities, facilityList => {
      flatFacilities = flatFacilities.concat(facilityList);
    });

    if (!clientLocation) {
      return flatFacilities;
    }

    const sortedFacilities = [];
    flatFacilities.forEach(facility =>
      sortedFacilities.push({
        ...facility,
        distance: facility.coordinates
          ? BookMdUtil.calcGeoDistance(
              [clientLocation.latitude, clientLocation.longitude],
              [facility.coordinates.latitude, facility.coordinates.longitude],
            )
          : NaN,
      }),
    );
    sortedFacilities.sort((x, y) => x.distance - y.distance);
    return sortedFacilities;
  },
);
