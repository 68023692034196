import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';

import { withHandlers, withPropsOnChange, compose, defaultProps } from 'recompose';
import CleanSelect from '../../../../../components/form/cleanSelect';
import partialMatch from '../../../../../utils/partialMatch';
import { loadingUntil } from '../../../../../components/recompose/loadingUntil';
import './SelectInsurer.less';

const MIN_FILTER_LENGTH = 2;

const Option = topOptions => option => {
  const top = _.includes(topOptions, option.id) ? 'top-option' : undefined;
  return (
    <div className={top} id={option.id}>
      {option.label}
      <img
        src={require('../../../../../assets/images/logos/vimChoiceLogo.png')}
        className="top-option-icon"
        alt=""
      />
    </div>
  );
};

const SelectInsurer = ({ options, onInsurerSelect, value, topOptions, ...rest }) => (
  <CleanSelect
    {...rest}
    options={options}
    onChange={onInsurerSelect}
    value={value}
    filterOptions={partialMatch(MIN_FILTER_LENGTH)}
    optionRenderer={Option(topOptions)}
  />
);

SelectInsurer.propTypes = {
  options: PropTypes.array.isRequired,
  onInsurerSelect: PropTypes.func,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  topOptions: PropTypes.array,
};

SelectInsurer.defaultProps = {
  value: null,
  onChange: _.noop,
  onInsurerSelect: _.noop,
  topOptions: undefined,
};

const sortOptions = (options, topOptions) => {
  if (_.isEmpty(topOptions)) return options;
  const sortedOptions = _.partition(options, ({ id }) => _.includes(topOptions, id));
  // The old logic was
  //  return _.concat(sortedOptions[0], sortedOptions[1]);
  return sortedOptions[0];
};

export default compose(
  defaultProps({
    onChange: _.noop,
    onInsurerSelect: _.noop,
    isLoading: false,
  }),
  loadingUntil(({ isLoading }) => !isLoading),
  withPropsOnChange(['insurers', 'topOptions'], ({ insurers, topOptions }) => {
    const options = _.map(insurers, insurer => ({
      value: insurer.pokitdokTradingPartnerId,
      id: insurer.pokitdokTradingPartnerId,
      vericredId: insurer.vericredId,
      label: insurer.displayName,
      domain: insurer.domain,
    }));
    return {
      options: sortOptions(options, topOptions),
    };
  }),
  withHandlers({
    onInsurerSelect: props => insurer => {
      props.onChange(insurer);
      props.onInsurerSelect(_.get(insurer, 'value'), _.get(insurer, 'domain'));
    },
  }),
)(SelectInsurer);
