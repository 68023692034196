import _ from 'lodash';

export const getProvidersCount = state => _.get(state, 'backoffice.providers.list.count', 0);
export const getProvidersList = state => _.get(state, 'backoffice.providers.list.data', []);
export const getProvider = state => state.backoffice.providers.details;
export const getProvidersSearch = state => state.backoffice.providers.search;
export const getProviderLocations = state =>
  _.get(state, 'backoffice.providers.providerLocations.data', []);
export const getProviderLocationsCount = state =>
  _.get(state, 'backoffice.providers.providerLocations.count', 0);
export const getLocationsByNpi = (state, npi) => {
  const providers = getProvidersList(state);
  const selectedProvider = _.find(providers, { npi });
  return _.get(selectedProvider, 'locations', []);
};
export const getTaxonomies = state => state.backoffice.providers.taxonomies;
