/* eslint-disable react/no-unused-prop-types */
/**
 * Created by guyavraham on 08/06/2017.
 */
import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose } from 'redux';

import { hasInsuranceIdSelector } from '../../../../store/member/selectors';
import { routeToWithNext } from '../../../../utils/routeToWithNext';
import { paths } from './routes';
import ThreeBounceSpinner from '../../../../components/ui/spinner/threeBounceSpinner';

/**
 * Wraps a component and make sure it accessible only if there's an authenticated user
 * @param Component
 * @returns {*}
 */
export default function requireInsuranceId(Component) {
  class RequiredInsuranceIdComponent extends React.Component {
    static propTypes = {
      hasInsuranceId: PropTypes.bool,
      location: PropTypes.object.isRequired,
      router: PropTypes.object.isRequired,
    };

    static defaultProps = {
      hasInsuranceId: null,
    };

    componentDidMount() {
      this.checkInsuranceId();
    }

    checkInsuranceId() {
      if (this.props.hasInsuranceId !== true) {
        const { router, location } = this.props;
        routeToWithNext(router, location, paths.enterInsuranceID());
      }
    }

    render() {
      if (_.isNil(this.props.hasInsuranceId)) {
        return <ThreeBounceSpinner />;
      }

      return <div>{this.props.hasInsuranceId ? <Component {...this.props} /> : <div />}</div>;
    }
  }

  return compose(
    withRouter,
    connect(state => ({
      hasInsuranceId: hasInsuranceIdSelector(state),
    })),
  )(RequiredInsuranceIdComponent);
}
/* eslint-disable react/no-unused-prop-types */
