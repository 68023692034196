import React from 'react';
import { autobind } from 'core-decorators';
import _ from 'lodash';
import { compose, withProps, withHandlers } from 'recompose';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { defineMessages, FormattedMessage } from 'react-intl';

import { getShareSelector } from '../../../../../store/share/selectors';
import { getVimGuideSelector } from '../../../../../store/vimGuide/selectors';
import ProviderList from '../../../../../components/providerList/providerList';
import LoadingPage from '../loadingPage';
import { loadingUntil } from '../../../../../components/recompose/loadingUntil';
import fetchNotEmpty from '../../../../../store/tools/fetchNotEmpty';
import { getVimGuideByUserId } from '../../../../../store/vimGuide/actions';
import queryConnect from '../../../../../store/tools/queryString/queryConnect';
import Layout from '../../layout/layout';
import { chooseClientLocation } from '../../directory/directorySelectors';
import withBookingModal from '../../../../../components/bookingModal/withBookingModal';
import ShareBookButton from './shareButtonWrapper';
import NetworkStatusWithFetch from '../../../../../components/doctorCard/sections/network/networkStatusWithFetch';

const messages = defineMessages({
  title: {
    defaultMessage: '{name} found these doctors just for you.',
    id: 'members.shareDoctors.list.title',
  },
});

@autobind
class DoctorsListDetails extends React.PureComponent {
  static propTypes = {
    /* eslint-disable react/no-unused-prop-types */
    params: PropTypes.object.isRequired,
    /* eslint-enable react/no-unused-prop-types */
    share: PropTypes.object.isRequired,
    vimGuide: PropTypes.object.isRequired,
    currentBookingProvider: PropTypes.object,
    isBookingOpen: PropTypes.bool.isRequired,
    onBookingClose: PropTypes.func.isRequired,
    clientLocation: PropTypes.object.isRequired,
    onBookingLocationChanged: PropTypes.func.isRequired,
    isInNetworkCustomComponent: PropTypes.func.isRequired,
    onBookClick: PropTypes.func.isRequired,
  };

  static defaultProps = {
    currentBookingProvider: null,
  };

  renderTitle() {
    const { vimGuide } = this.props;
    return (
      <FormattedMessage
        {...messages.title}
        values={{
          name: vimGuide.firstName,
        }}
      />
    );
  }

  renderDoctorsList() {
    const {
      share: { member, providers },
      onBookClick,
      isInNetworkCustomComponent,
    } = this.props;
    const { closestLocations, distances, plan, memberContext } = member;

    // This will sort the providers by vim choice and distance from patient
    const providerWithDistance = _.map(providers, provider => {
      const closestLocationId = closestLocations[provider.npi];
      const distance = distances[provider.npi][closestLocationId];
      const vimChoice = provider.vimChoice || false;
      return _.assign({}, provider, { distance, vimChoice });
    });
    const sortedProviders = _.orderBy(
      providerWithDistance,
      ['vimChoice', 'distance'],
      ['desc', 'asc'],
    );

    return (
      <div className="has-footer">
        <ProviderList
          title={this.renderTitle()}
          plan={plan}
          memberContext={memberContext}
          member={member}
          providers={sortedProviders}
          distances={distances}
          closestLocations={closestLocations}
          onBookClick={onBookClick}
          showBook
          bookButtonCustomComponent={ShareBookButton}
          isInNetworkCustomComponent={isInNetworkCustomComponent}
        />
      </div>
    );
  }

  render() {
    return (
      <Layout isLogoClickable={false} externalPageLogo footerClassName="white">
        <div className="container container-1040">
          <div className="row no-margin">{this.renderDoctorsList()}</div>
        </div>
      </Layout>
    );
  }
}

export default compose(
  queryConnect(query => ({
    vimGuideUserId: query.vimGuideUserId,
  })),
  fetchNotEmpty(
    ({ props: { vimGuideUserId } }) => getVimGuideByUserId(vimGuideUserId),
    getVimGuideSelector,
    { renderAlways: false, loading: <LoadingPage /> },
  ),
  connect(state => ({
    share: getShareSelector(state),
    vimGuide: getVimGuideSelector(state),
    clientLocation: chooseClientLocation(state),
  })),
  loadingUntil(({ share }) => !_.isEmpty(share), LoadingPage),
  withProps(({ share: { member }, params: { shareToken } }) => ({
    member,
    token: shareToken,
  })),
  withBookingModal(),
  withHandlers({
    isInNetworkCustomComponent: ({ member }) => outerProps => {
      const { domainMemberId, domain } = member;
      const { currentLocation } = outerProps;
      return (
        <NetworkStatusWithFetch
          shouldFetch
          externalMemberId={domainMemberId}
          externalMemberDomain={domain}
          location={currentLocation}
          npi={_.get(currentLocation, 'npi')}
        />
      );
    },
  }),
)(DoctorsListDetails);
