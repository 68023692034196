import { withHandlers } from 'recompose';
import _ from 'lodash';
import React from 'react';

/**
 * HOC which add handler who format locations for dropdown input
 */
const withLocationOptions = withHandlers({
  getLocationOptions: () => locations => _.isEmpty(locations) ? [] :
    _.map(locations, ({ addressLine1, city, id, officeName, timezone, isVerified }) => {
      const iconClass = isVerified ? 'font-color-brand-success icon-check' : 'text-danger icon-x';
      return {
        label: [`ID ${id} - ${officeName} - ${addressLine1} ${city} `, <i className={iconClass} />],
        value: id,
        timezone,
      };
    })
});

export default withLocationOptions;
