import Joi from 'joi-browser';

const FULL_NAME_ERROR = '!!Full name is required, letters only';

export const fullName = Joi.string()
// first word: [\s]*[^\s\\]+[\s]*
// last word: [\s]+[^\s\\]+[\s]*
// any number of words in between: [^\\]
  .regex(/^[\s]*[^\s\\]+[\s]*[^\\]*[\s]+[^\s\\]+[\s]*$/)
  .options({
    language: {
      any: { empty: FULL_NAME_ERROR, required: FULL_NAME_ERROR, },
      string: { regex: { base: FULL_NAME_ERROR } },
    },
  })
  .required();
