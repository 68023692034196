import PropTypes from 'prop-types';
import _ from 'lodash';
import { defineMessages, injectIntl } from 'react-intl';
import {
  compose,
  withPropsOnChange,
  setPropTypes,
  withProps,
  withHandlers,
  getContext,
  withStateHandlers,
} from 'recompose';
import momentTz from 'moment-timezone';

import { FlowShape } from '../../components/wizard/flow/shapes';
import SelectTimeSlot from '../../screenTemplates/SCR002/SelectTimeslot';
import { withFetchers } from '../../api/injectApi/withFetchers';
import Api from '../../api';
import injectNotification from '../../store/notification/injectNotification';

const messages = defineMessages({
  nextAvailable: {
    defaultMessage: 'Next available:',
    id: 'members.directory.booking.nextAvailable',
  },
  bookAnAppointment: {
    defaultMessage: 'Book an appointment',
    id: 'members.directory.booking.bookAnAppointment',
  },
  chooseATime: {
    defaultMessage: 'Choose a time',
    id: 'members.directory.booking.chooseATime',
  },
  bookIt: {
    defaultMessage: ' - Book it!',
    id: 'members.directory.booking.bookIt',
  },
  notAvailable: {
    defaultMessage:
      'Online booking for this type of appointment is not available at ' +
      'the moment. Please check back soon or call the office for more information.',
    id: 'members.directory.booking.notAvailable',
  },
  fetchMoreTimeslots: {
    defaultMessage: 'See more',
    id: 'members.directory.booking.fetchMoreTimeslots',
  },
  noMoreTimeslots: {
    defaultMessage: 'No more available appointments at the moment',
    id: 'members.directory.booking.noMoreTimeslots',
  },
  fetchMoreTimeslotsError: {
    id: 'members.directory.booking.seeMoreError',
    defaultMessage: 'An error has occurred and we could not complete your action, please try again',
  },
  fetchMoreTimeslotsErrorTitle: {
    id: 'members.directory.booking.seeMoreErrorTitle',
    defaultMessage: 'Oopsy',
  },
});

const getAppointmentTypeTimeSlots = ({ allAppointments, appointmentType }) => {
  if (!appointmentType || !allAppointments) return [];
  const appointmentTypeId = appointmentType.id;
  return _.filter(
    allAppointments,
    slot => !!_.find(slot.appointmentTypes, { id: appointmentTypeId }),
  );
};

export default compose(
  setPropTypes({
    control: FlowShape.isRequired,
    appointments: PropTypes.array,
    appointmentType: PropTypes.string,
    bookingStrategy: PropTypes.string.isRequired,
    onClose: PropTypes.func,
  }),
  injectIntl,
  injectNotification,
  getContext({
    analytics: PropTypes.object,
  }),
  withProps(({ intl }) => ({
    notAvailableText: intl.formatMessage(messages.notAvailable),
    nextAvailableTitle: intl.formatMessage(messages.chooseATime),
    nextAvailableText: intl.formatMessage(messages.nextAvailable),
    nextAvailableButtonText: intl.formatMessage(messages.bookIt),
    fetchMoreTimeslotsText: intl.formatMessage(messages.fetchMoreTimeslots),
    noMoreTimeslotsText: intl.formatMessage(messages.noMoreTimeslots),
  })),
  withStateHandlers(
    ({ appointments }) => ({
      allAppointments: appointments,
      noMoreTimeslots: false,
    }),
    {
      addAppointments: ({ allAppointments }) => ({ appointments }) => ({
        allAppointments: [...allAppointments, ...appointments],
        noMoreTimeslots: _.isEmpty(appointments),
      }),
    },
  ),
  withFetchers({
    fetchMoreTimeslots: {
      handler: ({ allAppointments, locationId, npi, timezone }) => () => {
        // We take the latest appointment time and add 1 day -
        // we start the calculation from the local time to make sure that we are not missing days
        const localLatestAppointmentTime = _.last(allAppointments).startTime;
        const startTime = momentTz
          .tz(localLatestAppointmentTime, timezone)
          .add('1', 'd')
          .set({ hour: 0, minute: 0 })
          .utc()
          .toISOString();
        return Api.schedulingApi.getBookingData(npi, locationId, startTime);
      },
      onSuccess: ({ addAppointments }, bookingData) => addAppointments(bookingData),
      onError: ({ notification }) =>
        notification.error(messages.fetchMoreTimeslotsErrorTitle, messages.fetchMoreTimeslotsError),
    },
  }),
  withHandlers({
    onTimeslotClick: ({ control }) => payload => {
      control.next(payload);
    },
  }),
  withPropsOnChange(['appointmentType', 'allAppointments'], ownerProps => ({
    timeslots: getAppointmentTypeTimeSlots(ownerProps),
  })),
)(SelectTimeSlot);
