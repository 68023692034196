import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { compose, pure, withHandlers } from 'recompose';
import * as FlowDefs from './definitions/colorectalFlowDef';
import Flow from '../components/wizard/flow/Flow';
import Api from '../api';

function ColorectalFlow({ token, persist, onFlowDataUpdate }) {
  return (
    <Flow
      name={FlowDefs.name}
      definitions={FlowDefs.flow}
      initialStepName={FlowDefs.initialStepName}
      experimentalQSSupport
      persist={persist}
      initialValues={{
        token,
      }}
      onFlowDataUpdate={onFlowDataUpdate}
    />
  );
}

ColorectalFlow.propTypes = {
  persist: PropTypes.bool,
  token: PropTypes.string,
  onFlowDataUpdate: PropTypes.func.isRequired,
};

ColorectalFlow.defaultProps = {
  persist: true,
  token: undefined,
};

export default compose(
  pure,
  withHandlers({
    onFlowDataUpdate: () => ({ name, flowId, flowState }) =>
      Api.interventionFlowDataApi.insertInterventionFlowData({
        type: name,
        flowId,
        data: _.get(flowState, 'data'),
      }),
  }),
)(ColorectalFlow);
