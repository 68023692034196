/**
 * Created by asafdavid on 13/01/2016.
 */
import React from 'react';

import cleanInputWrapper from './cleanInputWrapper';

/* eslint-disable react/prop-types */
const inputWithPictureComponent = ({ value, width, height, ...other }) => (
  <div>
    <input {...other} value={value} />
    <img src={value} alt="invalid link" width={width} height={height} />
  </div>);
/* eslint-enable react/prop-types */


export default cleanInputWrapper(inputWithPictureComponent);
