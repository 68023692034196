import React from 'react';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';

import * as FlowDefs from './definitions/pcpSelectionFlowDef';
import Flow from '../components/wizard/flow/Flow';

@autobind
class PCPSelectionFlow extends React.Component {
  static propTypes = {
    persist: PropTypes.bool,
    token: PropTypes.string.isRequired,
  };

  static defaultProps = {
    persist: true,
    token: undefined,
  };

  render() {
    const { token, persist } = this.props;
    return (
      <Flow
        name={FlowDefs.name}
        definitions={FlowDefs.flow}
        initialStepName={FlowDefs.initialStepName}
        persist={persist}
        initialValues={{ token }}
        experimentalQSSupport
      />
    );
  }
}

export default PCPSelectionFlow;
