import { autobind } from 'core-decorators';
import _ from 'lodash';
import ServicesApi from './services';
import BkmdApi from './bkmdApi';

const SERVICE_NAME = 'outreach';

@autobind
export default class OutreachApi extends BkmdApi {
  constructor(fetch) {
    super(fetch, '/outreach/');
    this.servicesApi = new ServicesApi(fetch);
  }

  getMetadata() {
    return this.servicesApi.callSecure(SERVICE_NAME, 'getMetadata').then(payload => payload.data);
  }

  createMlq(name) {
    return this.servicesApi
      .callSecure(SERVICE_NAME, 'createMlq', { name })
      .then(payload => payload.data);
  }

  listMlqs(queryPayload) {
    return this.servicesApi
      .callSecure(SERVICE_NAME, 'listMlqs', queryPayload)
      .then(payload => payload.data);
  }

  getMlq(id) {
    return this.servicesApi
      .callSecure(SERVICE_NAME, 'getMlq', { id })
      .then(payload => payload.data);
  }

  executeMlq(id, startIndex, endIndex) {
    const payload = _.extend(
      { id },
      startIndex ? { startIndex } : {},
      endIndex ? { endIndex } : {},
    );

    return this.servicesApi
      .callSecure(SERVICE_NAME, 'executeMlq', payload)
      .then(payload => payload.data);
  }

  listSteps(queryPayload) {
    return this.servicesApi
      .callSecure(SERVICE_NAME, 'listSteps', queryPayload)
      .then(payload => payload.data);
  }

  getStep(id) {
    return this.servicesApi
      .callSecure(SERVICE_NAME, 'getStep', { id })
      .then(payload => payload.data);
  }

  createStep(payload) {
    return this.servicesApi
      .callSecure(SERVICE_NAME, 'createStep', payload)
      .then(payload => payload.data);
  }

  updateStep(id, data) {
    return this.servicesApi
      .callSecure(SERVICE_NAME, 'updateStep', { id, data })
      .then(payload => payload.data);
  }

  executeStep(id, dependentData) {
    return this.servicesApi
      .callSecure(SERVICE_NAME, 'executeStep', { id, dependentData })
      .then(payload => payload.data);
  }

  switchSteps(mlqId, stepId1, stepId2) {
    return this.servicesApi
      .callSecure(SERVICE_NAME, 'switchSteps', {
        mlqId,
        stepId1,
        stepId2,
      })
      .then(payload => payload.data);
  }

  addStepToMlq(mlqId, stepId, dependencies) {
    return this.servicesApi
      .callSecure(SERVICE_NAME, 'addStepToMlq', { mlqId, stepId, dependencies })
      .then(payload => payload.data);
  }

  removeStepFromMlq(mlqId, stepId, dependencies) {
    return this.servicesApi
      .callSecure(SERVICE_NAME, 'removeStepFromMlq', { mlqId, stepId, dependencies })
      .then(payload => payload.data);
  }

  /* Recipes */

  listRecipes(queryPayload) {
    return this.servicesApi
      .callSecure(SERVICE_NAME, 'listRecipes', queryPayload)
      .then(payload => payload.data);
  }

  getRecipe(id) {
    return this.servicesApi
      .callSecure(SERVICE_NAME, 'getRecipe', { id })
      .then(payload => payload.data);
  }

  deleteRecipe(id) {
    return this.servicesApi
      .callSecure(SERVICE_NAME, 'deleteRecipe', { id })
      .then(payload => payload.data);
  }

  createRecipe(payload) {
    return this.servicesApi
      .callSecure(SERVICE_NAME, 'createRecipe', payload)
      .then(payload => payload.data);
  }

  updateRecipe(id, data) {
    return this.servicesApi
      .callSecure(SERVICE_NAME, 'updateRecipe', { id, data })
      .then(payload => payload.data);
  }

  executeRecipe(id) {
    return this.servicesApi
      .callSecure(SERVICE_NAME, 'executeRecipe', { id })
      .then(payload => payload.data);
  }

  getRecipeHistory(id, queryParams) {
    return this.servicesApi
      .callSecure(SERVICE_NAME, 'getRecipeHistory', { id, queryParams })
      .then(payload => payload.data);
  }

  getRecipeStatus(historyId) {
    return this.servicesApi
      .callSecure(SERVICE_NAME, 'getRecipeStatus', { historyId })
      .then(payload => payload.data);
  }
}
