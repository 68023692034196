import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '../../storybook/src/utils/theme';

function SvgLanguage({ theme }) {
  /* eslint-disable */
  return (
    <svg aria-hidden="true" focusable="false" className="svg-icon svg-language" width="32px" height="21px" viewBox="0 0 32 21" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-70.000000, -431.000000)">
          <g transform="translate(70.000000, 431.000000)">
            <g transform="translate(9.000000, 0.000000)" fill={theme.secondaryColor}>
              <path d="M15.1328,17.6851 C15.2958,17.6851 15.4578,17.7251 15.6048,17.8031 C16.4478,18.2541 17.3208,18.5791 18.2138,18.7751 C17.9168,18.1661 17.7278,17.5811 17.6078,17.0991 C17.5138,16.7191 17.6498,16.3191 17.9548,16.0761 C19.9188,14.5131 20.9998,12.4011 20.9998,10.1291 C20.9998,5.6461 16.7378,2.0001 11.4998,2.0001 C6.2618,2.0001 1.9998,5.6461 1.9998,10.1291 C1.9998,14.6111 6.2618,18.2581 11.4998,18.2581 C12.6238,18.2581 13.7428,18.0811 14.8268,17.7331 C14.9268,17.7011 15.0298,17.6851 15.1328,17.6851 M20.2838,21.0001 C18.4698,21.0001 16.7058,20.5831 15.0358,19.7591 C13.8798,20.0901 12.6928,20.2581 11.4998,20.2581 C5.1588,20.2581 -0.0002,15.7141 -0.0002,10.1291 C-0.0002,4.5441 5.1588,0.0001 11.4998,0.0001 C17.8408,0.0001 22.9998,4.5441 22.9998,10.1291 C22.9998,12.7691 21.8138,15.3111 19.7218,17.1991 C19.9508,17.8641 20.3398,18.6411 20.9908,19.2931 C21.2778,19.5791 21.3628,20.0091 21.2078,20.3831 C21.0528,20.7561 20.6888,21.0001 20.2838,21.0001" ></path>
            </g>
            <path d="M11.5,2 C6.262,2 2,5.646 2,10.129 C2,12.401 3.081,14.513 5.045,16.076 C5.351,16.319 5.486,16.719 5.393,17.099 C5.272,17.581 5.083,18.166 4.786,18.775 C5.679,18.579 6.552,18.254 7.396,17.803 C7.635,17.675 7.915,17.65 8.173,17.733 C9.257,18.081 10.376,18.258 11.5,18.258 C16.738,18.258 21,14.611 21,10.129 C21,5.646 16.738,2 11.5,2 M2.716,21 C2.312,21 1.947,20.756 1.792,20.383 C1.637,20.009 1.723,19.579 2.009,19.293 C2.66,18.641 3.049,17.864 3.278,17.199 C1.187,15.312 0,12.77 0,10.129 C0,4.544 5.159,0 11.5,0 C17.841,0 23,4.544 23,10.129 C23,15.714 17.841,20.258 11.5,20.258 C10.308,20.258 9.12,20.09 7.964,19.759 C6.294,20.583 4.53,21 2.716,21" fill={theme.mainColor}></path>
          </g>
        </g>
      </g>
    </svg>
  );
  /* eslint-enable */
}

SvgLanguage.propTypes = {
  theme: PropTypes.object.isRequired,
};

export default withTheme(SvgLanguage);
