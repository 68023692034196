/**
 * Created by meirshalev 13/02/2018.
 */
import _ from 'lodash';
import PromiseActionType from '../promiseActionType';

export const GET_INSURERS = new PromiseActionType('GET_INSURERS');
export const GET_INSURER_BY_PREFIX = new PromiseActionType('GET_INSURER_BY_PREFIX');

export function getInsurers() {
  return ({ bkmdApi: { eligibilityApi } }) => ({
    type: GET_INSURERS.SOURCE,
    payload: {
      promise: eligibilityApi.getInsurers()
        .then(res => res.data),
    },
    meta: {
      tracker: GET_INSURERS.SOURCE,
    }
  });
}

export function getInsurerByPrefix(prefix) {
  return ({ bkmdApi: { eligibilityApi } }) => ({
    type: GET_INSURER_BY_PREFIX.SOURCE,
    payload: {
      promise: eligibilityApi.getInsurerByPrefix(_.toUpper(prefix))
        .then(res => res.data),
    },
    meta: {
      tracker: GET_INSURER_BY_PREFIX.SOURCE,
    }
  });
}
