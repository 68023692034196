/**
 * Created by chenrozenes on 04/07/2017.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, defineMessages } from 'react-intl';
import { autobind } from 'core-decorators';
import { compose } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import Joi from 'joi-browser';
import CleanInput from '../../../../../../components/form/cleanInput';
import ShareDoctorHeader from './shareDoctorHeader';
import ModalHeader from '../../../../../../components/bkmdModal/modalHeader';
import shareMessages from './messages';
import { shareViaEmail, SHARE_VIA_EMAIL } from '../../../../../../store/directory/provider/actions';
import * as Errors from '../../../../../../api/errors';
import actionTracker from '../../../../../../store/tools/actionTracker/actionTrackerComponent';
import injectNotification from '../../../../../../store/notification/injectNotification';
import SpinnerButton from '../../../../../../components/ui/spinner/spinnerButton';
import { domain } from '../../../../../../store/config/selectors';

const messages = defineMessages({
  yourFriendEmail: {
    defaultMessage: "Your friend's email address",
    id: 'members.shareModal.email.yourFriendEmail',
  },
  emailSuccessText: {
    defaultMessage: 'Email sent successfully',
    id: 'members.shareModal.email.emailSuccessText',
  },
  invalidEmail: {
    defaultMessage: 'Please provide a valid email address',
    id: 'members.shareModal.email.invalidEmail',
  },
});

/**
 * Share via email step in the share provider modal
 */
@autobind
class ShareDoctorByEmail extends React.Component {
  static propTypes = {
    shareAction: PropTypes.object.isRequired,
    shareViaEmail: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired,
    notification: PropTypes.object.isRequired,
    providerLocationId: PropTypes.number,
    wizard: PropTypes.object.isRequired,
    provider: PropTypes.object.isRequired,
    domain: PropTypes.string.isRequired,
  };

  static defaultProps = {
    providerLocationId: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      email: '',
      error: null,
      blurred: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.shareAction.finished) this.handleActionResult(nextProps.shareAction);
  }

  onShareClick() {
    const { provider, providerLocationId } = this.props;
    const location = _.find(provider.locations, { id: providerLocationId });
    this.props.shareViaEmail(this.state.email, provider.npi, providerLocationId, location.geo);
  }

  onBlur() {
    this.setState({ blurred: true });
  }

  onInputChange(e) {
    const val = e.target.value;
    const hasError = Joi.validate(val, Joi.string().email()).error;
    this.setState({ email: val, error: hasError ? messages.invalidEmail : null });
  }

  handleActionResult(tracking) {
    const { notification, handleClose, wizard } = this.props;
    if (tracking.hasError) {
      switch (tracking.error.responseMessage) {
        case Errors.USER_SHARE_BLOCKED: {
          notification.error(shareMessages.shareFailedTitle, shareMessages.shareFailedUserBlocked);
          break;
        }
        case Errors.ADDRESS_SHARE_DENIED: {
          notification.error(
            shareMessages.shareFailedTitle,
            shareMessages.shareFailedAddressBlocked,
          );
          break;
        }
        default: {
          notification.error(
            shareMessages.shareFailedTitle,
            shareMessages.shareFailedGeneralMessage,
          );
          break;
        }
      }
    } else {
      notification.success(shareMessages.success, messages.emailSuccessText);
      wizard.advance(3);
      handleClose();
    }
  }

  render() {
    const {
      wizard: { back },
      handleClose,
      shareAction,
      provider,
    } = this.props;

    return (
      <div>
        <ModalHeader onClose={handleClose} title={shareMessages.shareThisDoctor} onBack={back} />
        <div className="dialog-body">
          <ShareDoctorHeader provider={provider} />
          <div>
            <div className="row margin-bottom-clean-form">
              <div className="col-xs-12 col-sm-10 col-sm-offset-1">
                <CleanInput
                  label={messages.yourFriendEmail}
                  className="no-margin"
                  inputClassName="no-margin"
                  value={this.state.email}
                  onChange={this.onInputChange}
                  onBlur={this.onBlur}
                  errorText={this.state.blurred ? this.state.error : null}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12 col-sm-10 col-sm-offset-1">
                <SpinnerButton
                  className="btn btn-big bg-color-brand-button"
                  onClick={this.onShareClick}
                  isLoading={shareAction.inProgress}
                  disabled={!!this.state.error || !this.state.email}
                >
                  <FormattedMessage {...shareMessages.send} />
                </SpinnerButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default compose(
  injectNotification,
  actionTracker({
    shareAction: SHARE_VIA_EMAIL.SOURCE,
  }),
  connect(
    state => ({ domain: domain(state) }),
    { shareViaEmail },
  ),
)(ShareDoctorByEmail);
