import React from 'react';

export default function SvgUsersCheck() {
  /* eslint-disable */
  return (
    <svg aria-hidden="true" focusable="false" className="svg-icon svg-users-check" width="71px" height="70px" viewBox="0 0 71 70" version="1.1">
      <defs>
        <polygon id="path-1" points="41 33.9996 41 0.542720769 0.4503 0.542720769 0.4503 33.9996 41 33.9996"></polygon>
      </defs>
      <g id="Initiating-Referral-flow---w/-a-vim-doctor" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Referral-submitted-" transform="translate(-807.000000, -80.000000)">
          <g id="Page-1" transform="translate(807.000000, 80.000000)">
            <path d="M7.6143,43.6269 L1.5003,43.6269 C0.6723,43.6269 0.0003,42.9549 0.0003,42.1269 L0.0003,38.7409 C0.0003,30.1289 5.0333,22.4299 12.7403,18.9139 C11.0793,16.8609 10.1573,14.3149 10.1573,11.6569 C10.1573,5.2289 15.3853,-0.0001 21.8133,-0.0001 C25.9213,-0.0001 29.6473,2.0979 31.7823,5.6109 C32.2123,6.3189 31.9863,7.2419 31.2783,7.6719 C30.5723,8.1019 29.6483,7.8779 29.2183,7.1689 C27.6323,4.5589 24.8643,2.9999 21.8133,2.9999 C17.0403,2.9999 13.1573,6.8829 13.1573,11.6569 C13.1573,14.2209 14.3233,16.6479 16.3563,18.3159 C16.7783,18.6629 16.9783,19.2119 16.8793,19.7489 C16.7793,20.2859 16.3963,20.7269 15.8783,20.8989 C8.1753,23.4609 3.0003,30.6309 3.0003,38.7409 L3.0003,40.6269 L7.6143,40.6269 C8.4423,40.6269 9.1143,41.2989 9.1143,42.1269 C9.1143,42.9549 8.4423,43.6269 7.6143,43.6269" id="Fill-1" fill="#083A6B"></path>
            <path d="M32.0146,67.6269 L8.4996,67.6269 C7.6716,67.6269 6.9996,66.9549 6.9996,66.1269 L6.9996,61.6059 C6.9996,50.0749 13.8726,39.7939 24.3316,35.3119 C21.9146,32.5759 20.5656,29.0859 20.5656,25.4299 C20.5656,17.1229 27.3246,10.3639 35.6316,10.3639 C43.9386,10.3639 50.6976,17.1229 50.6976,25.4299 C50.6976,26.3939 50.6036,27.3569 50.4186,28.2939 C50.2586,29.1069 49.4606,29.6359 48.6576,29.4759 C47.8446,29.3149 47.3156,28.5259 47.4756,27.7139 C47.6226,26.9679 47.6976,26.1999 47.6976,25.4299 C47.6976,18.7769 42.2856,13.3639 35.6316,13.3639 C28.9786,13.3639 23.5656,18.7769 23.5656,25.4299 C23.5656,29.0049 25.1906,32.3899 28.0226,34.7139 C28.4456,35.0609 28.6456,35.6099 28.5456,36.1479 C28.4466,36.6849 28.0636,37.1249 27.5446,37.2979 C17.0506,40.7869 9.9996,50.5559 9.9996,61.6059 L9.9996,64.6269 L32.0146,64.6269 C32.8426,64.6269 33.5146,65.2989 33.5146,66.1269 C33.5146,66.9549 32.8426,67.6269 32.0146,67.6269" id="Fill-3" fill="#083A6B"></path>
            <g id="Group-7" transform="translate(30.000000, 35.924800)">
              <mask id="mask-2" fill="white">
                <use xlinkHref="#path-1"></use>
              </mask>
              <g id="Clip-6"></g>
              <path d="M7.8623,13.0166 C6.7323,13.0166 5.6023,13.4466 4.7423,14.3056 C3.0223,16.0266 3.0223,18.8246 4.7423,20.5446 L13.9043,29.7076 C14.7383,30.5406 15.8463,30.9996 17.0243,30.9996 L17.1483,30.9976 C18.3633,30.9616 19.5173,30.4226 20.3213,29.5196 L36.8853,10.8856 C37.6693,10.0046 38.0613,8.8706 37.9923,7.6956 C37.9233,6.5186 37.4003,5.4406 36.5193,4.6576 C35.6373,3.8736 34.5083,3.4866 33.3283,3.5496 C32.1523,3.6196 31.0733,4.1426 30.2913,5.0236 L16.8363,20.1606 L10.9813,14.3056 C10.1213,13.4466 8.9913,13.0166 7.8623,13.0166 Z M17.0243,33.9996 C15.0463,33.9996 13.1843,33.2296 11.7833,31.8296 L2.6213,22.6656 C1.2203,21.2666 0.4503,19.4056 0.4503,17.4256 C0.4503,15.4456 1.2203,13.5836 2.6213,12.1846 C5.5103,9.2956 10.2113,9.2956 13.1023,12.1846 L16.7073,15.7886 L28.0493,3.0306 C29.3643,1.5506 31.1753,0.6716 33.1523,0.5556 C35.1173,0.4406 37.0323,1.0986 38.5123,2.4146 C39.9923,3.7306 40.8713,5.5426 40.9873,7.5196 C41.1033,9.4956 40.4433,11.3986 39.1283,12.8776 L22.5633,31.5126 C21.2143,33.0316 19.2743,33.9366 17.2423,33.9956 L17.0243,33.9996 Z" id="Fill-5" fill="#48C85A" mask="url(#mask-2)"></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
  /* eslint-enable */
}
