/**
 * Created by chenrozenes on 22/01/2017.
 *
 * Sandbox implementation build on Podrick infrastructure
 */

import _ from 'lodash';

export const ENTER_SANDBOX = 'podrick/ENTER_SANDBOX';
export const EXIT_SANDBOX = 'podrick/EXIT_SANDBOX';

export default function buildSandbox(podrickFactory) {
  return function createSandbox(config) {
    const key = config.key;
    if (!key) throw new Error('unique key must be provided for sandbox creation');

    config = _.extend({}, {
      debounceTime: false,
      alwaysFlush: false,
      updateMethod: () => Promise.resolve()
    }, config);

    const sandbox = podrickFactory.create(config);

    function enterSandbox(param) {
      return ({ dispatch }) => {
        dispatch(sandbox.actions.pull(param));
        return {
          type: ENTER_SANDBOX,
          payload: { key }
        };
      };
    }

    function exitSandbox() {
      return ({ dispatch }) => {
        dispatch(sandbox.actions.remove(key));
        return {
          type: EXIT_SANDBOX,
          payload: { key }
        };
      };
    }

    _.extend(sandbox.actions, {
      enterSandbox,
      exitSandbox
    });

    return sandbox;
  };
}
