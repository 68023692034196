import _ from 'lodash';

// This script add a class to the body when clicking the tab key, in order to change the focus style
export const accessibilityClassName = 'accessibility-tab-focus';
const TAB_KEY = 9;
const ESC_KEY = 27;

export const ESCAccessibility = (onClose = () => {}) => {
  document.body.onkeydown = e => {
    if (e.keyCode === ESC_KEY) {
      onClose();
    }
  };
};

document.body.onclick = () => {
  document.body.classList.remove(accessibilityClassName);
};

const selector =
  'button:not([disabled]), input, a, select, textarea, [tabindex]:not([tabindex="-1"])';
const getFocusable = rootElementId =>
  rootElementId && document.getElementById(rootElementId)
    ? document.getElementById(rootElementId).querySelectorAll(selector)
    : document.querySelectorAll(selector);

export const internalTabCycle = (e, rootElementId, getFirstFocusable) => {
  const defaultFocusIndex = getFirstFocusable ? getFirstFocusable() : 0;
  const focusable = getFocusable(rootElementId);
  const firstFocusable = focusable[0];
  const lastFocusable = focusable[focusable.length - 1];
  const isTabPressed = e.keyCode === TAB_KEY || e.key === 'Tab';
  if (!isTabPressed) {
    return;
  }
  if (!_.includes(document.body.classList, accessibilityClassName)) {
    document.body.classList.add(accessibilityClassName);
    if (!Array.from(focusable).find(f => f === document.activeElement)) {
      focusable[defaultFocusIndex] && focusable[defaultFocusIndex].focus();
      e.preventDefault();
    }
  }
  if (e.shiftKey) {
    // if shift key pressed for shift + tab combination
    if (document.activeElement === firstFocusable) {
      lastFocusable.focus();
      e.preventDefault();
    }
  } else if (document.activeElement === lastFocusable) {
    // if tab key is pressed & focused has reached to last focusable element then
    // focus first focusable element after pressing tab
    firstFocusable.focus();
    e.preventDefault();
  }
};

export const addEventListenerTabCycle = ({ rootElementId, getFirstFocusable }) =>
  document.addEventListener('keydown', e => internalTabCycle(e, rootElementId, getFirstFocusable));

export const removeEventListenerTabCycle = () =>
  document.removeEventListener('keydown', internalTabCycle);
