import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _ from 'lodash';

import './passwordFeatureMeter.less';

import { passwordValidator, passwordRules } from './passwordFeaturePolicy';

export default class PasswordFeatureMeter extends React.Component {
  static propTypes = {
    /**
     * The password to evaluate
     */
    password: PropTypes.string,
    /**
     * Show password strengthening.
     * default is false.
     */
    showSuggestions: PropTypes.bool,
    /**
     * Inputs to be considered when evaluating password
     */
    userInputs: PropTypes.array,
    /**
     * Optional hook method.
     * Called with the new password strength when changed.
     */
    onStrengthChanged: PropTypes.func,
  };

  static defaultProps = {
    password: '',
    showSuggestions: false,
    userInputs: [],
    onStrengthChanged: _.noop,
  };

  constructor(props) {
    super(props);
    this.state = { passwordEvaluation: this.evaluatePassword(props.password, props.userInputs) };
  }

  componentWillReceiveProps(nextProps) {
    const { password, userInputs } = nextProps;
    // No need to re-evaluation if the password haven't changed
    if (this.props.password !== password || !_.isEqual(this.props.userInputs, userInputs)) {
      this.setState({ passwordEvaluation: this.evaluatePassword(password) });
    }
  }

  evaluatePassword(password) {
    let { list, score, deprecatedCharacters } = passwordValidator(password);
    let passwordStrength = {
      feedback: {
        suggestions: list,
      },
      score,
    };
    this.props.onStrengthChanged({ score, deprecatedCharacters });

    return passwordStrength;
  }

  renderPasswordSuggestions(suggestions) {
    let result = [];
    _.forEach(suggestions, (rule, index) => {
      result.push(
        <li key={index}>
          <div
            className={`${rule.parentId ? 'child' : 'parent'} ${
              !rule.filled ? 'un-filled' : 'filled'
            }`}
          >
            <i className={`${!rule.filled ? 'far fa-circle' : 'far fa-check-circle'}`} />
            {rule.defaultMessage}
          </div>
        </li>,
      );
    });

    return <ul>{result}</ul>;
  }

  render() {
    const {
      passwordEvaluation: { score, feedback },
    } = this.state;
    const { password } = this.props;
    const hasPassword = !_.isEmpty(password);
    const strengthClass = `strength-${score}`;

    return (
      <div
        className={classNames('new-password-strength-wrap', { [strengthClass]: hasPassword })}
        id="pw-strength"
        aria-live="polite"
        aria-atomic="true"
      >
        <div className="password-strength-text">
          <p>Your password must contain:</p>
          {this.renderPasswordSuggestions(feedback.suggestions)}
        </div>
      </div>
    );
  }
}
