import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { autobind } from 'core-decorators';
import { defineMessages, FormattedMessage } from 'react-intl';
import { Link } from 'react-router';

import { paths } from '../../addInsuarnceInfo/routes';
import {
  hasInsuranceIdSelector,
  member as getMemberSelector,
} from '../../../../../store/member/selectors';

import FeatureFlagged from '../../../../../components/features/featureFlagged';
import LoggedIn from '../../../../../components/loggedIn/loggedIn';

const messages = defineMessages({
  stayInNetwork: {
    defaultMessage: 'Stay in-network,',
    id: 'members.searchDoctors.stayInNetwork',
  },
  enterMemberId: {
    defaultMessage: 'enter member ID',
    id: 'members.searchDoctors.enterMemberId',
  },
});

@autobind
class InsuranceIdBanner extends React.PureComponent {
  static propTypes = {
    hasInsuranceId: PropTypes.bool.isRequired,
    member: PropTypes.object.isRequired,
  };

  render() {
    const { hasInsuranceId, member } = this.props;
    if (_.isEmpty(member) || hasInsuranceId === true) return null;
    return (
      <FeatureFlagged uri="results.insuranceIdBanner">
        <LoggedIn>
          <div className="enter-id-row">
            <i className="icon-shield-check text-dark" />
            <span className="text-dark text-semibold">
              <FormattedMessage {...messages.stayInNetwork} />
            </span>
            &nbsp;
            <Link to={paths.enterInsuranceID()} className="btn btn-inline-link blue-link">
              <FormattedMessage {...messages.enterMemberId} />
            </Link>
          </div>
        </LoggedIn>
      </FeatureFlagged>
    );
  }
}

export default compose(
  connect(state => ({
    hasInsuranceId: hasInsuranceIdSelector(state),
    member: getMemberSelector(state),
  })),
)(InsuranceIdBanner);
