/**
 * Created by chenrozenes on 29/08/2016.
 */

import Immutable from 'seamless-immutable';
import _ from 'lodash';

import {
  ACTION_TRACKER_CLEAR_DATA,
  ACTION_TRACKER_START_MARK,
  ACTION_TRACKER_SUCCESS_MARK,
  ACTION_TRACKER_ERROR_MARK
} from './actions';

const initialState = {};

export default function actionTrackerReducer(state = initialState, action) {
  state = Immutable(state);

  switch (action.type) {
    case ACTION_TRACKER_CLEAR_DATA: {
      if (_.isUndefined(action.payload)) {
        return initialState;
      }
      return state.without(action.payload);
    }
    case ACTION_TRACKER_START_MARK: {
      const { actionKey, meta } = action.payload;
      return state.set(actionKey, {
        key: actionKey,
        inProgress: true,
        executed: false,
        error: null,
        hasError: false,
        meta
      });
    }
    case ACTION_TRACKER_SUCCESS_MARK: {
      const { actionKey, meta } = action.payload;
      if (!state[actionKey]) return state;
      return state.set(actionKey, {
        key: actionKey,
        inProgress: false,
        executed: true,
        error: null,
        hasError: false,
        meta
      });
    }
    case ACTION_TRACKER_ERROR_MARK: {
      const { actionKey, meta, errorPayload } = action.payload;
      if (!state[actionKey]) return state;
      return state.set(actionKey, {
        key: actionKey,
        inProgress: false,
        executed: true,
        error: errorPayload,
        hasError: true,
        meta
      });
    }

    default:
      return state;
  }
}
