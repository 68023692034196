import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { defineMessages, FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import Footer from '../../ui/footer/footer';
import Disclaimer from '../../atoms/Disclaimer/Disclaimer';
import { TosLink, PrivacyPolicyLink, AccessibilityLink } from '../../ui/links/index';
import { getFeature } from '../../../store/feature/selectors';
import FeatureFlagged from '../../features/featureFlagged';

const messages = defineMessages({
  bookMdCaAdministratorsInc: {
    defaultMessage: 'BookMD CA Administrators, Inc.',
    id: 'members.blueFooter.bookMdCaAdministratorsInc',
  },
  vim: {
    defaultMessage: '© Vim',
    id: 'members.blueFooter.vim',
  },
  craftedBy: {
    defaultMessage: 'Crafted by',
    id: 'members.blueFooter.craftedBy',
  },
});

class BlueFooter extends React.PureComponent {
  static propTypes = {
    /**
     * Sets component css class
     */
    footerClassName: PropTypes.string,
    hasDisclaimer: PropTypes.bool,
    forceHideDisclaimer: PropTypes.bool,
    poweredByVim: PropTypes.bool,
  };

  static defaultProps = {
    footerClassName: undefined,
    hasDisclaimer: false,
    poweredByVim: false,
    forceHideDisclaimer: false,
  };

  renderLogo() {
    const { poweredByVim } = this.props;

    if (poweredByVim) {
      return (
        <div>
          <img
            src={require('../../../assets/images/logos/poweredByVimFooter.png')}
            className="powered-by-vim"
            alt="powered by vim"
          />
        </div>
      );
    }

    return (
      <span>
        <FormattedMessage {...messages.vim} />
      </span>
    );
  }

  render() {
    const { footerClassName, hasDisclaimer, forceHideDisclaimer } = this.props;

    return (
      <Footer footerClassName={classNames('blue-footer text-14', footerClassName)}>
        <div className={classNames('footer-menu', hasDisclaimer && !forceHideDisclaimer ? 'left-menu' : '')}>
          {this.renderLogo()}
          <ul className="footer-links">
            <li>
              <TosLink className="underline-link" aria-labelledby="new-window-0" isMember />
              &nbsp;
              <i className="i-va-fix-2 icon-ext-link text-12" id="new-window-0" aria-label="external-link" />
            </li>
            <li>
              <PrivacyPolicyLink className="underline-link" aria-labelledby="new-window-1" isMember />
              &nbsp;
              <i className="i-va-fix-2 icon-ext-link text-12" id="new-window-1" aria-label="external-link" />
            </li>
            <FeatureFlagged uri="layout.accessibilityLink" renderChildren>
              <li>
                <AccessibilityLink className="underline-link" aria-labelledby="new-window-2" />
                &nbsp;
                <i className="i-va-fix-2 icon-ext-link text-12" id="new-window-2" aria-label="external-link" />
              </li>
            </FeatureFlagged>
          </ul>
        </div>
        {!forceHideDisclaimer && <Disclaimer />}
      </Footer>
    );
  }
}

export default connect(state => ({
  hasDisclaimer: getFeature(state, 'layout.disclaimer'),
  poweredByVim: getFeature(state, 'layout.poweredByVim'),
}))(BlueFooter);
