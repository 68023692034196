import React from 'react';
import { defineMessages } from 'react-intl';
import SvgPooStars from '../../assets/svg/poo/pooStars';
import { YesNoStep } from './yesNoStep';

const messages = defineMessages({
  question: {
    defaultMessage: `Do you or your family have a personal 
    history of a cancer syndrome (e.g Lynch Syndrome)?`,
    id: 'colorectalFlow.familyCancerHistory.question',
  },
});

const FamilyCancerHistory = YesNoStep(<SvgPooStars />, messages);

export default FamilyCancerHistory;
