import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import withFeatureFlag from 'Utils/featureFlags/withFeatureFlag';
import { compose } from 'recompose';

import Text from '../../atoms/Text/Text';
import './ResultCard.less';
import { withTheme } from '../../../utils/theme';
import ClickableDiv from '../../../../../components/clickableElement/clickableDiv';

function ResultCard({
  children,
  className,
  resultMainText,
  resultSecondaryText,
  primaryActionBtn,
  secondaryActionBtn,
  onClick,
  resultImg,
  showLocation,
  verticalMode,
  resultLeftDetail,
  secondaryResultLeftDetail,
  addressTitle,
  address,
  addressColor,
  locationDistance,
  showNewBookButton,
  showBottomActionBanner,
  resultBottomActionBanner,
  telehealth,
}) {
  const resultVerticalMode = verticalMode ? 'vertical-mode' : undefined;
  return (
    <div className="result-wrap">
      <ClickableDiv
        className={classNames(
          'card result-card',
          resultVerticalMode,
          { 'bottom-banner-opened': showBottomActionBanner },
          className,
        )}
        clickHandler={onClick || _.noop}
        disabled={!onClick}
      >
        <div className="result-main-content">
          <div className="result-left">
            <div className="result-img">{resultImg}</div>
            {resultLeftDetail && <div className="network-status">{resultLeftDetail}</div>}
            {secondaryResultLeftDetail && <div>{secondaryResultLeftDetail}</div>}
          </div>
          <div className="result-middle">
            <div className="result-main">
              <Text align="left" weight="semibold" color="dark" className="main-text">
                {resultMainText}
                {telehealth ? <i className="icon-video-cam telehealth-icon" /> : undefined}
              </Text>
              <Text align="left">{resultSecondaryText}</Text>
              {showLocation && (
                <div>
                  <div className="result-location">
                    <Text align="left" weight="bold" className="address-title" color={addressColor}>
                      {addressTitle}
                    </Text>
                    {locationDistance && (
                      <div className="location-distance">{locationDistance}</div>
                    )}
                  </div>
                  <Text align="left" color={addressColor}>
                    {address}
                  </Text>
                </div>
              )}
            </div>

            <div className="result-details">{children}</div>
          </div>

          <div className={classNames('result-actions', { 'new-book-button': showNewBookButton })}>
            {primaryActionBtn && <div className="top-item">{primaryActionBtn}</div>}
            {secondaryActionBtn && <div className="bottom-item">{secondaryActionBtn}</div>}
          </div>
        </div>

        <div className="result-bottom-action-banner">
          <div className="banner-content">{resultBottomActionBanner}</div>
        </div>
      </ClickableDiv>
      <div className="card-backdrop" />
    </div>
  );
}

ResultCard.propTypes = {
  className: PropTypes.string,
  verticalMode: PropTypes.bool,
  resultMainText: PropTypes.string,
  resultSecondaryText: PropTypes.string,
  onClick: PropTypes.func,
  resultImg: PropTypes.node,
  primaryActionBtn: PropTypes.object,
  secondaryActionBtn: PropTypes.object,
  showLocation: PropTypes.bool,
  resultLeftDetail: PropTypes.object,
  secondaryResultLeftDetail: PropTypes.object,
  addressTitle: PropTypes.string,
  address: PropTypes.string,
  addressColor: PropTypes.string,
  locationDistance: PropTypes.object,
  showNewBookButton: PropTypes.bool,
  showBottomActionBanner: PropTypes.bool,
  resultBottomActionBanner: PropTypes.object,
  telehealth: PropTypes.bool,
};

ResultCard.defaultProps = {
  className: '',
  verticalMode: true,
  resultMainText: '',
  resultSecondaryText: '',
  onClick: undefined,
  resultImg: '',
  primaryActionBtn: null,
  secondaryActionBtn: null,
  showLocation: false,
  resultLeftDetail: undefined,
  secondaryResultLeftDetail: undefined,
  addressTitle: '',
  address: '',
  locationDistance: '',
  showNewBookButton: false,
  showBottomActionBanner: false,
  addressColor: undefined,
  resultBottomActionBanner: null,
  telehealth: false,
};

export default compose(
  withTheme,
  withFeatureFlag({
    key: 'storybook.newBookButton',
    prop: 'showNewBookButton',
    defaultValue: false,
  }),
)(ResultCard);
