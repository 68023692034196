/**
 * Created by asafdavid on 22/03/2016.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  termsWaiver: {
    defaultMessage: 'By continuing, you accept our',
    id: 'store.termsWaiver'
  },
  and: {
    defaultMessage: 'and',
    id: 'store.and'
  },
  or: {
    defaultMessage: 'or',
    id: 'store.or'
  },
  yes: {
    defaultMessage: 'Yes',
    id: 'store.yes'
  },
  signIn: {
    defaultMessage: 'Sign in',
    id: 'store.signIn'
  },
  no: {
    defaultMessage: 'No',
    id: 'store.no'
  },
  your: {
    defaultMessage: 'Your',
    id: 'store.your'
  },
  my: {
    defaultMessage: 'my',
    id: 'store.my'
  },
  communications: {
    defaultMessage: 'Communications',
    id: 'store.communications'
  },
  termsAndConditions: {
    defaultMessage: 'Terms of Service',
    id: 'store.termsAndConditions'
  },
  termsOfUse: {
    defaultMessage: 'Terms of Use',
    id: 'store.termsOfUse'
  },
  privacyPolicy: {
    defaultMessage: 'Privacy Policy',
    id: 'store.privacyPolicy'
  },
  accessibilityLink: {
    defaultMessage: 'Accessibility',
    id: 'store.accessibilityLink'
  },
  moreInfo: {
    defaultMessage: `
      <span>For more info, please visit:</span><br/>
      <a href="https://{link}" class="blue-link">{link}</a>
    `,
    id: 'store.moreInfo'
  },
  bookmdPhoneNumber: {
    defaultMessage: '(844) 843-5381',
    id: 'store.bookmdPhoneNumber'
  },
  bookmdProvidersPhoneNumber: {
    defaultMessage: '(310) 421-2277',
    id: 'store.bookmdProvidersPhoneNumber'
  },
  bookmdSupportEmail: {
    defaultMessage: 'support@getvim.com',
    id: 'store.bookmdSupportEmail'
  },
  healthPlanSupportNumber: {
    defaultMessage: '(800) 508-4722',
    id: 'store.healthPlanSupportNumber'
  },
  healthPlanSupportOutsideUSNumber: {
    defaultMessage: '(855) 390-6514',
    id: 'store.healthPlanSupportOutsideUSNumber'
  },
  vimProvidersSupportNumber: {
    defaultMessage: '(907) 290-8399',
    id: 'store.vimProvidersSupportNumber'
  },
  bookmdInfoEmail: {
    defaultMessage: 'info@book-md.com',
    id: 'store.bookmdInfoEmail'
  },
  bookmdFax: {
    defaultMessage: '(844) 204 7873',
    id: 'store.bookmdFax'
  },
  bookmdAddress: {
    defaultMessage: 'PO Box 811335 <br />Los Angeles CA <br/ >90081-0006',
    id: 'store.bookmdAddress'
  },
  help: {
    defaultMessage: 'Help',
    id: 'store.help'
  },
  chat: {
    defaultMessage: 'Chat',
    id: 'store.chat'
  },
  delete: {
    defaultMessage: 'Delete',
    id: 'store.delete'
  },
  name: {
    defaultMessage: 'Name',
    id: 'store.name'
  },
  abort: {
    defaultMessage: 'Abort',
    id: 'store.abort'
  },
  edit: {
    defaultMessage: 'Edit',
    id: 'store.edit'
  },
  save: {
    defaultMessage: 'Save',
    id: 'store.save'
  },
  date: {
    defaultMessage: 'Date',
    id: 'store.date'
  },
  time: {
    defaultMessage: 'Time',
    id: 'store.time'
  },
  his: {
    defaultMessage: 'his',
    id: 'store.his'
  },
  her: {
    defaultMessage: 'her',
    id: 'store.her'
  },
  him: {
    defaultMessage: 'him',
    id: 'store.him'
  },
  doctor: {
    defaultMessage: 'Dr. {name}',
    id: 'store.doctor'
  },
  he: {
    defaultMessage: 'he',
    id: 'store.he'
  },
  she: {
    defaultMessage: 'she',
    id: 'store.she'
  },
  password: {
    defaultMessage: 'Password',
    id: 'store.password'
  },
  success: {
    defaultMessage: 'Success',
    id: 'store.success'
  },
  failure: {
    defaultMessage: 'Failure',
    id: 'store.failure'
  },
  unverified: {
    defaultMessage: 'Unverified',
    id: 'members.settings.unverified'
  },
  verified: {
    defaultMessage: 'Verified',
    id: 'members.settings.verified'
  },
  next: {
    defaultMessage: 'Next',
    id: 'store.next'
  },
  vimFax: {
    defaultMessage: '(855) 862-5438',
    id: 'store.vimFax'
  },
  submit: {
    defaultMessage: 'Submit',
    id: 'store.submit'
  },
  loadMore: {
    defaultMessage: 'Load more',
    id: 'store.loadMore'
  }
});

