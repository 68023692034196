/**
 * Created by moran on 6/20/17.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';

import analytics from '../../store/tools/analytics/analyticsHoc';

@autobind
class Tag extends React.Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    onClick: PropTypes.func,
  };

  static defaultProps = {
    onClick: () => {
    },
  };

  onClick() {
    this.props.onClick(this.props.id);
  }

  render() {
    const { value, id } = this.props;
    return (
      <button className="btn btn-pill" key={id} onClick={this.onClick}>
        {value}
      </button>
    );
  }
}

export default analytics(Tag, false, [{ event: 'onClick' }]);
