import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { defineMessages, FormattedMessage } from 'react-intl';

import ExternalSSOLink from '../../../../../components/externalLinks/externalSSOLink';

const messages = defineMessages({
  resources: {
    defaultMessage: 'Resources',
    id: 'members.premera.resources.resources',
  },
  hereAreSomeHelpfulResources: {
    defaultMessage: 'Here are some helpful resources you can visit for more info on your benefits.',
    id: 'members.premera.resources.hereAreSomeHelpfulResources',
  },
  manageYourHealth: {
    defaultMessage: 'Manage your health',
    id: 'members.premera.resources.manageYourHealth',
  },
  signIn: {
    defaultMessage: 'Sign In',
    id: 'members.premera.resources.signIn',
  },
});

function PremeraResources(props) {
  return (
    <div>
      <div className="resources-page-content">
        <h1 className="text-center margin-10 title-24 title-brand-1">
          <FormattedMessage {...messages.resources} />
        </h1>
        <div className="text-18 text-light text-center">
          <FormattedMessage {...messages.hereAreSomeHelpfulResources} />
        </div>
        <div className="row">
          <div className="col-sm-4">
            <div className="text-center margin-top-30 card">
              <div className="card-body padding-box-30">
                <ExternalSSOLink endpoint="myChart" url={props.url} ariaLabel="my benefits">
                  <img src={require('./mychartlogo.png')} className="resources-main-img" alt="" />
                  <br />
                  <div className="text-18 text-light">
                    <FormattedMessage {...messages.manageYourHealth} />
                  </div>
                  <br />
                  <div className="text-18 font-color-brand-link">
                    <FormattedMessage {...messages.signIn} />
                  </div>
                </ExternalSSOLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

PremeraResources.propTypes = {
  url: PropTypes.string.isRequired,
};

export default connect(state => ({
  url: _.get(state, 'config.myChartUrl'),
}))(PremeraResources);
