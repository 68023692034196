import _ from 'lodash';
import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Highlighter from 'react-highlight-words';
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete';
import { intlShape, injectIntl, FormattedHTMLMessage } from 'react-intl';
import { autobind } from 'core-decorators';
import { connect } from 'react-redux';
import { compose } from 'redux';
import ClickableDiv from '../../../../../../../components/clickableElement/clickableDiv';

import { getClosestZipCode, getState } from '../../../../../../../utils/geocodeByLocation';
import messages from './messages';
import geoPoint from '../../../../../../../utils/commonShape';
import CleanSelect from '../../../../../../../components/form/cleanSelect';
import getBrowserLocation from '../../../../../../../store/location/selectors';
import * as memberSelectors from '../../../../../../../store/member/selectors';
import injectNotification from '../../../../../../../store/notification/injectNotification';
import actionTracker from '../../../../../../../store/tools/actionTracker/actionTrackerComponent';
import { chooseClientLocation, getDefaultLocations } from '../../../directorySelectors';
import { getCurrentLocation, GET_LOCATION } from '../../../../../../../store/location/actions';
import { intlErrorNotification } from '../../../../../../../store/notification/actions';
import { getFeature } from '../../../../../../../store/feature/selectors';

import './locationSelect.less';
import { clientLocationShape } from '../../../../../../../components/searchDoctors/clientLocationShape';

const defaultLocationNames = _.map(getDefaultLocations(), 'name');

const getGeo = function getGeo(addressResults) {
  return getClosestZipCode(addressResults[0]).then(zip => ({
    latitude: addressResults[0].geometry.location.lat(),
    longitude: addressResults[0].geometry.location.lng(),
    zip,
    state: getState(addressResults[0]),
  }));
};

const renderValue = function renderValue(option) {
  return _.isString(option.label) ? (
    <span>option.label</span>
  ) : (
    <span>
      <FormattedHTMLMessage {...option.label} values={option.intlValues} />
    </span>
  );
};
@autobind
class LocationSelect extends React.Component {
  static propTypes = {
    /* eslint-disable react/no-unused-prop-types */
    notification: PropTypes.object.isRequired,
    getLocationAction: PropTypes.object.isRequired,
    /* eslint-enable react/no-unused-prop-types */

    hasMemberHomeSetting: PropTypes.string,
    intl: intlShape.isRequired, // injected i18n
    homeAddress: PropTypes.object,
    workAddress: PropTypes.object,
    homeGeo: PropTypes.shape({ geoPoint }),
    workGeo: PropTypes.shape({ geoPoint }),
    browserLocation: PropTypes.object.isRequired, // from redux
    clientLocation: PropTypes.shape(clientLocationShape).isRequired, // from redux
    onChange: PropTypes.func.isRequired,
    defaultGeo: PropTypes.object.isRequired,
    defaultLocations: PropTypes.object.isRequired,
    defaultAddress: PropTypes.string.isRequired,
    onStartGeocode: PropTypes.func.isRequired,
    onFinishGeocode: PropTypes.func.isRequired,
    colors: PropTypes.object,
  };

  static defaultProps = {
    hasMemberHomeSetting: '',
    homeAddress: undefined,
    workAddress: undefined,
    homeGeo: undefined,
    workGeo: undefined,
    colors: {
      brandColorSecondary: '#09ace4',
    },
  };

  constructor(props) {
    super(props);

    this.state = {
      value: null,
      autoCompleteValue: null,
      raiseEnterEvent: true, // indicate if 'Enter' key event is internal for selecting location
      isSelectingLocation: false, // indicate if the Enter event came from a location select
    };
  }

  componentDidMount() {
    this.initSearchLocation();
  }

  /**
   * For the my-location option: take the default from config, if no browser location
   * @param option
   */
  onSelectDefaultOption(option) {
    this.setState({ raiseEnterEvent: false, isSelectingLocation: true });

    if (!option) {
      this.setState({ value: null });
      this.props.onChange({ address: null, geo: null, locationType: null });
      return;
    }
    const value = _.get(option, 'value', '');
    this.setState({ value });

    const myLocation = this.props.defaultLocations.MY_LOCATION.name;
    if (value === myLocation) {
      const { browserLocation } = this.props;
      // will take the default from config, if no browser location
      if (browserLocation) {
        this.props.onChange({
          address: myLocation,
          geo: browserLocation,
          locationType: value,
          name: value,
        });
      } else {
        this.showBrowserLocationError();
        const { onChange, defaultAddress, defaultGeo } = this.props;
        onChange({ address: defaultAddress, geo: defaultGeo });
      }
    } else {
      this.props.onChange({
        address: option.address,
        geo: option.geo,
        locationType: value,
        name: value,
      });
    }
  }

  onEnterPressed(e) {
    if (
      e.keyCode === 13 &&
      this.state.raiseEnterEvent === false &&
      this.state.isSelectingLocation === true
    ) {
      this.setState({ raiseEnterEvent: true, isSelectingLocation: false });
      e.stopPropagation();
    }
  }

  /**
   * In this component we use 2 different drop downs components:§
   * 1. default options drop down 'defaultLocations'
   * 2. google places auto complete drop down
   * The drop down is replaced according to users input: for no input value, the default drop down
   * menu is opened, and for some input from the user - the google places auto complete drop down
   * is opened.
   * This method gets a value (current input to the component) and if its empty it fires a focus
   * event on the default drop down component (clean select) and if it has some value it fires an
   * input changed event to the places auto complete component.
   * @param value - value from user
   */
  onInputChange(value) {
    this.setState({ raiseEnterEvent: false });

    // if the user deleted all the text
    if (!value) {
      this.focusOnCleanSelect();
      this.setState({ value: null, autoCompleteValue: null });
      return;
    }

    // if there was an empty input string, or there was a default selection
    if (!this.state.value || _.includes(defaultLocationNames, this.state.value)) {
      this.setState({ autoCompleteValue: value, value: '' });
      setTimeout(() => this.setState({ value }));
    } else {
      this.setState({ autoCompleteValue: value, value });
    }
  }

  onSelectCustomAddress(address) {
    const value = address;
    this.setState({ autoCompleteValue: value, value, isSelectingLocation: true });

    const { onStartGeocode, onFinishGeocode, onChange } = this.props;
    onStartGeocode();
    return geocodeByAddress(address)
      .then(results => getGeo(results))
      .then(geo => onChange({ geo, address, locationType: '' }))
      .then(onFinishGeocode)
      .catch(error => {
        throw new Error(`Google service places auto complete failed with status = ${error}`);
      });
  }

  getClassName(value = this.state.value) {
    const { defaultLocations } = this.props;
    switch (value) {
      case defaultLocations.HOME.name: {
        return classNames(defaultLocations.HOME.icon, value);
      }
      case defaultLocations.WORK.name: {
        return classNames(defaultLocations.WORK.icon, value);
      }
      case defaultLocations.MY_LOCATION.name: {
        return classNames(defaultLocations.MY_LOCATION.icon, value);
      }
      default: {
        return classNames(defaultLocations.DEFAULT.icon, defaultLocations.DEFAULT.name);
      }
    }
  }

  getHomeOption() {
    const { homeAddress, homeGeo, defaultLocations } = this.props;
    if (_.isNil(homeAddress)) return null;
    return {
      value: defaultLocations.HOME.name,
      label: messages.nearHome,
      intlValues: { address: homeAddress.addressLine1 },
      address: homeAddress.addressLine1,
      geo: homeGeo,
    };
  }

  getWorkOption() {
    const { workAddress, workGeo, defaultLocations } = this.props;
    if (_.isNil(workAddress)) return null;
    return {
      value: defaultLocations.WORK.name,
      label: messages.nearWork,
      intlValues: { address: workAddress.addressLine1 },
      address: workAddress.addressLine1,
      geo: workGeo,
    };
  }

  getMyLocationOption() {
    const { browserLocation, defaultLocations } = this.props;
    return {
      value: defaultLocations.MY_LOCATION.name,
      label: messages.myLocation,
      address: defaultLocations.MY_LOCATION.name,
      geo: browserLocation,
    };
  }

  getInitialOptions() {
    return _.compact([this.getHomeOption(), this.getWorkOption(), this.getMyLocationOption()]);
  }

  focusOnCleanSelect() {
    setTimeout(() => this._raiseElementEvent('cleanSelect', el => el.focus()));
  }

  showBrowserLocationError() {
    const { notification, onChange } = this.props;
    notification.error(messages.locationErrorTitle, messages.locationErrorMessage);
    onChange({
      geo: this.props.defaultGeo,
      address: this.props.defaultAddress,
    });
  }

  initSearchLocation() {
    const { clientLocation, onChange } = this.props;

    // one of the default locations
    if (!_.isEmpty(_.get(clientLocation, 'name', null))) {
      this.setState({ value: clientLocation.name });
      onChange({
        ...clientLocation,
        locationType: clientLocation.name,
      });
      return;
    }

    // a custom location
    if (clientLocation.address) this.onSelectCustomAddress(clientLocation.address);
  }

  /**
   * Ugly hack to use the CleanSelect and PlacesAutocomplete components simultaneously.
   * @param refName: name of component's ref
   * @param func: the function to execute on the dom element corresponding with the given ref's name
   * @param params: arguments to the given function
   * @private
   */
  _raiseElementEvent(refName, func, rawParams) {
    const params = _.castArray(rawParams);
    const ref = this[refName];
    const refChild = ref ? ref.firstChild : null;
    const element = refChild ? refChild.querySelector('input') : null;
    const elementFunc = func.bind(this, element, ...params);
    elementFunc();
  }

  clearPlaces() {
    this.setState({ autoCompleteValue: null, value: null });
    this.focusOnCleanSelect();
  }

  renderOption(option) {
    const formatted = _.isString(option.label) ? (
      option.label
    ) : (
      <FormattedHTMLMessage {...option.label} values={option.intlValues} />
    );
    return (
      <div>
        <i className={this.getClassName(option.value)} />
        {formatted}
      </div>
    );
  }

  renderAutoCompleteOption(getSuggestionItemProps, suggestion) {
    const {
      formattedSuggestion: { mainText, secondaryText },
    } = suggestion;
    const searchWords = this.state.value.split(/[\s,]/).filter(word => word);

    return (
      <div {...getSuggestionItemProps(suggestion)}>
        <strong>
          <Highlighter
            searchWords={searchWords}
            textToHighlight={mainText}
            highlightClassName="location-highlight"
          />
        </strong>
        <br />
        {secondaryText}
      </div>
    );
  }

  render() {
    const placeholder = this.props.intl.formatMessage(messages.placeholder);
    const { defaultGeo, clientLocation } = this.props;
    const anchor = _.get(clientLocation, 'geo', null) || defaultGeo;
    const searchOptions = {
      // Location for prediction biasing.
      location:
        /* eslint-disable no-undef */
        new google.maps.LatLng(anchor.latitude, anchor.longitude),
      /* eslint-enable no-undef */
      radius: 8000, // Required param when given a location - set to 8000 for covering all the US
      componentRestrictions: { country: 'US' },
    };

    return this.state.autoCompleteValue ? (
      <ClickableDiv
        clickHandler={this.onEnterPressed}
        ref={el => {
          this.inputWrap = el;
        }}
      >
        <PlacesAutocomplete
          onChange={this.onInputChange}
          value={this.state.value}
          onSelect={this.onSelectCustomAddress}
          onEnterKeyDown={this.onSelectCustomAddress}
          googleLogo={false}
          searchOptions={searchOptions}
        >
          {({ getInputProps, getSuggestionItemProps, suggestions }) => (
            <div className="autocomplete-root location-autocomplete-wrap">
              <input
                {...getInputProps()}
                className="clean-input location-autocomplete-select"
                {...{ autoFocus: true }}
              />
              <div className="autocomplete-dropdown-container location-autocomplete-options">
                {suggestions.map(suggestion =>
                  this.renderAutoCompleteOption(getSuggestionItemProps, suggestion),
                )}
              </div>
            </div>
          )}
        </PlacesAutocomplete>
        <div className="clear-input-icon">
          <ClickableDiv clickHandler={this.clearPlaces} className="location-select-icon">
            <i className="icon-chevron-down" />
          </ClickableDiv>
          <ClickableDiv clickHandler={this.clearPlaces} className="location-select-icon">
            <i className="icon-x-2" />
          </ClickableDiv>
        </div>
      </ClickableDiv>
    ) : (
      <span
        ref={el => {
          this.cleanSelect = el;
        }}
      >
        <CleanSelect
          value={this.state.value}
          options={this.getInitialOptions()}
          optionRenderer={this.renderOption}
          className={classNames('location-select', 'left-label-icon')}
          placeholder={placeholder}
          iconClassName={this.getClassName()}
          clearable
          openOnFocus
          onChange={this.onSelectDefaultOption}
          onInputChange={this.onInputChange}
          noResultsText={false}
          valueRenderer={renderValue}
        />
      </span>
    );
  }
}

export default compose(
  injectIntl,
  injectNotification,
  connect(
    state => {
      const homeAddress = memberSelectors.homeSetting(state);
      const workAddress = memberSelectors.workSetting(state);
      const defaultLocation = memberSelectors.getDefaultLocationSelector(state);
      const colors = getFeature(state, 'site.colors');
      return {
        clientLocation: chooseClientLocation(state),
        browserLocation: getBrowserLocation(state),
        defaultGeo: defaultLocation.geo,
        defaultAddress: defaultLocation.address,
        defaultLocations: getDefaultLocations(),
        homeAddress: !_.isNil(homeAddress) ? { addressLine1: homeAddress.address } : undefined,
        homeGeo: !_.isNil(homeAddress) ? homeAddress.geo : undefined,
        workAddress: !_.isNil(workAddress) ? { addressLine1: workAddress.address } : undefined,
        workGeo: !_.isNil(workAddress) ? workAddress.geo : undefined,
        colors,
      };
    },
    (dispatch, props) => ({
      getCurrentLocation,
      showError(title, message) {
        dispatch(intlErrorNotification(props.intl, { title, message }));
      },
    }),
  ),
  actionTracker({
    getLocationAction: GET_LOCATION,
  }),
)(LocationSelect);
