import React from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'formik';
import _ from 'lodash';
import { compose, withProps } from 'recompose';
import { defineMessages } from 'react-intl';
import { object, string } from 'yup';

import { WrappedFormik } from '../../../../../components/form/formik/wrappedFormik';
import { CleanFormikInput } from '../../../../../components/form/formik/cleanFormikInput';
import { CleanFormikDateInput } from '../../../../../components/form/formik/cleanFormikDateInput';
import Button from '../../atoms/Button/Button';
import { withTranslatedMessages } from '../../../../../utils/withTranslatedMessages';
import BrandedH1 from '../../atoms/BrandedHeading/BrandedHeading';
import Container from '../../layout/Container/container';
import SvgCalendarCheck from '../../../../../assets/svg/calendarCheck';
import Footer from '../../../../../components/ui/footer/footer';
import BaseLayout from '../../layout/BaseLayout/baseLayout';
import Recaptcha from '../../../../../components/verification/bkmdRecaptcha';
import Text from '../../atoms/Text/Text';
import './MemberValidation.less';

const messages = defineMessages({
  title: {
    defaultMessage: 'Please enter your info to continue',
    id: 'scheduling.memberValidation.title',
  },
  firstName: {
    defaultMessage: 'First name',
    id: 'scheduling.memberValidation.firstName',
  },
  lastName: {
    defaultMessage: 'Last name',
    id: 'scheduling.memberValidation.lstName',
  },
  insurance: {
    defaultMessage: 'Member id',
    id: 'scheduling.memberValidation.insurance',
  },
  next: {
    defaultMessage: 'Next',
    id: 'scheduling.memberValidation.next',
  },
  dateOfBirth: {
    defaultMessage: 'Date of birth',
    id: 'scheduling.memberValidation.dateOfBirth',
  },
  agreementMessage: {
    defaultMessage:
      'By clicking "Next", you agree to Vim\'s <a href="{termOfUseLink}">Terms of Use</a> and <a href="{privacyPolicyLink}">Privacy Policy</a>',
    id: 'scheduling.memberValidation.agreementMessage',
  },
});

const schema = object().shape({
  firstName: string()
    .required('First name is required')
    .label('First name'),
  lastName: string()
    .required('Last name is required')
    .label('Last name'),
  memberId: string()
    .required('Insurance id is required')
    .label('Insurance id'),
  dateOfBirth: string()
    .required('Date of birth is required')
    .label('Date of birth'),
});

function MemberValidation({ onSubmit, messages, isLoading }) {
  return (
    <BaseLayout>
      <div className="header-placeholder" />
      <Container width="small">
        <WrappedFormik
          onSubmit={onSubmit}
          validationSchema={schema}
          render={data => (
            <Form>
              <div className="svg-72 text-center margin-bottom-30 margin-top-50">
                <SvgCalendarCheck />
              </div>
              <div className="margin-bottom-45">
                <BrandedH1 text={messages.title} margin="none" />
              </div>
              <div className="row">
                <div className="col-xs-12">
                  <Field name="firstName" component={CleanFormikInput} label={messages.firstName} />
                </div>
              </div>
              <div className="row">
                <div className="col-xs-12">
                  <Field name="lastName" component={CleanFormikInput} label={messages.lastName} />
                </div>
              </div>
              <div className="row">
                <div className="col-xs-12">
                  <Field
                    name="dateOfBirth"
                    component={CleanFormikDateInput}
                    label={messages.dateOfBirth}
                    fixedLabel={false}
                    showPlaceholderOnlyOnFocus
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-xs-12">
                  <Field
                    name="memberId"
                    component={CleanFormikInput}
                    label={messages.insurance}
                    inputClassName="margin-45"
                  />
                </div>
              </div>
              <div className="row recaptcha-wrap margin-bottom-45">
                <div className="col-xs-12 no-padding">
                  <Recaptcha />
                </div>
              </div>
              <div className="row">
                <div className="col-xs-12">
                  <Button
                    size="large"
                    width="block"
                    color="theme"
                    isLoading={isLoading}
                    disabled={!data.isValid}
                    text={messages.next}
                  />
                </div>
              </div>
              <div className="row agreement-message">
                <Text text={messages.agreementMessage} html />
              </div>
            </Form>
          )}
        />
      </Container>
      <Footer footerClassName="not-fixed center-content vertical-align-content">
        <img
          src={require('../../../../../assets/images/logos/poweredByVimFooter.png')}
          alt="Powered by vim"
          className="powered-by-vim"
        />
      </Footer>
    </BaseLayout>
  );
}

MemberValidation.propTypes = {
  messages: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default compose(
  withProps({
    termOfUseValues: {
      termOfUseLink: 'https://getvim.com/legal/terms-of-service/',
      privacyPolicyLink: 'https://getvim.com/legal/privacy-policy/',
    },
  }),
  withTranslatedMessages(messages, ({ termOfUseValues }) => termOfUseValues),
  withProps(({ onSubmitTracker }) => ({
    isLoading:
      _.get(onSubmitTracker, 'inProgress') ||
      // loading until onSuccess executed
      (_.get(onSubmitTracker, 'executed') && !_.get(onSubmitTracker, 'hasError')),
  })),
)(MemberValidation);
