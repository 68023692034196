import {
  compose,
  setPropTypes,
  defaultProps,
  withStateHandlers,
  withHandlers,
  withProps,
  mapProps,
} from 'recompose';
import { omit, noop } from 'lodash';
import PropTypes from 'prop-types';

const withFormikWrapper = ({ onChangeProp = 'onChange', valueProp = 'value' } = {}) =>
  compose(
    setPropTypes({
      hideErrorWhileFocused: PropTypes.bool,
      showInitialError: PropTypes.bool,
    }),
    defaultProps({
      onFocus: noop,
      onBlur: noop,
      hideErrorWhileFocused: true,
      showInitialError: false,
    }),
    withStateHandlers(
      {
        focused: false,
        onBlurHandler_: undefined,
      },
      {
        onFocus: (state, { onFocus }) => (...args) => {
          onFocus(...args);
          return { focused: true };
        },
        onBlur: ({ onBlurHandler }, { onBlur, form: { handleBlur }, field: { name } }) => (
          ...args
        ) => {
          let handler = onBlurHandler;
          if (!handler) {
            handler = handleBlur(name);
          }

          handler(...args);
          onBlur(...args);
          return {
            onBlurHandler_: handler,
            focused: false,
          };
        },
        setFocused: () => focused => ({ focused }),
      },
    ),
    withHandlers({
      [onChangeProp]: ({ form: { setFieldValue }, field: { name }, onChange }) => eventOrValue => {
        const { target } = eventOrValue || {};
        const val = target ? target.value : eventOrValue;
        setFieldValue(name, val);
        if (onChange) onChange(val);
      },
    }),
    withProps(
      ({
        field: { name, value },
        form: { touched, errors },
        focused,
        hideErrorWhileFocused,
        showInitialError,
        showError = true,
      }) => {
        let shouldDisplayError = showError ? showInitialError || touched[name] : false;
        if (hideErrorWhileFocused) {
          shouldDisplayError = shouldDisplayError && !focused;
        }
        let errorText;
        if (shouldDisplayError) {
          errorText = errors[name];
        }
        return {
          errorText,
          name,
          [valueProp]: value,
        };
      },
    ),
    mapProps(props => omit(props, ['onBlurHandler_', 'showInitialError', 'hideErrorWhileFocused'])),
  );

export default withFormikWrapper;
