import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { defineMessages, injectIntl } from 'react-intl';
import { compose, withProps } from 'recompose';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import { withRouter } from 'react-router';

import ActivationLanding from '../../../../components/activationLanding/activationLanding';
import queryConnect from '../../../../store/tools/queryString/queryConnect';
import paths from './memberAppointmentsPaths';
import { getVimGuideByUserId } from '../../../../store/vimGuide/actions';
// import { getAppointmentById } from '../../../../store/appointments/actions';
import { getAppointmentSelector } from '../../../../store/appointments/selectors';
import { getVimGuideSelector } from '../../../../store/vimGuide/selectors';
import RoundedImage from '../../../../components/ui/roundedImage/roundedImage';
import { withShortActivation } from '../../../../store/activation/withShortActivation';
import CoOpLogo from '../../../../components/branding/co_opLogo';
import Header from '../../../../components/ui/header/header';
import BlueFooter from '../../../../components/molecules/BlueFooter/BlueFooter';
import LoadingPage from './loadingPage';
import appointmentsEvents from './analytics/appointmentsEvents';
import { getDomain } from '../../../../store/feature/selectors';
import withFeatureFlag from '../../../../utils/featureFlags/withFeatureFlag';
import * as FlagTypes from '../../../../utils/featureFlags/flagTypes';

const messages = defineMessages({
  title: {
    defaultMessage: 'Your {vimGuideTitle} {guideName} booked an appointment for you.',
    id: 'members.appointments.landing.title',
  },
  subtitle: {
    defaultMessage: 'Continue to see your appointment details.',
    id: 'members.appointments.landing.text',
  },
  buttonText: {
    defaultMessage: 'View appointment details',
    id: 'members.appointments.landing.buttonText',
  },
});

@autobind
class AppointmentActivationLanding extends React.PureComponent {
  static propTypes = {
    appointment: PropTypes.object.isRequired,
    vimGuide: PropTypes.object.isRequired,
    intl: PropTypes.object.isRequired,
    /* eslint-disable react/no-unused-prop-types */
    router: PropTypes.object.isRequired,
    getVimGuideByUserIdTracker: PropTypes.func.isRequired,
    /* eslint-enable react/no-unused-prop-types */
    queryParams: PropTypes.object.isRequired,
    params: PropTypes.object.isRequired,
    token: PropTypes.string.isRequired,
    vimGuideUserId: PropTypes.string.isRequired,
    onActivateClicked: PropTypes.func.isRequired,
    getVimGuideByUserId: PropTypes.func.isRequired,
    isActivated: PropTypes.bool.isRequired,
    domain: PropTypes.string.isRequired,
    vimGuideTitle: PropTypes.string.isRequired,
  };

  static contextTypes = {
    analytics: PropTypes.object.isRequired,
  };

  componentWillMount() {
    const { vimGuideUserId, getVimGuideByUserId, token, params, queryParams } = this.props;
    const { analytics } = this.context;
    analytics.track(appointmentsEvents.activation.appointmentLandingVisit, {
      token,
      ...params,
      ...queryParams,
    });
    getVimGuideByUserId(vimGuideUserId);
  }

  onClick() {
    const { onActivateClicked, token, appointment, queryParams, isActivated } = this.props;
    onActivateClicked(token, paths.appointmentDetails(appointment.id, queryParams), isActivated);
  }

  renderGuideImage() {
    const { profilePicture } = this.props.vimGuide;
    return (
      <span className="pic-wrap">
        <RoundedImage className="doctor-img big" src={profilePicture} />
      </span>
    );
  }

  render() {
    const { vimGuide, intl, vimGuideTitle } = this.props;
    if (_.isEmpty(vimGuide)) return <LoadingPage />;

    const values = {
      guideName: _.get(vimGuide, 'firstName'),
      vimGuideTitle,
    };
    const translatedMessages = _.mapValues(messages, message =>
      intl.formatMessage(message, values),
    );
    return (
      <div>
        <Header>
          <CoOpLogo externalPageLogo />
        </Header>
        <ActivationLanding
          onActivateClicked={this.onClick}
          renderProfileImage={this.renderGuideImage}
          {...translatedMessages}
        />
        <BlueFooter footerClassName="xxs-not-fixed white" />
      </div>
    );
  }
}

export default compose(
  injectIntl,
  withRouter,
  queryConnect(query => ({
    vimGuideUserId: query.vimGuideUserId,
    token: query.token,
    queryParams: query,
  })),
  connect(
    (state, props) => ({
      appointment: getAppointmentSelector(state, props.params.appointmentId),
      vimGuide: getVimGuideSelector(state),
      domain: getDomain(state),
    }),
    { getVimGuideByUserId },
  ),
  withProps(ownProps => ({
    isActivated: _.get(ownProps, 'appointment.member.isActivated', false),
  })),
  withShortActivation,
  withFeatureFlag({
    key: 'atlas.vimGuideTitle',
    prop: 'vimGuideTitle',
    defaultValue: 'Vim Guide',
    type: FlagTypes.String,
    context: ({ domain }) => ({ domain }),
  }),
)(AppointmentActivationLanding);
