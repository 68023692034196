import React from 'react';
import { compose, withState, withHandlers } from 'recompose';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import get from 'lodash/get';
import CleanInput from './cleanInput';
import Chip from '../../storybook/src/components/atoms/Chip/Chip';

import './cleanChipInput.less';

function CleanChipInput({
  currentChip,
  onChipClose,
  onInputChange,
  onInputEnter,
  value: chips,
  ...props
}) {
  return (
    <div className="cleanChipInput">
      <CleanInput {...props} onChange={onInputChange} onKeyDown={onInputEnter} />
      <div className="chips">
        {map(chips, chip => (
          <Chip text={chip} onClose={onChipClose} />
        ))}
        {currentChip ? <Chip text={currentChip} /> : null}
      </div>
    </div>
  );
}

CleanChipInput.propTypes = {
  currentChip: PropTypes.string,
  value: PropTypes.array.isRequired,
  changeCurrentChip: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onChipClose: PropTypes.func.isRequired,
  onInputChange: PropTypes.func.isRequired,
  onInputEnter: PropTypes.func.isRequired,
};

const enhanced = compose(
  withState('currentChip', 'changeCurrentChip'),
  withHandlers({
    onInputChange: ({ changeCurrentChip }) => event => {
      changeCurrentChip(get(event, 'target.value'));
    },
    onInputEnter: ({ value: chips, currentChip, changeCurrentChip, onChange }) => event => {
      if (event.key === 'Enter') {
        event.preventDefault();
        onChange([...(chips ? chips : []), currentChip]);
        event.target.value = '';
        changeCurrentChip('');
      }
    },
    onChipClose: ({ value: chips, onChange }) => closedChip => {
      onChange(chips.filter(chip => chip !== closedChip));
    },
  }),
)(CleanChipInput);
export default enhanced;
