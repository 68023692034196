/**
 * Created by galgoltzman on 28/05/2017.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import { defineMessages, intlShape } from 'react-intl';
import paths from '../routes/paths';
import doctorCompose from '../doctorReviewHOC';
import PillsReviewPage from './pillsReviewPage';
import IntlEnum from '../../../../../utils/intlEnum';
import * as negativeOfficeEnum from '../../../../../components/doctorCard/sections/reviewPositiveOptions/negativeOfficeOptions';
import './doctorReview.less';
import displayProvider from '../../../../../utils/displayProvider';
import SvgHospital from '../../../../../assets/svg/hospital';

const messages = defineMessages({
  whatCouldDrOfficeImprove: {
    defaultMessage: 'What could {providerDisplayName}’s office improve?',
    id: 'members.doctorReview.whatCouldDrSmithsOfficeImprove',
  },
  waitTimes: {
    defaultMessage: 'Wait times',
    id: 'members.doctorReview.waitTimes',
  },
  cleanlinessAtmosphere: {
    defaultMessage: 'Cleanliness + atmosphere',
    id: 'members.doctorReview.cleanlinessAtmosphere',
  },
  staffAttitude: {
    defaultMessage: 'Staff attitude',
    id: 'members.doctorReview.staffAttitude',
  },
  scheduling: {
    defaultMessage: 'Scheduling',
    id: 'members.doctorReview.scheduling',
  },
  next: {
    defaultMessage: 'Next',
    id: 'members.doctorReview.next',
  },
});

const NegativeOffice = new IntlEnum(
  { value: negativeOfficeEnum.WAIT_TIME, message: messages.waitTimes },
  { value: negativeOfficeEnum.CLEANLINESS_ATMOSPHERE, message: messages.cleanlinessAtmosphere },
  { value: negativeOfficeEnum.STAFF_ATTITUDE, message: messages.staffAttitude },
  { value: negativeOfficeEnum.SCHEDULING, message: messages.scheduling },
);

@autobind
class NegativeRatingOffice extends React.Component {
  static propTypes = {
    router: PropTypes.object.isRequired,
    params: PropTypes.object.isRequired,
    intl: intlShape.isRequired,
    reviewProcess: PropTypes.object.isRequired,
  };

  onNext() {
    const {
      router,
      params: { token },
    } = this.props;
    router.push(paths.doctorTextReview(token));
  }

  onBack() {
    const {
      router,
      params: { token },
    } = this.props;
    router.push(paths.negativeDoctor(token));
  }

  render() {
    const {
      intl,
      reviewProcess: {
        provider: { suffix, firstName, lastName },
      },
    } = this.props;
    const providerDisplayName = displayProvider(firstName, lastName, suffix);

    const message = intl.formatMessage(messages.whatCouldDrOfficeImprove, { providerDisplayName });

    return (
      <PillsReviewPage
        token={this.props.params.token}
        onNext={this.onNext}
        onBack={this.onBack}
        intlEnum={NegativeOffice}
        icons="check-icons"
        name="negativeOffice"
        icon={<SvgHospital />}
        headerMessage={message}
      />
    );
  }
}

export default doctorCompose(NegativeRatingOffice);
