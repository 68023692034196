import Joi from 'joi-browser';
import _ from 'lodash';

import { PasswordSchema } from '../../../../utils/commonSchema';

const EmailSchema = Joi.string().email();

const phoneNumberSchema = Joi.string().length(10);
const phoneNumberFormatSchema = Joi.string().regex(/^(\+0?1\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/);

const PhoneOrEmailSchema = Joi.alternatives()
  .try(phoneNumberFormatSchema, phoneNumberSchema, EmailSchema)
  .options({
    language: {
      string: { email: '!!Oopsy, please enter valid email or mobile number here.' },
      any: { required: '!!Please enter your email or mobile number' },
    },
  });

export const schema = {
  identifier: PhoneOrEmailSchema.required(),
  password: PasswordSchema.required(),
};

export function createIdentifier({ identifier }) {
  if (!_.includes(identifier, '@')) {
    const phone = `+1${identifier.replace('+1', '').replace(/\D/g, '')}`; // gets a clear number
    return { identifier: phone, type: 'phone' };
  }

  return { identifier, type: 'email' };
}
