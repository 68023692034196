import { withFetchersOnMount } from './withFetchersOnMount';
import { ThreeBounceSpinner } from '../../components/ui/spinner/index';

/**
 * Wrapper of withFetchersOnMount(handlersConfig, withState=true)
 * Wraps a component with state fetchers - fetchers are something like "async" handlers.
 * the idea is to provide a unified way to fetch async data to the component.
 * it will inject a fetch tracker that can help to track the request @see withTracker
 * you can provide a loading predicate to prevent rendering your component until the data is ready
 * in addition, it will inject to the given resultPropName the result of the fetch
 * The usage is very similar to withHandlers from recompose, despite of few changes:
 * instead of providing for each key an handler - you need to pass fetcher config:
 * {
 *   doSomething: {
 *     handler: props => () => promise OR props => promise
 *     @optional onStart: (props, result) => {}
 *     @optional onSuccess: (props, result) => {}
 *     @optional onError: (props, error) => {}
 *     @optional track: boolean (true by default)
 *     @optional isReady - (props) => boolean
 *     @optional resultPropName - string (${key}Result by default)
 *   }
 *     @optional customLoadingComponent - (Component) (ThreeBounceSpinner by default)

 * }
 * @param handlersConfig
 * @return {*}
 */
export const withStateFetchersOnMount = (
  handlersConfig,
  customLoadingComponent = ThreeBounceSpinner,
) => withFetchersOnMount(handlersConfig, true, customLoadingComponent);
