/**
 * Created by meirshalev on 07/06/2017.
 */
import Joi from 'joi-browser';
import { ReduxFormAddress } from '../../../../../components/form/reduxForm/components';

export const name = 'address';

export const fields = {
  address: {
    name: 'address',
    component: ReduxFormAddress,
  },
};

export const schema = {
  address: Joi.string()
    .options({
      language: {
        any: {
          required: '!!Please choose an address from the list.',
          empty: '!!Please choose an address from the list.',
        },
      },
    })
    .required(),
};
