/**
 * Created by asafdavid on 4/23/15.
 */

import moment from 'moment';
import _ from 'lodash';
import GeoLib from 'geolib';

const MALE = 'MALE';
const IPHONE = 'iPhone';
const IPAD = 'iPad';

export default class BookMdUtil {
  /**
   * converts hours to seconds.
   * @param hours
   * @returns {number}
   */
  static hoursToSeconds(hours) {
    return hours * 3600;
  }

  /**
   * Removes any undefined, null or NaN properties from the given object.
   * @param object
   * @returns {Object} - a new object without the empty properties.
   */
  static omitEmptyValues(object) {
    return _.omitBy(object, value => _.isNil(value) || _.isNaN(value));
  }

  /**
   * returns the time delta from UTC
   * @param inSeconds
   * @returns {*}
   */
  static timeDelta(inSeconds = false) {
    const offsetInMinutes = moment().utcOffset();
    return inSeconds ? offsetInMinutes * 60 : offsetInMinutes / 60;
  }

  static roundHalf(num) {
    return Math.round(num * 2) / 2;
  }

  static format(format, params, strict = true) {
    if (!format || !params) return false;
    let res = format;

    _.each(params, (value, key) => {
      if (format.indexOf(`:${key}`) === -1) {
        if (strict) throw new Error(`not a valid format - couldn't find match to :${key}`);
      } else {
        res = res.split(`:${key}`).join(value);
      }
    });

    return res;
  }

  static dateToLocal(date) {
    return moment.utc(date.toISOString()).toDate();
  }

  static calcGeoDistance(coord1, coord2, unit = 'mi') {
    coord1 = _.get(coord1, 'coordinates', coord1);
    coord2 = _.get(coord2, 'coordinates', coord2);

    const coord1Length = _.get(coord1, 'length');
    const coord2Length = _.get(coord2, 'length');

    if (coord1Length !== 2 || coord2Length !== 2) return -1;
    if (!_.every(coord1, _.isNumber) || !_.every(coord2, _.isNumber)) return -1;

    const dist = GeoLib.getDistance(
      BookMdUtil.toGeoPoint(coord1),
      BookMdUtil.toGeoPoint(coord2));

    return GeoLib.convertUnit(unit, dist, 1);
  }

  /**
   * Returns a formatted string representing a geo code for google maps
   * @param geo
   * @returns {string}
   */
  static geoForGmaps(geo) {
    return `${geo.coordinates[1]},${geo.coordinates[0]}`;
  }

  static coordinatesToGeoForGmaps(coordinates) {
    return `${coordinates.latitude},${coordinates.longitude}`;
  }

  /**
   * Can take in a few options of structure for coordinates.
   * Converting it into a geo point object.
   * @param {Number[] | { lat: *, lon: *}} coordinates
   * @returns {{latitude: *, longitude: *}}
   */
  static toGeoPoint(coordinates) {
    if (_.has(coordinates, 'longitude') && _.has(coordinates, 'latitude')) {
      return {
        ...coordinates,
        longitude: _.toNumber(coordinates.longitude),
        latitude: _.toNumber(coordinates.latitude),
      };
    }
    if (_.isArray(coordinates)) return { latitude: coordinates[0], longitude: coordinates[1] };
    if (_.has(coordinates, 'lon') && _.has(coordinates, 'lat')) {
      return { latitude: _.toNumber(coordinates.lat), longitude: _.toNumber(coordinates.lon) };
    }

    return null;
  }

  /**
   * For use in when looking for closest in network location for a plan.
   * @param locations - list of locations ordered by distance, 0 is closest
   * @param planId - an insurance plan identifier, according to domain standard
   */
  static getClosestInNetworkLocation(locations, planId) {
    if (locations && planId) {
      const inNetworkClinics = locations.filter(location => _.find(
        location.networks, { insurancePlanId: planId })
      );
      if (!_.isEmpty(inNetworkClinics)) {
        return _.get(inNetworkClinics, `[${inNetworkClinics.length - 1}]`);
      }
    }

    return _.get(locations, '[0]', {
      geo: {},
      networks: {},
    });
  }

  /**
   * For use in componentWillReceiveProps. Executes a given action on props changes.
   * Example:
   *  Utils.inspectChange(this.props, nextProps, [
   { uri: 'uri.to.inspected.prop', action: this.callFunc },
   { uri: 'uri.to.inspected.prop', action: nextProps => func(nextProps.params) } ];
   * @param props
   * @param nextProps
   * @param inspects
   */
  static inspectChange(props, nextProps, inspects) {
    _.each(inspects, ({ uri, action }) => {
      if (!_.isEqual(_.get(props, uri), _.get(nextProps, uri))) action(nextProps);
    });
  }

  static isMale(gender) {
    return _.isEqual(_.upperCase(gender), MALE);
  }

  static toSelectOptions(enumm) {
    return _.map(enumm, (value, label) => ({ value, label }));
  }

  static isIphoneOrIpad(phoneType) {
    return _.includes(phoneType, IPHONE) || _.includes(phoneType, IPAD);
  }

  /**
   * Given an objects array, will return a plain object,
   * where the key and the value will be selected from the correspanding object in the array.
   * Example:
   *  Util.arrayToPlainObject([ {id: 1, name: 'guy'}, {id: 2, name: 'girl'} ], 'name', 'id');
   *  -> { guy: 1, girl: 2}
   * @param array
   * @param keySelector
   * @param valueSelector
   */
  static arrayToPlainObject(array, keySelector, valueSelector) {
    return _.reduce(array, (result, value) => {
      result[value[keySelector]] = value[valueSelector];
      return result;
    }, {});
  }

  static isOldEnough(dateOfBirth, minimalAge, dobFormat = undefined) {
    const negativeAge = moment(dateOfBirth, dobFormat).diff(moment(), 'years');
    const age = Math.abs(negativeAge);
    return age >= minimalAge;
  }
}
