import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { compose, withHandlers, withStateHandlers, lifecycle, pure } from 'recompose';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';
import { AsyncTypeahead, Menu, MenuItem } from 'react-bootstrap-typeahead';
import analytics from '../../store/tools/analytics/analyticsHoc';
import { focusSearchTypeahead } from '../../store/ui/actions';
import { formatIfIntl } from '../ui/intlString/intlString';
import ClickableDiv from '../clickableElement/clickableDiv';

export const DropMenu = Menu;
export const DropMenuItem = MenuItem;

let currentId = 0;

const generateId = () => {
  currentId += 1;
  return `search-typeahead-${currentId}`;
};

function CleanTypeahead({
  intl,
  containerClasses,
  placeholder,
  typeaheadClassName,
  currentText,
  updateText,
  setTypeaheadRef,
  isFocused,
  clearAndFocus,
  showLeftSearchIcon,
  ...others
}) {
  const id = generateId();
  const parsedPlaceholder = formatIfIntl(placeholder, intl);
  const labelUp = isFocused || !_.isEmpty(currentText);
  const inputProps = { id, className: 'bootstrap-typeahead-input-main', autoComplete: 'off' };

  return (
    <div>
      <div className="clean-typeahead">
        <div
          className={classNames(
            'big-search-input multiple-inputs left-search-icon right-clear-icon no-margin',
            containerClasses,
          )}
        >
          <AsyncTypeahead
            {...others}
            className={`bootstrap-typeahead ${typeaheadClassName}`}
            onInputChange={updateText}
            ref={setTypeaheadRef}
            useCache={false}
            minLength={0} // so we can see the suggestions
            inputProps={inputProps}
          />
        </div>
        {showLeftSearchIcon && (<i className="icon-search-2 search-inner-icon" />)}
        <ClickableDiv
          className="clear-input-btn"
          clickHandler={clearAndFocus}
          aria-label="Clear search input"
        >
          <i className="icon-x-2" />
        </ClickableDiv>
        <label
          htmlFor={id}
          className={classNames({ 'typeahead-label': true, 'input-active': labelUp })}
        >
          {parsedPlaceholder}
        </label>
      </div>
    </div>
  );
}

CleanTypeahead.defaultProps = {
  containerClasses: '',
  initialTerm: '',
  labelKey: 'name',
  maxHeight: '300px',
  name: 'type_ahead',
  options: [],
  placeholder: '',
  selected: [],
  typeaheadClassName: '',
  showLeftSearchIcon: true,
};

CleanTypeahead.propTypes = {
  clearAndFocus: PropTypes.func.isRequired,
  containerClasses: PropTypes.string,
  currentText: PropTypes.any.isRequired,
  filterBy: PropTypes.any.isRequired,
  initialTerm: PropTypes.string,
  maxHeight: PropTypes.string,
  intl: intlShape.isRequired,
  isFocused: PropTypes.any.isRequired,
  placeholder: PropTypes.string,
  setTypeaheadRef: PropTypes.func.isRequired,
  typeaheadClassName: PropTypes.string,
  updateText: PropTypes.func.isRequired,
  showLeftSearchIcon: PropTypes.bool,
};

function analyticsSelector(options) {
  const data = {};

  _.forEach(options, option => {
    if (!_.get(option, 'meta.type')) return;

    const properties = {
      data: ['name', 'type', 'npi']
    };
    data[option.meta.type] = data[option.meta.type] || { count: 0 };
    _.forOwn(properties, (value, key) => {
      value.forEach(property => {
        const val = option[key] && option[key][property];
        if (val) {
          // since we found the value, add it to our flattened list.
          data[option.meta.type][property] = data[option.meta.type][property] || [];
          data[option.meta.type][property].push(val);

          // update the count of the array lengths while we know we have an array.
          const newCount = data[option.meta.type][property].length;
          data[option.meta.type].count =
            newCount > data[option.meta.type].count ? newCount : data[option.meta.type].count;
        }
      });
    });
  });

  return data;
}

const withTypeaheadState = withStateHandlers(
  ({ initialTerm, autoFocus }) => ({
    selected: initialTerm ? [initialTerm] : [],
    currentText: initialTerm || '',
    isFocused: autoFocus || false,
    typeaheadInstance: undefined,
  }),
  {
    setSelected: () => newSelected => ({ selected: _.castArray(newSelected) }),
    updateText: () => text => ({ currentText: text, selected: _.castArray(text) }),
    clear: ({ typeaheadInstance }, { onOptionSelection }) => () => {
      onOptionSelection();
      typeaheadInstance.clear();
      return { currentText: '', selected: [] };
    },
    setFocused: () => isFocused => ({ isFocused }),
    setTypeaheadRef: () => typeaheadRef => ({ typeaheadInstance: typeaheadRef.getInstance() }),
  },
);

const withTypeaheadHandlers = compose(
  withHandlers({
    focus: ({ typeaheadInstance: { focus } }) => () => focus(),
    blur: ({ typeaheadInstance: { blur } }) => () => blur(),
  }),
  withHandlers({
    onFocus: ({ setFocused, onFocus }) => (...args) => {
      onFocus(...args);
      setFocused(true);
    },
    onBlur: ({ setFocused, onBlur }) => (...args) => {
      onBlur(...args);
      setFocused(false);
    },
    onSearch: ({ onInputChange, options }) => text => onInputChange(text, options),
    onChange: props => newSelections => {
      const {
        clearOnSelect,
        labelKey,
        onOptionSelection,
        clear,
        blur,
        updateText,
        setSelected,
      } = props;
      const selected = _.first(newSelections);
      if (!selected) return;

      if (clearOnSelect) {
        clear();
      } else {
        const formattedText = selected[labelKey];
        updateText(formattedText);
        setSelected(selected);
        blur();
      }

      onOptionSelection(selected);
    },
    clearAndFocus: ({ clear, focus }) => () => {
      clear();
      focus();
    },
    // so if I look for ghost terms, it wont filter them out
    filterBy: () => () => true,
  }),
);

const withInitialSearch = lifecycle({
  componentDidMount() {
    const { currentText, onSearch } = this.props;
    if (currentText) onSearch(currentText);
  },
});

const CleanTypeaheadEnhanced = compose(
  pure,
  injectIntl,
  connect(
    null,
    { focusSearchTypeahead },
  ),
  component =>
    analytics(component, false, [
      {
        event: 'onOptionSelection',
        eventData: selection => analyticsSelector([selection]),
      },
    ]),
  withTypeaheadState,
  withTypeaheadHandlers,
  withInitialSearch,
)(CleanTypeahead);

CleanTypeaheadEnhanced.propTypes = {
  clearOnSelect: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.object),
  onOptionSelection: PropTypes.func.isRequired,
  onInputChange: PropTypes.func,
  labelKey: PropTypes.string,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  autoFocus: PropTypes.bool,
};

CleanTypeaheadEnhanced.defaultProps = {
  clearOnSelect: false,
  options: [],
  onInputChange: _.noop,
  labelKey: 'name',
  onFocus: _.noop,
  onBlur: _.noop,
  autoFocus: false,
};

export default CleanTypeaheadEnhanced;
