/**
 * Created by chenrozenes on 26/12/2017.
 */
import _ from 'lodash';

/**
 * Class for traversing over flow steps
 */
class FlowTraverse {
  constructor(steps, initialStepName) {
    this.steps = steps;
    this.initialStepName = initialStepName;
  }

  _getStep(name) {
    return this.steps[name] || {};
  }

  hasNext(stepName) {
    const curr = this._getStep(stepName);
    return !_.isEmpty(curr.children);
  }

  hasBack(stepName) {
    const curr = this._getStep(stepName);
    // Can't go back if we are on the initial step of the flow or has no parents
    return stepName !== this.initialStepName && !_.isEmpty(curr.parents);
  }

  /**
   * Find the parent of the current step
   * @param stepName
   * @param flowState
   */
  findParent(stepName, flowState) {
    if (!this.hasBack(stepName)) return undefined;

    const curr = this._getStep(stepName);

    // Trying to go next from every parent
    const parent = _.find(curr.parents, parent => {
      const parentStep = this._getStep(parent);
      return parentStep.children[stepName](flowState);
    });

    if (parent && parent.transient) {
      return this.findParent(parent, flowState);
    }

    return parent;
  }

  /**
   * Find the next step
   * @param stepName
   * @param flowState
   * @param stepValue
   */
  findNext(stepName, flowState, stepValue) {
    if (!this.hasNext(stepName)) return undefined;

    const curr = this._getStep(stepName);
    const newState = _.extend({}, flowState, { [curr.name]: stepValue });

    return _.findKey(curr.children, predicate => predicate(newState));
  }

  /**
   * Returns true if the step named 'parentName' is a parent of 'stepName'
   * @param stepName
   * @param parentName
   * @returns {boolean}
   */
  isParent(stepName, parentName) {
    const curr = this._getStep(stepName);
    return _.includes(curr.parents, parentName);
  }

  /**
   * Returns true if step is a transient step
   * @param stepName
   * @returns {*}
   */
  isTransient(stepName) {
    const curr = this._getStep(stepName);
    return !!curr.transient;
  }

  _safeGetFunc(stepName, funcName) {
    const step = this._getStep(stepName);
    return _.get(step, funcName, _.noop);
  }

  mapReturnValueToContext(stepName, value) {
    const mapper = this._safeGetFunc(stepName, 'mapReturnValueToContext');
    return mapper(value);
  }

  mapContextToProps(stepName, context) {
    const mapper = this._safeGetFunc(stepName, 'mapContextToProps');
    return mapper(context);
  }
}

export default FlowTraverse;
