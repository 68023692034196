import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { defineMessages, FormattedMessage } from 'react-intl';
import { autobind } from 'core-decorators';
import { formValueSelector } from 'redux-form';

import * as FormDefs from './findMemberFormDefs';
import actionTracker, {
  actionTrackerShape,
} from '../../../../../../store/tools/actionTracker/actionTrackerComponent';
import FindMemberForm from './findMemberForm';
import {
  ACTIVATE_WITH_MEMBER_ID,
  activateWithMemberId,
} from '../../../../../../store/activation/actions';
import * as Errors from '../../../../../../api/errors';
import {
  createToggleState,
  createToggleStatePropTypes,
} from '../../../../../../components/recompose/toggleStateComponent';
import errorMapping from '../../activationErrorMapping';
import { PREMERA_BLUE_CROSS_ALASKA } from '../../../../../../model/enum/bkmdTpa';
import { getFeature } from '../../../../../../store/feature/selectors';

const messages = defineMessages({
  title: {
    defaultMessage: 'Your Details',
    id: 'members.fullActivation.flow.findPatient.title',
  },
});

@autobind
class FindMember extends React.PureComponent {
  static propTypes = {
    activateWithMemberId: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
    tracker: actionTrackerShape.isRequired,
    initialValues: PropTypes.shape({
      fullName: PropTypes.string,
      dateOfBirth: PropTypes.string,
    }).isRequired,
    ...createToggleStatePropTypes('errorLabel'),
  };

  constructor() {
    super();
    this.state = { fails: 0 };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.tracker.finished) {
      if (nextProps.tracker.hasError) {
        this.handleError(nextProps.tracker.error);
      } else {
        this.props.onSuccess();
      }
    }
  }

  onSearch(data) {
    const { dateOfBirth, fullName, insuranceId } = data;
    this.props.activateWithMemberId({
      fullName,
      dateOfBirth,
      memberId: insuranceId,
      phoneNumber: this.props.phoneNumber,
      insurer: PREMERA_BLUE_CROSS_ALASKA, // TODO
    });
  }

  handleError(error) {
    const { activationMinimalAge } = this.props;
    const { responseMessage } = error;
    switch (responseMessage) {
      case Errors.TOO_YOUNG: {
        this.props.notification.error(
          errorMapping[responseMessage].title,
          errorMapping[responseMessage].message,
          { autoDismiss: 10, titleParams: { minimalAge: activationMinimalAge } },
        );
        break;
      }
      case Errors.USER_ALREADY_EXIST: {
        this.props.alreadyActivatedModalShow();
        break;
      }
      case Errors.MEMBER_NOT_FOUND: {
        this.handleNotFoundError();
        break;
      }
      default:
        break;
    }
  }

  handleNotFoundError() {
    const { fails } = this.state;
    const newFails = fails + 1;
    this.setState({ fails: newFails });
    if (newFails >= this.props.maxFindPatientFails) {
      this.props.onError(Errors.MEMBER_NOT_FOUND);
    }

    const { message, title } = errorMapping.dobDoesNotMatch;
    this.props.notification.error(title, message, { autoDismiss: 10 });
  }

  render() {
    return (
      <div>
        <div className="row">
          <div className="col-xs-12">
            <div className="title-24 text-strong-main text-center margin-30">
              <FormattedMessage {...messages.title} />
            </div>
          </div>
        </div>
        <div className="margin-top-20">
          <FindMemberForm
            initialValues={this.props.initialValues}
            onSubmit={this.search}
            isLoading={this.props.tracker.inProgress || false}
          />
        </div>
      </div>
    );
  }
}

const selector = formValueSelector(FormDefs.name);
const {
  fields: { fullName, dateOfBirth, insuranceId },
} = FormDefs;

export default compose(
  connect(
    state => ({
      fullName: selector(state, fullName.name),
      dateOfBirth: selector(state, dateOfBirth.name),
      insuranceId: selector(state, insuranceId.name),
      maxFindPatientFails: _.get(state, 'config.schedules.addAppointment.maxFindPatientFails'),
      activationMinimalAge: getFeature(state, 'minimalAge', 18),
    }),
    { activateWithMemberId },
  ),
  actionTracker({
    tracker: ACTIVATE_WITH_MEMBER_ID.SOURCE,
  }),
  createToggleState('errorLabel'),
)(FindMember);
