/**
 * Created by ofirHartuv on 20/08/2017.
 */

import _ from 'lodash';

export function upperFirstEachWord(phrase) {
  return _
    .chain(phrase)
    .split(' ')
    .map(_.upperFirst)
    .join(' ')
    .value();
}

/**
 * Gets provider's details and return a common display name,
 * @returns {`${firstName} ${lastName}, ${suffix}`
 * ("James Scott, MD" e.g - firstName: James; lastName: Scott; suffix: MD)}
 */
export default function displayProvider(firstName, lastName, suffix) {
  return `${upperFirstEachWord(firstName)} ${upperFirstEachWord(lastName)}` +
         `${suffix ? `, ${_.invoke(suffix, 'toUpperCase')}` : ''}`;
}

function displayProviderWithMiddleName(firstName, middleName, lastName, suffix) {
  return `${upperFirstEachWord(firstName)} ${upperFirstEachWord(middleName)} ` +
    `${upperFirstEachWord(lastName)}, ${_.invoke(suffix, 'toUpperCase')}`;
}

export const displayProviderObj = provider => {
  if (!provider) return '';
  const { firstName, lastName, suffix } = provider;
  return displayProvider(firstName, lastName, suffix);
};

export const formatProviderName = provider => {
  if (!provider) return '';
  const { firstName, middleName, lastName, suffix } = provider;

  if (!middleName) {
    return displayProvider(firstName, lastName, suffix);
  }
  return displayProviderWithMiddleName(firstName, middleName, lastName, suffix);
};
