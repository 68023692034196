/**
 * Created by chenrozenes on 21/04/2016.
 */
import Joi from 'joi-browser';
import _ from 'lodash';

import { messages } from './joiErrorMsgs';

/**
 * Gets the Joi error object, and the current values of in the form,
 * and builds an error object for redux-form
 * @param errors - Joi errors object
 * @params values - Object - Current values in the form
 * @returns {{}}
 */
function buildErrorsForReduxForm(errors, formatMessageHandler = _.noop) {
  const toReturn = {};

  // Preparing object for redux-form
  if (errors) {
    _.reduce(
      errors.details,
      (obj, value) => {
        const fieldName = _.chain(value.path)
          .split('.')
          .first()
          .value();

        _.set(obj, fieldName, formatMessageHandler(fieldName, value.path, value.message));
        return obj;
      },
      toReturn,
    );
  }

  return toReturn;
}

/**
 * Gets a Joi schema and crates a validator function for redux-form
 * @param joiSchema
 * @param custom
 * @returns {Function}
 */
export function createValidator(joiSchema, custom) {
  return function formValidator(values, props) {
    // Validate using joi
    const errors = Joi.validate(values, joiSchema, { abortEarly: false });
    const reduxErrors = buildErrorsForReduxForm(errors.error, (fieldName, path, defaultMesage) => {
      if (!_.get(props, 'intl')) return null;
      const intlMsg = messages[path] || messages[fieldName];
      return intlMsg ? props.intl.formatMessage(intlMsg) : defaultMesage;
    });

    // Run custom validation if any was provided
    if (custom) {
      _.each(custom, (validate, field) => {
        if (!reduxErrors[field]) {
          const fieldError = validate(values[field], values);
          if (fieldError) reduxErrors[field] = fieldError;
        }
      });
    }

    return reduxErrors;
  };
}

/**
 * Generate default empty strings values for all fields in the schema.
 * To set some values manually pass the optional base parameter
 */
export function generateDefaults(object, base = {}) {
  return _.reduce(object, (result, value, key) => _.assign({ [key]: '' }, result), base);
}
