/**
 * Created by chenrozenes on 30/03/2017.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { destroy } from 'redux-form';
import { autobind } from 'core-decorators';
import _ from 'lodash';
import setInQueryString from '../../store/tools/queryString/setInQueryString';

@autobind
class FlowManager extends React.PureComponent {
  static propTypes = {
    /**
     * Current step on the flow - from redux/query string
     */
    currentStep: PropTypes.number.isRequired,
    /**
     *  When true, if the flow was accessed from different step rather than 1,
     *  it will redirect to the first step
     */
    enforceFirstStep: PropTypes.bool,
    /**
     * Redux function to update query string
     */
    setInQueryString: PropTypes.func.isRequired,
    /**
     * Array of step components
     */
    steps: PropTypes.array.isRequired,
    /**
     * Name of all forms in the flow
     */
    formName: PropTypes.string,
  };

  static defaultProps = {
    enforceFirstStep: true,
    maxSteps: 1,
    onLastStep: undefined,
    formName: undefined,
  };

  static contextTypes = {
    store: PropTypes.object
  };

  componentWillMount() {
    const { enforceFirstStep, setInQueryString, currentStep } = this.props;
    if (enforceFirstStep && currentStep > 1) {
      setInQueryString({ step: 1 });
    }
  }

  componentWillUnmount() {
    if (this.props.formName) {
      this.context.store.dispatch(destroy(this.props.formName));
    }
  }

  nextStep(stepToGo) {
    const { setInQueryString, currentStep } = this.props;
    setInQueryString({ step: stepToGo || currentStep + 1 }, true);
  }

  render() {
    const { steps, currentStep } = this.props;
    const stepIndex = currentStep - 1;
    if (!steps[stepIndex]) return null;

    const flowObj = {
      nextStep: this.nextStep,
      currentStep
    };

    const { component, props = {} } = steps[stepIndex];

    return React.createElement(component, { flow: flowObj, ...props });
  }
}

export default connect((state, props) => {
  const queryStep = _.get(state, 'routing.locationBeforeTransitions.query.step', 1);
  return {
    currentStep: Math.max(Math.min(queryStep, props.maxSteps), 1),
  };
}, { setInQueryString })(FlowManager);
