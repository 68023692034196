import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '../../storybook/src/utils/theme';

function SvgNotificationsStars({ theme }) {
  /* eslint-disable */
  return (
    <svg aria-hidden="true" focusable="false" width="71px" height="64px" viewBox="0 0 71 64" version="1.1" className="svg-icon svg-notifications-stars">
      <defs>
        <polygon id="path-1" points="0.65 0.25 10.332 0.25 10.332 9.9318 0.65 9.9318 0.65 0.25"></polygon>
      </defs>
      <g id="All-settings-screens" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Notifications-logged-in" transform="translate(-30.000000, -149.000000)">
          <g id="Page-1" transform="translate(30.000000, 148.000000)">
            <path d="M4.5,49.6592 C3.672,49.6592 3,48.9872 3,48.1592 L3,17.2502 C3,12.7212 6.936,8.7502 11.423,8.7502 L33.695,8.7502 C34.523,8.7502 35.195,9.4222 35.195,10.2502 C35.195,11.0782 34.523,11.7502 33.695,11.7502 L11.423,11.7502 C8.585,11.7502 6,14.3712 6,17.2502 L6,48.1592 C6,48.9872 5.328,49.6592 4.5,49.6592" id="Fill-1" fill={theme.mainColor} ></path>
            <path d="M69.5,61.75 L16.5,61.75 C15.672,61.75 15,61.078 15,60.25 C15,59.422 15.672,58.75 16.5,58.75 L65.879,58.75 L59.439,52.311 C59.158,52.029 59,51.647 59,51.25 L59,17.25 C59,14.32 56.543,11.75 53.741,11.75 L49.286,11.75 C48.458,11.75 47.786,11.078 47.786,10.25 C47.786,9.422 48.458,8.75 49.286,8.75 L53.741,8.75 C58.218,8.75 62,12.643 62,17.25 L62,50.629 L70.561,59.189 C70.989,59.618 71.118,60.264 70.886,60.824 C70.653,61.385 70.106,61.75 69.5,61.75" id="Fill-3" fill={theme.mainColor} ></path>
            <path d="M5.5,56.75 C4.121,56.75 3,57.871 3,59.25 C3,60.629 4.121,61.75 5.5,61.75 C6.879,61.75 8,60.629 8,59.25 C8,57.871 6.879,56.75 5.5,56.75 M5.5,64.75 C2.468,64.75 0,62.282 0,59.25 C0,56.218 2.468,53.75 5.5,53.75 C8.532,53.75 11,56.218 11,59.25 C11,62.282 8.532,64.75 5.5,64.75" id="Fill-5" fill={theme.secondaryColor} ></path>
            <g id="Group-9" transform="translate(36.000000, 0.750000)">
              <mask id="mask-2" fill="white">
                <use xlinkHref="#path-1"></use>
              </mask>
              <g id="Clip-8"></g>
              <path d="M8.832,3.5908 L6.991,3.5908 L6.991,1.7498 C6.991,0.9218 6.319,0.2498 5.491,0.2498 C4.663,0.2498 3.991,0.9218 3.991,1.7498 L3.991,3.5908 L2.15,3.5908 C1.322,3.5908 0.65,4.2628 0.65,5.0908 C0.65,5.9188 1.322,6.5908 2.15,6.5908 L3.991,6.5908 L3.991,8.4318 C3.991,9.2598 4.663,9.9318 5.491,9.9318 C6.319,9.9318 6.991,9.2598 6.991,8.4318 L6.991,6.5908 L8.832,6.5908 C9.66,6.5908 10.332,5.9188 10.332,5.0908 C10.332,4.2628 9.66,3.5908 8.832,3.5908" id="Fill-7" fill={theme.secondaryColor} mask="url(#mask-2)"></path>
            </g>
            <path d="M70.5,39.25 C70.5,40.631 69.381,41.75 68,41.75 C66.619,41.75 65.5,40.631 65.5,39.25 C65.5,37.869 66.619,36.75 68,36.75 C69.381,36.75 70.5,37.869 70.5,39.25" id="Fill-10" fill={theme.secondaryColor} ></path>
            <path d="M26.5,34.75 C26.5,36.131 25.381,37.25 24,37.25 C22.619,37.25 21.5,36.131 21.5,34.75 C21.5,33.369 22.619,32.25 24,32.25 C25.381,32.25 26.5,33.369 26.5,34.75" id="Fill-12" fill={theme.secondaryColor} ></path>
            <path d="M35.5,34.75 C35.5,36.131 34.381,37.25 33,37.25 C31.619,37.25 30.5,36.131 30.5,34.75 C30.5,33.369 31.619,32.25 33,32.25 C34.381,32.25 35.5,33.369 35.5,34.75" id="Fill-14" fill={theme.secondaryColor} ></path>
            <path d="M44.5,34.75 C44.5,36.131 43.381,37.25 42,37.25 C40.619,37.25 39.5,36.131 39.5,34.75 C39.5,33.369 40.619,32.25 42,32.25 C43.381,32.25 44.5,33.369 44.5,34.75" id="Fill-16" fill={theme.secondaryColor} ></path>
          </g>
        </g>
      </g>
    </svg>
  );
  /* eslint-enable */
}

SvgNotificationsStars.propTypes = {
  theme: PropTypes.object.isRequired,
};

export default withTheme(SvgNotificationsStars);
