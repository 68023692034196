import { connect } from 'react-redux';
import { compose, branch, renderComponent } from 'recompose';

import BookButtonByFlow from '../../../../../../components/bookingButton/bookingButton';
import BookButtonBySdk from '../../../../../../components/bookingButton/bookButtonBySdk';
import { domain as domainSelector } from '../../../../../../store/config/selectors';
import { memberContext } from '../../../../../../store/member/selectors';
import withFeatureFlag from '../../../../../../utils/featureFlags/withFeatureFlag';

// Todo: This is a temporary component, will be removed after fully moving directories to sdk
export default compose(
  connect(state => ({
    domain: domainSelector(state),
    memberContext: memberContext(state),
  })),
  withFeatureFlag({
    key: `members.useSchedulingSdk`,
    prop: 'useSchedulingSdk',
    defaultValue: false,
    context: ({ domain, memberContext }) => ({ domain, memberContext }),
  }),
  branch(({ useSchedulingSdk }) => useSchedulingSdk === true, renderComponent(BookButtonBySdk)),
)(BookButtonByFlow);
