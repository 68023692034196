import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '../../storybook/src/utils/theme';

function SvgPharmacies({ theme }) {
  /* eslint-disable */
  return (
    <svg aria-hidden="true" focusable="false" className="svg-icon svg-pharmacies" width="32px" height="32px" viewBox="0 0 32 32" version="1.1">
      <defs>
        <polygon points="23.4666667 0.598133333 23.4666667 23.4666667 12.0328 23.4666667 0.598933333 23.4666667 0.598933333 0.598133333"></polygon>
        <polygon points="6.96636036e-16 22.8677333 6.96636036e-16 3.78956126e-15 11.43376 3.78956126e-15 22.86752 0 22.86752 22.8677333"></polygon>
      </defs>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-428.000000, -426.000000)">
          <g transform="translate(428.000000, 426.000000)">
            <g transform="translate(8.533333, 8.533333)">
              <g></g>
              <path d="M14.1728,23.4666667 C11.7909333,23.4666667 9.41013333,22.56 7.59786667,20.7477333 L0.891733333,14.0416 C0.501333333,13.6512 0.501333333,13.0176 0.891733333,12.6272 C1.28213333,12.2368 1.91573333,12.2368 2.30613333,12.6272 L9.01226667,19.3333333 C11.8570667,22.1781333 16.4874667,22.1792 19.3333333,19.3333333 C22.1781333,16.4874667 22.1781333,11.8570667 19.3333333,9.01226667 L12.6272,2.30613333 C12.2368,1.91466667 12.2368,1.28213333 12.6272,0.891733333 C13.0176,0.500266667 13.6512,0.500266667 14.0416,0.891733333 L20.7477333,7.59786667 C24.3733333,11.2224 24.3733333,17.1221333 20.7477333,20.7477333 C18.9344,22.56 16.5536,23.4666667 14.1728,23.4666667" fill={theme.secondaryColor}></path>
            </g>
            <g>
              <g></g>
              <path d="M9.29365333,1.99946667 C7.42485333,1.99946667 5.55605333,2.71093333 4.13312,4.1328 C1.28832,6.97866667 1.28832,11.6090667 4.13312,14.4549333 L10.1320533,20.4528 L20.45312,10.1317333 L14.4541867,4.1328 C13.03232,2.71093333 11.1624533,1.99946667 9.29365333,1.99946667 M10.1320533,22.8677333 C9.87605333,22.8677333 9.62005333,22.7706667 9.42485333,22.5754667 L2.71872,15.8682667 C-0.90688,12.2437333 -0.90688,6.344 2.71872,2.7184 C6.34432,-0.906133333 12.2429867,-0.906133333 15.8685867,2.7184 L22.57472,9.42453333 C22.96512,9.816 22.96512,10.4485333 22.57472,10.8389333 L10.8392533,22.5754667 C10.6440533,22.7706667 10.3880533,22.8677333 10.1320533,22.8677333" fill={theme.mainColor}></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
  /* eslint-enable */
}

SvgPharmacies.propTypes = {
  theme: PropTypes.object.isRequired,
};

export default withTheme(SvgPharmacies);
