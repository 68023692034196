import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '../../storybook/src/utils/theme';

function SvgCellphoneStars({ theme }) {
  /* eslint-disable */
  return (
    <svg aria-hidden="true" focusable="false" width="72px" height="70px" viewBox="0 0 72 70" version="1.1" className="svg-icon svg-cellphone-stars">
      <g id="All-settings-screens" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Verify-number" transform="translate(-152.000000, -140.000000)">
          <g id="Page-1" transform="translate(152.000000, 140.000000)">
            <path d="M50.2941176,1.05882353 L18.5294118,1.05882353 C17.6516471,1.05882353 16.9411765,1.77035294 16.9411765,2.64705882 C16.9411765,3.52376471 17.6516471,4.23529412 18.5294118,4.23529412 L50.2941176,4.23529412 C53.6685882,4.23529412 56.1176471,6.68435294 56.1176471,10.0588235 L56.1176471,14.5747059 L12.7058824,14.5747059 L12.7058824,13.2352941 C12.7058824,12.3585882 11.9954118,11.6470588 11.1176471,11.6470588 C10.2398824,11.6470588 9.52941176,12.3585882 9.52941176,13.2352941 L9.52941176,57.7058824 C9.52941176,58.5825882 10.2398824,59.2941176 11.1176471,59.2941176 C11.9954118,59.2941176 12.7058824,58.5825882 12.7058824,57.7058824 L12.7058824,17.7511765 L56.1176471,17.7511765 L56.1176471,57.7058824 C56.1176471,58.5825882 56.8281176,59.2941176 57.7058824,59.2941176 C58.5836471,59.2941176 59.2941176,58.5825882 59.2941176,57.7058824 L59.2941176,10.0588235 C59.2941176,4.92776471 55.4251765,1.05882353 50.2941176,1.05882353" id="Fill-1" fill={theme.mainColor} ></path>
            <path d="M18.5294118,23.2941176 C18.2424706,23.2941176 18,23.5365882 18,23.8235294 L18,28.0588235 C18,28.3457647 18.2424706,28.5882353 18.5294118,28.5882353 L22.7647059,28.5882353 C23.0516471,28.5882353 23.2941176,28.3457647 23.2941176,28.0588235 L23.2941176,23.8235294 C23.2941176,23.5365882 23.0516471,23.2941176 22.7647059,23.2941176 L18.5294118,23.2941176 Z M22.7647059,31.7647059 L18.5294118,31.7647059 C16.4858824,31.7647059 14.8235294,30.1023529 14.8235294,28.0588235 L14.8235294,23.8235294 C14.8235294,21.78 16.4858824,20.1176471 18.5294118,20.1176471 L22.7647059,20.1176471 C24.8082353,20.1176471 26.4705882,21.78 26.4705882,23.8235294 L26.4705882,28.0588235 C26.4705882,30.1023529 24.8082353,31.7647059 22.7647059,31.7647059 L22.7647059,31.7647059 Z" id="Fill-3" fill={theme.mainColor} ></path>
            <path d="M18.5294118,37.0588235 C18.2424706,37.0588235 18,37.3012941 18,37.5882353 L18,41.8235294 C18,42.1104706 18.2424706,42.3529412 18.5294118,42.3529412 L22.7647059,42.3529412 C23.0516471,42.3529412 23.2941176,42.1104706 23.2941176,41.8235294 L23.2941176,37.5882353 C23.2941176,37.3012941 23.0516471,37.0588235 22.7647059,37.0588235 L18.5294118,37.0588235 Z M22.7647059,45.5294118 L18.5294118,45.5294118 C16.4858824,45.5294118 14.8235294,43.8670588 14.8235294,41.8235294 L14.8235294,37.5882353 C14.8235294,35.5447059 16.4858824,33.8823529 18.5294118,33.8823529 L22.7647059,33.8823529 C24.8082353,33.8823529 26.4705882,35.5447059 26.4705882,37.5882353 L26.4705882,41.8235294 C26.4705882,43.8670588 24.8082353,45.5294118 22.7647059,45.5294118 L22.7647059,45.5294118 Z" id="Fill-5" fill={theme.mainColor} ></path>
            <path d="M18.5294118,50.8235294 C18.2424706,50.8235294 18,51.066 18,51.3529412 L18,55.5882353 C18,55.8751765 18.2424706,56.1176471 18.5294118,56.1176471 L22.7647059,56.1176471 C23.0516471,56.1176471 23.2941176,55.8751765 23.2941176,55.5882353 L23.2941176,51.3529412 C23.2941176,51.066 23.0516471,50.8235294 22.7647059,50.8235294 L18.5294118,50.8235294 Z M22.7647059,59.2941176 L18.5294118,59.2941176 C16.4858824,59.2941176 14.8235294,57.6317647 14.8235294,55.5882353 L14.8235294,51.3529412 C14.8235294,49.3094118 16.4858824,47.6470588 18.5294118,47.6470588 L22.7647059,47.6470588 C24.8082353,47.6470588 26.4705882,49.3094118 26.4705882,51.3529412 L26.4705882,55.5882353 C26.4705882,57.6317647 24.8082353,59.2941176 22.7647059,59.2941176 L22.7647059,59.2941176 Z" id="Fill-7" fill={theme.mainColor} ></path>
            <path d="M32.2941176,23.2941176 C32.0071765,23.2941176 31.7647059,23.5365882 31.7647059,23.8235294 L31.7647059,28.0588235 C31.7647059,28.3457647 32.0071765,28.5882353 32.2941176,28.5882353 L36.5294118,28.5882353 C36.8163529,28.5882353 37.0588235,28.3457647 37.0588235,28.0588235 L37.0588235,23.8235294 C37.0588235,23.5365882 36.8163529,23.2941176 36.5294118,23.2941176 L32.2941176,23.2941176 Z M36.5294118,31.7647059 L32.2941176,31.7647059 C30.2505882,31.7647059 28.5882353,30.1023529 28.5882353,28.0588235 L28.5882353,23.8235294 C28.5882353,21.78 30.2505882,20.1176471 32.2941176,20.1176471 L36.5294118,20.1176471 C38.5729412,20.1176471 40.2352941,21.78 40.2352941,23.8235294 L40.2352941,28.0588235 C40.2352941,30.1023529 38.5729412,31.7647059 36.5294118,31.7647059 L36.5294118,31.7647059 Z" id="Fill-9" fill={theme.mainColor} ></path>
            <path d="M32.2941176,37.0588235 C32.0071765,37.0588235 31.7647059,37.3012941 31.7647059,37.5882353 L31.7647059,41.8235294 C31.7647059,42.1104706 32.0071765,42.3529412 32.2941176,42.3529412 L36.5294118,42.3529412 C36.8163529,42.3529412 37.0588235,42.1104706 37.0588235,41.8235294 L37.0588235,37.5882353 C37.0588235,37.3012941 36.8163529,37.0588235 36.5294118,37.0588235 L32.2941176,37.0588235 Z M36.5294118,45.5294118 L32.2941176,45.5294118 C30.2505882,45.5294118 28.5882353,43.8670588 28.5882353,41.8235294 L28.5882353,37.5882353 C28.5882353,35.5447059 30.2505882,33.8823529 32.2941176,33.8823529 L36.5294118,33.8823529 C38.5729412,33.8823529 40.2352941,35.5447059 40.2352941,37.5882353 L40.2352941,41.8235294 C40.2352941,43.8670588 38.5729412,45.5294118 36.5294118,45.5294118 L36.5294118,45.5294118 Z" id="Fill-11" fill={theme.mainColor} ></path>
            <path d="M32.2941176,50.8235294 C32.0071765,50.8235294 31.7647059,51.066 31.7647059,51.3529412 L31.7647059,55.5882353 C31.7647059,55.8751765 32.0071765,56.1176471 32.2941176,56.1176471 L36.5294118,56.1176471 C36.8163529,56.1176471 37.0588235,55.8751765 37.0588235,55.5882353 L37.0588235,51.3529412 C37.0588235,51.066 36.8163529,50.8235294 36.5294118,50.8235294 L32.2941176,50.8235294 Z M36.5294118,59.2941176 L32.2941176,59.2941176 C30.2505882,59.2941176 28.5882353,57.6317647 28.5882353,55.5882353 L28.5882353,51.3529412 C28.5882353,49.3094118 30.2505882,47.6470588 32.2941176,47.6470588 L36.5294118,47.6470588 C38.5729412,47.6470588 40.2352941,49.3094118 40.2352941,51.3529412 L40.2352941,55.5882353 C40.2352941,57.6317647 38.5729412,59.2941176 36.5294118,59.2941176 L36.5294118,59.2941176 Z" id="Fill-13" fill={theme.mainColor} ></path>
            <path d="M46.0588235,23.2941176 C45.7718824,23.2941176 45.5294118,23.5365882 45.5294118,23.8235294 L45.5294118,28.0588235 C45.5294118,28.3457647 45.7718824,28.5882353 46.0588235,28.5882353 L50.2941176,28.5882353 C50.5810588,28.5882353 50.8235294,28.3457647 50.8235294,28.0588235 L50.8235294,23.8235294 C50.8235294,23.5365882 50.5810588,23.2941176 50.2941176,23.2941176 L46.0588235,23.2941176 Z M50.2941176,31.7647059 L46.0588235,31.7647059 C44.0152941,31.7647059 42.3529412,30.1023529 42.3529412,28.0588235 L42.3529412,23.8235294 C42.3529412,21.78 44.0152941,20.1176471 46.0588235,20.1176471 L50.2941176,20.1176471 C52.3376471,20.1176471 54,21.78 54,23.8235294 L54,28.0588235 C54,30.1023529 52.3376471,31.7647059 50.2941176,31.7647059 L50.2941176,31.7647059 Z" id="Fill-15" fill={theme.mainColor} ></path>
            <path d="M46.0588235,37.0588235 C45.7718824,37.0588235 45.5294118,37.3012941 45.5294118,37.5882353 L45.5294118,41.8235294 C45.5294118,42.1104706 45.7718824,42.3529412 46.0588235,42.3529412 L50.2941176,42.3529412 C50.5810588,42.3529412 50.8235294,42.1104706 50.8235294,41.8235294 L50.8235294,37.5882353 C50.8235294,37.3012941 50.5810588,37.0588235 50.2941176,37.0588235 L46.0588235,37.0588235 Z M50.2941176,45.5294118 L46.0588235,45.5294118 C44.0152941,45.5294118 42.3529412,43.8670588 42.3529412,41.8235294 L42.3529412,37.5882353 C42.3529412,35.5447059 44.0152941,33.8823529 46.0588235,33.8823529 L50.2941176,33.8823529 C52.3376471,33.8823529 54,35.5447059 54,37.5882353 L54,41.8235294 C54,43.8670588 52.3376471,45.5294118 50.2941176,45.5294118 L50.2941176,45.5294118 Z" id="Fill-17" fill={theme.mainColor} ></path>
            <path d="M46.0588235,50.8235294 C45.7718824,50.8235294 45.5294118,51.066 45.5294118,51.3529412 L45.5294118,55.5882353 C45.5294118,55.8751765 45.7718824,56.1176471 46.0588235,56.1176471 L50.2941176,56.1176471 C50.5810588,56.1176471 50.8235294,55.8751765 50.8235294,55.5882353 L50.8235294,51.3529412 C50.8235294,51.066 50.5810588,50.8235294 50.2941176,50.8235294 L46.0588235,50.8235294 Z M50.2941176,59.2941176 L46.0588235,59.2941176 C44.0152941,59.2941176 42.3529412,57.6317647 42.3529412,55.5882353 L42.3529412,51.3529412 C42.3529412,49.3094118 44.0152941,47.6470588 46.0588235,47.6470588 L50.2941176,47.6470588 C52.3376471,47.6470588 54,49.3094118 54,51.3529412 L54,55.5882353 C54,57.6317647 52.3376471,59.2941176 50.2941176,59.2941176 L50.2941176,59.2941176 Z" id="Fill-19" fill={theme.mainColor} ></path>
            <path d="M67.2352941,37.1622706 C66.3575294,37.1622706 65.6470588,36.4507412 65.6470588,35.5740353 C65.6470588,34.8656824 65.6237647,34.1541529 65.5761176,33.4595647 C65.2436471,28.4915647 63.6956471,23.6146235 61.1025882,19.3560353 C60.6462353,18.6063882 60.8834118,17.6290941 61.6330588,17.1727412 C62.3816471,16.7153294 63.3589412,16.9535647 63.8163529,17.7032118 C66.6741176,22.3969765 68.3788235,27.7715647 68.7451765,33.2456824 C68.7970588,34.0101529 68.8235294,34.7947412 68.8235294,35.5740353 C68.8235294,36.4507412 68.1130588,37.1622706 67.2352941,37.1622706" id="Fill-21" fill={theme.secondaryColor} ></path>
            <path d="M34.4117647,69.9858 C15.4376471,69.9858 0,54.5492118 0,35.5740353 C0,29.2634471 1.73117647,23.0830941 5.00717647,17.7032118 C5.46458824,16.9546235 6.44188235,16.7163882 7.19047059,17.1727412 C7.94011765,17.6290941 8.17729412,18.6063882 7.72094118,19.3560353 C4.74776471,24.2382706 3.17647059,29.8458 3.17647059,35.5740353 C3.17647059,52.7979176 17.1889412,66.8093294 34.4117647,66.8093294 C45.0211765,66.8093294 54.8152941,61.4929765 60.6102353,52.5882706 C61.0888235,51.8545059 62.0714118,51.6438 62.8083529,52.1245059 C63.5431765,52.6020353 63.7507059,53.5867412 63.2731765,54.3215647 C56.8884706,64.1305059 46.1001176,69.9858 34.4117647,69.9858" id="Fill-23" fill={theme.secondaryColor} ></path>
            <path d="M66.1764706,42.3529412 C64.7174118,42.3529412 63.5294118,43.5398824 63.5294118,45 C63.5294118,46.4601176 64.7174118,47.6470588 66.1764706,47.6470588 C67.6355294,47.6470588 68.8235294,46.4601176 68.8235294,45 C68.8235294,43.5398824 67.6355294,42.3529412 66.1764706,42.3529412 M66.1764706,50.8235294 C62.9650588,50.8235294 60.3529412,48.2103529 60.3529412,45 C60.3529412,41.7896471 62.9650588,39.1764706 66.1764706,39.1764706 C69.3878824,39.1764706 72,41.7896471 72,45 C72,48.2103529 69.3878824,50.8235294 66.1764706,50.8235294" id="Fill-25" fill={theme.secondaryColor} ></path>
            <path d="M4.76470588,63 C4.76470588,64.1689412 3.816,65.1176471 2.64705882,65.1176471 C1.47811765,65.1176471 0.529411765,64.1689412 0.529411765,63 C0.529411765,61.8310588 1.47811765,60.8823529 2.64705882,60.8823529 C3.816,60.8823529 4.76470588,61.8310588 4.76470588,63" id="Fill-27" fill={theme.secondaryColor} ></path>
            <path d="M14.2941176,3.17647059 L12.7058824,3.17647059 L12.7058824,1.58823529 C12.7058824,0.711529412 11.9954118,0 11.1176471,0 C10.2398824,0 9.52941176,0.711529412 9.52941176,1.58823529 L9.52941176,3.17647059 L7.94117647,3.17647059 C7.06341176,3.17647059 6.35294118,3.888 6.35294118,4.76470588 C6.35294118,5.64141176 7.06341176,6.35294118 7.94117647,6.35294118 L9.52941176,6.35294118 L9.52941176,7.94117647 C9.52941176,8.81788235 10.2398824,9.52941176 11.1176471,9.52941176 C11.9954118,9.52941176 12.7058824,8.81788235 12.7058824,7.94117647 L12.7058824,6.35294118 L14.2941176,6.35294118 C15.1718824,6.35294118 15.8823529,5.64141176 15.8823529,4.76470588 C15.8823529,3.888 15.1718824,3.17647059 14.2941176,3.17647059" id="Fill-29" fill={theme.secondaryColor} ></path>
          </g>
        </g>
      </g>
    </svg>
  );
  /* eslint-enable */
}

SvgCellphoneStars.propTypes = {
  theme: PropTypes.object.isRequired,
};

export default withTheme(SvgCellphoneStars);
