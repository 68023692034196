import { autobind } from 'core-decorators';
import BkmdApi from './bkmdApi';
import ServicesApi from './services';

const SERVICE_NAME = 'feedbackSurvey';

@autobind
export default class FeedbackSurveyApi extends BkmdApi {
  /**
   * A fetch object that points to the server
   * @param fetch
   */
  constructor(fetch) {
    super(fetch, '/feedbackSurvey/');
    this.servicesApi = new ServicesApi(fetch);
  }

  updateReview(token, data) {
    return this.post(`review/${token}`, { token, review: data });
  }

  getReviewProcessByToken(token) {
    return this.get(`review/${token}`);
  }

  getAllReviews(skip, limit, search, sort, sortAscending, options) {
    return this.servicesApi.callSecure(SERVICE_NAME, 'getAllReviews', {
      skip,
      limit,
      search,
      sort,
      sortAscending,
      options,
    });
  }

  createReview(appointment) {
    return this.servicesApi.callSecure(SERVICE_NAME, 'boCreateAppointmentReview', { appointment });
  }

  updateStatus(token, status) {
    return this.servicesApi.callSecure(SERVICE_NAME, 'updateReviewStatus', { token, status });
  }

  saveReviewText(token, reviewText) {
    return this.servicesApi.callSecure(SERVICE_NAME, 'saveReviewText', {
      token,
      reviewText,
    });
  }

  getAllReviewsShown(npi, locationId) {
    return this.get(`review/getAllShown/${npi}/${locationId}`);
  }

  getRandomReview(npi) {
    return this.get(`review/getRandom/${npi}`);
  }
}
