import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { compose, withPropsOnChange, getContext, withHandlers, defaultProps } from 'recompose';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { member } from '../../../../../../../store/member/selectors';
import { getDomain } from '../../../../../../../store/feature/selectors';
import * as NpiType from '../../../../../../../model/enum/npiType';
import {
  createToggleState,
  createToggleStatePropTypes,
} from '../../../../../../../components/recompose/toggleStateComponent';

import BookingButton from '../../../components/bookButton/bookButton';
import { messages } from '../../../../../../../messages/searchDoctorResults/messages';
import DoctorLogo from '../../../../../../../components/doctorImage/doctorLogo';
import Rating from '../../../../../../../components/doctorCard/sections/rating/rating';
import DoctorLocation from '../../../../../../../components/doctorCard/doctorLocation';
import ResultCard from '../../../../../../../storybook/src/components/organisms/ResultCard/ResultCard';
import displayProvider from '../../../../../../../utils/displayProvider';
import withFeatureFlag from '../../../../../../../utils/featureFlags/withFeatureFlag';
import Formatter from '../../../../../../../utils/formatter';
import Availability from '../../../../../../../storybook/src/components/organisms/Availability/Availability';
import Text from '../../../../../../../storybook/src/components/atoms/Text/Text';
import SvgSmartphone from '../../../../../../../assets/svg/smartphone';
import NetworkStatusWithFetch from '../../../../../../../components/doctorCard/sections/network/networkStatusWithFetch';
import ShareDoctorModal from '../../../doctorDetails/share/shareDoctorModal';
import { openDoctorDetails } from '../../../routingActions';
import Button from '../../../../../../../storybook/src/components/atoms/Button/Button';

const MIN_RATING_COUNT = 3;

function DoctorCard(props) {
  const {
    member,
    provider,
    providerDisplayName,
    taxonomyName,
    onBookAppointmentClicked,
    shareModalOn,
    shareModalHide,
    onShareClick,
    showShare,
    clientLocation,
    onProviderDetailsClick,
    onShareSuccess,
    onBookSuccess,
    useBooleanTelehealth,
  } = props;
  const { npi, closestLocation, rating, ratingCount } = provider;
  const { officeName } = closestLocation;

  let telehealth = false;
  if (closestLocation.implementationSpecificFields) {
    telehealth = useBooleanTelehealth
      ? closestLocation.implementationSpecificFields.telehealth
      : closestLocation.implementationSpecificFields.telehealth === 'Y';
  }

  return (
    <ResultCard
      onClick={onProviderDetailsClick}
      showLocation
      addressTitle={Formatter.officeName(officeName)}
      address={Formatter.addressFormat(closestLocation)}
      resultMainText={providerDisplayName}
      resultSecondaryText={taxonomyName}
      telehealth={telehealth}
      resultImg={<DoctorLogo provider={provider} containerClass="small" />}
      primaryActionBtn={
        <BookingButton
          onBookAppointmentClicked={() => onBookAppointmentClicked(provider, closestLocation)}
          pickedLocation={closestLocation}
          npiType={NpiType.INDIVIDUAL}
          targetNpi={npi}
          targetLocationAddress={Formatter.addressFormat(closestLocation)}
          targetLocationGeo={closestLocation.geo}
          targetLocationPhone={closestLocation.phoneNumber}
          member={member}
          onShowBookingDialogSuccess={onBookSuccess}
          ariaLiveOff
        />
      }
      secondaryActionBtn={
        showShare ? (
          <Button bgColor="white" onClick={onShareClick}>
            <i className="i-va-fix-2 icon-ext-link" />
            &nbsp;
            <FormattedMessage {...messages.share} />
          </Button>
        ) : null
      }
      resultLeftDetail={
        <NetworkStatusWithFetch
          externalMemberId={member.domainMemberId}
          externalMemberDomain={member.domain}
          location={closestLocation}
          npi={npi}
          block
          popoverPlacement="right"
        />
      }
    >
      <ShareDoctorModal
        onFinish={onShareSuccess}
        isOpen={shareModalOn}
        handleClose={shareModalHide}
        provider={provider}
        providerLocationId={closestLocation.id}
      />
      <DoctorLocation doctorLocation={closestLocation} clientLocation={{ geo: clientLocation }} />
      {ratingCount >= MIN_RATING_COUNT && <Rating rating={rating} />}
      <Availability
        provider={provider}
        location={closestLocation}
        shortText
        size="tiny"
        bookingHorizonClassName="text-12"
      />
      <Text className="result-phone-number visible-md visible-lg" align="left" size="14px">
        <SvgSmartphone />
        {Formatter.phoneNumber(closestLocation.phoneNumber)}
      </Text>
      {_.get(provider, 'gender') ? (
        <input type="hidden" value={_.get(provider, 'gender')} className="hidden hidden-gender" />
      ) : null}
    </ResultCard>
  );
}

DoctorCard.propTypes = {
  ...createToggleStatePropTypes('shareModal'),
  member: PropTypes.object.isRequired,
  taxonomyName: PropTypes.string.isRequired,
  providerDisplayName: PropTypes.string.isRequired,
  provider: PropTypes.object.isRequired,
  onBookAppointmentClicked: PropTypes.func,
  onShareClick: PropTypes.func,
  showShare: PropTypes.bool,
  clientLocation: PropTypes.object.isRequired,
  chosenCode: PropTypes.array.isRequired,
  onProviderDetailsClick: PropTypes.func,
};

DoctorCard.defaultProps = {
  showShare: false,
  onProviderDetailsClick: _.noop,
  onShareClick: _.noop,
  onBookAppointmentClicked: _.noop,
};

export default compose(
  defaultProps({
    onShareSuccess: _.noop,
    onBookSuccess: _.noop,
    onProviderDetailsClick: _.noop,
  }),
  createToggleState('shareModal'),
  getContext({ paths: PropTypes.object.isRequired }),
  connect(
    (state) => ({
      member: { domain: getDomain(state), ...member(state) },
    }),
    (
      dispatch,
      {
        provider: {
          npi,
          ranking,
          closestLocation: { id: locationId },
        },
        chosenCode,
        paths,
      },
    ) => ({
      openDoctorDetails() {
        dispatch(openDoctorDetails(npi, locationId, paths, chosenCode, ranking));
      },
    }),
  ),
  withFeatureFlag({
    key: 'premera.useBooleanTelehealth',
    prop: 'useBooleanTelehealth',
    defaultValue: false,
  }),
  withPropsOnChange(
    ['provider', 'chosenCode'],
    ({ provider: { taxonomiesCodes, sources, firstName, lastName, suffix }, chosenCode }) => ({
      taxonomyName: Formatter.taxonomy(sources, taxonomiesCodes, chosenCode),
      providerDisplayName: displayProvider(firstName, lastName, suffix),
    }),
  ),
  withHandlers({
    onShareClick: ({ shareModalShow }) => (e) => {
      shareModalShow();
      e.stopPropagation();
    },
    onShareSuccess: ({ onShareSuccess, provider }) => () => {
      onShareSuccess(provider);
    },
    onBookSuccess: ({ onBookSuccess, provider }) => (entityId, bookingType) => {
      onBookSuccess(provider, bookingType, entityId);
    },
    onProviderDetailsClick: ({
      openDoctorDetails,
      provider: {
        closestLocation: { id: locationId },
        ...providerData
      },
      onProviderDetailsClick,
    }) => () => {
      openDoctorDetails();
      onProviderDetailsClick(providerData, locationId);
    },
  }),
)(DoctorCard);
