import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '../../storybook/src/utils/theme';

function SvgSpeechBubbleSmile({ theme }) {
  /* eslint-disable */
  return (

    <svg aria-hidden="true" focusable="false" width="72px" height="56px" viewBox="0 0 72 56" version="1.1" className="svg-icon svg-speech-bubble-smile">
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Comments-1" transform="translate(-152.000000, -140.000000)">
          <g id="Page-1" transform="translate(152.000000, 140.000000)">
            <path d="M59.8662,37.7141547 L71.5788717,52.7724767 C72.600701,54.086185 71.664521,56.0004 70.0002,56.0004 L8.9342,56.0004 C4.0156305,56.0004 0.0002,51.9849695 0.0002,47.0664 L0.0002,8.9344 C0.0002,4.0158305 4.0156305,0.0004 8.9342,0.0004 L50.9342,0.0004 C55.8511689,0.0004 59.8662,4.01622995 59.8662,8.9344 L59.8662,37.7141547 Z M56.2875283,39.6283233 C56.0144566,39.2772504 55.8662,38.84517 55.8662,38.4004 L55.8662,8.9344 C55.8662,6.22519082 53.6418517,4.0004 50.9342,4.0004 L8.9342,4.0004 C6.2247695,4.0004 4.0002,6.2249695 4.0002,8.9344 L4.0002,47.0664 C4.0002,49.7758305 6.2247695,52.0004 8.9342,52.0004 L65.9107833,52.0004 L56.2875283,39.6283233 Z" id="Fill-1" fill={theme.mainColor} fillRule="nonzero"></path>
            <path d="M26,28 C24.896,28 24,27.104 24,26 C24,24.898 23.102,24 22,24 C20.898,24 20,24.898 20,26 C20,27.104 19.104,28 18,28 C16.896,28 16,27.104 16,26 C16,22.692 18.692,20 22,20 C25.308,20 28,22.692 28,26 C28,27.104 27.104,28 26,28" id="Fill-5" fill={theme.mainColor} ></path>
            <path d="M42,28 C40.896,28 40,27.104 40,26 C40,24.898 39.102,24 38,24 C36.898,24 36,24.898 36,26 C36,27.104 35.104,28 34,28 C32.896,28 32,27.104 32,26 C32,22.692 34.692,20 38,20 C41.308,20 44,22.692 44,26 C44,27.104 43.104,28 42,28" id="Fill-7" fill={theme.mainColor} ></path>
            <path d="M30,38 C27.83,38 25.788,36.932 24.538,35.146 C23.906,34.24 24.126,32.994 25.032,32.36 C25.938,31.732 27.184,31.95 27.818,32.854 C28.82,34.288 31.18,34.288 32.182,32.854 C32.818,31.95 34.064,31.732 34.968,32.36 C35.874,32.994 36.094,34.24 35.462,35.146 C34.212,36.932 32.17,38 30,38" id="Fill-9" fill={theme.mainColor} ></path>
            <path d="M22,32 C22,33.104 21.104,34 20,34 C18.896,34 18,33.104 18,32 C18,30.896 18.896,30 20,30 C21.104,30 22,30.896 22,32" id="Fill-11" fill={theme.secondaryColor} ></path>
            <path d="M42,32 C42,33.104 41.104,34 40,34 C38.896,34 38,33.104 38,32 C38,30.896 38.896,30 40,30 C41.104,30 42,30.896 42,32" id="Fill-13" fill={theme.secondaryColor} ></path>
          </g>
        </g>
      </g>
    </svg>
  );
  /* eslint-enable */
}

SvgSpeechBubbleSmile.propTypes = {
  theme: PropTypes.object.isRequired,
};

export default withTheme(SvgSpeechBubbleSmile);
