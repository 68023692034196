import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { autobind } from 'core-decorators';
import { defineMessages, FormattedMessage } from 'react-intl';

// TODO: Move to atoms :)
import { SpinnerButton } from 'Components/ui/spinner';

import './ProviderList.less';
import * as BookingType from 'Model/enum/bookingType';

import FullProviderCard from '../FullProviderCard/FullProviderCard';

// TODO: change to see more? or different component component
const messages = defineMessages({
  text: {
    defaultMessage: 'See More Doctors',
    id: 'common.seeMoreButton.text',
  },
});

function DefaultLoadMoreButton(onClick, text, isLoading) {
  const buttonText = _.isObject(text) ? <FormattedMessage {...text} /> : text;
  return (
    <SpinnerButton
      onClick={onClick}
      className="btn btn-inline-link font-color-brand-link"
      isLoading={isLoading}
    >
      <span className="text-underline">{buttonText}</span>
    </SpinnerButton>
  );
}
@autobind
class ProviderList extends React.PureComponent {
  static propTypes = {
    providers: PropTypes.array.isRequired,
    distances: PropTypes.object,
    closestLocations: PropTypes.object,
    onProviderClick: PropTypes.func,
    plan: PropTypes.string,
    chosenTaxonomyCodes: PropTypes.array,
    showDetails: PropTypes.bool,
    showBook: PropTypes.bool,
    showShare: PropTypes.bool,
    onBookClick: PropTypes.func.isRequired,
    renderActions: PropTypes.func,
    onlyShowCurrentLocation: PropTypes.bool,
    bookingType: PropTypes.string,
    className: PropTypes.string,
    staticLocation: PropTypes.bool,
    memberContext: PropTypes.string,
    hideNetworkStatus: PropTypes.bool,
    isLoading: PropTypes.bool.isRequired,
    loadMoreClick: PropTypes.func,
    renderLoadMoreButton: PropTypes.func,
    miniCards: PropTypes.bool,
    horizontal: PropTypes.bool,
    disableSplit: PropTypes.bool,
    chunkSize: PropTypes.number,
    showDirection: PropTypes.bool,
    showNextAvailableSlot: PropTypes.bool,
  };

  static defaultProps = {
    closestLocations: undefined,
    renderActions: undefined,
    plan: undefined,
    chosenTaxonomyCodes: undefined,
    showDetails: false,
    showBook: true,
    showShare: false,
    showDirection: false,
    onProviderClick: undefined,
    onlyShowCurrentLocation: false,
    bookingType: BookingType.INSTANT,
    className: null,
    staticLocation: false,
    distances: undefined,
    memberContext: '',
    hideNetworkStatus: false,
    loadMoreClick: undefined,
    renderLoadMoreButton: DefaultLoadMoreButton,
    chunkSize: 3,
    miniCards: false,
    horizontal: false,
    disableSplit: false,
    showNextAvailableSlot: false,
  };

  renderProvidersChunk(providers) {
    const {
      plan,
      distances,
      closestLocations,
      onProviderClick,
      chosenTaxonomyCodes,
      showDetails,
      showBook,
      showShare,
      onBookClick,
      onlyShowCurrentLocation,
      bookingType,
      renderActions,
      staticLocation,
      memberContext,
      hideNetworkStatus,
      showDirection,
      showNextAvailableSlot,
      miniCards,
      horizontal,
    } = this.props;
    return (
      <div
        key={_.map(providers, 'npi').join('_')}
        className={`equal-flex-cards md-blocks ${horizontal ? 'horizontal-list' : ''}`}
      >
        {_.map(providers, provider => (
          <FullProviderCard
            provider={provider}
            key={provider.npi}
            distances={distances}
            closestLocations={closestLocations}
            chosenTaxonomyCodes={chosenTaxonomyCodes}
            staticLocation={staticLocation}
            onProviderClick={onProviderClick}
            showBook={showBook}
            showShare={showShare}
            showDetails={showDetails}
            showDirection={showDirection}
            plan={plan}
            onBookClick={onBookClick}
            bookingType={bookingType}
            hideNetworkStatus={hideNetworkStatus}
            onlyShowCurrentLocation={onlyShowCurrentLocation}
            renderActions={renderActions}
            memberContext={memberContext}
            miniCard={miniCards}
            horizontal={horizontal}
            showNextAvailableSlot={showNextAvailableSlot}
          />
        ))}
      </div>
    );
  }

  renderProviders(providersChunks) {
    return _.map(providersChunks, this.renderProvidersChunk);
  }

  render() {
    const {
      providers,
      className,
      renderLoadMoreButton,
      loadMoreClick,
      isLoading,
      chunkSize,
      disableSplit,
    } = this.props;
    const providersChunks = disableSplit ? [providers] : _.chunk(providers, chunkSize);
    return (
      <div className="row no-margin">
        <div className="col-md-12 col-lg-10 col-lg-offset-1 no-padding">
          <div className="margin-top-30">
            <div className={classNames('provider-list-wrap', className)}>
              {this.renderProviders(providersChunks)}
            </div>
            {loadMoreClick && !_.isEmpty(providersChunks) && (
              <div className="text-center margin-bottom-20">
                {renderLoadMoreButton(loadMoreClick, messages.text, isLoading)}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default ProviderList;
