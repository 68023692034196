import React from 'react';
import { compose } from 'recompose';
import withFeatureFlag from '../../utils/featureFlags/withFeatureFlag';
import DateInputNew from './cleanDateInputNew';
import DateInputOld from './cleanDateInputOld';

export const defaultDateFormat = 'MM/DD/YYYY';

export default compose(
  withFeatureFlag({
    key: 'dateInputNew',
    prop: 'useNewDateInput',
    defaultValue: false,
  }),
)(props => {
  /* eslint-disable no-unused-vars */
  const {
    flagContext,
    dispatch,
    useNewDateInput,
    ...rest
  } = props;
  /* eslint-enable no-unused-vars */
  return useNewDateInput ? <DateInputNew {...rest} /> : <DateInputOld {...rest} />;
});
