/**
 * Created by moran on 7/31/17.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import _ from 'lodash';

import analytics from '../../store/tools/analytics/analyticsHoc';
import CleanTextArea from './cleanTextArea';

/**
 * Component that handles JSON objects for display
 */
@autobind
class CleanJSONInput extends React.Component {
  static propTypes = {
    value: PropTypes.object.isRequired,
    spacePadding: PropTypes.bool,
    onChange: PropTypes.func,
    readOnly: PropTypes.bool,
    maxRows: PropTypes.number,
  };

  static defaultProps = {
    spacePadding: false,
    readOnly: false,
    onChange: _.noop,
    maxRows: undefined,
  };

  onChange(text) {
    this.props.onChange(JSON.parse(text));
  }

  format(val) {
    if (_.isString(val)) return val;

    if (this.props.spacePadding) {
      return JSON.stringify(val, null, 4);
    }

    return JSON.stringify(val);
  }

  render() {
    return (<CleanTextArea
      onChange={this.onChange}
      readOnly={this.props.readOnly}
      value={this.format(this.props.value)}
      maxRows={this.props.maxRows}
    />);
  }
}

export default analytics(CleanJSONInput, false);
