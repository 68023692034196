/**
 * Created by matan on 9/4/16.
 */
import _ from 'lodash';

import { setCache } from './actions';

function createPayload(cache, metadata, skip, limit) {
  return {
    metadata,
    // if skip + limit is NaN replace with undefined, resulting in a slice with not upper limit
    data: _.slice(cache, skip, _.defaultTo(skip + limit))
  };
}

/**
 * Cache searching middleware.
 * Middleware allows caching of some server responses by search key to avoid unnecessary querying.
 * Middleware looks for the following params under the meta.cacheSearch in the action:
 * - searchFunc - The actions function that sends a query to the server. function should receive
 * (query, limit, skip, sorting) in that order, and should return a promise.
 * - searchKey - UID to look up the appropriate cache, also used to save the updated cache.
 * if non provided results will not be cached
 * - currentQuery - The current query to pass to the searchFunc if necessary.
 * used to check if a new search is required or not (along with sorting).
 * * sorting - Sorting object to pass to the searchFunc if necessary.
 * used to check if a new search is required or not (along with currentQuery).
 * - ignoreCache - Dump the current cache and force sending a new requests to the server.
 * - limit - Total amount of objects to include in the response array. default is non
 * - skip - Index to start counting from in relation to the limit. default is 0.
 * - continueWith - A function to invoke with the middleware promise result before passing the
 * results to the reducer. The result of the function given will be passed to the reducer.
 *
 * The server respsonse must have data field with the array, and metadata: { count }
 */
export default ({ dispatch, getState }) => next => action => {
  const metaInfo = _.get(action, 'meta.cacheSearch', {});
  const {
    searchFunc,
    searchKey,
    currentQuery,
    sorting,
    ignoreCache = false,
    continueWith = _.identity
  } = metaInfo;
  let { skip = 0, limit } = metaInfo;

  // Check if the action is targeting this middleware
  if (!searchFunc || !currentQuery) {
    return next(action);
  }

  // Find previous search data, if exists
  const { query: previousQuery, cache, metadata } =
    _.get(getState().cacheSearch, searchKey, {});

  // If the search doesn't match the older search or we are forced to query the server, this is
  // a new search
  const newSearch = ignoreCache || !_.isEqual({ query: currentQuery, sorting }, previousQuery);
  if (!newSearch) {
    // skip max value should be the amount of available results
    skip = Math.min(skip, metadata.count);
    // If not limit given, set the limit to total amount of results available
    limit = Math.min(_.defaultTo(limit, Number.POSITIVE_INFINITY), metadata.count - skip);
    const missingData = _.some(_.slice(cache, skip, skip + limit), _.isNil) ||
      skip + limit > cache.length;

    // If there is no missing data in the cache, receive the required data locally
    if (!missingData) {
      return next({
        type: action.type,
        payload: {
          data: action.data,
          promise: Promise.resolve(createPayload(cache, metadata, skip, limit)).then(continueWith)
        }
      });
    }
  }

  // Drop current action and send a new, 'translated' action down the middleware pipeline.
  return next({
    type: action.type,
    meta: _.omit(action.meta, 'cacheSearch'),
    payload: {
      data: action.data,
      promise: Promise.resolve(searchFunc(currentQuery, limit, skip, sorting))
        .then(response => {
          // NOTE: We are using the variables here as they are saved on the current closure,
          // This results in a las-response-wins cache management
          // If new search or the amount of available data has changed, dump current cache
          let updatedCache = (newSearch || metadata.count !== response.metadata.count) ?
            [] : cache;
          if (!_.isEmpty(response.data)) {
            updatedCache = updatedCache.asMutable ? updatedCache.asMutable() : updatedCache;
            // Splice condenses the array by default, we want to keep it sparse. add the first
            // object manually
            updatedCache[skip] = _.head(response.data);
            updatedCache.splice(skip + 1, response.data.length - 1, ..._.tail(response.data));
          }

          // Query was successful, save new data to history
          if (!_.isNil(searchKey)) {
            dispatch(setCache(
              searchKey,
              { query: currentQuery, sorting },
              response.metadata,
              updatedCache));
          }
          return createPayload(updatedCache, response.metadata, skip, limit);
        })
        .then(continueWith)
    }
  });
};
