import React from 'react';
import noop from 'lodash/noop';
import { compose, withHandlers } from 'recompose';
import PropTypes from 'prop-types';
import './Chip.less';

function Chip({ text, onClose, onCloseClick }) {
  return (
    <div className="chip">
      {text}
      {onClose ? (
        <div className="chip-close-button" onClick={onCloseClick}>
          <i className="icon-x-2" />
        </div>
      ) : null}
    </div>
  );
}

Chip.propTypes = {
  text: PropTypes.string.isRequired,
  onCloseClick: PropTypes.func,
  onClose: PropTypes.func,
};

Chip.defaultProps = {
  onClose: undefined,
  onCloseClick: noop,
};

const enhanced = compose(
  withHandlers({
    onCloseClick: ({ onClose, text }) => () => {
      onClose(text);
    },
  }),
)(Chip);

export default enhanced;
