import _ from 'lodash';

import commonMessages from '../../store/intl/commonMessages';
import messages from './placeholderMessages';

export default function buildPlaceholder(searchTypes, intl, short = false) {
  const strings = _.map(searchTypes, type => intl.formatMessage(messages[type]));
  const orStr = intl.formatMessage(commonMessages.or);
  const searchBy = short ? '' : intl.formatMessage(messages.searchBy);

  if (strings.length === 1) {
    return `${searchBy} ${strings[0]}`.trim();
  }

  if (strings.length === 2) {
    return `${searchBy} ${strings[0]} ${orStr} ${strings[1]}`.trim();
  }

  return `${_.join(_.initial(strings), ', ')} ${orStr} ${_.last(strings)}`.trim();
}
