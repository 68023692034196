/**
 * Created by guyavraham on 04/07/2017.
 */

import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { autobind } from 'core-decorators';
import { connect } from 'react-redux';
import { compose } from 'redux';

import Utils from '../../utils/util';
import typeaheadMenu from './typeaheadMenu';
import displayProvider from '../../utils/displayProvider';
import { TypeaheadContentEntered, TypeaheadNoResults } from '../../store/tools/analytics/events/typeaheadEvents';
import { focusSearchTypeaheadNow } from '../../store/ui/selectors';

import CleanTypeahead from '../form/cleanTypeahead';
import './typeahead.less';
import { getFeature } from '../../store/feature/selectors';
import withFeatureFlag from '../../utils/featureFlags/withFeatureFlag';

const noResultsArray = [{ name: 'empty' }];
const BCBS_SOURCE = 'BLUE_CROSS_BLUE_SHIELD';

const searchFreeTextShape = PropTypes.shape({
  taxonomies: PropTypes.arrayOf(
    PropTypes.shape({
      data: PropTypes.shape({
        codes: PropTypes.arrayOf(PropTypes.string),
        name: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
      }),
      meta: PropTypes.shape({
        // @TODO: remove highlight and score after we remove support for legacy freeText
        highlight: PropTypes.object,
        score: PropTypes.number,
        type: PropTypes.string,
      }),
    }),
  ),
});

@autobind
class SearchTypeahead extends React.Component {
  static defaultProps = {
    searchTerm: '',
    containerClasses: '',
    suggestions: {},
    onChange: null,
    autoFocus: false,
    focusNow: false,
    maxHeight: '300px',
    onSearchTermChanged: undefined,
    customRenderItem: undefined,
    showSpinner: false,
    location: null,
    isLoading: false,
    disabled: false,
    autoComplete: {},
    allowClearValues: false,
    maxItems: 30,
    member: {},
  };

  static propTypes = {
    maxItems: PropTypes.number,
    allowClearValues: PropTypes.bool,
    onSearchTermChanged: PropTypes.func,
    customRenderItem: PropTypes.func,
    autoComplete: searchFreeTextShape,
    searchFreeText: PropTypes.func.isRequired,
    suggestions: searchFreeTextShape.isRequired,
    showSpinner: PropTypes.bool,
    autoFocus: PropTypes.bool,
    focusNow: PropTypes.bool,
    searchTerm: PropTypes.string,
    onChange: PropTypes.func,
    containerClasses: PropTypes.string,
    placeholder: PropTypes.string.isRequired,
    maxHeight: PropTypes.string,
    location: PropTypes.object,
    isLoading: PropTypes.bool,
    disabled: PropTypes.bool,
    onlyInNetworkFeatureFlag: PropTypes.bool.isRequired,
    onlyInNetworkDomainConfig: PropTypes.bool.isRequired,
    member: PropTypes.object,
  };

  static contextTypes = {
    analytics: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.searchFreeTextDebounce = _.debounce(this.props.searchFreeText, 100).bind(this);
  }

  componentWillReceiveProps(nextProps) {
    Utils.inspectChange(this.props, nextProps, [
      { uri: 'autoComplete', action: this.onNewResults },
    ]);
  }

  onNewResults(nextProps) {
    const {
      autoComplete: { physicians, taxonomies, freeText },
    } = nextProps;
    if (_.isEmpty(taxonomies) && _.isEmpty(physicians)) {
      this.context.analytics.track(TypeaheadNoResults, { term: freeText });
    } else {
      this.context.analytics.track(TypeaheadContentEntered, {
        term: freeText,
        taxonomies: _.map(taxonomies, tx => tx.data.name),
        physicians: _.map(physicians, doctor => doctor.data.npi),
      });
    }
  }

  onInputChange(term) {
    const {
      location,
      onlyInNetworkDomainConfig,
      member,
      onlyInNetworkFeatureFlag,
    } = this.props;
    if (term.length > 1) {
      this.searchFreeTextDebounce(
        term,
        location,
        false,
        onlyInNetworkDomainConfig && onlyInNetworkFeatureFlag,
        member.memberToken,
      );
    } else {
      this.searchFreeTextDebounce(
        '',
        location,
        false,
        onlyInNetworkDomainConfig && onlyInNetworkFeatureFlag,
        member.memberToken,
      );
    }
  }

  onOptionSelection(option) {
    // When the user clicks on clear CleanTypeahead emits undefined option
    if (!_.isEmpty(option) || this.props.allowClearValues) {
      const { onSearchTermChanged, onChange } = this.props;
      if (onSearchTermChanged) onSearchTermChanged(_.get(option, 'data.fullName'));
      onChange(option);
    }
  }

  getTaxonomiesOptions(autoComplete) {
    const { taxonomies } = autoComplete;

    if (_.isEmpty(taxonomies)) return [];

    const options = _.map(taxonomies, ({ data, meta }) => ({
      value: data.name,
      name: data.name,
      data,
      meta,
    }));
    return [...options];
  }

  getClinicsOptions(autoComplete) {
    const { clinics } = autoComplete;

    if (_.isEmpty(clinics)) return [];

    const options = _.map(clinics, ({ data, meta }) => ({
      key: data.officeName,
      value: data.name,
      name: data.name,
      data,
      meta,
      className: 'profile-img-option',
    }));
    return [...options];
  }

  getOptions() {
    const combined = _.isEmpty(this.props.autoComplete)
      ? []
      : [
        ...this.getTaxonomiesOptions(this.props.autoComplete),
        ...this.getDoctorsOptions(),
        ...this.getClinicsOptions(this.props.autoComplete),
      ];
    return _.isEmpty(combined) ? noResultsArray : combined;
  }

  getDoctorsOptions() {
    const {
      autoComplete: { physicians },
    } = this.props;
    if (_.isEmpty(physicians)) return [];

    const options = _.map(physicians, ({ data, meta }) => {
      const providerDisplayName = displayProvider(data.firstName, data.lastName, data.suffix);
      const specialtyDisplayName = _.join(
        _.map(data.taxonomiesCodes, code => {
          if (_.includes(data.sources, BCBS_SOURCE)) return code.sourceDescription;
          return code.desc;
        }),
        ', ',
      );
      return {
        key: data.npi,
        name: providerDisplayName,
        info: specialtyDisplayName,
        className: 'profile-img-option',
        data,
        meta,
      };
    });

    return options;
  }

  render() {
    const {
      containerClasses,
      searchTerm,
      autoFocus,
      focusNow,
      maxHeight,
      placeholder,
      isLoading,
      disabled,
      suggestions,
      showSpinner,
      customRenderItem,
      maxItems,
      ...others
    } = this.props;
    return (
      <CleanTypeahead
        disabled={disabled}
        maxHeight={maxHeight}
        options={_.take(this.getOptions(), maxItems)}
        onInputChange={this.onInputChange}
        initialTerm={searchTerm}
        onOptionSelection={this.onOptionSelection}
        containerClasses={containerClasses}
        placeholder={placeholder}
        renderMenu={typeaheadMenu({
          suggestions: this.getTaxonomiesOptions(suggestions),
          isLoading: showSpinner,
          overrideRenderItem: customRenderItem,
        })}
        autoFocus={autoFocus}
        focusNow={focusNow}
        isLoading={isLoading}
        {...others}
      />
    );
  }
}

export default compose(
  injectIntl,
  connect(state => ({
    focusNow: focusSearchTypeaheadNow(state),
    onlyInNetworkDomainConfig: getFeature(state, 'search.onlyInNetwork', false),
  })),
  withFeatureFlag({
    key: 'premera.providers.onlyInNetwork',
    prop: 'onlyInNetworkFeatureFlag',
    defaultValue: false,
  }),
)(SearchTypeahead);
