/**
 * Created by meirshalev on 24/05/2017.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  yourNotifications: {
    defaultMessage: 'Your notifications',
    id: 'members.settings.yourNotifications',
  },
  newsTitle: {
    defaultMessage: 'News, tips and promotions',
    id: 'members.settings.newsTitle',
  },
  premeraBookmdUpdatesBody: {
    defaultMessage: 'Find out when we have something new and shiny for you to check out.',
    id: 'members.settings.premeraBookmdUpdatesBody',
  },
  allowSmsNotificationTitle: {
    defaultMessage: 'Allow SMS notification',
    id: 'members.settings.allowSmsNotificationTitle',
  },
  allowSmsNotificationBody: {
    defaultMessage: 'Opt in or out from sms communication messages',
    id: 'members.settings.allowSmsNotificationBody',
  },
  financialAndHealthUpdatesTitle: {
    defaultMessage: 'Health updates',
    id: 'members.settings.financialAndHealthUpdatesTitle',
  },
  financialAndHealthUpdatesBody: {
    defaultMessage: 'Get important care alerts to help you get the most from your benefits',
    id: 'members.settings.financialAndHealthUpdatesBody',
  },
  accountRemindersTitle: {
    defaultMessage: 'Account reminders',
    id: 'members.settings.accountRemindersTitle',
  },
  accountRemindersBody: {
    defaultMessage: 'Manage your account more efficiently with helpful reminders.',
    id: 'members.settings.accountRemindersBody',
  },
  appointmentInfoTitle: {
    defaultMessage: 'Appointment info',
    id: 'members.settings.appointmentInfoTitle',
  },
  appointmentInfoBody: {
    defaultMessage:
      'Get appointment reminders, suggestions for your appointment ' +
      'and send feedback about your experience.',
    id: 'members.settings.appointmentInfoBody',
  },
  accountSupportTitle: {
    defaultMessage: 'Account support',
    id: 'members.settings.accountSupportTitle',
  },
  accountSupportBody: {
    defaultMessage:
      'These are important notifications regarding account security and privacy, ' +
      'transactions, legal info and customer support. We need to send these sometimes, they can’t be turned off.',
    id: 'members.settings.accountSupportBody',
  },
  on: {
    defaultMessage: 'ON',
    id: 'members.settings.on',
  },
  serverErrorTitle: {
    defaultMessage: 'Hmm. Something went wrong',
    id: 'members.settings.notifications.serverErrorTitle',
  },
  serverErrorMessage: {
    defaultMessage: 'Please refresh the page and try again',
    id: 'members.settings.notifications.serverErrorMessage',
  },
  changeNotificationErrorTitle: {
    defaultMessage: 'Hmm. Something went wrong while updating notification preferences',
    id: 'members.settings.notifications.changeNotificationErrorTitle',
  },
  changeNotificationErrorMessage: {
    defaultMessage: 'Please try again',
    id: 'members.settings.notifications.changeNotificationMessage',
  },
  changeNotificationSuccessMessage: {
    defaultMessage: 'Your preferences have been updated.',
    id: 'members.settings.notifications.changeNotificationSuccessMessage',
  },
  emailLabel: {
    defaultMessage: 'Email',
    id: 'members.settings.notifications.emailLabel',
  },
  smsLabel: {
    defaultMessage: 'Text messages',
    id: 'members.settings.notifications.smsLabel',
  },
  saveYourSettings: {
    defaultMessage: 'Save your settings',
    id: 'members.settings.notifications.saveYourSettings',
  },
});
