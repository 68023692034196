import { autobind } from 'core-decorators';
import BkmdApi from './bkmdApi';
import ServicesApi from './services';

const SERVICE_NAME = 'calendar';

@autobind
class CalendarApi extends BkmdApi {
  /**
   * A fetch object that points to the server
   * @param fetch
   */
  constructor(fetch) {
    super(fetch, '/calendar/');
    this.servicesApi = new ServicesApi(fetch);
  }

  getAllCalendars() {
    return this.servicesApi.callSecure(SERVICE_NAME, 'getAllCalendars').then(res => res.data);
  }

  getAllCalendarsForClinic(skip, limit, search, sort, sortAscending) {
    return this.servicesApi.callSecure(SERVICE_NAME, 'getAllCalendarsForClinic', {
      limit,
      skip,
      search,
      sort,
      sortAscending,
    });
  }

  getAllCalendarsForProvider(skip, limit, search, sort, sortAscending) {
    return this.servicesApi.callSecure(SERVICE_NAME, 'getAllCalendarsForProvider', {
      limit,
      skip,
      search,
      sort,
      sortAscending,
    });
  }

  getCalendarById(id) {
    return this.servicesApi.callSecure(SERVICE_NAME, 'getCalendarById', { id });
  }

  updateCalendar(data) {
    return this.servicesApi.callSecure(SERVICE_NAME, 'updateCalendar', { data });
  }

  updateAllCalendars(ids, data) {
    return this.servicesApi.callSecure(SERVICE_NAME, 'updateAllCalendars', { ids, data });
  }

  deleteCalendar(id) {
    return this.servicesApi.callSecure(SERVICE_NAME, 'deleteCalendar', { id });
  }

  createCalendar(data) {
    return this.servicesApi.callSecure(SERVICE_NAME, 'createCalendar', { data });
  }

  openTimeSlotsList(list, isFromEhr = false) {
    return this.servicesApi
      .callSecure(SERVICE_NAME, 'openTimeSlotsList', { list, isFromEhr })
      .then(res => res.data);
  }

  deleteAllTimeSlotsByCalendarId(calendarId) {
    return this.servicesApi.callSecure(SERVICE_NAME, 'deleteAllTimeSlotsByCalendarId', {
      calendarId,
    });
  }

  syncCalendarEhrAppointments(calendarId) {
    return this.servicesApi.callSecure(SERVICE_NAME, 'syncCalendarEhrAppointments', {
      calendarId,
    });
  }
}

export default CalendarApi;
