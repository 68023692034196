/**
 * Created by guyavarham on 17/07/2017.
 */

import _ from 'lodash';

export default {
  currRoute: state => _.get(state, 'routing.locationBeforeTransitions'),
  currRouteQuery: state => _.get(state, 'routing.locationBeforeTransitions.query'),
  currRouteState: state => _.get(state, 'routing.locationBeforeTransitions.state'),
  currRouteQueryKey: (state, key) => _.get(state, `routing.locationBeforeTransitions.query.${key}`),
  getCurrentUrl: state => _.get(state, 'routing.locationBeforeTransitions.pathname'),
};
