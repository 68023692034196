import { Results } from '../../../../../components/wizard/flowManager';
import * as ActivationErrors from '../../../../../api/errors';

const { hasResult, resultFor, trueFor } = Results;

export const name = 'full-activation';

export const steps = {
  ENTER_PHONE_NUMBER: 'enterPhoneNumber',
  FIND_MEMBER: 'findMember',
  NOT_FOUND: 'notFound',
  SIGNUP: 'signup',
};

export const flow = {
  [steps.ENTER_PHONE_NUMBER]: {
    name: steps.ENTER_PHONE_NUMBER,
    parents: [],
    children: {
      [steps.FIND_MEMBER]: hasResult(steps.ENTER_PHONE_NUMBER),
    },
  },
  [steps.FIND_MEMBER]: {
    name: steps.FIND_MEMBER,
    parents: [steps.ENTER_PHONE_NUMBER],
    children: {
      [steps.NOT_FOUND]: resultFor(steps.FIND_MEMBER, ActivationErrors.MEMBER_NOT_FOUND),
      [steps.SIGNUP]: trueFor(steps.FIND_MEMBER),
    },
  },
  [steps.NOT_FOUND]: {
    name: steps.NOT_FOUND,
    parents: [steps.FIND_MEMBER],
    children: {},
  },
  [steps.SIGNUP]: {
    name: steps.SIGNUP,
    parents: [steps.FIND_MEMBER],
    children: {},
  },
};
