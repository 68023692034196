/**
 * Created by noahguld on 22/06/2017.
 */
import React from 'react';
import PropTypes from 'prop-types';

import CleanInput from './cleanInput';

export default function CleanRestrictedInput(type, regex, replaceWith = '') {
  return class extends React.Component {
    /**
     * Properties
     */
    static
    propTypes = {
      /**
       * Callback function that will be called when the phone number is changes
       */
      onChange: PropTypes.func,

      /**
       * Callback function that will be called when the loses focus
       */
      onBlur: PropTypes.func,

      /**
       * The value of the component
       */
      value: PropTypes.string
    };

    static
    defaultProps = {
      value: '',
      onChange: undefined,
      onBlur: undefined,
    };

    constructor(props) {
      super(props);

      this.state = {
        internalValue: props.value
      };

      this._wrapOutputEvent = this._wrapOutputEvent.bind(this);
      this.handleNumberChange = this.handleNumberChange.bind(this);
      this.handleBlur = this.handleBlur.bind(this);
      this.updateRef = this.updateRef.bind(this);
    }

    /**
     * Updates the internal state
     * @param nextProps
     */
    componentWillReceiveProps(nextProps) {
      this.setState({ internalValue: nextProps.value });
    }

    /**
     * Wraps outputting events such as onChange so the output will be a simple string
     * Can add num parse functionality here
     */
    _wrapOutputEvent(innerEvent, event) {
      if (innerEvent) {
        innerEvent(event.target.value.replace(regex, replaceWith));
      }
    }

    handleNumberChange(event) {
      this._wrapOutputEvent(this.props.onChange, event);
    }

    handleBlur(event) {
      this._wrapOutputEvent(this.props.onBlur, event);
    }

    updateRef(clean) {
      this._input = clean ? clean._input : null;
    }

    /**
     * Wraps CleanInput and adds the needed functionality
     * @returns {XML}
     */
    render() {
      // Return the input
      return (
        <CleanInput
          type={type}
          {...this.props}
          value={this.state.internalValue}
          onChange={this.handleNumberChange}
          onBlur={this.handleBlur}
          ref={this.updateRef}
        />
      );
    }
  };
}
