/**
 * Created by guyavraham on 17/05/2016.
 */
import activationByToken from '../activation/activationByToken';
import inviteToVimLandingPage from '../inviteToVim/inviteToVimLandingPage';
import { pathsWithQuery } from '../../../../utils/pathsWithQuery';

if (typeof require.ensure !== 'function') require.ensure = (d, c) => c(require);

// eslint-disable-next-line import/first
import divComponent from './divComponent';

export const paths = {
  shortActivation: (token, qsParams) => pathsWithQuery(`/start/shortActivation/${token}`, qsParams),
};

// Routes
const MemberNotFound = require('../activation/components/memberNotFound');
const MainActivationFlow = require('../activation/activationFlowByDomain');
const SsoActivationFlow = require('../activation/ssoActivation/ssoActivationFlow');
const ShortActivation = require('../activation/shortActivation/shortActivation');
const familyActivationPage = require('../activation/familyActivation/familyActivationPage');

// Components
const Components = [
  {
    path: 'memberNotFound',
    name: 'memberNotFound',
    component: MemberNotFound.default || MemberNotFound,
  },
  {
    path: 'activation/do/:token',
    name: 'activation',
    component: activationByToken(MainActivationFlow.default || MainActivationFlow),
  },
  {
    path: 'activation/:source',
    name: 'activation',
    component: MainActivationFlow.default || MainActivationFlow,
  },
  {
    path: 'activation',
    name: 'activation',
    component: MainActivationFlow.default || MainActivationFlow,
  },
  {
    path: 'ssoActivation',
    name: 'ssoActivation',
    component: SsoActivationFlow.default || SsoActivationFlow,
  },
  {
    path: 'shortActivation/:token',
    name: 'shortActivation',
    component: ShortActivation.default || ShortActivation,
  },
  {
    path: ':source',
    component: MainActivationFlow.default || MainActivationFlow,
  },
];

export default [
  {
    path: 'start',
    name: 'start',
    getChildRoutes(location, cb) {
      require.ensure([], () => {
        cb(null, Components);
      });
    },
    // Requiring secured page NOT dynamically so it won't be unmounted every route
    component: divComponent,
    indexRoute: {
      component: MainActivationFlow.default || MainActivationFlow,
    },
  },
  {
    path: 'familyActivation',
    name: 'familyActivation',
    component: familyActivationPage.default || familyActivationPage,
  },
  {
    path: 'inviteToVim/:token',
    name: 'inviteToVim/:token',
    component: inviteToVimLandingPage.default || inviteToVimLandingPage,
  },
];
