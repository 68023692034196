import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '../../storybook/src/utils/theme';

function SvgurgentCare({ theme }) {
  /* eslint-disable */
  return (
    <svg aria-hidden="true" focusable="false" className="svg-icon svg-urgent-care" width="32px" height="32px" viewBox="0 0 32 32" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-548.000000, -426.000000)">
          <g transform="translate(548.000000, 426.000000)">
            <path d="M31,4 L1,4 C0.447,4 0,4.448 0,5 C0,5.552 0.447,6 1,6 L4,6 L4,31 C4,31.552 4.447,32 5,32 C5.553,32 6,31.552 6,31 L6,6 L26,6 L26,31 C26,31.552 26.447,32 27,32 C27.553,32 28,31.552 28,31 L28,6 L31,6 C31.553,6 32,5.552 32,5 C32,4.448 31.553,4 31,4" fill={theme.mainColor}></path>
            <path d="M21,32 C20.447,32 20,31.552 20,31 L20,22 L12,22 L12,31 C12,31.552 11.553,32 11,32 C10.447,32 10,31.552 10,31 L10,21 C10,20.448 10.447,20 11,20 L21,20 C21.553,20 22,20.448 22,21 L22,31 C22,31.552 21.553,32 21,32" fill={theme.mainColor}></path>
            <path d="M19,12 L17,12 L17,10 C17,9.448 16.553,9 16,9 C15.447,9 15,9.448 15,10 L15,12 L13,12 C12.447,12 12,12.448 12,13 C12,13.552 12.447,14 13,14 L15,14 L15,16 C15,16.552 15.447,17 16,17 C16.553,17 17,16.552 17,16 L17,14 L19,14 C19.553,14 20,13.552 20,13 C20,12.448 19.553,12 19,12" fill={theme.secondaryColor}></path>
            <path d="M25,2 L7,2 C6.447,2 6,1.552 6,1 C6,0.448 6.447,0 7,0 L25,0 C25.553,0 26,0.448 26,1 C26,1.552 25.553,2 25,2" fill={theme.secondaryColor}></path>
          </g>
        </g>
      </g>
    </svg>
  );
  /* eslint-enable */
}

SvgurgentCare.propTypes = {
  theme: PropTypes.object.isRequired,
};

export default withTheme(SvgurgentCare);
