import GeneralRoutes from '../generalRoutes';

class GeneralRouteStrategy {
  static predicate() {
    return true;
  }

  static getRoutes() {
    return [...GeneralRoutes];
  }
}

export default GeneralRouteStrategy;
