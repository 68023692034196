import _ from 'lodash';
import { connect } from 'react-redux';
import { compose, defaultProps } from 'recompose';
import { getInsurersList } from '../../store/insurers/selectors';
import fetchNotEmpty from '../../store/tools/fetchNotEmpty';
import { getInsurers } from '../../store/insurers/actions';
import SelectInsurer from '../../storybook/src/components/atoms/SelectInsurer/SelectInsurer';

export default compose(
  defaultProps({
    onChange: _.noop,
    onInsurerSelect: _.noop,
  }),
  fetchNotEmpty(getInsurers, getInsurersList, {
    renderAlways: false,
    loading: true,
  }),
  connect(state => ({
    insurers: getInsurersList(state),
  })),
)(SelectInsurer);
