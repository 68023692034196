/**
 * Created by chenrozenes on 24/07/2017.
 */
import { createSelectorCreator, defaultMemoize } from 'reselect';
import _ from 'lodash';

/* eslint-disable import/prefer-default-export */

export const memoizedSelectorCreator = createSelectorCreator(defaultMemoize, _.isEqual);

/* eslint-enable import/prefer-default-export */
