/**
 * Created by moran on 6/6/17.
 */
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { autobind } from 'core-decorators';
import { withRouter } from 'react-router';
import fetchNotEmpty from '../../../../store/tools/fetchNotEmpty';
import {
  actions,
  getReviewProcessByToken,
  getReviewProvider,
} from '../../../../store/collectReview/actions';

@autobind
class CollectReviewPage extends React.Component {
  static propTypes = {
    reviewProcess: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
    pull: PropTypes.func.isRequired,
  };

  componentWillMount() {
    this.props.pull('reviewProcess');
  }

  componentDidMount() {
    const { reviewProcess, router } = this.props;
    if (_.get(reviewProcess, 'expired', true)) router.push('review/expired');
  }

  render() {
    return <div>{this.props.children}</div>;
  }
}

export default compose(
  withRouter,
  fetchNotEmpty(({ params }) => getReviewProcessByToken(params), 'reviewProcess', {
    renderAlways: false,
  }),
  fetchNotEmpty(() => getReviewProvider(), 'reviewProcess.provider', { renderAlways: false }),
  connect(
    state => ({
      reviewProcess: state.reviewProcess,
    }),
    { pull: actions.pull },
  ),
)(CollectReviewPage);
