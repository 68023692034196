/**
 * Created by chenrozenes on 09/08/2017.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '../../storybook/src/utils/theme';

function SvgMug({ theme }) {
  /* eslint-disable */
  return (
    <svg aria-hidden="true" focusable="false" width="67px" height="71px" viewBox="0 0 67 71" version="1.1" className="svg-icon svg-mug">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-154.000000, -137.000000)">
          <g transform="translate(154.000000, 137.000000)">
            <path d="M14.5,21 C13.672,21 13,20.328 13,19.5 C13,17.25 14.503,16.123 15.601,15.301 C16.616,14.539 17,14.188 17,13.503 C17,12.817 16.616,12.464 15.6,11.702 C14.503,10.879 13,9.751 13,7.501 C13,5.251 14.503,4.124 15.6,3.301 C16.616,2.539 17,2.186 17,1.5 C17,0.672 17.672,0 18.5,0 C19.328,0 20,0.672 20,1.5 C20,3.751 18.497,4.878 17.399,5.701 C16.384,6.463 16,6.815 16,7.501 C16,8.187 16.384,8.54 17.4,9.302 C18.497,10.125 20,11.253 20,13.503 C20,15.752 18.497,16.879 17.4,17.701 C16.384,18.463 16,18.815 16,19.5 C16,20.328 15.328,21 14.5,21" fill={theme.secondaryColor}></path>
            <path d="M24.167,21 C23.339,21 22.667,20.328 22.667,19.5 C22.667,17.25 24.17,16.123 25.268,15.301 C26.283,14.539 26.667,14.188 26.667,13.503 C26.667,12.817 26.283,12.464 25.267,11.702 C24.17,10.879 22.667,9.751 22.667,7.501 C22.667,5.251 24.17,4.124 25.267,3.301 C26.283,2.539 26.667,2.186 26.667,1.5 C26.667,0.672 27.339,0 28.167,0 C28.995,0 29.667,0.672 29.667,1.5 C29.667,3.751 28.164,4.878 27.066,5.701 C26.051,6.463 25.667,6.815 25.667,7.501 C25.667,8.187 26.051,8.54 27.067,9.302 C28.164,10.125 29.667,11.253 29.667,13.503 C29.667,15.752 28.164,16.879 27.067,17.701 C26.051,18.463 25.667,18.815 25.667,19.5 C25.667,20.328 24.995,21 24.167,21" fill={theme.secondaryColor}></path>
            <path d="M33.5,21 C32.672,21 32,20.328 32,19.5 C32,17.25 33.503,16.123 34.601,15.301 C35.616,14.539 36,14.188 36,13.503 C36,12.817 35.616,12.464 34.6,11.702 C33.503,10.879 32,9.751 32,7.501 C32,5.251 33.503,4.124 34.6,3.301 C35.616,2.539 36,2.186 36,1.5 C36,0.672 36.672,0 37.5,0 C38.328,0 39,0.672 39,1.5 C39,3.751 37.497,4.878 36.399,5.701 C35.384,6.463 35,6.815 35,7.501 C35,8.187 35.384,8.54 36.4,9.302 C37.497,10.125 39,11.253 39,13.503 C39,15.752 37.497,16.879 36.4,17.701 C35.384,18.463 35,18.815 35,19.5 C35,20.328 34.328,21 33.5,21" fill={theme.secondaryColor}></path>
            <path  d="M64,47.5 C64,50.906 62.712,52 58.7,52 L53,52 L53,30 L58.7,30 C62.712,30 64,31.094 64,34.5 L64,47.5 Z M35.5,68 L17.5,68 C9.233,68 3,62.196 3,54.5 L3,22.901 C9.215,26.402 23.146,26.624 26.5,26.624 C29.854,26.624 43.785,26.402 50,22.901 L50,54.5 C50,62.196 43.767,68 35.5,68 L35.5,68 Z M58.7,27 L53,27 L53,19.5 C53,19.445 52.989,19.394 52.984,19.341 C52.984,19.339 52.985,19.337 52.985,19.335 C53.005,19.195 53.005,19.053 52.985,18.913 C52.603,16.217 49.354,14.292 43.057,13.029 C42.244,12.87 41.453,13.393 41.291,14.205 C41.128,15.018 41.654,15.808 42.467,15.971 C47.715,17.023 49.59,18.37 49.951,19.134 C49.113,21.086 40.858,23.624 26.5,23.624 C12.142,23.624 3.887,21.086 3.049,19.134 C3.41,18.37 5.285,17.023 10.533,15.971 C11.346,15.808 11.872,15.018 11.709,14.205 C11.547,13.393 10.757,12.871 9.943,13.029 C3.646,14.292 0.397,16.217 0.015,18.913 C-0.005,19.053 -0.005,19.195 0.015,19.335 C0.015,19.337 0.016,19.339 0.016,19.341 C0.011,19.394 0,19.445 0,19.5 L0,54.5 C0,63.906 7.523,71 17.5,71 L35.5,71 C45.297,71 52.711,64.153 52.975,55 L58.7,55 C62.263,55 67,54.223 67,47.5 L67,34.5 C67,27.777 62.263,27 58.7,27 L58.7,27 Z" fill={theme.mainColor}></path>
          </g>
        </g>
      </g>
    </svg>
  );
  /* eslint-enable */
}

SvgMug.propTypes = {
  theme: PropTypes.object.isRequired,
};

export default withTheme(SvgMug);
