import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '../../storybook/src/utils/theme';

function SvgCodeStars({ theme }) {
  /* eslint-disable */
  return (
    <svg aria-hidden="true" focusable="false" width="71px" height="64px" viewBox="0 0 71 64" version="1.1" className="svg-icon svg-code-stars">
      <g id="All-settings-screens" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Enter-code" transform="translate(-152.000000, -141.000000)">
          <g id="Page-1" transform="translate(152.000000, 141.000000)">
            <path d="M4.5,48.6592 C3.671,48.6592 3,47.9872 3,47.1592 L3,16.2502 C3,11.7212 6.936,7.7502 11.423,7.7502 L33.695,7.7502 C34.523,7.7502 35.195,8.4222 35.195,9.2502 C35.195,10.0782 34.523,10.7502 33.695,10.7502 L11.423,10.7502 C8.585,10.7502 6,13.3712 6,16.2502 L6,47.1592 C6,47.9872 5.329,48.6592 4.5,48.6592" id="Fill-1" fill={theme.mainColor} ></path>
            <path d="M69.5,60.75 L16.5,60.75 C15.671,60.75 15,60.078 15,59.25 C15,58.422 15.671,57.75 16.5,57.75 L65.879,57.75 L59.439,51.311 C59.158,51.029 59,50.647 59,50.25 L59,16.25 C59,13.32 56.543,10.75 53.741,10.75 L49.286,10.75 C48.458,10.75 47.786,10.078 47.786,9.25 C47.786,8.422 48.458,7.75 49.286,7.75 L53.741,7.75 C58.218,7.75 62,11.643 62,16.25 L62,49.629 L70.561,58.189 C70.989,58.618 71.118,59.264 70.886,59.824 C70.653,60.385 70.106,60.75 69.5,60.75" id="Fill-3" fill={theme.mainColor} ></path>
            <path d="M23.5,38.75 L23,38.75 L23,28.25 C23,27.422 22.329,26.75 21.5,26.75 L19.5,26.75 C18.671,26.75 18,27.422 18,28.25 C18,29.078 18.671,29.75 19.5,29.75 L20,29.75 L20,38.75 L19.5,38.75 C18.671,38.75 18,39.422 18,40.25 C18,41.078 18.671,41.75 19.5,41.75 L23.5,41.75 C24.329,41.75 25,41.078 25,40.25 C25,39.422 24.329,38.75 23.5,38.75" id="Fill-5" fill={theme.secondaryColor} ></path>
            <path d="M34.5,26.75 L28.5,26.75 C27.671,26.75 27,27.422 27,28.25 C27,29.078 27.671,29.75 28.5,29.75 L33,29.75 L33,32.853 L28.5,32.853 C27.671,32.853 27,33.524 27,34.353 L27,40.25 C27,41.078 27.671,41.75 28.5,41.75 L34.5,41.75 C35.328,41.75 36,41.078 36,40.25 C36,39.422 35.328,38.75 34.5,38.75 L30,38.75 L30,35.853 L34.5,35.853 C35.328,35.853 36,35.181 36,34.353 L36,28.25 C36,27.422 35.328,26.75 34.5,26.75" id="Fill-7" fill={theme.secondaryColor} ></path>
            <path d="M45.5,26.75 L39.5,26.75 C38.672,26.75 38,27.422 38,28.25 C38,29.078 38.672,29.75 39.5,29.75 L44,29.75 L44,32.853 L39.5,32.853 C38.672,32.853 38,33.524 38,34.353 C38,35.181 38.672,35.853 39.5,35.853 L44,35.853 L44,38.75 L39.5,38.75 C38.672,38.75 38,39.422 38,40.25 C38,41.078 38.672,41.75 39.5,41.75 L45.5,41.75 C46.328,41.75 47,41.078 47,40.25 L47,28.25 C47,27.422 46.328,26.75 45.5,26.75" id="Fill-9" fill={theme.secondaryColor} ></path>
            <path d="M5.5,55.75 C4.122,55.75 3,56.871 3,58.25 C3,59.629 4.122,60.75 5.5,60.75 C6.878,60.75 8,59.629 8,58.25 C8,56.871 6.878,55.75 5.5,55.75 M5.5,63.75 C2.467,63.75 0,61.282 0,58.25 C0,55.218 2.467,52.75 5.5,52.75 C8.533,52.75 11,55.218 11,58.25 C11,61.282 8.533,63.75 5.5,63.75" id="Fill-11" fill={theme.secondaryColor} ></path>
            <path d="M44.832,3.3408 L42.991,3.3408 L42.991,1.4998 C42.991,0.6718 42.319,-0.0002 41.491,-0.0002 C40.663,-0.0002 39.991,0.6718 39.991,1.4998 L39.991,3.3408 L38.15,3.3408 C37.322,3.3408 36.65,4.0128 36.65,4.8408 C36.65,5.6688 37.322,6.3408 38.15,6.3408 L39.991,6.3408 L39.991,8.1818 C39.991,9.0098 40.663,9.6818 41.491,9.6818 C42.319,9.6818 42.991,9.0098 42.991,8.1818 L42.991,6.3408 L44.832,6.3408 C45.66,6.3408 46.332,5.6688 46.332,4.8408 C46.332,4.0128 45.66,3.3408 44.832,3.3408" id="Fill-13" fill={theme.secondaryColor} ></path>
            <path d="M70.4453,38.25 C70.4453,39.48 69.4483,40.478 68.2173,40.478 C66.9883,40.478 65.9913,39.48 65.9913,38.25 C65.9913,37.02 66.9883,36.022 68.2173,36.022 C69.4483,36.022 70.4453,37.02 70.4453,38.25" id="Fill-16" fill={theme.secondaryColor} ></path>
          </g>
        </g>
      </g>
    </svg>
  );
  /* eslint-enable */
}

SvgCodeStars.propTypes = {
  theme: PropTypes.object.isRequired,
};

export default withTheme(SvgCodeStars);
