import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { compose, withStateHandlers, withPropsOnChange, getContext } from 'recompose';
import BookingModal from './bookingModal';
import * as BookingType from '../../model/enum/bookingType';

const getClosestProviderLocation = (closestLocations, provider) => {
  const id = _.toNumber(closestLocations[provider.npi]);
  return _.find(provider.locations, location => location.id === id);
};

const bookingManagement = compose(
  getContext({ analytics: PropTypes.object }),
  withStateHandlers(
    ({ analytics }) => ({
      isBookingOpen: false,
      currentBookingProvider: null,
      currentBookingType: null,
      analytics,
    }),
    {
      onBookClick: ({ analytics }, { bookingDisabled, onBookDisabledClick }) => (
        provider,
        currentProviderLocationId,
        bookingType = BookingType.INSTANT,
      ) => {
        if (!bookingDisabled) {
          analytics.track('onBookClick', {
            bookingType,
            locationId: currentProviderLocationId,
            npi: provider.npi,
          });
          return {
            isBookingOpen: true,
            currentProviderLocationId,
            currentBookingProvider: provider,
            currentBookingType: bookingType,
          };
        }
        onBookDisabledClick();
        return {};
      },
      onBookingClose: () => () => ({
        isBookingOpen: false,
        currentBookingProvider: null,
        currentBookingType: null,
      }),
    },
  ),
);

const withBookingModal = flowPropsGetter => Component =>
  class WrappedComponent extends React.PureComponent {
    static propTypes = {
      isBookingOpen: PropTypes.bool.isRequired,
      domainMemberId: PropTypes.string,
      domain: PropTypes.string,
      onBookingClose: PropTypes.func.isRequired,
      currentBookingType: PropTypes.string,
      currentBookingProvider: PropTypes.object,
      currentProviderLocationId: PropTypes.number,
    };

    static defaultProps = {
      currentBookingProvider: null,
      currentBookingType: null,
      currentProviderLocationId: null,
      domainMemberId: undefined,
      domain: undefined,
    };

    render() {
      const {
        isBookingOpen,
        onBookingClose,
        currentBookingProvider,
        currentBookingType,
        currentProviderLocationId,
        domainMemberId,
        domain,
        ...rest
      } = this.props;
      const {
        member: { closestLocations, insurer, plan },
        token,
      } = rest;
      let location = null;
      if (currentBookingProvider) {
        location = currentProviderLocationId
          ? _.find(currentBookingProvider.locations, { id: currentProviderLocationId })
          : getClosestProviderLocation(closestLocations, currentBookingProvider);
      }
      const shouldRenderModal = currentBookingProvider && location;
      const flowProps = flowPropsGetter(this.props);
      return (
        <div>
          <Component {...rest} />
          {shouldRenderModal && (
            <BookingModal
              bookingType={currentBookingType}
              isOpen={isBookingOpen}
              onClose={onBookingClose}
              provider={currentBookingProvider}
              insurer={insurer}
              domain={domain}
              plan={plan}
              location={location}
              domainMemberId={domainMemberId}
              token={token}
              flowProps={flowProps}
            />
          )}
        </div>
      );
    }
  };

export default (flowPropsGetter = _.noop) =>
  compose(
    withPropsOnChange(['member', 'domain'], ({ member, domain }) => ({
      domainMemberId: member.domainMemberId || member.id,
      domain: domain || member.domain,
    })),
    bookingManagement,
    withBookingModal(flowPropsGetter),
  );
