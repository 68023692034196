import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { compose, pure, setDisplayName } from 'recompose';
import _ from 'lodash';
import { defineMessages } from 'react-intl';
import { Field, Form } from 'formik';
import { object, string } from 'yup';
import SvgClapBold from '../assets/svg/clapBold';
import { WrappedFormik } from '../components/form/formik/wrappedFormik';
import FormActionButtons from '../components/molecules/FormSubmissionButtons/FormActionButtons';
import { CleanFormikYearSelector } from '../components/form/formik/cleanFormikYearSelector';
import { CleanFormikSelect } from '../components/form/formik/cleanFormikSelect';

const messages = defineMessages({
  year: {
    defaultMessage: 'Year',
    id: 'screenTemplates.emailForm.year',
  },
  results: {
    defaultMessage: 'Results',
    id: 'screenTemplates.emailForm.results',
  },
});

const scheme = object().shape({
  year: string()
    .required()
    .label('Year'),
  results: string()
    .required()
    .label('Results'),
});

function ReminderInput({
  className,
  title,
  buttonText,
  svgIcon,
  options,
  mainText,
  onButtonClick,
  initialValues,
}) {
  return (
    <WrappedFormik
      initialValues={initialValues}
      onSubmit={onButtonClick}
      validationSchema={scheme}
      render={() => (
        <Form>
          <div className={classNames('reminder-input-page padding-h-30', className)}>
            <div className="row margin-top">
              <div className="col-xs-12 text-center svg-72">{svgIcon}</div>
            </div>

            <div className="row margin-top-20">
              <div className="col-xs-12">
                <div className="title-24 text-strong-dark text-center no-margin">{title}</div>
              </div>
            </div>

            <div className="row margin-top-30">
              <div className="col-xs-12">
                <div className="text-18 text-center">{mainText}</div>
              </div>
            </div>

            <div className="row margin-top-30">
              <div className="col-xs-12">
                <Field name="year" label={messages.year} component={CleanFormikYearSelector} />
              </div>
            </div>

            <div className="row">
              <div className="col-xs-12">
                <Field
                  name="results"
                  label={messages.results}
                  component={CleanFormikSelect}
                  options={options}
                />
              </div>
            </div>

            <div className="row margin-bottom-clean-form">
              <div className="col-xs-12">
                <FormActionButtons primaryButtonText={buttonText} />
              </div>
            </div>
          </div>
        </Form>
      )}
    />
  );
}
ReminderInput.propTypes = {
  buttonText: PropTypes.string,
  className: PropTypes.string,
  initialValues: PropTypes.object,
  mainText: PropTypes.string,
  onButtonClick: PropTypes.func,
  options: PropTypes.array,
  svgIcon: PropTypes.object,
  title: PropTypes.string,
};
ReminderInput.defaultProps = {
  buttonText: 'Set a reminder',
  className: undefined,
  initialValues: null,
  mainText: undefined,
  onButtonClick: _.noop,
  options: null,
  svgIcon: <SvgClapBold />,
  title: undefined,
};

const enhance = compose(
  setDisplayName('SCR014ReminderInput'),
  pure,
);

export default enhance(ReminderInput);
