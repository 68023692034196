/**
 * See https://michalzalecki.com/why-using-localStorage-directly-is-a-bad-idea/
 * for why this exists
 */
function storageFactory(getStorage) {
  let inMemoryStorage = {};

  function isSupported() {
    try {
      const testKey = '__local_storage_existence_test_key__';
      getStorage().setItem(testKey, testKey);
      getStorage().removeItem(testKey);
      return true;
    } catch (e) {
      return false;
    }
  }

  function clear() {
    if (isSupported()) {
      getStorage().clear();
    } else {
      inMemoryStorage = {};
    }
  }

  function getItem(name) {
    if (isSupported()) {
      return getStorage().getItem(name);
    }
    // eslint-disable-next-line no-prototype-builtins
    if (inMemoryStorage.hasOwnProperty(name)) {
      return inMemoryStorage[name];
    }
    return null;
  }

  function key(index) {
    return isSupported() ? getStorage().key(index) : Object.keys(inMemoryStorage)[index] || null;
  }

  function removeItem(name) {
    if (isSupported()) {
      getStorage().removeItem(name);
    } else {
      delete inMemoryStorage[name];
    }
  }

  function setItem(name, value) {
    if (isSupported()) {
      getStorage().setItem(name, value);
    } else {
      inMemoryStorage[name] = String(value);
    }
  }

  function length() {
    return isSupported() ? getStorage().length : Object.keys(inMemoryStorage).length;
  }

  return {
    getItem,
    setItem,
    removeItem,
    clear,
    key,
    get length() {
      return length();
    },
  };
}

export const safeLocalStorage = storageFactory(() => localStorage);
