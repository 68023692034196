import React from 'react';
import PropTypes from 'prop-types';
import './messageBanner.less';

// TODO: @mrsufgi: I picked the worst name for it.
// no one named this component and I can't be the one deciding
const MessageBanner = ({ text, icon }) => (
  <div className="message-banner bg-color-brand-main">
    <div className="text-16 text-semibold text-white">
      {text}
    </div>
    <i className={icon} />
  </div>
);

MessageBanner.propTypes = {
  text: PropTypes.string.isRequired,
  icon: PropTypes.object,
};

MessageBanner.defaultProps = {
  icon: '',
};

export default MessageBanner;
