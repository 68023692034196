import _ from 'lodash';
import { compose, withPropsOnChange } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { pathsWithQuery } from '../../utils/pathsWithQuery';
import queryConnect from '../../store/tools/queryString/queryConnect';

const requireActivation = (landingUrl, activationSelector) =>
  compose(
    withRouter,
    connect((state, ownProps) => ({
      next: `${_.get(state, 'routing.locationBeforeTransitions.pathname')}${_.get(state, 'routing.locationBeforeTransitions.search')}`,
      isActivated: activationSelector(state, ownProps),
    })),
    queryConnect(query => ({
      queryParams: query,
    })),
    withPropsOnChange(['isActivated'], props => {
      const { next, queryParams, isActivated } = props;
      if (isActivated === false) {
        props.router.push(pathsWithQuery(landingUrl(props), { next, ...queryParams }));
      }
    })
  );

export default requireActivation;
