/**
 * Created by guyavarham on 03/01/2018.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';

import FindMemberComp from './findMember';
import { FlowShape, TRUE_RESULT } from '../../../../../../components/wizard/flowManager';

@autobind
class FindMember extends React.PureComponent {
  static propTypes = {
    flow: FlowShape,
    phoneNumber: PropTypes.object.isRequired,
  };

  static defaultProps = {
    flow: undefined,
  };

  next() {
    this.props.flow.next(TRUE_RESULT);
  }

  render() {
    return (
      <FindMemberComp
        onSuccess={this.next}
        onError={this.props.flow.next}
        phoneNumber={this.props.phoneNumber}
      />
    );
  }
}

export default FindMember;
