import React from 'react';

class SvgBullhorn extends React.PureComponent {
  render() {
    return (
      /* eslint-disable */
      <svg
        width="24px"
        height="25px"
        viewBox="0 0 24 25"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <defs>
          <rect id="path-1" x="0" y="0" width="1898" height="50"></rect>
          <filter id="filter-3">
            <feColorMatrix
              in="SourceGraphic"
              type="matrix"
              values="0 0 0 0 0.192157 0 0 0 0 0.196078 0 0 0 0 0.200000 0 0 0 1.000000 0"
            ></feColorMatrix>
          </filter>
        </defs>
        <g id="bullhorn-solid" transform="translate(1.500000, 3.000000)" fill="#001C36">
          <path
            d="M19.5,0.0234375 L18.3046875,0.890625 C18.3046875,0.890625 16.8134767,1.99804725 14.671875,3.09375 C12.5302733,4.1894535 9.74121075,5.25 7.2890625,5.25 L0,5.25 L0,12.09375 L0.0234375,12.2109375 L1.5234375,17.4609375 L1.6875,18 L6.234375,18 L5.9765625,17.0390625 L4.7578125,12.75 L7.2890625,12.75 C9.70898475,12.75 12.4980472,13.8310545 14.6484375,14.9296875 C16.7988285,16.0283205 18.3046875,17.109375 18.3046875,17.109375 L19.5,17.9765625 L19.5,11.109375 C20.3671875,10.7958983 21,9.96972675 21,9 C21,8.03027325 20.3671875,7.20410175 19.5,6.890625 L19.5,0.0234375 Z M18,2.8125 L18,15.1640625 C17.3789062,14.745117 16.8867188,14.364258 15.328125,13.5703125 C13.125,12.4453125 10.2802733,11.3056642 7.5,11.25 L7.5,6.75 C10.306641,6.69433575 13.1542972,5.53125 15.3515625,4.40625 C16.9072267,3.609375 17.384766,3.2314455 18,2.8125 Z M1.5,6.75 L6,6.75 L6,11.25 L1.5,11.25 L1.5,6.75 Z M1.7578125,12.75 L3.1875,12.75 L4.2421875,16.5 L2.8125,16.5 L1.7578125,12.75 Z"
            id="Shape"
          ></path>
        </g>
      </svg>
      /* eslint-enable */
    );
  }
}
export default SvgBullhorn;
