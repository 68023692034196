import _ from 'lodash';
import { compose, withHandlers, defaultProps } from 'recompose';
import { connect } from 'react-redux';
import BookMdUtil from '../../utils/util';
import Formatter from '../../utils/formatter';
import VimSdkClient from '../../utils/sdk/vimSdkClient';
import CommonFormats from '../../utils/formatter/commonFormats';
import * as BookingType from '../../model/enum/bookingType';
import { withStateFetchers } from '../../api/injectApi/withStateFetchers';
import { SdkBookButtonClicked } from '../../store/tools/analytics/events/sdkEvents';
import { isVimSDKLoaded } from '../../store/help/selectors';
import { loadingUntil } from '../recompose/loadingUntil';
import LoaderComponent from './loaderComponent';

/**
 * these are common logic components when using the sdk capabilities
 * getProviderAvailability: gets the entity's (provider/facility) availability
 * showBookingDialog: will show the booking dialog
 * bookEventHandler:
 *  if onShowBookingDialogSuccess is passed it will be fired with:
 *  1. entityId - the created entityId (appointment / appointment request)
 *  2. availability - the availability details for the entity we wanted to book an appointment with
 *
 * The usual order of operations is this:
 * 1. getProviderAvailability is called, returning an object containing a provider's
 *    booking availability into the 'availability' prop,
 *    In most cases we use the value of this prop to decide whether to show a book button and
 *    sometimes it's used to change the book button looks.
 * 2. showBookingDialog is called, a booking flow is shown during which several events are raised.
 *    One of those events is BOOKED to which we subscribe and respond to with bookEventHandler, if
 *    the value of the onShowBookingDialogSuccess prop is a function.
 */

export default compose(
  defaultProps({
    analyticsEvent: SdkBookButtonClicked,
    onSuccess: _.noop,
  }),
  connect(state => ({
    isVimSDKReady: isVimSDKLoaded(state),
  })),
  loadingUntil(({ isVimSDKReady }) => isVimSDKReady, LoaderComponent),
  withStateFetchers({
    getProviderAvailability: {
      handler: ({
        targetNpi: npi,
        targetLocationAddress: address,
        targetLocationGeo,
        npiType,
        language,
        code,
        member,
        insurer,
      }) => () => {
        const options = _.omitBy(
          {
            memberToken: _.get(member, 'memberToken'),
            plan: _.get(member, 'plan'),
            language,
            npiType,
            code,
            insurer,
          },
          _.isNil,
        );
        let params = { npi, address };
        const geo = BookMdUtil.toGeoPoint(targetLocationGeo);
        if (geo) params = { ...params, geo };
        return VimSdkClient.getProvidersAvailability([params], options)
          .then(([provider]) => ({
            bookingType: _.get(provider, 'bookingType', BookingType.NONE),
            timezone: _.get(provider, 'timezone', undefined),
          }))
          .catchReturn({ bookingType: BookingType.NONE, nextAvailableSlot: undefined });
      },
      resultPropName: 'availability',
      isReady: ({ availability }) => !_.isEmpty(availability),
      onSuccess: ({ onSuccess }, availability) => onSuccess(availability),
    },
  }),
  withHandlers({
    bookEventHandler: ({ availability, onShowBookingDialogSuccess }) => entityId => {
      if (_.isFunction(onShowBookingDialogSuccess)) {
        onShowBookingDialogSuccess(entityId, availability.bookingType);
      }
    },
  }),
  withStateFetchers({
    showBookingDialog: {
      handler: ({
        targetNpi: npi,
        targetLocationAddress: address,
        targetLocationGeo: geo,
        npiType,
        language,
        code,
        member,
        requestToken,
        analytics,
        availability,
        bookEventHandler,
        analyticsEvent,
        insurer,
        correlationId,
      }) => e => {
        _.invoke(e, 'stopPropagation');
        const options = _.omitBy(
          {
            geo: BookMdUtil.toGeoPoint(geo),
            requestToken,
            memberToken: _.get(member, 'memberToken'),
            plan: _.get(member, 'plan'),
            language,
            npiType,
            code,
            insurer,
            member: _.omitBy(
              {
                phoneNumber: _.get(member, 'phoneNumber'),
                firstName: _.get(member, 'firstName'),
                lastName: _.get(member, 'lastName'),
                dateOfBirth:
                  Formatter.dateUtc(
                    _.get(member, 'dateOfBirth'),
                    CommonFormats.SHORT_MONTH_DAY_YEAR_DATE,
                  ) || null,
              },
              _.isNil,
            ),
          },
          _.isNil,
        );
        if (!_.isEmpty(analytics)) {
          const analyticsData = {
            npi,
            address,
            ...availability,
            ..._.pick(options, ['geo', 'requestToken', 'memberToken', 'plan', 'npiType', 'code']),
            correlationId,
          };
          analytics.track(analyticsEvent, analyticsData);
        }

        // todo: remove the catch when sdk error integrate with 'withFetchers'
        return VimSdkClient.showBookingDialog(npi, address, options)
          .then(appointmentResult => {
            bookEventHandler(appointmentResult);
            return appointmentResult;
          })
          .catchReturn(null);
      },
      resultPropName: 'appointmentResult',
      // todo: put it back when sdk integrate with 'withFetchers' for now just catch
      // onError: (props, error) => {},
    },
  }),
);
