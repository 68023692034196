import Promise from 'bluebird';
import _ from 'lodash';
import { error, log } from '../logger';

// https://js.getvim.com/web/v1.2/vim-sdk-docs.html#vim
class VimSdkClient {
  async init(apiKey, options = {}) {
    try {
      if (_.isEmpty(this._client)) {
        log('initializing SDK client ...');
        this._client = await window.Vim(apiKey, {
          ...options,
          supportedWidgets: ['SCHEDULING', 'SEARCH_AND_ACTION', 'RESCHEDULE'],
        });
        log('initializing SDK completed');
      }
    } catch (e) {
      error('Could not initialize VIM SDK', e);
    }
  }

  /**
   * @param {string} npi
   * @param {string} address
   * @param {Object} options
   * @param {string} options.plan
   * @param {string} options.language
   * @param {string} options.memberToken
   * @param {string} options.code
   */
  showBookingDialog(npi, address, options = {}) {
    return Promise.resolve().then(() => {
      if (_.isEmpty(this._client)) {
        error('SDK is not initialized');
        return null;
      }
      return this._client.showBookingDialog(npi, address, options);
    });
  }

  showRescheduleDialog(appointmentId) {
    return Promise.resolve().then(() => {
      if (_.isEmpty(this._client)) {
        error('SDK is not initialized');
        return null;
      }
      return this._client.showRescheduleDialog(appointmentId);
    });
  }

  closeBookingDialog() {
    return Promise.resolve().then(() => {
      if (_.isEmpty(this._client)) {
        error('SDK is not initialized');
        return null;
      }
      return this._client.closeDialog();
    });
  }

  /**
   * @param {Array} providers
   * @param {string} providers[].npi
   * @param {string} providers[].address
   * @param {Object} options
   * @param {string} options.plan
   * @param {string} options.language
   * @param {string} options.memberToken
   * @param {string} options.code
   */
  getProvidersAvailability(providers, options = {}) {
    return Promise.resolve().then(() => {
      if (_.isEmpty(this._client)) {
        error('SDK is not initialized');
        return null;
      }
      return this._client.getProviderAvailability(providers, options);
    });
  }
}

export default new VimSdkClient();
