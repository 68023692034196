import { isNil } from 'lodash';
import {
  ACCEPTED_APPOINTMENTS_STATUS,
  REQUESTED_APPOINTMENTS_STATUS,
} from '../model/enum/appointments';

export const convertAppointments = (appointments) => {
  const sortedAppointments = [...appointments].sort((a, b) => {
    if (a.startTime === null) {
      return 1;
    }
    if (b.startTime === null) {
      return -1;
    }
    return Date.parse(b.startTime).valueOf() - Date.parse(a.startTime).valueOf() ? 1 : -1;
  });

  const upcomingAppointments = [
    ...sortedAppointments
      .filter((el) => !isNil(el.startTime))
      .filter(
        (el) =>
          ACCEPTED_APPOINTMENTS_STATUS.includes(el.status) &&
          Date.now() < Date.parse(el.startTime).valueOf(),
      ),
  ].sort((a, b) => Date.parse(a.startTime).valueOf() - Date.parse(b.startTime).valueOf());

  const requestedAppointments = [
    ...sortedAppointments.filter(
      (el) => REQUESTED_APPOINTMENTS_STATUS.includes(el.status) && isNil(el.startTime),
    ),
  ];

  const pastAppointments = sortedAppointments.filter(
    (el) =>
      (!ACCEPTED_APPOINTMENTS_STATUS.includes(el.status) &&
        !REQUESTED_APPOINTMENTS_STATUS.includes(el.status)) ||
      Date.now() > Date.parse(el.startTime).valueOf(),
  );

  return {
    requested: requestedAppointments,
    upcoming: upcomingAppointments,
    past: pastAppointments,
  };
};
