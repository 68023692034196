/**
 * Created by chenrozenes on 19/01/2017.
 */
import _ from 'lodash';
import buildActions from './actions';
import buildSelectors from './selectors';

class PodrickFactory {
  constructor(config, manager) {
    this.config = config;
    this.manager = manager;
  }

  create(type = {}) {
    type = _.extend({}, this.config, type);
    this.manager.register(type);

    return {
      actions: buildActions(type),
      selectors: buildSelectors(type)
    };
  }
}

export default PodrickFactory;
