/**
 * Created by meirshalev on 03/05/2017.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { reduxForm, Field } from 'redux-form';
import { defineMessages, FormattedMessage } from 'react-intl';

import withFeatureFlag from '../../../../../../utils/featureFlags/withFeatureFlag';
import { withResetPasswordForm } from './withResetPasswordForm';
import * as ResetPasswordFormDef from './resetPasswordFormDef';

const messages = defineMessages({
  makeItAGoodOne: {
    defaultMessage: 'Make it a good one',
    id: 'members.resetPassword.makeItAGoodOne',
  },
  yourPassword: {
    defaultMessage: 'Your password',
    id: 'members.resetPassword.yourPassword',
  },
  resetMyPassword: {
    defaultMessage: 'Reset my password',
    id: 'members.resetPassword.resetMyPassword',
  },
  repeatPassword: {
    defaultMessage: 'Re-enter password',
    id: 'members.resetPassword.repeatPassword',
  },
});

function VerificationCodeForm(props) {
  const onPasswordStrengthChanged = strength => {
    if (props.newPasswordValidationFeature) {
      props.change(ResetPasswordFormDef.fields.passwordStrength.name, strength);
    }
  };

  return (
    <div>
      <div className="row">
        <div className="col-xs-12 text-center">
          <span className="text-18 text-light">
            <FormattedMessage {...messages.makeItAGoodOne} />
          </span>
        </div>
      </div>
      <form className="form-section" onSubmit={props.handleSubmit} method="post">
        <div className="row">
          <div className="col-xs-12 margin-top">
            <Field
              type="tel"
              label={messages.yourPassword}
              {...ResetPasswordFormDef.fields.password}
              onStrengthChanged={onPasswordStrengthChanged}
              withFeatureFlag={props.newPasswordValidationFeature}
              verifyPasswordField={
                <div>
                  <Field
                    label={messages.repeatPassword}
                    className="password-input"
                    {...ResetPasswordFormDef.fields.repeatPassword}
                  />
                </div>
              }
            />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 margin-top">
            <button className="btn btn-big bg-color-brand-button" type="submit">
              <FormattedMessage {...messages.resetMyPassword} />
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

VerificationCodeForm.propTypes = {
  /**
   * Hook method. supplied by redux-forms
   */
  handleSubmit: PropTypes.func.isRequired,
  newPasswordValidationFeature: PropTypes.bool.isRequired,
};

const fieldNames = [
  ResetPasswordFormDef.fields.password.name,
  ResetPasswordFormDef.fields.repeatPassword.name,
];

export default compose(
  reduxForm({
    form: ResetPasswordFormDef.name,
    destroyOnUnmount: false,
  }),
  withResetPasswordForm(fieldNames, ResetPasswordFormDef.customValidator),
  withFeatureFlag({
    key: 'vim.members.password_validation_feature',
    prop: 'newPasswordValidationFeature',
    defaultValue: false,
  }),
)(VerificationCodeForm);
