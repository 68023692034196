import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { defineMessages, FormattedMessage } from 'react-intl';
import { Field } from 'redux-form';
import { autobind } from 'core-decorators';

import SvgUserCircle from '../../../../../assets/svg/userCircle';
import * as ActivationForm from '../activationForm';
import SpinnerButton from '../../../../../components/ui/spinner/spinnerButton';
import { withActivationForm } from './withActivationForm';
import { getFeature } from '../../../../../store/feature/selectors';
import FindIdNumModal from '../../../../../components/organisms/FindIdNumModal/FindIdNumModal';
import { createToggleState } from '../../../../../components/recompose/toggleStateComponent';
import FeatureFlagged from '../../../../../components/features/featureFlagged';

const messages = defineMessages({
  weNeedYourMemberId: {
    defaultMessage: 'We need your insurance ID to confirm your identity',
    id: 'activation.memberId.weNeedYourMemberId',
  },
  yourMemberId: {
    defaultMessage: 'Your insurance ID number',
    id: 'activation.memberId.yourMemberId',
  },
  whereCanIFindMyIdNumber: {
    defaultMessage: 'Where can I find my ID number?',
    id: 'activation.memberId.whereCanIFindMyIdNumber',
  },
});

@autobind
class MemberId extends React.PureComponent {
  static propTypes = {
    /**
     * Hook method. supplied by redux-forms
     */
    handleSubmit: PropTypes.func.isRequired,
    /**
     * True if form is currently submitting
     */
    loading: PropTypes.bool,
    confirmMessage: PropTypes.object.isRequired,
  };

  static contextTypes = {
    analytics: PropTypes.object.isRequired,
  };

  static defaultProps = {
    loading: false,
  };

  componentDidMount() {
    this.context.analytics.manualPageReport('activation.memberId');
  }

  render() {
    const { handleSubmit, loading, confirmMessage } = this.props;

    return (
      <div>
        <form onSubmit={handleSubmit} className="activation-mobile-num-wrap" method="post">
          <div className="top-section">
            <div className="row">
              <div className="col-xs-12 text-center">
                <SvgUserCircle />
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12 text-center">
                <h1 className="title-24 md-margin font-color-brand-main title-brand-1">
                  <FormattedMessage {...messages.weNeedYourMemberId} />
                </h1>
              </div>
            </div>
          </div>

          <div className="form-section">
            <div className="row">
              <div className="col-xs-12">
                <Field
                  placeholder=""
                  inputClassName="no-margin"
                  label={messages.yourMemberId}
                  {...ActivationForm.fields.memberId}
                />
              </div>
            </div>

            <FeatureFlagged uri="showInsuranceInfoHints" renderChildren>
              <div className="row margin-top-5 margin-bottom-clean-form">
                <div className="col-xs-12">
                  <button
                    className="btn btn-inline-link blue-link text-14"
                    onClick={this.props.findIdModalShow}
                    type="button"
                  >
                    <FormattedMessage {...messages.whereCanIFindMyIdNumber} />
                  </button>
                </div>
              </div>
            </FeatureFlagged>
          </div>

          <div className="row margin-top-30">
            <div className="col-xs-12">
              <SpinnerButton
                isLoading={loading}
                className="btn btn-big complete-btn bg-color-brand-button"
                type="submit"
              >
                <FormattedMessage {...confirmMessage} />
              </SpinnerButton>
            </div>
          </div>
        </form>
        <FindIdNumModal
          isOpen={this.props.findIdModalOn}
          handleClose={this.props.findIdModalHide}
        />
      </div>
    );
  }
}

const fieldName = 'memberId';
export default compose(
  createToggleState('findIdModal'),
  connect(state => ({
    confirmMessage: getFeature(state, 'activationFlow.dobStep.confirm'),
  })),
  withActivationForm(fieldName),
)(MemberId);
