/**
 * Created by guyavarham on 20/03/2017.
 */

import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { autobind } from 'core-decorators';
import { FormattedMessage } from 'react-intl';

import { messages, getKeyForMsgId } from '../../messages/searchDoctorResults/messages';
import IntlDropdown from '../intlBased/intlDropdown';

const bestMatchSort = 'bestMatch';

@autobind
class SortDropdown extends React.PureComponent {

  static propTypes = {
    options: PropTypes.arrayOf(PropTypes.string).isRequired,
    onChange: PropTypes.func.isRequired,
    sorting: PropTypes.string,
  };

  static defaultProps = {
    sorting: bestMatchSort,
  };

  handleSortChange(selectedMessageId) {
    const { onChange } = this.props;
    onChange({ sorting: getKeyForMsgId(selectedMessageId) }, true); // true for push to history
  }

  render() {
    const { sorting, options } = this.props;
    if (options.length === 1) {
      return (
        <div className="pull-right text-16 sorted-by-label">
          <FormattedMessage {...messages.sortedBy} values={{ value: _.first(options) }} />
        </div>
      );
    }

    if (!sorting) return null;
    const intlOptions = _.map(options, option => messages[option]);

    return (
      <div className="sort-dropdown-wrap pull-right">
        <span className="text-16 hidden-xs">
          <FormattedMessage {...messages.sortBy} />&nbsp;
        </span>
        <IntlDropdown
          id="search-sort-dropdown"
          options={intlOptions}
          value={messages[sorting]}
          onChange={this.handleSortChange}
          className="sort-btn btn btn-transparent pull-right"
        />
      </div>
    );
  }
}

export default SortDropdown;
