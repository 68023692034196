/**
 * Created by guyavarham on 20/03/2017.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import { defineMessages, FormattedMessage } from 'react-intl';
import SvgNoResults from '../../assets/svg/noResults';
import * as filtersUtils from './filter/filteringDefenitions';

import './noResultsPage.less';

const messages = defineMessages({
  title: {
    defaultMessage: 'Whoopsy',
    id: 'members.searchDoctorsResults.notFound.title'
  },
  text: {
    defaultMessage: 'We don\'t have any results for "{searchTerm}"',
    id: 'members.searchDoctorsResults.notFound.text'
  },
  clearFilters: {
    defaultMessage: 'Clear your filters',
    id: 'members.searchDoctorsResults.notFound.clearFilters'
  },
  searchAgain: {
    defaultMessage: 'Search again',
    id: 'members.searchDoctorsResults.notFound.searchAgain'
  },
});

@autobind
class NoResults extends React.PureComponent {
  static defaultProps = {
    searchTerm: '',
  };

  static propTypes = {
    setInQueryString: PropTypes.func.isRequired,
    onSearchAgain: PropTypes.func.isRequired,
    filter: PropTypes.object.isRequired,
    searchTerm: PropTypes.string,
  };

  clearFilters() {
    this.props.setInQueryString(filtersUtils.getDefaultsObject(), true);
  }

  render() {
    const { onSearchAgain, filter, searchTerm, children } = this.props;

    let action;
    let actionMsg;

    if (filtersUtils.areAllSetToDefault(filter)) {
      actionMsg = messages.searchAgain;
      action = () => onSearchAgain();
    } else {
      actionMsg = messages.clearFilters;
      action = this.clearFilters;
    }

    return (
      <div className="no-results-container">
        <div className="no-results-content">
          <SvgNoResults />
          <div className="title-24 no-margin font-color-brand-main">
            <strong>
              <FormattedMessage {...messages.title} />
            </strong>
          </div>
          <div className="no-results-text text-18">
            <FormattedMessage {...messages.text} values={{ searchTerm }} />
          </div>
          <button className="btn btn-big btn-outline btn-trans" onClick={action}>
            <FormattedMessage {...actionMsg} />
          </button>
          {children}
        </div>
      </div>
    );
  }
}

export default NoResults;
