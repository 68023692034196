/**
 * Created by galgoltzman on 11/04/2018.
 */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { autobind } from 'core-decorators';
import _ from 'lodash';

import displayProvider from '../../utils/displayProvider';
import DoctorLogo from '../doctorImage/doctorLogo';

import './miniDoctorCard.less';

@autobind
class MiniDoctorCard extends React.PureComponent {
  static propTypes = {
    containerClassName: PropTypes.string,
    docImgSize: PropTypes.string,
    provider: PropTypes.object.isRequired,
    onRemove: PropTypes.func,
    showSuffix: PropTypes.bool,
    isHighValueBadgeEnable: PropTypes.bool,
  };

  static defaultProps = {
    containerClassName: undefined,
    docImgSize: 'small-48',
    onRemove: undefined,
    showSuffix: false,
    isHighValueBadgeEnable: false,
  };

  onRemoveClick() {
    const { provider, onRemove } = this.props;
    onRemove(provider);
  }

  render() {
    const {
      containerClassName,
      provider,
      docImgSize,
      onRemove,
      showSuffix,
      isHighValueBadgeEnable
    } = this.props;
    // ugly hack! from the search engine we get taxonomiesCodes but from postgres we get taxonomies.
    const taxonomy = _.get(provider, 'taxonomies[0].desc') ||
      _.get(provider, 'taxonomies[0].taxonomyDisplayName') ||
      _.get(provider, 'taxonomiesCodes[0].desc');
    const providerDisplayName = showSuffix ?
      displayProvider(provider.firstName, provider.lastName, provider.suffix) :
      displayProvider(provider.firstName, provider.lastName);

    return (
      <div
        className={classNames('card mini-doctor-card', containerClassName, docImgSize, showSuffix)}
      >
        <div className="card-body">
          <div className="doctor-pic">
            <DoctorLogo provider={provider} isHighValueBadgeEnable={isHighValueBadgeEnable} />
          </div>
          <div className="doctor-info">
            <div className="dr-name text-16 text-semibold text-dark">
              {providerDisplayName}
            </div>
            <div className="doctor-speciality text-14">
              {taxonomy}
            </div>
            <div className="custom-section">
              {this.props.children}
            </div>
          </div>
        </div>
        {
          _.isFunction(onRemove) ?
            <button
              className="btn btn-inline-link card-close-icon"
              onClick={this.onRemoveClick}
              aria-label="Remove"
            >
              <i className="icon-x-2" />
            </button>
            :
            null
        }
      </div>
    );
  }
}

export default MiniDoctorCard;
