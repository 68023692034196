/**
 * Created by chenrozenes on 30/03/2017.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';
import { defineMessages, FormattedMessage } from 'react-intl';
import Joi from 'joi-browser';
import _ from 'lodash';

import { createValidator } from '../../../../../../utils/joiValidator';
import * as ResetPasswordFormDef from './resetPasswordFormDef';
import Recaptcha from '../../../../../../components/verification/bkmdRecaptcha';
import { schema } from '../../identificationSchema';
import SpinnerButton from '../../../../../../components/ui/spinner/spinnerButton';

const messages = defineMessages({
  yourMobileOrEmail: {
    defaultMessage: 'Your mobile number or email',
    id: 'members.resetPassword.yourMobileNumber',
  },
  resetYourPassword: {
    defaultMessage: 'Reset your password',
    id: 'members.resetPassword.resetYourPassword',
  },
  byClickingResetYourPassword: {
    defaultMessage:
      'By clicking Reset you password you agree to receive a text message required for completing this action',
    id: 'members.resetPassword.byClickingResetYourPassword',
  },
});

function IdentificationForm(props) {
  const isLoading = _.get(props.loadingTrack, 'inProgress', true);
  return (
    <div>
      <div className="row">
        <div className="col-xs-12 margin-top">
          <Field
            id="mobileNumberInput"
            label={messages.yourMobileOrEmail}
            {...ResetPasswordFormDef.fields.identifier}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-xs-12 text-center">
          <Recaptcha />
        </div>
      </div>

      <div className="row">
        <div className="col-xs-12 margin-top-30">
          <SpinnerButton
            className="btn btn-big bg-color-brand-button"
            isLoading={isLoading}
            onClick={props.handleSubmit}
          >
            <FormattedMessage {...messages.resetYourPassword} />
          </SpinnerButton>
        </div>
      </div>

      <div className="row">
        <div className="col-xs-12 margin-top-30">
          <div className="col-xs-12 text-center text-14">
            <FormattedMessage {...messages.byClickingResetYourPassword} />
          </div>
        </div>
      </div>
    </div>
  );
}

IdentificationForm.propTypes = {
  /**
   * Hook method. supplied by redux-forms
   */
  handleSubmit: PropTypes.func.isRequired,
  /**
   * redux action track object
   */
  loadingTrack: PropTypes.object.isRequired,
};

export default reduxForm({
  form: ResetPasswordFormDef.name,
  validate: createValidator(Joi.object().keys(schema)),
  destroyOnUnmount: false,
})(IdentificationForm);
