/**
 * Created by chenrozenes on 08/06/2016.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages } from 'react-intl';
import classNames from 'classnames';
import IntlString from '../ui/intlString/intlString';

import './backButton.less';

const messages = defineMessages({
  centered: {
    defaultMessage: 'Back to {item}',
    id: 'bookmd.components.modal.backButton.centered',
    values: {},
  },
});
const leftStr = ' ';
const centeredButtonClassNames = 'btn-transparent centered-back-btn';
const centeredIconClassNames = 'centered-back-btn-icon icon-back-arrow i-va-fix-2';
const leftButtonClassNames = 'btn-modal-header-back pull-left';
const leftIconClassNames = 'icon-back-arrow';

class ModalBackButton extends React.PureComponent {
  static propTypes = {
    centered: PropTypes.bool,
    onBack: PropTypes.func,
    text: PropTypes.object,
    previousPageName: PropTypes.string,
    className: PropTypes.string,
  };
  static defaultProps = {
    onBack: null,
    text: null,
    centered: false,
    previousPageName: undefined,
    className: '',
  };

  render() {
    const { centered, text, previousPageName, className, onBack } = this.props;
    messages.centered.values = { item: centered ? previousPageName : null };
    return (
      <button
        className={classNames('btn', {
          [centeredButtonClassNames]: centered,
          [leftButtonClassNames]: !centered
        }, className)}
        onClick={onBack}
        aria-label="Back"
      >
        <i
          className={classNames({
            [centeredIconClassNames]: centered,
            [leftIconClassNames]: !centered
          })}
        />
        <span>
          <IntlString message={text || centered ? messages.centered : leftStr} />
        </span>
      </button>
    );
  }
}

export default ModalBackButton;
