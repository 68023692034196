/**
 * Created by galgoltzman on 05/03/2017.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { ProgressBar } from 'react-bootstrap';
import { defineMessages } from 'react-intl';

import { IntervalComponent } from '../timeComponents';
import { IntlString, MessageShape } from '../intlString';
import Loader from '../../../storybook/src/components/atoms/Loader/Loader';

import './loadingPage.less';

const INTERVAL = 25;
const messages = defineMessages({
  processing: {
    defaultMessage: 'We\'re working on it!',
    id: 'loadingPage.title.processing',
  }
});
export default class LoadingPage extends IntervalComponent {
  static propTypes = {
    title: MessageShape,
    subTitle: MessageShape,
    /**
     * loading duration in milliseconds before navigation to next page
     * default is 10 seconds
     */
    duration: PropTypes.number,
    /**
     * Method hook, called on loading time complete
     */
    onComplete: PropTypes.func.isRequired,
  };

  static defaultProps = {
    duration: 10000,
    title: messages.processing.defaultMessage,
  };

  constructor(props) {
    super(props, INTERVAL);
    this.state = { progress: 0 };
  }

  timerTick() {
    const { duration, onComplete } = this.props;
    let { progress } = this.state;

    progress += (100 / duration) * INTERVAL;
    if (progress > 100) {
      super.clearInterval();
      onComplete();
    } else if (typeof window !== 'undefined') {
      // Fix ssr error - update state only on client side
      this.setState({ progress });
    }
  }

  render() {
    const { title, subtitle } = this.props;
    const { progress } = this.state;
    return (
      <div className="loading-page-wrap">
        <div className="loading-content">
          <Loader padding="none" />
          <h3 className="title-24 md-margin text-light text-dark">
            <IntlString message={title} />
          </h3>
          <h4 className="text-normal text-16 text-white progress-text">
            <IntlString message={subtitle} />
          </h4>
          <ProgressBar className="loading-progress" bsStyle="success" now={progress} />
        </div>
      </div>
    );
  }
}
