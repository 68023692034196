import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { autobind } from 'core-decorators';
import Griddle, { RowDefinition, plugins, components } from 'griddle-react';
import createClickableRow from './clickableRow';
import createClickableRowWithCustomComponent from './clickableRowWithCustomComponent';
import CountHeader from './countHeader';
import { MessageShape } from '../ui/intlString';
import CustomPagination from './pagination/customPagination';

import './bkmdGrid.less';

/**
 *  Component structure to render nothing for overriding Griddle defaults
 */
export const NoComponent = () => null;

const Noop = () => null;

const defaultClassNames = {
  Table: 'bkmd-table',
  TableHeading: 'bkmd-thead',
  Row: 'bkmd-tr',
  TableHeadingCell: 'bkmd-th',
  TableBody: 'bkmd-tbody',
  Cell: 'bkmd-td',
  RowDefinition: 'bkmd-tr',
};

/**
 * Bkmd general grid - wraps Griddle component
 */
@autobind
class BkmdGrid extends React.Component {
  static propTypes = {
    /**
     * Object with definitions of class names to griddle components
     */
    classNames: PropTypes.object,
    /**
     * Object with definitions of icons to griddle components
     */
    icons: PropTypes.object,
    /**
     * Hook function for clicking on a row
     */
    onRowClick: PropTypes.func,
    /**
     * Hook function for query change by the grid.
     * Limit can be changed by the "number of rows"
     * Skip can be changed by the pagination component
     * Sort can be changed by the columns themselves
     */
    onQueryChange: PropTypes.func,
    /**
     * Limit of the current data
     */
    limit: PropTypes.number,
    /**
     * Skip of the current data
     */
    skip: PropTypes.number,
    /**
     * Sorting preferences
     */
    sort: PropTypes.object,
    /**
     * True for showing the header of the grid
     */
    showHeader: PropTypes.bool,
    /**
     * Total number of records (regardless the limit and skip)
     */
    totalCount: PropTypes.number,
    /**
     * Array of data to display in the grid
     */
    data: PropTypes.array,
    /**
     * Title for the grid header
     */
    title: MessageShape,
    /**
     * Override griddle settings component - by default is disabled
     */
    settingsComponent: PropTypes.func,
    /**
     * Override griddle filter component - by default is disabled
     */
    filterComponent: PropTypes.func,
    /**
     * Custom Row Component for row footer - by default is disabled
     */
    customRowComponent: PropTypes.func,
    /**
     * When true, uses Griddle with local plugin that handle the data as local data and not external
     */
    local: PropTypes.bool,
    /**
     * The current view. Represents an enum value that should cause
     * a predetermined filter in the server when fetching
     */
    view: PropTypes.string,
    /**
     * Available view options
     */
    viewOptions: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        label: MessageShape.isRequired,
      }).isRequired
    ),
    /**
     * custom component to render inside the header
     */
    headerCustomComponent: PropTypes.node,
    customRowComponentEvents: PropTypes.object,

    showPagination: PropTypes.bool,
  };

  static defaultProps = {
    classNames: {},
    icons: {
      TableHeadingCell: {
        sortDescendingIcon: <i className="icon-chevron-down i-va-fix-2 text-12 text-light-blue" />,
        sortAscendingIcon: <i className="icon-chevron-up text-12 text-light-blue" />
      },
    },
    onRowClick: Noop,
    onQueryChange: Noop,
    limit: 10,
    skip: 0,
    sort: {},
    showHeader: true,
    totalCount: undefined,
    data: [],
    title: '',
    settingsComponent: NoComponent,
    filterComponent: NoComponent,
    customRowComponent: null,
    local: false,
    viewOptions: [],
    view: '',
    headerCustomComponent: null,
    customRowComponentEvents: {},
    showPagination: true,
  };

  constructor(props) {
    super(props);
    // Griddle has no onRowClick option, so we override the row component in Griddle
    // and use closure to get the wanted functionality
    this.rowComponent = !props.customRowComponent ?
      createClickableRow(props.onRowClick) :
      createClickableRowWithCustomComponent(props.onRowClick, props.customRowComponent,
        props.customRowComponentEvents);
  }

  onLimitChange(limit) {
    this.props.onQueryChange({ limit });
  }

  onViewChange(view) {
    this.props.onQueryChange({ view });
  }

  onNext() {
    const { onQueryChange, skip, limit } = this.props;
    onQueryChange({ skip: skip + limit });
  }

  onPrevious() {
    const { onQueryChange, skip, limit } = this.props;
    onQueryChange({ skip: skip - limit });
  }

  onGetPage(pageNumber) {
    const { onQueryChange, limit } = this.props;
    onQueryChange({ skip: (pageNumber - 1) * limit });
  }

  onSort(sortProperties = {}) {
    const { children } = this.props;
    const childForSort = _.find(children, child => child.props.id === sortProperties.id);
    if (childForSort && childForSort.props.sortable === false) return;
    const sortAscending = _.isBoolean(sortProperties.sortAscending) ?
      sortProperties.sortAscending : true;
    _.extend(sortProperties, { sortAscending });
    this.props.onQueryChange({ sort: sortProperties });
  }

  getStyle(style) {
    return _.extend({}, defaultClassNames, style);
  }

  render() {
    const {
      data,
      title,
      sort,
      limit,
      skip,
      totalCount,
      children,
      classNames: _classNames,
      icons,
      showHeader,
      settingsComponent,
      filterComponent,
      local,
      viewOptions,
      view,
      headerCustomComponent,
      showPagination,
      ...other
    } = this.props;
    // Disabling by default the filter and settings components
    const _components = {
      Pagination: showPagination ?
        components.PageDropdownContainer(CustomPagination) :
        components.PageDropdownContainer(() => null),
      Filter: filterComponent,
      SettingsWrapper: settingsComponent,
      Row: this.rowComponent, // Our clickable row component
    };

    const currentPage = Math.trunc(skip / limit) + 1;
    const _totalCount = totalCount || data.length;

    const _plugins = local ? [plugins.LocalPlugin] : undefined;
    const classNames = this.getStyle(_classNames);

    // Griddle adds properties to the data, so it needs to be mutable
    return (
      <div>
        {!showHeader ? null :
        <CountHeader
          title={title}
          totalCount={_totalCount}
          onLimitChange={this.onLimitChange}
          onViewChange={this.onViewChange}
          viewOptions={viewOptions}
          showRowsPerPage
          view={view}
          skip={skip}
          limit={limit}
          headerCustomComponent={headerCustomComponent}
        />
        }
        <Griddle
          data={data.asMutable ? data.asMutable() : data}
          plugins={_plugins}
          styleConfig={{ classNames, icons }}
          components={_components}
          sortProperties={sort}
          events={{
            onSort: this.onSort,
            onNext: this.onNext,
            onPrevious: this.onPrevious,
            onGetPage: this.onGetPage,
          }}
          pageProperties={{
            currentPage,
            pageSize: limit,
            recordCount: _totalCount,
          }}
          {...other}
        >
          <RowDefinition>
            {children}
          </RowDefinition>
        </Griddle>
      </div>
    );
  }
}

export default BkmdGrid;
