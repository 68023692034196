/**
 * Created by galgoltzman on 03/04/2018.
 */
import React from 'react';
import { autobind } from 'core-decorators';
import { injectIntl, defineMessages } from 'react-intl';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { compose, branch, renderComponent } from 'recompose';
import CoOpLogo from '../../../../components/branding/co_opLogo';
import RoundedImage from '../../../../components/ui/roundedImage/roundedImage';
import queryConnect from '../../../../store/tools/queryString/queryConnect';
import { withShortActivation } from '../../../../store/activation/withShortActivation';
import ActivationLanding from '../../../../components/activationLanding/activationLanding';
import { paths } from '../routes/activationRoutes';
import Header from '../../../../components/ui/header/header';
import inviteByVimGuide from './inviteByVimGuide';
import { getFeature } from '../../../../store/feature/selectors';
import { withTranslatedMessages } from '../../../../utils/withTranslatedMessages';

const defaultMessages = defineMessages({
  title: {
    defaultMessage: 'You have been invited to {insurerTitle}',
    id: 'members.inviteToVim.landing.title',
  },
  buttonText: {
    defaultMessage: 'Continue to {insurerTitle}',
    id: 'members.inviteToVim.landing.buttonText',
  },
});

const renderImage = function renderImage() {
  return (
    <span className="pic-wrap">
      <RoundedImage
        className="doctor-img big"
        src={require('../../../../assets/images/logos/vimHeart.png')}
      />
    </span>
  );
};
@autobind
class InviteToVimLanding extends React.Component {
  static propTypes = {
    intl: PropTypes.object.isRequired,
    params: PropTypes.object.isRequired,
    onActivateClicked: PropTypes.func.isRequired,
  };

  onClick() {
    const {
      onActivateClicked,
      params: { token },
      nextPath,
    } = this.props;
    const to = nextPath || paths.shortActivation(token);
    onActivateClicked(token, to);
  }

  render() {
    const { messages } = this.props;
    return (
      <div>
        <Header className="text-center">
          <div className="logos">
            <CoOpLogo externalPageLogo />
          </div>
        </Header>
        <ActivationLanding
          onActivateClicked={this.onClick}
          renderProfileImage={renderImage}
          {...messages}
        />
      </div>
    );
  }
}

export default compose(
  injectIntl,
  withRouter,
  queryConnect(query => ({
    vimGuideUserId: query.vimGuideUserId,
  })),
  connect(state => ({
    insurerTitle: getFeature(state, 'site.title'),
    nextPath: getFeature(state, 'shortActivation.nextPath'),
  })),
  withTranslatedMessages(({ messages }) => ({
    ...defaultMessages,
    ...messages,
  }), ({ insurerTitle }) => ({ insurerTitle })),
  branch(props => props.vimGuideUserId, renderComponent(inviteByVimGuide)),
  withShortActivation,
)(InviteToVimLanding);
