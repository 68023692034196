import _ from 'lodash';
import moment from 'moment';
import { autobind } from 'core-decorators';
import { getParameterByName, toQueryString } from '../../utils/queryString';

@autobind
class VimApiClass {
  constructor() {
    this.authorizationData = {};
  }

  initialize(apiUrl) {
    this.baseUrl = apiUrl;
  }

  _getAccessToken = () => getParameterByName('accessToken') || this.authorizationData.accessToken;

  _getApiKey = () => getParameterByName('apiKey') || this.authorizationData.apiKey;

  _defaultHeaders = (apiKey, accessToken) => ({
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Bearer ${this._getAccessToken() || accessToken}`,
    'x-api-key': this._getApiKey() || apiKey,
  });

  static _checkStatus = response => {
    if (response.status >= 200 && response.status < 300) {
      return response;
    }
    return response.text().then(responseText => {
      let message = response.statusText;
      try {
        const json = JSON.parse(responseText);
        message = json.responseMessage || message;
      } catch (e) {
        // I want application to not crash, but don't want eslint to yell at me
      }

      const error = new Error(message);
      error.responseMessage = message;
      error.responseText = responseText;
      error.status = response.status;
      throw error;
    });
  };

  _defaultFetch = (url, options, apiKey, accessToken) => {
    let customHeaders = options.headers || {};

    const xInsurer = getParameterByName('options.insurer');
    if (xInsurer) customHeaders = { ...customHeaders, 'x-insurer': xInsurer };

    return fetch(url, {
      ...options,
      headers: {
        ...this._defaultHeaders(apiKey, accessToken),
        ...customHeaders,
      },
    })
      .then(VimApiClass._checkStatus)
      .then(response => response.json());
  };

  _getJwt = async apiKey => {
    const response = await this._defaultFetch(
      `${this.baseUrl}auth/getJwt`,
      { method: 'GET' },
      apiKey,
    );
    // don't save authorizationData if not initialize with apiKey
    if (this.authorizationData.apiKey) {
      this.authorizationData.accessToken = response.data.access_token;
      this.authorizationData.refreshToken = response.data.refresh_token;
      this.authorizationData.tokenExpiry = moment(response.data.expiry);
    }
    return response.data.access_token;
  };

  _ensureAccessToken = async apiKey => {
    let accessToken = this._getAccessToken(apiKey);
    if (
      _.isEmpty(accessToken) ||
      (!getParameterByName('accessToken') && moment().isAfter(this.authorizationData.tokenExpiry))
    ) {
      accessToken = await this._getJwt(apiKey);
    }
    return accessToken;
  };

  get = async (path, params = {}, apiKey) => {
    const accessToken = await this._ensureAccessToken(apiKey);
    const filteredParams = _.pickBy(params, _.identity);
    const qs = toQueryString(filteredParams);
    const url = `${this.baseUrl}${path}?${qs}`;

    return this._defaultFetch(url, { method: 'GET' }, apiKey, accessToken);
  };

  post = async (path, body = {}, apiKey) => {
    const accessToken = await this._ensureAccessToken(apiKey);
    const url = `${this.baseUrl}${path}`;
    return this._defaultFetch(
      url,
      {
        method: 'POST',
        body: JSON.stringify(body),
      },
      apiKey,
      accessToken,
    );
  };

  initializeWithApiKey = async (apiUrl, apiKey) => {
    this.baseUrl = apiUrl;
    this.authorizationData.apiKey = apiKey;
    await this._ensureAccessToken();
  };

  getProviderByNpi = (npi, memberToken, insurer, apiKey) =>
    this.get(`provider/${npi}`, { memberToken, insurer }, apiKey);

  getBookingData = async payload => {
    const cleanedPayload = {};
    _.keys(payload).forEach(key => {
      if (!_.isEmpty(payload[key])) {
        cleanedPayload[key] = payload[key];
      }
    });
    const {
      npi,
      address,
      memberLanguage,
      startDateTime,
      endDateTime,
      npiType,
      code,
      memberToken,
      geoLatitude,
      geoLongitude,
    } = cleanedPayload;
    const result = await this.post(`provider/${npi}/bookingData/`, {
      address,
      startDateTime,
      endDateTime,
      npiType,
      code,
      memberToken,
      memberLanguage,
      geoLatitude,
      geoLongitude,
    });

    return result.data;
  };

  requestToBook({
    npi,
    address,
    appointmentTypeId,
    optionalTimes,
    timePreferences,
    reasonForVisit,
    member,
    insurer,
    geo,
    application,
  }) {
    return this.post('appointment/request', {
      npi,
      address,
      appointmentTypeId,
      optionalTimes,
      timePreferences,
      reasonForVisit,
      member,
      insurer,
      geo,
      application,
    });
  }

  identifyMember({ firstName, lastName, dateOfBirth, memberId, phoneNumber, insurer }) {
    return this.post('patient/internalIdentify', {
      firstName,
      lastName,
      dateOfBirth,
      memberId,
      phoneNumber,
      insurer,
    });
  }

  verifyTokens(memberToken, requestToken) {
    return this.post('member/verify', { memberToken, requestToken });
  }

  book({
    npi,
    address,
    appointmentTypeId,
    startDateTime,
    reasonForVisit,
    member,
    insurer,
    ehrInsuranceId,
    npiType,
    application,
    metadata,
  }) {
    return this.post('appointment/book', {
      npi,
      address,
      appointmentTypeId,
      startDateTime,
      reasonForVisit,
      member,
      insurer,
      ehrInsuranceId,
      npiType,
      application,
      metadata,
    });
  }

  getProviderAlternatives({ npi, address, plan, code, nextAvailableSlot, memberLanguage, memberToken }) {
    return this.get('provider/alternatives', {
      npi,
      address,
      plan,
      code,
      nextAvailableSlot,
      memberLanguage,
      memberToken,
    }).then(res => res.providers);
  }

  getProviderInsurers({ npi, address }) {
    return this.post('provider/insurers', { npi, address });
  }

  getProviderAvailability({ providers, memberToken, insurer, apiKey }) {
    return this.post(
      'provider/availability',
      { providers, memberToken, insurer },
      apiKey,
    ).then(({ data }) => _.keyBy(data, 'npi'));
  }

  appointmentRequestRequeue(requestToken) {
    return this.post('appointment/request/requeue', {
      requestToken,
    });
  }

  cancelAppointment({ appointmentId, memberLanguage, application }) {
    return this.post('appointment/cancel', { appointmentId, memberLanguage, application });
  }

  getAppointment({ appointmentId }) {
    return this.get(`appointment/${appointmentId}`);
  }

  reschedule({ appointmentId, startDateTime, memberLanguage, application }) {
    return this.post('appointment/reschedule', {
      appointmentId,
      newStartDateTime: startDateTime,
      memberLanguage,
      application,
    });
  }

  optOutAppointment({ optOutToken }) {
    return this.post('appointment/optOut', {
      optOutToken,
    });
  }

  getRequestProvidersData({ requestToken }) {
    return this.post('appointment/getRequestProvidersData', {
      requestToken,
    });
  }

  submitReview({ appointmentId, rating, comment }) {
    return this.post('appointment/review', {
      appointmentId,
      rating,
      comment,
    });
  }

  bookingConcierge({
    npi,
    address,
    appointmentTypeId,
    startDateTime,
    reasonForVisit,
    member,
    requestToken,
    geo,
    application,
  }) {
    return this.post('appointment/concierge', {
      npi,
      address,
      appointmentTypeId,
      startDateTime,
      reasonForVisit,
      member,
      requestToken,
      geo,
      application,
    });
  }
}

export default new VimApiClass();
