import { autobind } from 'core-decorators';
import ServicesApi from './services';
import BkmdApi from './bkmdApi';

const SERVICE_NAME = 'providerOnBoarding';

/**
 * Represents the search doctors API
 */
@autobind
export default class ProviderOnBoardingApi extends BkmdApi {
  constructor(fetch) {
    super(fetch, '/providerOnBoarding/');
    this.servicesApi = new ServicesApi(fetch);
  }
  scanProfilePicsS3Action(payload) {
    return this.servicesApi
      .callSecure(SERVICE_NAME, 'scanProfilePicsFromS3', payload)
      .then(res => res.data);
  }

  testCrop(npi, url, pictures) {
    return this.servicesApi
      .callSecure(SERVICE_NAME, 'testCrop', { npi, url, pictures })
      .then(result => result.data);
  }
}
