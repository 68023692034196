import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';

import SpinnerButton from '../../components/ui/spinner/spinnerButton';
import AppointmentDetailsWidget from '../../components/appointmentDetails/appointmentDetailsWidget';

@autobind
export default class ReviewDetails extends React.PureComponent {
  static propTypes = {
    appointment: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    service: PropTypes.object.isRequired,
    tracker: PropTypes.object.isRequired,
    onClick: PropTypes.func.isRequired,
    titleText: PropTypes.string,
    buttonText: PropTypes.string,
    showSelectedAppointmentType: PropTypes.bool,
  };

  static defaultProps = {
    titleText: '',
    buttonText: '',
    showSelectedAppointmentType: true,
  };

  // TODO: pass render prop for widget
  render() {
    const {
      onClick,
      appointment,
      location,
      service,
      tracker,
      titleText,
      buttonText,
      showSelectedAppointmentType,
    } = this.props;

    return (
      <div className="confirmation">
        <div className="title-24 md-margin text-strong font-color-brand-main text-center">
          {titleText}
        </div>
        <AppointmentDetailsWidget
          showSelectedAppointmentType={showSelectedAppointmentType}
          appointment={appointment}
          location={location}
          service={service}
        />

        <SpinnerButton
          className="bg-color-brand-button btn btn-big btn-block margin-top-30"
          isLoading={_.get(tracker, 'inProgress', false)}
          disabled={_.get(tracker, 'inProgress', false)}
          onClick={onClick}
        >
          {buttonText}
        </SpinnerButton>
      </div>
    );
  }
}
