import _ from 'lodash';

class RoutesBuilder {
  static getRoutes(options, strategies) {
    return strategies.reduce((finalRoutes, strategy) => {
      const strategyRoutes = strategy.predicate(options)
        ? _.castArray(strategy.getRoutes(options))
        : [];

      return [...finalRoutes, ...strategyRoutes];
    }, []);
  }
}

export default RoutesBuilder;
