/**
 * created by moran on 11/16/17
 */
import PromiseActionType from '../promiseActionType';

export const GET_BOOKING_DATA = new PromiseActionType('GET_BOOKING_DATA');
export const SCHEDULING_GET_AVAILABLE_SERVICES = new PromiseActionType('SCHEDULING_GET_AVAILABLE_SERVICES');
export const BOOK_APPOINTMENT = new PromiseActionType('BOOK_APPOINTMENT');
export const PROVIDER_BOOK_APPOINTMENT = new PromiseActionType('PROVIDER_BOOK_APPOINTMENT');
export const SELECT_APPOINTMENT = 'SELECT_APPOINTMENT';
export const SELECT_APPOINTMENT_TYPE = 'SELECT_APPOINTMENT_TYPE';
export const ADD_REASON_FOR_VISIT = 'ADD_REASON_FOR_VISIT';
export const MAX_ANSWER_LENGTH = 200;
export const MAX_REASON_LENGTH = 200;

export function getBookingData(npi, locationId, trackingContext = '') {
  return ({ bkmdApi: { schedulingApi } }) => ({
    type: GET_BOOKING_DATA.SOURCE,
    payload: {
      promise: schedulingApi.getBookingData(npi, locationId),
    },
    meta: {
      tracker: `${GET_BOOKING_DATA.SOURCE}-${npi}-${trackingContext}`,
    }
  });
}

export function getAvailableAppointmentTypes(calendarId) {
  return ({ bkmdApi: { schedulingApi } }) => ({
    type: SCHEDULING_GET_AVAILABLE_SERVICES.SOURCE,
    payload: {
      promise: schedulingApi.getAvailableAppointmentTypes(calendarId)
    },
  });
}

export function book(npi, locationId, appointmentTypeId, startTime, endTime, reasonForVisit) {
  const appointment = {
    npi,
    locationId,
    appointmentTypeId,
    startTime,
    endTime,
    reasonForVisit,
  };
  return ({ bkmdApi: { schedulingApi }, dispatch }) => ({
    type: BOOK_APPOINTMENT.SOURCE,
    payload: {
      promise: schedulingApi.patientBookAppointment(appointment)
        .then(res => res.data)
        .tap(() => dispatch(getBookingData(npi, locationId)))
    },
    meta: {
      tracker: BOOK_APPOINTMENT.SOURCE,
    },
  });
}

export function bookShare(
  shareToken,
  npi,
  locationId,
  appointmentTypeId,
  startTime,
  endTime,
  reasonForVisit,
) {
  const appointment = {
    npi,
    locationId,
    appointmentTypeId,
    startTime,
    endTime,
    reasonForVisit,
  };
  return ({ bkmdApi: { schedulingApi } }) => ({
    type: BOOK_APPOINTMENT.SOURCE,
    payload: {
      promise: schedulingApi.shareBookAppointment(shareToken, appointment).then(res => res.data),
    },
    meta: {
      tracker: BOOK_APPOINTMENT.SOURCE,
    },
  });
}

export function selectAppointmentTime(npi, locationId, appointmentTypeId, appointment) {
  return {
    type: SELECT_APPOINTMENT,
    payload: { npi, locationId, appointmentTypeId, ...appointment },
  };
}

export function selectAppointmentType(appointmentType) {
  return {
    type: SELECT_APPOINTMENT_TYPE,
    payload: appointmentType,
  };
}

export function addReasonForVisit(reason) {
  return {
    type: ADD_REASON_FOR_VISIT,
    payload: reason,
  };
}
