import { autobind } from 'core-decorators';
import BkmdApi from './bkmdApi';
import ServicesApi from './services';

const SERVICE_NAME = 'providerProfile';

@autobind
export default class ProviderProfileApi extends BkmdApi {
  constructor(fetch) {
    super(fetch, '/providerProfile/');
    this.servicesApi = new ServicesApi(fetch);
  }

  getByUserId() {
    return this.get('/');
  }

  createProfile(profile, domains) {
    return this.post('/', { profile, domains });
  }

  getAllProviderProfiles(skip, limit, search, sort, sortAscending) {
    return this.servicesApi.callSecure(SERVICE_NAME, 'getAll', {
      limit,
      skip,
      search,
      sort,
      sortAscending,
    });
  }

  getById(id) {
    return this.servicesApi.callSecure(SERVICE_NAME, 'getById', { id });
  }
}
