/**
 * Created by chenrozenes on 19/02/2017.
 */
export const ACCEPTED = 'ACCEPTED';
export const REQUESTED = 'REQUESTED';
export const CANCELED = 'CANCELED';
export const CANCELED_BY_BACKPACK = 'CANCELED_BY_BACKPACK';
export const CHECKED_IN = 'CHECKED_IN';
export const ARCHIVED = 'ARCHIVED';
export const PENDING_VERIFICATION = 'PENDING_VERIFICATION';
export const PENDING = 'PENDING';
export const RESCHEDULE = 'RESCHEDULE';
export const FAILED_TO_BOOK = 'FAILED_TO_BOOK';
