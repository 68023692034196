import React from 'react';
import PropTypes from 'prop-types';
import BrandedH1 from '../../../storybook/src/components/atoms/BrandedHeading/BrandedHeading';
import Text from '../../../storybook/src/components/atoms/Text/Text';

const SideDrawerHeader = ({ title, description }) => (
  <div>
    {title && (
      <BrandedH1 margin="medium">
        {title}
      </BrandedH1>
    )}
    {description && (
      <Text
        className="margin-bottom-30 text-center"
        size="16px"
        color="dark"
      >
        {description}
      </Text>
    )}
  </div>
);

SideDrawerHeader.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};

SideDrawerHeader.defaultProps = {
  title: '',
  description: '',
};

export default SideDrawerHeader;
