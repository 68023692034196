import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { compose, withProps, withHandlers } from 'recompose';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import Layout from '../../layout/layout';
import DirectoryDoctorDetails from './directoryDoctorDetails';
import queryConnect from '../../../../../store/tools/queryString/queryConnect';
import {
  extractChosenCode,
  extractFilters,
  extractLocationId,
  extractOpenBookingModal,
  extractRanking,
} from '../directorySelectors';
import routingSelectors from '../../../../../store/routing/selectors';
import paths from '../../routes/secure/securePaths';
import { withPromptRedirect } from '../../../../../components/router/withPromptRedirect';
import Api from '../../../../../api';
import { getMemberSessionId } from '../../../../../utils/storage/memberSession';
import SearchAction from '../../../../../model/enum/searchAction';

function DoctorDetailsPage({
  params,
  filters,
  locationId,
  openBookingModal,
  chosenCode,
  queryParams,
  goToSecureDoctorDetails,
  onShareSuccess,
  onBookSuccess,
}) {
  const npi = _.get(params, 'npi', null);
  return (
    <div className="doctor-details-wrap">
      <Layout
        hideLogo
        centeredBackBtn
        previousPageName="results"
        previousPageRoute={paths.searchDoctorsResults(queryParams)}
        footerClassName="not-fixed white"
      >
        <DirectoryDoctorDetails
          chosenCode={chosenCode}
          filters={filters}
          npi={npi}
          locationId={locationId}
          goToSecureDoctorDetails={goToSecureDoctorDetails}
          openBookingModal={openBookingModal}
          onShareSuccess={onShareSuccess}
          onBookSuccess={onBookSuccess}
        />
      </Layout>
    </div>
  );
}

DoctorDetailsPage.propTypes = {
  params: PropTypes.object,
  filters: PropTypes.object.isRequired,
  locationId: PropTypes.string,
  queryParams: PropTypes.object.isRequired,
  openBookingModal: PropTypes.bool,
  chosenCode: PropTypes.string,
  goToSecureDoctorDetails: PropTypes.func.isRequired,
  onShareSuccess: PropTypes.func.isRequired,
  onBookSuccess: PropTypes.func.isRequired,
};

DoctorDetailsPage.defaultProps = {
  params: null,
  locationId: null,
  openBookingModal: false,
  chosenCode: null,
};

export default compose(
  withPromptRedirect,
  connect(
    state => ({
      queryParams: routingSelectors.currRouteQuery(state),
    }),
    { push },
  ),
  queryConnect(query => ({
    locationId: extractLocationId(query),
    filters: extractFilters(query),
    openBookingModal: extractOpenBookingModal(query),
    choseCode: extractChosenCode(query),
    queryId: query.queryId,
    ranking: extractRanking(query),
  })),
  withProps(({ params }) => ({
    npi: _.get(params, 'npi', null),
  })),
  withHandlers({
    goToSecureDoctorDetails: ({ npi, redirect, queryParams }) => () => {
      const doctorDetailsPath = paths.doctorDetails(npi, {
        ...queryParams,
        openBookingModal: true,
      });
      redirect(doctorDetailsPath);
    },
    onShareSuccess: ({ npi, queryId, ranking, locationId }) => () => {
      if (queryId && ranking) {
        Api.searchApi
          .searchActionAnalytics({
            queryId,
            memberSessionId: getMemberSessionId(),
            npi,
            locationId,
            ranking,
            actionType: SearchAction.SHARE,
          })
          .catch();
      }
    },
    onBookSuccess: ({ queryId, ranking, npi, locationId }) => (entityId, bookingType) => {
      if (queryId && ranking) {
        Api.searchApi
          .searchActionAnalytics({
            queryId,
            memberSessionId: getMemberSessionId(),
            npi,
            locationId,
            ranking,
            actionType: bookingType,
            entityId,
          })
          .catch();
      }
    },
  }),
)(DoctorDetailsPage);
