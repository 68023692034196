import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { compose, withHandlers } from 'recompose';

function SelectOptions({ options, onClick, value, displayField }) {
  return (
    <div data-id="select-options" className="first-visit-buttons">
      {_.map(options, option => {
        const selected = value === option;
        return (
          <button
            key={option.id}
            className={classNames('btn btn-big btn-outline btn-block btn-break-line', {
              selected,
            })}
            onClick={e => onClick(e, option)}
            value={option.id}
          >
            {option[displayField]}
          </button>
        );
      })}
    </div>
  );
}

SelectOptions.propTypes = {
  options: PropTypes.array.isRequired,
  onClick: PropTypes.func.isRequired,
  value: PropTypes.object,
  displayField: PropTypes.string.isRequired,
};
SelectOptions.propTypes = {
  value: undefined,
};

function SelectOne({ headerText, options, onClick, value, displayField }) {
  return (
    <div>
      <div className="title-24 margin-top text-strong font-color-brand-main text-center">
        {headerText}
      </div>
      <SelectOptions
        options={options}
        onClick={onClick}
        value={value}
        displayField={displayField}
      />
    </div>
  );
}

SelectOne.propTypes = {
  options: PropTypes.array.isRequired,
  displayField: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  headerText: PropTypes.string,
  value: PropTypes.object,
};

SelectOne.defaultProps = {
  headerText: '',
  displayField: 'description',
  value: undefined,
};

export default compose(
  withHandlers({
    onClick: ({ onClick }) => (e, option) => {
      e.preventDefault();
      onClick(option);
    },
  }),
)(SelectOne);
