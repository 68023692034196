/**
 * Created by galgoltzman on 08/08/2017.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import _ from 'lodash';
import { defineMessages, FormattedMessage } from 'react-intl';

import CardListLayout from '../layout/cardListLayout/cardListLayout';
import CareCueItem from './careCueItem';
import ThreeBounceSpinner from '../../../../components/ui/spinner/threeBounceSpinner';
import InformationByPremera from '../help/informationByPremera';

import actionTracker from '../../../../store/tools/actionTracker/actionTrackerComponent';
import Folder from '../../../../assets/svg/folder';
import * as selectors from '../../../../store/member/selectors';
import { getCareCues, GET_CARE_CUES } from '../../../../store/member/actions';

import './careCues.less';

const messages = defineMessages({
  yourCareCues: {
    defaultMessage: 'Your care cues',
    id: 'members.careCues.yourCareCues',
  },
  nothingToSee: {
    defaultMessage: 'Nothing to see here',
    id: 'members.careCues.nothingToSee',
  },
});

class CareCues extends React.PureComponent {
  static propTypes = {
    getCareCues: PropTypes.func.isRequired,
    careCuesAction: PropTypes.object.isRequired,
    careCues: PropTypes.array.isRequired,
  };

  componentWillMount() {
    this.props.getCareCues();
  }

  renderContent() {
    const { careCuesAction, careCues } = this.props;
    if (careCuesAction.inProgress) {
      return <ThreeBounceSpinner />;
    }

    if (_.isEmpty(careCues)) {
      return (
        <div className="text-center margin-top-30 padding-v-30">
          <Folder />
          <br />
          <div className="text-18">
            <FormattedMessage {...messages.nothingToSee} />
          </div>
        </div>
      );
    }

    return _.map(careCues, careCue => <CareCueItem careCue={careCue} key={careCue.gapId} />);
  }

  render() {
    return (
      <CardListLayout
        title={messages.yourCareCues}
        listClassName="care-cues-list"
        blueHeaderClass="white no-icon-header"
      >
        {this.renderContent()}
        <InformationByPremera />
      </CardListLayout>
    );
  }
}

export default compose(
  actionTracker({
    careCuesAction: GET_CARE_CUES.SOURCE,
  }),
  connect(
    state => ({
      careCues: selectors.getCareCues(state),
    }),
    { getCareCues },
  ),
)(CareCues);
