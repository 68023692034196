import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import { defineMessages, FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import SvgCalendarFold from '../../../../../assets/svg/calendarFold';
import SimpleProgressPage from '../../../../../components/ui/layouts/simpleProgressPage';
import { isDirectoryAllowed } from '../../../../../store/ui/selectors';

const messages = defineMessages({
  signIn: {
    defaultMessage: 'Sign in to book a new <br> appointment.',
    id: 'members.appointment.signIn',
  },
  youCanBook: {
    defaultMessage:
      'You can book another appointment with the same doctor or search for ' +
      'other doctors in your network.',
    id: 'members.appointment.youCanBook',
  },
  bookAppointment: {
    defaultMessage: 'Book a new appointment',
    id: 'members.appointment.bookAppointment',
  },
});

@autobind
class CancelledAppointment extends React.Component {
  static propTypes = {
    router: PropTypes.object.isRequired,
    rescheduleAllowed: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  gotoHome() {
    this.props.router.push('/signin');
  }

  renderBook() {
    const { rescheduleAllowed } = this.props;

    return rescheduleAllowed ? (
      <div className="row margin-top-30">
        <div className="col-xs-12">
          <button className="btn btn-big" onClick={this.gotoHome}>
            <FormattedMessage {...messages.bookAppointment} />
          </button>
        </div>
      </div>
    ) : null;
  }

  render() {
    return (
      <SimpleProgressPage
        title=""
        progress={null}
        caption=""
        img=""
        showLogos={false}
        showRightNavInMobile
      >
        <div className="cancel-appointment-flow">
          <div className="top-section text-center">
            <SvgCalendarFold />
            <h1 className="title-24 md-margin font-color-brand-main">
              <strong>
                <FormattedHTMLMessage {...messages.signIn} />
              </strong>
            </h1>
            <div className="text-18">
              <FormattedMessage {...messages.youCanBook} />
            </div>
          </div>
          {this.renderBook()}
        </div>
      </SimpleProgressPage>
    );
  }
}

export default compose(
  withRouter,
  connect(state => ({
    rescheduleAllowed: isDirectoryAllowed(state),
  })),
)(CancelledAppointment);
