/**
 * Created by matan on 10/9/17.
 */
import _ from 'lodash';
import { NOT_ACCEPTABLE } from 'http-status';
import { defineMessages } from 'react-intl';

import { showNotification } from '../notification/actions';
import { resetRecaptch } from './actions';

const messages = defineMessages({
  errorTitle: {
    defaultMessage: 'Human validation',
    id: 'recaptch.error.title',
  },
  errorContent: {
    defaultMessage: "Please check that you're not a robot",
    id: 'recaptch.error.message',
  },
});

export default ({ dispatch }) => next => action => {
  if (action.error && _.get(action, 'payload.status') === NOT_ACCEPTABLE) {
    dispatch(resetRecaptch());
    dispatch(
      showNotification({
        title: messages.errorTitle,
        message: messages.errorContent,
        level: 'error',
      }),
    );
  }

  next(action);
};
