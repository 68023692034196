import { log } from '../../../utils/logger';

export default {
  onStart: name => next => props => {
    log('FETCH_START', name);
    next(props);
  },

  onSuccess: name => next => (props, response) => {
    log('FETCH_SUCCESS', name, response);
    next(props, response);
  },
  onError: name => next => (props, error) => {
    log('FETCH_ERROR', name, error);
    next(props, error);
  },
};
