/**
 * Created by galgoltzman on 04/12/2017.
 */
import React from 'react';
import momentz from 'moment-timezone';
import PropTypes from 'prop-types';
import Formatter from '../../../../utils/formatter';
import CommonFormats from '../../../../utils/formatter/commonFormats';
import displayProvider from '../../../../utils/displayProvider';

class AppointmentDetailsList extends React.PureComponent {
  static propTypes = {
    appointment: PropTypes.object.isRequired,
  };

  render() {
    const { appointment } = this.props;
    if (!appointment) return null;
    const { startTime, provider, timeZone } = appointment;
    return (
      <div>
        <ul className="appt-details-list">
          <li>
            <i className="item-icon icon-calendar-sm" />
            <div className="text-16 text-semibold text-dark">
              {Formatter.date(
                momentz.tz(startTime, timeZone),
                CommonFormats.SHORT_NAMING_WITH_YEAR,
              )}
            </div>
            <div className="text-14">{Formatter.time(momentz.tz(startTime, timeZone))}</div>
          </li>
          <li>
            <i className="item-icon icon-user-2" />
            <div className="text-16 text-semibold text-dark">
              {displayProvider(provider.firstName, provider.lastName, provider.suffix)}
            </div>
            <div className="text-14">{provider.taxonomies[0].desc}</div>
          </li>
          <li>
            <i className="item-icon icon-map-pin-e" />
            <div className="text-16 text-semibold text-dark">
              {appointment.address.addressLine1}
            </div>
            <div className="text-14">
              {appointment.address.city}, {appointment.address.state} {appointment.address.zip}
            </div>
          </li>
        </ul>
      </div>
    );
  }
}

export default AppointmentDetailsList;
