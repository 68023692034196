/* eslint-disable react/no-unused-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router';
import moment from 'moment';
import { defineMessages, FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { autobind } from 'core-decorators';

import SignInForm from './signInForm';
import NeedHelpModal from '../help/helpModal';
import TimeBasedContent from './timeBasedContent';
import BlueFooter from '../../../../components/molecules/BlueFooter/BlueFooter';
import Header from '../../../../components/ui/header/header';
import CoOpLogo from '../../../../components/branding/co_opLogo';
import actionTracker from '../../../../store/tools/actionTracker/actionTrackerComponent';
import injectNotification from '../../../../store/notification/injectNotification';
import FeatureFlagged from '../../../../components/features/featureFlagged';
import loginErrors from '../../../../components/pages/auth/loginErrors';
import LanguageMenu from '../../../../components/molecules/LanguageMenu/LanguageMenu';

import { goToNext } from '../../../../utils/reRouting';
import { createIdentifier } from './identificationSchema';
import { testLocalStorage } from '../../../../store/platform/actions';
import { isLocalStorageAccessible } from '../../../../store/platform/selectors';
import { MemberLogin } from '../../../../store/tools/analytics/events/memberEvents';

import SvgSigninMorning from '../../../../assets/svg/signinMorning';
import SvgSigninAfternoon from '../../../../assets/svg/signinAfternoon';
import SvgSigninEvening from '../../../../assets/svg/signinEvening';
import SvgSigninNight from '../../../../assets/svg/signinNight';

import {
  LOGIN_ACTION_TYPE,
  login,
  getUser,
  rememberLoginIdentifier,
} from '../../../../store/auth/actions';

import './signIn.less';
import { getFeature } from '../../../../store/feature/selectors';
import { pathsWithQuery } from '../../../../utils/pathsWithQuery';
import queryConnect from '../../../../store/tools/queryString/queryConnect';

const messages = defineMessages({
  goodMorning: {
    defaultMessage: 'Good morning',
    id: 'members.signIn.goodMorning',
  },
  goodAfternoon: {
    defaultMessage: 'Good afternoon',
    id: 'members.signIn.goodAfternoon',
  },
  goodEvening: {
    defaultMessage: 'Good evening',
    id: 'members.signIn.goodEvening',
  },
  goodNight: {
    defaultMessage: 'Howdy',
    id: 'members.signIn.goodNight',
  },
  forgotPassword: {
    defaultMessage: 'Forgot password?',
    id: 'members.signIn.forgotPassword',
  },
  help: {
    defaultMessage: 'Help',
    id: 'members.signIn.help',
  },
  continueAsAGuest: {
    defaultMessage: 'Continue as a guest',
    id: 'members.signIn.continueAsAGuest',
  },
  loginErrorTitle: {
    defaultMessage: "Sorry, we couldn't log you in",
    id: 'members.login.error.loginErrorTitle',
  },
  loginPage: {
    defaultMessage: 'sign in',
    id: 'members.login.loginPage',
  },
});

@autobind
class SignIn extends React.Component {
  static propTypes = {
    /**
     * Injected prop for notifying on errors
     */
    notification: PropTypes.object.isRequired,
    /**
     * Redux function to login.
     */
    login: PropTypes.func.isRequired,
    /**
     * Redux function to get user info.
     */
    getUser: PropTypes.func.isRequired,
    rememberLoginIdentifier: PropTypes.func.isRequired,
    /**
     * Server request action tracking.
     */
    loginTracking: PropTypes.object.isRequired,
    /**
     * Router object.
     * Provided by the withRouter HoC
     */
    router: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    testLocalStorage: PropTypes.func.isRequired,
    isLocalStorageAccessible: PropTypes.bool.isRequired,
    bannerHeader: PropTypes.object.isRequired,
    bannerMessage: PropTypes.object.isRequired,
    bannerLinkMessage: PropTypes.object.isRequired,
    bannerUrl: PropTypes.string.isRequired,
    next: PropTypes.string,
    qs: PropTypes.string,
  };

  static contextTypes = {
    paths: PropTypes.object.isRequired,
    analytics: PropTypes.object.isRequired,
  };

  static defaultProps = {
    next: null,
    qs: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
      mounted: false,
    };

    this.timeImageRules = [
      {
        from: moment('0', 'HH'),
        to: moment('4', 'HH'),
        img: <SvgSigninNight />,
        caption: messages.goodNight,
      },
      {
        from: moment('5', 'HH'),
        to: moment('11', 'HH'),
        img: <SvgSigninMorning />,
        caption: messages.goodMorning,
      },
      {
        from: moment('12', 'HH'),
        to: moment('16', 'HH'),
        img: <SvgSigninAfternoon />,
        caption: messages.goodAfternoon,
      },
      {
        from: moment('17', 'HH'),
        to: moment('21', 'HH'),
        img: <SvgSigninEvening />,
        caption: messages.goodEvening,
      },
      {
        from: moment('22', 'HH'),
        to: moment('23', 'HH'),
        img: <SvgSigninNight />,
        caption: messages.goodNight,
      },
    ];
  }

  componentWillMount() {
    this.props.testLocalStorage();
  }

  componentDidMount() {
    this.setState({ mounted: true }); // eslint-disable-line react/no-did-mount-set-state
  }

  componentWillReceiveProps(nextProps) {
    const { auth: currentAuth, loginTracking: currentLoginTracking } = this.props;
    const { loginTracking, auth, getUser } = nextProps;

    const isLoginJustFinished = loginTracking.finished && !currentLoginTracking.finished;

    if (!nextProps.isLocalStorageAccessible) {
      this.props.router.push('/safari');
    }

    // Go to the next page only in case the user was verified
    if (auth.accessToken && auth.user && currentAuth.loading && !nextProps.auth.loading) {
      const {
        router,
        location: { query },
      } = this.props;

      // Report login to analytics
      this.context.analytics.identify(auth.user.id);
      this.context.analytics.track(MemberLogin);

      goToNext(router, query, '/secure');
    }

    if (isLoginJustFinished && auth.expiredToken && !auth.loading) {
      const {
        router,
        location: { query },
      } = this.props;
      goToNext(router, query, '/renewExpiredPassword');
    }
    if (
      isLoginJustFinished &&
      !loginTracking.hasError &&
      !auth.expiredToken &&
      !auth.loading &&
      !auth.user
    ) {
      getUser();
    }

    if (isLoginJustFinished && loginTracking.hasError) {
      const error = loginErrors.findByStatus(_.get(auth, 'responseMessage'));
      if (this.loginErrorReroute(error)) return;

      this.props.notification.error(error.title || messages.loginErrorTitle, error.message, {
        autoDismiss: 10,
      });
    }
  }

  onSignIn(data) {
    const identifier = _.get(createIdentifier(data), 'identifier');
    if (data.rememberMe) this.props.rememberLoginIdentifier(identifier);
    this.props.login(identifier, data.password);
  }

  loginErrorReroute(error) {
    const { router } = this.props;
    let rerouted = true;

    switch (error) {
      case loginErrors.LOGIN_FAILED_PASSWORD_EXPIRED:
        router.push('resetPassword');
        break;
      case loginErrors.ACCOUNT_LOCKED:
        router.push('blockedAccount');
        break;
      default:
        rerouted = false;
    }

    return rerouted;
  }

  gotoForgotPassword() {
    this.props.router.push(pathsWithQuery('/resetPassword', this.props.location.query));
  }

  gotoOpenDirectory() {
    this.props.router.push('/public');
  }

  openModal() {
    this.setState({ isModalOpen: true });
  }

  closeModal() {
    this.setState({ isModalOpen: false });
  }

  goToActivation() {
    const { router, next, qs } = this.props;
    router.push(pathsWithQuery('/start/activation', { next, qs }));
  }

  render() {
    const { bannerHeader, bannerMessage, bannerLinkMessage, bannerUrl } = this.props;
    if (!this.state.mounted) {
      return <div />;
    }

    return (
      <div className="members-signin-wrap">
        <Header className="text-center">
          <div className="logos">
            <CoOpLogo externalPageLogo />
          </div>

          <ul className="header-right-nav">
            <FeatureFlagged renderLi uri="layout.changeLanguage">
              <LanguageMenu id="languageMenuSignIn" />
            </FeatureFlagged>
          </ul>
        </Header>

        <FeatureFlagged uri="loginScreen.topBanner.display">
          <div className="top-banner">
            <div className="bg-color-brand-main-dark text-white padding-box-20">
              <div className="row">
                <div className="col-sm-8">
                  <div className="text-18 text-semibold">
                    <FormattedMessage {...bannerHeader} />
                  </div>
                  <div className="text-14">
                    <FormattedHTMLMessage {...bannerMessage} />
                  </div>
                </div>
                <div className="col-sm-4">
                  <a
                    href={bannerUrl}
                    rel="noopener noreferrer"
                    target="_blank"
                    className="btn btn-big btn-white-border pull-right btn-transparent"
                  >
                    <FormattedMessage {...bannerLinkMessage} />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </FeatureFlagged>

        <main className="container container-1040 has-footer footer-not-fixed">
          <div className="row">
            <div className="col-lg-6 col-lg-offset-3 col-sm-8 col-sm-offset-2">
              <div className="content">
                <div className="top-img-wrap">
                  <TimeBasedContent rules={this.timeImageRules} className="signin-top-img" alt="" />
                </div>
                <SignInForm
                  onSubmit={this.onSignIn}
                  onCreateAccount={this.goToActivation}
                  auth={this.props.auth}
                  requireCaptcha={this.props.auth.requireCaptcha}
                />
                <FeatureFlagged uri="openDirectory">
                  <div className="row">
                    <div className="col-xs-12 text-center">
                      <button
                        className="btn btn-inline-link font-color-brand-link text-semibold"
                        onClick={this.gotoOpenDirectory}
                      >
                        <FormattedMessage {...messages.continueAsAGuest} />
                      </button>
                    </div>
                  </div>
                </FeatureFlagged>
                <div className="row signin-help-footer margin-top-20">
                  <div className="col-xs-12 text-center">
                    <button
                      className="btn btn-inline-link font-color-brand-link"
                      onClick={this.gotoForgotPassword}
                    >
                      <FormattedMessage {...messages.forgotPassword} />
                    </button>
                    <button
                      className="btn btn-inline-link font-color-brand-link"
                      onClick={this.openModal}
                    >
                      <span>
                        <FormattedMessage {...messages.help} />
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>

        <BlueFooter footerClassName="signin-footer white not-fixed-vh" />

        <NeedHelpModal isOpen={this.state.isModalOpen} handleClose={this.closeModal} />
        <h1 className="off-screen-text">
          <FormattedMessage {...messages.loginPage} />
        </h1>
      </div>
    );
  }
}

export default compose(
  queryConnect(query => ({
    next: query.next,
    qs: query.qs,
  })),
  actionTracker({
    loginTracking: LOGIN_ACTION_TYPE.SOURCE,
  }),
  connect(
    state => ({
      auth: state.auth,
      isLocalStorageAccessible: isLocalStorageAccessible(state),
      bannerHeader: getFeature(state, 'loginScreen.topBanner.header'),
      bannerMessage: getFeature(state, 'loginScreen.topBanner.message'),
      bannerLinkMessage: getFeature(state, 'loginScreen.topBanner.linkText'),
      bannerUrl: getFeature(state, 'loginScreen.topBanner.linkAction'),
    }),
    { getUser, rememberLoginIdentifier, testLocalStorage, login },
  ),
  injectNotification,
  withRouter,
)(SignIn);
/* eslint-enable react/no-unused-prop-types */
