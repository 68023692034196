/* eslint-disable import/first */
if (typeof require.ensure !== 'function') require.ensure = (d, c) => c(require);

import Home from '../home/home';
import divComponent from './divComponent';

// Routes
const DoctorDetailsPage = require('../directory/doctorDetails/doctorDetailsPage');
const SearchDoctorsResults = require('../directory/searchDoctorsResults/searchDoctorsResults');

// Components
const Components = [
  {
    path: 'searchDoctorsResults',
    name: 'searchDoctorsResults',
    component: SearchDoctorsResults.default || SearchDoctorsResults,
  },
  {
    path: 'doctorDetails/:npi',
    name: 'doctorDetails',
    component: DoctorDetailsPage.default || DoctorDetailsPage,
  },
];

export default [
  {
    path: 'directory',
    name: 'directory',
    getChildRoutes(location, cb) {
      require.ensure([], () => {
        cb(null, Components);
      });
    },
    // Requiring secured page NOT dynamically so it won't be unmounted every route
    component: divComponent,
    indexRoute: {
      name: 'Home',
      component: Home.default || Home,
    },
  },
];
