/**
 * Get session Id
 * If doesn't exist, generate it
 * return null on SSR!!
 * @returns {*}
 */
let storage;

/**
 * Small polyfill to make sure our system works on Safari's private mode as well,
 * in a nutshell session storage is not available on Safari's private mode, but instead of removing
 * sessionStorage object from window it just throws an Error whenever it's being accessed,
 * this is a super simple in-memory polyfill.
 * Did I already say that Safari is the new IE ?
 * @returns {*}
 */
export function getSessionStorage() {
  if (!storage) {
    try {
      if (!window.sessionStorage) throw new Error();
      sessionStorage.setItem('testKey', '1');
      sessionStorage.removeItem('testKey');
      storage = sessionStorage;
    } catch (error) {
      storage = {
        _data: {},
        setItem(id, val) {
          val = String(val);
          this._data[id] = String(val);
          return val;
        },
        getItem(id) {
          return this._data[id] ? this._data[id] : undefined;
        },
        removeItem(id) {
          return delete this._data[id];
        },
        clear() {
          this._data = {};
          return this._data;
        }
      };
    }
  }
  return storage;
}
