import ColorectalFlowPage from '../colorectalFlow/colorectalFlowPage';
import ColorectalFlowActivationPage from '../colorectalFlow/colorectalFlowActivationPage';

export default [
  {
    path: 'screeningkit',
    name: 'colorectalFlowActivation',
    component: ColorectalFlowActivationPage,
  },
  {
    path: 'screeningkit/:token',
    name: 'colorectalFlow',
    component: ColorectalFlowPage,
  },
];
