import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, intlShape } from 'react-intl';
import { autobind } from 'core-decorators';
import doctorCompose from '../doctorReviewHOC';
import IntlEnum from '../../../../../utils/intlEnum';
import paths from '../routes/paths';
import PillsReviewPage from './pillsReviewPage';
import * as PositiveOfficeEnum from '../../../../../components/doctorCard/sections/reviewPositiveOptions/positiveOfficeOptions';
import './doctorReview.less';
import displayProvider from '../../../../../utils/displayProvider';
import SvgHospital from '../../../../../assets/svg/hospital';

const messages = defineMessages({
  whatDidYouLikeMostAboutOffice: {
    defaultMessage: 'What did you like most about {providerDisplayName}’s office?',
    id: 'members.doctorReview.whatDidYouLikeMostAboutDrSmithsOffice',
  },
  easyScheduling: {
    defaultMessage: 'Easy scheduling',
    id: 'members.doctorReview.easyScheduling',
  },
  cleanComfy: {
    defaultMessage: 'Clean + comfy',
    id: 'members.doctorReview.cleanComfy',
  },
  friendlyStaff: {
    defaultMessage: 'Friendly staff',
    id: 'members.doctorReview.friendlyStaff',
  },
  shortWaitTimes: {
    defaultMessage: 'Short wait times',
    id: 'members.doctorReview.shortWaitTimes',
  },
  next: {
    defaultMessage: 'Next',
    id: 'members.doctorReview.next',
  },
});

const PositiveEnum = new IntlEnum(
  { value: PositiveOfficeEnum.EASY_SCHEDULING, message: messages.easyScheduling },
  { value: PositiveOfficeEnum.CLEAN_AND_COMFY, message: messages.cleanComfy },
  { value: PositiveOfficeEnum.FRIENDLY_STAFF, message: messages.friendlyStaff },
  { value: PositiveOfficeEnum.SHORT_WAIT_TIME, message: messages.shortWaitTimes },
);

@autobind
class PositiveRatingOffice extends React.Component {
  static propTypes = {
    router: PropTypes.object.isRequired,
    params: PropTypes.object.isRequired,
    intl: intlShape.isRequired,
    reviewProcess: PropTypes.object.isRequired,
  };

  onNext() {
    const {
      router,
      params: { token },
    } = this.props;
    router.push(paths.doctorTextReview(token));
  }

  onBack() {
    const {
      router,
      params: { token },
    } = this.props;
    router.push(paths.positiveDoctor(token));
  }

  render() {
    const {
      intl,
      reviewProcess: {
        provider: { suffix, firstName, lastName },
      },
    } = this.props;
    const providerDisplayName = displayProvider(firstName, lastName, suffix);

    const message = intl.formatMessage(messages.whatDidYouLikeMostAboutOffice, {
      providerDisplayName,
    });

    return (
      <PillsReviewPage
        token={this.props.params.token}
        onNext={this.onNext}
        onBack={this.onBack}
        intlEnum={PositiveEnum}
        name="positiveOffice"
        icons="heart-icons"
        icon={<SvgHospital />}
        headerMessage={message}
      />
    );
  }
}
export default doctorCompose(PositiveRatingOffice);
