import _ from 'lodash';
import { compose, withHandlers, withPropsOnChange, lifecycle, withState } from 'recompose';
import { buildQueryParams, buildQueryString } from './buildQueryString';

export const withQueryString = compose(
  withState('queryString', 'setQueryString', window.location.search),
  lifecycle({
    componentDidMount() {
      const { setQueryString } = this.props;
      window.onpopstate = () => {
        setQueryString(window.location.search);
      };
    },
    componentWillUnmount() {
      window.onpopstate = _.noop;
    },
  }),
);

export const fetchFromQuery = mapQueryToProps =>
  withPropsOnChange(['queryString'], props => {
    const queryParams = buildQueryParams(props.queryString);
    return mapQueryToProps ? mapQueryToProps(queryParams, props) : queryParams;
  });

export function rebuildQueryString(newQueryParams) {
  const queryParams = buildQueryParams(window.location.search);
  return buildQueryString(Object.assign({}, queryParams, newQueryParams));
}

export const withHistoryActions = withHandlers({
  push: () => newQueryParams => {
    window.history.pushState(null, null, rebuildQueryString(newQueryParams));
  },
  replace: () => newQueryParams => {
    window.history.replaceState(null, null, rebuildQueryString(newQueryParams));
  },
});
