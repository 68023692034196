import _ from 'lodash';

export function getStorageItemValue(key) {
  if (_.isNil(sessionStorage)) return undefined;
  return sessionStorage.getItem(key);
}

export function removeStorageItem(key) {
  if (_.isNil(sessionStorage)) return undefined;
  return sessionStorage.removeItem(key);
}

export function addOrUpdateStorageItem(key, value) {
  if (_.isNil(sessionStorage)) return undefined;
  return sessionStorage.setItem(key, value);
}

// { [key1] : value1 , [key2] : value2 , [keyn] : valuen...}
export function getStorageAsObject() {
  return Object.keys(sessionStorage).reduce((obj, key) => {
    obj[key] = sessionStorage.getItem(key);
    return obj;
  }, {});
}
