/**
 * Created by galgoltzman on 07/06/2017.
 */

import React from 'react';
import { autobind } from 'core-decorators';
import { connect } from 'react-redux';
import { compose, branch, renderComponent } from 'recompose';
import { defineMessages, FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import BkmdModal from '../../bkmdModal/bkmdModal';
import ModalHeader from '../../bkmdModal/modalHeader';
import SvgHelpStars from '../../../assets/svg/helpStars';
import { BkmdDomains } from '../../../model/enum/bkmdDomain';
import FindIdNumModalAllsavers from './FindIdNumModalAllsavers';
import { domain } from '../../../store/config/selectors';
import './FindIdNumModal.less';

const messages = defineMessages({
  modalText: {
    defaultMessage:
      'Below are some of the common formats for how a member ID may be shown on an insurance card.',
    id: 'activation.insuranceID.modalText',
  },
  modalTitle: {
    defaultMessage: 'Hints for entering the ID',
    id: 'activation.insuranceID.modalHeader',
  },
  whereCanIFind: {
    defaultMessage: 'Where can I find my ID number?',
    id: 'activation.insuranceID.whereCanIFind',
  },
  hintsOnEnteringTheMemberIdNumber: {
    defaultMessage: 'Hints on entering the member ID number.',
    id: 'activation.insuranceID.hintsOnEnteringTheMemberIdNumber',
  },
  insuranceIdNumber: {
    defaultMessage: 'Insurance ID number',
    id: 'activation.insuranceID.insuranceIdNumber',
  },
});

@autobind
class FindIdNumModal extends React.PureComponent {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    /**
     * Name for identifying via analytics
     */
    name: PropTypes.string, // eslint-disable-line react/no-unused-prop-types
  };

  static defaultProps = {
    name: 'find_id_number_modal',
  };

  render() {
    const { isOpen, handleClose, name } = this.props;
    return (
      <div>
        <BkmdModal
          isOpen={isOpen}
          handleClose={handleClose}
          name={name}
          className="id-num-help-modal fixed-header"
        >
          <ModalHeader onClose={handleClose} title={messages.modalTitle} />
          <div className="dialog-body">
            <div className="row">
              <div className="col-xs-12 text-center">
                <SvgHelpStars />
              </div>
            </div>
            <div className="row margin-top-30">
              <div className="col-xs-12 text-center">
                <span className="text-18 text-semibold text-dark">
                  <FormattedMessage {...messages.hintsOnEnteringTheMemberIdNumber} />
                </span>
              </div>
            </div>
            <div className="row margin-top-30">
              <div className="col-xs-12 text-16">
                <FormattedHTMLMessage {...messages.modalText} />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6 text-center">
                <img
                  src={require('./images/idCardHelp1.png')}
                  className="insurance-id-modal-main-img"
                  alt=""
                />
              </div>
              <div className="col-sm-6 text-center">
                <img
                  src={require('./images/idCardHelp2.png')}
                  className="insurance-id-modal-main-img"
                  alt=""
                />
              </div>
              <div className="col-sm-6 text-center">
                <img
                  src={require('./images/idCardHelp3.png')}
                  className="insurance-id-modal-main-img"
                  alt=""
                />
              </div>
              <div className="col-sm-6 text-center">
                <img
                  src={require('./images/idCardHelp4.png')}
                  className="insurance-id-modal-main-img"
                  alt=""
                />
              </div>
            </div>
          </div>
        </BkmdModal>
      </div>
    );
  }
}

export default compose(
  connect(state => ({
    domain: domain(state),
  })),
  branch(
    props => props.domain === BkmdDomains.UNITED_HEALTH_CARE_ALLSAVERS,
    renderComponent(FindIdNumModalAllsavers),
  ),
)(FindIdNumModal);
