/**
 * Created by moran on 6/6/17.
 */
import PromiseActionType from '../promiseActionType';
import { PodrickFactory } from '../app/podrick';
import { getPublicProviderDetails } from '../directory/provider/actions';

export const GET_REVIEW_PROCESS_BY_TOKEN = new PromiseActionType('GET_REVIEW_PROCESS_BY_TOKEN');
export const UPDATE_APPOINTMENT_REVIEW = new PromiseActionType('UPDATE_APPOINTMENT_REVIEW');
export const GET_REVIEW_PROVIDER = new PromiseActionType('GET_REVIEW_PROVIDER');

function getReviewProcessByToken(params) {
  const token = params.token;
  return ({ bkmdApi: { feedbackSurveyApi } }) => ({
    type: GET_REVIEW_PROCESS_BY_TOKEN.SOURCE,
    meta: {
      tracker: GET_REVIEW_PROCESS_BY_TOKEN.SOURCE,
    },
    payload: {
      promise: feedbackSurveyApi.getReviewProcessByToken(token)
    },
  });
}

function getReviewProvider() {
  return ({ dispatch, getState }) => {
    const npi = getState().reviewProcess.providerNpi;
    const action = {
      type: GET_REVIEW_PROVIDER.SOURCE,
      meta: {
        tracker: GET_REVIEW_PROVIDER.SOURCE,
      },
      payload: {
        promise: dispatch(getPublicProviderDetails(npi))
      },
    };
    return action;
  };
}

function updateReview({ token, ...delta }) {
  return ({ bkmdApi: { feedbackSurveyApi } }) => ({
    type: UPDATE_APPOINTMENT_REVIEW,
    payload: {
      promise: feedbackSurveyApi.updateReview(token, delta)
    },
  });
}

const { actions, selectors } = PodrickFactory.create({
  key: 'reviewProcess',
  updateAction: updateReview,
});

export { actions, selectors, getReviewProcessByToken, getReviewProvider };
