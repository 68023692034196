import React from 'react';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import _ from 'lodash';
import { compose } from 'redux';
import { withRouter } from 'react-router';
import queryConnect from '../../store/tools/queryString/queryConnect';
import BkmdGrid from './bkmdGrid';
import DebounceSearch from './debounceSearch';
import { MessageShape } from '../ui/intlString';

export const gridQueryParams = query => ({
  limit: parseInt(query.limit, 10),
  skip: parseInt(query.skip, 10),
  search: query.search,
  sort: query.sort,
  sortAscending: query.sortAscending === 'true',
  filter: query.filter,
  view: query.view,
});

export const gridQueryParamsProps = {
  limit: PropTypes.number.isRequired,
  skip: PropTypes.number.isRequired,
  search: PropTypes.string,
  sort: PropTypes.string,
  sortAscending: PropTypes.bool,
  filter: PropTypes.object,
  view: PropTypes.string,
};

/**
 * Wrap for BkmdGrid that uses the query string params to manage the data (limit, skip etc.)
 */
@autobind
class QueryStringGrid extends React.Component {
  static propTypes = {
    ...gridQueryParamsProps,
    onRowClick: PropTypes.func,
    router: PropTypes.object.isRequired,
    customRowComponent: PropTypes.func,
    limit: PropTypes.number.isRequired,
    setInQueryString: PropTypes.func.isRequired,
    onQueryChange: PropTypes.func,
    allowSearch: PropTypes.bool,
    searchLabel: PropTypes.string,
    viewOptions: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        label: MessageShape.isRequired,
      }).isRequired,
    ),
    headerCustomComponent: PropTypes.node,
  };

  static defaultProps = {
    onRowClick: _.noop,
    allowSearch: false,
    sort: undefined,
    sortAscending: undefined,
    filter: {},
    search: undefined,
    searchLabel: undefined,
    limit: 0,
    skip: 0,
    view: '',
    viewOptions: [],
    headerCustomComponent: null,
    customRowComponent: null,
    onQueryChange: _.noop,
  };

  componentWillReceiveProps(nextProps) {
    if (
      (!isNaN(nextProps.limit) &&
        !isNaN(this.props.limit) &&
        nextProps.limit !== this.props.limit) ||
      (!isNaN(nextProps.skip) && !isNaN(this.props.skip) && nextProps.skip !== this.props.skip) ||
      nextProps.search !== this.props.search ||
      nextProps.sort !== this.props.sort ||
      nextProps.sortAscending !== this.props.sortAscending ||
      nextProps.filter !== this.props.filter ||
      nextProps.view !== this.props.view
    ) {
      this.props.onQueryChange(
        nextProps.skip,
        nextProps.limit,
        nextProps.search,
        nextProps.sort,
        nextProps.sortAscending,
        nextProps.filter,
        nextProps.view,
      );
    }
  }

  onQueryChange(diff) {
    const shouldBeSorted =
      diff.sort && _.defaultTo(this.getFieldProp(diff.sort.id, 'sortable'), true);
    if (shouldBeSorted) {
      diff = { sort: this.getSortField(diff.sort.id), sortAscending: diff.sort.sortAscending };
    }
    this.props.setInQueryString(diff);
  }

  onSearchChanged(value) {
    this.onQueryChange({ search: value });
  }

  getFieldProp(columnId, propName) {
    const { children } = this.props;
    const selectedCoulmn = _.find(children, child => child.props.id === columnId);
    return _.get(selectedCoulmn, `props.${propName}`);
  }

  getSortField(columnId) {
    const { children } = this.props;
    const selectedCoulmn = _.find(children, child => child.props.id === columnId);
    const {
      props: { id, sortField },
    } = selectedCoulmn;
    return sortField || id;
  }

  getIdField(sortField) {
    const { children } = this.props;
    const selectedCoulmn = _.find(
      children,
      child => child.props.id === sortField || child.props.sortField === sortField,
    );
    if (!selectedCoulmn) return '';
    const {
      props: { id },
    } = selectedCoulmn;
    return id;
  }

  renderSearch() {
    if (!this.props.allowSearch) return <div></div>;

    return (
      <DebounceSearch
        label={this.props.searchLabel}
        text={this.props.search}
        onSearch={this.onSearchChanged}
      />
    );
  }

  renderGrid() {
    const { children, sort, sortAscending, ...other } = this.props;
    const _sort = { id: this.getIdField(sort), sortAscending };

    return (
      <BkmdGrid {...other} sort={_sort} onQueryChange={this.onQueryChange}>
        {children}
      </BkmdGrid>
    );
  }

  render() {
    return (
      <div>
        {this.renderSearch()}
        {this.renderGrid()}
      </div>
    );
  }
}

export default compose(
  withRouter,
  queryConnect(gridQueryParams, (query, props) =>
    _.extend(
      {},
      props.defaultLimit ? { limit: props.defaultLimit } : {},
      props.defaultSkip ? { skip: props.defaultSkip } : {},
      props.defaultSearch ? { search: props.defaultSearch } : {},
      props.defaultSort ? { sort: props.defaultSort } : {},
    ),
  ),
)(QueryStringGrid);
