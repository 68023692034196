import { autobind } from 'core-decorators';
import BkmdApi from './bkmdApi';

// eslint-disable-next-line
const SERVICE_NAME = 'auth';

/**
 * Represents the auth API
 */
@autobind
class AuthApi extends BkmdApi {
  /**
   * A fetch object that points to the server
   * @param fetch
   */
  constructor(fetch) {
    super(fetch, '/auth/');
  }

  /**
   * Sends a login request
   * @param identifier
   * @param password
   * @returns {*}
   */
  login(identifier, password) {
    return this.post('login', {
      identifier,
      password,
    });
  }

  /**
   * Sends a openId login request
   * @param token
   * @param redirectUri
   * @returns {*}
   */
  auth0Login(token, redirectUri) {
    return this.post('auth0Login', {
      token,
      redirectUri,
    });
  }

  /**
   * Sends a login request with captcha
   * @param identifier
   * @param password
   * @param captchaText
   * @returns {*}
   */
  loginCaptcha(identifier, password) {
    return this.post('loginCaptcha', {
      identifier,
      password,
    });
  }

  /**
   * Verifies if the current token is valid
   *
   * @returns {*}
   */
  getUser() {
    return this.get('me');
  }

  /**
   * Gets the user Acl permissions
   * @returns {Promise.<*>}
   */
  getPermissions() {
    return this.get('permissions');
  }

  /**
   * Refreshes the current token using the refresh token
   * @returns {*}
   */
  refresh(refreshToken) {
    return this.post('refresh', { refresh_token: refreshToken });
  }

  /**
   * @returns {*}
   */
  renewExpiredPassword(currentPassword, newPassword, expiredToken) {
    return this.post('renewExpiredPassword', {
      expired_token: expiredToken,
      currentPassword,
      newPassword,
    });
  }

  /**
   * Send a request to reset the user password
   * @param identifier - {identifier - the identifying string(email/phone) ,
   *                      type - 'email'\'phone'}
   */
  resetPasswordRequest({ identifier, type }) {
    return this.post('resetPasswordRequest', { identifier, type });
  }

  /**
   * Send a request to reset the user password
   * @param identifier - {identifier - the identifying string(email/phone) ,
   *                      type - 'email'\'phone'}
   */
  resendResetPasswordRequest({ identifier, type }) {
    return this.post('resendResetPasswordRequest', { identifier, type });
  }

  /**
   * Resets the user's password if the token is correct
   * @param identifier
   * @param password
   * @param token
   */
  resetPassword(identifier, password, token) {
    return this.post('resetPassword', {
      identifier,
      password,
      token,
    });
  }

  /**
   * Verifies the users reset token
   * @param identifier
   * @param token
   */
  verifyResetToken(identifier, token) {
    return this.post('verifyResetToken', {
      identifier,
      token,
    });
  }
}

export default AuthApi;
