/**
 * Created by chenrozenes on 09/04/2017.
 */
import _ from 'lodash';
import PromiseActionType from '../../promiseActionType';

export const GET_PROVIDER_LOCATIONS = new PromiseActionType(
  'GET_PROVIDER_LOCATIONS',
);

export const SEARCH_PROVIDERS = new PromiseActionType(
  'SEARCH_PROVIDERS',
);

export const GET_PROVIDER_FOR_BACKOFFICE = new PromiseActionType(
  'GET_PROVIDER_FOR_BACKOFFICE',
);
export const GET_ALL_PROVIDERS = new PromiseActionType('GET_ALL_PROVIDERS');
export const UPDATE_PROVIDER_VIDEO = new PromiseActionType(
  'UPDATE_PROVIDER_VIDEO',
);
export const GET_ALL_TAXONOMIES = new PromiseActionType('GET_ALL_TAXONOMIES');

export function getAllProviders(
  skip = 0,
  limit = 0,
  search,
  sort,
  sortAscending,
) {
  return ({ bkmdApi: { providerApi } }) => ({
    type: GET_ALL_PROVIDERS.SOURCE,
    payload: {
      promise: providerApi
        .getAllProviders(skip, limit, search, sort, sortAscending)
        .then(res => res.data),
    },
  });
}

export function searchProviderByNameOrNpi(search, domain) {
  return ({ bkmdApi: { searchApi } }) => ({
    type: SEARCH_PROVIDERS.SOURCE,
    payload: {
      promise: searchApi.searchByNameOrNpi(search,
        domain)
        .then(res => {
          const providers = res.data;
          return _.map(providers, provider => provider.data);
        })
    },
  });
}

export function getProviderLocations(npi, skip, limit, search, sort, sortAscending) {
  return ({ bkmdApi: { providerApi } }) => ({
    type: GET_PROVIDER_LOCATIONS.SOURCE,
    payload: {
      promise: providerApi.getProviderLocations(npi, skip, limit, search, sort, sortAscending)
        .then(res => res.data),
    },
  });
}

export function getProviderByNpi(npi) {
  return ({ bkmdApi: { providerApi } }) => ({
    type: GET_PROVIDER_FOR_BACKOFFICE.SOURCE,
    payload: {
      promise: providerApi.byNpiForBackoffice(npi).then(res => res.data),
    },
  });
}

export function updateProviderVideo(npi, videoObj) {
  return ({ bkmdApi: { providerApi }, dispatch }) => ({
    type: UPDATE_PROVIDER_VIDEO.SOURCE,
    payload: {
      promise: providerApi.updateVideo(npi, videoObj).then(() => {
        dispatch(getProviderByNpi(npi));
      }),
    },
    meta: {
      tracker: UPDATE_PROVIDER_VIDEO.SOURCE,
    },
  });
}

export function getAllTaxonomies() {
  return ({ bkmdApi: { rawProviderApi } }) => ({
    type: GET_ALL_TAXONOMIES.SOURCE,
    payload: {
      promise: rawProviderApi.getAllTaxonomies().then(res => res.data),
    },
    meta: {
      tracker: GET_ALL_TAXONOMIES.SOURCE,
    },
  });
}
