/**
 * Created by matan on 7/11/16.
 */
import Immutable from 'seamless-immutable';

import {
  LOAD_SNAP_ENGAGE,
  SNAP_ENGAGE_MESSAGE_COUNT,
  LOAD_HELP_SCOUT_BEACON,
  LOAD_GOOGLE_PLACES_API,
  INIT_SDK_API,
} from './actions';

const initialState = {
  snapEngage: { isReady: false, missedMessageCount: 0 },
  helpScout: { isReady: false },
  isGooglePlacesApiLoaded: false,
  isSdkLoaded: false,
};

export default function help(state = initialState, action) {
  state = Immutable(state);
  switch (action.type) {
    case `${LOAD_SNAP_ENGAGE}_SUCCESS`: {
      return state.set('snapEngage', state.snapEngage.merge({ isReady: true, ...action.payload }));
    }
    case SNAP_ENGAGE_MESSAGE_COUNT: {
      return state.setIn(['snapEngage', 'missedMessageCount'], action.payload);
    }
    case `${LOAD_HELP_SCOUT_BEACON}_SUCCESS`: {
      return state.set('helpScout', { ...action.payload, isReady: true });
    }
    case `${LOAD_GOOGLE_PLACES_API}_SUCCESS`: {
      return state.set('isGooglePlacesApiLoaded', action.payload);
    }
    case `${INIT_SDK_API}_SUCCESS`: {
      return state.set('isSdkLoaded', true);
    }
  }

  return state;
}
