import React from 'react';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import SideDrawerHeader from '../../molecules/SideDrawerHeader/SideDrawerHeader';

@autobind
export default class SideDrawerContent extends React.PureComponent {
  static propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    formComponent: PropTypes.func.isRequired,
  };

  static defaultProps = {
    title: '',
    description: '',
  };

  render() {
    const { title, description, formComponent: FormComponent, ...rest } = this.props;
    return (
      <div>
        <SideDrawerHeader title={title} description={description} />
        <div>
          <FormComponent {...rest} />
        </div>
      </div>
    );
  }
}
