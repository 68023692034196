import { autobind } from 'core-decorators';
import BkmdApi from './bkmdApi';

// eslint-disable-next-line
const SERVICE_NAME = 'externalLinks';

/**
 * Represents the search doctors API
 */
@autobind
class ExternalLinksApi extends BkmdApi {
  /**
   * A fetch object that points to the server
   * @param fetch
   */
  constructor(fetch) {
    super(fetch, '/externalLinks');
  }

  allegiance(url) {
    return this._updateHTMLResponse('/allegiance', { url }, 'post');
  }

  myChart(url) {
    return this._updateHTMLResponse('/myChart', { url }, 'post');
  }
}

export default ExternalLinksApi;
