/**
 * Created by galgoltzman on 15/08/2017.
 */
import React from 'react';
import SvgBirthdayCake from '../../svg/birthdayCake';
import SvgBoardCertification from '../../svg/boardCertification';
import SvgCalendar from '../../svg/calendar';
import SvgWorkStars from '../../svg/workStars';
import SvgWavingHand from '../../svg/wavingHand';
import SvgUserId from '../../svg/userId';
import SvgUserCircle from '../../svg/userCircle';
import SvgurgentCare from '../../svg/urgentCare';
import SvgTax from '../../svg/tax';
import SvgStethoscope from '../../svg/stethoscope';
import SvgSmartphone from '../../svg/smartphone';
import SvgSigninNight from '../../svg/signinNight';
import SvgSigninMorning from '../../svg/signinMorning';
import SvgSigninEvening from '../../svg/signinEvening';
import SvgSigninAfternoon from '../../svg/signinAfternoon';
import SvgSex from '../../svg/sex';
import SvgSearchUser from '../../svg/searchUser';
import SvgSearchUser2 from '../../svg/search-user';
import SvgPremeraIcon from '../../svg/premeraIcon';
import SvgPharmacies from '../../svg/pharmacies';
import SvgOtherFacilities from '../../svg/otherFacilities';
import SvgNotificationsStars from '../../svg/notificationsStars';
import SvgNoResults from '../../svg/noResults';
import SvgNewPatientsPlus from '../../svg/newPatientsPlus';
import SvgMug from '../../svg/mug';
import SvgMedication from '../../svg/medication';
import SvgMedal from '../../svg/medal';
import SvgLocationStars from '../../svg/locationStars';
import SvgLocation from '../../svg/location';
import SvgLanguage from '../../svg/language';
import SvgLabs from '../../svg/labs';
import SvgKey from '../../svg/key';
import SvgInsurance from '../../svg/insurance';
import SvgImaging from '../../svg/imaging';
import SvgHourglass from '../../svg/hourglass';
import SvgHospital from '../../svg/hospital';
import SvgHomeStars from '../../svg/homeStars';
import SvgHelpStars from '../../svg/helpStars';
import SvgHelp from '../../svg/help';
import SvgHeartBubble from '../../svg/heart-bubble';
import Folder from '../../svg/folder';
import SvgFaxStars from '../../svg/faxStars';
import SvgEnvelopeStars from '../../svg/envelopeStars';
import SvgEnvelope from '../../svg/envelope';
import SvgEducation from '../../svg/education';
import SvgDosage from '../../svg/dosage';
import SvgDoctor from '../../svg/doctor';
import SvgCodeStars from '../../svg/codeStars';
import SvgCodeExpStars from '../../svg/codeExpStars';
import SvgClock from '../../svg/clock';
import SvgCellphoneStars from '../../svg/cellphoneStars';
import SvgCalendarUpcoming from '../../svg/calendarUpcoming';
import CalendarSm from '../../svg/calendarSm';
import SvgCalendarPlus from '../../svg/calendarPlus';
import SvgCalendarPending from '../../svg/calendarPending';
import SvgCalendarMinus from '../../svg/calendarMinus';
import SvgCalendarM from '../../svg/calendarM';
import SvgCalendarIncomplete from '../../svg/calendarIncomplete';
import SvgCalendarFold from '../../svg/calendarFold';
import SvgCalendarComplete from '../../svg/calendarComplete';
import SvgCalendarArchived from '../../svg/calendarArchived';
import SvgUserQuestionMark from '../../svg/userQuestionMark';
import SvgShieldCheck from '../../svg/shieldCheck';
import SvgCactus from '../../svg/cactus';
import SvgClap from '../../svg/clap';
import SvgDoctorCircle from '../../svg/doctorCircle';
import SvgDoctorFemaleStars from '../../svg/doctorFemaleStars';
import SvgDoctorMaleStars from '../../svg/doctorMaleStars';
import SvgDoctorStuffStars from '../../svg/doctorStuffStars';
import SvgFlowersStars from '../../svg/flowersStars';
import SvgGlobeStars from '../../svg/globeStars';
import SvgHeartLock from '../../svg/heartLock';
import SvgHome from '../../svg/home';
import SvgLocation2 from '../../svg/location2';
import SvgMedicalTeam from '../../svg/medicalTeam';
import SvgSearchDoctor from '../../svg/searchDoctor';
import SvgSpeechBubbleSmile from '../../svg/speechBubbleSmile';
import SvgUsersCheck from '../../svg/usersCheck';
import SvgVimHeart from '../../svg/vimHeart';
import SvgUserCircleQ from '../../svg/userCircleQ';
import SvgDoctorsCheckC from '../../svg/doctorsCheckC';
import SvgDoctorStars from '../../svg/doctorStars';
import SvgEnvelopeStars2 from '../../svg/envelopeStars2';
import SvgCalendarStars from '../../svg/calendarStars';
import SvgFlag from '../../svg/flag';
import SvgHandReminder from '../../svg/handReminder';
import SvgPencilStars from '../../svg/pencilStars';
import SvgSpeechBubbleDots from '../../svg/svgSpeechbubbleDots';
import SvgWarningCheck from '../../svg/warningCheck';
import SvgAdultFemaleAvatar from '../../svg/avatars/adultFemaleAvatar';
import SvgAdultMaleAvatar from '../../svg/avatars/adultMaleAvatar';
import SvgBoyAvatar from '../../svg/avatars/boyAvatar';
import SvgGirlAvatar from '../../svg/avatars/girlAvatar';
import SvgPoo from '../../svg/poo/poo';
import SvgPooBox from '../../svg/poo/pooBox';
import SvgPooBoxArrow from '../../svg/poo/pooBoxArrow';
import SvgPooBoxCheck from '../../svg/poo/pooBoxCheck';
import SvgPooBoxOpen from '../../svg/poo/pooBoxOpen';
import SvgPooStrars from '../../svg/poo/pooStars';

import './iconsDemo.less';

const IconsDemo = function IconsDemo() {
  return (
    <div className="icons-demo-page">

      <div className="title-24 text-dark text-light margin-10">
        Svg icons
      </div>

      <SvgUserCircleQ />
      <SvgVimHeart />
      <SvgUsersCheck />
      <SvgSpeechBubbleSmile />
      <SvgSearchDoctor />
      <SvgMedicalTeam />
      <SvgLocation2 />
      <SvgHome />
      <SvgHeartLock />
      <SvgGlobeStars />
      <SvgFlowersStars />
      <SvgDoctorStuffStars />
      <SvgDoctorMaleStars />
      <SvgDoctorFemaleStars />
      <SvgDoctorCircle />
      <SvgClap />
      <SvgCactus />
      <SvgBirthdayCake />
      <SvgBoardCertification />
      <SvgCalendar />
      <SvgWorkStars />
      <SvgWavingHand />
      <SvgUserId />
      <SvgUserCircle />
      <SvgurgentCare />
      <SvgTax />
      <SvgStethoscope />
      <SvgSmartphone />
      <SvgSigninNight />
      <SvgSigninMorning />
      <SvgSigninEvening />
      <SvgSigninAfternoon />
      <SvgSex />
      <SvgSearchUser />
      <SvgSearchUser2 />
      <SvgPremeraIcon />
      <SvgPharmacies />
      <SvgOtherFacilities />
      <SvgNotificationsStars />
      <SvgNoResults />
      <SvgNewPatientsPlus />
      <SvgMug />
      <SvgMedication />
      <SvgMedal />
      <SvgLocationStars />
      <SvgLocation />
      <SvgLanguage />
      <SvgLabs />
      <SvgKey />
      <SvgInsurance />
      <SvgImaging />
      <SvgHourglass />
      <SvgHospital />
      <SvgHomeStars />
      <SvgHelpStars />
      <SvgHelp />
      <SvgHeartBubble />
      <Folder />
      <SvgFaxStars />
      <SvgEnvelopeStars />
      <SvgEnvelope />
      <SvgEducation />
      <SvgDosage />
      <SvgDoctor />
      <SvgCodeStars />
      <SvgCodeExpStars />
      <SvgClock />
      <SvgCellphoneStars />
      <SvgCalendarUpcoming />
      <CalendarSm />
      <SvgCalendarPlus />
      <SvgCalendarPending />
      <SvgCalendarMinus />
      <SvgCalendarM />
      <SvgCalendarIncomplete />
      <SvgCalendarFold />
      <SvgCalendarStars />
      <SvgCalendarComplete />
      <SvgCalendarArchived />
      <SvgUserQuestionMark />
      <SvgShieldCheck />
      <SvgDoctorsCheckC />
      <SvgDoctorStars />
      <SvgEnvelopeStars2 />
      <SvgFlag />
      <SvgHandReminder />
      <SvgPencilStars />
      <SvgSpeechBubbleDots />
      <SvgWarningCheck />
      <SvgAdultFemaleAvatar />
      <SvgAdultMaleAvatar />
      <SvgBoyAvatar />
      <SvgGirlAvatar />
      <SvgPoo />
      <SvgPooBox />
      <SvgPooBoxArrow />
      <SvgPooBoxCheck />
      <SvgPooBoxOpen />
      <SvgPooStrars />

      <div className="title-24 text-dark text-light margin-10">
        Icon font
      </div>

      <div className="clearfix mhl ptl">

        <div className="glyph fs1">
          <div className="clearfix bshadow0 pbs">
            <span className="icon-clipboard"></span>
            <span className="mls"> icon-clipboard</span>
          </div>
          <fieldset className="fs0 size1of1 clearfix hidden-false">
            <input type="text" value="e982" className="unit size1of2" />
            <input type="text" value="&#e982;" className="unitRight size1of2 talign-right" />
          </fieldset>
          <div className="fs0 bshadow0 clearfix hidden-true">
            <span className="unit pvs fgc1">liga: </span>
            <input type="text" value="" className="liga unitRight" />
          </div>
        </div>

        <div className="glyph fs1">
          <div className="clearfix bshadow0 pbs">
            <span className="icon-syringe"></span>
            <span className="mls"> icon-age</span>
          </div>
          <fieldset className="fs0 size1of1 clearfix hidden-false">
            <input type="text" value="e983" className="unit size1of2" />
            <input type="text" value="&#xe969;" className="unitRight size1of2 talign-right" />
          </fieldset>
          <div className="fs0 bshadow0 clearfix hidden-true">
            <span className="unit pvs fgc1">liga: </span>
            <input type="text" value="" className="liga unitRight" />
          </div>
        </div>

        <div className="glyph fs1">
          <div className="clearfix bshadow0 pbs">
            <span className="icon-age"></span>
            <span className="mls"> icon-age</span>
          </div>
          <fieldset className="fs0 size1of1 clearfix hidden-false">
            <input type="text" value="e984" className="unit size1of2" />
            <input type="text" value="&#xe969;" className="unitRight size1of2 talign-right" />
          </fieldset>
          <div className="fs0 bshadow0 clearfix hidden-true">
            <span className="unit pvs fgc1">liga: </span>
            <input type="text" value="" className="liga unitRight" />
          </div>
        </div>

        <div className="glyph fs1">
          <div className="clearfix bshadow0 pbs">
            <span className="icon-condition-heart"></span>
            <span className="mls"> icon-condition-heart</span>
          </div>
          <fieldset className="fs0 size1of1 clearfix hidden-false">
            <input type="text" value="e985" className="unit size1of2" />
            <input type="text" value="&#xe969;" className="unitRight size1of2 talign-right" />
          </fieldset>
          <div className="fs0 bshadow0 clearfix hidden-true">
            <span className="unit pvs fgc1">liga: </span>
            <input type="text" value="" className="liga unitRight" />
          </div>
        </div>

        <div className="glyph fs1">
          <div className="clearfix bshadow0 pbs">
            <span className="icon-gender"></span>
            <span className="mls"> icon-gender</span>
          </div>
          <fieldset className="fs0 size1of1 clearfix hidden-false">
            <input type="text" value="e986" className="unit size1of2" />
            <input type="text" value="&#xe969;" className="unitRight size1of2 talign-right" />
          </fieldset>
          <div className="fs0 bshadow0 clearfix hidden-true">
            <span className="unit pvs fgc1">liga: </span>
            <input type="text" value="" className="liga unitRight" />
          </div>
        </div>

        <div className="glyph fs1">
          <div className="clearfix bshadow0 pbs">
            <span className="icon-stethoscope"></span>
            <span className="mls"> icon-stethoscope</span>
          </div>
          <fieldset className="fs0 size1of1 clearfix hidden-false">
            <input type="text" value="e987" className="unit size1of2" />
            <input type="text" value="&#xe969;" className="unitRight size1of2 talign-right" />
          </fieldset>
          <div className="fs0 bshadow0 clearfix hidden-true">
            <span className="unit pvs fgc1">liga: </span>
            <input type="text" value="" className="liga unitRight" />
          </div>
        </div>

        <div className="glyph fs1">
          <div className="clearfix bshadow0 pbs">
            <span className="icon-ban"></span>
            <span className="mls"> icon-ban</span>
          </div>
          <fieldset className="fs0 size1of1 clearfix hidden-false">
            <input type="text" value="e969" className="unit size1of2" />
            <input type="text" value="&#xe969;" className="unitRight size1of2 talign-right" />
          </fieldset>
          <div className="fs0 bshadow0 clearfix hidden-true">
            <span className="unit pvs fgc1">liga: </span>
            <input type="text" value="" className="liga unitRight" />
          </div>
        </div>

        <div className="glyph fs1">
          <div className="clearfix bshadow0 pbs">
            <span className="icon-exclamation">
            </span>
            <span className="mls"> icon-exclamation</span>
          </div>
          <fieldset className="fs0 size1of1 clearfix hidden-false">
            <input type="text" value="e968" className="unit size1of2" />
            <input type="text" value="&#xe968;" className="unitRight size1of2 talign-right" />
          </fieldset>
          <div className="fs0 bshadow0 clearfix hidden-true">
            <span className="unit pvs fgc1">liga: </span>
            <input type="text" value="" className="liga unitRight" />
          </div>
        </div>
        <div className="glyph fs1">
          <div className="clearfix bshadow0 pbs">
            <span className="icon-medical-team">
            </span>
            <span className="mls"> icon-medical-team</span>
          </div>
          <fieldset className="fs0 size1of1 clearfix hidden-false">
            <input type="text" value="e967" className="unit size1of2" />
            <input type="text" value="&#xe967;" className="unitRight size1of2 talign-right" />
          </fieldset>
          <div className="fs0 bshadow0 clearfix hidden-true">
            <span className="unit pvs fgc1">liga: </span>
            <input type="text" value="" className="liga unitRight" />
          </div>
        </div>
        <div className="glyph fs1">
          <div className="clearfix bshadow0 pbs">
            <span className="icon-refresh">
            </span>
            <span className="mls"> icon-refresh</span>
          </div>
          <fieldset className="fs0 size1of1 clearfix hidden-false">
            <input type="text" value="e966" className="unit size1of2" />
            <input type="text" value="&#xe966;" className="unitRight size1of2 talign-right" />
          </fieldset>
          <div className="fs0 bshadow0 clearfix hidden-true">
            <span className="unit pvs fgc1">liga: </span>
            <input type="text" value="" className="liga unitRight" />
          </div>
        </div>
        <div className="glyph fs1">
          <div className="clearfix bshadow0 pbs">
            <span className="icon-flag">
            </span>
            <span className="mls"> icon-flag</span>
          </div>
          <fieldset className="fs0 size1of1 clearfix hidden-false">
            <input type="text" value="e964" className="unit size1of2" />
            <input type="text" value="&#xe964;" className="unitRight size1of2 talign-right" />
          </fieldset>
          <div className="fs0 bshadow0 clearfix hidden-true">
            <span className="unit pvs fgc1">liga: </span>
            <input type="text" value="" className="liga unitRight" />
          </div>
        </div>
        <div className="glyph fs1">
          <div className="clearfix bshadow0 pbs">
            <span className="icon-user-id">
            </span>
            <span className="mls"> icon-user-id</span>
          </div>
          <fieldset className="fs0 size1of1 clearfix hidden-false">
            <input type="text" value="e965" className="unit size1of2" />
            <input type="text" value="&#xe965;" className="unitRight size1of2 talign-right" />
          </fieldset>
          <div className="fs0 bshadow0 clearfix hidden-true">
            <span className="unit pvs fgc1">liga: </span>
            <input type="text" value="" className="liga unitRight" />
          </div>
        </div>
        <div className="glyph fs1">
          <div className="clearfix bshadow0 pbs">
            <span className="icon-play">
            </span>
            <span className="mls"> icon-play</span>
          </div>
          <fieldset className="fs0 size1of1 clearfix hidden-false">
            <input type="text" value="e963" className="unit size1of2" />
            <input type="text" value="&#xe963;" className="unitRight size1of2 talign-right" />
          </fieldset>
          <div className="fs0 bshadow0 clearfix hidden-true">
            <span className="unit pvs fgc1">liga: </span>
            <input type="text" value="" className="liga unitRight" />
          </div>
        </div>
        <div className="glyph fs1">
          <div className="clearfix bshadow0 pbs">
            <span className="icon-check-in">
            </span>
            <span className="mls"> icon-check-in</span>
          </div>
          <fieldset className="fs0 size1of1 clearfix hidden-false">
            <input type="text" value="e962" className="unit size1of2" />
            <input type="text" value="&#xe962;" className="unitRight size1of2 talign-right" />
          </fieldset>
          <div className="fs0 bshadow0 clearfix hidden-true">
            <span className="unit pvs fgc1">liga: </span>
            <input type="text" value="" className="liga unitRight" />
          </div>
        </div>
        <div className="glyph fs1">
          <div className="clearfix bshadow0 pbs">
            <span className="icon-user-r-stars">
            </span>
            <span className="mls"> icon-user-r-stars</span>
          </div>
          <fieldset className="fs0 size1of1 clearfix hidden-false">
            <input type="text" value="e960" className="unit size1of2" />
            <input type="text" value="&#xe960;" className="unitRight size1of2 talign-right" />
          </fieldset>
          <div className="fs0 bshadow0 clearfix hidden-true">
            <span className="unit pvs fgc1">liga: </span>
            <input type="text" value="" className="liga unitRight" />
          </div>
        </div>
        <div className="glyph fs1">
          <div className="clearfix bshadow0 pbs">
            <span className="icon-calendar-stars">
            </span>
            <span className="mls"> icon-calendar-stars</span>
          </div>
          <fieldset className="fs0 size1of1 clearfix hidden-false">
            <input type="text" value="e95f" className="unit size1of2" />
            <input type="text" value="&#xe95f;" className="unitRight size1of2 talign-right" />
          </fieldset>
          <div className="fs0 bshadow0 clearfix hidden-true">
            <span className="unit pvs fgc1">liga: </span>
            <input type="text" value="" className="liga unitRight" />
          </div>
        </div>
        <div className="glyph fs1">
          <div className="clearfix bshadow0 pbs">
            <span className="icon-star-sh">
            </span>
            <span className="mls"> icon-star-sh</span>
          </div>
          <fieldset className="fs0 size1of1 clearfix hidden-false">
            <input type="text" value="e95e" className="unit size1of2" />
            <input type="text" value="&#xe95e;" className="unitRight size1of2 talign-right" />
          </fieldset>
          <div className="fs0 bshadow0 clearfix hidden-true">
            <span className="unit pvs fgc1">liga: </span>
            <input type="text" value="" className="liga unitRight" />
          </div>
        </div>
        <div className="glyph fs1">
          <div className="clearfix bshadow0 pbs">
            <span className="icon-star-up">
            </span>
            <span className="mls"> icon-star-up</span>
          </div>
          <fieldset className="fs0 size1of1 clearfix hidden-false">
            <input type="text" value="e961" className="unit size1of2" />
            <input type="text" value="&#xe961;" className="unitRight size1of2 talign-right" />
          </fieldset>
          <div className="fs0 bshadow0 clearfix hidden-true">
            <span className="unit pvs fgc1">liga: </span>
            <input type="text" value="" className="liga unitRight" />
          </div>
        </div>
        <div className="glyph fs1">
          <div className="clearfix bshadow0 pbs">
            <span className="icon-dots">
            </span>
            <span className="mls"> icon-dots</span>
          </div>
          <fieldset className="fs0 size1of1 clearfix hidden-false">
            <input type="text" value="e95d" className="unit size1of2" />
            <input type="text" value="&#xe95d;" className="unitRight size1of2 talign-right" />
          </fieldset>
          <div className="fs0 bshadow0 clearfix hidden-true">
            <span className="unit pvs fgc1">liga: </span>
            <input type="text" value="" className="liga unitRight" />
          </div>
        </div>
        <div className="glyph fs1">
          <div className="clearfix bshadow0 pbs">
            <span className="icon-clock">
            </span>
            <span className="mls"> icon-clock</span>
          </div>
          <fieldset className="fs0 size1of1 clearfix hidden-false">
            <input type="text" value="e95c" className="unit size1of2" />
            <input type="text" value="&#xe95c;" className="unitRight size1of2 talign-right" />
          </fieldset>
          <div className="fs0 bshadow0 clearfix hidden-true">
            <span className="unit pvs fgc1">liga: </span>
            <input type="text" value="" className="liga unitRight" />
          </div>
        </div>
        <div className="glyph fs1">
          <div className="clearfix bshadow0 pbs">
            <span className="icon-lightning">
            </span>
            <span className="mls"> icon-lightning</span>
          </div>
          <fieldset className="fs0 size1of1 clearfix hidden-false">
            <input type="text" value="e95b" className="unit size1of2" />
            <input type="text" value="&#xe95b;" className="unitRight size1of2 talign-right" />
          </fieldset>
          <div className="fs0 bshadow0 clearfix hidden-true">
            <span className="unit pvs fgc1">liga: </span>
            <input type="text" value="" className="liga unitRight" />
          </div>
        </div>
        <div className="glyph fs1">
          <div className="clearfix bshadow0 pbs">
            <span className="icon-warning">
            </span>
            <span className="mls"> icon-warning</span>
          </div>
          <fieldset className="fs0 size1of1 clearfix hidden-false">
            <input type="text" value="e957" className="unit size1of2" />
            <input type="text" value="&#xe957;" className="unitRight size1of2 talign-right" />
          </fieldset>
          <div className="fs0 bshadow0 clearfix hidden-true">
            <span className="unit pvs fgc1">liga: </span>
            <input type="text" value="" className="liga unitRight" />
          </div>
        </div>
        <div className="glyph fs1">
          <div className="clearfix bshadow0 pbs">
            <span className="icon-check-s-2">
            </span>
            <span className="mls"> icon-check-s-2</span>
          </div>
          <fieldset className="fs0 size1of1 clearfix hidden-false">
            <input type="text" value="e958" className="unit size1of2" />
            <input type="text" value="&#xe958;" className="unitRight size1of2 talign-right" />
          </fieldset>
          <div className="fs0 bshadow0 clearfix hidden-true">
            <span className="unit pvs fgc1">liga: </span>
            <input type="text" value="" className="liga unitRight" />
          </div>
        </div>
        <div className="glyph fs1">
          <div className="clearfix bshadow0 pbs">
            <span className="icon-user-r">
            </span>
            <span className="mls"> icon-user-r</span>
          </div>
          <fieldset className="fs0 size1of1 clearfix hidden-false">
            <input type="text" value="e959" className="unit size1of2" />
            <input type="text" value="&#xe959;" className="unitRight size1of2 talign-right" />
          </fieldset>
          <div className="fs0 bshadow0 clearfix hidden-true">
            <span className="unit pvs fgc1">liga: </span>
            <input type="text" value="" className="liga unitRight" />
          </div>
        </div>
        <div className="glyph fs1">
          <div className="clearfix bshadow0 pbs">
            <span className="icon-note-h-e">
            </span>
            <span className="mls"> icon-note-h-e</span>
          </div>
          <fieldset className="fs0 size1of1 clearfix hidden-false">
            <input type="text" value="e95a" className="unit size1of2" />
            <input type="text" value="&#xe95a;" className="unitRight size1of2 talign-right" />
          </fieldset>
          <div className="fs0 bshadow0 clearfix hidden-true">
            <span className="unit pvs fgc1">liga: </span>
            <input type="text" value="" className="liga unitRight" />
          </div>
        </div>
        <div className="glyph fs1">
          <div className="clearfix bshadow0 pbs">
            <span className="icon-home-3">
            </span>
            <span className="mls"> icon-home-3</span>
          </div>
          <fieldset className="fs0 size1of1 clearfix hidden-false">
            <input type="text" value="e953" className="unit size1of2" />
            <input type="text" value="&#xe953;" className="unitRight size1of2 talign-right" />
          </fieldset>
          <div className="fs0 bshadow0 clearfix hidden-true">
            <span className="unit pvs fgc1">liga: </span>
            <input type="text" value="" className="liga unitRight" />
          </div>
        </div>
        <div className="glyph fs1">
          <div className="clearfix bshadow0 pbs">
            <span className="icon-places">
            </span>
            <span className="mls"> icon-places</span>
          </div>
          <fieldset className="fs0 size1of1 clearfix hidden-false">
            <input type="text" value="e954" className="unit size1of2" />
            <input type="text" value="&#xe954;" className="unitRight size1of2 talign-right" />
          </fieldset>
          <div className="fs0 bshadow0 clearfix hidden-true">
            <span className="unit pvs fgc1">liga: </span>
            <input type="text" value="" className="liga unitRight" />
          </div>
        </div>
        <div className="glyph fs1">
          <div className="clearfix bshadow0 pbs">
            <span className="icon-help-w">
            </span>
            <span className="mls"> icon-help-w</span>
          </div>
          <fieldset className="fs0 size1of1 clearfix hidden-false">
            <input type="text" value="e955" className="unit size1of2" />
            <input type="text" value="&#xe955;" className="unitRight size1of2 talign-right" />
          </fieldset>
          <div className="fs0 bshadow0 clearfix hidden-true">
            <span className="unit pvs fgc1">liga: </span>
            <input type="text" value="" className="liga unitRight" />
          </div>
        </div>
        <div className="glyph fs1">
          <div className="clearfix bshadow0 pbs">
            <span className="icon-heart-vim-e">
            </span>
            <span className="mls"> icon-heart-vim-e</span>
          </div>
          <fieldset className="fs0 size1of1 clearfix hidden-false">
            <input type="text" value="e956" className="unit size1of2" />
            <input type="text" value="&#xe956;" className="unitRight size1of2 talign-right" />
          </fieldset>
          <div className="fs0 bshadow0 clearfix hidden-true">
            <span className="unit pvs fgc1">liga: </span>
            <input type="text" value="" className="liga unitRight" />
          </div>
        </div>
        <div className="glyph fs1">
          <div className="clearfix bshadow0 pbs">
            <span className="icon-heart-vim">
            </span>
            <span className="mls"> icon-heart-vim</span>
          </div>
          <fieldset className="fs0 size1of1 clearfix hidden-false">
            <input type="text" value="e952" className="unit size1of2" />
            <input type="text" value="&#xe952;" className="unitRight size1of2 talign-right" />
          </fieldset>
          <div className="fs0 bshadow0 clearfix hidden-true">
            <span className="unit pvs fgc1">liga: </span>
            <input type="text" value="" className="liga unitRight" />
          </div>
        </div>
        <div className="glyph fs1">
          <div className="clearfix bshadow0 pbs">
            <span className="icon-globe">
            </span>
            <span className="mls"> icon-globe</span>
          </div>
          <fieldset className="fs0 size1of1 clearfix hidden-false">
            <input type="text" value="e951" className="unit size1of2" />
            <input type="text" value="&#xe951;" className="unitRight size1of2 talign-right" />
          </fieldset>
          <div className="fs0 bshadow0 clearfix hidden-true">
            <span className="unit pvs fgc1">liga: </span>
            <input type="text" value="" className="liga unitRight" />
          </div>
        </div>
        <div className="glyph fs1">
          <div className="clearfix bshadow0 pbs">
            <span className="icon-add-member">
            </span>
            <span className="mls"> icon-add-member</span>
          </div>
          <fieldset className="fs0 size1of1 clearfix hidden-false">
            <input type="text" value="e950" className="unit size1of2" />
            <input type="text" value="&#xe950;" className="unitRight size1of2 talign-right" />
          </fieldset>
          <div className="fs0 bshadow0 clearfix hidden-true">
            <span className="unit pvs fgc1">liga: </span>
            <input type="text" value="" className="liga unitRight" />
          </div>
        </div>
        <div className="glyph fs1">
          <div className="clearfix bshadow0 pbs">
            <span className="icon-notification-s">
            </span>
            <span className="mls"> icon-notification-s</span>
          </div>
          <fieldset className="fs0 size1of1 clearfix hidden-false">
            <input type="text" value="e94f" className="unit size1of2" />
            <input type="text" value="&#xe94f;" className="unitRight size1of2 talign-right" />
          </fieldset>
          <div className="fs0 bshadow0 clearfix hidden-true">
            <span className="unit pvs fgc1">liga: </span>
            <input type="text" value="" className="liga unitRight" />
          </div>
        </div>
        <div className="glyph fs1">
          <div className="clearfix bshadow0 pbs">
            <span className="icon-calendar-sm">
            </span>
            <span className="mls"> icon-calendar-sm</span>
          </div>
          <fieldset className="fs0 size1of1 clearfix hidden-false">
            <input type="text" value="e94d" className="unit size1of2" />
            <input type="text" value="&#xe94d;" className="unitRight size1of2 talign-right" />
          </fieldset>
          <div className="fs0 bshadow0 clearfix hidden-true">
            <span className="unit pvs fgc1">liga: </span>
            <input type="text" value="" className="liga unitRight" />
          </div>
        </div>
        <div className="glyph fs1">
          <div className="clearfix bshadow0 pbs">
            <span className="icon-building">
            </span>
            <span className="mls"> icon-building</span>
          </div>
          <fieldset className="fs0 size1of1 clearfix hidden-false">
            <input type="text" value="e94c" className="unit size1of2" />
            <input type="text" value="&#xe94c;" className="unitRight size1of2 talign-right" />
          </fieldset>
          <div className="fs0 bshadow0 clearfix hidden-true">
            <span className="unit pvs fgc1">liga: </span>
            <input type="text" value="" className="liga unitRight" />
          </div>
        </div>
        <div className="glyph fs1">
          <div className="clearfix bshadow0 pbs">
            <span className="icon-user-2">
            </span>
            <span className="mls"> icon-user-2</span>
          </div>
          <fieldset className="fs0 size1of1 clearfix hidden-false">
            <input type="text" value="e94b" className="unit size1of2" />
            <input type="text" value="&#xe94b;" className="unitRight size1of2 talign-right" />
          </fieldset>
          <div className="fs0 bshadow0 clearfix hidden-true">
            <span className="unit pvs fgc1">liga: </span>
            <input type="text" value="" className="liga unitRight" />
          </div>
        </div>
        <div className="glyph fs1">
          <div className="clearfix bshadow0 pbs">
            <span className="icon-medication">
            </span>
            <span className="mls"> icon-medication</span>
          </div>
          <fieldset className="fs0 size1of1 clearfix hidden-false">
            <input type="text" value="e94a" className="unit size1of2" />
            <input type="text" value="&#xe94a;" className="unitRight size1of2 talign-right" />
          </fieldset>
          <div className="fs0 bshadow0 clearfix hidden-true">
            <span className="unit pvs fgc1">liga: </span>
            <input type="text" value="" className="liga unitRight" />
          </div>
        </div>
        <div className="glyph fs1">
          <div className="clearfix bshadow0 pbs">
            <span className="icon-browser">
            </span>
            <span className="mls"> icon-browser</span>
          </div>
          <fieldset className="fs0 size1of1 clearfix hidden-false">
            <input type="text" value="e948" className="unit size1of2" />
            <input type="text" value="&#xe948;" className="unitRight size1of2 talign-right" />
          </fieldset>
          <div className="fs0 bshadow0 clearfix hidden-true">
            <span className="unit pvs fgc1">liga: </span>
            <input type="text" value="" className="liga unitRight" />
          </div>
        </div>
        <div className="glyph fs1">
          <div className="clearfix bshadow0 pbs">
            <span className="icon-iphone">
            </span>
            <span className="mls"> icon-iphone</span>
          </div>
          <fieldset className="fs0 size1of1 clearfix hidden-false">
            <input type="text" value="e949" className="unit size1of2" />
            <input type="text" value="&#xe949;" className="unitRight size1of2 talign-right" />
          </fieldset>
          <div className="fs0 bshadow0 clearfix hidden-true">
            <span className="unit pvs fgc1">liga: </span>
            <input type="text" value="" className="liga unitRight" />
          </div>
        </div>
        <div className="glyph fs1">
          <div className="clearfix bshadow0 pbs">
            <span className="icon-heart-key">
            </span>
            <span className="mls"> icon-heart-key</span>
          </div>
          <fieldset className="fs0 size1of1 clearfix hidden-false">
            <input type="text" value="e93f" className="unit size1of2" />
            <input type="text" value="&#xe93f;" className="unitRight size1of2 talign-right" />
          </fieldset>
          <div className="fs0 bshadow0 clearfix hidden-true">
            <span className="unit pvs fgc1">liga: </span>
            <input type="text" value="" className="liga unitRight" />
          </div>
        </div>
        <div className="glyph fs1">
          <div className="clearfix bshadow0 pbs">
            <span className="icon-cog-s-2">
            </span>
            <span className="mls"> icon-cog-s-2</span>
          </div>
          <fieldset className="fs0 size1of1 clearfix hidden-false">
            <input type="text" value="e940" className="unit size1of2" />
            <input type="text" value="&#xe940;" className="unitRight size1of2 talign-right" />
          </fieldset>
          <div className="fs0 bshadow0 clearfix hidden-true">
            <span className="unit pvs fgc1">liga: </span>
            <input type="text" value="" className="liga unitRight" />
          </div>
        </div>
        <div className="glyph fs1">
          <div className="clearfix bshadow0 pbs">
            <span className="icon-md-circle">
            </span>
            <span className="mls"> icon-md-circle</span>
          </div>
          <fieldset className="fs0 size1of1 clearfix hidden-false">
            <input type="text" value="e941" className="unit size1of2" />
            <input type="text" value="&#xe941;" className="unitRight size1of2 talign-right" />
          </fieldset>
          <div className="fs0 bshadow0 clearfix hidden-true">
            <span className="unit pvs fgc1">liga: </span>
            <input type="text" value="" className="liga unitRight" />
          </div>
        </div>
        <div className="glyph fs1">
          <div className="clearfix bshadow0 pbs">
            <span className="icon-speak-heart">
            </span>
            <span className="mls"> icon-speak-heart</span>
          </div>
          <fieldset className="fs0 size1of1 clearfix hidden-false">
            <input type="text" value="e942" className="unit size1of2" />
            <input type="text" value="&#xe942;" className="unitRight size1of2 talign-right" />
          </fieldset>
          <div className="fs0 bshadow0 clearfix hidden-true">
            <span className="unit pvs fgc1">liga: </span>
            <input type="text" value="" className="liga unitRight" />
          </div>
        </div>
        <div className="glyph fs1">
          <div className="clearfix bshadow0 pbs">
            <span className="icon-clinic">
            </span>
            <span className="mls"> icon-clinic</span>
          </div>
          <fieldset className="fs0 size1of1 clearfix hidden-false">
            <input type="text" value="e943" className="unit size1of2" />
            <input type="text" value="&#xe943;" className="unitRight size1of2 talign-right" />
          </fieldset>
          <div className="fs0 bshadow0 clearfix hidden-true">
            <span className="unit pvs fgc1">liga: </span>
            <input type="text" value="" className="liga unitRight" />
          </div>
        </div>
        <div className="glyph fs1">
          <div className="clearfix bshadow0 pbs">
            <span className="icon-hospital-2">
            </span>
            <span className="mls"> icon-hospital-2</span>
          </div>
          <fieldset className="fs0 size1of1 clearfix hidden-false">
            <input type="text" value="e944" className="unit size1of2" />
            <input type="text" value="&#xe944;" className="unitRight size1of2 talign-right" />
          </fieldset>
          <div className="fs0 bshadow0 clearfix hidden-true">
            <span className="unit pvs fgc1">liga: </span>
            <input type="text" value="" className="liga unitRight" />
          </div>
        </div>
        <div className="glyph fs1">
          <div className="clearfix bshadow0 pbs">
            <span className="icon-pill">
            </span>
            <span className="mls"> icon-pill</span>
          </div>
          <fieldset className="fs0 size1of1 clearfix hidden-false">
            <input type="text" value="e945" className="unit size1of2" />
            <input type="text" value="&#xe945;" className="unitRight size1of2 talign-right" />
          </fieldset>
          <div className="fs0 bshadow0 clearfix hidden-true">
            <span className="unit pvs fgc1">liga: </span>
            <input type="text" value="" className="liga unitRight" />
          </div>
        </div>
        <div className="glyph fs1">
          <div className="clearfix bshadow0 pbs">
            <span className="icon-tube">
            </span>
            <span className="mls"> icon-tube</span>
          </div>
          <fieldset className="fs0 size1of1 clearfix hidden-false">
            <input type="text" value="e946" className="unit size1of2" />
            <input type="text" value="&#xe946;" className="unitRight size1of2 talign-right" />
          </fieldset>
          <div className="fs0 bshadow0 clearfix hidden-true">
            <span className="unit pvs fgc1">liga: </span>
            <input type="text" value="" className="liga unitRight" />
          </div>
        </div>
        <div className="glyph fs1">
          <div className="clearfix bshadow0 pbs">
            <span className="icon-radiology">
            </span>
            <span className="mls"> icon-radiology</span>
          </div>
          <fieldset className="fs0 size1of1 clearfix hidden-false">
            <input type="text" value="e947" className="unit size1of2" />
            <input type="text" value="&#xe947;" className="unitRight size1of2 talign-right" />
          </fieldset>
          <div className="fs0 bshadow0 clearfix hidden-true">
            <span className="unit pvs fgc1">liga: </span>
            <input type="text" value="" className="liga unitRight" />
          </div>
        </div>
        <div className="glyph fs1">
          <div className="clearfix bshadow0 pbs">
            <span className="icon-shield-check">
            </span>
            <span className="mls"> icon-shield-check</span>
          </div>
          <fieldset className="fs0 size1of1 clearfix hidden-false">
            <input type="text" value="e93e" className="unit size1of2" />
            <input type="text" value="&#xe93e;" className="unitRight size1of2 talign-right" />
          </fieldset>
          <div className="fs0 bshadow0 clearfix hidden-true">
            <span className="unit pvs fgc1">liga: </span>
            <input type="text" value="" className="liga unitRight" />
          </div>
        </div>
        <div className="glyph fs1">
          <div className="clearfix bshadow0 pbs">
            <span className="icon-heart-e">
            </span>
            <span className="mls"> icon-heart-e</span>
          </div>
          <fieldset className="fs0 size1of1 clearfix hidden-false">
            <input type="text" value="e93a" className="unit size1of2" />
            <input type="text" value="&#xe93a;" className="unitRight size1of2 talign-right" />
          </fieldset>
          <div className="fs0 bshadow0 clearfix hidden-true">
            <span className="unit pvs fgc1">liga: </span>
            <input type="text" value="" className="liga unitRight" />
          </div>
        </div>
        <div className="glyph fs1">
          <div className="clearfix bshadow0 pbs">
            <span className="icon-heart-f">
            </span>
            <span className="mls"> icon-heart-f</span>
          </div>
          <fieldset className="fs0 size1of1 clearfix hidden-false">
            <input type="text" value="e93b" className="unit size1of2" />
            <input type="text" value="&#xe93b;" className="unitRight size1of2 talign-right" />
          </fieldset>
          <div className="fs0 bshadow0 clearfix hidden-true">
            <span className="unit pvs fgc1">liga: </span>
            <input type="text" value="" className="liga unitRight" />
          </div>
        </div>
        <div className="glyph fs1">
          <div className="clearfix bshadow0 pbs">
            <span className="icon-check-circle-e">
            </span>
            <span className="mls"> icon-check-circle-e</span>
          </div>
          <fieldset className="fs0 size1of1 clearfix hidden-false">
            <input type="text" value="e93c" className="unit size1of2" />
            <input type="text" value="&#xe93c;" className="unitRight size1of2 talign-right" />
          </fieldset>
          <div className="fs0 bshadow0 clearfix hidden-true">
            <span className="unit pvs fgc1">liga: </span>
            <input type="text" value="" className="liga unitRight" />
          </div>
        </div>
        <div className="glyph fs1">
          <div className="clearfix bshadow0 pbs">
            <span className="icon-check-circle-f">
            </span>
            <span className="mls"> icon-check-circle-f</span>
          </div>
          <fieldset className="fs0 size1of1 clearfix hidden-false">
            <input type="text" value="e93d" className="unit size1of2" />
            <input type="text" value="&#xe93d;" className="unitRight size1of2 talign-right" />
          </fieldset>
          <div className="fs0 bshadow0 clearfix hidden-true">
            <span className="unit pvs fgc1">liga: </span>
            <input type="text" value="" className="liga unitRight" />
          </div>
        </div>
        <div className="glyph fs1">
          <div className="clearfix bshadow0 pbs">
            <span className="icon-help-c-2">
            </span>
            <span className="mls"> icon-help-c-2</span>
          </div>
          <fieldset className="fs0 size1of1 clearfix hidden-false">
            <input type="text" value="e937" className="unit size1of2" />
            <input type="text" value="&#xe937;" className="unitRight size1of2 talign-right" />
          </fieldset>
          <div className="fs0 bshadow0 clearfix hidden-true">
            <span className="unit pvs fgc1">liga: </span>
            <input type="text" value="" className="liga unitRight" />
          </div>
        </div>
        <div className="glyph fs1">
          <div className="clearfix bshadow0 pbs">
            <span className="icon-cog-s">
            </span>
            <span className="mls"> icon-cog-s</span>
          </div>
          <fieldset className="fs0 size1of1 clearfix hidden-false">
            <input type="text" value="e938" className="unit size1of2" />
            <input type="text" value="&#xe938;" className="unitRight size1of2 talign-right" />
          </fieldset>
          <div className="fs0 bshadow0 clearfix hidden-true">
            <span className="unit pvs fgc1">liga: </span>
            <input type="text" value="" className="liga unitRight" />
          </div>
        </div>
        <div className="glyph fs1">
          <div className="clearfix bshadow0 pbs">
            <span className="icon-search-2">
            </span>
            <span className="mls"> icon-search-2</span>
          </div>
          <fieldset className="fs0 size1of1 clearfix hidden-false">
            <input type="text" value="e939" className="unit size1of2" />
            <input type="text" value="&#xe939;" className="unitRight size1of2 talign-right" />
          </fieldset>
          <div className="fs0 bshadow0 clearfix hidden-true">
            <span className="unit pvs fgc1">liga: </span>
            <input type="text" value="" className="liga unitRight" />
          </div>
        </div>
        <div className="glyph fs1">
          <div className="clearfix bshadow0 pbs">
            <span className="icon-link">
            </span>
            <span className="mls"> icon-link</span>
          </div>
          <fieldset className="fs0 size1of1 clearfix hidden-false">
            <input type="text" value="e934" className="unit size1of2" />
            <input type="text" value="&#xe934;" className="unitRight size1of2 talign-right" />
          </fieldset>
          <div className="fs0 bshadow0 clearfix hidden-true">
            <span className="unit pvs fgc1">liga: </span>
            <input type="text" value="" className="liga unitRight" />
          </div>
        </div>
        <div className="glyph fs1">
          <div className="clearfix bshadow0 pbs">
            <span className="icon-envelope-b">
            </span>
            <span className="mls"> icon-envelope-b</span>
          </div>
          <fieldset className="fs0 size1of1 clearfix hidden-false">
            <input type="text" value="e935" className="unit size1of2" />
            <input type="text" value="&#xe935;" className="unitRight size1of2 talign-right" />
          </fieldset>
          <div className="fs0 bshadow0 clearfix hidden-true">
            <span className="unit pvs fgc1">liga: </span>
            <input type="text" value="" className="liga unitRight" />
          </div>
        </div>
        <div className="glyph fs1">
          <div className="clearfix bshadow0 pbs">
            <span className="icon-speak-b">
            </span>
            <span className="mls"> icon-speak-b</span>
          </div>
          <fieldset className="fs0 size1of1 clearfix hidden-false">
            <input type="text" value="e936" className="unit size1of2" />
            <input type="text" value="&#xe936;" className="unitRight size1of2 talign-right" />
          </fieldset>
          <div className="fs0 bshadow0 clearfix hidden-true">
            <span className="unit pvs fgc1">liga: </span>
            <input type="text" value="" className="liga unitRight" />
          </div>
        </div>
        <div className="glyph fs1">
          <div className="clearfix bshadow0 pbs">
            <span className="icon-eye">
            </span>
            <span className="mls"> icon-eye</span>
          </div>
          <fieldset className="fs0 size1of1 clearfix hidden-false">
            <input type="text" value="e933" className="unit size1of2" />
            <input type="text" value="&#xe933;" className="unitRight size1of2 talign-right" />
          </fieldset>
          <div className="fs0 bshadow0 clearfix hidden-true">
            <span className="unit pvs fgc1">liga: </span>
            <input type="text" value="" className="liga unitRight" />
          </div>
        </div>
        <div className="glyph fs1">
          <div className="clearfix bshadow0 pbs">
            <span className="icon-back-arrow">
            </span>
            <span className="mls"> icon-back-arrow</span>
          </div>
          <fieldset className="fs0 size1of1 clearfix hidden-false">
            <input type="text" value="e932" className="unit size1of2" />
            <input type="text" value="&#xe932;" className="unitRight size1of2 talign-right" />
          </fieldset>
          <div className="fs0 bshadow0 clearfix hidden-true">
            <span className="unit pvs fgc1">liga: </span>
            <input type="text" value="" className="liga unitRight" />
          </div>
        </div>
        <div className="glyph fs1">
          <div className="clearfix bshadow0 pbs">
            <span className="icon-menu-bars"></span>
            <span className="mls"> icon-menu-bars</span>
          </div>
          <fieldset className="fs0 size1of1 clearfix hidden-false">
            <input type="text" value="e929" className="unit size1of2" />
            <input type="text" value="&#xe929;" className="unitRight size1of2 talign-right" />
          </fieldset>
          <div className="fs0 bshadow0 clearfix hidden-true">
            <span className="unit pvs fgc1">liga: </span>
            <input type="text" value="" className="liga unitRight" />
          </div>
        </div>
        <div className="glyph fs1">
          <div className="clearfix bshadow0 pbs">
            <span className="icon-asterisk">
            </span>
            <span className="mls"> icon-asterisk</span>
          </div>
          <fieldset className="fs0 size1of1 clearfix hidden-false">
            <input type="text" value="e92a" className="unit size1of2" />
            <input type="text" value="&#xe92a;" className="unitRight size1of2 talign-right" />
          </fieldset>
          <div className="fs0 bshadow0 clearfix hidden-true">
            <span className="unit pvs fgc1">liga: </span>
            <input type="text" value="" className="liga unitRight" />
          </div>
        </div>
        <div className="glyph fs1">
          <div className="clearfix bshadow0 pbs">
            <span className="icon-phone-f">
            </span>
            <span className="mls"> icon-phone-f</span>
          </div>
          <fieldset className="fs0 size1of1 clearfix hidden-false">
            <input type="text" value="e92b" className="unit size1of2" />
            <input type="text" value="&#xe92b;" className="unitRight size1of2 talign-right" />
          </fieldset>
          <div className="fs0 bshadow0 clearfix hidden-true">
            <span className="unit pvs fgc1">liga: </span>
            <input type="text" value="" className="liga unitRight" />
          </div>
        </div>
        <div className="glyph fs1">
          <div className="clearfix bshadow0 pbs">
            <span className="icon-ext-link">
            </span>
            <span className="mls"> icon-ext-link</span>
          </div>
          <fieldset className="fs0 size1of1 clearfix hidden-false">
            <input type="text" value="e92c" className="unit size1of2" />
            <input type="text" value="&#xe92c;" className="unitRight size1of2 talign-right" />
          </fieldset>
          <div className="fs0 bshadow0 clearfix hidden-true">
            <span className="unit pvs fgc1">liga: </span>
            <input type="text" value="" className="liga unitRight" />
          </div>
        </div>
        <div className="glyph fs1">
          <div className="clearfix bshadow0 pbs">
            <span className="icon-shield">
            </span>
            <span className="mls"> icon-shield</span>
          </div>
          <fieldset className="fs0 size1of1 clearfix hidden-false">
            <input type="text" value="e92d" className="unit size1of2" />
            <input type="text" value="&#xe92d;" className="unitRight size1of2 talign-right" />
          </fieldset>
          <div className="fs0 bshadow0 clearfix hidden-true">
            <span className="unit pvs fgc1">liga: </span>
            <input type="text" value="" className="liga unitRight" />
          </div>
        </div>
        <div className="glyph fs1">
          <div className="clearfix bshadow0 pbs">
            <span className="icon-map-pin-e">
            </span>
            <span className="mls"> icon-map-pin-e</span>
          </div>
          <fieldset className="fs0 size1of1 clearfix hidden-false">
            <input type="text" value="e92e" className="unit size1of2" />
            <input type="text" value="&#xe92e;" className="unitRight size1of2 talign-right" />
          </fieldset>
          <div className="fs0 bshadow0 clearfix hidden-true">
            <span className="unit pvs fgc1">liga: </span>
            <input type="text" value="" className="liga unitRight" />
          </div>
        </div>
        <div className="glyph fs1">
          <div className="clearfix bshadow0 pbs">
            <span className="icon-home-2">
            </span>
            <span className="mls"> icon-home-2</span>
          </div>
          <fieldset className="fs0 size1of1 clearfix hidden-false">
            <input type="text" value="e92f" className="unit size1of2" />
            <input type="text" value="&#xe92f;" className="unitRight size1of2 talign-right" />
          </fieldset>
          <div className="fs0 bshadow0 clearfix hidden-true">
            <span className="unit pvs fgc1">liga: </span>
            <input type="text" value="" className="liga unitRight" />
          </div>
        </div>
        <div className="glyph fs1">
          <div className="clearfix bshadow0 pbs">
            <span className="icon-suitcase">
            </span>
            <span className="mls"> icon-suitcase</span>
          </div>
          <fieldset className="fs0 size1of1 clearfix hidden-false">
            <input type="text" value="e930" className="unit size1of2" />
            <input type="text" value="&#xe930;" className="unitRight size1of2 talign-right" />
          </fieldset>
          <div className="fs0 bshadow0 clearfix hidden-true">
            <span className="unit pvs fgc1">liga: </span>
            <input type="text" value="" className="liga unitRight" />
          </div>
        </div>
        <div className="glyph fs1">
          <div className="clearfix bshadow0 pbs">
            <span className="icon-location-cross">
            </span>
            <span className="mls"> icon-location-cross</span>
          </div>
          <fieldset className="fs0 size1of1 clearfix hidden-false">
            <input type="text" value="e931" className="unit size1of2" />
            <input type="text" value="&#xe931;" className="unitRight size1of2 talign-right" />
          </fieldset>
          <div className="fs0 bshadow0 clearfix hidden-true">
            <span className="unit pvs fgc1">liga: </span>
            <input type="text" value="" className="liga unitRight" />
          </div>
        </div>
        <div className="glyph fs1">
          <div className="clearfix bshadow0 pbs">
            <span className="icon-ul">
            </span>
            <span className="mls"> icon-ul</span>
          </div>
          <fieldset className="fs0 size1of1 clearfix hidden-false">
            <input type="text" value="e927" className="unit size1of2" />
            <input type="text" value="&#xe927;" className="unitRight size1of2 talign-right" />
          </fieldset>
          <div className="fs0 bshadow0 clearfix hidden-true">
            <span className="unit pvs fgc1">liga: </span>
            <input type="text" value="" className="liga unitRight" />
          </div>
        </div>
        <div className="glyph fs1">
          <div className="clearfix bshadow0 pbs">
            <span className="icon-cog">
            </span>
            <span className="mls"> icon-cog</span>
          </div>
          <fieldset className="fs0 size1of1 clearfix hidden-false">
            <input type="text" value="e928" className="unit size1of2" />
            <input type="text" value="&#xe928;" className="unitRight size1of2 talign-right" />
          </fieldset>
          <div className="fs0 bshadow0 clearfix hidden-true">
            <span className="unit pvs fgc1">liga: </span>
            <input type="text" value="" className="liga unitRight" />
          </div>
        </div>
        <div className="glyph fs1">
          <div className="clearfix bshadow0 pbs">
            <span className="icon-calendar-o">
            </span>
            <span className="mls"> icon-calendar-o</span>
          </div>
          <fieldset className="fs0 size1of1 clearfix hidden-false">
            <input type="text" value="e91e" className="unit size1of2" />
            <input type="text" value="&#xe91e;" className="unitRight size1of2 talign-right" />
          </fieldset>
          <div className="fs0 bshadow0 clearfix hidden-true">
            <span className="unit pvs fgc1">liga: </span>
            <input type="text" value="" className="liga unitRight" />
          </div>
        </div>
        <div className="glyph fs1">
          <div className="clearfix bshadow0 pbs">
            <span className="icon-note-h">
            </span>
            <span className="mls"> icon-note-h</span>
          </div>
          <fieldset className="fs0 size1of1 clearfix hidden-false">
            <input type="text" value="e91f" className="unit size1of2" />
            <input type="text" value="&#xe91f;" className="unitRight size1of2 talign-right" />
          </fieldset>
          <div className="fs0 bshadow0 clearfix hidden-true">
            <span className="unit pvs fgc1">liga: </span>
            <input type="text" value="" className="liga unitRight" />
          </div>
        </div>
        <div className="glyph fs1">
          <div className="clearfix bshadow0 pbs">
            <span className="icon-check-s">
            </span>
            <span className="mls"> icon-check-s</span>
          </div>
          <fieldset className="fs0 size1of1 clearfix hidden-false">
            <input type="text" value="e920" className="unit size1of2" />
            <input type="text" value="&#xe920;" className="unitRight size1of2 talign-right" />
          </fieldset>
          <div className="fs0 bshadow0 clearfix hidden-true">
            <span className="unit pvs fgc1">liga: </span>
            <input type="text" value="" className="liga unitRight" />
          </div>
        </div>
        <div className="glyph fs1">
          <div className="clearfix bshadow0 pbs">
            <span className="icon-pie-chart">
            </span>
            <span className="mls"> icon-pie-chart</span>
          </div>
          <fieldset className="fs0 size1of1 clearfix hidden-false">
            <input type="text" value="e921" className="unit size1of2" />
            <input type="text" value="&#xe921;" className="unitRight size1of2 talign-right" />
          </fieldset>
          <div className="fs0 bshadow0 clearfix hidden-true">
            <span className="unit pvs fgc1">liga: </span>
            <input type="text" value="" className="liga unitRight" />
          </div>
        </div>
        <div className="glyph fs1">
          <div className="clearfix bshadow0 pbs">
            <span className="icon-quote">
            </span>
            <span className="mls"> icon-quote</span>
          </div>
          <fieldset className="fs0 size1of1 clearfix hidden-false">
            <input type="text" value="e922" className="unit size1of2" />
            <input type="text" value="&#xe922;" className="unitRight size1of2 talign-right" />
          </fieldset>
          <div className="fs0 bshadow0 clearfix hidden-true">
            <span className="unit pvs fgc1">liga: </span>
            <input type="text" value="" className="liga unitRight" />
          </div>
        </div>
        <div className="glyph fs1">
          <div className="clearfix bshadow0 pbs">
            <span className="icon-user">
            </span>
            <span className="mls"> icon-user</span>
          </div>
          <fieldset className="fs0 size1of1 clearfix hidden-false">
            <input type="text" value="e923" className="unit size1of2" />
            <input type="text" value="&#xe923;" className="unitRight size1of2 talign-right" />
          </fieldset>
          <div className="fs0 bshadow0 clearfix hidden-true">
            <span className="unit pvs fgc1">liga: </span>
            <input type="text" value="" className="liga unitRight" />
          </div>
        </div>
        <div className="glyph fs1">
          <div className="clearfix bshadow0 pbs">
            <span className="icon-plus-s">
            </span>
            <span className="mls"> icon-plus-s</span>
          </div>
          <fieldset className="fs0 size1of1 clearfix hidden-false">
            <input type="text" value="e924" className="unit size1of2" />
            <input type="text" value="&#xe924;" className="unitRight size1of2 talign-right" />
          </fieldset>
          <div className="fs0 bshadow0 clearfix hidden-true">
            <span className="unit pvs fgc1">liga: </span>
            <input type="text" value="" className="liga unitRight" />
          </div>
        </div>
        <div className="glyph fs1">
          <div className="clearfix bshadow0 pbs">
            <span className="icon-notebook">
            </span>
            <span className="mls"> icon-notebook</span>
          </div>
          <fieldset className="fs0 size1of1 clearfix hidden-false">
            <input type="text" value="e925" className="unit size1of2" />
            <input type="text" value="&#xe925;" className="unitRight size1of2 talign-right" />
          </fieldset>
          <div className="fs0 bshadow0 clearfix hidden-true">
            <span className="unit pvs fgc1">liga: </span>
            <input type="text" value="" className="liga unitRight" />
          </div>
        </div>
        <div className="glyph fs1">
          <div className="clearfix bshadow0 pbs">
            <span className="icon-arrow-s">
            </span>
            <span className="mls"> icon-arrow-s</span>
          </div>
          <fieldset className="fs0 size1of1 clearfix hidden-false">
            <input type="text" value="e926" className="unit size1of2" />
            <input type="text" value="&#xe926;" className="unitRight size1of2 talign-right" />
          </fieldset>
          <div className="fs0 bshadow0 clearfix hidden-true">
            <span className="unit pvs fgc1">liga: </span>
            <input type="text" value="" className="liga unitRight" />
          </div>
        </div>
        <div className="glyph fs1">
          <div className="clearfix bshadow0 pbs">
            <span className="icon-filter-sliders">
            </span>
            <span className="mls"> icon-filter-sliders</span>
          </div>
          <fieldset className="fs0 size1of1 clearfix hidden-false">
            <input type="text" value="e91d" className="unit size1of2" />
            <input type="text" value="&#xe91d;" className="unitRight size1of2 talign-right" />
          </fieldset>
          <div className="fs0 bshadow0 clearfix hidden-true">
            <span className="unit pvs fgc1">liga: </span>
            <input type="text" value="" className="liga unitRight" />
          </div>
        </div>
        <div className="glyph fs1">
          <div className="clearfix bshadow0 pbs">
            <span className="icon-arrow-best">
            </span>
            <span className="mls"> icon-arrow-best</span>
          </div>
          <fieldset className="fs0 size1of1 clearfix hidden-false">
            <input type="text" value="e91c" className="unit size1of2" />
            <input type="text" value="&#xe91c;" className="unitRight size1of2 talign-right" />
          </fieldset>
          <div className="fs0 bshadow0 clearfix hidden-true">
            <span className="unit pvs fgc1">liga: </span>
            <input type="text" value="" className="liga unitRight" />
          </div>
        </div>
        <div className="glyph fs1">
          <div className="clearfix bshadow0 pbs">
            <span className="icon-chat-f">
            </span>
            <span className="mls"> icon-chat-f</span>
          </div>
          <fieldset className="fs0 size1of1 clearfix hidden-false">
            <input type="text" value="e91a" className="unit size1of2" />
            <input type="text" value="&#xe91a;" className="unitRight size1of2 talign-right" />
          </fieldset>
          <div className="fs0 bshadow0 clearfix hidden-true">
            <span className="unit pvs fgc1">liga: </span>
            <input type="text" value="" className="liga unitRight" />
          </div>
        </div>
        <div className="glyph fs1">
          <div className="clearfix bshadow0 pbs">
            <span className="icon-chat">
            </span>
            <span className="mls"> icon-chat</span>
          </div>
          <fieldset className="fs0 size1of1 clearfix hidden-false">
            <input type="text" value="e91b" className="unit size1of2" />
            <input type="text" value="&#xe91b;" className="unitRight size1of2 talign-right" />
          </fieldset>
          <div className="fs0 bshadow0 clearfix hidden-true">
            <span className="unit pvs fgc1">liga: </span>
            <input type="text" value="" className="liga unitRight" />
          </div>
        </div>
        <div className="glyph fs1">
          <div className="clearfix bshadow0 pbs">
            <span className="icon-help-center">
            </span>
            <span className="mls"> icon-help-center</span>
          </div>
          <fieldset className="fs0 size1of1 clearfix hidden-false">
            <input type="text" value="e919" className="unit size1of2" />
            <input type="text" value="&#xe919;" className="unitRight size1of2 talign-right" />
          </fieldset>
          <div className="fs0 bshadow0 clearfix hidden-true">
            <span className="unit pvs fgc1">liga: </span>
            <input type="text" value="" className="liga unitRight" />
          </div>
        </div>
        <div className="glyph fs1">
          <div className="clearfix bshadow0 pbs">
            <span className="icon-filter">
            </span>
            <span className="mls"> icon-filter</span>
          </div>
          <fieldset className="fs0 size1of1 clearfix hidden-false">
            <input type="text" value="e916" className="unit size1of2" />
            <input type="text" value="&#xe916;" className="unitRight size1of2 talign-right" />
          </fieldset>
          <div className="fs0 bshadow0 clearfix hidden-true">
            <span className="unit pvs fgc1">liga: </span>
            <input type="text" value="" className="liga unitRight" />
          </div>
        </div>
        <div className="glyph fs1">
          <div className="clearfix bshadow0 pbs">
            <span className="icon-left-arrow-w">
            </span>
            <span className="mls"> icon-left-arrow-w</span>
          </div>
          <fieldset className="fs0 size1of1 clearfix hidden-false">
            <input type="text" value="e917" className="unit size1of2" />
            <input type="text" value="&#xe917;" className="unitRight size1of2 talign-right" />
          </fieldset>
          <div className="fs0 bshadow0 clearfix hidden-true">
            <span className="unit pvs fgc1">liga: </span>
            <input type="text" value="" className="liga unitRight" />
          </div>
        </div>
        <div className="glyph fs1">
          <div className="clearfix bshadow0 pbs">
            <span className="icon-right-arrow-w">
            </span>
            <span className="mls"> icon-right-arrow-w</span>
          </div>
          <fieldset className="fs0 size1of1 clearfix hidden-false">
            <input type="text" value="e918" className="unit size1of2" />
            <input type="text" value="&#xe918;" className="unitRight size1of2 talign-right" />
          </fieldset>
          <div className="fs0 bshadow0 clearfix hidden-true">
            <span className="unit pvs fgc1">liga: </span>
            <input type="text" value="" className="liga unitRight" />
          </div>
        </div>
        <div className="glyph fs1">
          <div className="clearfix bshadow0 pbs">
            <span className="icon-envelope">
            </span>
            <span className="mls"> icon-envelope</span>
          </div>
          <fieldset className="fs0 size1of1 clearfix hidden-false">
            <input type="text" value="e800" className="unit size1of2" />
            <input type="text" value="&#xe800;" className="unitRight size1of2 talign-right" />
          </fieldset>
          <div className="fs0 bshadow0 clearfix hidden-true">
            <span className="unit pvs fgc1">liga: </span>
            <input type="text" value="" className="liga unitRight" />
          </div>
        </div>
        <div className="glyph fs1">
          <div className="clearfix bshadow0 pbs">
            <span className="icon-search">
            </span>
            <span className="mls"> icon-search</span>
          </div>
          <fieldset className="fs0 size1of1 clearfix hidden-false">
            <input type="text" value="e60b" className="unit size1of2" />
            <input type="text" value="&#xe60b;" className="unitRight size1of2 talign-right" />
          </fieldset>
          <div className="fs0 bshadow0 clearfix hidden-true">
            <span className="unit pvs fgc1">liga: </span>
            <input type="text" value="" className="liga unitRight" />
          </div>
        </div>
        <div className="glyph fs1">
          <div className="clearfix bshadow0 pbs">
            <span className="icon-notification">
            </span>
            <span className="mls"> icon-notification</span>
          </div>
          <fieldset className="fs0 size1of1 clearfix hidden-false">
            <input type="text" value="e60c" className="unit size1of2" />
            <input type="text" value="&#xe60c;" className="unitRight size1of2 talign-right" />
          </fieldset>
          <div className="fs0 bshadow0 clearfix hidden-true">
            <span className="unit pvs fgc1">liga: </span>
            <input type="text" value="" className="liga unitRight" />
          </div>
        </div>
        <div className="glyph fs1">
          <div className="clearfix bshadow0 pbs">
            <span className="icon-help">
            </span>
            <span className="mls"> icon-help</span>
          </div>
          <fieldset className="fs0 size1of1 clearfix hidden-false">
            <input type="text" value="e600" className="unit size1of2" />
            <input type="text" value="&#xe600;" className="unitRight size1of2 talign-right" />
          </fieldset>
          <div className="fs0 bshadow0 clearfix hidden-true">
            <span className="unit pvs fgc1">liga: </span>
            <input type="text" value="" className="liga unitRight" />
          </div>
        </div>
        <div className="glyph fs1">
          <div className="clearfix bshadow0 pbs">
            <span className="icon-home">
            </span>
            <span className="mls"> icon-home</span>
          </div>
          <fieldset className="fs0 size1of1 clearfix hidden-false">
            <input type="text" value="e910" className="unit size1of2" />
            <input type="text" value="&#xe910;" className="unitRight size1of2 talign-right" />
          </fieldset>
          <div className="fs0 bshadow0 clearfix hidden-true">
            <span className="unit pvs fgc1">liga: </span>
            <input type="text" value="" className="liga unitRight" />
          </div>
        </div>
        <div className="glyph fs1">
          <div className="clearfix bshadow0 pbs">
            <span className="icon-attachment">
            </span>
            <span className="mls"> icon-attachment</span>
          </div>
          <fieldset className="fs0 size1of1 clearfix hidden-false">
            <input type="text" value="e911" className="unit size1of2" />
            <input type="text" value="&#xe911;" className="unitRight size1of2 talign-right" />
          </fieldset>
          <div className="fs0 bshadow0 clearfix hidden-true">
            <span className="unit pvs fgc1">liga: </span>
            <input type="text" value="" className="liga unitRight" />
          </div>
        </div>
        <div className="glyph fs1">
          <div className="clearfix bshadow0 pbs">
            <span className="icon-print">
            </span>
            <span className="mls"> icon-print</span>
          </div>
          <fieldset className="fs0 size1of1 clearfix hidden-false">
            <input type="text" value="e912" className="unit size1of2" />
            <input type="text" value="&#xe912;" className="unitRight size1of2 talign-right" />
          </fieldset>
          <div className="fs0 bshadow0 clearfix hidden-true">
            <span className="unit pvs fgc1">liga: </span>
            <input type="text" value="" className="liga unitRight" />
          </div>
        </div>
        <div className="glyph fs1">
          <div className="clearfix bshadow0 pbs">
            <span className="icon-hospital">
            </span>
            <span className="mls"> icon-hospital</span>
          </div>
          <fieldset className="fs0 size1of1 clearfix hidden-false">
            <input type="text" value="e913" className="unit size1of2" />
            <input type="text" value="&#xe913;" className="unitRight size1of2 talign-right" />
          </fieldset>
          <div className="fs0 bshadow0 clearfix hidden-true">
            <span className="unit pvs fgc1">liga: </span>
            <input type="text" value="" className="liga unitRight" />
          </div>
        </div>
        <div className="glyph fs1">
          <div className="clearfix bshadow0 pbs">
            <span className="icon-md-bag">
            </span>
            <span className="mls"> icon-md-bag</span>
          </div>
          <fieldset className="fs0 size1of1 clearfix hidden-false">
            <input type="text" value="e914" className="unit size1of2" />
            <input type="text" value="&#xe914;" className="unitRight size1of2 talign-right" />
          </fieldset>
          <div className="fs0 bshadow0 clearfix hidden-true">
            <span className="unit pvs fgc1">liga: </span>
            <input type="text" value="" className="liga unitRight" />
          </div>
        </div>
        <div className="glyph fs1">
          <div className="clearfix bshadow0 pbs">
            <span className="icon-face">
            </span>
            <span className="mls"> icon-face</span>
          </div>
          <fieldset className="fs0 size1of1 clearfix hidden-false">
            <input type="text" value="e915" className="unit size1of2" />
            <input type="text" value="&#xe915;" className="unitRight size1of2 talign-right" />
          </fieldset>
          <div className="fs0 bshadow0 clearfix hidden-true">
            <span className="unit pvs fgc1">liga: </span>
            <input type="text" value="" className="liga unitRight" />
          </div>
        </div>
        <div className="glyph fs1">
          <div className="clearfix bshadow0 pbs">
            <span className="icon-life-ring">
            </span>
            <span className="mls"> icon-life-ring</span>
          </div>
          <fieldset className="fs0 size1of1 clearfix hidden-false">
            <input type="text" value="e909" className="unit size1of2" />
            <input type="text" value="&#xe909;" className="unitRight size1of2 talign-right" />
          </fieldset>
          <div className="fs0 bshadow0 clearfix hidden-true">
            <span className="unit pvs fgc1">liga: </span>
            <input type="text" value="" className="liga unitRight" />
          </div>
        </div>
        <div className="glyph fs1">
          <div className="clearfix bshadow0 pbs">
            <span className="icon-check">
            </span>
            <span className="mls"> icon-check</span>
          </div>
          <fieldset className="fs0 size1of1 clearfix hidden-false">
            <input type="text" value="e90a" className="unit size1of2" />
            <input type="text" value="&#xe90a;" className="unitRight size1of2 talign-right" />
          </fieldset>
          <div className="fs0 bshadow0 clearfix hidden-true">
            <span className="unit pvs fgc1">liga: </span>
            <input type="text" value="" className="liga unitRight" />
          </div>
        </div>
        <div className="glyph fs1">
          <div className="clearfix bshadow0 pbs">
            <span className="icon-folder">
            </span>
            <span className="mls"> icon-folder</span>
          </div>
          <fieldset className="fs0 size1of1 clearfix hidden-false">
            <input type="text" value="e90b" className="unit size1of2" />
            <input type="text" value="&#xe90b;" className="unitRight size1of2 talign-right" />
          </fieldset>
          <div className="fs0 bshadow0 clearfix hidden-true">
            <span className="unit pvs fgc1">liga: </span>
            <input type="text" value="" className="liga unitRight" />
          </div>
        </div>
        <div className="glyph fs1">
          <div className="clearfix bshadow0 pbs">
            <span className="icon-chevron-up">
            </span>
            <span className="mls"> icon-chevron-up</span>
          </div>
          <fieldset className="fs0 size1of1 clearfix hidden-false">
            <input type="text" value="e90c" className="unit size1of2" />
            <input type="text" value="&#xe90c;" className="unitRight size1of2 talign-right" />
          </fieldset>
          <div className="fs0 bshadow0 clearfix hidden-true">
            <span className="unit pvs fgc1">liga: </span>
            <input type="text" value="" className="liga unitRight" />
          </div>
        </div>
        <div className="glyph fs1">
          <div className="clearfix bshadow0 pbs">
            <span className="icon-chevron-right">
            </span>
            <span className="mls"> icon-chevron-right</span>
          </div>
          <fieldset className="fs0 size1of1 clearfix hidden-false">
            <input type="text" value="e90d" className="unit size1of2" />
            <input type="text" value="&#xe90d;" className="unitRight size1of2 talign-right" />
          </fieldset>
          <div className="fs0 bshadow0 clearfix hidden-true">
            <span className="unit pvs fgc1">liga: </span>
            <input type="text" value="" className="liga unitRight" />
          </div>
        </div>
        <div className="glyph fs1">
          <div className="clearfix bshadow0 pbs">
            <span className="icon-chevron-left">
            </span>
            <span className="mls"> icon-chevron-left</span>
          </div>
          <fieldset className="fs0 size1of1 clearfix hidden-false">
            <input type="text" value="e90e" className="unit size1of2" />
            <input type="text" value="&#xe90e;" className="unitRight size1of2 talign-right" />
          </fieldset>
          <div className="fs0 bshadow0 clearfix hidden-true">
            <span className="unit pvs fgc1">liga: </span>
            <input type="text" value="" className="liga unitRight" />
          </div>
        </div>
        <div className="glyph fs1">
          <div className="clearfix bshadow0 pbs">
            <span className="icon-chevron-down">
            </span>
            <span className="mls"> icon-chevron-down</span>
          </div>
          <fieldset className="fs0 size1of1 clearfix hidden-false">
            <input type="text" value="e90f" className="unit size1of2" />
            <input type="text" value="&#xe90f;" className="unitRight size1of2 talign-right" />
          </fieldset>
          <div className="fs0 bshadow0 clearfix hidden-true">
            <span className="unit pvs fgc1">liga: </span>
            <input type="text" value="" className="liga unitRight" />
          </div>
        </div>
        <div className="glyph fs1">
          <div className="clearfix bshadow0 pbs">
            <span className="icon-map-pin">
            </span>
            <span className="mls"> icon-map-pin</span>
          </div>
          <fieldset className="fs0 size1of1 clearfix hidden-false">
            <input type="text" value="e908" className="unit size1of2" />
            <input type="text" value="&#xe908;" className="unitRight size1of2 talign-right" />
          </fieldset>
          <div className="fs0 bshadow0 clearfix hidden-true">
            <span className="unit pvs fgc1">liga: </span>
            <input type="text" value="" className="liga unitRight" />
          </div>
        </div>
        <div className="glyph fs1">
          <div className="clearfix bshadow0 pbs">
            <span className="icon-facility">
            </span>
            <span className="mls"> icon-facility</span>
          </div>
          <fieldset className="fs0 size1of1 clearfix hidden-false">
            <input type="text" value="e905" className="unit size1of2" />
            <input type="text" value="&#xe905;" className="unitRight size1of2 talign-right" />
          </fieldset>
          <div className="fs0 bshadow0 clearfix hidden-true">
            <span className="unit pvs fgc1">liga: </span>
            <input type="text" value="" className="liga unitRight" />
          </div>
        </div>
        <div className="glyph fs1">
          <div className="clearfix bshadow0 pbs">
            <span className="icon-hat">
            </span>
            <span className="mls"> icon-hat</span>
          </div>
          <fieldset className="fs0 size1of1 clearfix hidden-false">
            <input type="text" value="e906" className="unit size1of2" />
            <input type="text" value="&#xe906;" className="unitRight size1of2 talign-right" />
          </fieldset>
          <div className="fs0 bshadow0 clearfix hidden-true">
            <span className="unit pvs fgc1">liga: </span>
            <input type="text" value="" className="liga unitRight" />
          </div>
        </div>
        <div className="glyph fs1">
          <div className="clearfix bshadow0 pbs">
            <span className="icon-staff">
            </span>
            <span className="mls"> icon-staff</span>
          </div>
          <fieldset className="fs0 size1of1 clearfix hidden-false">
            <input type="text" value="e907" className="unit size1of2" />
            <input type="text" value="&#xe907;" className="unitRight size1of2 talign-right" />
          </fieldset>
          <div className="fs0 bshadow0 clearfix hidden-true">
            <span className="unit pvs fgc1">liga: </span>
            <input type="text" value="" className="liga unitRight" />
          </div>
        </div>
        <div className="glyph fs1">
          <div className="clearfix bshadow0 pbs">
            <span className="icon-datepicker-s">
            </span>
            <span className="mls"> icon-datepicker-s</span>
          </div>
          <fieldset className="fs0 size1of1 clearfix hidden-false">
            <input type="text" value="e904" className="unit size1of2" />
            <input type="text" value="&#xe904;" className="unitRight size1of2 talign-right" />
          </fieldset>
          <div className="fs0 bshadow0 clearfix hidden-true">
            <span className="unit pvs fgc1">liga: </span>
            <input type="text" value="" className="liga unitRight" />
          </div>
        </div>
        <div className="glyph fs1">
          <div className="clearfix bshadow0 pbs">
            <span className="icon-help-c">
            </span>
            <span className="mls"> icon-help-c</span>
          </div>
          <fieldset className="fs0 size1of1 clearfix hidden-false">
            <input type="text" value="e903" className="unit size1of2" />
            <input type="text" value="&#xe903;" className="unitRight size1of2 talign-right" />
          </fieldset>
          <div className="fs0 bshadow0 clearfix hidden-true">
            <span className="unit pvs fgc1">liga: </span>
            <input type="text" value="" className="liga unitRight" />
          </div>
        </div>
        <div className="glyph fs1">
          <div className="clearfix bshadow0 pbs">
            <span className="icon-download">
            </span>
            <span className="mls"> icon-download</span>
          </div>
          <fieldset className="fs0 size1of1 clearfix hidden-false">
            <input type="text" value="e902" className="unit size1of2" />
            <input type="text" value="&#xe902;" className="unitRight size1of2 talign-right" />
          </fieldset>
          <div className="fs0 bshadow0 clearfix hidden-true">
            <span className="unit pvs fgc1">liga: </span>
            <input type="text" value="" className="liga unitRight" />
          </div>
        </div>
        <div className="glyph fs1">
          <div className="clearfix bshadow0 pbs">
            <span className="icon-plus-fat">
            </span>
            <span className="mls"> icon-plus-fat</span>
          </div>
          <fieldset className="fs0 size1of1 clearfix hidden-false">
            <input type="text" value="e901" className="unit size1of2" />
            <input type="text" value="&#xe901;" className="unitRight size1of2 talign-right" />
          </fieldset>
          <div className="fs0 bshadow0 clearfix hidden-true">
            <span className="unit pvs fgc1">liga: </span>
            <input type="text" value="" className="liga unitRight" />
          </div>
        </div>
        <div className="glyph fs1">
          <div className="clearfix bshadow0 pbs">
            <span className="icon-pencil">
            </span>
            <span className="mls"> icon-pencil</span>
          </div>
          <fieldset className="fs0 size1of1 clearfix hidden-false">
            <input type="text" value="e900" className="unit size1of2" />
            <input type="text" value="&#xe900;" className="unitRight size1of2 talign-right" />
          </fieldset>
          <div className="fs0 bshadow0 clearfix hidden-true">
            <span className="unit pvs fgc1">liga: </span>
            <input type="text" value="" className="liga unitRight" />
          </div>
        </div>
        <div className="glyph fs1">
          <div className="clearfix bshadow0 pbs">
            <span className="icon-plus">
            </span>
            <span className="mls"> icon-plus</span>
          </div>
          <fieldset className="fs0 size1of1 clearfix hidden-false">
            <input type="text" value="e616" className="unit size1of2" />
            <input type="text" value="&#xe616;" className="unitRight size1of2 talign-right" />
          </fieldset>
          <div className="fs0 bshadow0 clearfix hidden-true">
            <span className="unit pvs fgc1">liga: </span>
            <input type="text" value="" className="liga unitRight" />
          </div>
        </div>
        <div className="glyph fs1">
          <div className="clearfix bshadow0 pbs">
            <span className="icon-info-f">
            </span>
            <span className="mls"> icon-info-f</span>
          </div>
          <fieldset className="fs0 size1of1 clearfix hidden-false">
            <input type="text" value="e615" className="unit size1of2" />
            <input type="text" value="&#xe615;" className="unitRight size1of2 talign-right" />
          </fieldset>
          <div className="fs0 bshadow0 clearfix hidden-true">
            <span className="unit pvs fgc1">liga: </span>
            <input type="text" value="" className="liga unitRight" />
          </div>
        </div>
        <div className="glyph fs1">
          <div className="clearfix bshadow0 pbs">
            <span className="icon-info">
            </span>
            <span className="mls"> icon-info</span>
          </div>
          <fieldset className="fs0 size1of1 clearfix hidden-false">
            <input type="text" value="e614" className="unit size1of2" />
            <input type="text" value="&#xe614;" className="unitRight size1of2 talign-right" />
          </fieldset>
          <div className="fs0 bshadow0 clearfix hidden-true">
            <span className="unit pvs fgc1">liga: </span>
            <input type="text" value="" className="liga unitRight" />
          </div>
        </div>
        <div className="glyph fs1">
          <div className="clearfix bshadow0 pbs">
            <span className="icon-date-picker">
            </span>
            <span className="mls"> icon-date-picker</span>
          </div>
          <fieldset className="fs0 size1of1 clearfix hidden-false">
            <input type="text" value="e613" className="unit size1of2" />
            <input type="text" value="&#xe613;" className="unitRight size1of2 talign-right" />
          </fieldset>
          <div className="fs0 bshadow0 clearfix hidden-true">
            <span className="unit pvs fgc1">liga: </span>
            <input type="text" value="" className="liga unitRight" />
          </div>
        </div>
        <div className="glyph fs1">
          <div className="clearfix bshadow0 pbs">
            <span className="icon-fax">
            </span>
            <span className="mls"> icon-fax</span>
          </div>
          <fieldset className="fs0 size1of1 clearfix hidden-false">
            <input type="text" value="e612" className="unit size1of2" />
            <input type="text" value="&#xe612;" className="unitRight size1of2 talign-right" />
          </fieldset>
          <div className="fs0 bshadow0 clearfix hidden-true">
            <span className="unit pvs fgc1">liga: </span>
            <input type="text" value="" className="liga unitRight" />
          </div>
        </div>
        <div className="glyph fs1">
          <div className="clearfix bshadow0 pbs">
            <span className="icon-trash">
            </span>
            <span className="mls"> icon-trash</span>
          </div>
          <fieldset className="fs0 size1of1 clearfix hidden-false">
            <input type="text" value="e611" className="unit size1of2" />
            <input type="text" value="&#xe611;" className="unitRight size1of2 talign-right" />
          </fieldset>
          <div className="fs0 bshadow0 clearfix hidden-true">
            <span className="unit pvs fgc1">liga: </span>
            <input type="text" value="" className="liga unitRight" />
          </div>
        </div>
        <div className="glyph fs1">
          <div className="clearfix bshadow0 pbs">
            <span className="icon-calendar-fav">
            </span>
            <span className="mls"> icon-calendar-fav</span>
          </div>
          <fieldset className="fs0 size1of1 clearfix hidden-false">
            <input type="text" value="e60d" className="unit size1of2" />
            <input type="text" value="&#xe60d;" className="unitRight size1of2 talign-right" />
          </fieldset>
          <div className="fs0 bshadow0 clearfix hidden-true">
            <span className="unit pvs fgc1">liga: </span>
            <input type="text" value="" className="liga unitRight" />
          </div>
        </div>
        <div className="glyph fs1">
          <div className="clearfix bshadow0 pbs">
            <span className="icon-dropdown-arrow">
            </span>
            <span className="mls"> icon-dropdown-arrow</span>
          </div>
          <fieldset className="fs0 size1of1 clearfix hidden-false">
            <input type="text" value="e60e" className="unit size1of2" />
            <input type="text" value="&#xe60e;" className="unitRight size1of2 talign-right" />
          </fieldset>
          <div className="fs0 bshadow0 clearfix hidden-true">
            <span className="unit pvs fgc1">liga: </span>
            <input type="text" value="" className="liga unitRight" />
          </div>
        </div>
        <div className="glyph fs1">
          <div className="clearfix bshadow0 pbs">
            <span className="icon-x-2">
            </span>
            <span className="mls"> icon-x-2</span>
          </div>
          <fieldset className="fs0 size1of1 clearfix hidden-false">
            <input type="text" value="e60f" className="unit size1of2" />
            <input type="text" value="&#xe60f;" className="unitRight size1of2 talign-right" />
          </fieldset>
          <div className="fs0 bshadow0 clearfix hidden-true">
            <span className="unit pvs fgc1">liga: </span>
            <input type="text" value="" className="liga unitRight" />
          </div>
        </div>
        <div className="glyph fs1">
          <div className="clearfix bshadow0 pbs">
            <span className="icon-search-circle">
            </span>
            <span className="mls"> icon-search-circle</span>
          </div>
          <fieldset className="fs0 size1of1 clearfix hidden-false">
            <input type="text" value="e610" className="unit size1of2" />
            <input type="text" value="&#xe610;" className="unitRight size1of2 talign-right" />
          </fieldset>
          <div className="fs0 bshadow0 clearfix hidden-true">
            <span className="unit pvs fgc1">liga: </span>
            <input type="text" value="" className="liga unitRight" />
          </div>
        </div>
        <div className="glyph fs1">
          <div className="clearfix bshadow0 pbs">
            <span className="icon-speak">
            </span>
            <span className="mls"> icon-speak</span>
          </div>
          <fieldset className="fs0 size1of1 clearfix hidden-false">
            <input type="text" value="e607" className="unit size1of2" />
            <input type="text" value="&#xe607;" className="unitRight size1of2 talign-right" />
          </fieldset>
          <div className="fs0 bshadow0 clearfix hidden-true">
            <span className="unit pvs fgc1">liga: </span>
            <input type="text" value="" className="liga unitRight" />
          </div>
        </div>
        <div className="glyph fs1">
          <div className="clearfix bshadow0 pbs">
            <span className="icon-phone">
            </span>
            <span className="mls"> icon-phone</span>
          </div>
          <fieldset className="fs0 size1of1 clearfix hidden-false">
            <input type="text" value="e608" className="unit size1of2" />
            <input type="text" value="&#xe608;" className="unitRight size1of2 talign-right" />
          </fieldset>
          <div className="fs0 bshadow0 clearfix hidden-true">
            <span className="unit pvs fgc1">liga: </span>
            <input type="text" value="" className="liga unitRight" />
          </div>
        </div>
        <div className="glyph fs1">
          <div className="clearfix bshadow0 pbs">
            <span className="icon-left-arrow">
            </span>
            <span className="mls"> icon-left-arrow</span>
          </div>
          <fieldset className="fs0 size1of1 clearfix hidden-false">
            <input type="text" value="e609" className="unit size1of2" />
            <input type="text" value="&#xe609;" className="unitRight size1of2 talign-right" />
          </fieldset>
          <div className="fs0 bshadow0 clearfix hidden-true">
            <span className="unit pvs fgc1">liga: </span>
            <input type="text" value="" className="liga unitRight" />
          </div>
        </div>
        <div className="glyph fs1">
          <div className="clearfix bshadow0 pbs">
            <span className="icon-x">
            </span>
            <span className="mls"> icon-x</span>
          </div>
          <fieldset className="fs0 size1of1 clearfix hidden-false">
            <input type="text" value="e60a" className="unit size1of2" />
            <input type="text" value="&#xe60a;" className="unitRight size1of2 talign-right" />
          </fieldset>
          <div className="fs0 bshadow0 clearfix hidden-true">
            <span className="unit pvs fgc1">liga: </span>
            <input type="text" value="" className="liga unitRight" />
          </div>
        </div>
        <div className="glyph fs1">
          <div className="clearfix bshadow0 pbs">
            <span className="icon-night">
            </span>
            <span className="mls"> icon-night</span>
          </div>
          <fieldset className="fs0 size1of1 clearfix hidden-false">
            <input type="text" value="e601" className="unit size1of2" />
            <input type="text" value="&#xe601;" className="unitRight size1of2 talign-right" />
          </fieldset>
          <div className="fs0 bshadow0 clearfix hidden-true">
            <span className="unit pvs fgc1">liga: </span>
            <input type="text" value="" className="liga unitRight" />
          </div>
        </div>
        <div className="glyph fs1">
          <div className="clearfix bshadow0 pbs">
            <span className="icon-evening">
            </span>
            <span className="mls"> icon-evening</span>
          </div>
          <fieldset className="fs0 size1of1 clearfix hidden-false">
            <input type="text" value="e602" className="unit size1of2" />
            <input type="text" value="&#xe602;" className="unitRight size1of2 talign-right" />
          </fieldset>
          <div className="fs0 bshadow0 clearfix hidden-true">
            <span className="unit pvs fgc1">liga: </span>
            <input type="text" value="" className="liga unitRight" />
          </div>
        </div>
        <div className="glyph fs1">
          <div className="clearfix bshadow0 pbs">
            <span className="icon-afternoon">
            </span>
            <span className="mls"> icon-afternoon</span>
          </div>
          <fieldset className="fs0 size1of1 clearfix hidden-false">
            <input type="text" value="e603" className="unit size1of2" />
            <input type="text" value="&#xe603;" className="unitRight size1of2 talign-right" />
          </fieldset>
          <div className="fs0 bshadow0 clearfix hidden-true">
            <span className="unit pvs fgc1">liga: </span>
            <input type="text" value="" className="liga unitRight" />
          </div>
        </div>
        <div className="glyph fs1">
          <div className="clearfix bshadow0 pbs">
            <span className="icon-morning">
            </span>
            <span className="mls"> icon-morning</span>
          </div>
          <fieldset className="fs0 size1of1 clearfix hidden-false">
            <input type="text" value="e604" className="unit size1of2" />
            <input type="text" value="&#xe604;" className="unitRight size1of2 talign-right" />
          </fieldset>
          <div className="fs0 bshadow0 clearfix hidden-true">
            <span className="unit pvs fgc1">liga: </span>
            <input type="text" value="" className="liga unitRight" />
          </div>
        </div>
        <div className="glyph fs1">
          <div className="clearfix bshadow0 pbs">
            <span className="icon-sunrise">
            </span>
            <span className="mls"> icon-sunrise</span>
          </div>
          <fieldset className="fs0 size1of1 clearfix hidden-false">
            <input type="text" value="e605" className="unit size1of2" />
            <input type="text" value="&#xe605;" className="unitRight size1of2 talign-right" />
          </fieldset>
          <div className="fs0 bshadow0 clearfix hidden-true">
            <span className="unit pvs fgc1">liga: </span>
            <input type="text" value="" className="liga unitRight" />
          </div>
        </div>
        <div className="glyph fs1">
          <div className="clearfix bshadow0 pbs">
            <span className="icon-noon">
            </span>
            <span className="mls"> icon-noon</span>
          </div>
          <fieldset className="fs0 size1of1 clearfix hidden-false">
            <input type="text" value="e606" className="unit size1of2" />
            <input type="text" value="&#xe606;" className="unitRight size1of2 talign-right" />
          </fieldset>
          <div className="fs0 bshadow0 clearfix hidden-true">
            <span className="unit pvs fgc1">liga: </span>
            <input type="text" value="" className="liga unitRight" />
          </div>
        </div>
        <div className="glyph fs1">
          <div className="clearfix bshadow0 pbs">
            <span className="icon-ticket">
            </span>
            <span className="mls"> icon-ticket</span>
          </div>
          <fieldset className="fs0 size1of1 clearfix hidden-false">
            <input type="text" value="e94e" className="unit size1of2" />
            <input type="text" value="&#xe94e;" className="unitRight size1of2 talign-right" />
          </fieldset>
          <div className="fs0 bshadow0 clearfix hidden-true">
            <span className="unit pvs fgc1">liga: </span>
            <input type="text" value="" className="liga unitRight" />
          </div>
        </div>
      </div>
      <div className="clearfix mhl ptl">
        <div className="glyph fs2">
          <div className="clearfix bshadow0 pbs">
            <span className="icon-tumblr2">
            </span>
            <span className="mls"> icon-tumblr2</span>
          </div>
          <fieldset className="fs0 size1of1 clearfix hidden-false">
            <input type="text" value="eabc" className="unit size1of2" />
            <input type="text" value="&#xeabc;" className="unitRight size1of2 talign-right" />
          </fieldset>
          <div className="fs0 bshadow0 clearfix hidden-true">
            <span className="unit pvs fgc1">liga: </span>
            <input type="text" value="tumblr2, brand51" className="liga unitRight" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default IconsDemo;
