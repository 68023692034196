export const getCurrentLocale = state => state.intl.locale;
export const getLocales = state => state.intl.locales;
export const getLocale = state => state.intl.locale;
export const getMessages = state => state.intl.allMessages[state.intl.locale];
export const getDefaultInsurer = state => state.config.defaultInsurer;
export const getMultiPayerFlag = state => state.config.isMultiPayer;
export const getIsEmailNotificationsEnabled = state => state.config.isEmailNotificationsEnabled;
export const getSupportsCashPay = state => state.config.supportsCashPay;
export const getCSRConcierge = state => state.config.CSRConcierge;
export const getApiKey = state => state.config.apiKey;
export const getVimApiUrl = state => state.config.vimApiUrl;
export const getSdkKeys = state => state.config.schedulingSdkKeys;
