import { autobind } from 'core-decorators';
import BkmdApi from './bkmdApi';
import ServicesApi from './services';

const SERVICE_NAME = 'clinicPermissions';

@autobind
export default class ClinicPermissionsApi extends BkmdApi {
  constructor(fetch) {
    super(fetch, '/clinicManagement');
    this.servicesApi = new ServicesApi(fetch);
  }

  addPermission(type, userId, permission, resourceType, aclResourceId, isGroup) {
    return this.servicesApi.callSecure(SERVICE_NAME, 'addPermission', {
      type,
      userId,
      isGroup,
      permission,
      resourceType,
      aclResourceId,
    });
  }

  removePermission(type, userId, resourceType, aclResourceId, isGroup) {
    return this.servicesApi.callSecure(SERVICE_NAME, 'removePermission', {
      type,
      userId,
      isGroup,
      resourceType,
      aclResourceId,
    });
  }
}
