/**
 * Created by chenrozenes on 21/12/2017.
 */
import PropTypes from 'prop-types';

/**
 * PropType object shape for the flow object
 */
export const FlowShape = PropTypes.shape({
  next: PropTypes.func.isRequired,
  back: PropTypes.func.isRequired,
  hasNext: PropTypes.func.isRequired,
  hasBack: PropTypes.func.isRequired,
  finish: PropTypes.func.isRequired,
  start: PropTypes.func.isRequired,
  state: PropTypes.object.isRequired,
  isActive: PropTypes.bool.isRequired,
  flowName: PropTypes.string.isRequired,
  flowId: PropTypes.string,
  currentStep: PropTypes.string, // step name
  stepProps: PropTypes.object,
});

/**
 * PropType object for single step meta description
 */
export const FlowStepMetaShape = PropTypes.shape({
  /**
   * Name of the step - should be equal to the holding object key
   */
  name: PropTypes.string.isRequired,
  /**
   * Array of parents
   */
  parents: PropTypes.array,
  /**
   * Object with keys as step names and value is a predicate that will determine which child
   * to go next
   */
  children: PropTypes.objectOf(PropTypes.func),
  mapContextToProps: PropTypes.func,
  mapReturnValueToContext: PropTypes.func,
});
