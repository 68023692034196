/**
 * Created by chenrozenes on 18/01/2017.
 */

import _ from 'lodash';
import { getFromState } from './util';

export const INIT = 'podrick/INIT';
export const REMOVE = 'podrick/REMOVE';
export const UPDATE_CHANGES = 'podrick/UPDATE_CHANGES';
export const CLEAR_CHANGES = 'podrick/CLEAR_CHANGES';
export const SAVE_CHANGES = 'podrick/SAVE_CHANGES';
export const ROLLBACK = 'podrick/ROLLBACK';
export const SEND_UPDATE = 'podrick/SEND_UPDATE';
export const UPDATE = 'podrick/UPDATE';
export const FLUSH = 'podrick/FLUSH';
export const PULL = 'podrick/PULL';

export default function buildActions(type) {
  const key = type.key;
  const updateMethod = type.updateMethod;
  const updateAction = type.updateAction;
  const alwaysFlush = type.alwaysFlush;

  if (!updateMethod && !updateAction) {
    throw new Error('Every type defined needs to have an update method that returns a ' +
      'promise or an updateAction that returns a redux action');
  }

  const configDebounceTime = type.debounceTime;

  function updateChanges(batch) {
    return { type: UPDATE_CHANGES, payload: batch, meta: { key } };
  }

  function clearChanges() {
    return { type: CLEAR_CHANGES, meta: { key } };
  }

  function rollback() {
    return { type: ROLLBACK, meta: { key } };
  }

  function remove() {
    return { type: REMOVE, meta: { key } };
  }

  function init() {
    return { type: INIT, meta: { key } };
  }

  function sendUpdate(pendingChanges) {
    return ({ dispatch, getState }) => {
      const state = getState();
      return {
        type: SEND_UPDATE,
        payload: {
          promise: updateAction ? Promise.resolve(dispatch(updateAction(pendingChanges, state)))
            : updateMethod(pendingChanges, state)
        }
      };
    };
  }

  /**
   * Updates the entity in the server by its pending changes object
   * @param debounceTime
   * @returns {function()}
   */
  function update(debounceTime = configDebounceTime) {
    return ({ dispatch, getState }) => {
      const state = getState();
      const pendingChanges = getFromState(state, key, 'pendingChanges');

      function send() {
        return dispatch(sendUpdate(pendingChanges))
          .then(() => {
            const currState = getState();
            const currPendingChanges = getFromState(currState, key, 'pendingChanges');
            // Keep updated pending changes if different from what we just updated the server with
            if (!_.isEqual(currPendingChanges, pendingChanges)) {
              dispatch(updateChanges(currPendingChanges));
              return dispatch(update());
            }

            dispatch(clearChanges());
            return true;
          });
      }

      return {
        type: UPDATE,
        meta: { key },
        debounce: {
          time: debounceTime,
          flush: debounceTime === false,
          execute: send
        }
      };
    };
  }

  /**
   * General method for updating an entity in batch updates.
   * If update is true, update will be performed in the server immediately
   * @param batch
   * @param flush
   * @param debounceTime
   * @returns {*}
   */
  function saveChanges(batch, flush = alwaysFlush, debounceTime) {
    return ({ dispatch }) => ({
      type: SAVE_CHANGES,
      meta: { key },
      payload: {
        promise: new Promise(resolved => {
          dispatch(updateChanges(batch));
          if (flush) {
            return dispatch(update(debounceTime)).then(resolved);
          }
          return resolved();
        })
      }
    });
  }

  /**
   * Saves the pending changes on the entity without debouncing
   */
  function flush() {
    return ({ dispatch }) => ({
      type: FLUSH,
      meta: { key },
      payload: {
        promise: dispatch(update(false))
      }
    });
  }

  /**
   * Pulls the data into Podrick state.
   * parameter can be :
   * 1. string - for path to an object on the state
   * 2. function(state) - to be evaluated by a function that receives the state as parameter
   * 3. other - will be pulled as is
   * @param param
   * @returns {function()}
   */
  function pull(param) {
    return ({ getState }) => {
      const state = getState();
      let data = param;

      if (_.isString(param)) {
        data = _.get(state, param, {});
      } else if (_.isFunction(param)) {
        data = param(state);
      }

      return {
        type: PULL,
        meta: { key },
        payload: _.cloneDeep(data)
      };
    };
  }

  return { pull, flush, saveChanges, remove, init, rollback };
}
