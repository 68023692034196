import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '../../storybook/src/utils/theme';

function SvgSearchUser({ theme }) {
  /* eslint-disable */
  return (
    <svg aria-hidden="true" focusable="false" className="svg-icon svg-search-user" width="70px" height="70px" viewBox="0 0 70 70" version="1.1">
      <g id="Initiating-Referral-flow---w/-a-vim-doctor" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="find-specialist" transform="translate(-798.000000, -79.000000)">
          <g id="Page-1" transform="translate(799.000000, 80.000000)">
            <g id="Group-16">
              <path d="M5.5,55 C5.5,54.1715729 6.17157288,53.5 7,53.5 C7.82842712,53.5 8.5,54.1715729 8.5,55 L8.5,61 C8.5,61.8284271 7.82842712,62.5 7,62.5 C6.17157288,62.5 5.5,61.8284271 5.5,61 L5.5,55 Z" id="Stroke-1" fill={theme.secondaryColor} fillRule="nonzero"></path>
              <path d="M10,56.5 C10.8284271,56.5 11.5,57.1715729 11.5,58 C11.5,58.8284271 10.8284271,59.5 10,59.5 L4,59.5 C3.17157288,59.5 2.5,58.8284271 2.5,58 C2.5,57.1715729 3.17157288,56.5 4,56.5 L10,56.5 Z" id="Stroke-2" fill={theme.secondaryColor} fillRule="nonzero"></path>
              <path d="M59.5,19.5 C59.5,17.8434271 58.1565729,16.5 56.5,16.5 C54.8434271,16.5 53.5,17.8434271 53.5,19.5 C53.5,21.1565729 54.8434271,22.5 56.5,22.5 C58.1565729,22.5 59.5,21.1565729 59.5,19.5 Z M62.5,19.5 C62.5,22.8134271 59.8134271,25.5 56.5,25.5 C53.1865729,25.5 50.5,22.8134271 50.5,19.5 C50.5,16.1865729 53.1865729,13.5 56.5,13.5 C59.8134271,13.5 62.5,16.1865729 62.5,19.5 Z" id="Stroke-4" fill={theme.secondaryColor} fillRule="nonzero"></path>
              <path d="M68.5,34.5 C68.5,35.604 67.604,36.5 66.5,36.5 C65.396,36.5 64.5,35.604 64.5,34.5 C64.5,33.396 65.396,32.5 66.5,32.5 C67.604,32.5 68.5,33.396 68.5,34.5" id="Fill-6" fill={theme.secondaryColor} ></path>
              <path d="M60.75,50.917 L60.75,55.5 C60.75,56.3284271 60.0784271,57 59.25,57 L39.083,57 C38.2545729,57 37.583,56.3284271 37.583,55.5 C37.583,54.6715729 38.2545729,54 39.083,54 L57.75,54 L57.75,50.917 C57.75,39.6418439 50.4966785,29.7663034 39.9535569,26.2593241 C38.8332717,25.8866815 38.5626962,24.4257891 39.4752128,23.6766462 C42.3242222,21.3377134 44,17.9347521 44,14.25 C44,7.48442712 38.5155729,2 31.75,2 C24.9844271,2 19.5,7.48442712 19.5,14.25 C19.5,17.9347521 21.1757778,21.3377134 24.0247872,23.6766462 C24.6650806,24.2023038 24.7580113,25.1474938 24.2323538,25.7877872 C23.7066962,26.4280806 22.7615062,26.5210113 22.1212128,25.9953538 C18.5911397,23.0972925 16.5,18.8508658 16.5,14.25 C16.5,5.82757288 23.3275729,-1 31.75,-1 C40.1724271,-1 47,5.82757288 47,14.25 C47,18.0084427 45.6045323,21.5303596 43.1691383,24.2739256 C53.6877249,28.7781203 60.75,39.1655182 60.75,50.917 Z" id="Stroke-8" fill={theme.mainColor} fillRule="nonzero"></path>
              <path d="M3.86885207,51.2119674 C4.33267879,51.8983757 4.15224081,52.8308253 3.46583257,53.2946521 C2.77942432,53.7584788 1.84697465,53.5780408 1.38314793,52.8916326 C-0.161088672,50.6063465 -1,47.9091457 -1,45.0918 C-1,37.3880461 5.24489961,31.1428 12.949,31.1428 C20.6524271,31.1428 26.898,37.3883729 26.898,45.0918 C26.898,51.9250356 21.952261,57.7204434 15.2846557,58.8458781 C14.4677834,58.9837591 13.6938029,58.433328 13.5559219,57.6164557 C13.4180409,56.7995834 13.968472,56.0256029 14.7853443,55.8877219 C20.0150001,55.0050008 23.898,50.4549086 23.898,45.0918 C23.898,39.0452271 18.9955729,34.1428 12.949,34.1428 C6.90179112,34.1428 2,39.0448631 2,45.0918 C2,47.3058 2.65725173,49.4189434 3.86885207,51.2119674 Z" id="Stroke-10" fill={theme.mainColor} fillRule="nonzero"></path>
              <path d="M12.8047,40.2656 C11.9762729,40.2656 11.3047,39.5940271 11.3047,38.7656 C11.3047,37.9371729 11.9762729,37.2656 12.8047,37.2656 C16.0297871,37.2656 18.8982966,39.2032364 20.1323319,42.1225682 C20.4548838,42.8856224 20.0977861,43.7656801 19.3347318,44.0882319 C18.5716776,44.4107838 17.6916199,44.0536861 17.3690681,43.2906318 C16.6004716,41.472379 14.8139356,40.2656 12.8047,40.2656 Z" id="Stroke-12" fill={theme.secondaryColor} fillRule="nonzero"></path>
              <path d="M22.5819398,55.7032602 C21.9961534,55.1174737 21.9961534,54.1677263 22.5819398,53.5819398 C23.1677263,52.9961534 24.1174737,52.9961534 24.7032602,53.5819398 L37.5602602,66.4389398 C38.1460466,67.0247263 38.1460466,67.9744737 37.5602602,68.5602602 C36.9744737,69.1460466 36.0247263,69.1460466 35.4389398,68.5602602 L22.5819398,55.7032602 Z" id="Stroke-14" fill={theme.mainColor} fillRule="nonzero"></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
  /* eslint-enable */
}

SvgSearchUser.propTypes = {
  theme: PropTypes.object.isRequired,
};

export default withTheme(SvgSearchUser);
