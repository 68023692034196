import { compose, pure } from 'recompose';
import Api from '../../../../api';
import ThreeBounceSpinner from '../../../../components/ui/spinner/threeBounceSpinner';
import withTokenizedFlow from '../../../../components/recompose/withTokenizedFlow';

export default compose(
  pure,
  withTokenizedFlow({
    activationCampaignSource: 'colorectalFlow',
    activationNextPage: '/screeningkit',
    activatedFlowPath: token => `/screeningkit/${token}`,
    tokenGeneratorHandler: activationToken => Api.colorectalFlowApi.generateToken(activationToken),
  }),
)(ThreeBounceSpinner);
