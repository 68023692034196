/**
 * Created by chenrozenes on 26/04/2017.
 */
import React from 'react';
import PropTypes from 'prop-types';
import Formatter from '../../../utils/formatter';
import CommonFormats from '../../../utils/formatter/commonFormats';

export default function Date({ value, cellProperties }) {
  let toDisplay = '';
  const { format } = cellProperties;
  if (value) {
    toDisplay = format ? Formatter.dateUtc(value, format) :
     `${Formatter.dateUtc(value, CommonFormats.SHORT_MONTH_DAY_YEAR_TIME)} UTC`;
  }
  return <span>{toDisplay}</span>;
}

Date.propTypes = {
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  cellProperties: PropTypes.object,
};

Date.defaultProps = {
  value: {},
  cellProperties: {},
};
