/**
 * Created by moran on 7/17/17.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages } from 'react-intl';
import * as Levels from '../../../../model/enum/physicianLevels';
import ProviderScore from './providerScore';
import './rating.less';

export const messages = defineMessages({
  superHero: {
    defaultMessage: 'Super hero',
    id: 'members.searchDoctorsResults.superHero',
  },
  hero: {
    defaultMessage: 'Hero',
    id: 'members.searchDoctorsResults.hero',
  },
});

export default class Rating extends React.PureComponent {
  static propTypes = {
    rating: PropTypes.number,
    level: PropTypes.string,
  };

  static defaultProps = {
    rating: 0,
    level: '',
  };

  render() {
    const { rating, level } = this.props;

    return (
      <ProviderScore
        rating={rating}
        ratingClassName={level === Levels.SUPER_HERO ? 'super-hero' : ''}
      />
    );
  }
}
