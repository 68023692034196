/* eslint-disable react/no-unused-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';

import { fullNameSelector } from '../../../../store/member/selectors';

import ThreeBounceSpinner from '../../../../components/ui/spinner/threeBounceSpinner';

export default function fetchProfile(Component) {
  class FetchProfile extends React.Component {
    static hasData(props) {
      const { email, fullName, notificationSettings } = props;
      return !!email && !!fullName && !!notificationSettings;
    }

    static propTypes = {
      // Profile data fetched by this component
      phoneNumber: PropTypes.object,
      phoneNumberVerified: PropTypes.bool,
      email: PropTypes.string,
      emailVerified: PropTypes.bool,
      fullName: PropTypes.string,
      notificationSettings: PropTypes.object,
      searchSettings: PropTypes.object,
    };

    static defaultProps = {
      phoneNumber: undefined,
      phoneNumberVerified: undefined,
      email: undefined,
      emailVerified: undefined,
      fullName: undefined,
      notificationSettings: undefined,
      searchSettings: undefined,
    };

    render() {
      return (
        <div>
          {FetchProfile.hasData(this.props) ? (
            <Component {...this.props} />
          ) : (
            <ThreeBounceSpinner />
          )}
        </div>
      );
    }
  }

  return connect(state => {
    const { notifications, search } = state.member.settings;
    const user = state.auth.user || {};
    const { phoneNumber, phoneNumberVerified, email, emailVerified } = user;
    return {
      phoneNumber,
      fullName: fullNameSelector(state),
      phoneNumberVerified,
      email,
      emailVerified,
      notificationSettings: notifications,
      searchSettings: search,
      member: _.get(state, 'member.info'),
    };
  })(FetchProfile);
}
/* eslint-enable react/no-unused-prop-types */
