import _ from 'lodash';
import { compose, lifecycle } from 'recompose';

/**
 * warp a component with effects
 * effects are actions that happens in mount or update of a component
 * there are several configurations to preform one or multiple effects on mount or update
 * effects config should look like:
 * {
 *   someAction: {
 *     action: (props, onDidUpdate) => { someActionToPerform }
 *     didMountPredicate: props => { return someCondition; }
 *     @optional didUpdatePredicate: (props, prevProps) => { return someCondition; }
 *   }
 * }
 *
 * if only didMountPredicate is being sent it will be checked against didMount/Update
 * if both predicates are being sent, each predicate will be checked against it's event
 *
 * the onDidUpdate indicates that:
 * componentDidMount -> onDidUpdate = false
 * componentDidUpdate -> onDidUpdate = true
 * @param effects
 * @returns {*}
 */
const withEffects = effects =>
  compose(
    lifecycle({
      componentDidUpdate(prevProps) {
        const props = this.props;
        _.each(effects, ({ action, didMountPredicate, didUpdatePredicate }) => {
          const predicate = didUpdatePredicate || didMountPredicate;
          if (predicate(props, prevProps)) {
            action(props, true);
          }
        });
      },
      componentDidMount() {
        const props = this.props;
        _.each(effects, ({ action, didMountPredicate }) => {
          if (didMountPredicate(props)) {
            action(props, false);
          }
        });
      },
    }),
  );

export default withEffects;
