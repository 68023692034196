/**
 * Created by chenrozenes on 07/07/2016.
 */
import zxcvbn from 'zxcvbn';
import _ from 'lodash';
import { defineMessages } from 'react-intl';

import IntlEnum from '../../../utils/intlEnum';

const messages = defineMessages({
  veryWeak: {
    defaultMessage: 'Very weak',
    id: 'enum.passwordStrength.veryWeak'
  },
  weak: {
    defaultMessage: 'Weak',
    id: 'enum.passwordStrength.weak'
  },
  good: {
    defaultMessage: 'Good',
    id: 'enum.passwordStrength.good'
  },
  strong: {
    defaultMessage: 'Strong',
    id: 'enum.passwordStrength.strong'
  },
  veryStrong: {
    defaultMessage: 'Very strong',
    id: 'enum.passwordStrength.veryStrong'
  }
});

export const PasswordStrengthEnum = new IntlEnum(
  { value: 0, message: messages.veryWeak },
  { value: 1, message: messages.weak },
  { value: 2, message: messages.good },
  { value: 3, message: messages.strong },
  { value: 4, message: messages.veryStrong },
);

export function passwordValidator(userInputFields = [], fieldName = 'password', minScore = 2) {
  return {
    [fieldName]: (value, allFields) => {
      const userInputs = _.values(_.pick(allFields, userInputFields));
      const passwordScore = zxcvbn(value, userInputs);
      return passwordScore.score < minScore;
    }
  };
}
