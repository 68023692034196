import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import PhoneLink from '../../../../components/ui/links/phoneLink';
import commonMessages from '../../../../store/intl/commonMessages';

import './help.less';

const messages = defineMessages({
  callCustomerSupport: {
    defaultMessage: 'Call customer support',
    id: 'activation.helpModal.callCustomerSupport',
  },
  monFriFrom: {
    defaultMessage: 'Mon-Fri from 9AM - 5PM PT',
    id: 'activation.helpModal.monFriFrom',
  },
  sendUsAnEmail: {
    defaultMessage: 'Send us an email',
    id: 'activation.helpModal.sendUsAnEmail',
  },
  wellGetBackToYou: {
    defaultMessage: 'We’ll get back to you within 2 business days.',
    id: 'activation.helpModal.wellGetBackToYou',
  },
  bookmdSupportEmail: {
    defaultMessage: 'Email Us',
    id: 'activation.helpModal.bookmdSupportEmail',
  },
});

function HelpContactInfo() {
  return (
    <div className="row">
      <div className="col-sm-4 col-sm-offset-2">
        <div className="contact-section">
          <i className="icon-iphone contact-icon" />
          <h2 className="text-semibold text-dark text-16 no-margin">
            <FormattedMessage {...messages.callCustomerSupport} />
          </h2>
          <div className="margin-top-5 text-16 font-color-brand-link">
            <PhoneLink phoneNumber={commonMessages.bookmdPhoneNumber} />
          </div>
          <div className="margin-top-5 text-14">
            <FormattedMessage {...messages.monFriFrom} />
          </div>
        </div>
      </div>
      <div className="col-sm-4">
        <div className="contact-section">
          <i className="icon-envelope contact-icon small" />
          <h2 className="text-semibold text-dark text-16 no-margin">
            <FormattedMessage {...messages.sendUsAnEmail} />
          </h2>
          <div className="margin-top-5 text-16">
            <FormattedMessage {...messages.wellGetBackToYou} />
          </div>
          <div className="margin-top-5 text-16">
            <a href="mailto:support@getvim.com" className="font-color-brand-link">
              <FormattedMessage {...messages.bookmdSupportEmail} />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HelpContactInfo;
