/**
 * Created by meirshalev 03/01/2018.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import _ from 'lodash';
import { FormattedMessage, defineMessages } from 'react-intl';
import classNames from 'classnames';

import {
  createToggleState,
  createToggleStatePropTypes,
} from '../../../../../components/recompose/toggleStateComponent';

import BlueFooter from '../../../../../components/molecules/BlueFooter/BlueFooter';
import ActivationHeader from './activationHeader';
import AlreadyActivatedModal from '../modals/alreadyActivatedModal';
import NeedHelpModal from '../../help/helpModal';
import ClickableDiv from '../../../../../components/clickableElement/clickableDiv';

import '../activation.less';
import FeatureFlagged from '../../../../../components/features/featureFlagged';

const messages = defineMessages({
  help: {
    defaultMessage: 'I need help',
    id: 'activation.mobileNum.iNeedHelp',
  },
  signIn: {
    defaultMessage: 'Sign in to your account now',
    id: 'activation.mobileNum.signIn',
  },
});

const ActivationLayout = rawProps => {
  const props = _.assign({ bottomLinks: true, loading: false, contentOnly: false }, rawProps);
  return (
    <div className="activation-layout">
      {!props.contentOnly && <ActivationHeader goBack={props.goBack} />}
      <main className="content">
        <div className="row">
          <div className="col-sm-6 col-sm-offset-3 col-lg-4 col-lg-offset-4">
            {props.children}
            <div className={classNames('col-xs-12 text-center', { hidden: !props.bottomLinks })}>
              <div className="margin-top-20">
                <button
                  className="btn btn-inline-link font-color-brand-link"
                  onClick={props.helpModalShow}
                  type="button"
                >
                  <FormattedMessage {...messages.help} />
                </button>
              </div>
              <FeatureFlagged uri="activation.signInLink">
                <div className="margin-top">
                  <ClickableDiv
                    className="font-color-brand-link"
                    clickHandler={props.onSigninClick}
                  >
                    <FormattedMessage {...messages.signIn} />
                  </ClickableDiv>
                </div>
              </FeatureFlagged>
            </div>
          </div>
        </div>
      </main>

      {!props.contentOnly && <BlueFooter footerClassName="xs-not-fixed white" />}

      <NeedHelpModal handleClose={props.helpModalHide} isOpen={props.helpModalOn} />

      <AlreadyActivatedModal
        isOpen={props.alreadyActivatedModalOn}
        handleClose={props.alreadyActivatedModalHide}
      />
    </div>
  );
};

ActivationLayout.propTypes = {
  ...createToggleStatePropTypes('alreadyActivatedModal'),
  ...createToggleStatePropTypes('helpModal'),
  onSigninClick: PropTypes.func.isRequired,
  bottomLinks: PropTypes.bool,
  goBack: PropTypes.func,
  // setting true will hide the logos and the footer
  contentOnly: PropTypes.bool,
};

ActivationLayout.defaultProps = {
  contentOnly: false,
  bottomLinks: true,
  goBack: undefined,
};

export default compose(createToggleState('helpModal'))(ActivationLayout);
