import _ from 'lodash';
import { ENGLISH } from '../intl/languages';

export const UPDATE_APP_STATE_FROM_STORAGE = 'UPDATE_APP_STATE_FROM_STORAGE';
export const NOOP = 'NOOP';
export const UPDATE = '@@intl/UPDATE';

/** HACK: @mrsufgi - this is temporary until we install react-intl-redux ! */
export const updateIntl = ({ locale, formats, messages }) => ({
  type: UPDATE,
  payload: { locale, formats, messages },
});

export function updateLocaleFromStorage(initializeLocale) {
  return ({ dispatch, engine }) => {
    engine.load().then(state => {
      const locale = _.toLower(initializeLocale || _.get(state, 'intl.locale', ENGLISH));
      dispatch(updateIntl({ locale }));
    });

    return {
      type: UPDATE_APP_STATE_FROM_STORAGE,
    };
  };
}

export function noop() {
  return {
    type: NOOP,
    payload: {
      promise: Promise.resolve(),
    },
  };
}
