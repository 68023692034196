import React from 'react';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import { lifecycle } from 'recompose';
import { defineMessages, FormattedMessage } from 'react-intl';
import {
  addEventListenerTabCycle,
  removeEventListenerTabCycle,
} from '../../utils/modalAccessibility';
import Button from '../../storybook/src/components/atoms/Button/Button';
import BrandedH1 from '../../storybook/src/components/atoms/BrandedHeading/BrandedHeading';

const messages = defineMessages({
  cancel: {
    defaultMessage: 'Cancel this booking?',
    id: 'members.directory.booking.cancel',
  },
  areYouSure: {
    defaultMessage:
      'Are you sure you want to close this window before you’ve completed booking ' +
      'your appointment? You will be cancelling this booking.',
    id: 'members.directory.booking.areYouSure',
  },
  yes: {
    defaultMessage: 'Yes',
    id: 'members.directory.booking.yes',
  },
  no: {
    defaultMessage: 'No',
    id: 'members.directory.booking.no',
  },
});

@autobind
class CancelBooking extends React.Component {
  static propTypes = {
    onCloseBooking: PropTypes.func.isRequired,
    onKeepBooking: PropTypes.func.isRequired,
    messages: PropTypes.object,
  };

  static defaultProps = {
    messages: {},
  };

  render() {
    const formatMessages = { ...messages, ...this.props.messages };
    return (
      <div id="cancel-modal">
        <BrandedH1>
          <FormattedMessage {...formatMessages.cancel} />
        </BrandedH1>
        <div className="text-16 text-dark" aria-live="polite">
          <FormattedMessage {...formatMessages.areYouSure} />
        </div>
        <div className="row tiny-gutter margin-top-30 form-buttons">
          <div className="col-xs-6">
            <Button width="block" bgColor="gray" onClick={this.props.onKeepBooking}>
              <FormattedMessage {...formatMessages.no} />
            </Button>
          </div>
          <div className="col-xs-6">
            <Button width="block" onClick={this.props.onCloseBooking}>
              <FormattedMessage {...formatMessages.yes} onClick={this.onKeepBooking} />
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default lifecycle({
  componentDidMount() {
    addEventListenerTabCycle({ rootElementId: 'cancel-modal' });
  },
  componentWillUnmount() {
    removeEventListenerTabCycle();
  },
})(CancelBooking);
