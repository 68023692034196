/**
 * Created by meirshalev 24/08/2017.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  email: {
    defaultMessage: 'Email',
    id: 'members.settings.email',
  },
  password: {
    defaultMessage: 'Your password',
    id: 'members.settings.updateEmail.password',
  },
  serverErrorTitle: {
    defaultMessage: 'Hmm. Something went wrong',
    id: 'members.settings.updateEmail.serverErrorTitle',
  },
  serverErrorMessage: {
    defaultMessage: 'Please refresh the page and try again',
    id: 'members.settings.updateEmail.serverErrorMessage',
  },
  emailAlreadyTakenTitle: {
    defaultMessage: 'This email is already taken',
    id: 'members.settings.updateEmail.emailAlreadyTakenTitle',
  },
  invalidPasswordTitle: {
    defaultMessage: 'Wrong password',
    id: 'members.settings.updateEmail.invalidPasswordTitle',
  },
  updateEmail: {
    defaultMessage: 'Update your email',
    id: 'members.settings.updateEmail',
  },
  verifyEmail: {
    defaultMessage: 'Resend verification email',
    id: 'members.settings.verifyEmail',
  },
  yourEmail: {
    defaultMessage: 'Your email',
    id: 'members.settings.yourEmail',
  },
  weSentYouAVerificationEmail: {
    defaultMessage:
      'We sent you a verification email when you activated your account. ' +
      'You can check your inbox and click on the link, or resend it.',
    id: 'members.settings.weSentYouAVerificationEmail',
  },
  verifyEmailSuccessMessage: {
    defaultMessage: 'Sent, please check your inbox.',
    id: 'members.settings.updateEmail.verifyEmailSuccessMessage',
  },
  updateEmailSuccessMessage: {
    defaultMessage: 'Your email is updated.',
    id: 'members.settings.updateEmail.updateEmailSuccessMessage',
  },
});
