import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { FormattedMessage, defineMessages } from 'react-intl';
import { compose } from 'redux';
import { createToggleState, createToggleStatePropTypes } from '../recompose/toggleStateComponent';

const messages = defineMessages({
  alwaysStayInNetwork: {
    defaultMessage: 'Always stay in-network',
    id: 'common.insuranceIdBanner.alwaysStayInNetwork'
  },
  enterYourMemberIdToMakeSure: {
    defaultMessage:
      'Enter your member ID to make sure all doctors are in-network and close to work or home.',
    id: 'common.insuranceIdBanner.enterYourMemberIdToMakeSure'
  },
  enterYourMemberId: {
    defaultMessage: 'Enter your member ID',
    id: 'common.insuranceIdBanner.enterYourMemberId'
  }
});

const InsuranceIdBanner = props => {
  const { insuranceIdBannerHide, insuranceIdBannerOn, onClick = _.noop } = props;
  return (
    insuranceIdBannerOn && (
      <div className="insurance-id-banner bg-color-brand-main banner top">
        <button
          className="close-banner-btn btn btn-inline-link"
          onClick={insuranceIdBannerHide}
          aria-label="Close"
        >
          <i className="icon-x-2" />
        </button>
        <div className="row">
          <div className="col-sm-8">
            <div className="text-18 text-semibold">
              <i className="banner-icon icon-shield-check i-va-fix-2" />
              <FormattedMessage {...messages.alwaysStayInNetwork} />
            </div>
            <div className="text-14 margin-top-5">
              <FormattedMessage {...messages.enterYourMemberIdToMakeSure} />
            </div>
          </div>
          <div className="col-sm-4">
            <button
              className="btn btn-small bg-color-brand-success text-semibold banner-right-btn"
              onClick={onClick}
            >
              <FormattedMessage {...messages.enterYourMemberId} />
            </button>
          </div>
        </div>
      </div>
    )
  );
};

InsuranceIdBanner.propTypes = {
  onClick: PropTypes.func.isRequired,
  ...createToggleStatePropTypes('insuranceIdBanner')
};

export default compose(createToggleState('insuranceIdBanner', true))(InsuranceIdBanner);
