/**
 * Created by galgoltzman on 26/06/2017.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { autobind } from 'core-decorators';
import { connect } from 'react-redux';
import BkmdModal from '../../../../../components/bkmdModal/bkmdModal';
import ModalHeader from '../../../../../components/bkmdModal/modalHeader';
import SvgHelpStars from '../../../../../assets/svg/helpStars';
import { getSupportPhoneNumber } from '../../../../../store/config/selectors';
import Formatter from '../../../../../utils/formatter';
import PhoneLink from '../../../../../components/ui/links/phoneLink';

const messages = defineMessages({
  whatIfIDidntGetAnEmail: {
    defaultMessage: 'What if I didn’t get an email?',
    id: 'members.facilityResults.whatIfIDidntGetAnEmail',
  },
  ifYouveSearchedAround: {
    defaultMessage:
      'If you’ve searched around and you can’t find the email, we suggest you ' +
      '<a href="resetPassword" class="font-color-brand-link">try again</a>, ' +
      'just in case your email was mispelled the ' +
      'first time. It may take a few minutes to come through.',
    id: 'members.facilityResults.ifYouveSearchedAround',
  },
  stillNotThere: {
    defaultMessage: 'Still not there? Call us at {phoneLink} and we’ll be happy to help.',
    id: 'members.facilityResults.stillNotThere',
  },
  learnMore: {
    defaultMessage: 'Learn More',
    id: 'members.facilityResults.learnMore',
  },
  yourResetEmail: {
    defaultMessage: 'Your reset email',
    id: 'members.facilityResults.yourResetEmail',
  },
  findAnUrgentCareFacility: {
    defaultMessage: 'Find an urgent care facility',
    id: 'members.facilityResults.findAnUrgentCareFacility',
  },
  findAnEmergencyRoom: {
    defaultMessage: 'Find an emergency room',
    id: 'members.facilityResults.findAnEmergencyRoom',
  },
  ifYoureHavingALifeThreateningEmergency: {
    defaultMessage: 'If you’re having a life threatening emergency, please call 911.',
    id: 'members.facilityResults.ifYoureHavingALifeThreateningEmergency',
  },
  emergencyCare: {
    defaultMessage: 'Emergency Care',
    id: 'members.facilityResults.emergencyCare',
  },
});

@autobind
class ResetEmailHelpModal extends React.PureComponent {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    name: PropTypes.string,

    /**
     * Support phone number, fetched from store
     */
    phoneNumber: PropTypes.string.isRequired,
  };

  static defaultProps = {
    name: 'emergency_care_modal',
  };

  formatNumber() {
    const { number, countryDialingCode } = this.props.phoneNumber;
    return Formatter.phoneNumber({ countryDialingCode, number });
  }

  render() {
    const { isOpen, handleClose } = this.props;
    return (
      <BkmdModal
        isOpen={isOpen}
        handleClose={handleClose}
        className="emergency-care-modal fixed-header"
      >
        <ModalHeader onClose={handleClose} title={messages.yourResetEmail} />
        <div className="dialog-body">
          <div className="top-section text-center">
            <SvgHelpStars />
          </div>
          <div className="margin-top-30 text-18 text-semibold text-dark text-center">
            <FormattedMessage {...messages.whatIfIDidntGetAnEmail} />
          </div>
          <div className="margin-top-20 text-16">
            <FormattedHTMLMessage {...messages.ifYouveSearchedAround} />
          </div>
          <div className="margin-top-20 text-16">
            <FormattedMessage
              {...messages.stillNotThere}
              values={{
                phoneLink: <PhoneLink phoneNumber={this.formatNumber()} />,
              }}
            />
          </div>
        </div>
      </BkmdModal>
    );
  }
}

export default connect(state => ({
  phoneNumber: getSupportPhoneNumber(state),
}))(ResetEmailHelpModal);
