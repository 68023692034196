export const AppointmentType = {
  NEW_PATIENT: 'NEW_PATIENT',
  RETURNING_PATIENT: 'RETURNING_PATIENT',
};

export const appointmentTypeOptions = [
  { id: AppointmentType.NEW_PATIENT, commonName: 'New patient', displayName: 'New patient' },
  {
    id: AppointmentType.RETURNING_PATIENT,
    commonName: 'Returning patient',
    displayName: 'Returning patient',
  },
];
