import BkmdApi from '../bkmdApi';
import ServicesApi from '../services';

const SERVICE_NAME = 'calendar';

export default class ScheduleApi extends BkmdApi {
  constructor(fetch) {
    super(fetch, '/clinicManagement/');
    this.servicesApi = new ServicesApi(fetch);
  }

  getAllAppointments(calendarId) {
    return this.get('calendar/getAllAppointments', { id: calendarId }, undefined);
  }

  getAppointments(calendarId, startTime, endTime) {
    return this.get(
      '/calendar/getAppointments',
      { calendars: [calendarId], startTime, endTime },
      undefined,
    );
  }

  getUserAppointments(query) {
    return this.get('/calendar/getUserAppointments', query, undefined);
  }

  createSlot(calendarId, startTime, endTime, appointmentTypesIds) {
    return this.post('calendar/createSlot', {
      calendarId,
      startTime,
      endTime,
      appointmentTypesIds,
    });
  }

  updateSlot(slotId, startTime, endTime, appointmentTypesIds) {
    return this.post('calendar/updateSlot', {
      slotId,
      startTime,
      endTime,
      appointmentTypesIds,
    });
  }

  deleteSlot(slotId) {
    return this.post('calendar/deleteSlot', { slotId });
  }

  list(calendarId) {
    return this.get(`calendar/${calendarId}/list`, { resourceId: calendarId });
  }

  getCalendarList() {
    return this.get('calendar/calendarList', undefined, undefined);
  }

  getCalendarAppointmentTypes(calendarId) {
    return this.servicesApi.callSecure(SERVICE_NAME, 'getCalendarAppointmentTypes', {
      calendarId,
      resourceId: calendarId,
    });
  }
}
