import _ from 'lodash';
import * as BkmdDomain from '../../model/enum/bkmdDomain';
import PremeraLogos from './premeraLogos/premeraLogos';

const logos = {
  [BkmdDomain.PREMERA_BLUE_CROSS_ALASKA]: {
    internal: PremeraLogos,
    external: PremeraLogos,
  },
  [BkmdDomain.UNITED_HEALTH_CARE_ALLSAVERS]: {
    internal: require('../../assets/images/logos/vimLogoMdTM.png'),
    external: require('../../assets/images/logos/vimUhcLogo.png'),
  },
  [BkmdDomain.ANTHEM]: {
    internal: require('../../assets/images/logos/vimLogoMdTM.png'),
    // external: require('../../assets/images/logos/anthemLogo.png'), // TODO: ANTHEM logo
  },
  [BkmdDomain.FLORIDA_BLUE]: {
    internal: require('../../assets/images/logos/sanitasLogo.png'),
    external: require('../../assets/images/logos/sanitasLogo.png'),
  },
  [BkmdDomain.VIM]: { internal: require('../../assets/images/logos/vimLogoMdTM.png') },
};

function getLogoByDomain(domain, externalPageLogo) {
  const domainLogos = logos[domain];
  const logoPath = externalPageLogo && domainLogos.external ? 'external' : 'internal';
  return _.get(domainLogos, logoPath);
}

export { logos, getLogoByDomain };
