import _ from 'lodash';
import React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { defineMessages, FormattedMessage } from 'react-intl';
import { autobind } from 'core-decorators';
import PropTypes from 'prop-types';

import Layout from '../layout/layout';
import { withFetchersOnMount } from '../../../../api/injectApi/withFetchersOnMount';
import Api from '../../../../api/index';
import VimSdkClient from '../../../../utils/sdk/vimSdkClient';
import { setAppointments } from 'Store/appointments/actions';
import { getAppointmentsSelector } from 'Store/appointments/selectors';
import { member } from 'Store/member/selectors';
import { getPathsFromContext } from '../routes/injectPathsToContext';

import * as constants from '../../../../model/enum/appointments';
import AppointmentCard from '../../../../components/appointmentCard';
import ShowMoreItems from '../../../../components/showMoreItems';
import { convertAppointments } from 'Utils/appointments';
import appointmentsEvents from './analytics/appointmentsEvents';

import './appointmentPage.less';

const messages = defineMessages({
  appointmentsTitle: {
    defaultMessage: 'My Appointments',
    id: 'atlas.appointmentsPage.appointmentsTitle',
  },
  requestedAppointments: {
    defaultMessage: 'Requested Appointments',
    id: 'atlas.appointmentsPage.requestedAppointments',
  },
  upcomingAppointments: {
    defaultMessage: 'Upcoming Appointments',
    id: 'atlas.appointmentsPage.upcomingAppointments',
  },
  pastCanceledAppointments: {
    defaultMessage: 'Past/Canceled Appointments',
    id: 'atlas.appointmentsPage.postCanceledAppointments',
  },
  viewAll: {
    defaultMessage: 'View all',
    id: 'atlas.appointmentsPage.viewAll',
  },
});

@autobind
class AppointmentsPage extends React.PureComponent {
  static contextTypes = {
    analytics: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      isLoadingAvailability: true,
      isLoadingReschedule: {},
      isLoadingCancel: {},
      availabilityByNpi: {},
    };
  }

  componentDidMount() {
    const {
      appointments: { upcoming, requested },
    } = this.props;
    const { analytics } = this.context;
    analytics.track(appointmentsEvents.viewAppointmentsPage);
    if (upcoming.length || requested.length) {
      const providers = [...upcoming, ...requested].map((appointment) => {
        const { address } = appointment.provider;
        return {
          npi: appointment.provider.npi,
          address,
          npiType: appointment.provider.npiType,
        };
      });

      this.getAvailability(providers);
    }
  }

  getAvailability = async (providers) => {
    const {
      member: { memberToken },
    } = this.props;

    this.setState({ isLoadingAvailability: true });

    const providersList = providers.map((provider) => {
      return {
        npi: provider.npi,
        address: provider.address,
      };
    });

    const result = await Api.providerApi.getProviderAvailability({
      memberToken,
      providers: providersList,
    });

    result.forEach((provider) =>
      this.setState({
        availabilityByNpi: {
          ...this.state.availabilityByNpi,
          [provider.npi]: provider.bookingType,
        },
      }),
    );
    this.setState({ isLoadingAvailability: false });
  };

  cancel = (appointmentId, type) => {
    const {
      member: { memberToken },
    } = this.props;
    const { analytics } = this.context;
    analytics.track(appointmentsEvents.cancelAppointment, { type });

    return Api.appointmentApi.cancelAppointment(memberToken, appointmentId);
  };

  reschedule = (appointmentId) => {
    this.setState({
      isLoadingReschedule: {
        ...this.state.isLoadingReschedule,
        [appointmentId]: false,
      },
    });
    const { analytics } = this.context;
    analytics.track(appointmentsEvents.rescheduleAppointments);

    return VimSdkClient.showRescheduleDialog(appointmentId);
  };

  onConfirmation = (payload) => {
    const { getPatientAppointments } = this.props;

    if (payload.action === constants.CANCEL_APPOINTMENT_ACTION) {
      this.setState({
        isLoadingCancel: {
          ...this.state.isLoadingCancel,
          [payload.appointment.id]: true,
        },
      });
      this.cancel(payload.appointment.id, payload.type)
        .then(() => {
          getPatientAppointments();
        })
        .finally(() => {
          this.setState({
            isLoadingCancel: {
              ...this.state.isLoadingCancel,
              [payload.appointment.id]: false,
            },
          });
        });
    }
    if (payload.action === constants.RESCHEDULE_APPOINTMENT_ACTION) {
      this.setState({
        isLoadingReschedule: {
          ...this.state.isLoadingReschedule,
          [payload.appointment.id]: true,
        },
      });
      this.reschedule(payload.appointment.id).then(() => {
        getPatientAppointments();
      });
    }
  };

  render() {
    const {
      appointments: { requested, upcoming, past },
    } = this.props;
    const {
      isLoadingAvailability,
      availabilityByNpi,
      isLoadingCancel,
      isLoadingReschedule,
    } = this.state;

    return (
      <Layout>
        <div className="patient-appointments-header">
          <FormattedMessage {...messages.appointmentsTitle} />
        </div>

        <div className="patient-appointments-wrap">
          <div className="container">
            <div className="patient-appointments-wrap__group">
              <div className="patient-appointments-wrap__group-title">
                <FormattedMessage
                  className="patient-appointments-wrap__group-title"
                  {...messages.requestedAppointments}
                />
              </div>
              <div className="patient-appointments-wrap__group-items">
                {requested.length ? (
                  <ShowMoreItems>
                    {requested.map((appointment) => {
                      return (
                        <AppointmentCard
                          key={appointment.id}
                          appointment={appointment}
                          type={constants.CARD_TYPE_REQUESTED}
                          availabilityByNpi={availabilityByNpi}
                          isLoadingAvailability={isLoadingAvailability}
                          isLoadingCancel={isLoadingCancel}
                          onConfirmation={this.onConfirmation}
                        />
                      );
                    })}
                  </ShowMoreItems>
                ) : (
                  <AppointmentCard type={constants.CARD_TYPE_EMPTY} />
                )}
              </div>
            </div>
            <div className="patient-appointments-wrap__group">
              <div className="patient-appointments-wrap__group-title">
                <FormattedMessage {...messages.upcomingAppointments} />
              </div>
              <div className="patient-appointments-wrap__group-items">
                {upcoming.length ? (
                  <ShowMoreItems>
                    {upcoming.map((appointment) => {
                      return (
                        <AppointmentCard
                          key={appointment.id}
                          appointment={appointment}
                          type={constants.CARD_TYPE_UPCOMING}
                          availabilityByNpi={availabilityByNpi}
                          isLoadingAvailability={isLoadingAvailability}
                          isLoadingReschedule={isLoadingReschedule}
                          isLoadingCancel={isLoadingCancel}
                          onConfirmation={this.onConfirmation}
                        />
                      );
                    })}
                  </ShowMoreItems>
                ) : (
                  <AppointmentCard type={constants.CARD_TYPE_EMPTY} />
                )}
              </div>
            </div>
            <div className="patient-appointments-wrap__group">
              <div className="patient-appointments-wrap__group-title">
                <FormattedMessage {...messages.pastCanceledAppointments} />
              </div>
              <div className="patient-appointments-wrap__group-items">
                {past.length ? (
                  <ShowMoreItems>
                    {past.map((appointment) => {
                      return (
                        <AppointmentCard
                          key={appointment.id}
                          appointment={appointment}
                          type={constants.CARD_TYPE_PAST}
                        />
                      );
                    })}
                  </ShowMoreItems>
                ) : (
                  <AppointmentCard type={constants.CARD_TYPE_EMPTY} />
                )}
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

const fetchAppointmentsNotEmpty = compose(
  connect(
    (state) => ({
      appointments: getAppointmentsSelector(state),
      member: member(state),
    }),
    { setAppointments },
  ),
  withFetchersOnMount({
    getPatientAppointments: {
      handler: ({ member }) => () => Api.appointmentApi.getPatientAppointments(member.memberToken),
      onSuccess: ({ setAppointments }, { appointments }) =>
        setAppointments(convertAppointments(appointments)),
      isReady: ({ appointments }) => !_.isEmpty(appointments),
    },
  }),
);

export default compose(
  connect(null, { setAppointments }),
  getPathsFromContext(),
  fetchAppointmentsNotEmpty,
)(AppointmentsPage);
