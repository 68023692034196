/**
 * Created by chenrozenes on 13/03/2017.
 */
import { combineReducers } from 'redux';
import genericReducer from '../genericReducer';
import autoCompleteProvider from './autoCompleteProvider/reducer';
import providers from './provider/providerReducer';
import { GET_RECOMMENDED, GET_RECOMMENDED_PCP_DOCTORS, SEARCH_DOCTORS, } from './search/actions';
import { SEARCH_SUGGESTIONS } from './autoCompleteProvider/actions';

const searchDoctorsResults = genericReducer(
  SEARCH_DOCTORS, [], null, { keepStateOnStart: true });
const recommended = genericReducer(GET_RECOMMENDED);
const suggestions = genericReducer(SEARCH_SUGGESTIONS);
const recommendedPcp = genericReducer(GET_RECOMMENDED_PCP_DOCTORS, { recommandedPcp: {} });

export default combineReducers({
  autoCompleteProvider,
  searchDoctorsResults,
  providers,
  recommended,
  suggestions,
  recommendedPcp,
});
