import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { defineMessages, FormattedMessage } from 'react-intl';
import { autobind } from 'core-decorators';
import doctorCompose from '../doctorReviewHOC';
import DoctorReviewLayout from './doctorReviewLayout';
import IntlEnum from '../../../../../utils/intlEnum';
import DoctorLogo from '../../../../../components/doctorImage/doctorLogo';
import CleanRadioGroup from '../../../../../components/form/cleanRadioGroup';
import { MessageShape, IntlString } from '../../../../../components/ui/intlString';
import './doctorReview.less';

const messages = defineMessages({
  next: {
    defaultMessage: 'Next',
    id: 'members.doctorReview.next',
  },
});

@autobind
class PillsReviewPage extends React.PureComponent {
  static propTypes = {
    intlEnum: PropTypes.instanceOf(IntlEnum).isRequired,
    reviewProcess: PropTypes.object.isRequired,
    updateReview: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    token: PropTypes.string.isRequired,
    headerMessage: MessageShape.isRequired,
    icons: PropTypes.string,
    icon: PropTypes.element,
    onNext: PropTypes.func,
    onBack: PropTypes.func.isRequired,
  };

  static defaultProps = {
    icons: '',
    icon: '',
    onNext: () => {},
  };

  onChange(rawValue) {
    const { name, token, reviewProcess } = this.props;
    const value = reviewProcess[name] === rawValue ? undefined : rawValue;
    this.props.updateReview({ token, [name]: value });
  }

  render() {
    const {
      intlEnum,
      headerMessage,
      icon,
      icons,
      reviewProcess,
      name,
      reviewProcess: { provider },
    } = this.props;

    const value = reviewProcess[name];

    return (
      <DoctorReviewLayout onBack={this.props.onBack}>
        <div className="text-center padding-v-20">
          {_.isEmpty(icon) ? <DoctorLogo provider={provider} /> : <span>{icon}</span>}
        </div>

        <div className="title-24 text-strong text-center no-margin padding-h-30 title-brand-1">
          <IntlString message={headerMessage} />
        </div>
        <div className="margin-top-30">
          <CleanRadioGroup
            btnStyle="btn-pill"
            intlEnum={intlEnum}
            value={value}
            onChange={this.onChange}
            wrapClass={`pills-radio-group big-btns ${icons}`}
            marginBetweenOptions={1}
          />
        </div>
        <div className="row margin-top-30 padding-h-30">
          <div className="col-xs-12">
            <button className="btn btn-big bg-color-brand-button" onClick={this.props.onNext}>
              <FormattedMessage {...messages.next} />
            </button>
          </div>
        </div>
      </DoctorReviewLayout>
    );
  }
}

export default doctorCompose(PillsReviewPage);
