/**
 * Created by matan on 11/15/17.
 */
import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import { connect } from 'react-redux';
import { compose, pure, withProps } from 'recompose';

import { member as getMemberInfo } from '../../../../store/member/selectors';
import { loadingUntil } from '../../../../components/recompose/loadingUntil';

const commonMessages = defineMessages({
  informationIsProvidedByPremera: {
    defaultMessage: 'Information is provided by Premera. For questions, please contact',
    id: 'help.informationByPremera.informationIsProvidedByPremera',
  },
  premeraCustomerCare: {
    defaultMessage: 'Premera Customer Care',
    id: 'help.informationByPremera.premeraCustomerCare',
  },
});

const messagesByContext = {
  AK: defineMessages({
    customerSupportWebsite: {
      defaultMessage: 'https://www.premera.com/ak/visitor/contact-us/',
      id: 'informationByPremera.messagesByContext.AK.subtitle1',
    },
  }),
  WA: defineMessages({
    customerSupportWebsite: {
      defaultMessage: 'https://www.premera.com/wa/visitor/contact-us/',
      id: 'informationByPremera.messagesByContext.WA.subtitle1',
    },
  }),
  PK: defineMessages({
    customerSupportWebsite: {
      defaultMessage: 'https://www.premera.com/wa/visitor/contact-us/',
      id: 'informationByPremera.messagesByContext.PK.subtitle1',
    },
  }),
};

@autobind
class InformationByPremera extends React.Component {
  static propTypes = {
    contextMessages: PropTypes.object.isRequired,
  };

  render() {
    const { contextMessages } = this.props;
    return (
      <div className="row margin-top-30 text-center">
        <div className="col-sm-8 col-sm-offset-2 text-14 padding-h-30">
          <FormattedMessage {...commonMessages.informationIsProvidedByPremera} />
          &nbsp;
          <a
            href={contextMessages.customerSupportWebsite.defaultMessage}
            target="_blank"
            rel="noopener noreferrer"
            className="font-color-brand-link text-nowrap"
            aria-labelledby="go-to-premera-customer-care"
          >
            <FormattedMessage {...commonMessages.premeraCustomerCare} />
            &nbsp;
            <i
              className="icon-ext-link text-12"
              id="go-to-premera-customer-care"
              aria-label="external-link"
            />
          </a>
        </div>
      </div>
    );
  }
}

export default compose(
  pure,
  connect(state => ({
    memberContext: _.get(getMemberInfo(state), 'memberContext'),
  })),
  loadingUntil(({ memberContext }) => !_.isEmpty(memberContext)),
  withProps(({ memberContext }) => ({
    contextMessages: messagesByContext[memberContext],
  })),
)(InformationByPremera);
