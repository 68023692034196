import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, FormattedMessage } from 'react-intl';
import Text from '../../atoms/Text/Text';
import { withTheme } from '../../../utils/theme';
import Formatter from '../../../../../utils/formatter';

const messages = defineMessages({
  nextAvailableLabel: {
    defaultMessage: 'Next available: ',
    id: 'scheduling.booking.alternativeProviders.nextAvailable',
  },
  noSlotsAvailableLabel: {
    defaultMessage: "This provider doesn't have any available appointments.",
    id: 'scheduling.booking.alternativeProviders.noSlotsAvailableLabell',
  },
});

function NextAvailable({ nextAvailable, theme }) {
  const dynamicStyle = { color: theme.linkColor };
  return nextAvailable ? (
    <div>
      <div className="next-appt padding-box-10" data-id="next-available-slot" style={dynamicStyle}>
        <FormattedMessage {...messages.nextAvailableLabel} />
        &nbsp;
        <span className="text-semibold">{Formatter.date(nextAvailable)}</span>
      </div>
    </div>
  ) : (
    <div data-id="no-available-slots" className="next-appt">
      <Text className="margin-top-20">
        <FormattedMessage {...messages.noSlotsAvailableLabel} />
        &nbsp;
      </Text>
    </div>
  );
}
NextAvailable.propTypes = {
  nextAvailable: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};
export default withTheme(NextAvailable);
