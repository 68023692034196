import React from 'react';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import Slider from 'react-slick';
import { Tooltip, Overlay } from 'react-bootstrap';
import SvgBullhorn from './SvgBullhorn';
import {
  ViewedInfoBanner,
  InfoBannerClickedSeeMore,
  ClosedInfoBanner,
  InfoBannerNextMessages
} from '../../../../store/tools/analytics/events/memberEvents';

const refsList = [];
const refSlides = [];
@autobind
class InformationalBannerComponent extends React.PureComponent {
  static propTypes = {
    items: PropTypes.array.isRequired,
    onClose: PropTypes.func.isRequired,
  };
  static contextTypes = {
    analytics: PropTypes.object.isRequired,
  };
  state = {
    showTooltip: false,
    showReadMoreBtn: false,
  };
  currentItem = 0;
  SLIDER_SETTINGS = {
    dots: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: false,
    useTransform: false,
    swipe: false,
    beforeChange: (oldIndex, newIndex) => {
      this.currentItem = newIndex;
      this.setState({ showReadMoreBtn: false });
      this.closeTooltip();
      if (newIndex > oldIndex) {
        this.context.analytics.track(InfoBannerNextMessages);
      }
    },
    afterChange: () => {
      this.onResize();
    },
  };
  closeTooltip() {
    this.setState({ ...this.state, showTooltip: false });
  }
  closeBanner() {
    this.context.analytics.track(ClosedInfoBanner);
    if (typeof this.props.onClose === 'function') {
      this.props.onClose();
    }
  }
  onResize() {
    const slide = refSlides[this.currentItem];
    if (!slide) {
      return;
    }
    const { showReadMoreBtn } = this.state;
    const { scrollWidth, clientWidth } = slide;
    const avoidedOversight = 2;

    if (scrollWidth - clientWidth >= avoidedOversight && !showReadMoreBtn) {
      this.setState({ showReadMoreBtn: true });
    }
    if (scrollWidth - clientWidth <= avoidedOversight && showReadMoreBtn) {
      this.setState({ showReadMoreBtn: false });
    }
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
  }
  componentDidMount() {
    window.addEventListener('resize', this.onResize);
    this.onResize();
    this.context.analytics.track(ViewedInfoBanner);
  }

  render() {
    const { currentItem } = this;
    const { items } = this.props;
    const { showReadMoreBtn, showTooltip } = this.state;
    return (
      <div className="informational-banner-wrapper">
        <div className="informational-banner">
          <SvgBullhorn />
          <Slider {...this.SLIDER_SETTINGS}>
            {items.map((item, index) => {
              return (
                <div className="informational-banner__items-wrapper">
                  <div
                    ref={(el) => {
                      refSlides[index] = el;
                    }}
                    className="informational-banner__item"
                  >
                    {item}
                  </div>
                  {showReadMoreBtn && (
                    <div>
                      <span
                        ref={(el) => {
                          refsList[index] = el;
                        }}
                        className="informational-banner__read-more"
                        onClick={() => {
                          this.setState({ showTooltip: true })
                          this.context.analytics.track(InfoBannerClickedSeeMore);
                        }
                      }
                      >
                        Read more
                      </span>
                      <Overlay
                        target={refsList[index]}
                        placement="bottom"
                        show={showTooltip && currentItem === index}
                      >
                        <Tooltip id={`show-more--${index}`} className="show-more-tooltip">
                          <div className="show-more-tooltip__title">{item}</div>
                          <div
                            onClick={() => this.closeTooltip()}
                            className="show-more-tooltip__close-btn"
                          >
                            Close
                          </div>
                        </Tooltip>
                      </Overlay>
                    </div>
                  )}
                </div>
              );
            })}
          </Slider>
          <button className="informational-banner__close-btn" onClick={this.closeBanner} />
        </div>
      </div>
    );
  }
}

export default InformationalBannerComponent;
