/**
 * Created by galgoltzman on 28/05/2017.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import { defineMessages, intlShape } from 'react-intl';
import paths from '../routes/paths';
import doctorCompose from '../doctorReviewHOC';
import IntlEnum from '../../../../../utils/intlEnum';
import PillsReviewPage from './pillsReviewPage';
import * as NegativeDrEnum from '../../../../../components/doctorCard/sections/reviewPositiveOptions/negativeDoctorOptions';
import { genderPicker } from './utils';
import displayProvider from '../../../../../utils/displayProvider';
import './doctorReview.less';

const messages = defineMessages({
  whatCouldDrImprove: {
    defaultMessage: 'What could {providerDisplayName} improve on?',
    id: 'members.doctorReview.whatCouldDrSmithImprove',
  },
  timeSpentWithMe: {
    defaultMessage: 'Time spent with me',
    id: 'members.doctorReview.timeSpentWithMe',
  },
  listening: {
    defaultMessage: 'Listening',
    id: 'members.doctorReview.listening',
  },
  explainingClearly: {
    defaultMessage: 'Explaining clearly',
    id: 'members.doctorReview.explainingClearly',
  },
  careDecisionsFemale: {
    defaultMessage: 'Her care decisions',
    id: 'members.doctorReview.careDecisionsFemale',
  },
  careDecisionsMale: {
    defaultMessage: 'His care decisions',
    id: 'members.doctorReview.careDecisionsMale',
  },
});

const NegativeGenderText = {
  FEMALE: new IntlEnum(
    { value: NegativeDrEnum.TIME_SPENT, message: messages.timeSpentWithMe },
    { value: NegativeDrEnum.LISTENING, message: messages.listening },
    { value: NegativeDrEnum.EXPLAINING_CLEARLY, message: messages.explainingClearly },
    { value: NegativeDrEnum.CARE_DECISIONS, message: messages.careDecisionsFemale },
  ),
  MALE: new IntlEnum(
    { value: NegativeDrEnum.TIME_SPENT, message: messages.timeSpentWithMe },
    { value: NegativeDrEnum.LISTENING, message: messages.listening },
    { value: NegativeDrEnum.EXPLAINING_CLEARLY, message: messages.explainingClearly },
    { value: NegativeDrEnum.CARE_DECISIONS, message: messages.careDecisionsMale },
  ),
};

@autobind
class NegativeRatingDr extends React.Component {
  static propTypes = {
    router: PropTypes.object.isRequired,
    params: PropTypes.object.isRequired,
    intl: intlShape.isRequired,
    reviewProcess: PropTypes.object.isRequired,
  };

  onNext() {
    const {
      router,
      reviewProcess: { locationId },
      params: { token },
    } = this.props;
    router.push(!locationId ? paths.doctorTextReview(token) : paths.negativeOffice(token));
  }

  onBack() {
    const {
      router,
      params: { token },
    } = this.props;
    router.push(paths.doctorStarRating(token));
  }

  render() {
    const {
      intl,
      reviewProcess: {
        provider: { suffix, firstName, lastName, gender },
      },
    } = this.props;
    const providerDisplayName = displayProvider(firstName, lastName, suffix);

    const message = intl.formatMessage(messages.whatCouldDrImprove, { providerDisplayName });
    return (
      <PillsReviewPage
        token={this.props.params.token}
        onNext={this.onNext}
        onBack={this.onBack}
        intlEnum={genderPicker(NegativeGenderText, gender)}
        icons="check-icons"
        name="negativeDoctor"
        headerMessage={message}
      />
    );
  }
}

export default doctorCompose(NegativeRatingDr);
