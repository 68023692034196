/**
 * Created by guyavarham on 31/12/2017.
 */

import _ from 'lodash';

export const memberSearchSelector = state => _.get(state, 'activation.memberSearch');
export const inviteMemberResponseSelector = state => _.get(state, 'activation.inviteMember');
export const activationToken = state => _.get(state, 'activation.activationToken');
export const validationToken = state => _.get(state, 'activation.validationToken');
export const sourceSelector = (state, ownProps) => {
  const defaultSource = _.get(state, 'config.activation.defaultSource', 'direct');
  return _.toLower(_.get(ownProps, 'params.source', defaultSource));
};
