import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { defineMessages, FormattedMessage } from 'react-intl';
import _ from 'lodash';
import { autobind } from 'core-decorators';
import SvgBoyAvatar from '../../../../../../assets/svg/avatars/boyAvatar';
import SvgGirlAvatar from '../../../../../../assets/svg/avatars/girlAvatar';
import { getFeature } from '../../../../../../store/feature/selectors';

import '../familyActivation.less';

const boyImage = <SvgBoyAvatar />;
const girlImage = <SvgGirlAvatar />;

const messages = defineMessages({
  weveAddedTheKiddos: {
    defaultMessage: 'We’ve added the kiddos',
    id: 'kids.weveAddedTheKiddos',
  },
  kidsUnderAge: {
    defaultMessage:
      'Kids under {underAge} are automatically added to your account. Other family ' +
      'members must activate their own accounts.',
    id: 'kids.kidsUnderAge',
  },
  inviteTheRest: {
    defaultMessage: 'Invite the rest of my family',
    id: 'kids.inviteTheRest',
  },
});

const renderKid = function renderKid(kid) {
  return (
    <div className="invite-item checked" key={kid.firstName}>
      {kid.gender === 'MALE' ? boyImage : girlImage}
      <div className="invite-item-name text-18 text-semibold text-dark text-center">
        {kid.firstName}
      </div>
    </div>
  );
};
@autobind
class KidsStep extends React.PureComponent {
  static propTypes = {
    kidsList: PropTypes.arrayOf(PropTypes.object),
    underAge: PropTypes.number.isRequired,
  };

  static defaultProps = {
    kidsList: {},
  };

  render() {
    const { kidsList, underAge } = this.props;
    return (
      <div>
        <div className="row">
          <div className="col-sm-8 col-sm-offset-2 text-center">
            <div className="title-24 text-strong text-center no-margin font-color-brand-main">
              <FormattedMessage {...messages.weveAddedTheKiddos} />
            </div>
            <div className="text-16 text-light text-center margin-top-5">
              <FormattedMessage {...messages.kidsUnderAge} values={{ underAge }} />
            </div>
          </div>
        </div>
        <div className="text-center">
          <div className="invite-list margin-top-20">{_.map(kidsList, renderKid)}</div>
        </div>
      </div>
    );
  }
}

export default compose(
  connect(state => ({
    underAge: getFeature(state, 'minimalAge', 18),
  })),
)(KidsStep);
