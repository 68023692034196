/**
 * Created by meirshalev 31/08/2017.
 */
export const WELCOME = 'WELCOME';
export const IS_ALASKA = 'IS_ALASKA';
export const ENTER_PHONE_NUMBER = 'ENTER_PHONE_NUMBER';
export const ENTER_NAME = 'ENTER_NAME';
export const ENTER_DOB = 'ENTER_DOB';
export const ENTER_MEMBER_ID = 'ENTER_MEMBER_ID';
export const COULD_NOT_FIND = 'COULD_NOT_FIND';
export const SIGN = 'SIGN';
export const COMPLETE = 'COMPLETE';
