/**
 * Created by meirshalev 13/02/2018.
 */
import { combineReducers } from 'redux';
import genericReducer from '../genericReducer';

import { GET_INSURER_BY_PREFIX, GET_INSURERS } from './actions';

const initialState = {
  insurers: [],
  insurerFromPrefix: null,
};

export default combineReducers({
  insurers: genericReducer(GET_INSURERS, initialState.insurers),
  insurerFromPrefix: genericReducer(
    GET_INSURER_BY_PREFIX,
    initialState.insurerFromPrefix,
    null,
    { keepStateOnStart: false }
    ),
});
