/**
 * Created by moran on 9/13/17.
 */

import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import './safariNotSupported.less';

const safariTopImg = require('./safari.png');

const messages = defineMessages({
  sorry: {
    defaultMessage: 'We’re sorry, we don’t support Safari in private mode.',
    id: 'safariNotSupported.sorry'
  },
  pleaseVisitTheSite: {
    defaultMessage: 'Please visit the site in a new tab, thank you!',
    id: 'safariNotSupported.pleaseVisitTheSite'
  },
});

const SafariNotSupported = function SafariNotSupported() {
  return (
    <div className="safari-private-wrap">
      <div className="safari-private-content">
        <img src={safariTopImg} className="top-img" alt="" />
        <div className="title-48 no-margin text-main text-semibold">
          <FormattedMessage {...messages.sorry} />
        </div>
        <div className="title-24 text-light margin-top-20">
          <FormattedMessage {...messages.pleaseVisitTheSite} />
        </div>
      </div>
    </div>
  );
};

export default SafariNotSupported;
