import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '../../storybook/src/utils/theme';

function SvgDoctorStars({ theme }) {
  /* eslint-disable */
  return (
    <svg aria-hidden="true" focusable="false" width="72px" height="72px" viewBox="0 0 72 72" version="1.1" className="svg-icon svg-doctor-stars">

      <defs>
        <polygon id="path-1" points="0 0.854457894 11.4178915 0.854457894 11.4178915 12.2705 0 12.2705"></polygon>
      </defs>
      <g id="Subscriber-don't-choose-PCP---empty-my-medical-team-page" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="empty-my-medical-team-page" transform="translate(-460.000000, -305.000000)">
          <g id="Group-2" transform="translate(65.000000, 253.000000)">
            <g id="Page-1" transform="translate(395.000000, 52.000000)">
              <path d="M13.3384,57.168 C19.2034,64.084 27.6824,68.03 36.7544,68.03 C45.8264,68.03 54.3054,64.084 60.1704,57.168 C56.8954,51.441 51.5084,47.009 45.2544,44.93 C44.7354,44.757 44.3524,44.316 44.2534,43.779 C44.1534,43.241 44.3544,42.692 44.7764,42.347 C47.7624,39.897 49.4754,36.329 49.4754,32.559 C49.4754,25.544 43.7694,19.838 36.7544,19.838 C29.7404,19.838 24.0344,25.544 24.0344,32.559 C24.0344,36.329 25.7474,39.897 28.7334,42.347 C29.1554,42.692 29.3564,43.241 29.2564,43.779 C29.1574,44.316 28.7744,44.757 28.2554,44.93 C22.0014,47.009 16.6144,51.442 13.3384,57.168 M36.7544,71.03 C26.4334,71.03 16.8144,66.38 10.3624,58.271 C9.9934,57.807 9.9324,57.167 10.2074,56.642 C13.4524,50.439 18.6614,45.646 25.0304,42.942 C22.4674,40.078 21.0344,36.406 21.0344,32.559 C21.0344,23.891 28.0864,16.838 36.7544,16.838 C45.4224,16.838 52.4754,23.891 52.4754,32.559 C52.4754,36.406 51.0424,40.078 48.4794,42.942 C54.8484,45.646 60.0574,50.439 63.3024,56.642 C63.5774,57.167 63.5164,57.807 63.1474,58.271 C56.6944,66.38 47.0754,71.03 36.7544,71.03" id="Fill-1" fill={theme.secondaryColor} ></path>
              <path d="M8.3365,53.9668 C7.8025,53.9668 7.2845,53.6808 7.0135,53.1758 C5.3395,50.0548 4.1745,46.7158 3.5515,43.2518 C3.4045,42.4368 3.9465,41.6558 4.7625,41.5098 C5.5755,41.3648 6.3575,41.9048 6.5045,42.7208 C7.0715,45.8748 8.1325,48.9148 9.6575,51.7578 C10.0495,52.4878 9.7745,53.3978 9.0445,53.7888 C8.8185,53.9088 8.5755,53.9668 8.3365,53.9668" id="Fill-3" fill={theme.mainColor} ></path>
              <path d="M65.1734,53.9668 C64.9344,53.9668 64.6914,53.9098 64.4654,53.7888 C63.7354,53.3978 63.4604,52.4878 63.8524,51.7578 C66.2304,47.3248 67.4864,42.3248 67.4864,37.2988 C67.4864,20.3528 53.7004,6.5668 36.7544,6.5668 C23.6064,6.5668 11.9154,14.9208 7.6654,27.3558 C7.3984,28.1398 6.5474,28.5608 5.7614,28.2888 C4.9784,28.0218 4.5594,27.1688 4.8274,26.3848 C9.4914,12.7368 22.3224,3.5668 36.7544,3.5668 C55.3554,3.5668 70.4864,18.6978 70.4864,37.2988 C70.4864,42.8188 69.1074,48.3088 66.4964,53.1758 C66.2264,53.6808 65.7074,53.9668 65.1734,53.9668" id="Fill-5" fill={theme.mainColor} ></path>
              <path d="M24.274,55.251 C23.446,55.251 22.774,54.579 22.774,53.751 L22.774,44.582 C22.774,43.754 23.446,43.082 24.274,43.082 C25.102,43.082 25.774,43.754 25.774,44.582 L25.774,53.751 C25.774,54.579 25.102,55.251 24.274,55.251" id="Fill-7" fill={theme.secondaryColor} ></path>
              <path d="M48.3902,53.2696 C47.5622,53.2696 46.8902,52.5976 46.8902,51.7696 L46.8902,44.1286 C46.8902,43.3006 47.5622,42.6286 48.3902,42.6286 C49.2182,42.6286 49.8902,43.3006 49.8902,44.1286 L49.8902,51.7696 C49.8902,52.5976 49.2182,53.2696 48.3902,53.2696" id="Fill-9" fill={theme.secondaryColor} ></path>
              <path d="M24.4439,55.6543 C23.3309,55.6543 22.4239,56.5603 22.4239,57.6743 C22.4239,58.7873 23.3309,59.6933 24.4439,59.6933 C25.5569,59.6933 26.4639,58.7873 26.4639,57.6743 C26.4639,56.5603 25.5569,55.6543 24.4439,55.6543 M24.4439,62.6933 C21.6759,62.6933 19.4239,60.4413 19.4239,57.6743 C19.4239,54.9063 21.6759,52.6543 24.4439,52.6543 C27.2119,52.6543 29.4639,54.9063 29.4639,57.6743 C29.4639,60.4413 27.2119,62.6933 24.4439,62.6933" id="Fill-11" fill={theme.secondaryColor} ></path>
              <path d="M50.6558,61.8369 C49.8278,61.8369 49.1558,61.1649 49.1558,60.3369 C49.1558,59.6809 49.5768,59.1229 50.1638,58.9199 L50.1638,55.0429 C50.1638,54.0659 49.3678,53.2699 48.3898,53.2699 C47.4128,53.2699 46.6178,54.0659 46.6178,55.0429 L46.6178,58.9199 C47.2038,59.1239 47.6248,59.6819 47.6248,60.3369 C47.6248,61.1649 46.9528,61.8369 46.1248,61.8369 C44.7428,61.8369 43.6178,60.7119 43.6178,59.3299 L43.6178,55.0429 C43.6178,52.4109 45.7588,50.2699 48.3898,50.2699 C51.0218,50.2699 53.1638,52.4109 53.1638,55.0429 L53.1638,59.3299 C53.1638,60.7119 52.0388,61.8369 50.6558,61.8369" id="Fill-13" fill={theme.secondaryColor} ></path>
              <g id="Group-17" transform="translate(0.000000, 28.030300)">
                <g id="Clip-16"></g>
                <path d="M5.7183,3.8555 C4.9973,3.8555 4.3123,4.1315 3.7953,4.6485 C3.1013,5.3415 2.8423,6.3405 3.1023,7.3205 C3.3373,8.2095 4.0633,8.9345 4.9493,9.1685 C5.9353,9.4295 6.9293,9.1695 7.6223,8.4755 C8.3173,7.7825 8.5753,6.7835 8.3163,5.8035 C8.0813,4.9165 7.3563,4.1905 6.4673,3.9555 C6.2173,3.8885 5.9653,3.8555 5.7183,3.8555 M5.7133,12.2705 C5.2063,12.2705 4.6923,12.2045 4.1813,12.0695 C2.2423,11.5555 0.7173,10.0315 0.2013,8.0895 C-0.3357,6.0655 0.2153,3.9855 1.6733,2.5265 C3.1333,1.0675 5.2133,0.5215 7.2373,1.0545 C9.1783,1.5705 10.7023,3.0955 11.2163,5.0355 C11.7533,7.0585 11.2023,9.1385 9.7443,10.5975 C8.6543,11.6885 7.2153,12.2705 5.7133,12.2705" id="Fill-15" fill={theme.secondaryColor} ></path>
              </g>
              <path d="M61.4683,10.0303 C60.6403,10.0303 59.9683,9.3583 59.9683,8.5303 L59.9683,1.5003 C59.9683,0.6723 60.6403,0.0003 61.4683,0.0003 C62.2963,0.0003 62.9683,0.6723 62.9683,1.5003 L62.9683,8.5303 C62.9683,9.3583 62.2963,10.0303 61.4683,10.0303" id="Fill-18" fill={theme.mainColor} ></path>
              <path d="M64.9839,6.5157 L57.9539,6.5157 C57.1259,6.5157 56.4539,5.8437 56.4539,5.0157 C56.4539,4.1877 57.1259,3.5157 57.9539,3.5157 L64.9839,3.5157 C65.8119,3.5157 66.4839,4.1877 66.4839,5.0157 C66.4839,5.8437 65.8119,6.5157 64.9839,6.5157" id="Fill-20" fill={theme.mainColor} ></path>
              <path d="M71.5005,17.7598 C71.5005,19.2118 70.3225,20.3898 68.8695,20.3898 C67.4175,20.3898 66.2395,19.2118 66.2395,17.7598 C66.2395,16.3068 67.4175,15.1288 68.8695,15.1288 C70.3225,15.1288 71.5005,16.3068 71.5005,17.7598" id="Fill-22" fill={theme.mainColor} ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
  /* eslint-enable */
}

SvgDoctorStars.propTypes = {
  theme: PropTypes.object.isRequired,
};

export default withTheme(SvgDoctorStars);
