import Immutable from 'seamless-immutable';
import _ from 'lodash';

import {
  LOGIN_ACTION_TYPE,
  AUTH0_LOGIN_ACTION_TYPE,
  LOGOUT_ACTION_TYPE,
  REQUIRE_CAPTCHA_ACTION_TYPE,
  LOAD_STORAGE_ACTION_TYPE,
  GET_USER_ACTION_TYPE,
  AUTH_TOUCH_ACTION_TYPE,
  REFRESH_ACTION_TYPE,
  RENEW_EXPIRED_PASSWORD,
  CREATE_USER_ACTION_TYPE,
  GET_PERMISSIONS_ACTION_TYPE,
  RESET_PASSWORD_REQUEST_ACTION_TYPE,
} from './actions';

import {
  CHANGE_PHONE_NUMBER,
  CHANGE_EMAIL,
  VERIFY_EMAIL,
  VERIFY_PHONE_NUMBER,
} from '../user/actions';

import { SIGNUP } from '../activation/actions';

import { SSO_SIGNIN } from '../sso/actions';
import Formatter from '../../utils/formatter';
import { hasPhiAccess } from './util';

const initialState = {
  accessToken: null,
  refreshToken: null,
  expiredToken: null,
  isAuthenticated: false,
  loading: undefined,
  hasError: false,
  user: null,
  permissions: null,
  hasPhiAccess: false,
  identifier: null,
  identifierType: null,
  lastActivity: new Date(),
  requireCaptcha: false,
  openIdError: false,
};

export default function authReducer(state = initialState, action) {
  // Make state immutable. if already immutable will return itself
  state = Immutable(state);

  switch (action.type) {
    case SSO_SIGNIN.START:
    case LOGIN_ACTION_TYPE.START:
    case AUTH0_LOGIN_ACTION_TYPE.START:
    case RENEW_EXPIRED_PASSWORD:
    case SIGNUP.START: {
      return state.merge({
        expiredToken: null,
        loading: true,
        hasError: false,
        permissions: initialState.permissions,
        hasPhiAccess: initialState.hasPhiAccess,
        user: initialState.user,
      });
    }
    case SSO_SIGNIN.SUCCESS:
    case LOGIN_ACTION_TYPE.SUCCESS:
    case AUTH0_LOGIN_ACTION_TYPE.SUCCESS:
    case RENEW_EXPIRED_PASSWORD.SUCCESS:
    case SIGNUP.SUCCESS: {
      const diff = Object.assign(
        {
          loading: false,
          expiredToken: null,
          requireCaptcha: false,
        },
        action.payload,
      );
      return state.merge(diff);
    }
    case LOGIN_ACTION_TYPE.ERROR: {
      // Clear the session
      return Immutable(initialState).merge({
        hasError: true,
        error: action.payload.status,
        responseMessage: action.payload.responseMessage,
        requireCaptcha: state.requireCaptcha,
      });
    }
    case AUTH0_LOGIN_ACTION_TYPE.ERROR: {
      return Immutable(initialState).merge({
        openIdError: true,
      });
    }

    case SIGNUP.ERROR: {
      return Immutable(initialState).merge({ hasError: true });
    }
    case GET_USER_ACTION_TYPE.START: {
      return state.merge({ loading: true });
    }
    case GET_USER_ACTION_TYPE.SUCCESS:
    case CHANGE_PHONE_NUMBER.SUCCESS:
    case CHANGE_EMAIL.SUCCESS:
    case VERIFY_PHONE_NUMBER.SUCCESS:
    case VERIFY_EMAIL.SUCCESS: {
      const user = _.clone(state.user);
      return state.merge({ user: _.merge({}, user, action.payload), loading: false });
    }
    case GET_USER_ACTION_TYPE.ERROR: {
      return state.merge({ loading: false, hasError: true });
    }
    case LOGOUT_ACTION_TYPE:
      return Immutable(initialState);
    case LOAD_STORAGE_ACTION_TYPE:
      return state.merge(action.payload);
    case AUTH_TOUCH_ACTION_TYPE:
      return state.set('lastActivity', action.payload.lastActivity);
    case REFRESH_ACTION_TYPE.SUCCESS:
      return state.merge(action.payload);
    case REQUIRE_CAPTCHA_ACTION_TYPE:
      return state.set('requireCaptcha', true);

    // User creation

    case CREATE_USER_ACTION_TYPE.START:
      return state.merge({ loading: true, hasError: false });
    case CREATE_USER_ACTION_TYPE.SUCCESS:
      // Check for errors during the signup
      if (action.payload.error) {
        return state.merge({ loading: false, hasError: true, error: action.payload.error });
      }

      return state.merge({ loading: false, user: action.payload, error: undefined });
    case CREATE_USER_ACTION_TYPE.ERROR:
      return state.merge({ loading: false, hasError: true });

    // Acl

    case GET_PERMISSIONS_ACTION_TYPE.START:
      return state.merge({ permissions: null, hasPhiAccess: false, loading: true });
    case GET_PERMISSIONS_ACTION_TYPE.SUCCESS:
      return state.merge({
        permissions: action.payload,
        hasPhiAccess: hasPhiAccess(action.payload),
        loading: false,
      });
    case RESET_PASSWORD_REQUEST_ACTION_TYPE.SUCCESS:
      return state.merge({
        identifier: _.includes(action.payload.data.identifier, '@')
          ? action.payload.data.identifier
          : Formatter.phoneNumberDefault(action.payload.data.identifier),
        identifierType: action.payload.data.type,
      });
    case RESET_PASSWORD_REQUEST_ACTION_TYPE.ERROR: {
      const {
        responseMessage: { type },
      } = action.payload;
      return state.merge({ identifierType: type });
    }
    case GET_PERMISSIONS_ACTION_TYPE.ERROR:
      return state.merge({
        permissions: null,
        hasError: true,
        error: action.payload,
        loading: false,
      });

    default:
      return state;
  }
}
