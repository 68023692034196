import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '../../storybook/src/utils/theme';

function SvgGlobeStars({ theme }) {
  /* eslint-disable */
  return (
    <svg aria-hidden="true" focusable="false" width="71px" height="73px" viewBox="0 0 71 73" className="svg-icon svg-globe-stars">
      <defs>
        <polygon id="path-1" points="0.9711 0.2016 6.853 0.2016 6.853 6.0826 0.9711 6.0826"></polygon>
      </defs>
      <g id="Subscriber---no-Children-" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="6" transform="translate(-461.000000, -221.000000)">
          <g id="Page-1" transform="translate(461.000000, 221.000000)">
            <path d="M35.8531,72.3398 C26.2471,72.3398 17.0611,68.4778 11.8791,62.2598 C11.3491,61.6238 11.4351,60.6778 12.0721,60.1478 C12.7071,59.6168 13.6531,59.7018 14.1841,60.3398 C18.7411,65.8068 27.2471,69.3398 35.8531,69.3398 C52.2861,69.3398 65.6541,55.9708 65.6541,39.5378 C65.6541,23.1058 52.2861,9.7368 35.8531,9.7368 C19.4201,9.7368 6.0521,23.1058 6.0521,39.5378 C6.0521,40.6928 6.1191,41.8498 6.2491,42.9788 C6.3431,43.8018 5.7531,44.5458 4.9301,44.6408 C4.1111,44.7358 3.3621,44.1458 3.2681,43.3218 C3.1241,42.0798 3.0521,40.8068 3.0521,39.5378 C3.0521,21.4518 17.7671,6.7368 35.8531,6.7368 C53.9391,6.7368 68.6541,21.4518 68.6541,39.5378 C68.6541,57.6248 53.9391,72.3398 35.8531,72.3398" id="Fill-1" fill={theme.secondaryColor} ></path>
            <path d="M35.8531,69.9003 C35.4581,69.9003 35.0641,69.7453 34.7701,69.4373 C27.0281,61.3543 22.7641,50.7363 22.7641,39.5383 C22.7641,28.3933 26.9931,17.8113 34.6711,9.7413 C35.2441,9.1403 36.1911,9.1183 36.7921,9.6883 C37.3921,10.2593 37.4161,11.2093 36.8451,11.8093 C29.7001,19.3193 25.7641,29.1673 25.7641,39.5383 C25.7641,49.9593 29.7321,59.8403 36.9361,67.3633 C37.5091,67.9613 37.4891,68.9113 36.8901,69.4833 C36.6001,69.7613 36.2261,69.9003 35.8531,69.9003" id="Fill-3" fill={theme.secondaryColor} ></path>
            <path d="M37.8082,69.4531 C37.4422,69.4531 37.0762,69.3201 36.7872,69.0521 C36.1802,68.4871 36.1462,67.5381 36.7092,66.9321 C43.6522,59.4671 47.4752,49.7381 47.4752,39.5381 C47.4752,30.2981 44.4292,21.6091 38.6662,14.4121 C37.8612,13.4071 37.0302,12.4641 36.1672,11.5731 C35.5912,10.9771 35.6072,10.0281 36.2032,9.4521 C36.7962,8.8781 37.7462,8.8921 38.3242,9.4871 C39.2492,10.4441 40.1432,11.4581 40.9792,12.5001 C47.1132,20.1621 50.4752,29.7511 50.4752,39.5381 C50.4752,50.4991 46.3672,60.9531 38.9072,68.9741 C38.6112,69.2921 38.2092,69.4531 37.8082,69.4531" id="Fill-5" fill={theme.secondaryColor} ></path>
            <path d="M64.7476,41.038 L6.9606,41.038 C6.1326,41.038 5.4606,40.366 5.4606,39.538 C5.4606,38.71 6.1326,38.038 6.9606,38.038 L64.7476,38.038 C65.5756,38.038 66.2476,38.71 66.2476,39.538 C66.2476,40.366 65.5756,41.038 64.7476,41.038" id="Fill-7" fill={theme.secondaryColor} ></path>
            <path d="M60.7633,25.3877 L11.3993,25.3877 C10.5713,25.3877 9.8993,24.7157 9.8993,23.8877 C9.8993,23.0597 10.5713,22.3877 11.3993,22.3877 L60.7633,22.3877 C61.5913,22.3877 62.2633,23.0597 62.2633,23.8877 C62.2633,24.7157 61.5913,25.3877 60.7633,25.3877" id="Fill-9" fill={theme.secondaryColor} ></path>
            <path d="M60.2418,56.6884 L17.2718,56.6884 C16.4438,56.6884 15.7718,56.0164 15.7718,55.1884 C15.7718,54.3604 16.4438,53.6884 17.2718,53.6884 L60.2418,53.6884 C61.0698,53.6884 61.7418,54.3604 61.7418,55.1884 C61.7418,56.0164 61.0698,56.6884 60.2418,56.6884" id="Fill-11" fill={theme.secondaryColor} ></path>
            <path d="M6.2164,50.082 C5.3634,50.082 4.5524,50.409 3.9414,51.02 C3.1194,51.842 2.8134,53.022 3.1194,54.18 C3.3994,55.232 4.2604,56.093 5.3134,56.372 C6.4664,56.682 7.6504,56.373 8.4714,55.551 C9.2924,54.73 9.5994,53.549 9.2924,52.393 C9.0134,51.34 8.1534,50.478 7.1004,50.199 C6.8054,50.121 6.5084,50.082 6.2164,50.082 M6.2094,59.491 C5.6584,59.491 5.0994,59.42 4.5434,59.272 C2.4684,58.722 0.7714,57.024 0.2194,54.949 C-0.3646,52.746 0.2344,50.484 1.8204,48.899 C3.4044,47.313 5.6654,46.716 7.8694,47.299 C9.9454,47.851 11.6424,49.548 12.1934,51.623 C12.7774,53.825 12.1784,56.087 10.5924,57.672 C9.4064,58.858 7.8434,59.491 6.2094,59.491" id="Fill-13" fill={theme.mainColor} ></path>
            <path d="M57.8609,10.8603 C57.0329,10.8603 56.3609,10.1883 56.3609,9.3603 L56.3609,1.5003 C56.3609,0.6723 57.0329,0.0003 57.8609,0.0003 C58.6889,0.0003 59.3609,0.6723 59.3609,1.5003 L59.3609,9.3603 C59.3609,10.1883 58.6889,10.8603 57.8609,10.8603" id="Fill-15" fill={theme.mainColor} ></path>
            <path d="M61.7906,6.9306 L53.9306,6.9306 C53.1026,6.9306 52.4306,6.2586 52.4306,5.4306 C52.4306,4.6026 53.1026,3.9306 53.9306,3.9306 L61.7906,3.9306 C62.6186,3.9306 63.2906,4.6026 63.2906,5.4306 C63.2906,6.2586 62.6186,6.9306 61.7906,6.9306" id="Fill-17" fill={theme.mainColor} ></path>
            <g id="Group-21" transform="translate(64.000000, 12.339800)">
              <mask id="mask-2" fill="white">
                <use xlinkHref="#path-1"></use>
              </mask>
              <g id="Clip-20"></g>
              <path d="M6.8531,3.1416 C6.8531,4.7666 5.5371,6.0826 3.9121,6.0826 C2.2881,6.0826 0.9711,4.7666 0.9711,3.1416 C0.9711,1.5176 2.2881,0.2016 3.9121,0.2016 C5.5371,0.2016 6.8531,1.5176 6.8531,3.1416" id="Fill-19" fill={theme.mainColor} mask="url(#mask-2)"></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
  /* eslint-enable */
}

SvgGlobeStars.propTypes = {
  theme: PropTypes.object.isRequired,
};

export default withTheme(SvgGlobeStars);
