/**
 * Created by chenrozenes on 04/05/2016.
 */
import { SHOW_NOTIFICATION, REMOVE_ALL_NOTIFICATIONS } from './actions';

const initialState = [];

export default function notification(state = initialState, action) {
  switch (action.type) {
    case SHOW_NOTIFICATION: {
      return [action.payload, ...state];
    }
    case REMOVE_ALL_NOTIFICATIONS: {
      return initialState;
    }
    default:
      return state;
  }
}
