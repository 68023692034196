/**
 * Created by chenrozenes on 19/01/2017.
 */
import _ from 'lodash';

class PodrickManager {
  constructor(config) {
    this.config = config;
    this.types = {};
  }

  register(type) {
    if (!type.key) throw new Error('type must contain a unique key property');
    this.types[type.key] = type;
  }

  get(key, property) {
    return !property ? this.types[key]
      : _.get(this.types, `${key}.${property}`, this.config[property]);
  }
}

export default PodrickManager;
