/**
 * Created by guyavarham on 05/04/2017.
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  mi5: {
    defaultMessage: '5<br />miles',
    id: 'members.resultsFilter.mi5',
  },
  mi10: {
    defaultMessage: '10<br />miles',
    id: 'members.resultsFilter.mi10',
  },
  mi20: {
    defaultMessage: '20<br />miles',
    id: 'members.resultsFilter.mi20',
  },
  mi20plus: {
    defaultMessage: '20+<br />miles',
    id: 'members.resultsFilter.mi20plus',
  },
  any: {
    defaultMessage: 'Any',
    id: 'members.resultsFilter.any',
  },
  male: {
    defaultMessage: 'Male',
    id: 'members.resultsFilter.male',
  },
  female: {
    defaultMessage: 'Female',
    id: 'members.resultsFilter.female',
  },
  apply: {
    defaultMessage: 'Apply',
    id: 'members.resultsFilter.apply',
  },
  cancel: {
    defaultMessage: 'Cancel',
    id: 'members.resultsFilter.cancel',
  },
  acceptingNewPatients: {
    defaultMessage: 'Accepting new patients',
    id: 'members.resultsFilter.acceptingNewPatients',
  },
  languageSpoken: {
    defaultMessage: 'Language spoken',
    id: 'members.resultsFilter.languageSpoken',
  },
  within: {
    defaultMessage: 'Within',
    id: 'members.resultsFilter.within',
  },
  doctorsGender: {
    defaultMessage: 'Doctor’s gender',
    id: 'members.resultsFilter.doctorsGender',
  },
  doctorsLanguage: {
    defaultMessage: 'Doctor’s Language',
    id: 'members.resultsFilter.doctorsLanguage',
  },
  location: {
    defaultMessage: 'Location',
    id: 'members.resultsFilter.location',
  },
  insurancePlaceholder: {
    defaultMessage: 'Choose your insurance plan',
    id: 'members.resultsFilter.insurancePlaceholder',
  },
  yourPlan: {
    defaultMessage: 'Your plan',
    id: 'members.resultsFilter.yourPlan',
  },
  filterResults: {
    defaultMessage: 'Filter results',
    id: 'members.searchDoctors.filterResults',
  },
  filterDoctors: {
    defaultMessage: 'Filters',
    id: 'members.resultsFilter.filterDoctors',
  },
  showMe: {
    defaultMessage: 'Show me',
    id: 'members.resultsFilter.showMe',
  },
  vimChoiceDoctorsOnly: {
    defaultMessage: 'Vim choice doctors only',
    id: 'members.resultsFilter.vimChoiceDoctorsOnly',
  },
  vimChoiceDoctorsAreHighlyRated: {
    defaultMessage: 'Vim Choice doctors are highly rated by patients and offer instant booking.',
    id: 'members.resultsFilter.vimChoiceDoctorsAreHighlyRated',
  },
});
