import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import { connect } from 'react-redux';
import { compose, getContext, pure, withHandlers, withProps } from 'recompose';
import { updateFlow, updateFlowData } from './actionCreators';
import { log } from '../../../utils/logger';
import { hasTransition, mapPayloadToFlowData, reduceStep } from './flowTraverse';
import { DEFAULT_FLOW_NAME, ERROR, NEXT, PREV } from './consts';
import { FlowStepMetaShape } from './shapes';
import * as AnalyticConsts from './analyticConsts';
import { withHistoryActions } from '../../../utils/queryString/historyConnector';

const flowControl = Component => {
  @autobind
  class FlowControl extends React.Component {
    static propTypes = {
      /**
       * The name of the flow, used as key.
       */
      name: PropTypes.string.isRequired,

      /**
       * An object represents steps and links between them
       */
      definitions: PropTypes.objectOf(FlowStepMetaShape),
      /**
       * Results of every flow step. represents the path that have made through the flow
       */
      stateHistory: PropTypes.array,
      /**
       * Current step name
       */
      stepName: PropTypes.string,
      /**
       * Flow state flowId
       */
      flowId: PropTypes.string,

      /**
       * @mrsufgi: hacky way to return stub as long as the controller wasn't initilized yet.
       */
      ready: PropTypes.bool,

      /**
       * Flow state
       */
      flowState: PropTypes.object,
      /**
       * update flow data
       */
      updateFlowData: PropTypes.func.isRequired,
      /**
       * update flow
       */
      updateFlow: PropTypes.func.isRequired,
      /**
       * The props that we got in the start of the hoc
       * Used to pass them on to the next component without littering it's props
       */
      incomingProps: PropTypes.object.isRequired,
      flowProps: PropTypes.object,
      analytics: PropTypes.object.isRequired,
      experimentalQSSupport: PropTypes.bool,
      mapFlowDataToAnalytics: PropTypes.func,
    };

    static defaultProps = {
      name: DEFAULT_FLOW_NAME,
      definitions: {},
      stateHistory: [],
      flowState: null,
      flowProps: {},
      stepName: undefined,
      flowId: undefined,
      flowTraverse: null,
      ready: false,
      experimentalQSSupport: false,
      mapFlowDataToAnalytics: _.noop,
    };

    progress(action) {
      const {
        flowState,
        stepName,
        definitions,
        stateHistory,
        updateFlowData,
        updateFlow,
        analytics,
        name,
        flowId,
        flowProps,
        mapFlowDataToAnalytics,
      } = this.props;
      const { type, payload, meta = {} /* error */ } = action;
      const currentStep = stepName;

      // Update the data
      // @mrsufgi: this is the actual reducing of data, the updateFlow
      const partialData = mapPayloadToFlowData(definitions, currentStep, payload, flowState);
      flowState.data = { ...flowState.data, ...partialData };
      if (meta.forceNullUpdate || !_.isNil(payload)) {
        updateFlowData(partialData);
        log('[FlowControl] data update:', partialData);
      }

      // TODO: @mrsufgi consider renaming reduceStep,
      // breaking reduceStep api is essential in my opinion.
      // TODO: @mrsufgi remodel state in store.
      const nextStep = reduceStep(
        definitions,
        currentStep,
        type,
        flowState,
        stateHistory,
        meta.popHistory,
        flowProps,
      );

      if (nextStep) {
        analytics.track(`${AnalyticConsts.SCOPE}_${AnalyticConsts.TRANSITION}`, {
          fromStep: currentStep,
          toStep: nextStep,
          transition: type,
          name,
          flowId,
          ...mapFlowDataToAnalytics(flowState.data),
        });
        updateFlow(nextStep, stepName, stateHistory);
      }
    }

    // TODO: Support Clear on prev vs persist on request, implement immutablie data.
    prev({ meta, payload, error }) {
      if (this.props.experimentalQSSupport) {
        window.history.back();
      } else {
        this.progress({
          type: PREV,
          meta: Object.assign({}, meta, { popHistory: true }),
          payload,
          error,
        });
      }
    }

    prevAndClear({ meta, payload, error }) {
      this.progress({
        type: PREV,
        meta: Object.assign({}, meta, { forceNullUpdate: true, popHistory: true }),
        payload,
        error,
      });
    }

    next({ meta, payload, error }) {
      this.progress({ type: NEXT, meta, payload, error });
    }

    error({ meta, payload, error }) {
      this.progress({
        type: ERROR,
        meta: Object.assign({}, meta, { forceNullUpdate: true }),
        payload,
        error,
      });
    }

    hasNext() {
      const { stepName, definitions, flowState, stateHistory, flowProps } = this.props;
      return hasTransition(definitions, stepName, NEXT, flowState, flowProps, stateHistory, false);
    }

    hasPrev() {
      const { stepName, definitions, flowState, stateHistory, flowProps } = this.props;
      return hasTransition(definitions, stepName, PREV, flowState, flowProps, stateHistory, true);
    }

    render() {
      const {
        stepName,
        name,
        ready,
        incomingProps,
        flowState,
        flowId,
        mapFlowDataToAnalytics,
      } = this.props;
      const control = {
        mapFlowDataToAnalytics,
        flowId,
        next: ready ? this.next : _.noop,
        prev: ready ? this.prev : _.noop,
        error: ready ? this.error : _.noop,
        prevAndClear: ready ? this.prevAndClear : _.noop,
        progress: this.progress,
        hasNext: ready ? this.hasNext : _.noop,
        hasPrev: ready ? this.hasPrev : _.noop,
        restart: ready ? this.restart : _.noop,
        name,
        currentStep: stepName,
        flowState,
      };

      return <Component control={control} {...incomingProps} />;
    }
  }

  return FlowControl;
};

const mapStoreToProps = connect(
  (reduxState, ownProps) => {
    const flow = _.get(reduxState, `flows[${ownProps.name}]`);
    if (!flow) return { ready: false };
    const { config, state } = flow;
    return {
      name: config.name,
      definitions: config.definitions,
      initialStepName: config.initialStepName,
      flowId: config.flowId,
      fromStep: state.fromStep,
      stepName: state.stepName,
      stateHistory: state.history,
      flowData: state.data,
      flowProps: state.flowProps,
      flowState: state,
      ready: true,
      experimentalQSSupport: config.experimentalQSSupport,
      mapFlowDataToAnalytics: config.mapFlowDataToAnalytics,
    };
  },
  { updateFlow, updateFlowData },
);

const withControl = nameGetter =>
  compose(
    pure,
    withProps(props => ({ incomingProps: props })),
    getContext({ analytics: PropTypes.object }),
    withProps(props => ({
      name: _.isFunction(nameGetter) ? nameGetter(props) : nameGetter,
    })),
    mapStoreToProps,
    withHistoryActions,
    withHandlers({
      updateFlow: ({ updateFlow, experimentalQSSupport, name, push }) => (
        nextStep,
        currentStep,
        history,
      ) => {
        log(`[FlowControl] progressing to step: ${nextStep}. from step: ${currentStep}`);
        updateFlow(name, {
          history,
          stepName: nextStep,
          fromStep: currentStep,
        });

        // to support query browser back
        if (experimentalQSSupport) {
          push({ history });
        }
      },
      updateFlowData: ({ updateFlowData, name }) => updatePayload => {
        updateFlowData(name, updatePayload);
      },
    }),
    flowControl,
  );

export default withControl;
