/**
 * Created by matan on 2/16/16.
 */

import React from 'react';

const SvgCalendarArchived = React.createClass({
  /* eslint-disable */
  render() {
    return (
      <svg aria-hidden="true" focusable="false" className="svg-icon svg-icon-calendar-archived" width="22px" height="22px" viewBox="0 0 22 22" version="1.1">
        <defs></defs>
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="calendar-pending">
            <path d="M21,20 C21,20.552 20.552,21 20,21 L2,21 C1.449,21 1,20.552 1,20 L1,7 L21,7 L21,20 L21,20 Z" id="Shape-path" fill="#FFFFFF"></path>
            <g id="Page-1" fill="#545454">
              <g id="pending-apt-icon-22x22_Red">
                <g id="Group">
                  <path d="M20,3 L18,3 L18,0 L16,0 L16,3 L6,3 L6,0 L4,0 L4,3 L2,3 C0.896,3 0,3.896 0,5 L0,20 C0,21.104 0.896,22 2,22 L20,22 C21.104,22 22,21.104 22,20 L22,5 C22,3.896 21.104,3 20,3 L20,3 L20,3 Z M21,20 C21,20.552 20.552,21 20,21 L2,21 C1.449,21 1,20.552 1,20 L1,7 L21,7 L21,20 L21,20 Z" id="Shape"></path>
                  <path d="M10,19 L12,19 L12,15 L16,15 L16,13 L12,13 L12,9 L10,9 L10,13 L6,13 L6,15 L10,15 L10,19 L10,19 Z" id="Shape" transform="translate(-37, 17)  rotate(45 50 50)"></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    );
    /* eslint-enable */
  }
});


export default SvgCalendarArchived;
