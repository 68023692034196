import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import SvgUserCircleQ from '../../../../../assets/svg/userCircleQ';

const messages = defineMessages({
  weCantFindYourInfo: {
    defaultMessage: "Sorry, we can't seem to find your info.",
    id: 'activation.mobileNum.memberNotFound.weCantFindYourInfo',
  },
  pleaseContactVimToActivate: {
    defaultMessage: 'Please contact Vim to activate your account.',
    id: 'activation.mobileNum.memberNotFound.pleaseContactVimToActivate',
  },
  notifiedCustomerSupportForMembers: {
    defaultMessage:
      'Having trouble with your account? Call <br/>' +
      'us at <a href="tel:8448435381" class="blue-link">(844) 843-5381</a> and we\'ll be happy ' +
      'to help.',
    id: 'activation.mobileNum.memberNotFound.notifiedCustomerSupportForMembers',
  },
});

class MemberNotFound extends React.Component {
  static propTypes = {
    /**
     * Hook method. supplied by redux-forms
     */
    isMember: PropTypes.bool,
  };

  static contextTypes = {
    analytics: PropTypes.object.isRequired,
  };

  static defaultProps = {
    isMember: false,
  };

  componentDidMount() {
    this.context.analytics.manualPageReport('activation.memberNotFound');
  }

  render() {
    const { isMember } = this.props;
    const messageFields = isMember
      ? messages.notifiedCustomerSupportForMembers
      : messages.pleaseContactVimToActivate;

    return (
      <div className="activation-mobile-num-wrap">
        <div className="top-section">
          <div className="row">
            <div className="col-xs-12 text-center">
              <SvgUserCircleQ />
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 text-center">
              <h4 className="title-24 md-margin text-strong-main">
                <FormattedMessage {...messages.weCantFindYourInfo} />
              </h4>
              <FormattedHTMLMessage {...messageFields} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default MemberNotFound;
