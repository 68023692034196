/**
 * Created by galgoltzman on 11/07/2017.
 */
import _ from 'lodash';
import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { defineMessages, FormattedMessage, injectIntl, intlShape } from 'react-intl';
import Formatter from '../../../../utils/formatter';
import CommonFormats from '../../../../utils/formatter/commonFormats';
import { getReviewsByNpiAndLocation } from '../../../../store/reviews/actions';
import { getReviews } from '../../../../store/reviews/selectors';
import { getProviderByNpi } from '../../../../store/directory/provider/selectors';
import BkmdModal from '../../../bkmdModal/bkmdModal';
import ModalHeader from '../../../bkmdModal/modalHeader';
import RoundedImage from '../../../ui/roundedImage/roundedImage';
import ReviewStarRate from '../../../ui/starRating/reviewStarRate';
import PositiveEnum from '../communityFeedbackMsg/positiveFeedbackMsg';
import { displayProviderObj } from '../../../../utils/displayProvider';

import './reviewsModal.less';

const messages = defineMessages({
  drSmithHas: {
    defaultMessage: '{title} {lastName} has a',
    id: 'members.reviewsModal.drSmithHas'
  },
  average: {
    defaultMessage: '{rating} average',
    id: 'members.reviewsModal.average'
  },
  ratingFrom: {
    defaultMessage: 'rating from',
    id: 'members.reviewsModal.ratingFrom'
  },
  patients: {
    defaultMessage: 'patients.',
    id: 'members.reviewsModal.patients'
  },
  reviewsFromPatients: {
    defaultMessage: 'Reviews from {providerFullName}’s patients',
    id: 'members.reviewsModal.reviewsFromPatients'
  },
  verifiedPatient: {
    defaultMessage: 'Verified patient',
    id: 'members.reviewsModal.verifiedPatient'
  },
  likedMostFor: {
    defaultMessage: 'Liked most for:',
    id: 'members.reviewsModal.likedMostFor'
  },
  from: {
    defaultMessage: 'From {date}',
    id: 'members.reviewsModal.from'
  },
  showMore: {
    defaultMessage: 'Show more',
    id: 'members.reviewsModal.showMore'
  },
  ratingsReviews: {
    defaultMessage: 'Ratings + reviews',
    id: 'members.reviewsModal.RatingsReviews'
  }
});
const hasPagination = false;

class ReviewsModal extends React.PureComponent {

  static propTypes = {
    intl: intlShape.isRequired,
    reviews: PropTypes.object.isRequired,
    getReviewsByNpiAndLocation: PropTypes.func.isRequired,
    npi: PropTypes.number.isRequired,
    locationId: PropTypes.number.isRequired,
    provider: PropTypes.object.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    /**
     * Name for identifying via analytics
     */
    name: PropTypes.string, // eslint-disable-line react/no-unused-prop-types
  };

  static defaultProps = {
    name: 'reviews_modal',
  };

  componentWillMount() {
    const { npi, locationId } = this.props;
    this.props.getReviewsByNpiAndLocation(npi, locationId);
  }

  renderFeedback(review) {
    const { positiveDoctor, positiveOffice } = review;
    if (!positiveDoctor && !positiveOffice) return <div></div>;
    return (
      <div className="review-footer">
        <div className="text-14">
          <FormattedMessage {...messages.likedMostFor} />
        </div>
        <ul className="feedback-list">
          {this.renderDrFeedback(positiveDoctor)}
          {this.renderOfficeFeedback(positiveOffice)}
        </ul>
      </div>
    );
  }

  renderOfficeFeedback(positiveOffice) {
    if (!positiveOffice) return <div></div>;
    const { intl } = this.props;
    const feedback = intl.formatMessage(PositiveEnum.getMessageByValue(positiveOffice));
    return (
      <li>
        <i className="icon-heart-f item-icon" />
        <span className="text-14 text-semibold text-dark">
          {_.capitalize(feedback)}
        </span>
      </li>
    );
  }

  renderDrFeedback(positiveDoctor) {
    if (!positiveDoctor) return <div></div>;
    const { intl } = this.props;
    const feedback = intl.formatMessage(PositiveEnum.getMessageByValue(positiveDoctor));
    return (
      <li>
        <i className="icon-heart-f item-icon" />
        <span className="text-14 text-semibold text-dark">
          {_.capitalize(feedback)}
        </span>
      </li>
    );
  }

  renderDrReview(review) {
    const { reviewDate, rating: reviewRating, review: reviewText } = review;
    const formattedReviewText = reviewText ? `"${reviewText}"` : null;

    return (
      <div className="reviews margin-top-30">
        <div className="review-wrap">
          <div className="review-header">
            <RoundedImage
              src={require('../../../../assets/images/profilePics/profilePic6.png')}
              className={'patient-small-img'}
            />
            <div className="text-16">
              <strong>
                <FormattedMessage {...messages.verifiedPatient} />
              </strong>
            </div>
            <div className="text-12">
              <FormattedMessage
                {...messages.from}
                values={{ date: Formatter.date(reviewDate, CommonFormats.FULL_DATE) }}
              />
            </div>
            <div className="review-star-rating">
              <span className="text-strong-dark i-va-fix-2">
                {reviewRating}
              </span>
              <ReviewStarRate
                className={'orange'}
                onChange={() => {
                }}
                rateValue={reviewRating}
              />
            </div>
          </div>
          <div className="review-content text-16 text-dark">
            {formattedReviewText}
          </div>
          {this.renderFeedback(review)}
        </div>
      </div>
    );
  }

  render() {
    const { onClose, isOpen, reviews, name, provider } = this.props;
    const { provider: { title, lastName, rating, ratingCount } } = this.props;
    if (_.isEmpty(reviews)) return <div></div>;
    const providerFullName = displayProviderObj(provider);
    return (
      <BkmdModal
        isOpen={isOpen}
        className={'reviews-modal fixed-header'}
        handleClose={onClose}
        name={name}
      >
        <ModalHeader
          onClose={onClose}
          title={messages.ratingsReviews}
        />
        <div className="dialog-body">

          <div className="top-section">
            <div className="title-24 no-margin">
              <span className="text-light">
                <FormattedMessage
                  {...messages.drSmithHas}
                  values={{ title, lastName }}
                />
              </span>
              <span className="text-semibold text-dark inline-block">
                &nbsp;
                <FormattedMessage {...messages.average} values={{ rating: _.ceil(rating, 1) }} />
              </span>
              <span className="text-light">
                &nbsp;<FormattedMessage {...messages.ratingFrom} />&nbsp;
              </span>
              <span className="text-semibold text-dark">
                {ratingCount}&nbsp;
                <FormattedMessage {...messages.patients} />
              </span>
            </div>
          </div>

          <div className="reviews-title margin-top-30">
            <span className="text-strong-dark">
              <FormattedMessage
                {...messages.reviewsFromPatients}
                values={{ providerFullName }}
              />
            </span>
          </div>

          {_.map(reviews, review => this.renderDrReview(review))}

          {hasPagination ?
            <div>
              <button className="btn btn-big btn-outline">
                <FormattedMessage {...messages.showMore} />
              </button>
            </div> : null
          }

        </div>
      </BkmdModal>
    );
  }
}

export default compose(
  injectIntl,
  connect((state, props) => ({
    provider: getProviderByNpi(state, props.npi),
    reviews: getReviews(state),
  }), { getReviewsByNpiAndLocation })
)(ReviewsModal);
