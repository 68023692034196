import React from 'react';

import MemberNotFound from '../../activation/components/memberNotFound';
import CoOpLogo from '../../../../../components/branding/co_opLogo';
import Header from '../../../../../components/ui/header/header';

export default function MemberNotFoundPage() {
  return (
    <div className="activation-layout">
      <Header>
        <CoOpLogo externalPageLogo />
      </Header>
      <MemberNotFound isMember />
    </div>
  );
}
