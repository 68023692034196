/**
 * Created by rom on 20/12/2015.
 */
import React from 'react';

export default function SvgCalendar() {
  /* eslint-disable */
  return (
    <svg aria-hidden="true" focusable="false" className="svg-icon svg-icon-calendar-m" width="22px" height="22px" viewBox="0 0 22 22" version="1.1">
      <defs></defs>
      <g id="Latest" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" sketch-type="MSPage">
        <g id="01-Calendar---Incomp-Copy" sketch-type="MSArtboardGroup" transform="translate(-290.000000, -232.000000)">
          <g id="Modal-Copy-3" sketch-type="MSLayerGroup" transform="translate(260.000000, 87.000000)">
            <g id="Imported-Layers" transform="translate(30.000000, 145.000000)" sketch-type="MSShapeGroup">
              <path className="bg" d="M21,7 L21,20 C21,20.553 20.552,21 20,21 L2,21 C1.449,21 1,20.553 1,20 L1,7 L21,7" id="Fill-1" fill="#FFFFFF"></path>
              <path d="M3,9 L7,9 L7,13 L3,13 L3,9 Z" id="Fill-2" fill="#08ACE4"></path>
              <path d="M21,7 L21,20 C21,20.552 20.552,21 20,21 L2,21 C1.449,21 1,20.552 1,20 L1,7 L21,7 L21,7 Z M20,3 L2,3 C0.896,3 0,3.896 0,5 L0,20 C0,21.104 0.896,22 2,22 L20,22 C21.104,22 22,21.104 22,20 L22,5 C22,3.896 21.104,3 20,3 L20,3 Z" id="Fill-3" fill="#898989"></path>
              <path d="M9,9 L13,9 L13,13 L9,13 L9,9 Z" id="Fill-4" fill="#898989"></path>
              <path d="M15,9 L19,9 L19,13 L15,13 L15,9 Z" id="Fill-5" fill="#898989"></path>
              <path d="M4,0.04 L6,0.04 L6,3 L4,3 L4,0.04 Z" id="Fill-6" fill="#898989"></path>
              <path d="M16,0.04 L18,0.04 L18,3 L16,3 L16,0.04 Z" id="Fill-7" fill="#898989"></path>
              <path d="M3,15 L7,15 L7,19 L3,19 L3,15 Z" id="Fill-8" fill="#898989"></path>
              <path d="M9,15 L13,15 L13,19 L9,19 L9,15 Z" id="Fill-9" fill="#898989"></path>
              <path d="M15,15 L19,15 L19,19 L15,19 L15,15 Z" id="Fill-10" fill="#898989"></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
  /* eslint-enable */
}
