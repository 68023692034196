import { autobind } from 'core-decorators';
import BkmdApi from './bkmdApi';
import ServicesApi from './services';

const SERVICE_NAME = 'session';

@autobind
export default class SessionApi extends BkmdApi {
  /**
   * A fetch object that points to the server
   * @param fetch
   */
  constructor(fetch) {
    super(fetch, '/session/');
    this.servicesApi = new ServicesApi(fetch);
  }

  createAction(sessionToken, action, entity) {
    return this.servicesApi
      .call(SERVICE_NAME, 'createAction', { sessionToken, action, entity })
      .then(result => result.data);
  }
}
