/**
 * Created by chenrozenes on 08/06/2016.
 */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { defineMessages } from 'react-intl';
import SpinnerButton from '../ui/spinner/spinnerButton';
import { IntlString, MessageShape } from '../ui/intlString';

const messages = defineMessages({
  cancel: {
    defaultMessage: 'Cancel',
    id: 'bookmd.components.modal.footer.cancel'
  },
  confirm: {
    defaultMessage: 'Confirm',
    id: 'bookmd.components.modal.footer.confirm'
  }
});

/**
 * Simple footer for BkmdModal
 */

export default class ModalFooterComponent extends React.Component {
  static defaultProps = {
    cancelText: messages.cancel,
    confirmText: messages.confirm,
    confirmDisabled: false,
    loading: false,
    cancelBtnClass: null,
    onCancel: null,
    confirmBtnClass: null,
    className: null
  };

  static propTypes = {
    cancelText: MessageShape,
    cancelBtnClass: PropTypes.string,
    onCancel: PropTypes.func,
    confirmText: MessageShape,
    confirmBtnClass: PropTypes.string,
    onConfirm: PropTypes.func.isRequired,
    confirmDisabled: PropTypes.bool,
    loading: PropTypes.bool,
    className: PropTypes.string
  };

  constructor(props) {
    super(props);

    // Bind methods
    this.renderCancelButton = this.renderCancelButton.bind(this);
  }

  renderCancelButton() {
    const { cancelText, cancelBtnClass, onCancel } = this.props;

    return (
      <button
        className={classNames('btn btn-small pull-left', cancelBtnClass)}
        onClick={onCancel}
      >
        <IntlString message={cancelText} />
      </button>
    );
  }

  render() {
    const {
      className,
      confirmText,
      confirmBtnClass,
      onConfirm,
      confirmDisabled,
      onCancel,
      loading
    } = this.props;

    return (
      <div className={classNames('dialog-footer', className)}>
        <div className="row">

          {onCancel ? this.renderCancelButton() : undefined}

          <SpinnerButton
            className={classNames('btn btn-small pull-right', confirmBtnClass)}
            onClick={onConfirm}
            disabled={confirmDisabled}
            isLoading={loading}
          >
            <IntlString message={confirmText} />
          </SpinnerButton>
        </div>
      </div>
    );
  }
}
