import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Dropdown, MenuItem } from 'react-bootstrap';
import Formatter from 'Utils/formatter/index';
import ClickableDiv from 'Components/clickableElement/clickableDiv';
import './ProviderLocationSelect.less';

function ProviderLocationSelect({
  otherLocations,
  pickedLocation,
  staticLocation,
  onLocationChanged,
}) {
  if (_.isEmpty(pickedLocation)) return null;
  const locationTitleToShow = pickedLocation.officeName || Formatter.addressFormat(pickedLocation);

  if (otherLocations.length === 0) {
    return <div className="single-location">{locationTitleToShow}</div>;
  }
  if (staticLocation) {
    return <div>{locationTitleToShow}</div>;
  }
  return (
    <ClickableDiv clickHandler={e => e.stopPropagation()}>
      <Dropdown
        id="summery"
        className="locations-dropdown pill-btn-dropdown margin-top-5"
        onSelect={onLocationChanged}
      >
        <Dropdown.Toggle bsStyle="transparent" noCaret>
          {locationTitleToShow}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {_.map(otherLocations, location => (
            <MenuItem eventKey={location.id} key={location.id} id={location.id}>
              <div className="item-title text-16 text-semibold">{location.officeName}</div>
              <div className="text-14">{Formatter.addressFormat(location)}</div>
            </MenuItem>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </ClickableDiv>
  );
}

ProviderLocationSelect.propTypes = {
  pickedLocation: PropTypes.object,
  otherLocations: PropTypes.array,
  onLocationChanged: PropTypes.func.isRequired,
  staticLocation: PropTypes.bool,
};

ProviderLocationSelect.defaultProps = {
  otherLocations: [],
  pickedLocation: {},
  staticLocation: false,
};
export default ProviderLocationSelect;
