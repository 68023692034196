import React from 'react';
import { compose, pure, withProps, withHandlers } from 'recompose';
import { defineMessages, FormattedHTMLMessage } from 'react-intl';
import SCR007Info from '../../screenTemplates/SCR007Info';
import { withTranslatedMessages } from '../../utils/withTranslatedMessages';
import SvgPooBox from '../../assets/svg/poo/pooBox';

const messages = defineMessages({
  title: {
    defaultMessage: 'Use an at-home colon cancer screening kit',
    id: 'colorectalFlow.introduction.title',
  },
  buttonText: {
    defaultMessage: "See if I'm eligible",
    id: 'colorectalFlow.introduction.buttonText',
  },
  mainText: {
    defaultMessage: `National care guidelines recommend colon cancer screening starting at age 50.
     According to the American Cancer Society, when caught early, the 5-year relative survival rate of colon cancer is about 90%.`,
    id: 'colorectalFlow.introduction.mainText',
  },
  footerNote: {
    defaultMessage: `*Clinical guidelines have been approved by your healthcare network. 
    For further questions regarding colon cancer screening please contact your primary care physician.`,
    id: 'colorectalFlow.introduction.footerNote',
  },
});

const Introduction = compose(
  pure,
  withTranslatedMessages(messages),
  withProps({
    mainText: <FormattedHTMLMessage {...messages.mainText} />,
    svgIcon: <SvgPooBox />,
    buttonType: 'bg-color-brand-button'
  }),
  withHandlers({
    onButtonClick: ({ control }) => () => control.next({}),
  })
)(SCR007Info);

export default Introduction;
