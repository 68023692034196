import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { autobind } from 'core-decorators';
import SvgAdultMaleAvatar from '../../../../assets/svg/avatars/adultMaleAvatar';
import SvgBoyAvatar from '../../../../assets/svg/avatars/boyAvatar';
import SvgAdultFemaleAvatar from '../../../../assets/svg/avatars/adultFemaleAvatar';
import SvgGirlAvatar from '../../../../assets/svg/avatars/girlAvatar';
import SvgOtherAvatar from '../../../../assets/svg/userCircle';
import { MALE, FEMALE } from '../../../../model/enum/gender';

import './childrenList.less';

@autobind
class ChildrenList extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    guardians: PropTypes.array.isRequired,
    adults: PropTypes.bool,
  };

  static defaultProps = {
    className: undefined,
    adults: false,
  };

  constructor(props) {
    super(props);
    this.iconMapper = {
      [MALE]: props.adults ? <SvgAdultMaleAvatar /> : <SvgBoyAvatar />,
      [FEMALE]: props.adults ? <SvgAdultFemaleAvatar /> : <SvgGirlAvatar />,
    };
  }

  getIcon(gender) {
    const icon = this.iconMapper[gender];
    if (icon) return icon;
    return <SvgOtherAvatar />;
  }

  renderChild(child) {
    const { gender, firstName, id } = child;
    return (
      <li key={id}>
        {this.getIcon(gender)}
        <div className="text-semibold text-dark">{firstName}</div>
      </li>
    );
  }

  render() {
    const { className, guardians } = this.props;
    return (
      <div>
        <ul className={classNames('children-list', className)}>
          {_.map(guardians, this.renderChild)}
        </ul>
      </div>
    );
  }
}

export default ChildrenList;
