/**
 * Created by chenrozenes on 16/10/2017.
 */
import { defineMessages } from 'react-intl';
import _ from 'lodash';

import IntlEnum from '../../utils/intlEnum';

const messages = defineMessages({
  english: {
    defaultMessage: 'English',
    id: 'intl.languages.english',
  },
  spanish: {
    defaultMessage: 'Español',
    id: 'intl.languages.spanish',
  },
  vietnamese: {
    defaultMessage: 'Tiếng Việt',
    id: 'intl.languages.vietnamese',
  },
  haitianCreole: {
    defaultMessage: 'Kreyòl Ayisyen',
    id: 'intl.languages.haitianCreole',
  },
});

const shortMessages = defineMessages({
  english: {
    defaultMessage: 'EN',
    id: 'intl.languages.short.english',
  },
  spanish: {
    defaultMessage: 'ES',
    id: 'intl.languages.short.spanish',
  },
  vietnamese: {
    defaultMessage: 'VI',
    id: 'intl.languages.short.vietnamese',
  },
  haitianCreole: {
    defaultMessage: 'HT',
    id: 'intl.languages.short.haitianCreole',
  },
});

export const ENGLISH = 'en';
export const SPANISH = 'es';
export const VIETNAMESE = 'vi';
export const HAITIAN_CREOLE = 'ht';

const mapping = {
  [ENGLISH]: { value: ENGLISH, message: messages.english, short: shortMessages.english },
  [SPANISH]: { value: SPANISH, message: messages.spanish, short: shortMessages.spanish },
  [VIETNAMESE]: {
    value: VIETNAMESE,
    message: messages.vietnamese,
    short: shortMessages.vietnamese,
  },
  [HAITIAN_CREOLE]: {
    value: HAITIAN_CREOLE,
    message: messages.haitianCreole,
    short: shortMessages.haitianCreole,
  },
};

export function getLanguagesEnum(list = [ENGLISH, SPANISH, VIETNAMESE, HAITIAN_CREOLE]) {
  const params = _.map(list, curr => mapping[curr]);
  return new IntlEnum(...params);
}

const defaultEnum = getLanguagesEnum();
export default defaultEnum;
