import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose, withHandlers, withPropsOnChange } from 'recompose';
import { defineMessages, FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import FlaggedRender from '../../utils/FlaggedRender';
import displayProvider from '../../utils/displayProvider';
import DoctorLogo from '../doctorImage/doctorLogo';
import PointDistance from '../ui/map/pointDistance';
import directoryMessages from '../../messages/providersCommon/messages';
import BookingHorizonChip from '../../storybook/src/components/atoms/BookingHorizonChip/BookingHorizonChip';
import ClickableDiv from '../clickableElement/clickableDiv';
import BookingButton from '../bookingButton/bookingButton';
import './recommendedProviderCard.less';
import * as ValidUserTags from '../../model/enum/userTags';
import { getUserTags } from '../../store/auth/selectors';
import withFeatureFlag from '../../utils/featureFlags/withFeatureFlag';

const BCBS_SOURCE = 'BLUE_CROSS_BLUE_SHIELD';

const messages = defineMessages({
  doctorDetails: {
    defaultMessage: 'Details',
    id: 'members.recommencedProviderCard.doctorDetails',
  },
});
const defaultBookButton = props => <BookingButton {...props} />;

const RecommendedDoctorCard = props => {
  const {
    firstName,
    lastName,
    suffix,
    clientLocation,
    closestLocation,
    npi,
    locations,
    sources,
    taxonomiesCodes,
    openDoctor,
    isClickable,
    pdStatus,
    onBookClick,
    showBooking,
    customBookButtonRenderProp,
    customDoctorLogoComponent,
    detailsBtnClassName,
    showNewBookButton,
  } = props;
  const providerDisplayName = displayProvider(firstName, lastName, suffix);
  const specialtyDisplayName = _.includes(sources, BCBS_SOURCE)
    ? taxonomiesCodes[0].sourceDescription
    : taxonomiesCodes[0].desc;
  const firstLocation = _.get(locations, '[0]', {});
  const closestLocationGeo = closestLocation.geo;
  const extraProps = {
    pickedLocation: closestLocation,
    onBookAppointmentClicked: onBookClick,
    btnClassName: showNewBookButton ? 'btn-block book-btn btn-small' : 'btn-block book-btn',
  };
  const bookButton = customBookButtonRenderProp || defaultBookButton;
  const DoctorLogoComponent = customDoctorLogoComponent || DoctorLogo;

  return (
    <div className="col-sm-4 cell-col" key={npi}>
      <ClickableDiv onClick={openDoctor} clickable={isClickable} className="card recommended-card">
        <div className="card-body">
          <DoctorLogoComponent provider={props} className={'recommended-doc-img'} />
          <div className="text-15 text-dark text-semibold dr-name">{providerDisplayName}</div>
          <div className="text-14 dr-position">{specialtyDisplayName}</div>
          <div className="margin-top-20 text-center">
            <span className="distance">
              <PointDistance
                message={directoryMessages.clinicDistance}
                p1={clientLocation}
                p2={closestLocationGeo}
              />
            </span>
            <span className="booking-horizon-wrap">
              <BookingHorizonChip bookingHorizon={firstLocation.bookingHorizon} shortText />
            </span>
            <div
              className={classNames('action-buttons', showNewBookButton ? 'new-book-button' : '')}
            >
              {showBooking && bookButton(extraProps)}
              <button
                className={classNames(
                  showNewBookButton ? 'btn-small' : detailsBtnClassName,
                  'btn btn-outline btn-block',
                )}
                onClick={openDoctor}
              >
                <FormattedMessage {...messages.doctorDetails} />
              </button>
            </div>
            <FlaggedRender shouldRender={!_.isNil(pdStatus)}>
              <div>
                PD Status: {_.get(pdStatus, 'uhpdKey')} {_.get(pdStatus, 'superPremium')}
              </div>
            </FlaggedRender>
          </div>
        </div>
      </ClickableDiv>
    </div>
  );
};

RecommendedDoctorCard.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  suffix: PropTypes.string,
  openDoctor: PropTypes.func.isRequired,
  closestLocation: PropTypes.object,
  clientLocation: PropTypes.object,
  taxonomiesCodes: PropTypes.array,
  npi: PropTypes.string.isRequired,
  onBookClick: PropTypes.func.isRequired,
  locations: PropTypes.array.isRequired,
  isClickable: PropTypes.bool.isRequired,
  sources: PropTypes.array,
  pdStatus: PropTypes.shape({
    uhpdKey: PropTypes.string, // eslint-disable-line react/no-unused-prop-types
    superPremium: PropTypes.string, // eslint-disable-line react/no-unused-prop-types
  }),
  showBooking: PropTypes.bool,
  customBookButtonRenderProp: PropTypes.element,
  customDoctorLogoComponent: PropTypes.element,
  detailsBtnClassName: PropTypes.string,
  showNewBookButton: PropTypes.bool.isRequired,
};

RecommendedDoctorCard.defaultProps = {
  firstName: '',
  lastName: '',
  suffix: '',
  locations: [],
  closestLocation: {},
  clientLocation: {},
  sources: null,
  taxonomiesCodes: [{ desc: '', sourceDescription: '' }],
  pdStatus: undefined,
  showBooking: true,
  customBookButtonRenderProp: undefined,
  customDoctorLogoComponent: undefined,
  detailsBtnClassName: '',
};

const enhance = compose(
  connect(state => ({
    userTags: getUserTags(state),
  })),
  // Nintendo have a specific clinic that should not book, its users have the NINTENDO_CLINIC tag
  withPropsOnChange(['userTags'], ({ userTags, showBooking }) => ({
    showBooking: showBooking && !userTags.includes(ValidUserTags.NINTENDO_CLINIC),
  })),
  withPropsOnChange(['openDoctor'], ({ openDoctor }) => ({ isClickable: !_.isNil(openDoctor) })),
  withHandlers({
    openDoctor: ({ npi, closestLocation, openDoctor = () => {} }) => e => {
      e.stopPropagation();
      openDoctor(npi, closestLocation.id);
    },
    onBookClick: ({ onBookClick, closestLocation, data }) => event => {
      event.stopPropagation();
      onBookClick(data, closestLocation.id, closestLocation.bookingType);
    },
  }),
  withFeatureFlag({
    key: 'storybook.newBookButton',
    prop: 'showNewBookButton',
    defaultValue: false,
  }),
);
export default enhance(RecommendedDoctorCard);
