/**
 * Created by galgoltzman on 05/04/2017.
 */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _ from 'lodash';
import { autobind } from 'core-decorators';
import { withProps, compose, withStateHandlers } from 'recompose';
import { IntlString } from '../intlString';
import messageShape from '../intlString/messageShape';
import ClickableDiv from '../../clickableElement/clickableDiv';

import './collapsibleCard.less';

@autobind
class CollapsibleCard extends React.Component {
  static propTypes = {
    /**
     * Unique id not give to other checkboxes in the form
     */
    id: PropTypes.string.isRequired,
    /**
     * Sets card title text
     */
    title: messageShape,
    /**
     * Sets the initial dropdown state
     */
// eslint-disable-next-line react/no-unused-prop-types
    defaultChecked: PropTypes.bool,
    /**
     * Sets component css class
     */
    className: PropTypes.string,
    /**
     * method hook, called each time the card is ordered to open/collapse
     */
    onClick: PropTypes.func,
    /**
     * Determine if the card is open
     */
    isOpen: PropTypes.bool.isRequired,
    /**
     * Toggle card state
     */
    toggleState: PropTypes.func.isRequired,
  };

  static defaultProps = {
    title: undefined,
    titleIntl: undefined,
    className: undefined,
    defaultChecked: false,
    onClick: _.noop,
  };

  onClick() {
    const { toggleState, onClick } = this.props;
    toggleState();
    onClick();
  }

  render() {
    const { title, id, className, isOpen, children } = this.props;
    const openClassName = { open: isOpen };
    return (
      <div className={classNames('collapsible-card', className, openClassName)}>
        <ClickableDiv className="card-title" onClick={this.onClick}>
          <label htmlFor={id}>
            <IntlString message={title} />
          </label>
          <i
            className={classNames('collapse-icon', {
              'icon-plus': !isOpen
            })}
          />
        </ClickableDiv>
        <div className="card-body">
          <div className="card-content">{children}</div>
        </div>
      </div>
    );
  }
}

let runningId = 0;
export default compose(
  withStateHandlers(
    ({ defaultChecked = false }) => ({
      isOpen: defaultChecked,
    }),
    {
      toggleState: ({ isOpen }) => () => ({
        isOpen: !isOpen,
      }),
    }
  ),
  withProps(ownProps => ({
    id: ownProps.id || `card-${++runningId}`
  }))
)(CollapsibleCard);
