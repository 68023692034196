import React from 'react';

export default function SvgTax() {
  /* eslint-disable */
  return (
    <svg aria-hidden="true" focusable="false" className="svg-icon svg-icon-tax" width="22px" height="18px" viewBox="0 0 22 18" version="1.1">
      <defs></defs>
      <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Facility-narrow" transform="translate(-221.000000, -98.000000)">
          <g id="Page-1" transform="translate(221.000000, 98.000000)">
            <polygon id="Fill-1" fill="#898989" points="14 7 21.999 7 21.999 5 14 5"></polygon>
            <polygon id="Fill-2" fill="#898989" points="14 11 19 11 19 9 14 9"></polygon>
            <polygon id="Fill-3" fill="#08ACE4" points="0 18 12 18 12 0 0 0"></polygon>
            <polygon id="Path" fill="#FFFFFF" points="3 16 5.001 16 5.001 14 3 14"></polygon>
            <polygon id="Path" fill="#FFFFFF" points="7 16 9 16 9 14 7 14"></polygon>
            <polygon id="Path" fill="#FFFFFF" points="3 12 5.001 12 5.001 10 3 10"></polygon>
            <polygon id="Path" fill="#FFFFFF" points="7 12 9 12 9 10 7 10"></polygon>
            <polygon id="Path" fill="#FFFFFF" points="3 8 5.001 8 5.001 6 3 6"></polygon>
            <polygon id="Path" fill="#FFFFFF" points="7 8 9 8 9 6 7 6"></polygon>
            <polygon id="Path" fill="#FFFFFF" points="3 4 5.001 4 5.001 2 3 2"></polygon>
            <polygon id="Path" fill="#FFFFFF" points="7 4 9 4 9 2 7 2"></polygon>
          </g>
        </g>
      </g>
    </svg>
  );
  /* eslint-enable */
}
