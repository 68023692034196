import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { defineMessages, injectIntl } from 'react-intl';
import { withHandlers, setPropTypes, compose, withPropsOnChange, mapProps } from 'recompose';
import { get } from 'lodash';

import { getUser } from '../../store/auth/selectors';
import { SuccessfulConfirmation } from '../../screenTemplates/SCR005/SuccessfulConfirmation';
import { FlowShape } from '../../components/wizard/flow/shapes';
import { getMessages } from '../../utils/withTranslatedMessages';
import Formatter from '../../utils/formatter';
import * as BookingType from '../../model/enum/bookingType';

const messages = defineMessages({
  titleText: {
    defaultMessage: 'You’re all set!',
    id: 'members.directory.booking.youreAllSet',
  },
  weSentYourBookingDetailsTo: {
    defaultMessage: 'We sent your booking details to:',
    id: 'members.directory.booking.weSentYourBookingDetailsTo',
  },
  sentBookingDetailsSMS: {
    defaultMessage: 'We sent your booking details to your phone.',
    id: 'members.directory.booking.sentBookingDetailsSMS',
  },
  buttonText: {
    defaultMessage: 'Close this window',
    id: 'members.directory.booking.closeThisWindow',
  },
});

const requestMessages = defineMessages({
  titleText: {
    defaultMessage: "We've received your appointment request",
    id: 'members.directory.booking.requestTitleText',
  },
  secondaryText: {
    defaultMessage:
      "We're finding appointment times based on your selections. " +
      "We'll be in contact with you soon.",
    id: 'members.directory.booking.requestSecondaryText',
  },
});

/**
 * Input: non
 * Output:
 * * Result: NEXT
 * * Returned value: answer
 */
const Confirmation = compose(
  injectIntl,
  setPropTypes({
    control: FlowShape.isRequired,
    onConfirmationClick: PropTypes.func.isRequired,
    bookingType: PropTypes.string,
  }),
  connect(state => ({
    user: getUser(state),
  })),
  withPropsOnChange(['user', 'bookingType'], ({ intl, user, bookingType }) => {
    const { weSentYourBookingDetailsTo, sentBookingDetailsSMS, ...rest } = messages;
    const confirmationText = user ? weSentYourBookingDetailsTo : sentBookingDetailsSMS;
    const phoneNumber = Formatter.phoneNumber(get(user, 'phoneNumber'));
    const isRequest = bookingType === BookingType.REQUEST;
    const overrides = isRequest ? requestMessages : {};
    return {
      ...getMessages(intl, { ...rest, confirmationText, ...overrides }),
      showWeSent: !isRequest,
      phoneNumber,
    };
  }),
  mapProps(({ autoSelectedAppointmentType, ...rest }) => ({
    showSelectedAppointmentType: !autoSelectedAppointmentType,
    ...rest,
  })),
  withHandlers({
    onClick: ({ onConfirmationClick }) => () => onConfirmationClick(),
  }),
)(SuccessfulConfirmation);

export default Confirmation;
