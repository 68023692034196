import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { compose, withProps, pure } from 'recompose';
import { connect } from 'react-redux';

import { getDomain } from '../../store/feature/selectors';
import { BkmdDomains } from '../../model/enum/bkmdDomain';
import UnitedTermsOfService from './unitedTermsOfService';
import PremeraBlueCrossTermsOfService from './premeraBlueCrossTermsOfService';
import DefaultTermsOfService from './defaultTermsOfService';

const termsMapper = {
  [BkmdDomains.PREMERA_BLUE_CROSS_ALASKA]: PremeraBlueCrossTermsOfService,
  [BkmdDomains.UNITED_HEALTH_CARE_ALLSAVERS]: UnitedTermsOfService,
};

const TermsOfServices = ({ TosComponent, isMember, hideAgreeMessage, ...rest }) => (
  <TosComponent isMember={isMember} hideAgreeMessage={hideAgreeMessage} {...rest} />
);

TermsOfServices.propTypes = {
  /* eslint-disable react/no-unused-prop-types */
  domain: PropTypes.string,
  /* eslint-enable react/no-unused-prop-types */
  isMember: PropTypes.bool,
  TosComponent: PropTypes.func.isRequired,
  hideAgreeMessage: PropTypes.bool,
};

TermsOfServices.defaultProps = {
  domain: undefined,
  isMember: false,
  hideAgreeMessage: false,
};

export default compose(
  pure,
  connect(state => ({
    domain: getDomain(state),
  })),
  withProps(props => ({
    hideAgreeMessage: props.hideAgreeMessage,
    TosComponent: _.get(termsMapper, props.domain, DefaultTermsOfService),
  })),
)(TermsOfServices);
