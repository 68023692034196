// This script add a class to the body when clicking the tab key, in order to change the focus style
const className = 'accessibility-tab-focus';
const TAB_KEY = 9;

document.body.onkeyup = e => {
  if (e.keyCode === TAB_KEY) {
    document.body.classList.add(className);
  }
};

document.body.onclick = () => {
  document.body.classList.remove(className);
};
