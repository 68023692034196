/**
 * Created by chenrozenes on 27/12/2017.
 */
import _ from 'lodash';
import { TRUE_RESULT, FALSE_RESULT } from './consts';

export const resultFor = (stepName, result) => flowState => flowState[stepName] === result;
export const trueFor = stepName => resultFor(stepName, TRUE_RESULT);
export const falseFor = stepName => resultFor(stepName, FALSE_RESULT);
export const hasResult = stepName => flowState => !_.isNil(flowState[stepName]);

// Comparison operators
export const gt = (stepName, val) => flowState => parseInt(flowState[stepName], 10) > val;
export const gte = (stepName, val) => flowState => parseInt(flowState[stepName], 10) >= val;
export const lt = (stepName, val) => flowState => parseInt(flowState[stepName], 10) < val;
export const lte = (stepName, val) => flowState => parseInt(flowState[stepName], 10) <= val;
