import React from 'react';
import { withProps } from 'recompose';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import AcceptNewPatients from '../../molecules/AcceptNewPatients/AcceptNewPatients';
import BookingHorizonChip from '../../atoms/BookingHorizonChip/BookingHorizonChip';
import './Availability.less';

const Availability = ({
  size,
  acceptNewPatients,
  bookingHorizonClassName,
  card,
  location,
  shortText,
}) => {
  if (!location.bookingHorizon && !acceptNewPatients) return null;
  const availability = (
    <div className={classNames('availability-section', size)}>
      <AcceptNewPatients
        acceptNewPatients={acceptNewPatients}
        textClass="item-text"
        itemClass="availability-item new-patients"
      />
      <BookingHorizonChip
        bookingHorizon={location.bookingHorizon}
        className={bookingHorizonClassName}
        shortText={shortText}
      />
    </div>
  );
  const component = card ? (
    <div className="card">
      <div className="card-body">{availability}</div>
    </div>
  ) : (
    availability
  );

  return <div className={classNames('availability-section', size)}>{component}</div>;
};

Availability.propTypes = {
  acceptNewPatients: PropTypes.bool.isRequired,
  bookingHorizonClassName: PropTypes.string,
  card: PropTypes.bool,
  location: PropTypes.object.isRequired,
  shortText: PropTypes.bool,
  size: PropTypes.string,
};

Availability.defaultProps = {
  bookingHorizonClassName: '',
  card: false,
  shortText: false,
  size: '',
};

export default withProps(({ provider, location }) => ({
  acceptNewPatients: !!(location.acceptNewPatients || provider.acceptNewPatients),
}))(Availability);
