import { defineMessages } from 'react-intl';

const messages = defineMessages({
  clinicDistance: {
    defaultMessage: '{clinicDistance} mi',
    id: 'members.components.clinicDistance'
  },
  getDirections: {
    defaultMessage: 'Get Directions',
    id: 'members.components.getDirections'
  },
  book: {
    defaultMessage: 'Book now',
    id: 'members.directory.booking.book',
  },
  requestBook: {
    defaultMessage: 'Request appointment',
    id: 'members.directory.booking.requestBook',
  },
  nextAvailable: {
    defaultMessage: 'Next available: ',
    id: 'members.directory.booking.nextAvailable',
  },
});

export default messages;
