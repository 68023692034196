/**
 * Created by moran on 6/26/17.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import _ from 'lodash';

import analytics from '../../store/tools/analytics/analyticsHoc';
import CleanInput from './cleanInput';
import { IntlString, MessageShape } from '../ui/intlString';

@autobind
class CleanGeoPoint extends React.Component {
  static propTypes = {
    label: MessageShape.isRequired,
    value: PropTypes.shape({
      longitude: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      latitude: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }).required,
    onChange: PropTypes.func,
  };

  static defaultProps = {
    value: {
      longitude: '',
      latitude: '',
    },
    label: 'Geo',
    onChange: () => {
    },
  };

  onLatChanged(e) {
    const { value: { longitude } } = this.props;
    const latitude = _.get(e, 'target.value', 0);
    if (this.props.onChange) this.props.onChange({ longitude, latitude });
    return { longitude, latitude };
  }

  onLongChanged(e) {
    const { value: { latitude } } = this.props;
    const longitude = _.get(e, 'target.value', 0);
    if (this.props.onChange) this.props.onChange({ longitude, latitude });
    return { longitude, latitude };
  }

  render() {
    const { label, value: { longitude, latitude } } = this.props;
    return (
      <div>
        <label>
          <IntlString message={label} />
        </label>
        <div className="row margin-top-20">
          <div className="col-xs-6">
            <CleanInput label="longitude" onChange={this.onLongChanged} value={longitude} />
          </div>
          <div className="col-xs-6">
            <CleanInput label="latitude" onChange={this.onLatChanged} value={latitude} />
          </div>
        </div>
      </div>);
  }
}

export default analytics(CleanGeoPoint, false, [
  { event: 'onChanged', eventData: loc => ({ loc }) },
]);
