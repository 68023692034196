/**
 * Created by meirshalev 09/10/2017.
 */
import PromiseActionType from '../promiseActionType';

export const ROUTE_TO_EXTERNAL = new PromiseActionType('ROUTE_TO_EXTERNAL');

export function routeToExternal(endpoint, url, window) {
  return ({ bkmdApi: { externalLinksApi } }) => ({
    type: ROUTE_TO_EXTERNAL.SOURCE,
    payload: {
      promise: externalLinksApi[endpoint](url)
        .then(res => {
          window.document.write(res);
        })
    }
  });
}
