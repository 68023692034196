/**
 * Created by chenrozenes on 23/08/2016.
 */
import httpStatus from 'http-status';
import { defineMessages } from 'react-intl';
import _ from 'lodash';

import IntlEnum from '../../../utils/intlEnum';

const messages = defineMessages({
  loginError: {
    defaultMessage: "Hey, let's try that again, please check your email/mobile and password.",
    id: 'providers.login.loginError',
  },
  loginErrorPasswordExpired: {
    defaultMessage: 'Your password is expired, please reset your password',
    id: 'providers.login.loginErrorPasswordExpired',
  },
  loginErrorUnverified: {
    defaultMessage: 'Your email is not verified, please verify your email and try again.',
    id: 'providers.login.loginErrorUnverified',
  },
  accountLocked: {
    defaultMessage: 'Your account has been locked. please contact us for help',
    id: 'providers.login.accountLocked',
  },
  invalidMemberForUserTitle: {
    defaultMessage: "We can't find your info",
    id: 'providers.login.invalidMemberForUserTitle',
  },
  invalidMemberForUser: {
    defaultMessage: 'Has your plan expired? Please check with your health plan provider.',
    id: 'providers.login.invalidMemberForUser',
  },
});

class LoginErrors extends IntlEnum {
  constructor() {
    super(
      {
        value: 'LOGIN_FAILED',
        codes: ['USER_NOT_FOUND'],
        status: httpStatus.UNAUTHORIZED,
        message: messages.loginError,
      },
      {
        value: 'LOGIN_FAILED_PASSWORD_EXPIRED',
        codes: ['PASSWORD_EXPIRED'],
        status: httpStatus.FORBIDDEN,
        message: messages.loginErrorPasswordExpired,
      },
      {
        value: 'ACCOUNT_LOCKED',
        codes: ['USER_LOCKED', 'MAX_RESET_PASSWORD_ATTEMPTS'],
        status: httpStatus.LOCKED,
        message: messages.accountLocked,
      },
      {
        value: 'INVALID_MEMBER_FOR_USER',
        codes: ['MEMBER_FOR_USER_NOT_FOUND'],
        status: httpStatus.FORBIDDEN,
        title: messages.invalidMemberForUserTitle,
        message: messages.invalidMemberForUser,
      },
    );
  }

  findByStatus(status) {
    const result = _.chain(this)
      .values()
      .find(error => _.includes(error.codes, status) || error.status === status)
      .value();

    return _.isNil(result) ? this.LOGIN_FAILED : result;
  }
}

export default new LoginErrors();
