import React from 'react';

export default function SvgCalendarFold() {
  /* eslint-disable */
  return (
    <svg aria-hidden="true" focusable="false" className="svg-icon svg-calendar-fold" width="72px" height="72px" viewBox="0 0 72 72" version="1.1">
      <g id="Cancel-appointment-mobile" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g  id="Sign-in-to-book" transform="translate(-152.000000, -141.000000)" fill="#083A6B">
          <g id="Page-1" transform="translate(152.000000, 141.000000)">
            <g id="Group-3" transform="translate(0.000000, 20.250000)">
              <path d="M64.318275,29.25 L49.499775,44.0685 L49.499775,29.25 L64.318275,29.25 Z M71.999775,0 L67.499775,0 L67.499775,24.75 L47.249775,24.75 C46.005525,24.75 44.999775,25.758 44.999775,27 L44.999775,47.25 L11.249775,47.25 C7.528275,47.25 4.499775,44.2215 4.499775,40.5 L4.499775,0 L-0.000225,0 L-0.000225,40.5 C-0.000225,46.70325 5.046525,51.75 11.249775,51.75 L47.249775,51.75 C47.551275,51.75 47.846025,51.68925 48.116025,51.5745 C48.390525,51.462 48.635775,51.2955 48.842775,51.0885 L71.338275,28.593 C71.545275,28.386 71.711775,28.1385 71.826525,27.86625 C71.939025,27.594 71.999775,27.29925 71.999775,27 L71.999775,0 Z" id="Fill-1"></path>
            </g>
            <path d="M4.5,18 L4.5,11.25 C4.5,7.5285 7.5285,4.5 11.25,4.5 L60.75,4.5 C64.4715,4.5 67.5,7.5285 67.5,11.25 L67.5,18 L4.5,18 Z M60.75,0 L11.25,0 C5.04675,0 0,5.04675 0,11.25 L0,18 L0,22.5 L4.5,22.5 L67.5,22.5 L72,22.5 L72,18 L72,11.25 C72,5.04675 66.95325,0 60.75,0 L60.75,0 Z" id="Fill-4"></path>
          </g>
        </g>
      </g>
    </svg>
  );
  /* eslint-enable */
}
