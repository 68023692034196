/**
 * Created by galgoltzman on 26/06/2017.
 */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { defineMessages, FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { autobind } from 'core-decorators';

import { firstNameSelector } from '../../../../../store/member/selectors';

const messages = defineMessages({
  welcome: {
    defaultMessage: 'Welcome {username},<br/>invite your family.',
    id: 'inviteFamily.welcome',
  },
  getHelpfulNotifications: {
    defaultMessage: 'Get helpful notifications and better manage your family’s needs.',
    id: 'inviteFamily.getHelpfulNotifications',
  },
  doItLater: {
    defaultMessage: 'Do it later',
    id: 'inviteFamily.doItLater',
  },
  ok: {
    defaultMessage: 'Ok',
    id: 'inviteFamily.ok',
  },
});

@autobind
class InviteFamilyModal extends React.PureComponent {
  static propTypes = {
    onNext: PropTypes.func.isRequired,
    username: PropTypes.string.isRequired,
  };

  submitCancel() {
    this.props.onNext(false);
  }

  submitContinue() {
    this.props.onNext(true);
  }

  render() {
    const { username } = this.props;
    return (
      <div className="invite-family-modal fixed-header">
        <div className="text-18 text-semibold text-dark text-center">
          <FormattedHTMLMessage {...messages.welcome} values={{ username }} />
        </div>
        <div className="margin-top-5 text-16 text-center">
          <FormattedMessage {...messages.getHelpfulNotifications} />
        </div>
        <div className="row tiny-gutter margin-top-20">
          <div className="col-xs-6">
            <button className="btn btn-big btn-sm-block btn-grey" onClick={this.submitCancel}>
              <FormattedMessage {...messages.doItLater} />
            </button>
          </div>
          <div className="col-xs-6">
            <button
              className="btn btn-big bg-color-brand-button btn-sm-block"
              onClick={this.submitContinue}
            >
              <FormattedMessage {...messages.ok} />
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(state => ({
  username: firstNameSelector(state),
}))(InviteFamilyModal);
