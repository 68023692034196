import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, FormattedMessage } from 'react-intl';
import {
  branch,
  compose,
  getContext,
  lifecycle,
  renderNothing,
  withHandlers,
  withPropsOnChange,
} from 'recompose';
import { capitalize, isEmpty, isNil, map, toLower } from 'lodash';

import Api from '../../../api/index';
import { displayProviderObj } from '../../../utils/displayProvider';
import {
  createToggleState,
  createToggleStatePropTypes,
} from '../../recompose/toggleStateComponent';
import CostEstimationModal from './modals/costEstimationModal';
import { withStateFetchersOnMount } from '../../../api/injectApi/withStateFetchersOnMount';

const messages = defineMessages({
  sectionTitle: {
    defaultMessage: 'Cost estimates for {providerName}',
    id: 'doctorDetails.costEstimation.sectionTitle',
  },
  sectionMainText: {
    defaultMessage: 'See your out of pocket cost for certain procedures with this provider.',
    id: 'doctorDetails.costEstimation.sectionMainText',
  },
  btnText: {
    defaultMessage: 'Estimate procedure costs',
    id: 'doctorDetails.costEstimation.btnText',
  },
});

function CostEstimationCard({
  treatments,
  costModalShow,
  costModalHide,
  costModalOn,
  provider,
  location,
  domainMemberId,
}) {
  const providerName = displayProviderObj(provider);
  return (
    <div className="cost-estimation-section margin-top-10 padding-h-10">
      <div className="card no-margin">
        <div className="card-body padding-box-30 clearfix">
          <div className="text-16 text-strong text-dark">
            <FormattedMessage {...messages.sectionTitle} values={{ providerName }} />
          </div>
          <div className="text-16 text-dark">
            <FormattedMessage {...messages.sectionMainText} />
          </div>
          <div className="margin-top-30">
            <button className="btn btn-big btn-outline pull-left" onClick={costModalShow}>
              <i className="icon-calc i-va-fix-2" />
              &nbsp;
              <FormattedMessage {...messages.btnText} />
            </button>
          </div>
        </div>
      </div>
      <CostEstimationModal
        provider={provider}
        treatments={treatments}
        location={location}
        isOpen={costModalOn}
        onClose={costModalHide}
        domainMemberId={domainMemberId}
      />
    </div>
  );
}

CostEstimationCard.propTypes = {
  ...createToggleStatePropTypes('costModal'),
  provider: PropTypes.object.isRequired,
  domainMemberId: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired,
};

function startCaseWords(description) {
  // lodash startCase drop special character like (,- and it needs to stay in description
  return toLower(description).replace(/\w+/g, capitalize);
}

export default compose(
  withStateFetchersOnMount({
    getTreatments: {
      handler: ({ provider, location }) => () =>
        Api.costEstimationApi.treatments(provider.npi, location.id),
      resultPropName: 'treatments',
      isReady: ({ treatments, getTreatmentsTracker }) =>
        !isNil(treatments) || getTreatmentsTracker.inProgress === false,
    },
  }),
  withPropsOnChange(['treatments'], ({ treatments }) => ({
    treatments: map(treatments, ({ description, treatmentCode }) => ({
      label: startCaseWords(description),
      value: treatmentCode,
    })),
  })),
  createToggleState('costModal'),
  getContext({ analytics: PropTypes.object }),
  withHandlers({
    costModalShow: ({
      costModalShow,
      analytics,
      provider,
      domainMemberId /* todo: pass dmbr */,
      memberPlan,
    }) => (...args) => {
      analytics.track('COST_ESTIMATION_OPEN', {
        npi: provider.npi,
        domainMemberId,
        memberPlan,
      });
      costModalShow(...args);
    },
  }),
  lifecycle({
    componentDidMount() {
      const { provider, domainMemberId, memberPlan, analytics, treatments } = this.props;
      if(treatments.length) {
        // send COST_ESTIMATION_LOAD only if we have treatments
        analytics.track('COST_ESTIMATION_LOAD', {
          npi: provider.npi,
          domainMemberId,
          memberPlan,
        });
      }
    },
  }),
  branch(({ treatments }) => isEmpty(treatments), renderNothing),
)(CostEstimationCard);
