/**
 * Created by moran on 6/6/17.
 */
import Immutable from 'seamless-immutable';
import _ from 'lodash';
import {
  UPDATE_APPOINTMENT_REVIEW,
  GET_REVIEW_PROCESS_BY_TOKEN,
  GET_REVIEW_PROVIDER
} from './actions';

const initialState = {};

export default function appointmentReview(state = initialState, action) {
  state = Immutable(state);
  switch (action.type) {
    case GET_REVIEW_PROCESS_BY_TOKEN.SUCCESS: {
      return _.get(action, 'payload.data');
    }
    case UPDATE_APPOINTMENT_REVIEW.SUCCESS: {
      return state.merge(_.get(action, 'payload.data'));
    }
    case GET_REVIEW_PROVIDER.SUCCESS: {
      const providerData = _.get(action, 'payload');
      return state.set('provider', providerData);
    }
    default:
      return state;
  }
}
