/**
 * Created by galgoltzman on 28/03/2019.
 */
import React from 'react';
import { defineMessages } from 'react-intl';
import PropTypes from 'prop-types';
import Image from 'react-image-webp';
import { compose, setDisplayName, withHandlers } from 'recompose';
import './bookForADoctorIntro.less';
import { withTranslatedMessages } from '../../../utils/withTranslatedMessages';

const defaultMessages = defineMessages({
  findADoctorYoullLove: {
    defaultMessage: 'Find a doctor you’ll love',
    id: 'bookingFlow.landing.findADoctorYoullLove',
  },
  connectWithADoctor: {
    defaultMessage:
      'Connect with a doctor who listens to your concerns, helps you understand ' +
      'your treatment options and partners with you throughout your healthcare journey.',
    id: 'bookingFlow.landing.connectWithADoctor',
  },
  viewDoctorsNow: {
    defaultMessage: 'View doctors now',
    id: 'bookingFlow.landing.viewDoctorsNow',
  },
});

const topImg = require('./bookForADoctorIntro.png');
const topImgWebP = require('./bookForADoctorIntro.webp');

function bookForADoctorIntro({ onNext, messages }) {
  return (
    <div>
      <div>
        <div className="container-1040 container">
          <div className="row no-margin">
            <div className="col-lg-6 col-lg-offset-3 col-sm-8 col-sm-offset-2 padding-h-30">
              <div className="text-center">
                <Image
                  className="landing-top-img"
                  src={topImg}
                  webp={topImgWebP}
                  alt=""
                />
              </div>

              <div className="title-24 md-margin text-strong font-color-brand-secondary text-center">
                {messages.findADoctorYoullLove}
              </div>

              <div className="text-16 text-center">
                {messages.connectWithADoctor}
              </div>

              <div className="margin-top-30">
                <button onClick={onNext} className="btn btn-big bg-color-brand-button">
                  {messages.viewDoctorsNow}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

bookForADoctorIntro.propTypes = {
  onNext: PropTypes.func.isRequired,
  messages: PropTypes.object.isRequired,
};


export default compose(
  setDisplayName('Intro'),
  withTranslatedMessages(defaultMessages),
  withHandlers({ onNext: ({ control }) => control.next }),
)(bookForADoctorIntro);
