/**
 * Created by guyavarham on 20/03/2017.
 */

import { defineMessages } from 'react-intl';

const messages = defineMessages({
  about: {
    defaultMessage: 'About',
    id: 'common.doctorDetails.about'
  },
  getDirections: {
    defaultMessage: 'Get directions',
    id: 'common.doctorDetails.getDirections'
  },
  seeOtherLocationsForThisDoctor: {
    defaultMessage: 'See other locations for this doctor',
    id: 'common.doctorDetails.seeOtherLocationsForThisDoctor'
  },
  hideOtherLocationsForThisDoctor: {
    defaultMessage: 'Hide other locations',
    id: 'common.doctorDetails.hideOtherLocationsForThisDoctor'
  },
  call: {
    defaultMessage: 'Call',
    id: 'common.doctorDetails.call'
  },
  share: {
    defaultMessage: 'Share',
    id: 'common.doctorDetails.share'
  },
  fromAddress: {
    defaultMessage: 'from {address}',
    id: 'common.doctorDetails.fromAddress'
  },
  acceptsNewPatients: {
    defaultMessage: 'Accepts new patients',
    id: 'common.doctorDetails.acceptsNewPatients'
  },
  doctorsBio: {
    defaultMessage: 'Doctor\'s bio',
    id: 'common.doctorDetails.doctorsBio'
  },
  showMore: {
    defaultMessage: '+ Show more',
    id: 'common.doctorDetails.showMore'
  },
  residency: {
    defaultMessage: 'Residency',
    id: 'common.doctorDetails.residency'
  },
  medicalDegree: {
    defaultMessage: 'Medical Degree',
    id: 'common.doctorDetails.medicalDegree'
  },
  internship: {
    defaultMessage: 'Internship',
    id: 'common.doctorDetails.internship'
  },
  practicesAt: {
    defaultMessage: 'Practices at',
    id: 'common.doctorDetails.practicesAt'
  },
  hospitalAffiliations: {
    defaultMessage: 'Hospital affiliations',
    id: 'common.doctorDetails.hospitalAffiliations'
  },
  specialties: {
    defaultMessage: 'Specialties',
    id: 'common.doctorDetails.specialties'
  },
  otherLanguagesSpoken: {
    defaultMessage: 'Other languages spoken',
    id: 'common.doctorDetails.otherLanguagesSpoken'
  },
  mi: {
    defaultMessage: 'mi',
    id: 'common.doctorDetails.miles',
  },
  inNetwork: {
    defaultMessage: 'In-Network',
    id: 'common.doctorDetails.inNetwork',
  },
  someRecommended: {
    defaultMessage: 'See some recommended doctors in your area.',
    id: 'common.doctorDetails.someRecommended',
  },
  allRecommended: {
    defaultMessage: 'See all recommended',
    id: 'common.doctorDetails.allRecommended',
  },
  reveal: {
    defaultMessage: 'Call',
    id: 'common.doctorDetails.reveal'
  },
  officeInformation: {
    defaultMessage: 'Office information',
    id: 'common.doctorDetails.officeInformation'
  },
  professionalBackground: {
    defaultMessage: 'Professional background',
    id: 'common.doctorDetails.professionalBackground'
  },
  specializesIn: {
    defaultMessage: '{gender, select, MALE {He} FEMALE {She} other {They} } {gender, select, ' +
    'MALE {specializes} FEMALE {specializes} other {specialize} } in',
    id: 'common.proBackground.specializesIn'
  },
  gotMedicalDegreeAt: {
    defaultMessage:
      '{gender, select, MALE {He} FEMALE {She} other {They} } got ' +
      '{gender, select, MALE {his} FEMALE {her} other {their} } medical degree at',
    id: 'common.proBackground.gotMedicalDegreeAt'
  },
  seeMore: {
    defaultMessage: 'See more',
    id: 'common.doctorDetails.seeMore'
  },
  mon: {
    defaultMessage: 'Mon',
    id: 'common.doctorDetails.mon'
  },
  tue: {
    defaultMessage: 'Tue',
    id: 'common.doctorDetails.tue'
  },
  wed: {
    defaultMessage: 'Wed',
    id: 'common.doctorDetails.wed'
  },
  thu: {
    defaultMessage: 'Thu',
    id: 'common.doctorDetails.thu'
  },
  fri: {
    defaultMessage: 'Fri',
    id: 'common.doctorDetails.fri'
  },
  sat: {
    defaultMessage: 'Sat',
    id: 'common.doctorDetails.sat'
  },
  sun: {
    defaultMessage: 'Sun',
    id: 'common.doctorDetails.sun'
  },
  closed: {
    defaultMessage: 'Closed',
    id: 'common.doctorDetails.closed'
  },
  openNow: {
    defaultMessage: 'Open now',
    id: 'common.doctorDetails.openNow'
  },
  phone: {
    defaultMessage: 'Phone:',
    id: 'common.doctorDetails.phone'
  },
  acceptingNewPatients: {
    defaultMessage: 'Accepting new patients',
    id: 'common.doctorDetails.acceptingNewPatients:'
  },
  seeRatingsReviews: {
    defaultMessage: 'See ratings + reviews',
    id: 'common.doctorDetails.seeRatingsReviews'
  },
  patientReview: {
    defaultMessage: 'Patient review',
    id: 'common.doctorDetails.patientReview'
  },
  drProfessionalBackground: {
    defaultMessage: '{ providerDisplayName }’s professional background',
    id: 'common.proBackground.drProfessionalBackground'
  },
  didFellowshipIn: {
    defaultMessage: '{gender, select, MALE {He} FEMALE {She} other {They} } did ' +
    '{gender, select, MALE {his} FEMALE {her} other {their} } fellowship in',
    id: 'common.proBackground.didFellowshipIn'
  },
  didResidencyAt: {
    defaultMessage: '{gender, select, MALE {He} FEMALE {She} other {They} } did ' +
    '{gender, select, MALE {his} FEMALE {her} other {their} } residency at',
    id: 'common.proBackground.didResidencyAt'
  },
  drPracticesAt: {
    defaultMessage: `{gender, select, MALE {He} FEMALE {She} other {They} } {gender, select, 
    MALE {practices}
    FEMALE {practices}
    other {practice}
    } at`,
    id: 'common.proBackground.drPracticesAt'
  },
  affiliatedWith: {
    defaultMessage: '{gender, select, MALE {He\'s} FEMALE {She\'s} other {They\'re} } ' +
    'affiliated with',
    id: 'common.proBackground.affiliatedWith'
  },
  speaks: {
    defaultMessage: '{gender, select, MALE {He speaks} FEMALE {She speaks} other {They speak} }:',
    id: 'common.proBackground.speaks'
  },
  boardCertified: {
    defaultMessage: 'Board Certified',
    id: 'common.proBackground.boardCertified'
  },
  certifications: {
    defaultMessage: '{gender, select, MALE {He\'s} FEMALE {She\'s} other {They\'re} }',
    id: 'common.proBackground.certifications'
  }
});

export default messages;
