/**
 * Created by guyavarham on 04/04/2017.
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    defaultMessage: 'Page Not Found',
    id: 'notFound.title'
  },
  h1: {
    defaultMessage: 'Whoops!',
    id: 'notFound.h1'
  },
  weCantSeemToFind: {
    defaultMessage: 'We can’t seem to find the page that you’re looking for.',
    id: 'notFound.weCantSeemToFind'
  },
  goBack: {
    defaultMessage: 'Go back home',
    id: 'notFound.goBack'
  },
  gotQuestions: {
    defaultMessage: 'Got questions? Need help? Get in touch:',
    id: 'notFound.gotQuestions'
  },
  helpMail: {
    defaultMessage: 'help@getvim.com',
    id: 'notFound.helpMail'
  },
  continue: {
    defaultMessage: 'Continue here please.',
    id: 'notFound.continue'
  },
  home: {
    defaultMessage: 'Home',
    id: 'notFound.home'
  },
  bookmdPhoneNumber: {
    defaultMessage: '(844) 843-5381',
    id: 'store.bookmdPhoneNumber'
  },
  safariNotFound: {
    defaultMessage: 'Sorry! Safari in private mode is not supported',
    id: 'common.safariNotFound'
  },
});
