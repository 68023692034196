import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import { defineMessages } from 'react-intl';

import { getShareByToken, GET_SHARE_BY_TOKEN } from '../../../../store/share/actions';
// import LoadingComponent from '../../../../components/pages/loading/loadingComponent';
import actionTracker, {
  actionTrackerShape,
} from '../../../../store/tools/actionTracker/actionTrackerComponent';
import ErrorPage from '../../../../components/pages/error/errorPage';
import { withPublicToken } from '../../../../components/recompose/withPublicToken';

const messages = defineMessages({
  loadingScreenTitle: {
    defaultMessage: 'Loading Providers',
    id: 'members.shareDoctors.loadingScreenTitle',
  },
  loadingScreenMessage: {
    defaultMessage: 'Finding your shared providers list info',
    id: 'members.shareDoctors.loadingScreenMessage',
  },
  errorScreenTitle: {
    defaultMessage: "We're having trouble",
    id: 'members.shareDoctors.errorTitleMessage',
  },
  errorScreenMessage: {
    defaultMessage: "We're sorry, we couldn't find your shared providers.",
    id: 'members.shareDoctors.errorDescriptionMessage',
  },
});

/* const renderLoading = () => (
  <LoadingComponent
    headerMessage={messages.loadingScreenTitle}
    contentMessage={messages.loadingScreenMessage}
  />
); */

const renderErrorPage = () => (
  <ErrorPage
    titleMessage={messages.errorScreenTitle}
    descriptionMessage={messages.errorScreenMessage}
    displayError={false}
    displayButton={false}
  />
);

@autobind
class ShareDoctorsPage extends React.Component {
  static propTypes = {
    params: PropTypes.object.isRequired,
    getShareByToken: PropTypes.func.isRequired,
    getShareByTokenTracking: actionTrackerShape.isRequired,
  };

  componentDidMount() {
    const { params, getShareByToken } = this.props;
    getShareByToken(params.shareToken);
  }

  renderChildren() {
    const { children } = this.props;
    return <div className="shared-doctors-content">{children}</div>;
  }

  render() {
    const { getShareByTokenTracking } = this.props;
    if (_.get(getShareByTokenTracking, 'hasError')) return renderErrorPage();

    return this.renderChildren();
  }
}

export default compose(
  withPublicToken(props => props.params.shareToken),
  connect(
    null,
    { getShareByToken },
  ),
  actionTracker({
    getShareByTokenTracking: GET_SHARE_BY_TOKEN.SOURCE,
  }),
)(ShareDoctorsPage);
