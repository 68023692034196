import PropTypes from 'prop-types';
import React from 'react';

import * as FlowDefs from './definitions/savingAlaskaFlowDef';
import Flow from '../components/wizard/flow/Flow';

function SavingAlaskaFlow({ token, npiList, persist }) {
  return (
    <Flow
      name={FlowDefs.name}
      definitions={FlowDefs.flow}
      initialStepName={FlowDefs.initialStepName}
      persist={persist}
      initialValues={{
        npiList,
        token,
      }}
      experimentalQSSupport
    />
  );
}

SavingAlaskaFlow.propTypes = {
  token: PropTypes.string.isRequired,
  npiList: PropTypes.string.isRequired,
  persist: PropTypes.bool
};

SavingAlaskaFlow.defaultProps = {
  persist: true
};

export default SavingAlaskaFlow;
