import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { compose } from 'redux';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { defineMessages, FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { autobind } from 'core-decorators';
import SimpleProgressPage from '../../../../../components/ui/layouts/simpleProgressPage';
import AppointmentDetailsList from '../appointmentDetailsList';
import SvgCalendarMinus from '../../../../../assets/svg/calendarMinus';
import { cancelAppointment, CANCEL_APPOINTMENT } from '../../../../../store/appointments/actions';
import { getAppointmentSelector } from '../../../../../store/appointments/selectors';
import actionTracker from '../../../../../store/tools/actionTracker/actionTrackerComponent';
import commonMessage from '../../../../../store/intl/commonMessages';
import paths from '../memberAppointmentsPaths';
import appointmentsEvents from '../analytics/appointmentsEvents';
import { isDirectoryAllowed } from '../../../../../store/ui/selectors';
import { isCancelledFunc } from '../booked/bookedApptDetails';
import SpinnerButton from '../../../../../components/ui/spinner/spinnerButton';

const messages = defineMessages({
  cancelAppointment: {
    defaultMessage: 'Cancel this appointment?',
    id: 'members.appointment.cancelAppointment.cancelAppointment',
  },
  toReschedule: {
    defaultMessage: 'To reschedule, please cancel and book <br> a new appointment',
    id: 'members.appointment.cancelAppointment.toReschedule',
  },
  errorTitle: {
    defaultMessage: 'Appointment Cancellation Error',
    id: 'members.appointment.cancelAppointment.errorTitle',
  },
  errorText: {
    defaultMessage: 'Canceling Checked-In, Declined or past appointment is not possible.',
    id: 'members.appointment.cancelAppointment.errorText',
  },
});

@autobind
class CancelAppointment extends React.Component {
  static propTypes = {
    router: PropTypes.object.isRequired,
    appointment: PropTypes.object.isRequired,
    cancelAppointment: PropTypes.func.isRequired,
    allowDirectory: PropTypes.bool.isRequired,
    cancelAppointmentAction: PropTypes.object.isRequired,
  };

  static contextTypes = {
    analytics: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.checkAppointmentStatus();
  }

  checkAppointmentStatus() {
    const { appointment, allowDirectory, router } = this.props;

    if (isCancelledFunc(appointment)) {
      const nextPage = allowDirectory
        ? paths.cancelled(appointment.id)
        : paths.appointmentDetails(appointment.id);

      router.push(nextPage);
    }
  }

  cancelAppointment() {
    const { appointment } = this.props;
    const { analytics } = this.context;
    analytics.track(appointmentsEvents.cancel.cancelAppointment, {
      ..._.pick(appointment, ['id', 'status']),
    });
    this.props.cancelAppointment(appointment.id);
  }

  render() {
    const { appointment, cancelAppointmentAction } = this.props;
    if (_.isEmpty(appointment)) return null;

    return (
      <SimpleProgressPage
        title=""
        progress={null}
        caption=""
        img=""
        showLogos={false}
        showRightNavInMobile
      >
        <div className="cancel-appointment-flow">
          <div className="top-section text-center">
            <SvgCalendarMinus />
            <h1 className="title-24 md-margin font-color-brand-main">
              <strong>
                <FormattedMessage {...messages.cancelAppointment} />
              </strong>
            </h1>
            <div className="text-18">
              <FormattedHTMLMessage {...messages.toReschedule} />
            </div>
          </div>

          <AppointmentDetailsList appointment={appointment} />

          <div className="row tiny-gutter">
            <div className="col-xs-6">
              <button
                className="btn btn-big btn-grey pull-right"
                onClick={() => this.props.router.push(paths.declineCancellation(appointment.id))}
              >
                <FormattedMessage {...commonMessage.no} />
              </button>
            </div>
            <div className="col-xs-6">
              <SpinnerButton
                className="btn btn-big pull-left"
                onClick={this.cancelAppointment}
                isLoading={cancelAppointmentAction.inProgress}
                disabled={cancelAppointmentAction.inProgress}>
                <FormattedMessage {...commonMessage.yes} />
              </SpinnerButton>
            </div>
          </div>
        </div>
      </SimpleProgressPage>
    );
  }
}

export default compose(
  withRouter,
  actionTracker(
    {
      cancelAppointmentAction: CANCEL_APPOINTMENT.SOURCE,
    },
    {
      cancelAppointmentAction: {
        error: { title: messages.errorTitle, text: messages.errorText },
      },
    },
  ),
  connect(
    (state, { params }) => ({
      appointment: getAppointmentSelector(state, params.appointmentId),
      allowDirectory: isDirectoryAllowed(state),
    }),
    { cancelAppointment },
  ),
)(CancelAppointment);
