import PropTypes from 'prop-types';
import _ from 'lodash';
import React from 'react';
import { compose, defaultProps, setDisplayName, setPropTypes, withHandlers } from 'recompose';

import { defineMessages, FormattedMessage } from 'react-intl';
import ProviderList from '../../components/providerList/providerList';

import { FlowShape } from '../../components/wizard/flow/shapes';

import { withTranslatedMessages } from '../../utils/withTranslatedMessages';
import withBookingModal from '../../components/bookingModal/withBookingModal';
import SpinnerButton from '../../components/ui/spinner/spinnerButton';

const defaultMessages = defineMessages({
  title: {
    defaultMessage: 'Book an appointment',
    id: 'members.public.bookForADoctor.title',
  },
  seeMoreInYourNetwork: {
    defaultMessage: 'See more doctors',
    id: 'members.bookForADoctor.seeMoreInYourNetwork',
  },
});

function BookForADoctor({
  providers,
  member: { plan },
  title,
  onBookClick,
  moreDoctorsButton,
  seeMoreDoctors,
}) {
  return (
    <div className="margin-top-30">
      <h1 className="title-24 no-margin padding-h-30 text-center">
        <span className="title-brand-1">{title}</span>
      </h1>
      <ProviderList
        className="selected-pcp"
        plan={plan}
        providers={_.castArray(providers)}
        onBookClick={onBookClick}
        showBook
      />
      {moreDoctorsButton ? (
        <div className="margin-top-20 text-center margin-bottom-clean-form">
          <SpinnerButton
            onClick={() => seeMoreDoctors()}
            className="btn btn-small btn-outline btn-min-100"
          >
            <FormattedMessage {...defaultMessages.seeMoreInYourNetwork} />
          </SpinnerButton>
        </div>
      ) : null}
    </div>
  );
}

BookForADoctor.propTypes = {
  onBookClick: PropTypes.func.isRequired,
  seeMoreDoctors: PropTypes.func.isRequired,
  providers: PropTypes.array.isRequired,
  member: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  moreDoctorsButton: PropTypes.bool.isRequired,
};

export default compose(
  setDisplayName('BookForADoctor'),
  setPropTypes({
    control: FlowShape.isRequired,
    providers: PropTypes.array.isRequired,
    member: PropTypes.object.isRequired,
  }),
  defaultProps({
    moreDoctorsButton: false,
  }),
  withTranslatedMessages(props => ({
    ...defaultMessages,
    ...props.messages,
  })),
  withHandlers({
    seeMoreDoctors: ({ control }) => () => control.next({}),
  }),
  withBookingModal(({ onBookingSuccess }) => ({
    onBookingSuccess,
  })),
)(BookForADoctor);
