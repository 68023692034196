import { defineMessages } from 'react-intl';

/**
 * You can add here more specific msg per joi error.
 * For example adding a field called phoneNumber.number
 * instead of showing the same error for all of the phoneNumber errors.
 */
export const messages = defineMessages({
  phoneNumber: {
    defaultMessage: 'Oopsy, please enter a valid number here.',
    id: 'joi.errors.invalidPhoneNumber',
  },
  name: {
    defaultMessage: 'We\'re getting to know you. Please enter your full name.',
    id: 'joi.errors.invalidFullName',
  },
  email: {
    defaultMessage: 'Please enter a valid email address.',
    id: 'joi.errors.invalidEmail',
  },
  dob: {
    defaultMessage: 'Please enter your date of birth MM/DD/YYYY',
    id: 'joi.errors.invalidDateOfBirth',
  },
});

