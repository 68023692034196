import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { defineMessages, FormattedMessage } from 'react-intl';
import { autobind } from 'core-decorators';
import { withRouter } from 'react-router';

import { SimpleProgressPage } from '../../../../../components/ui/layouts';
import {
  RESET_PASSWORD_REQUEST_ACTION_TYPE,
  resetPasswordRequest,
} from '../../../../../store/auth/actions';
import { resetRecaptch } from '../../../../../store/recaptcha/actions';
import IdentificationForm from './forms/IdentificationForm';
import actionTracker from '../../../../../store/tools/actionTracker/actionTrackerComponent';
import injectNotification from '../../../../../store/notification/injectNotification';
import { createIdentifier } from '../identificationSchema';
import * as Errors from '../../../../../api/errors';
import attemptsMessages from './attemptsMessages';
import resetPasswordMessages from './resetPasswordMessages';
import SvgKey from '../../../../../assets/svg/key';

const messages = defineMessages({
  resetPassword: {
    defaultMessage: 'Reset your password',
    id: 'members.auth.identificationStep.resetPassword',
  },
  forgotYourPassword: {
    defaultMessage: 'Forgot your password?',
    id: 'members.auth.identificationStep.forgotYourPassword',
  },
  enterYourMobileNumberOrEmail: {
    defaultMessage: 'Enter your mobile number or email and we’ll get you a shiny new one.',
    id: 'members.auth.identificationStep.enterYourMobileNumberOrEmail',
  },
  serverErrorTitle: {
    defaultMessage: 'Sorry, an error has occurred',
    id: 'members.auth.identificationStep.error.serverErrorTitle',
  },
  generalErrorMessage: {
    defaultMessage: 'Error occurred when resetting password',
    id: 'members.auth.identificationStep.error.generalErrorMessage',
  },
});

@autobind
class IdentificationStep extends React.Component {
  static propTypes = {
    /**
     * Server request action tracking.
     * For resetPasswordRequest.
     */
    resetPasswordRequestTracking: PropTypes.object.isRequired,
    /**
     * Redux function to resetPasswordRequest.
     */
    resetPasswordRequest: PropTypes.func.isRequired,
    /**
     * Reset system Recaptch action.
     * Provided by the connect HoC
     */
    resetRecaptch: PropTypes.func.isRequired,
    /**
     * Injected prop for notifying on errors
     */
    notification: PropTypes.object.isRequired,
    /**
     * Injected prop for using the flow manager.
     */
    flow: PropTypes.object.isRequired,
    /**
     * Router object.
     * Provided by the withRouter HoC
     */
    router: PropTypes.object.isRequired,
    /**
     * auth object.
     * Provided by redux
     */
    auth: PropTypes.object.isRequired,
  };

  componentWillReceiveProps(nextProps) {
    const { resetPasswordRequestTracking: tracking } = nextProps;
    if (tracking.finished) this.handleGeneralResponse(tracking);
  }

  onSubmit(data) {
    const identifierDetails = createIdentifier(data);
    this.props.resetPasswordRequest(identifierDetails);
  }

  handleGeneralResponse(tracking) {
    if (tracking.hasError) {
      this.props.resetRecaptch();

      let errorMsg;
      const { responseMessage } = tracking.error;
      switch (responseMessage) {
        case Errors.MAX_RESET_PASSWORD_ATTEMPTS: {
          errorMsg = attemptsMessages.maxAttemptsErrorMessage;
          break;
        }
        case Errors.USER_LOCKED: {
          errorMsg = attemptsMessages.userLockedErrorMessage;
          break;
        }
        case Errors.RECAPTCHA_FAILED:
          return; // Handled by recaptchaMiddleware
        default: {
          errorMsg = messages.generalErrorMessage;
          break;
        }
      }

      this.props.notification.error(messages.serverErrorTitle, errorMsg, {
        autoDismiss: 15,
      });
    } else {
      this.props.notification.success(
        resetPasswordMessages.successTitle,
        resetPasswordMessages.successMessage,
        {
          autoDismiss: 15,
        },
      );
      this.props.flow.nextStep();
    }
  }

  render() {
    const { resetPasswordRequestTracking } = this.props;
    return (
      <SimpleProgressPage
        caption={messages.forgotYourPassword}
        progress={null}
        img={<SvgKey />}
        externalPageLogo
        showRightNavInMobile
        title=""
      >
        <div className="row">
          <div className="col-xs-12 text-center">
            <span className="text-18 text-light">
              <FormattedMessage {...messages.enterYourMobileNumberOrEmail} />
            </span>
          </div>
        </div>
        <br />
        <IdentificationForm onSubmit={this.onSubmit} loadingTrack={resetPasswordRequestTracking} />
      </SimpleProgressPage>
    );
  }
}

export default compose(
  actionTracker({
    resetPasswordRequestTracking: RESET_PASSWORD_REQUEST_ACTION_TYPE.SOURCE,
  }),
  connect(
    state => ({
      auth: state.auth,
    }),
    { resetPasswordRequest, resetRecaptch },
  ),
  injectNotification,
  withRouter,
)(IdentificationStep);
