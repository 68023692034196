import _ from 'lodash';

import { PASSWORD_ALLOWED_CHARACTERS } from 'Utils/regex/password';

const MINIMUM_CHILD_RULES = 3;

export const passwordRules = {
  min: {
    defaultMessage: 'At least 8 characters',
    id: 'min',
    filled: false,
  },
  minRules: {
    defaultMessage: 'At least 3 of the following:',
    id: 'minRules',
    filled: false,
    childListIds: ['uppercase', 'lowercase', 'digits', 'symbols'],
  },
  uppercase: {
    defaultMessage: 'Upper case letters (A-Z)',
    id: 'uppercase',
    filled: false,
    parentId: 'minRules',
  },
  lowercase: {
    defaultMessage: 'Lower case letters (a-z)',
    id: 'lowercase',
    filled: false,
    parentId: 'minRules',
  },
  digits: {
    defaultMessage: 'Numbers (0-9)',
    id: 'digits',
    filled: false,
    parentId: 'minRules',
  },
  symbols: {
    defaultMessage: `Special characters (ex: ${PASSWORD_ALLOWED_CHARACTERS})`,
    id: 'symbols',
    filled: false,
    parentId: 'minRules',
  },
};

export function passwordValidator(password) {
  let list = [];
  let tmpRules = [];
  let filledChildRules = 0;
  let score = 0;
  const PASSWORD_ALLOWED_CHARACTERS_REGEX = new RegExp(
    '[' + PASSWORD_ALLOWED_CHARACTERS + ']',
    'g',
  );
  const PASSWORD_DEPRECATE_CHARACTERS_REGEX = new RegExp(
    '[A-Za-z-0-9' + PASSWORD_ALLOWED_CHARACTERS + ']',
    'g',
  );

  const validate = pass => {
    let list = [];

    const MIN_LENGTH = 8;
    const lowercase = pass.length - pass.replace(/[a-z]/g, '').length;
    const uppercase = pass.length - pass.replace(/[A-Z]/g, '').length;
    const symbols = pass.length - pass.replace(PASSWORD_ALLOWED_CHARACTERS_REGEX, '').length;
    const digits = pass.length - pass.replace(/[0-9]/g, '').length;

    if (!lowercase) {
      list.push('lowercase');
    }
    if (!uppercase) {
      list.push('uppercase');
    }
    if (!symbols) {
      list.push('symbols');
    }
    if (!digits) {
      list.push('digits');
    }
    if (pass.length < MIN_LENGTH) {
      list.push('min');
    }

    return list;
  };

  const validationList = validate(password);
  const deprecatedCharacters = password.replace(PASSWORD_DEPRECATE_CHARACTERS_REGEX, '').trim()
    .length;

  _.forEach([...validationList, passwordRules.minRules.id], (item, index) =>
    passwordRules[item] ? list.push(passwordRules[item].id) : list,
  );

  _.forOwn(passwordRules, (rule, key) =>
    list.some(el => el === passwordRules[key].id)
      ? tmpRules.push({ ...passwordRules[key], filled: false })
      : tmpRules.push({ ...passwordRules[key], filled: true }),
  );

  _.forEach(passwordRules['minRules'].childListIds, (rule, index) =>
    tmpRules.find(item => item.id === rule && item.filled) ? filledChildRules++ : filledChildRules,
  );

  if (filledChildRules >= MINIMUM_CHILD_RULES) {
    tmpRules = tmpRules.map(rule => (rule.id === 'minRules' ? { ...rule, filled: true } : rule));
  }

  _.forEach(tmpRules, (rule, index) => {
    if (!rule.parentId && rule.filled) {
      score++;
    }
  });

  return { list: tmpRules, score, deprecatedCharacters };
}
