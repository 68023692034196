import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { defineMessages, injectIntl } from 'react-intl';
import Joi from 'joi-browser';
import _ from 'lodash';
import { compose } from 'redux';
import { withHandlers, withProps, withPropsOnChange } from 'recompose';

import { createValidator } from '../../../utils/joiValidator';
import * as RenewExpiredPasswordFormDef from './renewExpiredPasswordFormDef';
import { schema } from './renewExpiredPasswordScheme';
import { SpinnerButton } from '../../ui/spinner';
import { RENEW_EXPIRED_PASSWORD, renewExpiredPassword } from '../../../store/auth/actions';
import actionTracker from '../../../store/tools/actionTracker/actionTrackerComponent';
import injectNotification from '../../../store/notification/injectNotification';
import SvgHeartLock from '../../../assets/svg/heartLock';
import * as Errors from '../../../api/errors';
import { getReason } from '../../../store/auth/selectors';
import withFeatureFlag from '../../../utils/featureFlags/withFeatureFlag';

const messages = defineMessages({
  serverErrorTitle: {
    defaultMessage: 'Sorry, an error has occured.',
    id: 'auth.renewExpiredPassword.error.serverErrorTitle',
  },
  serverErrorMessage: {
    defaultMessage: 'Please refresh the page and try again.',
    id: 'auth.renewExpiredPassword.error.serverErrorMessage',
  },
  tokenExpiredErrorTitle: {
    defaultMessage: 'This session has expired.',
    id: 'auth.renewExpiredPassword.error.tokenExpiredErrorTitle',
  },
  invalidPasswordTitle: {
    defaultMessage: 'Invalid password.',
    id: 'auth.renewExpiredPassword.error.invalidPasswordTitle',
  },
  invalidPasswordMessage: {
    defaultMessage: 'Please ensure your current password is correct.',
    id: 'auth.renewExpiredPassword.error.invalidPasswordMessage',
  },
  weakPasswordErrorTitle: {
    defaultMessage: 'The password you entered is too weak',
    id: 'auth.renewExpiredPassword.error.weakPasswordErrorTitle',
  },
  weakPasswordErrorMessage: {
    defaultMessage: 'Try another one',
    id: 'auth.renewExpiredPassword.error.weakPasswordErrorMessage',
  },
  passwordWasInUseTitle: {
    defaultMessage: 'This password has been used already',
    id: 'auth.renewExpiredPassword.error.passwordWasInUseTitle',
  },
  passwordWasInUseMessage: {
    defaultMessage: 'Try another one',
    id: 'activation.flow.error.passwordWasInUseMessage',
  },
  tokenExpiredErrorBody: {
    defaultMessage: 'Please refresh the page and try again.',
    id: 'auth.renewExpiredPassword.error.tokenExpiredErrorBody',
  },
  changeSuccessTitle: {
    defaultMessage: 'Password has been updated.',
    id: 'auth.renewExpiredPassword.changeSuccessTitle',
  },
  changeSuccessBody: {
    defaultMessage: 'You’re all set.',
    id: 'auth.renewExpiredPassword.changeSuccessBody',
  },
  currentPassword: {
    defaultMessage: 'Current password',
    id: 'auth.renewExpiredPassword.currentPassword',
  },
  newPassword: {
    defaultMessage: 'New password',
    id: 'auth.renewExpiredPassword.newPassword',
  },
  repeatPassword: {
    defaultMessage: 'Re-enter password',
    id: 'auth.renewExpiredPassword.repeatPassword',
  },
  verifyNewPassword: {
    defaultMessage: 'Verify new password',
    id: 'auth.renewExpiredPassword.verifyNewPassword',
  },
  savePassword: {
    defaultMessage: 'Save password',
    id: 'auth.renewExpiredPassword.savePassword',
  },
  createPassword: {
    defaultMessage: 'Create password',
    id: 'auth.renewExpiredPassword.createPassword',
  },
  itsTimeToChangeYourPassword: {
    defaultMessage: 'It’s time to change your password',
    id: 'auth.renewExpiredPassword.itsTimeToChangeYourPassword',
  },
  forSecurityPurposes: {
    defaultMessage: 'For security purposes, you’ll need to update your password every 90 days.',
    id: 'auth.renewExpiredPassword.forSecurityPurposes',
  },
  createYourOwn: {
    defaultMessage: 'Create your own password',
    id: 'auth.renewExpiredPassword.createYourOwn',
  },
  someYouWillKnow: {
    defaultMessage: "Something you'll know by heart.",
    id: 'auth.renewExpiredPassword.someYouWillKnow',
  },
});

/* export const passwordsMatch = function passwordsMatch(value, allValues) {
  return value !== allValues.newPassword ? 'Passwords don\'t match' : undefined;
}; */

const handleResponse = function handleResponse({ error, success }, onSuccess, tracking) {
  if (tracking.hasError) {
    const { responseMessage } = tracking.error;
    switch (responseMessage) {
      case Errors.FORBIDDEN: {
        error(messages.tokenExpiredErrorTitle, messages.tokenExpiredErrorBody);
        break;
      }
      case Errors.WEAK_PASSWORD: {
        error(messages.weakPasswordErrorTitle, messages.weakPasswordErrorMessage);
        break;
      }
      case Errors.PASSWORD_WAS_IN_USE: {
        error(messages.passwordWasInUseTitle, messages.passwordWasInUseMessage);
        break;
      }
      case Errors.INVALID_PASSWORD: {
        error(messages.invalidPasswordTitle, messages.invalidPasswordMessage);
        break;
      }
      default: {
        error(messages.serverErrorTitle, messages.serverErrorMessage);
      }
    }
  } else {
    success(messages.changeSuccessTitle, messages.changeSuccessBody);
    onSuccess();
  }
};

const RenewExpiredPassword = props => {
  const {
    logo,
    title,
    subtitle,
    buttonText,
    changeExpiredPasswordTracker,
    handleSubmit,
    newPasswordValidationFeature,
    change,
  } = props;
  const isLoading = _.get(changeExpiredPasswordTracker, 'inProgress', true);

  const onPasswordStrengthChanged = strength => {
    if (newPasswordValidationFeature) {
      change(RenewExpiredPasswordFormDef.fields.passwordStrength.name, strength);
    }
  };

  return (
    <div className="container-1040 container">
      <div className="row padding-h-30">
        <div className="col-sm-6 col-sm-offset-3 col-md-4 col-md-offset-4">
          <div className="svg-72 text-center margin-top-30">{logo}</div>
          <div className="title-24 md-margin font-color-brand-main text-center text-strong">
            {title}
          </div>
          <div className="text-18 text-light margin-bottom-clean-form text-center">{subtitle}</div>
          <div className="row">
            <div className="col-xs-12">
              <Field
                hideHelpers
                id="currentPassword"
                label={messages.currentPassword}
                withFeatureFlag={newPasswordValidationFeature}
                {...RenewExpiredPasswordFormDef.fields.currentPassword}
              />
            </div>
          </div>
          <div className="row margin-top-30">
            <div className="col-xs-12">
              <Field
                id="newPassword"
                label={messages.newPassword}
                withFeatureFlag={newPasswordValidationFeature}
                onStrengthChanged={onPasswordStrengthChanged}
                verifyPasswordField={
                  <div className="margin-top-30">
                    <Field
                      id="repeatPassword"
                      label={messages.repeatPassword}
                      {...RenewExpiredPasswordFormDef.fields.repeatPassword}
                    />
                  </div>
                }
                {...RenewExpiredPasswordFormDef.fields.newPassword}
              />
            </div>
          </div>
          {!newPasswordValidationFeature && (
            <div className="row">
              <div className="col-xs-12">
                <Field
                  id="verifyNewPassword"
                  label={messages.verifyNewPassword}
                  {...RenewExpiredPasswordFormDef.fields.verifyNewPassword}
                />
              </div>
            </div>
          )}
          <div className="row margin-bottom-clean-form">
            <div className="col-xs-12">
              <SpinnerButton
                className="btn btn-big bg-color-brand-button"
                isLoading={isLoading}
                onClick={handleSubmit}
              >
                {buttonText}
              </SpinnerButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

RenewExpiredPassword.propTypes = {
  /**
   * Hook method. supplied by redux-forms
   */
  handleSubmit: PropTypes.func.isRequired,
  /**
   * redux action track object
   */
  changeExpiredPasswordTracker: PropTypes.object.isRequired,
  logo: PropTypes.element,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
};

RenewExpiredPassword.defaultProps = {
  logo: <SvgHeartLock />,
};

const chooseMessages = compose(
  injectIntl,
  withProps(({ intl, reason }) => {
    if (reason === Errors.PASSWORD_EXPIRED) {
      return {
        title: intl.formatMessage(messages.itsTimeToChangeYourPassword),
        subtitle: intl.formatMessage(messages.forSecurityPurposes),
        buttonText: intl.formatMessage(messages.savePassword),
      };
    }
    // TEMPORARY_PASSWORD
    return {
      title: intl.formatMessage(messages.createYourOwn),
      subtitle: intl.formatMessage(messages.someYouWillKnow),
      buttonText: intl.formatMessage(messages.createPassword),
    };
  }),
);

const form = reduxForm({
  onSubmit: (values, dispatch) => {
    dispatch(renewExpiredPassword(values));
  },
  form: RenewExpiredPasswordFormDef.name,
  validate: createValidator(Joi.object().keys(schema), RenewExpiredPasswordFormDef.customValidator),
});

export default compose(
  form,
  injectNotification,
  withHandlers({
    handleResponse: ({ notification, onSuccess }) => tracking =>
      handleResponse(notification, onSuccess, tracking),
  }),
  actionTracker({
    changeExpiredPasswordTracker: RENEW_EXPIRED_PASSWORD.SOURCE,
  }),
  connect(state => ({
    reason: getReason(state),
  })),
  withPropsOnChange(['changeExpiredPasswordTracker'], ownProps => {
    const { changeExpiredPasswordTracker, handleResponse } = ownProps;
    if (changeExpiredPasswordTracker.finished) {
      handleResponse(changeExpiredPasswordTracker);
    }
  }),
  chooseMessages,
  withFeatureFlag({
    key: 'vim.members.password_validation_feature',
    prop: 'newPasswordValidationFeature',
    defaultValue: false,
  }),
)(RenewExpiredPassword);
