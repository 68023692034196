/**
 * Created by matan on 10/1/17.
 */
import _ from 'lodash';
import Immutable from 'seamless-immutable';
import { combineReducers } from 'redux';

import {
  VALIDATE,
  SET_ACTIVATION_STEP,
  SEARCH_MEMBER,
  INVITE_MEMBER,
  ACTIVATE,
  ACTIVATE_WITH_MEMBER_ID,
  VALIDATE_PHONE_NUMBER,
} from './actions';
import genericReducer from '../genericReducer';

const activationToken = (state = {}, action) => {
  switch (action.type) {
    case ACTIVATE_WITH_MEMBER_ID.SUCCESS:
    case ACTIVATE.SUCCESS:
    case VALIDATE.SUCCESS: {
      return _.get(action.payload.data, 'activationToken', 'undefined');
    }
  }
  return state;
};

const validationToken = (state = {}, action) => {
  if (action.type === VALIDATE_PHONE_NUMBER.SUCCESS) {
    return _.get(action.payload, 'validationToken', 'undefined');
  }

  return state;
};

export default combineReducers({
  step: (state = null, action) =>
    Immutable(action.type === SET_ACTIVATION_STEP ? action.payload : state),
  isInActiveState: genericReducer(VALIDATE, {}, payload => payload.data.activeState),
  activationToken,
  validationToken,
  memberSearch: genericReducer(SEARCH_MEMBER),
  inviteMember: genericReducer(INVITE_MEMBER),
});
