import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import Address from '../../form/address';
import { isGooglePlacesApiLoaded } from '../../../store/help/selectors';
import { loadingUntil } from '../../recompose/loadingUntil';

function getOptions(geo) {
  if (_.isEmpty(geo)) return {};
  return {
    // Location for prediction biasing.
    location: new window.google.maps.LatLng(geo.latitude, geo.longitude),
    radius: 8000, // Required param when given a location - set to 8000 for covering all the US
    componentRestrictions: { country: 'US' },
  };
}

const LocationSelect = ({ location, addressChange, defaultOptions, placeholder }) => {
  const address = _.get(location, 'address');
  const options = getOptions(_.get(location, 'geo'));

  return (
    <Address
      name="address"
      address={address}
      onChange={addressChange}
      options={options}
      defaultOptions={defaultOptions}
      placeholder={placeholder}
    />
  );
};

LocationSelect.propTypes = {
  addressChange: PropTypes.func.isRequired,
  location: PropTypes.object,
  placeholder: PropTypes.string,
  defaultOptions: PropTypes.array,
};

LocationSelect.defaultProps = {
  location: null,
  placeholder: undefined,
  defaultOptions: undefined,
};

export default compose(
  connect(state => ({
    isReady: isGooglePlacesApiLoaded(state),
  })),
  loadingUntil(({ isReady }) => isReady === true),
)(LocationSelect);
