import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '../../storybook/src/utils/theme';

function SvgFaxStars({ theme }) {
  /* eslint-disable */
  return (
    <svg aria-hidden="true" focusable="false" className="svg-icon svg-fax-stars" width="72px" height="68px" viewBox="0 0 72 68" version="1.1">
      <g id="Initiating-Referral-flow---w/-a-vim-doctor" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Fax-required-visit-summary" transform="translate(-801.000000, -79.000000)">
          <g id="Page-1" transform="translate(801.000000, 80.000000)">
            <path d="M60.5,42.7837797 L60.5,9.0811 C60.5,5.18952712 57.3105729,2.0001 53.419,2.0001 L17.581,2.0001 C13.6894271,2.0001 10.5,5.18952712 10.5,9.0811 L10.5,11.2241 C10.5,12.0525271 9.82842712,12.7241 9,12.7241 C8.17157288,12.7241 7.5,12.0525271 7.5,11.2241 L7.5,9.0811 C7.5,3.53267288 12.0325729,-0.9999 17.581,-0.9999 L53.419,-0.9999 C58.9674271,-0.9999 63.5,3.53267288 63.5,9.0811 L63.5,43.4051 C63.5,43.8029247 63.3419647,44.1844556 63.0606602,44.4657602 L41.6086602,65.9177602 C41.3273556,66.1990647 40.9458247,66.3571 40.548,66.3571 L17.581,66.3571 C12.0325729,66.3571 7.5,61.8245271 7.5,56.2761 L7.5,30.8571 C7.5,30.0286729 8.17157288,29.3571 9,29.3571 C9.82842712,29.3571 10.5,30.0286729 10.5,30.8571 L10.5,56.2761 C10.5,60.1676729 13.6894271,63.3571 17.581,63.3571 L39.9266797,63.3571 L60.5,42.7837797 Z" id="Stroke-1" fill={theme.mainColor} fillRule="nonzero"></path>
            <path d="M42.0479,44.9051 L42.0479,64.8571 C42.0479,65.6855271 41.3763271,66.3571 40.5479,66.3571 C39.7194729,66.3571 39.0479,65.6855271 39.0479,64.8571 L39.0479,43.4051 C39.0479,42.5766729 39.7194729,41.9051 40.5479,41.9051 L61.9999,41.9051 C62.8283271,41.9051 63.4999,42.5766729 63.4999,43.4051 C63.4999,44.2335271 62.8283271,44.9051 61.9999,44.9051 L42.0479,44.9051 Z" id="Stroke-3" fill={theme.mainColor} fillRule="nonzero"></path>
            <path d="M34.71,9.4909 C34.71,8.66247288 35.3815729,7.9909 36.21,7.9909 C37.0384271,7.9909 37.71,8.66247288 37.71,9.4909 L37.71,23.2139 C37.71,24.0423271 37.0384271,24.7139 36.21,24.7139 C35.3815729,24.7139 34.71,24.0423271 34.71,23.2139 L34.71,9.4909 Z" id="Stroke-5" fill={theme.mainColor} fillRule="nonzero"></path>
            <path d="M43.0713,14.8527 C43.8997271,14.8527 44.5713,15.5242729 44.5713,16.3527 C44.5713,17.1811271 43.8997271,17.8527 43.0713,17.8527 L29.3483,17.8527 C28.5198729,17.8527 27.8483,17.1811271 27.8483,16.3527 C27.8483,15.5242729 28.5198729,14.8527 29.3483,14.8527 L43.0713,14.8527 Z" id="Stroke-7" fill={theme.mainColor} fillRule="nonzero"></path>
            <path d="M54.4287,30.9421 C55.2571271,30.9421 55.9287,31.6136729 55.9287,32.4421 C55.9287,33.2705271 55.2571271,33.9421 54.4287,33.9421 L17.9907,33.9421 C17.1622729,33.9421 16.4907,33.2705271 16.4907,32.4421 C16.4907,31.6136729 17.1622729,30.9421 17.9907,30.9421 L54.4287,30.9421 Z" id="Stroke-9" fill={theme.secondaryColor} fillRule="nonzero"></path>
            <path d="M33.6074,38.7721 C34.4358271,38.7721 35.1074,39.4436729 35.1074,40.2721 C35.1074,41.1005271 34.4358271,41.7721 33.6074,41.7721 L17.9914,41.7721 C17.1629729,41.7721 16.4914,41.1005271 16.4914,40.2721 C16.4914,39.4436729 17.1629729,38.7721 17.9914,38.7721 L33.6074,38.7721 Z" id="Stroke-11" fill={theme.secondaryColor} fillRule="nonzero"></path>
            <path d="M33.6074,46.6022 C34.4358271,46.6022 35.1074,47.2737729 35.1074,48.1022 C35.1074,48.9306271 34.4358271,49.6022 33.6074,49.6022 L17.9914,49.6022 C17.1629729,49.6022 16.4914,48.9306271 16.4914,48.1022 C16.4914,47.2737729 17.1629729,46.6022 17.9914,46.6022 L33.6074,46.6022 Z" id="Stroke-13" fill={theme.secondaryColor} fillRule="nonzero"></path>
            <path d="M2.6953,21.1637 C2.6953,17.6034744 5.58167135,14.7167 9.1413,14.7167 C12.7025256,14.7167 15.5893,17.6030714 15.5893,21.1637 C15.5893,24.7243286 12.7025256,27.6107 9.1413,27.6107 C5.58167135,27.6107 2.6953,24.7239256 2.6953,21.1637 Z M5.6953,21.1637 C5.6953,23.0671651 7.23861938,24.6107 9.1413,24.6107 C11.0457651,24.6107 12.5893,23.0673806 12.5893,21.1637 C12.5893,19.2600194 11.0457651,17.7167 9.1413,17.7167 C7.23861938,17.7167 5.6953,19.2602349 5.6953,21.1637 Z" id="Stroke-15" fill={theme.secondaryColor} fillRule="nonzero"></path>
            <path d="M4,31.8571 C4,32.9611 3.104,33.8571 2,33.8571 C0.896,33.8571 0,32.9611 0,31.8571 C0,30.7531 0.896,29.8571 2,29.8571 C3.104,29.8571 4,30.7531 4,31.8571" id="Fill-17" fill={theme.secondaryColor} ></path>
            <path d="M66,51.3571 C66,50.5286729 66.6715729,49.8571 67.5,49.8571 C68.3284271,49.8571 69,50.5286729 69,51.3571 L69,57.3571 C69,58.1855271 68.3284271,58.8571 67.5,58.8571 C66.6715729,58.8571 66,58.1855271 66,57.3571 L66,51.3571 Z" id="Stroke-19" fill={theme.secondaryColor} fillRule="nonzero"></path>
            <path d="M70.5,52.8571 C71.3284271,52.8571 72,53.5286729 72,54.3571 C72,55.1855271 71.3284271,55.8571 70.5,55.8571 L64.5,55.8571 C63.6715729,55.8571 63,55.1855271 63,54.3571 C63,53.5286729 63.6715729,52.8571 64.5,52.8571 L70.5,52.8571 Z" id="Stroke-21" fill={theme.secondaryColor} fillRule="nonzero"></path>
          </g>
        </g>
      </g>
    </svg>
  );
  /* eslint-enable */
}

SvgFaxStars.propTypes = {
  theme: PropTypes.object.isRequired,
};

export default withTheme(SvgFaxStars);
