/**
 * Created by chenrozenes on 09/08/2017.
 */
import { defineMessages } from 'react-intl';
import IntlEnum from '../../../utils/intlEnum';

const messages = defineMessages({
  womenHealth: {
    defaultMessage: 'Women\'s health',
    id: 'enum.careCues.subCategory.womenHealth'
  },
  cancerScreening: {
    defaultMessage: 'Cancer Screenings',
    id: 'enum.careCues.subCategory.cancerScreening'
  },
  careOfDiabetes: {
    defaultMessage: 'Care for diabetes',
    id: 'enum.careCues.subCategory.careOfDiabetes'
  },
  careOfOsteoporosis: {
    defaultMessage: 'Care for osteoporosis',
    id: 'enum.careCues.subCategory.careOfOsteoporosis'
  },
  careOfArthritis: {
    defaultMessage: 'Care for arthritis',
    id: 'enum.careCues.subCategory.careOfArthritis'
  },
});

export const WOMEN_HEALTH = 'WOMEN_HEALTH';
export const CARE_OF_DIABETES = 'CARE_OF_DIABETES';
export const CANCER_SCREENING = 'CANCER_SCREENING';
export const CARE_OF_OSTEOPOROSIS = 'CARE_OF_OSTEOPOROSIS';
export const CARE_OF_ARTHRITIS = 'CARE_OF_ARTHRITIS';

export default new IntlEnum(
  { value: WOMEN_HEALTH, message: messages.womenHealth },
  { value: CANCER_SCREENING, message: messages.cancerScreening },
  { value: CARE_OF_DIABETES, message: messages.careOfDiabetes },
  { value: CARE_OF_OSTEOPOROSIS, message: messages.careOfOsteoporosis },
  { value: CARE_OF_ARTHRITIS, message: messages.careOfArthritis },
);
