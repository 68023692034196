/**
 * Created by galgoltzman on 28/02/2017.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { defineMessages, FormattedMessage } from 'react-intl';
import { Field } from 'redux-form';
import { autobind } from 'core-decorators';

import { PhoneNumberModal } from '../modals/index';
import * as ActivationForm from '../activationForm';
import Recaptcha from '../../../../../components/verification/bkmdRecaptcha';
import TermsOfServices from '../../../../../components/termsOfServices/termsOfServices';
import SpinnerButton from '../../../../../components/ui/spinner/spinnerButton';
import SvgUserCircle from '../../../../../assets/svg/userCircle';
import { getFeature, getDomain } from '../../../../../store/feature/selectors';
import { withActivationForm } from './withActivationForm';

const messages = defineMessages({
  yourNumber: {
    defaultMessage: 'Your mobile number',
    id: 'activation.mobileNum.yourNumber',
  },
});

@autobind
class MobileNum extends React.Component {
  static propTypes = {
    /**
     * Hook method. supplied by redux-forms
     */
    handleSubmit: PropTypes.func.isRequired,
    /**
     * True if form is currently submitting
     */
    loading: PropTypes.bool,

    domain: PropTypes.string,
    letsGetYouSetUp: PropTypes.object.isRequired,
    activateMyAccount: PropTypes.object.isRequired,
  };

  static contextTypes = {
    analytics: PropTypes.object.isRequired,
  };

  static defaultProps = {
    loading: false,
    domain: undefined,
  };

  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
    };
  }

  componentDidMount() {
    this.context.analytics.manualPageReport('activation.mobileNumber');
  }

  openModal(e) {
    e.preventDefault();
    this.setState({ isModalOpen: true });
  }

  closeModal() {
    this.setState({ isModalOpen: false });
  }

  render() {
    const { handleSubmit, loading, domain, letsGetYouSetUp, activateMyAccount } = this.props;
    return (
      <form onSubmit={handleSubmit} className="activation-mobile-num-wrap" method="post">
        <div className="top-section">
          <div className="row">
            <div className="col-xs-12 text-center">
              <SvgUserCircle />
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 text-center">
              <h1 className="title-24 md-margin title-brand-1">
                <FormattedMessage {...letsGetYouSetUp} />
              </h1>
            </div>
          </div>
        </div>

        <div className="form-section">
          <div className="row">
            <div className="col-xs-12">
              <Field
                inputClassName="no-margin"
                label={messages.yourNumber}
                {...ActivationForm.fields.phoneNumber}
                id="phoneNumber"
              />
            </div>
          </div>
        </div>

        <div className="row recaptcha-wrap">
          <div className="col-xs-12 no-padding">
            <Recaptcha />
          </div>
        </div>

        <div className="row margin-top-30">
          <div className="col-xs-12">
            <SpinnerButton
              isLoading={loading}
              className="btn btn-big bg-color-brand-button"
              type="submit"
            >
              <FormattedMessage {...activateMyAccount} />
            </SpinnerButton>
          </div>
        </div>
        <div className="margin-top-30 text-center">
          <TermsOfServices isMember domain={domain} activateText={activateMyAccount} />
        </div>
        <PhoneNumberModal isOpen={this.state.isModalOpen} handleClose={this.closeModal} />
      </form>
    );
  }
}

const fieldName = 'phoneNumber';
export default compose(
  connect(state => ({
    domain: getDomain(state),
    letsGetYouSetUp: getFeature(state, 'activationFlow.phoneNumberStep.letsGetYouSetUp'),
    activateMyAccount: getFeature(state, 'activationFlow.phoneNumberStep.activateMyAccount'),
  })),
  withActivationForm(fieldName),
)(MobileNum);
