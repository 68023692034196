import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import SvgVimLoader from '../../../../../assets/svg/vimLoader';
import SvgVimHeart from '../../../../../assets/svg/vimHeart';
import './Loader.less';
import { withTheme } from '../../../utils/theme';

const loaderType = {
  default: 'vim-loader',
  dots: 'dots-loader',
};

const loaderSize = {
  default: '',
  small: 'small',
};

const loaderColor = {
  default: '',
  grey: 'grey',
  white: 'white',
};

const loaderPadding = {
  default: 'padding-v-50',
  small: 'padding-box-10',
  none: 'no-padding',
};

function Loader({ className, type, size, color, theme, inline, padding, ariaLiveOff, ariaLabel }) {
  const dynamicStyle = color === 'default' ? { backgroundColor: theme.mainColor } : {};
  const loaderInline = inline ? 'inline-block' : undefined;
  const ariaLive = ariaLiveOff ? 'off' : 'polite';
  return (
    <div
      className={classNames(
        'loader',
        className,
        loaderType[type],
        loaderSize[size],
        loaderColor[color],
        loaderInline,
        loaderPadding[padding],
      )}
      aria-live={ariaLive}
      aria-label={ariaLabel}
    >
      <div className="bounce bounce1" style={dynamicStyle} />
      <div className="bounce bounce2" style={dynamicStyle} />
      <div className="bounce bounce3" style={dynamicStyle} />
      <span className="svg-loader">
        <SvgVimLoader />
        <SvgVimHeart />
      </span>
    </div>
  );
}

Loader.propTypes = {
  theme: PropTypes.object.isRequired,
  className: PropTypes.string,
  type: PropTypes.string,
  size: PropTypes.string,
  color: PropTypes.string,
  padding: PropTypes.string,
  inline: PropTypes.bool,
  ariaLiveOff: PropTypes.bool,
  ariaLabel: PropTypes.string,
};

Loader.defaultProps = {
  className: '',
  type: 'default',
  size: 'default',
  color: 'default',
  padding: 'default',
  inline: false,
  ariaLiveOff: false,
  ariaLabel: 'Loading',
};

export default withTheme(Loader);
