/**
 * Created by moran on 6/27/17.
 */
import PromiseActionType from '../promiseActionType';

export const GET_TAXONOMIES_LIST = new PromiseActionType('GET_TAXONOMIES_LIST');

export function getTaxonomyList() {
  return ({ bkmdApi: { metaApi } }) => ({
    type: GET_TAXONOMIES_LIST.SOURCE,
    meta: {
      tracker: GET_TAXONOMIES_LIST.SOURCE,
    },
    payload: {
      promise: metaApi.getTaxonomyList()
    }
  });
}
