import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { pure, compose, setDisplayName, setPropTypes, withProps } from 'recompose';
import { defineMessages } from 'react-intl';
import _ from 'lodash';
import { withTranslatedMessages } from '../utils/withTranslatedMessages';
import './SCR016YesNo.less';

const messages = defineMessages({
  yes: {
    defaultMessage: 'Yes',
    id: 'flowsManager.yesNoPage.yes',
  },
  no: {
    defaultMessage: 'No',
    id: 'flowsManager.yesNoPage.no',
  },
});

function YesNo({
  className,
  question,
  svgIcon,
  onYes,
  onNo,
  horizontal,
  yesBtnText,
  noBtnText,
  noButtonClassName,
  yesButtonClassName,
  questionClassName,
}) {
  return (
    <div className={classNames('yes-no-page padding-h-15', className)}>
      <div className="card">
        <div className="card-body padding-box-30">
          <div className="row margin-top page-img">
            <div className="col-xs-12 text-center svg-72">{svgIcon}</div>
          </div>
          <div className="row yes-no-question">
            <div className="col-xs-12">
              <div
                className={classNames('title-24 text-center', questionClassName)}
              >
                {question}
              </div>
            </div>
          </div>
          <div
            className="row tiny-gutter button-row margin-top-30"
          >
            <div className={classNames('button-col', { horizontal })}>
              <button
                className={classNames('btn yes-btn', yesButtonClassName)}
                onClick={onYes}
              >
                {yesBtnText}
              </button>
              <button
                className={classNames('btn btn-big btn-grey btn-min-100 no-btn', noButtonClassName)}
                onClick={onNo}
              >
                {noBtnText}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
YesNo.propTypes = {
  className: PropTypes.string,
  question: PropTypes.string,
  svgIcon: PropTypes.object,
  onYes: PropTypes.func,
  onNo: PropTypes.func,
  yesBtnText: PropTypes.string.isRequired,
  noBtnText: PropTypes.string.isRequired,
  noButtonClassName: PropTypes.string,
  yesButtonClassName: PropTypes.string,
  horizontal: PropTypes.bool,
  questionClassName: PropTypes.string,
};
YesNo.defaultProps = {
  className: undefined,
  question: undefined,
  svgIcon: undefined,
  onYes: _.noop,
  onNo: _.noop,
  yesButtonClassName: 'btn-big btn-min-100 bg-color-brand-button',
  noButtonClassName: undefined,
  horizontal: true,
  questionClassName: 'text-strong-dark margin-top-20',
};

const enhance = compose(
  setDisplayName('SCR016YesNo'),
  pure,
  setPropTypes({
    yesBtnText: PropTypes.string,
    noBtnText: PropTypes.string,
    noButtonClassName: PropTypes.string,
  }),
  withTranslatedMessages(messages),
  withProps(({ yes, no, yesBtnText, noBtnText }) => ({
    yesBtnText: yesBtnText || yes,
    noBtnText: noBtnText || no,
  })),
);

export default enhance(YesNo);
