import React from 'react';
import { defineMessages } from 'react-intl';
import UpdateAddressPage from './updateAddressPage';
import SvgHomeStars from '../../../../../assets/svg/homeStars';

const messages = defineMessages({
  enterYourAddress: {
    defaultMessage: 'Your home address',
    id: 'members.settings.homeAddress.enterAddress',
  },
  hiddenTitle: {
    defaultMessage: 'Update home address',
    id: 'members.settings.homeAddress.hiddenTitle',
  },
});

export default UpdateAddressPage(
  'HOME',
  messages.enterYourAddress,
  'member.settings.search.homeAddress.address',
  <SvgHomeStars />,
  messages.hiddenTitle,
);
