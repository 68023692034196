import _ from 'lodash';
import PropTypes from 'prop-types';
import { compose, setPropTypes, defaultProps, branch, renderNothing, mapProps } from 'recompose';
import withEffects from '../../components/recompose/withEffects';
import { loadingUntil } from '../../components/recompose/loadingUntil';
import withSdkHandlers from './sdkHandlers';

import * as BookingType from '../../model/enum/bookingType';
import BookButton from '../../storybook/src/components/atoms/BookButton/BookButton';
import LoaderComponent from './loaderComponent';
import { withStateFetchers } from '../../api/injectApi/withStateFetchers';

const BookButtonBySdk = mapProps(
  ({ onClick, btnClassName, scheduleBtn, availability }) => ({
    btnClassName,
    scheduleButton: scheduleBtn,
    bookingType: _.get(availability, 'bookingType'),
    onClick,
  }),
)(BookButton);

BookButtonBySdk.propTypes = {
  onClick: PropTypes.func.isRequired,
  btnClassName: PropTypes.string,
  scheduleBtn: PropTypes.bool,
  availability: PropTypes.object.isRequired,
};

BookButtonBySdk.defaultProps = {
  btnClassName: undefined,
  scheduleBtn: false,
};

export default compose(
  setPropTypes({
    targetNpi: PropTypes.string.isRequired,
    targetLocationAddress: PropTypes.string.isRequired,
    bookingType: PropTypes.string,
    targetLocationGeo: PropTypes.object,
    targetLocationPhone: PropTypes.object,
    onBookClicked: PropTypes.func,
    language: PropTypes.string,
    member: PropTypes.object,
    code: PropTypes.string,
    onSuccess: PropTypes.func,
    showBookingDialogOnMount: PropTypes.bool,
    onShowBookingDialogSuccess: PropTypes.func,
    correlationId: PropTypes.string,
  }),
  defaultProps({
    onBookClicked: null,
    targetLocationGeo: null,
    targetLocationPhone: null,
    language: null,
    member: undefined,
    code: undefined,
    bookingType: undefined,
    onSuccess: _.noop,
    showBookingDialogOnMount: false,
    onShowBookingDialogSuccess: undefined,
    correlationId: undefined,
  }),
  branch(
    ({ targetNpi, targetLocationAddress }) =>
      _.isEmpty(targetNpi) || _.isEmpty(targetLocationAddress),
    renderNothing,
  ),
  withSdkHandlers,
  withEffects({
    onCodeChangeEffect: {
      action: ({ getProviderAvailability }) => getProviderAvailability(),
      didMountPredicate: () => true,
      didUpdatePredicate: (props, prevProps) =>
        props.code !== prevProps.code ||
        props.targetLocationAddress !== prevProps.targetLocationAddress,
    },
  }),
  loadingUntil(
    ({ getProviderAvailabilityTracker }) => !getProviderAvailabilityTracker.inProgress,
    LoaderComponent,
  ),
  branch(
    ({ availability }) => _.get(availability, 'bookingType', BookingType.NONE) === BookingType.NONE,
    renderNothing,
  ),
  withStateFetchers({
    onClick: {
      handler: ({ showBookingDialog, onBookClicked }) => params => {
        if (onBookClicked) onBookClicked(params);
        return showBookingDialog(params);
      },
    },
  }),
)(BookButtonBySdk);
