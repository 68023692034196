import _ from 'lodash';
import { safeLocalStorage } from './localStorage';

export function getFromReduxStorage(path, defaultValue) {
  const localRedux = safeLocalStorage.getItem('redux-storage');

  try {
    const parsed = JSON.parse(localRedux);
    return _.get(parsed, path, defaultValue);
  } catch (e) {
    return _.get(localRedux, path, defaultValue);
  }
}
