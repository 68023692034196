/**
 * Created by meirshalev on 18/10/2017.
 */

import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router';

import actionTracker from '../../../../store/tools/actionTracker/actionTrackerComponent';
import ThreeBounceSpinner from '../../../../components/ui/spinner/threeBounceSpinner';
import { ssoSignin, SSO_SIGNIN } from '../../../../store/sso/actions';

/**
 * This component is the entrypoint for users that logs in from other sites using SSO
 * (the other site being the IdP and us being the SP).
 * This component assumes that a SAML assertion was inserted to the state,
 * and, on mount, submits the assertion to the server to receive the login and refresh tokens.
 * The assertion should be encrypted by the SP that produced it and will only be decrypted in the
 * server. When the component is unmounted, the assertion is deleted from the state.
 */
class SSOEntrypoint extends React.Component {
  static propTypes = {
    assertion: PropTypes.string,
    ssoSignin: PropTypes.func.isRequired,
    ssoSigninTracking: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
  };

  static defaultProps = {
    assertion: undefined,
  };

  componentDidMount() {
    const { ssoSigninTracking } = this.props;
    if (!ssoSigninTracking.inProgress && !ssoSigninTracking.finished) {
      this.props.ssoSignin({ SAMLResponse: this.props.assertion });
    }
  }

  componentWillReceiveProps(nextProps) {
    const { ssoSigninTracking } = nextProps;
    if (ssoSigninTracking.finished) {
      // if finished successfully, the tokens were stored an the user got logged-in.
      // if the tokens weren't store, the user will get to to sign-in page.
      if (ssoSigninTracking.hasError) {
        switch (ssoSigninTracking.error.status) {
          case 404:
            this.props.router.replace('/start/ssoActivation');
            break;
          default:
            this.props.router.replace('/secure');
        }
      } else {
        this.props.router.replace('/secure');
      }
    }
  }

  render() {
    return <ThreeBounceSpinner />;
  }
}

export default compose(
  connect(
    () => ({
      assertion: _.get(window, '__SAML_RESPONSE.sso'),
    }),
    {
      ssoSignin,
    },
  ),
  actionTracker({
    ssoSigninTracking: SSO_SIGNIN.SOURCE,
  }),
  withRouter,
)(SSOEntrypoint);
