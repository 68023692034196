import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Layout from '../layout';
import BlueHeader from '../blueHeader/blueHeader';
import { MessageShape } from '../../../../../components/ui/intlString';

class CardListLayout extends React.PureComponent {
  static propTypes = {
    blueHeaderClass: PropTypes.string,
    mainIconSrc: PropTypes.string,
    mainSvgComp: PropTypes.object,
    listClassName: PropTypes.string,
    title: MessageShape,
    active: PropTypes.string,
  };

  static defaultProps = {
    blueHeaderClass: undefined,
    mainIconSrc: undefined,
    mainSvgComp: undefined,
    listClassName: undefined,
    title: undefined,
    active: '',
  };

  render() {
    const {
      listClassName,
      blueHeaderClass,
      mainIconSrc,
      mainSvgComp,
      title,
      children,
      active,
    } = this.props;

    return (
      <Layout active={active}>
        <div>
          <BlueHeader
            blueHeaderClass={blueHeaderClass}
            mainIconSrc={mainIconSrc}
            mainSvgComp={mainSvgComp}
            title={title}
          />
          <div className="container container-1040">
            <div className="row no-margin">
              <div className="col-xs-12">
                <div className={classNames('cards-list', listClassName)}>{children}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="content-grey-bg" />
      </Layout>
    );
  }
}

export default CardListLayout;
