import React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import moment from 'moment';
import InformationalBannerComponent from './InformationalBannerComponent';
import './styles.less';
import withFeatureFlag from 'Utils/featureFlags/withFeatureFlag';
import * as FlagTypes from 'Utils/featureFlags/flagTypes';
import { isLocalStorageAccessible } from 'Store/platform/selectors';
import { testLocalStorage } from 'Store/platform/actions';
import { safeLocalStorage } from 'Utils/storage/localStorage';

const INFO_CLOSED_TILL = 'INFO_CLOSED_TILL';

function closeToEndOfDay() {
  const now = moment();
  safeLocalStorage.setItem(INFO_CLOSED_TILL, now.endOf('day').valueOf());
}

function getClosedToo() {
  try {
    const closedToo = safeLocalStorage.getItem(INFO_CLOSED_TILL) || 0;
    return parseInt(closedToo);
  } catch (e) {
    return 0;
  }
}

@autobind
class InformationalBanner extends React.PureComponent {
  static propTypes = {
    bannerItems: PropTypes.string.isRequired,
    testLocalStorage: PropTypes.func.isRequired,
    isLocalStorageAccessible: PropTypes.bool.isRequired,
  };
  state = { showBanner: false, items: [] };
  onCloseBanner() {
    this.setState({ items: [], showBanner: false });
    closeToEndOfDay();
  }
  componentWillMount() {
    this.props.testLocalStorage();
  }
  componentDidMount() {
    if (this.props.bannerItems) {
      const now = moment().valueOf();
      const closedToo = getClosedToo();
      const items = this.props.bannerItems.split('//');
      if (items.length && closedToo <= now) {
        this.setState({ items, showBanner: true });
      }
    }
  }

  render() {
    const { items, showBanner } = this.state;
    const { children } = this.props;
    return (
      <div className={showBanner ? 'informational-banner__show' : ''}>
        {!!showBanner && (
          <InformationalBannerComponent items={items} onClose={this.onCloseBanner} />
        )}
        {children}
      </div>
    );
  }
}

export default compose(
  withFeatureFlag({
    key: 'premera.informationalBanner',
    prop: 'bannerItems',
    defaultValue: '',
    type: FlagTypes.String,
  }),
  connect(
    (state) => ({
      isLocalStorageAccessible: isLocalStorageAccessible(state),
    }),
    { testLocalStorage },
  ),
)(InformationalBanner);
