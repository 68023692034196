import _ from 'lodash';
import Joi from 'joi-browser';
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { defineMessages, FormattedMessage } from 'react-intl';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import { autobind } from 'core-decorators';

import { SpinnerButton } from '../ui/spinner';
import { createValidator } from '../../utils/joiValidator';
import SvgCodeStars from '../../assets/svg/codeStars';
import Recaptcha from './bkmdRecaptcha';
import { resetRecaptch } from '../../store/recaptcha/actions';

import { VerificationCodeSchema } from '../../utils/commonSchema';
import { ReduxFormCleanPositiveInteger } from '../form/reduxForm/components';

import './verification.less';

export const FORM_NAME = 'verification';

const messages = defineMessages({
  enterYourCode: {
    defaultMessage: 'Enter your verification code',
    id: 'verification.verificationCode.enterYourCode',
  },
  weSentItTo: {
    defaultMessage: 'We sent it to',
    id: 'verification.verificationCode.weSentItTo',
  },
  yourCode: {
    defaultMessage: 'Your verification code',
    id: 'verification.verificationCode.yourCode',
  },
  confirmBtn: {
    defaultMessage: 'Confirm',
    id: 'verification.verificationCode.confirmBtn',
  },
  resendMyCode: {
    defaultMessage: 'Resend my code',
    id: 'verification.verificationCode.resendMyCode',
  },
});

const schema = {
  verificationCode: VerificationCodeSchema.required(),
};

export const fields = {
  verificationCode: {
    name: 'verificationCode',
    component: ReduxFormCleanPositiveInteger,
  },
};

@autobind
class VerificationCode extends React.Component {
  static propTypes = {
    /**
     * redux-forms initialize function
     */
    initialize: PropTypes.func,
    /**
     * redux-forms form submission method.
     * Provided by redux-forms HoC
     */
    handleSubmit: PropTypes.func.isRequired,
    /**
     * Function for resending the verification code
     */
    resendCode: PropTypes.func.isRequired,
    /**
     * The verification code value filled out in this form
     */
    verificationCode: PropTypes.string,
    /**
     * True if form is currently submitting
     */
    loading: PropTypes.bool,
    /**
     * The identifier - phone number or email
     */
    identifier: PropTypes.object.isRequired,
    /**
     * Should the form include a Recaptcha validation
     */
    withRecaptcha: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    verificationCode: undefined,
    loading: false,
    withRecaptcha: true,
  };

  constructor(props) {
    super(props);

    const { verificationCode } = props;
    if (verificationCode) {
      this.clearVerificationCode();
    }
  }

  clearVerificationCode() {
    const { initialize } = this.props;
    initialize({ verificationCode: undefined });
  }

  resendCode() {
    const { resendCode, identifier, withRecaptcha, dispatch } = this.props;
    resendCode(identifier);
    if (withRecaptcha) dispatch(resetRecaptch());
  }

  render() {
    const { handleSubmit, verificationCode, loading, identifier, withRecaptcha } = this.props;
    return (
      <div className="activation-verification-code-wrap">
        <div className="top-section">
          <div className="row">
            <div className="col-xs-12 text-center">
              <SvgCodeStars />
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 text-center">
              <h4 className="title-24 md-margin text-strong font-color-brand-main">
                <FormattedMessage {...messages.enterYourCode} />
              </h4>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 text-center">
              <span className="text-18 text-light">
                <FormattedMessage {...messages.weSentItTo} />
              </span>
            </div>
          </div>
          &nbsp;
          <div className="row">
            <div className="col-xs-12 text-center">
              <div className="text-18 text-semibold">{identifier}</div>
            </div>
          </div>
        </div>

        <form onSubmit={handleSubmit}>
          <div className="row margin-top-30">
            <div className="col-xs-12">
              <Field
                inputClassName="margin-45"
                label={messages.yourCode}
                maxLength="6"
                {...fields.verificationCode}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-xs-12">
              <SpinnerButton
                className="btn btn-big bg-color-brand-button"
                type="submit"
                disabled={_.isEmpty(verificationCode)}
                isLoading={loading}
              >
                <FormattedMessage {...messages.confirmBtn} />
              </SpinnerButton>
            </div>
          </div>
        </form>

        <div className="row">
          <div className="col-xs-12 text-center text-14 margin-top-5 margin-45 margin-bottom-45">
            <button
              className="btn btn-small btn-outline xs-block btn-max-300"
              onClick={this.resendCode}
              type="button"
            >
              <FormattedMessage {...messages.resendMyCode} />
            </button>
          </div>
        </div>

        <div className={classNames('row', { hidden: !withRecaptcha })}>
          <div className="col-xs-12 text-center text-14 margin-top-5">
            <Recaptcha />
          </div>
        </div>
      </div>
    );
  }
}

const fieldSelector = formValueSelector(FORM_NAME);
export default compose(
  reduxForm({
    form: FORM_NAME,
    validate: createValidator(Joi.object().keys(schema)),
    destroyOnUnmount: false,
  }),
  connect(state => ({
    verificationCode: fieldSelector(state, fields.verificationCode.name),
    state,
  })),
)(VerificationCode);
