/**
 * Created by chenrozenes on 01/09/2016.
 */

import _ from 'lodash';
import { startMark, successMark, errorMark } from './actions';

const defaultTypes = ['PENDING', 'FULFILLED', 'REJECTED'];

/**
 * Action Tracker middleware for catching the promise actions and dispatching corresponding
 * actions to the action tracker reducer
 * @param config
 * @returns {function(): function(): function()}
 */
export default (config = {}) => {
  let typesSuffixes = defaultTypes;
  if (config.promiseTypeSuffixes) {
    typesSuffixes = config.promiseTypeSuffixes;
  }

  const START = `_${typesSuffixes[0]}`;
  const SUCCESS = `_${typesSuffixes[1]}`;
  const ERROR = `_${typesSuffixes[2]}`;

  return ({ dispatch }) => next => action => {
    const identifier = _.get(action, 'meta.tracker');

    // we want the all the middlewares to run before dispatch
    next(action);

    if (identifier) {
      if (_.endsWith(action.type, START)) {
        dispatch(startMark(identifier, action.meta));
      } else if (_.endsWith(action.type, SUCCESS)) {
        dispatch(successMark(identifier, action.meta));
      } else if (_.endsWith(action.type, ERROR)) {
        dispatch(errorMark(identifier, action.payload, action.meta));
      }
    }
  };
};
