import loadingPage from 'Components/ui/loadingPage/loadingPage';
import iconsDemo from 'Assets/fonts/iconsDemo/iconsDemo';
import styleGuide from 'Assets/styleGuide/styleGuide';
import ssoEntrypoint from '../auth/ssoEntrypoint';
import MemberValidation from '../auth/memberValidation';

// @TODO: Copied from previous implementation. Need to reorganize ASAP
export default [
  {
    path: 'LoadingPage',
    name: 'LoadingPage',
    component: loadingPage.default || loadingPage,
  },
  {
    path: 'iconsDemo',
    name: 'iconsDemo',
    component: iconsDemo.default || iconsDemo,
  },
  {
    path: 'styleGuide',
    name: 'styleGuide',
    component: styleGuide.default || styleGuide,
  },
  {
    path: 'ssoEntrypoint',
    name: 'ssoEntrypoint',
    component: ssoEntrypoint.default || ssoEntrypoint,
  },
  {
    path: 'auth',
    name: 'auth',
    component: MemberValidation.default || MemberValidation,
  },
];
