import { compose, branch, renderComponent } from 'recompose';
import { connect } from 'react-redux';

import withFeatureFlag from '../../../../../utils/featureFlags/withFeatureFlag';
import { domain as domainSelector } from '../../../../../store/config/selectors';
import { memberContext } from '../../../../../store/member/selectors';
import NotificationsPage from './notificationsPage';
import RefactoredNotificationsPage from './refactored/notificationsPage';

export default compose(
  connect(state => ({
    domain: domainSelector(state),
    memberContext: memberContext(state),
  })),
  withFeatureFlag({
    key: `members.newNotificationPage`,
    prop: 'newNotificationPage',
    defaultValue: false,
    context: ({ domain, memberContext }) => ({ domain, memberContext }),
  }),
  branch(
    ({ newNotificationPage }) => newNotificationPage,
    renderComponent(RefactoredNotificationsPage),
  ),
)(NotificationsPage);
