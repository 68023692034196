/**
 * Created by guyavarham on 20/04/2017.
 */

import { defineMessages } from 'react-intl';

const messages = defineMessages({
  findADoctor: {
    defaultMessage: 'Find a doctor',
    id: 'members.searchDoctors.findADoctor',
  },
  findADoctorInYourNetwork: {
    defaultMessage: 'Find a doctor in your network',
    id: 'members.searchDoctors.findADoctorInYourNetwork',
  },
  yourPlan: {
    defaultMessage: 'Your plan',
    id: 'members.searchDoctors.yourPlan',
  },
  location: {
    defaultMessage: 'Location',
    id: 'members.searchDoctors.location',
  },
  search: {
    defaultMessage: 'Search',
    id: 'members.searchDoctors.search',
  },
  insurancePlaceholder: {
    defaultMessage: 'Choose your insurance plan',
    id: 'members.searchDoctors.insurancePlaceholder',
  },
});

export default messages;
