/**
 * Created by meirshalev 22/08/2017.
 */
import Joi from 'joi-browser';
import { ReduxFormCleanSwitch } from '../../../../../../components/form/reduxForm/components';

export const name = 'notifications';

export const fields = {
  SmsMessages: {
    id: 'SmsMessages',
    name: 'SmsMessages',
    component: ReduxFormCleanSwitch,
  },
  financialAndHealthUpdatesEmails: {
    id: 'financialAndHealthUpdatesEmails',
    name: 'financialAndHealthUpdatesEmails',
    component: ReduxFormCleanSwitch,
  },
  appointmentInfoEmails: {
    id: 'appointmentInfoEmails',
    name: 'appointmentInfoEmails',
    component: ReduxFormCleanSwitch,
  },
  accountRemindersEmails: {
    id: 'accountRemindersEmails',
    name: 'accountRemindersEmails',
    component: ReduxFormCleanSwitch,
  },
  newsTipsPromotionsEmails: {
    id: 'newsTipsPromotionsEmails',
    name: 'newsTipsPromotionsEmails',
    component: ReduxFormCleanSwitch,
  },
};

export const schema = {
  SmsMessages: Joi.boolean(),
  financialAndHealthUpdatesEmails: Joi.boolean(),
  appointmentInfoEmails: Joi.boolean(),
  accountRemindersEmails: Joi.boolean(),
  newsTipsPromotionsEmails: Joi.boolean(),
};
