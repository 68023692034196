/**
 * Created by anna on 06/08/2017.
 */
import PromiseActionType from '../promiseActionType';

export const GET_REVIEWS_BY_NPI = new PromiseActionType('GET_REVIEWS_BY_NPI');
export const GET_REVIEW_BY_NPI = new PromiseActionType('GET_REVIEW_BY_NPI');

export function getReviewsByNpiAndLocation(npi, locationId) {
  return ({ bkmdApi: { feedbackSurveyApi } }) => ({
    type: GET_REVIEWS_BY_NPI.SOURCE,
    payload: {
      promise: feedbackSurveyApi.getAllReviewsShown(npi, locationId),
    },
    meta: {
      tracker: GET_REVIEWS_BY_NPI.SOURCE,
    },
  });
}
