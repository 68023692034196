/**
 * Created by anna on 06/06/2017.
 */
import PropTypes from 'prop-types';

const geoPoint = {
  latitude: PropTypes.number,
  longitude: PropTypes.number
};

export default geoPoint;
