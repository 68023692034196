import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { compose, defaultProps, setPropTypes, branch } from 'recompose';
import { defineMessages, FormattedMessage } from 'react-intl';

import { withTranslatedMessages } from '../../utils/withTranslatedMessages';
import { PrivacyPolicyLink, TosLink } from '../ui/links/index';
import BaseLink from '../ui/links/baseLink';

const messages = defineMessages({
  byClickingIAgree: {
    defaultMessage: 'By clicking "{activateBtnText}", you agree to Vim\'s',
    id: 'tos.premera.byClickingIAgree',
  },
  and: {
    defaultMessage: 'and',
    id: 'tos.premera.and',
  },
  premeraTos: {
    defaultMessage: 'Texting Terms and Conditions',
    id: 'tos.premeraTos.premeraTos',
  },
  premera: {
    defaultMessage: 'Premera`s',
    id: 'tos.premeraTos.premera',
  },
  activateText: {
    defaultMessage: 'Activate my account',
    id: 'tos.premeraTos.activateText',
  },
});

const PremeraBlueCrossTermsOfService = ({ isMember, hideAgreeMessage, activateText }) => (
  <div className="row">
    <div className="col-xs-12 text-12" aria-labelledby="new-window-0">
      {hideAgreeMessage ? null : (
        <FormattedMessage
          {...messages.byClickingIAgree}
          values={{ activateBtnText: activateText }}
        />
      )}
      &nbsp;
      <TosLink className="underline-link" isMember={isMember} />
      &nbsp;
      <i
        className="icon-ext-link text-12 i-va-fix-2"
        id="new-window-0"
        aria-label="external-link"
      />
      &nbsp;
      <FormattedMessage {...messages.and} />
      &nbsp;
      <PrivacyPolicyLink
        aria-labelledby="new-window-1"
        className="underline-link"
        isMember={isMember}
      />
      &nbsp;
      <i
        className="icon-ext-link text-12 i-va-fix-2"
        id="new-window-1"
        aria-label="external-link"
      />
      &nbsp;
      <FormattedMessage {...messages.and} />
      &nbsp;
      <FormattedMessage {...messages.premera} />
      &nbsp;
      <BaseLink
        className="underline-link"
        url="https://premerapulse.getvim.com/terms-and-conditions/"
        label={messages.premeraTos}
        aria-labelledby="new-window-2"
      />
      &nbsp;
      <i
        className="icon-ext-link text-12 i-va-fix-2"
        id="new-window-2"
        aria-label="external-link"
      />
      <br />
    </div>
  </div>
);

PremeraBlueCrossTermsOfService.propTypes = {
  isMember: PropTypes.bool,
  hideAgreeMessage: PropTypes.bool,
  activateText: PropTypes.string.required,
};

PremeraBlueCrossTermsOfService.defaultProps = {
  hideAgreeMessage: false,
  isMember: false,
};

export default compose(
  setPropTypes({
    activateText: PropTypes.object,
  }),
  defaultProps({
    activateText: messages.activateText,
  }),
  branch(
    ({ activateText }) => !_.isString(activateText),
    withTranslatedMessages(({ activateText }) => ({ activateText })),
  ),
)(PremeraBlueCrossTermsOfService);
