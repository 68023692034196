/**
 * Created by meirshalev on 04/06/2017.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { autobind } from 'core-decorators';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import { withRouter } from 'react-router';
import Joi from 'joi-browser';

import { createValidator } from '../../../../../../utils/joiValidator';
import * as FormDef from './changeEmailDef';
import injectNotification from '../../../../../../store/notification/injectNotification';
import actionTracker from '../../../../../../store/tools/actionTracker/actionTrackerComponent';

import { paths } from '../../paths';
import messages from './messages';
import commonMessages from '../../../../../../store/intl/commonMessages';
import { SpinnerButton } from '../../../../../../components/ui/spinner/index';
import Layout from '../../../layout/layout';
import SettingsLayout from '../../settingsLayout';
import SvgEnvelopeStars from '../../../../../../assets/svg/envelopeStars';

import {
  CHANGE_EMAIL,
  changeEmail,
  resendEmailVerification,
  RESEND_EMAIL_VERIFICATION,
} from '../../../../../../store/user/actions';
import * as Errors from '../../../../../../api/errors';

const emailAlreadyTakenMessage = ' ';

@autobind
class ChangeEmailPage extends React.Component {
  static propTypes = {
    /**
     * Injected prop for notifying on errors
     */
    notification: PropTypes.object.isRequired,
    /**
     * Server request action tracking.
     */
    changeEmailTracking: PropTypes.object.isRequired,
    /**
     * Server request action tracking.
     */
    regenerateEmailVerificationTracking: PropTypes.object.isRequired,
    /**
     * Redux action creator
     */
    changeEmail: PropTypes.func.isRequired,
    /**
     * Redux action creator
     */
    regenerateEmailVerification: PropTypes.func.isRequired,
    /**
     * Current email of the member, injected by fetchProfile.
     */
    email: PropTypes.string.isRequired,
    /**
     * Specifies whether or not the current email address has been verified.
     */
    emailVerified: PropTypes.bool.isRequired,
    /**
     * The email address entered to this form.
     */
    enteredEmail: PropTypes.string.isRequired,
    /**
     * Injected by redux-form HOC.
     */
    handleSubmit: PropTypes.func.isRequired,
    /**
     * Injected by withRouter
     */
    router: PropTypes.object.isRequired,
  };

  componentWillReceiveProps(nextProps) {
    const { changeEmailTracking, regenerateEmailVerificationTracking } = nextProps;
    if (changeEmailTracking.finished) this.handleChangeResponse(changeEmailTracking);
    if (regenerateEmailVerificationTracking.finished) {
      this.handleVerifyResponse(regenerateEmailVerificationTracking);
    }
  }

  handleErrors(error) {
    const { responseMessage } = error;

    switch (responseMessage) {
      case Errors.USER_ALREADY_EXIST: {
        this.props.notification.error(messages.emailAlreadyTakenTitle, emailAlreadyTakenMessage, {
          autoDismiss: 10,
        });
        break;
      }
      case Errors.INVALID_PASSWORD: {
        this.props.notification.error(messages.invalidPasswordTitle, ' ', { autoDismiss: 10 });
        break;
      }
      default: {
        this.props.notification.error(messages.serverErrorTitle, messages.serverErrorMessage, {
          autoDismiss: 10,
        });
      }
    }
  }

  handleResponse(tracking, successTitle, successMessage) {
    if (tracking.hasError) {
      this.handleErrors(tracking.error);
    } else {
      this.props.notification.success(successTitle, successMessage, { autoDismiss: 10 });
      this.props.router.push(paths.profile());
    }
  }

  handleVerifyResponse(tracking) {
    this.handleResponse(tracking, ' ', messages.verifyEmailSuccessMessage);
  }

  handleChangeResponse(tracking) {
    this.handleResponse(tracking, ' ', messages.updateEmailSuccessMessage);
  }

  isModified() {
    return this.props.email !== this.props.enteredEmail;
  }

  isUpdate() {
    return this.isModified() || this.props.emailVerified;
  }

  updateEmail({ email, password }) {
    this.props.changeEmail(email, password);
  }

  generateVerificationCode({ email }) {
    this.props.regenerateEmailVerification(email);
  }

  render() {
    const {
      handleSubmit,
      emailVerified,
      changeEmailTracking,
      regenerateEmailVerificationTracking,
    } = this.props;

    const onSubmit = this.isModified()
      ? handleSubmit(this.updateEmail)
      : handleSubmit(this.generateVerificationCode);

    return (
      <Layout
        hideLogo
        centeredBackBtn
        previousPageName="your profile"
        previousPageRoute={paths.profile()}
      >
        <SettingsLayout>
          <div className="text-center margin-top-20 margin-bottom-clean-form">
            <SvgEnvelopeStars />
          </div>
          <form onSubmit={onSubmit} method="post">
            <div className="row">
              <div className="col-xs-12">
                <Field
                  label={messages.yourEmail}
                  {...FormDef.fields.email}
                  type="email"
                  inputClassName="no-margin"
                  id="email"
                />
              </div>
            </div>

            <div className="row margin-top-5">
              <div className="col-xs-12">
                <span className="text-16 text-success">
                  {emailVerified && !this.isModified() ? (
                    <div className="text-12 text-success">
                      <FormattedMessage {...commonMessages.verified} />
                    </div>
                  ) : (
                    <div className="text-12 text-danger">
                      <FormattedMessage {...commonMessages.unverified} />
                    </div>
                  )}
                </span>
              </div>
            </div>

            {this.isUpdate() ? (
              <div className="row margin-top-30">
                <div className="col-xs-12">
                  <Field label={messages.password} id="password" {...FormDef.fields.password} />
                  <Field type="hidden" {...FormDef.fields.existingEmail} />
                </div>
              </div>
            ) : (
              <div className="row margin-top-30">
                <div className="col-xs-12 text-16">
                  <FormattedMessage {...messages.weSentYouAVerificationEmail} />
                </div>
              </div>
            )}
            <div className="row margin-top-30">
              <div className="col-xs-12">
                <SpinnerButton
                  className="btn btn-big bg-color-brand-button"
                  type="submit"
                  isLoading={
                    this.isUpdate()
                      ? changeEmailTracking.inProgress
                      : regenerateEmailVerificationTracking.inProgress
                  }
                  disabled={
                    !this.isModified() && this.isUpdate() // disable if this is an update button
                    // but the email hasn't been modified.
                  }
                >
                  {this.isUpdate() ? (
                    <FormattedMessage {...messages.updateEmail} />
                  ) : (
                    <FormattedMessage {...messages.verifyEmail} />
                  )}
                </SpinnerButton>
              </div>
            </div>
          </form>
        </SettingsLayout>
      </Layout>
    );
  }
}

const fieldSelector = formValueSelector(FormDef.name);
export default compose(
  withRouter,
  connect(
    state => ({
      enteredEmail: fieldSelector(state, FormDef.fields.email.name),
      initialValues: {
        [FormDef.fields.email.name]: state.auth.user.email,
        [FormDef.fields.existingEmail.name]: state.auth.user.email,
      },
    }),
    {
      changeEmail,
      regenerateEmailVerification: resendEmailVerification,
    },
  ),
  reduxForm({
    form: FormDef.name,
    validate: createValidator(Joi.object().keys(FormDef.schema), FormDef.customValidator),
  }),
  injectNotification,
  actionTracker({
    changeEmailTracking: CHANGE_EMAIL.SOURCE,
    regenerateEmailVerificationTracking: RESEND_EMAIL_VERIFICATION.SOURCE,
  }),
)(ChangeEmailPage);
