/**
 * created by moran on 11/26/17
 */
/* eslint-disable import/first */

if (typeof require.ensure !== 'function') require.ensure = (d, c) => c(require);
import AppointmentPage from './appointmentPage';
import AppointmentActivationLanding from './appointmentActivationLanding';
import AppointmentDetails from './booked/bookedApptDetails';
import CancelAppointment from './cancel/cancelAppointment';
import CancelledAppointment from './cancel/cancelledAppointment';
import DeclineCancellationPage from './cancel/declineAppointmentCancellation';
import paths from './memberAppointmentsPaths';

const Components = [
  {
    path: paths.landing(),
    name: 'landing',
    component: AppointmentActivationLanding.default || AppointmentActivationLanding,
  },
  {
    path: paths.cancelled(),
    name: 'canceled',
    component: CancelledAppointment.default || CancelledAppointment,
  },
  {
    path: paths.declineCancellation(),
    name: 'declineCancellation',
    component: DeclineCancellationPage.default || DeclineCancellationPage,
  },
  {
    path: paths.appointmentDetails(),
    name: 'appointmentDetails',
    component: AppointmentDetails.default || AppointmentDetails,
  },
  {
    path: paths.cancel(),
    name: 'cancel',
    component: CancelAppointment.default || CancelAppointment,
  },
];

export default [
  {
    path: 'appointment',
    name: 'appointment',
    getChildRoutes(location, cb) {
      require.ensure([], () => {
        cb(null, Components);
      });
    },
    component: AppointmentPage.default || AppointmentPage,
  },
];
/* eslint-enable import/first */
