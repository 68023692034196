import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { compose } from 'redux';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { defineMessages, FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { autobind } from 'core-decorators';
import AppointmentDetailsList from '../appointmentDetailsList';
import SvgCalendarFold from '../../../../../assets/svg/calendarFold';
import SimpleProgressPage from '../../../../../components/ui/layouts/simpleProgressPage';
import { isDirectoryAllowed } from '../../../../../store/ui/selectors';

const messages = defineMessages({
  stillBooked: {
    defaultMessage: "You're still booked",
    id: 'members.appointment.stillBooked.header',
  },
  toReschedule: {
    defaultMessage: 'To reschedule, please cancel and book <br> a new appointment',
    id: 'members.appointment.stillBooked.text',
  },
  signIn: {
    defaultMessage: 'Sign in',
    id: 'members.appointment.stillBooked.signIn',
  },
});

@autobind
class DeclineAppointmentCancellation extends React.Component {
  static propTypes = {
    appointment: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
    signInAllowed: PropTypes.bool.isRequired,
  };

  goToSignIn() {
    this.props.router.push('/signin');
  }

  renderSignIn() {
    const { signInAllowed } = this.props;

    return signInAllowed ? (
      <div className="row">
        <div className="col-xs-12">
          <button className="btn btn-big" onClick={this.goToSignIn}>
            <FormattedMessage {...messages.signIn} />
          </button>
        </div>
      </div>
    ) : null;
  }

  render() {
    const { appointment } = this.props;

    return (
      <SimpleProgressPage title="" progress={null} caption="" img="" showRightNavInMobile>
        <div className="cancel-appointment-flow">
          <div className="top-section text-center">
            <SvgCalendarFold />
            <div className="title-24 md-margin font-color-brand-main">
              <strong>
                <FormattedMessage {...messages.stillBooked} />
              </strong>
            </div>
            <div className="text-18">
              <FormattedHTMLMessage {...messages.toReschedule} />
            </div>
          </div>
          <AppointmentDetailsList appointment={appointment} />
          {this.renderSignIn()}
        </div>
      </SimpleProgressPage>
    );
  }
}

export default compose(
  withRouter,
  connect((state, props) => ({
    appointment: _.get(state, `appointments.${props.params.appointmentId}`),
    signInAllowed: isDirectoryAllowed(state),
  })),
)(DeclineAppointmentCancellation);
