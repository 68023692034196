import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { getContext, withPropsOnChange, defaultProps } from 'recompose';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import withFeatureFlag from '../../../../../../utils/featureFlags/withFeatureFlag';
import messages from '../../../../../../messages/doctorDetails/messages';
import {
  createToggleState,
  createToggleStatePropTypes,
} from '../../../../../../components/recompose/toggleStateComponent';
import ShareDoctorModal from '../share/shareDoctorModal';
import { isPublicDirectory } from '../../../../../../store/config/selectors';
import { getFeature } from '../../../../../../store/feature/selectors';
import BookingButton from '../../components/bookButton/bookButton';
import { member } from '../../../../../../store/member/selectors';
import * as NpiType from '../../../../../../model/enum/npiType';
import Formatter from '../../../../../../utils/formatter';

@autobind
class ActionButtons extends React.PureComponent {
  static propTypes = {
    showShare: PropTypes.bool.isRequired,
    provider: PropTypes.object.isRequired,
    pickedLocation: PropTypes.object.isRequired,
    onBookAppointmentClicked: PropTypes.func.isRequired,
    enableBooking: PropTypes.bool,
    showNewBookButton: PropTypes.bool,
    ...createToggleStatePropTypes('shareModal'),
  };

  static defaultProps = {
    enableBooking: true,
    showNewBookButton: false,
  };

  render() {
    const {
      provider,
      pickedLocation,
      enableBooking,
      shareModalOn,
      shareModalHide,
      shareModalShow,
      showShare,
      onBookAppointmentClicked,
      memberInfo,
      showNewBookButton,
      onShareSuccess,
      onBookSuccess,
    } = this.props;

    if (!pickedLocation) return null;

    return (
      <div className={classNames('action-buttons', showNewBookButton ? 'new-book-button' : '')}>
        <div className="row tiny-gutter centralize">
          {showShare ? (
            <div className="share-wrapper">
              <button className="btn btn-big btn-outline sm-pull-right" onClick={shareModalShow}>
                <i className="icon-ext-link" />
                &nbsp;
                <FormattedMessage {...messages.share} />
              </button>
            </div>
          ) : null}
          <div className="booking-btn-wrap">
            <BookingButton
              enableBooking={enableBooking}
              onBookAppointmentClicked={onBookAppointmentClicked}
              pickedLocation={pickedLocation}
              npiType={NpiType.INDIVIDUAL}
              targetNpi={_.get(provider, 'npi')}
              targetLocationAddress={Formatter.addressFormat(pickedLocation)}
              targetLocationGeo={pickedLocation.geo}
              targetLocationPhone={pickedLocation.phoneNumber}
              member={memberInfo}
              onShowBookingDialogSuccess={onBookSuccess}
            />
          </div>
        </div>
        <ShareDoctorModal
          isOpen={shareModalOn}
          handleClose={shareModalHide}
          provider={provider}
          providerLocationId={pickedLocation.id}
          onFinish={onShareSuccess}
        />
      </div>
    );
  }
}

export default compose(
  defaultProps({
    onShareSuccess: _.noop,
    onBookSuccess: _.noop,
  }),
  connect(state => ({
    // Share is relevant only if a user is involved
    memberInfo: member(state),
    showShare: !isPublicDirectory(state) && getFeature(state, 'results.doctorCard.share'),
  })),
  withPropsOnChange(
    ['pickedLocation'],
    ({ pickedLocation: { isBookingAvailable, bookingType } }) => ({
      isBookingAvailable,
      bookingType,
    }),
  ),
  getContext({ paths: PropTypes.object.isRequired }),
  createToggleState('shareModal'),
  withFeatureFlag({
    key: 'storybook.newBookButton',
    prop: 'showNewBookButton',
    defaultValue: false,
  }),
)(ActionButtons);
