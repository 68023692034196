/**
 * Created by orizis on 17/04/2018.
 */

import _ from 'lodash';
import Immutable from 'seamless-immutable';

import { HAS_DEPENDENTS, GET_DEPENDENTS, GET_PCP_SELECTION } from './actions';

const initialState = {
  hasDependents: false,
  dependents: { adults: null, guardians: null },
  selectedPcp: null,
};

export default function medicalTeamReducer(state = initialState, action) {
  state = Immutable(state);
  switch (action.type) {
    case HAS_DEPENDENTS.SUCCESS: {
      return state.merge(action.payload);
    }
    case GET_DEPENDENTS.START:
    case GET_DEPENDENTS.ERROR: {
      return state.merge({
        hasDependents: false,
        dependents: { adults: [], guardians: [] },
      });
    }
    case GET_DEPENDENTS.SUCCESS: {
      const { dependents: adults, guardianDependents: guardians } = action.payload;
      const dependents = { adults, guardians };
      return state.merge({
        hasDependents: !(_.isEmpty(adults) && _.isEmpty(guardians)),
        dependents,
      });
    }
    case GET_PCP_SELECTION.START:
    case GET_PCP_SELECTION.ERROR: {
      return state.merge({ selectedPcp: { npi: null, provider: {}, locationId: null } });
    }
    case GET_PCP_SELECTION.SUCCESS: {
      return state.merge({ selectedPcp: action.payload });
    }
    default:
      return state;
  }
}
