
/**
 * Created by guyavarham on 06/03/2017.
 */

import _ from 'lodash';
import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  filter: {
    defaultMessage: 'Filter',
    id: 'common.searchDoctorsResults.filter'
  },
  acceptsNewPatients: {
    defaultMessage: 'Accepts new patients',
    id: 'common.searchDoctorsResults.acceptsNewPatients'
  },
  inNetwork: {
    defaultMessage: 'In-network',
    id: 'common.searchDoctorsResults.inNetwork'
  },
  bestMatch: {
    defaultMessage: 'Relevance',
    id: 'common.searchDoctorsResults.bestMatch'
  },
  distance: {
    defaultMessage: 'Distance',
    id: 'common.searchDoctorsResults.distance'
  },
  wantToCheckYourDeductible: {
    defaultMessage: 'Want to check your deductible?',
    id: 'common.searchDoctorsResults.wantToCheckYourDeductible'
  },
  getUpToDatePlanInfo: {
    defaultMessage: 'Get up-to-date plan info.',
    id: 'common.searchDoctorsResults.getUpToDatePlanInfo'
  },
  goToYourPlan: {
    defaultMessage: 'Go to your plan',
    id: 'common.searchDoctorsResults.goToYourPlan'
  },
  shareThisListOfDoctors: {
    defaultMessage: 'Share this list of doctors with friends and family or send to yourself ' +
    'for later.',
    id: 'common.searchDoctorsResults.shareThisListOfDoctors'
  },
  shareThisList: {
    defaultMessage: 'Share this list',
    id: 'common.searchDoctorsResults.shareThisList'
  },
  any: {
    defaultMessage: 'Any',
    id: 'common.searchDoctorsResults.any'
  },
  male: {
    defaultMessage: 'Male',
    id: 'common.searchDoctorsResults.male'
  },
  female: {
    defaultMessage: 'Female',
    id: 'common.searchDoctorsResults.female'
  },
  mi5: {
    defaultMessage: '5 mi',
    id: 'common.searchDoctorsResults.mi5'
  },
  mi10: {
    defaultMessage: '10 mi',
    id: 'common.searchDoctorsResults.mi10'
  },
  mi20: {
    defaultMessage: '20 mi',
    id: 'common.searchDoctorsResults.mi20'
  },
  mi20plus: {
    defaultMessage: '20+ mi',
    id: 'common.searchDoctorsResults.mi20plus'
  },
  english: {
    defaultMessage: 'English',
    id: 'common.searchDoctorsResults.english'
  },
  spanish: {
    defaultMessage: 'Spanish',
    id: 'common.searchDoctorsResults.spanish'
  },
  chinese: {
    defaultMessage: 'Chinese',
    id: 'common.searchDoctorsResults.chinese',
  },
  mi: {
    defaultMessage: 'mi',
    id: 'common.searchDoctorsResults.miles',
  },
  treatsWomenLikeYou: {
    defaultMessage: 'Treats <span class="text-semibold">women like you</span>',
    id: 'common.searchDoctorsResults.treatsWomenLikeYou',
  },
  superHero: {
    defaultMessage: 'Super hero',
    id: 'common.searchDoctorsResults.superHero',
  },
  hero: {
    defaultMessage: 'Hero',
    id: 'common.searchDoctorsResults.hero',
  },
  share: {
    defaultMessage: 'Share',
    id: 'common.searchDoctorsResults.share',
  },
  unShare: {
    defaultMessage: 'Unshare',
    id: 'common.searchDoctorsResults.unShare',
  },
  call: {
    defaultMessage: 'Call',
    id: 'common.searchDoctorsResults.call',
  },
  sortBy: {
    defaultMessage: 'Sort by',
    id: 'common.searchDoctorsResults.sortBy',
  },
  pcpBannerTitle: {
    defaultMessage: 'Your primary care doctor is often the best place to start.',
    id: 'common.pcpBanner.title',
  },
  learnMore: {
    defaultMessage: 'Learn More',
    id: 'common.pcpBanner.learnMore',
  },
  primaryCare: {
    defaultMessage: 'Primary Care',
    id: 'common.searchDoctorsResults.primaryCare',
  },
  near: {
    defaultMessage: 'near',
    id: 'common.searchDoctorsResults.near',
  },
  sortedBy: {
    defaultMessage: 'Sorted by {value}',
    id: 'common.searchDoctorsResults.sortedBy',
  },
  details: {
    defaultMessage: 'Details',
    id: 'common.searchDoctorsResults.details',
  },
  openNow: {
    defaultMessage: 'Open now',
    id: 'common.searchDoctorsResults.openNow',
  },
  acceptingNewPatients: {
    defaultMessage: 'Accepting new<br /> patients',
    id: 'common.searchDoctorsResults.acceptingNewPatients',
  },
  book: {
    defaultMessage: 'Book',
    id: 'common.searchDoctorsResults.book',
  },
  shared: {
    defaultMessage: 'Shared',
    id: 'common.searchDoctorsResults.shared',
  },
  doctorSearchResults: {
    defaultMessage: 'doctor search results',
    id: 'common.searchDoctorsResults.doctorSearchResults',
  },
  phone: {
    defaultMessage: 'Phone: ',
    id: 'common.searchDoctorsResults.phone',
  },
  schedule: {
    defaultMessage: 'Schedule',
    id: 'common.searchDoctorsResults.schedule',
  },
});

export function getKeyForMsgId(msgId) {
  return _.findKey(messages, function compareMsgId(msg) {
    return _.isEqual(msg.id, msgId);
  });
}
