import { replace } from 'lodash';
import { SPANISH } from '../../store/intl/languages';

/**
 * Created by chenrozenes on 29/11/2015.
 */

const CommonFormats = {
  AM_PM_TIME: 'h:mm A', // 08:22 PM
  HOURS_24_TIME: 'HH:mm',
  HOURS_12_TIME: 'h:mm',
  HOURS_12_TIME_FULL: 'hh:mm A',
  AM_PM_UNITS: 'A',
  HOURS_AM_PM: 'h A',
  SHORT_NAMING_DATE: 'ddd, MMM D', // Sun, Jul 13
  SHORT_DAY_TIME: 'ddd, hh:mm A', // Sun, 01:30 PM
  NUMBERS_DATE_AND_TIME: 'MM/DD/YYYY h:mma',
  SHORT_NAMING_WITH_YEAR: 'ddd, MMM D, YYYY', // Sun, Jul 13, 2015
  LONG_NAMING_DATE: 'dddd, MMMM D', // Sunday, July 13
  LONG_NAMING_DATE_WITH_YEAR: 'dddd, MMMM D, YYYY', // Sunday, July 13, 2015
  FULL_NUMBERS_DATE: 'YYYY-MM-DD', // 2015-07-13
  MONTH_DAY_YEAR: 'MM/DD/YYYY',
  MONTH_DAY_SHORT_YEAR: 'MM/DD/YY',
  MONTH_DAY: 'MM/DD',
  SHORT_DATE: 'MMM D',
  SHORT_DATE_AND_TIME: 'MMM D, h:mm A', // Jul 13, 08:20 AM
  DATE_AND_TIME: 'dddd, MMMM D, h:mm A', // Sunday, July 13, 08:22 PM
  DATE_AND_TIME_SHORT_DAY: 'ddd, MMM D, h:mm A', // Sun, Jul 13, 08:22 PM
  FULL_DATE_AND_TIME: 'MMMM D, YYYY • hh:mm A', // July 13, 2016 • 08:22 PM
  FULL_TIME: 'HH:mm a',
  HALF_TIME: 'hh:mm a',
  DEFAULT_DATE_AND_TIME: 'YYYY-MM-DD HH:mm', // 2016-07-13 08:22
  DEFAULT_DATE_AND_DEFAULT_TIME: 'MM/DD/YY hh:mm A', // 12/12/18 2:58 PM
  APPOINTMENTS_DEFAULT_DATE_AND_DEFAULT_TIME: 'MM/DD/YYYY - h:mma', // 12/12/18 2:58 PM
  FULL_DATE: 'MMMM D, YYYY', // July 13, 2016
  FULL_NUMBERS_LOCAL_DATE: 'L', // 07/13/2015
  LONG_DATE_AND_TIME: 'MMM Do YY hh:mm A', // Nov 30th 15 02:03 PM
  NUMBER_TIME: 'Hmm', // 07:30 am -> 730 / 15:45 pm -> 1545
  SHORT_MONTH_DAY_YEAR_DATE: 'MMM D, YYYY', // Jul 13, 2015
  SHORT_MONTH_DAY_YEAR_TIME: 'MMM D, YYYY • h:mm A',
  SHORT_TIMEZONE: 'z',
  TIME_WITH_SECONDS: 'h:mm:ss A',
  DOLLAR_CURRENCY: '$0,0.00', // $220.00
  SHORT_DOLLAR_CURRENCY: '$0,0', // $220
  PERCENTAGE: '0%', // 80%
};

const map = {
  // moment spanish adds dot after the day, so we remove the comma
  [SPANISH]: (format) => replace(format, 'ddd,', 'ddd'),
};

export const getLocaledFormats = (format, locale) => {
  if (map[locale]) return map[locale](format);
  return format;
};

export default CommonFormats;
