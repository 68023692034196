import { getContext, withContext } from 'recompose';
import PropTypes from 'prop-types';

export const themes = {
  premera: {
    className: 'premera',
    fullName: 'premera',
    logo: require('../../../assets/images/logos/premeraLogo2.png'),
    // logoWebp: require('../../../assets/images/logos/webP/premeraLogo.webp'),
    font: 'loraregular , Helvetica, Arial, sans-serif',
    fontWeight: 'normal',
    mainColor: '#313234',
    secondaryColor: '#117bab',
    secondaryDarkColor: '#117bab',
    // @brand-color-secondary-darken: darken(saturate(@brand-color-secondary-dark, 30%), 10%);
    buttonColor: '#117bab',
    accentColor: '#e04e39',
    linkColor: '#117bab',
    successColor: '#278600',
    buttonBorderRadius: '4px',
  },
  sanitas: {
    className: 'sanitas',
    fullName: 'sanitas medical center',
    logo: require('../../../assets/images/logos/sanitasLogo.png'),
    // logoWebp: require('../../../assets/images/logos/webP/sanitasLogo.webp'),
    font: 'proximaNova , Helvetica, Arial, sans-serif',
    fontWeight: 'bold',
    mainColor: '#0f5499',
    secondaryColor: '#0091cb',
    secondaryDarkColor: '#0071B8',
    // @brand-color-secondary-darken: darken(saturate(@brand-color-secondary-dark, 30%), 10%);
    buttonColor: '#43b02a',
    accentColor: '#E74476',
    linkColor: '#1477D7',
    successColor: '#43b02a',
    buttonBorderRadius: '4px',
  },
  allsavers: {
    className: 'uhc',
    fullName: 'united healthcare',
    logo: require('../../../assets/images/logos/uhcLogo.png'),
    // logoWebp: require('../../../assets/images/logos/webP/uhcLogo.webp'),
    font: 'proximaNova , Helvetica, Arial, sans-serif',
    fontWeight: 'bold',
    mainColor: '#083a6b',
    secondaryColor: '#0071b8',
    secondaryDarkColor: '#0071b8',
    buttonColor: '#278600',
    accentColor: '#e74476',
    linkColor: '#0071b8',
    successColor: '#278600',
    buttonBorderRadius: '4px',
  },
  vim: {
    className: 'vim',
    fullName: 'vim',
    logo: require('../../../assets/images/logos/vimLogoMdTM.png'),
    // logoWebp: require('../../../assets/images/logos/webP/vimLogoMdTM.webp'),
    font: 'proximaNova , Helvetica, Arial, sans-serif',
    fontWeight: 'bold',
    mainColor: '#083a6b',
    secondaryColor: '#09ace4',
    secondaryDarkColor: '#09ace4',
    // @brand-color-secondary-darken: darken(saturate(@brand-color-secondary-dark, 30%), 10%);
    buttonColor: '#278600',
    accentColor: '#e74476',
    linkColor: '#09ace4',
    successColor: '#278600',
    buttonBorderRadius: '4px',
  },
  floridaBlue: {
    className: 'fb',
    fullName: 'florida blue',
    logo: require('../../../assets/images/logos/floridaBlueLogo.png'),
    // logoWebp: require('../../../assets/images/logos/webP/floridaBlueLogo.webp'),
    font: 'proximaNova , Helvetica, Arial, sans-serif',
    fontWeight: 'bold',
    mainColor: '#0f5499',
    secondaryColor: '#0091cb',
    secondaryDarkColor: '#0071B8',
    // @brand-color-secondary-darken: darken(saturate(@brand-color-secondary-dark, 30%), 10%);
    buttonColor: '#43b02a',
    accentColor: '#E74476',
    linkColor: '#1477D7',
    successColor: '#43b02a',
    buttonBorderRadius: '4px',
  },
};

export const ThemeProvider = withContext({ theme: PropTypes.object.isRequired }, ({ theme }) => ({
  theme,
}))(({ children }) => children);

export const withTheme = getContext({
  theme: PropTypes.object.isRequired,
});
