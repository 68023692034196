/**
 * Created by asafdavid on 18/01/2016.
 */

import _ from 'lodash';
import React from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { injectIntl, intlShape } from 'react-intl';
import { autobind } from 'core-decorators';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withPropsOnChange } from 'recompose';
import { IntlString, MessageShape } from '../../components/ui/intlString';
import analytics from '../../store/tools/analytics/analyticsHoc';
import messageShape from '../ui/intlString/messageShape';
import Formatter from '../../utils/formatter';
import { dropDownOpen } from '../../store/ui/actions';
import './cleanSelect.less';

export const toSelectOptions = enumm => {
  const enummm = _.isPlainObject(enumm) ? _.keys(enumm) : enumm;
  return _.map(_.toArray(enummm), x => ({ label: x, value: x }));
};

const SELECT_OPTION_CLASS_IDENTIFIER = 'Select-option';

@autobind
class CleanSelect extends React.PureComponent {
  // We are using react 15.6, which don't support createRef function.
  // that's why, we are using class inner propery.
  // eslint-disable-next-line react/sort-comp
  selectRef;

  static propTypes = {
    intl: intlShape.isRequired,
    options: PropTypes.array,
    /**
     * The css class name of the root element.
     */
    className: PropTypes.string,

    /**
     * The css class name of the input
     */
    selectClassName: PropTypes.string,

    /**
     * The css class name of the error
     */
    errorClassName: PropTypes.string,

    /**
     * The error content to display.
     */
    errorText: MessageShape,

    /**
     * The content to use for the floating label element.
     */
    label: messageShape,

    /**
     * Css class name of the label
     */
    labelClassName: PropTypes.string,

    /**
     * Css class name of the icon
     */
    iconClassName: PropTypes.string,

    /**
     * Callback function that is fired when the textfield's value changes.
     */
    onChanged: PropTypes.func,

    /**
     * The value of the text field.
     */
    value: PropTypes.any,

    /**
     * The id of the select component
     */
    id: PropTypes.string,

    /**
     * Redux function to update UI state when drop down is open or closed
     */
    dropDownOpen: PropTypes.func,
    onDropDownOpen: PropTypes.func,
    onDropDownClose: PropTypes.func,

    /**
     * React ref to react-select component
     */
    inputRef: PropTypes.any,

    placeholder: messageShape,

    /**
     * Flag to ignore onBlur event
     */
    ignoreOnBlur: PropTypes.bool,

    /**
     * Flag to make value change after options changed
     */
    hotOptions: PropTypes.bool,

    /**
     * If no value selected, scroll to that option
     * ex: We have hours from 00:00 to 23:55, and no time selected.
     * if we open the timeSelect scroll,
     * the scroll bar will be set to the option provided to "scrollToOption"
     */
    scrollToOption: PropTypes.string,

    /**
     * If false - the old "clean-input" class will be removed
     */
    isCleanInputOldStyle: PropTypes.bool,
  };

  static defaultProps = {
    options: [],
    className: undefined,
    errorClassName: undefined,
    selectClassName: undefined,
    errorText: undefined,
    label: undefined,
    labelClassName: undefined,
    iconClassName: undefined,
    onChanged: undefined,
    value: undefined,
    id: undefined,
    dropDownOpen: undefined,
    onDropDownOpen: _.noop,
    onDropDownClose: _.noop,
    inputRef: undefined,
    placeholder: '',
    hotOptions: false,
    ignoreOnBlur: true,
    isCleanInputOldStyle: true,
  };

  constructor(props) {
    super(props);
    this.state = { isSelecting: false };
  }

  onDropDownOpen() {
    this.setState({ isSelecting: true });
    this.props.dropDownOpen(true);
    this.props.onDropDownOpen();

    // Letting the menu render takes place,
    // because it isn't available immediately on open.
    setTimeout(() => this.scrollToOption(), 5);
  }

  onDropDownClose() {
    this.props.dropDownOpen(false);
    this.props.onDropDownClose();
  }

  onEnterPressed(e) {
    if (e.keyCode === 13 && this.state.isSelecting === true) {
      e.stopPropagation();
      this.setState({ isSelecting: false });
    }
  }

  /**
   * The 'react-select' automatically scrolls to option if value is available.
   * in our case, we dont have a chosen value yet,
   * but still want to open up the menu on specific value.
   */
  scrollToOption() {
    const { value, scrollToOption } = this.props;

    // check if value is not defined & scrollToOption is available.
    const shouldScrollToOption = !value && scrollToOption;
    if (!shouldScrollToOption) return;

    const selectRef = this.selectRef;
    const menuContainer = selectRef.menuContainer;
    const OptionsHTMLNodes = menuContainer.getElementsByClassName(SELECT_OPTION_CLASS_IDENTIFIER);
    const optionsDom = Array.from(OptionsHTMLNodes);

    // Find the option to scroll to.
    const optionToScroll = optionsDom.find(x => x.innerText === scrollToOption);
    if (optionToScroll) {
      optionToScroll.scrollIntoView();
    }
  }

  /**
   * Handles input change
   * @param e
   * @private
   */
  _handleSelectChange(e) {
    this.onDropDownClose();
    if (this.props.onChanged) this.props.onChanged(e, this.props.id);
  }

  /**
   * Checks if the current input has a value
   * @returns {*}
   */
  hasValue() {
    return !_.isNil(this.props.value);
  }

  /**
   * Builds input's group class
   * @param className
   * @returns {string|*}
   */
  buildClassName(className) {
    className = `form-group material-form-group ${className}`;
    if (this.props.errorText) {
      className += ' has-error';
    }

    return className;
  }

  renderErrorSpan() {
    const { errorText, errorClassName } = this.props;
    if (_.isEmpty(errorText)) return null;

    return _.isString(errorText) ? (
      <span className={classNames('validation-message', errorClassName)}>{errorText}</span>
    ) : (
      <IntlString
        className={classNames('validation-message', errorClassName)}
        message={errorText}
      />
    );
  }

  render() {
    // Extract properties
    let {
      // eslint-disable-next-line no-unused-vars
      className,
      label,
      value,
      labelClassName,
      iconClassName,
      inputRef,
      placeholder,
      intl,
      ignoreOnBlur,
      isCleanInputOldStyle,
      ...other
    } = this.props;

    // onBlur is sent to the input instead of the select and leads value reset and issues
    if (ignoreOnBlur) {
      delete other.onBlur;
    }

    const cleanInput = isCleanInputOldStyle ? 'clean-input' : undefined;
    // Build classes
    className = this.buildClassName(className);
    placeholder = Formatter.intlToString(intl, placeholder);
    return (
      // eslint-disable-next-line jsx-a11y/no-static-element-interactions
      <div className={className} onKeyUp={this.onEnterPressed}>
        <Select
          className={classNames(cleanInput, this.props.selectClassName)}
          value={value}
          onOpen={this.onDropDownOpen}
          onClose={this.onDropDownClose}
          placeholder={placeholder}
          ref={el => {
            inputRef = el;
            this.selectRef = el;
          }}
          onChange={this._handleSelectChange}
          {...other}
        />
        <label
          className={classNames(
            'static-label-header',
            { 'input-active': this.hasValue() },
            labelClassName,
          )}
        >
          <IntlString message={label} />
        </label>
        <i className={classNames('select-left-icon', iconClassName)} />
        {this.renderErrorSpan()}
      </div>
    );
  }
}

/**
 * Call onChanged with value update in case the options were changed.
 * If there is a new option with the same value as the selected one - use it.
 * Otherwise, clear selected value.
 */
const handleHotOptions = (options, value, onChanged, id) => {
  if (value) {
    const updatedValue = _.find(options, val => val.value === value.value);
    if (!updatedValue && value) {
      onChanged(null, id);
    } else if (updatedValue.label !== value.label) {
      onChanged(updatedValue, id);
    }
  }
};

export default compose(
  injectIntl,
  connect(null, { dropDownOpen }),
  withPropsOnChange(['options'], ({ hotOptions, options, value, onChanged = _.noop, id }) => {
    if (hotOptions) {
      handleHotOptions(options, value, onChanged, id);
    }
    return { options };
  }),
  component => analytics(component, false),
)(CleanSelect);
