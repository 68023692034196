import _ from 'lodash';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';

import {
  compose,
  withHandlers,
  withPropsOnChange,
  branch,
  renderComponent,
  withStateHandlers,
  withProps,
  getContext,
} from 'recompose';

import injectNotification from '../../store/notification/injectNotification';
import { activate, ACTIVATE } from './actions';
import actionTracker from '../../store/tools/actionTracker/actionTrackerComponent';
import * as Errors from '../../api/errors';
import { createToggleState } from '../../components/recompose/toggleStateComponent';
import dateOfBirthPage from '../../apps/members/src/dateOfBirth/dateOfBirthPage';
import messages from '../../messages/errors/activation';
import shortActivationEvents from './shortActivationEvents';
import { setInternalSessionId } from '../../utils/internalSession';

/**
 * callActivate: runs API call to activate. then goes to signin or render fail notification
 * onActivateClicked: will either call to API with only token or render DOB form
 * activateWithDOB: will call API with token and DOB
 */
export const withShortActivation = compose(
  getContext({ analytics: PropTypes.object.isRequired }),
  createToggleState('alreadyActivatedModal'),
  createToggleState('helpModal'),
  withRouter,
  injectNotification,
  connect(
    null,
    { activate }
  ),
  withProps(ownProps => {
    setInternalSessionId(ownProps.location.query.sessionToken);
    return {
      token: ownProps.location.query.token,
      next: ownProps.location.query.next,
    };
  }),
  withStateHandlers(
    ({ askDOB = false, location }) => ({
      askDOB,
      token: location.query.token,
      next: location.query.next,
    }),
    {
      onActivateClicked: (state, props) =>
        (token, to, isActivated = false, onActivationSuccess = _.noop) => {
          const activationParams = {
            askDOB: true,
            token,
            to,
            isActivated,
            onActivationSuccess,
          };
          props.analytics.track(shortActivationEvents.flow.startFlow,
            {
              askDOB: true,
              token,
              to,
              isActivated
            });
          return activationParams;
        }
    }
  ),
  withHandlers({
    callActivate: props => (token, dob, to, isActivated = false) => {
      const { next, router, activate, analytics } = props;
      const nextPage = next || to;
      if (isActivated === true) {
        return router.push(nextPage);
      }
      analytics.track(shortActivationEvents.flow.dobEntered,
        { token, to, isActivated });
      return activate(token, dob, nextPage);
    },
  }),
  actionTracker({
    activationTracking: ACTIVATE.SOURCE
  }),
  withPropsOnChange(['activationTracking'], props => {
    const { activationTracking, notification, router, onActivationSuccess } = props;
    if (_.get(activationTracking, 'finished', false) === true) {
      // error case
      if (_.get(activationTracking, 'hasError', false) === true) {
        const { responseMessage } = activationTracking.error;
        switch (responseMessage) {
          case Errors.USER_ALREADY_EXIST:
            router.push(activationTracking.meta.nextPage);
            break;
          case Errors.SHORT_TOKEN_DOB_MISS_MATCH: {
            props.analytics.track(shortActivationEvents.flow.dobMissMatch,
              { ...activationTracking });
            notification.error(
              messages.dobDoesNotMatchTitle,
              messages.dobDoesNotMatchMessage,
              { autoDismiss: 10 }
            );
            break;
          }
          default: {
            props.analytics.track(shortActivationEvents.flow.failed,
              { ...activationTracking });
            notification.error(messages.serverErrorTitle, messages.serverErrorMessage, {
              autoDismiss: 10
            });
            break;
          }
        }
      } else {
        // success case
        props.analytics.track(shortActivationEvents.flow.complete,
          { nextPage: activationTracking.meta.nextPage });
        onActivationSuccess();
        router.push(activationTracking.meta.nextPage);
      }
    }
  }),
  withHandlers({
    activateWithDOB: props => data =>
      props.callActivate(props.token, data.dob, props.to, props.isActivated)
  }),
  branch(props => props.askDOB, renderComponent(dateOfBirthPage))
);
