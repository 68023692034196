/**
 * Created by meirshalev on 04/06/2017.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, FormattedMessage } from 'react-intl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import Joi from 'joi-browser';
import { autobind } from 'core-decorators';

import { createValidator } from '../../../../../../utils/joiValidator';
import { SpinnerButton } from '../../../../../../components/ui/spinner/index';
import * as FormDef from './updatePasswordDef';
import withFeatureFlag from '../../../../../../utils/featureFlags/withFeatureFlag';

const messages = defineMessages({
  password: {
    defaultMessage: 'Password',
    id: 'members.settings.password',
  },
  updatePassword: {
    defaultMessage: 'Update your password',
    id: 'members.settings.updatePassword',
  },
  currentPassword: {
    defaultMessage: 'Current password',
    id: 'members.settings.currentPassword',
  },
  newPassword: {
    defaultMessage: 'Your new password',
    id: 'members.settings.newPassword',
  },
  repeatPassword: {
    defaultMessage: 'Re-enter password',
    id: 'activation.welcome.repeatPassword',
  },
});

@autobind
class UpdatePasswordForm extends React.Component {
  static propTypes = {
    /**
     * Supplied by redux-form
     */
    change: PropTypes.func.isRequired,
    /**
     * redux-forms form submission method.
     * Provided by redux-forms HoC
     */
    handleSubmit: PropTypes.func.isRequired,
    /**
     * The password value filled out in this form
     */
    password: PropTypes.string,
    /**
     * True if form is currently submitting
     */
    loading: PropTypes.bool,
  };

  static defaultProps = {
    password: undefined,
    loading: false,
  };

  /**
   * Updates the passwordStrength field whenever the strength changes.
   * Uses the "change" action of redux-form.
   * @param strength
   */
  onPasswordStrengthChanged(strength) {
    this.props.change(FormDef.fields.passwordStrength.name, strength);
  }

  render() {
    const { handleSubmit, loading, newPasswordValidationFeature } = this.props;
    return (
      <form onSubmit={handleSubmit} method="post">
        <div className="row">
          <div className="col-xs-12">
            <Field
              type="password"
              label={messages.currentPassword}
              {...FormDef.fields.currentPassword}
              id="currentPassword"
            />
          </div>
          <div className="col-xs-12">
            <Field
              showSuggestions
              onStrengthChanged={this.onPasswordStrengthChanged}
              label={messages.newPassword}
              {...FormDef.fields.newPassword}
              id="newPassword"
              aria-describedby="pw-strength"
              withFeatureFlag={newPasswordValidationFeature}
              verifyPasswordField={
                <div>
                  <Field
                    label={messages.repeatPassword}
                    className="password-input"
                    {...FormDef.fields.repeatPassword}
                  />
                </div>
              }
            />
            <Field type="hidden" {...FormDef.fields.passwordStrength} />
          </div>
        </div>

        <div className="row">
          <div className="col-xs-12">
            <SpinnerButton
              className="btn btn-big bg-color-brand-button"
              type="submit"
              isLoading={loading}
            >
              <FormattedMessage {...messages.updatePassword} />
            </SpinnerButton>
          </div>
        </div>
      </form>
    );
  }
}

const fieldSelector = formValueSelector(FormDef.name);
export default compose(
  reduxForm({
    form: FormDef.name,
    validate: createValidator(Joi.object().keys(FormDef.schema), FormDef.customValidator),
    destroyOnUnmount: true,
  }),
  connect(state => ({
    currentPassword: fieldSelector(state, FormDef.fields.currentPassword.name),
    newPassword: fieldSelector(state, FormDef.fields.currentPassword.name),
  })),
  withFeatureFlag({
    key: 'vim.members.password_validation_feature',
    prop: 'newPasswordValidationFeature',
    defaultValue: false,
  }),
)(UpdatePasswordForm);
