import PromiseActionType from '../promiseActionType';

export const GET_APPOINTMENT_BY_ID = new PromiseActionType('GET_APPOINTMENT_BY_ID');
export const CANCEL_APPOINTMENT = new PromiseActionType('CANCEL_APPOINTMENT');
export const SET_APPOINTMENTS = 'SET_APPOINTMENTS';

export function getAppointmentById(id) {
  return ({ bkmdApi: { appointmentApi } }) => ({
    type: GET_APPOINTMENT_BY_ID.SOURCE,
    payload: {
      promise: appointmentApi.publicGetAppointment(id),
    },
    meta: {
      tracker: GET_APPOINTMENT_BY_ID.SOURCE,
    },
  });
}

export function setAppointment(appointment) {
  return {
    payload: appointment,
    type: GET_APPOINTMENT_BY_ID.SUCCESS,
  };
}

export function setAppointments(payload) {
  return {
    type: SET_APPOINTMENTS,
    payload,
  };
}

export function cancelAppointment(id) {
  return ({ bkmdApi: { schedulingApi }, dispatch }) => ({
    type: CANCEL_APPOINTMENT.SOURCE,
    payload: {
      promise: schedulingApi.patientCancelAppointment(id).then(() => {
        dispatch(getAppointmentById(id));
      }),
    },
    meta: {
      tracker: CANCEL_APPOINTMENT.SOURCE,
    },
  });
}
