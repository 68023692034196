/**
 * Created by meirshalev on 04/06/2017.
 */
import _ from 'lodash';
import Immutable from 'seamless-immutable';
import moment from 'moment';
import { combineReducers } from 'redux';
import reduceReducers from 'reduce-reducers';

import medicalTeam from './medicalTeam/reducer';
import genericReducer from '../genericReducer';
import { convertDrugHistory } from './converters';
import {
  GET_MEMBER,
  ADD_INSURANCE_INFO,
  GET_MEMBER_SETTINGS,
  CHANGE_NOTIFICATION_PREFERENCES,
  CHANGE_LOCATION_PERMISSIONS,
  CHANGE_ADDRESS,
  GET_CARE_CUES,
  GET_MEDICATIONS_SUMMARY,
  GET_DRUG_HISTORY,
  FETCH_ACTIVATION_BY_TOKEN,
  GET_FULL_MEMBER_INFO,
  SET_MEMBER,
} from './actions';

const medicationDateFormat = 'D MMM YYYY';

const info = reduceReducers(
  genericReducer(SET_MEMBER, {}, null, { keepStateOnStart: true }),
  genericReducer(GET_MEMBER, {}, null, { keepStateOnStart: true }),
  genericReducer(ADD_INSURANCE_INFO, {}, null, { keepStateOnStart: true, keepStateOnError: true }),
);

const medicationsSummary = genericReducer(
  GET_MEDICATIONS_SUMMARY,
  null,
  payload => _.map(payload, record => ({
    ...record,
    lastPrescribedString: moment.utc(record.lastPrescribed).format(medicationDateFormat),
  })),
);

function drugHistory(state = {}, action) {
  state = Immutable(state);
  switch (action.type) {
    case GET_DRUG_HISTORY.SUCCESS: {
      const drugName = _.get(action.payload, [0, 'drugName'], false);
      if (!drugName) return state;
      return state.merge({ [drugName]: _.map(action.payload, convertDrugHistory) });
    }
  }
  return state;
}

const settings = (state = {}, action) => {
  state = Immutable(state);

  switch (action.type) {
    case GET_MEMBER_SETTINGS.SUCCESS:
      return Immutable(action.payload);

    case CHANGE_NOTIFICATION_PREFERENCES.SUCCESS:
    case CHANGE_LOCATION_PERMISSIONS.SUCCESS:
    case CHANGE_ADDRESS.SUCCESS: {
      return state.merge(action.payload);
    }
  }

  return state;
};

const initialMembersState = {
  arr: [],
};

const members = (state = initialMembersState, action) => {
  state = Immutable(state);
  switch (action.type) {
    case GET_FULL_MEMBER_INFO.SUCCESS: {
      if (!_.find(state.arr, ['id', action.payload.id])) {
        return state.merge({ arr: [...state.arr, action.payload] });
      }
    }
  }
  return state;
};

const activation = genericReducer(FETCH_ACTIVATION_BY_TOKEN);

const careCues = genericReducer(GET_CARE_CUES, []);

export default combineReducers({
  info,
  settings,
  activation,
  careCues,
  medicationsSummary,
  drugHistory,
  members,
  medicalTeam,
});
