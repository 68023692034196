/**
 * Created by chenrozenes on 12/04/2017.
 */
import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import queryString from 'qs';
import { memoizedSelectorCreator } from '../../../utils/reselectUtils';
import setInQueryString from './setInQueryString';
import clearQueryString from './clearQueryString';
import queryStringParseOptions from '../../../utils/queryStringParseOptions';

// Simple qurey selector, extract qs from state router
const querySelector = state => _.get(state, 'routing.locationBeforeTransitions.query');

/**
 * HOC for handling query string as a state like redux state.
 * Pass mapQueryToProps like mapStateToProps.
 * You can pass an mapping object, an array, or a function
 *
 * @param mapQueryToProps - array, function or object for mapping query string values to props
 * @param defaults - optional default values object or function to set in the query string if not
 * exists when component mounts
 * @returns {function()}
 */
export default function queryConnect(mapQueryToProps = {}, defaults = {}) {
  // wrap defaults value in selector. runs or uses defaults with the given params
  const defaultsSelector = (state, props, query) => {
    const _defaults = _.isFunction(defaults) ? defaults(query, props, state) : defaults;
    return _.assign({}, _defaults);
  };
  // Wrap defaultsSelector with a deep equal memoize reselector.
  const defaultsReselector = memoizedSelectorCreator(defaultsSelector, _.identity);

  // wrap mapQueryToProps with a selector runs or uses mapQueryToProps with the give props
  const mapQueryToPropsSelector = (state, props, query) => {
    query = queryString.parse(query, queryStringParseOptions);
    if (_.isFunction(mapQueryToProps)) return mapQueryToProps(query, props, state);

    let map = mapQueryToProps; // Assuming object
    // If array, transform to obj (duplicate key and val)
    if (_.isArray(mapQueryToProps)) map = _.zipObject(mapQueryToProps, mapQueryToProps);

    return _.mapValues(map, queryVal => _.get(query, queryVal));
  };

  // wraps mapQueryToPropsSelector with a deep equal memoize reselector. avoid unneeded rendering
  const mapQueryToPropsReselector = memoizedSelectorCreator(mapQueryToPropsSelector, _.identity);

  function mapStateToProps(state, props) {
    const originQuery = querySelector(state);
    return {
      originQuery,
      defaults: defaultsReselector(state, props, originQuery),
      qs: mapQueryToPropsReselector(state, props, originQuery),
    };
  }

  return Component => {
    @autobind
    class QueryConnect extends React.PureComponent {
      static propTypes = {
        setInQueryString: PropTypes.func.isRequired,
        qs: PropTypes.object.isRequired,
        defaults: PropTypes.object.isRequired,
        originQuery: PropTypes.object.isRequired,
      };

      componentWillMount() {
        const { originQuery, qs } = this.props;
        this.changeQuery(qs, originQuery);
      }

      componentWillReceiveProps(nextProps) {
        const { originQuery, qs } = nextProps;
        this.changeQuery(qs, originQuery);
      }

      changeQuery(qs, originQuery) {
        const { defaults, setInQueryString } = this.props;

        if (!this.isReady(qs, originQuery)) {
          setInQueryString(defaults);
        }
      }

      /**
       * Returns true if page is ready to be rendered:
       * when query string has its default values
       * @returns {*}
       */
      isReady(qs, originQuery) {
        return _.chain(qs)
          .keys()
          .every(key => (this.props.defaults[key] ? !_.isNil(originQuery[key]) : true))
          .value();
      }

      render() {
        const { qs, originQuery, ...other } = this.props;
        if (!this.isReady(qs, originQuery)) return null;

        return (
          <Component {...qs} {..._.omit(other, 'defaults')} />
        );
      }
    }

    return connect(mapStateToProps, { setInQueryString, clearQueryString })(QueryConnect);
  };
}
