import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  compose,
  renderNothing,
  branch,
  renderComponent,
  defaultProps,
  setPropTypes,
  withProps,
} from 'recompose';
import withEffects from '../../../../components/recompose/withEffects';
import { loadingUntil } from '../../../../components/recompose/loadingUntil';

import { getFeature } from '../../../../store/feature/selectors';
import Api from '../../../../api';
import NetworkStatusChip, {
  Unverified,
} from '../../../../storybook/src/components/atoms/NetworkStatusChip/NetworkStatusChip';
import Loader from '../../../../storybook/src/components/atoms/Loader/Loader';
import { withStateFetchers } from '../../../../api/injectApi/withStateFetchers';

import './networkStatus.less';

const LoaderComponent = () => (
  <Loader size="small" type="dots" color="grey" inline padding="none" ariaLiveOff />
);

const fetchNetworkCompose = compose(
  withStateFetchers({
    isInNetwork: {
      handler: ({ npi, location: { geo }, externalMemberId, externalMemberDomain }) => () =>
        Api.payerApi.isInNetwork(npi, geo, externalMemberId, externalMemberDomain),
      resultPropName: 'networkStatus',
      isReady: ({ networkStatus }) => !_.isEmpty(networkStatus),
    },
  }),
  withEffects({
    onGeoChangeEffect: {
      action: ({ isInNetwork }) => isInNetwork(),
      didMountPredicate: () => true,
      didUpdatePredicate: (props, prevProps) => props.location !== prevProps.location,
    },
  }),
  loadingUntil(({ isInNetworkTracker }) => !isInNetworkTracker.inProgress, LoaderComponent),
);

const NetworkStatusWithFetch = compose(
  setPropTypes({
    npi: PropTypes.string.isRequired,
    location: PropTypes.object.isRequired,
    externalMemberId: PropTypes.string,
    externalMemberDomain: PropTypes.string,
    block: PropTypes.bool,
  }),
  defaultProps({
    externalMemberId: '',
    externalMemberDomain: '',
    block: false,
  }),
  connect(state => ({
    showStatus: getFeature(state, 'showInNetwork', true),
  })),
  branch(({ showStatus }) => !showStatus, renderNothing),
  branch(
    ({ externalMemberId, externalMemberDomain, location }) =>
      !externalMemberId || !externalMemberDomain || _.isEmpty(location),
    renderComponent(Unverified),
  ),
  fetchNetworkCompose,
)(NetworkStatusChip);

export default compose(
  withProps(({ shouldFetch, location }) => ({
    shouldFetch: !_.isNil(shouldFetch) ? shouldFetch : !location.network,
  })),
  branch(({ shouldFetch = false }) => shouldFetch, renderComponent(NetworkStatusWithFetch)),
)(NetworkStatusChip);
