import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { compose as recomposeCompose, withState, withHandlers, withProps } from 'recompose';

export const createToggleState = (name, initialState = false) => {
  const toggleFuncName = `${name}Toggle`;

  return recomposeCompose(
    withState(`${name}On`, toggleFuncName, initialState),
    withHandlers({
      [toggleFuncName]: props => () => props[toggleFuncName](current => !current),
      [`${name}Hide`]: props => () => props[toggleFuncName](false),
      [`${name}Show`]: props => () => props[toggleFuncName](true),
    }),
    // TODO: rename and change API to, on, off, isOn, isOff, toggle, set(bool)
    withProps(props => ({
      [name]: {
        shown: props[`${name}On`],
        isShown: () => props[`${name}On`],
        toggle: props[toggleFuncName],
        show: props[`${name}Show`],
        hide: props[`${name}Hide`],
      },
    })),
  );
};

export const toggleStateShape = PropTypes.shape({
  on: PropTypes.bool.isRequired,
  isOn: PropTypes.func.isRequired,
  toggle: PropTypes.func.isRequired,
  show: PropTypes.func.isRequired,
  hide: PropTypes.func.isRequired,
});

/**
 * Spread this one into your propTypes
 * @param name
 */
export const createToggleStatePropTypes = name => ({
  [`${name}Show`]: PropTypes.func.isRequired,
  [`${name}Hide`]: PropTypes.func.isRequired,
  [`${name}On`]: PropTypes.bool.isRequired,
});

/**
 * Spread this one into your propTypes
 * @param name
 */
export const createOptionalToggleStatePropTypes = name => ({
  [`${name}Show`]: PropTypes.func,
  [`${name}Hide`]: PropTypes.func,
  [`${name}On`]: PropTypes.bool,
});

/**
 * Spread this one into your propTypes
 * @param name
 */
export const createDefaultToggleStatePropTypes = name => ({
  [`${name}Show`]: noop,
  [`${name}Hide`]: noop,
  [`${name}On`]: false,
});
