/**
 * Created by meirshalev 22/08/2017.
 */
import Joi from 'joi-browser';
import { ReduxFormCleanSwitch } from '../../../../../components/form/reduxForm/components';

export const name = 'notifications';

export const fields = {
  financialAndHealthUpdatesMessages: {
    id: 'financialAndHealthUpdatesMessages',
    name: 'financialAndHealthUpdatesMessages',
    component: ReduxFormCleanSwitch,
  },
  financialAndHealthUpdatesEmails: {
    id: 'financialAndHealthUpdatesEmails',
    name: 'financialAndHealthUpdatesEmails',
    component: ReduxFormCleanSwitch,
  },
  appointmentInfoMessages: {
    id: 'appointmentInfoMessages',
    name: 'appointmentInfoMessages',
    component: ReduxFormCleanSwitch,
  },
  appointmentInfoEmails: {
    id: 'appointmentInfoEmails',
    name: 'appointmentInfoEmails',
    component: ReduxFormCleanSwitch,
  },
  accountRemindersMessages: {
    id: 'accountRemindersMessages',
    name: 'accountRemindersMessages',
    component: ReduxFormCleanSwitch,
  },
  accountRemindersEmails: {
    id: 'accountRemindersEmails',
    name: 'accountRemindersEmails',
    component: ReduxFormCleanSwitch,
  },
  newsTipsPromotionsEmails: {
    id: 'newsTipsPromotionsEmails',
    name: 'newsTipsPromotionsEmails',
    component: ReduxFormCleanSwitch,
  },
};

export const schema = {
  financialAndHealthUpdatesMessages: Joi.boolean(),
  financialAndHealthUpdatesEmails: Joi.boolean(),
  appointmentInfoMessages: Joi.boolean(),
  appointmentInfoEmails: Joi.boolean(),
  accountRemindersMessages: Joi.boolean(),
  accountRemindersEmails: Joi.boolean(),
  newsTipsPromotionsEmails: Joi.boolean(),
};
