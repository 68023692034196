import _ from 'lodash';
import { defineMessages } from 'react-intl';
import { compose } from 'recompose';
import Api from '../../api';
import { withFetchersOnMount } from '../../api/injectApi/withFetchersOnMount';
import { ThreeBounceSpinner } from '../../components/ui/spinner';
import injectNotification from '../../store/notification/injectNotification';

const messages = defineMessages({
  actionErrorTitle: {
    defaultMessage: 'Oopsy',
    id: 'flows.getProviderData.actionErrorTitle',
  },
  actionErrorText: {
    defaultMessage: 'An error has occurred and we could not complete your action, please try again',
    id: 'flows.getProviderData.actionErrorText',
  },
});

export default compose(
  injectNotification,
  withFetchersOnMount(
    {
      getProviderData: {
        handler: ({ npiList }) => () =>
          Promise.map(npiList, npi => Api.providerApi.byNpiPublicToken(npi)),
        isReady: props => !!props.providerData,
        onSuccess: ({ control }, providerData) => {
          control.next({
            payload: {
              member: providerData[0].member,
              providers: _.map(providerData, p => p.provider),
            },
          });
        },
        onError: ({ notification }) => {
          notification.error(messages.actionErrorTitle, messages.actionErrorText);
        },
        resultPropName: 'providerData',
      },
    },
    true,
  ),
)(ThreeBounceSpinner);
