/**
 * Created by galgoltzman on 22/02/2017.
 */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import analytics from '../../store/tools/analytics/analyticsHoc';
import { IntlString, MessageShape } from '../ui/intlString';

import './cleanSwitch.less';

class CleanSwitch extends React.Component {
  static propTypes = {
    /**
     * Unique id not give to other switches in the form
     */
    id: PropTypes.string.isRequired,
    /**
     * boolean for current value
     */
    value: PropTypes.bool,
    /**
     * on change func
     */
    onChange: PropTypes.func.isRequired,
    /**
     * Sets component root css class
     */
    className: PropTypes.string,
    /**
     * Label to show next to the switch
     */
    label: MessageShape,
    /**
     * True for the component to be readOnly
     */
    readOnly: PropTypes.bool,
  };

  static defaultProps = {
    id: undefined,
    className: undefined,
    value: false,
    label: '',
    readOnly: false,
  };

  render() {
    const { className, id, label, value, onChange, readOnly } = this.props;

    return (
      <div className={classNames('clean-switch', { 'light-blue': !!readOnly }, className)}>
        <input id={id} type="checkbox" checked={value} onChange={!readOnly ? onChange : null} />
        <label htmlFor={id} />
        <label htmlFor={id} className="switch-text-label">
          <IntlString message={label} />
        </label>
      </div>
    );
  }
}

export default analytics(CleanSwitch, false, [
  { event: 'onChange', eventData: value => ({ value }) },
]);
