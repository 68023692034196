import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages } from 'react-intl';
import { compose } from 'recompose';
import PcpSearchTypeahead from './pcpSearchTypeahead';
import PcpSearchModal from './pcpSearchModal';
import { createToggleStatePropTypes } from '../../recompose/toggleStateComponent';
import { withTranslatedMessages } from '../../../utils/withTranslatedMessages';

const messages = defineMessages({
  placeholder: {
    defaultMessage: 'Name',
    id: 'pcpSelection.findDoctors.searchPlaceholder',
  },
});

const PcpSelectionSearch = ({
  updatePcpSelection,
  memberPlan,
  memberContext,
  pcpSearchModalOn,
  pcpSearchModalHide,
  placeholder,
  clientLocation,
}) => (
  <div>
    {/* /// Desktop search input /// */}
    <div className="hidden-xs">
      <PcpSearchTypeahead
        updatePcpSelection={updatePcpSelection}
        memberPlan={memberPlan}
        memberContext={memberContext}
        placeholder={placeholder}
        clientLocation={clientLocation}
      />
    </div>

    {/* /// Mobile search input /// */}
    <div className="visible-xs">
      <PcpSearchModal
        isOpen={pcpSearchModalOn}
        handleClose={pcpSearchModalHide}
        memberContext={memberContext}
        updatePcpSelection={updatePcpSelection}
        memberPlan={memberPlan}
        clientLocation={clientLocation}
      />
    </div>
  </div>
);

PcpSelectionSearch.propTypes = {
  memberPlan: PropTypes.string.isRequired,
  memberContext: PropTypes.string.isRequired,
  clientLocation: PropTypes.object.isRequired,
  updatePcpSelection: PropTypes.func.isRequired,
  ...createToggleStatePropTypes('pcpSearchModal'),
};

export default compose(withTranslatedMessages(messages))(PcpSelectionSearch);
