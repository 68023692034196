import React from 'react';
import { defineMessages } from 'react-intl';
import SvgPooStars from '../../assets/svg/poo/pooStars';
import { YesNoStep } from './yesNoStep';

const messages = defineMessages({
  question: {
    defaultMessage: `Have you been screened 
    for colon cancer in the past 10 years?`,
    id: 'colorectalFlow.pastScreened.question',
  },
});

const ScreenedWithin10Years = YesNoStep(<SvgPooStars />, messages);

export default ScreenedWithin10Years;
