import { autobind } from 'core-decorators';
import BkmdApi from './bkmdApi';
import ServicesApi from './services';

const SERVICE_NAME = 'costEstimation';

/**
 * Represents the cost estimation API
 */
@autobind
class CostEstimationApi extends BkmdApi {
  constructor(fetch) {
    super(fetch, '/costEstimation/');
    this.servicesApi = new ServicesApi(fetch);
  }

  estimate(npi, locationId, treatmentCode, domainMemberId) {
    return this.servicesApi
      .callSecure(SERVICE_NAME, 'estimate', {
        npi,
        locationId,
        treatmentCode,
        domainMemberId,
      })
      .then(response => response.data);
  }

  treatments(npi, locationId) {
    return this.servicesApi
      .callSecure(SERVICE_NAME, 'treatments', {
        npi,
        locationId,
      })
      .then(response => response.data);
  }
}

export default CostEstimationApi;
