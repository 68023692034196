/**
 * Created by chenrozenes on 01/05/2017.
 */
import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import FieldWrapper from './fieldWrapper';
import { BkmdGrid, BkmdGridColumn } from '../../grid';

/**
 * Component to display an array inside redux form
 * Useful for read only forms since the grid doesn't have the onChange function
 */
@autobind
class ReduxFormBkmdGrid extends React.Component {
  static propTypes = {
    value: PropTypes.array.isRequired,
  };

  render() {
    const { value, ...other } = this.props;
    return (
      <div>
        <BkmdGrid
          data={value}
          totalCount={value.length}
          classNames={{ Table: 'bkmd-table auto-layout' }}
          local
          {...other}
        >
          {_.map(_.keys(value[0]) || [], curr => <BkmdGridColumn id={curr} />)}
        </BkmdGrid>
        <br /><br />
      </div>
    );
  }
}

export default FieldWrapper(ReduxFormBkmdGrid);
