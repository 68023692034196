/**
 * Created by meirshalev on 01/06/2017.
 */
import {
  ReduxFormCleanPhoneNumber,
  ReduxFormCleanInput,
} from '../../../../../../components/form/reduxForm/components';
import { PhoneNumberSchema, PasswordSchema } from '../../../../../../utils/commonSchema';

export const name = 'updatePhoneNumber';

export const fields = {
  phoneNumber: {
    name: 'phoneNumber',
    component: ReduxFormCleanPhoneNumber,
  },
  // hidden field for validation purpose only
  existingPhoneNumber: {
    name: 'existingPhoneNumber',
    component: 'input',
  },
  password: {
    name: 'password',
    type: 'password',
    component: ReduxFormCleanInput,
  },
};

export const schema = {
  phoneNumber: PhoneNumberSchema.required(),
  existingPhoneNumber: PhoneNumberSchema,
  password: PasswordSchema,
};

export const customValidator = {
  [fields.password.name]: (value, values) => {
    const phoneNumber = values[fields.phoneNumber.name];
    const existingPhoneNumber = values[fields.existingPhoneNumber.name];
    const password = value;
    if (phoneNumber !== existingPhoneNumber && !password) {
      return 'For your security, please re-enter your password';
    }
    return undefined;
  },
};
