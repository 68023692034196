import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '../../storybook/src/utils/theme';

function SvgCalendarStars({ theme }) {
  /* eslint-disable */
  return (
    <svg className="svg-icon svg-calendar-stars" width="72px" height="62px" viewBox="0 0 72 62" version="1.1">
      <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Artboard" transform="translate(0.000000, -5.000000)" fill-rule="nonzero">
          <g id="calendar-stars" transform="translate(0.000000, 5.000000)">
            <path
              d="M5.76950312,41.3115445 C5.01111236,41.3115445 4.28957436,41.602469 3.74551143,42.1455281 C3.01524515,42.8757485 2.74272878,43.9269557 3.01621496,44.9568284 C3.2635163,45.8926354 4.02772589,46.656797 4.9626219,46.9040829 C5.9915894,47.1785216 7.04383232,46.9050526 7.7740986,46.1748322 C8.50436488,45.4446117 8.77785107,44.3934046 8.50339508,43.3635319 C8.25609375,42.4277249 7.49188414,41.6635632 6.55698813,41.4153076 C6.29320005,41.3454858 6.03038177,41.3115445 5.76950312,41.3115445 M5.76368426,49.92 C5.25259484,49.92 4.73374695,49.8530874 4.21683868,49.7163529 C2.28982792,49.2052955 0.714857874,47.6304243 0.203768457,45.701595 C-0.338354852,43.6602749 0.216375977,41.5607699 1.68757644,40.088692 C3.16071653,38.6175839 5.25938351,38.0619181 7.30277136,38.6030377 C9.26081601,39.1228228 10.7989333,40.6618132 11.3168114,42.6187652 C11.8579649,44.6610551 11.3032341,46.7595904 9.83106376,48.2316683 C8.73130022,49.3323326 7.28046575,49.92 5.76368426,49.92"
              id="Fill-1" fill={theme.secondaryColor} ></path>
            <path
              d="M60,10.56 C59.20512,10.56 58.56,9.87870968 58.56,9.03926267 L58.56,1.52073733 C58.56,0.681290322 59.20512,0 60,0 C60.79488,0 61.44,0.681290322 61.44,1.52073733 L61.44,9.03926267 C61.44,9.87870968 60.79488,10.56 60,10.56"
              id="Fill-3" fill={theme.secondaryColor} ></path>
            <path
              d="M63.7592626,6.72 L56.2407374,6.72 C55.4012903,6.72 54.72,6.07488 54.72,5.28 C54.72,4.48512 55.4012903,3.84 56.2407374,3.84 L63.7592626,3.84 C64.5987097,3.84 65.28,4.48512 65.28,5.28 C65.28,6.07488 64.5987097,6.72 63.7592626,6.72"
              id="Fill-5" fill={theme.secondaryColor} ></path>
            <path
              d="M59.3544924,27.707231 L9.76550758,28.1936111 L9.76550758,34.2758359 C9.76550758,35.1046646 9.08331389,35.7773373 8.24275379,35.7773373 C7.4021937,35.7773373 6.72,35.1046646 6.72,34.2758359 L6.72,21.0776377 C6.72,16.8664264 10.1949242,13.44 14.465741,13.44 L54.654259,13.44 C58.9250758,13.44 62.4,16.8664264 62.4,21.0776377 L62.4,53.8023623 C62.4,58.0135736 58.9250758,61.44 54.654259,61.44 L14.465741,61.44 C10.1949242,61.44 6.72,58.0135736 6.72,53.8023623 L6.72,53.2948549 C6.72,52.4660261 7.4021937,51.7933534 8.24275379,51.7933534 C9.08331389,51.7933534 9.76550758,52.4660261 9.76550758,53.2948549 L9.76550758,53.8023623 C9.76550758,56.3579179 11.874014,58.436997 14.465741,58.436997 L54.654259,58.436997 C57.245986,58.436997 59.3544924,56.3579179 59.3544924,53.8023623 L59.3544924,27.707231 Z M59.3544924,24.7039393 L59.3544924,21.0776377 C59.3544924,18.5220821 57.245986,16.443003 54.654259,16.443003 L14.465741,16.443003 C11.874014,16.443003 9.76550758,18.5220821 9.76550758,21.0776377 L9.76550758,25.1903193 L59.3544924,24.7039393 Z"
              id="Combined-Shape" fill={theme.mainColor} ></path>
            <g id="Fill-11-Clipped" transform="translate(16.000000, 31.000000)" fill={theme.mainColor}
               >
              <polygon id="Fill-11"
                       points="0 10.1205016 9.73493976 10.1205016 9.73493976 0.394106583 0 0.394106583"></polygon>
            </g>
            <g id="Fill-13-Clipped" transform="translate(29.000000, 31.000000)" fill={theme.mainColor}
               >
              <path
                d="M10.6144578,0.394106583 L10.6144578,10.1205016 L0.879518072,10.1205016 L0.879518072,0.394106583 L10.6144578,0.394106583 Z M7.60520289,3.40041037 L3.88877301,3.40041037 L3.88877301,7.11419778 L7.60520289,7.11419778 L7.60520289,3.40041037 Z"
                id="Fill-13"></path>
            </g>
            <g id="Fill-14-Clipped" transform="translate(43.000000, 31.000000)" fill={theme.mainColor}
               >
              <path
                d="M10.4939759,0.394106583 L10.4939759,10.1205016 L0.759036145,10.1205016 L0.759036145,0.394106583 L10.4939759,0.394106583 Z M7.48472096,3.40041037 L3.76829108,3.40041037 L3.76829108,7.11419778 L7.48472096,7.11419778 L7.48472096,3.40041037 Z"
                id="Fill-14"></path>
            </g>
            <g id="Fill-15-Clipped" transform="translate(43.000000, 45.000000)" fill={theme.mainColor}
               >
              <polygon id="Fill-15"
                       points="0.759036145 9.98783699 10.4939759 9.98783699 10.4939759 0.261442006 0.759036145 0.261442006"></polygon>
            </g>
            <g id="Fill-16-Clipped" transform="translate(29.000000, 45.000000)" fill={theme.mainColor}
               >
              <polygon id="Fill-16"
                       points="0.879518072 9.98783699 10.6144578 9.98783699 10.6144578 0.261442006 0.879518072 0.261442006"></polygon>
            </g>
            <g id="Fill-17-Clipped" transform="translate(16.000000, 45.000000)" fill={theme.mainColor}
               >
              <path
                d="M9.73493976,0.261442006 L9.73493976,9.98783699 L0,9.98783699 L0,0.261442006 L9.73493976,0.261442006 Z M6.72599489,3.26774579 L3.00894487,3.26774579 L3.00894487,6.9815332 L6.72599489,6.9815332 L6.72599489,3.26774579 Z"
                id="Fill-17"></path>
            </g>
            <g id="Fill-18-Clipped" transform="translate(66.000000, 16.000000)" fill={theme.secondaryColor}
               >
              <path
                d="M6,3.25968482 C6,4.80275591 4.74896781,6.05291536 3.20582651,6.05291536 C1.66067075,6.05291536 0.409638554,4.80275591 0.409638554,3.25968482 C0.409638554,1.71762027 1.66067075,0.467460815 3.20582651,0.467460815 C4.74896781,0.467460815 6,1.71762027 6,3.25968482"
                id="Fill-18"></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

SvgCalendarStars.propTypes = {
  theme: PropTypes.object.isRequired,
};

export default withTheme(SvgCalendarStars);
