import React from 'react';
import PropTypes from 'prop-types';
import Loader from '../../storybook/src/components/atoms/Loader/Loader';

const LoaderComponent = ({ ariaLiveOff = false }) => (
  <Loader size="small" type="dots" color="grey" inline padding="none" ariaLiveOff={ariaLiveOff} />
);

LoaderComponent.propTypes = {
  ariaLiveOff: PropTypes.bool,
};

LoaderComponent.defaultProps = {
  ariaLiveOff: false,
};

export default LoaderComponent;
