/**
 * Created by chenrozenes on 09/08/2017.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '../../storybook/src/utils/theme';

function CalendarSm({ theme }) {
  /* eslint-disable */
  return (
    <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" className="svg-icon-calendar svg-icon" aria-label="booking horizon">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-189.000000, -738.000000)">
          <g transform="translate(65.000000, 526.000000)">
            <g transform="translate(68.000000, 212.000000)">
              <g transform="translate(56.000000, 0.000000)">
                <g>
                  <polygon fill={theme.secondaryColor} points="3 8 5 8 5 6 3 6"></polygon>
                  <polygon fill={theme.secondaryColor} points="7 8 9 8 9 6 7 6"></polygon>
                  <polygon fill={theme.secondaryColor} points="11 8 13 8 13 6 11 6"></polygon>
                  <polygon fill={theme.secondaryColor} points="3 11 5 11 5 9 3 9"></polygon>
                  <polygon fill={theme.secondaryColor} points="7 11 9 11 9 9 7 9"></polygon>
                  <polygon fill={theme.secondaryColor} points="11 11 13 11 13 9 11 9"></polygon>
                  <polygon fill={theme.secondaryColor} points="3 14 5 14 5 12 3 12"></polygon>
                  <polygon fill={theme.secondaryColor} points="7 14 9 14 9 12 7 12"></polygon>
                  <polygon fill={theme.secondaryColor} points="11 14 13 14 13 12 11 12"></polygon>
                  <path d="M15,15 L1,15 L1,4 L3,4 L3,4.5 C3,4.776 3.2235,5 3.5,5 C3.7765,5 4,4.776 4,4.5 L4,4 L12,4 L12,4.5 C12,4.776 12.2235,5 12.5,5 C12.7765,5 13,4.776 13,4.5 L13,4 L15,4 L15,15 Z M15.5,3 L13,3 L13,0.5 C13,0.224 12.7765,0 12.5,0 C12.2235,0 12,0.224 12,0.5 L12,3 L4,3 L4,0.5 C4,0.224 3.7765,0 3.5,0 C3.2235,0 3,0.224 3,0.5 L3,3 L0.5,3 C0.2235,3 0,3.224 0,3.5 L0,15.5 C0,15.776 0.2235,16 0.5,16 L15.5,16 C15.7765,16 16,15.776 16,15.5 L16,3.5 C16,3.224 15.7765,3 15.5,3 L15.5,3 Z" fill={theme.mainColor}></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
  /* eslint-enable */
}

CalendarSm.propTypes = {
  theme: PropTypes.object.isRequired,
};

export default withTheme(CalendarSm);
