/**
 * Created by matan on 10/19/17.
 */
import { initRecaptchaApi } from '../help/initializationSnippets';
import { storeRecaptcha, removeRecaptcha } from '../../utils/auth/storage';
import PromiseActionType from '../promiseActionType';

export const LOAD_RECAPTCHA_API = new PromiseActionType('LOAD_RECAPTCHA_API');
export const SET_RECAPTCHA_TOKEN = 'SET_RECAPTCHA_TOKEN';
export const RESET_RECAPTCHA = new PromiseActionType('RESET_RECAPTCHA');

export function loadRecaptchaApi() {
  return {
    type: LOAD_RECAPTCHA_API,
    payload: {
      promise: initRecaptchaApi(),
    },
  };
}

function _storeRecaptchaToken(token) {
  return {
    type: SET_RECAPTCHA_TOKEN,
    payload: token,
  };
}
export function saveRecaptchaToken(token) {
  storeRecaptcha(token);
  return _storeRecaptchaToken(token);
}

export function clearRecaptchaToken() {
  removeRecaptcha();
  return _storeRecaptchaToken(null);
}

export function resetRecaptch() {
  return {
    type: RESET_RECAPTCHA,
    payload: {
      promise: Promise.resolve(),
    },
    meta: {
      tracker: RESET_RECAPTCHA,
    },
  };
}
