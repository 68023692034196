/**
 * Created by moran on 4/27/16.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import classNames from 'classnames';
import {
  injectIntl,
  intlShape,
  defineMessages
} from 'react-intl';
import _ from 'lodash';
import CleanSelect from '../cleanSelect';


import states from './states';

const messages = defineMessages({
  state: {
    defaultMessage: 'State',
    id: 'bookmd.components.stateSelect.state'
  }
});

@autobind
class StateSelect extends React.Component {
  static propTypes = {
    intl: intlShape.isRequired,
    /**
     * Method hook, called when state selection has changed
     */
    onChange: PropTypes.func.isRequired,
    /**
     * Bool, determines if the states are displayed in full names or abbreviations.
     * Default is full name.
     */
    showShortName: PropTypes.bool,
    /**
     * String label to display as the field label
     */
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.bool]),
    /**
     * String value placeholder
     */
    placeholder: PropTypes.string,
    /**
     * String component css class
     */
    className: PropTypes.string,
    /**
     * Boolean value. determines if a 'required' astrix is displayed.
     * default is false
     */
    required: PropTypes.bool,
  };

  static defaultProps = {
    showShortName: false,
    placeholder: '',
    className: 'col-sm-3 text-center',
    required: false,
    label: false
  };

  onChanged(e) {
    this.props.onChange(e.value);
  }

  prepareStates() {
    const { showShortName } = this.props;
    const options = [];
    _.each(states, (name, shortName) => {
      options.push({
        value: shortName,
        label: showShortName ? shortName : name
      });
    });
    return options;
  }

  render() {
    const { className, placeholder, label, intl, required, ...others } = this.props;

    const selectOptions = this.prepareStates();
    const labelText = label || intl.formatMessage(messages.state);
    return (
      <div className={className}>
        <CleanSelect
          {...others}
          label={labelText}
          onChange={this.onChanged}
          options={selectOptions}
          placeholder={placeholder || ''}
          clearable={false}
          autosize={false}
          inputClassName="clean-input text-center"
          labelClassName={classNames({ 'required-pink': required })}
          inputProps={{ autoComplete: false, className: 'text-center' }}
        />
      </div>
    );
  }
}

export default injectIntl(StateSelect);
