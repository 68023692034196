import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '../../storybook/src/utils/theme';

function SvgLabs({ theme }) {
  /* eslint-disable */
  return (
    <svg aria-hidden="true" focusable="false" className="svg-icon svg-labs" width="20px" height="32px" viewBox="0 0 20 32" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-314.000000, -426.000000)">
          <g transform="translate(314.000000, 426.000000)">
            <path d="M14.7998,17 L12.9998,17 C11.7328,17 10.5668,16.418 9.8028,15.403 C9.8018,15.403 9.8018,15.403 9.8018,15.402 C8.6558,13.875 7.1658,13 5.2598,13 C4.7068,13 4.2598,13.448 4.2598,14 C4.2598,14.552 4.7068,15 5.2598,15 C6.5298,15 7.4388,15.584 8.2018,16.602 C8.2018,16.603 8.2018,16.604 8.2028,16.605 C9.3498,18.127 11.0978,19 12.9998,19 L14.7998,19 C15.3528,19 15.7998,18.552 15.7998,18 C15.7998,17.448 15.3528,17 14.7998,17" fill={theme.secondaryColor}></path>
            <path d="M16,6 L4,6 C2.897,6 2,5.103 2,4 C2,2.897 2.897,2 4,2 L16,2 C17.103,2 18,2.897 18,4 C18,5.103 17.103,6 16,6 L16,6 Z M14,26 C14,28.206 12.206,30 10,30 C7.794,30 6,28.206 6,26 L6,8 L14,8 L14,26 Z M16,0 L4,0 C1.794,0 0,1.794 0,4 C0,6.206 1.794,8 4,8 L4,26 C4,29.309 6.691,32 10,32 C13.309,32 16,29.309 16,26 L16,8 C18.206,8 20,6.206 20,4 C20,1.794 18.206,0 16,0 L16,0 Z" fill={theme.mainColor}></path>
          </g>
        </g>
      </g>
    </svg>
  );
  /* eslint-enable */
}

SvgLabs.propTypes = {
  theme: PropTypes.object.isRequired,
};

export default withTheme(SvgLabs);
