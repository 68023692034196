import _ from 'lodash';
import { autobind } from 'core-decorators';
import ServicesApi from './services';
import BkmdApi from './bkmdApi';

const COMM_SERVICE = 'communication';
const SERVICE_NAME = 'communicationManager';

@autobind
export default class CommunicationApi extends BkmdApi {
  constructor(fetch) {
    super(fetch, '/communication/');
    this.servicesApi = new ServicesApi(fetch);
  }

  /**
   * Gets the communication data mapping: all available variables that can be used in emails, sms..
   * @returns {*}
   */
  getDataMapping() {
    return this.servicesApi.callSecure(SERVICE_NAME, 'getDataMapping');
  }

  /**
   * Sends an appointment to users calendar.
   * @param appointmentId - the Id of the appointment which details need to be sent.
   * @returns {*}
   */
  addToCalendar(callSid) {
    return this.post('addToCalendar', { callSid });
  }

  getHistory(queryParams) {
    return this.servicesApi.callSecure(SERVICE_NAME, 'getHistory', queryParams);
  }

  getHistoryRecord(id) {
    return this.servicesApi
      .callSecure(SERVICE_NAME, 'getHistoryRecord', { id })
      .then(res => res.data);
  }

  getChatHistoryOfMember(queryParams) {
    return this.servicesApi.callSecure(SERVICE_NAME, 'getChatHistoryOfMember', queryParams);
  }

  sendChatSmsToMember(queryParams) {
    return this.servicesApi.callSecure(SERVICE_NAME, 'sendChatSmsToMember', queryParams);
  }

  listTemplates(queryParams) {
    return this.servicesApi.callSecure(SERVICE_NAME, 'listTemplates', queryParams);
  }

  getTemplateById(templateId) {
    return this.servicesApi.callSecure(SERVICE_NAME, 'getTemplateById', { templateId });
  }

  createTemplate(payload) {
    return this.servicesApi.callSecure(SERVICE_NAME, 'createTemplate', payload);
  }

  updateTemplate(payload) {
    return this.servicesApi.callSecure(SERVICE_NAME, 'updateTemplate', payload);
  }

  deleteTemplate(payload) {
    return this.servicesApi.callSecure(SERVICE_NAME, 'deleteTemplate', payload);
  }

  manualNotify(payload, domain) {
    return this.servicesApi.callSecure(SERVICE_NAME, 'backofficeNotify', payload, domain);
  }

  notifyBundle(sharedMeta, bundle) {
    return this.servicesApi.callSecure(SERVICE_NAME, 'backofficeNotifyBundle', {
      sharedMeta,
      bundle,
    });
  }

  previewOutreach(channel, templateId, params) {
    return this.servicesApi.callSecure(SERVICE_NAME, 'previewOutreach', {
      channel,
      templateId,
      params,
    });
  }

  getMetadata() {
    return this.servicesApi.callSecure(SERVICE_NAME, 'getMetadata', {});
  }

  validatePhoneNumber(phoneNumber) {
    // There is some old code that sends an object { phoneNumber } as the parameter
    // This is why we test for it and otherwise just use it
    phoneNumber = _.get(phoneNumber, 'phoneNumber', phoneNumber);
    return this.servicesApi.call(COMM_SERVICE, 'validatePhoneNumber', { phoneNumber });
  }

  inviteDependent(dependentWithContactInfo) {
    return this.post('/inviteDependent', dependentWithContactInfo);
  }

  getLastTextOfRecipient(recipient, status) {
    return this.servicesApi.callSecure(SERVICE_NAME, 'getLastTextOfRecipient', {
      recipient,
      status,
    });
  }
}
