import React from 'react';
import { compose, pure, withProps } from 'recompose';
import { defineMessages } from 'react-intl';
import { withTranslatedMessages } from '../../utils/withTranslatedMessages';
import SCR007Info from '../../screenTemplates/SCR007Info';
import SvgHandReminder from '../../assets/svg/handReminder';

const messages = defineMessages({
  title: {
    defaultMessage: 'Thank you! We\'ll send you a reminder for next colon cancer screening.',
    id: 'colorectalFlow.setAReminderConfirmation.title',
  },
});

const SetAReminderConfirmation = compose(
  pure,
  withTranslatedMessages(messages),
  withProps({
    svgIcon: <SvgHandReminder />,
    showButton: false,
  })
)(SCR007Info);

export default SetAReminderConfirmation;
