/**
 * Created by guyavarham on 16/03/2017.
 */
import _ from 'lodash';
import qs from 'qs';
import { replace, push } from 'react-router-redux';

import { pathsWithQuery } from '../../../utils/pathsWithQuery';
import queryStringParseOptions from '../../../utils/queryStringParseOptions';

export const getQueryObject = queryString => qs.parse(queryString, queryStringParseOptions);

export default function setInQueryString(payload, pushToHistory = false, routerState = {}) {
  return ({ getState }) => {
    const state = getState();
    const pathname = _.get(state, 'routing.locationBeforeTransitions.pathname');
    const currQueryString = _.trimStart(
      _.get(state, 'routing.locationBeforeTransitions.search'), '?');

    const currQuery = getQueryObject(currQueryString);

    const newQuery = _.assign(currQuery, payload);
    const action = pushToHistory ? push : replace;

    if (!_.isEmpty(routerState)) {
      return action({ pathname, query: newQuery, state: routerState });
    }

    const urlWithQS = pathsWithQuery(pathname, newQuery);
    return action(urlWithQS);
  };
}
