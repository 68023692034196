import _ from 'lodash';
import { PHYSICIAN_TYPE, TAXONOMY, CLINIC } from '../../components/freeText/resultType';
import Formatter from '../formatter';

export function findResponseMapping({ data }) {
  const providers = data.providers.map(currProvider => {
    const { firstName, lastName, profilePicture } = currProvider;
    return {
      data: {
        ...currProvider,
        fullName: Formatter.toFullName(firstName, null, lastName),
        hasProfilePicture: !_.isEmpty(profilePicture),
        taxonomiesCodes: currProvider.taxonomies.map(({ taxonomyCode, taxonomyDisplayName }) => ({
          code: taxonomyCode,
          desc: taxonomyDisplayName,
        })),
      },
    };
  });
  return { data: providers, metadata: { count: data.total } };
}

export function freeTextMapping({ data }) {
  // change when removing flag separateTaxonomies
  const taxonomies = {
    data: _.map(data.suggestions.taxonomies, taxonomy => ({
      data: {
        codes: _.filter(taxonomy.filters, { type: 'TAXONOMY' }).map(
          filter => filter.value.taxonomyCode,
        ),
        name: taxonomy.searchTerm,
      },
      meta: { type: TAXONOMY, matchType: taxonomy.matchType },
    })),
  };
  const physicians = {
    data: data.suggestions.provider.map(currProvider => {
      const { taxonomies, ...currProviderRest } = currProvider;
      return {
        data: {
          ...currProviderRest,
          taxonomiesCodes: taxonomies.map(({ taxonomyCode, taxonomyDisplayName }) => ({
            code: taxonomyCode,
            desc: taxonomyDisplayName,
          })),
        },
        meta: {
          type: PHYSICIAN_TYPE,
          matchType: currProvider.matchType,
        },
      };
    }),
  };
  const clinics = {
    data: data.suggestions.clinic.map(currClinic => ({
      data: {
        name: currClinic.name,
        // verify that name is the displayed name and officeName is the searchTerm
        officeName: currClinic.searchTerm,
        address: Formatter.addressFormat(currClinic),
        specialties: _.filter(currClinic.filters, { type: 'TAXONOMY' }).map(
          ({ value: { taxonomyCode, taxonomyDisplayName } }) => ({
            taxonomyCode,
            taxonomyDisplayName,
          }),
        ),
      },
      meta: { type: CLINIC, matchType: currClinic.matchType },
    })),
  };

  const formattedResults = { physicians, taxonomies, clinics };
  // add all match types into one array per category
  _.each(formattedResults, category => {
    _.set(
      category,
      'meta.matchType',
      _.reduce(
        category.data,
        (accumulator, { meta }) => accumulator.add(...meta.matchType),
        new Set(),
      ),
    );
  });

  // sort categories by match type
  const sortedResults = _.chain(formattedResults)
    .mapValues((results, category) => ({ category, ...results }))
    .sortBy(formattedResults, result => !result.meta.matchType.has('EXACT_MATCH'))
    .keyBy('category')
    .value();

  return { ...sortedResults, freeText: data.searchTerm };
}
