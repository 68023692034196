import _ from 'lodash';
import React from 'react';
import { autobind } from 'core-decorators';
import PropTypes from 'prop-types';
import { compose, withHandlers, withState } from 'recompose';
import FilterModal from '../../../components/searchDoctors/filter/filterModal';
import LocationSelectWithGeoAndZip from '../../../components/molecules/locationSelectWithGeoAndZip/locationSelectWithGeoAndZip';

const initialState = 'notInitialized';

@autobind
class ProviderFilterModal extends React.PureComponent {
  static defaultProps = {
    filters: {},
    languageFilterOn: false,
    distanceFilterOn: false,
    genderFilterOn: false,
    currLocationState: undefined,
    defaultLocationOptions: [],
  };

  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    filters: PropTypes.object,
    currLocationState: PropTypes.object,
    isGeocoding: PropTypes.bool.isRequired,
    languageFilterOn: PropTypes.bool,
    genderFilterOn: PropTypes.bool,
    distanceFilterOn: PropTypes.bool,
    toggleGeocoding: PropTypes.func.isRequired,
    onApply: PropTypes.func.isRequired,
    setLocation: PropTypes.func.isRequired,
    defaultLocationOptions: PropTypes.array,
  };

  onApply(params) {
    // here params will not have the updated state of locaion.
    // Its because filterModal does not manage the state off location.
    const { onApply, currLocationState, filters } = this.props;

    const locationFromQS = _.get(filters, 'location');

    // The current component (providerFilterModal) manage the location box state
    // with currLocationState which will be null until it has a change
    const newLocation = _.isEqual(currLocationState, initialState)
      ? locationFromQS
      : currLocationState;

    onApply({ ...params, location: newLocation });
  }

  locationChanged(newLocation) {
    this.props.setLocation(newLocation); // on every address box change we update our inner state
  }

  renderLocation() {
    const {
      toggleGeocoding,
      filters: { location },
      defaultLocationOptions,
    } = this.props;
    return (
      <LocationSelectWithGeoAndZip
        onChange={this.locationChanged}
        location={location}
        onStartGeocode={toggleGeocoding}
        onFinishGeocode={toggleGeocoding}
        defaultOptions={defaultLocationOptions}
      />
    );
  }

  render() {
    const {
      isOpen,
      onClose,
      filters,
      isGeocoding,
      genderFilterOn,
      distanceFilterOn,
      languageFilterOn,
    } = this.props;

    return (
      <FilterModal
        isOpen={isOpen}
        onClose={onClose}
        filters={filters}
        onApply={this.onApply}
        isGeocoding={isGeocoding}
        locationComponent={this.renderLocation}
        genderFilterOn={genderFilterOn}
        distanceFilterOn={distanceFilterOn}
        languageFilterOn={languageFilterOn}
      />
    );
  }
}

export default compose(
  withState('currLocationState', 'setLocation', initialState),
  withState('isGeocoding', 'setIsGeocoding', false),
  withHandlers({
    toggleGeocoding: ({ isGeocoding, setIsGeocoding }) => () => setIsGeocoding(!isGeocoding),
  }),
)(ProviderFilterModal);
