import React from 'react';
import { autobind } from 'core-decorators';
import { defineMessages, injectIntl } from 'react-intl';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import CoOpLogo from '../../../../components/branding/co_opLogo';
import RoundedImage from '../../../../components/ui/roundedImage/roundedImage';
import { getVimGuideSelector } from '../../../../store/vimGuide/selectors';
import queryConnect from '../../../../store/tools/queryString/queryConnect';
import { getVimGuideByUserId } from '../../../../store/vimGuide/actions';
import { withShortActivation } from '../../../../store/activation/withShortActivation';
import fetchNotEmpty from '../../../../store/tools/fetchNotEmpty';
import ActivationLanding from '../../../../components/activationLanding/activationLanding';
import { paths } from '../routes/openDirectoryRoutes';
import Header from '../../../../components/ui/header/header';
import { getDomain } from '../../../../store/feature/selectors';
import withFeatureFlag from '../../../../utils/featureFlags/withFeatureFlag';
import * as FlagTypes from '../../../../utils/featureFlags/flagTypes';

const messages = defineMessages({
  title: {
    defaultMessage: 'Your {vimGuideTitle} {guideName} has invited you to Vim',
    id: 'members.inviteToVim.byVimGuide.title',
  },
  buttonText: {
    defaultMessage: 'Continue to Vim',
    id: 'members.inviteToVim.byVimGuide.buttonText',
  },
});

@autobind
class InviteByVimGuide extends React.Component {
  static propTypes = {
    vimGuide: PropTypes.object.isRequired,
    intl: PropTypes.object.isRequired,
    params: PropTypes.object.isRequired,
    onActivateClicked: PropTypes.func.isRequired,
    domain: PropTypes.string.isRequired,
    vimGuideTitle: PropTypes.string.isRequired,
  };

  onClick() {
    const {
      onActivateClicked,
      params: { token },
    } = this.props;
    onActivateClicked(token, paths.home());
  }

  renderGuideImage() {
    const { profilePicture } = this.props.vimGuide;
    return (
      <span className="pic-wrap">
        <RoundedImage
          className="doctor-img big"
          src={profilePicture || require('../../../../assets/images/logos/vimHeart.png')}
        />
      </span>
    );
  }

  render() {
    const { vimGuide, intl, vimGuideTitle } = this.props;
    const values = {
      guideName: _.get(vimGuide, 'firstName'),
      vimGuideTitle,
    };
    const translatedMessages = _.mapValues(messages, message =>
      intl.formatMessage(message, values),
    );
    return (
      <div>
        <Header className="text-center">
          <div className="logos">
            <CoOpLogo externalPageLogo />
          </div>
        </Header>
        <ActivationLanding
          onActivateClicked={this.onClick}
          renderProfileImage={this.renderGuideImage}
          {...translatedMessages}
        />
      </div>
    );
  }
}

export default compose(
  injectIntl,
  withRouter,
  queryConnect(query => ({
    vimGuideUserId: query.vimGuideUserId,
  })),
  fetchNotEmpty(
    ({ props: { vimGuideUserId } }) => getVimGuideByUserId(vimGuideUserId),
    getVimGuideSelector,
    { renderAlways: false, loading: true },
  ),
  connect(state => ({
    vimGuide: getVimGuideSelector(state),
    domain: getDomain(state),
  })),
  withShortActivation,
  withFeatureFlag({
    key: 'atlas.vimGuideTitle',
    prop: 'vimGuideTitle',
    defaultValue: 'Vim Guide',
    type: FlagTypes.String,
    context: ({ domain }) => ({ domain }),
  }),
)(InviteByVimGuide);
