import { branch, compose, renderNothing } from 'recompose';
import { get } from 'lodash';
import { connect } from 'react-redux';
import * as NetworkStatus from '../../../model/enum/networkStatus';
import { getFeature } from '../../../store/feature/selectors';
import { memberContext } from '../../../store/member/selectors';
import CostEstimationCard from './costEstimationCard';

export default compose(
  connect(state => ({
    costEstimationFlag: getFeature(state, 'enableCostEstimation', false, memberContext(state)),
    app: state.config.appName,
  })),
  branch(
    ({ costEstimationFlag, location }) =>
      !(costEstimationFlag && get(location, 'network.status') === NetworkStatus.IN_NETWORK),
    renderNothing,
  ),
)(CostEstimationCard);
