import PropTypes from 'prop-types';
import React from 'react';
import { compose, pure } from 'recompose';
import { connect } from 'react-redux';
import { FormattedMessage, defineMessages } from 'react-intl';
import PcpSearchTypeahead from './pcpSearchTypeahead';
import BkmdModal from '../../bkmdModal/bkmdModal';
import ModalHeader from '../../bkmdModal/modalHeader';
import { getBrowserHeight } from '../../../store/browser/selectors';

import '../../searchDoctors/searchModal.less';

const messages = defineMessages({
  findADoctor: {
    defaultMessage: 'Find a doctor',
    id: 'pcpSelection.pcpSearchModal.findADoctor'
  },
});

const headerHeight = 106;

function PcpSearchModal({ isOpen, handleClose, name, maxHeight, updatePcpSelection }) {
  return (
    <div>
      <BkmdModal
        isOpen={isOpen}
        handleClose={handleClose}
        name={name}
        className="fixed-header search-modal"
      >
        <ModalHeader onClose={handleClose} title="">
          <h4 className="modal-title text-left">
            <FormattedMessage {...messages.findADoctor} />
          </h4>
        </ModalHeader>
        <div className="dialog-body">
          <div className="header-search-input">
            <PcpSearchTypeahead
              containerClasses="left-search-icon no-right-border hide-arrow"
              onChange={handleClose}
              maxHeight={maxHeight}
              updatePcpSelection={updatePcpSelection}
              autoFocus
            />
          </div>
        </div>
      </BkmdModal>
    </div>
  );
}

PcpSearchModal.defaultProps = {
  name: 'search_modal'
};

PcpSearchModal.propTypes = {
  maxHeight: PropTypes.number.isRequired,
  handleClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  updatePcpSelection: PropTypes.func.isRequired,
  name: PropTypes.string,
};

export default compose(
  pure,
  connect(
    state => ({
      maxHeight: getBrowserHeight(state) - headerHeight,
    }),
  ),
)(PcpSearchModal);
