import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withTheme } from '../../../utils/theme';

const textSize = {
  default: '',
  '12px': 'text-12',
  '13px': 'text-13',
  '14px': 'text-14',
  '16px': 'text-16',
  '18px': 'text-18',
  '22px': 'text-22',
};

const textColor = {
  default: '',
  dark: 'text-dark',
  extraDark: 'text-extra-dark',
  light: 'text-faded',
  theme: '',
  themeSecondary: '',
};

const textAlign = {
  default: 'text-center',
  left: 'text-left',
  right: 'text-right',
};

const textWeight = {
  default: '',
  light: 'text-light',
  medium: 'text-medium',
  semibold: 'text-semibold',
  bold: 'text-strong',
};

function Text({
  text,
  children,
  size,
  color,
  theme,
  weight,
  align,
  inline,
  className,
  ellipsis,
  html,
  ...other
}) {
  const textInline = inline ? 'inline-block' : undefined;
  const textEllipsis = ellipsis ? 'three-dots' : undefined;
  let dynamicStyle = {};
  if (color === 'theme') dynamicStyle = { color: theme.mainColor };
  else if (color === 'themeSecondary') dynamicStyle = { color: theme.secondaryColor };

  const innerHTML = html && { dangerouslySetInnerHTML: { __html: text } };
  const divChildren = !html ? [text, children] : null;
  return (
    <div
      className={classNames(
        textSize[size],
        textColor[color],
        textAlign[align],
        textWeight[weight],
        textEllipsis,
        textInline,
        className,
      )}
      style={dynamicStyle}
      // eslint-disable-next-line
      {...innerHTML}
      {...other}
    >
      {divChildren}
    </div>
  );
}

Text.propTypes = {
  text: PropTypes.string,
  size: PropTypes.string,
  color: PropTypes.string,
  align: PropTypes.string,
  weight: PropTypes.string,
  theme: PropTypes.object.isRequired,
  inline: PropTypes.bool,
  ellipsis: PropTypes.string,
  className: PropTypes.string,
  html: PropTypes.bool,
};

Text.defaultProps = {
  size: 'default',
  color: 'default',
  align: 'default',
  weight: 'default',
  inline: false,
  ellipsis: '',
  className: '',
  text: '',
  html: false,
};

export default withTheme(Text);
