import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './Button.less';
import { withTheme } from '../../../utils/theme';
import Loader from '../Loader/Loader';

const btnColor = {
  default: undefined,
  gray: '#6E6E6E',
  darkGray: '#5C5C5C',
  green: '#278600',
  red: '#B12704',
  orange: '#B55A00',
};

const btnType = {
  small: 'btn-small',
  large: 'btn-big',
  huge: 'btn-huge',
  pill: 'btn-small btn-pill',
  link: 'btn-inline-link',
};

const btnWidth = {
  default: '',
  block: 'btn-block',
  small: 'btn-min-100',
  medium: 'btn-min-300',
};

const btnBgColor = {
  default: 'default-color',
  gray: 'btn-grey',
  lightGray: 'btn-light-grey',
  white: 'btn-outline',
  red: 'btn-red',
};

function Button({
  text,
  type,
  width,
  color,
  bgColor,
  multiLines,
  underline,
  isLoading,
  theme,
  className,
  selected,
  children,
  disabled,
  buttonType,
  ...rest
}) {
  const isDisabled = isLoading || disabled;
  const bgStyle = bgColor === 'default' ? { backgroundColor: theme.buttonColor } : {};
  const selectedStyle = selected
    ? { borderColor: theme.secondaryColor, color: theme.secondaryColor }
    : {};
  const linkStyle = type === 'link' ? { color: theme.linkColor } : {};
  const inlineColor = btnColor[color];
  const inlineColorStyle = inlineColor ? { color: btnColor[color] } : {};
  const dynamicStyle = { ...bgStyle, ...selectedStyle, ...linkStyle, ...inlineColorStyle };
  const btnMultiLines = multiLines ? 'two-lines-btn' : undefined;
  const btnUnderline = underline ? 'text-underline' : undefined;
  const loaderColor = bgColor === 'white' ? 'grey' : 'white';
  return (
    <button
      type={buttonType}
      className={classNames(
        'btnx',
        btnType[type],
        btnWidth[width],
        btnBgColor[bgColor],
        btnMultiLines,
        btnUnderline,
        className,
      )}
      style={dynamicStyle}
      disabled={isDisabled}
      {...rest}
    >
      {isLoading && <Loader type="dots" size="small" padding="no-padding" color={loaderColor} />}
      {!isLoading && text}
      {!isLoading && children}
    </button>
  );
}

Button.propTypes = {
  text: PropTypes.string,
  type: PropTypes.string,
  width: PropTypes.string,
  className: PropTypes.string,
  bgColor: PropTypes.string,
  multiLines: PropTypes.bool,
  underline: PropTypes.bool,
  isLoading: PropTypes.bool,
  selected: PropTypes.bool,
  theme: PropTypes.object.isRequired,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  buttonType: PropTypes.string,
};

Button.defaultProps = {
  text: '',
  type: 'large',
  width: '',
  className: '',
  bgColor: 'default',
  multiLines: false,
  underline: false,
  isLoading: false,
  selected: false,
  disabled: false,
  color: 'default',
  buttonType: 'submit',
};

export default withTheme(Button);
