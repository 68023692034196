/**
 * Created by chenrozenes on 09/08/2017.
 */
import { defineMessages } from 'react-intl';
import * as Id from './id';

export default defineMessages({
  considerGetting: {
    defaultMessage: 'Consider getting your',
    id: 'careCues.labels.considerGetting',
  },
  [Id.BKMD01]: {
    defaultMessage: 'Breast cancer screening',
    id: 'careCues.labels.bkmd01',
  },
  [Id.BKMD02]: {
    defaultMessage: 'Cervical cancer screening',
    id: 'careCues.labels.bkmd02',
  },
  [Id.BKMD03]: {
    defaultMessage: 'Chlamydia screening',
    id: 'careCues.labels.bkmd03',
  },
  [Id.BKMD04]: {
    defaultMessage: 'Colorectal cancer screening',
    id: 'careCues.labels.bkmd04',
  },
  [Id.BKMD05]: {
    defaultMessage: 'Annual diabetes eye exam',
    id: 'careCues.labels.bkmd05',
  },
  [Id.BKMD06]: {
    defaultMessage: 'Annual HbA1c test',
    id: 'careCues.labels.bkmd06',
  },
  [Id.BKMD07]: {
    defaultMessage: 'Annual kidney urine test',
    id: 'careCues.labels.bkmd07',
  },
  [Id.BKMD08]: {
    defaultMessage: 'Following up on your fracture',
    id: 'careCues.labels.bkmd08',
  },
  [Id.BKMD09]: {
    defaultMessage: 'Following up on your arthritis',
    id: 'careCues.labels.bkmd09',
  },
  [Id.BKMD10]: {
    defaultMessage: 'Check your cholesterol, stay heart healthy!',
    id: 'careCues.labels.bkmd10',
  },
  [Id.BKMD11]: {
    defaultMessage: 'annual flu shot.',
    id: 'careCues.labels.bkmd11',
  },
  [Id.BKMD12]: {
    defaultMessage: 'Start your healthy journey with your doctor this year',
    id: 'careCues.labels.bkmd12',
  },
});
