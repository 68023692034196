import { compose, withHandlers, branch } from 'recompose';
import _ from 'lodash';
import withActivationFlow from '../withActivationFlow/withActivationFlow';
import { withFetchersOnMount } from '../../api/injectApi/withFetchersOnMount';
import withEffects from '../recompose/withEffects';
import { removePublicToken } from '../../utils/publicToken';

function clearPublicToken() {
  removePublicToken();
}

const withTokenizedFlow = ({
  activationCampaignSource,
  activationNextPage,
  activatedFlowPath,
  tokenGeneratorHandler,
}) =>
  compose(
    withEffects({
      clearToken: {
        action: clearPublicToken,
        didMountPredicate: () => true,
      },
    }),
    withActivationFlow({
      activationCampaignSource,
      activationNextPage,
    }),
    withHandlers({
      redirectToActivatedFlow: props => token => {
        let activatedPath = activatedFlowPath;
        if (_.isFunction(activatedFlowPath)) {
          activatedPath = activatedFlowPath(token, props);
        } else if (_.isEmpty(activatedFlowPath)) {
          activatedPath = `${activationNextPage}/${token}`;
        }
        return props.router.push(activatedPath);
      },
    }),
    branch(
      ({ hasToken }) => hasToken,
      withFetchersOnMount({
        getToken: {
          handler: ({ activationToken }) => () => tokenGeneratorHandler(activationToken),
          onSuccess: ({ redirectToActivatedFlow }, result) => redirectToActivatedFlow(result),
          onError: ({ redirectToActivationFlow }) => {
            clearPublicToken();
            redirectToActivationFlow();
          },
        },
      }),
    ),
  );

export default withTokenizedFlow;
