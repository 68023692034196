/**
 * Created by chenrozenes on 09/03/2017.
 */
import _ from 'lodash';
import Promise from 'bluebird';
import PromiseActionType from '../../promiseActionType';
import { freeTextMapping } from '../../../utils/mapping/search';
import getBrowserLocation from '../../location/selectors';
import { enrichGeoWithZipAndState } from '../../../utils/geocodeByLocation';

export const SEARCH_FREE_TEXT = new PromiseActionType('SEARCH_FREE_TEXT');
export const SEARCH_SUGGESTIONS = new PromiseActionType('SEARCH_SUGGESTIONS');

export function searchFreeText(
  text,
  location,
  initialSearch = false,
  onlyInNetwork,
  memberToken,
) {
  return ({ bkmdApi: { searchApi }, getState }) => {
    if (!location) location = getBrowserLocation(getState());
    return {
      type: SEARCH_FREE_TEXT.SOURCE,
      payload: {
        promise: _.isEmpty(text) ? Promise.resolve([]) :
          enrichGeoWithZipAndState(location)
          .then(enrichedLocation =>
            searchApi.freeText({
              freeText: text,
              geo: {
                geocode: _.pick(enrichedLocation, ['latitude', 'longitude']),
                zip: _.get(enrichedLocation, 'zip'),
              },
              filters: {
                state: enrichedLocation.state,
                onlyInNetwork,
              },
              memberToken,
            }).then(res => freeTextMapping(res))),
      },
      meta: {
        tracker: SEARCH_FREE_TEXT.SOURCE,
        initialSearch,
      },
    };
  };
}

async function getSuggestions(searchApi, suggestions) {
  return Promise.map(suggestions, freeText => searchApi.freeText({ freeText })).then(
    arrayOfResults => {
      const response = {
        taxonomies: [],
      };

      _.each(arrayOfResults, origCurr => {
        const curr = freeTextMapping(origCurr);
        _.each(curr.taxonomies.data, option => {
          // return only the exact options we were requested
          if (_.includes(suggestions, option.data.name)) response.taxonomies.push(option);
        });
      });

      return response;
    },
  );
}

export function suggestions(configuredSuggestions) {
  return ({ bkmdApi: { searchApi } }) => ({
    type: SEARCH_SUGGESTIONS.SOURCE,
    payload: {
      promise: getSuggestions(searchApi, configuredSuggestions),
    },
    meta: {
      tracker: SEARCH_SUGGESTIONS.SOURCE,
    },
  });
}
