/**
 * Created by moran on 5/22/17.
 */
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { autobind } from 'core-decorators';
import { defineMessages, injectIntl } from 'react-intl';
import CleanTagList from '../form/cleanTagList';
import LanguagesList from '../../model/enum/languages';

const messages = defineMessages({
  languages: {
    defaultMessage: 'Languages:',
    id: 'components.cleanLanguages.languages'
  },
  placeholder: {
    defaultMessage: 'Enter Languages',
    id: 'components.cleanLanguages.placeholder'
  }
});

@autobind
class LanguagesTags extends React.Component {
  static propTypes = {
    value: PropTypes.array,
    onChange: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
    track: PropTypes.bool,
  };

  static defaultProps = {
    value: [],
    track: true,
  };

  onChange(languagesTags) {
    this.props.onChange(_.map(languagesTags, lang => ({ id: lang.value, name: lang.label })));
  }

  render() {
    return (<div>
      <CleanTagList
        placeholder={this.props.intl.formatMessage(messages.placeholder)}
        value={_.map(this.props.value, lang => ({ value: lang.id, label: lang.name }))}
        suggestions={_.map(LanguagesList, lang => ({ value: lang.id, label: lang.name }))}
        onChange={this.onChange}
        label={messages.languages}
        track={this.props.track}
        name="LANGUAGE_TAGS"
      />
    </div>);
  }
}

export default injectIntl(LanguagesTags);
