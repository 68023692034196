import React from 'react';
import PropTypes from 'prop-types';
import { BkmdModal, ModalHeader } from '../bkmdModal';

const ProviderModal = props => {
  const { onClose, isOpen, className, title, subtitle, mainText } = props;

  return (
    <BkmdModal isOpen={isOpen} className={className} handleClose={onClose}>
      <ModalHeader onClose={onClose} title={title} />
      <div className="dialog-body">
        <div className="row">
          <div className="col-xs-12 text-center">
            <img
              src={require('../../assets/images/help-modal-top.png')}
              className="tell-me-more-help-modal-img"
              alt=""
            />
          </div>
        </div>
        <div className="row margin-top-30">
          <div className="col-xs-12 text-dark text-18 text-semibold text-center">{subtitle}</div>
        </div>
        <div className="row">
          <div className="col-xs-12 margin-top-30">{mainText}</div>
        </div>
      </div>
    </BkmdModal>
  );
};

ProviderModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  className: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  mainText: PropTypes.string
};

ProviderModal.defaultProps = {
  onBack: undefined,
  className: 'fixed-header',
  title: '',
  subtitle: '',
  mainText: ''
};

export default ProviderModal;
