import { withPropsOnChange, compose } from 'recompose';
import { mapValues, noop, isFunction } from 'lodash';
import { injectIntl } from 'react-intl';

export const getMessages = function getTranslatedMessages(intl, messages, values) {
  return mapValues(messages, message => intl.formatMessage(message, values));
};

export const withTranslatedMessages = (messagesGetter = noop, valuesGetter = noop) =>
  compose(
    injectIntl,
    withPropsOnChange(['intl'], ({ intl, ...rest }) => {
      const messages = !isFunction(messagesGetter) ? messagesGetter : messagesGetter(rest);
      const calculatedMessages = getMessages(intl, messages, valuesGetter(rest));
      return {
        ...calculatedMessages,
        messages: {
          ...calculatedMessages,
        },
      };
    }),
  );
