/**
 * Created by galgoltzman on 14/05/2017.
 */
import React from 'react';
import { Link } from 'react-router';
import PropTypes from 'prop-types';
import { defineMessages, FormattedMessage } from 'react-intl';

import Layout from '../../layout/layout';
import Formatter from '../../../../../utils/formatter';
import commonMessages from '../../../../../store/intl/commonMessages';
import SettingsLayout from '../settingsLayout';
import { paths } from '../paths';
import SvgUserCircle from '../../../../../assets/svg/userCircle';

const messages = defineMessages({
  profile: {
    defaultMessage: 'Profile',
    id: 'members.settings.profile',
  },
  password: {
    defaultMessage: 'Password',
    id: 'members.settings.password',
  },
  footerNote: {
    defaultMessage: 'FYI, these changes are not reflected on your health plan account.',
    id: 'members.settings.footerNote',
  },
});

class Profile extends React.PureComponent {
  static propTypes = {
    phoneNumber: PropTypes.object.isRequired,
    phoneNumberVerified: PropTypes.bool.isRequired,
    email: PropTypes.string.isRequired,
    emailVerified: PropTypes.bool.isRequired,
    fullName: PropTypes.string.isRequired,
  };

  render() {
    const { fullName, phoneNumber, phoneNumberVerified, email, emailVerified } = this.props;
    return (
      <Layout
        hideLogo
        centeredBackBtn
        previousPageName="settings"
        previousPageRoute={paths.settingsHome()}
      >
        <SettingsLayout>
          <div className="profile-basic-info margin-top-30">
            <SvgUserCircle />
            <div className="basic-info-text">
              <span className="text-18 text-dark text-semibold">{fullName}</span>
            </div>
          </div>
          <ul className="settings-menu-list">
            <li>
              <Link to={paths.updatePhoneNumber()}>
                <i className="icon-iphone item-icon" aria-label="phone number" />
                <div className="item-content">
                  <span className="text-dark text-16 text-semibold">
                    {Formatter.phoneNumber(phoneNumber)}
                  </span>
                  {phoneNumberVerified ? (
                    <div className="text-12 text-success">
                      <FormattedMessage {...commonMessages.verified} />
                    </div>
                  ) : (
                    <div className="text-12 text-danger">
                      <FormattedMessage {...commonMessages.unverified} />
                    </div>
                  )}
                </div>
                <i className="icon-chevron-right item-arrow" />
              </Link>
            </li>
            <li>
              <Link to={paths.updateEmail()}>
                <i className="icon-envelope item-icon" />
                <div className="item-content">
                  <span className="text-dark text-16 text-semibold">{email}</span>
                  {emailVerified ? (
                    <div className="text-12 text-success">
                      <FormattedMessage {...commonMessages.verified} />
                    </div>
                  ) : (
                    <div className="text-12 text-danger">
                      <FormattedMessage {...commonMessages.unverified} />
                    </div>
                  )}
                </div>
                <i className="icon-chevron-right item-arrow" />
              </Link>
            </li>
            <li>
              <Link to={paths.updatePassword()}>
                <i className="icon-heart-key item-icon" />
                <div className="item-content">
                  <span className="text-dark text-16 text-semibold">
                    <FormattedMessage {...messages.password} />
                  </span>
                </div>
                <i className="icon-chevron-right item-arrow" />
              </Link>
            </li>
          </ul>
          <div className="text-14">
            <FormattedMessage {...messages.footerNote} />
          </div>
        </SettingsLayout>
      </Layout>
    );
  }
}

export default Profile;
