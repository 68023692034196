import _ from 'lodash';
import { getFeature } from '../feature/selectors';

/**
 * Created by guyavarham on 27/06/2017.
 */

export const isSearchModalOpenSelector = state => state.ui.isSearchModalOpen;
export const focusSearchTypeaheadNow = state => _.get(state, 'ui.focusSearchTypeaheadNow');
export const isSidebarMenuOpened = state => state.ui.isSidebarMenuOpened;
export const isDirectoryAllowed = state =>
  getFeature(state, 'securedDirectory') || getFeature(state, 'openDirectory');

export default { isSearchModalOpenSelector };
