import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { compose, withStateHandlers, lifecycle } from 'recompose';

import BkmdModal from '../../../bkmdModal/bkmdModal';
import ModalHeader from '../../../bkmdModal/modalHeader';
import MiniDoctorCard from '../../../miniDoctorCard/miniDoctorCard';
import Api from '../../../../api/index';

import CostEstimationSlider from '../../../molecules/costEstimationSlider/costEstimationSlider';
import './costEstimationModal.less';
import CleanSelect from '../../../../components/form/cleanSelect';
import { withStateFetchersOnMount } from '../../../../api/injectApi/withStateFetchersOnMount';
import { ThreeBounceSpinner } from '../../../../components/ui/spinner/index';

const messages = defineMessages({
  modalTitle: {
    defaultMessage: 'Estimate your cost',
    id: 'costEstimationModal.modalTitle',
  },
  closeBtnLabel: {
    defaultMessage: 'Close this window',
    id: 'costEstimationModal.closeBtnLabel',
  },
  bookAnAppointment: {
    defaultMessage: 'Book an appointment',
    id: 'costEstimationModal.bookAnAppointment',
  },
  mainText: {
    defaultMessage: 'your estimated out of pocket cost for ',
    id: 'costEstimationModal.mainText',
  },
  footerNote: {
    defaultMessage: `<strong>Actual healthcare costs vary based on your health plan,
    your healthcare needs, and where you receive care.</strong></br>
    This website does not guarantee you will be covered for any service or for the costs shown
    for any service.
    Check with your health plan to find out exactly what is covered.`,
    id: 'costEstimationModal.footerNote',
  },
});

// TODO: Seperate template from page here.
function CostEstimationModal({
  onClose,
  isOpen,
  provider,
  onChange,
  selectedTreatment,
  treatments,
  costEstimation: { estimate, ...restEstimate },
  getCostEstimationTracker,
}) {
  const title = estimate >= 0 ? `$${estimate}` : 'Unavailable';
  return (
    <BkmdModal
      handleClose={onClose}
      isOpen={isOpen}
      className="cost-estimation-dialog fixed-header fixed-footer sidebar-modal"
    >
      <ModalHeader onClose={onClose} title={messages.modalTitle} />
      <div className="dialog-body">
        <div className="main-content">
          <div className="padding-v-20 text-center">
            <MiniDoctorCard
              containerClassName="no-shadow no-pointer inline-block"
              provider={provider}
              docImgSize="doc-img-72"
              showSuffix
            />
          </div>

          <div className="title-36 margin-10 text-semibold font-color-brand-secondary text-center">
            <div>{title}</div>
          </div>
          {getCostEstimationTracker.inProgress && <ThreeBounceSpinner />}

          <div className="title-brand-1 title-24 text-center no-margin">
            <FormattedMessage {...messages.mainText} />
          </div>
          <div className="margin-top-5">
            <CleanSelect
              clearable={false}
              backspaceRemoves={false}
              deleteRemoves={false}
              options={treatments}
              value={selectedTreatment}
              onChange={onChange}
              className="dashed-border-typeahead show-caret"
              selectClassName="margin-30"
            />
          </div>
          <div className="margin-top-30">
            {estimate >= 0 && (
              <CostEstimationSlider
                {...restEstimate}
                average={estimate}
                title="Cost estimates for this procedure with this doctor"
              />
            )}
          </div>

          <div className="margin-top-30">
            <button className="btn btn-small btn-block btn-outline" onClick={onClose}>
              <FormattedMessage {...messages.closeBtnLabel} />
            </button>
          </div>
        </div>
        <div className="footer-note">
          <div className="text-center text-light">
            <FormattedHTMLMessage {...messages.footerNote} />
          </div>
        </div>
      </div>
    </BkmdModal>
  );
}

CostEstimationModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  provider: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  selectedTreatment: PropTypes.object.isRequired,
  treatments: PropTypes.array.isRequired,
  costEstimation: PropTypes.object,
  getCostEstimationTracker: PropTypes.object.isRequired,
};

CostEstimationModal.defaultProps = {
  costEstimation: {},
};

export default compose(
  withStateHandlers(
    ({ treatments }) => ({
      selectedTreatment: treatments[0],
    }),
    {
      onChange: () => selected => ({
        selectedTreatment: selected,
      }),
    },
  ),
  withStateFetchersOnMount({
    getCostEstimation: {
      handler: ({ provider, location, domainMemberId, selectedTreatment }) => () =>
        Api.costEstimationApi.estimate(
          provider.npi,
          location.id,
          selectedTreatment.value,
          domainMemberId,
        ),
      resultPropName: 'costEstimation',
    },
  }),
  lifecycle({
    componentDidUpdate(prevProps) {
      if (prevProps.selectedTreatment !== this.props.selectedTreatment) {
        this.props.getCostEstimation();
      }
    },
  }),
)(CostEstimationModal);
