/**
 * created by moran on 11/26/17
 */

import _ from 'lodash';
import Immutable from 'seamless-immutable';

import { GET_SHARE_BY_TOKEN } from './actions';

const initialState = {
  details: {},
};

export default function shareReducer(state = initialState, action) {
  state = Immutable(state);
  switch (action.type) {
    case GET_SHARE_BY_TOKEN.SUCCESS: {
      return state.merge({ details: _.get(action, 'payload.data') });
    }
    case GET_SHARE_BY_TOKEN.START: {
      return Immutable(initialState);
    }
    default:
      return state;
  }
}
