import _ from 'lodash';
import { SdkBookButtonClicked } from './sdkEvents';

const ShareClick = 'share click';
const ShareSubmit = 'share submit click';
const ShareSubmitFailed = 'share submit failed';
const DetailsClick = 'show details click';
const SearchShareAuthFailed = 'search share auth failed';
const ViewedSearchShareProfile = 'viewed search share profile';
const SearchShareBookSuccess = 'search share book success';

export default {
  bookClick: ({ npi, address, ranking, queryId, memberSessionId, correlationId } = {}) => [
    SdkBookButtonClicked,
    { npi, address, ranking, queryId, memberSessionId, correlationId },
  ],
  shareClick: ({ npi, address, ranking, queryId, memberSessionId } = {}) => [
    ShareClick,
    { npi, address, ranking, queryId, memberSessionId },
  ],
  shareSubmitClick: ({ npi, address, ranking, queryId, memberSessionId } = {}) => [
    ShareSubmit,
    { npi, address, ranking, queryId, memberSessionId },
  ],
  shareFailed: ({
    error,
    npi,
    locationId,
    insurer,
    clinicName,
    memberSessionId,
    queryId,
  } = {}) => [
    ShareSubmitFailed,
    {
      npi,
      locationId,
      insurer,
      clinicName,
      memberSessionId,
      queryId,
      errorMessage: _.get(error, 'responseMessage'),
      errorStatus: _.get(error, 'status'),
    }
  ],
  detailsClick: ({ npi, address, ranking, queryId, memberSessionId } = {}) => [
    DetailsClick,
    { npi, address, ranking, queryId, memberSessionId },
  ],
  SearchShareAuthFailed: ({ correlationId, error } = {}) => [
    SearchShareAuthFailed,
    {
      correlationId,
      errorMessage: _.get(error, 'responseMessage'),
      errorStatus: _.get(error, 'status'),
    }
  ],
  viewedSearchShareProfile: ({ insurer, npi, ranking, address, correlationId } = {}) => [
    ViewedSearchShareProfile,
    { insurer, npi, ranking, address, correlationId }
  ],
  searchShareBookSuccess: ({ entityId, insurer, npi, ranking, address, correlationId } = {}) => [
    SearchShareBookSuccess,
    { entityId, insurer, npi, ranking, address, correlationId }
  ],
};
