import React from 'react';

export default function SvgMedal() {
  /* eslint-disable */
  return (
    <svg aria-hidden="true" focusable="false" className="svg-icon svg-icon-tax" width="12px" height="22px" viewBox="0 0 12 22" version="1.1">
      <defs></defs>
      <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Facility-narrow" transform="translate(-411.000000, -96.000000)">
          <g id="Page-1" transform="translate(411.000000, 96.000000)">
            <polygon id="Fill-1" fill="#898989" points="0 0.0004 0 5.9994 5 10.0004 5 0.0004"></polygon>
            <polygon id="Fill-2" fill="#898989" points="7.0002 0.0004 7.0002 10.0004 11.9992 5.9994 11.9992 0.0004"></polygon>
            <polygon id="Fill-3" fill="#08ACE4" points="4.1463 14.3437 0.0003 14.9467 2.9993 17.8697 2.2913 21.9997 6.0003 20.0497 9.7083 21.9997 9.0013 17.8697 11.9993 14.9467 7.8543 14.3437 6.0003 10.5857"></polygon>
          </g>
        </g>
      </g>
    </svg>
  );
  /* eslint-enable */
}
