import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { branch } from 'recompose';
import { defineMessages, FormattedMessage } from 'react-intl';
import { PrivacyPolicyLink, TosLink } from '../ui/links/index';
import FeatureFlagged from '../../components/features/featureFlagged';
import { withTranslatedMessages } from '../../utils/withTranslatedMessages';

const messages = defineMessages({
  byClickingIAgree: {
    defaultMessage: 'By clicking "{activateBtnText}", you agree to Vim\'s',
    id: 'tos.defaultTos.byClickingIAgree',
  },
  and: {
    defaultMessage: 'and',
    id: 'tos.defaultTos.and',
  },
});

const DefaultTermsOfService = ({ isMember, activateText }) => (
  <FeatureFlagged uri="layout.showTermsOfService">
    <div className="row padding-v-20">
      <div className="col-xs-12 text-12">
        <FormattedMessage
          {...messages.byClickingIAgree}
          values={{ activateBtnText: activateText }}
        />
        &nbsp;
        <span className="text-no-line-breaks">
          <TosLink className="underline-link" isMember={isMember} aria-labelledby="new-window-0" />
          &nbsp;
          <i
            className="icon-ext-link i-va-fix-2 text-12"
            id="new-window-0"
            aria-label="external-link"
          />
        </span>
        &nbsp;
        <FormattedMessage {...messages.and} />
        &nbsp;
        <span className="text-no-line-breaks">
          <PrivacyPolicyLink
            className="underline-link"
            isMember={isMember}
            aria-labelledby="new-window-1"
          />
          &nbsp;
          <i
            className="icon-ext-link i-va-fix-2 text-12"
            id="new-window-1"
            aria-label="external-link"
          />
        </span>
      </div>
    </div>
  </FeatureFlagged>
);

DefaultTermsOfService.propTypes = {
  isMember: PropTypes.bool,
  activateText: PropTypes.object.isRequired,
};

DefaultTermsOfService.defaultProps = {
  isMember: false,
};

export default branch(
  ({ activateText }) => !_.isString(activateText),
  withTranslatedMessages(({ activateText }) => ({ activateText })),
)(DefaultTermsOfService);
