/**
 * Created by matan on 3/9/17.
 */
import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';

const wrapperPropTypes = {
  /**
   * redux-forms field meta info.
   * For more info read about "Meta Props" at http://redux-form.com/6.5.0/docs/api/Field.md/
   */
  meta: PropTypes.object.isRequired,
  /**
   * redux-forms field input info.
   * For more info read about "Input Props" at http://redux-form.com/6.5.0/docs/api/Field.md/
   */
  input: PropTypes.object.isRequired,
  hideErrorWhileFocused: PropTypes.bool,
  defaultValue: PropTypes.any,
  onBlur: PropTypes.func,
};
const wrapperDefaultProps = {
  hideErrorWhileFocused: false,
  defaultValue: undefined,
  onBlur: _.noop,
};

export default function FieldWrapper(Component) {
  function FieldWrapperInstance(props) {
    let shouldDisplayError = props.meta.touched;
    if (props.hideErrorWhileFocused) shouldDisplayError = props.meta.touched && !props.meta.active;
    const { onBlur } = props;
    return (
      <Component
        errorText={shouldDisplayError ? props.meta.error : null}
        {...props.input}
        value={props.input.value || props.defaultValue}
        {..._.omit(props, ['input', 'meta'])}
        onBlur={() => onBlur()}
      />
    );
  }
  FieldWrapperInstance.propTypes = wrapperPropTypes;
  FieldWrapperInstance.defaultProps = wrapperDefaultProps;

  return FieldWrapperInstance;
}
