/**
 * Created by anna on 09/10/2017.
 */
import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import { connect } from 'react-redux';
import classNames from 'classnames';
import _ from 'lodash';

import BkmdModal from '../../../../../components/bkmdModal/bkmdModal';
import ModalHeader from '../../../../../components/bkmdModal/modalHeader';
import ThreeBounceSpinner from '../../../../../components/ui/spinner/threeBounceSpinner';
import InviteFamilyModal from './inviteFamilyModal';
import InviteLaterModal from './inviteLaterModal';
import FamilyActivation from './familyActivation';
import EnterInsuranceId from '../../addInsuarnceInfo/enterInsuranceID.presentational';
import RestOfFamilyStep from './steps/restOfFamilyStep';
import {
  setActivationStep,
  getFamilyMembers,
  activateFamily,
} from '../../../../../store/familyActivation/actions';
import { hasInsuranceIdSelector } from '../../../../../store/member/selectors';
import {
  getStep,
  getDependents,
  getGuardianDependents,
  fetchHasFamilyMembersNotEmpty,
  getHasDependents,
} from '../../../../../store/familyActivation/selectors';

export const steps = {
  START: 'START',
  INSURANCE: 'INSURANCE',
  ADD_KIDS: 'ADD_KIDS',
  LOADING: 'LOADING',
  INVITE_FAMILY: 'INVITE_FAMILY',
  INVITE_LATER: 'INVITE_LATER',
};

@autobind
class InviteFamilyFlow extends React.PureComponent {
  static propTypes = {
    step: PropTypes.string,
    setStep: PropTypes.func.isRequired,
    dependents: PropTypes.arrayOf(PropTypes.object),
    guardianDependents: PropTypes.arrayOf(PropTypes.object),
    skipIntro: PropTypes.bool,
    getFamilyMembers: PropTypes.func.isRequired,
    activateFamily: PropTypes.func.isRequired,
    hasInsuranceId: PropTypes.bool,
    hasDependents: PropTypes.bool,
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
  };

  static defaultProps = {
    step: steps.START,
    hasInsuranceId: false,
    hasDependents: false,
    skipIntro: false,
    dependents: null,
    guardianDependents: null,
  };

  componentDidMount() {
    this.resetComponent(true);
  }

  componentWillReceiveProps(nextProps) {
    const { guardianDependents, step, setStep } = nextProps;
    // return if data has not loaded yet
    if (_.isNil(guardianDependents)) return;
    if (_.isEmpty(guardianDependents)) this.goToInviteFamily(nextProps);
    else if (step !== steps.INVITE_FAMILY) setStep(steps.ADD_KIDS);
  }

  onExitFlow() {
    const { skipIntro, setStep, onClose } = this.props;
    if (skipIntro) this.resetComponent(false);
    else setStep(null);
    onClose();
  }

  resetComponent(fetch = true) {
    const { hasInsuranceId, skipIntro, setStep } = this.props;
    if (!skipIntro) return;
    if (!hasInsuranceId) setStep(steps.INSURANCE);
    else if (fetch) this.fetchFamily();
  }

  nextAfterFirstStep(toContinue) {
    const { setStep, hasInsuranceId } = this.props;
    if (toContinue) {
      // eslint-disable-next-line no-unused-expressions
      hasInsuranceId ? this.afterEnterInsuranceId() : setStep(steps.INSURANCE);
    } else {
      setStep(steps.INVITE_LATER);
    }
  }

  fetchFamily() {
    const { activateFamily, getFamilyMembers } = this.props;
    activateFamily();
    getFamilyMembers();
  }

  afterEnterInsuranceId() {
    this.fetchFamily();
    this.props.setStep(steps.LOADING);
  }

  goToInviteFamily(props) {
    if (_.isEmpty(props.dependents)) this.onExitFlow();
    else props.setStep(steps.INVITE_FAMILY);
  }

  nextAfterAddKinds() {
    this.goToInviteFamily(this.props);
  }

  renderLayout(Component, options = {}) {
    const { isOpen, onClose, hasDependents } = this.props;
    return (
      <BkmdModal
        className={classNames('invite-family-modal fixed-header', options.className)}
        isOpen={isOpen && hasDependents}
        handleClose={this.onExitFlow}
        closeOnBackdrop={false}
      >
        {options.addTitle ? <ModalHeader onClose={onClose} title="Invite your family" /> : null}
        <div className="dialog-body">
          <Component
            onNext={options.onNext}
            onClose={this.onExitFlow}
            notificationTimeout={options.notificationTimeout}
            {...options.params}
          />
        </div>
      </BkmdModal>
    );
  }

  render() {
    const { step, onClose, dependents } = this.props;
    switch (step) {
      case steps.START:
        return this.renderLayout(InviteFamilyModal, {
          onNext: this.nextAfterFirstStep,
          className: 'mini-modal',
        });
      case steps.INVITE_LATER:
        return this.renderLayout(InviteLaterModal, {
          onNext: onClose,
          className: 'mini-modal',
        });
      case steps.INSURANCE:
        return this.renderLayout(EnterInsuranceId, {
          onNext: () => this.afterEnterInsuranceId(),
          notificationTimeout: 3,
          addTitle: true,
        });
      case steps.LOADING:
        return this.renderLayout(ThreeBounceSpinner);
      case steps.ADD_KIDS:
        return this.renderLayout(FamilyActivation, {
          onNext: this.nextAfterAddKinds,
          addTitle: true,
          params: { isFinal: _.isEmpty(dependents) },
        });
      case steps.INVITE_FAMILY: {
        return this.renderLayout(RestOfFamilyStep, {
          onNext: onClose,
          addTitle: true,
        });
      }
      default:
        return null;
    }
  }
}

export default compose(
  fetchHasFamilyMembersNotEmpty(),
  connect(
    (state, props) => ({
      step: getStep(state, props.skipIntro ? null : steps.START),
      hasInsuranceId: hasInsuranceIdSelector(state),
      hasDependents: getHasDependents(state),
      dependents: getDependents(state),
      guardianDependents: getGuardianDependents(state),
    }),
    { setStep: setActivationStep, getFamilyMembers, activateFamily },
  ),
)(InviteFamilyFlow);
