export const BOOKING_TYPES = ['CONCIERGE', 'INSTANT', 'HYBRID', 'NONE', 'REQUEST'];
export const ACCEPTED_APPOINTMENTS_STATUS = ['ACCEPTED', 'PENDING', 'CHECKED_IN'];
export const REQUESTED_APPOINTMENTS_STATUS = [
  'REQUESTED',
  'DECLINED_BY_CLINIC',
  'WAITING_FOR_PATIENT_ACTION',
  'WAITING_FOR_CLINIC_ACTION',
];
export const CANCEL_APPOINTMENT_ACTION = 'cancel';
export const RESCHEDULE_APPOINTMENT_ACTION = 'reschedule';
export const CARD_TYPE_REQUESTED = 'requested';
export const CARD_TYPE_UPCOMING = 'upcoming';
export const CARD_TYPE_PAST = 'past';
export const CARD_TYPE_EMPTY = 'empty';
export const CANCEL_CONFIRMATION = 'cancel_confirmation';
export const APPOINTMENT_TIME_FORMAT = 'MM/DD/YYYY - h:mma';
export const APP_NAME = 'WALGREENS-SEARCH';
export const API_CANCELLATION_TYPE = 'API';
export const NPI_TYPE = { ORGANIZATION: 'ORGANIZATION', INDIVIDUAL: 'INDIVIDUAL' };
