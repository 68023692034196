/**
 * Created by chenrozenes on 20/07/2017.
 */

export const MONDAY = 'Mon';
export const TUESDAY = 'Tue';
export const WEDNESDAY = 'Wed';
export const THURSDAY = 'Thu';
export const FRIDAY = 'Fri';
export const SATUDRAY = 'Sat';
export const SUNDAY = 'Sun';
