/**
 * Created by chenrozenes on 17/01/2017.
 */

import Immutable from 'seamless-immutable';
import _ from 'lodash';

import {
  CLEAR_CHANGES,
  FLUSH,
  ROLLBACK,
  UPDATE_CHANGES,
  PULL,
  SEND_UPDATE,
  REMOVE,
  INIT
} from './actions';

const initialEntityState = {
  isUpdating: false,
  serverData: {}, // Holds the data from the server
  pendingChanges: {}, // Holds the changes the client performed
  data: {}, // Holds the merge between client and server data for auto saving
  error: null,
  hasError: true,
  isFlushing: false
};

const initialState = {};

/**
 * Like seamless-immutable merge function but for a specific key in the state
 * @param state
 * @param key
 * @param changes
 * @returns {*}
 */
function mergeStateInKey(state, key, changes) {
  return state.set(key, _.extend({}, state[key], changes));
}

export default function buildReducer(config = {}, manager) {
  const promiseSuffixes = config.promiseSuffixes;

  const START = promiseSuffixes[0];
  const SUCCESS = promiseSuffixes[1];
  const ERROR = promiseSuffixes[2];

  return function podrickReducer(state = initialState, action) {
    state = Immutable(state);

    const key = _.get(action, 'meta.key');
    if (!key) return state;

    const mergeChanges = manager.get(key, 'mergeChanges');
    const transform = manager.get(key, 'transform');

    // Initialize state if not exists
    if (!state[key]) {
      state = state.set(key, initialEntityState);
    }

    switch (action.type) {
      case INIT: {
        return state.set(key, initialEntityState);
      }
      case REMOVE: {
        return state.without(key);
      }
      case PULL: {
        const data = action.payload;
        return mergeStateInKey(state, key, { serverData: data, data });
      }
      case CLEAR_CHANGES: {
        return mergeStateInKey(state, key, { pendingChanges: initialEntityState.pendingChanges });
      }
      case ROLLBACK: {
        return mergeStateInKey(state, key, {
          data: state[key].serverData,
          pendingChanges: initialEntityState.pendingChanges
        });
      }
      case UPDATE_CHANGES: {
        // Merging the pending changes to the data and saving them both
        const pendingChanges = mergeChanges(state[key].pendingChanges, action.payload);
        const data = mergeChanges(state[key].data, action.payload);

        return mergeStateInKey(state, key, { pendingChanges, data });
      }
      case `${SEND_UPDATE}_${START}`: {
        return mergeStateInKey(state, key, { isUpdating: false, error: null, hasError: false });
      }
      case `${SEND_UPDATE}_${SUCCESS}`: {
        state = mergeStateInKey(state, key, {
          isUpdating: false,
          error: false,
          hasError: false
        });

        // When we got empty payload, we can update the boolean indicators
        if (_.isEmpty(action.payload)) return state;

        const data = transform(action.payload);
        return mergeStateInKey(state, key, {
          serverData: data,
          data: mergeChanges(data, state[key].pendingChanges, { state, action }),
        });
      }
      case `${SEND_UPDATE}_${ERROR}`: {
        return mergeStateInKey(state, key, {
          isUpdating: false,
          error: action.payload,
          hasError: true
        });
      }
      case `${FLUSH}_${START}`: {
        return mergeStateInKey(state, key, { isFlushing: true, error: null, hasError: false });
      }
      case `${FLUSH}_${SUCCESS}`: {
        return mergeStateInKey(state, key, { isFlushing: false, error: null, hasError: false });
      }
      case `${FLUSH}_${ERROR}`: {
        return mergeStateInKey(state, key, {
          isFlushing: false,
          error: action.payload,
          hasError: true
        });
      }

      default:
        return state;
    }
  };
}
