import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import ActivationButton from '../activationButton/activationButton';

const ActivationLanding = props => {
  const { title, subtitle, text, buttonText, onActivateClicked, renderProfileImage } = props;
  return (
    <main>
      <div className="container container-1040">
        <div className="row no-margin">
          <div className="col-lg-6 col-lg-offset-3 col-sm-8 col-sm-offset-2 no-padding">
            <div className="padding-box-30">
              <div className="text-center">{renderProfileImage()}</div>
              <h1 className="title-24 text-strong-main md-margin text-center">{title}</h1>
              <div className="text-16 text-center">{subtitle}</div>
              <div className="row">
                <div className="col-xs-12 text-center">
                  <div>
                    <span>{text}</span>
                    <ActivationButton
                      onActivateClicked={onActivateClicked}
                      activateButtonText={buttonText}
                      showSignIn
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

ActivationLanding.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  text: PropTypes.string,
  buttonText: PropTypes.string,
  onActivateClicked: PropTypes.func,
  renderProfileImage: PropTypes.func
};

ActivationLanding.defaultProps = {
  title: '',
  subtitle: '',
  text: '',
  buttonText: '',
  onActivateClicked: _.noop,
  renderProfileImage: _.noop
};

export default ActivationLanding;
