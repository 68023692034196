import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

export default function ToBooleanColumn({ value }) {
  return <span>{_.isNil(value) ? 'Unknown' : value ? 'Yes' : 'No'}</span>;
}

ToBooleanColumn.propTypes = {
  value: PropTypes.bool
};

ToBooleanColumn.defaultProps = {
  value: null
};
