import _ from 'lodash';
import { withPropsOnChange } from 'recompose';
import { setPublicToken } from '../../utils/publicToken';

const defaultTokenSelector = props => _.get(props, 'params.token');

export const withPublicToken = (tokenSelector = defaultTokenSelector) => withPropsOnChange(
  (newProps, oldProps) => tokenSelector(newProps) !== tokenSelector(oldProps),
  props => {
    const token = tokenSelector(props);
    setPublicToken(token);
    return { token };
  },
);
