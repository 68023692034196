import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { formValueSelector } from 'redux-form';
import { defineMessages } from 'react-intl';
import { withRouter } from 'react-router';
import { autobind } from 'core-decorators';

import {
  fields as verificationFields,
  FORM_NAME as VERIFICATION_FORM_NAME,
} from '../../../../../components/verification/verification';
import actionTracker from '../../../../../store/tools/actionTracker/actionTrackerComponent';
import { SimpleProgressPage } from '../../../../../components/ui/layouts';
import { RESET_PASSWORD_ACTION_TYPE, resetPassword } from '../../../../../store/auth/actions';
import { getIdentifier } from '../../../../../store/auth/selectors';
import injectNotification from '../../../../../store/notification/injectNotification';
import PasswordForm from './forms/passwordForm';
import * as Errors from '../../../../../api/errors';
import SvgKey from '../../../../../assets/svg/key';

const messages = defineMessages({
  yourPassword: {
    defaultMessage: 'Your password',
    id: 'members.auth.resetPassword.yourPassword',
  },
  chooseANewPassword: {
    defaultMessage: 'Choose a new password',
    id: 'members.auth.resetPassword.chooseANewPassword',
  },
  serverErrorTitle: {
    defaultMessage: 'Sorry, an error was occurred',
    id: 'members.auth.resetPassword.error.serverErrorTitle',
  },
  serverErrorMessage: {
    defaultMessage: 'Please refresh the page and try again',
    id: 'members.auth.resetPassword.error.serverErrorMessage',
  },
  weakPasswordErrorTitle: {
    defaultMessage: 'The password you entered is too weak',
    id: 'members.auth.resetPassword.error.weakPasswordErrorTitle',
  },
  weakPasswordErrorMessage: {
    defaultMessage: 'Try another one',
    id: 'members.auth.resetPassword.error.weakPasswordErrorMessage',
  },
  passwordWasInUseTitle: {
    defaultMessage: 'This password has been used already',
    id: 'activation.flow.error.passwordWasInUseTitle',
  },
  passwordWasInUseMessage: {
    defaultMessage: 'Try another one',
    id: 'activation.flow.error.passwordWasInUseMessage',
  },
  successTitle: {
    defaultMessage: 'Your password is updated',
    id: 'members.auth.resetPassword.succeeded',
  },
  succeededMessage: {
    defaultMessage: 'Your password has been updated.',
    id: 'members.auth.resetPassword.succeededMessage',
  },
});

@autobind
class PasswordStep extends React.Component {
  static propTypes = {
    /**
     * Injected prop for notifying on errors
     */
    notification: PropTypes.object.isRequired,
    /**
     * Server request action tracking.
     * For resetPassword.
     */
    resetPasswordTracking: PropTypes.object.isRequired,
    /**
     * Redux function to resetPassword.
     */
    resetPassword: PropTypes.func.isRequired,
    /**
     * verification code, taken from redux
     */
    verificationCode: PropTypes.string,
    /**
     * email or phone number, taken from props
     */
    identifier: PropTypes.string,
    /**
     * Router object.
     * Provided by the withRouter HoC
     */
    router: PropTypes.object.isRequired,
  };

  static defaultProps = {
    verificationCode: undefined,
    identifier: undefined,
  };

  componentWillReceiveProps(nextProps) {
    const { resetPasswordTracking } = nextProps;

    // in case resetting password - try login or redirect to error page
    if (resetPasswordTracking.finished) this.handleResetPasswordResponse(resetPasswordTracking);
  }

  onSubmit({ password }) {
    const { identifier } = this.props;
    // Setting the user details by the identifier mode
    const userDetails = {
      identifier,
      verificationCode: this.props.verificationCode,
    };

    this.props.resetPassword(userDetails.identifier, password, userDetails.verificationCode);
  }

  handleResetPasswordResponse(resetPasswordTracking) {
    const { router } = this.props;
    if (resetPasswordTracking.hasError) {
      const { responseMessage } = resetPasswordTracking.error;
      switch (responseMessage) {
        case Errors.WEAK_PASSWORD: {
          this.props.notification.error(
            messages.weakPasswordErrorTitle,
            messages.weakPasswordErrorMessage,
          );
          break;
        }
        case Errors.PASSWORD_WAS_IN_USE: {
          this.props.notification.error(
            messages.passwordWasInUseTitle,
            messages.passwordWasInUseMessage,
          );
          break;
        }
        default: {
          this.props.notification.error(messages.serverErrorTitle, messages.serverErrorMessage);
        }
      }
    } else {
      // Try login after resetting the password
      this.props.notification.success(messages.successTitle, messages.succeededMessage);
      router.push('/');
    }
  }

  render() {
    return (
      <SimpleProgressPage
        caption={messages.chooseANewPassword}
        progress={null}
        img={<SvgKey />}
        externalPageLogo
      >
        <PasswordForm onSubmit={this.onSubmit} />
      </SimpleProgressPage>
    );
  }
}

const verificationFieldSelector = formValueSelector(VERIFICATION_FORM_NAME);

export default compose(
  connect(
    state => ({
      identifier: getIdentifier(state),
      verificationCode: verificationFieldSelector(state, verificationFields.verificationCode.name),
      auth: state.auth,
    }),
    { resetPassword },
  ),
  actionTracker({
    resetPasswordTracking: RESET_PASSWORD_ACTION_TYPE.SOURCE,
  }),
  injectNotification,
  withRouter,
)(PasswordStep);
