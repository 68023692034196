import PropTypes from 'prop-types';
import { compose, defaultProps, setDisplayName, setPropTypes, withProps } from 'recompose';
import { defineMessages } from 'react-intl';
import { FlowShape } from '../../components/wizard/flow/shapes';
import MemberPhoneView from '../../components/organisms/MemberPhoneView/MemberPhoneView';
import SideDrawerContent from '../../components/templates/SideDrawerContent/SideDrawerContent';
import injectNotification from '../../store/notification/injectNotification';
import { INVALID_PHONE_NUMBER, USER_ALREADY_EXIST } from '../../api/errors';
import { withFetchers } from '../../api/injectApi/withFetchers';

const messages = defineMessages({
  inviteMemberErrorTitle: {
    defaultMessage: 'Member Invitation failed.',
    id: 'provider.flows.actions.inviteMemberErrorTitle',
  },
  phoneValidationErrorTitle: {
    defaultMessage: 'Phone number validation failed.',
    id: 'provider.flows.actions.phoneValidationErrorTitle',
  },
  inviteMemberPhoneInUseError: {
    defaultMessage: 'The phone number is in use.',
    id: 'provider.flows.actions.inviteMemberPhoneInUseError',
  },
  inviteMemberInvalidPhoneError: {
    defaultMessage: 'Invalid phone number.',
    id: 'provider.flows.actions.inviteMemberInvalidPhoneError',
  },
  inviteMemberDefaultErrorTitle: {
    defaultMessage: 'Phone number validation failed.',
    id: 'provider.flows.actions.inviteMemberDefaultErrorTitle',
  },
  inviteMemberDefaultError: {
    defaultMessage: "We couldn't verify the phone number. Please try again later",
    id: 'provider.flows.actions.inviteMemberDefaultError',
  },
});

const ComposedTemplate = withProps({
  formComponent: MemberPhoneView,
})(SideDrawerContent);

const MemberPhone = compose(
  injectNotification,
  setDisplayName('MemberPhone'),
  setPropTypes({
    control: FlowShape.isRequired,
    fullName: PropTypes.string,
    options: PropTypes.array,
    memberPhone: PropTypes.object,
    validatePhoneNumberAPI: PropTypes.func.isRequired,
  }),
  defaultProps({
    options: [],
    memberPhone: undefined,
    fullName: undefined,
  }),
  withFetchers({
    onSubmit: {
      handler: ({ validatePhoneNumberAPI }) => ({ memberPhone }) =>
        validatePhoneNumberAPI(memberPhone).then(() => memberPhone),
      onSuccess: ({ control }, memberPhone) => control.next({ payload: memberPhone }),
      onError: ({ notification }, error) => {
        switch (error.responseMessage) {
          case USER_ALREADY_EXIST:
            notification.error(
              messages.phoneValidationErrorTitle,
              messages.inviteMemberPhoneInUseError,
            );
            break;
          case INVALID_PHONE_NUMBER:
            notification.error(
              messages.phoneValidationErrorTitle,
              messages.inviteMemberInvalidPhoneError,
            );
            break;
          default:
            notification.error(
              messages.inviteMemberDefaultErrorTitle,
              messages.inviteMemberDefaultError,
            );
            break;
        }
      },
    },
  }),
)(ComposedTemplate);

export default MemberPhone;
