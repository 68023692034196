/* eslint-disable import/first,no-confusing-arrow */
import { compose } from 'recompose';
import paths from './securePaths';

import Home from '../../home/home';
import divComponent from '../divComponent';
import injectMemberDetails from '../../../../../store/member/injectMemberDetails';
import requireAuthentication from '../../../../../store/auth/authenticatedComponent';
import requireInsuranceId from '../../addInsuarnceInfo/requireInsuranceIdComponent';
import facilitySearchResults from '../../directory/facilitySearchResults/facilitySearchResults';
import help from '../../help/helpPage';
import premeraHelp from '../../help/premeraHelpContent';
import uhcAllSaversHelp from '../../help/uhcAllsaversHelpContent';
import anthemHelp from '../../help/anthemHelpContent';
import signInPage from '../../auth/signInPage';
import blockedAccount from '../../auth/blockedAccount';
import MemberNotFoundPage from '../../auth/resetPassword/MemberNotFoundPage';
import WeSentYouAnEmailPage from '../../auth/resetPassword/WeSentYouAnEmailPage';
import resetPasswordPage from '../../auth/resetPassword/resetPasswordPage';
import memberRenewPasswordPage from '../../auth/memberRenewPasswordPage';
import SafariNotSupported from '../../../../../components/pages/notfound/safariNotSupported';
import DirectoryRoutes from '../directoryRoutes';
import AppointmentsPage from '../../appointments/appointmentsPage';

if (typeof require.ensure !== 'function') require.ensure = (d, c) => c(require);

export default function (features) {
  const indexRoute = {
    name: 'Home',
    component: Home.default || Home,
  };

  // Routes
  let MedicationHistory = require('../../medications/medications');
  MedicationHistory = MedicationHistory.default || MedicationHistory;

  const Settings = require('./settingsRoutes');
  const InsuranceId = require('../../addInsuarnceInfo/routes');
  const CareCues = require('../../careCues/careCues');
  const MedicalTeam = require('../../medicalTeam/medicalTeam');
  const Resources = require('../../resources/resources');

  // Components
  const components = [
    ...DirectoryRoutes,
    Settings.default || Settings,
    InsuranceId.default || InsuranceId,
    {
      path: paths.careCues(),
      name: 'careCues',
      component: requireInsuranceId(CareCues.default || CareCues),
    },

    {
      path: paths.medicationHistory(),
      name: 'medication',
      component: requireInsuranceId(MedicationHistory.default || MedicationHistory),
    },
    {
      path: 'facilitySearchResults',
      name: 'facilitySearchResults',
      component: facilitySearchResults.default || facilitySearchResults,
    },
    {
      path: 'help',
      name: 'help',
      component: help.default || help,
    },
    {
      path: 'premeraHelp',
      name: 'premeraHelp',
      component: premeraHelp.default || premeraHelp,
    },
    {
      path: 'uhcAllSaversHelp',
      name: 'uhcAllSaversHelp',
      component: uhcAllSaversHelp.default || uhcAllSaversHelp,
    },
    {
      path: 'anthemHelp',
      name: 'anthemHelp',
      component: anthemHelp.default || anthemHelp,
    },
    {
      path: 'floridaHelp',
      name: 'floridaHelp',
      component: premeraHelp.default || premeraHelp,
    },
    {
      path: 'resources',
      name: 'resources',
      component: Resources.default || Resources,
    },
    {
      path: paths.appointments(),
      name: 'appointments',
      component: AppointmentsPage.default || AppointmentsPage,
    },
  ];

  if (features.pcpSelection) {
    components.push({
      path: paths.medicalTeam(),
      name: 'medicalTeam',
      component: requireInsuranceId(MedicalTeam.default || MedicalTeam),
    });
  }
  return [
    {
      path: 'secure',
      name: 'secure',
      getChildRoutes(location, cb) {
        require.ensure([], () => {
          cb(null, components);
        });
      },
      // Requiring secured page NOT dynamically so it won't be unmounted every route
      component: compose(requireAuthentication(), injectMemberDetails)(divComponent),
      indexRoute,
    },
    {
      path: 'signIn',
      name: 'SignIn',
      component: signInPage.default || signInPage,
    },
    {
      path: 'blockedAccount',
      name: 'blockedAccount',
      component: blockedAccount.default || blockedAccount,
    },
    {
      path: 'memberNotFound',
      name: 'memberNotFound',
      component: MemberNotFoundPage.default || MemberNotFoundPage,
    },
    {
      path: 'weSentYouAnEmail',
      name: 'weSentYouAnEmail',
      component: WeSentYouAnEmailPage.default || WeSentYouAnEmailPage,
    },
    {
      path: 'resetPassword',
      name: 'resetPassword',
      component: resetPasswordPage.default || resetPasswordPage,
    },
    {
      path: 'renewExpiredPassword',
      name: 'MemberRenewPasswordPage',
      component: memberRenewPasswordPage.default || memberRenewPasswordPage,
    },
    {
      path: 'safari',
      name: 'safari',
      component: SafariNotSupported.default || SafariNotSupported,
    },
  ];
}
/* eslint-enable import/first,no-confusing-arrow */
