import _ from 'lodash';
import { connect } from 'react-redux';
import MapSnapshot from '../../storybook/src/components/atoms/MapSnapshot/MapSnapshot';

export default connect(state => {
  const mapStrategy = _.get(state, 'config.mapStrategy', 'bingMaps');
  const { templateUrl, key } = _.get(state, ['config', mapStrategy], {});

  return {
    mapConfig: { templateUrl, key },
  };
})(MapSnapshot);
