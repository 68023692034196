import { compose, withProps } from 'recompose';
import { connect } from 'react-redux';
import { logout } from '../../../../store/auth/actions';
import { clearMemberSession } from '../../../../utils/storage/memberSession';

export default compose(
  connect(
    null,
    { logout },
  ),
  withProps(({ logout }) => ({
    logout: () => {
      clearMemberSession();
      logout();
    },
  })),
);
