/**
 * Created by moran on 8/13/17.
 */
export const LOAD_DOMAIN = 'LOAD_DOMAIN';

export function loadDomain(domain) {
  return {
    type: LOAD_DOMAIN,
    payload: { domain }
  };
}
