import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '../../../storybook/src/utils/theme';

function SvgAdultMaleAvatar({ theme }) {
  /* eslint-disable */
  return (
    <svg aria-hidden="true" focusable="false" width="71px" height="71px" viewBox="0 0 71 71" version="1.1" className="svg-icon svg-adult-male-avatar">
      <defs>
        <polygon points="35.50025 0 71 0 71 53 0.0005 53 0.0005 0"></polygon>
      </defs>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-1.000000, -1.000000)">
          <g transform="translate(1.000000, 1.000000)">
            <path d="M10.6992,56.4688 C16.9032,63.8108 25.8862,67.9998 35.5002,67.9998 C45.1142,67.9998 54.0962,63.8108 60.3012,56.4688 C56.8392,50.3878 51.1272,45.6788 44.4922,43.4708 C43.9732,43.2988 43.5912,42.8588 43.4912,42.3218 C43.3912,41.7838 43.5922,41.2358 44.0132,40.8888 C47.1822,38.2878 49.0002,34.5008 49.0002,30.4998 C49.0002,23.0558 42.9442,16.9998 35.5002,16.9998 C28.0552,16.9998 22.0002,23.0558 22.0002,30.4998 C22.0002,34.5008 23.8172,38.2878 26.9862,40.8888 C27.4082,41.2358 27.6082,41.7838 27.5092,42.3218 C27.4092,42.8588 27.0262,43.2988 26.5082,43.4708 C19.8732,45.6788 14.1612,50.3878 10.6992,56.4688 M35.5002,70.9998 C24.6372,70.9998 14.5132,66.1058 7.7242,57.5718 C7.3552,57.1078 7.2942,56.4678 7.5692,55.9428 C11.0012,49.3828 16.5212,44.3208 23.2712,41.4848 C20.5322,38.4658 19.0002,34.5758 19.0002,30.4998 C19.0002,21.4028 26.4022,13.9998 35.5002,13.9998 C44.5972,13.9998 52.0002,21.4028 52.0002,30.4998 C52.0002,34.5758 50.4682,38.4658 47.7282,41.4848 C54.4782,44.3208 59.9992,49.3828 63.4302,55.9428 C63.7062,56.4678 63.6442,57.1078 63.2752,57.5718 C56.4862,66.1058 46.3622,70.9998 35.5002,70.9998" fill={theme.mainColor} ></path>
            <g>
              <g></g>
              <path d="M5.5225,53 C4.9885,53 4.4705,52.714 4.1995,52.209 C1.4525,47.088 0.0005,41.31 0.0005,35.5 C0.0005,15.925 15.9245,0 35.5005,0 C55.0755,0 71.0005,15.925 71.0005,35.5 C71.0005,41.312 69.5475,47.09 66.7995,52.209 C66.4085,52.941 65.4975,53.212 64.7695,52.821 C64.0395,52.43 63.7645,51.521 64.1575,50.791 C66.6705,46.105 68.0005,40.818 68.0005,35.5 C68.0005,17.579 53.4205,3 35.5005,3 C17.5795,3 3.0005,17.579 3.0005,35.5 C3.0005,40.816 4.3295,46.104 6.8435,50.791 C7.2355,51.521 6.9605,52.431 6.2305,52.822 C6.0045,52.942 5.7615,53 5.5225,53" fill={theme.secondaryColor} ></path>
            </g>
            <path d="M25.5,31 C24.672,31 24,30.328 24,29.5 C24,28.672 24.672,28 25.5,28 C36.664,28 42.387,22.491 42.443,22.436 C43.032,21.856 43.979,21.858 44.562,22.445 C45.145,23.032 45.145,23.977 44.561,24.561 C44.298,24.823 37.978,31 25.5,31" fill={theme.mainColor} ></path>
            <path d="M35.5,51 C35.158,51 34.817,50.884 34.54,50.652 L30.417,47.216 L26.038,48.9 C25.269,49.192 24.397,48.812 24.1,48.038 C23.803,47.266 24.188,46.397 24.962,46.1 L30.162,44.1 C30.667,43.906 31.242,43.999 31.66,44.348 L35.52,47.563 L39.562,44.329 C39.985,43.99 40.559,43.907 41.057,44.107 L46.057,46.107 C46.826,46.415 47.2,47.288 46.893,48.057 C46.585,48.827 45.713,49.201 44.943,48.893 L40.755,47.218 L36.437,50.671 C36.163,50.891 35.831,51 35.5,51" fill={theme.mainColor} ></path>
            <path d="M35.5,57 C34.672,57 34,56.328 34,55.5 L34,54.5 C34,53.672 34.672,53 35.5,53 C36.328,53 37,53.672 37,54.5 L37,55.5 C37,56.328 36.328,57 35.5,57" fill={theme.mainColor} ></path>
            <path d="M35.5,63 C34.672,63 34,62.328 34,61.5 L34,60.5 C34,59.672 34.672,59 35.5,59 C36.328,59 37,59.672 37,60.5 L37,61.5 C37,62.328 36.328,63 35.5,63" fill={theme.mainColor} ></path>
          </g>
        </g>
      </g>
    </svg>
  );
  /* eslint-enable */
}

SvgAdultMaleAvatar.propTypes = {
  theme: PropTypes.object.isRequired,
};

export default withTheme(SvgAdultMaleAvatar);
