/**
 * Created by guyavarham on 20/03/2017.
 */

import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { autobind } from 'core-decorators';
import { connect } from 'react-redux';
import { compose } from 'redux';

import Layout from '../../layout/layout';
import Utils from '../../../../../utils/util';
import * as queryStringSelectors from '../directorySelectors';
import SortDropdown from '../../../../../components/searchDoctors/sortDropdown';
import FilterModal from './components/filter/directoryFilterModal';
import TaxonomySelection from '../../../../../components/searchDoctors/taxonomySelector';
import ResultsScrolable from './components/resultsScrolable';
import SearchTypeahead from '../searchDoctors/directorySearch/directorySearchTypeahead';
import queryConnect from '../../../../../store/tools/queryString/queryConnect';
import { getCurrentLocation } from '../../../../../store/location/actions';
import { messages } from '../../../../../messages/searchDoctorResults/messages';
import {
  bucketLabelsToCodesSelector,
  fetchTaxonomiesNotEmpty,
} from '../../../../../store/meta/selectors';
import {
  createToggleState,
  createToggleStatePropTypes,
} from '../../../../../components/recompose/toggleStateComponent';
import { TaxonomySelected } from '../../../../../store/tools/analytics/events/memberEvents';

import './searchDoctorsResults.less';
import { clientLocationShape } from '../../../../../components/searchDoctors/clientLocationShape';
import paths from '../../routes/secure/securePaths';
import { withPromptRedirect } from '../../../../../components/router/withPromptRedirect';
import routingSelectors from '../../../../../store/routing/selectors';
import { generateUUID } from '../../../../../utils/uuid';

@autobind
class SearchDoctorsResults extends React.Component {
  static propTypes = {
    clientLocation: PropTypes.shape(clientLocationShape).isRequired,
    getCurrentLocation: PropTypes.func.isRequired,
    setInQueryString: PropTypes.func.isRequired,
    ...createToggleStatePropTypes('filterModal'),
    location: PropTypes.object.isRequired,
    sortingFeatureFlag: PropTypes.bool.isRequired,
    chosenLabel: PropTypes.string,
    searchTerm: PropTypes.string,
  };

  static contextTypes = {
    analytics: PropTypes.object.isRequired,
  };

  static defaultProps = {
    searchTerm: null,
    chosenLabel: null,
  };

  constructor() {
    super();
    this.state = {};
  }

  componentWillMount() {
    if (
      !this.props.chosenLabel ||
      !_.includes(_.keys(this.props.bucketLabelsToCodes), this.props.chosenLabel)
    ) {
      this.chooseFirstTaxonomyBucket(this.props);
    }
  }

  componentWillReceiveProps(nextProps) {
    Utils.inspectChange(this.props, nextProps, [
      { uri: 'codes', action: this.chooseFirstTaxonomyBucket },
    ]);
  }

  onFilterModalApply(payload) {
    this.props.setInQueryString({ ...payload, skip: 0, queryId: generateUUID() });
    this.props.filterModalHide();
  }

  onCodeSelection(chosenLabel) {
    // On changing selection -> reset the page results
    this.props.setInQueryString({ skip: 0, chosenLabel, queryId: generateUUID() });
    this.context.analytics.track(TaxonomySelected, { chosenLabel });
  }

  chooseFirstTaxonomyBucket(props) {
    const chosenLabel = _.first(_.keys(props.bucketLabelsToCodes));
    this.onCodeSelection(chosenLabel);
  }

  chooseOnePcpLabel() {
    const { bucketLabelsToCodes, pcpLabels } = this.props;
    const pcpLabelsInCurrSearch = _.intersection(_.keys(bucketLabelsToCodes), pcpLabels);
    const first = _.first(pcpLabelsInCurrSearch);
    if (first) this.onCodeSelection(first);
  }

  shouldShowPcpBanner(chosenLabel) {
    const { bucketLabelsToCodes, pcpLabels } = this.props;
    const pcpLabelsInCurrSearch = _.intersection(_.keys(bucketLabelsToCodes), pcpLabels);
    const showPCPBanner =
      // pcp is one of the relevant taxonomies
      !_.isEmpty(pcpLabelsInCurrSearch) &&
      // and the chosen taxonomy is not pcp
      !_.includes(pcpLabels, chosenLabel);

    return showPCPBanner;
  }

  goToSecureSearchResults(npi, locationId) {
    const { redirect, queryParams } = this.props;
    const doctorDetailsPath = paths.searchDoctorsResults({
      ...queryParams,
      openBookingModal: true,
      bookingNpi: npi,
      bookingLocationId: locationId,
    });
    redirect(doctorDetailsPath);
  }

  render() {
    const {
      setInQueryString,
      searchTerm,
      bucketLabelsToCodes,
      clientLocation,
      sortingFeatureFlag,
      filterModalShow,
      filterModalHide,
      filterModalOn,
      filters,
      sorting,
      chosenLabel,
      openBookingModal,
      bookingNpi,
      bookingLocationId,
      queryId,
    } = this.props;

    // Protect from ios exceptions
    if (!bucketLabelsToCodes || !chosenLabel) return null;

    const chosenCode = bucketLabelsToCodes[chosenLabel];
    if (!chosenCode) return null;

    const renderSelector = _.keys(bucketLabelsToCodes).length > 1;

    return (
      <div className="search-doctors-results-wrap">
        <Layout logoClassName="logos hidden-xs" searchPreview={{ searchTerm, showOnBar: true }}>
          <div className="filter-bar">
            <SortDropdown
              onChange={setInQueryString}
              sorting={sorting}
              options={sortingFeatureFlag}
            />
            <button className="filter-btn btn btn-transparent" onClick={filterModalShow}>
              <i className="icon-filter-sliders" />
              <FormattedMessage {...messages.filter} />
            </button>
            <div className="doctors-search-input-desktop hidden-xs">
              <SearchTypeahead
                containerClasses="left-search-icon no-right-border hide-arrow"
                location={_.get(this.props, 'clientLocation.geo', null)}
              />
            </div>
          </div>

          <TaxonomySelection
            bucketLabelsToCodes={bucketLabelsToCodes}
            onChange={this.onCodeSelection}
            value={chosenLabel}
          />

          <ResultsScrolable
            filter={filters}
            routerLocation={this.props.location}
            setInQueryString={setInQueryString}
            chosenLocation={clientLocation}
            chosenCode={chosenCode}
            codes={chosenCode ? _.castArray(chosenCode) : []}
            queryId={queryId}
            showPCPBanner={this.shouldShowPcpBanner(chosenLabel)}
            onChoosePcpLabel={this.chooseOnePcpLabel}
            searchTerm={chosenLabel}
            filterModalShow={filterModalShow}
            hiddenTaxonomiesSelector={!renderSelector}
            goToSecureSearchResults={this.goToSecureSearchResults}
            openBookingModal={openBookingModal}
            bookingNpi={bookingNpi}
            bookingLocationId={bookingLocationId}
          />
        </Layout>

        <FilterModal
          isOpen={filterModalOn}
          onClose={filterModalHide}
          filter={filters}
          onApply={this.onFilterModalApply}
        />

        <div className="content-grey-bg" />
      </div>
    );
  }
}

export default compose(
  withPromptRedirect,
  queryConnect(query => ({
    codes: queryStringSelectors.extractCodesArray(query),
    sorting: queryStringSelectors.extractSorting(query),
    searchTerm: queryStringSelectors.extractSearchTerm(query),
    filters: queryStringSelectors.extractFilters(query),
    chosenLabel: queryStringSelectors.extractChosenLabel(query),
    openBookingModal: queryStringSelectors.extractOpenBookingModal(query),
    bookingNpi: queryStringSelectors.extractBookingNpi(query),
    bookingLocationId: queryStringSelectors.extractBookingLocationId(query),
    queryId: queryStringSelectors.extractQueryId(query),
  })),
  createToggleState('filterModal'),
  fetchTaxonomiesNotEmpty(),
  connect(
    (state, ownProps) => ({
      clientLocation: queryStringSelectors.chooseClientLocation(state),
      bucketLabelsToCodes: bucketLabelsToCodesSelector(state, ownProps.codes),
      pcpLabels: _.get(state, 'config.directory.searchResults.pcpLabels'),
      sortingFeatureFlag: _.get(state, 'config.features.results.sorting', true),
      queryParams: routingSelectors.currRouteQuery(state),
    }),
    { getCurrentLocation },
  ),
)(SearchDoctorsResults);
