import { defineMessages } from 'react-intl';

export default defineMessages({
  serverErrorTitle: {
    defaultMessage: 'Hmm. Something went wrong',
    id: 'activation.flow.error.serverErrorTitle'
  },
  serverErrorMessage: {
    defaultMessage: 'Please refresh the page and try again',
    id: 'activation.flow.error.serverErrorMessage'
  },
  wrongCodeMessage: {
    defaultMessage: "That didn't work. Let's try again, thanks!",
    id: 'activation.flow.error.wrongCodeMessage'
  },
  codeExpiredTitle: {
    defaultMessage: 'Verification code has expired',
    id: 'activation.flow.error.codeExpiredTitle'
  },
  codeExpiredMessage: {
    defaultMessage: 'Click on "Resend my code" and try again',
    id: 'activation.flow.error.codeExpiredMessage'
  },
  weakPasswordTitle: {
    defaultMessage: 'Your password is too weak',
    id: 'activation.flow.error.weakPasswordTitle'
  },
  weakPasswordMessage: {
    defaultMessage: 'Try another one',
    id: 'activation.flow.error.weakPasswordMessage'
  },
  nameNotFoundTitle: {
    defaultMessage: "We couldn't find the name you entered",
    id: 'activation.flow.error.nameNotFoundTitle'
  },
  nameNotFoundMessage: {
    defaultMessage: 'Your name should be written as it is on your member ID card',
    id: 'activation.flow.error.nameNotFoundMessage'
  },
  dobDoesNotMatchTitle: {
    defaultMessage: "That doesn't match what we have",
    id: 'activation.flow.error.dobDoesNotMatchTitle'
  },
  dobDoesNotMatchMessage: {
    defaultMessage: 'please try again.',
    id: 'activation.flow.error.dobDoesNotMatchMessage'
  },
  invalidPhoneNumberMessage: {
    defaultMessage: 'Please use a mobile number for your account.',
    id: 'activation.flow.error.invalidPhoneNumberMessage'
  },
  dobTooYoungTitle: {
    defaultMessage: 'Whoops, you must be older to activate.',
    id: 'activation.flow.error.dobTooYoungTitle'
  },
  dobTooYoungMessage: {
    defaultMessage:
      "If you're a parent or guardian, activate your own account to search for doctors for you and your family.",
    id: 'activation.flow.error.dobTooYoungMessage'
  },
  activationTokenExpiredTitle: {
    defaultMessage: 'This session has expired',
    id: 'activation.flow.error.activationTokenExpiredTitle'
  },
  activationTokenExpiredMessage: {
    defaultMessage: 'Please refresh and try again',
    id: 'activation.flow.error.activationTokenExpiredMessage'
  },
  validationTokenExpiredTitle: {
    defaultMessage: 'This session has expired',
    id: 'activation.flow.error.validationTokenExpiredTitle'
  },
  validationTokenExpiredMessage: {
    defaultMessage: 'Please refresh and try again',
    id: 'activation.flow.error.validationTokenExpiredMessage'
  },
  userAlreadyExistsTitle: {
    defaultMessage: 'member activation',
    id: 'activation.flow..error.userAlreadyExists.title'
  },
  userAlreadyExistsMessage: {
    defaultMessage: 'you already activated',
    id: 'activation.flow..error.userAlreadyExists.message'
  }
});
