import savingAlaskaRoutes from '../savingAlaskaRoutes';

class SavingAlaskaRoutesStrategy {
  static predicate() {
    return true;
  }

  static getRoutes() {
    return [...savingAlaskaRoutes];
  }
}

export default SavingAlaskaRoutesStrategy;
