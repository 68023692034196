import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { autobind } from 'core-decorators';

import attemptsMessages from './attemptsMessages';
import messages from './verificationMessages';
import { ProgressLayout } from '../../../../../components/ui/layouts';
import actionTracker from '../../../../../store/tools/actionTracker/actionTrackerComponent';
import {
  verifyResetToken,
  resetPasswordRequest,
  VERIFY_RESET_TOKEN_ACTION_TYPE,
  RESET_PASSWORD_REQUEST_ACTION_TYPE,
} from '../../../../../store/auth/actions';
import { getIdentifier } from '../../../../../store/auth/selectors';

import injectNotification from '../../../../../store/notification/injectNotification';
import Verification from '../../../../../components/verification/verification';
import * as Errors from '../../../../../api/errors';
import resetPasswordMessages from './resetPasswordMessages';

@autobind
class VerificationCodeStep extends React.Component {
  static propTypes = {
    /**
     * Injected prop for notifying on errors
     */
    notification: PropTypes.object.isRequired,
    /**
     * Injected prop for using the flow manager.
     */
    flow: PropTypes.object.isRequired,
    /**
     * email or phone number, taken from redux-form.
     */
    identifier: PropTypes.string.isRequired,
    /**
     * Redux action creator for the verifyResetToken command.
     */
    verifyResetToken: PropTypes.func.isRequired,
    /**
     * Redux action creator.
     */
    resetPasswordRequest: PropTypes.func.isRequired,
    /**
     * Server request action tracking.
     * For verifyResetToken.
     */
    verifyResetTokenTracking: PropTypes.object.isRequired,
    /**
     * Server request action tracking.
     * For verifyResetToken.
     */
    resetPasswordRequestTracking: PropTypes.object.isRequired,
  };

  componentWillReceiveProps(nextProps) {
    const { verifyResetTokenTracking, resetPasswordRequestTracking } = nextProps;
    if (verifyResetTokenTracking.finished) this.handleResponse(verifyResetTokenTracking);
    if (resetPasswordRequestTracking.finished) {
      this.handleResetPasswordResponse(resetPasswordRequestTracking);
    }
  }

  onSubmit({ verificationCode }) {
    const { identifier } = this.props;
    this.props.verifyResetToken(identifier, verificationCode);
  }

  resendCode(identifier) {
    const type = _.includes(identifier, '@') ? 'email' : 'phone';
    const identifierObj = { identifier, type };
    this.props.resetPasswordRequest(identifierObj, true);
  }

  handleResetPasswordResponse(tracking) {
    if (tracking.hasError) {
      let errorMsg;
      const { responseMessage } = tracking.error;
      switch (responseMessage) {
        case Errors.MAX_RESET_PASSWORD_ATTEMPTS: {
          errorMsg = attemptsMessages.maxAttemptsErrorMessage;
          break;
        }
        default: {
          errorMsg = messages.verificationErrorMsg;
          break;
        }
      }
      this.props.notification.error(messages.verificationErrorTitle, errorMsg, { autoDismiss: 15 });
    } else {
      this.props.notification.success(
        resetPasswordMessages.successTitle,
        resetPasswordMessages.successMessage,
        {
          autoDismiss: 15,
        },
      );
    }
  }

  handleResponse(tracking) {
    if (tracking.hasError) {
      let errorMsg;
      const { responseMessage } = tracking.error;
      switch (responseMessage) {
        case Errors.INVALID_CODE: {
          errorMsg = messages.wrongCodeMessage;
          break;
        }
        case Errors.RECAPTCHA_FAILED:
          errorMsg = messages.recaptchaErrorMessage;
          break;
        default: {
          errorMsg = messages.verificationErrorMsg;
        }
      }
      this.props.notification.error(messages.verificationErrorTitle, errorMsg, { autoDismiss: 15 });
    } else {
      this.props.flow.nextStep();
    }
  }

  render() {
    return (
      <ProgressLayout
        className="progress-layout"
        contentClassName=""
        title={messages.enterYourCode}
        progress={null}
      >
        <div className="main-content padding-box-30">
          <Verification
            onSubmit={this.onSubmit}
            loading={this.props.verifyResetTokenTracking.inProgress}
            identifier={this.props.identifier}
            resendCode={this.resendCode}
          />
        </div>
      </ProgressLayout>
    );
  }
}

export default compose(
  connect(
    state => ({
      identifier: getIdentifier(state),
    }),
    {
      verifyResetToken,
      resetPasswordRequest,
    },
  ),
  actionTracker({
    verifyResetTokenTracking: VERIFY_RESET_TOKEN_ACTION_TYPE.SOURCE,
    resetPasswordRequestTracking: RESET_PASSWORD_REQUEST_ACTION_TYPE.SOURCE,
  }),
  injectNotification,
)(VerificationCodeStep);
