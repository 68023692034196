/**
 * Created by chenrozenes on 24/09/2017.
 */
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { defineMessages, injectIntl, intlShape } from 'react-intl';
import { autobind } from 'core-decorators';
import Gender from '../../model/enum/gender';
import { MessageShape } from '../ui/intlString';
import CleanSelect from './cleanSelect';

const messages = defineMessages({
  gender: {
    defaultMessage: 'Gender',
    id: 'components.genderSelector.gender'
  }
});

@autobind
class GenderSelect extends React.Component {
  static propTypes = {
    label: MessageShape,
    intl: intlShape.isRequired,
    value: PropTypes.oneOf(_.keys(Gender)),
    onChange: PropTypes.func.isRequired,
  };

  static defaultProps = {
    label: messages.gender,
    value: undefined,
  };

  onChange(e) {
    this.props.onChange(_.get(e, 'value'));
  }

  render() {
    const { value, label, intl, ...other } = this.props;
    return (
      <CleanSelect
        options={Gender.toSelectOptions(intl)}
        value={value}
        label={label}
        {...other}
        onChange={this.onChange}
      />
    );
  }
}

export default injectIntl(GenderSelect);
