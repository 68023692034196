/**
 * Share providers by Vim guide
 * @type {string}
 */
export const SHARE_PROVIDERS_BY_VIM_GUIDE = 'SHARE_PROVIDERS_BY_VIM_GUIDE';

/**
 * Book appointment by Vim guide
 * @type {string}
 */
export const BOOK_BY_VIM_GUIDE = 'BOOK_BY_VIM_GUIDE';

/**
 * Book appointment from share landing by member
 * @type {string}
 */
export const BOOK_FROM_LANDING = 'BOOK_FROM_LANDING';

/**
 * Reschedule appointment from share landing by member
 * @type {string}
 */
export const RESCHEDULE_BY_MEMBER = 'RESCHEDULE_BY_MEMBER';

/**
 * Invite member by vim guide
 * @type {string}
 */
export const INVITE_BY_VIM_GUIDE = 'INVITE_BY_VIM_GUIDE';

/**
 * Member activate from landing page
 * @type {string}
 */
export const ACTIVATE_FROM_LANDING = 'ACTIVATE_FROM_LANDING';

/**
 * SDK booking from Atlas
 * @type {string}
 */
export const SDK_BOOKING = 'SDK_BOOKING';
