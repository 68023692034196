import { defineMessages } from 'react-intl';

const messages = defineMessages({
  enterYourCode: {
    defaultMessage: 'Enter your code',
    id: 'members.auth.verificationCode.enterYourCode',
  },
  weSentAVerificationCodeTo: {
    defaultMessage: 'We sent a verification code to',
    id: 'members.auth.verificationCode.weSentAVerificationCodeTo',
  },
  yourCode: {
    defaultMessage: 'Your verification code',
    id: 'members.auth.verificationCode.yourCode',
  },
  continueBtn: {
    defaultMessage: 'Continue',
    id: 'members.auth.verificationCode.continueBtn',
  },
  iDidntGetMyVerificationCode: {
    defaultMessage: 'I didn’t get my verification code.',
    id: 'members.auth.verificationCode.iDidntGetMyVerificationCode',
  },
  verificationErrorTitle: {
    defaultMessage: 'Cannot verify your code',
    id: 'members.auth.verificationCode.verificationErrorTitle',
  },
  verificationErrorMsg: {
    defaultMessage: 'Your code has expired, please resend it',
    id: 'members.auth.verificationCode.verificationErrorMsg',
  },
  wrongCodeMessage: {
    defaultMessage: "Let's try again, that doesn't seem to match the code we sent.",
    id: 'members.auth.verificationCode.wrongCodeMessage',
  },
});

export default messages;
