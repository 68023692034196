import { defineMessages } from 'react-intl';
import { compose, setDisplayName } from 'recompose';
import MemberPhone from './memberPhone';
import { withTranslatedMessages } from '../../utils/withTranslatedMessages';

const messages = defineMessages({
  title: {
    defaultMessage: "What's your mobile number?",
    id: 'public.memberPhone.title',
  },
  details: {
    defaultMessage: "The doctor's office may need to contact you about your appointment.",
    id: 'public.memberPhone.details',
  },
  mobileNum: {
    defaultMessage: 'Your mobile number',
    id: 'public.memberPhone.mobileNum',
  },
  next: {
    defaultMessage: 'Next',
    id: 'public.memberPhone.next',
  },
});

const PatientMemberPhone = compose(
  setDisplayName('PatientMemberPhone'),
  withTranslatedMessages(messages),
)(MemberPhone);

export default PatientMemberPhone;
