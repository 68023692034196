/**
 * Created by chenrozenes on 07/11/2016.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import _ from 'lodash';

/**
 * Generic wizard component which renders steps and providers functions to advance and go back.
 * Step number one is 0
 *
 * The difference between Wizard and FlowManager, is that Wizard is uses react state to manage
 * the steps, and flow manager uses query string and it switches between routes
 */
@autobind
class Wizard extends React.Component {
  static propTypes = {
    steps: PropTypes.arrayOf(React.Component),
    onFinish: PropTypes.func,
  };

  static defaultProps = {
    steps: [],
    onFinish: undefined,
  };

  constructor(props) {
    super(props);
    this.state = {
      currentStep: 0,
      data: null,
    };
  }

  /**
   * Gets the data save in the wizard
   * @returns {*}
   */
  getData() {
    return this.state.data;
  }

  /**
   * Saves inner data for the entire wizard.
   * Can be retrieved with "getData" function and when wizard is finished
   * @param toSave
   */
  saveData(toSave) {
    const data = _.extend({}, this.state.data, toSave);
    this.setState({ data });
  }

  /**
   * Advanced by default to the next step if exists.
   * If stepIndex is provided, this step will be rendered
   * @param stepIndex
   */
  advance(stepIndex) {
    const { steps, onFinish } = this.props;
    stepIndex = _.isNumber(stepIndex) ? stepIndex : this.state.currentStep + 1;
    if (stepIndex < steps.length) {
      this.setState({ currentStep: stepIndex });
    } else if (stepIndex === steps.length && !!onFinish) {
      onFinish(this.state.data);
    }
  }

  /**
   * Goes back by default to the previous step if not the first
   * If stepIndex is provided, this step will be rendered
   * @param stepIndex
   */
  back(stepIndex) {
    stepIndex = _.isNumber(stepIndex) ? stepIndex : this.state.currentStep - 1;
    if (stepIndex >= 0) {
      this.setState({ currentStep: stepIndex });
    }
  }

  render() {
    const { steps, ...other } = this.props;
    const stepToRender = steps[this.state.currentStep];
    if (!stepToRender) return null;

    return React.createElement(stepToRender, {
      wizard: {
        advance: this.advance,
        back: this.back,
        saveData: this.saveData,
        getData: this.getData,
        currentStep: this.state.currentStep,
      },
      ...other
    });
  }
}

export default Wizard;
