/**
 * created by moran on 11/26/17
 */
/* eslint-disable import/first */

if (typeof require.ensure !== 'function') require.ensure = (d, c) => c(require);
import DoctorsListDetails from './doctorsList/doctorsListDetails';
import ShareDoctorsPage from './shareDoctorsPage';
import paths from './shareDoctorsPaths';

const Components = [
  {
    path: paths.shareDoctorsDetails(),
    name: 'shareDoctorsDetails',
    component: DoctorsListDetails.default || DoctorsListDetails,
  },
];

export default [
  {
    path: 'doctorsShare',
    name: 'doctorsShare',
    getChildRoutes(location, cb) {
      require.ensure([], () => {
        cb(null, Components);
      });
    },
    component: ShareDoctorsPage,
  },
];
/* eslint-enable import/first */
