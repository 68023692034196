import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { defineMessages } from 'react-intl';
import { connect } from 'react-redux';
import { compose, pure, withHandlers, withPropsOnChange } from 'recompose';
import { withStateFetchersOnMount } from '../../../api/injectApi/withStateFetchersOnMount';
import Api from '../../../api';
import BkmdModal from '../../bkmdModal/bkmdModal';
import ModalHeader from '../../bkmdModal/modalHeader';
import DoctorDetails from '../../doctorDetails/doctorDetails';
import PCPSelectionActions from './pcpSelectionActions';
import { member } from '../../../store/member/selectors';

import './pcpDetailsModal.less';

const modalMessages = defineMessages({
  title: {
    defaultMessage: 'Provider Details',
    id: 'members.pcpSelectModal.whereShouldTheyBeLocated',
  },
});

function PcpDetailsModal({
  isOpen,
  providerData,
  memberPlan,
  memberContext,
  member,
  updatePcpSelection,
  handleClose,
  showUpdate,
  closestLocation,
  onLocationChanged,
  clientLocation,
}) {
  const { distances } = providerData;
  const locationId = parseInt(closestLocation, 10);
  const location = _.find(providerData.locations, { id: locationId });
  const distance = distances ? distances[closestLocation] : location.distance;

  return (
    <BkmdModal
      handleClose={handleClose}
      isOpen={isOpen}
      className="pcp-select-details-modal fixed-header"
      key={providerData}
    >
      <ModalHeader title={modalMessages.title.defaultMessage} onBack={handleClose} />
      <div className="dialog-body">
        <div className="doctor-details-wrap">
          <DoctorDetails
            details={providerData}
            distance={distance}
            locationId={locationId}
            memberPlan={memberPlan}
            memberContext={memberContext}
            member={member}
            onLocationChanged={onLocationChanged}
            clientLocation={clientLocation}
            renderActions={() => (
              <PCPSelectionActions
                details={providerData}
                onUpdatePcpSelection={updatePcpSelection}
                showUpdate={showUpdate}
                plan={memberPlan}
                memberContext={memberContext}
                showDetails={false}
              />
            )}
          />
        </div>
      </div>
    </BkmdModal>
  );
}

PcpDetailsModal.propTypes = {
  providerData: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  updatePcpSelection: PropTypes.func.isRequired,
  memberPlan: PropTypes.string,
  memberContext: PropTypes.string,
  member: PropTypes.object,
  showUpdate: PropTypes.bool,
  closestLocation: PropTypes.oneOfType(PropTypes.number, PropTypes.string).isRequired,
  onLocationChanged: PropTypes.func,
  clientLocation: PropTypes.object,
};

PcpDetailsModal.defaultProps = {
  memberPlan: undefined,
  memberContext: '',
  showUpdate: true,
  onLocationChanged: undefined,
};

export default compose(
  pure,
  connect(state => ({
    apiKey: _.get(state, 'config.schedulingSdkKeys.sdkKey'),
    member: member(state),
  })),
  withStateFetchersOnMount({
    fetchProviderProfile: {
      handler: ({ shouldFetchProviderDetails, details, member }) => async () => {
        if (shouldFetchProviderDetails) {
          return Api.searchApi.getProviderByNpi({ npi: details.npi, memberToken: _.get(member, 'memberToken') });
        }

        return details;
      },
      resultPropName: 'providerData',
      isReady: ({ providerData }) => !_.isNil(providerData),
    }
  }),
  withPropsOnChange(['providerData', 'selectedLocation'], ({ providerData, selectedLocation }) => ({
    closestLocation:
        selectedLocation || _.get(providerData, 'closestLocation') || _.get(providerData, 'locations[0].id'),
  })),
  withHandlers({
    updatePcpSelection:
      ({ providerData, updatePcpSelection, handleClose, closestLocation }) => () => {
        updatePcpSelection(providerData, closestLocation);
        handleClose();
      },
  }),
)(PcpDetailsModal);
