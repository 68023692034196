import _ from 'lodash';
import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import { defineMessages, FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import NetworkStatus from '../../doctorCard/sections/network/networkStatus';
import directoryMessages from '../../../messages/providersCommon/messages';
import DoctorLogo from '../../doctorImage/doctorLogo';
import PointDistance from '../../ui/map/pointDistance';
import Rating from '../../doctorCard/sections/rating/rating';
import displayProvider from '../../../utils/displayProvider';
import Formatter from '../../../utils/formatter';
import getProviderPhoneNumber from '../../../utils/getProviderPhoneNumber';
import { memberContext } from '../../../store/member/selectors';
import Text from '../../../storybook/src/components/atoms/Text/Text';
import withFeatureFlag from '../../../utils/featureFlags/withFeatureFlag';

const messages = defineMessages({
  doctorCard: {
    defaultMessage: 'doctor details',
    id: 'members.signIn.doctorCard',
  },
});

const BCBS_SOURCE = 'BLUE_CROSS_BLUE_SHIELD';

@autobind
class Summary extends React.Component {
  static propTypes = {
    details: PropTypes.object.isRequired,
    clientGeo: PropTypes.object,
    pickedLocation: PropTypes.object,
    plan: PropTypes.string,
    chosenCode: PropTypes.string,
    onVideoPreviewClick: PropTypes.func,
    hideVideo: PropTypes.bool.isRequired,
    hideQuote: PropTypes.bool.isRequired,
    hideIcons: PropTypes.bool,
    showNetworkStatus: PropTypes.bool,
    distance: PropTypes.number,
    memberContext: PropTypes.string,
    isInNetworkCustomComponent: PropTypes.func,
    doctorLogoCustomComponent: PropTypes.func,
    officeDetailsCustomComponent: PropTypes.func,
    onLocationChanged: PropTypes.func,
    otherLocations: PropTypes.array,
  };

  static defaultProps = {
    plan: undefined,
    pickedLocation: {},
    hideVideo: false,
    hideQuote: false,
    hideIcons: false,
    chosenCode: undefined,
    showNetworkStatus: true,
    clientGeo: undefined,
    onVideoPreviewClick: _.noop,
    distance: undefined,
    memberContext: undefined,
    isInNetworkCustomComponent: undefined,
    doctorLogoCustomComponent: undefined,
    officeDetailsCustomComponent: undefined,
    onLocationChanged: undefined,
    otherLocations: [],
  };

  getTaxonomiesToDisplay() {
    const {
      chosenCode,
      details: { sources, taxonomiesCodes, taxonomies },
    } = this.props;
    const taxonomiesToDisplay = taxonomiesCodes || taxonomies;
    const chosenCodeArray = _.castArray(chosenCode);
    const filteredTaxonomies = _.filter(taxonomiesToDisplay, (taxonomy) =>
      _.includes(chosenCodeArray, taxonomy.code),
    );
    const taxCollection = _.isEmpty(filteredTaxonomies) ? taxonomiesToDisplay : filteredTaxonomies;
    return _.join(
      _.map(taxCollection, (code) => {
        if (_.includes(sources, BCBS_SOURCE && chosenCode)) {
          return code.sourceDescription;
        }
        return code.desc || code.taxonomyDisplayName;
      }),
      ', ',
    );
  }

  renderDistance() {
    const { pickedLocation, clientGeo, distance } = this.props;
    const pickedLocationGeo = _.get(pickedLocation, 'geo');

    return (
      pickedLocationGeo &&
      (distance || clientGeo) && (
        <div>
          <i className="location-icon icon-map-pin-e" aria-label="Distance" />
          <PointDistance
            message={directoryMessages.clinicDistance}
            distance={distance}
            p1={pickedLocationGeo}
            p2={clientGeo}
          />
        </div>
      )
    );
  }

  renderNetworkStatus() {
    const {
      details: { npi },
      showNetworkStatus,
      isInNetworkCustomComponent: IsInNetworkCustomComponent,
      pickedLocation,
      plan,
    } = this.props;

    if (!showNetworkStatus) {
      return null;
    }

    return (
      <span className="detail">
        {IsInNetworkCustomComponent ? (
          <div className="inline-block">
            <IsInNetworkCustomComponent location={pickedLocation} npi={npi} />
          </div>
        ) : (
          <div className="inline-block">
            <NetworkStatus location={pickedLocation} plan={plan} className="inline-block" />
          </div>
        )}
      </span>
    );
  }

  renderOfficeDetails() {
    const {
      officeDetailsCustomComponent: OfficeDetailsCustomComponent,
      pickedLocation,
      otherLocations,
      onLocationChanged,
      memberContext,
    } = this.props;
    const phoneNumberToCall = getProviderPhoneNumber(pickedLocation, memberContext);
    return (
      <span className="detail">
        {OfficeDetailsCustomComponent ? (
          <OfficeDetailsCustomComponent
            pickedLocation={pickedLocation}
            otherLocations={otherLocations}
            onLocationChanged={onLocationChanged}
            phoneNumberToCall={phoneNumberToCall}
          />
        ) : (
          <Text color="dark" size="16px" weight="semibold" className="margin-top">
            {Formatter.phoneNumber(phoneNumberToCall)}
          </Text>
        )}
      </span>
    );
  }

  render() {
    const {
      details,
      hideVideo,
      hideQuote,
      hideIcons,
      onVideoPreviewClick,
      doctorLogoCustomComponent,
      pickedLocation,
      useBooleanTelehealth,
    } = this.props;
    const {
      firstName,
      lastName,
      suffix,
      level,
      rating,
      ratingCount,
      videoUrl,
      videoPreviewImg,
      videoQuote,
    } = details;
    const providerDisplayName = displayProvider(firstName, lastName, suffix);
    const videoShown = videoUrl && !hideVideo;

    let telehealth = false;
    if (pickedLocation.implementationSpecificFields) {
      telehealth = useBooleanTelehealth
        ? pickedLocation.implementationSpecificFields.telehealth
        : pickedLocation.implementationSpecificFields.telehealth === 'Y';
    }

    const taxonomyDisplay = this.getTaxonomiesToDisplay();
    const DoctorLogoComponent = doctorLogoCustomComponent || DoctorLogo;
    return (
      <div className={classNames('top-section', { 'provider-video-on': videoShown })}>
        <div className="provider-video-section">
          <div className="provider-video-thumbnail">
            <img src={videoPreviewImg} alt="" />
          </div>
          <button className="btn btn-inline-link watch-video-btn" onClick={onVideoPreviewClick}>
            <i className="icon-play" />
            <span className="hidden-xs">
              <FormattedMessage {...directoryMessages.watchVideo} />
            </span>
          </button>
        </div>

        {/*  hero/super-hero class will add matching frame */}
        <div className="doctor-pic">
          <DoctorLogoComponent provider={details} />
        </div>

        <div className="doctor-info">
          <span
            className={classNames({
              'dr-name title-24 text-semibold font-color-brand-main no-margin': videoShown,
              'dr-name text-18 text-semibold text-dark text-center': !videoShown,
            })}
          >
            {providerDisplayName}
            {telehealth ? <i className="icon-video-cam telehealth-icon" /> : undefined}
          </span>

          <div
            className={classNames('doctor-speciality text-center', {
              'text-16 text-dark': videoShown,
              'text-14': videoShown,
            })}
          >
            {taxonomyDisplay}
          </div>
          {!hideQuote && (
            <div className="provider-video-quote margin-top visible-lg visible-md">
              <div className="text-center text-16 text-medium font-color-brand-main">
                {videoQuote}
              </div>
            </div>
          )}
          {!hideIcons && (
            <div className="info-row">
              <span className="detail">{this.renderDistance()}</span>
              {ratingCount >= 3 ? (
                <span className="detail">
                  <Rating rating={rating} level={level} />
                </span>
              ) : null}
              {this.renderNetworkStatus()}
            </div>
          )}
          {this.renderOfficeDetails()}
        </div>
        <h1 className="off-screen-text">
          <FormattedMessage {...messages.doctorCard} />
        </h1>
      </div>
    );
  }
}

export default compose(
  connect((state) => ({
    // Share is relevant only if a user is involved
    memberContext: memberContext(state),
  })),
  withFeatureFlag({
    key: 'premera.useBooleanTelehealth',
    prop: 'useBooleanTelehealth',
    defaultValue: false,
  }),
)(Summary);
