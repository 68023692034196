/**
 * Created by moran on 5/29/17.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import moment from 'moment';
import analytics from '../../store/tools/analytics/analyticsHoc';
import CleanSelect from './cleanSelect';

const options = moment.tz.names().map(tz => ({ value: tz, label: tz }));

@autobind
class CleanTimezoneSelect extends React.Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired
  };

  onChange(e) {
    this.props.onChange(e.value);
  }

  render() {
    return (
      <CleanSelect
        label="Timezone"
        {...this.props}
        options={options}
        onChange={this.onChange}
      />
    );
  }
}

export default analytics(CleanTimezoneSelect, false, [
  { event: 'onChange', eventData: newTimezone => ({ newTimezone }) },
]);
