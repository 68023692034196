/**
 * Created by matan on 5/26/16.
 */
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import classNames from 'classnames';
import Textarea from 'react-textarea-autosize';
import { defineMessages, FormattedMessage } from 'react-intl';

import analytics from '../../store/tools/analytics/analyticsHoc';
import { IntlString, MessageShape } from '../ui/intlString';

const messages = defineMessages({
  limit: {
    defaultMessage: '{chars} of {max} characters',
    id: 'cleanTextArea.limit',
  },
});

class CleanTextArea extends React.PureComponent {
  static propTypes = {
    /**
     * The css class name of the root element.
     */
    className: PropTypes.string,

    /**
     * The id of the root element.
     */
    id: PropTypes.string,

    /**
     * The css class name of the error
     */
    errorClassName: PropTypes.string,

    /**
     * The css class name of the input
     */
    inputClassName: PropTypes.string,

    /**
     * The error content to display.
     */
    errorText: PropTypes.node,

    /**
     * The content to use for the floating label element.
     */
    label: MessageShape,

    /**
     * Class name to apply for the label text
     * Can be either a simple string or
     */
    labelClass: PropTypes.string,

    /**
     * Callback function that is fired when the textfield's value changes.
     */
    onChange: PropTypes.func,

    /**
     * Callback function that is fired when the textfield is blured.
     */
    onBlur: PropTypes.func,

    /**
     * Callback function that is fired when the textfield is blured.
     */
    onFocus: PropTypes.func,

    /**
     * On key up callback
     */
    onKeyUp: PropTypes.func,

    /**
     * The value of the text field.
     */
    value: PropTypes.any,

    /**
     * The help text content to display.
     */
    helpText: MessageShape,

    /**
     * The css class name of the help text
     */
    helpClassName: PropTypes.string,

    /**
     * The maximum length of the input value.
     */
    maxLength: PropTypes.number,

    /**
     * weather we should show a limit help span (X chars out of Y)
     */
    showLimitSpan: PropTypes.bool,
  };

  static defaultProps = {
    className: undefined,
    errorClassName: undefined,
    inputClassName: undefined,
    errorText: undefined,
    label: undefined,
    labelClass: undefined,
    onChange: undefined,
    onFocus: undefined,
    onBlur: undefined,
    onKeyUp: undefined,
    helpText: undefined,
    helpClassName: undefined,
    value: undefined,
    maxLength: undefined,
    id: undefined,
    showLimitSpan: false,
  };

  constructor(props) {
    super(props);

    this.buildClassName = this.buildClassName.bind(this);
    this.updateRef = this.updateRef.bind(this);
  }

  /**
   * Builds input's group class
   * @param className
   * @returns {string|*}
   */
  buildClassName(className) {
    className = `form-group material-form-group ${className}`;
    if (this.props.errorText) {
      className += ' has-error';
    }

    return className;
  }

  updateRef(input) {
    this._input = input;
  }

  renderHelpSpan() {
    const { helpText, helpClassName } = this.props;
    return _.isEmpty(helpText) ? null :
    <IntlString
      className={classNames('help-message', helpClassName)}
      message={helpText}
    />;
  }

  renderLimitSpan() {
    const { value, maxLength, showLimitSpan } = this.props;
    const chars = _.isString(value) ? value.length : 0;
    if (chars < (maxLength - 10) || !showLimitSpan) return null;

    return (
      <span
        className={classNames('textarea-limit', {
          'text-danger': chars === maxLength,
        })}
      >
        <FormattedMessage
          {...messages.limit}
          values={{ chars, max: maxLength }}
        />
      </span>);
  }

  /**
   * Renders the component
   * @returns {XML}
   */
  render() {
    // Extract properties
    let {
      className,
      errorClassName,
      label,
      labelClass,
      value,
      onFocus,
      onBlur,
      onKeyUp,
      maxLength,
      id,
      ...other
    } = this.props;

    className = this.buildClassName(className);
    const errorSpan = !this.props.errorText ? '' :
      (
        <IntlString
          message={this.props.errorText}
          className={classNames('validation-message', errorClassName)}
        />
      );
    const inlineStyle = { minHeight: 29 };
    return (
      <div className={className}>
        <Textarea
          className={classNames('clean-input clean-text-area', this.props.inputClassName)}
          value={value}
          onBlur={onBlur}
          onFocus={onFocus}
          onKeyUp={onKeyUp}
          style={inlineStyle}
          {..._.omit(other, ['showLimitSpan',
            'inputClassName',
            'errorText',
            'helpText',
            'helpClassName',
            'hideErrorWhileFocused',
            'focused',
            'field'])}
          ref={this.updateRef}
          maxLength={maxLength}
          id={id}
        />
        <label
          className={classNames(
            'static-label-header',
            { 'input-active': !!this.props.value },
            labelClass)}
          htmlFor={id}
        >
          <IntlString message={label} />
        </label>
        {errorSpan}
        {this.renderHelpSpan()}
        {this.renderLimitSpan()}
      </div>
    );
  }
}

export default analytics(CleanTextArea, false);
