import BookForADoctor from '../steps/bookForADoctor';
import GetProviderData from '../steps/getProviderData';
import Intro from '../steps/bookForADoctorIntro/bookForADoctorIntro';

export const name = 'pcpPreSelectionFlow';

export const steps = {
  SHOW_PROVIDER_FOR_BOOK: 'showProviderForBook',
  GET_PROVIDER: 'getProvider',
  INTRO: 'intro',
};

export const flow = {
  [steps.INTRO]: {
    component: Intro,
    transitions: {
      NEXT: steps.GET_PROVIDER,
    },
  },
  [steps.GET_PROVIDER]: {
    component: GetProviderData,
    transitions: {
      PREV: steps.INTRO,
      NEXT: steps.SHOW_PROVIDER_FOR_BOOK,
    },
    mapFlowDataToProps: ({ npiList }) => ({ npiList }),
    mapPayloadToFlowData: ({ member, providers }) => ({ member, providers }),
  },
  [steps.SHOW_PROVIDER_FOR_BOOK]: {
    component: BookForADoctor,
    mapFlowDataToProps: flowData => ({
      providers: flowData.providers,
      member: flowData.member,
      token: flowData.token,
    }),
    transitions: {},
  },
};

export const initialStepName = steps.INTRO;
