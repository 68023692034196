import { storeJwt, removeJwt } from '../../utils/auth/storage';

export default function (response) {
  removeJwt();
  const { access_token, refresh_token, expired_token, reason } = response.data;
  const isAuthenticated = !!access_token; // eslint-disable-line camelcase

  storeJwt(access_token, refresh_token);
  return {
    isAuthenticated,
    accessToken: access_token,
    refreshToken: refresh_token,
    expiredToken: expired_token,
    reason,
  };
}
