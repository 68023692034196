/**
 * Created by chenrozenes on 20/07/2017.
 */
import { defineMessages } from 'react-intl';
import IntlEnum from '../../utils/intlEnum';

const messages = defineMessages({
  sameDay: {
    defaultMessage: 'Same day',
    id: 'enum.providerWaitTime.sameDay'
  },
  sameDayLong: {
    defaultMessage: 'Same day appointments',
    id: 'enum.providerWaitTime.sameDayLong'
  },
  sameWeek: {
    defaultMessage: 'Same week',
    id: 'enum.providerWaitTime.sameWeek'
  },
  sameWeekLong: {
    defaultMessage: 'Same week appointments',
    id: 'enum.providerWaitTime.sameWeekLong'
  },
  sameMonth: {
    defaultMessage: 'In a month',
    id: 'enum.providerWaitTime.SameMonth'
  },
  overAMonth: {
    defaultMessage: 'Over a month',
    id: 'enum.providerWaitTime.overAMonth'
  },
  notAccepting: {
    defaultMessage: 'Not accepting',
    id: 'enum.providerWaitTime.notAccepting'
  },
});

export const SAME_DAY = 'sameDay';
export const SAME_WEEK = 'sameWeek';
export const SAME_MONTH = 'sameMonth';
export const OVER_A_MONTH = 'overAMonth';
export const NOT_ACCEPTING = 'notAccepting';

export const options = [SAME_DAY, SAME_WEEK, SAME_MONTH, OVER_A_MONTH, NOT_ACCEPTING];

export default new IntlEnum(
  { value: SAME_DAY, message: messages.sameDay, fullMessage: messages.sameDayLong },
  { value: SAME_WEEK, message: messages.sameWeek, fullMessage: messages.sameWeekLong },
  { value: SAME_MONTH, message: messages.sameMonth, fullMessage: messages.sameMonth },
  { value: OVER_A_MONTH, message: messages.overAMonth, fullMessage: messages.overAMonth },
  { value: NOT_ACCEPTING, message: messages.notAccepting, fullMessage: messages.notAccepting },
);
