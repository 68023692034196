/**
 * Created by meirshalev on 19/10/2017.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';

import { routeToExternal } from '../../store/externalLinks/actions';
import loadingPage from './loadingPage';

@autobind
class ExternalSSOLink extends React.Component {
  static propTypes = {
    routeToExternal: PropTypes.func.isRequired,
    url: PropTypes.string.isRequired,
    ariaLabel: PropTypes.string,
    endpoint: PropTypes.string.isRequired,
  };

  static defaultProps = {
    ariaLabel: null,
  };

  onClick(e) {
    e.preventDefault();
    const newWindow = window.open('', '_blank');
    newWindow.document.write(loadingPage);
    this.props.routeToExternal(this.props.endpoint, this.props.url, newWindow);
  }

  render() {
    const { ariaLabel } = this.props;
    return (
      <button className="btn btn-inline-link" onClick={this.onClick} aria-label={ariaLabel}>
        {this.props.children}
      </button>
    );
  }
}

export default compose(
  connect(
    null,
    { routeToExternal },
  )
)(ExternalSSOLink);
