/**
 * Created by moran on 6/8/17.
 */

const paths = {
  home: () => '/',
  expiry: () => '/review/expiry',
  doctorStarRating: (token = ':token') => `/review/${token}/doctorStarRate`,
  positiveDoctor: (token = ':token') => `/review/${token}/positiveRatingDr`,
  negativeDoctor: (token = ':token') => `/review/${token}/negativeRatingDr`,
  positiveOffice: (token = ':token') => `/review/${token}/positiveRatingOffice`,
  negativeOffice: (token = ':token') => `/review/${token}/negativeRatingOffice`,
  doctorTextReview: (token = ':token') => `/review/${token}/doctorTextReview`,
  thankYou: () => '/review/reviewThanks',
};

export default paths;
