import { log } from '../logger';
import FeatureFlagClient from '../featureFlags/featureFlagClient';

const setupFeatureFlagClient = async ({ key }) => {
  log('initializing feature flag client');
  await FeatureFlagClient.init(key);
  log('feature flag client initialized');
};

export default setupFeatureFlagClient;
