import React from 'react';
import PropTypes from 'prop-types';
import { compose, withStateHandlers, withState } from 'recompose';
import { withRouter } from 'react-router';
import { defineMessages, FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { BkmdModal } from '../bkmdModal';
import { createToggleState, createToggleStatePropTypes } from '../recompose/toggleStateComponent';

const messages = defineMessages({
  signInOrCreateAnAccountToSeeMore: {
    defaultMessage: 'Sign in or create an<br/>account to see more',
    id: 'prompt.redirectModal.signInOrCreateAnAccountToSeeMore'
  },
  noThanks: {
    defaultMessage: 'No thanks',
    id: 'prompt.redirectModal.noThanks'
  },
  okay: {
    defaultMessage: 'Okay',
    id: 'prompt.redirectModal.okay'
  },
  loggingIntoYourAccountWillGiveYouSecure: {
    defaultMessage: 'Logging into your account will give you secure access to more information.\n',
    id: 'prompt.redirectModal.loggingIntoYourAccountWillGiveYouSecure'
  },
});

const PromptModal = ({ onSignInClick, onCancelClick, isOpen }) => (
  <BkmdModal isOpen={isOpen} handleClose={onCancelClick} className="mini-modal">
    <div className="dialog-body">
      <div className="row">
        <div className="col-xs-12 text-16 text-dark text-semibold text-center">
          <FormattedHTMLMessage {...messages.signInOrCreateAnAccountToSeeMore} />
        </div>
      </div>
      <div className="row margin-top">
        <div className="col-xs-12 padding-h-20 text-center">
          <FormattedMessage {...messages.loggingIntoYourAccountWillGiveYouSecure} />
        </div>
      </div>
      <div className="row tiny-gutter margin-top-20">
        <div className="col-xs-6">
          <button className="btn btn-big btn-grey btn-block" onClick={onCancelClick}>
            <FormattedMessage {...messages.noThanks} />
          </button>
        </div>
        <div className="col-xs-6">
          <button className="btn btn-big btn-block bg-color-brand-button" onClick={onSignInClick}>
            <FormattedMessage {...messages.okay} />
          </button>
        </div>
      </div>
    </div>
  </BkmdModal>
);

PromptModal.propTypes = {
  onSignInClick: PropTypes.func.isRequired,
  onCancelClick: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired
};

const PromptRedirect = ({ promptModalHide, promptModalOn, push }) => (
  <PromptModal isOpen={promptModalOn} onSignInClick={push} onCancelClick={promptModalHide} />
);

PromptRedirect.propTypes = {
  ...createToggleStatePropTypes('promptModal')
};

const PromptRedirectHoc = WrappedComponent =>
  class extends React.PureComponent {
    render() {
      return (
        <div>
          <WrappedComponent {...this.props} />
          <PromptRedirect {...this.props} />
        </div>
      );
    }
  };

export const withPromptRedirect = compose(
  withRouter,
  createToggleState('promptModal'),
  withState('url', 'setUrl', null),
  withStateHandlers(null, {
    redirect: (_, { router, location, promptModalShow }) => (url, options = {}) => {
      let shouldRoute;
      const pathname = _.get(location, 'pathname');
      // do you route to a secure page from an unsecured page?
      if (pathname) {
        shouldRoute = location.pathname.startsWith('/secure') || !url.startsWith('/secure');
      } else {
        shouldRoute = !url.startsWith('/secure');
      }

      // If passed force, always route
      if (shouldRoute || options.force) {
        router.push(url);
      } else {
        promptModalShow();
      }
      return { push: () => router.push(url) };
    }
  }),
  PromptRedirectHoc
);
