/**
 * Created by moran on 6/7/17.
 */
/* eslint-disable import/first */
/**
 * Created by moran on 6/6/17.
 */
if (typeof require.ensure !== 'function') require.ensure = (d, c) => c(require);

import CollectReviewPage from '../collectReviewPage';
import paths from './paths';

// Routes
const DoctorStarRate = require("../doctorReviewPages/doctorStarRate");
const NegativeRatingDr = require("../doctorReviewPages/negativeRatingDr");
const NegativeRatingOffice = require("../doctorReviewPages/negativeRatingOffice");
const PositiveRatingDr = require("../doctorReviewPages/positiveRatingDoctor");
const PositiveRatingOffice = require("../doctorReviewPages/positiveRatingOffice");
const DoctorTextReview = require("../doctorReviewPages/doctorTextReview");

// Components
const Components = [
  {
    path: paths.doctorStarRating(),
    name: 'doctorStarRate',
    component: DoctorStarRate.default || DoctorStarRate,
  },
  {
    path: paths.negativeDoctor(),
    name: 'negativeRatingDr',
    component: NegativeRatingDr.default || NegativeRatingDr,
  },
  {
    path: paths.negativeOffice(),
    name: 'negativeRatingOffice',
    component: NegativeRatingOffice.default || NegativeRatingOffice,
  },
  {
    path: paths.positiveDoctor(),
    name: 'positiveRatingDr',
    component: PositiveRatingDr.default || PositiveRatingDr,
  },
  {
    path: paths.positiveOffice(),
    name: 'positiveRatingOffice',
    component: PositiveRatingOffice.default || PositiveRatingOffice,
  },
  {
    path: paths.doctorTextReview(),
    name: 'doctorTextReview',
    component: DoctorTextReview.default || DoctorTextReview,
  },
];

export default {
  path: ':token',
  name: 'reviewFlow',
  getChildRoutes(location, cb) {
    require.ensure([], () => {
      cb(null, Components);
    });
  },

  // Requiring secured page NOT dynamically so it won't be unmounted every route
  component: CollectReviewPage,
};
/* eslint-enable import/first */
