/**
 * Created by matan on 3/15/17.
 */
import React from 'react';
import Layout from '../layout/layout';
import HelpContent from './helpContent';

const HelpPage = function HelpPage() {
  return (
    <Layout footerClassName="xs-not-fixed white">
      <div className="help-container">
        <HelpContent />
      </div>
    </Layout>
  );
};

export default HelpPage;
