import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '../../storybook/src/utils/theme';

function SvgDoctorCircle({ theme }) {
  /* eslint-disable */
  return (
    <svg aria-hidden="true" focusable="false" width="71px" height="71px" viewBox="0 0 71 71" version="1.1" className="svg-icon svg-doctor-circle">
      <g id="Subscriber---Children---has-different-PCP-" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="has-different-PCP-3" transform="translate(-114.000000, -807.000000)">
          <g id="Group-3" transform="translate(65.000000, 716.000000)">
            <g id="Page-1" transform="translate(49.000000, 91.000000)">
              <path d="M10.6995,56.469 C16.9035,63.811 25.8865,68 35.5005,68 C45.1145,68 54.0965,63.811 60.3015,56.469 C56.8395,50.388 51.1275,45.679 44.4925,43.471 C43.9735,43.299 43.5915,42.859 43.4915,42.322 C43.3915,41.784 43.5925,41.236 44.0135,40.889 C47.1825,38.288 49.0005,34.501 49.0005,30.5 C49.0005,23.056 42.9445,17 35.5005,17 C28.0555,17 22.0005,23.056 22.0005,30.5 C22.0005,34.501 23.8175,38.288 26.9865,40.889 C27.4085,41.236 27.6085,41.784 27.5095,42.322 C27.4095,42.859 27.0265,43.299 26.5085,43.471 C19.8735,45.679 14.1615,50.388 10.6995,56.469 M35.5005,71 C24.6375,71 14.5135,66.106 7.7245,57.572 C7.3555,57.108 7.2945,56.468 7.5695,55.943 C11.0015,49.383 16.5215,44.321 23.2715,41.485 C20.5325,38.466 19.0005,34.576 19.0005,30.5 C19.0005,21.403 26.4025,14 35.5005,14 C44.5975,14 52.0005,21.403 52.0005,30.5 C52.0005,34.576 50.4685,38.466 47.7285,41.485 C54.4785,44.321 59.9995,49.383 63.4305,55.943 C63.7065,56.468 63.6445,57.108 63.2755,57.572 C56.4865,66.106 46.3625,71 35.5005,71" id="Fill-1" fill={theme.secondaryColor} ></path>
              <g id="Group-5" fill={theme.mainColor} >
                <path d="M64.7698,52.8212926 C64.0398,52.4302926 63.7648,51.5212928 64.1578,50.7912929 C66.6708,46.1052935 67.9998,40.8182943 67.9998,35.500295 C67.9998,17.5792975 53.4208,3.00029958 35.4998,3.00029958 C17.5798,3.00029958 2.9998,17.5792975 2.9998,35.500295 C2.9998,40.8182943 4.3298,46.1052935 6.8428,50.7912929 C7.2358,51.5212928 6.9608,52.4302926 6.2308,52.8212926 C5.5028,53.2132925 4.5918,52.9402926 4.2008,52.2092927 C1.4528,47.0902934 -0.0002,41.3122942 -0.0002,35.500295 C-0.0002,15.9252978 15.9248,0.0003 35.4998,0.0003 C55.0758,0.0003 70.9998,15.9252978 70.9998,35.500295 C70.9998,41.3122942 69.5478,47.0902934 66.7998,52.2092927 C66.5298,52.7142926 66.0118,53.0002926 65.4778,53.0002926 C65.2378,53.0002926 64.9958,52.9422926 64.7698,52.8212926 Z" id="Fill-3"></path>
              </g>
              <path d="M20.8352,52.8558 L20.8352,44.1838 C20.8352,43.3558 21.5072,42.6838 22.3352,42.6838 C23.1632,42.6838 23.8352,43.3558 23.8352,44.1838 L23.8352,52.8558 C23.8352,53.6838 23.1632,54.3558 22.3352,54.3558 C21.5072,54.3558 20.8352,53.6838 20.8352,52.8558 Z" id="Fill-6" fill={theme.secondaryColor} ></path>
              <path d="M47.7737,52.2659 C46.9457,52.2659 46.2737,51.5939 46.2737,50.7659 L46.2737,43.7049 C46.2737,42.8769 46.9457,42.2049 47.7737,42.2049 C48.6017,42.2049 49.2737,42.8769 49.2737,43.7049 L49.2737,50.7659 C49.2737,51.5939 48.6017,52.2659 47.7737,52.2659" id="Fill-8" fill={theme.secondaryColor} ></path>
              <path d="M22.5139,54.7806 C21.2939,54.7806 20.3009,55.7736 20.3009,56.9936 C20.3009,58.2136 21.2939,59.2056 22.5139,59.2056 C23.7339,59.2056 24.7269,58.2136 24.7269,56.9936 C24.7269,55.7736 23.7339,54.7806 22.5139,54.7806 M22.5139,62.2056 C19.6399,62.2056 17.3009,59.8676 17.3009,56.9936 C17.3009,54.1196 19.6399,51.7806 22.5139,51.7806 C25.3879,51.7806 27.7269,54.1196 27.7269,56.9936 C27.7269,59.8676 25.3879,62.2056 22.5139,62.2056" id="Fill-10" fill={theme.secondaryColor} ></path>
              <path d="M50.1643,61.303 C49.3363,61.303 48.6643,60.631 48.6643,59.803 C48.6643,59.127 49.1113,58.555 49.7273,58.367 L49.7273,54.218 C49.7273,53.142 48.8513,52.266 47.7733,52.266 C46.6973,52.266 45.8213,53.142 45.8213,54.218 L45.8213,58.367 C46.4363,58.555 46.8843,59.127 46.8843,59.803 C46.8843,60.631 46.2123,61.303 45.3843,61.303 C43.9713,61.303 42.8213,60.154 42.8213,58.74 L42.8213,54.218 C42.8213,51.488 45.0433,49.266 47.7733,49.266 C50.5053,49.266 52.7273,51.488 52.7273,54.218 L52.7273,58.74 C52.7273,60.154 51.5773,61.303 50.1643,61.303" id="Fill-12" fill={theme.secondaryColor} ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
  /* eslint-enable */
}

SvgDoctorCircle.propTypes = {
  theme: PropTypes.object.isRequired,
};

export default withTheme(SvgDoctorCircle);
