import React from 'react';
import PropTypes from 'prop-types';

import analytics from '../../store/tools/analytics/analyticsHoc';
import CleanInput from './cleanInput';

class CleanRepeatPasswordInput extends React.Component {
  static propTypes = {
    /**
     * Component optional outer class name
     */
    className: PropTypes.string,

    /**
     * Currently inputed password
     */
    value: PropTypes.string,

    // PasswordMeter props
    hideHelpers: PropTypes.bool,
  };

  static defaultProps = {
    className: '',
    hideHelpers: false,
    value: '',
  };

  constructor(props) {
    super(props);
    this.state = { showRepeatPassword: false };

    this.inputIconClick = this.inputIconClick.bind(this);
  }

  inputIconClick(eyeType) {
    this.setState({ [eyeType]: !this.state[eyeType] });
  }

  changePasswordVisibility(eyeType) {
    return this.state[eyeType] ? 'text' : 'password';
  }

  render() {
    const { className, value, hideHelpers, ...other } = this.props;
    const { showPassword } = this.state;

    return (
      <CleanInput
        {...other}
        type={this.changePasswordVisibility('showRepeatPassword')}
        value={value}
        rightIcon={!hideHelpers ? 'icon-eye' : ''}
        rightIconClick={() => this.inputIconClick('showRepeatPassword')}
        rightIconType="button"
        name="cleanRepeatPassword"
        rightIconLabel="Show password"
        rightIconState={showPassword}
      />
    );
  }
}

export default analytics(CleanRepeatPasswordInput, false);
