/**
 * Created by moran on 5/23/17.
 */
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { autobind } from 'core-decorators';

import analytics from '../../store/tools/analytics/analyticsHoc';
import { IntlString, MessageShape } from '../ui/intlString';
import Tag from './tag';
import CleanSelect from './cleanSelect';
import './cleanTagList.less';

@autobind
class CleanTagList extends React.Component {
  static propTypes = {
    value: PropTypes.array.isRequired,
    suggestions: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    label: MessageShape,
    placeholder: PropTypes.string,
  };

  static defaultProps = {
    label: '',
    placeholder: '',
  };

  handleDelete(value) {
    const languages = _.reject(this.props.value, { value });
    this.props.onChange(languages);
  }

  handleAddition(val) {
    this.props.onChange(_.concat(this.props.value, val));
  }

  renderTags() {
    const { value } = this.props;

    return _.map(value, tag => (
      <Tag onClick={this.handleDelete} key={tag.value} id={tag.value} value={tag.label} />));
  }

  render() {
    const { label, placeholder, suggestions } = this.props;

    return (
      <div>
        <div>
          <IntlString message={label} />
        </div>
        <div className="clean-tag-list-selected">
          {this.renderTags()}
        </div>
        <CleanSelect
          placeholder={placeholder}
          options={suggestions}
          onChanged={this.handleAddition}
        />
      </div>
    );
  }
}

export default analytics(CleanTagList, false, [
  { event: 'onChange', eventData: newTags => ({ newTags }) },
]);
