import _ from 'lodash';
import { connect } from 'react-redux';
import { compose, withHandlers, withState } from 'recompose';
import FeatureFlagClient from '../../utils/featureFlags/featureFlagClient';
import withEffects from '../recompose/withEffects';

const password = [86, 73, 77, 49, 51, 51, 55]; // vim1337

export const withRolloutFlagsView = compose(
  connect(state => ({ env: _.get(state, 'config.APP_ENV') })),
  withState('keystroke', 'updateKeystroke', []),
  withHandlers({
    handleKey: ({ keystroke, updateKeystroke }) => e => {
      let newKeystroke = [...keystroke];
      newKeystroke.push(e.keyCode);

      newKeystroke =
        newKeystroke.length >= password.length + 1
          ? _.slice(newKeystroke, 1, password.length + 1)
          : newKeystroke;
      updateKeystroke(newKeystroke);

      if (_.isEqual(newKeystroke, password)) {
        FeatureFlagClient.showOverrides();
      }
    },
  }),
  withEffects({
    listenToFlags: {
      action: ({ handleKey }) => {
        document.addEventListener('keydown', handleKey);
      },
      didMountPredicate: ({ env }) => ['development', 'staging', 'local'].includes(env),
      didUpdatePredicate: false,
    },
  }),
);
