import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import { compose } from 'redux';
import { Dropdown, MenuItem } from 'react-bootstrap';
import { defineMessages, FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router';

import * as domains from '../../../../../model/enum/bkmdDomain';
import { getFeature, getDomain } from '../../../../../store/feature/selectors';
import { mobileMenuOpen } from '../../../../../store/ui/actions';
import { isMobile } from '../../../../../store/platform/selectors';
import SvgHelp from '../../../../../assets/svg/help';
import SvgMug from '../../../../../assets/svg/mug';
import SvgPremera from '../../../../../assets/svg/premeraIcon';
import SvgSmartphone from '../../../../../assets/svg/smartphone';
import FeatureFlagged from '../../../../../components/features/featureFlagged';
import { getPathsFromContext } from '../../routes/injectPathsToContext';

import './helpMenu.less';

const messages = defineMessages({
  help: {
    defaultMessage: 'Help',
    id: 'members.helpMenu.help',
  },
  helpCenter: {
    defaultMessage: 'Help center',
    id: 'members.helpMenu.helpCenter',
  },
  weveGotLotsOfAnswersHere: {
    defaultMessage: 'We’ve got lots of answers here. So if you need help, check it out.',
    id: 'members.helpMenu.weveGotLotsOfAnswersHere',
  },
  contactDomain: {
    defaultMessage: 'Contact {domainName}',
    id: 'members.helpMenu.contactDomain',
  },
  forQuestionsAboutYourHealthCoverage: {
    defaultMessage: 'For questions about your health coverage and your membership.',
    id: 'members.helpMenu.forQuestionsAboutYourHealthCoverage',
  },
  contactVim: {
    defaultMessage: 'Contact Support',
    id: 'members.helpMenu.contactVim',
  },
  forQuestionsAboutYourAccount: {
    defaultMessage: 'For questions about your account and technical support.',
    id: 'members.helpMenu.forQuestionsAboutYourAccount',
  },
});

@autobind
class HelpMenu extends React.PureComponent {
  static propTypes = {
    helpScout: PropTypes.object.isRequired,
    isMobileScreen: PropTypes.bool.isRequired,
    mobileMenuOpen: PropTypes.func.isRequired,
    closeMainMenu: PropTypes.func,
    router: PropTypes.object.isRequired,
    domainName: PropTypes.string.isRequired,
    domain: PropTypes.string.isRequired,
    paths: PropTypes.object.isRequired,
    id: PropTypes.string.isRequired,
  };

  static defaultProps = {
    closeMainMenu() {},
  };

  helpCenterClick() {
    this.props.helpScout.open();
    this.props.closeMainMenu();
  }

  loadContactPremera() {
    this.props.router.push(this.props.paths.premeraHelp());
    this.props.closeMainMenu();
  }

  loadContactUhcAllsavers() {
    this.props.router.push(this.props.paths.uhcAllsaversHelp());
    this.props.closeMainMenu();
  }

  loadContactAnthem() {
    this.props.router.push(this.props.paths.anthemHelp());
    this.props.closeMainMenu();
  }

  loadContactFlorida() {
    this.props.router.push(this.props.paths.floridaHelp());
    this.props.closeMainMenu();
  }

  loadContactVim() {
    this.props.router.push(this.props.paths.help());
    this.props.closeMainMenu();
  }

  backToMenu(notRelevant, e) {
    if (this.props.isMobileScreen) {
      this.props.mobileMenuOpen(true);
      e.stopPropagation();
    }
  }

  render() {
    let loadContactDomain = null;
    switch (this.props.domain) {
      case domains.PREMERA_BLUE_CROSS_ALASKA:
        loadContactDomain = this.loadContactPremera;
        break;
      case domains.UNITED_HEALTH_CARE_ALLSAVERS:
        loadContactDomain = this.loadContactUhcAllsavers;
        break;
      case domains.ANTHEM:
        loadContactDomain = this.loadContactAnthem;
        break;
      case domains.FLORIDA_BLUE:
        loadContactDomain = this.loadContactFlorida;
        break;
      default:
        break;
    }
    const { id } = this.props;
    return (
      <Dropdown id={id} className="help-menu xs-fullscreen-dropdown">
        <Dropdown.Toggle
          bsStyle="inline-link text-semibold help-menu-toggle"
          noCaret
          aria-label="Help"
          tabIndex="0"
          focusable="true"
        >
          <span className="visible-xs">
            <FormattedMessage {...messages.help} />
          </span>
          <i className="icon-help-w hidden-xs" aria-label="Help" />
          <span className="off-screen-text">
            <FormattedMessage {...messages.help} />
          </span>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <MenuItem className="dropdown-header visible-xs" onSelect={this.backToMenu}>
            <span className="text-18 text-semibold text-main">
              <FormattedMessage {...messages.help} />
              <i className="icon-back-arrow dropdown-back-btn left" />
            </span>
          </MenuItem>
          <FeatureFlagged uri="help.helpCenter" renderChildren>
            <MenuItem onSelect={this.helpCenterClick}>
              <SvgHelp />
              <span className="item-title text-16 text-semibold">
                <FormattedMessage {...messages.helpCenter} />
              </span>
              <div>
                <FormattedMessage {...messages.weveGotLotsOfAnswersHere} />
              </div>
            </MenuItem>
          </FeatureFlagged>
          <FeatureFlagged uri="help.contactDomain" renderChildren>
            <MenuItem onSelect={loadContactDomain}>
              <FeatureFlagged uri="help.premeraLogoIcon" renderChildren>
                <SvgPremera />
              </FeatureFlagged>
              <FeatureFlagged uri="help.uhcLogoIcon" renderChildren>
                <SvgSmartphone />
              </FeatureFlagged>
              <FeatureFlagged uri="help.floridaLogoIcon" renderChildren>
                <SvgPremera />
              </FeatureFlagged>
              <span className="item-title text-16 text-semibold">
                <FormattedMessage
                  {...messages.contactDomain}
                  values={{ domainName: this.props.domainName }}
                />
              </span>
              <div>
                <FormattedMessage {...messages.forQuestionsAboutYourHealthCoverage} />
              </div>
            </MenuItem>
          </FeatureFlagged>
          <FeatureFlagged uri="help.contactVim" renderChildren>
            <MenuItem onSelect={this.loadContactVim}>
              <SvgMug />
              <span className="item-title text-16 text-semibold">
                <FormattedMessage {...messages.contactVim} />
              </span>
              <div>
                <FormattedMessage {...messages.forQuestionsAboutYourAccount} />
              </div>
            </MenuItem>
          </FeatureFlagged>
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}

export default compose(
  withRouter,
  getPathsFromContext(),
  connect(
    state => ({
      helpScout: state.help.helpScout,
      isMobileScreen: isMobile(state),
      domainName: getFeature(state, 'site.clientName'),
      domain: getDomain(state),
    }),
    { mobileMenuOpen },
  ),
)(HelpMenu);
