/**
 * Created by chenrozenes on 26/04/2017.
 */
import React from 'react';
import PropTypes from 'prop-types';
import Formatter from '../../../utils/formatter';

export default function PhoneNumber({ value }) {
  return <span>{value && value.toJSON ? Formatter.phoneNumberSimple(value.toJSON()) : ''}</span>;
}

PhoneNumber.propTypes = {
  value: PropTypes.object
};

PhoneNumber.defaultProps = {
  value: {}
};
