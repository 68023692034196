/**
 * Created by matan on 6/5/16.
 */
import S3Upload from 'react-s3-uploader/s3upload';
import _ from 'lodash';

import PromiseActionType from '../promiseActionType';

export const UPLOAD_FILE = 'UPLOAD_FILE';
export const UPLOAD_PROGRESS = 'UPLOAD_PROGRESS';
export const ABORT_UPLOAD = 'ABORT_UPLOAD';
export const FORCE_ERROR = 'FORCE_ERROR';
export const DROP_UPLOAD_BY_ID = 'DROP_UPLOAD_BY_ID';
export const CLEAR = 'CLEAR_NON_PENDING';
export const GET_FILE_METADATA = new PromiseActionType('GET_FILE_METADATA');

function uploadFileProgress(id, percent, status, file, managerName) {
  return {
    type: UPLOAD_PROGRESS,
    payload: {
      id,
      file,
      percent,
      status,
      managerName,
    },
  };
}

export function uploadFile(signedUrl, id, file, managerName) {
  return ({ dispatch }) => {
    const upload = new S3Upload({
      contentDisposition: 'inline',
    });
    return {
      type: UPLOAD_FILE,
      payload: {
        managerName,
        data: { file, id, upload, managerName },
        promise: new Promise((resolve, reject) => {
          upload.onError = status => reject({ id, status, managerName });
          upload.onFinishS3Put = () => resolve({ id, managerName });
          upload.onProgress = (percent, status) =>
            dispatch(uploadFileProgress(id, percent, status, file, managerName));

          upload.uploadToS3(file, { signedUrl });
        }),
      },
    };
  };
}

export function forceError(id, managerName) {
  return {
    type: FORCE_ERROR,
    payload: { id, managerName },
  };
}

export function uploadAborted(id, managerName) {
  return {
    type: ABORT_UPLOAD,
    payload: {
      id,
      managerName,
      status: 'ABORTED',
    },
  };
}

export function abortUploads(ids, managerName) {
  return {
    type: DROP_UPLOAD_BY_ID,
    payload: { managerName, ids: _.castArray(ids) },
  };
}

export function clearUploads(managerName) {
  return { payload: { managerName }, type: CLEAR };
}

export function getFileMetadata(id, managerName) {
  return ({ bkmdApi: { metaApi } }) => ({
    type: GET_FILE_METADATA.SOURCE,
    meta: {
      tracker: GET_FILE_METADATA.SOURCE,
    },
    payload: {
      managerName,
      promise: metaApi.getFileMetadata(id).then(res => _.assign({ id }, res.data)),
    },
  });
}
