import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { pure, compose, setDisplayName } from 'recompose';
import { defineMessages, FormattedMessage } from 'react-intl';
import _ from 'lodash';
import SvgPooBoxCheck from '../assets/svg/poo/pooBoxCheck';
import States from '../components/form/stateSelect/states';

const messages = defineMessages({
  itllBeShippedTo: {
    defaultMessage: 'It’ll be shipped to',
    id: 'screenTemplates.orderReview.itllBeShippedTo',
  },
});

function OrderReview({
  className,
  title,
  buttonText,
  svgIcon,
  address: { addressLine1, addressLine2, city, state, zip },
  onButtonClick,
}) {
  return (
    <div className={classNames('order-review-page padding-h-30', className)}>
      <div className="row margin-top">
        <div className="col-xs-12 text-center svg-72">{svgIcon}</div>
      </div>

      <div className="row margin-top-20">
        <div className="col-xs-12">
          <div className="title-24 text-strong-dark text-center no-margin">{title}</div>
        </div>
      </div>

      <div className="row margin-top-30">
        <div className="col-xs-12">
          <div className="text-18 text-semibold text-dark text-center">
            <FormattedMessage {...messages.itllBeShippedTo} />
          </div>
        </div>
      </div>

      <div className="row margin-top-30">
        <div className="col-xs-12 text-center">
          {addressLine1}<br />
          {addressLine2} {addressLine2 && <br />}
          {city}, {States[state]} {zip}
        </div>
      </div>

      <div className="row margin-top-30 margin-bottom-20">
        <div className="col-xs-12">
          <button className="btn btn-big btn-outline" onClick={onButtonClick}>
            {buttonText}
          </button>
        </div>
      </div>
    </div>
  );
}

OrderReview.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  buttonText: PropTypes.string,
  svgIcon: PropTypes.object,
  address: PropTypes.object,
  onButtonClick: PropTypes.func,
};
OrderReview.defaultProps = {
  className: undefined,
  title: undefined,
  svgIcon: <SvgPooBoxCheck />,
  buttonText: 'View video',
  address: {},
  onButtonClick: _.noop,
};

const enhance = compose(
  setDisplayName('SCR030OrderReview'),
  pure,
);

export default enhance(OrderReview);
