import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import _ from 'lodash';

import CalendarMd from '../../../../../assets/svg/calendarMd';
import ProviderWaitTime, { NOT_ACCEPTING } from '../../../../../model/enum/providerWaitTime';

import './BookingHorizonChip.less';

function BookingHorizonChip({ bookingHorizon, shortText, className }) {
  const enumValue = ProviderWaitTime[bookingHorizon];
  if (_.isNil(enumValue) || enumValue.value === NOT_ACCEPTING) return null;

  const message = shortText ? enumValue.message : enumValue.fullMessage;
  return (
    <div className="availability-item booking-horizon">
      <CalendarMd />
      <div className={classNames('item-text', className)}>
        <FormattedMessage {...message} />
      </div>
    </div>
  );
}

BookingHorizonChip.propTypes = {
  bookingHorizon: PropTypes.string.isRequired,
  shortText: PropTypes.bool,
  className: PropTypes.string,
};

BookingHorizonChip.defaultProps = {
  shortText: false,
  className: '',
};

export default BookingHorizonChip;
