/**
 * Created by galgoltzman on 19/03/2018.
 */
import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { defineMessages, FormattedMessage } from 'react-intl';
import _ from 'lodash';
import { autobind } from 'core-decorators';
import PropTypes from 'prop-types';
import { renderNothing, branch } from 'recompose';

import Layout from '../layout/layout';
import BlueHeader from '../layout/blueHeader/blueHeader';
import SvgMedicalTeam from '../../../../assets/svg/medicalTeam';
import SvgDoctorStars from '../../../../assets/svg/doctorStars';
import {
  getPcpSelection,
  getDependents,
  updatePcpSelection,
  GET_PCP_SELECTION,
  UPDATE_PCP_SELECTION,
  GET_DEPENDENTS,
} from '../../../../store/member/medicalTeam/actions';
import * as medicalTeamSelectors from '../../../../store/member/medicalTeam/selectors';
import PcpCard from './pcpCard';
import { getPathsFromContext } from '../routes/injectPathsToContext';
import actionTracker from '../../../../store/tools/actionTracker/actionTrackerComponent';
import ErrorPage from '../../../../components/pages/error/errorPage';
import medicalTeamEvents from '../../../../store/tools/analytics/pcpSelection/medicalTeamEvents';
import LoadingComponent from '../../../../components/pages/loading/loadingComponent';
import ChildrenList from './childrenList';
import InviteFamilyFlow from '../activation/familyActivation/inviteFamilyFlow';
import PcpFamilyConfirmModal from '../pcpSelection/components/pcpFamilyConfirmModal';
import { displayProviderObj } from '../../../../utils/displayProvider';
import { hasInsuranceIdSelector, memberContext } from '../../../../store/member/selectors';
import {
  createToggleState,
  createToggleStatePropTypes,
} from '../../../../components/recompose/toggleStateComponent';
import SvgDoctorStuffStars from '../../../../assets/svg/doctorStuffStars';

import './medicalTeam.less';
import { getFeature, getDomain } from '../../../../store/feature/selectors';
import { PREMERA_BLUE_CROSS_ALASKA } from '../../../../model/enum/bkmdDomain';
import * as Subdomain from '../../../../model/enum/subdomain';

export const messages = defineMessages({
  actionSuccessTitle: {
    defaultMessage: 'Success!',
    id: 'members.medicalTeam.actionSuccessTitle',
  },
  actionErrorTitle: {
    defaultMessage: 'Oopsy',
    id: 'members.medicalTeam.actionErrorTitle',
  },
  yourPrimaryCareDoctor: {
    defaultMessage: 'Your Primary Care Doctor',
    id: 'members.medicalTeam.yourPrimaryCareDoctor',
  },
  lastSeen: {
    defaultMessage: 'Last seen: ',
    id: 'members.medicalTeam.lastSeen',
  },
  viewDetails: {
    defaultMessage: 'View details',
    id: 'members.medicalTeam.viewDetails',
  },
  changeMyDoctor: {
    defaultMessage: 'Change my doctor',
    id: 'members.medicalTeam.changeMyDoctor',
  },
  primaryCareDoctorFor: {
    defaultMessage: 'Primary Care Doctor for ',
    id: 'members.medicalTeam.primaryCareDoctorFor',
  },
  changeMyChildDoctor: {
    defaultMessage: 'Change my child’s doctor',
    id: 'members.medicalTeam.changeMyChildDoctor',
  },
  beReady: {
    defaultMessage:
      'Be ready when {firstName} needs a doctor. Choose a Primary Care Doctor for ' +
      '{genderPrep}.',
    id: 'members.medicalTeam.beReady',
  },
  chooseADoctor: {
    defaultMessage: 'Choose a doctor',
    id: 'members.medicalTeam.chooseADoctor',
  },
  chooseYourPrimaryCareDoctorToday: {
    defaultMessage: 'Choose your Primary Care Doctor today',
    id: 'members.medicalTeam.chooseYourPrimaryCareDoctorToday',
  },
  yourPrimaryCareDoctorKnowsYou: {
    defaultMessage:
      'Your Primary Care Doctor knows you and is the first place to start when you ' +
      'need care. Make sure you’re always ready by choosing a doctor you’ll love.',
    id: 'members.medicalTeam.yourPrimaryCareDoctorKnowsYou',
  },
  chooseMyDoctor: {
    defaultMessage: 'Choose my doctor',
    id: 'members.medicalTeam.chooseMyDoctor',
  },
  afterYouChooseAPrimaryCareDoctor: {
    defaultMessage:
      'After you choose a Primary Care Doctor for yourself, you can choose for ' +
      'your children. Be ready when they need care.',
    id: 'members.medicalTeam.afterYouChooseAPrimaryCareDoctor',
  },
  assignForAllDependents: {
    defaultMessage: 'Apply for my children',
    id: 'members.medicalTeam.assignForAllDependents',
  },
  assignForAllDependentsSuccess: {
    defaultMessage: "We've updated the doctor for your kids.",
    id: 'members.medicalTeam.assignForAllDependentsSuccess',
  },
  assignForAllDependentsError: {
    defaultMessage: 'Error occurred while updating pcp for dependents.',
    id: 'members.medicalTeam.assignForAllDependentsError',
  },
  errorScreenTitle: {
    defaultMessage: "We're having trouble",
    id: 'members.medicalTeam.errorTitleMessage',
  },
  errorScreenMessage: {
    defaultMessage: "We're sorry, but you'll have to go back.",
    id: 'members.medicalTeam.errorDescriptionMessage',
  },
  errorScreenButtonMessage: {
    defaultMessage: 'Go back home',
    id: 'members.medicalTeam.errorButtonDescription',
  },
  loadingScreenTitle: {
    defaultMessage: 'Loading your medical team',
    id: 'members.medicalTeam.loadingScreenTitle',
  },
  loadingScreenMessage: {
    defaultMessage: "Finding your doctor's info",
    id: 'members.medicalTeam.loadingScreenMessage',
  },
  inviteMyFamily: {
    defaultMessage: 'Invite my family',
    id: 'members.medicalTeam.inviteMyFamily',
  },
  inviteYourAdultDependentsToChoose: {
    defaultMessage: 'Invite your adult dependents to choose a Primary Care Doctor.',
    id: 'members.medicalTeam.inviteYourAdultDependentsToChoose',
  },
});

@autobind
class MedicalTeam extends React.PureComponent {
  static propTypes = {
    router: PropTypes.object.isRequired,
    updatePcpSelection: PropTypes.func.isRequired,
    getDependents: PropTypes.func.isRequired,
    getPcpSelection: PropTypes.func.isRequired,
    paths: PropTypes.object.isRequired,
    selectedPcp: PropTypes.object.isRequired,
    getDependentsTracker: PropTypes.object.isRequired,
    getPcpSelectionTracker: PropTypes.object.isRequired,
    hasInsuranceId: PropTypes.bool.isRequired,
    dependents: PropTypes.arrayOf(PropTypes.object),
    ...createToggleStatePropTypes('inviteFamilyFlowModal'),
    ...createToggleStatePropTypes('pcpFamilyConfirmModal'),
  };

  static contextTypes = {
    analytics: PropTypes.object.isRequired,
  };

  static defaultProps = {
    dependents: [],
  };

  componentWillMount() {
    const { getPcpSelection, getDependents } = this.props;
    getPcpSelection();
    getDependents(true);
  }

  pcpSelectionFlow(domainMemberId = '') {
    const { router, location, publicPcpSelectionFeature } = this.props;
    const isDependentMode = !!domainMemberId;
    this.context.analytics.track(
      isDependentMode
        ? medicalTeamEvents.dependentCard.changeMyChildDoctorClick
        : medicalTeamEvents.mainMemberCard.mainMemberChangeMyDoctorClick,
      { domainMemberId, isDependentMode },
    );
    if (publicPcpSelectionFeature) {
      router.push(`/publicPcpSelection?next=${location.pathname}`);
    }
  }

  viewPcpDetails(npi, locationId) {
    const { router, paths } = this.props;
    this.context.analytics.track(medicalTeamEvents.mainMemberCard.medicalTeamViewDoctorDetails, {
      npi,
    });
    router.push(paths.doctorDetails(npi, { locationId }));
  }

  assignAllChildren() {
    const { selectedPcp, dependents, updatePcpSelection, pcpFamilyConfirmModalHide } = this.props;

    pcpFamilyConfirmModalHide();
    updatePcpSelection(
      _.map(dependents.guardians, 'id'),
      _.get(selectedPcp, 'npi'),
      _.get(selectedPcp, 'locationId'),
      true,
    );
    this.context.analytics.track(medicalTeamEvents.mainMemberCard.applyForAllMyChildrenClick, {
      familyDomainMemberIds: _.map(dependents.guardians, 'id'),
      npi: _.get(selectedPcp, 'npi'),
    });
  }

  memberPcpSelectionFlow() {
    this.pcpSelectionFlow();
  }

  goToHomeOnError() {
    const { router, paths } = this.props;
    router.push(paths.home());
  }

  renderNoPcpForMember() {
    return (
      <div className="no-pcp">
        <div className="card">
          <div className="card-body text-center">
            <SvgDoctorStars />
            <div className="row">
              <div className="col-sm-6 col-sm-offset-3">
                <div className="title-24 margin-top-30 text-dark text-strong">
                  <FormattedMessage {...messages.chooseYourPrimaryCareDoctorToday} />
                </div>
                <div className="text-16 margin-bottom-clean-form">
                  <FormattedMessage {...messages.yourPrimaryCareDoctorKnowsYou} />
                </div>
                <div>
                  <button
                    className="btn btn-big bg-color-brand-button"
                    onClick={this.memberPcpSelectionFlow}
                  >
                    <FormattedMessage {...messages.chooseMyDoctor} />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderGuardianDependentsSection() {
    const { dependents } = this.props;
    // Sorting the dependents for consistency. Moreover, dependents with pcp should appear first
    const [hasPcp, noPcp] = _.partition(dependents.guardians, '_pcp.npi');
    const sortedDependents = _.sortBy(hasPcp, 'firstName').concat(_.sortBy(noPcp, 'firstName'));

    return _.map(sortedDependents, currDependent => (
      <PcpCard
        key={_.get(currDependent, 'id')}
        member={currDependent}
        pcp={_.get(currDependent, '_pcp._provider')}
        pcpLocationId={_.get(currDependent, '_pcp.locationId')}
        dependent={currDependent}
        onPcpSelectionFlow={this.pcpSelectionFlow}
        onViewPcpDetails={this.viewPcpDetails}
      />
    ));
  }

  renderAdultDependentsSection() {
    const {
      dependents,
      hasInsuranceId,
      inviteFamilyFlowModalOn,
      inviteFamilyFlowModalShow,
      inviteFamilyFlowModalHide,
    } = this.props;

    // Sorting for consistency
    const sortedDependents = _.sortBy(dependents.adults, 'firstName');

    return (
      <div className="card">
        <div className="card-body">
          <div className="text-18 text-center">
            <FormattedMessage {...messages.inviteYourAdultDependentsToChoose} />
          </div>
          <div className="margin-top-30">
            <ChildrenList guardians={sortedDependents} adults />
          </div>
          <div className="margin-top-30">
            <button
              className="btn btn-big bg-color-brand-button"
              onClick={inviteFamilyFlowModalShow}
            >
              <FormattedMessage {...messages.inviteMyFamily} />
            </button>
          </div>
        </div>
        <InviteFamilyFlow
          hasInsuranceId={hasInsuranceId}
          isOpen={inviteFamilyFlowModalOn}
          onClose={inviteFamilyFlowModalHide}
          skipIntro
        />
      </div>
    );
  }

  renderContent() {
    const {
      selectedPcp,
      dependents,
      getDependentsTracker,
      getPcpSelectionTracker,
      pcpFamilyConfirmModalOn,
      pcpFamilyConfirmModalShow,
      pcpFamilyConfirmModalHide,
      isPeakcare,
    } = this.props;

    const isPcpSelected = _.get(selectedPcp, 'npi');
    const provider = isPcpSelected && _.get(selectedPcp, '_provider');
    const hasMinorDependents = !_.isEmpty(_.get(dependents, 'guardians', []));
    const hasAdultsDependents = !_.isEmpty(_.get(dependents, 'adults', []));

    if (!getDependentsTracker.executed || !getPcpSelectionTracker.executed) {
      return (
        <LoadingComponent
          spinnerClassName="medical-team-loading-spinner"
          headerMessage={messages.loadingScreenTitle}
          contentMessage={messages.loadingScreenMessage}
          svgComponent={SvgDoctorStuffStars}
        />
      );
    }
    if (getDependentsTracker.hasError || getPcpSelectionTracker.hasError) {
      return (
        <ErrorPage
          titleMessage={messages.errorScreenTitle}
          descriptionMessage={messages.errorScreenMessage}
          buttonMessage={messages.errorScreenButtonMessage}
          displayError={false}
          displayButton
          onSubmit={this.goToHomeOnError}
        />
      );
    }

    return (
      <div className="container container-1040">
        <div className="row">
          <div className="col-xs-12">
            <div className="medical-team-page">
              {isPcpSelected ? (
                <div>
                  <PcpCard
                    pcp={provider}
                    pcpLocationId={_.get(selectedPcp, 'locationId')}
                    hasMinorDependents={hasMinorDependents}
                    onPcpSelectionFlow={this.pcpSelectionFlow}
                    onViewPcpDetails={this.viewPcpDetails}
                    onAssignAllChildren={pcpFamilyConfirmModalShow}
                  />

                  <PcpFamilyConfirmModal
                    doctorName={displayProviderObj(provider)}
                    isOpen={pcpFamilyConfirmModalOn}
                    handleClose={pcpFamilyConfirmModalHide}
                    onApprove={this.assignAllChildren}
                  />
                </div>
              ) : (
                this.renderNoPcpForMember()
              )}

              {!isPeakcare && hasMinorDependents ? this.renderGuardianDependentsSection() : null}

              {!isPeakcare && hasAdultsDependents ? this.renderAdultDependentsSection() : null}
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <Layout>
        <div>
          <BlueHeader
            blueHeaderClass="white"
            mainSvgComp={<SvgMedicalTeam />}
            title="Your medical team"
          />
          <div> {this.renderContent()} </div>
        </div>
        <div className="content-grey-bg" />
      </Layout>
    );
  }
}

export const shouldShowPCPModal = function shouldShowPCPModalForPeakcare(state) {
  const isPremera = getDomain(state) === PREMERA_BLUE_CROSS_ALASKA;
  return isPremera ? memberContext(state) === Subdomain.PREMERA_PEAK_CARE : true;
};

const isPeakcare = function isPeakcareMember(state) {
  const isPremera = getDomain(state) === PREMERA_BLUE_CROSS_ALASKA;
  const isPeakcare = memberContext(state) === Subdomain.PREMERA_PEAK_CARE;
  return isPremera && isPeakcare;
};

export default compose(
  createToggleState('inviteFamilyFlowModal'),
  createToggleState('pcpFamilyConfirmModal'),
  withRouter,
  getPathsFromContext(),
  connect(
    state => ({
      selectedPcp: medicalTeamSelectors.getSelectedPcp(state),
      dependents: medicalTeamSelectors.getDependents(state),
      hasInsuranceId: hasInsuranceIdSelector(state),
      publicPcpSelectionFeature: getFeature(state, 'publicPcpSelection'),
      shouldShowPCPModal: shouldShowPCPModal(state),
      isPeakcare: isPeakcare(state),
    }),
    { updatePcpSelection, getPcpSelection, getDependents },
  ),
  branch(({ shouldShowPCPModal }) => !shouldShowPCPModal, renderNothing),
  actionTracker(
    {
      updatePcpSelectionTracker: UPDATE_PCP_SELECTION.SOURCE,
      getDependentsTracker: GET_DEPENDENTS.SOURCE,
      getPcpSelectionTracker: GET_PCP_SELECTION.SOURCE,
    },
    {
      updatePcpSelectionTracker: {
        success: {
          title: messages.actionSuccessTitle,
          text: messages.assignForAllDependentsSuccess,
        },
        error: { title: messages.actionErrorTitle, text: messages.assignForAllDependentsError },
      },
    },
  ),
)(MedicalTeam);
