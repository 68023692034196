/**
 * Created by matan on 7/27/16.
 */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import zxcvbn from 'zxcvbn';
import _ from 'lodash';

import './passwordMeter.less';

import { PasswordStrengthEnum } from './passwordPolicy';

export default class PasswordMeter extends React.Component {
  static propTypes = {
    /**
     * The password to evaluate
     */
    password: PropTypes.string,
    /**
     * Show password strengthening.
     * default is false.
     */
    showSuggestions: PropTypes.bool,
    /**
     * Inputs to be considered when evaluating password
     */
    userInputs: PropTypes.array,
    /**
     * Optional hook method.
     * Called with the new password strength when changed.
     */
    onStrengthChanged: PropTypes.func,
  };

  static defaultProps = {
    password: '',
    showSuggestions: false,
    userInputs: [],
    onStrengthChanged: _.noop,
  };

  constructor(props) {
    super(props);
    this.state = { passwordEvaluation: this.evaluatePassword(props.password, props.userInputs) };
  }

  componentWillReceiveProps(nextProps) {
    const { password, userInputs } = nextProps;
    // No need to re-evaluation if the password haven't changed
    if (this.props.password !== password || !_.isEqual(this.props.userInputs, userInputs)) {
      this.setState({ passwordEvaluation: this.evaluatePassword(password, userInputs) });
    }
  }

  evaluatePassword(password, userInputs) {
    const currentStrength = _.get(this.state, 'passwordEvaluation.score', 0);
    const passwordStrength = zxcvbn(password, userInputs);

    if (passwordStrength.score !== currentStrength) {
      this.props.onStrengthChanged({ score: passwordStrength.score });
    }

    return passwordStrength;
  }

  renderPasswordSuggestions(suggestions = []) {
    const result = [];
    _.forEach(suggestions, (suggestion, index) => {
      result.push(<li key={index}>{suggestion}</li>);
    });

    return <ul>{result}</ul>;
  }

  render() {
    const {
      passwordEvaluation: { score, feedback },
    } = this.state;
    const { showSuggestions, password } = this.props;
    const hasPassword = !_.isEmpty(password);
    const strengthClass = `strength-${score}`;

    return (
      <div
        className={classNames('password-strength-wrap', { [strengthClass]: hasPassword })}
        id="pw-strength"
        aria-live="polite"
        aria-atomic="true"
      >
        <div className="strength-meter">
          <div className="strength-col " />
          <div className="strength-col" />
          <div className="strength-col" />
          <div className="strength-col" />
          <div className="strength-col" />
        </div>
        <div className="password-strength-text">
          {feedback.warning}
          {showSuggestions ? this.renderPasswordSuggestions(feedback.suggestions) : null}
        </div>
        {hasPassword ? (
          <div className="password-strength-description">
            <div className="off-screen-text">Password strength is </div>
            <FormattedMessage {...PasswordStrengthEnum.getMessageByValue(score)} />
          </div>
        ) : null}
      </div>
    );
  }
}
