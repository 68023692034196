import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import _ from 'lodash';
import { autobind } from 'core-decorators';
import ClickacleDiv from '../../../../../components/clickableElement/clickableDiv';
import composeDoctor from '../doctorReviewHOC';

import './doctorReview.less';

@autobind
class PostReviewActionsList extends React.Component {
  static propTypes = {
    options: PropTypes.arrayOf(
      PropTypes.shape({
        message: PropTypes.object.isRequired,
        pic: PropTypes.element.isRequired,
        path: PropTypes.string.isRequired,
      }),
    ).isRequired,
    router: PropTypes.object.isRequired,
  };

  onClick(path) {
    const { router } = this.props;
    return () => router.push(path);
  }

  renderOptions() {
    const { options } = this.props;
    return _.map(options, (option, index) => (
      <li key={index}>
        <ClickacleDiv clickHandler={this.onClick(option.path)} className="doctor-review-icons">
          <div className="item-icon">{option.pic}</div>
          <div className="item-content font-color-brand-secondary">
            <FormattedMessage {...option.message} />
          </div>
          <i className="icon-chevron-right item-arrow font-color-brand-secondary" />
        </ClickacleDiv>
      </li>
    ));
  }

  render() {
    return (
      <div className="padding-h-30">
        <ul className="thanks-action-list">{this.renderOptions()}</ul>
      </div>
    );
  }
}

export default composeDoctor(PostReviewActionsList);
