import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _ from 'lodash';
import PointDistance from 'Components/ui/map/pointDistance';
import { defineMessages } from 'react-intl';

import './DistanceChip.less';
import SvgLocation2 from '../../../../../assets/svg/location2';

// TODO: make PointDistance accept string and send in trnaslated message..
const messages = defineMessages({
  clinicDistance: {
    defaultMessage: '{clinicDistance} mi',
    id: 'members.components.clinicDistance',
  },
});

function DistanceChip({ distance, p1, p2, className, options: { showIcon } }) {
  if ((!p1 || !p2) && _.isNil(distance)) return null;

  return (
    <div className={classNames('distance', className)}>
      {showIcon ? <SvgLocation2 /> : null}
      <PointDistance distance={distance} p1={p1} p2={p2} message={messages.clinicDistance} />
    </div>
  );
}

DistanceChip.propTypes = {
  distance: PropTypes.number,
  p1: PropTypes.object,
  p2: PropTypes.object,
  options: PropTypes.object,
  className: PropTypes.string,
};
DistanceChip.defaultProps = {
  distance: undefined,
  p1: undefined,
  p2: undefined,
  options: { showIcon: true },
  className: '',
};

export default DistanceChip;
