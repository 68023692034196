import _ from 'lodash';
import { replace, push } from 'react-router-redux';

export default function clearQueryString(pushToHistory = false) {
  return ({ getState }) => {
    const state = getState();
    const pathname = _.get(state, 'routing.locationBeforeTransitions.pathname');
    const action = pushToHistory ? push : replace;
    return action(pathname);
  };
}
