import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import ErrorPageSvg from './errorPageSvg';
import MessageShape from '../../ui/intlString/messageShape';
import './errorPage.less';
import Button from '../../../storybook/src/components/atoms/Button/Button';
import Text from '../../../storybook/src/components/atoms/Text/Text';

const ErrorPage = ({
  titleMessage,
  descriptionMessage,
  buttonMessage,
  errorMessage,
  displayError,
  displayButton,
  onSubmit,
  onClose,
}) => (
  <div className="error-page">
    {onClose && (
      <Button onClick={onClose} type="link" color="darkGray" className="close-button">
        <Text className="icon-x-2" size="18px" />
      </Button>
    )}
    <div className="row margin-top-30">
      <ErrorPageSvg className="error-image" />
    </div>
    <div className="row title-36 text-strong font-color-brand-main text-center main-title">
      <FormattedMessage {...titleMessage} />
    </div>
    <div className="row title-24 text-center md-margin description-text">
      <FormattedMessage {...descriptionMessage} />
    </div>
    {displayButton ? (
      <div className="row">
        <button className="btn btn-block text-medium text-dark btn-bordered" onClick={onSubmit}>
          <FormattedMessage {...buttonMessage} />
        </button>
      </div>
    ) : null}

    <div className={classNames('row dev-error', { visible: displayError })}>{errorMessage}</div>
  </div>
);

ErrorPage.propTypes = {
  titleMessage: MessageShape.isRequired,
  descriptionMessage: MessageShape.isRequired,
  buttonMessage: MessageShape,
  errorMessage: PropTypes.string,
  onSubmit: PropTypes.func,
  displayError: PropTypes.bool,
  displayButton: PropTypes.bool,
  onClose: PropTypes.func,
};

ErrorPage.defaultProps = {
  errorMessage: '',
  buttonMessage: '',
  displayError: false,
  displayButton: true,
  onSubmit: _.noop,
  onClose: undefined,
};

export default ErrorPage;
