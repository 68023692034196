import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, FormattedMessage } from 'react-intl';
import { PrivacyPolicyLink, TosLink } from '../ui/links/index';
import BaseLink from '../ui/links/baseLink';
import FeatureFlagged from '../../components/features/featureFlagged';

const messages = defineMessages({
  byClickingIAgree: {
    defaultMessage: 'By clicking "Activate my account", you agree to Vim\'s',
    id: 'tos.unitedTos.byClickingIAgree'
  },
  and: {
    defaultMessage: 'and',
    id: 'tos.unitedTos.and'
  },
  unitedHealthCareTos: {
    defaultMessage: 'Texting Terms and Conditions',
    id: 'tos.unitedTos.unitedHealthCareTos',
  },
  UnitedHealthcare: {
    defaultMessage: 'UnitedHealthcare`s',
    id: 'tos.unitedTos.UnitedHealthcare',
  },
});

const UnitedTermsOfService = ({ isMember, hideAgreeMessage }) => (
  <FeatureFlagged uri="layout.showTermsOfService">
    <div className="row padding-v-20">
      <div className="col-xs-12 text-12">
        {hideAgreeMessage ? null : <FormattedMessage {...messages.byClickingIAgree} />}
        &nbsp;
        <TosLink className="underline-link" aria-labelledby="new-window-0" isMember={isMember} />
        &nbsp;
        <i className="icon-ext-link i-va-fix-2 text-12" id="new-window-0" aria-label="external-link" />
        &nbsp;
        <FormattedMessage {...messages.and} />
        &nbsp;
        <span className="inline-block">
          <PrivacyPolicyLink className="underline-link" aria-labelledby="new-window-1" isMember={isMember} />
          &nbsp;
          <i className="icon-ext-link i-va-fix-2 text-12" id="new-window-1" aria-label="external-link" />
          &nbsp;
        </span>
        <FormattedMessage {...messages.and} />
        &nbsp;
        <FormattedMessage {...messages.UnitedHealthcare} />
        &nbsp;
        <BaseLink
          className="underline-link"
          url="https://www.uhc.com/legal/texting-terms-and-conditions"
          label={messages.unitedHealthCareTos}
          aria-labelledby="new-window-2"
        />
        &nbsp;
        <i className="icon-ext-link i-va-fix-2 text-12" id="new-window-2" aria-label="external-link" />
      </div>
    </div>
  </FeatureFlagged>
);

UnitedTermsOfService.propTypes = {
  isMember: PropTypes.bool,
  hideAgreeMessage: PropTypes.bool,
};

UnitedTermsOfService.defaultProps = {
  isMember: false,
  hideAgreeMessage: false,
};

export default UnitedTermsOfService;
