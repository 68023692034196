import { safeLocalStorage } from '../storage/localStorage';

/**
 * Created by asafdavid on 09/05/2016.
 */
/**
 * Stores the provided jwt token in local storage
 * @param accessToken
 * @param refreshToken
 */
export function storeJwt(accessToken, refreshToken) {
  if (accessToken) safeLocalStorage.setItem('accessToken', accessToken);
  if (refreshToken) safeLocalStorage.setItem('refreshToken', refreshToken);
}

/**
 * Removes the stored tokens
 * @param accessToken
 * @param refreshToken
 */
export function removeJwt() {
  safeLocalStorage.removeItem('accessToken');
  safeLocalStorage.removeItem('refreshToken');
}

/**
 * Fetches the stored tokens from local storage
 * @returns {{accessToken, refreshToken}}
 */
export function getJwt() {
  const accessToken = safeLocalStorage.getItem('accessToken');
  const refreshToken = safeLocalStorage.getItem('refreshToken');
  return { accessToken, refreshToken };
}

export function getRecaptcha() {
  return safeLocalStorage.getItem('recaptcha');
}

/**
 * Stores the provided login identifier in local storage
 * @param phone
 */
export function storeLoginIdentifier(identifier) {
  safeLocalStorage.setItem('loginIdentifier', identifier);
}

export function storeRecaptcha(token) {
  safeLocalStorage.setItem('recaptcha', token);
}

export function removeRecaptcha() {
  safeLocalStorage.removeItem('recaptcha');
}

/**
 * Fetches the stored login identifier from local storage
 * @returns {phone}
 */
export function getLoginIdentifier() {
  return safeLocalStorage.getItem('loginIdentifier');
}

export function isLocalStorageAccessible() {
  try {
    localStorage.setItem('test', 'test');
    localStorage.removeItem('test');
    return true;
  } catch (e) {
    return false;
  }
}
